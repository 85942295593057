const {FuzzyString} = require("./FuzzyString");
const {sourceSignificantList, sourceTrashList, targetList} = require("./lists");

/* eslint-disable max-statements */
export function fuzzySearch(text, substring) {
  let start = -1;
  let end = -1;

  if (
    typeof text === "string" &&
    text.length > 0 &&
    typeof substring === "string" &&
    substring.length > 0
  ) {
    const fuzzyText = new FuzzyString(
      text,
      sourceSignificantList,
      sourceTrashList,
    );
    const fuzzySubstring = new FuzzyString(substring, targetList);

    const pureStart = fuzzyText.pure.indexOf(fuzzySubstring.pure);
    const pureEnd = pureStart + fuzzySubstring.pure.length;

    if (pureStart > -1) {
      // get start and end offsets for pure substring
      // without possible start and end not pure characters
      start = fuzzyText.getOffsetForPosition(pureStart, true);
      end = fuzzyText.getOffsetForPosition(pureEnd);

      // try to process possible start and end not pure characters
      const substringWrapper = fuzzySubstring.getWrapper();
      const textStartPart = text.substring(
        start - substringWrapper.start.length,
        start,
      );
      if (textStartPart === substringWrapper.start) {
        start -= textStartPart.length;
      }
      const textEndPart = text.substring(
        end,
        end + substringWrapper.end.length,
      );
      if (textEndPart === substringWrapper.end) {
        end += textEndPart.length;
      }
    }
  }

  return {start, end};
}
/* eslint-enable max-statements */

import React from "react";
import {FormControl, Menu, MenuItem, Select} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const disabledMenuItemStyles = makeStyles({
  root: {
    color: "#0574d6",
    fontSize: 14,
    fontWeight: 600,
    cursor: "unset",
    "&.MuiListItem-button:hover": {
      backgroundColor: "unset",
    },
  },
});

export function StateSelectMenu(props) {
  const [currentStateId, updateCurrentStateId] = React.useState(props.stateId);
  React.useEffect(
    () => {
      updateCurrentStateId(props.stateId);
    },
    [props.stateId],
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const {states} = props;
  if (states.length === 0) {
    return null;
  }

  const currentState = states.find(({id}) => id === currentStateId);

  return (
    <div style={{...(props.containerStyles ? props.containerStyles : {})}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          color: props.invertColors ? "#424242" : "#9e9e9e",
        }}
        onClick={handleClick}
      >
        <div>{currentState.name}</div>
        <ExpandMoreIcon
          style={{
            marginLeft: 4,
            color: props.isFlipChecklistIcons ? "#bdbdbd" : "#5D6770",
          }}
        />
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{style: {borderRadius: 0}}}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem classes={disabledMenuItemStyles()} disableRipple>
          Document Status
        </MenuItem>
        {states.map((state, stateIndex) => {
          function handler() {
            props.updateState(state.id);
            updateCurrentStateId(state.id);
          }
          return (
            <MenuItem
              key={stateIndex}
              onClick={createItemClicker(handleClose, handler)}
            >
              <div>{state.name}</div>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}

function createItemClicker(onClose, handler) {
  return e => {
    e.stopPropagation();
    if (handler) {
      handler();
    }
    onClose();
  };
}

export default function renderDocumentStateSelect({
  states,
  stateId,
  updateState,
  style,
}) {
  if (states.length === 0) {
    return null;
  }

  const state = states.find(({id}) => id === stateId);
  return (
    <FormControl variant="outlined" margin="dense">
      <Select
        value={state ? state.id : ""}
        onChange={event => updateState(event.target.value)}
        size="small"
        style={{
          ...(style || {}),
          ...(state ? state.style : {}),
        }}
      >
        {states.map(({id, name}) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

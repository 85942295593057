import _ from "underscore";
import React from "react";

import IssueListButton from "./common_components/issue_list_button";

import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getIssueValue from "utils/issues/get_issue_value";
import {getCommonDefinitionApplicableClauseGroups} from "utils/issues/common_definitions";

const issueClasses = {1: true, 3: true};

function MissingIssues(props) {
  const [isOpen, setIsOpen] = React.useState(true);
  if (!props.showClauseButtons) {
    return null;
  }
  const issueset = props.currentIssuesetItem;
  const missingIssues = props.issues
    .filter(
      issue =>
        getIssueValue(issue, issueset, "show_in_clause_buttons") &&
        hasNoClauses(
          issue,
          issueset,
          props.documentClauses,
          props.topicsById,
          props.positiveReasonData,
          props.documentClauseparts,
          props.document?.parties ?? [],
        ) &&
        issue.issue_type !== "EMPTY_PARENT" &&
        isIssueAlert(issue) &&
        issueClasses[issue.issue_class_id] &&
        !issue.shouldBeHidden,
    )
    .reduce(
      (state, issue) => {
        const displayName = getSecondPartIssueName(
          props.areIssuesCollapsed
            ? filterName(issue.display_name || issue.name)
            : issue.display_name,
        );

        if (!state.names[displayName]) {
          state.names[displayName] = true;
          state.issues.push(issue);
        }
        return state;
      },
      {issues: [], names: {}},
    ).issues;
  if (missingIssues.length === 0) {
    return null;
  }

  const Icon = !isOpen ? PlusIcon : MinusIcon;

  return (
    <div
      style={{
        margin: "1rem 2rem",
        border: "1px solid #ccc",
      }}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: 400,
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #ccc",
          padding: "1rem",
        }}
      >
        <Icon
          style={{marginRight: "10px"}}
          onClick={() => setIsOpen(!isOpen)}
        />Missing Issues ({missingIssues.length})
      </div>
      {isOpen && (
        <div
          style={{
            padding: "1rem",
            display: "flex",
            flexWrap: "wrap",
            backgroundColor: "#fafafa",
          }}
        >
          {missingIssues.map(issue => {
            const displayName = fixName(issue.display_name || issue.name);
            return (
              <IssueListButton
                key={issue.id}
                issue={issue}
                name={displayName}
                showIssueDetail={props.showIssueDetail}
                showIssuesInChecklist={props.showIssuesInChecklist}
                project={props.project}
                selectedReportId={props.selectedReportId}
                currentIssuesetItem={props.currentIssuesetItem}
                user={props.user}
                documentClauses={props.documentClauses}
                topicsById={props.topicsById}
                positiveReasonData={props.positiveReasonData}
                documentClauseparts={props.documentClauseparts}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

function hasNoClauses(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  parties,
) {
  const commonDefinitionApplicableClauseGroups = getCommonDefinitionApplicableClauseGroups(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    parties,
  );
  if (
    _.flatten(Object.values(commonDefinitionApplicableClauseGroups || {}))
      .length > 0
  ) {
    return false;
  }

  const issuesetMasterId = issueset.master_id;
  if (issue.applicable_clauses) {
    const issuesetClauses = issue.applicable_clauses[issuesetMasterId];
    if (!issuesetClauses) {
      return (
        !issue.applicable_clauses.base ||
        issue.applicable_clauses.base.length === 0
      );
    }
    const clientClauses = issuesetClauses[issueset.remote_client_id];
    if (!clientClauses) {
      const masterClauses = issuesetClauses.master;
      if (!masterClauses) {
        return (
          !issue.applicable_clauses.base ||
          issue.applicable_clauses.base.length === 0
        );
      }
      return issuesetClauses.master.length === 0;
    }
    return clientClauses.length === 0;
  }

  return !issue.reason || issue.reason.length === 0;
}

function fixName(name) {
  return name.replace(/\[.*\]$/, "");
  // if (name.match(/\/.*\//)) {
  // return name.replace(/^.*?\/\s*/, "");
  // }
  // return name.replace(/^.*?/\s*/, "");
}

// TODO: refactor with issue_list_button
function filterName(name) {
  return name.replace(/\s*[\[\{].*[\]\}]/g, "");
}

function getSecondPartIssueName(issueName) {
  const issueNameParts = issueName.split("/");
  return issueNameParts[issueNameParts.length - 1].trim();
}

export default MissingIssues;

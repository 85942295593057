import issueHeaderUtils from "utils/issues/issue_header_utils";

function getEmptyParentColor(issue, issueset) {
  const subissueStats = issueHeaderUtils.getSubissueStats(
    issue,
    issueset,
  );
  if (
    subissueStats.notesTriggered > 0 &&
    subissueStats.notesTriggered === subissueStats.triggered
  ) {
    return "grey";
  }
  if (
    subissueStats.triggered &&
    subissueStats.triggered === subissueStats.warningsTriggered
  ) {
    return "amber";
  }
  if (subissueStats.triggered) {
    return "red";
  }
  if (subissueStats.confirmed) {
    return "blue";
  }
  if (subissueStats.rejected) {
    return "grey";
  }

  return "green";
}

export default getEmptyParentColor;

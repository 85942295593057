import React from "react";

import {makeStyles} from "@material-ui/styles";

import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DeleteIcon from "@material-ui/icons/Delete";

import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles({
  root: {
    cursor: "pointer",
    padding: "0px 4px 0px 0px",
    "&:hover": {
      background: "#eeeeee",
      "& .MuiSvgIcon-root": {
        visibility: "visible",
      },
    },
    "& .MuiSvgIcon-root:not(.visible)": {
      visibility: "hidden",
      color: "#424242",
      width: 18,
      height: 18,
    },
  },
});

function ListItemHoverable(props) {
  const {
    onShiftUp,
    onShiftDown,
    onDelete,
    disabled,
    selected,
    style = {},
  } = props;
  const classes = useStyles();

  const onShiftUpClick = e => {
    e.stopPropagation();
    if (onShiftUp) {
      onShiftUp();
    }
  };

  const onShiftDownClick = e => {
    e.stopPropagation();
    if (onShiftDown) {
      onShiftDown();
    }
  };
  const disabledHiddenStyles = disabled ? {visibility: "hidden"} : {};
  return (
    <ListItem
      onClick={disabled ? undefined : props.onClick}
      className={classes.root}
      style={{
        ...(selected ? {background: "#bdbdbd"} : {}),
        ...(disabled
          ? {opacity: "0.3", cursor: "default", background: "unset"}
          : {}),
        ...style,
      }}
    >
      {onDelete && <DeleteIcon onClick={onDelete} />}
      {props.children}
      {onShiftUp && (
        <KeyboardArrowUpIcon
          onClick={onShiftUpClick}
          style={{
            marginRight: 2,
            ...(props.isFirstInList ? {visibility: "hidden"} : {}),
            ...disabledHiddenStyles,
          }}
        />
      )}
      {onShiftDown && (
        <KeyboardArrowDownIcon
          onClick={onShiftDownClick}
          style={{
            ...(props.isLastInList ? {visibility: "hidden"} : {}),
            ...disabledHiddenStyles,
          }}
        />
      )}
    </ListItem>
  );
}

export default ListItemHoverable;

// sessionStorage can be unavailable in some cases, e.g. safari incognito mode

const sessionStorage = {
  getItem: key => {
    let result = null;
    try {
      // if result is a string but not JSON we return string
      result = window.sessionStorage.getItem(key);
      result = JSON.parse(result);
    } catch {
      // should throw error or something if there's no access to sessionStorage
    }
    return result;
  },
  setItem: (key, value) => {
    try {
      window.sessionStorage.setItem(
        key,
        typeof value === "string" ? value : JSON.stringify(value),
      );
    } catch {
      // should throw error or something if there's no access to sessionStorage
    }
  },
  removeItem: key => {
    try {
      window.sessionStorage.removeItem(key);
    } catch {
      // should throw error or something if there's no access to sessionStorage
    }
  },
  clear: () => {
    try {
      window.sessionStorage.clear();
    } catch {
      // should throw error or something if there's no access to sessionStorage
    }
  },
};

export default sessionStorage;

import _ from "underscore";
import Issue from "../../../plugins/issues";

const topicparameterMatchLogics = {
  a_equals_b: "equals",
  a_contains_b: "contains",
  a_ends_with_b: "ends with",
  a_starts_with_b: "starts with",
  a_does_not_equal_b_exactly: "does not equal exactly",
  a_matches_list_b: "A is the same list as B",
  a_does_not_match_list_b: "A is not the same list as B",
};

class IssueDescriber {
  constructor(issue, topics, conjunction = "and") {
    this.issue = issue;
    this.topics = topics;
    this.conjunction = conjunction;
  }

  get topic() {
    if (!this.cachedTopic) {
      const {topic_id: topicId} = this.issue.rules;
      this.cachedTopic = this.topics.find(value => value.id === topicId);
    }
    return this.cachedTopic;
  }

  get parameter() {
    const {parameter_id: parameterId} = this.issue.rules;
    return this.topic.parameters.find(value => value.id === parameterId);
  }

  get topicName() {
    return this.topic.name;
  }

  get parameterName() {
    return this.parameter.name;
  }

  listParameters(conjunction = "and") {
    const values = this.issue.rules.parameter_ids.map(
      parameterId =>
        this.topic.parameters.find(parameter => parameter.id === parameterId)
          .name,
    );
    return this.makeList(values, conjunction);
  }
  listParameterRules(conjunction = "and") {
    const values = this.issue.rules.parameter_ids.map(parameterRule => {
      const parameterId = this.getParameterId(parameterRule);
      const parameterType = this.getParameterType(parameterRule);
      const parameterName = this.topic.parameters.find(
        parameter => parameter.id === parameterId,
      ).name;
      return `parameter ${parameterName} exists ${
        parameterType === "any_value"
          ? "with any value"
          : `matching regex ${parameterRule.text}`
      }`;
    });
    return this.makeList(values, conjunction);
  }

  getParameterType(parameterRule) {
    return typeof parameterRule === "object" ? parameterRule.type : "any_value";
  }

  getParameterId(parameterRule) {
    return typeof parameterRule === "object" ? parameterRule.id : parameterRule;
  }

  get listParameterValues() {
    const values = this.issue.rules.parameter_values.map(value => `'${value}'`);
    return this.makeList(values, "or");
  }

  get listChildIssues() {
    const values = this.issue.rules.issues.map(childIssue =>
      this.printChildIssue(childIssue),
    );
    return this.makeList(values, this.conjunction);
  }

  printChildIssue(childIssue) {
    if (Array.isArray(childIssue.issues)) {
      return childIssue.issues
        .map(issue => this.printChildIssue(issue))
        .join(", ");
    }
    if (childIssue.issue_type) {
      const type = childIssue.issue_type.toLowerCase();
      return Issue[type].issueDescription(childIssue, this.topics);
    }
    return "";
  }

  makeList(values, predicate) {
    let valueText;
    if (values.length >= 3) {
      valueText = [
        values.slice(0, -1).join(", "),
        predicate,
        values[values.length - 1],
      ].join(" ");
    } else {
      valueText = values.join(` ${predicate} `);
    }
    return valueText;
  }

  get durationRange() {
    const {rules: {min, max, unit}} = this.issue;
    let valueText;
    if (min && max) {
      valueText = `between ${min} and ${max} ${unit}s`;
    } else if (min) {
      valueText = `more than ${min} ${unit}${min !== 1 ? "s" : ""}`;
    } else {
      // if (max) {
      valueText = `less than ${max} ${unit}${max !== 1 ? "s" : ""}`;
    }
    return valueText;
  }

  get currencies() {
    const {rules: {currencies}} = this.issue;
    const combinedValues = _.map(currencies, (value, currency) => {
      return `${currency} ${this.makeRangeText(value)}`;
    }).join(" and ");
    return combinedValues;
  }

  get range() {
    return this.makeRangeText(this.issue.rules.range);
  }

  makeRangeText(value) {
    const {min, max} = value;
    let rangeText;
    if (min && max) {
      rangeText = `between ${min} and ${max}`;
    } else if (min) {
      rangeText = `greater than ${min}`;
    } else {
      // if (max) {
      rangeText = `less than ${max}`;
    }
    return rangeText;
  }

  get numericType() {
    const types = this.issue.rules.permitted_types;
    const hasUnspecified = types.indexOf("unspecified") >= 0;
    const hasPercentage = types.indexOf("percentage") >= 0;
    let text;
    if (hasUnspecified && hasPercentage) {
      text = "a number of any type";
    } else if (hasUnspecified) {
      text = "a number (not a percentage)";
    } else if (hasPercentage) {
      text = "a percentage";
    } else {
      text = "a number";
    }
    return text;
  }

  get matchLogic() {
    return topicparameterMatchLogics[this.issue.rules.match_logic];
  }
}

export default {IssueDescriber};

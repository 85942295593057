import React from "react";
import PropTypes from "prop-types";

import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";

class Errors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.updateErrorState();
  }

  componentDidUpdate() {
    this.updateErrorState();
  }

  render() {
    return (
      <div
        style={{
          fontFamily: "Roboto, sans-serif",
        }}
      >
        {this.renderError()}
      </div>
    );
  }

  renderError() {
    const {error} = this.props;
    if (error && error.message) {
      return (
        <Snackbar
          className="error"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={this.state.isOpen || false}
          autoHideDuration={5000}
          onClose={this.closeErrorSnackbar}
          message={
            this.isDocumentOutOfDateMessage(error.message)
              ? "Document is out of date. Refresh and try again."
              : error.message
          }
          action={this.getActionButton()}
        />
      );
    }
    return null;
  }

  getActionButton() {
    const {error, location} = this.props;
    const locationRegex = /project\/\d+\/document\/\d+\/(?:detail|checklist)/;

    if (
      location &&
      location.pathname &&
      locationRegex.test(location.pathname) &&
      error &&
      this.isDocumentOutOfDateMessage(error.message)
    ) {
      const handler = () => {
        this.props.fetchDocument();
        this.closeErrorSnackbar();
      };
      return (
        <IconButton size="small" color="inherit" onClick={handler}>
          <RefreshIcon fontSize="small" />
        </IconButton>
      );
    }
  }

  isDocumentOutOfDateMessage(message) {
    return message
      ? message.indexOf("document") !== -1 &&
          message.indexOf("is out of date") !== -1
      : false;
  }

  updateErrorState() {
    const {error} = this.props;
    const isOpen = error && error.time + 60000 > this.props.now.getTime();
    if (isOpen !== this.state.isOpen && error !== this.state.lastError) {
      this.setState({isOpen, lastError: error});
    }
  }

  /* eslint-disable no-invalid-this */
  closeErrorSnackbar = () => {
    this.setState({isOpen: false});
  };
  /* eslint-enable no-invalid-this */
}

Errors.propTypes = {
  errors: PropTypes.shape({
    message: PropTypes.string,
    time: PropTypes.number,
  }),
};

export default Errors;

import _ from "underscore";

export function getStoredChecklistState(name, documentId) {
  const data = sessionStorage.getItem(name);
  if (data) {
    const dataArray = data.split("---");
    const storedDocumentId = parseInt(dataArray[0], 10);
    if (documentId === storedDocumentId) {
      return parseInt(dataArray[1], 10) || dataArray[1];
    }
  }
  return null;
}

export const storeChecklistState = _.memoize(
  name => (documentId, itemId) => {
    sessionStorage.setItem(name, `${documentId}---${itemId}`);
  },
  (...args) => JSON.stringify(args),
);

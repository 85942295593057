function getDateStatement(dateFrom, dateTo, dateToCompare) {
  const {unit: unitFrom, value: valueFrom, range: rangeFrom} = dateFrom;
  const {unit: unitTo, value: valueTo, range: rangeTo} = dateTo;

  const compareDate = dateToCompare ? `${dateToCompare}.` : "Upload.";
  if (valueFrom && valueTo) {
    return [
      "between",
      fillDateTemplate(valueFrom, unitFrom, rangeFrom),
      "and",
      fillDateTemplate(valueTo, unitTo, rangeTo),
      compareDate,
    ].join(" ");
  }

  if (valueFrom) {
    return [
      "from",
      fillDateTemplate(valueFrom, unitFrom, rangeFrom),
      compareDate,
    ].join(" ");
  }

  if (valueTo) {
    return [
      "up to",
      fillDateTemplate(valueTo, unitTo, rangeTo),
      compareDate,
    ].join(" ");
  }
  return "";
}

function fillDateTemplate(value, unit, range) {
  const valueNum = parseInt(value, 10);
  const properUnit = valueNum > 1 ? `${unit}s` : unit;
  return `${valueNum} ${properUnit} ${range}`;
}

export default getDateStatement;

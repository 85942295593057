import _ from "underscore";
import getOverriddenIssue from "common/utils/issues/get_overridden_issue";

const getOverriddenIssueMemo = _.memoize(
  getOverriddenIssue,
  (issue, masterId, clientId, useMasterName, omitIssueKeys) =>
    `${issue.id}_${
      issue.last_edited
    }_${masterId}_${clientId}_${useMasterName}_${omitIssueKeys}`,
);

export default function calculateIssueOverrides(
  issue,
  issueset,
  useMasterName,
  preventMemo = false,
) {
  if (!issueset || Object.keys(issueset).length === 0) {
    return {
      ...issue,
      name: issue.true_name || issue.name,
    };
  }
  if (
    issueset.issues.find(issueId => issueId === issue.id) ||
    issue.is_archived
  ) {
    const overrides = preventMemo
      ? getOverriddenIssue(
          issue,
          issueset.master_id,
          issueset.remote_client_id,
          useMasterName,
          true,
        )
      : getOverriddenIssueMemo(
          issue,
          issueset.master_id,
          issueset.remote_client_id,
          useMasterName,
          true,
        );
    return {
      ...issue,
      ...overrides,
    };
  }
  return issue;
}

import _ from "underscore";
import parseXmlToJson from "./xml_to_json";

export default function getDocxJson(docxXmlFiles) {
  if (!docxXmlFiles) {
    return null;
  }
  return _.mapObject(
    _.pick(
      docxXmlFiles,
      "word/document.xml",
      "word/numbering.xml",
      "word/styles.xml",
    ),
    parseXmlToJson,
  );
}

import React from "react";

import Text from "./text";
import Tab from "./tab";

export default function Run({t, tab}) {
  return (
    <>
      {tab && <Tab {...tab} />}
      {t && <Text {...t} />}
    </>
  );
}

export function filterOutstanding(issue) {
  if (!issue.reason) {
    return [];
  }
  return issue.reason
    .map((reason, index) => ({...reason, index}))
    .filter(reason => reason.state === null);
}

export function filterAccepted(issue) {
  if (!issue.reason) {
    return [];
  }
  return issue.reason
    .map((reason, index) => ({...reason, index}))
    .filter(reason => reason.state === 1);
}

export function filterRejected(issue) {
  if (!issue.reason) {
    return [];
  }
  return issue.reason
    .map((reason, index) => ({...reason, index}))
    .filter(reason => reason.state === 0);
}

export function filterNotProblem(issue) {
  if (!issue.reason) {
    return [[]];
  }
  return [];
}

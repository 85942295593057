import React, {useState, useRef} from "react";

import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import CancelIcon from "@material-ui/icons/Cancel";
import * as colors from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/core/styles";

import ItemContainer from "../../helper_components/item_container";
import {Button} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    marginBottom: "8px",
    "& .MuiOutlinedInput-input": {
      padding: "9px 32px 9px 6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.grey[300],
    },
  },
  select: {backgroundColor: "#fff", padding: "0.75em"},
  selectValueContainer: {
    display: "flex",
    flexDirection: "column",
    fontSize: 13,
    fontWeight: 400,
    justifyContent: "space-between",
    marginLeft: 4,
    width: "100%",
  },
  selectValueLabel: {color: colors.grey[400]},
  selectValueText: {
    color: colors.grey[700],
  },
  selectItem: {textAlign: "right", display: "inherit"},
  chip: {margin: 2, maxWidth: 400},
});

function PrecedentLanguageFilters(props) {
  const {
    filters: selectedFilters,
    filterList,
    updateFilters: updateSelectedFilters,
    defaultFilterValues,
    isExpanded,
    triggerIsExpanded,
    onAddFilterOption,
  } = props;

  const inputRef = useRef(null);

  // WARN: We don't handle changes to filterList at runtime
  const [filterInputValue, setFilterInputValue] = useState(
    filterList.reduce((acc, filter) => ({...acc, [filter.type]: ""}), {}),
  );
  function updateFilterValue(filterName, newValue) {
    const newFilters = {
      ...selectedFilters,
      [filterName]: newValue,
    };
    updateSelectedFilters(newFilters);
  }

  function clearAll() {
    updateSelectedFilters(defaultFilterValues);
  }

  const saveAll = () => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const showAll = Object.values(selectedFilters).every(selectedFilter =>
    Array.isArray(selectedFilter)
      ? selectedFilter.length === 0
      : selectedFilter === "all",
  );

  const getSummary = () => (
    <div
      style={{
        width: "100%",
        padding: "1rem 0rem",
        paddingRight: showAll ? "0rem" : "1rem",
      }}
    >
      {showAll
        ? "Filter: Show All"
        : filterList.map((filter, i) => {
            const selectedFilter = selectedFilters[filter.type];
            const displayValue = Array.isArray(selectedFilter) ? (
              selectedFilter.length === 0 ? (
                "All"
              ) : (
                <div>
                  {filter.items
                    .filter(item =>
                      selectedFilter.some(type => item.type === type),
                    )
                    .map(({label, type}) => (
                      <div style={{textAlign: "right"}} key={type}>
                        {label}
                      </div>
                    ))}
                </div>
              )
            ) : (
              filter.items.find(item => item.type === selectedFilter).label
            );

            return (
              <React.Fragment key={filter.type}>
                {i > 0 && (
                  <hr
                    style={{
                      opacity: 0.1,
                      width: "75%",
                      margin: "0.5rem auto",
                    }}
                  />
                )}
                <div
                  key={filter.label}
                  style={{display: "flex", justifyContent: "space-between"}}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {filter.label}:{" "}
                  </span>
                  <span style={{color: "grey"}}>{displayValue}</span>
                </div>
              </React.Fragment>
            );
          })}
    </div>
  );

  const [isAnyInputFocused, setIsAnyInputFocused] = useState(false);

  const handleSingleDelete = optionToDelete => {
    const currentSelectedTypes = selectedFilters[optionToDelete.filterType];

    const newSelectedTypes = currentSelectedTypes.filter(
      type => type !== optionToDelete.type,
    );

    updateFilterValue(optionToDelete.filterType, newSelectedTypes);
  };

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        key={getFilterLabel(option)}
        label={getFilterLabel(option)}
        {...getTagProps({index})}
        onDelete={() => handleSingleDelete(option)}
        deleteIcon={<CancelIcon />}
        className={classes.chip}
      />
    ));

  const handleKeyDown = (event, params, filter) => {
    if (event.key === "Enter" && params.inputProps.value) {
      event.preventDefault();

      const inputValue = params.inputProps.value.trim();
      const inputValueLower = inputValue.toLowerCase();

      const exactMatchItem = filter.items.find(
        item => item.label.toLowerCase() === inputValueLower,
      );

      if (exactMatchItem) {
        updateFilterValue(filter.type, [
          ...selectedFilters[filter.type],
          exactMatchItem.type,
        ]);
        setFilterInputValue(prev => ({
          ...prev,
          [filter.type]: "",
        }));
        return;
      }
      const partialMatchExists = filter.items.some(item =>
        item.label.toLowerCase().includes(inputValueLower),
      );

      if (partialMatchExists) {
        onAddFilterOption(filter.type, inputValue);
        setFilterInputValue(prev => ({
          ...prev,
          [filter.type]: "",
        }));
      }
    }
  };

  const classes = useStyles();
  return (
    <ItemContainer
      title={
        isExpanded ? (
          <div style={{height: "3em", display: "flex", alignItems: "center"}}>
            Filters
          </div>
        ) : (
          getSummary()
        )
      }
      expandable={true}
      onExpandMenu={triggerIsExpanded}
      isMenuExpanded={isExpanded}
      headerStyles={{height: "100%"}}
      containerStyles={{
        padding: "0 0.6rem",
        border: `1px solid ${colors.grey[300]}`,
        backgroundColor: "#fcfcfc",
        marginTop: 12,
      }}
      titleStyles={{color: colors.grey[700]}}
      bodyStyles={{
        padding: "12px 0px",
      }}
    >
      {filterList.map((filter, filterIndex) => {
        const renderValues = values => (
          <span className={classes.selectValueContainer}>
            <span className={classes.selectValueLabel}>{filter.label}</span>
            {values.map(value => (
              <span key={value.type} className={classes.selectValueText}>
                {getFilterLabel(value)}
              </span>
            ))}
          </span>
        );
        return (
          <FormControl
            variant="outlined"
            key={filterIndex}
            style={{width: "100%"}}
          >
            {filter.allowMultipleSelection ? (
              <Autocomplete
                multiple
                value={filter.items
                  .filter(({type}) =>
                    selectedFilters[filter.type]?.some(
                      selection => selection === type,
                    ),
                  )
                  .map(item => ({...item, filterType: filter.type}))}
                getOptionSelected={(option, value) =>
                  value.type === option.type
                }
                onChange={(_, updatedValues) => {
                  updateFilterValue(
                    filter.type,
                    updatedValues.map(({type}) => type),
                  );
                  setFilterInputValue(prev => ({
                    ...prev,
                    [filter.type]: "", // Clear the input after making a selection
                  }));
                }}
                className={classes.root}
                style={{
                  padding: "9px 6px 9px 6px",
                  boxSizing: "border-box",
                  border: `1px solid ${colors.grey[300]}`,
                }}
                renderTags={renderTags}
                options={filter.items}
                renderOption={renderFilterItem}
                getOptionLabel={getFilterLabel}
                inputValue={filterInputValue[filter.type]}
                onInputChange={(_, value, reason) => {
                  // HACK: For some reason, the material-ui autocomplete
                  // component is designed to reset the input value when its
                  // value prop changes unfortunately, this means that
                  // referentially unstable arrays (such as the value we
                  // provide above) trigger a reset on every render, invariably
                  // undoing changes to the input value.
                  if (reason === "reset") {
                    return;
                  }
                  setFilterInputValue(prev => ({
                    ...prev,
                    [filter.type]: value,
                  }));
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    inputRef={inputRef}
                    placeholder={`Search ${filter.label}...`}
                    onFocus={() => setIsAnyInputFocused(true)}
                    onBlur={() => setIsAnyInputFocused(false)}
                    onKeyDown={e => handleKeyDown(e, params, filter)}
                  />
                )}
              />
            ) : (
              <Select
                value={selectedFilters[filter.type]}
                onChange={e => updateFilterValue(filter.type, e.target.value)}
                className={classes.root}
                renderValue={value =>
                  renderValues(filter.items.filter(item => item.type === value))
                }
              >
                {filter.items.map((filterItem, filterItemIndex) => (
                  <MenuItem
                    key={filterItemIndex}
                    value={filterItem.type}
                    className={classes.selectItem}
                  >
                    {renderFilterItem(filterItem)}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        );
      })}
      <div
        style={{
          fontSize: 13,
          fontWeight: 500,
          marginTop: 6,
          display: "flex",
          justifyContent: "space-between",
          height: "1.2rem",
        }}
      >
        <Button size="small" color="primary" onClick={clearAll}>
          Clear All
        </Button>
        <Button
          size="small"
          color="primary"
          disabled={!isAnyInputFocused}
          onClick={saveAll}
        >
          Save All
        </Button>
      </div>
    </ItemContainer>
  );
}

function renderFilterItem(filterItem) {
  return (
    <span style={{opacity: filterItem.resultLength === 0 ? 0.5 : 1}}>
      {getFilterLabel(filterItem)}
    </span>
  );
}
function getFilterLabel(filterItem) {
  return filterItem.resultLength !== undefined
    ? `${filterItem.label} (${String(filterItem.resultLength)})`
    : filterItem.label;
}

export default PrecedentLanguageFilters;

import * as colors from "@material-ui/core/colors";

export default function matchIssue(node, props, clausepartIssues = []) {
  const {
    panelData,
    clauseToHighlight,
    hoveredClausepartChildrenIds,
    addClausesToReportDocumentIssueId,
    isHovered,
    isNumberItemsHovered,
    documentLedReview,
  } = props;

  // when adding new clauses to report and hover over the clause:
  if (addClausesToReportDocumentIssueId && isHovered) {
    return {backgroundColor: colors.blue[200], cursor: "pointer"};
  }
  if (isNumberItemsHovered && isNumberItemsHovered.includes(node.id)) {
    return {backgroundColor: colors.blue[200]};
  }

  // when in edit mode and hovering over clause deletion icon
  // cadidate deletion text is highlighted
  if (
    !documentLedReview &&
    hoveredClausepartChildrenIds &&
    hoveredClausepartChildrenIds.includes(node.id)
  ) {
    return {backgroundColor: "#c4c4c4"};
  }

  // highlight clause in blue when issue is selected and this
  // clause is referenced in the selected issue
  if (clauseToHighlight && node.id === clauseToHighlight.id) {
    return {backgroundColor: colors.blue[50]};
  }

  // highlight clause when clause_detail is shown
  if (
    props.currentClause &&
    props.currentClause.clause.atomIds.includes(node.id)
  ) {
    return {backgroundColor: "#ebfaff"};
  }

  if (clausepartIssues.length > 0) {
    const selectedIssueId =
      panelData && panelData.type === "issue_detail"
        ? panelData.data.issue.id
        : null;
    const isCurrentIssueSelected = clausepartIssues.find(
      issue => issue.id === selectedIssueId,
    );

    return isCurrentIssueSelected
      ? {
          backgroundColor: "#f3f3f3",
          mixBlendMode: "multiply",
          border: "solid 1px rgba(211, 211, 211, 0.1)",
        }
      : {
          /* backgroundColor: "#e7e7e7" */
        };
  }

  return {};
}

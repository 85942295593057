import React from "react";

import MatchSection from "../../match_section";
import findMatches from "./find_matches";

import getClauseAtomsByTopicGroup from "utils/clauses/related_clauses/get_clause_atoms_data_by_topic_groups";
import groupClausesByTopicsAndTopicGroups from "routes/document_detail/utils/related_clauses/group_related_clauses_by_topics_and_topic_groups";
import getAcceptedTopicCategoryIds from "utils/topic/get_accepted_topic_category_ids";

import renderRelatedClauses from "routes/document_detail/utils/related_clauses/render_related_clauses";

export default class TopicGroups extends React.Component {
  render() {
    return (
      <MatchSection
        items={this.props.items}
        disableMatchAll={false}
        sectionTitle="Topic Groups"
        onSearch={this.onTopicGroupSearch}
        resultRenderer={this.resultRenderer}
        isLast={this.props.isLast}
        appType={this.props.appType}
      />
    );
  }
  onTopicGroupSearch = topicGroup => {
    return groupClausesByTopicsAndTopicGroups(
      getClauseAtomsByTopicGroup(
        [topicGroup.id],
        this.props.documentClauses,
        this.props.topicsById,
        getAcceptedTopicCategoryIds(this.props.topicCategories),
      ).data,
    );
  };
  resultRenderer = result =>
    renderRelatedClauses(
      result,
      this.props.onClauseClick,
      this.props.topicsById,
      true,
      this.props.document,
    );
}

TopicGroups.findMatches = props =>
  findMatches(props.searchValue, props.documentTopics);

import React from "react";
import Paper from "material-ui/Paper";
import TopicsItem from "./topics_item";

import constructTopicsData from "../../document_views/clause_heading/utils/construct_heading_topics_data";

const styles = {
  mainContainer: {
    paddingLeft: "0.2rem",
  },
  topics: {
    padding: "0.2rem",
    fontWeight: 100,
    fontSize: "12px",
  },
  topicContainerStyles: {
    justifyContent: "center",
    width: "100%",
  },
  ultimateTopicGroupStyles: {
    width: "65%",
  },
  topicGroupStyles: {
    width: "100%",
    margin: "0.25em 0",
  },
};

function Topics(props) {
  const filteredTopics = props.topics.filter(topic =>
    props.acceptedTopicCategoryIds.includes(topic.topic.topiccategory_id),
  );
  const topicsRawData = constructTopicsData(
    filteredTopics,
    props.selectedTopics,
  );
  return (
    <div className="topics" style={styles.mainContainer}>
      <Paper style={props.headingStyles}>Topics</Paper>
      <div style={styles.topics}>
        {topicsRawData.map((item, i) => (
          <TopicsItem
            key={i}
            item={item}
            documentClauses={props.documentClauses}
            scrollToClause={props.scrollToClause}
          />
        ))}
      </div>
    </div>
  );
}

export default Topics;

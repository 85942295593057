function makeIssueReasonValue(path, baseValue) {
  return {
    path,
    values: [
      {
        label: "value",
        path: `${path}.match_text`,
        value: baseValue.match_text,
      },
    ],
  };
}

export default makeIssueReasonValue;

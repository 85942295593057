import React from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

const styles = {
  mainContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#9e9e9e",
  },
};

function RequestErrorPanel(props) {
  return (
    <div style={styles.mainContainer}>
      <ErrorOutlineIcon style={{width: 48, height: 48}} />
      <div style={{marginTop: 6}}>Network request failed</div>
      <div
        onClick={props.fetchData}
        style={{
          color: "#2196f3",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Click to Reload
      </div>
    </div>
  );
}

export default RequestErrorPanel;

import Describer from "../../../utils/issues/issue_description/describer";

function render(issue, topics) {
  const {rules} = issue;
  const describerA = new Describer.IssueDescriber(
    {...issue, rules: {...rules.topic_a, match_logic: rules.match_logic}},
    topics,
  );
  const describerB = new Describer.IssueDescriber(
    {...issue, rules: {...rules.topic_b, match_logic: rules.match_logic}},
    topics,
  );
  return [
    `Parameter ${describerA.parameterName}`,
    `of topic ${describerA.topicName}`,
    `${describerA.matchLogic}`,
    `parameter ${describerB.parameterName}`,
    `of topic ${describerB.topicName}.`,
  ].join(" ");
}

export default render;

import _ from "underscore";

export default function groupDocuments(documents) {
  return Object.values(
    _.groupBy(
      _.sortBy(documents, doc => -1 * new Date(doc.creation_date)),
      "group_id",
    ),
  ).map(docArray => {
    const allDocs = docArray.map(doc => ({
      ...doc,
      ...(docArray.length > 1
        ? {revisions: docArray.filter(docArrayDoc => docArrayDoc.id !== doc.id)}
        : {}),
    }));
    const firstDoc = allDocs.shift();
    if (allDocs.length > 0) {
      firstDoc.revisions = allDocs;
      firstDoc.isGroupParent = true;
    }
    return firstDoc;
  });
}

import UNINITIALISED from "utils/uninitialised";
import ActionTypes from "../constants/action_types";
import ProjectActionTypes from "../../projects/constants/action_types";
import {Accesstag, FetchAccesstagsAction} from "../actions/fetch";
import {CreateAccesstagAction} from "modules/projects/actions/project_create_accesstag";
import {MaybeUninitialised} from "modules/redux_types";

type Action = FetchAccesstagsAction | CreateAccesstagAction;

export default function accesstagsReducer(
  state: MaybeUninitialised<Accesstag[]> = UNINITIALISED,
  action: Action,
) {
  switch (action.type) {
    case ActionTypes.ACCESSTAGS_FETCH.SUCCESS:
      return action.payload;
    // When an accesstag is created for a PROJECT, its implied also to be added
    // to the broader set of "available" accesstags on the whole ORGANISATION.
    case ProjectActionTypes.PROJECT_CREATE_ACCESS_TAG.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.concat([action.payload]);
    }
    default:
      return state;
  }
}

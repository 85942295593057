import React from "react";

import IconButton from "material-ui/IconButton";
import DoneIcon from "material-ui/svg-icons/action/done";
import CancelIcon from "material-ui/svg-icons/content/clear";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import EditIcon from "material-ui/svg-icons/image/edit";
import RevertIcon from "material-ui/svg-icons/av/replay";
import TextField from "material-ui/TextField";
import * as colors from "material-ui/styles/colors";

import TopicChip from "common_components/topic_chip";

import getIssueNames from "utils/issues/get_issue_names";
import formatTemplate from "utils/templates/format_template";
import getEmailTextTemplate from "utils/templates/get_email_text_template";

import commonStyles from "./common_styles";
const styles = {
  iconButtonMain: commonStyles.iconButtonMain,
  iconStyle: commonStyles.iconStyle,
  iconHoverColor: commonStyles.iconHoverColor,
  actionIconContainer: commonStyles.actionIconContainer,
  textAreaStyle: commonStyles.textAreaStyle,
  textFieldMain: {
    width: "100%",
    height: "auto",
    cursor: "pointer",
  },
  issueChipStyles: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "10px",
    backgroundColor: colors.red200,
    textAlign: "center",
    visibility: "hidden",
    width: "4rem",
  },
  emailTextContainer: {
    textAlign: "justify",
    verticalAlign: "top",
  },
};

function getEmailText(issue, props) {
  const emailTextTemplateString = getEmailTextTemplate(issue, props);
  return issue.user_text || emailTextTemplateString;
}

class IssueItem extends React.Component {
  constructor(props) {
    super(props);
    const text = getEmailText(props.issue, props);
    this.state = {
      areActionElementsVisible: false,
      emailText: text,
    };
  }
  componentDidUpdate(prevProps) {
    if (
      getEmailText(this.props.issue, this.props) !==
      getEmailText(prevProps.issue, prevProps)
    ) {
      this.setState(() => ({
        emailText: getEmailText(this.props.issue, this.props),
      }));
    }
  }

  render() {
    const {issue, index, inList, selectedItemId, editedItemId} = this.props;
    const {areActionElementsVisible} = this.state;
    const isIssueSelected = selectedItemId === issue.id;
    const isEmailEditActive = editedItemId === issue.id;

    const areEditRevertButtonsShown =
      (areActionElementsVisible || isIssueSelected) && !isEmailEditActive;
    return (
      <tr
        key={index}
        onMouseOver={this.showActionElements}
        onMouseLeave={this.hideActionElements}
        style={{
          backgroundColor: isIssueSelected ? colors.grey200 : "inherit",
        }}
      >
        <td style={{verticalAlign: "top", width: "4.2rem"}}>
          <TopicChip
            text={getIssueNames(issue).name}
            chipStyles={{
              ...styles.issueChipStyles,
              visibility:
                areActionElementsVisible || isIssueSelected
                  ? "visible"
                  : "hidden",
            }}
          />
        </td>

        {this.props.areReportItemsNumbered && (
          <td
            style={{
              verticalAlign: "top",
              width: "4%",
              paddingTop: "2px",
              fontSize: "15px",
            }}
          >
            {inList ? `${index + 1}.` : ""}
          </td>
        )}

        <td
          onDoubleClick={this.onEditClick}
          style={styles.emailTextContainer}
          onClick={this.onEmailTextClick}
        >
          {isEmailEditActive ? (
            <TextField
              className="email-input-textarea"
              name="emailText"
              ref={this.createEmailTextRef(issue.id)}
              style={{
                ...styles.textFieldMain,
                cursor: "text",
                height: "auto",
              }}
              textareaStyle={styles.textAreaStyle}
              underlineShow={false}
              multiLine
              value={this.state.emailText}
              onChange={this.onEmailTextChange}
              spellCheck="false"
              onFocus={this.moveCaretAtEnd}
            />
          ) : (
            <div style={{fontSize: "16px"}}>
              {formatTemplate(this.state.emailText)}
            </div>
          )}
        </td>

        <td
          style={{
            ...styles.actionIconContainer,
            paddingLeft: "8px",
          }}
        >
          <IconButton
            style={styles.iconButtonMain}
            iconStyle={styles.iconStyle}
            onClick={
              areEditRevertButtonsShown ? this.onEditClick : this.onEditSave
            }
          >
            {areEditRevertButtonsShown ? (
              <EditIcon hoverColor={styles.iconHoverColor} />
            ) : isEmailEditActive ? (
              <DoneIcon hoverColor={styles.iconHoverColor} />
            ) : null}
          </IconButton>
        </td>

        <td
          style={{
            ...styles.actionIconContainer,
            paddingLeft: "4px",
          }}
        >
          <IconButton
            style={styles.iconButtonMain}
            iconStyle={styles.iconStyle}
            onClick={
              areEditRevertButtonsShown
                ? this.onRevertEmailText
                : this.onEditCancel
            }
          >
            {areEditRevertButtonsShown ? (
              <RevertIcon hoverColor={styles.iconHoverColor} />
            ) : isEmailEditActive ? (
              <CancelIcon hoverColor={styles.iconHoverColor} />
            ) : null}
          </IconButton>
        </td>

        <td style={styles.actionIconContainer}>
          <IconButton
            style={{
              ...styles.iconButtonMain,
              visibility: areEditRevertButtonsShown ? "visible" : "hidden",
            }}
            iconStyle={styles.iconStyle}
            onClick={this.onRevertIssue}
          >
            <DeleteIcon hoverColor={styles.iconHoverColor} />
          </IconButton>
        </td>
      </tr>
    );
  }

  onEditClick = async e => {
    e.stopPropagation();
    if (this.props.editedItemId) {
      return;
    }
    const {issue} = this.props;
    const text = getEmailText(this.props.issue, this.props);
    await this.setState(() => ({emailText: text}));
    await this.props.startItemEdit(issue.id);
    this[`emailText-${issue.id}`].focus();
  };

  onEditSave = template => {
    const templateText = typeof template === "string" ? template : null;
    const {issue, updateIssueDetail} = this.props;
    const {emailText} = this.state;
    if (
      (!templateText && emailText === issue.user_text) ||
      (templateText && emailText === templateText)
    ) {
      this.props.finishItemEdit();
      return;
    }
    this.setState(
      () => ({emailText: templateText || emailText}),
      async () => {
        this.props.finishItemEdit();
        const updates = {
          user_text: templateText || emailText,
        };
        const issueWithNames = {
          ...issue,
          ...getIssueNames(issue),
        };
        const {value} = await this.props.updateDocumentIssue(
          issueWithNames,
          updates,
        );
        const issueUpdated = {
          ...issueWithNames,
          user_text: this.state.emailText,
          last_edited: value.last_edited,
        };
        if (updateIssueDetail) {
          updateIssueDetail(issueUpdated);
        }
      },
    );
  };

  onEditCancel = e => {
    e.stopPropagation();
    this.setState(
      () => ({
        emailText: getEmailText(this.props.issue, this.props),
      }),
      () => this.props.finishItemEdit(),
    );
  };

  onRevertEmailText = e => {
    e.stopPropagation();
    this.onEditSave(getEmailTextTemplate(this.props.issue, this.props));
  };

  showActionElements = () => {
    if (this.props.selectedItemId) {
      return;
    }
    this.setState(() => ({areActionElementsVisible: true}));
  };
  hideActionElements = () =>
    this.setState(() => ({areActionElementsVisible: false}));

  onEmailTextClick = e => {
    e.stopPropagation();
    this.props.selectItem(this.props.issue.id);
  };

  onEmailTextChange = e => {
    this.setState({emailText: e.target.value});
  };

  onRevertIssue = async () => {
    const {issue, reportSettings, updateIssueDetail} = this.props;
    const {value} = await this.props.revertIssueActionState(
      issue,
      reportSettings && reportSettings.id,
    );
    const issueUpdated = {
      ...issue,
      ...getIssueNames(issue),
      ...(value.updates || {}),
      last_edited: value.last_edited,
    };
    if (updateIssueDetail) {
      updateIssueDetail(issueUpdated);
    }
  };

  moveCaretAtEnd(e) {
    const t = e.target.value;
    e.target.value = "";
    e.target.value = t;
  }

  createEmailTextRef = issueId => node => (this[`emailText-${issueId}`] = node);
}

export default IssueItem;

import React from "react";

import FlatButton from "material-ui/FlatButton";
import AddIcon from "material-ui/svg-icons/av/playlist-add";
import * as colors from "material-ui/styles/colors";

import EditorItem from "./editor_item";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "24rem",
  },
  addItemButton: {
    margin: "0.2rem",
  },
};

class ValueEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {values: props.values};
  }

  render() {
    return (
      <div style={styles.mainContainer}>
        {this.state.values &&
          this.state.values.map((value, index) => (
            <EditorItem
              key={index}
              value={value}
              valueIndex={index}
              onUpdateValue={this.onUpdateValue}
              onDeleteValue={this.onDeleteValue}
              roles={this.props.roles}
            />
          ))}
        <FlatButton
          backgroundColor={colors.grey100}
          icon={<AddIcon color={colors.grey700} />}
          style={styles.addItemButton}
          onClick={this.onAddValue}
        />
      </div>
    );
  }

  onDeleteValue = index => () =>
    this.setState(prevState => {
      const {values} = prevState;
      values.splice(index, 1);
      return {values};
    });

  onAddValue = () => {
    if (this.props?.roles?.length) {
      const firstRole = this.props.roles[0];
      this.setState(prevState => ({
        values: [
          ...(prevState.values ? prevState.values : []),
          {
            role_id: firstRole.role_id,
            role_name: firstRole.role_name,
            value: firstRole.name || firstRole.term,
            party_name: firstRole.name,
            party_term: firstRole.term,
          },
        ],
      }));
    }
  };

  onUpdateValue = (updatedItem, index) =>
    this.setState(prevState => {
      const {values} = prevState;
      values.splice(index, 1, updatedItem);
      return {values};
    });
}

export default ValueEditor;

import React from "react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";

const Chevron = ({iconButtonRef, isMenuOpen, styles, onClick}) => {
  return (
    <IconButton
      ref={iconButtonRef}
      size="small"
      onClick={onClick ? onClick : null}
      style={styles}
    >
      {isMenuOpen
        ? <ExpandLessIcon />
        : <ExpandMoreIcon />
      }
    </IconButton>
  );
};

export default Chevron;

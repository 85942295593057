const _ = require("underscore");
const yauzl = require("yauzl");
const {DumpStream} = require("./dump_stream");

function readDocx(data, encoding) {
  return new Promise((resolve, reject) => {
    const fileContents = {};
    let filesCount = 0;
    let wasResolved = false;

    // setTimeout is added because of wrong events order inside inZData.
    // inZData.end fired more early then readStream.end for some files
    // for example, it's true for file word/_rels/document.xml.rels
    function execOnceUponCondition() {
      if (wasResolved) {
        return;
      }
      if (filesCount === _.keys(fileContents).length) {
        resolve(fileContents);
        wasResolved = true;
        return;
      }
      setTimeout(execOnceUponCondition, 100);
    }

    yauzl.fromBuffer(data, {lazyEntries: true}, (err, inZData) => {
      if (err) {
        reject(err);
      } else {
        inZData.readEntry();
        inZData.on("entry", entry => {
          if (/\/$/.test(entry.fileName)) {
            inZData.readEntry();
          } else {
            filesCount += 1;
            inZData.openReadStream(entry, (errs, readStream) => {
              if (errs) {
                reject(errs);
              }
              const dumpStream = new DumpStream();
              readStream.pipe(dumpStream);
              readStream.on("end", () => {
                fileContents[entry.fileName] = dumpStream
                  .getData()
                  .toString(encoding || "utf8");
              });
              inZData.readEntry();
            });
          }
        });

        inZData.on("end", execOnceUponCondition);
      }
    });
  });
}

function readDocumentXml(data) {
  return readDocx(data).then(
    contents =>
      new Promise((resolve, reject) => {
        if (contents["word/document.xml"]) {
          resolve(contents["word/document.xml"]);
        } else {
          reject(new Error("word/document.xml is absent"));
        }
      }),
  );
}

readDocx.readDocumentXml = readDocumentXml;

export default readDocx;

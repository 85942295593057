import {ActionTypes} from "modules/redux_types";
import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

const types = [
  "WORKFLOWS_FETCH",
  "WORKFLOWS_CLEAR",
  "WORKFLOW_FETCH",
  "WORKFLOW_UPDATE",
  "WORKFLOW_DUPLICATE",
  "WORKFLOW_REMOVE",
  "WORKFLOW_ADD",
  "WORKFLOW_CLEAR",
  "WORKFLOW_INPUT_ADD",
  "WORKFLOW_INPUT_UPDATE",
  "WORKFLOW_INPUT_REMOVE",
  "WORKFLOW_INPUT_REORDER",
  "WORKFLOW_TASK_ADD",
  "WORKFLOW_TASK_UPDATE",
  "WORKFLOW_TASK_REMOVE",
  "WORKFLOW_TASK_REORDER",
  "WORKFLOW_PREREQUISITE_ADD",
  "WORKFLOW_PREREQUISITE_FETCH",
  "WORKFLOW_PREREQUISITE_REMOVE",
  "WORKFLOW_PREREQUISITE_UPDATE_INPUT_VALUE",
  "WORKFLOW_INSTANCE_UPDATE_NAME",
  "LLM_RUNS_FETCH",
  "LLM_RUNS_CLEAR",
  "LLM_RUN_FETCH",
  "LLM_RUN_CLEAR",
  "ACTION_DEFINITION_FETCH",
  "CONTEXTS_FETCH",
  "FLUSH_ASSISTANTS",
] as const;

types.forEach(type => createReduxPromiseActionType(actionTypes, type));

export type ActionType = typeof types[number];

export type WorkflowsActionTypes = ActionTypes<ActionType>;

export default actionTypes as WorkflowsActionTypes;

import _ from "underscore";
import {get, set} from "lodash";
import byId from "common/utils/by_id";
import globalStore from "common/utils/global_store";
import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import isEmptyParent from "common/utils/issues/is_empty_parent";
import getEmptyParentApplicableIssues from "common/utils/issues/get_empty_parent_applicable_issues";

export function registerCommonDefinitions(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  parties,
  preventMemo,
) {
  const commonDefinitions = get(globalStore, `commonDefinitions.${issue.id}`);
  if (!commonDefinitions) {
    set(globalStore, "setting_common_definitions", true);
    getIssueTemplatedText(
      issue,
      issueset,
      "common_definitions",
      documentClauses,
      topicsById,
      positiveReasonData,
      documentClauseparts,
      parties,
      false,
      false,
      preventMemo,
    );
    set(globalStore, "setting_common_definitions", false);
  }
}

export function getCommonDefinitionApplicableClauseGroups(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  preventMemo,
) {
  if (isEmptyParent(issue)) {
    const subissues = getEmptyParentApplicableIssues(issue);
    const result = {};
    subissues.forEach(subissue => {
      registerCommonDefinitions(
        subissue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        documentClauseparts,
        preventMemo,
      );
      const groups = getCommonDefinitionApplicableClauseGroupsForSingleIssue(
        subissue.id,
      );
      Object.keys(groups ?? {}).forEach(groupName => {
        if (!result[groupName]) {
          result[groupName] = groups[groupName];
        } else {
          result[groupName] = _.unique(result[groupName].concat(groups[groupName]));
        }
      });
    });
    return result;
  }
  registerCommonDefinitions(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    preventMemo,
  );
  return getCommonDefinitionApplicableClauseGroupsForSingleIssue(issue.id);
}
function getCommonDefinitionApplicableClauseGroupsForSingleIssue(issueId) {
  const commonDefinitions = get(globalStore, `commonDefinitions.${issueId}`);
  if (commonDefinitions) {
    const result = {};
    let resultHasValues = false;
    Object.keys(commonDefinitions).forEach(key => {
      const commonDefinitionValue = commonDefinitions[key];
      if (key.startsWith("group_") && Array.isArray(commonDefinitionValue)) {
        resultHasValues = true;
        const groupName = _.rest(key.split("_")).join(" ");
        result[groupName] = commonDefinitionValue;
      }
    });

    if (resultHasValues) {
      return result;
    }
  }
}

export function groupByCommonDefinitions(clauseparts, commonDefinitions) {
  if (!commonDefinitions || !clauseparts) {
    return undefined;
  }
  const clausepartsById = byId(clauseparts);

  const result = {};
  Object.keys(commonDefinitions).forEach(groupName => {
    const ids = commonDefinitions[groupName];
    if (Array.isArray(ids)) {
      const newGroup = ids.reduce((accum, id) => {
        const idNum = parseInt(id, 10);
        if (isNaN(idNum) || typeof idNum !== "number") {
          return accum;
        }
        const existingClausepart = clausepartsById[id];
        if (existingClausepart) {
          accum.push(existingClausepart);
        }
        return accum;
      }, []);
      if (newGroup.length > 0) {
        result[groupName] = newGroup;
      }
    }
  });
  return result;
}

export function getCommonDefinitionApplicableClauses(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  parties,
) {
  if (isEmptyParent(issue)) {
    return calculateEmptyParentApplicableClauses(
      issue,
      issueset,
      documentClauses,
      topicsById,
      positiveReasonData,
      documentClauseparts,
      parties,
    );
  }
  return calculateSingleIssueApplicableClause(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    parties,
  );
}

function calculateEmptyParentApplicableClauses(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  parties,
) {
  return _.chain(getEmptyParentApplicableIssues(issue))
    .map(innerIssue =>
      calculateSingleIssueApplicableClause(
        innerIssue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        documentClauseparts,
        parties,
      ),
    )
    .flatten()
    .unique(clausepart => clausepart.id)
    .value();
}

function calculateSingleIssueApplicableClause(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  parties,
) {
  const commonDefinitionApplicableClauseGroups = getCommonDefinitionApplicableClauseGroups(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    parties,
  );
  const applicableClausesGroupedByCommonDefinitions = groupByCommonDefinitions(
    documentClauseparts,
    commonDefinitionApplicableClauseGroups,
  );
  const clauses = _.chain(
    Object.values(applicableClausesGroupedByCommonDefinitions ?? {}),
  )
    .flatten()
    .uniq(item => item.id)
    .map(clause => ({
      ...clause,
      order: clause.clause_order,
      is_next_conjunction: isNextConjunction(clause, documentClauses),
    }))
    .sortBy(
      clause =>
        `${clause.section_id} ${(clause.order || "")
          .toString()
          .padStart(4, "0")}`,
    )
    .value();
  return clauses;
}

function isNextConjunction(atom, documentClauses) {
  const clauses = documentClauses[atom.section_id];
  const clause =
    clauses && clauses.find(_clause => _clause.id === atom.clause_id);
  const nextNode =
    clause && findNodeByOrder(clause.nodes, atom.clause_order + 1);
  return nextNode ? nextNode.is_conjunction : false;
}

function findNodeByOrder(node, order) {
  if (!node) {
    return null;
  }
  if (Array.isArray(node.clauseNodes)) {
    for (let idx = 0; idx < node.clauseNodes.length; idx += 1) {
      const childNode = node.clauseNodes[idx];
      const match = findNodeByOrder(childNode, order);
      if (match) {
        return match;
      }
      if (childNode.order > order) {
        return null;
      }
    }
  }
  if (node.order === order) {
    return node;
  }
  return null;
}

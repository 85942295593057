import getDefaultIssuesetId from "utils/issuesets/get_default_issueset_id";

export default function getPredefinedIssuesetId(
  document,
  project,
  contractTypesById,
  applicableIssuesetIds,
) {
  function isApplicable(id) {
    return applicableIssuesetIds.find(_id => _id === id);
  }

  const {default_issueset_id: defaultIssuesetId} = project;
  if (defaultIssuesetId && isApplicable(defaultIssuesetId)) {
    return defaultIssuesetId;
  }

  return getDefaultIssuesetId(
    document,
    project,
    contractTypesById,
    applicableIssuesetIds,
  );
}

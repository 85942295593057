import React from "react";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";

const styles = {
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    height: "100%",
    width: "170px",
  },
  buttons: {
    maxHeight: "30px",
  },
};

const SaveCancelButtons = ({isShow, onSave, onCancel, reportLabel}) => {
  return (
    <div style={styles.main}>
      {isShow ? (
        <>
          <Button
            variant="contained"
            color="primary"
            size="small"
            title="Save changes"
            style={styles.buttons}
            startIcon={<SaveIcon />}
            onMouseDown={onSave}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            title="Cancel editing"
            style={styles.buttons}
            startIcon={<CancelIcon />}
            onMouseDown={onCancel}
          >
            Cancel
          </Button>
        </>
      ) : reportLabel ?
        reportLabel :
        null}
    </div>
  );
};

export default SaveCancelButtons;

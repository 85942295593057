const levels = {
  debug: 10,
  verbose: 8,
  info: 6,
  log: 5,
  warn: 3,
  error: 2,
  critical: 1,
};
/* eslint-disable no-console */
const boundConsoles = {
  error: console.error.bind(console),
  warn: console.warn.bind(console),
  log: console.log.bind(console),
  debug: (console.debug || console.error).bind(console),
};
/* eslint-enable no-console */

function noOp() {}

class Logger {
  constructor(level) {
    this.level = level;
    this.boundConsoles = boundConsoles;
  }

  set level(level) {
    this._level =
      levels[level] || (typeof level === "number" ? level : levels.log);
  }

  get level() {
    return this._level;
  }

  set boundConsoles(_boundConsoles) {
    this._boundConsoles = _boundConsoles;
  }

  get boundConsoles() {
    return this._boundConsoles;
  }

  logAtLevel(level) {
    return this.getMethod(level);
  }

  /* eslint-disable complexity */
  getMethod(level) {
    /* eslint-enable complexity */
    const numericLevel =
      typeof level === "string" ? this.getNumericLevel(level) : level;
    if (numericLevel > this.level) {
      return noOp;
    }
    switch (numericLevel) {
      case 1:
      case 2:
        return this.boundConsoles.error;
      case 3:
      case 4:
        return this.boundConsoles.warn;
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        return this.boundConsoles.log;
      case 10:
        return this.boundConsoles.debug;
      default:
        return this.boundConsoles.log;
    }
  }

  getNumericLevel(level) {
    const numericLevel = levels[level];
    return numericLevel ? numericLevel : 5;
  }

  get debug() {
    return this.logAtLevel("debug");
  }

  get verbose() {
    return this.logAtLevel("verbose");
  }

  get info() {
    return this.logAtLevel("info");
  }

  get log() {
    return this.logAtLevel("log");
  }

  get warn() {
    return this.logAtLevel("warn");
  }

  get error() {
    return this.logAtLevel("error");
  }

  get critical() {
    return this.logAtLevel("critical");
  }
}

const instance = new Logger("log");

export default instance;

/* eslint-disable no-unused-vars */
import React from "react";
import _ from "underscore";
/* eslint-enable no-unused-vars */

import ClosedText from "../closed/text";
import OpenText from "../open/graph_node";
import {isIe11} from "utils/browser_test";
import escapeRegex from "utils/escape_regex";
import formatText from "utils/text/format_text";

import TextField from "material-ui/TextField";
import VerticalClausepartControl from "../../common_components/vertical_clausepart_control";
import GuiTextEditor from "common_components/gui_text_editor";
import * as colors from "material-ui/styles/colors";

import clauseStyles from "constants/text_changes_styles";
import calculateNumber from "../../../../utils/calculate_number";

const blueIconColor = colors.lightBlue600;
const styles = {
  term: {
    marginBottom: "0.25em",
    position: "relative",
    display: "flex",
  },
  termRef: {
    display: "inline-block",
    width: "2em",
  },
  termText: {
    whiteSpace: "nowrap",
    fontWeight: "400",
    paddingRight: "0.5em",
    verticalAlign: "top",
    marginBottom: "0.5em",
    color: "#555",
  },
  termAdded: {
    fontWeight: 500,
    color: blueIconColor,
  },
  meaning: {
    display: "inline-block",
    paddingBottom: "1em",
    ...(isIe11() ? {width: "100%"} : {minWidth: "calc(100% - 2em)"}),
  },
  meaningPadding: {
    marginLeft: "2em",
  },
};
export default class Definition extends React.Component {
  state = {
    deleteButtonHovered: false,
    hovered: false,
    newDefinitionTerm: "",
    newDefinitionMeaning: "",
  };

  render() {
    const {definitionNode, reference, index, changes, parentNode} = this.props;
    if (!definitionNode.clauseNodes) {
      // will not have these if it isn't parsed as a definition section
      return null;
    }
    const [termNode, meaningNode] = definitionNode.clauseNodes;
    const {partial_text: term} = termNode;
    const isDeleted = this.isDeleted();
    const otherChanges = changes.filter(
      change => change.type !== "definition_deletion",
    );
    const termRef = getTermReference(
      parentNode,
      index,
      reference,
      termNode.reference,
      term,
    );
    const isAdded = this.isAdded();
    return (
      <div
        className="definition clause-id"
        id={`clause-atom-${termNode.id}`}
        key={`${term}-${index}`}
        style={{
          ...styles.definition,
          ...(isAdded && clauseStyles.textChanges.text_addition),
        }}
        clauseid={termNode.id}
      >
        {index === 0 && this.renderAddDefinitionForm(-1, meaningNode.level)}
        {this.renderTerm(term, termRef, isDeleted, isAdded)}
        <div
          className="meaning"
          style={{...styles.meaning, ...(termRef && styles.meaningPadding)}}
        >
          {this.props.isOpen ? (
            <OpenText
              {...this.props}
              node={meaningNode}
              reference={reference}
              isDefinition={true}
              onAddDefinitionBeforeCurrent={this.addDefinitionAbove}
              onAddDefinitionAfterCurrent={this.addDefinitionBelow}
              isDefinitionDeletion={isDeleted}
              changes={
                isDeleted ? otherChanges.concat([isDeleted]) : otherChanges
              }
              showReference={false}
            />
          ) : (
            <ClosedText
              {...this.props}
              node={meaningNode}
              reference={termNode.reference || reference}
            />
          )}
        </div>
        {this.renderAddDefinitionForm(null, meaningNode.level)}
      </div>
    );
  }
  renderTerm(term, termRef, isDeleted, isAdded) {
    const {definitionNode, hoveredClausepartChildrenIds} = this.props;
    const [termNode] = definitionNode.clauseNodes;
    const isClauseInHoveredClauseparts =
      hoveredClausepartChildrenIds &&
      hoveredClausepartChildrenIds.includes(termNode.id);
    const changes = this.getTermChanges();
    const formattedText = formatText(
      this.props.document.file_extension,
      term || "",
      {changes, documentDefinitions: []},
      this.props.findAndReplace.find,
    );
    return (
      <div
        className="term"
        style={{
          ...styles.term,
          ...(this.state.deleteButtonHovered
            ? {
                transition: "background 1s",
                background: "#ffa6ca",
              }
            : {}),
          ...(isClauseInHoveredClauseparts && {background: "#ffa6ca"}),
        }}
        onMouseEnter={this.onDefinitionHover}
        onMouseLeave={this.onDefinitionHoverEnd}
      >
        {this.props.editModeOn && this.renderCommandPanel(isAdded || isDeleted)}
        {termRef && (
          <span
            style={{
              ...styles.termRef,
              ...(isDeleted && clauseStyles.textChanges.text_deletion),
            }}
          >
            {termRef}
          </span>
        )}
        <span
          style={{
            ...styles.termText,
            ...(isAdded && styles.termAdded),
            ...(isDeleted && clauseStyles.textChanges.text_deletion),
          }}
        >
          {formattedText}
        </span>
      </div>
    );
  }
  renderCommandPanel(shouldRevert) {
    if (!this.state.hovered) {
      return null;
    }
    return (
      <VerticalClausepartControl
        itemName="definition"
        shouldRevert={shouldRevert}
        showAddButtons={true}
        onTopButtonClick={
          shouldRevert ? this.revertDefinition : this.deleteDefinition
        }
        onTopButtonHover={shouldRevert ? undefined : this.onDeleteButtonHover}
        onTopButtonHoverFinish={
          shouldRevert ? undefined : this.onDeleteButtonHoverEnd
        }
        onAddAboveClick={this.addDefinitionAbove}
        onAddBelowClick={this.addDefinitionBelow}
        mainContainerStyles={{
          position: "absolute",
          left: "-30px",
          top: "0px",
          width: "50px",
          height: "70px",
        }}
      />
    );
  }
  getTermChanges() {
    const {documentClause, definitionNode, changes} = this.props;
    if (!definitionNode.clauseNodes) {
      return [];
    }
    const [termNode] = definitionNode.clauseNodes;
    const {partial_text: term} = termNode;
    return changes.filter(
      change =>
        (change.type === "clausepart_deletion" &&
          change.new_clausepart_id === termNode.id) ||
        (change.type === "definition_deletion" &&
          change.new_clause_id === documentClause.id &&
          change.new_reference === term) ||
        (change.type === "clause_deletion" &&
          change.new_clause_id === documentClause.id),
    );
  }
  isDeleted() {
    const {documentClause, definitionNode, changes} = this.props;
    if (!definitionNode.clauseNodes) {
      return false;
    }
    const [termNode] = definitionNode.clauseNodes;
    const {partial_text: term} = termNode;

    return changes.find(
      change =>
        change.new_clause_id === documentClause.id &&
        change.type === "definition_deletion" &&
        change.new_reference === term,
    );
  }
  isAdded() {
    const {definitionNode, documentChanges} = this.props;
    if (!definitionNode.clauseNodes) {
      return false;
    }
    const [termNode] = definitionNode.clauseNodes;
    return documentChanges.find(
      change => change.new_clausepart_id === termNode.id,
    );
  }
  onDefinitionHover = () => {
    this.props.setLastHoveredClausepartId(null);
    this.setState({hovered: true});
  };
  onDefinitionHoverEnd = () => {
    this.setState({hovered: false});
  };
  onDeleteButtonHover = () => {
    this.setState({deleteButtonHovered: true});
    this.props.setHoveredClausepartChildrenIds(this.getMeaningClausepartIds());
  };
  onDeleteButtonHoverEnd = () => {
    this.setState({deleteButtonHovered: false});
    this.props.setHoveredClausepartChildrenIds([]);
  };
  getMeaningClausepartIds() {
    const meaning = this.props.definitionNode.clauseNodes[1];
    const ids = this.getClausepartIds(meaning);
    return ids;
  }
  getClausepartIds(node) {
    if (node.id) {
      return [node.id];
    } else if (node.clauseNodes) {
      return _.flatten(
        node.clauseNodes.map(child => this.getClausepartIds(child)),
      );
    }
    return [];
  }
  componentDidUpdate(prevProps) {
    if (this.isBeingAdded(prevProps) !== this.isBeingAdded()) {
      this.termEditorRef && this.termEditorRef.focus();
    }
  }
  renderAddDefinitionForm(offset, meaningNodeLevel) {
    if (this.isBeingAdded(this.props, offset)) {
      return (
        <div ref={this.createRootAddDefinitionFormRef}>
          <TextField
            hintText="Term"
            onChange={this.updateAddedDefintionTerm}
            ref={this.createTermEditorRef}
          />
          <GuiTextEditor
            closeHandler={this.updateAddedDefinitionMeaning}
            doNotFocus={true}
            usedListFormatStylesPerLevel={
              this.props.usedListFormatStylesPerLevel
            }
            startingLevel={meaningNodeLevel}
            getRootEditorRef={this.getRootEditorRef}
          />
        </div>
      );
    }
    return null;
  }
  isBeingAdded(props = this.props, offset) {
    const {addingDefinition} = props;
    return (
      addingDefinition &&
      addingDefinition.clauseId === props.documentClause.id &&
      addingDefinition.path === this.getPath(props, offset)
    );
  }
  updateAddedDefintionTerm = (e, text) => {
    this.setState({newDefinitionTerm: text});
  };
  updateAddedDefinitionMeaning = contents =>
    this.setState(
      () => ({newDefinitionMeaning: contents}),
      () => this.saveDefinition(),
    );
  addDefinitionAbove = () => {
    this.onAddDefinition(-1);
  };

  addDefinitionBelow = () => {
    this.onAddDefinition();
  };
  onAddDefinition = (offset = 0) => {
    this.props.hideClausepartEditor();
    this.props.setDefinitionBeingAdded(
      this.props.documentClause.id,
      this.getPath(this.props, offset),
    );
  };
  deleteDefinition = () => {
    this.onDeleteButtonHoverEnd();
    const term = this.props.definitionNode.clauseNodes[0].partial_text;
    this.props.deleteDefinition(this.props.documentClause, term);
  };
  saveDefinition = () => {
    const {newDefinitionTerm: term, newDefinitionMeaning: meaning} = this.state;
    if (term && term.length && meaning && meaning.length) {
      this.props.addDefinition(
        this.props.documentClause,
        this.props.addingDefinition.path,
        this.state.newDefinitionTerm,
        this.state.newDefinitionMeaning,
      );
    }
    this.props.unsetDefinitionBeingAdded();
  };
  revertDefinition = () => {
    const {documentClause} = this.props;
    const isDeleted = this.isDeleted();
    if (isDeleted) {
      this.props.revertDefinitionDeletion(documentClause, isDeleted);
    }
    const isAdded = this.isAdded();
    if (isAdded) {
      this.props.revertDefinitionAddition(documentClause, isAdded);
    }
  };
  getPath(props = this.props, offset = 0) {
    return `${props.path}[${props.index + offset}]`;
  }

  getRootEditorRef = () => this.rootAddDefinitionFormRef;
  createRootAddDefinitionFormRef = node =>
    (this.rootAddDefinitionFormRef = node);
  createTermEditorRef = node => (this.termEditorRef = node);
}

function getTermReference(parentNode, index, clauseRef, definitionRef, term) {
  if (parentNode.type === "ClausePartNumberedList") {
    return calculateNumber(parentNode.counterType, index);
  }
  const res = definitionRef
    ? definitionRef
        .replace(new RegExp(`^${escapeRegex(clauseRef)}\\.?`), "")
        .replace(new RegExp(escapeRegex(term)), "")
    : "";
  return res ? `${res}.` : "";
}

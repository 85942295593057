import React from "react";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import Switch from "@material-ui/core/Switch";

const classes = {
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  paper: {
    margin: "10px",
    padding: "10px",
  },
  spaceAround: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "baseline",
    fontSize: "14px",
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    fontSize: "14px",
  },
  spaceAroundLine: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    fontSize: "14px",
    marginTop: "0.5em",
    marginBottom: "0.5em",
  },
  grow: {
    width: "15em",
  },
  titleMain: {
    fontSize: "1rem",
    marginRight: "1em",
    textTransform: "uppercase",
  },
  title: {
    fontSize: "1rem",
  },
  select: {
    fontSize: "14px",
    color: "#555",
  },
  expando: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  textField: {
    width: "100%",
  },
};

export default class PlaybookEditor extends React.Component {
  constructor(...args) {
    super(...args);
    this.state = {
      subRuleValue: false,
    };
  }
  render() {
    const {issue, normalDisplay} = this.props;
    const issueName = issue.display_name || issue.name;
    const isAffiliate = issueName.match(/disclos/i);
    const isGoverningLaw = issueName.match(/governing/i);
    const isTerm = !(isAffiliate || isGoverningLaw);

    const settings = {isAffiliate, isTerm, isGoverningLaw};

    const renderOverlay = !normalDisplay;
    return (
      <div>
        {renderOverlay && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              left: 0,
              backgroundColor: "#cccc",
            }}
          />
        )}
        <div
          style={
            renderOverlay
              ? {
                  position: "fixed",
                  zIndex: 3,
                  backgroundColor: "white",
                  height: "100%",
                  width: "26em",
                  right: "26em",
                }
              : {}
          }
        >
          {this.renderHeader(this.props)}

          {this.renderPosition(settings)}

          {this.renderConditions(settings)}

          {this.renderDisplaySettings(settings)}

          {this.renderGuidance(settings)}

          {this.renderButtons(settings)}
        </div>
      </div>
    );
  }

  renderHeader(props) {
    return (
      <AppBar position="static" style={{backgroundColor: "#232e38"}}>
        <Toolbar>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>
              <Typography variant="h6" style={classes.titleMain}>
                {props.issue.groupName}
              </Typography>
              <Typography variant="h5" style={classes.title}>
                {props.issue.name}
              </Typography>
            </span>
            <span style={{flexShrink: 0}}>
              <Chip label="ON" />
              <IconButton onClick={props.clearPanelData}>
                <Close style={{color: "white"}} />
              </IconButton>
            </span>
          </div>
        </Toolbar>
      </AppBar>
    );
  }

  renderPosition({isAffiliate, isTerm, isGoverningLaw}) {
    return (
      <div style={classes.paper}>
        <Typography variant="h6" style={classes.title}>
          Position
        </Typography>
        {isAffiliate && this.renderAffiliatePosition()}
        {isGoverningLaw && this.renderGovLawPosition()}
        {isTerm && this.renderPositionTerm()}
      </div>
    );
  }

  renderAffiliatePosition() {
    return (
      <div style={classes.spaceBetween}>
        <FormControl style={classes.formControl}>
          <InputLabel id="state-label">State</InputLabel>
          <Select
            labelId="state-label"
            id="state"
            value={1}
            style={classes.select}
          >
            <MenuItem value={1}>Required</MenuItem>
            <MenuItem value={2}>Unacceptable</MenuItem>
          </Select>
        </FormControl>
        <span style={{fontSize: "12px"}}>
          Permitted Disclosure to GROUP MEMBERS
        </span>
      </div>
    );
  }

  renderGovLawPosition() {
    return (
      <div style={classes.spaceBetween}>
        <FormControl style={classes.formControl}>
          <InputLabel id="state-label">State</InputLabel>
          <Select
            labelId="state-label"
            id="state"
            value={1}
            style={classes.select}
          >
            <MenuItem value={1}>Required</MenuItem>
            <MenuItem value={2}>Unacceptable</MenuItem>
          </Select>
        </FormControl>
        <span style={{fontSize: "12px"}}>
          Governing Law is a RELIABLE Location
        </span>
      </div>
    );
  }

  renderPositionTerm() {
    return (
      <div style={classes.spaceBetween}>
        <FormControl style={classes.formControl}>
          <Select value={1} style={classes.select}>
            <MenuItem value={1}>Range is Acceptable</MenuItem>
          </Select>
        </FormControl>
        ≥
        <Select value={1} style={classes.select}>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Select>
        ≤
        <Select value={2} style={classes.select}>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </Select>
        Years
      </div>
    );
  }

  renderConditions({isAffiliate, isTerm, isGoverningLaw}) {
    const {subRuleValue} = this.state;
    return (
      <div style={classes.paper}>
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Typography variant="h6" style={classes.title}>
            {isAffiliate ? "Sub-Rules" : "Conditions"}
          </Typography>
          {isAffiliate && (
            <span>
              <Chip label={subRuleValue ? "ON" : "OFF"} />
              <Switch
                value={subRuleValue}
                color="primary"
                onChange={() => {
                  this.setState({subRuleValue: !subRuleValue});
                }}
              />
            </span>
          )}
        </div>

        {isAffiliate && this.renderAffiliateConditions()}
        {isGoverningLaw && this.renderGovLawConditions()}
        {isTerm && this.renderTermConditions()}
      </div>
    );
  }

  renderAffiliateConditions() {
    return (
      <React.Fragment>
        <div style={classes.spaceAroundLine}>
          <Select value={1} style={{...classes.select, marginRight: "6rem"}}>
            <MenuItem value={1}>REQUIRED</MenuItem>
            <MenuItem value={2}>NOT REQUIRED</MenuItem>
          </Select>
          <span style={classes.grow}>Definition of Affiliates</span>
        </div>
        <div style={classes.spaceAroundLine}>
          <span style={{...classes.grow, width: "11.5rem"}}>
            Acceptable Definition
          </span>
          <Select value={1} style={classes.select}>
            <MenuItem value={1}>Majority Control</MenuItem>
            <MenuItem value={2}>50% or more</MenuItem>
            <MenuItem value={3}>More than 50%</MenuItem>
          </Select>
        </div>
      </React.Fragment>
    );
  }

  renderGovLawConditions() {
    return (
      <React.Fragment>
        <div style={classes.spaceBetween}>
          <Select value={1} style={classes.select}>
            <MenuItem value={1}>Reliable Locations</MenuItem>
            <MenuItem value={2}>Unreliable Locations</MenuItem>
          </Select>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={true} value="newyork" />
                }
                label="New York"
              />
              <FormControlLabel
                control={
                  <Checkbox color="primary" checked={true} value="hongkong" />
                }
                label="Hong Kong"
              />
              <FormControlLabel
                control={<Checkbox color="primary" checked={true} value="uk" />}
                label="UK"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div style={classes.spaceAroundLine}>
          <span style={classes.grow}>Additional Locations</span>
          <FormControl className={classes.formControl}>
            <InputLabel id="Location-label">Additional Location</InputLabel>
            <Select
              multiple
              value={[]}
              style={{...classes.select, width: "12em"}}
              onChange={this.onLocationChange}
            >
              <MenuItem value={1}>Singapore</MenuItem>
              <MenuItem value={2}>Australia</MenuItem>
              <MenuItem value={2}>California</MenuItem>
            </Select>
          </FormControl>
        </div>
      </React.Fragment>
    );
  }

  onLocationChange() {}

  renderTermConditions() {
    return (
      <React.Fragment>
        <div style={classes.spaceAroundLine}>
          <span style={classes.grow}>Is a perpetual term acceptable?</span>
          <Select value={1} style={classes.select}>
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={2}>No</MenuItem>
          </Select>
        </div>

        <div style={classes.spaceAroundLine}>
          <span style={classes.grow}>Is a specified term required?</span>
          <Select value={1} style={classes.select}>
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={2}>No</MenuItem>
          </Select>
        </div>
      </React.Fragment>
    );
  }

  renderDisplaySettings() {
    return (
      <div style={classes.paper}>
        <Typography variant="h6" style={classes.title}>
          Display Settings
        </Typography>
        <div style={classes.spaceBetween}>
          <FormControl style={classes.formControl}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              id="state"
              value={1}
              style={classes.select}
            >
              <MenuItem value={1}>Required</MenuItem>
              <MenuItem value={2}>Unacceptable</MenuItem>
              <MenuItem value={3}>Guidance</MenuItem>
            </Select>
          </FormControl>

          <FormControl style={classes.formControl}>
            <InputLabel id="display-label">When to show</InputLabel>
            <Select
              labelId="display-label"
              id="display"
              value={1}
              style={classes.select}
            >
              <MenuItem value={1}>Always</MenuItem>
              <MenuItem value={2}>Position Met</MenuItem>
              <MenuItem value={3}>Position Not Met</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    );
  }

  renderGuidance() {
    const text =
      "Each party's obligations under this agreement shall continue in full force and effect for a period of 2 years from the date of this agreement.";
    return (
      <div style={classes.paper}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography style={classes.expando}>Guidance</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              variant="outlined"
              multiline
              value={text}
              style={classes.textField}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }

  renderButtons() {
    return (
      <div style={classes.paper}>
        <div style={classes.spaceAround}>
          <Button variant="contained" disabled>
            Save
          </Button>
          <Button variant="contained" disabled>
            Restore Default Settings
          </Button>
        </div>
      </div>
    );
  }
}

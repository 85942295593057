import React, {Component, useEffect, useState} from "react";
import _ from "underscore";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import ClausesPanel from "../clauses_panel";
import TextFieldWithStateValue from "./text_field_with_state_value";

import issueHeaderUtils from "utils/issues/issue_header_utils";
import issueTemplateUtils from "utils/issues/issue_template_utils";
import getEmailTextTemplate from "utils/templates/get_email_text_template";
import isEmptyParent from "common/utils/issues/is_empty_parent";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getDefaultColor from "utils/issues/get_default_color";
import {
  getIssueManualCorrections,
  getUpdatedManualCorrections,
} from "utils/manual_corrections_utils";

const styles = {
  icon: {
    cursor: "pointer",
    marginLeft: "4px",
    width: "18px",
    height: "18px",
  },
  input: {
    borderRadius: 0,
    minHeight: "50px",
    padding: "10px",
  },
  form: {
    width: "30%",
    margin: "8px 0px",
    marginLeft: "13px",
  },
  textField: {
    margin: "0.75em 0",
    width: "100%",
  },
  textFieldProp: {
    style: {
      fontSize: "13px",
      fontWeight: 100,
    },
  },
  button: {
    flexGrow: 1,
    borderRadius: 0,
    fontFamily: "Segoe UI, Roboto, sans-serif",
  },
  wrapper: {
    display: "flex",
    marginBottom: "0.75em",
  },
  clausesWrapper: {
    border: "1px solid #ccc",
    padding: "0 12px",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 6,
  },
  clausesContainer: {
    fontSize: "12px",
    display: "flex",
    color: "rgba(0, 0, 0, 0.54)",
    alignItems: "center",
  },
  clausesBlock: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    margin: "12px 0",
  },
};

function AlertColorSelect(props) {
  const {label, onSave, fieldValue} = props;
  const [value, onValueChange] = useState(fieldValue);
  useEffect(() => {
    onValueChange(fieldValue);
  }, [props.fieldValue]);

  function onSelectChange(e) {
    const newValue = e.target.value;
    onValueChange(newValue);
    onSave(newValue);
  }

  return (
    <FormControl style={styles.form}>
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={onSelectChange}>
        <MenuItem value={"red"}>Red</MenuItem>
        <MenuItem value={"amber"}>Amber</MenuItem>
        <MenuItem value={"green"}>Green</MenuItem>
        <MenuItem value={"grey"}>Grey</MenuItem>
      </Select>
    </FormControl>
  );
}

export default class RagReportPanel extends Component {
  constructor() {
    super();
    this.state = {
      isGuiEdited: false,
      isShowReportClauses: true,
    };
  }
  openTextInGuiEditor = () => this.setState({isGuiEdited: true});
  onShowReportClauses = () => {
    this.setState({
      isGuiEdited: false,
      isShowReportClauses: !this.state.isShowReportClauses,
    });
  };

  render() {
    const {
      reportSettings,
      issue,
      currentIssuesetItem,
      project,
      correctDocumentIssueManually,
    } = this.props;
    const issueReportActionState = (issue.action_state || {})[
      reportSettings.id
    ];
    const manualCorrections = getIssueManualCorrections(
      issue,
      currentIssuesetItem,
      reportSettings.id,
    );
    const {
      clause_text: correctedApplicableClauses,
      locked_value: lockedTextValue,
    } = manualCorrections;
    const getReasonText = showDetailedReason => {
      return (
        issueHeaderUtils.getReasonTextArray(
          issue,
          this.props.documentClauses,
          this.props.topicsById,
          this.props.positiveReasonData,
          this.props.reportSettings.id,
          this.props.currentIssuesetItem,
          this.props.documentClauseparts,
          this.props.document.parties,
          true,
          showDetailedReason,
          false, // shouldUseManualCorrections
          true, // preventMemo
        ) || []
      ).join("\n");
    };
    const getIssueNameFieldValue = fieldValue => {
      const prefix = issue.display_name.split("/")[0].trim();
      const finalValue = fieldValue.split("/");
      const testValue = finalValue[0].trim();
      return testValue === prefix
        ? finalValue.slice(1).join("").trim()
        : fieldValue;
    };
    const hasReasons = issue.reason && issue.reason.length > 0;
    const fields = [
      {
        name: "issue_name",
        // We use settings_name (and not name) to decide whether to render the field.
        // In project report_settings we specify which fields should be shown and
        // they mainly correspond to the issue key but sometimes the issue key depends
        // on some condition and can change (e.g. depending on hasReasons we use "reason"
        // or "positive_reason" issue field but in report_settings it's always "reason")
        settings_name: "issue_name",
        label: "Name",
        getValue: () => issue.name,
      },
      {
        name: "description",
        settings_name: "description",
        label: "Requirements",
      },
      {
        name: hasReasons ? "reason" : "positive_reason",
        settings_name: "reason",
        label: "Issue", // "Reason",
        getValue: () => getReasonText(false),
      },
      {
        name: hasReasons ? "detailed_reason" : "detailed_positive_reason",
        settings_name: "detailed_reason",
        label: "Issue", // "Detailed Reason",
        getValue: () => getReasonText(true),
        isMarkdown: true,
      },
      {
        name: "standard_language",
        settings_name: "standard_language",
        label: "Standard Language",
        getValue: () =>
          issueTemplateUtils.getStandardLanguageTemplateText(
            issue,
            this.props.currentIssuesetItem,
            this.props.positiveReasonData,
            this.props.documentClauseparts,
            reportSettings.id,
            false,
          ),
      },
      {
        name: hasReasons ? "guidance" : "positive_guidance",
        settings_name: "guidance",
        label: "Guidance",
        getValue: () =>
          issueTemplateUtils.getGuidanceTemplateText(
            issue,
            this.props.currentIssuesetItem,
            this.props.documentClauses,
            this.props.topicsById,
            this.props.positiveReasonData,
            this.props.documentClauseparts,
            reportSettings.id,
            false, // shouldUseManualCorrections
          ),
      },
      {
        name: "required_change",
        settings_name: "required_change",
        label: "Required Change",
        getValue: () =>
          issueTemplateUtils.getRequiredChangeTemplatedText(
            issue,
            this.props.currentIssuesetItem,
            this.props.documentClauses,
            this.props.topicsById,
            this.props.positiveReasonData,
            this.props.documentClauseparts,
            reportSettings.id,
            false, // shouldUseManualCorrections
          ),
      },
      {
        name: "fallback",
        settings_name: "fallback",
        label: "Fallback",
        getValue: () =>
          issueTemplateUtils.getFallbackTemplateText(
            issue,
            this.props.currentIssuesetItem,
            this.props.positiveReasonData,
            this.props.documentClauseparts,
            reportSettings.id,
            false, // shouldUseManualCorrections
          ),
      },
      {
        name: "comment",
        settings_name: "comment",
        label: "Comment",
        getValue: () => getEmailTextTemplate(issue, this.props),
      },
    ];
    const editAllIssueValues = this.props.clientModeOn
      ? false
      : project.edit_all_issue_values;
    const reportSettingsFields = reportSettings.fields || [];

    const fieldsByType = getObjectByType(reportSettingsFields);
    const {add_all_items_to_report: addAllItemsToReport} = reportSettings;
    return (
      <>
        <div style={styles.wrapper}>
          {this.renderActionButton(issueReportActionState, addAllItemsToReport)}
          <AlertColorSelect
            fieldValue={
              (manualCorrections || {})["alert_color"] ||
              issue?.manual_corrections?.global?.alert_color ||
              getDefaultColor(issue, reportSettings.id)
            }
            onSave={this.onAlertColorChange}
            label={
              (fieldsByType["status"] && fieldsByType["status"].label) ||
              "Status"
            }
          />
        </div>
        {fields.map(field => {
          const defaultValue = field.getValue
            ? field.getValue()
            : issue[field.name];
          const correctedValue = (manualCorrections || {})[field.name];
          const fieldValue =
            field.name === "issue_name"
              ? getIssueNameFieldValue(correctedValue || defaultValue || "")
              : correctedValue || defaultValue || "";
          if (
            !editAllIssueValues &&
            !(
              fieldsByType[field.settings_name] &&
              fieldsByType[field.settings_name].value
            )
          ) {
            return null;
          }
          return (
            <TextFieldWithStateValue
              key={field.name}
              label={
                (fieldsByType[field.settings_name] &&
                  fieldsByType[field.settings_name].label) ||
                field.label
              }
              fieldValue={fieldValue}
              onSave={this.onReportTabTextfieldChange(field.name)}
              defaultValue={defaultValue}
              onRevert={this.onReportTabTextfieldClear(field.name)}
              issue={issue}
              fieldName={field.name}
              selectedReportId={reportSettings.id}
              correctDocumentIssueManually={correctDocumentIssueManually}
              lockedTextValue={lockedTextValue?.[field.name]}
              isMarkdown={field?.isMarkdown}
            />
          );
        })}
        {reportSettingsFields
          .filter(
            fld =>
              fld.type.indexOf("custom_field") === 0 &&
              (editAllIssueValues || fld.value),
          )
          .map(customField => {
            const correctedValue = (manualCorrections || {})[customField.type];
            return (
              <TextFieldWithStateValue
                key={customField.type}
                label={customField.label}
                fieldValue={correctedValue || ""}
                onSave={this.onReportTabTextfieldChange(customField.type)}
                defaultValue={""}
                onRevert={this.onReportTabTextfieldClear(customField.type)}
              />
            );
          })}
        {this.renderClausesBlock(fieldsByType, correctedApplicableClauses)}
      </>
    );
  }

  renderActionButton = (actionState, addAllItemsToReport) => {
    const noAction = actionState === null || actionState === undefined;
    let buttonLabel;
    let buttonHandler;
    if (noAction) {
      buttonLabel = addAllItemsToReport
        ? "Remove from Report"
        : "Add to report";
      buttonHandler = addAllItemsToReport
        ? this.removeFromRagReport
        : this.addToRagReport;
    } else if (actionState === 0) {
      buttonLabel = "Add to report";
      buttonHandler = this.revertRagReportActionState;
    } else if (actionState === 1) {
      buttonLabel = "Remove from report";
      buttonHandler = this.revertRagReportActionState;
    }
    return (
      <Button variant="outlined" onClick={buttonHandler} style={styles.button}>
        {buttonLabel}
      </Button>
    );
  };

  renderClausesBlock = (fieldsByType, correctedApplicableClauses) => {
    const label =
      fieldsByType["clause_text"] &&
      fieldsByType["clause_text"].value &&
      fieldsByType["clause_text"].label
        ? fieldsByType["clause_text"].label
        : fieldsByType["clause_references"] &&
          fieldsByType["clause_references"].value &&
          fieldsByType["clause_references"].label
        ? fieldsByType["clause_references"].label
        : "Clauses";
    return (
      <div style={styles.clausesWrapper}>
        <div style={styles.clausesContainer}>
          <span style={{flexGrow: 1}}>{label}</span>
          <div style={styles.clausesBlock}>
            {correctedApplicableClauses && !this.state.isGuiEdited ? (
              <IconButton size="small" onClick={this.openTextInGuiEditor}>
                <Tooltip title="Edit clauses text" placement="top" arrow>
                  <EditIcon style={{width: "20px"}} />
                </Tooltip>
              </IconButton>
            ) : null}
            <IconButton size="small" onClick={this.onShowReportClauses}>
              {this.state.isShowReportClauses ? (
                <Tooltip title="Show report clauses" placement="top" arrow>
                  <KeyboardArrowDownIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Hide report clauses" placement="top" arrow>
                  <KeyboardArrowUpIcon />
                </Tooltip>
              )}
            </IconButton>
          </div>
        </div>
        {this.state.isShowReportClauses && (
          <ClausesPanel
            {...this.props}
            onManualCorrectDocumentIssue={this.onManualCorrectDocumentIssue}
            selectedReport={this.props.reportSettings.id}
            onClauseClick={this.props.scrollToClause}
          />
        )}
      </div>
    );
  };

  revertRagReportActionState = () => {
    if (this.props.revertIssueActionState && this.props.reportSettings) {
      this.props.revertIssueActionState(
        this.props.issue,
        this.props.reportSettings.id,
      );
    }
  };

  addToRagReport = () => {
    if (this.props.addIssueToReport && this.props.reportSettings) {
      this.props.addIssueToReport(
        this.props.issue,
        this.props.reportSettings.id,
      );
    }
  };

  removeFromRagReport = () => {
    if (this.props.removeIssueFromReport && this.props.reportSettings) {
      this.props.removeIssueFromReport(
        this.props.issue,
        this.props.reportSettings.id,
      );
    }
  };

  selectClausepart = _.memoize(clausepartId => (e, checked) => {
    const {
      issue: documentIssue,
      currentIssuesetItem,
      reportSettings,
    } = this.props;

    const manualCorrections = getIssueManualCorrections(
      documentIssue,
      currentIssuesetItem,
      reportSettings.id,
    );
    const removedClauses = {
      ...((manualCorrections || {}).removedClauses || {}),
    };
    if (checked) {
      delete removedClauses[clausepartId];
    } else {
      removedClauses[clausepartId] = true;
    }
    this.onManualCorrectDocumentIssue({removedClauses});
  });

  removeAdditionalClauseFromReport = _.memoize(clausepartId => () => {
    this.props.onRemoveAdditionalClauseFromReport(
      clausepartId,
      this.props.issue.id,
    );
  });

  onReportTabTextfieldChange = _.memoize(fieldName => e => {
    const newValue = e.target.value;
    const updates = {[fieldName]: newValue ? newValue : undefined};
    this.onManualCorrectDocumentIssue(updates, true);
  });

  onReportTabTextfieldClear = _.memoize(fieldName => () => {
    // if we revert a value we don't need to save it in manual corrections
    this.onManualCorrectDocumentIssue({[fieldName]: undefined});
  });

  onAlertColorChange = newValue => {
    const {
      issue: documentIssue,
      currentIssuesetItem,
      reportSettings,
    } = this.props;
    const isTriggered = isIssueAlert(documentIssue);
    const manualCorrections = getIssueManualCorrections(
      documentIssue,
      currentIssuesetItem,
      reportSettings.id,
    );
    const {issue_class_id: issueClassId} = documentIssue;

    let updates;
    if (
      !manualCorrections ||
      (!manualCorrections.new_state && !manualCorrections.alert_color)
    ) {
      if (!isTriggered && (newValue === "red" || newValue === "amber")) {
        updates = {
          new_state: "alert",
          alert_color: newValue,
        };
      } else if (isTriggered && newValue === "green") {
        updates = {
          new_state: "non-alert",
          alert_color: newValue,
        };
      } else {
        updates = {alert_color: newValue};
      }
    } else {
      // a state correction exists
      const defaultColor = getDefaultColor(documentIssue, reportSettings.id);
      if (defaultColor === newValue) {
        updates = {
          new_state: undefined,
          alert_color: undefined,
        };
      } else if (
        isTriggered &&
        !isEmptyParent(documentIssue) &&
        ((newValue === "red" && issueClassId === 1) ||
          (newValue === "amber" && issueClassId === 3))
      ) {
        updates = {
          new_state: undefined,
          alert_color: undefined,
        };
      } else if (
        isTriggered &&
        ((newValue === "red" && issueClassId !== 1) ||
          (newValue === "amber" && issueClassId !== 3))
      ) {
        updates = {
          new_state: undefined,
          alert_color: newValue,
        };
      } else if (isTriggered && newValue === "green") {
        updates = {
          new_state: "non-alert",
          alert_color: newValue,
        };
      } else if (!isTriggered && newValue === "green") {
        updates = {
          new_state: undefined,
          alert_color: undefined,
        };
      } else if (!isTriggered && (newValue === "amber" || newValue === "red")) {
        updates = {
          new_state: "alert",
          alert_color: newValue,
        };
      } else {
        updates = {
          alert_color: newValue,
          new_state: undefined,
        };
      }
    }
    this.onManualCorrectDocumentIssue(updates);
  };
  onManualCorrectDocumentIssueState = () => {
    const {
      issue: documentIssue,
      currentIssuesetItem,
      reportSettings,
    } = this.props;
    const manualCorrections = getIssueManualCorrections(
      documentIssue,
      currentIssuesetItem,
      reportSettings.id,
    );
    const isTriggered = isIssueAlert(documentIssue);
    let updates;
    if (!manualCorrections || !manualCorrections.new_state) {
      updates = {
        new_state: isTriggered ? "non-alert" : "alert",
        alert_color: isTriggered ? "green" : "red",
      };
    } else if (manualCorrections && manualCorrections.new_state) {
      updates = {
        new_state: undefined,
        alert_color: null,
      };
    }
    this.onManualCorrectDocumentIssue(updates);
  };

  onManualCorrectDocumentIssue = (
    updates,
    shouldMergeUpdateObject,
    prevIssue,
  ) => {
    const {reportSettings} = this.props;
    const documentIssue = prevIssue || this.props.issue;
    if (
      documentIssue.groupName === "Unrecognised Clauses" ||
      documentIssue.correction
    ) {
      return;
    }
    const newManualCorrections = getUpdatedManualCorrections(
      documentIssue.manual_corrections,
      reportSettings.id,
      null,
      null,
      updates,
    );
    if (newManualCorrections) {
      if (shouldMergeUpdateObject) {
        const updateObject = {[reportSettings.id]: {...updates}};
        const options = {
          merge_update_object: true,
          resetSelectedApplicableClauses: Boolean(
            updates.additional_report_clauses,
          ),
        };
        this.props.correctDocumentIssueManually(
          documentIssue,
          updateObject,
          options,
        );
      } else {
        this.props.correctDocumentIssueManually(documentIssue, {
          manual_corrections: newManualCorrections,
        });
      }
    }
  };
}

function getObjectByType(fields) {
  return fields.reduce(
    (result, field) => ({
      ...result,
      [field.type]: field,
    }),
    {},
  );
}

import _ from "underscore";
import React, {useState} from "react";

import {Button} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import getZoomedFontSize from "utils/get_zoomed_font_size";
import DraftingExamplePreview from "./drafting_example_preview";
import IssueExample from "./drafting_example";
import ItemContainer from "../../helper_components/item_container";

const styles = {
  valueContainer: {
    flipped: {},
    unflipped: {
      border: "1px solid #eee",
      padding: "1em",
      backgroundColor: "#fff",
    },
  },
  noItemsLabel: {
    marginBottom: "1em",
    textAlign: "center",
  },
  button: {
    borderRadius: "0",
    backgroundColor: "#f4f4f4",
    border: "none",
    color: "#333333",
    fontWeight: "200",
  },
  addButtonDiv: {
    display: "flex",
    justifyContent: "center",
    fontSize: "14px",
  },
  icon: {
    color: "#0078d4",
  },
};

export default function DraftingExamples({
  user,
  issue,
  getValue,
  title,
  headerStyles = {},
  containerStyles = {flex: "1 0 auto"},
  hiddenFields,
  getComponent,
  isFlipChecklistIcons,
  noItemsLabel,
  hideCopyComment,
  addButtonCaption,
  addIssueResponse,
  saveIssueResponse,
  deleteIssueResponse,
  insertIssueResponseIntoDocument,
  zoom,
  currentIssuesetItem,
}) {
  const [isExpanded, updateIsExpanded] = useState(true);

  function triggerIsExpanded() {
    updateIsExpanded(!isExpanded);
  }

  const [editableIssueResponse, setEditableIssueResponse] = useState(null);

  function editIssueResponse(issueResponse) {
    setEditableIssueResponse({...editableIssueResponse, ...issueResponse});
  }

  function addIssueResponseClick() {
    setEditableIssueResponse({
      id: "new",
      issueset_id: currentIssuesetItem.id,
    });
  }

  const isNewIssueResponse =
    editableIssueResponse && editableIssueResponse.id === "new";

  const onSaveIssueResponse = async issueResponse => {
    if (isNewIssueResponse) {
      await addIssueResponse(issueResponse);
      await setEditableIssueResponse(null);
    } else {
      await saveIssueResponse(issueResponse);
    }
  };

  const renderIssueResponseEditor = () => {
    return (
      <IssueExample
        isNew={isNewIssueResponse}
        issueResponse={editableIssueResponse}
        user={user}
        hiddenFields={hiddenFields}
        returnToList={() => setEditableIssueResponse(null)}
        saveIssueResponse={onSaveIssueResponse}
        deleteIssueResponse={isNewIssueResponse ? null : deleteIssueResponse}
        insertIssueResponseIntoDocument={insertIssueResponseIntoDocument}
        zoom={zoom}
      />
    );
  };

  const renderIssueResponseList = () => {
    const filteredResponses = issue.issue_responses
      ? issue.issue_responses.filter(issueResponse => {
          const value = getValue(issueResponse);
          if (_.isString(value)) {
            return value.trim();
          }
          return value;
        })
      : [];
    const shouldShowList = filteredResponses.length;
    if (shouldShowList) {
      return filteredResponses.map((issueResponse, index) => (
        <DraftingExamplePreview
          key={index}
          index={index}
          editIssueResponse={() => editIssueResponse(issueResponse)}
          getComponent={getComponent}
          getValue={getValue}
          issueResponse={issueResponse}
          hideCopyComment={hideCopyComment}
          zoom={zoom}
        />
      ));
    }
    return (
      <div
        style={{
          ...styles.noItemsLabel,
          fontSize: getZoomedFontSize(14, "checklist", zoom),
        }}
      >
        {noItemsLabel}
      </div>
    );
  };

  function AddButton({addIssueResponseClick, caption, zoom}) {
    return (
      <div style={styles.addButtonDiv}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{
            ...styles.button,
            fontSize: getZoomedFontSize(14, "checklist", zoom),
          }}
          onClick={addIssueResponseClick}
          startIcon={<AddIcon style={styles.icon} />}
          fullWidth={false}
        >
          {caption}
        </Button>
      </div>
    );
  }

  return (
    <ItemContainer
      key={2}
      title={title}
      expandable={true}
      onExpandMenu={triggerIsExpanded}
      isMenuExpanded={isExpanded}
      headerStyles={headerStyles}
      containerStyles={containerStyles}
      innerBodyStyles={
        isFlipChecklistIcons
          ? styles.valueContainer.flipped
          : styles.valueContainer.unflipped
      }
    >
      {editableIssueResponse
        ? renderIssueResponseEditor()
        : renderIssueResponseList()}
      {editableIssueResponse ? null : (
        <AddButton
          caption={addButtonCaption}
          addIssueResponseClick={addIssueResponseClick}
          zoom={zoom}
        />
      )}
    </ItemContainer>
  );
}

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function generateDocumentIssueLlmPrompt(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_ISSUE_CONVERSATION_CLEAR,
    (organisationId, projectId, documentId, issueId) =>
      requestor
        .delete(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            `/issue/${issueId}` +
            "/conversation",
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
        })),
  );
}

import Describer from "../../../utils/issues/reason/describer";

/* eslint-disable complexity */
/* eslint-disable max-statements */
function describeReason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);
  const value = describer.reasonValue.value;
  const {currencies} = issue.rules;
  const {currency, symbol, value: originalValue} = describer.reasonValue;
  let currencyRules;
  let valueString;
  if (currencies[currency]) {
    currencyRules = currencies[currency];
    valueString = `${value} ${currency}`;
  } else if (currencies[symbol]) {
    currencyRules = currencies[symbol];
    valueString = `${symbol}${value}`;
  } else {
    const symbolString = symbol ? symbol : "";
    const currencyString = currency ? ` ${currency}` : "";
    valueString = `${symbolString}${originalValue}${currencyString}`;
  }
  let reason;
  if (currencyRules) {
    const {min, max} = currencyRules;

    const minExists = min !== undefined && min !== null;
    const maxExists = max !== undefined && max !== null;

    const symbolString = symbol ? symbol : "";
    reason = [
      minExists ? `more than ${symbolString}${min}` : "",
      minExists && maxExists ? " and " : "",
      maxExists ? `less than ${symbolString}${max}` : "",
      currency ? ` ${currency}` : "",
    ].join("");
  } else {
    reason = "not an acceptable currency";
  }
  return (
    [
      `Topic '${describer.topicName}'`,
      `has value '${valueString}'`,
      `for '${describer.parameterName}' parameter`,
      "which is",
      reason,
    ].join(" ") + (showClause ? ` in clause ${describer.clauseReference}` : "")
  );
}

export default describeReason;

function getClauseAtomIds(clause) {
  const atomIds = [];
  function getIds(nodes) {
    if (nodes.text) {
      atomIds.push(nodes.id);
    }
    if (nodes.clauseNodes) {
      nodes.clauseNodes.forEach(child => getIds(child));
    }
  }
  getIds(clause.nodes);
  return atomIds;
}

export default getClauseAtomIds;

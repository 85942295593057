import {intToRoman} from "../roman_number";

function convertListIndex(index, listStyleType) {
  const id = Number.isInteger(index) && index >= 0 ? index : 0;
  if (listStyleType === "lower-latin") {
    return String.fromCharCode("a".charCodeAt(0) + id);
  }
  if (listStyleType === "upper-latin") {
    return String.fromCharCode("A".charCodeAt(0) + id);
  }
  if (listStyleType === "lower-roman") {
    return `${intToRoman(id + 1)}`.toLowerCase();
  }
  if (listStyleType === "upper-roman") {
    return `${intToRoman(id + 1)}`.toUpperCase();
  }
  return `${id + 1}`;
}

export default convertListIndex;

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import _ from "lodash";
import React from "react";
import renderProjectSettings from "../../project/project_settings/render_project_settings";

const styles = {
  dialogContent: {
    paddingTop: 0,
    width: "unset",
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
  dialogActions: {
    padding: 20,
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteBtn: {
    cursor: "pointer",
    color: "#616161",
  },
};

const SettingsDialog = ({
  reportSettings,
  editedReport,
  project,
  issues,
  contractTypes,
  document,
  updateStateEditedReport,
  onRemoveExistingDocumentReportSettings,
  onSaveEditedReport,
  cancelSelectedReportEditing,
}) => {
  return (
    <Dialog
      open={true}
      // onClose={this.cancelSelectedReportEditing}
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          width: reportSettings.type === "custom_report" ? 800 : 374,
        },
      }}
    >
      <DialogTitle>
        <div style={styles.dialogTitle}>
          <Typography variant="h6">
            {`Edit ${reportSettings.label} Settings`}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent style={styles.dialogContent}>
        <div style={{display: "flex"}}>
          {renderProjectSettings(reportSettings.type, {
            settings: editedReport,
            baseSettings: reportSettings,
            onSettingsChange: updateStateEditedReport,
            issuesets: project.issuesets,
            contractTypes,
            project,
            issues,
          })}
        </div>
      </DialogContent>
      <DialogActions style={styles.dialogActions}>
        <div title="Remove Custom Report Settings">
          {(document.report_settings || []).find(
            report => report.id === editedReport.id,
          ) && (
            <DeleteIcon
              style={styles.deleteBtn}
              onClick={onRemoveExistingDocumentReportSettings}
            />
          )}
        </div>
        <div>
          <Button
            variant="contained"
            color="primary"
            style={{marginRight: 16}}
            onClick={onSaveEditedReport}
            disabled={_.isEqual(reportSettings, editedReport)}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={cancelSelectedReportEditing}
          >
            Cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsDialog;

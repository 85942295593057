import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

function addNewTags(state, payload) {
  if (payload.tags) {
    const newTags = payload.tags.filter(
      newTag => !state.find(tag => tag.id === newTag.id),
    );
    return state.concat(
      newTags.map(tag => ({
        last_edited: payload.last_edited,
        ...tag,
      })),
    );
  }
  return state;
}

export default function topicTagsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.TOPIC_TAGS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.TOPIC_TAGS_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.TOPIC_UPDATE.SUCCESS:
      return addNewTags(state, action.payload);
    case ActionTypes.TOPICS_UPDATE.SUCCESS:
      return addNewTags(state, {
        last_edited: action.payload[0].last_edited,
        tags: _.uniq(
          _.flatten(_.map(action.payload, item => item.tags)),
          tag => tag.id,
        ),
      });
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD.SUCCESS:
      return addNewTags(state, action.payload);
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

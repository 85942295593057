import React from "react";

import MatchSection from "../../match_section";
import findMatches from "./find_matches";

import getClauseAtomsByTopic from "routes/document_detail/utils/applicable_clauses/get_clause_atoms_by_topic";
import renderApplicableClauses from "routes/document_detail/utils/applicable_clauses/render_applicable_clauses";

export default class Topics extends React.Component {
  render() {
    return (
      <MatchSection
        items={this.props.items}
        disableMatchAll={false}
        sectionTitle="Topics"
        onSearch={this.onTopicSearch}
        resultRenderer={this.resultRenderer}
        isLast={this.props.isLast}
        appType={this.props.appType}
      />
    );
  }
  onTopicSearch = topic => {
    return getClauseAtomsByTopic(this.props.documentClauses, topic);
  };
  resultRenderer = result =>
    renderApplicableClauses(
      result,
      this.props.onClauseClick,
      this.props.topicsById,
      true,
      this.props.document.file_extension,
    );
}

Topics.findMatches = props =>
  findMatches(props.searchValue, props.documentTopics, props.topicsById);

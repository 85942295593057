import React from "react";
import _ from "lodash";
import grey from "@material-ui/core/colors/grey";

import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import SyncIcon from "@material-ui/icons/Sync";

import TopPanelContainer from "../top_panel_container";
import ReportItem from "../report_item";
import groupClausesReferences from "../utils/group_clauses_references";
import constructReportSettingsFields from "./construct_report_settings_fields";
import generateTableHeaderItems from "./generate_table_header_items";
import downloadDocx from "../utils/download_docx";
import downloadXlsx from "../utils/download_xlsx";
import constructRagReportTable from "./construct_rag_report_table";
import getReportIssues from "../utils/get_report_issues";
import constructReportFilename from "../utils/construct_report_filename";
import constructRagReportDocument from "../utils/construct_report_docx_document";
import constructRagReportSpreadsheet from "../utils/construct_report_xlsx_spreadsheet";
import getIssuesReportData from "../utils/get_issues_report_data";
import {initialFiltersState} from "../../filters/constants";
import filterRagReportTable from "../utils/filter_rag_report_table_items";
import keyCreator from "../../../routes/document_detail/utils/key_creator";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import TextField from "@material-ui/core/TextField";
import uuid from "uuid";

const styles = {
  contentMain: {
    width: "100%",
    color: grey[600],
  },
  blankMessage: {
    height: "5rem",
    lineHeight: "5rem",
    fontSize: "22px",
    color: grey[600],
    textAlign: "center",
  },
  tableMain: {
    borderCollapse: "collapse",
    width: "100%",
    borderSpacing: 0,
    fontFamily: "Roboto",
    tableLayout: "fixed",
  },
  clauseHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  clauseHeaderSvg: {
    height: 18,
    width: 18,
    cursor: "pointer",
    marginLeft: 2,
  },
  savePopupMessage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "75px",
  },
  topPanelRoot: {
    boxShadow: "none",
    borderBottom: "1px solid #e8e8e8",
  },
  tableHeaderCell: {
    fontWeight: "500",
    color: "#000",
  },
  tableHeader: {
    top: 0,
    position: "sticky",
    backgroundColor: "#ddd",
    zIndex: 1,
    // Border doesn't work on sticky header...
    borderBottom: `2px solid ${grey[300]}`,
  },
  tableHeaderRow: {
    height: "26px",
  },
  tableBody: {
    fontWeight: 100,
    verticalAlign: "top",
  },
  snackbarAnchor: {
    vertical: "bottom",
    horizontal: "center",
  },
  deleteStackbar: {
    style: {
      minWidth: 100,
    },
  },
  saveStackbar: {
    style: {
      minWidth: 100,
      backgroundColor: "#66bb6a",
    },
  },
};

function ClauseTextHeaderItem(props) {
  const [isHovered, updateIsHovered] = React.useState(false);
  const onHoverStart = () => updateIsHovered(true);
  const onHoverFinish = () => updateIsHovered(false);
  return (
    <div
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverFinish}
      style={styles.clauseHeader}
    >
      <div style={{flexShrink: 0}}>{props.text}</div>
      <SyncIcon
        onClick={props.onClick}
        style={{
          ...styles.clauseHeaderSvg,
          visibility: isHovered ? "visible" : "hidden",
        }}
      />
    </div>
  );
}

function filterIssues(
  issues,
  deletedDocumentIssues,
  showDeletedIssues,
  reportSettings,
  showIssuesInChecklist,
) {
  const {add_all_items_to_report: addAllItemsToReport} = reportSettings;
  return issues.reduce((result, issue) => {
    if (issue.shouldBeHidden) {
      return result;
    }
    const issueActionState = (issue.action_state || {})[reportSettings.id];
    let shouldKeepIssueInListBase;
    if (addAllItemsToReport && issueActionState !== 0) {
      shouldKeepIssueInListBase = true;
    } else if (showIssuesInChecklist) {
      const {current_state: currentState} = issue;
      if (!issue.reason) {
        shouldKeepIssueInListBase = issueActionState === 1;
      } else if (currentState === null) {
        shouldKeepIssueInListBase = Boolean(issue.reason);
      } else {
        shouldKeepIssueInListBase = Boolean(currentState);
      }
    } else {
      shouldKeepIssueInListBase = issueActionState === 1;
    }
    const shouldKeepIssueInList =
      shouldKeepIssueInListBase &&
      !deletedDocumentIssues[issue.document_issue_id];

    if (!shouldKeepIssueInList && showDeletedIssues) {
      result.push({
        ...issue,
        isDeleted: true,
      });
    } else if (shouldKeepIssueInList) {
      result.push(issue);
    }
    return result;
  }, []);
}

class RagReport extends React.Component {
  constructor() {
    super();
    this.state = {
      deletedDocumentIssueId: null,
      deletedDocumentIssues: {},
      showDeletedIssues: false,
      itemIssuesFilterValues: {
        ...initialFiltersState,
      },
      broadcastData: {},
      openSavedPopup: false,
      isSaveButtonShown: false,
      saveTrigger: 0,
      cancelTrigger: 0,
      customRows: [],
      editedFieldIndex: -1,
    };
    this.contentBlockRef = React.createRef();
  }

  componentDidMount() {
    this.setupBroadcastChannel();
    if (this.props.document.additional_report_items) {
      this.setState({customRows: this.props.document.additional_report_items});
    }
  }

  componentWillUnmount() {
    this.cleanupBroadcastChannel();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.selectedReportId !== nextProps.selectedReportId ||
      this.props.highlightManualCorrections !==
        nextProps.highlightManualCorrections ||
      this.props.showDeletedIssues !== nextProps.showDeletedIssues ||
      this.props.documentIssuesManualUpdateCount !==
        nextProps.documentIssuesManualUpdateCount ||
      !_.isEqual(this.props.reportSettings, nextProps.reportSettings) ||
      !_.isEqual(
        this.props.currentIssuesetItem,
        nextProps.currentIssuesetItem,
      ) ||
      !_.isEqual(this.state, nextState) ||
      this.props.reviewTrackingOn !== nextProps.reviewTrackingOn ||
      this.props.toggleReportPanelVisibility !==
        nextProps.toggleReportPanelVisibility ||
      this.props.toggleReportOpenInRightSidebar !==
        nextProps.toggleReportOpenInRightSidebar
    );
  }

  handleBroadcastMessage = event => {
    this.setState({broadcastData: event.data});
  };

  setupBroadcastChannel = () => {
    if ("BroadcastChannel" in self) {
      this.channel = new BroadcastChannel(
        keyCreator("report", this.props.document.document_id),
      );

      this.channel.onmessage = this.handleBroadcastMessage;
    }
  };

  cleanupBroadcastChannel = () => {
    if (this.channel) {
      this.channel.close();
      this.channel = null;
    }
  };

  render() {
    const {props, state} = this;
    const {reportSettings, renderInSeparateWindow} = props;
    const {itemIssuesFilterValues, customRows} = state;

    const filteredIssues = filterIssues(
      props.issues,
      state.deletedDocumentIssues,
      state.showDeletedIssues,
      props.reportSettings,
      props.showIssuesInChecklist,
    );

    const reportSettingsFields = constructReportSettingsFields(
      reportSettings ? reportSettings.fields || [] : [],
    );

    const reportIssues = getReportIssues(filteredIssues, props.groupedIssues);
    const issuesReport = getIssuesReportData(reportIssues, props);
    const finalIssues = groupClausesReferences(
      issuesReport,
      props.documentClauseparts,
    );

    const ragRows = constructRagReportTable(
      finalIssues,
      reportSettingsFields,
      props,
      customRows,
    );

    if (!ragRows || ragRows.length === 0) {
      return <div style={styles.blankMessage}>No added issues</div>;
    }

    const filteredRows = filterRagReportTable(ragRows, itemIssuesFilterValues);

    const ragHeaderRowItems = generateTableHeaderItems(
      reportSettingsFields,
      reportSettings,
    );
    const reportLabel = reportSettings ? reportSettings.label : "Rag Report";

    const onDocxDownload = () => {
      const ragRows = constructRagReportTable(
        finalIssues,
        reportSettingsFields,
        props,
        customRows,
      );
      downloadDocx(
        constructRagReportDocument(
          ragHeaderRowItems,
          ragRows,
          reportLabel,
          reportSettings,
        ),
        constructReportFilename("RAG_Report", props.document, reportLabel),
      );
    };

    const onDownloadXlsx = async () => {
      const ragRows = constructRagReportTable(
        finalIssues,
        reportSettingsFields,
        props,
        customRows,
      );
      downloadXlsx(
        constructRagReportSpreadsheet(
          ragRows,
          reportSettingsFields,
          reportSettings.are_report_items_numbered,
        ),
        constructReportFilename(
          "RAG_Report",
          props.document,
          reportLabel,
          "xlsx",
        ),
      );
    };

    const getBroadcastData = () => {
      const channel = new self.BroadcastChannel(
        keyCreator("report", props.document.document_id),
      );
      channel.addEventListener("message", event => {
        this.setState({broadcastData: event.data});
      });
    };

    if ("BroadcastChannel" in self) {
      try {
        getBroadcastData();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("BroadcastChannel\n", error);
      }
    }

    const setAdditionalReportItems = items => {
      this.setState({customRows: items});
      this.props.updateDocument({additional_report_items: items});
    };

    const addNewReportItem = () => {
      const newReportItem = [
        ...this.state.customRows,
        {
          id: uuid(),
          issue_name: "Additional items / Blank",
          additional_item: true,
        },
      ];
      setAdditionalReportItems(newReportItem);
      setTimeout(() => {
        this.contentBlockRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }, 100);
    };

    const updateAdditionalReportItems = itemValue => {
      const updatedReportItem = [...this.state.customRows].map(item => {
        if (item.id === itemValue.id) {
          item[itemValue.type] =
            itemValue.type === "issue_name"
              ? `Additional items / ${itemValue.value}`
              : itemValue.value;
        }
        return item;
      });
      setAdditionalReportItems(updatedReportItem);
    };

    const deleteAdditionalReportItem = id => {
      const updatedReportItem = [...this.state.customRows].filter(
        item => item.id !== id,
      );
      setAdditionalReportItems(updatedReportItem);
    };

    const onCorrectdocumentIssueManually = (issue, obj) => {
      this.setState({openSavedPopup: true});
      props.correctDocumentIssueManually(issue, obj);
      setTimeout(() => {
        this.setState({openSavedPopup: false});
      }, 3000);
    };

    const headerItemsWithLeftIcon = [
      {
        width: "20px",
        maxWidth: "20px",
        title: "\u00A0",
      }, // deleted issue
      {
        width: "20px",
        maxWidth: "20px",
        title: "\u00A0",
      }, // open issue detail button
      ...ragHeaderRowItems,
      {
        width: "40px",
        maxWidth: "40px",
        title: "\u00A0",
      }, // remove issue from report button
    ];

    return (
      <TopPanelContainer
        renderInSeparateWindow={props.renderInSeparateWindow}
        triangleRightOffset="82"
        rootDivStyles={styles.topPanelRoot}
        hasExpandableView={true}
        onDownloadDocx={onDocxDownload}
        onDownloadXlsx={onDownloadXlsx}
        highlightManualCorrections={props.highlightManualCorrections}
        triggerHighlightManualCorrections={
          props.triggerHighlightManualCorrections
        }
        showDeletedIssues={state.showDeletedIssues}
        triggerShowDeletedIssues={this.triggerShowDeletedIssues}
        startSelectedReportEditing={props.startSelectedReportEditing}
        toggleReportPanelVisibility={props.toggleReportPanelVisibility}
        toggleReportOpenInRightSidebar={props.toggleReportOpenInRightSidebar}
        isReportShownInRightSide={props.isReportShownInRightSide}
        documentId={props.documentId}
        project={props.project}
        projectId={props.project.id}
        organisationId={props.organisationId}
        isDocumentReport={props.isDocumentReport}
        itemIssuesFilterValues={this.state.itemIssuesFilterValues}
        changeItemIssuesFilterValues={this.changeItemIssuesFilterValues}
        reportId={props.reportId}
        reportType={props.reportType}
        reviewTrackingOn={props.reviewTrackingOn}
        reportLabel={props.reportSettings.label}
        isSaveButtonShown={state.isSaveButtonShown}
        saveButtonHandler={this.saveButtonHandler}
        cancelButtonHandler={this.cancelButtonHandler}
        addNewReportItem={addNewReportItem}
        issueset={props.currentIssuesetItem}
        reportSettings={reportSettings}
        issues={props.issues}
      >
        <div
          className="content"
          style={styles.contentMain}
          ref={this.contentBlockRef}
        >
          <Snackbar
            anchorOrigin={styles.snackbarAnchor}
            open={Boolean(state.deletedDocumentIssueId)}
            onClose={this.onDeletePopupClose}
            message="Item Deleted"
            action={this.renderSnackbarActions()}
            ContentProps={styles.deleteStackbar}
          />
          <Snackbar
            open={state.openSavedPopup}
            message={
              <span style={styles.savePopupMessage}>
                <CheckCircleIcon /> <span>Saved</span>
              </span>
            }
            anchorOrigin={styles.snackbarAnchor}
            ContentProps={styles.saveStackbar}
          />
          {this.renderSummaryEditors()}
          <table style={styles.tableMain}>
            <thead style={styles.tableHeader}>
              <tr style={styles.tableHeaderRow}>
                {headerItemsWithLeftIcon.map((baseItem, baseItemIndex) => (
                  <th
                    key={`header-${baseItemIndex}`}
                    style={{
                      ...styles.tableHeaderCell,
                      width: baseItem.width,
                      maxWidth: baseItem.maxWidth,
                    }}
                  >
                    {baseItem.type === "clause_text" ||
                    baseItem.type === "clause_references" ? (
                      <ClauseTextHeaderItem
                        onClick={this.triggerApplicableClausesDisplayMode}
                        text={baseItem.title}
                      />
                    ) : (
                      baseItem.title
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody style={styles.tableBody}>
              {filteredRows.map((item, itemIndex) => (
                <ReportItem
                  key={itemIndex}
                  index={itemIndex}
                  item={item}
                  broadcastData={state.broadcastData}
                  scrollToClause={props.scrollToClause}
                  correctDocumentIssueManually={onCorrectdocumentIssueManually}
                  issues={reportIssues}
                  isLastItem={itemIndex === ragRows.length - 1}
                  onShowIssueDetail={props.onShowIssueDetail}
                  selectedReportId={props.selectedReportId}
                  renderInSeparateWindow={renderInSeparateWindow}
                  removeIssueFromReport={this.onDeletePopupShow}
                  revertDeletedIssue={this.revertDeletedIssue}
                  updateDocumentIssue={props.updateDocumentIssue}
                  project={props.project}
                  user={props.user}
                  updateIssueReviewState={props.updateIssueReviewState}
                  issueset={props.currentIssuesetItem}
                  isDocumentReport={props.isDocumentReport}
                  canDelete={true}
                  showButtonsHandler={this.showButtonsHandler}
                  saveTrigger={state.saveTrigger}
                  cancelTrigger={state.cancelTrigger}
                  isSaveButtonShown={state.isSaveButtonShown}
                  areAllItemsNumbered={
                    props.reportSettings.are_report_items_numbered
                  }
                  updateAdditionalReportItems={updateAdditionalReportItems}
                  deleteAdditionalReportItem={deleteAdditionalReportItem}
                  saveButtonHandler={this.saveButtonHandler}
                />
              ))}
            </tbody>
          </table>
        </div>
      </TopPanelContainer>
    );
  }

  renderSummaryEditors() {
    const {
      show_summary_editor: showSummaryEditor,
      summary_fields: summaryFields,
    } = this.props.reportSettings;
    if (showSummaryEditor) {
      return (
        <div style={{padding: "0 0.5em"}}>
          {summaryFields.map((field, index) =>
            this.renderSummaryEditor(field, index),
          )}
        </div>
      );
    }
  }

  renderSummaryEditor(field, index) {
    const summaryTextObject = (
      this.props.reportSettings.summary_text ?? []
    ).find(item => item.id === field.id) ?? {id: field.id, text: ""};
    const hasContent = summaryTextObject.text.length > 0;
    return (
      <div
        key={index}
        style={{
          border: "1px solid #ccc",
          padding: "1em",
          maxWidth: "60em",
          margin: "0.5em auto",
          borderRadius: "8px",
          backgroundColor: "#fafafa",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto 1fr",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            paddingBottom: "0.5em",
          }}
        >
          {" "}
          <div></div> {/* Empty div for the first column */}
          <div>{field.summary_title}</div>
          <div>
            <Button
              variant="outlined"
              color="primary"
              style={{
                marginLeft: "2em",
                visibility:
                  this.state.editedFieldIndex === index ? "visible" : "hidden",
              }}
            >
              Save
            </Button>
          </div>
        </div>
        <TextField
          multiline
          autoFocus
          variant="outlined"
          placeholder={field.summary_placeholder}
          onBlur={this.updateSummaryText(field.id)}
          onChange={() => this.setState({editedFieldIndex: index})}
          defaultValue={summaryTextObject.text}
          style={{
            width: "calc(100% - 1em - 2px)",
            padding: "0.5em",
            paddingBottom: "2px",
          }}
          InputProps={{
            style: {
              fontWeight: 100,
              backgroundColor: "#fff",
              color: "#666",
              ...(!hasContent
                ? {
                    fontStyle: "italic",
                  }
                : {}),
            },
          }}
        />
      </div>
    );
  }

  renderSnackbarActions = () => (
    <Button onClick={this.undoDeleteIssue} color="primary" size="small">
      UNDO
    </Button>
  );

  showButtonsHandler = val => this.setState({isSaveButtonShown: val});
  saveButtonHandler = () =>
    this.setState({saveTrigger: this.state.saveTrigger + 1});
  cancelButtonHandler = () =>
    this.setState({cancelTrigger: this.state.cancelTrigger + 1});

  onDeletePopupShow = deletedDocumentIssueId => {
    this.setState(() => ({
      deletedDocumentIssueId,
      deletedDocumentIssues: {
        ...this.state.deletedDocumentIssues,
        [deletedDocumentIssueId]: true,
      },
    }));
    const issue = this.props.issues.find(
      issue => issue.document_issue_id === deletedDocumentIssueId,
    );
    this.props.removeIssueFromReport(issue, this.props.reportSettings.id);
    if (this.previousDeleteTimeout) {
      clearTimeout(this.previousDeleteTimeout);
    }
    this.previousDeleteTimeout = setTimeout(() => {
      this.setState({deletedDocumentIssueId: null});
    }, 10000);
  };

  onDeletePopupClose = () => {
    const {reportSettings} = this.props;
    const {deletedDocumentIssueId} = this.state;
    if (!deletedDocumentIssueId) {
      return;
    }
    const issue = this.props.issues.find(
      issue => issue.document_issue_id === deletedDocumentIssueId,
    );

    const addAllItemsToReport = reportSettings.add_all_items_to_report;
    const issueActionState = (issue.action_state || {})[reportSettings.id];

    const noAction =
      issueActionState === null || issueActionState === undefined;

    if (noAction && addAllItemsToReport) {
      this.props.removeIssueFromReport(issue, reportSettings.id);
    } else if (issueActionState === 1) {
      this.props.revertIssueActionState(issue, reportSettings.id);
    }

    this.setState(() => ({deletedDocumentIssueId: null}));
  };

  changeItemIssuesFilterValues = item => {
    if (item.value === this.state.itemIssuesFilterValues.sort) {
      return;
    }
    if (item === "delete") {
      this.setState({
        itemIssuesFilterValues: {
          ...initialFiltersState,
        },
      });
      return;
    }
    if (item.type === "issueState") {
      this.setState({
        itemIssuesFilterValues: {
          ...this.state.itemIssuesFilterValues,
          [item.value]: !this.state.itemIssuesFilterValues[item.value],
        },
      });
    } else {
      if (
        Object.values(this.state.itemIssuesFilterValues).find(
          value => value === item.value,
        )
      ) {
        const newState = {...this.state.itemIssuesFilterValues};
        delete newState[item.type];
        this.setState({
          itemIssuesFilterValues: {
            ...newState,
          },
        });
      } else {
        this.setState({
          itemIssuesFilterValues: {
            ...this.state.itemIssuesFilterValues,
            [item.type]: item.value,
          },
        });
      }
    }
  };

  undoDeleteIssue = () => {
    const {deletedDocumentIssueId} = this.state;
    this.revertDeletedIssue(deletedDocumentIssueId);
    if (this.previousDeleteTimeout) {
      clearTimeout(this.previousDeleteTimeout);
    }
  };

  revertDeletedIssue = deletedDocumentIssueId => {
    const {reportSettings} = this.props;
    const issue = this.props.issues.find(
      issue => issue.document_issue_id === deletedDocumentIssueId,
    );

    const newDeletedDocumentIssues = {...this.state.deletedDocumentIssues};
    delete newDeletedDocumentIssues[deletedDocumentIssueId];
    this.setState(() => ({
      deletedDocumentIssueId: null,
      deletedDocumentIssues: newDeletedDocumentIssues,
    }));

    const addAllItemsToReport = reportSettings.add_all_items_to_report;
    const issueActionState = (issue.action_state || {})[reportSettings.id];
    const noAction =
      issueActionState === null || issueActionState === undefined;

    if (noAction) {
      if (!addAllItemsToReport) {
        this.props.addIssueToReport(issue, reportSettings.id);
      }
    } else {
      this.props.revertIssueActionState(issue, reportSettings.id);
    }
  };

  triggerShowDeletedIssues = () =>
    this.setState(prevState => ({
      showDeletedIssues: !prevState.showDeletedIssues,
    }));

  updateSummaryText = id => event => {
    this.setState({editedFieldIndex: -1});
    const text = event.target.value;
    const newSummaryTextItem = {id, text};
    if (this.props.updateDocument) {
      const {report_settings: documentReportSettings} = this.props.document;
      const newDocumentReportSettings = _.cloneDeep(documentReportSettings);
      const reportIndex = newDocumentReportSettings.findIndex(
        report => report.id === this.props.reportSettings.id,
      );
      if (!newDocumentReportSettings[reportIndex]) {
        // TODO: don't spread report settings here, but
        // also make sure report settings get taken from props if a specific item is not set
        // IE handle settings coming from project or document?

        const matchedField = (
          this.props.reportSettings.summary_fields ?? []
        ).find(field => field.id === id);
        if (matchedField) {
          newDocumentReportSettings.push({
            ...this.props.reportSettings,
            summary_text: [newSummaryTextItem],
          });
        }
      } else {
        const matchedField = (
          newDocumentReportSettings[reportIndex].summary_fields ?? []
        ).find(field => field.id === id);
        if (matchedField) {
          const existingItemIndex =
            newDocumentReportSettings[reportIndex].summary_text?.findIndex(
              item => item.id === id,
            ) ?? -1;
          let newSummaryText;
          if (existingItemIndex !== -1) {
            newSummaryText = [...this.props.reportSettings.summary_text];
            newSummaryText[existingItemIndex] = newSummaryTextItem;
          } else {
            newSummaryText = [
              ...(this.props.reportSettings.summary_text || []),
              newSummaryTextItem,
            ];
          }
          newDocumentReportSettings[reportIndex].summary_text = newSummaryText;
        }
      }
      this.props.updateDocument({report_settings: newDocumentReportSettings});
    }
  };

  triggerApplicableClausesDisplayMode = () => {
    const {reportSettings} = this.props;
    let isReferencesSelected = false;
    const clauseReferencesIndex = reportSettings.fields.findIndex(field => {
      if (field.type === "clause_references") {
        if (field.value) {
          isReferencesSelected = true;
        }
        return true;
      }
      return false;
    });
    const clauseTextIndex = reportSettings.fields.findIndex(
      field => field.type === "clause_text",
    );
    const newFields = _.cloneDeep(reportSettings.fields);

    const temp = newFields[clauseReferencesIndex];
    newFields[clauseReferencesIndex] = newFields[clauseTextIndex];
    newFields[clauseTextIndex] = temp;
    newFields[clauseReferencesIndex].value = isReferencesSelected;
    newFields[clauseTextIndex].value = !isReferencesSelected;

    const newReportSettings = {
      ...reportSettings,
      fields: newFields,
    };

    const {report_settings: documentReportSettings} = this.props.document;
    const newDocumentReportSettings = _.cloneDeep(documentReportSettings);
    const reportIndex = newDocumentReportSettings.findIndex(
      report => report.id === newReportSettings.id,
    );

    if (reportIndex === -1) {
      newDocumentReportSettings.push(newReportSettings);
    } else {
      newDocumentReportSettings[reportIndex] = newReportSettings;
    }

    if (this.props.updateDocument) {
      this.props.updateDocument({report_settings: newDocumentReportSettings});
    }
  };
}

export default RagReport;

import {get} from "lodash";
import CorrectedIcon from "@material-ui/icons/Reply";
import CheckBoxIconChecked from "@material-ui/icons/CheckBox";
import CheckBoxIconUnchecked from "@material-ui/icons/CheckBoxOutlineBlank";
import ExclamationIcon from "@material-ui/icons/PriorityHigh";
import * as colors from "@material-ui/core/colors";
import {
  ClauseAtom,
  DocumentClauses,
  Party,
  PositiveReasonData,
  ReportId,
  TopicsById,
} from "common/types/document";
import {DocumentIssue} from "common/types/issue";
import {Issueset} from "common/types/issueset";

import {
  FixedIcon,
  GuidanceIcon,
  HiddenIcon,
  IgnoreIcon,
  IssueIcon,
  NoIssueIcon,
  WarningIcon,
} from "constants/icons";

import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import isEmptyParent from "common/utils/issues/is_empty_parent";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getPositiveReasonText from "common/utils/issues/reason/get_positive_reason_text";
import globalOverrideKey from "common/utils/issues/global_override_key";
import getSubissueEmptyParentReasonTextArray from "common/utils/issues/get_subissue_empty_parent_reason_text_array";

import reports from "constants/reports";
import {
  getIssueManualCorrections,
  getOverride,
} from "utils/manual_corrections_utils";

function isEmptyParentResolved(issue, issueset) {
  if (issue.resolved_state === 1) {
    return true;
  }

  const {subissues} = issue.subissueData;
  let hasResolvedSubissues = false;
  let hasNonResolvedSubissues = false;
  subissues.forEach(subissue => {
    if (subissue.shouldBeHidden) {
      return;
    }
    const manualCorrections = getIssueManualCorrections(
      subissue,
      issueset,
      globalOverrideKey,
    );
    const overrides = getOverride(subissue, issueset);
    const manuallyCorrectedAlertState = manualCorrections.new_state;
    const isAlert =
      manuallyCorrectedAlertState === "alert" ? true : isIssueAlert(subissue);
    const resolvedState = overrides?.resolved_state;

    if (isAlert) {
      if (resolvedState === 1) {
        hasResolvedSubissues = true;
      } else {
        hasNonResolvedSubissues = true;
      }
    }
  });
  return hasResolvedSubissues && !hasNonResolvedSubissues;
}

function isEmptyParentIgnored(issue, issueset) {
  if (issue.resolved_state === 0) {
    return true;
  }

  const {subissues} = issue.subissueData;
  let hasIgnoredSubissues = false;
  let hasNonIgnoredSubissues = false;
  subissues.forEach(subissue => {
    if (subissue.shouldBeHidden) {
      return;
    }

    const manualCorrections = getIssueManualCorrections(
      subissue,
      issueset,
      globalOverrideKey,
    );
    const overrides = getOverride(subissue, issueset);
    const manuallyCorrectedAlertState = manualCorrections.new_state;
    const isAlert =
      manuallyCorrectedAlertState === "alert" ? true : isIssueAlert(subissue);
    const resolvedState = overrides?.resolved_state;

    if (isAlert) {
      if (resolvedState === 0) {
        hasIgnoredSubissues = true;
      } else {
        hasNonIgnoredSubissues = true;
      }
    }
  });
  return hasIgnoredSubissues && !hasNonIgnoredSubissues;
}

function getSubissueStats(issue, issueset) {
  return issue.subissueData.subissues.reduce(
    (stats, subissue) => {
      if (
        subissue.shouldBeHidden &&
        get(issue, "parent_settings.set_state_and_display_text_from") !==
          "all_sub_issues"
      ) {
        return stats;
      }
      const manualCorrections = getIssueManualCorrections(
        subissue,
        issueset,
        globalOverrideKey,
      );
      const manuallyCorrectedColor = manualCorrections?.alert_color;
      const manuallyCorrectedAlertState = manualCorrections?.new_state;
      const isAlert = manuallyCorrectedAlertState
        ? manuallyCorrectedAlertState === "alert"
        : isIssueAlert(subissue);
      if (isAlert) {
        if (
          subissue.issue_class_id === 2 &&
          get(issue, "parent_settings.match_alert_class") !== "no"
        ) {
          stats.notesTriggered += 1;
        } else if (
          (subissue.issue_class_id === 3 && !manuallyCorrectedColor) ||
          manuallyCorrectedColor === "amber"
        ) {
          stats.warningsTriggered += 1;
        }

        if (
          subissue.issue_class_id !== 2 ||
          get(issue, "parent_settings.match_alert_class") !== "no"
        ) {
          stats.triggered += 1;
        }
        return stats;
      }

      const subissueActionState = (subissue.action_state || {})[
        globalOverrideKey
      ];

      if (subissueActionState === 0) {
        stats.rejected += 1;
        return stats;
      }
      if (subissueActionState === 1) {
        stats.confirmed += 1;
        return stats;
      }
      stats.noReasons += 1;
      return stats;
    },
    {
      noReasons: 0,
      confirmed: 0,
      rejected: 0,
      triggered: 0,
      notesTriggered: 0,
      warningsTriggered: 0,
    },
  );
}

function getIcon(iconName) {
  if (!iconName) {
    return null;
  }
  const ReportIcon = (reports.action_state || {}).icon || reports.email.icon;
  const icons = {
    ReportIcon,
    ExclamationIcon,
    CheckBoxIconUnchecked,
    CheckBoxIconChecked,
    GuidanceIcon,
    IssueIcon,
    NoIssueIcon,
    WarningIcon,
    CorrectedIcon,
    FixedIcon,
    IgnoreIcon,
    HiddenIcon,
  };
  return icons[iconName];
}

function getIconName(
  issue,
  issueset,
  showIssuesInChecklist,
  project,
  ignoreResolved = false,
) {
  const iconName = getBaseIconName(
    issue,
    issueset,
    showIssuesInChecklist,
    project,
    ignoreResolved,
  );

  if (iconName === "FixedIcon" || iconName === "IgnoreIcon") {
    return iconName;
  }

  const manualCorrections = getIssueManualCorrections(
    issue,
    issueset,
    globalOverrideKey,
  );
  const changeableIcons = [
    "IssueIcon",
    "NoIssueIcon",
    "CheckBoxIconUnchecked",
    "CheckBoxIconChecked",
  ];

  const {
    new_state: newState,
    alert_color: manualAlertColor,
  } = manualCorrections;

  if (manualAlertColor === "amber") {
    return "WarningIcon";
  } else if (manualAlertColor === "red") {
    return "IssueIcon";
  } else if (manualAlertColor === "green") {
    return "NoIssueIcon";
  }

  if (
    changeableIcons.includes(iconName) &&
    manualCorrections &&
    manualCorrections.new_state
  ) {
    if (iconName === "IssueIcon" && newState === "non-alert") {
      return "NoIssueIcon";
    } else if (iconName === "NoIssueIcon" && newState === "alert") {
      return "IssueIcon";
    } else if (
      iconName === "CheckBoxIconUnchecked" &&
      newState === "non-alert"
    ) {
      return "CheckBoxIconChecked";
    } else if (iconName === "CheckBoxIconChecked" && newState === "alert") {
      return "CheckBoxIconUnchecked";
    }
  }
  // If it is an empty parent and a child is an issue, but it is a warning
  // Keep it as a warning.
  if (issue.issue_class_id === 3 && iconName === "IssueIcon") {
    return "WarningIcon";
  }
  return iconName;
}

function getBaseIconName(
  issue: DocumentIssue,
  issueset,
  showIssuesInChecklist,
  project = {},
  ignoreResolved,
) {
  if (issue.issue_class_id === 4) {
    return "HiddenIcon";
  }
  if (project.resolve_issues) {
    const overrides = getOverride(issue, issueset);
    const resolvedState = ignoreResolved ? null : overrides?.resolved_state;
    if (
      resolvedState === 1 ||
      (isEmptyParent(issue) && isEmptyParentResolved(issue, issueset))
    ) {
      return "FixedIcon";
    } else if (
      resolvedState === 0 ||
      (isEmptyParent(issue) && isEmptyParentIgnored(issue, issueset))
    ) {
      return "IgnoreIcon";
    }
  }

  if (issue.trigger_type === "LLM" && issue.llm_response) {
    return (
      {
        Red: "IssueIcon",
        Amber: "WarningIcon",
        Green: "NoIssueIcon",
        Grey: "GuidanceIcon",
      }[issue.llm_response.rag_state] ?? "NoIssueIcon"
    );
  }

  if (issue.groupName === "Unrecognised Clauses") {
    return "ExclamationIcon";
  }
  if (showIssuesInChecklist) {
    if (issue.current_state === null) {
      return issue.reasonCount === 0
        ? "CheckBoxIconUnchecked"
        : "CheckBoxIconChecked";
    }
    return issue.current_state
      ? "CheckBoxIconChecked"
      : "CheckBoxIconUnchecked";
  }

  const isAlert = isIssueAlert(issue);

  if (issue.issue_class_id === 2) {
    return "GuidanceIcon";
  }
  if (isEmptyParent(issue)) {
    const subissueStats = getSubissueStats(issue, issueset);
    if (
      subissueStats.warningsTriggered > 0 &&
      subissueStats.warningsTriggered + subissueStats.notesTriggered ===
        subissueStats.triggered
    ) {
      // Set warning icon if only Warning issues are in alert state
      return "WarningIcon";
    }

    if (
      subissueStats.notesTriggered > 0 &&
      subissueStats.notesTriggered === subissueStats.triggered
    ) {
      // Set guidance icon if only Guidance issues are in alert state
      return "GuidanceIcon";
    }
    if (subissueStats.triggered) {
      return "IssueIcon";
    }
    if (subissueStats.corrected) {
      return "CorrectedIcon";
    }
    return "NoIssueIcon";
  }

  if (!isAlert) {
    return "NoIssueIcon";
  }
  if (issue.correction) {
    return "CorrectedIcon";
  }
  return issue.issue_class_id === 3 ? "WarningIcon" : "IssueIcon";
}

const usedColors = {
  amber0: "#ff8800",
  red0: "#ff0000",
  red1: "#bc0000",
  grey0: colors.grey[500],
  grey1: "#888",
  grey2: colors.grey[700],
  green0: colors.green["A700"],
  blue0: colors.blue[700],
  blue1: "#42a5f5",
};

const colorNames = {
  "#ff8800": "amber",
  "#ff0000": "red",
  "#bc0000": "red",
  [colors.grey[500]]: "grey",
  "#888": "grey",
  [colors.grey[700]]: "grey",
  [colors.green["A700"]]: "green",
  [colors.blue[700]]: "blue",
  "#42a5f5": "blue",
};

function getIconColor(
  issue,
  issueset,
  project,
  showIssuesInChecklist,
  showNoteState,
) {
  if (issue.issue_class_id === 4) {
    if (showNoteState) {
      return isIssueAlert(issue) ? usedColors.red0 : usedColors.green0;
    }
    return usedColors.grey0;
  }

  if (project.resolve_issues) {
    const {resolved_state: resolvedState} = issue;
    if (
      resolvedState === 1 ||
      (isEmptyParent(issue) && isEmptyParentResolved(issue, issueset))
    ) {
      return usedColors.blue1;
    } else if (
      resolvedState === 0 ||
      (isEmptyParent(issue) && isEmptyParentIgnored(issue, issueset))
    ) {
      return usedColors.grey0;
    }
  }

  const manualCorrections = getIssueManualCorrections(
    issue,
    issueset,
    globalOverrideKey,
  );
  if (
    manualCorrections &&
    manualCorrections.alert_color &&
    !(showNoteState && issue.issue_class_id === 2)
  ) {
    return usedColors[`${manualCorrections.alert_color}0`];
  }
  const colorName = getIconColorName(
    issue,
    issueset,
    showIssuesInChecklist,
    showNoteState,
  );
  // TODO: Use Debugger to find out why black
  const changeableColors = ["red0", "green0", "amber0"];
  const isWarning =
    issue.issue_class_id === 3 || manualCorrections.alert_color === "amber";
  if (
    changeableColors.includes(colorName) &&
    manualCorrections &&
    manualCorrections.new_state &&
    !showNoteState
  ) {
    const {new_state: newState, alert_color: alertColor} = manualCorrections;
    if (alertColor === "grey") {
      return "grey1";
    }
    return newState === "alert"
      ? alertColor === "amber"
        ? usedColors["amber0"]
        : isWarning
        ? usedColors["amber0"]
        : usedColors["red0"]
      : usedColors["green0"];
  }
  return usedColors[isWarning && colorName === "red0" ? "amber0" : colorName];
}

function getIconColorName(
  issue: DocumentIssue,
  issueset,
  showIssuesInChecklist,
  showNoteState,
) {
  if (issue.trigger_type === "LLM" && issue.llm_response) {
    return (
      {
        Red: "red0",
        Amber: "amber0",
        Green: "green0",
        Grey: "grey0",
      }[issue.llm_response.rag_state] ?? "green0"
    );
  }
  if (issue.groupName === "Unrecognised Clauses") {
    return "red0";
  }
  if (showIssuesInChecklist) {
    return issue.current_state === null ? "grey2" : "red1";
  }
  if (issue.issue_class_id === 2 && !showNoteState) {
    return "grey0";
  }
  if (isEmptyParent(issue)) {
    const subissueStats = getSubissueStats(issue, issueset);
    if (
      subissueStats.notesTriggered > 0 &&
      subissueStats.notesTriggered === subissueStats.triggered
    ) {
      return "grey1";
    }
    if (
      subissueStats.triggered &&
      subissueStats.triggered ===
        subissueStats.warningsTriggered + subissueStats.notesTriggered
    ) {
      return "amber0";
    }
    if (subissueStats.triggered) {
      return "red0";
    }
    return "green0";
  }

  const isTriggered = isIssueAlert(issue);
  return !isTriggered ? "green0" : issue.correction ? "blue0" : "red0";
}

function getTextColor(issue, showIssuesInChecklist, color = usedColors.grey2) {
  if (showIssuesInChecklist) {
    return color;
  }
  if (issue.reasonCount !== 0 && !issue.issue_class_id === 2) {
    return usedColors.red0;
  }
  return color;
}

function getRagScore(issue) {
  if (isEmptyParent(issue)) {
    return issue.subissueData.subissues.reduce((max, subissue) => {
      if (isIssueAlert(subissue)) {
        return Math.max(parseInt(subissue.rag_score.toString(), 10), max);
      }
      return max;
    }, 0);
  }
  return isIssueAlert(issue) ? issue.rag_score : 0;
}

function getMaxRagScore(issue) {
  if (isEmptyParent(issue)) {
    return issue.subissueData.subissues.reduce(
      (max, subissue) => Math.max(subissue.max_rag_score, max),
      0,
    );
  }
  return issue.max_rag_score || issue.rag_score;
}

function getReasonText(
  issue,
  issueset,
  documentClauses,
  topicsById,
  data,
  reasonData,
  parties,
  logDebugInfo,
  preventMemo = false,
  last_classification_changed,
) {
  return issue.reason && issue.reason.length > 0
    ? getIssueTemplatedText(
        issue,
        issueset,
        "reason_template",
        documentClauses,
        topicsById,
        data,
        reasonData,
        parties,
        false,
        logDebugInfo,
        preventMemo,
        last_classification_changed,
      )[0]
    : getPositiveReasonText(
        issue.positive_reason,
        issue,
        issueset,
        documentClauses,
        topicsById,
        data,
        reasonData,
        parties,
        logDebugInfo,
        preventMemo,
        last_classification_changed,
      );
}
function getDetailedReasonText(
  issue,
  issueset,
  documentClauses,
  topicsById,
  data,
  reasonData,
  parties,
  logDebugInfo,
  preventMemo = false,
  last_classification_changed,
) {
  return issue.reason && issue.reason.length > 0
    ? getIssueTemplatedText(
        issue,
        issueset,
        "detailed_reason",
        documentClauses,
        topicsById,
        data,
        reasonData,
        parties,
        false,
        logDebugInfo,
        preventMemo,
        last_classification_changed,
      )[0]
    : getPositiveReasonText(
        issue.detailed_positive_reason,
        issue,
        issueset,
        documentClauses,
        topicsById,
        data,
        reasonData,
        parties,
        logDebugInfo,
        preventMemo,
        last_classification_changed,
      );
}

function getReasonTemplate(
  issue,
  issueset,
  selectedReportId,
  showDetailedReason,
  shouldUseManualCorrections,
) {
  const {
    reason_template: baseReasonTemplate,
    detailed_reason: baseDetailedReason,
  } = issue;
  const manualCorrections = getIssueManualCorrections(
    issue,
    issueset,
    selectedReportId,
  );
  const manualReasonTemplate = manualCorrections?.reason;
  const reasonTemplate =
    shouldUseManualCorrections &&
    manualReasonTemplate &&
    manualReasonTemplate !== baseReasonTemplate
      ? manualReasonTemplate
      : baseReasonTemplate;

  const manualDetailedReason = manualCorrections?.detailed_reason;
  const detailedReason =
    shouldUseManualCorrections &&
    manualDetailedReason &&
    manualDetailedReason !== baseDetailedReason
      ? manualDetailedReason
      : baseDetailedReason;

  return showDetailedReason && detailedReason ? detailedReason : reasonTemplate;
}

function getPositiveReasonTemplate(
  issue,
  issueset,
  selectedReportId,
  showDetailedReason,
  shouldUseManualCorrections,
) {
  const {
    positive_reason: basePositiveReasonTemplate,
    detailed_positive_reason: baseDetailedPositiveReason,
  } = issue;
  const manualCorrections = getIssueManualCorrections(
    issue,
    issueset,
    selectedReportId,
  );
  const manualPositiveReasonTemplate = manualCorrections?.positive_reason;
  const positiveReasonTemplate =
    shouldUseManualCorrections &&
    manualPositiveReasonTemplate &&
    manualPositiveReasonTemplate !== basePositiveReasonTemplate
      ? manualPositiveReasonTemplate
      : basePositiveReasonTemplate;

  const manualDetailedPositiveReason =
    manualCorrections?.detailed_positive_reason;
  const detailedPositiveReason =
    shouldUseManualCorrections &&
    manualDetailedPositiveReason &&
    manualDetailedPositiveReason !== baseDetailedPositiveReason
      ? manualDetailedPositiveReason
      : baseDetailedPositiveReason;

  return showDetailedReason && detailedPositiveReason
    ? detailedPositiveReason
    : positiveReasonTemplate;
}

function getReasonTextArray(
  _issue: DocumentIssue,
  documentClauses: DocumentClauses,
  topicsById: TopicsById[],
  positiveReasonData: PositiveReasonData,
  selectedReportId: ReportId,
  currentIssuesetItem: Issueset,
  reasonData: ClauseAtom[] = [],
  parties: Party[] = [],
  logDebugInfo: boolean,
  showDetailedReason: boolean,
  shouldUseManualCorrections = false,
  preventMemo = false,
  last_classification_changed: boolean,
) {
  const issue = {
    ..._issue,
    [showDetailedReason
      ? "detailed_reason"
      : "reason_template"]: getReasonTemplate(
      _issue,
      currentIssuesetItem,
      selectedReportId,
      showDetailedReason,
      shouldUseManualCorrections,
    ),
    [showDetailedReason
      ? "detailed_positive_reason"
      : "positive_reason"]: getPositiveReasonTemplate(
      _issue,
      currentIssuesetItem,
      selectedReportId,
      showDetailedReason,
      shouldUseManualCorrections,
    ),
  };

  if (issue.trigger_type === "LLM" && issue.llm_response) {
    if (showDetailedReason && issue.llm_response.issue_detail) {
      return [issue.llm_response.issue_detail];
    }
    return [issue.llm_response.issue_summary ?? ""];
  }

  const reasonTextArray = isEmptyParent(issue)
    ? getSubissueEmptyParentReasonTextArray(
        issue,
        currentIssuesetItem,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        logDebugInfo,
        showDetailedReason,
        preventMemo,
        last_classification_changed,
      )
    : [
        showDetailedReason
          ? getDetailedReasonText(
              issue,
              currentIssuesetItem,
              documentClauses,
              topicsById,
              positiveReasonData,
              reasonData,
              parties,
              logDebugInfo,
              preventMemo,
              last_classification_changed,
            )
          : getReasonText(
              issue,
              currentIssuesetItem,
              documentClauses,
              topicsById,
              positiveReasonData,
              reasonData,
              parties,
              logDebugInfo,
              preventMemo,
              last_classification_changed,
            ),
      ];

  return reasonTextArray.filter(
    reasonText => reasonText && reasonText.trim().length,
  );
}

export default {
  getBaseIconName,
  getIconName,
  getIcon,
  getIconColor,
  getReasonText,
  getReasonTextArray,
  getTextColor,
  isEmptyParentResolved,
  isEmptyParentIgnored,
  getSubissueStats,
  getRagScore,
  getMaxRagScore,
  colors: usedColors,
  colorNames,
};

import React, {Component} from "react";
import _ from "lodash";
import "quill/dist/quill.snow.css";
import "./style.css";
import Quill, {linkListItemsOnSameLevel} from "utils/quill";
import {callIfExists} from "common/func";

import IndentIncrease from "material-ui/svg-icons/editor/format-indent-increase";
import IndentDecrease from "material-ui/svg-icons/editor/format-indent-decrease";
import Done from "material-ui/svg-icons/action/done";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import * as colors from "material-ui/styles/colors";

import ClausepartControl, {tooltipStyles} from "./clausepart_control";

import Tooltip from "common_components/tooltip";

const styles = {
  menuItemIcon: {
    width: "19px",
    height: "19px",
    color: colors.grey800,
    cursor: "pointer",
    position: "relative",
    top: "2px",
  },
  quillBody: {
    background: "#f8fbff",
    fontFamily: "Roboto, sans-serif",
  },
  indentButtonsContainer: {
    width: "2rem",
    borderRight: "1px solid lightgray",
    marginRight: "0.5rem",
  },
};

const listStyleTypeMap = {
  DECIMAL: "decimal",
  LOWER_LETTER: "lower-latin",
  LOWER_ROMAN: "lower-roman",
  UPPER_LETTER: "upper-latin",
  UPPER_ROMAN: "upper-roman",
};

const listStyleTypeImportanceArray = [
  "DECIMAL",
  "LOWER_LETTER",
  "LOWER_ROMAN",
  "UPPER_LETTER",
  "UPPER_ROMAN",
];

function containsIfNodeExists(node, target) {
  return !node ? false : node.contains(target);
}

class GuiTextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {contents: props.contents};
    this.notUsedListStyleTypes = _.difference(
      listStyleTypeImportanceArray,
      props.usedListFormatStylesPerLevel,
    ).concat(listStyleTypeImportanceArray);
    this.usedListStyleTypes = {};
    this.initStyleTypeTables(
      props.contents,
      props.usedListFormatStylesPerLevel,
      this.notUsedListStyleTypes,
      this.usedListStyleTypes,
      props.startingLevel,
    );
  }

  componentDidMount() {
    this.addDecimalListWithPrefixClass();
    document.addEventListener("click", this.handleClickOutsideQuill);
    this.addQuill();
    this.setEnableMode();
  }

  addQuill() {
    if (this.quillRef) {
      this.initQuill();
      const {quill, state} = this;
      linkListItemsOnSameLevel(quill);
      quill.on("text-change", this.onTextChange);
      if (state.contents) {
        quill.setContents(state.contents);
      }
      if (!this.props.doNotFocus) {
        quill.focus();
        const {caretOffset} = this.props;
        const position = _.isNumber(caretOffset)
          ? caretOffset
          : quill.getLength();
        quill.setSelection(position, 0);
      }
    }
  }

  initQuill() {
    const {notUsedListStyleTypes, usedListStyleTypes, onAbortEdit} = this;
    const {usedListFormatStylesPerLevel, startingLevel} = this.props;
    this.quill = new Quill(this.quillRef, {
      modules: {
        toolbar: {
          container: this.toolbarRef,
          handlers: {
            list: function list(listType) {
              if (listType) {
                setIndentListStyle(
                  this.quill,
                  usedListFormatStylesPerLevel,
                  startingLevel,
                  notUsedListStyleTypes,
                  usedListStyleTypes,
                );
              } else {
                this.quill.format("list", null);
                this.quill.format("list-style-type", null);
              }
            },
          },
        },
        keyboard: {
          bindings: {
            tab: {
              key: 9,
              handler: function handler() {
                const contents = this.quill.getContents();
                const range = this.quill.getSelection();
                const itemUnderCursor = getContentItemUnderCursor(
                  range,
                  contents,
                );
                if (
                  itemUnderCursor.attributes &&
                  itemUnderCursor.attributes.list
                ) {
                  this.quill.format(
                    "indent",
                    itemUnderCursor.attributes.indent
                      ? itemUnderCursor.attributes.indent + 1
                      : 1,
                  );
                }
                setIndentListStyle(
                  this.quill,
                  usedListFormatStylesPerLevel,
                  startingLevel,
                  notUsedListStyleTypes,
                  usedListStyleTypes,
                );
              },
            },
            esc: {
              key: 27,
              handler: function handler() {
                onAbortEdit();
              },
            },
            custom: {
              key: "enter",
              handler: function handler(range, context) {
                if (context.prefix.endsWith(":")) {
                  const cursorPosition = this.quill.getSelection().index;

                  const format = this.quill.getFormat();
                  if (Object.keys(format).length === 0) {
                    format["list"] = "ordered";
                    format["list-style-type"] = "lower-latin";
                  } else {
                    if (!format.indent) {
                      format.indent = 1;
                    } else {
                      format.indent += 1;
                    }
                  }
                  this.quill.insertText(cursorPosition + 1, "\n", format);
                  this.quill.setSelection(cursorPosition + 1, 0);
                  setIndentListStyle(
                    this.quill,
                    usedListFormatStylesPerLevel,
                    startingLevel,
                    notUsedListStyleTypes,
                    usedListStyleTypes,
                  );
                  return false;
                }
                return true;
              },
            },
          },
        },
        clipboard: {
          matchers: [
            [
              Node.ELEMENT_NODE,
              (node, delta) => {
                // this matcher is used to strip any color/background format in case pasting preformatted data
                delta.ops.forEach(op => {
                  if (op && op.attributes) {
                    for (const attrName of [
                      "color",
                      "background",
                      "code-block",
                      "header",
                      "bold",
                      "italic",
                      "underline",
                    ]) {
                      if (op.attributes[attrName]) {
                        delete op.attributes[attrName];
                      }
                    }
                  }
                });
                return delta;
              },
            ],
          ],
        },
      },
      theme: "snow",
      placeholder: this.props.placeholder || "",
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {props, state} = this;
    if (
      props.disabled !== nextProps.disabled ||
      !_.isEqual(props.contents, nextProps.contents) ||
      !_.isEqual(state.contents, nextState.contents)
    ) {
      return true;
    }
    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const {props, state, quill} = this;
    if (props.updateHandler) {
      props.updateHandler(props, prevProps, state, prevState);
    }
    if (
      !_.isEqual(props.contents, state.contents) &&
      !_.isEqual(props.contents, prevProps.contents)
    ) {
      this.setState({contents: props.contents});
      quill.setContents(props.contents);
    }
    this.setEnableMode();
  }

  componentWillUnmount() {
    if (this.haveContentsChanged()) {
      callIfExists(this.props.changeHandler, this.state.contents);
    }
    document.removeEventListener("click", this.handleClickOutsideQuill);
  }

  addDecimalListWithPrefixClass = () => {
    const {decimalPrefix} = this.props;
    const style = document.createElement("style");
    style.type = "text/css";
    style.innerHTML = `
      .ql-container .ql-editor ol li.ql-list-style-type-decimal:before {
        content: "${
          decimalPrefix ? `${decimalPrefix}.` : ""
        }" counter(list-0, decimal) ${decimalPrefix ? "" : "."};
        padding-right: 0.5em;
      };
    `;
    document.getElementsByTagName("head")[0].appendChild(style);
  };

  handleClickOutsideQuill = (event, shouldUnmount, shouldAbort) => {
    const {getRootEditorRef} = this.props;
    const rootEditorRef =
      (getRootEditorRef && getRootEditorRef()) ||
      this.rootGuiEditorContainerRef;
    const editModePanelRef = document.getElementById("edit-mode-panel");
    const clausesAreTextualDialogNode = document.getElementsByClassName(
      "dialog",
    )[0];
    if (
      shouldUnmount ||
      (rootEditorRef &&
        !rootEditorRef.contains(event.target) &&
        editModePanelRef &&
        !editModePanelRef.contains(event.target) &&
        !containsIfNodeExists(clausesAreTextualDialogNode, event.target))
    ) {
      callIfExists(
        this.props.closeHandler,
        this.getEligibleContents(shouldAbort),
      );
    }
  };

  render() {
    const {toolbarPosition} = this.props;

    return (
      <div
        ref={this.createRootGuiEditorContainerRef}
        style={{
          width: "100%",
          // we use relative here to prevent button tooltips going out of the flow
          position: "relative",
        }}
      >
        {(!toolbarPosition || toolbarPosition === "top") &&
          this.renderToolbar()}
        <div
          style={styles.quillBody}
          ref={_ref => {
            this.quillRef = _ref;
          }}
        />
        {toolbarPosition === "bottom" && this.renderToolbar()}
      </div>
    );
  }

  renderToolbar() {
    const {showListButtonsOnly} = this.props;
    const haveContentsChanged = this.haveContentsChanged();
    return (
      <div
        style={{display: "flex", background: "#fafafa"}}
        ref={_ref => {
          this.toolbarRef = _ref;
        }}
      >
        {showListButtonsOnly || this.renderIndentLastListItemButtons()}
        {this.renderListButtons()}
        {showListButtonsOnly || (
          <ClausepartControl
            itemType={this.props.itemType}
            onAddItemBefore={this.props.onAddItemBefore}
            onAddItemAfter={this.props.onAddItemAfter}
            onRevert={this.props.onRevertClausepartAddition}
            haveContentsChanged={haveContentsChanged}
          />
        )}
        {showListButtonsOnly || this.renderSaveButtons()}
      </div>
    );
  }

  renderSaveButtons = () => {
    if (this.props.skipSaveButton) {
      return null;
    }
    const haveContentsChanged = this.haveContentsChanged();
    return (
      <>
        <Tooltip
          tooltipContent="Save"
          rootDivStyle={tooltipStyles.rootDiv}
          tooltipContainerStyle={tooltipStyles.getTooltipContainerStyles({
            right: "16px",
          })}
          tooltipContentStyle={tooltipStyles.getTooltipContentStyles()}
          delay={300}
        >
          <Done
            style={{
              ...styles.menuItemIcon,
              ...(!haveContentsChanged
                ? {color: colors.grey400, cursor: "unset"}
                : {}),
            }}
            onClick={this.getSaveButtonHandler(haveContentsChanged)}
          />
        </Tooltip>
        <Tooltip
          tooltipContent="Close without saving"
          rootDivStyle={{
            ...tooltipStyles.rootDiv,
            marginLeft: "1.5rem",
            paddingLeft: "0.36rem",
            borderLeft: "1px solid #ccc",
          }}
          tooltipContainerStyle={tooltipStyles.getTooltipContainerStyles({
            right: "16px",
            width: "7rem",
          })}
          tooltipContentStyle={tooltipStyles.getTooltipContentStyles()}
          delay={300}
        >
          <CloseIcon style={styles.menuItemIcon} onClick={this.onAbortEdit} />
        </Tooltip>
      </>
    );
  };

  renderListButtons = () => {
    const {contents} = this.state;
    const hasListInContents =
      contents &&
      contents.ops &&
      contents.ops.find(op => op.attributes && op.attributes.list);

    const buttonsData = [
      {
        tooltipMessage: "List",
        element: (
          <span className="ql-formats">
            <button className="ql-list" value="ordered" />
          </span>
        ),
      },
      {
        tooltipMessage: "Indent Left",
        element: (
          <span
            key="indent-left"
            className="ql-formats"
            style={{visibility: hasListInContents ? "visible" : "hidden"}}
          >
            <button
              className="ql-indent"
              value="-1"
              onClick={this.onListStyleChange}
            />
          </span>
        ),
      },
      {
        tooltipMessage: "Indent Right",
        element: (
          <span
            key="indent-right"
            className="ql-formats"
            style={{visibility: hasListInContents ? "visible" : "hidden"}}
          >
            <button
              className="ql-indent"
              value="+1"
              onClick={this.onListStyleChange}
            />
          </span>
        ),
      },
      {
        tooltipMessage: "List Type",
        element: (
          <span
            className="ql-formats"
            style={{visibility: hasListInContents ? "visible" : "hidden"}}
          >
            <select className="ql-list-style-type">
              <option value="lower-latin" />
              <option value="upper-latin" />
              <option value="lower-roman" />
              <option value="upper-roman" />
            </select>
          </span>
        ),
        // <option value="decimal" />
      },
    ];
    return buttonsData.map(item => this.renderItemWithTooltip(item));
  };

  renderItemWithTooltip = item => (
    <Tooltip
      key={item.tooltipMessage}
      tooltipContent={item.tooltipMessage}
      rootDivStyle={tooltipStyles.rootDiv}
      tooltipContainerStyle={tooltipStyles.getTooltipContainerStyles({
        left: "16px",
      })}
      tooltipContentStyle={tooltipStyles.getTooltipContentStyles({
        width: `${item.tooltipMessage.length * 0.32}rem`,
      })}
      delay={300}
    >
      {item.element}
    </Tooltip>
  );

  renderIndentLastListItemButtons = () => {
    const {
      addNewItemOnTheLevelAboveEndHandler,
      addNewItemOnTheLevelBelowEndHandler,
    } = this.props;
    const buttonsDataArray = [
      addNewItemOnTheLevelAboveEndHandler && {
        tooltipMessage: "Add Footer",
        element: (
          <IndentDecrease
            style={styles.menuItemIcon}
            onClick={this.onIndentDecrease}
          />
        ),
      },
      addNewItemOnTheLevelBelowEndHandler && {
        tooltipMessage: "Add Last List Item",
        element: (
          <IndentIncrease
            style={styles.menuItemIcon}
            onClick={this.onIndentIncrease}
          />
        ),
      },
    ];
    const buttonsData = _.compact(buttonsDataArray);
    return (
      buttonsData &&
      buttonsData.length > 0 && (
        <div style={styles.indentButtonsContainer}>
          {buttonsData.map(item => this.renderItemWithTooltip(item))}
        </div>
      )
    );
  };

  onAbortEdit = () => {
    this.setState(
      () => ({contents: this.props.contents}),
      () => this.handleClickOutsideQuill(null, true, true),
    );
  };

  getSaveButtonHandler = haveContentsChanged => {
    if (!haveContentsChanged) {
      return;
    }
    if (this.props.closeHandler) {
      return () => this.handleClickOutsideQuill(null, true, false);
    }
    return () => this.props.changeHandler(this.state.contents);
  };

  onIndentDecrease = () =>
    this.props.addNewItemOnTheLevelAboveEndHandler(this.state.contents);
  onIndentIncrease = () =>
    this.props.addNewItemOnTheLevelBelowEndHandler(this.state.contents);

  onListStyleChange = () => {
    const {notUsedListStyleTypes, usedListStyleTypes} = this;
    const {usedListFormatStylesPerLevel, startingLevel} = this.props;
    setIndentListStyle(
      this.quill,
      usedListFormatStylesPerLevel,
      startingLevel,
      notUsedListStyleTypes,
      usedListStyleTypes,
    );
  };

  onTextChange = (delta, oldContents, source) => {
    const quillContents = this.quill.getContents();
    // when we create list inside quill - default list type is set up. In
    // case we decide not to save list and remove list reference this default
    // list type still stays. Thus we manually remove attribute from the contents.
    if (source === "user") {
      const defaultListTypeAttributeIndex =
        quillContents &&
        quillContents.ops &&
        quillContents.ops.findIndex(
          op =>
            op.attributes &&
            op.attributes["list-style-type"] &&
            !op.attributes["list"],
        );
      if (
        defaultListTypeAttributeIndex !== -1 &&
        defaultListTypeAttributeIndex !== 0
      ) {
        quillContents.ops[defaultListTypeAttributeIndex - 1].insert = `${
          quillContents.ops[defaultListTypeAttributeIndex - 1].insert
        }\n`;
        quillContents.ops.splice(defaultListTypeAttributeIndex, 1);
      }
    }
    this.setState(() => ({contents: quillContents}));
  };

  haveContentsChanged = () => {
    const contents = this.getEligibleContents();
    if (contents) {
      // here we clone contents object to covert from quill's Delta object type to
      // ordinary object, otherwise comparing Delta vs non-Delta objects with
      // same properies and property values will return false.
      const contentsClone = _.cloneDeep(contents);
      return !_.isEqual(this.props.contents, contentsClone);
    }
    return false;
  };

  getEligibleContents = shouldAbort => {
    const {contents} = this.state;
    if (!contents || !contents.ops || !contents.ops.length || shouldAbort) {
      return;
    }
    const {ops} = contents;
    const contentsLength = ops.length;
    const lastItem = ops[contentsLength - 1];
    const itemBeforeLast = ops[contentsLength - 2];

    if (
      (contentsLength === 1 && getNonEmptyCharLength(lastItem.insert) === 0) ||
      (lastItem.attributes &&
        (!itemBeforeLast &&
          lastItem.attributes.list &&
          lastItem.insert === "\n"))
    ) {
      return;
    }
    return contents;
  };

  initStyleTypeTables(
    contents,
    usedListFormatStylesPerLevel,
    notUsedListStyleTypes,
    usedListStyleTypes,
    startingLevel = 0,
  ) {
    if (!contents || !contents.ops || !usedListFormatStylesPerLevel) {
      return;
    }
    contents.ops.forEach(contentOp => {
      if (
        contentOp &&
        contentOp.attributes &&
        contentOp.attributes["list-style-type"]
      ) {
        const indent = contentOp.attributes.indent
          ? contentOp.attributes.indent + startingLevel + 1
          : startingLevel + 1;
        if (
          usedListFormatStylesPerLevel[indent] ||
          usedListStyleTypes[indent]
        ) {
          return;
        }
        const listStyleType = notUsedListStyleTypes.shift();
        usedListStyleTypes[indent] = listStyleType;
      }
    });
  }

  createRootGuiEditorContainerRef = node =>
    (this.rootGuiEditorContainerRef = node);

  setEnableMode = () => this.quill.enable(Boolean(!this.props.disabled));
}

function setIndentListStyle(
  quill,
  usedListFormatStylesPerLevel,
  startingLevel,
  notUsedListStyleTypes,
  usedListStyleTypes,
) {
  const range = quill.getSelection();
  const quillContents = quill.getContents();
  const itemUnderCursor = getContentItemUnderCursor(range, quillContents);
  const quillItemLevel =
    itemUnderCursor &&
    itemUnderCursor.attributes &&
    itemUnderCursor.attributes.indent
      ? itemUnderCursor.attributes.indent + startingLevel + 1
      : startingLevel + 1;
  let listStyleTypeToUse = getListFormatStyle(
    usedListFormatStylesPerLevel,
    notUsedListStyleTypes,
    usedListStyleTypes,
    quillItemLevel,
  );
  if (listStyleTypeToUse === "decimal") {
    listStyleTypeToUse = "lower-latin";
  }
  quill.format("list", "ordered");
  quill.format("list-style-type", listStyleTypeToUse);
}

function getContentItemUnderCursor(range, contents) {
  if (!range || range.index === undefined || !contents || !contents.ops) {
    return null;
  }
  const cursorPosition = range.index;
  let fullString = "";
  let result;
  const {ops} = contents;
  ops.forEach((contentOpItem, index) => {
    fullString += contentOpItem.insert || "";
    if (fullString.length >= cursorPosition && !result) {
      result =
        ops[index + 1] && ops[index + 1].attributes
          ? ops[index + 1]
          : contentOpItem;
    }
  });
  return result;
}

function getNonEmptyCharLength(str) {
  if (!str) {
    return 0;
  }
  return str.replace(/ |\r\n|\n|\r/gm, "").length;
}

function getListFormatStyle(
  usedListFormatStylesPerLevel,
  notUsedListStyleTypes,
  usedListStyleTypes,
  quillItemLevel,
) {
  if (usedListFormatStylesPerLevel[quillItemLevel]) {
    return listStyleTypeMap[usedListFormatStylesPerLevel[quillItemLevel]];
  }
  if (usedListStyleTypes[quillItemLevel]) {
    const inUsedListStyleType =
      listStyleTypeMap[usedListStyleTypes[quillItemLevel]];
    return inUsedListStyleType;
  }
  const listStyleType = notUsedListStyleTypes.shift();
  usedListStyleTypes[quillItemLevel] = listStyleType;
  return listStyleTypeMap[listStyleType];
}

export default GuiTextEditor;

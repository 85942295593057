import getDomain from "utils/get_domain";

let host =
  process.env.NODE_ENV === "production"
    ? `https://${getSubdomain()}.${getDomain()}.com`
    : "http://localhost:5012";

if (
  process.env.REACT_APP_HTTPS === "true" &&
  process.env.NODE_ENV !== "production"
) {
  host = "https://localhost:5012";
}

function getSubdomain() {
  if (
    document &&
    document.location &&
    (document.location.href.startsWith("https://staging") ||
      document.location.href.startsWith("https://uat-api"))
  ) {
    return "uat-api";
  }
  if (
    document &&
    document.location &&
    document.location.href.startsWith("https://api")
  ) {
    return "api";
  }
  return "app";
}

export default host;

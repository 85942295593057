import _ from "underscore";
import {cloneDeep, get} from "lodash";
import UNINITIALISED from "utils/uninitialised";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function classifiersReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.CLASSIFIERS_FETCH.SUCCESS:
    case ActionTypes.TOPIC_CLASSIFIERS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.TOPIC_CLASSIFIER_UPDATE.SUCCESS: {
      let hasChanged = false;
      if (state === UNINITIALISED) {
        return state;
      }
      const newState = state.map(classifier => {
        if (
          action.payload.configuration_id === classifier.configuration_id &&
          action.payload.last_edited !== classifier.last_edited
        ) {
          hasChanged = true;
          return {
            ...classifier,
            ..._.pick(action.payload, [
              "omit_definitions",
              "omit_definition_indicator",
              "omit_headings",
              "value",
              "configuration_name",
              "is_in_use",
              "dev_mode",
              "last_edited",
            ]),
          };
        }
        return classifier;
      });
      return hasChanged ? newState : state;
    }
    case ActionTypes.TOPIC_CLASSIFIER_ADD.SUCCESS: {
      const filteredState = (state || [])
        .filter(classifier => {
          if (!classifier.configuration_id) {
            if (classifier.name === action.payload.name) {
              return false;
            }
          }
          return (
            action.payload.configuration_id !== classifier.configuration_id
          );
        })
        .concat(action.payload);
      return _.sortBy(filteredState, item => item.id);
    }
    case ActionTypes.TOPIC_CLASSIFIER_LOCK.REQUEST: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(classifier => {
        if (action.payload[3] === classifier.configuration_id) {
          return {
            ...classifier,
            last_value: {
              omit_definitions: classifier.omit_definitions,
              omit_definition_indicator: classifier.omit_definition_indicator,
              omit_headings: classifier.omit_headings,
              ...classifier.value,
            },
            needs_updating: true,
          };
        }
        return classifier;
      });
    }
    case ActionTypes.TOPIC_CLASSIFIER_LOCK.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(classifier => {
        if (action.payload.id === classifier.id) {
          if (action.payload.last_edited > classifier.last_edited) {
            return {
              ...classifier,
              ..._.pick(action.payload, [
                "needs_updating",
                "force_update",
                "last_edited",
              ]),
            };
          }
        }
        return classifier;
      });
    }
    case ActionTypes.TOPIC_CLASSIFIER_JOB_INFO_FETCH.SUCCESS: {
      return state.map(classifier => {
        if (
          parseInt(action.payload.configuration_id, 10) ===
          classifier.configuration_id
        ) {
          return {
            ...classifier,
            training_state: action.payload.status,
          };
        }
        return classifier;
      });
    }
    case ActionTypes.TOPIC_CLASSIFIER_JOB_LOGS_FETCH.SUCCESS: {
      return state.map(classifier => {
        if (
          parseInt(action.payload.configuration_id, 10) ===
          classifier.configuration_id
        ) {
          return {
            ...classifier,
            logs: action.payload.logs,
          };
        }
        return classifier;
      });
    }
    case ActionTypes.CLASSIFIER_GENERATED.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(classifier => {
        if (
          action.payload.log.data.configuration_id ===
          classifier.configuration_id
        ) {
          if (action.payload.log.time > classifier.last_edited) {
            return {
              ...classifier,
              needs_updating: false,
              ...(action.payload.is_in_use !== undefined
                ? {is_in_use: action.payload.is_in_use}
                : {}),
              ...(action.payload.clauses_used !== undefined
                ? {clauses_used: action.payload.clauses_used}
                : {}),
              ...(action.payload.creation_date !== undefined
                ? {creation_date: action.payload.creation_date}
                : {}),
            };
          }
        }
        return classifier;
      });
    }
    case ActionTypes.TOPIC_REGEX_CLASSIFIER_STATS_FETCH.REQUEST: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(classifier => {
        if (classifier.name === "regex") {
          const type = action.payload[3].regex_type;
          const regex = action.payload[3].regex;
          const stats = classifier.stats ?? {};
          const typeStats = stats[type] ?? {};
          return {
            ...classifier,
            stats: {
              ...stats,
              [type]: {...typeStats, [regex]: {isLoading: true}},
            },
          };
        }
        return classifier;
      });
    }
    case ActionTypes.TOPIC_REGEX_CLASSIFIER_STATS_FETCH.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(classifier => {
        if (classifier.name === "regex") {
          const payloadStats = action.payload.stats;
          if (!payloadStats) {
            return classifier;
          }
          if (!classifier.stats) {
            return {
              ...classifier,
              stats: action.payload.stats,
            };
          }
          const newStats = {};
          Object.keys(classifier.stats).forEach(type => {
            newStats[type] = {
              ...classifier.stats[type],
              ...payloadStats[type],
              isLoading: false,
            };
          });

          return {
            ...classifier,
            stats: newStats,
          };
        }
        return classifier;
      });
    }
    case ActionTypes.TOPIC_REGEX_CLASSIFIER_STATS_CLEAR.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      // this is not an async action hence taking data directly from action
      const {regex, regex_type: regexType} = action;
      if (!regex || !regexType) {
        return state.map(classifier => {
          if (classifier.name === "regex") {
            const newClassifier = {...classifier};
            delete newClassifier.stats;
            return newClassifier;
          }
          return classifier;
        });
      }
      return state.map(classifier => {
        if (classifier.name === "regex") {
          const existingStats = get(classifier, ["stats", regexType, regex]);
          if (existingStats) {
            const newClassifier = cloneDeep(classifier);
            delete newClassifier.stats[regexType][regex];
            return newClassifier;
          }
        }
        return classifier;
      });
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import React from "react";
import scrollIntoView from "scroll-into-view";

import {Project} from "common/types/project";
import {Issueset} from "common/types/issueset";
import {User} from "common/types/user";
import {IssueId, GroupedIssues, GroupedIssueItem} from "common/types/issue";

import IssueGroup from "./issue_group";
import IssueItem from "./issue_item";
import getUnrecognisedClauses from "utils/clauses/unrecognised_clauses/get_unrecognised_clauses";
import convertUnrecognisedClausesToIssues from "utils/clauses/unrecognised_clauses/convert_unrecognised_clauses_to_issues";

import byId from "common/utils/by_id";
import calculateDocumentRagState from "common/utils/issues/rag_state";
import Permissioner from "utils/permissioner";

import FlatButton from "material-ui/FlatButton";
import sortIssueItems from "utils/issues/sort_issue_items";
import issueHeaderUtils from "utils/issues/issue_header_utils";

const styles = {
  body: isFlipChecklistIcons => ({
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: 0,
    marginBottom: 15,
    fontFamily: isFlipChecklistIcons
      ? "Segoe UI, Roboto, sans-serif"
      : "Roboto, sans-serif",
    backgroundColor: isFlipChecklistIcons ? "#fff" : "#232e38",
    paddingTop: isFlipChecklistIcons ? "0" : "15px",
  }),
  noMatchesContainer: {
    textAlign: "center",
    margin: "20px",
    color: "#50b2ff",
  },
};

type ChecklistComponentProps = {
  project: Project;
  groupedIssues: unknown;
  areIssuesCollapsed: boolean;
  showClausesWithoutTopics: boolean;
  itemIssuesFilterValues: unknown;
  editPlaybookMode: boolean;
  isFlipChecklistIcons: boolean;
  isSorting: boolean;
  switchEditPlaybookMode: () => void;
  bottomPaddingOffset: number;
  currentIssuesetItem: Issueset;
  issueDetailData: unknown;
  correctDocumentIssueManually: unknown;
  user: User;
  showIssueDetail: unknown;
  updateIssueDetail: unknown;
  onIssueCheckboxCheck: unknown;
  currentIssueset: unknown;
  showIssuesInChecklist: boolean;
  viewMode: unknown;
  updateDocumentIssue: unknown;
  positiveReasonData: unknown;
  isOpenByDefault: boolean;
  document: unknown;
  documentClauses: unknown;
  documentClauseparts: unknown;
  documentHeadings: unknown;
  topicsById: unknown;
  openGroupsWithIssuesByDefault: boolean;
  issueIdToHighlight: IssueId;
  location: {search: string; pathname: string};
  showIssue: unknown;
  displayHiddenIssues: unknown;
  clientModeOn: boolean;
  masterIssuesetAmount: unknown;
  contractTypesById: unknown;
  issuesById: unknown;
  togglePlaybookState: unknown;
  playbookState: unknown;
  isDocumentDetailPageShown: unknown;
  selectedReport: unknown;
  selectedReportId: unknown;
  highlightIssueIds: unknown;
  showCompareIssues: unknown;
  showComparisonDetail: unknown;
  reviewTrackingOn: unknown;
  preventMemo: boolean;
  updateBottomPaddingOffset: unknown;
  expandAllIssueState: unknown;
  useLlm: boolean;
};

export default class Checklist extends React.Component<ChecklistComponentProps> {
  issueState: unknown;

  componentDidMount() {
    if (
      !this.props.location ||
      this.props.location.pathname.indexOf("issue") === -1
    ) {
      return null;
    }
    const pathIssueId = parseInt(
      this.props.location.pathname.split("/")[4] ?? "0",
      10,
    );
    const scroller = document.getElementById(
      `checklist-issue-item-${pathIssueId}`,
    );
    scrollIntoView(scroller, {
      time: 100,
      align: {
        top: 0.3,
        topOffset: 0,
      },
    });
  }

  render() {
    const {
      groupedIssues,
      showClausesWithoutTopics,
      itemIssuesFilterValues,
      project,
    } = this.props;
    const filteredGroupedIssues = this.filterGroupedIssues(groupedIssues);
    const issues = this.getFlatIssues(filteredGroupedIssues);
    return (
      <>
        {this.props.editPlaybookMode && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              fontFamily: this.props.isFlipChecklistIcons
                ? "Segoe UI, Roboto, sans-serif"
                : "Roboto, sans-serif",
            }}
          >
            <FlatButton
              onClick={() => this.props.switchEditPlaybookMode()}
              style={{
                backgroundColor: "#fff",
                marginTop: "0.5em",
                padding: "0 1em",
              }}
            >
              Exit edit mode
            </FlatButton>
          </div>
        )}
        {this.renderRagScore(project, issues)}
        <div
          className="body"
          id="checklistBody"
          style={{
            ...styles.body(this.props.isFlipChecklistIcons),
            paddingBottom: this.props.bottomPaddingOffset,
          }}
        >
          {this.renderList(
            filteredGroupedIssues.every(value => value.item.length === 0),
            itemIssuesFilterValues?.sort,
            filteredGroupedIssues,
            showClausesWithoutTopics,
          )}
        </div>
      </>
    );
  }

  filterGroupedIssues(groupedIssues) {
    if (!this.props.clientModeOn) {
      return groupedIssues;
    } else {
      const filteredGroupedIssues = groupedIssues
        .map(group => ({
          ...group,
          ...(group.isGroup
            ? {item: group.item.filter(item => !item.report_only)}
            : {}),
        }))
        .filter(group => !group.isGroup || group.item.length > 0);
      return filteredGroupedIssues;
    }
  }

  renderRagScore(project, issues) {
    if (!project.show_rag_score) {
      return null;
    }
    const issuesWithRagScores = issues.map(issue => ({
      ...issue,
      max_rag_score: issueHeaderUtils.getMaxRagScore(issue),
      rag_score: issueHeaderUtils.getRagScore(issue),
    }));
    const ragStateNew = calculateDocumentRagState(
      issuesWithRagScores,
      project.bulk_report_settings?.rag_state_thresholds,
      true,
    );
    const ragStateOld = calculateDocumentRagState(
      issuesWithRagScores,
      project.bulk_report_settings?.rag_state_thresholds,
      false,
    );
    const colors = {
      red: "red",
      amber: "orange",
      green: "green",
    };
    return (
      <div
        style={{
          margin: "0 16px",
          marginTop: "11px",
          display: "flex",
          justifyContent: "space-between",
          fontSize: "1.3em",
          padding: "8px",
          border: "1px solid #888",
        }}
      >
        <span
          style={{
            fontStyle: "italic",
          }}
        >
          Risk Score:
        </span>
        <div style={{display: "flex"}}>
          {ragStateNew.score !== ragStateOld.score && (
            <div style={{zoom: 0.8, display: "flex", alignItems: "center"}}>
              <div
                style={{
                  width: "1em",
                  backgroundColor: colors[ragStateOld.color],
                  border: "1px solid white",
                  marginRight: "9px",
                  height: "75%",
                }}
              />
              <span>{`${Math.floor(
                ragStateOld.score * 100,
              ).toString()}%`}</span>
              <span style={{margin: "0 0.5em"}}>⇒</span>
            </div>
          )}
          <div
            style={{
              width: "1em",
              backgroundColor: colors[ragStateNew.color],
              border: "1px solid white",
              marginRight: "9px",
            }}
          />
          <span>{`${Math.floor(ragStateNew.score * 100).toString()}%`}</span>
        </div>
      </div>
    );
  }

  getFlatIssues(groupedIssues: GroupedIssues): GroupedIssueItem[] {
    const extractIssues = issueGroupOrItem => {
      if (issueGroupOrItem.item) {
        return getSubissues(issueGroupOrItem.item);
      }
      return [issueGroupOrItem];
    };

    const getSubissues = issues => {
      return issues.reduce((issueList, groupedIssue) => {
        const flatIssues = extractIssues(groupedIssue);
        issueList.push(...flatIssues);
        return issueList;
      }, []);
    };

    return getSubissues(groupedIssues);
  }

  renderList(
    isGroupedIssues,
    sortKey,
    groupedIssues,
    showClausesWithoutTopics,
  ) {
    if (isGroupedIssues) {
      return (
        <div style={styles.noMatchesContainer}>No issues match filters!</div>
      );
    }
    if (sortKey && sortKey !== "Default" && this.props.isSorting) {
      return this.renderSortedList(groupedIssues, sortKey);
    }
    return this.renderDefaultOrderedList(
      groupedIssues,
      showClausesWithoutTopics,
    );
  }

  renderSortedList(groupedIssues, sortKey) {
    const canViewIssueErrors = new Permissioner(this.props.user).hasPermission(
      "view-issue-errors",
    );
    return sortIssueItems(
      groupedIssues,
      sortKey,
      this.props.currentIssuesetItem,
      this.props.project,
    ).map((item, index) =>
      this.renderSingleIssue(
        index,
        item,
        this.props.issueDetailData?.issue?.id,
        this.props.correctDocumentIssueManually,
        canViewIssueErrors,
        true,
      ),
    );
  }

  renderDefaultOrderedList(groupedIssues, showClausesWithoutTopics) {
    const {issueDetailData, correctDocumentIssueManually} = this.props;
    const selectedIssueId = this.props.issueDetailData
      ? this.props.issueDetailData.issue.id
      : null;

    const canViewIssueErrors = new Permissioner(this.props.user).hasPermission(
      "view-issue-errors",
    );

    const result = groupedIssues.map((item, index) => {
      if (item.isGroup) {
        const shouldBeOpened = Boolean(
          issueDetailData &&
            item.item.find(issue => issueDetailData.issue.id === issue.id),
        );
        return (
          <IssueGroup
            key={index}
            user={this.props.user}
            project={this.props.project}
            document={this.props.document}
            group={item}
            areIssuesCollapsed={this.props.areIssuesCollapsed}
            documentClauses={this.props.documentClauses}
            documentClauseparts={this.props.documentClauseparts}
            topicsById={this.props.topicsById}
            selectedIssueId={selectedIssueId}
            showIssueDetail={this.props.showIssueDetail}
            updateIssueDetail={this.props.updateIssueDetail}
            showIssuesInChecklist={this.props.showIssuesInChecklist}
            viewMode={this.props.viewMode}
            updateDocumentIssue={this.props.updateDocumentIssue}
            positiveReasonData={this.props.positiveReasonData}
            onIssueCheckboxCheck={this.props.onIssueCheckboxCheck}
            currentIssueset={this.props.currentIssueset}
            currentIssuesetItem={this.props.currentIssuesetItem}
            location={this.props.location}
            isOpenByDefault={
              this.props.openGroupsWithIssuesByDefault || shouldBeOpened
            }
            issueIdToHighlight={this.props.issueIdToHighlight}
            showIssue={this.props.showIssue}
            displayHiddenIssues={this.props.displayHiddenIssues}
            bottomPaddingOffset={this.props.bottomPaddingOffset}
            updateBottomPaddingOffset={this.props.updateBottomPaddingOffset}
            clientModeOn={this.props.clientModeOn}
            contractTypesById={this.props.contractTypesById}
            issuesById={this.props.issuesById}
            editPlaybookMode={this.props.editPlaybookMode}
            togglePlaybookState={this.props.togglePlaybookState}
            playbookState={this.props.playbookState}
            expandAllIssueState={this.props.expandAllIssueState}
            isDocumentDetailPageShown={this.props.isDocumentDetailPageShown}
            selectedReport={this.props.selectedReport}
            selectedReportId={this.props.selectedReportId}
            highlightIssueIds={this.props.highlightIssueIds}
            isFlipChecklistIcons={this.props.isFlipChecklistIcons}
            correctDocumentIssueManually={correctDocumentIssueManually}
            showCompareIssues={this.props.showCompareIssues}
            showComparisonDetail={this.props.showComparisonDetail}
            canViewIssueErrors={canViewIssueErrors}
            reviewTrackingOn={this.props.reviewTrackingOn}
            preventMemo={this.props.preventMemo}
          />
        );
      }
      const issue = item.item[0];
      return this.renderSingleIssue(
        index,
        issue,
        selectedIssueId,
        correctDocumentIssueManually,
        canViewIssueErrors,
        false,
      );
    });
    if (showClausesWithoutTopics) {
      const unrecognisedClauses = getUnrecognisedClauses(
        this.props.documentClauses,
      );
      const unrecognisedClausesAsIssues = convertUnrecognisedClausesToIssues(
        unrecognisedClauses,
        byId(this.props.documentHeadings, "reference"),
      );
      if (unrecognisedClausesAsIssues.length > 0) {
        const unrecognisedClausesGroupData = {
          name: "Unrecognised Clauses",
          isGroup: true,
          item: unrecognisedClausesAsIssues,
        };
        const unrecognisedClausesGroup = (
          <IssueGroup
            key="unrecognised_clauses"
            group={unrecognisedClausesGroupData}
            selectedIssueId={selectedIssueId}
            showIssueDetail={this.props.showIssueDetail}
            updateIssueDetail={this.props.updateIssueDetail}
            onIssueCheckboxCheck={this.props.onIssueCheckboxCheck}
            currentIssueset={this.props.currentIssueset}
            currentIssuesetItem={this.props.currentIssuesetItem}
            user={this.props.user}
            project={this.props.project}
            location={this.props.location}
            isOpenByDefault={this.props.openGroupsWithIssuesByDefault}
            issueIdToHighlight={this.props.issueIdToHighlight}
            showIssue={this.props.showIssue}
            displayHiddenIssues={this.props.displayHiddenIssues}
            masterIssuesetAmount={this.props.masterIssuesetAmount}
            contractTypesById={this.props.contractTypesById}
            issuesById={this.props.issuesById}
            isDocumentDetailPageShown={this.props.isDocumentDetailPageShown}
            selectedReport={this.props.selectedReport}
            selectedReportId={this.props.selectedReportId}
            highlightIssueIds={this.props.highlightIssueIds}
            isFlipChecklistIcons={this.props.isFlipChecklistIcons}
            correctDocumentIssueManually={
              this.props.correctDocumentIssueManually
            }
            canViewIssueErrors={canViewIssueErrors}
            preventMemo={this.props.preventMemo}
          />
        );
        result.push(unrecognisedClausesGroup);
      }
    }
    return result;
  }

  renderSingleIssue = (
    index,
    issue,
    selectedIssueId,
    correctDocumentIssueManually,
    canViewIssueErrors,
    isShowGroupTitle,
  ) => (
    <div key={index}>
      <IssueItem
        key={index}
        user={this.props.user}
        project={this.props.project}
        document={this.props.document}
        issue={issue}
        documentClauses={this.props.documentClauses}
        documentClauseparts={this.props.documentClauseparts}
        topicsById={this.props.topicsById}
        selectedIssueId={selectedIssueId}
        showIssueDetail={this.props.showIssueDetail}
        updateIssueDetail={this.props.updateIssueDetail}
        showIssuesInChecklist={this.props.showIssuesInChecklist}
        viewMode={this.props.viewMode}
        updateDocumentIssue={this.props.updateDocumentIssue}
        positiveReasonData={this.props.positiveReasonData}
        onIssueCheckboxCheck={this.props.onIssueCheckboxCheck}
        isOpenByDefault={this.props.openGroupsWithIssuesByDefault}
        location={this.props.location}
        issueIdToHighlight={this.props.issueIdToHighlight}
        currentIssuesetItem={this.props.currentIssuesetItem}
        showIssue={this.props.showIssue}
        displayHiddenIssues={this.props.displayHiddenIssues}
        clientModeOn={this.props.clientModeOn}
        masterIssuesetAmount={this.props.masterIssuesetAmount}
        contractTypesById={this.props.contractTypesById}
        issuesById={this.props.issuesById}
        editPlaybookMode={this.props.editPlaybookMode}
        togglePlaybookState={this.props.togglePlaybookState}
        playbookState={this.props.playbookState}
        isDocumentDetailPageShown={this.props.isDocumentDetailPageShown}
        selectedReport={this.props.selectedReport}
        selectedReportId={this.props.selectedReportId}
        highlightIssueIds={this.props.highlightIssueIds}
        isFlipChecklistIcons={this.props.isFlipChecklistIcons}
        correctDocumentIssueManually={correctDocumentIssueManually}
        showCompareIssues={this.props.showCompareIssues}
        showComparisonDetail={this.props.showComparisonDetail}
        canViewIssueErrors={canViewIssueErrors}
        reviewTrackingOn={this.props.reviewTrackingOn}
        isShowGroupTitle={isShowGroupTitle}
        preventMemo={this.props.preventMemo}
      />
    </div>
  );

  toggleIssueUse = issue => {
    this.issueState = this.issueState || {};
    this.issueState[issue.id] =
      this.issueState[issue.id] === undefined
        ? false
        : !this.issueState[issue.id];
  };
}

import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import actionTypes from "../constants/action_types";
import {WorkflowInstance} from "common/flowmaster/types/workflow";
import {WorkflowPrerequisiteFetchAction} from "../actions/workflow_prerequisite_fetch";

export default function workflowsReducer(
  state: MaybeUninitialised<WorkflowInstance[]> = UNINITIALISED,
  action: WorkflowPrerequisiteFetchAction,
): typeof state {
  if (action.type === actionTypes.WORKFLOW_PREREQUISITE_FETCH.SUCCESS) {
    return (action as WorkflowPrerequisiteFetchAction).payload;
  }
  return state;
}

import issuesetUtils from "common/utils/issues/issueset_utils";

function getDefaultIssuesetId(
  document,
  project,
  contractTypesById,
  availableIssuesets,
) {
  if (document.contract_type.id !== project.default_contract_type.id) {
    const documentCT = contractTypesById[document.contract_type.id] || {
      issuesets: [],
    };
    const defaultIssueset = documentCT.issuesets.find(
      getDocumentDefaultIssueset,
    );
    if (defaultIssueset) {
      return defaultIssueset.id;
    }
    const allDocumentCTIssuesetIds = documentCT.issuesets.map(is => is.id);
    const availableIssuesetIds =
      availableIssuesets &&
      allDocumentCTIssuesetIds.filter(issuesetId =>
        availableIssuesets.find(id => id === issuesetId),
      );
    const documentCTIssuesetIds =
      availableIssuesetIds && availableIssuesetIds.length > 0
        ? availableIssuesetIds
        : allDocumentCTIssuesetIds;
    const minIssuesetId = Math.min.apply(null, documentCTIssuesetIds);
    return minIssuesetId ? minIssuesetId : 0;
  }

  const issuesetsById = issuesetUtils.getIssuesetsById(contractTypesById);
  if (project.document_type_id === 4) {
    let allIssuesets = [...document.issuesets, ...project.issuesets];
    if (allIssuesets.length === 0) {
      allIssuesets = Object.keys(issuesetsById).map(isId => parseInt(isId, 10));
    }
    const defaultIssueset = allIssuesets
      .map(issuesetId => ({
        id: issuesetId,
        name: issuesetsById[issuesetId].name,
      }))
      .find(is => is.name.toLowerCase().indexOf("special conditions") !== -1);
    if (defaultIssueset) {
      return defaultIssueset.id;
    }
  } else {
    const {issuesets: documentIssuesets} = document;
    const validDocumentIssuesets = (documentIssuesets || []).filter(
      documentIssuesetId => issuesetsById[documentIssuesetId],
    );

    const documentDefaultIssueset =
      validDocumentIssuesets
        .map(documentIssuesetId => ({
          id: documentIssuesetId,
          name: issuesetsById[documentIssuesetId].name,
        }))
        .find(getDocumentDefaultIssueset) ||
      (validDocumentIssuesets.length &&
        issuesetsById[validDocumentIssuesets[0]]);
    if (documentDefaultIssueset) {
      return documentDefaultIssueset.id;
    }

    const {issuesets: projectIssuesetIds} = project;
    const projectDefaultIssuesetId = projectIssuesetIds[0];
    if (projectDefaultIssuesetId) {
      return projectDefaultIssuesetId;
    }
    const {id: defaultContractTypeId} = project.default_contract_type;
    const {issuesets: defaultCtIssuesets} = contractTypesById[
      defaultContractTypeId
    ];
    const ctDefaultIssueset =
      defaultCtIssuesets.find(is => is.name === "Default") ||
      defaultCtIssuesets[0];
    if (ctDefaultIssueset) {
      return ctDefaultIssueset.id;
    }
  }
  return 0;
}

function getDocumentDefaultIssueset(issueset) {
  return issueset.name.toLowerCase().indexOf("default") !== -1;
}

export default getDefaultIssuesetId;

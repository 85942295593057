import React from "react";
import _ from "lodash";

import grey from "@material-ui/core/colors/grey";

import TopPanelContainer from "../top_panel_container";
import ReportItem from "../report_item";

import getReportIssues from "../utils/get_report_issues";
import getIssuesReportData from "../utils/get_issues_report_data";

import constructCustomReportTable from "./construct_custom_report_table";
import constructReportFilename from "../utils/construct_report_filename";
import constructReportDocxDocument from "../utils/construct_report_docx_document";
import downloadDocx from "../utils/download_docx";
import constructSubissues from "common/utils/issues/construct_subissues";

const styles = {
  contentMain: {
    width: "100%",
    color: grey[600],
  },
};

function constructReportRows(baseRows) {
  return baseRows.map(row => {
    if (row.type === "clause_references" || row.type === "clause_text") {
      return {
        ...row,
        type: "clause_display",
        value: row.type,
      };
    } else if (row.type === "free_text") {
      return row;
    }
    return {
      ...row,
      value: true,
    };
  });
}

/* eslint-disable no-continue */
function filterIssues(issues, reportSettingsRows) {
  const result = {
    reportRows: [],
    issuesInReport: [],
  };
  if (!reportSettingsRows) {
    return result;
  }
  for (const reportRow of reportSettingsRows) {
    if (!reportRow.use_issue_value) {
      result.reportRows.push(reportRow);
      continue;
    }

    const matchedIssue = issues.find(
      issue =>
        issue.name.toLowerCase().indexOf(reportRow.issue_name.toLowerCase()) !==
        -1,
    );
    const subissues = issues.filter(_issue =>
      _issue.display_name.startsWith(`${matchedIssue.display_name} /`),
    );
    const isInGroup = matchedIssue.name.match(/\//);
    const allIssues = [matchedIssue, ...subissues];
    const allIssuesFixed = isInGroup
      ? allIssues.map(issue => ({
          ...issue,
          name: issue.name.replace(/^(.*?\/ )/, ""),
        }))
      : allIssues;
    const matchedIssueWithSubissues = constructSubissues(allIssuesFixed)[0];

    if (matchedIssue) {
      result.reportRows.push({
        ...reportRow,
        document_issue_id: matchedIssueWithSubissues.document_issue_id, // for easier further search
      });
      result.issuesInReport.push(matchedIssueWithSubissues);
    }
  }
  return result;
}

function CustomReport(props) {
  const {reportSettings = {}, renderInSeparateWindow} = props;
  const reportSettingsRows = constructReportRows(reportSettings.rows);

  // issues which exist in document issues
  const {reportRows, issuesInReport: baseIssuesInReport} = filterIssues(
    props.issues,
    reportSettingsRows,
  );

  const reportIssues = getReportIssues(baseIssuesInReport, props.groupedIssues);
  const issuesReportData = getIssuesReportData(reportIssues, props);
  const customReportRows = constructCustomReportTable(
    issuesReportData,
    reportRows,
    props,
  );

  const reportLabel = reportSettings ? reportSettings.label : "Custom Report";

  const onDocxDownload = () => {
    downloadDocx(
      constructReportDocxDocument(
        null,
        customReportRows,
        reportLabel,
        reportSettings,
      ),
      constructReportFilename("Custom Report", props.document, reportLabel),
    );
  };

  const updateFreeTextFieldValue = (fieldId, fieldValue) => {
    const documentReportSettings = props.document.report_settings || [];
    const newReportSettings = _.cloneDeep(reportSettings);
    newReportSettings.rows.forEach(row => {
      if (row.id === fieldId) {
        row.value = fieldValue;
      }
    });
    const newDocumentReportSettings = _.cloneDeep(documentReportSettings);
    const reportIndex = documentReportSettings.findIndex(
      report => report.id === reportSettings.id,
    );
    if (reportIndex === -1) {
      newDocumentReportSettings.push(newReportSettings);
    } else {
      newDocumentReportSettings[reportIndex] = newReportSettings;
    }
    props.updateDocument({report_settings: newDocumentReportSettings});
  };

  // stub function so that there is no error
  const doSomething = () => {};

  const headerItems = [
    {
      width: "0.5",
      title: "",
    }, // deleted issue
    {
      width: "1",
      title: "",
    }, // open issue detail button
    {
      width: 50,
      title: "",
    },
    {
      width: 50,
      title: "",
    },
    {
      width: "1",
      title: "",
    }, // remove issue from report button
  ];

  return (
    <TopPanelContainer
      renderInSeparateWindow={props.renderInSeparateWindow}
      triangleRightOffset="82"
      rootDivStyles={{
        boxShadow: "none",
        borderBottom: "1px solid #e8e8e8",
      }}
      hasExpandableView={true}
      onDownloadDocx={onDocxDownload}
      highlightManualCorrections={props.highlightManualCorrections}
      triggerHighlightManualCorrections={
        props.triggerHighlightManualCorrections
      }
      documentId={props.document.id}
      projectId={props.project.id}
      organisationId={props.organisationId}
      isDocumentReport={props.isDocumentReport}
      reportId={props.reportId}
      project={props.project}
      toggleReportPanelVisibility={props.toggleReportPanelVisibility}
      startSelectedReportEditing={props.startSelectedReportEditing}
      reportType={props.reportType}
      reportLabel={props.reportSettings.label}
    >
      <div className="content" style={styles.contentMain}>
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            borderSpacing: 0,
            fontFamily: "Roboto",
          }}
        >
          <thead>
            <tr
              style={{
                borderBottom: `1px solid ${grey[300]}`,
                height: "26px",
              }}
            >
              {headerItems.map((baseItem, baseItemIndex) => (
                <th
                  key={`header-${baseItemIndex}`}
                  style={{
                    width: `${baseItem.width}%`,
                    backgroundColor: "#fff",
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                  }}
                >
                  {baseItem.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody
            style={{
              fontWeight: 100,
              verticalAlign: "top",
            }}
          >
            {customReportRows.map((item, itemIndex) => (
              <ReportItem
                key={itemIndex}
                index={itemIndex}
                item={item}
                hideNonTextItems={true}
                scrollToClause={props.scrollToClause}
                correctDocumentIssueManually={
                  props.correctDocumentIssueManually
                }
                issues={reportIssues}
                isLastItem={itemIndex === customReportRows.length - 1}
                onShowIssueDetail={props.onShowIssueDetail}
                selectedReportId={props.selectedReportId}
                renderInSeparateWindow={renderInSeparateWindow}
                updateDocumentIssue={props.updateDocumentIssue}
                updateFreeTextFieldValue={updateFreeTextFieldValue}
                project={props.project}
                user={props.user}
                isDocumentReport={props.isDocumentReport}
                showButtonsHandler={doSomething}
              />
            ))}
          </tbody>
        </table>
      </div>
    </TopPanelContainer>
  );
}

export default CustomReport;

import _ from "underscore";
import basePath from "base_path";
import requestor from "requestor";
import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.SEARCH,
  (organisationId, type, queryValue, filters) => {
    const parts = [];
    parts.push(`type=${type}`);
    if (type === "text") {
      const filteredQuery =
        _.isString(queryValue) && !_.isEmpty(queryValue)
          ? queryValue.replace(/[^\d\w\s-]+/g, "")
          : "";
      if (filteredQuery) {
        parts.push(`query=${filteredQuery}`);
      }
      if (_.isString(filters)) {
        parts.push(filters);
      }
    }
    if (type === "logs") {
      parts.push(`query=${queryValue}`);
    }
    const searchString = `?${parts.join("&")}`;
    return requestor
      .get(`${basePath}/organisation/${organisationId}/search${searchString}`, {
        withCredentials: true,
      })
      .then(response => {
        return response.data;
      });
  },
);

import React from "react";

import MenuIcon from "@material-ui/icons/Menu";
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab";

import SidebarContext from "./context";
import isContractReview from "utils/is_contract_review";

export default class Header extends React.Component {
  render() {
    const {user = {}, environment} = this.props;
    const iconColor =
      user.is_developer && environment === "production"
        ? "#f44336"
        : " #646c73";
    return (
      <div
        className="app-sidebar__header"
        onClick={() => this.context.toggleExpandability()}
      >
        {(() => {
          if (this.context.isExpanded) {
            return (
              <React.Fragment>
                {isContractReview() ? (
                  <div className="app-sidebar__header-logo">
                    <div>CONRACT</div>
                    <div>REVIEW.COM</div>
                  </div>
                ) : (
                  <div className="app-sidebar__header-logo">
                    <div>LEXICAL</div>
                    <div>LABS</div>
                  </div>
                )}
                <KeyboardTabIcon
                  className="app-sidebar__header-icon"
                  style={{color: iconColor}}
                />
              </React.Fragment>
            );
          }
          return <MenuIcon style={{color: iconColor}} />;
        })()}
      </div>
    );
  }
}

Header.contextType = SidebarContext;

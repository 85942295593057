import React from "react";

import EmailReportSettings from "./email_report_settings";
import RagReportSettings from "./rag_report_settings";
import CustomReportSettings from "./custom_report_settings";

function renderProjectSettings(selectedReportType, reportProps) {
  switch (selectedReportType) {
    case "rag_report":
      return <RagReportSettings {...reportProps} />;
    case "email":
      return <EmailReportSettings {...reportProps} />;
    case "custom_report":
      return <CustomReportSettings {...reportProps} />;
    default:
      return null;
  }
}

export default renderProjectSettings;

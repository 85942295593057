import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function generateDocumentIssueLlmPrompt(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_ISSUE_CONVERSATION_FETCH,
    (
      organisationId,
      projectId,
      documentId,
      issueId,
      conversationId,
      conversationType,
      issuesetMasterId,
      issuesetClientId,
    ) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            `/issue/${issueId}` +
            `/conversation/${conversationId}`,
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          conversation_type: conversationType,
          issueset_master_id: issuesetMasterId,
          issueset_remote_client_id: issuesetClientId,
        })),
  );
}

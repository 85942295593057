import React from "react";
import moment from "moment";

import * as colors from "@material-ui/core/colors";

import TopPanelContainer from "../top_panel_container";
import downloadDocx from "../utils/download_docx";
import constructDocument from "./construct_document";

const values = {
  14922: {
    "facilities agreement":
      "FACILITY AGREEMENT FOR A USD 126,000,000 TERM LOAN FACILITY",
    "31st December, 2019": "13th November 2014",
    "A Bank": "ABC Global Bank",
    "B Bank": "CREDIT SUISSE AG",
    "Software Ventures PLC": "NAVIOS MARITIME MIDSTREAM PARTNERS L.P.",
    "C Bank": "CREDIT SUISSE (UK) LTD",
    "Clause 25.5": "Clause 15.3 (Transfer/assignment by Lender)",
    "Transfer Date": "20th October 2020",
    "<Address of Lender>": "Five Cabot Square, London, E14 4QR, United Kingdom",
  },
  14956: {
    "facilities agreement": "Facility agreement",
    "31st December, 2019": "18th August 2019",
    "A Bank": "ABC Global Bank",
    "B Bank": "Premium Financial Ltd",
    "Software Ventures PLC": "Global Company Ltd",
    "C Bank": "Fundamental Trust Ltd",
    "Clause 25.5": "Clause 19 (Assignment and Transfer)",
    "Transfer Date": "20th October 2020",
    "<Address of Lender>": "Cromwell Rd, Knightsbridge, London SW7 2RL",
  },
  14960: {
    "facilities agreement":
      "Credit Agreement regulated by the Consumer Credit Act 1974. Running Account Credit. ",
    "31st December, 2019": "24th March 2016",
    "A Bank": "ABC Global Bank",
    "B Bank": "Next Retail Limited",
    "Software Ventures PLC": "Paul Brown",
    "C Bank": "Next Sourcing Ltd",
    "Clause 25.5": "Clause 12",
    "Transfer Date": "20th October 2020",
    "<Address of Lender>": "200 Tottenham Court Rd, Bloomsbury, London W1T 7PL",
  },
  14883: {
    "facilities agreement": "FACILITY AGREEMENT",
    "31st December, 2019": "40094",
    "A Bank": "ABC Global Bank",
    "B Bank": "MGT CAPITAL INVESTMENTS LIMITED",
    "Software Venture PLC": "MONEYGATE GROUP LIMITED",
    "C Bank": "N/A",
    // "Clause 25.5": "N/A",
    "Transfer Date": "20th October 2020",
    "Address of New Lender": "Kingsway, Holborn, London WC2B 6AA",
  },
  14961: {
    "facilities agreement": "Facility AGREEMENT",
    "31st December, 2019": "April 6th 2008",
    "A Bank": "ABC Global Bank",
    "B Bank": "The Council of the City of York of the Guildhall York",
    "Software Venture PLC": "SCIENCE CITY YORK",
    "C Bank": "Global Networks Bank",
    "Clause 25.5": "Clause 4.2",
    "Transfer Date": "20th October 2020",
    "Address of New Lender": "17 Russell Square, Bloomsbury, London WC1B 5DR",
  },
};

function getValue(documentId, text) {
  if (values[documentId]) {
    const value = values[documentId][text];
    return value !== undefined ? value : text;
  }
  return text;
}

const styles = {
  highlight: {
    backgroundColor: colors.blue[50],
    padding: "2px",
  },
};

function constructFilename(document) {
  if (!document || !document.name) {
    return "Transfer_of_Certificate.docx";
  }
  const fullName = document.name;
  const lastDotIndex = fullName.lastIndexOf(".");
  const name =
    lastDotIndex > 0 ? fullName.substring(0, lastDotIndex) : fullName;
  return `${name}-transfer_of_certificate.docx`;
}

function TransferCertificate(props) {
  const onDocxDownload = () => {
    downloadDocx(constructDocument(), constructFilename(props.document));
  };
  const {id: documentId} = props.document;
  const lookup = str => getValue(documentId, str);
  return (
    <TopPanelContainer
      renderInSeparateWindow={props.renderInSeparateWindow}
      triangleRightOffset="82"
      rootDivStyles={props.rootDivStyles}
      hasExpandableView={true}
      onDownloadDocx={onDocxDownload}
    >
      <div>
        <p style={{textAlign: "center"}}>
          <strong>Transfer Certificate</strong>
        </p>
        <p>
          To: <span style={styles.highlight}>{lookup("A Bank")}</span> as Agent
        </p>
        <p>
          From: <span style={styles.highlight}>{lookup("B Bank")}</span> (the{" "}
          <strong>"Existing Lender"</strong>) and{" "}
          <span style={styles.highlight}>{lookup("C Bank")}</span> (the{" "}
          <strong>"New Lender"</strong>)
        </p>

        <p style={{textAlign: "center"}}>
          <strong>
            <span style={styles.highlight}>
              {lookup("Software Ventures PLC")} –{" "}
              {lookup("facilities agreement")}
            </span>{" "}
            dated{" "}
            <span style={styles.highlight}>
              {lookup("31st December, 2019")}
            </span>
            (the "Agreement")
          </strong>
        </p>

        <ol>
          <li>
            <p style={{textAlign: "justify"}}>
              We refer to the Agreement. This is a Transfer Certificate. Terms
              defined in the Agreement have the same meaning in this Transfer
              Certificate unless given a different meaning in this Transfer
              Certificate.
            </p>
          </li>
          <li>
            <p style={{textAlign: "justify"}}>
              We refer to{" "}
              <span style={styles.highlight}>
                {lookup("Clause 25.5")} (Procedure for transfer)
              </span>
              :
            </p>
            <ol type="a">
              <li>
                The Existing Lender and the New Lender agree to the Existing
                Lender transferring to the New Lender by novation all or part of
                the Existing Lender's Commitment, rights and obligations
                referred to in the Schedule in accordance with{" "}
                <span style={styles.highlight}>{lookup("Clause 25.5")}</span>
                (Procedure for transfer).
              </li>
              <li>
                The proposed Transfer Date is{" "}
                <span style={styles.highlight}>
                  {moment().format("MMMM Do YYYY")}
                </span>
                .
              </li>
              <li>
                The Facility Office and address, email address and attention
                details for notices of the New Lender are set out in the
                Schedule.
              </li>
            </ol>
          </li>
          <li>
            <p style={{textAlign: "justify"}}>
              This Transfer Certificate may be executed in any number of
              counterparts and this has the same effect as if the signatures on
              the counterparts were on a single copy of this Transfer
              Certificate.
            </p>
          </li>
          <li>
            <p style={{textAlign: "justify"}}>
              This Transfer Certificate and any non-contractual obligations
              arising out of or in connection with it are governed by English
              law.
            </p>
          </li>
        </ol>

        <p style={{textAlign: "center"}}>
          <strong>The Schedule</strong>
        </p>
        <p style={{textAlign: "center"}}>
          <strong>COMMITMENT/RIGHTS AND OBLIGATIONS TO BE TRANSFERRED</strong>
        </p>

        <p>
          All rights and obligations under the Agreement with effect from the
          Transfer Date
        </p>
        <p>
          Address of New Lender:{" "}
          <span style={styles.highlight}>{lookup("<Address of Lender>")}</span>
        </p>

        <p>
          <span style={styles.highlight}>{lookup("B Bank")}</span> as Existing
          Lender
        </p>
        <p>By:...............................................</p>

        <p>
          <span style={styles.highlight}>{lookup("C Bank")}</span> as New Lender
        </p>
        <p>By:...............................................</p>

        <br />

        <p>
          This Transfer Certificate is accepted by the Agent and the Transfer
          Date is confirmed as of {moment().format("MMMM Do YYYY")}
        </p>

        <p>
          <span style={styles.highlight}>{lookup("A Bank")}</span>
        </p>
        <p>By:...............................................</p>
      </div>
    </TopPanelContainer>
  );
}

export default TransferCertificate;

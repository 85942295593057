import React from "react";
import LinearProgress from "material-ui/LinearProgress";

function PendingSave(props) {
  const {index, rootDivStyle} = props;
  return (
    <div
      key={`pending-save-item-${index}`}
      style={{
        margin: "0 14rem 1rem 14rem",
        ...(rootDivStyle ? rootDivStyle : {}),
      }}
    >
      <LinearProgress
        mode="indeterminate"
        style={{backgroundColor: "rgb(189, 189, 189)"}}
      />
    </div>
  );
}

export default PendingSave;

import _ from "underscore";
import React from "react";

import ReactSelectLabel from "common_components/react_select_label";

import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "react-select";
import {filterOptions} from "common_components/select_handlers";

import LinkedSelect, {getTopicLink} from "common_components/select_with_link";
import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

const styles = {
  component: {
    margin: "0 1em",
    display: "flex",
    justifyContent: "space-between",
  },
  topic: {
    flexGrow: 1,
  },
  parameterWrapper: {
    padding: "1em",
  },
  parameterLabel: {
    fontFamily: "Roboto",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "22px",
    textShadow: "rgba(0, 0, 0, 0.15) 0px 0px 2px",
    color: "rgba(0, 0, 0, 0.4)",
  },
  parameterGroup: {
    border: "1px solid #cacaca",
    borderRadius: "4px",
    display: "flex",
  },
  parameterToggleWrapper: {
    width: "20rem",
    margin: "0.5em",
    background: "#ffe4c4",
    borderRadius: "0.5em",
    padding: "0.5em",
  },
  parameterCloseIcon: {
    marginTop: "0.5em",
    marginRight: "0.5em",
  },
  parameter: {
    margin: "0.5em",
    border: "1px solid #aaa",
    backgroundColor: "#eef",
    display: "flex",
  },
  parameterAddIcon: {
    display: "flex",
    alignItems: "center",
    marginTop: "17px",
  },
  parameterContent: {
    flexGrow: 1,
  },
  parameterClose: {
    display: "flex",
    alignItems: "center",
  },
  parameterTitle: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.5em",
  },
  parameterToggle: {
    cursor: "pointer",
  },
  parameterFilters: {
    padding: "0.5em",
    display: "flex",
    justifyContent: "space-between",
  },
};

function initialise(state) {
  return {
    topic_id: null,
    any: true,
    parameter_ids: [],
    match_all: false,
    ignore_params: true,
    ..._.pick(state, "topic_id"),
  };
}

function validate(issue) {
  const {rules} = issue;
  if (!(rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  return null;
}

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      topics,
      isNonOverridableFieldsDisabled,
      overridableFieldsStyle,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    const hasParameters = currentTopic && currentTopic.parameters.length > 0;
    const ruleHasMultiParameters = !rules.any && rules.parameter_ids.length > 1;
    const isActionDisabled = disabled || (editLevel && editLevel !== "base");
    return (
      <div>
        <div style={styles.component}>
          <span style={styles.topic}>
            <ReactSelectLabel>Topic</ReactSelectLabel>
            <LinkedSelect
              className="topic"
              multi={false}
              value={rules.topic_id}
              options={topics.map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
              onChange={this.updateTopic}
              clearable={false}
              disabled={isNonOverridableFieldsDisabled || isActionDisabled}
              filterOption={filterByWord}
              style={overridableFieldsStyle}
              link={getTopicLink(this.props.organisationId, rules.topic_id)}
              shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
            />
          </span>
          {hasParameters &&
            rules.ignore_params && (
              <span
                style={styles.parameterAddIcon}
                onClick={() => this.toggleIgnoreParams()}
              >
                <AddIcon />
              </span>
            )}
          {renderError(this.props.rulesError, "topic_id")}
        </div>
        {hasParameters &&
          !rules.ignore_params && (
            <div style={styles.parameterWrapper}>
              <span style={styles.parameterLabel}>Parameters</span>
              <div style={styles.parameterGroup}>
                <div style={{flexGrow: 1}}>
                  <div style={styles.parameterToggleWrapper}>
                    <FormControlLabel
                      label="Match Any Parameter"
                      control={
                        <Switch
                          className="number"
                          color="primary"
                          checked={rules.any}
                          onChange={this.updateAnyToggle}
                          disabled={
                            isNonOverridableFieldsDisabled || isActionDisabled
                          }
                        />
                      }
                    />
                    {ruleHasMultiParameters && (
                      <FormControlLabel
                        label="All specified parameters must match"
                        control={
                          <Switch
                            color="primary"
                            checked={rules.match_all}
                            onChange={this.updateMatchAll}
                            disabled={
                              isNonOverridableFieldsDisabled || isActionDisabled
                            }
                          />
                        }
                      />
                    )}
                  </div>
                  {!rules.any &&
                    this.renderParameters(
                      rules,
                      currentTopic,
                      isNonOverridableFieldsDisabled || disabled,
                      isActionDisabled,
                    )}
                </div>
                <CloseIcon
                  style={{
                    ...styles.parameterCloseIcon,
                    color: isActionDisabled ? "grey" : "black",
                  }}
                  onClick={() => !isActionDisabled && this.toggleIgnoreParams()}
                />
              </div>
            </div>
          )}
        {renderError(this.props.rulesError, "parameter_values")}
      </div>
    );
  }

  renderParameters(rules, currentTopic, disabled, isActionDisabled) {
    const remainingParameters =
      currentTopic &&
      currentTopic.parameters
        .filter(
          parameter =>
            !rules.parameter_ids.find(
              _parameter => parameter.id === getParameterId(_parameter),
            ),
        )
        .map(parameter => ({
          label: parameter.name,
          value: parameter.id,
        }));
    const hasValueMatchers = rules.parameter_ids.find(
      parameterRule => getParameterType(parameterRule) !== "any_value",
    );
    return (
      <div style={{flexGrow: 1}}>
        <div style={!hasValueMatchers ? {display: "flex"} : {}}>
          {rules.parameter_ids.map(parameter =>
            this.renderParameter(
              currentTopic,
              parameter,
              hasValueMatchers,
              disabled,
              isActionDisabled,
            ),
          )}
        </div>
        {remainingParameters.length ? (
          <Select
            allowCreate={false}
            filterOptions={filterOptions}
            value={[]}
            options={remainingParameters}
            onChange={this.updateParameterIds}
            clearable={false}
            filterOption={filterByWord}
            disabled={isActionDisabled}
          />
        ) : null}
      </div>
    );
  }

  renderParameter(
    currentTopic,
    parameterRule,
    hasValueMatchers,
    disabled,
    isActionDisabled,
  ) {
    const type = getParameterType(parameterRule);
    const parameterId = getParameterId(parameterRule);
    const parameter = currentTopic.parameters.find(
      topicParameter => topicParameter.id === parameterId,
    );
    return (
      <div
        key={parameterId}
        style={{
          ...styles.parameter,
          ...(!hasValueMatchers ? {marginRight: "0.5em"} : {}),
        }}
      >
        <div style={styles.parameterContent}>
          <div
            style={{
              ...styles.parameterTitle,
              ...(type === "filter"
                ? {
                    borderBottom: "1px solid #ccc",
                    borderRight: "1px solid #ccc",
                  }
                : {}),
            }}
          >
            {parameter.name}
            <span>
              {type === "any_value" && (
                <span
                  style={!isActionDisabled ? styles.parameterToggle : {}}
                  onClick={() =>
                    !isActionDisabled && this.filterParameterValues(parameterId)
                  }
                >
                  <AddIcon
                    style={{color: isActionDisabled ? "grey" : "black"}}
                  />
                </span>
              )}
              <span
                style={!isActionDisabled ? styles.parameterToggle : {}}
                onClick={() =>
                  !isActionDisabled && this.removeParameter(parameterId)
                }
              >
                <DeleteIcon
                  style={{color: isActionDisabled ? "grey" : "black"}}
                />
              </span>
            </span>
          </div>
          {type === "filter" && (
            <div style={styles.parameterFilters}>
              <TextField
                style={{width: "100%", backgroundColor: "#fff"}}
                value={parameterRule.text}
                onChange={event =>
                  this.setParameterRule(parameterRule, event.target.value)
                }
                label="Parameter match"
                variant="outlined"
                disabled={disabled}
              />
              <div
                style={styles.parameterClose}
                onClick={() =>
                  !isActionDisabled &&
                  this.stopFilteringParameterValues(parameterRule.id)
                }
              >
                <CloseIcon
                  style={{color: isActionDisabled ? "grey" : "black"}}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  filterParameterValues = parameterId => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_ids: rules.parameter_ids.map(
        ruleParamId =>
          ruleParamId === parameterId
            ? {id: parameterId, type: "filter", text: ""}
            : ruleParamId,
      ),
    });
  };
  removeParameter = parameterId => {
    const {editLevel, rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_ids: rules.parameter_ids.filter(
        ruleParamId => parameterId !== getParameterId(ruleParamId),
      ),
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
    });
  };
  stopFilteringParameterValues = parameterId => {
    const {editLevel, rules, onChange} = this.props;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      parameter_ids: rules.parameter_ids.map(
        ruleParam => (ruleParam.id === parameterId ? parameterId : ruleParam),
      ),
    });
  };
  setParameterRule(parameter, value) {
    const {editLevel, rules, onChange} = this.props;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      parameter_ids: rules.parameter_ids.map(
        ruleParamId =>
          ruleParamId.id === parameter.id
            ? {...ruleParamId, text: value}
            : ruleParamId,
      ),
    });
  }

  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
      parameter_ids: [],
    });
  };

  toggleIgnoreParams = () => {
    const {rules, onChange} = this.props;
    onChange({...rules, ignore_params: !rules.ignore_params});
  };
  updateAnyToggle = (event, value) => {
    const {rules, onChange} = this.props;
    onChange({...rules, any: value});
  };
  updateMatchAll = (event, value) => {
    const {rules, onChange} = this.props;
    onChange({...rules, match_all: value});
  };

  updateParameterIds = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_ids: [...rules.parameter_ids, value.value],
    });
  };
  /* eslint-enable no-invalid-this */
}

function getParameterType(parameterRule) {
  return typeof parameterRule === "object" ? parameterRule.type : "any_value";
}

function getParameterId(parameterRule) {
  return typeof parameterRule === "object" ? parameterRule.id : parameterRule;
}

export default {
  initialise,
  validate,
  component: Component,
};

/* eslint-disable no-underscore-dangle*/
export function getStandardTimeout(env = "PRODUCTION", override = false) {
  let _env = window.__env && window.__env.ENVIRONMENT;
  // to get coverage in testing!
  if (override) {
    _env = env;
  }
  if (_env === "TEST") {
    return 1;
  }
  return 1100;
}
/* eslint-enable no-underscore-dangle*/

export default function(fn, time) {
  let lastSave;
  let saveTimeout;
  let hasFinished = true;

  function remainingTimeBeforeNextSave() {
    let timeLeft = lastSave + time - new Date().getTime();
    if (timeLeft < 0) {
      timeLeft = 0;
    }
    if (timeLeft === 0 && !hasFinished) {
      timeLeft = 1000;
    }
    return timeLeft;
  }

  function canSave() {
    return !lastSave || remainingTimeBeforeNextSave() === 0;
  }

  function trigger(...args) {
    return new Promise((resolve) /* , reject */ => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }

      doTrigger(resolve, ...args);
    });
  }

  function doTrigger(resolve, ...args) {
    if (canSave()) {
      lastSave = new Date().getTime();
      doCall(resolve, fn, ...args);
    } else {
      saveTimeout = setTimeout(
        () => doTrigger(resolve, ...args),
        remainingTimeBeforeNextSave(),
      );
    }
  }

  function doCall(resolve, func, ...args) {
    hasFinished = false;
    const res = func(...args);
    res.then(
      () => {
        hasFinished = true;
      },
      () => {
        hasFinished = true;
      },
    );
    resolve(res);
  }

  return trigger;
}

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SettingsIcon from "@material-ui/icons/Settings";
import AddIcon from "@material-ui/icons/Add";
import {MenuItem} from "material-ui";
import React from "react";
import CheckboxBasic from "../../inputs/checkbox_basic";

const styles = {
  iconSize: {
    width: "20px",
  },
  buttonSize: {
    width: "30px",
    height: "30px",
  },
  checkbox: {
    marginLeft: "-5px",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  helpIcon: {
    color: "#9aa0a7",
    width: "16px",
    height: "16px",
  },
};

const CheckboxesMenu = ({
  openMenu,
  anchorEl,
  closeMenu,
  startEditingReport,
  highlightManualCorrections,
  triggerHighlightManualCorrections,
  reportType,
  showDeletedIssues,
  triggerShowDeletedIssues,
  addNewReportItem,
}) => {
  const tooltipStyles = {
    popper: {
      zIndex: 150000,
    },
  };

  const useTooltipStyles = makeStyles(tooltipStyles);
  const classes = useTooltipStyles();

  return (
    <div>
      <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
      <Tooltip title="Add new item to report" enterDelay={1000} placement="top" arrow>
        <IconButton
          style={styles.buttonSize}
          aria-haspopup="true"
          onClick={addNewReportItem}
        >
          <AddIcon style={styles.iconSize} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Customise report" enterDelay={1000} placement="top" arrow>
        <IconButton
          style={styles.buttonSize}
          aria-controls="settings-menu"
          aria-haspopup="true"
          onClick={openMenu}
        >
          <MoreVertIcon style={styles.iconSize} />
        </IconButton>
      </Tooltip>
      </div>

      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem onClick={startEditingReport}>
          <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
            <SettingsIcon style={styles.iconSize} /> Customise layout
            <Tooltip
              placement="top"
              title="Change reporting columns for this document only"
              arrow
              classes={classes}
            >
              <HelpOutlineIcon style={{...styles.helpIcon, float: "right"}} />
            </Tooltip>
          </div>
        </MenuItem>

        <MenuItem>
          <div style={styles.checkboxContainer}>
            <CheckboxBasic
              key="highlight-edited"
              label="Show changes"
              checked={highlightManualCorrections}
              onCheck={triggerHighlightManualCorrections}
              containerStyles={styles.checkbox}
            />
            <Tooltip
              placement="top"
              title="Highlight values in the report that have been edited"
              arrow
              classes={classes}
            >
              <HelpOutlineIcon style={styles.helpIcon} />
            </Tooltip>
          </div>
        </MenuItem>

        {reportType !== "custom_report" ? (
          <MenuItem>
            <div style={styles.checkboxContainer}>
              <CheckboxBasic
                key="show-deleted"
                label="Show Deleted"
                checked={showDeletedIssues}
                onCheck={triggerShowDeletedIssues}
                containerStyles={styles.checkbox}
              />
              <Tooltip
                placement="top"
                title="Temporarily show issues that have been removed from the report"
                arrow
                classes={classes}
              >
                <HelpOutlineIcon style={styles.helpIcon} />
              </Tooltip>
            </div>
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default CheckboxesMenu;

import React from "react";
import _ from "lodash";

import Tooltip from "common_components/tooltip";
import VerticalAlignTop from "material-ui/svg-icons/editor/vertical-align-top";
import VerticalAlignBottom from "material-ui/svg-icons/editor/vertical-align-bottom";
import Undo from "material-ui/svg-icons/content/undo";
import Delete from "material-ui/svg-icons/action/delete";
import * as colors from "material-ui/styles/colors";

const styles = {
  menuItemIcon: {
    width: "19px",
    height: "19px",
    color: colors.grey800,
    cursor: "pointer",
    position: "relative",
    top: "2px",
  },
};

export const tooltipStyles = {
  rootDiv: {
    marginLeft: "0.3rem",
    position: "relative",
  },
  getTooltipContainerStyles: customStyles => ({
    padding: "4px 5px",
    position: "absolute",
    top: "-20px",
    lineHeight: "8px",
    ...(customStyles ? customStyles : {}),
  }),
  getTooltipContentStyles: customStyles => ({
    fontSize: "10px",
    margin: "0 0.1rem",
    top: "-1px",
    position: "relative",
    fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
    ...(customStyles ? customStyles : {}),
  }),
};

function ClausepartControl(props) {
  const {itemType, haveContentsChanged} = props;
  const rawButtonsData = [
    props.onAddItemBefore &&
      !haveContentsChanged && {
        text: `Add ${itemType ? itemType : "Subclause"} Before`,
        handler: props.onAddItemBefore,
        Icon: VerticalAlignTop,
      },
    props.onAddItemAfter &&
      !haveContentsChanged && {
        text: `Add ${itemType ? itemType : "Subclause"} After`,
        handler: props.onAddItemAfter,
        Icon: VerticalAlignBottom,
      },
    props.onRevert &&
      !haveContentsChanged && {
        text: `Revert ${itemType ? itemType : "Subclause"}`,
        handler: props.onRevert,
        Icon: Undo,
      },
  ];
  if (!props.onRevert && props.onDelete && !haveContentsChanged) {
    rawButtonsData.push({
      text: `Delete ${itemType ? itemType : "Subclause"}`,
      handler: props.onDelete,
      Icon: Delete,
    });
  }
  const buttonsData = _.compact(rawButtonsData);
  return (
    <div
      className="ql-formats"
      style={{marginLeft: "auto", display: "flex", alignItems: "center"}}
    >
      {buttonsData.map((buttonItem, index) => {
        const {text, handler, Icon} = buttonItem;
        return (
          <Tooltip
            key={index}
            tooltipContent={text}
            rootDivStyle={tooltipStyles.rootDiv}
            tooltipContainerStyle={tooltipStyles.getTooltipContainerStyles({
              right: "16px",
            })}
            tooltipContentStyle={tooltipStyles.getTooltipContentStyles({
              width: `${text.length * 0.32}rem`,
            })}
            delay={300}
          >
            <Icon style={styles.menuItemIcon} onClick={handler} />
          </Tooltip>
        );
      })}
    </div>
  );
}

export default ClausepartControl;

import _ from "underscore";
import path from "common/utils/path";

export default function forEachProjectsTreeItem(list, handler, isAdmin) {
  for (const itemName in list) {
    const itemData = list[itemName];
    let addChildren = true;
    const dontAddChildren = () => {
      addChildren = false;
    };
    const hasChildren = _.keys(itemData.childProjects).length > 0;
    const filteredChilren = _.pairs(itemData.childProjects)
      .filter(([, value]) => isAdmin || !path(value, ["project", "admin_only"]))
      .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
    const hasFilteredChildren = _.keys(filteredChilren).length > 0;
    if (!hasChildren || hasFilteredChildren) {
      handler(itemData, itemName, dontAddChildren);
    }
    if (addChildren && hasFilteredChildren) {
      forEachProjectsTreeItem(filteredChilren, handler, isAdmin);
    }
  }
}

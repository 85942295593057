function updateTopicLocation(props) {
  return (e, i, value) => {
    const {rules, onChange} = props;
    onChange({
      ...rules,
      topic_location: value,
    });
  };
}

function getOnChangeObj(issues, rules) {
  return {
    issues,
    ...(rules.topic_location ? {topic_location: rules.topic_location} : {}),
  };
}

const logicIssueTypes = [
  "AND_ON_DOCUMENT",
  "AND_ON_CLAUSE",
  "AND_ON_WHOLE_CLAUSE",
  "OR",
];

function isLogic(issueType) {
  return (
    issueType && logicIssueTypes.find(_issueType => _issueType === issueType)
  );
}

function revertLogicIssueType(issueType) {
  if (issueType === "OR") {
    return "AND_ON_DOCUMENT";
  }
  return "OR";
}

function getAboveIssueType(issueType, parentIssueType) {
  if (isLogic(parentIssueType)) {
    if (isLogic(issueType)) {
      if (
        (issueType === "OR" || parentIssueType === "OR") &&
        issueType !== parentIssueType
      ) {
        return null;
      }
    }
    return revertLogicIssueType(parentIssueType);
  }
  if (isLogic(issueType)) {
    return revertLogicIssueType(issueType);
  }
  return "OR";
}

function isLogicIssue(issue) {
  return (
    issue &&
    issue.rules &&
    issue.rules.issues &&
    ["OR", "AND_ON_DOCUMENT", "AND_ON_CLAUSE", "AND_ON_WHOLE_CLAUSE"].includes(
      issue.issue_type,
    )
  );
}

export {updateTopicLocation, getOnChangeObj, getAboveIssueType, isLogicIssue};

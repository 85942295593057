import {WorkflowId} from "common/flowmaster/types/workflow";
import {Id} from ".";
import {OrganisationId} from "./organisation";
import {TopicCategoryId} from "./topic_categories";
import {UserId} from "./user";
import {LlmRunId} from "common/flowmaster/types/llm_run";

export type DocumentId = Id;
export type ClauseId = Id;
export type ClausepartId = Id;
export type SectionId = Id;
export type RoleId = Id;
export type ReportId = Id;
export type PartyId = Id;
export type DocumentPartyId = Id;

export type TopicId = Id;
export type TopicParameterId = Id;

export type ClauseReference = Id;
export type ClauseFullReference = Id;
export type ClauseSortReference = Id;

export interface Party {
  id: PartyId;
  name: string;
}

export interface DocumentParty extends Party {
  document_party_id: DocumentPartyId;
  term: string;
  address: string;
  is_role_empty: boolean;
  role_id: RoleId;
  role_match_text: string;
  role_match_source: string;
  role_match_method: string;
}

export type DocumentClauses = {
  [id: SectionId]: DocumentClause[];
};

export type ReasonData = {
  [id: TopicId]: {
    [id: TopicParameterId]: [];
  };
};

export type TopicsById = {
  [id: TopicId]: Topic;
};

export type DocumentSection = {
  id: number;
  text: string;
  name: string;
  reference: string;
  order: number;
  is_hidden: boolean;
  is_unnumbered: boolean;
  file_index: number;
};

export interface Document {
  id: DocumentId;
  name: string;
  parties: Party[];
  creation_date: Date;
  user_id: UserId;
  concat_filenames: string[];
  concat_text_length: number[];
  user_email: string;
  group_id: string;
  revisions: number;
  sections: DocumentSection[];
}

export type ClauseNodes = {
  clauseNodes: ClauseNodes[];
  clauseId: string;
  clausePartNumber: number;
  formattedText: string;
  id: number;
  is_badly_parsed: boolean;
  is_conjunction: boolean;
  is_terminal: boolean;
  level: number;
  listOrder: number;
  load_state: number;
  order: number;
  partial_text: string;
  path: string;
  qualifiers: null | unknown;
  reference: string;
  references: null | unknown;
  text: string;
  topics: NodeTopic[];
  pageNumber?: number;
};

export interface Role {
  id: RoleId;
  name: string;
  creation_date: Date;
  last_edited: Date;
  is_mutual: boolean;
  organisation_id: OrganisationId;
}

export interface Topic {
  id: TopicId;
  name: string;
  is_deleted: boolean;

  // type ContractType = {
  //   contract_type_id: ContractTypeId;
  // };
  // type ContractTypeId = Id;

  // clause_topic_id: number;
  // contract_types: ContractType[];
  // exact_match_id: unknown | null;
  // is_confirmed: boolean;
  // is_deleted: boolean;
  // is_substantive: boolean;
  // match_source_id: number;
  // original_exact_match_id: unknown | null;
  // topic_id: TopicId;
  // topic_order: number;
  topiccategory_id: TopicCategoryId;
}

export interface Clausepart {
  id: ClausepartId;
  last_edited: Date;
  text: string;
  partial_text: string;
  path: string;
  type: "Atom";
  reference: string;
  is_conjunction: boolean;
  is_terminal: boolean;
  is_badly_parsed: boolean;
  order: number;
  load_state: number;
  workflow_settings: ClausepartWorkflowSettings;
}

interface ClausepartWorkflowSettings {
  [key: WorkflowId]: ClausepartLlmRun;
}

interface ClausepartLlmRun {
  start_time: string;
  llm_run_id: LlmRunId | null;
  is_complete: boolean;
  finish_time: string;
}

interface Reference {
  id: Id;
}

export interface ClauseAtom {
  reference: string;
  references: Reference[];
  text: string;
  partial_text: string;
  full_text: string;
  id: ClausepartId;
  clause_reference: ClauseReference;
  clause_id: ClauseId;
  section_id: SectionId;
  clause_section_id: SectionId;
  nodes: ClauseNodes;
  clause_full_reference: ClauseReference;
  clause_order: number;
  topics: Topic[];
  // Q: Is this optional?
  baseTopics?: Topic[];
}

export interface DocumentClause {
  // Q: When is this defined?
  path?: string;
  id: ClauseId;
  section_id: SectionId;
  reference: ClauseReference;
  full_reference: ClauseFullReference;
  sort_reference: ClauseSortReference;
  nodes: ClauseNodes;
  last_edited: Date;
  deleted: boolean;
  parts: Clausepart[];
  row_number: number;
}
export interface DateExtractedValue {
  start: number;
  end: number;
  value: string;
  original_value: "";
}
export interface DurationExtractedValue {
  start: number;
  end: number;
  value: number;
  unit: string;
  original_value: number | "";
}
export interface MonetaryExtractedValue {
  start: number;
  end: number;
  value: number;
  symbol: string;
  currency: null | string;
}
export interface NumericalExtractedValue {
  start: number;
  end: number;
  value: number;
  unit?: "percentage";
}
export type RegexExtractedValue = string;

export interface RoleExtractedValue {
  start: number;
  end: number;
  value: string;
  role_id: RoleId;
  role_name: string;
  match_type: string;
  party_name: string;
  party_term: string;
}

export type ParameterExtractedValue =
  | DateExtractedValue
  | DurationExtractedValue
  | MonetaryExtractedValue
  | NumericalExtractedValue
  | RegexExtractedValue
  | RoleExtractedValue;

export interface PositiveReasonData {
  [topicId: TopicId]: {
    [topicParameterId: TopicParameterId]: ParameterExtractedValue[];
  };
}

type ApplicableClause = {id: ClauseId; text?: string};

export const isApplicableClause = (input: unknown): input is ApplicableClause =>
  typeof input === "object" &&
  input !== null &&
  "id" in input &&
  typeof input.id === "number";

export type GroupedApplicableClauses = {
  [topic: string]: ApplicableClause[];
};

export type NodeTopic = {
  topic_id: TopicId;
  topicparameters: Array<{
    name: string;
    values: string[];
    actual_values: null | unknown;
    parameter_type: "regex" | string; // etc...
    topicparameter_id: number;
    clause_topic_topicparameter_id: TopicParameterId;
  }>;
  is_deleted: boolean;
  topic_order: number;
  is_confirmed: boolean;
  contract_types: Array<{
    contract_type_id: number;
  }>;
  exact_match_id: null;
  is_substantive: boolean;
  clause_topic_id: TopicId;
  match_source_id: number;
  topiccategory_id: TopicCategoryId;
  original_exact_match_id: null | unknown;
};

export type Node = ClauseNodes;

export default function calculateChildReference(childRef, parentRef, node) {
  if (
    !parentRef ||
    parentRef.length === 0 ||
    childRef.indexOf(parentRef) !== 0
  ) {
    return null;
  }

  if (node.counterType === "DECIMAL") {
    if (findFirst(node).text === "") {
      return "";
    }
    return childRef;
  }
  return childRef.substring(parentRef.length + 1);
}

function findFirst(node) {
  if (node.clauseNodes && node.clauseNodes.length > 0) {
    return findFirst(node.clauseNodes[0]);
  }
  return node;
}

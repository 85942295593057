import React from "react";
import _ from "lodash";

function getElementInnerText(element) {
  const texts = [];
  if (React.isValidElement(element)) {
    React.Children.forEach(element.props.children, child => {
      texts.push(
        _.isString(child) || _.isNumber(child)
          ? child
          : getElementInnerText(child),
      );
    });
  }
  return texts.join(" ");
}

export default getElementInnerText;

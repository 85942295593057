import issuesetUtils from "common/utils/issues/issueset_utils";

function getApplicableIssuesetIds(
  document,
  project,
  contractTypesById,
  config = {},
) {
  const {select_checklist_on_upload: selectChecklistOnUpload} = project;
  const {
    get_issuesets_from_contract_types: getIssuesetsFromContractTypes,
  } = config;

  if (!getIssuesetsFromContractTypes) {
    const {issuesets: documentIssuesets = []} = document;
    const {issuesets: projectIssuesets = []} = project;
    if (document.contract_type.id !== project.default_contract_type.id) {
      const allIssuesets = issuesetUtils.getIssuesetIdsPresentInContractTypes(
        [contractTypesById[document.contract_type.id]].filter(item => item),
      );
      if (documentIssuesets.length > 0) {
        const matchingIssuesets = allIssuesets.filter(issueset =>
          documentIssuesets.find(
            documentIssueset => documentIssueset === issueset,
          ),
        );
        if (matchingIssuesets.length > 0) {
          return matchingIssuesets;
        }
      }
      return allIssuesets;
    }

    if (selectChecklistOnUpload && documentIssuesets.length > 0) {
      return documentIssuesets;
    }

    if (projectIssuesets.length > 0) {
      return projectIssuesets;
    }
  }
  const projectDefaultCtId = project.default_contract_type.id;
  return issuesetUtils.getIssuesetIdsPresentInContractTypes([
    contractTypesById[projectDefaultCtId],
  ]);
}

export default getApplicableIssuesetIds;

import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

import SearchItem from "./search_item";
import SearchIcon from "./search_icon";

import * as colors from "material-ui/styles/colors";

const styles = theme => ({
  root: {
    marginBottom: 13,
    padding: "0 19px 18px 21px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "13px",
    borderBottom: "1px solid #c8c8c8",
    transition: theme.transitions.create(["padding-bottom", "margin-bottom"]),
  },
  collapsed: {
    border: "none",
    paddingBottom: 0,
    marginBottom: 0,
  },
  sectionTitle: {
    color: "#1f88e5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 12,
    borderBottom: "1px solid #e6e6e6",
    cursor: "pointer",
    lineHeight: "31px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
  },
  expandIcon: {
    color: "#82888e",
    fontSize: "1.2rem",
    marginRight: -2,
  },
  sectionBody: {
    padding: "5px 0 0",
  },
  noResults: {
    padding: "7px 0px",
    color: "#8d9398",
  },
});

const inlineStyles = {
  matchAllMain: {
    fontWeight: 800,
    textTransform: "uppercase",
    position: "relative",
    color: colors.grey600,
    fontSize: "12px",
  },
};

class SearchMatchSection extends React.Component {
  state = {dataToRender: {}, isCollapsed: false};

  toggleCollapsing = () => {
    this.setState({isCollapsed: !this.state.isCollapsed});
  };

  render() {
    const {classes} = this.props;
    const {isCollapsed} = this.state;
    const ExpandIcon = !isCollapsed ? ExpandLess : ExpandMore;
    const className = `${classes.root}${
      isCollapsed ? ` ${classes.collapsed}` : ""
    }`;
    return (
      <div className={className}>
        <div
          className={classes.sectionTitle}
          onClick={this.toggleCollapsing}
          style={{
            ...(this.props.isLast && isCollapsed && {border: "none"}),
          }}
        >
          {`${this.props.sectionTitle} (${this.props.items.length})`}
          <ExpandIcon className={classes.expandIcon} />
        </div>
        <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
          <div className={classes.sectionBody}>
            {!this.props.items || this.props.items.length === 0 ? (
              <div className={classes.noResults}>
                {" "}
                {this.props.noMatchMessage
                  ? this.props.noMatchMessage
                  : "No Matches"}{" "}
              </div>
            ) : (
              this.renderItems()
            )}
          </div>
        </Collapse>
      </div>
    );
  }
  renderItems = () => {
    const matchAllItemData = {
      name: `Match All ${this.props.sectionTitle}`,
    };
    const items = this.props.items.map((item, index) => (
      <SearchItem
        key={`${index}-${item.name}`}
        sectionTitle={this.props.sectionTitle}
        item={item}
        onMatchItemClick={this.onSearchOne}
        resultRenderer={this.props.resultRenderer}
        dataToRender={this.state.dataToRender}
      />
    ));
    if (items.length > 1 && !this.props.disableMatchAll) {
      items.unshift(
        <SearchItem
          key={`match-all-${this.props.sectionTitle}`}
          sectionTitle={this.props.sectionTitle}
          item={matchAllItemData}
          onMatchItemClick={this.onSearchAll}
          itemStyle={inlineStyles.matchAllMain}
          leftIcon={<SearchIcon />}
        />,
      );
    }
    return items;
  };
  onSearchOne = item => {
    this.setState(prevState => {
      if (prevState.dataToRender[item.id]) {
        return {dataToRender: {}};
      }
      const newDataToRender = {
        [item.id]: this.props.onSearch(item),
      };
      return {dataToRender: newDataToRender};
    });
  };
  onSearchAll = () => {
    this.setState(prevState => {
      if (Object.keys(prevState.dataToRender).length > 1) {
        return {dataToRender: {}};
      }
      const dataToRenderAll = {};
      this.props.items.forEach(item => {
        dataToRenderAll[item.id] = this.props.onSearch(item);
      });
      return {dataToRender: dataToRenderAll};
    });
  };
}

SearchMatchSection.propTypes = {
  onSearch: PropTypes.func,
  sectionTitle: PropTypes.string,
  disableMatchAll: PropTypes.bool,
  resultRenderer: PropTypes.func,
  items: PropTypes.array,
  noMatchMessage: PropTypes.string,
  isLast: PropTypes.bool,
};

export default withStyles(styles)(SearchMatchSection);

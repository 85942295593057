function splitHelper(str, _token, token2) {
  let token = _token;
  if (typeof token !== "string") {
    token = ",";
  }

  const list = str.split(token);

  if (typeof token2 === "string") {
    return list.map(item => item.split(token2));
  }
  return list;
}

export default splitHelper;

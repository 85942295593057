function constructHtmlFromChildren(children = []) {
  return children
    .reduce((accum, child) => {
      if (child.type !== "paragraph") {
        return accum;
      } else if (child.children) {
        const children = child.children
          .map(childChild => childChild.value)
          .join(", ");
        accum.push(
          `<p>${child.value ? `${child.value}: ` : ""}${children}</p>`,
        );
        return accum;
      }
      const textArr = child.value.split("\n");
      for (const lineStr of textArr) {
        let text = lineStr;
        if (child.bold) {
          text = `<strong>${text}</strong>`;
        }

        if (child.indent) {
          text = `<p class="ql-indent-${child.indent}">${text}</p>`;
        } else {
          text = `<p>${text}</p>`;
        }
        accum.push(text);
      }
      return accum;
    }, [])
    .join("");
}

export default constructHtmlFromChildren;

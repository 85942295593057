import * as React from "react";
import createSvgIcon from "./utils/create_svg_icon";

export default createSvgIcon(
  <>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path d="M3 13h8v2H3zm0 4h8v2H3zm0-8h8v2H3zm0-4h8v2H3zm16 2v10h-4V7h4m2-2h-8v14h8V5z" />
  </>,
  "VerticalSplit",
  {viewBox: "0 0 24 24"},
);

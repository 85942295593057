import React from "react";

import Tooltip from "@material-ui/core/Tooltip";

export default function CopyTooltip({title, copyTitle, children}) {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  return (
    <span>
      <Tooltip onClose={handleTooltipClose} title={open ? copyTitle : title}>
        <span onMouseUp={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
          {children}
        </span>
      </Tooltip>
    </span>
  );
}

import React from "react";

import TopicChip from "common_components/topic_chip";

import getClauseTopicsNames from "../../../../utils/applicable_clauses/get_clause_topics_names";

const styles = {
  noMatchingClauses: {
    marginTop: "0.25em",
    textAlign: "center",
    fontStyle: "italic",
    fontSize: "13px",
  },
  clauseItem: {
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "4px",
    paddingRight: 0,
    cursor: "pointer",
  },
  clauseText: {
    textAlign: "justify",
    fontSize: "10px",
    lineHeight: "12px",
    marginLeft: "1px",
    marginRight: "4px",
    fontWeight: 100,
  },
  clauseId: {
    fontSize: "12px",
    fontWeight: "400",
  },
};
styles.clauseItemAlt = {
  ...styles.clauseItem,
  backgroundColor: "#fafafa",
};

function renderClickableClauses(clauses, onClauseClick, topicsById) {
  if (clauses && clauses.length === 0) {
    return <div style={styles.noMatchingClauses}>No Matching Clauses</div>;
  }
  return clauses.map((clause, index) => {
    const clauseTopicsNames = topicsById
      ? getClauseTopicsNames(clause.topics, topicsById)
      : "null";
    return (
      <div
        style={index % 2 === 0 ? styles.clauseItem : styles.clauseItemAlt}
        key={index}
        onClick={onClauseClick(clause)}
      >
        <div className="clamped-clause" style={styles.clauseText}>
          {topicsById && renderClauseTopicsNames(clauseTopicsNames)}
          <span style={styles.clauseId}>
            {clause.reference ? `${clause.reference}. ` : ""}
          </span>
          <span>{clause.text}</span>
        </div>
      </div>
    );
  });
}

function renderClauseTopicsNames(names, style) {
  return names.map((name, index) => (
    <TopicChip key={index} text={name} chipStyles={style} />
  ));
}

export default renderClickableClauses;

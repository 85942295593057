import _ from "underscore";

function getUnrecognisedClauses(documentClauses) {
  const unrecognisedAtoms = [];
  function getAtoms(node, clause) {
    const nodeText = node.text;
    if (nodeText && nodeText !== "and" && nodeText !== "or") {
      const nodeWithClauseData = {
        ...node,
        clause_reference: clause.reference,
        section_id: clause.section_id,
      };
      if (!node.topics || node.topics.length === 0) {
        unrecognisedAtoms.push(nodeWithClauseData);
        return;
      }
      const substantiveTopic = node.topics.find(
        topic => topic.is_substantive && !topic.is_deleted,
      );
      if (!substantiveTopic) {
        unrecognisedAtoms.push(nodeWithClauseData);
      }
    }
    if (node.clauseNodes) {
      if (node.type === "ClausePartNode") {
        getAtoms(
          node.clauseNodes.length > 1
            ? node.clauseNodes[1]
            : node.clauseNodes[0],
          clause,
        );
      } else {
        node.clauseNodes.forEach(child => getAtoms(child, clause));
      }
    }
  }

  _.forEach(documentClauses, clauses => {
    clauses.forEach(clause => {
      getAtoms(clause.nodes, clause);
    });
  });
  return unrecognisedAtoms;
}

export default getUnrecognisedClauses;

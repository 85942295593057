import React from "react";
import {validate} from "email-validator";

import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";

const styles = {
  actions: {
    zIndex: 0,
  },
  textFieldStyles: {
    width: "95%",
    margin: "0 1rem",
  },
};

class EmailReportDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.username,
      addressError: "",
    };
  }
  render() {
    return (
      <Dialog
        title="Email Report"
        className="dialog"
        onDismiss={this.props.onDismiss}
        open={true}
        actions={this.getActions()}
        bodyStyle={{overflowY: "inherit"}}
      >
        <div style={{marginBottom: "0.5rem"}}>
          Please specify email address:
        </div>
        <TextField
          floatingLabelText="Email"
          type="email"
          onFocus={this.clearError}
          onChange={this.onAddressChange}
          onBlur={this.validateAddress}
          value={this.state.address}
          errorText={this.state.addressError}
          style={styles.textFieldStyles}
        />
      </Dialog>
    );
  }

  getActions = () => {
    return [
      <FlatButton
        label="Cancel"
        key="email-report-dialog-cancel-button"
        secondary={true}
        onClick={this.props.onDismiss}
        style={styles.actions}
      />,
      <FlatButton
        label="Send"
        key="email-report-dialog-send-button"
        primary={true}
        onClick={this.onSendEmail}
        className="send-button"
        style={styles.actions}
      />,
    ];
  };

  onAddressChange = e => {
    const address = e.target.value;
    this.setState(() => ({address}));
  };

  validateAddress = () => {
    if (!validate(this.state.address)) {
      this.setError("Invalid Email");
    }
  };

  setError = value => this.setState(() => ({addressError: value}));

  clearError = () => this.setError("");

  onSendEmail = () => {
    if (this.state.addressError) {
      return;
    }
    this.props.sendEmailReport(this.props.issuesetId, {email_address: this.state.address});
    this.props.onDismiss();
  };
}

export default EmailReportDialog;

import React from "react";

import {isIe11} from "utils/browser_test";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import {shouldUseManualCorrections} from "utils/manual_corrections_utils";
import issueHeaderUtils from "utils/issues/issue_header_utils";
import Markdown from "common_components/markdown";

const styles = {
  reason: {
    color: "#474747",
    marginTop: "0.2rem",
    ...(isIe11() ? {width: "100%"} : {}),
  },
};

function isReasonTextArrayEmpty(reasonTextArray) {
  if (!reasonTextArray) {
    return true;
  }
  const nonEmptyItems = reasonTextArray.filter(
    item => item && item.trim().length,
  );
  return !nonEmptyItems.length;
}

function DetailedReason(props) {
  const {isFlipChecklistIcons} = props;
  const reasonTextArray = issueHeaderUtils.getReasonTextArray(
    props.issue,
    props.documentClauses,
    props.topicsById,
    props.positiveReasonData,
    props.selectedReport,
    props.currentIssuesetItem,
    props.documentClauseparts,
    props.document.parties,
    true,
    true,
    shouldUseManualCorrections(
      "detailed_reason",
      props.project,
      props.selectedReport,
    ),
    props.preventMemo,
  );

  if (isReasonTextArrayEmpty(reasonTextArray)) {
    return null;
  }
  const text = reasonTextArray.join("\n");
  return (
    <div
      style={{
        backgroundColor: "#efefef",
        padding: "12px 20px",
        border: "1px solid #bbb",
        margin: "0.5rem",
        fontSize: getZoomedFontSize(15, "checklist", props.zoom),
        flexShrink: 0,
      }}
    >
      <div
        style={{
          color: isFlipChecklistIcons ? "#333333" : "#42a5f5",
          fontWeight: isFlipChecklistIcons ? "normal" : "bold",
          padding: "0.5rem 0 1rem 0",
          borderBottom: "1px solid #d3d3d3",
          marginBottom: "0.5em",
        }}
      >
        Overview
      </div>
      <div style={styles.reason} data-testid="detailed_reason">
        <Markdown ignoreLegacy={props.issue.trigger_type === "LLM"}>
          {text}
        </Markdown>
      </div>
    </div>
  );
}

export default DetailedReason;

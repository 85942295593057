import editor from "utils/issues/editor/multi_selector";

export function typeFilter(type) {
  return type !== "AND_ON_DOCUMENT";
}

export default {
  ...editor(typeFilter),
  typeFilter,
};

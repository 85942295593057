import React from "react";
import _, {cloneDeep} from "lodash";

import List from "@material-ui/core/List";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckBoxBasic from "common_components/inputs/checkbox_basic";
import StatefulTextField from "common_components/inputs/stateful_textfield";
import GuiLight from "common_components/gui_text_editor/gui_light";

import ListItemHoverable from "./list_item_hoverable";

import issuesetUtils from "common/utils/issues/issueset_utils";
import REPORT_TYPES from "constants/report_types";
import usePrevious from "utils/hooks/use_previous";
import uuid from "utils/uuid";
import byId from "utils/by_id_memo";

function getDefaultRowSettings() {
  return {
    label: "",
    issue_name: "",
    type: "free_text",
    value: "",
    use_issue_value: false,
    id: uuid(),
  };
}

function CustomReportSettings(props) {
  const [addedRowSettings, updateAddedRowSettings] = React.useState(
    getDefaultRowSettings(),
  );
  const {settings} = props;
  const prevRowsCount = usePrevious(settings.rows.length);

  React.useEffect(
    () => {
      if (settings.rows.length > prevRowsCount) {
        const lastAddedRow = document.getElementById("last_added_row");
        if (lastAddedRow) {
          lastAddedRow.focus();
        }
      }
    },
    [settings.rows.length],
  );

  function renderIssuesetSelector() {
    const contractTypesById = byId("contract_types", props.contractTypes);
    const issuesetsById = issuesetUtils.getIssuesetsById(contractTypesById);
    const selectedProjectIssuesets = issuesetUtils.constructGroupedIssuesetSelectorItems(
      props.issuesets,
      issuesetsById,
      false, // show contract type name
    );

    function onIssuesetChange(e) {
      const newSettings = {
        ...settings,
        referenced_issueset_id:
          e.target.value === "any" ? null : e.target.value,
      };
      props.onSettingsChange(newSettings);
    }

    return (
      <FormControl style={{marginBottom: 12}}>
        <InputLabel>Checklist</InputLabel>
        <Select
          value={settings.referenced_issueset_id || "any"}
          onChange={onIssuesetChange}
          style={{
            minWidth: 320,
            fontStyle: !settings.referenced_issueset_id ? "italic" : "inherit",
            fontWeight: !settings.referenced_issueset_id ? 500 : 400,
          }}
        >
          <MenuItem key="any" value={"any"} style={{fontStyle: "italic"}}>
            Any
          </MenuItem>

          {selectedProjectIssuesets.map(issueset => (
            <MenuItem key={issueset.value} value={issueset.value}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{paddingLeft: issueset.level * 15}} />
                <span>{issueset.primaryText}</span>
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function renderExistingRows() {
    const shiftRowUp = index => () => {
      const newSettingsRows = cloneDeep(settings.rows);
      const temp = newSettingsRows[index - 1];
      newSettingsRows[index - 1] = newSettingsRows[index];
      newSettingsRows[index] = temp;
      const newSettings = {
        ...settings,
        rows: newSettingsRows,
      };
      props.onSettingsChange(newSettings);
    };

    const shiftRowDown = index => () => {
      const newSettingsRows = cloneDeep(settings.rows);
      const temp = newSettingsRows[index + 1];
      newSettingsRows[index + 1] = newSettingsRows[index];
      newSettingsRows[index] = temp;
      const newSettings = {
        ...settings,
        rows: newSettingsRows,
      };
      props.onSettingsChange(newSettings);
    };

    const deleteRow = index => () => {
      const newSettingsRows = settings.rows.filter(
        (row, rowIndex) => rowIndex !== index,
      );
      const newSettings = {
        ...settings,
        rows: newSettingsRows,
      };
      props.onSettingsChange(newSettings);
    };

    const onRowSettingsUpdate = index => newRowSettings => {
      const newSettingsRows = settings.rows.map((rowSettings, rowIndex) => {
        if (rowIndex === index) {
          return newRowSettings;
        }
        return rowSettings;
      });
      const newSettings = {
        ...settings,
        rows: newSettingsRows,
      };
      props.onSettingsChange(newSettings);
    };

    const projectContractTypeId = props.project.default_contract_type?.id;

    const contractType = props.contractTypes.find(
      ct => ct.id === projectContractTypeId,
    );
    const issueset =
      contractType &&
      contractType.issuesets.find(
        issueset => issueset.id === settings.referenced_issueset_id,
      );
    const baseIssues = props.issues.filter(
      issue =>
        projectContractTypeId
          ? issue.contract_types.find(
              ct => ct.contract_type_id === projectContractTypeId,
            )
          : issue.contract_types.length > 0,
    );
    const relevantIssues = settings.referenced_issueset_id
      ? getSelectedIssuesetIssues(baseIssues, settings.referenced_issueset_id)
      : baseIssues;

    const issueNames = getIssueNames(relevantIssues, issueset);

    return (
      <List dense={true}>
        {settings.rows.map((rowSettings, rowIndex) => (
          <ListItemHoverable
            key={`row-${rowIndex}`}
            isFirstInList={rowIndex === 0}
            isLastInList={rowIndex === settings.rows.length - 1}
            style={{background: "inherit"}}
            onShiftUp={shiftRowUp(rowIndex)}
            onShiftDown={shiftRowDown(rowIndex)}
            onDelete={deleteRow(rowIndex)}
          >
            <RowItem
              settings={rowSettings}
              onSettingsUpdate={onRowSettingsUpdate(rowIndex)}
              isLastRow={rowIndex === settings.rows.length - 1}
              issueNames={issueNames}
            />
          </ListItemHoverable>
        ))}
      </List>
    );
  }

  function renderAddRow() {
    const onAddRow = () => {
      props.onSettingsChange({
        ...props.settings,
        rows: [...props.settings.rows, addedRowSettings],
      });
      updateAddedRowSettings(getDefaultRowSettings());
    };
    return (
      <StatefulTextField
        label="Name"
        value={""}
        preventError={true}
        style={{marginRight: 12}}
        onFocus={onAddRow}
      />
    );
  }

  return (
    <div style={{marginTop: 22}}>
      {renderIssuesetSelector()}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minWidth: 700,
        }}
      >
        <fieldset
          style={{
            display: "flex",
            flexDirection: "column",
            border: "1px solid rgba(0, 0, 0, 0.3)",
            paddingRight: 0,
          }}
        >
          <legend
            style={{
              color: "rgba(0, 0, 0, 0.7)",
              fontSize: "14px",
            }}
          >
            Report Rows
          </legend>
          {settings.rows.length > 0 ? (
            renderExistingRows()
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: 62,
              }}
            >
              <div
                style={{
                  fontSize: 28,
                  color: "#9e9e9e",
                }}
              >
                Please Add Rows
              </div>
            </div>
          )}
        </fieldset>
      </div>
      {renderAddRow()}
    </div>
  );
}

function RowItem(props) {
  const {
    settings,
    onSettingsUpdate,
    isLastRow,
    styles = {},
    issueNames,
  } = props;
  const onLabelChange = newValue =>
    onSettingsUpdate({
      ...settings,
      label: newValue,
    });
  const onCheckboxCheck = () => {
    const use_issue_value = !settings.use_issue_value;
    const type = use_issue_value ? "select_field" : "free_text";
    onSettingsUpdate({
      ...settings,
      use_issue_value,
      type,
    });
  };
  const updateIssueName = name => {
    if (name) {
      onSettingsUpdate({
        ...settings,
        issue_name: name,
      });
    }
  };
  const onTypeChange = e =>
    onSettingsUpdate({
      ...settings,
      type: e.target.value,
    });
  const onFreeValueChange = newValue =>
    onSettingsUpdate({
      ...settings,
      value: newValue,
    });
  const {use_issue_value: useIssueValue} = settings;
  const renderValueFromIssue = () => (
    <div>
      <Autocomplete
        options={issueNames}
        value={settings.issue_name}
        freeSolo
        onChange={(event, option) => {
          updateIssueName(option);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Issue Name"
            margin="normal"
            onKeyDown={event => {
              if (event.key === "Enter") {
                event.target.blur();
              }
            }}
            onBlur={() => updateIssueName(null, params.inputProps.value)}
          />
        )}
      />
      <Select
        value={settings.type}
        onChange={onTypeChange}
        style={{
          marginTop: 10,
          width: 180,
          fontStyle: settings.type === "select_field" ? "italic" : "inherit",
          fontWeight: settings.type === "custom_field" ? 500 : 400,
        }}
      >
        <MenuItem
          disabled
          value={"select_field"}
          style={{
            background: "none",
            fontStyle: "italic",
          }}
        >
          Select Field
        </MenuItem>
        <MenuItem value="custom_field" style={{fontWeight: 500}}>
          Blank
        </MenuItem>
        {REPORT_TYPES["custom_report"].default_settings.fields.map(field => (
          <MenuItem key={field.type} value={field.type}>
            {field.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );

  const renderFreeValue = () => (
    <GuiLight
      contentsHTML={settings.value || ""}
      onChange={onFreeValueChange}
      editorId={settings.id}
    />
  );

  return (
    <div
      style={{
        ...styles,
        display: "flex",
        marginRight: 6,
        marginTop: 4,
      }}
    >
      <div
        style={{
          borderRight: "1px solid rgba(0, 0, 0, 0.3)",
          flexShrink: 0,
        }}
      >
        <StatefulTextField
          inputId={isLastRow ? "last_added_row" : undefined}
          label="Name"
          value={settings.label}
          onBlur={onLabelChange}
          preventError={true}
          style={{marginRight: 12}}
        />
      </div>
      <div style={{width: 420}}>
        <CheckBoxBasic
          iconClassName="visible"
          checked={useIssueValue}
          onCheck={onCheckboxCheck}
          containerStyles={{
            marginTop: 12,
            visibility: "visible",
          }}
          label="Take value from Issue"
        />
        <div
          style={{
            marginLeft: 8,
            marginTop: 8,
          }}
        >
          {useIssueValue ? renderValueFromIssue() : renderFreeValue()}
        </div>
      </div>
    </div>
  );
}

function getSelectedIssuesetIssues(relevantIssues, issuesetId) {
  return relevantIssues.reduce((accum, issue) => {
    const issueIssuesetIds = issuesetUtils.getIssuesetIdsPresentInContractTypes(
      issue.contract_types,
    );
    if (issueIssuesetIds.indexOf(issuesetId) !== -1) {
      accum.push(issue);
    }
    return accum;
  }, []);
}

function getIssueNames(issues, issueset) {
  const names = issues.map(issue => {
    const baseIssueName =
      (issueset &&
        (_.get(
          issue.override_values,
          `${issueset.master_id}.${issueset.remote_client_id}`,
        )?.display_name ||
          _.get(issue.override_values, `${issueset.master_id}.master`)
            ?.display_name)) ||
      issue.display_name ||
      issue.name;
    return getIssueName(baseIssueName);
  });

  return _.chain(names)
    .sortBy(name => name.toLowerCase())
    .uniq()
    .value();
}

function getIssueName(name) {
  let resultName = name;
  const bracketIndex = name.indexOf("[");
  if (bracketIndex !== -1) {
    resultName = resultName.substring(0, bracketIndex).trim();
  }

  if (resultName.endsWith("/")) {
    resultName = resultName.substring(0, resultName.length - 1).trim();
  }

  const resultNameSplitted = resultName.split("/");
  return resultNameSplitted[resultNameSplitted.length - 1].trim();
}

export default CustomReportSettings;

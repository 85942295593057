export default function findReference(node) {
  if (node.reference) {
    return node.reference;
  }
  if (node.clauseNodes) {
    let childReference;
    if (
      node.clauseNodes.some(child => {
        childReference = findReference(child);
        return childReference;
      })
    ) {
      return childReference;
    }
  }
  return null;
}

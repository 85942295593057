import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function userPasswordUpdate(requestor) {
  return createReduxPromiseAction(
    ActionTypes.USER_PASSWORD_UPDATE,
    (userId, data) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }

      return requestor
        .put(`${basePath}/user/${userId}/password`, data, {
          withCredentials: true,
        })
        .then(response => ({
          ...response.data,
        }));
    },
  );
}

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export function transition(prevState, nextState, action) {
  if (action.type === ActionTypes.PROJECT_CREATE.SUCCESS) {
    const {organisationId} = action.payload;
    return {
      pathname: `/organisation/${organisationId}/project/list`,
    };
  }
  return null;
}

export default function initProject(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PROJECT_CREATE,
    (organisationId, data) =>
      requestor
        .post(`${basePath}/organisation/${organisationId}/project`, data, {
          withCredentials: true,
        })
        .then(response => ({organisationId, ...response.data})),
    null,
    {transition},
  );
}

function isSameDocumentIssueBySection(
  issueId,
  selectedChecklistSectionId,
  documentIssue,
) {
  if (!documentIssue) {
    return false;
  }
  return !selectedChecklistSectionId
    ? documentIssue.id === issueId && !documentIssue.section_id
    : documentIssue.id === issueId &&
        documentIssue.section_id === selectedChecklistSectionId;
}

function isSameDetailShown(newIssue, oldIssue, selectedChecklistSectionId) {
  if (!newIssue) {
    return false;
  } else if (!oldIssue.underlyingIssues && !newIssue.underlyingIssues) {
    return newIssue.id === oldIssue.id;
  }
  return Boolean(
    (newIssue.underlyingIssues || []).find(underlyingIssue =>
      isSameDocumentIssueBySection(
        oldIssue.id,
        selectedChecklistSectionId,
        underlyingIssue,
      ),
    ),
  );
}

export default {
  isSameDocumentIssueBySection,
  isSameDetailShown,
};

import _ from "lodash";

import setNestedObjects from "common/utils/setNestedObjects";
import deleteNestedObjectsIfEmpty from "common/utils/deleteNestedObjectsIfEmpty";

import {documentIssueOverrideFields} from "common/utils/issues/get_overridden_issue";

function convertUpdatesToOverridesIfAvailable(issue, updates, issueset) {
  if (!issue || !updates || !issueset) {
    return;
  }
  const dataPath = !issueset.is_duplicate_on_master
    ? `${issueset.master_id}.master`
    : `${issueset.master_id}.${issueset.remote_client_id}`;

  const overrideValues = _.cloneDeep(
    issue.override_document_issue_values || {},
  );

  const updatedKeys = Object.keys(updates);

  /* eslint-disable no-continue */
  for (const fieldName of updatedKeys) {
    if (
      !documentIssueOverrideFields.find(field => field.fieldName === fieldName)
    ) {
      continue;
    }
    const fieldValue = updates[fieldName];

    if (fieldValue !== undefined && fieldValue !== null) {
      if (!_.has(overrideValues, dataPath)) {
        setNestedObjects(overrideValues, dataPath);
      }
      _.set(overrideValues, `${dataPath}.${fieldName}`, fieldValue);
    } else if (_.has(overrideValues, dataPath)) {
      delete _.get(overrideValues, dataPath)[fieldName];
      deleteNestedObjectsIfEmpty(overrideValues, dataPath);
    }
    delete updates[fieldName];
  }
  updates.override_document_issue_values = overrideValues;
}

export default convertUpdatesToOverridesIfAvailable;

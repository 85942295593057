import findGroupedIssue from "utils/issues/find_grouped_issue";
import calculateIssueApplicableClauses from "utils/issues/calculate_applicable_clauses";

export default function getIssuesWithApplicableClauses(
  issues,
  groupedIssues,
  currentIssuesetItem,
  positiveReasonData,
  additionalReportClausesState = [],
  props,
) {
  return issues.map(issue => {
    const groupedIssue = findGroupedIssue(issue, groupedIssues);
    if (groupedIssue) {
      const {applicableClauses} = calculateIssueApplicableClauses(
        groupedIssue,
        currentIssuesetItem,
        props.documentClauses,
        props.topicCategories.map(tc => tc.id),
        props.documentSections,
        props.topicsById,
        positiveReasonData,
        props.documentClauseparts,
        props.document.parties,
        false,
        additionalReportClausesState,
      );
      return {
        ...issue,
        subissueData: groupedIssue.subissueData,
        currentApplicableClauses: applicableClauses,
      };
    }
    return issue;
  });
}

import _ from "underscore";

import getClauseByPath from "./get_clause_by_path";

function cleanParts(parts) {
  return parts.map(part => _.omit(part, "type"));
}

function applyPart(clause, part) {
  if (part.path && part.path.length > 0) {
    const el = getClauseByPath(clause, part.path);
    if (el) {
      Object.assign(el, part);
    }
  }
}

function addPathToTreeNodes(clause, path = "root") {
  if (clause) {
    if (!clause.path) {
      clause.path = path;
    }
    if (clause.clauseNodes) {
      clause.clauseNodes.forEach((node, index) => {
        addPathToTreeNodes(node, `${path}[${index}]`);
      });
    }
  }
  return clause;
}

function normaliseClause(clause) {
  const parts = cleanParts(clause.parts);
  parts.forEach(part => applyPart(clause, part));
  const clauseWithouParts = _.omit(clause, "parts");
  return {
    ...clauseWithouParts,
    nodes: addPathToTreeNodes(clauseWithouParts.nodes),
  };
}

export default normaliseClause;

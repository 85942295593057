import React from "react";

import CopyButton from "common_components/buttons/copy_button";
import copy from "copy-to-clipboard";

const styles = {
  reference: {
    flexGrow: "0",
    flexShrink: "0",
    width: "3em",
    paddingTop: "6px",
  },
  clausepart: {
    cursor: "pointer",
    marginBottom: "6px",
    flexGrow: "1",
    padding: 1,
  },
  clausepartSelected: {
    border: "solid 1px #2196f3",
    backgroundColor: "rgba(0, 120, 212, 0.05)",
  },
  text: {
    padding: "0.5em",
  },
  bottomPanel: {
    width: "100%",
    cursor: "pointer",
    color: "#42A5F5",
    borderTop: "1px solid #d6d6d6",
    marginTop: "0.5em",
    padding: "0.5em",
    boxSizing: "border-box",
    fontSize: "0.9em",
    display: "flex",
    justifyContent: "space-between",
  },
};

export default function ExpandableClausepart(props) {
  const {
    text,
    textElements,
    reference,
    isExpanded,
    isExpandable,
    onExpand,
    isSelected,
    onSelect,
    isFlipChecklistIcons,
  } = props;

  function expandClausepart(e) {
    e.stopPropagation();
    onExpand();
  }

  function copyText() {
    copy(text);
  }

  return (
    <div style={{display: "flex"}}>
      <div style={styles.reference}>{reference}</div>
      <div
        style={{
          ...styles.clausepart,
          ...(isFlipChecklistIcons
            ? {border: "1px solid white"}
            : {border: "1px solid #e0e0e0"}),
          backgroundColor: isFlipChecklistIcons ? "#f3f3f3" : "#eeeeee",
          ...(isSelected ? styles.clausepartSelected : {}),
        }}
        onClick={onSelect}
      >
        <div style={styles.text}>{textElements}</div>
        <div
          onClick={isExpandable ? expandClausepart : onSelect}
          style={styles.bottomPanel}
        >
          <span>
            {isExpandable ? (isExpanded ? "Read Less" : "Read More") : ""}
          </span>
          <CopyButton
            hideCaption={true}
            onCopyClick={copyText}
            iconStyle={{color: "#afafaf"}}
          />
        </div>
      </div>
    </div>
  );
}

import React, {useEffect, useRef} from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import LaunchIcon from "@material-ui/icons/Launch";
import ReplayIcon from "@material-ui/icons/Replay";
import makeComponentHoverable from "common_components/hocs/make_component_hoverable";
import RowChild from "./row_child";

const styles = {
  rightIcon: {
    cursor: "pointer",
    width: 16,
    height: 16,
    marginTop: 5,
  },
  scrollStyle: {
    behavior: "smooth",
    block: "start",
  },
};

const ReportItem = props => {
  const {
    item,
    issues,
    itemIndex,
    broadcastData,
    selectedReportId,
    isReportShownInDialog,
    isHovered,
    updateAdditionalReportItems,
    removeIssueFromReport,
    deleteAdditionalReportItem,
  } = props;

  useEffect(() => {
    if (issue && broadcastData?.selectedIssue === issue.id) {
      scrollToElement();
    }
  }, [broadcastData]);

  const nodeRef = useRef(null);
  const scrollToElement = () =>
    nodeRef.current.scrollIntoView(styles.scrollStyle);

  const issue = issues.find(
    issue => issue.document_issue_id === item.document_issue_id,
  );

  const getShowIssueDetailClicker = (item, props) => {
    if (item.document_issue_id && props.onShowIssueDetail) {
      return function onRagRowClick() {
        props.onShowIssueDetail(item.document_issue_id);
      };
    }
  };

  if (item && item.children && item.children.length > 0) {
    const showIssueDetailClicker = getShowIssueDetailClicker(item, props);
    const shouldRenderIcon =
      item.document_issue_id && !props.renderInSeparateWindow;
    const removeIssueFromRagReport = () => {
      if (item?.additional_item) {
        return deleteAdditionalReportItem(item.additional_item);
      }
      if (removeIssueFromReport) {
        return removeIssueFromReport(issue.document_issue_id);
      }
    };

    const revertDeletedIssue = () => {
      if (props.revertDeletedIssue) {
        props.revertDeletedIssue(issue.document_issue_id);
      }
    };

    const isIssueDeleted = issue && issue.isDeleted;
    const RightIcon = isIssueDeleted ? ReplayIcon : DeleteIcon;
    const rightIconHandler = isIssueDeleted
      ? revertDeletedIssue
      : removeIssueFromRagReport;

    return (
      <tr
        ref={nodeRef}
        id={
          item.document_issue_id && `rag-report-item-${item.document_issue_id}`
        }
        style={{borderBottom: "1px solid #e0e0e0"}}
        onMouseEnter={props.onHoverStart}
        onMouseLeave={props.onHoverFinish}
      >
        <td style={isIssueDeleted ? {background: "red"} : {}} />
        <td>
          {props.isDocumentReport
            ? null
            : shouldRenderIcon && (
                <div title="Show Issue Detail">
                  <LaunchIcon
                    onClick={showIssueDetailClicker}
                    style={{
                      ...styles.rightIcon,
                      visibility:
                        !isReportShownInDialog && isHovered
                          ? "visible"
                          : "hidden",
                    }}
                  />
                </div>
              )}
        </td>
        {item.children.map((rowChild, rowChildIndex) => (
          <RowChild
            key={`${itemIndex}-${rowChildIndex}`}
            rowChildIndex={`${itemIndex}-${rowChildIndex}`}
            rowChild={rowChild}
            scrollToClause={props.scrollToClause}
            issue={issue}
            correctDocumentIssueManually={props.correctDocumentIssueManually}
            updateFreeTextFieldValue={props.updateFreeTextFieldValue}
            selectedReportId={selectedReportId}
            project={props.project}
            updateDocumentIssue={props.updateDocumentIssue}
            user={props.user}
            updateApplicableClausesShown={props.updateApplicableClausesShown}
            applicableClausesShown={props.applicableClausesShown}
            updateIssueReviewState={props.updateIssueReviewState}
            issueset={props.issueset}
            showButtonsHandler={props.showButtonsHandler}
            saveTrigger={props.saveTrigger}
            cancelTrigger={props.cancelTrigger}
            reportType={props.reportType}
            isSaveButtonShown={props.isSaveButtonShown}
            areAllItemsNumbered={props.areAllItemsNumbered}
            updateAdditionalReportItems={updateAdditionalReportItems}
            saveButtonHandler={props.saveButtonHandler}
          />
        ))}
        <td>
          {(props.canDelete && item.document_issue_id) ||
          item?.additional_item ? (
            <div
              title={isIssueDeleted ? "Undo Deletion" : "Delete From Report"}
              style={{
                paddingTop: "1px",
                display: "flex",
                justifyContent: "right",
                paddingRight: "5px",
              }}
            >
              <RightIcon
                onClick={rightIconHandler}
                style={{
                  ...styles.rightIcon,
                  visibility:
                    !isReportShownInDialog && isHovered ? "visible" : "hidden",
                }}
              />
            </div>
          ) : null}
        </td>
      </tr>
    );
  }
  return null;
};

export default makeComponentHoverable(ReportItem);

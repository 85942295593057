import radium from "radium";
import React from "react";

import constantSyles from "constants/text_changes_styles";

export const styles = {
  main: {
    display: "flex",
    width: "100%",
  },
  number: {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    alignItems: "stretch",
    marginRight: "0.5em",
  },
  numberText: {
    display: "block",
    fontWeight: 400,
    // color: "#6f6f6f",
    textAlign: "left",
  },
  start: {},
  end: {},
  conjunctionText: {
    fontStyle: "italic",
    color: "#ccc",
    flexGrow: "2",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
};

class NumberedItem extends React.Component {
  render() {
    const {
      node,
      clause,
      number,
      start,
      end,
      hasTopLevelReference,
      isClauseDeletion,
      isClausepartDeletion,
      isClauseAddition,
      isClausepartAddition,
      onNumberItemHoverFinish,
      addClausesToReportDocumentIssueId,
      onAddClauseToReport,
      isNumberItemsHovered,
    } = this.props;
    return (
      <div
        className="clause-list numbered-item"
        key={clause.id}
        style={[styles.main]}
      >
        {this.willRenderListItemReference() && (
          <span
            className="number"
            style={[
              styles.number,
              start ? styles.start : {},
              end ? styles.end : {},
              (node.level || clause.level) <= 2 && hasTopLevelReference
                ? {marginLeft: "4.5em"}
                : {},
              {width: getNumberLength(number)},
              addClausesToReportDocumentIssueId ? {cursor: "pointer"} : {},
            ]}
            onMouseEnter={this.onNumberHover}
            onMouseLeave={onNumberItemHoverFinish}
            onClick={() => onAddClauseToReport(isNumberItemsHovered)}
          >
            <span
              className="number-text"
              style={{
                ...styles.numberText,
                ...(isClauseDeletion ||
                isClausepartDeletion ||
                isClauseAddition ||
                isClausepartAddition
                  ? constantSyles.textChanges.text_addition
                  : {}),
              }}
            >
              {number}
            </span>
          </span>
        )}
        {node}
      </div>
    );
  }

  willRenderListItemReference = () => {
    return !(
      this.props.hideTextDeletions &&
      (this.props.isClauseDeletion || this.props.isClausepartDeletion)
    );
  };

  onNumberHover = () => {
    const {
      firstNodeAtomId,
      setLastHoveredClausepartId,
      onNumberItemHoverStart,
      clause,
    } = this.props;
    if (firstNodeAtomId) {
      setLastHoveredClausepartId(firstNodeAtomId);
    }
    return onNumberItemHoverStart(clause);
  };
}

function getNumberLength(number) {
  if (number) {
    const partsCount = `${number}`.split(".").length;
    return `${partsCount * 0.8 + 1}em`;
  }
  return "0";
}

export default radium(NumberedItem);

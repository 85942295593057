import _ from "underscore";

function getTopicsSearchData(searchValue, documentTopics, topicsById) {
  const topics = [];
  if (!searchValue) {
    return [];
  }
  const words = searchValue.toLowerCase().split(/\s+/);
  documentTopics.forEach(topic => {
    const {topicName: name} = topic;
    const searchName = name.toLowerCase();
    if (words.every(word => searchName.indexOf(word) !== -1)) {
      topics.push({
        id: topic.id,
        name: topic.topicName,
        topicDescription: topic.topic.description,
        fullName: topicsById[topic.id].name,
      });
    }
  });
  return _.sortBy(topics, topic => topic.name);
}

export default getTopicsSearchData;

export default function getItemReturnValue(atom, returnType) {
  switch (returnType) {
    case "clausepart_reference":
      return `${atom.section_id}-${atom.reference}`;
    case "clausepart_id":
      return atom.id;
    case "clause_id":
      return atom.clause_id;
    case "clause_reference":
      return `${atom.section_id}-${atom.clause_reference}`;
    default:
      return atom.id;
  }
}

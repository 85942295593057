import functionList from "./function_list";

function evaluateFunction(context) {
  return (value, code) => {
    const functionName = code.split(" ")[0];
    const args = code
      .substring(functionName.length)
      .trim()
      .replace(/^"|"$/g, "")
      .split('" "');
    return functionList[functionName](...[value, ...args, context]);
  };
}

function composeHelper(value, ...functions) {
  const context = functions.pop();
  return functions
    .filter(fn => typeof fn === "string")
    .reduce(evaluateFunction(context), value);
}

export default composeHelper;

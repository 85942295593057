export default function getIssueUiOrderIds(initialIssue) {
  const issuePaths = [];
  function addIssuePaths(issue) {
    if (typeof issue.ui_order_id !== "undefined") {
      issuePaths.push(issue.ui_order_id);
    }
    if (issue.rules && issue.rules.issues && issue.rules.issues.length > 0) {
      issue.rules.issues.forEach(_issue => addIssuePaths(_issue));
    }
  }
  addIssuePaths(initialIssue);
  return issuePaths;
}

import React from "react";
import _ from "lodash";

import NumberedItem from "./numbered_item";

import GraphNode from "./graph_node";
import calculateNumber from "../../../../utils/calculate_number";
import calculateNodeKey from "../../../../utils/calculate_node_key";
import renderReference from "../utils/render_reference";
import getNodeAtoms from "../utils/get_node_atoms";
import increaseReference from "common/utils/increase_reference";
import GuiTextEditor from "common_components/gui_text_editor";
import {isIe11} from "utils/browser_test";
import escapeRegex from "utils/escape_regex";
import cleanReference from "utils/clauses/clean_reference";
import isDoubleNewLineInContents from "../utils/is_double_newline_in_contents";

const styles = {
  graphItem: {
    ...(isIe11() ? {width: "100%"} : {}),
  },
  textEditor: {
    width: "100%",
  },
};

const renderChanges = false;

function areAllChildAtomsDeleted(atoms, changes) {
  const result = atoms.filter(atom => {
    const atomDeletionChange = changes.find(
      change =>
        change.type === "clausepart_deletion" &&
        change.old_clausepart_id === change.new_clausepart_id &&
        change.new_clausepart_id === atom.id,
    );
    return !atomDeletionChange;
  });
  return result.length === 0;
}

function childContainsSingleElementAndConjunction(child, change) {
  const changePath = change.old_path;
  if (
    !child.type.endsWith("Atom") &&
    changePath &&
    changePath.endsWith("[0]")
  ) {
    const changePathWithoutLast = changePath.substring(
      0,
      changePath.length - 3,
    );
    if (changePathWithoutLast === child.path) {
      if (
        child.clauseNodes &&
        child.clauseNodes.length === 2 &&
        child.clauseNodes[1].is_conjunction
      ) {
        return true;
      }
    }
  }
  return false;
}

export default function renderClauseList(props) {
  const {
    clause,
    reference,
    firstNode,
    lastNode,
    path,
    changes,
    isClauseDeletion,
    listItemPath: parentListItemPath,
  } = props;
  const {length: nodeCount} = clause.clauseNodes;
  const lastClause = clause.clauseNodes[nodeCount - 1];

  const referenceText =
    props.showReference &&
    renderReference(props.numberParent, {
      ...clause,
      reference: clause.reference || reference,
    });
  const isSectionUnnumbered = props.section.is_unnumbered;
  const willRenderReference =
    !isSectionUnnumbered && referenceText && referenceText.length > 0;
  const deletions = changes.filter(
    change =>
      change.type === "clausepart_deletion" &&
      (change.old_path.match(
        new RegExp(`${escapeRegex(`${path}[`)}[^[]+?${escapeRegex("]")}$`),
      ) ||
        // TODO: Remove this is a cludge to make a particular document work for a demo
        change.old_path.match(
          new RegExp(`${escapeRegex(`${path}[`)}[^[]+?${escapeRegex("][0]")}$`),
        )),
  );
  const isListUnnumbered =
    !clause.type || clause.type.indexOf("Unnumbered") >= 0;
  let remainingDeletions = deletions;
  // we can't show several clausepart creation editors simultaneously thus use
  // isClausepartCreationEditorShown flag for tracking if it's already shown
  let isClausepartCreationEditorShown = false;
  // we use prevListItemReference to track listItem references when clausepart creation
  // editor is rendered
  let prevListItemReference = null;
  // keeping track of deleted items number in order to shift back the references of non-deleted items
  let listItemDeletionsOffset = 0;
  // nonDeletedListItems: keeping track of all deleted items in order to properly render clausepart creation editor
  // references after deleted list item(s). For editor rendering we need to have a reference point of previous closest
  // non deleted item. In case the first list item is deleted and we want to add an item to the beginning
  // of the list we use the first list item (irrespectively of it's deletion status) as a reference point.
  const nonDeletedListItems = [];

  const list = (
    <div className="items graph-item" style={styles.graphItem}>
      {_.flatten(
        clause.clauseNodes.map((child, index) => {
          if (!child || child.is_conjunction) {
            return null;
          }
          const nextIsConjunction =
            index === nodeCount - 2 && lastClause && lastClause.is_conjunction;

          // isInNumberedList is used to properly render atoms' add above/below buttons on hover: if the atom is in
          // unnumbered list which in its turn is in numbered list (closest ancestor) we show add buttons. If the
          // atom is not in numbered list we don't show add buttons (see atom.js)
          const isInNumberedList = getIsInNumberedList(
            props.isInNumberedList,
            isListUnnumbered,
            clause,
          );

          const matchingDeletions = deletions.filter(
            change =>
              change.old_document_id !== change.new_document_id &&
              change.old_path.match(
                new RegExp(escapeRegex(`${path}[${index}]`)),
              ),
          );

          remainingDeletions = _.without(
            remainingDeletions,
            ...matchingDeletions,
          );
          const listItem = nextIsConjunction
            ? addConjunction(child, lastClause, reference)
            : child;
          addListClauseChildrenData(listItem);
          const listItemPath = nextIsConjunction
            ? parentListItemPath
            : child.path;

          const isClausepartDeletion =
            Boolean(
              changes.find(
                change =>
                  child.path === change.old_path ||
                  childContainsSingleElementAndConjunction(child, change),
              ),
            ) || areAllChildAtomsDeleted(getNodeAtoms(child), changes);

          const isClausepartAddition = Boolean(
            changes.find(change => change.new_clausepart_id === listItem.id),
          );

          const items = [];
          // 1. Getting clausepart creation editor for the first list item (when
          // adding before current existing/deleted one)
          if (
            !isClausepartCreationEditorShown &&
            shouldPushNewClausepart(
              "zeroItemPositionIndexInList",
              props,
              child.path,
            )
          ) {
            const clauseItemNumber = getListItemReference(
              listItem,
              index,
              clause, // parentClause
              reference, // parentReference
              isListUnnumbered,
              null, // prevListItemReference
              0, // listItemDeletionsOffset
            );
            const newClausepartEditor = getClausepartCreationEditorElement(
              getClausepartCreationCommonEditorParams(
                props,
                child,
                isListUnnumbered,
              ),
              formatPathToZero(child.path),
              clauseItemNumber,
              false,
              null,
              false, // props.hasFooter
              props,
            );
            items.push(newClausepartEditor);
            prevListItemReference = clauseItemNumber;
            isClausepartCreationEditorShown = true;
          }

          // 2. Getting list item element
          const listItemReference = isClausepartDeletion
            ? "—"
            : getListItemReference(
                listItem,
                index,
                clause, // parentClause
                reference, // parentReference
                isListUnnumbered,
                prevListItemReference,
                listItemDeletionsOffset,
              );

          if (index === 0) {
            // This is used for the case the first list item is deleted and we want to add an item to the beginning
            // of the list. Thust we use the first list item (irrespectively of it's deletion status) as a reference point.
            nonDeletedListItems.push({clause: listItem, index});
          }
          if (isClausepartDeletion) {
            listItemDeletionsOffset -= 1;
          } else {
            prevListItemReference = listItemReference;
            if (index !== 0) {
              nonDeletedListItems.push({
                clause: listItem,
                index: index + listItemDeletionsOffset + 1,
              });
            }
          }
          items.push(
            getListItemElement(
              props,
              clause,
              listItem,
              reference,
              index,
              index === 0,
              index === nodeCount - 1,
              firstNode,
              lastNode,
              willRenderReference,
              isClauseDeletion,
              isClausepartDeletion,
              listItemPath,
              isListUnnumbered,
              listItemReference,
              isClausepartAddition,
              isInNumberedList,
            ),
          );

          // 3. Getting clausepart creation editor being not the first in the list
          if (
            !isClausepartCreationEditorShown &&
            shouldPushNewClausepart(
              "nonZeroItemPositionIndexInList",
              props,
              child.path,
            )
          ) {
            let newClauseItemNumber;
            if (prevListItemReference) {
              newClauseItemNumber = increaseReference(
                listItemReference,
                prevListItemReference,
              );
            } else {
              const lastNonDeletedItem = nonDeletedListItems.reduce(
                (resultRaw, item) => {
                  let result = resultRaw;
                  if (item.index <= index) {
                    result = item;
                  }
                  return result;
                },
                null,
              );
              newClauseItemNumber =
                lastNonDeletedItem &&
                getListItemReference(
                  lastNonDeletedItem.clause,
                  lastNonDeletedItem.index,
                  clause, // parentClause
                  reference, // parentReference
                  isListUnnumbered,
                  null,
                  0,
                );
            }
            const newClausepartEditor = getClausepartCreationEditorElement(
              getClausepartCreationCommonEditorParams(
                props,
                child,
                isListUnnumbered,
              ),
              child.path,
              newClauseItemNumber,
              index === nodeCount - 1,
              props.newClausepartData,
              props.hasFooter,
              props,
            );
            items.push(newClausepartEditor);
            prevListItemReference = newClauseItemNumber;
            isClausepartCreationEditorShown = true;
          }

          // 4. TODO: proper handling deletions for template comparisons
          if (matchingDeletions.length > 0 && renderChanges) {
            // this block will never be executed
            items.push(
              matchingDeletions.map(deletion =>
                renderDeletion(
                  deletion,
                  props,
                  clause,
                  null, // TO DO: check this properly
                  reference,
                  index,
                  index === 0,
                  index === nodeCount - 1,
                  firstNode,
                  lastNode,
                  false,
                  listItemPath,
                  prevListItemReference,
                ),
              ),
            );
          }
          // prevListItemReference is only used to proper render references for
          // clausepart creation editor. Therefore if the editor is not shown we
          // don't keep track of prevListItemReference
          if (!isClausepartCreationEditorShown) {
            prevListItemReference = null;
          }
          return items;
        }),
      )}
      {renderChanges &&
        remainingDeletions
          .filter(
            deletion => deletion.old_document_id !== deletion.new_document_id,
          )
          .map(deletion => {
            const index = clause.clauseNodes.length;
            return renderDeletion(
              deletion,
              props,
              clause,
              null, // TO DO: check this properly
              reference,
              index,
              index === 0,
              index === nodeCount - 1,
              firstNode,
              lastNode,
              false,
              parentListItemPath,
              prevListItemReference,
            );
          })}
    </div>
  );

  return props.isTableRow ? (
    <td
      style={{
        border: "1px solid #e0e0e0",
        padding: "4px",
        verticalAlign: "top",
      }}
      colSpan={clause.colSpan || 1}
    >
      {list}
    </td>
  ) : (
    list
  );
}

function renderDeletion(
  deletion,
  props,
  parentClause,
  listItem,
  reference,
  index,
  start,
  end,
  firstNode,
  lastNode,
  willRenderReference,
  listItemPath,
) {
  const clause = {
    id: deletion.new_clausepart_id,
    type: "Atom",
    partial_text: deletion.old_text,
    text: deletion.old_text,
    load_state: 7,
    topics: deletion.old_clausepart_topics,
    last_edited: "",
    changes: [],
    reference,
    counterType: parentClause.counterType,
    level: parentClause.level,
  };

  return getListItemElement(
    {...props, clause},
    clause,
    {...clause, level: clause.level + 1},
    reference,
    index,
    start,
    end,
    firstNode,
    lastNode,
    willRenderReference,
    false,
    true,
    listItemPath,
  );
}

function addConjunction(child, lastClause, reference) {
  if (child && child.type && child.type.endsWith("Atom")) {
    return {...child, text: `${child.text} ${lastClause.text}`};
  }
  if (
    !child ||
    !child.clauseNodes ||
    !child.clauseNodes[child.clauseNodes.length - 1]
  ) {
    return child;
  }
  const conjunction = addConjunction(
    child.clauseNodes[child.clauseNodes.length - 1],
    lastClause,
    reference,
  );
  if (!conjunction) {
    return child;
  }
  return {
    ...child,
    clauseNodes: [...child.clauseNodes.slice(0, -2), conjunction],
  };
}

function formatPathToZero(path) {
  return path.replace(/\[\d+\]$/, "[-1]");
}

function shouldPushNewClausepart(position, props, childPath) {
  const {newClausepartData, documentClause} = props;
  const hasNewClausepart =
    newClausepartData && newClausepartData.clauseId === documentClause.id;
  if (hasNewClausepart) {
    const {path: newPath} = newClausepartData;
    switch (position) {
      case "zeroItemPositionIndexInList": {
        const zeroPath = formatPathToZero(childPath);
        return newPath === zeroPath;
      }
      case "nonZeroItemPositionIndexInList": {
        return newPath === childPath;
      }
    }
  }
  return false;
}

function getClausepartCreationEditorElement(
  params,
  newPath,
  clauseItemNumber,
  isEditorLastInList,
  newClausepartData,
  listHasFooter,
  props,
) {
  const newClausepartId = `add-clausepart-${newPath}`;
  // updateHandler is used for the case when adding last item to a list and
  // hitting enter twice => this will bring new editor on the level above
  // current
  const updateHandler = getGuiEditorUpdateHandler(
    isEditorLastInList,
    params.documentClause,
    params.listPath,
    params.showAddClausepartEditor,
    newClausepartData && newClausepartData.path,
    listHasFooter,
  );

  const addNewItemOnTheLevelAboveEndHandler = getAddNewItemOnTheLevelAboveEndHandler(
    isEditorLastInList,
    params.documentClause,
    params.listPath,
    params.showAddClausepartEditor,
    newClausepartData && newClausepartData.path,
    listHasFooter,
  );

  const editorNode = (
    <div key={newClausepartId} style={styles.textEditor}>
      <GuiTextEditor
        contents={newClausepartData && newClausepartData.oldContents}
        closeHandler={params.saveNewClausepartBinded}
        updateHandler={updateHandler}
        addNewItemOnTheLevelAboveEndHandler={
          addNewItemOnTheLevelAboveEndHandler
        }
        startingLevel={params.level}
        usedListFormatStylesPerLevel={props.usedListFormatStylesPerLevel}
        decimalPrefix={
          /^[\d.]+$/.test(clauseItemNumber) ? clauseItemNumber : undefined
        }
      />
    </div>
  );
  if (params.isListUnnumbered) {
    return editorNode;
  }
  return (
    <NumberedItem
      node={editorNode}
      clause={{
        level: params.level,
        id: newClausepartId,
      }}
      number={clauseItemNumber}
      key={newClausepartId}
      parentNode={params.clause}
      hasTopLevelReference={params.hasTopLevelReference}
      isClausepartAddition={true}
      onAddClauseToReport={props.onAddClauseToReport}
      isNumberItemsHovered={props.isNumberItemsHovered}
    />
  );
}

function getGuiEditorUpdateHandler(
  isEditorLastInList,
  documentClause,
  listPath,
  showAddClausepartEditor,
  newClausepartDataPath,
  listHasFooter,
) {
  if (!isEditorLastInList || listHasFooter) {
    return;
  }
  return function(props, prevProps, state, prevState) {
    const {contents: currentContents} = state;
    const {contents: prevContents} = prevState;
    if (
      !_.isEqual(currentContents, prevContents) &&
      isDoubleNewLineInContents(currentContents)
    ) {
      // here we wait for quill's internal selection state update and
      // only after that run our function
      _.delay(
        addNewItemOnTheLevelAboveEnd,
        1,
        documentClause,
        listPath,
        showAddClausepartEditor,
        newClausepartDataPath,
      );
    }
  };
}

function getAddNewItemOnTheLevelAboveEndHandler(
  isEditorLastInList,
  documentClause,
  listPath,
  showAddClausepartEditor,
  oldPath,
  listHasFooter,
) {
  if (!isEditorLastInList || listHasFooter) {
    return;
  }
  return function(contents) {
    addNewItemOnTheLevelAboveEnd(
      documentClause,
      listPath,
      showAddClausepartEditor,
      oldPath,
      contents,
    );
  };
}

function addNewItemOnTheLevelAboveEnd(
  documentClause,
  listPath,
  showAddClausepartEditor,
  oldPath,
  oldContents,
) {
  if (!documentClause) {
    return;
  }
  const {id: clauseId} = documentClause;
  if (listPath && listPath !== "root") {
    showAddClausepartEditor(clauseId, listPath, oldPath, oldContents);
  }
}

function getClausepartCreationCommonEditorParams(
  listProps,
  child,
  isListUnnumbered,
) {
  return {
    saveNewClausepartBinded: listProps.saveNewClausepartBinded,
    documentClause: listProps.documentClause,
    isClauseDeletion: listProps.isClauseDeletion,
    showAddClausepartEditor: listProps.showAddClausepartEditor,
    showAddClauseEditor: listProps.showAddClauseEditor,
    listPath: listProps.clause.path,
    hasTopLevelReference: listProps.hasTopLevelReference,
    level: child.level,
    isListUnnumbered,
  };
}

function getListItemReference(
  listItem,
  childIndex,
  parentClause,
  parentReference,
  isListUnnumbered,
  prevListItemReference,
  listItemDeletionsOffset,
) {
  let listItemReference;
  if (isListUnnumbered) {
    listItemReference = parentReference;
  } else {
    const {counterType} = parentClause;
    const number = calculateNumber(
      counterType,
      childIndex + listItemDeletionsOffset,
      parentReference,
    );
    listItemReference = getReference(
      number,
      counterType,
      parentReference,
      listItem.level,
    );
  }

  if (prevListItemReference) {
    listItemReference = increaseReference(
      listItemReference,
      prevListItemReference,
    );
  }
  return listItemReference;
}

function getListItemElement(
  props,
  parentClause,
  listItem,
  parentReference,
  childIndex,
  start,
  end,
  firstNode,
  lastNode,
  willRenderReference,
  isClauseDeletion = false,
  isClausepartDeletion = false,
  listItemPath = null,
  isListUnnumbered,
  listItemReference,
  isClausepartAddition,
  isInNumberedList,
) {
  const {conjunctionType, level} = parentClause;
  const clausePartNumber = listItem ? listItem.clausePartNumber : null;

  const key = calculateNodeKey({...props, index: childIndex});

  // firstNodeAtomId is used to render clauseControl buttons when hovering over
  let firstNodeAtomId;
  if (
    !isListUnnumbered &&
    listItemReference &&
    listItem.type !== "PendingSave"
  ) {
    firstNodeAtomId = getFirstNodeAtomId(listItem);
  }
  const node = (
    <div style={{width: "100%"}} key={key}>
      <GraphNode
        {...props}
        isTableRow={false}
        clause={{
          ...props.clause,
          is_top_level_subclause:
            isListUnnumbered && props.is_top_level_subclause,
        }}
        node={listItem}
        parentNode={parentClause}
        numberParent={
          !isListUnnumbered
            ? {...parentClause, reference: parentReference}
            : props.numberParent
        }
        reference={listItemReference}
        index={childIndex}
        firstNode={firstNode && start}
        lastNode={lastNode && end}
        indentFooter={
          isListUnnumbered &&
          level === (props.is_top_level_subclause ? 2 : 1) &&
          clausePartNumber > 1 &&
          willRenderReference
        }
        hasNumber={!isListUnnumbered || props.hasNumber}
        isInNumberedList={isInNumberedList}
        // this is used for the case when we have unnumbered list in
        // numbered list and we want to add clausepart before/after
        // to the numbered list. See getCurrentPath() in atom.js
        isFirstParentListNumbered={!isListUnnumbered}
        isClauseDeletion={isClauseDeletion}
        isClausepartDeletion={isClausepartDeletion}
        listItemPath={listItemPath}
      />
    </div>
  );
  if (isListUnnumbered || listItem.type === "PendingSave") {
    return node;
  }
  return (
    <NumberedItem
      {...props}
      clause={listItem}
      node={node}
      parent={parentClause}
      key={`${listItem.id}_${listItemReference}_${childIndex}`}
      number={listItemReference}
      start={start}
      end={end}
      conjunctionType={conjunctionType}
      isClauseDeletion={isClauseDeletion}
      isClausepartDeletion={isClausepartDeletion}
      isClausepartAddition={isClausepartAddition}
      firstNodeAtomId={firstNodeAtomId}
      onAddClauseToReport={props.onAddClauseToReport}
      isNumberItemsHovered={props.isNumberItemsHovered}
    />
  );
}

function getReference(number, counterType, reference, level) {
  if (counterType === "BULLET") {
    return "•";
  }
  if (counterType === "DECIMAL" && level > 1) {
    return cleanReference(`${reference}.${number}`);
  }
  if (!counterType) {
    return cleanReference(reference);
  }
  return cleanReference(number);
}

function getIsInNumberedList(isInNumberedList, isListUnnumbered, clause) {
  if (isInNumberedList === undefined) {
    return !isListUnnumbered;
  } else if (
    clause.type &&
    clause.type.toLowerCase().indexOf("list") !== -1 &&
    clause.type.toLowerCase().indexOf("unnumberedlist") === -1
  ) {
    return true;
  }
  return isInNumberedList;
}

function getFirstNodeAtomId(node) {
  if (node && node.type && node.type.toLowerCase().endsWith("atom")) {
    return node.id;
  }
  let result;
  function getAtomId(node) {
    if (result) {
      return;
    }
    if (node.text && !node.is_conjunction) {
      result = node.id;
    }
    if (node.clauseNodes) {
      node.clauseNodes.forEach(child => getAtomId(child));
    }
  }
  if (node.clauseNodes) {
    node.clauseNodes.forEach(node => {
      getAtomId(node);
    });
  }
  return result;
}

function addListClauseChildrenData(listItem) {
  // used for the case we need to delete/revert list item which consists of several
  // subclauses but is not a ClausePartNode type. For ClausePartNode type look
  // node.js file getChildrenClauses function
  if (
    listItem &&
    listItem.type &&
    listItem.type.toLowerCase().indexOf("unnumberedlist") !== -1 &&
    listItem.clauseNodes &&
    listItem.clauseNodes.length > 1 &&
    listItem.clauseNodes[0].type.toLowerCase().indexOf("atom") !== -1
  ) {
    const childrenClausesOfListItem = listItem.clauseNodes
      .slice(1)
      .filter(item => item.is_conjunction !== true);
    if (childrenClausesOfListItem.length > 0) {
      listItem.clauseNodes[0].childrenClausesOfListItem = childrenClausesOfListItem;
    }
  }
}

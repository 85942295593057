import _ from "underscore";

function getClauseTopicsIds(clause, topicsById, acceptedTopicCategories) {
  const topicGroups = [];
  function getTopics(nodes) {
    if (nodes.text) {
      nodes.topics.forEach(topic => {
        if (
          !topic.is_deleted &&
          acceptedTopicCategories.includes(topic.topiccategory_id)
        ) {
          topicGroups.push(
            _.first(topicsById[topic.topic_id].name.split("/"), 2)
              .join("/")
              .trim(),
          );
        }
      });
    }
    if (nodes.clauseNodes) {
      nodes.clauseNodes.forEach(child => getTopics(child));
    }
  }
  getTopics(clause);
  return _.uniq(topicGroups);
}
export default getClauseTopicsIds;

import {get} from "lodash";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import {DocumentIssue, ParentSettings} from "common/types/document_issue";

function getEmptyParentIssues(
  parentIssue: DocumentIssue,
  settingsItemKey: keyof DocumentIssue["parent_settings"],
): DocumentIssue[] {
  const {parent_settings: parentSettings} = parentIssue;
  const subissues: DocumentIssue[] = get(
    parentIssue,
    "subissueData.subissues",
    [],
  );
  const settingsItem: ParentSettings[keyof ParentSettings] = get(
    parentSettings,
    settingsItemKey,
  );

  const isParentTriggered = isIssueAlert(parentIssue);
  const displayTextRule: ParentSettings[
    | "display_text_alert"
    | "display_text_non_alert"] = isParentTriggered
    ? get(parentSettings, "display_text_alert")
    : get(parentSettings, "display_text_non_alert");
  let issuesToUse: DocumentIssue[] = [];
  switch (settingsItem) {
    case "active_issues": {
      switch (displayTextRule) {
        case "first_alert_issue": {
          const firstAlertIssue = subissues.find(subissue =>
            isIssueAlert(subissue),
          );
          // BUG?: Can we assume there will always be an alert issue?
          issuesToUse = [firstAlertIssue];
          break;
        }
        case "all_alert_issues": {
          issuesToUse = subissues.filter(subissue => isIssueAlert(subissue));
          break;
        }
        case "first_issue": {
          issuesToUse = [subissues[0]];
          break;
        }
        case "set_manually": {
          issuesToUse = [parentIssue];
          break;
        }
        case "all_issues":
        default: {
          issuesToUse = [...subissues];
          break;
        }
      }
      break;
    }
    case "none": {
      issuesToUse = [parentIssue];
      break;
    }
    case "all_issues": {
      issuesToUse = [parentIssue, ...subissues];
      break;
    }
    default: {
      issuesToUse = subissues;
      break;
    }
  }
  return issuesToUse;
}

export default getEmptyParentIssues;

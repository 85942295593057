import _ from "underscore";
import React from "react";

import ReactSelectLabel from "common_components/react_select_label";
import Select, {getTopicLink} from "common_components/select_with_link";

import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

function initialise(state) {
  return {
    topic_id: null,
    ..._.pick(state, "topic_id"),
  };
}

function validate(issue) {
  if (!(issue.rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  return null;
}

class Component extends React.Component {
  render() {
    const {props} = this;
    const {
      editLevel,
      rules,
      topics,
      isNonOverridableFieldsDisabled,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = props;
    return (
      <div>
        <ReactSelectLabel>Topic</ReactSelectLabel>
        <Select
          className="topic"
          multi={false}
          value={rules.topic_id}
          options={
            topics
              ? topics.map(topic => ({
                  label: topic.name,
                  value: topic.id,
                }))
              : []
          }
          onChange={this.updateTopic}
          clearable={false}
          disabled={
            isNonOverridableFieldsDisabled ||
            disabled ||
            (editLevel && editLevel !== "base")
          }
          filterOption={filterByWord}
          link={getTopicLink(props.organisationId, rules.topic_id)}
          shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
        />
        {renderError(props.rulesError, "topic_id")}
      </div>
    );
  }

  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
    });
  };
  /* eslint-enable no-invalid-this */
}

export default {
  initialise,
  validate,
  component: Component,
};

import React from "react";
import * as colors from "material-ui/styles/colors";
import getTemplateFormat from "utils/get_template_format";

function formatTemplate(text, style) {
  return getTemplateFormat(text).map(
    ({text, isTick, isAsterisk, isAnchor, isDeleted, isAdded}, index) => {
      if (isAnchor) {
        return (
          <a
            key={index}
            href={isAnchor}
            target="_blank"
            style={{
              color: colors.lightBlue600,
              ...(isDeleted ? {backgroundColor: "#ffebee"} : {}),
              ...(isAdded ? {fontWeight: 600} : {}),
            }}
            title={isAnchor}
          >
            {text}
          </a>
        );
      } else {
        return (
          <span
            key={index}
            style={{
              ...(isTick ? {color: colors.lightBlue600} : {}),
              ...(isAsterisk ? {fontWeight: 600} : {}),
              ...(isDeleted
                ? {textDecoration: "line-through", color: colors.lightBlue600}
                : {}),
              ...(isAdded ? {fontWeight: 600, color: colors.lightBlue600} : {}),
              ...(style || {}),
            }}
          >
            {text}
          </span>
        );
      }
    },
  );
}

export default formatTemplate;

import Describer from "../../../utils/issues/issue_description/describer";

function render(issue, topics) {
  const describer = new Describer.IssueDescriber(issue, topics);

  return [
    `${describer.topicName} exists`,
    `with ${describer.parameterName}`,
    `not having value ${describer.listParameterValues}`,
  ].join(" ");
}

export default render;

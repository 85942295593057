import React from "react";
import _ from "lodash";

import localStorageUtil from "utils/local_storage";
import isJSON from "utils/is_json";
import LocalStorageContext from "./context";

export default class LocalStorage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  loadItem = (key, defaultValue = null) => {
    if (!_.has(this.state, key)) {
      const value = localStorageUtil.getItem(key);
      this.setState({
        [key]: (() => {
          if (isJSON(value)) {
            return JSON.parse(value);
          }
          if (/^\d+.?\d+$/.test(value)) {
            return parseFloat(value);
          }
          switch (value) {
            case "true":
              return true;
            case "false":
              return false;
            case null:
              return defaultValue;
            default:
              return value;
          }
        })(),
      });
    }
  };

  getItem = key => {
    return this.state[key];
  };

  setItem = (key, value) => {
    if (_.has(this.state, key)) {
      localStorageUtil.setItem(
        key,
        (() => {
          if (_.isObject(value) || _.isArray(value)) {
            return JSON.stringify(value);
          }
          return value;
        })(),
      );
      this.setState({[key]: value});
    }
  };

  removeItem = key => {
    if (_.has(this.state, key)) {
      localStorageUtil.removeItem(key);
      this.setState({[key]: null});
    }
  };

  render() {
    return (
      <LocalStorageContext.Provider
        value={{
          loadItem: this.loadItem,
          getItem: this.getItem,
          setItem: this.setItem,
          removeItem: this.removeItem,
        }}
      >
        {this.props.children}
      </LocalStorageContext.Provider>
    );
  }
}

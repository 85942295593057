import isIssueAlert from "./is_issue_alert";

function shouldIgnoreIssue(issue) {
  // this does not address EmptyParent issue type as we need to look
  // at subissues to decide if EmptyParent is triggered. Please refer
  // to client/src/routes/document_detail/utils/issue/construct_subissues.js
  // when dealing with EmptyParent and issue.show_if_triggered_only
  if (issue.issue_type === "EMPTY_PARENT") {
    return false;
  }
  const {show_if_triggered_only: showIfTriggeredOnly} = issue;
  if (showIfTriggeredOnly === "ifTriggered") {
    return !isIssueAlert(issue);
  }
  if (showIfTriggeredOnly === "ifNotTriggered") {
    return isIssueAlert(issue);
  }
  if (showIfTriggeredOnly === "never") {
    return true;
  }
  return false;
}

export default shouldIgnoreIssue;

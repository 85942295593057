import React from "react";
import copy from "copy-to-clipboard";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import CopyButton from "common_components/buttons/copy_button";

function getMenuItem(item, itemIndex) {
  const {Component} = item;
  switch (item.type) {
    case "divider":
      return <Divider key={`divider-${itemIndex}`} style={item.style || {}} />;
    case "link": {
      /* eslint-disable no-inner-declarations */
      function onCopyClick() {
        copy(item.href || "");
      }
      /* eslint-enable no-inner-declarations*/
      return (
        <a
          key={`link-${itemIndex}`}
          href={item.href}
          target="_blank"
          onClick={createItemClicker(item.postHandler)}
          style={{textDecoration: "none", color: "inherit"}}
        >
          <ListItem button>
            {item.leftIcon && <ListItemIcon>{item.leftIcon}</ListItemIcon>}
            <ListItemText primary={item.primaryText} />
            {item.showCopyButton ? (
              <div style={{color: "#42a5f5", marginRight: 8}}>
                <CopyButton onCopyClick={onCopyClick} hideCaption={true} />
              </div>
            ) : null}
          </ListItem>
        </a>
      );
    }
    case "component":
      return (
        <ListItem key={`link-${itemIndex}`}>
          {item.leftIcon && <ListItemIcon>{item.leftIcon}</ListItemIcon>}
          <ListItemText primary={item.primaryText} />
          {Component}
        </ListItem>
      );
    default:
      return (
        <ListItem
          key={item.key}
          onClick={createItemClicker(item.clickHandler, item.postHandler)}
          selected={item.selected}
          disabled={item?.disabled}
          button
        >
          {item.leftIcon && <ListItemIcon>{item.leftIcon}</ListItemIcon>}
          <ListItemText primary={item.primaryText} />
        </ListItem>
      );
  }
}

function createItemClicker(handler, postHandler) {
  return e => {
    e.stopPropagation();
    if (postHandler) {
      postHandler();
    }
    if (handler) {
      handler();
    }
  };
}

export default getMenuItem;

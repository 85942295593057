import _ from "underscore";
import React from "react";
import {
  DragSource as makeDragSource,
  DropTarget as makeDropTarget,
} from "react-dnd";

import Issue from "./issue";

const getIssueComponentMemoized = _.memoize(
  getIssueComponent,
  (path, issueId) => `${path}:${issueId}`,
);

export default props => {
  const IssueComponent = getIssueComponentMemoized(
    props.path,
    props.issue.ui_order_id,
  );
  return <IssueComponent {...props} />;
};

function makeType(ui_order_id) {
  return `ISSUE${ui_order_id ? `-${ui_order_id}` : ""}`;
}

function getIssueComponent(path) {
  const itemType = makeType(path);
  const headingRef = React.createRef();
  const embedHeadingRef = _.memoize(
    Component => props => <Component {...props} headingRef={headingRef} />,
    (...argv) => JSON.stringify(argv),
  );
  const IssueComponent = _.compose(
    embedHeadingRef,
    makeDragSource(
      itemType,
      {
        beginDrag: ({issue}) => ({ui_order_id: issue.ui_order_id}),
        canDrag: (props, monitor) =>
          doesOffsetInsideRect(
            monitor.getClientOffset(),
            headingRef.current.getBoundingClientRect(),
          ),
      },
      (connect, monitor) => ({
        isDragging: monitor.isDragging(),
        connectDragSource: connect.dragSource(),
      }),
    ),
    makeDropTarget(
      itemType,
      {
        hover: hoverIssue,
      },
      connect => ({
        connectDropTarget: connect.dropTarget(),
      }),
    ),
  )(Issue);
  return IssueComponent;
}

function hoverIssue(props, monitor, component) {
  const dragId = monitor.getItem().ui_order_id;
  const hoverId = props.issue.ui_order_id;
  // Don't replace items with themselves
  if (dragId === hoverId) {
    return null;
  }

  const dragIndex = props.getIndex(dragId);
  const hoverIndex = props.getIndex(hoverId);
  if (!component || !component.ref || !component.ref.current) {
    return null;
  }
  const clientOffset = monitor.getClientOffset();
  const hoverBoundingRect = component.ref.current.getBoundingClientRect();
  if (shouldSwapNodes(dragIndex, hoverIndex, clientOffset, hoverBoundingRect)) {
    props.swapIssues(props.path, dragId, hoverId);
  }
}

function shouldSwapNodes(
  dragIndex,
  hoverIndex,
  clientOffset,
  hoverBoundingRect,
) {
  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  // Get pixels to the top
  const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  // Only perform the move when the mouse has crossed half of the items height
  // When dragging downwards, only move when the cursor is below 50%
  // When dragging upwards, only move when the cursor is above 50%
  // Dragging downwards
  if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    return false;
  }
  // Dragging upwards
  if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    return false;
  }
  return true;
}

function doesOffsetInsideRect(offset, rect) {
  return offset.y >= rect.top && offset.y <= rect.bottom;
}

import _ from "underscore";

import byId from "common/utils/by_id";
import calculateTopicIdsFromMasks from "./calculate_topic_ids_from_masks";

export default _.memoize((rawRopicMasks, topicsById) => {
  const topicMasks = rawRopicMasks.map(mask => ({
    ...mask,
    parameters: [].concat(
      ...calculateTopicIdsFromMasks(mask.topic_ids).map(
        topicId => (topicsById[topicId] ? topicsById[topicId].parameters : []),
      ),
    ),
  }));
  const topicMasksById = byId(topicMasks);
  return {topicMasks, topicMasksById};
});

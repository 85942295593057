import _ from "lodash";

function generateTableHeaderItems(baseFields, reportSettings = {}) {
  const {are_report_items_numbered: areAllItemsNumbered} = reportSettings;
  const {shownFields, baseFieldsByType} = baseFields.reduce(
    (accum, field) => {
      const {type, value} = field;
      // const finalLabel = type === label ? fieldLabels[type].label : label;

      if (type === "clause_display") {
        accum.shownFields.push(value);
      } else {
        accum.shownFields.push(type);
      }
      accum.baseFieldsByType = {
        ...accum.baseFieldsByType,
        [type]: field,
      };
      return accum;
    },
    {shownFields: [], baseFieldsByType: {}},
  );

  // calculating widths
  const shortFieldNames = ["status"];
  const mediumFieldNames = ["issue_name", "clause_references", "reason"];
  const longFieldNames = ["clause_text", "clause_references_and_text"];
  const numberOfShortItems = _.intersection(shortFieldNames, shownFields)
    .length;
  const numberOfMediumItems = _.intersection(mediumFieldNames, shownFields)
    .length;
  const numberOfLongItems = _.intersection(longFieldNames, shownFields).length;
  const numberOfOtherItems =
    shownFields.length -
    numberOfLongItems -
    numberOfMediumItems -
    numberOfShortItems;

  const shortItemFactor = 0.25;
  const mediumItemFactor = 0.5;
  const longItemFactor = 2;

  const baseWidthUnits =
    numberOfShortItems * shortItemFactor +
    numberOfMediumItems * mediumItemFactor +
    numberOfLongItems * longItemFactor +
    numberOfOtherItems +
    (areAllItemsNumbered ? shortItemFactor : 0);

  const baseWidth = 100 / baseWidthUnits;

  const resultFields = shownFields.map(typeOrValue => {
    const width = shortFieldNames.includes(typeOrValue)
      ? `${baseWidth * shortItemFactor}%`
      : mediumFieldNames.includes(typeOrValue)
      ? `${baseWidth * mediumItemFactor}%`
      : longFieldNames.includes(typeOrValue)
      ? `${baseWidth * longItemFactor}%`
      : `${baseWidth}%`;
    const maxWidth = shortFieldNames.includes(typeOrValue)
      ? "2em"
      : mediumFieldNames.includes(typeOrValue)
      ? "10em"
      : longFieldNames.includes(typeOrValue)
      ? "30em"
      : "20em";
    const baseField =
      typeOrValue === "clause_references" ||
      typeOrValue === "clause_text" ||
      typeOrValue === "clause_references_and_text"
        ? baseFieldsByType["clause_display"]
        : baseFieldsByType[typeOrValue];
    return {
      title: baseField.label.toUpperCase(),
      type: typeOrValue,
      width,
      maxWidth,
    };
  });
  if (areAllItemsNumbered) {
    resultFields.unshift({title: "", width: `${baseWidth * shortItemFactor}%`});
  }
  return resultFields;
}

export default generateTableHeaderItems;

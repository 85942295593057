import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initUser(requestor) {
  return createReduxPromiseAction(ActionTypes.USER_LOGOUT, () =>
    requestor
      .post(`${basePath}/auth/logout`, {}, {withCredentials: true})
      .then(response => response.data),
  );
}

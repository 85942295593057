import _ from "underscore";
import React from "react";

import ReactSelectLabel from "common_components/react_select_label";
import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";

import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

function initialise(state) {
  return {
    topic_id: null,
    parameter_id: null,
    parameter_values: [],
    ..._.pick(state, "topic_id", "parameter_id", "parameter_values"),
  };
}

function validate(issue) {
  const {rules} = issue;
  if (!(rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  if (!(rules.parameter_id > 0)) {
    return {rulesError: {parameter_id: "You must set a parameter id"}};
  }
  return null;
}

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      topics,
      isNonOverridableFieldsDisabled,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    return (
      <div>
        <div style={{margin: "0 1em"}}>
          <ReactSelectLabel>Topic</ReactSelectLabel>
          <Select
            className="topic"
            multi={false}
            value={rules.topic_id}
            options={topics
              .filter(topic => topic.parameters.length)
              .map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
            onChange={this.updateTopic}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getTopicLink(this.props.organisationId, rules.topic_id)}
            shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
          />
          {renderError(this.props.rulesError, "topic_id")}
        </div>
        <div style={{margin: "2em 1em"}}>
          <ReactSelectLabel>Parameter</ReactSelectLabel>
          <Select
            className="parameter"
            multi={false}
            value={rules.parameter_id}
            options={
              currentTopic &&
              currentTopic.parameters.map(parameter => ({
                label: parameter.name,
                value: parameter.id,
              }))
            }
            onChange={this.updateParameter}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getParameterLink(
              this.props.organisationId,
              rules.topic_id,
              rules.parameter_id,
            )}
          />
          {renderError(this.props.rulesError, "parameter_id")}
        </div>
      </div>
    );
  }

  getParameters(topicId) {
    if (!topicId) {
      return [];
    }
    return (
      (this.props.topicsById[topicId] &&
        this.props.topicsById[topicId].parameters) ||
      []
    );
  }
  getParameter(topicId, parameterId) {
    return this.getParameters(topicId, parameterId).find(
      param => param.id === parameterId,
    );
  }

  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
    });
  };
  updateParameter = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_id: value.value,
    });
  };
  /* eslint-enable no-invalid-this */
}

export default {
  initialise,
  validate,
  component: Component,
};

import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import {ContextsFetchAction} from "../actions/action_definitions_fetch";
import actionTypes from "../constants/action_types";
import {WorkflowContext} from "common/flowmaster/types/workflow";

export default function contextsReducer(
  state: MaybeUninitialised<WorkflowContext[]> = UNINITIALISED,
  action: ContextsFetchAction,
): typeof state {
  if (action.type === actionTypes.CONTEXTS_FETCH.SUCCESS) {
    return (action as ContextsFetchAction).payload;
  }
  return state;
}

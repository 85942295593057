/* eslint-disable no-invalid-this */

function replaceInList(list, ...args) {
  if (!list || !Array.isArray(list)) {
    return [];
  }

  let newList = list;
  for (let idx = 0; idx < args.length - 1; idx += 2) {
    const regexStr = args[idx];
    const value = args[idx + 1];
    const regex = new RegExp(regexStr);
    newList = newList.map(
      item =>
        item && item.toString && item.toString().match(regex) ? value : item,
    );
  }
  return newList;
}

export default replaceInList;

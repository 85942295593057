import _ from "underscore";
import groupClausesByHeadings from "utils/clauses/group_clauses_by_headings";
import groupClausesByDefinitions from "utils/clauses/group_clauses_by_definitions";
import addIndentationToClauses from "utils/clauses/add_indentation_to_clauses";
import escapeRegex from "utils/escape_regex";
import groupClausesBySections from "./group_clauses_by_sections";

import issueHeaderUtils from "utils/issues/issue_header_utils";
import findIssue from "utils/issues/find_issue_in_grouped_issues";

function removeHeading(text, heading) {
  if (text === "Blank") {
    return text;
  }
  return (
    text && text.replace(new RegExp(`${escapeRegex(heading || "")}s*/s*`), "")
  );
}

function getHeadingText(text) {
  if (text && text.indexOf("/") > 0) {
    return text.substring(0, text.indexOf("/"));
  }
  return null;
}

function extractValue(strOrArray) {
  const value = Array.isArray(strOrArray) ? strOrArray.join("\n") : strOrArray;
  return value?.replace(/\n{3,}/g, "\n\n") ?? "Blank";
}

function getClauseDisplayFieldName(issue, settings) {
  return (
    issue.manualCorrections["applicable_clauses_display_mode"] ||
    settings.value ||
    "clause_references"
  );
}

function getApplicableClausesChildren(
  applicableClauses,
  clauseDisplay,
  removedClauses,
  headings,
  sections,
  groupedSections,
) {
  const filteredClauses = applicableClauses.filter(
    applicableClause => !removedClauses[applicableClause.id],
  );
  if (clauseDisplay === "clause_references") {
    const clausesBySections = groupClausesBySections(
      filteredClauses,
      groupedSections,
    );
    const result = [];
    Object.keys(clausesBySections).forEach(sectionIdStr => {
      const sectionId = parseInt(sectionIdStr, 10);
      const section = sections.find(section => section.id === sectionId);
      const children = _.uniq(
        clausesBySections[sectionIdStr],
        clause => clause.reference,
      ).map(clause =>
        constructCellChild("text_run", clause.reference || "", {
          applicableClause: clause,
        }),
      );
      result.push(
        constructCellChild(
          "paragraph",
          section.reference === "main" ? "" : section.reference,
          {
            children,
          },
        ),
      );
    });
    return result;
  }

  const clausesByHeadings = groupClausesByHeadings(
    filteredClauses,
    headings,
    sections,
  );

  const clausesGroupedByHeadingsAndDefinitions = groupClausesByDefinitions(
    clausesByHeadings,
    [],
    sections,
  );
  const indentedClausesByHeadings = addIndentationToClauses(
    clausesGroupedByHeadingsAndDefinitions,
  );

  return _.chain(indentedClausesByHeadings)
    .map((clauseGroup, applicableClauseIndex) => {
      const items = [];
      if (clauseGroup.hasHeading) {
        items.push(
          constructCellChild("paragraph", clauseGroup.headingText, {
            bold: true,
          }),
        );
      }
      const addApplicableClause = applicableClause => {
        const additionalItems = {applicableClause};
        if (
          applicableClauses.length > 1 &&
          applicableClauseIndex < applicableClauses.length - 1
        ) {
          additionalItems.spacing = {after: 200};
        }
        items.push(
          constructCellChild(
            "paragraph",
            getClauseText(applicableClause, clauseDisplay),
            {
              ...additionalItems,
              ...(applicableClause.indent
                ? {indent: applicableClause.indent}
                : {}),
            },
          ),
        );
      };
      clauseGroup.items &&
        clauseGroup.items.forEach(definitionGroup => {
          definitionGroup.items &&
            definitionGroup.items.forEach(addApplicableClause);
          definitionGroup.item && addApplicableClause(definitionGroup.item);
        });

      return _.uniq(items, item => item.value);
    })
    .flatten()
    .value();
}

function getClauseText(clause, clauseDisplay) {
  const clauseText = clause.text || "";
  const clauseReference = clause.reference || "";
  switch (clauseDisplay) {
    case "clause_references_and_text":
    case "clause_text":
      return `${cleanReference(clauseReference)} ${clauseText}`;
    case "clause_references":
      return cleanReference(clauseReference);
    default:
      return "";
  }
}

function cleanReference(reference) {
  return reference.replace(/meaning/, "");
}

function constructStatusCellContent(issue, props) {
  return [
    constructCellChild("circle", issue.isTriggered, {
      color: getStatusColor(issue, props),
    }),
  ];
}

function constructReviewState(issue) {
  return [constructCellChild("review_state_circle", issue.review_state)];
}

function constractRagScore(issue) {
  const scoreValue = issue.manualCorrections?.rag_score
    ? issue.manualCorrections.rag_score
    : 0;
  return [constructCellChild("rag_score", scoreValue)];
}

const getStatusColor = (issue, props) => {
  if (issue.additional_item) {
    return issue?.status;
  } else {
    const status = getOverrideStatusColor(issue);
    const groupedIssue = findIssue(issue.id, null, props.groupedIssues, true);
    const color = issueHeaderUtils.getIconColor(
      groupedIssue,
      props.currentIssuesetItem,
      {resolve_issues: false},
      false,
      false,
    );
    const colorName = issueHeaderUtils.colorNames[color];
    return status?.color || colorName;
  }
};

function getOverrideStatusColor(issue) {
  const {
    alertColor: primaryAlertColor,
    action_state: {alert_color: secondaryAlertColor},
  } = issue;
  if (primaryAlertColor) {
    return {color: primaryAlertColor};
  } else if (issue.isResolved) {
    return {color: "blue"};
  } else if (issue.isIgnored) {
    return {color: "grey"};
  } else if (secondaryAlertColor) {
    return {color: secondaryAlertColor};
  }
}

function constructCustomFieldCellContent(issue, fieldSettings) {
  const value = (issue.manualCorrections || {})[fieldSettings.type];
  return [constructCellChild("paragraph", value || "Blank")];
}

function constructApplicableClausesCellContent(issue, settings, props) {
  const {applicableClauses, groupedApplicableClauses, removedClauses} = issue;
  const clauseDisplay = getClauseDisplayFieldName(issue, settings);
  const clauses =
    clauseDisplay === "clause_references"
      ? groupedApplicableClauses
      : applicableClauses;
  return applicableClauses && applicableClauses.length > 0
    ? getApplicableClausesChildren(
        clauses,
        clauseDisplay,
        removedClauses || {},
        props.documentHeadings,
        props.documentSections,
        props.groupedSections,
      )
    : [constructCellChild("paragraph", "Blank")];
}

const descriptors = {
  issue_name: {
    type: "issue_name",
    getValue: issue =>
      removeHeading(
        extractValue(issue.issue_name),
        getHeadingText(issue.issue_name),
      ),
  },
  description: {type: "description"},
  clause_display: {
    type: "clause_display",
    getFieldName: (issue, settings) =>
      getClauseDisplayFieldName(issue, settings),
    constructCellContent: (issue, settings, props) =>
      constructApplicableClausesCellContent(issue, settings, props),
    addCorrectedValue: true,
    cellOptions: {
      isEditable: false,
      isEditableInGui: true,
    },
  },
  reason: {
    type: "reason",
    getFieldName: issue => (issue.hasReasons ? "reason" : "positive_reason"),
  },
  detailed_reason: {
    type: "detailed_reason",
    getFieldName: issue =>
      issue.hasReasons ? "detailed_reason" : "detailed_positive_reason",
  },
  guidance: {
    type: "guidance",
    getFieldName: issue =>
      issue.hasReasons ? "guidance" : "positive_guidance",
  },
  required_change: {type: "required_change"},
  fallback: {type: "fallback"},
  comment: {type: "comment"},
  status: {
    type: "status",
    constructCellContent: (issue, field, props) =>
      constructStatusCellContent(issue, props),
    getFieldName: () => "new_state",
    cellOptions: {
      isEditable: false,
    },
  },
  review_state: {
    type: "review_state",
    constructCellContent: issue => constructReviewState(issue),
    cellOptions: {
      isEditable: false,
    },
  },
  rag_score: {
    type: "rag_score",
    constructCellContent: issue => constractRagScore(issue),
    cellOptions: {
      isEditable: false,
    },
  },
  custom_field: {
    type: "custom_field",
    constructCellContent: (issue, settings, props) =>
      constructCustomFieldCellContent(issue, settings, props),
    getFieldName: (issue, fieldSettings) => fieldSettings.type,
    addCorrectedValue: true,
  },
  standard_language: {type: "standard_language"},
  free_text: {
    type: "free_text",
    constructCellContent: (issue, settings) => [
      constructCellChild("paragraph", settings.value),
    ],
    shouldNotCorrectManually: true,
    useValueAsCorrectedValue: true,
    cellOptions: {
      isEditable: false,
      isEditableInGui: true,
    },
  },
};

function constructRow(children, additionalItems = {}) {
  return {
    type: "tr",
    children,
    ...additionalItems,
  };
}

function constructCell(children, options, type) {
  return {
    type: type ? type : "td",
    children,
    ...options,
  };
}

function constructCellChild(type, value, additionalItems = {}) {
  return {
    type,
    value,
    ...additionalItems,
  };
}

const defaultCellOptions = {
  isEditable: true,
};

function getCellData(field, issue, props) {
  const isCustomField = field.type.indexOf("custom_field") === 0;
  const fieldDescriptor = isCustomField
    ? descriptors["custom_field"]
    : descriptors[field.type];

  const fieldName = fieldDescriptor.getFieldName
    ? fieldDescriptor.getFieldName(issue, field, props)
    : fieldDescriptor.type;
  let cellContent;
  if (fieldDescriptor.constructCellContent) {
    cellContent = fieldDescriptor.constructCellContent(issue, field, props);
  } else {
    // !!NOTE here we use field.type instead of fieldName because positive values
    // are still set to non-positive keys (e.g. when we have issue without reasons
    // and positive guidance is used issue has this value in guidance key), see
    // getIssuesRagData in report/rag_report/index.js
    const fieldValue = fieldDescriptor.getValue
      ? fieldDescriptor.getValue(issue)
      : extractValue(issue[fieldDescriptor.type]);
    const markupValue = (issue?.manualCorrections?.locked_value ?? {})[
      fieldName
    ];
    const cellType = fieldDescriptor.cellType || "paragraph";
    cellContent = [constructCellChild(cellType, markupValue ?? fieldValue)];
  }

  const correctedValue = fieldDescriptor.shouldNotCorrectManually
    ? undefined
    : (issue.manualCorrections || {})[fieldName];
  const fieldOptions = {
    ...defaultCellOptions,
    ...(fieldDescriptor.cellOptions || {}),
    fieldName,
    fieldType: field.type,
    ...(props.highlightManualCorrections
      ? {isManuallyCorrected: Boolean(correctedValue)}
      : {}),
    ...(field.id ? {field_id: field.id} : {}),
  };

  if (fieldDescriptor.addCorrectedValue && correctedValue) {
    fieldOptions.correctedValue = correctedValue;
  }
  if (fieldDescriptor.useValueAsCorrectedValue) {
    fieldOptions.correctedValue = field.value;
  }
  return {
    cellContent,
    fieldOptions,
  };
}

export default {
  getHeadingText,
  getCellData,
  constructRow,
  constructCell,
  constructCellChild,
  descriptors,
  getStatusColor,
  removeHeading,
  extractValue,
};

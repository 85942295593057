import _ from "underscore";
import React from "react";

import {Tooltip, Button} from "@material-ui/core";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import IconButton from "material-ui/IconButton";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import KeyboardArrowDownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import KeyboardArrowRightIcon from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import WrapText from "material-ui/svg-icons/editor/wrap-text";
import ArrowUpward from "material-ui/svg-icons/navigation/arrow-upward";
import ArrowDownward from "material-ui/svg-icons/navigation/arrow-downward";
import Launch from "material-ui/svg-icons/action/launch";

import Issues from "plugins/issues";
import getWantedPosition from "common_components/issue_editor/utils/get_wanted_position";
import {updateTopicLocation, getAboveIssueType, isLogicIssue} from "./utils";
import getIssueTypeName from "../get_issue_type_name";

const getIssueComponent = _.memoize(
  type => Issues[type.toLowerCase()].editor.component,
);

export default class Issue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ref = React.createRef();
    const {connectDragSource, connectDropTarget} = props;
    connectDragSource && connectDragSource(this.ref);
    connectDropTarget && connectDropTarget(this.ref);
  }

  static getDerivedStateFromProps(props) {
    const {issue} = props;
    return {
      IssueComponent: getIssueComponent(issue.issue_type),
    };
  }

  shouldComponentUpdate(prevProps, prevState) {
    return (
      !_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)
    );
  }

  render() {
    return (
      <div
        key={this.props.issue.ui_order_id}
        ref={this.ref}
        className="issue"
        style={{
          opacity: this.props.isDragging ? 0 : 1,
          border: "1px solid #ddd",
          margin: "1em 0",
        }}
      >
        {this.getIssueToggled()
          ? this.renderIssueFully()
          : this.renderIssueHeader()}
      </div>
    );
  }

  renderIssueFully() {
    const {issue, parentIssueType, topics, disabled} = this.props;
    const issueDescription = getIssueDescription(issue, topics);
    const hasDescription = issueDescription !== null;
    const shouldShowCollapseAll = isLogicIssue(issue);
    const wantedPosition = shouldShowCollapseAll && this.getWantedPosition();

    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            backgroundColor: "#f5f5f5",
          }}
          ref={this.props.headingRef}
          onClick={this.toggleIssue}
        >
          <div
            style={{
              display: "flex",
              alignItems: "baseline",
              overflow: "hidden",
            }}
          >
            {hasDescription && this.renderTogleArrow()}
            <SelectField
              value={issue.issue_type}
              onChange={this.props.updateType}
              onClick={this.stopPropagation}
              className="issue-type"
              style={{margin: "0 1em", width: "unset"}}
              floatingLabelText="Issue type"
              disabled={disabled || this.props.isOverrideMode}
              dropDownMenuProps={{
                menuStyle: {width: "auto", overflowX: "hidden"},
              }}
            >
              {_.sortBy(
                this.props.availalbleIssueKeys,
                type => Issues[type.toLowerCase()].order,
              ).map(type => (
                <MenuItem
                  value={type}
                  primaryText={getIssueTypeName(Issues, type)}
                  key={type}
                />
              ))}
            </SelectField>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "1rem",
              flexWrap: "wrap",
            }}
          >
            {![
              "AND_ON_DOCUMENT",
              "EMPTY_PARENT",
              "AND_ON_CLAUSE",
              "AND_ON_WHOLE_CLAUSE",
            ].includes(issue.issue_type) &&
              ["AND_ON_CLAUSE", "AND_ON_WHOLE_CLAUSE"].includes(
                parentIssueType,
              ) &&
              this.renderTopicLocationSelector(issue.rules)}
            {shouldShowCollapseAll && (
              <Button
                size="small"
                onClick={this.toggleAllIssues(wantedPosition)}
              >
                {wantedPosition ? "Expand All" : "Collapse All"}
              </Button>
            )}
            {this.renderOrderButtons()}
          </div>
        </div>
        <div style={{padding: "1em"}}>{this.renderIssues()}</div>
      </>
    );
  }

  renderOrderButtons() {
    const {
      issue,
      injectDownside,
      injectUpside,
      ejectDownside,
      addIssueAbove,
      isPreviousIssueLogical,
      isNextIssueLogical,
      parentIssueType,
    } = this.props;
    const disabled =
      this.props.isNonOverridableFieldsDisabled || this.props.disabled;
    const shouldDisableAddAbove =
      !getAboveIssueType(issue.issue_type, parentIssueType) ||
      disabled ||
      this.props.editLevel !== "base";
    return (
      <>
        {isPreviousIssueLogical && (
          <TooltipOrDisable
            Component={ArrowUpward}
            disabled={disabled}
            className="inject-upside"
            title="Move into block above"
            onClick={injectUpside}
          />
        )}
        {isNextIssueLogical && (
          <TooltipOrDisable
            Component={ArrowDownward}
            disabled={disabled}
            className="inject-downside"
            title="Move into block below"
            onClick={injectDownside}
          />
        )}
        {ejectDownside && (
          <TooltipOrDisable
            Component={Launch}
            disabled={disabled}
            className="eject-up-level"
            title="Move out of block"
            onClick={ejectDownside}
          />
        )}
        <TooltipOrDisable
          Component={WrapText}
          disabled={shouldDisableAddAbove}
          className="add-issue-above"
          title="Add OR/AND on top of this rule"
          onClick={addIssueAbove}
        />
        {parentIssueType && (
          <IconButton
            className="delete"
            onClick={this.props.deleteIssue}
            disabled={disabled}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </>
    );
  }

  renderIssues() {
    const {issue, filteredTopics, allTopics, error} = this.props;
    const {IssueComponent} = this.state;
    const newProps = _.omit(
      this.props,
      "issue",
      "topics",
      "path",
      "updateRules",
      "updateType",
      "deleteIssue",
      "addIssueAbove",
    );
    const {topics, shouldTopicSelectBeHighlighted} = getTopicsIncludingIssue(
      filteredTopics,
      allTopics,
      issue,
    );
    return (
      <IssueComponent
        {...newProps}
        topics={topics}
        shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
        rules={issue.rules}
        rulesError={error && error.rulesError}
        parentIssueType={issue.issue_type}
        path={issue.ui_order_id}
        onChange={this.props.updateRules}
        roles={this.props.roles}
      />
    );
  }

  renderIssueHeader() {
    const {issue, topics} = this.props;
    const issueDescription = getIssueDescription(issue, topics);
    const hasDescription = issueDescription !== null;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 1em .5em 0",
          backgroundColor: "#f5f5f5",
        }}
        ref={this.props.headingRef}
        onClick={this.toggleIssue}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#777",
          }}
        >
          <div style={{marginRight: "1em"}}>
            {hasDescription && this.renderTogleArrow()}
          </div>
          <div style={{paddingTop: ".5em", marginRight: "1em"}}>
            {Issues[issue.issue_type.toLowerCase()].displayName}
          </div>
        </div>
        <div style={{paddingTop: ".5em", fontSize: ".9em"}}>
          <IssueDescription issue={issue} topics={topics} />
        </div>
      </div>
    );
  }

  getIssueToggled() {
    const {issue, issuePositions} = this.props;
    const issueToggled = issuePositions && issuePositions[issue.ui_order_id];
    return issueToggled || "";
  }

  renderTogleArrow() {
    const issueToggled = this.getIssueToggled();
    return (
      <IconButton
        style={{
          width: "24px",
          height: "24px",
          marginRight: "8px",
        }}
      >
        {issueToggled ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
      </IconButton>
    );
  }

  renderTopicLocationSelector = rules => {
    return (
      <SelectField
        floatingLabelText="Topic Location"
        value={rules.topic_location || "in_clause_only"}
        onChange={updateTopicLocation({
          rules,
          onChange: this.props.updateRules,
        })}
        onClick={this.stopPropagation}
        style={{marginLeft: "1em", width: "unset"}}
        disabled={
          this.props.isNonOverridableFieldsDisabled || this.props.disabled
        }
      >
        <MenuItem value="in_clause_only" primaryText="In Clause Only" />
        <MenuItem
          value="in_reference_only"
          primaryText="In Immediate Reference Only"
        />
        <MenuItem
          value="in_both_clause_and_reference"
          primaryText="In Both Clause And Immediate Reference"
        />
        <MenuItem value="in_any_reference" primaryText="In Any Reference" />
        <MenuItem
          value="in_both_clause_and_any_reference"
          primaryText="In Both Clause And Any Reference"
        />
      </SelectField>
    );
  };

  toggleIssue = () => {
    const {toggleIssue, issue} = this.props;
    if (typeof toggleIssue === "function") {
      toggleIssue(issue.ui_order_id);
    }
  };

  toggleAllIssues = _.memoize(wantedPosition => e => {
    this.stopPropagation(e);
    const ui_order_ids = this.props.issue.rules.issues.map(
      ({ui_order_id}) => ui_order_id,
    );
    this.props.toggleAllIssues(ui_order_ids, wantedPosition);
  });

  getWantedPosition = () => {
    const {issue, issuePositions} = this.props;
    const ui_order_ids = issue.rules.issues.map(({ui_order_id}) => ui_order_id);
    const wantedPosition = getWantedPosition(issuePositions, ui_order_ids);
    return wantedPosition;
  };

  stopPropagation = e => {
    if (e) {
      e.stopPropagation();
    }
  };
}

function getIssueDescription(issue, topics) {
  try {
    return Issues[issue.issue_type.toLowerCase()].issueDescription(
      issue,
      topics,
    );
  } catch {
    // description generation trows an error
  }
  return null;
}

function IssueDescription({issue, topics, showIssueType}) {
  if (isLogicIssue(issue)) {
    return (
      <>
        {showIssueType && (
          <span>{Issues[issue.issue_type.toLowerCase()].displayName}</span>
        )}
        <ul>
          {issue.rules.issues.map(_issue => (
            <li key={`description-${_issue.ui_order_id}`}>
              <IssueDescription
                issue={_issue}
                topics={topics}
                showIssueType={true}
              />
            </li>
          ))}
        </ul>
      </>
    );
  }
  return <>{getIssueDescription(issue, topics)}</>;
}

function TooltipOrDisable({Component, disabled, className, title, onClick}) {
  return disabled ? (
    <IconButton className={className} disabled={true}>
      <Component />
    </IconButton>
  ) : (
    <Tooltip title={title}>
      <IconButton className={className} onClick={onClick}>
        <Component />
      </IconButton>
    </Tooltip>
  );
}

function getTopicsIncludingIssue(topics, allTopics, issue) {
  const topicId = issue && issue.rules && issue.rules.topic_id;
  if (
    topicId &&
    topics &&
    topics.length > 0 &&
    allTopics &&
    allTopics.length > 0
  ) {
    const foundInTopics = topics.find(({id}) => id === topicId);
    const foundInAllTopics = allTopics.find(({id}) => id === topicId);
    if (!foundInTopics && foundInAllTopics) {
      return {
        topics: [foundInAllTopics, ...topics],
        shouldTopicSelectBeHighlighted: true,
      };
    }
  }
  return {topics, shouldTopicSelectBeHighlighted: false};
}

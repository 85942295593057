import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";

export default function permissionsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.PERMISSIONS_FETCH.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

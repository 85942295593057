function moveReferencesToTheTextLine(text) {
  if (!text) {
    return text;
  }
  const lines = text
    .split("\n")
    .map(line => line.trim())
    .filter(line => line);
  if (lines.length === 1) {
    return text;
  }
  const resultLines = [];
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i].trim();
    if (
      lines[i + 1] &&
      ((/[\.\d]/.test(line) && line.length < 8) || line.length < 4)
    ) {
      resultLines.push(
        `${line}${line.endsWith(".") ? "" : "."} ${lines[i + 1].trim()}`,
      );
      i++;
    } else {
      resultLines.push(line);
    }
  }
  return resultLines.join("\n");
}

export default moveReferencesToTheTextLine;

import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};
createReduxPromiseActionType(actionTypes, "ORGANISATION_FETCH");
createReduxPromiseActionType(actionTypes, "ORGANISATION_CLEAR");
createReduxPromiseActionType(actionTypes, "ORGANISATION_UPDATE");
createReduxPromiseActionType(actionTypes, "ORGANISATION_CHANGE");
createReduxPromiseActionType(actionTypes, "ORGANISATION_USER_UPDATE");
createReduxPromiseActionType(actionTypes, "ORGANISATION_USER_DELETE");
createReduxPromiseActionType(actionTypes, "ORGANISATION_USER_ADD");
createReduxPromiseActionType(actionTypes, "ORGANISATION_USER_UNLOCK");
createReduxPromiseActionType(actionTypes, "CLIENTS_FETCH");
createReduxPromiseActionType(actionTypes, "CLIENTS_CLEAR");
createReduxPromiseActionType(actionTypes, "CLIENT_ADD");
createReduxPromiseActionType(actionTypes, "CLIENT_UPDATE");
createReduxPromiseActionType(actionTypes, "CLIENT_FETCH_ISSUESET_SYNC_INFO");
createReduxPromiseActionType(actionTypes, "CLIENT_SYNC_ISSUESET");
createReduxPromiseActionType(actionTypes, "ISSUESET_UPDATE");
createReduxPromiseActionType(actionTypes, "SIGNUP_ADD");

export default actionTypes;

import React from "react";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import * as colors from "@material-ui/core/colors";
import {Tooltip} from "@material-ui/core";

import getZoomedFontSize from "utils/get_zoomed_font_size";

const styles = {
  clausesBody: {
    color: "#333333",
    padding: 20,
    background: "#f4f4f4",
  },
  iconStyle: {
    color: "#a6a6a6",
    marginRight: 6,
  },
  added: {
    margin: "0.25em 0",
    color: colors.blue[400],
  },
  removed: {
    margin: "0.25em 0",
    color: colors.blue[400],
    textDecoration: "line-through",
  },
  similar: {
    margin: "0.25em 0",
  },
  warning: {
    fontSize: "0.85rem",
    fontStyle: "italic",
    paddingBottom: "1rem",
  },
};

function ItemsSection(props) {
  const {
    items,
    label,
    zoom,
    scrollToClause,
    appType,
    isComparisonBetweenRevisions,
  } = props;
  const [isCollapsed, updateIsCollapsed] = React.useState(false);
  function triggerIsCollapsed() {
    updateIsCollapsed(!isCollapsed);
  }

  function renderBody() {
    if (isCollapsed) {
      return null;
    } else if (!items || items.length === 0) {
      return (
        <div style={styles.clausesBody}>
          <div
            style={{
              textAlign: "center",
              fontStyle: "italic",
              fontSize: clauseTextFontSize,
            }}
          >
            No Applicable Clauses Found
          </div>
        </div>
      );
    }

    return (
      <div style={styles.clausesBody}>
        {!isComparisonBetweenRevisions && (
          <div style={styles.warning}>
            Warning: Comparing text with a document that may not be a revision
          </div>
        )}
        {items.map((item, itemIndex) => {
          const clickHandler =
            (item.clausepart_id || item.partial_text) && scrollToClause
              ? () =>
                  scrollToClause(
                    // taskpane and web use different scrollToClause handlers
                    // with different API
                    appType === "wordTaskpane" && item.partial_text
                      ? {text: item.partial_text}
                      : {id: item.clausepart_id},
                  )
              : undefined;

          const key = `${item.id}-${itemIndex}`;
          switch (item.type) {
            case "extra_in_current":
              return (
                <Tooltip title="Added in current version" key={key} arrow>
                  <div onClick={clickHandler} style={styles.added}>
                    {item.text}
                  </div>
                </Tooltip>
              );
            case "extra_in_previous":
              return (
                <Tooltip title="Deleted in current version" key={key} arrow>
                  <div onClick={clickHandler} style={styles.removed}>
                    {item.text}
                  </div>
                </Tooltip>
              );
            case "similar":
              return (
                <div key={key} onClick={clickHandler} style={styles.similar}>
                  {item.diffs.map((diffItem, diffItemIndex) =>
                    renderDiffItem(diffItem, diffItemIndex),
                  )}
                </div>
              );
            case "matched":
              return (
                <Tooltip title="Same in both documents" key={key} arrow>
                  <div onClick={clickHandler} style={styles.similar}>
                    {item.text}
                  </div>
                </Tooltip>
              );
            default:
              return null;
          }
        })}
        {// If there are no applicable clauses from both documents ("similar")...
        items.every(item => item.type !== "similar") && (
          <div style={{paddingTop: "1rem"}}>
            {[
              {
                type: "extra_in_current",
                noItemsOfTypeMessage:
                  "No applicable clauses for issue in this document",
              },
              {
                type: "extra_in_previous",
                noItemsOfTypeMessage:
                  "No applicable clauses for issue in comparison document",
              },
            ]
              // ...and there are no applicable clauses for either the
              // comparison or original document...
              .filter(item => items.every(({type}) => type !== item.type))
              // ...then inform the user with a warning message.
              .map(({noItemsOfTypeMessage}) => (
                <div style={styles.warning} key={noItemsOfTypeMessage}>
                  {noItemsOfTypeMessage}
                </div>
              ))}
          </div>
        )}
      </div>
    );
  }

  const Icon = isCollapsed ? AddIcon : RemoveIcon;
  const clauseTextFontSize = getZoomedFontSize(12, "checklist", zoom);
  return (
    <div style={{paddingTop: 10}}>
      <div
        style={{
          ...styles.clausesLabel,
          fontSize: getZoomedFontSize(14, "checklist", zoom),
        }}
        onClick={triggerIsCollapsed}
      >
        <Icon
          style={{
            ...styles.iconStyle,
            height: clauseTextFontSize,
            width: clauseTextFontSize,
          }}
        />
        {label}
      </div>
      {renderBody()}
    </div>
  );
}

function renderDiffItem(part, index) {
  if (part.added) {
    return (
      <span key={`${part.value}-${index}`} style={styles.added}>
        {part.value}
      </span>
    );
  } else if (part.removed) {
    return (
      <span key={`${part.value}-${index}`} style={styles.removed}>
        {part.value}
      </span>
    );
  }
  return <span key={`${part.value}-${index}`}>{part.value}</span>;
}

export default ItemsSection;

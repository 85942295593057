import _ from "underscore";
import React from "react";

import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DescriptionIcon from "@material-ui/icons/Description";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import moment from "moment";

import {replaceUrlParam} from "routes/navigation";

import styles from "./styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditableField from "common_components/editable_field";

const componentStyles = {
  documentNameWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    flexDirection: "column",
  },
  documentName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  documentNameShifted: {
    maxWidth: "calc(10em - 20px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  documentDate: {
    fontSize: "0.6em",
    color: "#666",
    fontFamily: "monospace",
  },
  documentVersion: {
    position: "absolute",
    bottom: "1.2em",
    fontSize: ".5em",
  },
};

export default function ToolbarDocumentSelector({
  fileIndex,
  setFileIndex,
  document: shownDocument,
  documents,
  router,
  renderingStatus,
  onFieldUpdate,
  hasPermissionToEdit,
  preventStrippingDocumentExtension,
}) {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const iconButtonRef = React.useRef(null);

  const docs = reorderDocumentsShowOpenFirst(documents, shownDocument);
  const documentItems = makeDocumentMenuItems(docs);
  const versionNumber = getVersionNumber(shownDocument, documents);
  const date = moment(shownDocument.creation_date).format("DD/MM/YYYY HH:MM");
  const openDocumentSelector = () => setMenuOpen(true);
  const renderingPercent = renderingStatus * 100;
  const closeMenu = () => setMenuOpen(false);

  const nameFieldElement = React.useRef(null);
  React.useEffect(() => {
    nameFieldElement.current = document.querySelectorAll(
      ".document-name-field",
    )[0];
  }, []);
  const nameFieldDimensions = nameFieldElement.current
    ? nameFieldElement.current.getBoundingClientRect()
    : {width: 200};

  return (
    <div style={styles.container}>
      <div style={styles.projectContainer}>
        {renderingStatus < 1 ? (
          <Tooltip
            title={`Showing ${Math.floor(renderingPercent)}% of clauses`}
            arrow
          >
            <CircularProgress
              variant="determinate"
              size={20}
              value={renderingPercent}
            />
          </Tooltip>
        ) : (
          <Tooltip title={"Open document selector"} arrow>
            <IconButton
              style={styles.documentSelectorButton}
              ref={iconButtonRef}
              onClick={openDocumentSelector}
              size="small"
            >
              <DescriptionIcon
                style={{
                  cursor: "pointer",
                  color: isMenuOpen ? "#1f88e5" : "#424242",
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <Menu
        MenuListProps={{
          style: {
            width:
              nameFieldDimensions.width < 230 ? 230 : nameFieldDimensions.width,
          },
        }}
        getContentAnchorEl={null}
        anchorOrigin={{horizontal: "center", vertical: "bottom"}}
        transformOrigin={{horizontal: "right", vertical: "top"}}
        anchorEl={iconButtonRef.current}
        open={isMenuOpen}
        onClose={closeMenu}
      >
        <div style={{padding: "12px 0"}}>
          {documentItems.map(
            (
              {id, name, creation_date: _creationDate, isDisabled, isShifted},
              index,
            ) => {
              const creationDate = new Date(_creationDate);
              return (
                <MenuItem
                  key={index}
                  disabled={isDisabled}
                  selected={id === shownDocument.id}
                  style={{
                    fontSize: 15,
                    paddingLeft: 24 + (isShifted ? 20 : 0),
                  }}
                  onClick={
                    isDisabled
                      ? undefined
                      : () => replaceUrlParam(router, "documentId", id)
                  }
                >
                  <span
                    title={name}
                    style={componentStyles.documentNameWrapper}
                  >
                    <span
                      style={
                        isShifted
                          ? componentStyles.documentNameShifted
                          : componentStyles.documentName
                      }
                      title={name}
                    >
                      {name}
                    </span>
                    {!isDisabled && (
                      <span style={componentStyles.documentDate}>
                        {creationDate.toLocaleDateString()}{" "}
                        {creationDate.toISOString().substring(11, 16)}
                      </span>
                    )}
                  </span>
                </MenuItem>
              );
            },
          )}
        </div>
      </Menu>
      {versionNumber ? (
        <div style={componentStyles.documentVersion}>
          Version {versionNumber} - {date}
        </div>
      ) : null}

      {renderDocumentName(
        shownDocument,
        fileIndex,
        setFileIndex,
        onFieldUpdate,
        hasPermissionToEdit,
        preventStrippingDocumentExtension,
        isMenuOpen,
        openDocumentSelector,
        renderingStatus,
      )}
    </div>
  );
}

function renderDocumentName(
  shownDocument,
  fileIndex,
  setFileIndex,
  onFieldUpdate,
  hasPermissionToEdit,
  preventStrippingDocumentExtension,
  isMenuOpen,
  openDocumentSelector,
  renderingStatus,
) {
  const files = shownDocument.concat_filenames;
  if (files.length > 1) {
    return (
      <FormControl>
        <Select
          value={fileIndex}
          onChange={e => setFileIndex(parseInt(e.target.value, 10))}
        >
          {files.map((filename, index) => (
            <MenuItem value={index + 1} key={index}>
              {filename}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <EditableField
        className="document-name-field"
        name="document-name"
        text={shownDocument.name}
        updateFieldName="name"
        onFieldUpdate={onFieldUpdate}
        hasPermissionToEdit={hasPermissionToEdit}
        rootDivStyles={styles.editableFieldRootDiv}
        containerStyles={styles.editableFieldContainer}
        inputStyles={styles.editableFieldInput}
        inputHoverStyles={styles.editableFieldInputHover}
        inputFocusStyles={styles.editableFieldInputFocus}
        editIconStyles={styles.editableFieldIcon}
        useEditIcon={false}
        fitInputContent={true}
        underlineShow={false}
        processTextBeforeUpdate={preventStrippingDocumentExtension}
        isMenuOpen={isMenuOpen}
        openDocumentSelector={openDocumentSelector}
        showChevron={Boolean(renderingStatus < 1)}
      />
    );
  }
}

function makeDocumentMenuItems(documents) {
  const documentItems = [];
  let prevGroupId;
  documents.forEach(document => {
    const isNewGroup = document.group_id !== prevGroupId;
    const hasRevisions = document.revisions && document.revisions.length > 0;
    if (isNewGroup && hasRevisions) {
      documentItems.push({
        ...document,
        isDisabled: true,
      });
    }
    documentItems.push({
      ...document,
      isDisabled: false,
      isShifted: hasRevisions,
    });
    prevGroupId = document.group_id;
  });
  return documentItems;
}

function getVersionNumber(document, documents) {
  const revisionDocuments = _.sortBy(
    documents.filter(({group_id: groupId}) => groupId === document.group_id),
    ({creation_date: creationDate}) => new Date(creationDate),
  );
  if (revisionDocuments.length <= 1) {
    return 0;
  }
  const documentPosition = revisionDocuments.findIndex(
    ({id}) => id === document.id,
  );
  return documentPosition + 1;
}

function reorderDocumentsShowOpenFirst(documents, document) {
  const {group_id: groupId} = document;
  return [
    ...documents.filter(({group_id}) => group_id === groupId),
    ...documents.filter(({group_id}) => group_id !== groupId),
  ];
}

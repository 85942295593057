import _ from "underscore";
import React from "react";

import ReactSelectLabel from "common_components/react_select_label";
import {
  filterOptions,
  updateSelection,
} from "common_components/select_handlers";
import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";

import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

function initialise(state) {
  return {
    topic_id: null,
    parameter_ids: [],
    ..._.pick(state, "topic_id"),
  };
}

function validate(issue) {
  const {rules} = issue;
  if (!(rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  return null;
}

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      topics,
      isNonOverridableFieldsDisabled,
      overridableFieldsStyle,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    return (
      <div>
        <div style={{margin: "0 1em"}}>
          <ReactSelectLabel>Topic</ReactSelectLabel>
          <Select
            className="topic"
            multi={false}
            value={rules.topic_id}
            options={topics
              .filter(topic => topic.parameters.length)
              .map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
            onChange={this.updateTopic}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getTopicLink(this.props.organisationId, rules.topic_id)}
            shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
          />
          {renderError(this.props.rulesError, "topic_id")}
        </div>
        <div style={{margin: "2em 1em"}}>
          <ReactSelectLabel>Parameters</ReactSelectLabel>
          <Select
            className="parameter-ids"
            multi={true}
            disabled={editLevel && editLevel !== "base"}
            allowCreate={false}
            filterOptions={filterOptions}
            value={rules.parameter_ids}
            options={
              currentTopic &&
              currentTopic.parameters.map(parameter => ({
                label: parameter.name,
                value: parameter.id,
              }))
            }
            onChange={this.updateParameterIds}
            clearable={false}
            style={overridableFieldsStyle}
            filterOption={filterByWord}
            link={getParameterLink(
              this.props.organisationId,
              rules.topic_id,
              rules.parameter_ids,
            )}
          />
        </div>
        {renderError(this.props.rulesError, "parameter_values")}
      </div>
    );
  }

  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
      parameter_ids: [],
    });
  };
  updateParameterIds = _values => {
    const values = updateSelection(_values);
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_ids: values.map(value => value.value),
    });
  };
  /* eslint-enable no-invalid-this */
}

export default {
  initialise,
  validate,
  component: Component,
};

import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import WorkIcon from "@material-ui/icons/Work";
import Tooltip from "@material-ui/core/Tooltip";
import IssueIcon from "@material-ui/icons/AssignmentTurnedIn";

import ProjectSelector from "common_containers/project_selector";
import Chevron from "./buttons/chevron";

import styles from "./styles";

const ToolbarProjectSelector = ({
  useLinkItems,
  projectId,
  hideLeftIcon,
  issueEditorHref,
  autoOpen,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProjectSelectorOpen, setIsProjectSelectorOpen] = useState(
    autoOpen ?? false,
  );
  const iconButtonRef = useRef(null);

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);
  const projectSelectorHandler = () =>
    setIsProjectSelectorOpen(!isProjectSelectorOpen);

  return (
    <ProjectSelector useLinkItems={useLinkItems} projectId={projectId}>
      {self => {
        const {Icon} = self;
        const {parentPath, lastSegment} = self.getSegmentedProjectName();
        return (
          <>
            <div style={{display: "flex"}}>
              {issueEditorHref !== undefined && (
                <Tooltip title="Open issue editor" arrow>
                  <Link
                    to={issueEditorHref}
                    target="_blank"
                    style={styles.linkBlock}
                    onlyActiveOnIndex
                  >
                    <IssueIcon
                      style={{
                        ...styles.toolbarIcon,
                        color: "#424242",
                        marginLeft: 10,
                        cursor: "pointer",
                      }}
                    />
                  </Link>
                </Tooltip>
              )}
              <Tooltip
                title={
                  isProjectSelectorOpen
                    ? "Close project selector"
                    : "Open project selector"
                }
                arrow
              >
                <WorkIcon
                  onClick={projectSelectorHandler}
                  style={{
                    ...styles.toolbarIcon,
                    color: "#424242",
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </div>

            {isProjectSelectorOpen ? (
              <div style={{maxWidth: "10em"}}>
                <div style={styles.projectContainer} onClick={onMenuOpen}>
                  {!hideLeftIcon ? <Icon style={styles.projectIcon} /> : null}
                  <div style={styles.projectNameContainer}>
                    {(() => {
                      if (!parentPath) {
                        return lastSegment;
                      }
                      return (
                        <>
                          <div style={styles.projectNameParentPath}>
                            {parentPath}
                          </div>
                          <div style={styles.projectName}>{lastSegment}</div>
                        </>
                      );
                    })()}
                  </div>
                  <Chevron
                    iconButtonRef={iconButtonRef}
                    isMenuOpen={isMenuOpen}
                    styles={{marginLeft: 5}}
                  />
                </div>
              </div>
            ) : null}
            <Menu
              getContentAnchorEl={null}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorEl={iconButtonRef.current}
              open={isMenuOpen}
              onClose={onMenuClose}
            >
              <div style={{paddingBottom: 8}}>
                {self.getActionItems().map(({Icon, onClick, text}, index) => (
                  <MenuItem key={index} onClick={onClick}>
                    <ListItemIcon style={styles.linkIconBlock}>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      primaryTypographyProps={{style: {fontSize: 15}}}
                    />
                  </MenuItem>
                ))}
              </div>
              <Divider />
              <div style={{padding: "12px 0"}}>
                {self.getItems().map(item => {
                  const menuItemInner = (
                    <MenuItem
                      key={item.path}
                      disabled={item.isDisabled}
                      selected={item.isSelected}
                      style={{
                        fontSize: 15,
                        paddingLeft: 24 + item.level * 20,
                      }}
                      onClick={useLinkItems ? onMenuClose : item.onClick}
                    >
                      {item.name}
                    </MenuItem>
                  );
                  return useLinkItems ? (
                    <Link
                      key={item.path}
                      to={item.link}
                      target="_blank"
                      style={styles.linkBlock}
                      onlyActiveOnIndex
                    >
                      {menuItemInner}
                    </Link>
                  ) : (
                    menuItemInner
                  );
                })}
              </div>
            </Menu>
          </>
        );
      }}
    </ProjectSelector>
  );
};

ToolbarProjectSelector.propTypes = {
  projectId: PropTypes.number.isRequired,
};

export default ToolbarProjectSelector;

import React from "react";
import PropTypes from "prop-types";

import {withStyles} from "@material-ui/core/styles";
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab";

import SidebarContext from "./context";

const styles = theme => ({
  root: {
    flexShrink: 0,
    width: 280,
    boxShadow:
      "rgba(0, 0, 0, 0.17) 0px 1px 10px, rgba(0, 0, 0, 0.04) 0px 1px 15px",
    zIndex: 1210,
    backgroundColor: "#f8f8f8",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
    fontFamily: "Roboto, sans-serif",
  },
  header: {
    position: "relative",
    flexShrink: 0,
    borderBottom: "1px solid #dbdbdb",
    boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.07)",
  },
  headerInner: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 72,
  },
  headerIcon: {
    fontSize: 48,
    color: "#38424b",
  },
  headerText: {
    fontSize: 18,
  },
  headerButton: {
    position: "absolute",
    right: 19,
    color: "#b9bcbf",
    transform: "rotate(180deg)",
    cursor: "pointer",
    transition: theme.transitions.create("color"),
    "&:hover": {
      color: "#1f88e5",
    },
  },
});

class SidebarPanel extends React.Component {
  render() {
    return (
      <div className={this.props.classes.root}>
        {this.renderHeader()}
        {this.props.children}
      </div>
    );
  }

  renderHeader() {
    const {
      headerIcon,
      headerText,
      isCloseButtonShown,
      headerChildren,
      classes,
    } = this.props;
    if (!headerIcon && !headerText && !isCloseButtonShown && !headerChildren) {
      return null;
    }
    return (
      <div className={classes.header}>
        {Boolean(headerIcon || isCloseButtonShown) && (
          <div className={classes.headerInner}>
            {headerIcon &&
              React.cloneElement(headerIcon, {
                className: classes.headerIcon,
              })}
            {headerText && (
              <div className={classes.headerText}>{headerText}</div>
            )}
            {isCloseButtonShown && (
              <KeyboardTabIcon
                className={classes.headerButton}
                onClick={() => this.context.hidePanel()}
              />
            )}
          </div>
        )}
        {headerChildren}
      </div>
    );
  }
}

SidebarPanel.contextType = SidebarContext;

SidebarPanel.defaultProps = {
  isCloseButtonShown: true,
};

SidebarPanel.propTypes = {
  headerIcon: PropTypes.element,
  headerText: PropTypes.string,
  isCloseButtonShown: PropTypes.bool.isRequired,
  headerChildren: PropTypes.element,
};

export default withStyles(styles)(SidebarPanel);

import React from "react";

import SplitButton from "common_components/buttons/split_button";
import REPORT_TYPES from "constants/report_types";

const styles = {
  reportTooltip: {
    position: "absolute",
    fontSize: 12,
    bottom: -40,
    left: 38,
    fontWeight: 500,
    width: 112,
    textAlign: "center",
    color: "rgb(238, 238, 238)",
    background: "rgb(55, 71, 79)",
    padding: "0.5rem",
    borderRadius: 6,
    zIndex: 2,
    boxSizing: "border-box",
  },
  reportTooltipTriangle: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "0 9px 10px 9px",
    borderColor: "transparent transparent #37474f transparent",
    position: "absolute",
    right: 45,
    top: "-6px",
  },
};

function ReportSelector(props) {
  const {
    project,
    isReportPanelShown,
    isReportShownInRightSide,
    selectedReportId,
    currentIssueset,
    disabled,
  } = props;
  const {report_settings: reportSettings = []} = project;
  const options = reportSettings
    .filter(report => {
      if (report.type === "custom_report" && currentIssueset) {
        return report.referenced_issueset_id
          ? report.referenced_issueset_id === currentIssueset
          : true;
      }
      return true;
    })
    .map(report => ({
      id: report.id,
      label: report.label,
      type: report.type,
    }));
  if (options.length === 0 || !selectedReportId) {
    return null;
  }
  const selectedReport = options.find(report => report.id === selectedReportId);
  React.useEffect(() => {
    if (!selectedReport && options.length > 0) {
      props.onSelectedReportChange(options[0].id);
    }
  }, [selectedReport]);

  function onMenuCloseHandler(value) {
    props.onSelectedReportChange(value.id);
    if (!isReportPanelShown && !isReportShownInRightSide) {
      props.toggleReportPanelVisibility();
    }
  }

  function renderTooltip() {
    if (!props.responseAddedToReport || !selectedReport) {
      return null;
    }
    return (
      <div style={styles.reportTooltip}>
        <div style={styles.reportTooltipTriangle} />
        {`Response added to ${REPORT_TYPES[selectedReport.type].label}`}
      </div>
    );
  }

  function onClickhandler() {
    if (isReportShownInRightSide) {
      props.toggleReportOpenInRightSidebar();
    } else {
      props.toggleReportPanelVisibility();
    }
  }

  return (
    <div style={{position: "relative"}}>
      <SplitButton
        variant="contained"
        size="small"
        baseValue={selectedReportId}
        color={
          isReportPanelShown || isReportShownInRightSide ? "primary" : "inherit"
        }
        onClick={onClickhandler}
        onMenuCloseHandler={onMenuCloseHandler}
        clickWithValue={true}
        options={options}
        disabled={disabled}
        labelStyle={{
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          maxWidth: "10rem",
        }}
      />
      {renderTooltip()}
    </div>
  );
}

export default ReportSelector;

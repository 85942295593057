import lineClamp from "line-clamp";

function clampLine(elements) {
  [...elements].forEach(element => {
    // The more children the element has the more lines should be shown.
    // In case of typical 4 lines clamp and big amout of topic chips
    // the clause text is not shown. Hence the following logic
    const childrenLength = element.children?.length;
    if (childrenLength) {
      if (childrenLength < 5) {
        lineClamp(element, 4);
      } else if (childrenLength > 6) {
        lineClamp(element, 7);
      } else {
        lineClamp(element, 5);
      }
    }
  });
}

export default clampLine;

import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import moment from "moment";

const useStyles = makeStyles({
  tbl: {
    margin: "0 1em 1em",
    borderCollapse: "collapse",
    fontSize: ".9em",
  },
  row: {
    borderBottom: "1px solid rgb(224, 224, 224)",
  },
  cell: {
    padding: "1em",
    verticalAlign: "top",
  },
  typeSpan: {},
  timeSpan: {},
  usernameSpan: {},
  dataDiv: {
    width: "50em",
    overflow: "hidden",
  },
});

export default function ReportsTable({logs}) {
  const {
    tbl,
    row,
    cell,
    typeSpan,
    timeSpan,
    usernameSpan,
    dataDiv,
  } = useStyles();
  return (
    <table className={tbl}>
      <thead>
        <tr>
          <th className={cell}>Type</th>
          <th className={cell}>Time</th>
          <th className={cell}>User Name</th>
          <th className={cell}>Data</th>
        </tr>
      </thead>
      <tbody>
        {logs.map(({type, time, username, data, id}, index) => (
          <tr key={id || index} className={row}>
            <td id={`td-type-${index}`} className={cell}>
              <span className={typeSpan}>{type}</span>
            </td>
            <td id={`td-time-${index}`} className={cell}>
              <span className={timeSpan}>
                {moment(time).format("DD/MM/YYYY - h:mm A")}
              </span>
            </td>
            <td id={`td-username-${index}`} className={cell}>
              <span className={usernameSpan}>{username}</span>
            </td>
            <td id={`td-data${index}`} className={cell}>
              <div className={dataDiv}>{JSON.stringify(data)}</div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

import React from "react";
import PropTypes from "prop-types";
import {Link} from "react-router";
import _ from "lodash";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import FixedTooltip from "common_components/fixed_tooltip";
import getElementInnerText from "utils/get_element_inner_text";

import SidebarContext from "./context";

import Popover from "./popover";

export default class NavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPopoverShown: false,
      isActive: this.isActive(props),
    };
    this.panelUpdating = false;
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.routePath !== this.props.routePath ||
      prevProps.isActive !== this.props.isActive
    ) {
      const isActive = this.isActive(this.props);
      if (this.state.isActive !== isActive) {
        this.setState({isActive});
      }
    }
    if (
      prevProps.panelChildren !== this.props.panelChildren &&
      this.context.isPanelNavItemActive(this)
    ) {
      if (this.panelUpdating === true) {
        this.panelUpdating = false;
      } else {
        this.panelUpdating = true;
        this.context.showPanel(this);
      }
    }
  }

  isActive = props => {
    if (props.isActive) {
      return true;
    }
    if (props.nestedChildren && this.props.activeByNested) {
      return this.hasActiveNestedItem(props.nestedChildren);
    }
    return false;
  };

  hasActiveNestedItem = children => {
    const list = React.Children.toArray(children).filter(child => {
      return React.isValidElement(child);
    });
    for (const index in list) {
      const child = list[index];
      if (child.type === NavItem && child.props.isActive) {
        return true;
      }
      const hasActive = this.hasActiveNestedItem(child.props.children);
      if (hasActive) {
        return true;
      }
    }
    return false;
  };

  render() {
    const props = _.omit(this.props, [
      ..._.keys(NavItem.propTypes),
      "className",
      "style",
      "icon",
      "onClick",
      "children",
    ]);
    return (
      <React.Fragment>
        <FixedTooltip
          position="right"
          showDelay={1000}
          content={this.getCollapsedTooltipContent()}
        >
          <Link
            {...props}
            innerRef={node => (this.rootNode = node)}
            style={this.props.style}
            className={(() => {
              const classNames = [];
              const className = "app-sidebar-nav-item";
              classNames.push(className);
              if (this.state.isPopoverShown) {
                classNames.push(`${className}--pressed`);
              }
              if (
                this.state.isActive ||
                (this.props.panelChildren &&
                  this.context.isPanelNavItemActive(this))
              ) {
                classNames.push(`${className}--active`);
              }
              if (this.props.isCollapsed) {
                classNames.push(`${className}--collapsed`);
              }
              if (this.props.isDisabled) {
                classNames.push(`${className}--disabled`);
              }
              if (_.isString(this.props.className)) {
                classNames.push(this.props.className);
              }
              return classNames.join(" ");
            })()}
            {...this.props.pathname && {
              to: {pathname: this.props.pathname},
            }}
            {...(() => {
              const onClickHandlers = [];
              if (_.isFunction(this.props.onClick)) {
                onClickHandlers.push(this.props.onClick);
              }
              if (this.props.nestedChildren) {
                onClickHandlers.push(() =>
                  this.setState({
                    isPopoverShown: !this.state.isPopoverShown,
                  }),
                );
              } else if (this.props.panelChildren) {
                onClickHandlers.push(() => {
                  if (this.context.isPanelNavItemActive(this)) {
                    this.context.hidePanel(this);
                  } else {
                    this.context.showPanel(this);
                  }
                });
              } else if (
                this.props.hideParentPopover &&
                this.props.shouldHideParentPopover
              ) {
                onClickHandlers.push(() => this.props.hideParentPopover());
              }
              if (onClickHandlers.length) {
                return {
                  onClick: event => {
                    onClickHandlers.forEach(handler => handler(event));
                  },
                };
              }
            })()}
          >
            <div className="app-sidebar-nav-item__inner">
              {this.props.icon && (
                <div className="app-sidebar-nav-item__icon">
                  {this.props.icon}
                </div>
              )}
              {!this.props.isCollapsed && (
                <div className="app-sidebar-nav-item__content">
                  {this.props.children}
                </div>
              )}
              {this.props.nestedChildren &&
                !this.props.isCollapsed && (
                  <KeyboardArrowRightIcon className="app-sidebar-nav-item__arrow-icon" />
                )}
            </div>
          </Link>
        </FixedTooltip>
        {this.props.nestedChildren && this.renderPopover()}
      </React.Fragment>
    );
  }

  getCollapsedTooltipContent = () => {
    if (!this.props.isCollapsed) {
      return null;
    }
    let title = this.props.collapsedTitle;
    if (!title) {
      const {children} = this.props;
      if (_.isString(children) || _.isNumber(children)) {
        title = children;
      } else {
        title = getElementInnerText(children);
      }
    }
    return title;
  };

  renderPopover() {
    if (!this.state.isPopoverShown) {
      return null;
    }
    return (
      <Popover
        parentNode={this.rootNode}
        onHide={() => this.setState({isPopoverShown: false})}
      >
        {this.cloneNestedChildren(this.props.nestedChildren)}
      </Popover>
    );
  }

  cloneNestedChildren = children => {
    return React.Children.map(children, child => {
      if (!child || child.type !== NavItem) {
        if (!React.isValidElement(child)) {
          return child;
        }
        return React.cloneElement(
          child,
          null,
          this.cloneNestedChildren(child.props.children),
        );
      }
      return React.cloneElement(child, {
        hideParentPopover: () => this.setState({isPopoverShown: false}),
      });
    });
  };
}

NavItem.contextType = SidebarContext;

NavItem.defaultProps = {
  isActive: false,
  isDisabled: false,
  activeByNested: true,
  isCollapsed: false,
  pathname: "",
  nestedChildren: null,
  panelChildren: null,
  shouldHideParentPopover: true,
};

NavItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  activeByNested: PropTypes.bool.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  pathname: PropTypes.string,
  nestedChildren: PropTypes.element,
  panelChildren: PropTypes.element,
  routePath: PropTypes.string,
  collapsedTitle: PropTypes.string,
  shouldHideParentPopover: PropTypes.bool.isRequired,
  hideParentPopover: PropTypes.func,
};

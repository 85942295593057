import Describer from "../../../utils/issues/issue_description/describer";

function render(issue, topics) {
  const describer = new Describer.IssueDescriber(issue, topics);
  const parameterText =
    issue.rules.parameter_ids.length > 0
      ? `${describer.listParameters()} do${
          issue.rules.parameter_ids.length === 1 ? "es" : ""
        } not`
      : "no parameters";
  return `${
    describer.topicName
  } exists, but ${parameterText} exist on any instance`;
}

export default render;

import _ from "underscore";

export default function(rawTopics, selectedTopics, showSelectedTopicsOnly) {
  let topics = rawTopics;
  if (showSelectedTopicsOnly) {
    const selectedTopicsIds = Object.keys(selectedTopics).map(item =>
      parseInt(item, 10),
    );
    topics = rawTopics.filter(topic => selectedTopicsIds.includes(topic.id));
  }
  const data = topics.reduce((acc, topic) => {
    const names = topic.topic.name.split(/\s*\/\s*/i);
    const namesLastIndex = names.length - 1;
    let item = acc;
    names.forEach((name, i) => {
      if (i < namesLastIndex) {
        const parent = item.find(element => element.name === name);
        if (!parent) {
          item.push({
            name,
            children: [],
            count: 0,
            nestingLevel: 0,
            hasSelections: false,
            isTopic: false,
            nestedChildrenIds: [],
          });
        }
        const parentItem = parent || item[item.length - 1];
        if (!parentItem.children) {
          parentItem.children = [];
        }
        item = parentItem.children;
      } else {
        item.push({
          name,
          count: 1,
          id: topic.id,
          isTopic: true,
          fullName: topic.topic.name,
          help: topic.topic.help,
        });
      }
    });
    return acc;
  }, []);

  const selectedTopicsIds = selectedTopics ? Object.keys(selectedTopics) : [];

  function calculateCounts(item, nestingLevel) {
    if (item.children) {
      item.children.forEach(child => calculateCounts(child, nestingLevel + 1));
    }
    if (!item.children) {
      item.nestingLevel = nestingLevel;
      return;
    }
    let count = 0;
    let nestedChildrenIds = [];
    item.children.forEach(child => {
      if (
        (child.id && selectedTopicsIds.includes(child.id.toString())) ||
        child.hasSelections
      ) {
        item.hasSelections = true;
      }
      if (child.isTopic) {
        nestedChildrenIds.push(child.id);
      }
      if (!child.isTopic) {
        nestedChildrenIds = [...nestedChildrenIds, ...child.nestedChildrenIds];
      }
      count += child.count;
    });
    item.nestedChildrenIds = nestedChildrenIds;
    item.count = count;
    item.nestingLevel = nestingLevel;
  }
  data.forEach(dataItem => calculateCounts(dataItem, 0));
  return _.sortBy(data, item => item.children && item.children.length);
}

import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import Textarea from "./textarea_autosize";

const useFormStyles = makeStyles({
  root: ({style}) => ({
    ...((style && style.panel) || {}),
  }),
  panel: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  buttons: {},
  squareButton: {
    borderRadius: 0,
    boxShadow: "none",
    marginLeft: ".5em",
  },
});

const styles = {
  textarea: {
    width: "calc(100% - 2rem + 2px)",
    margin: ".5em auto",
    padding: "1em",
  },
};

function IssueCommentComponent({
  value,
  onClick,
  onChange,
  rows,
  isBlurred,
  onCancel,
  onSave,
  style,
}) {
  const classes = useFormStyles({style});
  return (
    <div className={classes.root}>
      <Textarea
        placeholder="Add comment"
        value={value}
        onClick={onClick}
        onChange={onChange}
        variant="outlined"
        rows={rows}
        styles={{...styles.textarea, ...((style && style.textarea) || {})}}
      />
      {isBlurred && (
        <div className={classes.panel}>
          <div className={classes.icons} />
          <div className={classes.buttons}>
            <Button
              variant="contained"
              classes={{root: classes.squareButton}}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              classes={{root: classes.squareButton}}
              onClick={onSave}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default IssueCommentComponent;

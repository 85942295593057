import Describer from "../../../utils/issues/issue_description/describer";

function describeReason(clauses, topicsById, issue) {
  const {rules} = issue;

  const describerA = new Describer.IssueDescriber(
    {...issue, rules: {...rules.topic_a, match_logic: rules.match_logic}},
    Object.values(topicsById),
  );
  const describerB = new Describer.IssueDescriber(
    {...issue, rules: {...rules.topic_b, match_logic: rules.match_logic}},
    Object.values(topicsById),
  );
  return [
    `Parameter ${describerA.parameterName}`,
    `of topic ${describerA.topicName}`,
    `${describerA.matchLogic}`,
    `parameter ${describerB.parameterName}`,
    `of topic ${describerB.topicName}.`,
  ].join(" ");
}

export default describeReason;

import _ from "underscore";

function groupRelatedClausesByTopicsAndTopicGroups(clauses) {
  const result = {};
  _.each(clauses, (clausesArray, groupName) => {
    if (!result[groupName]) {
      result[groupName] = {};
    }
    clausesArray.forEach(clause => {
      clause.topics.forEach(topic => {
        if (!result[groupName][getTopicName(topic.name)]) {
          result[groupName][getTopicName(topic.name)] = [clause];
        } else {
          result[groupName][getTopicName(topic.name)].push(clause);
        }
      });
    });
  });
  return result;
}

function getTopicName(fullName) {
  const topicNameArray = fullName.split("/");
  return topicNameArray[topicNameArray.length - 1].trim();
}

export default groupRelatedClausesByTopicsAndTopicGroups;

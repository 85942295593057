import FileSaver from "file-saver";

async function downloadXlsx(workbook, filename) {
  const buffer = await workbook.xlsx.writeBuffer();
  const downloadedDocumentBlob = new Blob([buffer], {
    type: "application/octet-binary",
  });
  FileSaver.saveAs(downloadedDocumentBlob, filename);
}

export default downloadXlsx;

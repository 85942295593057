/**
 * This is a simple utility type to enable explicit error handling. This
 * concept is a popular alternative to throwing exceptions via the special
 * try/catch mechanism. Chiefly, this works better with the type checker.
 */
export type Result<Value> = {ok: true; value: Value} | {ok: false};

/**
 * This value represents an explicit "Loading" state. It's useful to express
 * that the identity of an optional variable extends beyond the typical two
 * states of "truthy"/"falsy", to include a third state expressing the common
 * scenario where data is neither omitted completely, nor currently available.
 * This is such a frequent scenario in frontend development that I find it
 * useful to introduce this concept as a "third state" of a variable. It's
 * actually more common to use a completely separate state variable for this
 * (e.g. "variableIsLoading"), but to me this misses an obvious trick with
 * Typescript to avoid the possible expression of the invalid combination of
 * states in which the variable is actually defined, while its supposedly
 * correlated loading state implies that it isn't. We can completely avoid
 * the possibility of this "bad state" by using Typescript more effectively,
 * merging the two states into a single union of mutually exclusive values.
 *
 * One criticism of this approach is that it may be useful to present old data
 * while new data is loading, which would be trivial under the common method of
 * defining a separate loading state. Personally, I have such a distaste for
 * dependending on implicit behaviours or properties of an implementation that
 * I would rather implement a hook or helper function with explicit reference
 * to the distinctly new and old states. I have no doubt that this would result
 * in longer, more explicit conditions being written in places to handle these
 * newly distinguished variables. My opinion is simply that the introduction of
 * Typescript tips the balance of tradeoffs towards favouring longer, more
 * explicit code, over code that is shorter but with more implicit behaviour.
 * In short, I think it's simply worth all that to get stronger guarantees of
 * correctness from the compiler. Besides, it's always easier to "squash" more
 * precisely discriminated states for simplicity than is it to reconstruct a
 * more explicit state from a collection of implicit ones.
 *
 * The use of uppercase here is mostly to avoid collisions with its derived
 * type. I hope this is also interpreted as a stylistically valid use of
 * uppercase to denote a global constant, although this is neither really
 * intentional, nor generally a standard in this codebase.
 */
export const LOADING = Symbol("Loading");
export type Loading = typeof LOADING;
export type MaybeLoading<T> = T | Loading;

/**
 * This type represents a value that we've failed to dereference from an ID,
 * probably indicating a bug, but not one worth throwing a fatal error for.
 */
export const MISSING = Symbol("Missing");
export type MaybeMissing<T> = T | typeof MISSING;

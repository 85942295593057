import _ from "underscore";

export default function getText(paragraphs) {
  let offset = 0;
  return getField(
    paragraphs,
    ({r}) => ({
      text: getField(r, ({t}) => {
        if (!t) {
          return {text: ""};
        }
        offset = addPosition(t, offset);
        const atomText = t["#text"] || "";
        return {text: atomText};
      }),
    }),
    " ",
  );
}

function getField(value, mapper, joinWith = "") {
  if (_.isArray(value)) {
    const elements = value
      .map((element, index) => mapper(element, index).text)
      .join(joinWith);
    return elements;
  }
  if (_.isObject(value)) {
    return mapper(value, null).text;
  }
  return "";
}

function addPosition(t, offset) {
  const text = `${t["#text"] || ""}`;
  t.start = offset;
  t.end = offset + text.length;
  return t.end + (text ? 1 : 0);
}

import {ActionTypes} from "modules/redux_types";
import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

const types = [
  "USERGROUPS_FETCH",
  "USERGROUP_CREATE",
  "USERGROUP_DELETE",
  "USERGROUP_EDIT",

  "USERGROUP_USERS_FETCH",
  "USERGROUP_USER_ADD",
  "USERGROUP_USER_REMOVE",
] as const;

types.forEach(type => createReduxPromiseActionType(actionTypes, type));

export type ActionType = typeof types[number];

export type UsergroupActionTypes = ActionTypes<ActionType>;

export default actionTypes as UsergroupActionTypes;

import isContext from "./utils/is_context";

/* eslint-disable no-invalid-this */
function getStringValue(obj) {
  if (Array.isArray(obj)) {
    if (obj.length === 0) {
      return "";
    }
  }
  if (obj && typeof obj === "object") {
    if (Object.keys(obj).length === 0) {
      return "{}";
    }
  }
  return (obj || "").toString();
}

function replaceTemplate(strRaw, v1, v2) {
  const str = typeof strRaw === "string" ? strRaw : JSON.stringify(strRaw);
  return (str || "")
    .toString()
    .replace(/\$\$1/g, v1)
    .replace(/\$\$2/g, v2);
}

function ifCond(v1, v2, trueValue, falseValue) {
  if (isContext(trueValue)) {
    return "";
  }
  if (getStringValue(v1) === getStringValue(v2)) {
    return replaceTemplate(trueValue, v1, v2);
  }
  if (isContext(falseValue)) {
    return "";
  }
  return replaceTemplate(falseValue, v1, v2);
}

export default ifCond;

const getRiskScoreIconColor = (issueStats) => {
  if (issueStats) {
    if (issueStats === "green") {
      return "#66bb6a";
    }
    if (issueStats === "red") {
      return "#ef5350";
    }
    if (issueStats === "amber") {
      return "#ffa000";
    }
  } else {
    return "rgba(130,130,130,0.28)";
  }
};

export default getRiskScoreIconColor;

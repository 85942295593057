import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import {LlmStatusAction} from "../actions/fetch";
import actionTypes from "../constants/action_types";

export default function actionDefinitionsReducer(
  state: MaybeUninitialised<string> = UNINITIALISED,
  action: LlmStatusAction,
): typeof state {
  if (action.type === actionTypes.FETCH_LLM_STATUS.SUCCESS) {
    return (action as LlmStatusAction).payload;
  }
  return state;
}

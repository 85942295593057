import {get} from "lodash";
import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getPositiveReasonText from "common/utils/issues/reason/get_positive_reason_text";

function getSubissueEmptyParentReasonTextArray(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  logDebugInfo,
  showDetailedReason,
  preventMemo,
  last_classification_changed,
) {
  const parentSetStateAndDisplayTextFrom = get(
    issue,
    "parent_settings.set_state_and_display_text_from",
  );
  const isParentAlert = isIssueAlert(issue);
  const displayTextRule = isParentAlert
    ? get(issue, "parent_settings.display_text_alert")
    : get(issue, "parent_settings.display_text_non_alert");

  const parentTemplateText = isParentAlert
    ? (showDetailedReason
        ? issue.detailed_reason ?? issue.reason_template
        : issue.reason_template) &&
      getTriggeredText(
        issue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        logDebugInfo,
        showDetailedReason,
        last_classification_changed,
      )[0]
    : (showDetailedReason
        ? issue.detailed_positive_reason ?? issue.positive_reason
        : issue.positive_reason) &&
      getNonTriggeredText(
        issue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        logDebugInfo,
        showDetailedReason,
        preventMemo,
        last_classification_changed,
      );

  if (!parentSetStateAndDisplayTextFrom && !displayTextRule) {
    // old behaviour: if no parent reasonText - show only children
    // if parent reasonText available - don't show children
    if (parentTemplateText) {
      return [parentTemplateText];
    }
    const alertSubissues = issue.subissueData.subissues.filter(subissue =>
      isIssueAlert(subissue),
    );
    if (alertSubissues.length > 0) {
      return alertSubissues
        .map(subissue =>
          getTemplateText(
            subissue,
            issueset,
            documentClauses,
            topicsById,
            positiveReasonData,
            reasonData,
            parties,
            logDebugInfo,
            showDetailedReason,
            preventMemo,
          ),
        )
        .filter(item => item);
    }
    return [
      getNonTriggeredText(
        issue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        logDebugInfo,
        showDetailedReason,
        preventMemo,
      ),
    ];
  }

  const subissuesToUse =
    parentSetStateAndDisplayTextFrom === "all_sub_issues"
      ? issue.subissueData.subissues
      : issue.subissueData.subissues.filter(
          subissue => !subissue.shouldBeHidden,
        );

  let subissueReasonTexts = [];
  switch (displayTextRule) {
    case "first_alert_issue": {
      const firstAlertIssue = subissuesToUse.find(subissue =>
        isIssueAlert(subissue),
      );
      const reasonText = getTemplateText(
        firstAlertIssue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        logDebugInfo,
        showDetailedReason,
        preventMemo,
      );
      subissueReasonTexts = reasonText ? [reasonText] : [];
      break;
    }
    case "all_alert_issues": {
      subissueReasonTexts = subissuesToUse.reduce((accum, subissue) => {
        if (isIssueAlert(subissue)) {
          const reasonText = getTemplateText(
            subissue,
            issueset,
            documentClauses,
            topicsById,
            positiveReasonData,
            reasonData,
            parties,
            logDebugInfo,
            showDetailedReason,
            preventMemo,
          );
          if (reasonText) {
            accum.push(reasonText);
          }
        }
        return accum;
      }, []);
      break;
    }

    case "first_issue": {
      const firstSubissue = subissuesToUse[0];
      // TODO: should test trigger_display here
      const reasonText = getNonTriggeredText(
        firstSubissue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        logDebugInfo,
        showDetailedReason,
        preventMemo,
      );
      subissueReasonTexts = reasonText ? [reasonText] : [];
      break;
    }
    case "set_manually": {
      subissueReasonTexts = [];
      break;
    }
    case "all_issues":
    default: {
      subissueReasonTexts = subissuesToUse.map(subissue =>
        getTemplateText(
          subissue,
          issueset,
          documentClauses,
          topicsById,
          positiveReasonData,
          reasonData,
          parties,
          logDebugInfo,
          showDetailedReason,
          preventMemo,
        ),
      );
      break;
    }
  }
  const lines = [parentTemplateText, ...subissueReasonTexts]
    .filter(item => item && item.length)
    .map(item => item.replace(/^[ \t]+/m, ""));
  return lines;
}

function getTemplateText(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  logDebugInfo,
  showDetailedReason,
  preventMemo,
) {
  return issue.reason && issue.reason.length > 0
    ? getTriggeredText(
        issue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        logDebugInfo,
        showDetailedReason,
        preventMemo,
      )[0]
    : getNonTriggeredText(
        issue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        logDebugInfo,
        showDetailedReason,
        preventMemo,
      );
}

function getTriggeredText(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  logDebugInfo,
  showDetailedReason,
  preventMemo,
  last_classification_changed,
) {
  if (showDetailedReason) {
    const text = getIssueTemplatedText(
      issue,
      issueset,
      "detailed_reason",
      documentClauses,
      topicsById,
      positiveReasonData,
      reasonData,
      parties,
      false,
      logDebugInfo,
      preventMemo,
      last_classification_changed,
    );
    if (text.length) {
      return text;
    }
  }
  return getIssueTemplatedText(
    issue,
    issueset,
    "reason_template",
    documentClauses,
    topicsById,
    positiveReasonData,
    reasonData,
    parties,
    false,
    logDebugInfo,
    preventMemo,
    last_classification_changed,
  );
}

function getNonTriggeredText(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  logDebugInfo,
  showDetailedReason,
  preventMemo,
) {
  if (showDetailedReason) {
    const text = getPositiveReasonText(
      issue.detailed_positive_reason,
      issue,
      issueset,
      documentClauses,
      topicsById,
      positiveReasonData,
      reasonData,
      parties,
      logDebugInfo,
      preventMemo,
    );
    if (text?.length) {
      return text;
    }
  }
  return getPositiveReasonText(
    issue.positive_reason,
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    reasonData,
    parties,
    logDebugInfo,
    preventMemo,
  );
}
export default getSubissueEmptyParentReasonTextArray;

import buildProjectsTree from "./build_projects_tree";
import forEachProjectsTreeItem from "./for_each_projects_tree_item";

export default function splitNestedProjectItems(projects, isAdmin) {
  const tree = buildProjectsTree(projects);
  const items = [];
  forEachProjectsTreeItem(
    tree,
    (itemData, itemName) => {
      if (!itemData.project || !itemData.project.admin_only || isAdmin) {
        items.push({
          name: itemName,
          level: itemData.level,
          path: itemData.path,
          projectId: !itemData.project ? null : itemData.project.id,
        });
      }
    },
    isAdmin,
  );
  return items;
}

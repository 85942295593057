/* eslint-disable no-invalid-this */
import _ from "underscore";

function groupTextByClause(textArray, clauseArray, valuesData, _groupSiblings) {
  const groupSiblings =
    typeof _groupSiblings === "object"
      ? valuesData === true
      : _groupSiblings === true;
  return _.chain(_.zip(clauseArray, textArray, valuesData && valuesData.values))
    .groupBy(item => `${item[0]}___${item[1]}`)
    .reduce((state, _item, key) => {
      const obj = {ref: _item[0][0], text: _item[0][1], values: _item[0][2]};
      const ref = key.split("___")[0];
      const matchingRef = Object.keys(state).find(existingRef =>
        areMatchingRefs(ref, existingRef),
      );
      if (
        matchingRef &&
        state[matchingRef].find(item => item.text === obj.text)
      ) {
        if (matchingRef.length > ref.length) {
          if (
            state[matchingRef].find(
              item =>
                _.intersection(findLongest(item.values), obj.values).length ===
                (item.values || []).length,
            )
          ) {
            const removedObj = removeItem(state, matchingRef, obj);
            addItem(state, ref, obj);
            addExistingItem(state, ref, removedObj);
          }
        } else if (
          (obj.values || []).length > 0 &&
          !state[matchingRef].find(
            item =>
              _.intersection(findLongest(item.values), obj.values).length ===
              (obj.values || []).length,
          )
        ) {
          addItem(state, ref, obj);
        } else {
          addExistingItem(state, matchingRef, obj);
        }
      } else {
        addItem(state, ref, obj);
      }
      return state;
    }, {})
    .reduce((state, value, key) => {
      let newState;
      if (groupSiblings) {
        newState = addSibling(state, key, value);
      } else {
        state[key] = value;
        newState = state;
      }
      return newState;
    }, {})
    .pairs()
    .map((value, index, list) => {
      return [
        value[0],
        value[1].filter(el => {
          const children = list.filter(item =>
            item[0].startsWith(`${value[0]}.`),
          );
          if (children.length > 0) {
            const values = _.chain(children)
              .map(child => child[1].map(childEl => childEl.values))
              .flatten()
              .uniq()
              .value();
            const elValues = findLongest(el.values);
            if (_.intersection(elValues, values).length === elValues.length) {
              return false;
            }
          }
          return true;
        }),
      ];
    }, {})
    .object()
    .mapObject((value, key) =>
      value.map(item => createText(item, valuesData, key)),
    )
    .map(item => item)
    .flatten()
    .value();
}

function createText(item, valuesData, key) {
  if (item.ref.length === 2 && item.values.find(el => el && el.length)) {
    const {index: idx} = item.ref.reduce(
      (state, el, index) =>
        el.length > state.maxLength ? {maxLength: el.length, index} : state,
      {maxLength: 0, index: -1},
    );
    return replaceText(item.text, valuesData, item.ref[idx], item.values[idx]);
  }

  const bestValue = findLongest(item.values || []);
  const otherValues = _.flatten(
    (item.values || []).filter(el => !_.isEqual(el, bestValue)),
  );

  return replaceText(
    item.text,
    valuesData,
    key,
    otherValues.length ? _.intersection(bestValue, otherValues) : bestValue,
  );
}

function replaceText(text, valuesData, ref, values) {
  return text
    .replace("{{{id}}}", ref)
    .replace(
      "{{{values}}}",
      valuesData && values && values.length
        ? `${valuesData.pre || ""}${values.join(", ")}${valuesData.post || ""}`
        : "",
    );
}

function findLongest(listOfLists) {
  return listOfLists.reduce(
    (state, el) => (el && el.length > state.length ? el : state),
    [],
  );
}

function areMatchingRefs(refA, refB) {
  return (
    refA === refB || refA.startsWith(`${refB}.`) || refB.startsWith(`${refA}.`)
  );
}

function isSibling(refA, refB) {
  return cleanLastPart(refA) === cleanLastPart(refB);
}

function cleanLastPart(ref) {
  return ref.replace(/\.[^.]+$/, "");
}

function removeItem(state, ref, value) {
  const obj = state[ref].find(item => item.text === value.text);
  state[ref] = state[ref].filter(item => item.text !== value.text);
  if (state[ref].length === 0) {
    delete state[ref];
  }
  return obj;
}

function addItem(state, ref, obj) {
  const newVal = {text: obj.text, ref: [obj.ref], values: [obj.values || []]};
  if (state[ref]) {
    state[ref].push(newVal);
  } else {
    state[ref] = [newVal];
  }
}
function addExistingItem(state, ref, obj) {
  const existingItem = state[ref].find(item => item.text === obj.text);
  existingItem.ref.push(obj.ref);
  existingItem.values.push(obj.values);
}

function addSibling(state, ref, items) {
  let added = false;
  const siblingRef = Object.keys(state).find(item => isSibling(item, ref));
  if (siblingRef) {
    const sameTextItem = state[siblingRef].find(siblingItem =>
      items.find(thisItem => thisItem.text === siblingItem.text),
    );
    const diffTextItem = _.find(
      state,
      (siblingItems, key) =>
        isSibling(key, ref) &&
        siblingItems.find(siblingItem =>
          items.find(item => item.text !== siblingItem.text),
        ),
    );
    if (sameTextItem && !diffTextItem) {
      removeItem(state, siblingRef, sameTextItem);
      addSibling(state, cleanLastPart(ref), [sameTextItem]);
      added = true;
    }
  }
  if (!added) {
    state[ref] = items;
  }
  return state;
}

export default groupTextByClause;

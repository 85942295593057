import ActionTypes from "../constants/action_types";
import UNINITIALISED from "utils/uninitialised";

export default function websocket(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.WEBSOCKET_INIT.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

function zipDouble(...args) {
  args.pop();
  return args.reduce((state, list) => {
    const newState = list.reduce((_state, item, index) => {
      _state[index] = _state[index] ? _state[index] : [];
      _state[index].push(item);
      return _state;
    }, state);
    if (list.length < args[0].length) {
      for (let idx = list.length; idx < args[0].length; idx += 1) {
        newState[idx].push([]);
      }
    }
    return newState;
  }, []);
}

export default zipDouble;

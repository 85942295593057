import issueHeaderUtils from "utils/issues/issue_header_utils";

function doesIssueMatchFilter(
  issue,
  filter,
  currentIssuesetItem,
  project,
  showOnlyIssues,
) {
  if (showOnlyIssues) {
    if (!showOnlyIssues.find(issueId => issue.id === issueId)) {
      return false;
    }
  }
  if (
    filter.actioned ||
    filter.issue ||
    filter.warning ||
    filter.notIssues ||
    filter.guidance ||
    filter.ignored
  ) {
    const issueIconName = issueHeaderUtils.getIconName(
      issue,
      currentIssuesetItem,
      false,
      project,
    );

    if (filter.ignored !== true) {
      if (issueIconName === "IgnoreIcon") {
        return false;
      }
    }
    if (filter.actioned !== true) {
      if (issueIconName === "FixedIcon") {
        return false;
      }
    }
    if (filter.issue !== true) {
      if (issueIconName === "IssueIcon") {
        return false;
      }
    }
    if (filter.guidance !== true) {
      if (issueIconName === "GuidanceIcon") {
        return false;
      }
    }
    if (filter.warning !== true) {
      if (issueIconName === "WarningIcon") {
        return false;
      }
    }
    if (filter.notIssues !== true) {
      if (issueIconName === "NoIssueIcon") {
        return false;
      }
    }
  }
  if (filter.reviewState) {
    if (filter.reviewState !== "unread") {
      if (issue.review_state === 1) {
        return false;
      }
    }
    if (filter.reviewState !== "read") {
      if (issue.review_state !== 1) {
        return false;
      }
    }
  }
  if (filter.escalatedReviewState) {
    if (filter.escalatedReviewState !== "needReview") {
      if (issue.review_state === 3) {
        return false;
      }
    }
    if (filter.escalatedReviewState !== "dontNeedReview") {
      if (issue.review_state !== 3) {
        return false;
      }
    }
  }
  return true;
}

export default doesIssueMatchFilter;

import getClauseAtomsByTopics from "utils/clauses/get_clause_atoms_by_topics";

function getClauseAtomsDataByTopics(
  referencedTopics,
  documentClauses,
  topicsById,
  categoryIds,
  applicableClauses,
) {
  const clauseAtoms = getClauseAtomsByTopics(
    referencedTopics,
    documentClauses,
    topicsById,
    categoryIds,
    applicableClauses,
  );
  const clauseCount = clauseAtoms.length;
  const data = clauseCount ? {"Related Topics": clauseAtoms} : {};
  return {data, clauseCount};
}

export default getClauseAtomsDataByTopics;

import React from "react";

import GraphNode from "./graph_node";
import calculateNodeKey from "../../../../utils/calculate_node_key";

const styles = {
  main: {
    borderCollapse: "collapse",
    marginBottom: 4,
    border: "1px solid #e0e0e0",
    width: "100%",
  },
};

function Table(props) {
  const {node} = props;
  return (
    <table style={styles.main}>
      <tbody>
        {node.clauseNodes.map((child, index) => (
          <GraphNode
            {...props}
            key={`${calculateNodeKey(props)}-${index}`}
            node={child}
          />
        ))}
      </tbody>
    </table>
  );
}

export default Table;

import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function createSvgIcon(path, displayName, baseProps = {}) {
  const Component = (props, ref) => (
    <SvgIcon
      data-mui-test={`${displayName}Icon`}
      ref={ref}
      width="16pt"
      height="16pt"
      viewBox="0 0 16 16"
      {...baseProps}
      {...props}
    >
      {path}
    </SvgIcon>
  );

  if (process.env.NODE_ENV !== "production") {
    // Need to set `displayName` on the inner component for React.memo.
    // React prior to 16.14 ignores `displayName` on the wrapper.
    Component.displayName = `${displayName}Icon`;
  }

  Component.muiName = SvgIcon.muiName;

  return React.memo(React.forwardRef(Component));
}

import React, {useState} from "react";

import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import DoneIcon from "@material-ui/icons/Done";

const styles = {
  baseFlexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    width: 18,
    height: 18,
  },
};

function CopyButton(props) {
  const {onCopyClick, hideCaption} = props;
  const [isCopied, isCopiedUpdate] = useState(false);

  function onCopy(e) {
    e.preventDefault();
    e.stopPropagation();
    onCopyClick();
    isCopiedUpdate(true);
    setTimeout(() => {
      isCopiedUpdate(false);
    }, 1500);
  }

  const CopyIcon = isCopied ? DoneIcon : FileCopyOutlinedIcon;
  return (
    <div
      onClick={onCopy}
      style={{
        ...styles.baseFlexContainer,
        cursor: "pointer",
        userSelect: "none",
        msUserSelect: "none",
      }}
    >
      <CopyIcon style={{...styles.icon, ...props.iconStyle}} />
      {!hideCaption && (
        <span style={{marginLeft: 4, fontSize: 12, fontWeight: 500}}>Copy</span>
      )}
    </div>
  );
}

export default CopyButton;

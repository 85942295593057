const getSelectedReportStateValues = (project, selectedReportId) => {
  const {report_settings: reportSettings = []} = project;
  if (!reportSettings || reportSettings.length === 0) {
    return {selectedReportId: null};
  }
  const selectedReportSettings = reportSettings.find(
    rs => rs.id === selectedReportId,
  );
  if (selectedReportSettings) {
    return {
      selectedReportId,
    };
  }
  return {};
};

export default getSelectedReportStateValues;

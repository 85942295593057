import functionList from "./function_list";

function map(list, functionName, ...args) {
  const fn = functionList[functionName];
  return list.map(item =>
    fn(...args.map(arg => (arg === "#value" ? item : arg))),
  );
}

export default map;

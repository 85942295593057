import {get} from "lodash";
import isIssueAlert from "./is_issue_alert";
import getEmptyParentIssues from "./get_empty_parent_issues";
import {DocumentIssue} from "common/types/document_issue";

function getEmptyParentApplicableIssues(issue: DocumentIssue) {
  const {empty_parent_applicable_clauses: emptyParentApplicableClauses} = issue;
  const subissues: DocumentIssue[] = get(issue, "subissueData.subissues", []);
  const applicableIssues =
    emptyParentApplicableClauses === "triggered_clauses_only"
      ? subissues.filter(subissue => isIssueAlert(subissue))
      : getEmptyParentIssues(issue, "applicable_clauses");
  return applicableIssues.filter(issue => issue);
}

export default getEmptyParentApplicableIssues;

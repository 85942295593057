export default function groupByTopicOrder(clauses, topicId) {
  return clauses.reduce(
    (result, clause) => {
      if (clause.topics[topicId]) {
        result.clauses.push([]);
      }
      if (result.clauses.length) {
        result.clauses[result.clauses.length - 1].push(clause);
      }
      return result;
    },
    {clauses: []},
  ).clauses;
}

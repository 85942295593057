import JSONPath from "JSONPath";

function getClauseByPath(clause, _path) {
  const path = _path
    .split("[")
    .join(".clauseNodes[")
    .replace("root", "$.nodes");

  const el = JSONPath.eval(clause, path)[0];

  return el;
}

export default getClauseByPath;

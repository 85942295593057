import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";
import UserActionTypes from "modules/user/constants/action_types";

export default function promptsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.PROMPTS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PROMPT_ADD.SUCCESS: {
      return [...state, action.payload];
    }
    case ActionTypes.PROMPT_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(prompt => {
        if (prompt.id === action.payload.id) {
          return {
            ...prompt,
            ...action.payload,
          };
        }
        return prompt;
      });
    }
    case ActionTypes.PROMPT_REMOVE.SUCCESS: {
      if (action.payload && action.payload.id) {
        return state.filter(prompt => prompt.id !== action.payload.id);
      }
      return state;
    }
    case ActionTypes.PROMPTS_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import {get} from "lodash";
import getKeyValues from "common/utils/get_key_values";
import applyOverrideRulesChanges from "common/utils/issues/apply_override_rules_changes";

function getFieldOverrides(issue, field, issuesetMasterId, issuesetClientId) {
  // returns overrides which is an array of 2 objects:
  // [{remote_client_overrides}, {master_overrides}]
  return [
    issuesetClientId === "master"
      ? {}
      : get(issue, [field, issuesetMasterId, issuesetClientId], {}),
    get(issue, [field, issuesetMasterId, "master"], {}),
  ];
}

export const documentIssueOverrideFields = [
  {fieldName: "user_text", defaultValue: ""},
  {fieldName: "current_state", defaultValue: null},
  {fieldName: "is_correctly_applied", defaultValue: null},
  {fieldName: "user_verified", defaultValue: null},
  {fieldName: "should_be_issue", defaultValue: null},
  {fieldName: "reject_reason_details", defaultValue: {}},
  {fieldName: "correction", defaultValue: null},
  {fieldName: "action_state", defaultValue: {}},
  {fieldName: "manual_corrections", defaultValue: null},
  {fieldName: "resolved_state", defaultValue: null},
  {fieldName: "review_state", defaultValue: 1},
];

function getOverriddenIssue(
  issue,
  issuesetMasterId,
  issuesetClientId,
  useMasterName,
  omitIssueKeys = false,
) {
  const overrideValues = getFieldOverrides(
    issue,
    "override_values",
    issuesetMasterId,
    issuesetClientId,
  );
  const issueWithOverrides = {};

  // custom fields
  issueWithOverrides.name = useMasterName
    ? issue.true_name || issue.name
    : getOverrideValue(issue, overrideValues, "display_name") || issue.name;
  issueWithOverrides.issue_order =
    getOverrideValue(issue, overrideValues, "issue_order") || "";
  issueWithOverrides.issue_class_id = parseInt(
    getOverrideValue(issue, overrideValues, "issue_class_id"),
    10,
  );
  issueWithOverrides.parent_settings = {
    ...issue.parent_settings,
    ...(overrideValues && overrideValues[1]
      ? overrideValues[1].parent_settings
      : {}), // master
    ...(overrideValues && overrideValues[0]
      ? overrideValues[0].parent_settings
      : {}), // client
  };

  // other issue fields
  const otherFields = [
    "display_name",
    "email_template",
    "reason_template",
    "standard_language",
    "positive_reason",
    "positive_guidance",
    "description",
    "guidance",
    "detailed_reason",
    "detailed_positive_reason",
    "show_if_triggered_only",
    "open_subissues_when",
    "hide_subissue_when_parent",
    "display_topics",
    "additional_applicable_clause_topics",
    "related_topics",
    "styling",
    "empty_parent_applicable_clauses",
    // "related_searches",
    "fallback_guidance",
    "non_triggered_display_topics",
    "non_triggered_additional_applicable_clause_topics",
    "non_triggered_related_topics",
    "rag_score",
    "show_parties",
    "report_only",
    "show_in_clause_buttons",
    "about_description",
    "parameter_filters",
    "internal_notes",
    "prioritize_non_issue_mutex",
    "do_not_sync",
    "common_definitions",
    "required_change",
    "required_change_not_triggered",
    "use_parameter_filter_clauses",
  ];

  otherFields.forEach(fieldName => {
    issueWithOverrides[fieldName] = getOverrideValue(
      issue,
      overrideValues,
      fieldName,
    );
  });

  // handle overridden 'rules_changes' field
  const templateRulesChanges = get(
    issue,
    `override_values.${issuesetMasterId}.master.rules_changes`,
    [],
  );
  const clientRulesChanges = get(
    issue,
    `override_values.${issuesetMasterId}.${issuesetClientId}.rules_changes`,
    [],
  );
  if (templateRulesChanges.length > 0 || clientRulesChanges.length > 0) {
    const overriddenRules = applyOverrideRulesChanges(
      issue.rules,
      templateRulesChanges,
      clientRulesChanges,
    );
    issueWithOverrides.rules = overriddenRules;
    const keyValues = getKeyValues(overriddenRules, [
      "topic_id",
      "parameter_id",
    ]);
    issueWithOverrides.referenced_topics = keyValues.topic_id;
    issueWithOverrides.referenced_parameters = keyValues.parameter_id;
  }

  // handle reasons and override_reasons field
  // Here we should understand if the selected issueset has overridden rules. If it does then
  // reasons should not fall back to master/base.
  // 1. check at what level we have override rules
  const clientHasRulesChanges =
    overrideValues[0] && overrideValues[0].rules_changes;
  const masterHasRulesChanges =
    overrideValues[1] && overrideValues[1].rules_changes;

  // 2. base case, if no overrideRules found
  issueWithOverrides.reason = issue.reason;
  // 3. cases when client or master has overridden rules
  if (clientHasRulesChanges && issuesetClientId) {
    issueWithOverrides.reason = get(
      issue,
      ["override_reasons", issuesetMasterId, issuesetClientId, "reasons"],
      null,
    );
  } else if (masterHasRulesChanges) {
    issueWithOverrides.reason = get(
      issue,
      ["override_reasons", issuesetMasterId, "master", "reasons"],
      null,
    );
  }

  //
  // handle override_document_issue_values
  // values don't fall back to master/base values
  const documentIssueOverrides = get(
    issue,
    [
      "override_document_issue_values",
      issuesetMasterId,
      issuesetMasterId && !issuesetClientId ? "master" : issuesetClientId,
    ],
    {},
  );
  for (const field of documentIssueOverrideFields) {
    const {fieldName} = field;
    issueWithOverrides[fieldName] =
      documentIssueOverrides[fieldName] !== undefined
        ? documentIssueOverrides[fieldName]
        : field.defaultValue;
  }
  return {
    ...(omitIssueKeys ? {} : issue),
    ...issueWithOverrides,
  };
}

function getOverrideValue(issue, overrideMaps = [], key) {
  let result;
  overrideMaps.forEach(value => {
    if (result === undefined) {
      if (value && value[key] !== null && value[key] !== undefined) {
        result = value[key];
      }
    }
  });
  return result === null || result === undefined ? issue[key] : result;
}

export default getOverriddenIssue;

import _ from "underscore";
import getIssueOrder from "./get_issue_order";
import isIssueAlert from "./is_issue_alert";
import getIssueLevel from "./get_issue_level";
import shouldIgnoreIssue from "./should_ignore_issue";
/* eslint-disable max-statements */
/* eslint-disable complexity */

function eliminateSimilarIssues(issues, displayHiddenIssues = false) {
  if (!issues || issues.length < 2) {
    return issues;
  }

  // 1) distinct singular and bracket issues
  let singularIssues = [];
  const bracketIssues = {};
  issues.forEach(issue => {
    // apart from ignored if issue if not part of a collapsed [] group
    // and is of hidden class 4 we don't show it
    if (
      shouldIgnoreIssue(issue) ||
      (issue.issue_class_id === 4 && issue.name.indexOf("[") === -1)
    ) {
      // set it to be hidden, if ignored
      issue.shouldBeHidden = true;
    } else if (issue.shouldBeHidden) {
      issue.shouldBeHidden = undefined;
    }

    const bracketIndex = issue.name.indexOf("[");
    if (bracketIndex === -1) {
      singularIssues.push(issue);
      return;
    }

    let issueName = issue.name.substring(0, bracketIndex).trim();
    if (issueName.endsWith("/")) {
      issueName = issueName.substring(0, issueName.length - 1).trim();
    }
    if (!bracketIssues[issueName]) {
      bracketIssues[issueName] = [issue];
    } else {
      bracketIssues[issueName].push(issue);
    }
  });

  // 1.1) if singular issue name is the same as one of the bracket
  // parent issues names, then use it in grouping
  singularIssues = singularIssues.filter(singularIssue => {
    if (bracketIssues[singularIssue.name]) {
      bracketIssues[singularIssue.name].push(singularIssue);
      return false;
    }
    return true;
  });

  // 2) construct resulting issues from bracket issues
  const bracketResultingIssues = [];
  Object.keys(bracketIssues).forEach(parentIssueName => {
    let underlyingIssues = _.sortBy(
      bracketIssues[parentIssueName],
      issue => issue.name,
    );

    const parentIssue = findParentIssue(
      parentIssueName,
      underlyingIssues,
      displayHiddenIssues,
    );
    if (parentIssue) {
      // When issue is part of a [] collapsed group:
      // If isAlert then hide the issue and any children (i.e. subissues).
      // If not isAlert also do not show it, but show the rest of the [] group.
      // I.e.hidden issue is never shown
      // NOTE: this code does not have have any reference to subissues (!!! subissues
      // are not underlying issues). But when we add shouldBeHidden to Parent issue
      // we feed this issue further for subissues' construction and if this issue
      // becomes the Parent for subissues it will be hidden thus not shown
      underlyingIssues = underlyingIssues.map(issue => {
        if (issue.issue_class_id === 4) {
          return {...issue, shouldBeHidden: true};
        }
        return issue;
      });

      const shouldParentBeHidden = !underlyingIssues.find(
        issue => !issue.shouldBeHidden,
      );
      if (shouldParentBeHidden) {
        parentIssue.shouldBeHidden = true;
      }

      const parentIssueLevel = getIssueLevel(parentIssue);
      const topLevelUnderlyingIssues = underlyingIssues.filter(
        issue => getIssueLevel(issue) === parentIssueLevel,
      );
      const plainParentDisplayName = (
        parentIssue.display_name ?? parentIssue.name
      )
        .replace(/\[.*?\]\s*/, "")
        .trim();
      const childIssues = underlyingIssues
        .filter(
          issue =>
            (issue.display_name ?? issue.name).startsWith(
              parentIssue.display_name ?? parentIssue.name,
            ) &&
            (issue.display_name ?? issue.name) !==
              (parentIssue.display_name ?? parentIssue.name) &&
            getIssueLevel(issue) > parentIssueLevel,
        )
        .map(issue => {
          const name = (issue.display_name ?? issue.name).replace(
            parentIssue.display_name ?? parentIssue.name,
            plainParentDisplayName,
          );
          return {
            ...issue,
            name,
            display_name: name,
          };
        });
      parentIssue.display_name = plainParentDisplayName;
      parentIssue.underlyingIssues = topLevelUnderlyingIssues;
      bracketResultingIssues.push(...[parentIssue, ...childIssues]);
    }
  });

  // 3) merge singular and bracket issues
  return _.sortBy(
    [...singularIssues, ...bracketResultingIssues],
    issue => issue.name,
  )
    .map(issue =>
      !displayHiddenIssues && issue.issue_class_id === 4
        ? {...issue, shouldBeHidden: true}
        : issue,
    )
    .map(issue => ({
      ...issue,
      max_rag_score:
        issue.max_rag_score ??
        (issue.rag_score ? parseInt(issue.rag_score, 10) : 0),
    }));
}

const issueFieldsToUse = [
  "positive_reason",
  "detailed_positive_reason",
  "email_template",
  "guidance",
  "positive_guidance",
  "fallback_guidance",
  "about_description",
  "common_definitions",
];

function constructParentIssue(issues, parentIssueName) {
  let parentIssue = issues[0];
  let parentIssueOrder = getIssueOrder(parentIssue.issue_order);
  issues.forEach(currentIssue => {
    const newIssueFields = {};

    const currentIssueOrder = getIssueOrder(currentIssue.issue_order);
    if (currentIssueOrder.issueItemOrder < parentIssueOrder.issueItemOrder) {
      newIssueFields.issue_order = currentIssue.issue_order;
      parentIssueOrder = currentIssueOrder;
    }

    issueFieldsToUse.forEach(fieldKey => {
      if (!parentIssue[fieldKey] && currentIssue[fieldKey]) {
        newIssueFields[fieldKey] = currentIssue[fieldKey];
      }
    });

    if (Object.keys(newIssueFields).length > 0) {
      parentIssue = {
        ...parentIssue,
        ...newIssueFields,
      };
    }
  });
  return {...parentIssue, name: parentIssueName};
}

function findLowestOrderIssue(issues) {
  let resultIssue = issues[0];
  let resultIssueOrder = getIssueOrder(resultIssue.issue_order);
  issues.forEach(currentIssue => {
    const currentIssueOrder = getIssueOrder(currentIssue.issue_order);
    if (currentIssueOrder.issueItemOrder < resultIssueOrder.issueItemOrder) {
      resultIssue = currentIssue;
      resultIssueOrder = currentIssueOrder;
    }
  });
  return resultIssue;
}

function findParentIssue(
  parentIssueName,
  underlyingIssues,
  displayHiddenIssues,
) {
  const levels = underlyingIssues.reduce((_levels, issue) => {
    const level = getIssueLevel(issue);
    const list = _levels[level] ?? [];
    list.push(issue);
    _levels[level] = list;
    return _levels;
  }, {});
  const levelIssues = levels[1] ?? levels[2] ?? levels[3] ?? [];

  const maxRagScore = Math.max(
    ...levelIssues.map(issue =>
      issue.rag_score ? parseInt(issue.rag_score, 10) : 0,
    ),
  );

  // Parent issue is nonhidden alert issue with the lowest issue_order.
  // If there are no such issues we construct parent issue using nonalert
  // issues taking the lowest order and the first available positive reason
  const [alertIssues, nonAlertIssues] = levelIssues.reduce(
    (accum, underlyingIssue) => {
      if (!underlyingIssue.shouldBeHidden) {
        if (
          isIssueAlert({
            ...underlyingIssue,
            subissueData: {
              subissues: underlyingIssues.filter(issue =>
                (issue.display_name ?? issue.name).startsWith(
                  underlyingIssue.display_name,
                ),
              ),
            },
          })
        ) {
          accum[0].push(underlyingIssue);
        } else {
          accum[1].push(underlyingIssue);
        }
      }
      return accum;
    },
    [[], []],
  );

  // if among nonAlertIssues there are issues with prioritize_non_issue_mutex = true
  // use them;
  const prioritizedNonAlertIssuesNonHidden = nonAlertIssues.filter(
    issue =>
      issue.prioritize_non_issue_mutex &&
      (displayHiddenIssues || !issue.shouldBeHidden),
  );

  if (prioritizedNonAlertIssuesNonHidden.length > 0) {
    const lowestOrderIssue = findLowestOrderIssue(
      prioritizedNonAlertIssuesNonHidden,
    );
    return {
      ...lowestOrderIssue,
      name: parentIssueName,
      max_rag_score: maxRagScore,
    };
  }

  // Only show a hidden alert issue if there are no nonAlertIssues to show
  const showHiddenAlertIssue =
    displayHiddenIssues && nonAlertIssues.length === 0;
  if (alertIssues.length > 0) {
    const nonHiddenAlertIssues = alertIssues.filter(
      issue => showHiddenAlertIssue || !issue.shouldBeHidden,
    );
    if (nonHiddenAlertIssues.length !== 0) {
      const lowestOrderIssue = findLowestOrderIssue(nonHiddenAlertIssues);
      return {
        ...lowestOrderIssue,
        name: parentIssueName,
        max_rag_score: maxRagScore,
      };
    }
  }
  const visibleNonAlertIssues = nonAlertIssues.filter(
    issue => !issue.shouldBeHidden,
  );
  const potentialNonAlertIssues = nonAlertIssues.filter(
    issue => displayHiddenIssues || !issue.shouldBeHidden,
  );
  const selectedNonAlertIssues =
    visibleNonAlertIssues.length > 0
      ? visibleNonAlertIssues
      : potentialNonAlertIssues;
  if (selectedNonAlertIssues.length === 0) {
    // if only hidden issues
    const alertHiddenIssues = underlyingIssues.filter(
      issue =>
        (issue.issue_class_id === 1 || issue.issue_class_id === 3) &&
        isIssueAlert(issue),
    );

    const prioritisedNonAlertHiddenIssues = underlyingIssues.filter(
      issue => !isIssueAlert(issue) && issue.prioritize_non_issue_mutex,
    );

    const bestHiddenIssues = prioritisedNonAlertHiddenIssues.length
      ? prioritisedNonAlertHiddenIssues
      : alertHiddenIssues.length
      ? alertHiddenIssues
      : underlyingIssues;

    const bestHiddenIssue = findLowestOrderIssue(bestHiddenIssues);

    return {
      ...bestHiddenIssue,
      name: parentIssueName,
      max_rag_score: maxRagScore,
    };
  }

  // if among selectedNonAlertIssues there are issues with names
  // in brackets starting with [* or [ * and those issues have positive
  // reason the parent issue will be the one with the lowest order
  // or the first one available alphabetically if having the same order
  const positiveStarIssues = selectedNonAlertIssues.filter(issue => {
    const issueNameWithoutParentPrefix = issue.name.slice(
      parentIssueName.length,
    );
    return (
      /^\[\s*\*/.test(issueNameWithoutParentPrefix) &&
      issue.positive_reason &&
      issue.positive_reason.length &&
      issue.positive_reason.length > 0
    );
  });
  if (positiveStarIssues.length > 0) {
    const lowestOrderPositiveStarIssue = findLowestOrderIssue(
      positiveStarIssues,
    );
    return {
      ...lowestOrderPositiveStarIssue,
      name: parentIssueName,
      max_rag_score: maxRagScore,
    };
  }

  const triggerDisplayIssue = selectedNonAlertIssues.find(
    issue => !issue.trigger_display && issue.reason_template,
  );
  if (triggerDisplayIssue) {
    return {...triggerDisplayIssue, name: parentIssueName};
  }
  const nonAlertDisplayIssues = selectedNonAlertIssues.filter(
    issue => issue.trigger_display,
  );
  if (nonAlertDisplayIssues.length === 0) {
    return {...selectedNonAlertIssues[0], name: parentIssueName};
  }
  return {
    ...constructParentIssue(nonAlertDisplayIssues, parentIssueName),
    max_rag_score: maxRagScore,
  };
}

export default eliminateSimilarIssues;

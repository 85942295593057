import React from "react";

import ItemContainer from "../helper_components/item_container";

import ClausesRenderer from "utils/clauses/clauses_renderer";

function RelatedClauses(props) {
  const {relatedClauses} = props;
  if (!relatedClauses || Object.keys(relatedClauses).length === 0) {
    return null;
  }
  return (
    <ItemContainer
      title={`Related Clauses (${props.relatedClausesCount})`}
      expandable={true}
      onExpandMenu={props.onRelatedClausesOpen}
      isMenuExpanded={props.relatedClausesOpen}
      headerStyles={props.headerStyles}
      helpText="This section shows clauses in the document which provide further context"
      containerStyles={{
        flex: "1 0 auto",
      }}
    >
      <ClausesRenderer
        clauses={relatedClauses}
        onClauseClick={props.onClauseClick}
        topicsById={props.topicsById || {}}
        showTopicNamesAlways={true}
        documentHeadings={props.documentHeadings || []}
        documentSections={props.documentSections || []}
        documentDefinitions={props.documentDefinitions || []}
        documentChanges={props.documentChanges || []}
        document={props.document || {}}
        renderStatus={props.renderStatus}
        fileNames={props.fileNames}
        fileIndex={props.fileIndex}
      />
    </ItemContainer>
  );
}

export default RelatedClauses;

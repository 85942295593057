import React from "react";
import _ from "underscore";
import currencySymbols from "currency-symbol-map";

import AutoComplete from "material-ui/AutoComplete";
import ArrowDown from "material-ui/svg-icons/navigation/arrow-drop-down";
import MenuItem from "material-ui/MenuItem";
import SelectField from "material-ui/SelectField";
import TextField from "material-ui/TextField";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as colors from "material-ui/styles/colors";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  currencyContainer: {
    position: "relative",
    display: "flex",
    margin: "0 0.5rem",
    width: "6rem",
    marginLeft: "4px",
  },
  dropDownArrowIcon: {
    cursor: "pointer",
    color: colors.grey300,
    position: "absolute",
    right: "8px",
    top: "34px",
    height: "24px",
    width: "24px",
  },
  deleteIcon: {
    cursor: "pointer",
    color: colors.grey700,
  },
  symbolContainer: {
    width: "6rem",
    margin: "0 0.5rem",
  },
  valueContainer: {
    width: "7rem",
    margin: "0 0.5rem",
  },
  symbolSelectorLabel: {
    left: "0px",
  },
};

class ValueEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {currencyInput: this.props.value.currency};
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value.currency !== this.props.value.currency) {
      this.setState(() => ({currencyInput: this.props.value.currency}));
    }
  }

  render() {
    const {value} = this.props;
    const availableCurrencies = this.getCurrencyDataSource();
    return (
      <div style={styles.container}>
        <TextField
          type="number"
          value={value.value}
          onChange={this.onValueChange}
          floatingLabelText="Value"
          style={styles.valueContainer}
          min={0}
        />
        <div style={styles.currencyContainer}>
          <AutoComplete
            ref={this.createCurrencyRef}
            name="currency"
            floatingLabelText="Currency"
            filter={AutoComplete.caseInsensitiveFilter}
            searchText={this.state.currencyInput || ""}
            onFocus={this.onCurrencyFocus}
            onUpdateInput={this.onCurrencyInputUpdate}
            onNewRequest={this.onUpdateCurrency}
            dataSource={availableCurrencies}
            openOnFocus={true}
            underlineFocusStyle={{width: "0px"}}
            listStyle={{maxHeight: "20rem", overflowY: "auto", width: "5rem"}}
            style={{width: "6rem"}}
            textFieldStyle={{width: "6rem"}}
          />
          <ArrowDown
            style={styles.dropDownArrowIcon}
            onMouseDown={this.onCurrencyArrowClick}
          />
        </div>
        <SelectField
          floatingLabelText="Symbol"
          value={value.symbol}
          onChange={this.onSymbolChange}
          autoWidth={true}
          style={styles.symbolContainer}
          floatingLabelStyle={styles.symbolSelectorLabel}
          maxHeight={320}
          dropDownMenuProps={{
            anchorOrigin: {vertical: "bottom", horizontal: "left"},
          }}
        >
          {_.uniq(
            availableCurrencies.map(
              currency => currencySymbols.currencySymbolMap[currency],
            ),
          ).map((symbol, index) => (
            <MenuItem
              key={`${symbol}-${index}`}
              value={symbol}
              primaryText={symbol}
            />
          ))}
        </SelectField>
        <DeleteIcon
          onMouseDown={this.onDeleteValue}
          style={styles.deleteIcon}
        />
      </div>
    );
  }

  getCurrencyDataSource = () => {
    return _.uniq(
      ["USD", "EUR", "GBP", "CHF"].concat(
        _.sortBy(Object.keys(currencySymbols.currencySymbolMap), c => c),
      ),
    );
  };

  onCurrencyInputUpdate = currencyInput =>
    this.setState(() => ({currencyInput}));
  onCurrencyArrowClick = () => this.currencyRef.focus();
  onCurrencyFocus = () => this.setState(() => ({currencyInput: ""}));

  onUpdateCurrency = value => {
    this.props.onUpdateValue(
      {
        ...this.props.value,
        currency: value,
      },
      this.props.valueIndex,
    );
  };

  onSymbolChange = (e, i, value) => {
    this.props.onUpdateValue(
      {
        ...this.props.value,
        symbol: value,
      },
      this.props.valueIndex,
    );
  };

  onValueChange = e => {
    const {value} = e.target;
    this.props.onUpdateValue(
      {...this.props.value, value},
      this.props.valueIndex,
    );
  };

  onDeleteValue = this.props.onDeleteValue(this.props.valueIndex);
  createCurrencyRef = node => (this.currencyRef = node);
}

export default ValueEditor;

import React from "react";

import ExternalClauseLibraryButton from "../../../routes/document_detail/components/toolbar/buttons/external_clause_library";

import DraftingExamples from "../panels/drafting_examples";
import PredefinedComments from "../panels/predefined_comments";
import RelatedSearches from "../panels/related_searches";
import CorrectionSelector from "../panels/correction_selector";
import TemplateDocumentLinks from "../panels/template_document_links";

export default function render({
  issue,
  documentIssue,
  containerStyles,
  openTabs,
  onItemOpen,
  project,
  ...props
}) {
  const {hideDocumentRelatedItems} = props;

  const itemsProps = {
    issue,
    user: props.user,
    addIssueResponse: props.addIssueResponse,
    saveIssueResponse: props.saveIssueResponse,
    deleteIssueResponse: props.deleteIssueResponse,
    insertIssueResponseIntoDocument: props.insertIssueResponseIntoDocument,
    isFlipChecklistIcons: props.isFlipChecklistIcons,
    zoom: props.zoom,
    currentIssuesetItem: props.currentIssuesetItem,
  };

  const items = [
    <ExternalClauseLibraryButton
      key="external_clause_library"
      externalClauseLibrary={project.external_clause_library}
    />,
    <TemplateDocumentLinks
      key="template-doc-links"
      templateDocumentLink={project.template_document_link}
    />,
    <DraftingExamples key="drafting-examples" {...itemsProps} />,
    <PredefinedComments key="predefined-comments" {...itemsProps} />,
  ];

  if (
    documentIssue &&
    documentIssue.correction_settings &&
    documentIssue.reason
  ) {
    items.push(
      <CorrectionSelector
        key="corrections"
        documentIssue={documentIssue}
        onCorrectClick={props.onCorrectClick}
        onRevertClick={props.onRevertClick}
        onOpen={onItemOpen("correctionSelectorOpen")}
        isOpen={openTabs.correctionSelectorOpen}
        containerStyles={containerStyles}
        issue={documentIssue}
        {...props}
      />,
    );
  }
  if (!hideDocumentRelatedItems) {
    items.push(
      <RelatedSearches
        key="related-searches"
        containerStyles={containerStyles}
        onOpen={onItemOpen("relatedSearchesOpen")}
        isOpen={openTabs.relatedSearchesOpen}
        relatedSearches={issue.related_searches}
        triggerCustomEvent={props.triggerCustomEvent}
      />,
    );
  }
  return items;
}

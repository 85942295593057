import React, {useCallback, useState} from "react";
import _ from "underscore";
import {get} from "lodash";
import copy from "copy-to-clipboard";

import {withStyles} from "@material-ui/core/styles";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Button from "@material-ui/core/Button";

import PrecedentLanguageFilters from "./precedent_language_filters";
import PrecedentLanguageSort from "./precedent_language_sort";
import DocumentListItem from "./document_list_item";

import getClausepartsSubstitutionData from "./get_clauseparts_substitution_data";
import filterItemsAndUpdateFilters from "./filter_items_update_filters";

const CopyButton = withStyles({
  root: {width: "7em"},
})(Button);

const defaultFilterValues = {
  status: "all",
  parties: [],
};

const itemHasParty = (item, party) =>
  item.parties.some(({term, name}) => [term, name].includes(party));

const whatToSubstituteTypes = [
  {
    type: "default",
    label: "Default (Recognised terms)",
  },
  {
    type: "all",
    label: "All Capitalised Terms",
  },
  {
    type: "definitions_only",
    label: "Defined Terms Only",
  },
];

const sortByTypes = [
  {
    type: "best_match",
    label: "Best Match",
  },
  {
    type: "most_recent",
    label: "Most Recent",
  },
];

function PrecedentLanguageDocumentList(props) {
  const {items, showIntelligentDefinitions} = props;

  const [filters, updateFilters] = useState(defaultFilterValues);

  const [whatToSubstituteMap, updateWhatToSubstituteMap] = useState(
    getDefaultWhatToSubstituteMap(items),
  );

  function getWhatToSubsituteItemUpdater(id) {
    return function updateWhatToSubstitute(newValue) {
      updateWhatToSubstituteMap({
        ...whatToSubstituteMap,
        [id]: newValue,
      });
    };
  }

  const [availableFilters, setAvailableFilters] = useState([
    {
      type: "status",
      label: "RAG Status",
      items: [
        {type: "all", label: "All", handler: item => item},
        {
          type: "issues_only",
          label: "Not acceptable",
          handler: item => item.colorName === "red",
        },
        {
          type: "not_issues_only",
          label: "Acceptable",
          handler: item => item.colorName !== "red",
        },
      ],
    },
    {
      type: "parties",
      label: "Parties",
      items: Array.from(
        new Set(
          items.reduce(
            (acc, {parties}) =>
              acc.concat(
                parties
                  .map(party => [
                    party.name,
                    ...(party.term
                      ? party.term.split(",").map(term => term.trim())
                      : []),
                  ])
                  .flat(),
              ),
            [],
          ),
        ),
      )
        .map(party => ({
          type: party,
          label: party,
          handler: item => itemHasParty(item, party),
        }))
        .filter(party => party.label !== null)
        .sort((a, b) => a.label.localeCompare(b.label)),
      allowMultipleSelection: true,
    },
  ]);

  const {
    filterListWithResultsLengths,
    filteredItems,
  } = filterItemsAndUpdateFilters(items, availableFilters, filters);

  const itemHasPartySubstring = (item, partySubstring) =>
    item.parties.some(({term, name}) =>
      [term, name].some(
        partyName =>
          partyName &&
          partyName.toLowerCase().includes(partySubstring.toLowerCase()),
      ),
    );

  const [sortBy, onUpdateSortBy] = useState("best_match");

  const memoisedGetClausepartsSubstitutionData = useCallback(
    getClausepartsSubstitutionData,
    // WARN: Considering how many arguments this function has, this is an oddly
    // limited set of dependencies to invalidate the cache on.
    [getPrecedentTermsMemoObject(props.precedentTerms)],
  );

  // add substitution data to filtered items
  const enrichedFilteredItems = filteredItems.map(documentListItem => {
    const {
      clauseparts,
      definitions: itemDefinitions,
      linked_definitions: itemLinkedDefinitions,
      parties: itemParties,
      id: documentListItemId,
    } = documentListItem;

    const groupedClauseparts = groupClausepartsByReference(clauseparts);

    const baseArgs = [
      groupedClauseparts,
      itemDefinitions,
      props.documentDefinitions,
      itemLinkedDefinitions,
      props.definitionGroups || [],
      props.documentCapitalizedWords,
      props.precedentTerms,
      itemParties,
      props.documentParties,
      props.documentReferenceIndicator,
      props.referenceIndicatorGroup,
    ];

    const {
      substitutionMap,
      optionsMap,
    } = memoisedGetClausepartsSubstitutionData(
      ...baseArgs,
      whatToSubstituteMap[documentListItemId], // whatToSubstitute
    );

    const whatToSubstituteStats = constructWhatToSubstituteStats(
      whatToSubstituteMap[documentListItemId],
      baseArgs,
      substitutionMap,
    );

    const {activeSubstitutions, otherSubstitutions, noSubstitutions} = (
      Object.keys(substitutionMap) || []
    ).reduce(
      (result, foundTerm) => {
        const substitutionMapFoundTerm = substitutionMap[foundTerm];
        const value = get(
          substitutionMapFoundTerm,
          "userValue.value",
          get(substitutionMapFoundTerm, "value.value"),
        );

        if (value && foundTerm === value) {
          result.noSubstitutions.push(foundTerm);
        } else if (value) {
          result.activeSubstitutions.push(foundTerm);
        } else {
          result.otherSubstitutions.push(foundTerm);
        }
        return result;
      },
      {activeSubstitutions: [], otherSubstitutions: [], noSubstitutions: []},
    );

    const activeSubstitutionsLen = activeSubstitutions.length;
    const otherSubstitutionsLen = otherSubstitutions.length;
    const noSubstitutionsLen = noSubstitutions.length;
    return {
      ...documentListItem,
      clauseparts: groupedClauseparts,
      substitutionMap,
      optionsMap,
      activeSubstitutions,
      otherSubstitutions,
      noSubstitutions,
      activeSubstitutionsLen,
      otherSubstitutionsLen,
      noSubstitutionsLen,
      totalSubstitutionsLen:
        activeSubstitutionsLen + otherSubstitutionsLen + noSubstitutionsLen,
      whatToSubstituteStats,
      precedentDefinitions: itemDefinitions,
    };
  });

  const sortedItems = getSortedItems(enrichedFilteredItems, sortBy);

  const [shownItemsNumber, updateShownItemsNumber] = useState(5);

  function showFiveMoreItems() {
    updateShownItemsNumber(shownItemsNumber + 5);
  }

  const shownItems = sortedItems.slice(0, shownItemsNumber);

  const [isFiltersExpanded, updateIsFiltersExpanded] = useState(false);
  function triggerIsFiltersExpanded() {
    updateIsFiltersExpanded(!isFiltersExpanded);
  }

  const [isSortExpanded, updateIsSortExpanded] = useState(false);
  function triggerIsSortExpanded() {
    updateIsSortExpanded(!isSortExpanded);
  }

  const [selectedClauseparts, setSelectedClauseparts] = useState({
    id: null,
    items: {},
  });
  const selectedClausepartCount = Object.values(
    selectedClauseparts.items,
  ).filter(item => item).length;
  const areBothCollapsed = !isFiltersExpanded && !isSortExpanded;
  const showAll = Object.values(filters).every(selectedFilter =>
    Array.isArray(selectedFilter)
      ? selectedFilter.length === 0
      : selectedFilter === "all",
  );

  function copySelectedClausepartsToClipboard() {
    copy(selectedClauseparts.text, {format: "text/plain"});
  }

  const onAddFilterOption = (filterType, newOption) => {
    // Crudely unsupported due to awkward separation of concerns.
    // Only the parties filter has handlers that are trivially
    // derived from a given filter value.
    if (filterType !== "parties") {
      return;
    }

    const alreadyExists = availableFilters
      .find(filter => filter.type === filterType)
      ?.items.some(
        item =>
          item.type === newOption ||
          item.label.toLowerCase() === newOption.toLowerCase(),
      );

    if (alreadyExists) {
      return;
    }

    // Calculate resultsLength based on substring match
    const resultsLength = items.filter(documentListItem =>
      itemHasPartySubstring(documentListItem, newOption),
    ).length;

    setAvailableFilters(prev =>
      prev.map(filter => {
        if (filter.type === filterType) {
          const newItem = {
            type: newOption,
            label: newOption,
            handler: item => itemHasPartySubstring(item, newOption),
            resultsLength,
          };
          return {...filter, items: [...filter.items, newItem]};
        }
        return filter;
      }),
    );

    updateFilters(prev => ({
      ...prev,
      [filterType]: [...(prev[filterType] || []), newOption],
    }));
  };

  return (
    <div>
      <div>
        <div
          style={
            areBothCollapsed && showAll
              ? {display: "flex", justifyContent: "space-evenly"}
              : {}
          }
        >
          <PrecedentLanguageFilters
            filters={filters}
            filterList={filterListWithResultsLengths}
            updateFilters={updateFilters}
            defaultFilterValues={defaultFilterValues}
            isExpanded={isFiltersExpanded}
            triggerIsExpanded={triggerIsFiltersExpanded}
            onAddFilterOption={onAddFilterOption}
          />
          <PrecedentLanguageSort
            sortBy={sortBy}
            sortByTypes={sortByTypes}
            updateSortBy={onUpdateSortBy}
            isExpanded={isSortExpanded}
            triggerIsExpanded={triggerIsSortExpanded}
          />
        </div>
        {shownItems.map(shownItem => (
          <DocumentListItem
            showIntelligentDefinitions={showIntelligentDefinitions}
            key={shownItem.id}
            item={shownItem}
            definitionGroups={props.definitionGroups || []}
            hideDocumentRelatedItems={props.hideDocumentRelatedItems}
            documentDefinitions={props.documentDefinitions}
            documentCapitalizedWords={props.documentCapitalizedWords}
            precedentTerms={props.precedentTerms}
            onPrecedentTermUpdate={props.onPrecedentTermUpdate}
            isFlipChecklistIcons={props.isFlipChecklistIcons}
            whatToSubstitute={whatToSubstituteMap[shownItem.id]}
            updateWhatToSubstitute={getWhatToSubsituteItemUpdater(shownItem.id)}
            whatToSubstituteTypes={whatToSubstituteTypes}
            updateOtherDocument={props.updateOtherDocument}
            setSelectedClauseparts={setSelectedClauseparts}
            selectedClauseparts={selectedClauseparts}
            copySelectedClausepartsToClipboard={
              copySelectedClausepartsToClipboard
            }
          />
        ))}
        {shownItems.length === 0 ? (
          <div
            style={{
              padding: "14px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            No Search Results
          </div>
        ) : null}
        {shownItems.length < filteredItems.length ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            <MoreHorizIcon
              style={{cursor: "pointer"}}
              onClick={showFiveMoreItems}
            />
          </div>
        ) : null}
      </div>
      {selectedClausepartCount ? (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            zIndex: 100,
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
            padding: "0.75em 1em",
            boxShadow: "-4px -2px 8px #a3a3a3",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div>
              {selectedClausepartCount} Clause
              {selectedClausepartCount > 1 ? "s" : ""} Selected
            </div>
            <div
              style={{
                color: "#2196f3",
                fontSize: "0.8em",
                cursor: "pointer",
              }}
              onClick={() => setSelectedClauseparts({id: null, items: {}})}
            >
              Clear selection
            </div>
          </div>
          <div>
            <CopyButton
              variant="contained"
              color="primary"
              style={{width: "5rem"}}
              onClick={copySelectedClausepartsToClipboard}
            >
              Copy
            </CopyButton>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function getSortedItems(items, sortBy) {
  if (sortBy === "best_match") {
    const allFixed = [];
    const active = [];
    const other = [];
    const noSubstitutions = [];
    items.forEach(item => {
      if (item.activeSubstitutionsLen === item.totalSubstitutionsLen) {
        allFixed.push(item);
      } else if (item.activeSubstitutionsLen > 0) {
        active.push(item);
      } else if (item.otherSubstitutionsLen > 0) {
        other.push(item);
      } else {
        noSubstitutions.push(item);
      }
    });

    const sortedItems = [
      ...sortItems(allFixed, "totalSubstitutionsLen"),
      ...sortItems(active, "activeSubstitutionsLen"),
      ...sortItems(other, "otherSubstitutionsLen"),
      ...sortItems(noSubstitutions, "noSubstitutionsLen"),
    ];
    return _.sortBy(sortedItems, item => !item.holds_clause_templates);
  }
  return _.sortBy(items, item => -item.creation_date);
}

function getDefaultWhatToSubstituteMap(items) {
  return items.reduce((resultMap, item) => {
    resultMap[item.id] = "default";
    return resultMap;
  }, {});
}

function sortItems(items, sortField) {
  return _.chain(items)
    .sortBy(item => -item.creation_date)
    .sortBy(item => item[sortField])
    .value();
}

function constructWhatToSubstituteStats(
  currentWhatToSubstitute,
  baseArgs,
  currentSubstitutionMap,
) {
  const remainingWhatToSubstitute = whatToSubstituteTypes
    .map(item => item.type)
    .filter(type => type !== currentWhatToSubstitute);

  const result = {
    [currentWhatToSubstitute]: Object.keys(currentSubstitutionMap).length,
  };

  remainingWhatToSubstitute.forEach(type => {
    const {substitutionMap} = getClausepartsSubstitutionData(...baseArgs, type);
    result[type] = Object.keys(substitutionMap).length;
  });
  return result;
}

function groupClausepartsByReference(clauseparts = []) {
  const grouped = clauseparts.reduce((result, clausepart) => {
    if (!result[clausepart.reference]) {
      result[clausepart.reference] = [clausepart];
    } else {
      result[clausepart.reference].push(clausepart);
    }
    return result;
  }, {});
  const result = [];

  clauseparts.forEach(clausepart => {
    const group = grouped[clausepart.reference];
    if (group) {
      const firstItem = group[0];
      if (group.length === 1) {
        result.push(firstItem);
      } else {
        const newClausepart = {
          highlighted_text: group.map(item => item.highlighted_text).join("\n"),
          id: group.map(item => item.id).join("_"),
          underlyingClauseparts: group,
          ..._.pick(firstItem, ["clause_reference", "sort_field", "reference"]),
        };
        result.push(newClausepart);
      }
      delete grouped[clausepart.reference];
    }
  });
  return result;
}

function getPrecedentTermsMemoObject(precedentTerms) {
  const result = {};
  Object.keys(precedentTerms || {}).forEach(term => {
    result[term] = _.omit(precedentTerms[term], ["source"]);
  });
  return JSON.stringify(result);
}

export default PrecedentLanguageDocumentList;

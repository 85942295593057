import React from "react";
import Paper from "material-ui/Paper";

import getScrollSize from "utils/get_scroll_size";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import Report from "common_components/report";
import DocumentHeading from "./document_heading";
import DocumentDetailContext from "common_components/context/document_detail_context";

const styles = {
  root: {
    flexShrink: 0,
  },
  container: {
    margin: "0px auto",
    paddingTop: "1.5rem",
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
    transition: "padding 100ms ease",
  },
  paper: {
    borderRadius: 0,
  },
};

export default class DocumentTopPanel extends React.Component {
  static contextType = DocumentDetailContext;
  state = {
    scrollWidth: 0,
  };

  componentDidMount() {
    this.removeScrollUpdater = getScrollSize(this.updateScrollWidth);
  }

  updateScrollWidth = scrollWidth => {
    if (scrollWidth !== this.state.scrollWidth) {
      this.setState({scrollWidth});
    }
  };

  componentWillUnmount() {
    this.removeScrollUpdater();
  }

  render() {
    return (
      <div
        style={{
          ...styles.root,
          paddingRight: `${this.state.scrollWidth}px`,
        }}
      >
        <div
          style={{
            ...styles.container,
            ...(this.props.isScrolled && {
              paddingTop: 0,
            }),
            maxWidth: getZoomedFontSize(1072, "document", this.context.zoom),
          }}
        >
          <Paper style={styles.paper}>
            {this.renderReportPanel()}
            {this.renderHeading()}
          </Paper>
        </div>
      </div>
    );
  }

  renderReportPanel() {
    const {isReportPanelShown, selectedReportId} = this.props;
    if (!isReportPanelShown || !selectedReportId) {
      return null;
    }
    const reportSettings =
      this.props.document.report_settings.find(
        rep => rep.id === selectedReportId,
      ) ||
      this.props.project.report_settings.find(
        rep => rep.id === selectedReportId,
      ) ||
      {};

    return (
      <Report
        key={selectedReportId}
        reportSettings={reportSettings}
        user={this.props.user}
        project={this.props.project}
        document={this.props.document}
        documentClauses={this.props.documentClauses}
        documentHeadings={this.props.documentHeadings}
        documentClauseparts={this.props.documentClauseparts}
        topicCategories={this.props.topicCategories}
        documentSections={this.props.documentSections}
        topicsById={this.props.topicsById}
        issues={this.props.issues}
        positiveReasonData={this.props.positiveReasonData}
        groupedIssues={this.props.groupedIssues}
        documentIssuesManualUpdateCount={
          this.props.documentIssuesManualUpdateCount
        }
        updateDocumentIssue={this.props.updateDocumentIssue}
        revertIssueActionState={this.props.revertIssueActionState}
        updateDocument={this.props.updateDocument}
        showIssueDetail={this.props.showIssueDetail}
        updateIssueDetail={this.props.updateIssueDetail}
        showIssuesInChecklist={this.props.showIssuesInChecklist}
        scrollToClause={this.props.scrollToClause}
        editModeOn={this.props.editModeOn}
        areReportItemsNumbered={reportSettings.are_report_items_numbered}
        addAllItemsToReport={reportSettings.add_all_items_to_report}
        currentIssuesetItem={this.props.currentIssuesetItem}
        correctDocumentIssueManually={this.props.correctDocumentIssueManually}
        panelData={this.props.panelData}
        selectedReportId={selectedReportId}
        toggleReportPanelVisibility={this.props.toggleReportPanelVisibility}
        toggleReportOpenInRightSidebar={
          this.props.toggleReportOpenInRightSidebar
        }
        removeIssueFromReport={this.props.removeIssueFromReport}
        addIssueToReport={this.props.addIssueToReport}
        selectedChecklistSectionId={this.props.selectedChecklistSectionId}
        contractTypes={this.props.contractTypes}
        updateIssueReviewState={this.props.updateIssueReviewState}
        organisationId={this.props.organisationId}
        reviewTrackingOn={this.props.reviewTrackingOn}
        reports={this.props.reports}
      />
    );
  }

  renderHeading() {
    if (typeof this.props.editModeOn !== "boolean") {
      return null;
    }
    return (
      <DocumentHeading
        document={this.props.document}
        downloadDocument={this.props.downloadDocument}
        user={this.props.user}
        updateDocument={this.props.updateDocument}
        responseAddedToReport={this.props.responseAddedToReport}
        project={this.props.project}
        findAndReplace={this.props.findAndReplace}
        onFindAndReplaceChange={this.props.onFindAndReplaceChange}
        documentTextReplace={this.props.documentTextReplace}
        editModeOn={this.props.editModeOn}
        setEditModeOn={this.props.setEditModeOn}
        hideTextDeletions={this.props.hideTextDeletions}
        triggerHideTextDeletions={this.props.triggerHideTextDeletions}
        editableClausepart={this.props.editableClausepart}
        showAddClausepartEditor={this.props.showAddClausepartEditor}
        editableClauseId={this.props.editableClauseId}
        showAddClauseEditor={this.props.showAddClauseEditor}
        deleteClause={this.props.deleteClause}
        deleteClauseparts={this.props.deleteClauseparts}
        documentClauses={this.props.documentClauses}
        documentHeadings={this.props.documentHeadings}
        documentHasChanges={
          this.props.documentChanges && this.props.documentChanges.length > 0
        }
        onRevertDocumentChangesDialogShow={
          this.props.onRevertDocumentChangesDialogShow
        }
      />
    );
  }
}

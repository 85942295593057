import _ from "underscore";
import findDefinitionInstances from "./find_definition_instances";
import findQualifierInstances from "./find_qualifier_instances";
import findAlterationInstances from "./find_alteration_instances";

export default function(text, definitions, qualifiers, changes, totalOffset) {
  const definitionInstances = findDefinitionInstances(text, definitions);
  const qualifierInstances = findQualifierInstances(
    text,
    qualifiers,
    totalOffset,
  );
  const changeInstances = findAlterationInstances(changes, totalOffset || 0);
  return _.sortBy(
    [...definitionInstances, ...qualifierInstances, ...changeInstances],
    "start",
  );
}

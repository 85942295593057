let globalStore = {};

export function reset() {
  globalStore = {};
}

export default globalStore;

// TESTING
// const gsDummy = {
//   commonDefinitions: {
//     890: {
//       zzz: [1, 2, 3],
//       group_xxx_some: [69884, 69882, 33, 12, 14],
//       group_yyy_some: [69884, 12, 16, 19],
//     },
//     900: {
//       zzz: [1, 2, 3],
//       group_mmm_some: [69884, 33, 12, 14],
//       group_nnn_some: [69913, 69869, 33, 12, 14],
//     }
//   }
// };

// export default gsDummy;

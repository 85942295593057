import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import searchAtomsInDocx from "../utils/docx_json/search_atoms";
import DocxContext from "./docx_context";
import Paragraph from "./paragraph";

const useStyles = makeStyles({
  document: {},
  missingClauses: {
    marginBottom: "1em",
  },
  clause: {
    margin: "1em auto",
  },
  clauseText: {color: "red"},
});

export default function DocxComponent({docxJson, document}) {
  const classes = useStyles();

  if (!docxJson) {
    return null;
  }

  const {
    "word/document.xml": documentJson,
    "word/numbering.xml": numberingJson,
    "word/styles.xml": stylesJson,
  } = docxJson;

  if (documentJson) {
    const {p} = documentJson.document.body;
    if (p) {
      const {paragraphs, intersections, notFoundAtoms} = searchAtomsInDocx(
        p,
        document.clauses,
      );
      const styles = valueOrNull(stylesJson, "styles");
      const numbering = valueOrNull(numberingJson, "numbering");

      return (
        <DocxContext.Provider value={{styles, intersections}}>
          <div className={classes.missingClauses}>
            {notFoundAtoms.map(({text}, index) => (
              <div className={classes.clause} key={index}>
                <span className={classes.clauseText}>{text}</span>
              </div>
            ))}
          </div>
          <div className={classes.document}>
            {paragraphs.map((paragraph, index) => (
              <Paragraph key={index} {...paragraph} numbering={numbering} />
            ))}
          </div>
        </DocxContext.Provider>
      );
    }
  }
  return null;
}

function valueOrNull(object, fieldName) {
  if (object && fieldName && object[fieldName]) {
    return object[fieldName];
  }
  return null;
}

// localStorage can be unavailable in some cases, e.g. safari incognito mode

const localStorage = {
  getItem: key => {
    try {
      return window.localStorage.getItem(key);
    } catch {
      // should throw error or something if there's no access to localStorage
    }
    return null;
  },
  setItem: (key, value) => {
    try {
      window.localStorage.setItem(key, value);
    } catch {
      // should throw error or something if there's no access to localStorage
    }
  },
  removeItem: key => {
    try {
      window.localStorage.removeItem(key);
    } catch {
      // should throw error or something if there's no access to localStorage
    }
  },
  clear: () => {
    try {
      window.localStorage.clear();
    } catch {
      // should throw error or something if there's no access to localStorage
    }
  },
};

export default localStorage;

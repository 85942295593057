import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function definitionGroupsFetch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DEFINITION_GROUPS_FETCH,
    organisationId =>
      requestor
        .get(`${basePath}/organisation/${organisationId}/definition_group`, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        }),
  );
}

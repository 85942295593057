import React from "react";
import _ from "lodash";

import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Avatar from "@material-ui/core/Avatar";
import * as colors from "material-ui/styles/colors";

const styles = {
  blockContainer: {
    padding: "4px 12px",
  },
  label: {
    fontSize: 10,
    fontWeight: 600,
    marginBottom: 4,
    marginTop: 5,
  },
  button: {
    borderRadius: 0,
    width: "47%",
    boxShadow: "none",
  },
};

function TextWithDropdown(props) {
  const {matchedTerm} = props;
  const meaning = (props.precedentDefinition || {}).meaning;

  const [filterString, updateFilterString] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = e => {
    if (e) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (
      e &&
      anchorRef &&
      anchorRef.current &&
      anchorRef.current.contains(e.target)
    ) {
      return;
    }
    updateFilterString("");
    setOpen(false);
    updateSelectedOption(null);
  };

  const updateValue = value => {
    props.updateValueFromText(matchedTerm, value);
  };

  const onOptionsListScroll = e => e.stopPropagation();

  const [inputString, updateInputString] = React.useState(props.text);
  const onInputStringChange = e => {
    updateInputString(e.target.value);
    updateFilterString(e.target.value);
  };

  const handleOpenToggle = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(() => !open);
  };

  React.useEffect(
    () => {
      if (open) {
        updateInputString(props.text);
      }
    },
    [open],
  );

  const [isMeaningShown, updateIsMeaningShown] = React.useState(false);
  const triggerIsMeaningShown = () => updateIsMeaningShown(!isMeaningShown);

  const onInputKeyDown = e => {
    if (e.key === "Enter") {
      handleClose();
      updateValue(inputString);
    } else {
      updateSelectedOption(null);
    }
  };

  const stopPropagationHandler = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const inInputFocus = e => e.target.select();

  const onSubstitutionClear = () => {
    props.clearUserSubstitution(props.matchedTerm);
    handleClose();
  };

  const [selectedOption, updateSelectedOption] = React.useState(null);

  const onOptionSelect = option => () => {
    updateSelectedOption(option);
    updateInputString(option);
  };

  const onReplace = () => {
    if (selectedOption) {
      updateValue(selectedOption);
      handleClose();
    } else if (inputString && inputString !== props.text) {
      updateValue(inputString);
      handleClose();
    }
  };

  const ExpandIcon = isMeaningShown ? ExpandLessIcon : ExpandMoreIcon;

  const renderMeaning = () => {
    return (
      <div
        style={{
          overflow: "auto",
          fontSize: 12,
          padding: "5px 0px 5px 0px",
          height: "95%",
        }}
      >
        {meaning}
      </div>
    );
  };

  const renderOptions = () => {
    if (meaning && isMeaningShown) {
      return null;
    }
    const baseOptions = _.sortBy(props.options, item =>
      item.value.toLowerCase(),
    );

    const options = filterString
      ? baseOptions.filter(
          option =>
            option.value.toLowerCase().indexOf(filterString.toLowerCase()) !==
            -1,
        )
      : baseOptions;

    return (
      <div style={{height: "100%"}}>
        <div style={{...styles.label}}>Terms found in your document</div>
        <div style={{overflow: "auto", height: "90%"}}>
          <MenuList
            style={{
              overflow: "auto",
              paddingTop: 0,
            }}
          >
            {options.map(option => (
              <MenuItem
                key={option.value}
                style={{
                  padding: "1px 6px",
                  marginRight: 4,
                  border:
                    option.value === selectedOption
                      ? "1px solid #42A5F5"
                      : "1px solid #fff",
                  background:
                    option.value === selectedOption ? "#f5f5f5" : "none",
                }}
                onClick={onOptionSelect(option.value)}
              >
                <div style={{display: "flex"}}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: 280,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontSize: 14,
                      }}
                    >
                      <span title={option.value}>{option.value}</span>
                      {option.origin === "definition" && (
                        <>
                          &nbsp;
                          <Avatar
                            style={{
                              fontWeight: 600,
                              height: "18px",
                              width: "18px",
                              backgroundColor: colors.blue400,
                              display: "inline-flex",
                              fontSize: "14px",
                              marginLeft: "1px",
                            }}
                            title="Defined Term in your document"
                          >
                            D
                          </Avatar>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </div>
      </div>
    );
  };

  return (
    <span>
      <span
        ref={anchorRef}
        className="substitutable-term"
        onClick={handleOpenToggle}
        style={{background: props.isItemSubstituted ? "#bbdefb" : "#e0e0e0"}}
      >
        {props.text}
      </span>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{style: {borderRadius: 0}}}
      >
        <div
          onScroll={onOptionsListScroll}
          style={{maxWidth: 220, display: "flex", flexDirection: "column"}}
          onClick={stopPropagationHandler}
        >
          <div
            style={{
              ...styles.blockContainer,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#f5f5f5",
            }}
          >
            <div style={{display: "flex", flexDirection: "column"}}>
              <div style={{...styles.label}}>Select replacement term for</div>
              <div style={{fontSize: 14, marginBottom: 3}}>
                {props.matchedTerm}
              </div>
            </div>
            {meaning && (
              <ExpandIcon
                style={{
                  width: 22,
                  height: 22,
                  color: "#42A5F5",
                  cursor: "pointer",
                }}
                onClick={triggerIsMeaningShown}
              />
            )}
          </div>

          <div
            style={{
              ...styles.blockContainer,
              paddingRight: 5,
              height: 200,
            }}
          >
            {isMeaningShown ? renderMeaning() : renderOptions()}
          </div>

          <div
            style={{
              ...styles.blockContainer,
              borderTop: "1px solid #e0e0e0",
              borderBottom: "1px solid #e0e0e0",
              paddingBottom: 9,
            }}
          >
            <div style={{...styles.label, marginBottom: 6}}>
              Replacement Text
            </div>
            <TextField
              value={inputString}
              onFocus={inInputFocus}
              autoFocus={true}
              onChange={onInputStringChange}
              fullWidth
              variant="outlined"
              inputProps={{style: {padding: 0}}}
              InputProps={{
                style: {fontSize: 14, borderRadius: 0, padding: "6px 10px"},
                onKeyDown: onInputKeyDown,
              }}
              onClick={stopPropagationHandler}
            />
          </div>

          <div
            style={{
              ...styles.blockContainer,
              display: "flex",
              justifyContent: "space-between",
              paddingTop: 8,
              paddingBottom: 8,
            }}
          >
            <Button
              variant="contained"
              style={styles.button}
              size="small"
              disabled={!props.shouldShowClear}
              onClick={onSubstitutionClear}
            >
              Revert
            </Button>
            <Button
              variant="contained"
              style={styles.button}
              color="primary"
              size="small"
              onClick={onReplace}
              disabled={
                !selectedOption && !(inputString && inputString !== props.text)
              }
            >
              Replace
            </Button>
          </div>
        </div>
      </Popover>
    </span>
  );
}

export default TextWithDropdown;

export default function limitTextLines(text, maxCharacters) {
  if (maxCharacters && maxCharacters < text.length) {
    let pos = maxCharacters;
    while (pos > 0 && !/\s/.test(text.charAt(pos))) {
      pos -= 1;
    }
    if (pos > 0) {
      return `${text.substring(0, pos).trim()}...`;
    }
  }
  return text;
}

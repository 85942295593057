export default function(props) {
  const {clause, reference, renderPath, index} = props;
  const type = clause.type.substring(clause.type.length - 4);
  const id = clause.id || clause.clauseId || reference;
  return [
    renderPath || "",
    id,
    type,
    clause.level,
    clause.listOrder,
    ...(index ? [index] : []),
    ...(clause.clausePartNumber ? [clause.clausePartNumber] : []),
  ].join("_");
}

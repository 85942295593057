import _ from "lodash";

function filterIsArchivedIssuesets(issuesetsById) {
  const result = {};
  Object.values(issuesetsById)
    .filter(issueset => !issueset.is_archived)
    .forEach(issueset => {
      result[issueset.id] = {
        ...issueset,
      };
    });

  return result;
}

function getIssuesetsById(contractTypesById, ctIdToUse, filterIsArchived) {
  const result = {};
  Object.keys(contractTypesById)
    .map(ctId => parseInt(ctId, 10))
    .filter(ctId => {
      if (ctIdToUse) {
        return ctId === ctIdToUse;
      }
      return true;
    })
    .forEach(ctId => {
      const contractType = contractTypesById[ctId];
      contractType.issuesets.forEach(is => {
        result[is.id] = {
          ...is,
          contract_type_name: contractType.name,
          contract_type_last_edited: contractType.last_edited,
        };
      });
    });
  return filterIsArchived ? filterIsArchivedIssuesets(result) : result;
}

function getIssuesetIdsPresentInContractTypes(contractTypes) {
  return contractTypes.reduce((result, contractType) => {
    contractType.issuesets.forEach(is => {
      result.push(is.id);
    });
    return result;
  }, []);
}

function getIssuesetsPresentInContractTypes(contractTypes) {
  return contractTypes.reduce((result, contractType) => {
    (contractType.issuesets || []).forEach(is => {
      result.push(is);
    });
    return result;
  }, []);
}

function constructContractTypesByIssuesets(issuesetIds, issueSetsById) {
  const contractTypesById = issuesetIds.reduce((acc, issuesetId) => {
    const issueset = issueSetsById[issuesetId];
    if (!acc[issueset.contract_type_id]) {
      acc[issueset.contract_type_id] = {
        contract_type_id: issueset.contract_type_id,
        name: issueset.contract_type_name,
        last_edited: issueset.contract_type_last_edited,
        issuesets: [issueset],
      };
    } else {
      acc[issueset.contract_type_id].issuesets.push(issueset);
    }
    return acc;
  }, {});
  return Object.keys(contractTypesById).map(ctId => contractTypesById[ctId]);
}

function constructIssuesetSelectorItems(
  issuesetIds,
  issuesetsById,
  hideCtName,
) {
  return _.chain(issuesetIds)
    .filter(id => issuesetsById[id] && !issuesetsById[id].is_archived)
    .map(id => {
      const issueset = issuesetsById[id];
      return {id, name: constructIssuesetName(issueset, hideCtName)};
    })
    .sortBy(is => is.name)
    .value();
}

function constructIssuesetName(issueset, hideCtName) {
  if (issueset.is_duplicate_on_master === true) {
    return (
      `${issueset.master_issueset_name} - ` +
      `${issueset.remote_client_name}: ` +
      `"${issueset.client_issueset_name}"`
    );
  }
  return hideCtName
    ? issueset.name.trim()
    : `${issueset.contract_type_name.trim()} - ${issueset.name.trim()}`;
}

function constructGroupedIssuesetSelectorItems(
  issuesetIds,
  issuesetsById,
  showContractTypeName,
) {
  const contractTypesById = {};
  issuesetIds
    .filter(id => issuesetsById[id])
    .forEach(id => {
      const issueset = issuesetsById[id];
      if (!_.has(contractTypesById, issueset.contract_type_id)) {
        contractTypesById[issueset.contract_type_id] = {
          name: issueset.contract_type_name,
          masterIssuesetsById: {},
        };
      }
      const contractType = contractTypesById[issueset.contract_type_id];
      const {masterIssuesetsById} = contractType;
      if (issueset.is_duplicate_on_master === false) {
        if (!_.has(masterIssuesetsById, issueset.id)) {
          masterIssuesetsById[issueset.id] = {
            name: issueset.name,
            clientIssuesetsById: {},
          };
        }
      } else {
        if (!_.has(masterIssuesetsById, issueset.master_issueset_id)) {
          masterIssuesetsById[issueset.master_issueset_id] = {
            name: issueset.master_issueset_name,
            clientIssuesetsById: {},
          };
        }
        const masterIssueset = masterIssuesetsById[issueset.master_issueset_id];
        const {clientIssuesetsById} = masterIssueset;
        if (!_.has(clientIssuesetsById, issueset.id)) {
          clientIssuesetsById[issueset.id] = {
            name: issueset.client_issueset_name,
            remoteClientName: issueset.remote_client_name,
          };
        }
      }
    });

  const masterLevel = showContractTypeName ? 1 : 0;
  const clientLevel = masterLevel + 1;
  const items = [];
  _.forEach(contractTypesById, (contractType, contractTypeIdStr) => {
    const {name: contractTypeName, masterIssuesetsById} = contractType;
    const contractTypeId = parseInt(contractTypeIdStr, 10);
    if (showContractTypeName) {
      items.push({
        value: null,
        primaryText: contractTypeName,
        level: 0,
        disabled: true,
        contract_type_id: contractTypeId,
      });
    }
    _.forEach(masterIssuesetsById, (masterIssueset, idStr) => {
      const {name: masterName, clientIssuesetsById} = masterIssueset;
      items.push({
        value: parseInt(idStr, 10),
        primaryText: masterName,
        label: `${contractTypeName} - ${masterName}`,
        level: masterLevel,
        disabled: false,
        contract_type_id: contractTypeId,
        is_hot: issuesetsById[idStr] && issuesetsById[idStr].is_hot,
        is_star: issuesetsById[idStr] && issuesetsById[idStr].is_star,
      });
      _.forEach(clientIssuesetsById, (clientIssueset, idString) => {
        const {remoteClientName, name: clientName} = clientIssueset;
        const primaryText = `${remoteClientName}: "${clientName}"`;
        const label = `${contractTypeName} - ${masterName} - ${primaryText}`;
        items.push({
          value: parseInt(idString, 10),
          primaryText,
          label,
          level: clientLevel,
          disabled: false,
          contract_type_id: contractTypeId,
          is_hot: issuesetsById[idString] && issuesetsById[idString].is_hot,
          is_star: issuesetsById[idString] && issuesetsById[idString].is_star,
        });
      });
    });
  });
  return items;
}

function filterContractTypesByIssuesetIds(contractTypes, issuesetIds) {
  const result = {};
  contractTypes.forEach(ct => {
    ct.issuesets.forEach(is => {
      if (issuesetIds.includes(is.id)) {
        if (!result[ct.contract_type_id]) {
          result[ct.contract_type_id] = {
            ...ct,
            issuesets: [is],
          };
        } else {
          result[ct.contract_type_id].issuesets.push(is);
        }
      }
    });
  });
  return Object.keys(result).map(ctId => result[ctId]);
}

function getOnlyClientIssuesets(contractTypes, issuesetsById) {
  return contractTypes.reduce((result, contractType) => {
    const newIssuesets = (contractType.issuesets || []).filter(issueset => {
      const issuesetToUse = issuesetsById[issueset.id];
      return issuesetToUse.remote_client_id !== null;
    });
    if (newIssuesets.length > 0) {
      result.push({
        ...contractType,
        issuesets: newIssuesets,
      });
    }
    return result;
  }, []);
}

function filterOutClientIssuesetFromContractTypes(
  contractTypes,
  issuesetsById,
) {
  return contractTypes.reduce((result, contractType) => {
    const newIssuesets = (contractType.issuesets || []).filter(issueset => {
      const issuesetToUse = issuesetsById[issueset.id];
      return issuesetToUse && !issuesetToUse.remote_client_id;
    });
    if (newIssuesets.length > 0) {
      result.push({
        ...contractType,
        issuesets: newIssuesets,
      });
    }
    return result;
  }, []);
}

export default {
  filterIsArchivedIssuesets,
  getIssuesetsById,
  getIssuesetIdsPresentInContractTypes,
  getIssuesetsPresentInContractTypes,
  constructContractTypesByIssuesets,
  constructIssuesetSelectorItems,
  constructGroupedIssuesetSelectorItems,
  constructIssuesetName,
  filterContractTypesByIssuesetIds,
  getOnlyClientIssuesets,
  filterOutClientIssuesetFromContractTypes,
};

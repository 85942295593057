import React from "react";
import ReactDOM from "react-dom";
import _ from "underscore";
import * as colors from "material-ui/styles/colors";

import VerticalClausepartControl from "../common_components/vertical_clausepart_control";
import {isIe11} from "utils/browser_test";
import * as PossibleUpcomingEventCatcher from "utils/possible_upcoming_event_catcher";
import HeadingEditor from "./heading_editor";
import formatText from "utils/text/format_text";
import increaseReference from "common/utils/increase_clause_reference";
import {isElementEntirelyInViewport} from "../../../utils/is_visible";
import generateHeadingContent from "./generate_heading_content";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import DocumentDetailContext from "common_components/context/document_detail_context";

const styles = {
  clauseHeading: {
    ...(isIe11()
      ? {
          textAlign: "center",
        }
      : {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }),
    textAlign: "center",
    cursor: "pointer",
    overflow: "hidden",
  },
  stickyHeading: {
    position: "absolute",
    visibility: "visible",
    top: 0,
    zIndex: 100,
    boxSizing: "border-box",
    paddingRight: "15px",
  },
  clauseMenu: {
    alignSelf: "flex-end",
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: "3px",
    backgroundColor: "#fff",
  },
  reference: {
    display: "inline-block",
    minWidth: "1.9em",
    textAlign: "left",
    paddingRight: "0.5em",
  },
  text: {
    flexGrow: 1,
    textAlign: "left",
  },
  hidden: {visibility: "hidden"},
  visible: {visibility: "visible"},
  clauseAddition: {
    color: colors.lightBlue600,
  },
};

function isVisible(headingVisibility, index) {
  return (
    headingVisibility &&
    headingVisibility[index] &&
    headingVisibility[index].isVisible
  );
}
class ClauseHeading extends React.Component {
  static contextType = DocumentDetailContext;
  state = {
    stickHeading: false,
  };

  render() {
    const {reference, heading, documentHasHeadings, clause} = this.props;
    const stickHeading = this.state.stickHeading;

    const showClauseNumbers = !this.props.section.is_unnumbered;
    if (
      (heading && heading.id) ||
      (showClauseNumbers &&
        (documentHasHeadings || this.props.doesHaveEditableClause))
    ) {
      return (
        <div
          key={`clause-heading-${reference}`}
          className={`clause-heading r${reference.replace(
            /\./g,
            "_",
          )} clause-id`}
          clauseid={`HEADER_${clause?.id}`}
        >
          {this.renderContent({
            ...(stickHeading ? styles.hidden : styles.visible),
          })}
          {stickHeading &&
            this.renderContent(
              {...(stickHeading ? styles.stickyHeading : styles.hidden)},
              "second",
            )}
        </div>
      );
    }
    return null;
  }

  renderContent(style, key = "first") {
    const {
      reference,
      isHidden,
      clauseVisible,
      showClausesTopicsSelectorValue,
      hideTopics,
      doesHaveEditableClause,
      doesHaveEditableClauseHeading,
    } = this.props;
    return (
      <div style={style} key={`r${reference}-${key}`}>
        {this.renderBanner(key)}
        {!hideTopics &&
        (isHidden || clauseVisible) &&
        showClausesTopicsSelectorValue !== "none" &&
        !doesHaveEditableClause &&
        !doesHaveEditableClauseHeading
          ? generateHeadingContent(this.props, showClausesTopicsSelectorValue)
          : null}
      </div>
    );
  }

  componentDidMount() {
    this.updateVisibility();
  }

  componentDidUpdate() {
    this.updateVisibility();
  }

  updateVisibility() {
    const stickHeading = this.shouldShowHeading();
    if (stickHeading !== this.state.stickHeading) {
      this.setState(() => ({
        stickHeading,
      }));
    }
  }

  shouldShowHeading() {
    const {
      fullReference,
      headingVisibility,
      viewIndex,
      showClausesTopicsSelectorValue,
    } = this.props;
    if (showClausesTopicsSelectorValue === "none" || !fullReference) {
      return false;
    }
    const ref =
      fullReference.indexOf(".") > 0
        ? headingVisibility[fullReference] ? fullReference : "xxx"
        : fullReference;

    const isHeadingVisible = isVisible(
      headingVisibility[fullReference],
      viewIndex,
    );
    let isFirst = false;
    if (isHeadingVisible) {
      const first = _.chain(Object.keys(headingVisibility))
        .filter(
          ref =>
            ref !== "undefined" && isVisible(headingVisibility[ref], viewIndex),
        )
        .sortBy(ref => parseInt(ref, 10))
        .first()
        .value();
      isFirst = ref === first;
    }

    let showHeader = false;
    if (isFirst) {
      if (!this.isEntirelyVisible()) {
        showHeader = true;
      }
    }
    return showHeader;
  }

  isEntirelyVisible() {
    const node = ReactDOM.findDOMNode(this);
    return isElementEntirelyInViewport(node, 0, this.findParentScroller);
  }

  findParentScroller(target) {
    return (
      target.className &&
      target.className
        .split(" ")
        .find(className => className === "view-scroller")
    );
  }

  renderBanner(key) {
    const {
      doesHaveEditableClause,
      doesHaveEditableClauseHeading,
      showHeadingReferenceAsIncreased,
      isHidden,
      reference,
      clauseAdditionChange,
      heading,
      onNumberItemHoverFinish,
      isNumberItemsHovered,
      onAddClauseToReport,
    } = this.props;

    if (
      (!heading.text && !doesHaveEditableClause) ||
      !this.props.documentHasHeadings
    ) {
      return null;
    }

    const isHeadingHidden = Boolean(this.props.hiddenHeadings[reference]);
    const clauseMenu = this.getClauseMenuElement(
      this.props.hasEditDocumentTextPermission &&
        this.props.editModeOn &&
        (this.props.isClauseHeadingHovered ||
          this.props.isAddedClauseHovered) &&
        !isHeadingHidden &&
        !this.props.editableClausepart,
    );

    let shownReference = reference
      .replace(/^[A-Za-z]{2,}/, "$& ")
      .replace(/_dup\d+$/, "");
    if (showHeadingReferenceAsIncreased) {
      shownReference = increaseReference(shownReference);
    }
    const isEditable = doesHaveEditableClause || doesHaveEditableClauseHeading;
    const shouldEditHeadings =
      this.props.section.reference.indexOf("background") === -1 &&
      this.props.section.reference.indexOf("parties") === -1 &&
      this.props.section.reference.indexOf("execution") === -1;
    return (
      <div
        className="heading"
        id={`clause-heading-${heading.reference}-${heading.text}`}
        style={{
          ...styles.clauseHeading,
          ...(isEditable
            ? {
                textAlign: "left",
              }
            : {
                paddingTop: "0.5em",
                paddingBottom: "0.5em",
              }),
          fontSize: getZoomedFontSize(19, "document", this.context.zoom),
        }}
        onMouseUp={
          doesHaveEditableClause || !isHidden
            ? undefined
            : this.onToggleClauseHeadingOpen(reference)
        }
        onMouseEnter={this.props.clauseHeadingHoverStart}
        onMouseLeave={this.props.clauseHeadingHoverFinish}
        key={`${reference}-${key}`}
      >
        {clauseMenu}
        <div style={{display: "flex", flexGrow: 1}}>
          {shouldEditHeadings &&
            !this.props.section.is_unnumbered && (
              <span
                className="reference"
                style={{
                  ...styles.reference,
                  ...(isIe11() &&
                    isEditable && {float: "left", marginTop: ".5em"}),
                  ...(clauseAdditionChange ? styles.clauseAddition : {}),
                }}
                onMouseEnter={this.onNumberHover}
                onMouseLeave={onNumberItemHoverFinish}
                onClick={() => onAddClauseToReport(isNumberItemsHovered)}
              >
                {shownReference}
                <span className="dot">.</span>
              </span>
            )}
          <span
            className="text"
            style={{
              ...styles.text,
              ...(isIe11() && isEditable && {float: "left"}),
            }}
          >
            {!isEditable || !shouldEditHeadings
              ? this.renderStatic()
              : this.renderEditor(shouldEditHeadings)}
          </span>
        </div>
      </div>
    );
  }

  renderStatic() {
    const {
      documentDefinitions,
      editModeOn,
      isClauseDeletion,
      clauseAdditionChange,
    } = this.props;
    const changes = getHeadingChanges(this.props);
    const formattedText = formatText(
      this.props.document.file_extension,
      this.props.heading.text || "",
      {changes, documentDefinitions},
      this.props.findAndReplace.find,
    );
    if (isClauseDeletion) {
      return <div style={{cursor: "auto"}}>{formattedText}</div>;
    }
    return (
      <div
        onMouseDown={event => {
          if (event.detail > 1) {
            event.preventDefault();
          }
        }}
        onDoubleClick={
          this.props.doesHaveEditableClause || editModeOn
            ? undefined
            : this.onHeadingEdit
        }
        onClick={event => {
          if (editModeOn) {
            // Prevent a text selection in the heading editor
            PossibleUpcomingEventCatcher.preventDefault("mousedown");
            this.onHeadingEdit(event);
          }
        }}
        style={{...(clauseAdditionChange ? styles.clauseAddition : {})}}
      >
        {formattedText}
      </div>
    );
  }

  renderEditor(shouldEditHeadings) {
    if (!this.props.hasDocumentFilledHeadings || !shouldEditHeadings) {
      return null;
    }
    return (
      <HeadingEditor
        changes={getHeadingChanges(this.props)}
        doesHaveEditableClauseHeading={this.props.doesHaveEditableClauseHeading}
        heading={this.props.heading}
        isAdded={isHeadingAdded(this.props)}
        isDeleted={isHeadingDeleted(this.props)}
        onAdd={this.props.addDocumentHeading}
        onAlter={this.props.alterDocumentHeading}
        onDelete={this.props.deleteDocumentHeading}
        onRevert={this.props.revertDocumentHeadingChanges}
        reference={this.props.reference}
        section={this.props.section}
        unsetEditableClauseHeading={this.props.unsetEditableClauseHeading}
      />
    );
  }

  getClauseMenuElement = showClauseMenu => {
    if (!showClauseMenu) {
      return null;
    }
    const {props} = this;
    const {documentClause} = props;
    const shouldRevert = props.isClauseDeletion || props.clauseAdditionChange;
    return (
      <VerticalClausepartControl
        itemName="clause"
        shouldRevert={shouldRevert}
        showAddButtons={true}
        onTopButtonClick={
          shouldRevert
            ? props.revertClause(documentClause.section_id, documentClause.id)
            : props.deleteClause(documentClause.section_id, documentClause.id)
        }
        onTopButtonHover={
          this.props.isAddedClauseHovered
            ? this.onTopButtonHover("revert")
            : this.onTopButtonHover("delete")
        }
        onTopButtonHoverFinish={this.onTopButtonHoverEnd}
        onAddAboveClick={props.showAddClauseEditor(props.documentClause, -1)}
        onAddBelowClick={props.showAddClauseEditor(props.documentClause, 1)}
        mainContainerStyles={{
          position: "absolute",
          left: "5px",
          top: "0px",
          width: "50px",
          height: "70px",
        }}
      />
    );
  };

  onNumberHover = () => {
    const {onNumberItemHoverStart, clause} = this.props;
    return onNumberItemHoverStart(clause);
  };

  onHeadingEdit = event => {
    event.stopPropagation();
    const {heading} = this.props;
    this.props.setEditableClauseHeading(heading);
  };

  onToggleClauseHeadingOpen = _.memoize(reference => () => {
    if (!this.props.doesHaveEditableClause) {
      this.props.onToggleClauseHeadingOpen(reference);
    }
  });

  onTopButtonHover = buttonType => () =>
    this.props.setHoveredHeadingActionButton(buttonType);
  onTopButtonHoverEnd = () => this.props.setHoveredHeadingActionButton(null);
}

function getHeadingChanges(props) {
  return props.documentChanges.filter(
    change =>
      (change.type !== "clauseheading_addition" &&
        (change.type.startsWith("clauseheading") &&
          change.new_heading_id === props.heading.id)) ||
      (change.type === "clause_deletion" &&
        change.new_clause_id === props.clause.id),
  );
}

function isHeadingAdded(props) {
  return Boolean(
    props.documentChanges.find(
      change =>
        change.type === "clauseheading_addition" &&
        change.new_heading_id === props.heading.id,
    ),
  );
}

function isHeadingDeleted(props) {
  return Boolean(
    props.documentChanges.find(
      change =>
        change.type === "clauseheading_deletion" &&
        change.new_heading_id === props.heading.id,
    ),
  );
}

export default ClauseHeading;

const validators = [
  {
    validate(p) {
      return p.length >= 10;
    },
    name: "charLength",
    message: " be at least 10 characters long",
  },
  {
    validate(p) {
      const regex = /[a-z]+/;
      return regex.test(p);
    },
    name: "lowerLetters",
    message: "lowercase letters",
  },
  {
    validate(p) {
      const regex = /[A-Z]+/;
      return regex.test(p);
    },
    name: "capitalLetters",
    message: "capital letters",
  },
  {
    validate(p) {
      const regex = /\d/;
      return regex.test(p);
    },
    name: "numbers",
    message: "numbers",
  },
  {
    validate(p) {
      const regex = /[^a-zA-Z0-9]/;
      return regex.test(p);
    },
    name: "specialCharacters",
    message: "special characters",
  },
];

const validatorMessages = {
  charLength: " be at least 10 characters long",
  lowerLetters: "lowercase letters",
  capitalLetters: "uppercase letters",
  numbers: "numbers",
  specialCharacters: "special characters",
};

export function getInvalidConditions(password) {
  return validators.reduce((accum, validator) => {
    if (!validator.validate(password)) {
      accum.push(validator.name);
    }
    return accum;
  }, []);
}

function validate(password) {
  return getInvalidConditions(password).length === 0;
}

export function constructErrorMessage(invalidConditions) {
  let validationMessage = "";
  if (invalidConditions.length > 0) {
    validationMessage = "Password should";
    invalidConditions.forEach((condition, conditionIndex) => {
      const conditionMessage = validatorMessages[condition];
      if (condition === "charLength") {
        validationMessage += conditionMessage;
      } else {
        if (conditionIndex !== 0) {
          validationMessage = `${validationMessage}${
            conditionIndex === invalidConditions.length - 1 ? " and" : ","
          }`;
        }
        if (validationMessage.indexOf("contain") === -1) {
          validationMessage = `${validationMessage} contain`;
        }
        validationMessage = `${validationMessage} ${conditionMessage}`;
      }
    });
    validationMessage = `${validationMessage}.`;
  }
  return validationMessage;
}

export function getValidationMessage(password) {
  return constructErrorMessage(getInvalidConditions(password));
}

export default validate;

import React, {useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import InputBase from "@material-ui/core/InputBase";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import RevertButton from "./revert_button";
import TextFieldHandlers from "./text_field_handlers";

const styles = {
  open: {
    WebkitLineClamp: 3,
    width: "100%",
  },
  close: {
    fontSize: "12px",
    textOverflow: "ellipsis",
    width: "300px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    margin: "2px 7px",
    cursor: "pointer",
  },
  deleteIcon: {
    color: "#757575",
    width: 20,
    height: 20,
    margin: "0 2px 0 3px",
    cursor: "pointer",
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  inputBase: {
    border: "1px solid #bbb",
    width: "100%",
    lineHeight: 1.2,
    backgroundColor: "#EEE",
    padding: "5px",
  },
  inputBaseText: {
    borderTop: "none",
  },
  inputBaseReference: {
    borderBottom: "none",
  },
  inputProps: {
    style: {
      fontSize: 12,
      fontWeight: 100,
    },
  },
  reference: {
    marginRight: "0.5em",
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "center",
    width: "23px",
  },
};

const ReportClause = ({
  id,
  clause,
  checked,
  onClick,
  useDeleteIcon,
  isChanged,
  scrollToClause,
  alteredClausesText,
  onUpdateAlteredClause,
  onRevertAlteredClause,
}) => {
  const currentTextValue = alteredClausesText?.[clause.id]?.text
    ? alteredClausesText[clause.id].text
    : clause.text;
  const currentReferenceValue = alteredClausesText?.[clause.id]?.reference
    ? alteredClausesText[clause.id].reference
    : clause.reference;

  const [expand, setExpand] = useState({key: id, isExpand: true});
  const [openTextEditor, setOpenTextEditor] = useState(false);
  const [referenceValue, setReferenceValue] = useState(currentReferenceValue);
  const [textValue, setTextValue] = useState(currentTextValue);
  const onTextFieldChange = e => setTextValue(e.target.value);
  const onReferenceFieldChange = e => setReferenceValue(e.target.value);
  const expandTextHandler = () => {
    if (expand.key === id) {
      setExpand({key: expand.key, isExpand: !expand.isExpand});
      setTimeout(() => scrollToClause(clause), 1);
    }
  };
  const openTextEditorHandler = () => {
    setOpenTextEditor(true);
  };
  const onAbortField = () => {
    setOpenTextEditor(false);
    setTextValue(clause.text);
    setReferenceValue(clause.reference);
  };
  const onSave = () => {
    setOpenTextEditor(false);
    if (clause.text !== textValue || clause.reference !== referenceValue) {
      onUpdateAlteredClause({
        id: clause.id,
        reference: referenceValue,
        text: textValue,
      });
    }
  };
  const onRevertChanges = () => onRevertAlteredClause(clause.id);

  return (
    <div
      key={clause.id}
      style={{
        ...styles.wrapper,
        backgroundColor: isChanged ? "#fffde7" : "inherit",
      }}
    >
      {useDeleteIcon ? (
        <DeleteIcon style={styles.deleteIcon} onClick={onClick} />
      ) : (
        <Checkbox
          checked={checked}
          color="primary"
          onChange={onClick}
          style={{padding: 0}}
        />
      )}
      <div
        style={
          expand.isExpand ? {...styles.close, ...styles.open} : styles.close
        }
      >
        {openTextEditor ? (
          <ClickAwayListener onClickAway={onSave}>
            <div style={styles.wrapper}>
              <div style={{width: "100%"}}>
                <InputBase
                  value={referenceValue}
                  multiline={true}
                  autoFocus={true}
                  onChange={onReferenceFieldChange}
                  inputProps={styles.inputProps}
                  style={{
                    ...styles.inputBase,
                    ...styles.inputBaseReference,
                  }}
                />
                <InputBase
                  value={textValue}
                  multiline={true}
                  onChange={onTextFieldChange}
                  inputProps={styles.inputProps}
                  style={{
                    ...styles.inputBase,
                    ...styles.inputBaseText,
                  }}
                />
              </div>
              <div style={styles.buttonWrapper}>
                {clause.text !== textValue ||
                clause.reference !== referenceValue ? (
                  <TextFieldHandlers
                    onSave={onSave}
                    onAbortField={onAbortField}
                    isColumnDirection
                  />
                ) : null}
              </div>
            </div>
          </ClickAwayListener>
        ) : (
          <>
            <span onClick={expandTextHandler} style={styles.reference}>
              {currentReferenceValue}
            </span>
            <span onDoubleClick={openTextEditorHandler}>
              {currentTextValue}
            </span>
          </>
        )}
      </div>
      <div>
        {!openTextEditor &&
        (alteredClausesText?.[clause.id]?.text ||
          alteredClausesText?.[clause.id]?.reference) ? (
          <RevertButton onRevertChanges={onRevertChanges} />
        ) : null}
      </div>
    </div>
  );
};

export default ReportClause;

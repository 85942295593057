import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";

import {
  updateStateWithRequest,
  updateStateWithSuccess,
} from "utils/ensure_fresh_update";

import ActionTypes from "../constants/action_types";
import readTask from "../utils/read_task";

export default function taskReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.TASK_FETCH.SUCCESS:
      return readTask(action.payload);
    case ActionTypes.TASK_UPDATE.REQUEST:
      return {
        ...state,
        ...updateStateWithRequest(state, action.payload, ["id", "last_edited"]),
      };
    case ActionTypes.TASK_UPDATE.SUCCESS:
      return updateStateWithSuccess(state, action.payload);
    case ActionTypes.PROJECT_TASKS_REORDER.SUCCESS:
      return {
        ...state,
        order: action.payload.order[state.id].order,
        last_edited: action.payload.last_edited,
      };
    case ActionTypes.TASK_ROLE_UPDATE.SUCCESS: {
      return {
        ...state,
        last_edited: action.payload.last_edited,
        task_roles: _.chain(state.task_roles)
          .filter(role => role.id !== action.payload.role_id)
          .union([
            {
              id: action.payload.role_id,
              ..._.omit(action.payload, ["last_edited", "role_id"]),
            },
          ])
          .sortBy(role => role.id)
          .value(),
      };
    }
    case ActionTypes.TASK_COMMENT_CREATE.SUCCESS: {
      return {
        ...state,
        comments: state.comments.concat([action.payload]),
      };
    }
    default:
      return state;
  }
}

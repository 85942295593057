import React from "react";

import MaterialDialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import {Button, DialogActions, DialogContent} from "@material-ui/core";

type DialogProps = {
  onClose?: () => void;
  title: string;
  buttons: Array<{
    text: string;
    onClick?: () => void;
    disabled?: boolean;
  }>;
  children: React.ReactNode;
};

const Dialog = ({onClose, children, title, buttons}: DialogProps) => (
  <MaterialDialog maxWidth={false} open onClose={onClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      {buttons.map(button => (
        <Button
          key={button.text}
          color="primary"
          onClick={button.onClick}
          disabled={button.disabled}
        >
          {button.text}
        </Button>
      ))}
    </DialogActions>
  </MaterialDialog>
);

export default Dialog;

export default function getScrollSizeHandler(updateScrollSize) {
  function getScrollSize() {
    const div = document.createElement("div");

    div.style.position = "fixed";
    div.style.width = "100px";
    div.style.height = "100px";
    div.style.overflow = "scroll";
    div.style.top = "-9999px";

    document.body.appendChild(div);
    const scrollSize = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    if (typeof updateScrollSize === "function") {
      updateScrollSize(scrollSize);
    }
  }

  getScrollSize();
  window.addEventListener("resize", getScrollSize);
  return () => window.removeEventListener("resize", getScrollSize);
}

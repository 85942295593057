import _ from "underscore";

function areTopicParametersSame(tpA, tpB = []) {
  if (tpA.length !== tpB.length) {
    return false;
  }
  return _.isEqual(
    _.sortBy(tpA, tp => tp.topicparameter_id),
    _.sortBy(tpB, tp => tp.topicparameter_id),
  );
}

const ABORT = "ABORT";

// eslint-disable-next-line
function applyRules(clauseTopics, maskRules) {
  if (!maskRules) {
    return false;
  }
  if (Array.isArray(maskRules)) {
    const matchingTopics = maskRules
      .map(rule => applyRules(clauseTopics, rule))
      .filter(result => result);
    if (matchingTopics.find(topic => topic === ABORT)) {
      return false;
    }
    if (matchingTopics.length > 0) {
      return matchingTopics.reduce(
        (topics, topic) => [
          ...topics,
          ...(Array.isArray(topic) ? topic : [topic]),
        ],
        [],
      );
    }
    return false;
  }
  if (typeof maskRules === "number") {
    const match = clauseTopics.find(
      clauseTopic => clauseTopic.topic_id === maskRules,
    );
    if (match) {
      return {
        topic_id: match.topic_id,
        topicparameters: match.topicparameters || [],
      };
    }
    return false;
  }
  if (typeof maskRules === "object") {
    switch (maskRules.type) {
      case "not": {
        const applied = applyRules(clauseTopics, maskRules.rules);
        if (!applied) {
          return [];
        }
        return ABORT;
      }
      case "and": {
        const matchingTopics = maskRules.rules.map(rule =>
          applyRules(clauseTopics, rule),
        );
        const allMatch =
          matchingTopics.filter(match => match && match !== "ABORT").length ===
          maskRules.rules.length;
        if (allMatch) {
          return matchingTopics.reduce(
            (topics, topic) => [
              ...topics,
              ...(Array.isArray(topic) ? topic : [topic]),
            ],
            [],
          );
        }
        return false;
      }
    }
  }
  return false;
}

export default function calculateTopics(
  clauseTopics,
  showMasks,
  masks,
  topicsById,
  usedTopics,
) {
  const topicsStates = clauseTopics
    .filter(clauseTopic => {
      const topic = topicsById[clauseTopic.topic_id];
      return (
        !topic ||
        !(
          topic.name.startsWith("xxx") ||
          topic.name.startsWith("yyy") ||
          topic.name.startsWith("zzz")
        )
      );
    })
    .filter(clauseTopic => {
      if (usedTopics) {
        const matchingTopic = usedTopics[clauseTopic.topic_id];
        if (matchingTopic && !matchingTopic.is_deleted) {
          return !areTopicParametersSame(
            matchingTopic,
            clauseTopic.topicparameters,
          );
        }
      }
      return true;
    });

  if (showMasks) {
    const goodMasks = masks.filter(mask => !mask.name.match(/Blank|Spare/));
    const matchedMasks = goodMasks
      .map(mask => ({
        ...mask,
        match: applyRules(clauseTopics, mask.topic_ids),
      }))
      .filter(mask => mask.match);
    const otherTopics = clauseTopics.filter(
      clauseTopic =>
        !matchedMasks.find(mask =>
          mask.match.find(match => match.topic_id === clauseTopic.topic_id),
        ),
    );

    const normalTopics = otherTopics.map(topic => ({
      ...topic,
      is_confirmed: true,
    }));
    const maskedTopics = matchedMasks.map(mask => {
      return {
        ...mask,
        id: `m_${mask.id}`,
        is_confirmed: true,
        topic_mask_id: mask.id,
        topicparameters: mask.match.reduce(
          (tps, match) => [...tps, ...match.topicparameters],
          [],
        ),
      };
    });
    const allTopics = normalTopics.concat(maskedTopics);
    return allTopics;
  }
  return topicsStates;
}

function isSectionInSelectedGroup(
  sectionId,
  selectedChecklistSectionId,
  checklistSections,
) {
  const selectedGroup = checklistSections.find(
    group => group.id === selectedChecklistSectionId,
  );
  if (selectedGroup) {
    return Boolean(
      selectedGroup.sections.find(section => section.id === sectionId),
    );
  }
  return sectionId === selectedChecklistSectionId;
}

export default isSectionInSelectedGroup;

import requestor from "requestor";

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_ISSUE_REVERT,
  (
    organisationId,
    projectId,
    documentId,
    documentIssueId,
    dataPath,
    lastEdited,
  ) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}` +
          `/project/${projectId}` +
          `/document/${documentId}` +
          `/document_issue/${documentIssueId}/revert`,
        {last_edited: lastEdited, data_path: dataPath},
        {withCredentials: true},
      )
      .then(response => response.data),
);

import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import CheckboxesMenu from "./checkboxes_menu";
import DownloadButton from "./download_button";
import FiltersPanel from "../../filters";
import ClearIcon from "@material-ui/icons/Clear";
import {filtersState} from "../../filters/constants";
import getFiltersTitles from "../utils/get_filters_titles";
import {filterTitlesStyles} from "../../filters/styles";
import SaveCancelButtons from "./save_cancel_buttons";
import RiskScore from "../../risk_score";

const styles = {
  headerContainer: {
    backgroundColor: "#f1f1f1",
    borderBottom: "#e7e7e7 solid 1px",
  },
  filtersContainer: {
    display: "flex",
    justifyContent: "space-between",
    letterSpacing: "0.02857em",
    fontSize: "0.875rem",
    fontWeight: 500,
    borderTop: "1px solid lightgrey",
    paddingLeft: "20px",
  },
  buttonsBlock: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    padding: "5px 20px 5px 5px",
    borderLeft: "1px solid lightgrey",
  },
  topPanelScroll: {
    maxHeight: "calc(100vh - 105px)",
    overflowY: "auto",
  },
  filtersBlock: {
    display: "flex",
    gap: "10px",
  },
};

const DocumentReportPanel = ({
  scrollerStyle,
  children,
  onContainerClick,
  onDownloadXlsx,
  onDownloadDocx,
  triggerHighlightManualCorrections,
  highlightManualCorrections,
  triggerShowDeletedIssues,
  showDeletedIssues,
  itemIssuesFilterValues,
  changeItemIssuesFilterValues,
  reviewTrackingOn,
  isSaveButtonShown,
  saveButtonHandler,
  cancelButtonHandler,
  startSelectedReportEditing,
  reportType,
  issueset,
  reportSettings,
  issues,
  addNewReportItem,
}) => {
  const handleClearFilter = item =>
    changeItemIssuesFilterValues(
      filtersState.find(filter => filter.title === item),
    );
  const handleClearAllFilters = () => changeItemIssuesFilterValues("delete");
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = event => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const startEditingReport = () => {
    closeMenu();
    startSelectedReportEditing();
  };

  return (
    <Paper id="top-panel">
      <div style={styles.headerContainer}>
        <div style={styles.filtersContainer}>
          <div style={styles.filtersBlock}>
            <div>
              {itemIssuesFilterValues ? (
                <FiltersPanel
                  isFlipChecklistIcons={true}
                  changeItemIssuesFilterValues={changeItemIssuesFilterValues}
                  itemIssuesFilterValues={itemIssuesFilterValues}
                  reviewTrackinOn={reviewTrackingOn}
                  reportView={"rag_report"}
                />
              ) : null}
            </div>
            <RiskScore
              issues={issues}
              issueset={issueset}
              isShow={reportSettings?.show_document_risk_score}
            />
            <SaveCancelButtons
              isShow={isSaveButtonShown}
              onSave={saveButtonHandler}
              onCancel={cancelButtonHandler}
            />
          </div>
          <div style={styles.buttonsBlock}>
            <CheckboxesMenu
              openMenu={openMenu}
              anchorEl={anchorEl}
              closeMenu={closeMenu}
              startEditingReport={startEditingReport}
              highlightManualCorrections={highlightManualCorrections}
              triggerHighlightManualCorrections={
                triggerHighlightManualCorrections
              }
              reportType={reportType}
              showDeletedIssues={showDeletedIssues}
              triggerShowDeletedIssues={triggerShowDeletedIssues}
              addNewReportItem={addNewReportItem}
            />
            <DownloadButton
              onDownloadXlsx={onDownloadXlsx}
              onDownloadDocx={onDownloadDocx}
            />
          </div>
        </div>
      </div>
      <div>
        {getFiltersTitles(filtersState, itemIssuesFilterValues).length ? (
          <div style={filterTitlesStyles.filtersPanel}>
            {getFiltersTitles(filtersState, itemIssuesFilterValues).map(
              item => (
                <div key={item} style={filterTitlesStyles.filterClips}>
                  {item}
                  <ClearIcon
                    style={filterTitlesStyles.clearIcon}
                    onClick={() => handleClearFilter(item)}
                  />
                </div>
              ),
            )}
            <div
              style={filterTitlesStyles.clearFiltersBtn}
              onClick={handleClearAllFilters}
            >
              <ClearIcon style={{height: "15px"}} />
              Clear All
            </div>
          </div>
        ) : null}
      </div>
      <div onClick={onContainerClick}>
        <div
          className="top-panel-scroller"
          style={{
            ...scrollerStyle,
            ...styles.topPanelScroll,
          }}
        >
          {children}
        </div>
      </div>
    </Paper>
  );
};

export default DocumentReportPanel;

import _ from "underscore";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import EditIcon from "@material-ui/icons/EditOutlined";

import SwitchCopyTooltip from "./switch_copy_tooltip";
import copySelection from "utils/copy_selected_text_inside_element";
import limitTextLines from "utils/limit_text_lines";
import getZoomedFontSize from "utils/get_zoomed_font_size";

const useStyles = makeStyles({
  draftingExamplePreview: {
    marginBottom: "1em",
    padding: "1em",
    display: "flex",
    backgroundColor: "#f4f4f4",
  },
  draftingExampleHeadingBar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  },
  draftingExampleHeading: {
    fontWeight: "bold",
    paddingBottom: "0.5em",
    width: "100%",
  },
  draftingExampleText: {
    width: "100%",
  },
  footer: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  },
  readMoreSpan: {
    color: "#2196f3",
    cursor: "pointer",
  },
  copyCommentIcon: {
    height: ".85em",
    width: ".85em",
    color: "#777",
    cursor: "pointer",
  },
  icon: {
    height: ".85em",
    width: ".85em",
    color: "#2196f3",
    cursor: "pointer",
  },
  clampedText: {
    cursor: "pointer",
  },
  buttons: {
    display: "inline-block",
    "& > *": {
      marginLeft: ".3em",
    },
  },
  visibilityIcon: {
    width: "16px",
    height: "16px",
    color: "#777",
  },
  description: {
    marginBottom: ".5em",
    color: "#777",
    fontStyle: "italic",
  },
  commentLabel: {
    margin: ".5em 0",
    color: "#777",
    fontWeight: "bold",
    fontStyle: "italic",
  },
  commentText: {
    marginBottom: ".5em",
  },
});

export const MAX_TEXT_SIZE = 140;
const MAX_COMMENT_SIZE = 140;

export default function DraftingExamplePreview({
  index,
  editIssueResponse,
  getComponent,
  getValue,
  issueResponse,
  hideCopyComment,
  zoom,
}) {
  const classes = useStyles();
  const [isClamped, setClamped] = useState(true);
  const {name: heading, is_public: isPublic, description} = issueResponse;
  const predefinedComment = issueResponse.predefined_comment || "";
  const id = `drafting-example-text-${index}`;
  const text = getValue(issueResponse);
  const shouldShowReadMore =
    text.length > MAX_TEXT_SIZE || predefinedComment.length > MAX_COMMENT_SIZE;
  const maxTextSize = isClamped ? MAX_TEXT_SIZE : 0;
  const component = _.isFunction(getComponent)
    ? getComponent(issueResponse, maxTextSize)
    : limitTextLines(text, maxTextSize);
  const maxCommentSize = isClamped ? MAX_COMMENT_SIZE : 0;
  const comment = limitTextLines(predefinedComment, maxCommentSize);

  return (
    <div className={classes.draftingExamplePreview}>
      <div
        className={classes.draftingExampleText}
        style={{fontSize: getZoomedFontSize(14, "checklist", zoom)}}
      >
        <div className={classes.draftingExampleHeadingBar}>
          <div
            className={classes.draftingExampleHeading}
            onDoubleClick={editIssueResponse}
          >
            {heading}
          </div>
          {isPublic || (
            <Tooltip title="Private (only you can see this)">
              <VisibilityOffIcon className={classes.visibilityIcon} />
            </Tooltip>
          )}
        </div>
        {description && (
          <div className={classes.description}>{description}</div>
        )}
        <div
          id={id}
          className={classes.clampedText}
          onDoubleClick={editIssueResponse}
        >
          {component}
        </div>
        {predefinedComment &&
          !hideCopyComment && (
            <div className={classes.comment}>
              <div className={classes.commentLabel}>Comment</div>
              <div className={classes.commentText}>{comment}</div>
            </div>
          )}
        <div className={classes.footer}>
          {shouldShowReadMore ? (
            <span
              className={classes.readMoreSpan}
              onClick={() => setClamped(!isClamped)}
            >
              Read {isClamped ? "more" : "less"}
            </span>
          ) : (
            <div />
          )}
          <div className={classes.buttons}>
            <SwitchCopyTooltip title="Copy clause text" copyTitle="Text copied">
              <CopyIcon
                className={classes.icon}
                onClick={copySelection(text, id)}
              />
            </SwitchCopyTooltip>
            {predefinedComment &&
              !hideCopyComment && (
                <SwitchCopyTooltip
                  title="Copy comment"
                  copyTitle="Comment copied"
                >
                  <CopyIcon
                    className={classes.copyCommentIcon}
                    onClick={copySelection(predefinedComment, id)}
                  />
                </SwitchCopyTooltip>
              )}
            <EditIcon className={classes.icon} onClick={editIssueResponse} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default function readTask(task) {
  return {
    ...task,
    ...(task.due_date
      ? {
          due_date: new Date(task.due_date),
        }
      : {}),
    ...(task.comments
      ? {
          comments: task.comments.map(comment => ({
            ...comment,
            creation_date: new Date(comment.creation_date),
          })),
        }
      : {}),
  };
}

import {ActionTypes} from "modules/redux_types";
import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

const types = ["FETCH_LLM_STATUS"] as const;

types.forEach(type => createReduxPromiseActionType(actionTypes, type));

export type ActionType = typeof types[number];

export type LlmStatusActionTypes = ActionTypes<ActionType>;

export default actionTypes as LlmStatusActionTypes;

import _ from "lodash";
import pluralize from "pluralize";

import filterCapitalizedTermsByOtherTerms from "utils/clauses/filter_capitalized_terms_by_other_terms";
import getCapitalizedWordsFromText from "utils/text/get_capitalized_words_from_text";
import getReferenceIndicatorFromText from "common/utils/clauses/get_reference_indicator_from_text";
import escapeRegex from "utils/escape_regex";

const possessiveRegex = /['’]s$/;

/* eslint-disable max-statements */
function getClausepartSubstitutionData(
  clausepartText = "",
  whatToSubstitute = "default",
  comparisonDocumentDefinitions = [],
  baseDocumentDefinitions = [], // currently open document definitions
  linkedDefinitions = {},
  definitionGroups = [],
  baseDocumentCapitalizedWords = [], // sorted by more frequent first
  precedentTerms = {},
  _comparisonDocumentParties = [], // currently open document parties
  _baseDocumentParties = [],
  documentReferenceIndicator, // currently open document refIndicator
  referenceIndicatorGroup, // definition group "reference indicator"
) {
  let result = {
    substitutionMap: {},
    optionsMap: {},
  };

  if (!clausepartText) {
    return result;
  }
  const isClausepartTextCapitalized =
    clausepartText.toUpperCase() === clausepartText;

  // 0) handle reference indicators
  if (documentReferenceIndicator && referenceIndicatorGroup) {
    const {
      indicator: clausepartTextReferenceIndicator,
      isPlural,
    } = getReferenceIndicatorFromText(
      clausepartText,
      referenceIndicatorGroup.synonyms,
    );

    if (clausepartTextReferenceIndicator) {
      const options = constructOptions(
        referenceIndicatorGroup.synonyms,
        baseDocumentDefinitions,
        baseDocumentCapitalizedWords,
        isPlural,
      );

      const bestValueValue = isPlural
        ? pluralise(documentReferenceIndicator)
        : documentReferenceIndicator;
      result = setResult(
        result,
        whatToSubstitute,
        precedentTerms,
        isPlural
          ? pluralise(clausepartTextReferenceIndicator)
          : clausepartTextReferenceIndicator,
        "reference_indicator", // term type
        true, // isInSynonyms
        true, // isSubstituted
        {
          value: bestValueValue,
          origin: "synonym",
        }, // bestValue
        options,
        isClausepartTextCapitalized,
      );
    }
  }

  const allSynonymsSingular = (definitionGroups || []).reduce((result, dg) => {
    dg.synonyms.forEach(syn => {
      result.push(pluralize.singular(syn.toLowerCase()));
    });
    return result;
  }, []);

  // 1) handle parties
  const baseDocumentParties = _baseDocumentParties
    .filter(partiesArrayFunc)
    .map(partiesArrayFunc);
  const comparisonDocumentParties = _comparisonDocumentParties
    .filter(partiesArrayFuncNoArticles)
    .map(partiesArrayFuncNoArticles);
  if (baseDocumentParties.length > 0 && comparisonDocumentParties.length > 0) {
    comparisonDocumentParties.forEach(partyTerm => {
      if (clausepartText.indexOf(partyTerm) !== -1) {
        const bestValue = {
          value:
            baseDocumentParties.find(baseParty => baseParty === partyTerm) ||
            baseDocumentParties[0],
          origin: "party",
        };
        const options = constructOptions(
          [],
          baseDocumentDefinitions,
          baseDocumentCapitalizedWords,
          false,
          false,
          baseDocumentParties,
        );

        const isSubstituted = true;
        result = setResult(
          result,
          whatToSubstitute,
          precedentTerms,
          partyTerm,
          "party", // term type
          isTermInSynonyms(partyTerm.toLowerCase(), allSynonymsSingular),
          isSubstituted,
          bestValue,
          options,
          isClausepartTextCapitalized,
        );
      }
    });
  }

  // 2) handle definitions
  let strippedClausepartText = clausepartText;

  const comparisonDocumentDefinitionsTerms =
    comparisonDocumentDefinitions && comparisonDocumentDefinitions.length
      ? comparisonDocumentDefinitions.map(definition =>
          definition.term.trim().replace(/^(?:(?:the|an|a) )?/i, ""),
        )
      : [];

  const resultValues = Object.keys(result.substitutionMap);

  const capitalizedTerms = filterCapitalizedTermsByOtherTerms(
    [...comparisonDocumentDefinitionsTerms, ...resultValues],
    getCapitalizedWordsFromText(clausepartText),
  );

  const baseTerms = [
    ...addType(comparisonDocumentDefinitionsTerms, "definition"),
    ...addType(capitalizedTerms, "capitalized_term"),
  ];

  const allTerms = _.chain(baseTerms)
    .uniq(item => item.term)
    .sortBy(item => -item.term.length)
    .filter(item => {
      const {term} = item;
      const text = escapeRegex(strippedClausepartText);
      const regex = new RegExp(
        `(^|\\W)${escapeRegex(term)}(s|es|'s|’s)?(\\W|$)`,
        "g",
      );
      strippedClausepartText = strippedClausepartText.replace(regex, " ");
      return text.match(regex);
    })
    .value();

  allTerms.forEach(item => {
    const {term: clausepartTerm, type} = item;
    const clausepartTermClean = clausepartTerm.toLowerCase().trim();
    // console.log('clausepartTermClean: ', clausepartTermClean);
    const isClausepartTermCleanInSynonyms = isTermInSynonyms(
      clausepartTermClean,
      allSynonymsSingular,
    );

    let bestValue = null;
    let options = constructOptions(
      [],
      baseDocumentDefinitions,
      baseDocumentCapitalizedWords,
    );
    let isSubstituted = false;

    // 1. search definition groups' synonyms for the term. If found use either linked definition
    // from the synonym's group or search among document definitions

    // if current term is assigned to a specific definition group that means
    // that the meaning of this term should be searched only among this DG
    // synonyms;

    const linkedDefinitionsFlippedNormalized = flipAndNormalizeLinkedDefinitions(
      linkedDefinitions,
    );
    const savedLinkedDefinitionGroupId =
      linkedDefinitionsFlippedNormalized[clausepartTermClean];

    if (savedLinkedDefinitionGroupId) {
      const savedLinkedDefinitionGroup = definitionGroups.find(
        dg => dg.id === savedLinkedDefinitionGroupId,
      );
      const result = getResultsFromDefinitionGroup(
        savedLinkedDefinitionGroup,
        clausepartTerm,
        clausepartTermClean,
        linkedDefinitions,
        baseDocumentDefinitions,
        baseDocumentCapitalizedWords,
        baseDocumentParties,
      );

      bestValue = result.bestValue;
      options = result.options || options;
      isSubstituted = result.isSubstituted || isSubstituted;
    } else {
      (definitionGroups || []).find(dg => {
        const result = getResultsFromDefinitionGroup(
          dg,
          clausepartTerm,
          clausepartTermClean,
          linkedDefinitions,
          baseDocumentDefinitions,
          baseDocumentCapitalizedWords,
          baseDocumentParties,
        );
        bestValue = result.bestValue;
        options = result.options || options;
        isSubstituted = result.isSubstituted || isSubstituted;
        return Boolean(result && result.bestValue && result.bestValue.value);
      });
    }

    // 2. if best value not found in definition groups look in document definitions
    //    If found among document definitions use it as bestValue otherwise use
    //    the first definition from the list. Use document definitions as options
    if (!bestValue && baseDocumentParties && baseDocumentParties.length > 0) {
      const foundDocumentParty = baseDocumentParties.find(party => {
        const cleanPartyTerm = party.toLowerCase().trim();
        return (
          clausepartTermClean.startsWith(cleanPartyTerm) &&
          clausepartTermClean.length - cleanPartyTerm.length < 3
        );
      });
      if (foundDocumentParty) {
        const value = foundDocumentParty;
        const {isPlural, withApostrophe} = isClausepartTermPlural(
          clausepartTermClean,
          value,
        );
        bestValue = {
          value: isPlural ? pluralise(value, withApostrophe) : value,
          origin: "party",
        };
        options = constructOptions(
          [],
          baseDocumentDefinitions,
          baseDocumentCapitalizedWords,
          isPlural,
          withApostrophe,
          baseDocumentParties,
        );
        isSubstituted = Boolean(foundDocumentParty);
      }
    }

    // 3. if best value not found in definition groups look in document definitions
    //    If found among document definitions use it as bestValue otherwise use
    //    the first definition from the list. Use document definitions as options
    if (
      !bestValue &&
      baseDocumentDefinitions &&
      baseDocumentDefinitions.length > 0
    ) {
      const foundDocumentDefinition = baseDocumentDefinitions.find(
        definition => {
          const cleanDefinitionTerm = definition.term.toLowerCase().trim();
          return (
            clausepartTermClean.startsWith(cleanDefinitionTerm) &&
            clausepartTermClean.length - cleanDefinitionTerm.length < 3
          );
        },
      );
      if (foundDocumentDefinition) {
        const value = foundDocumentDefinition.term;
        const {isPlural, withApostrophe} = isClausepartTermPlural(
          clausepartTermClean,
          value,
        );
        bestValue = {
          value: isPlural ? pluralise(value, withApostrophe) : value,
          origin: "definition",
        };
        options = constructOptions(
          [],
          baseDocumentDefinitions,
          baseDocumentCapitalizedWords,
          isPlural,
          withApostrophe,
        );
        isSubstituted = Boolean(foundDocumentDefinition);
      }
    }

    // 4. if no definition groups and no current document definitions and no
    //    linked definitions we search in Current Document Capitalized Words
    if (
      !bestValue &&
      baseDocumentCapitalizedWords &&
      baseDocumentCapitalizedWords.length > 0
    ) {
      const foundTermInBaseDocumentCapitalizedWords = baseDocumentCapitalizedWords.find(
        capWord => {
          const cleanCapWord = capWord.toLowerCase().trim();
          return cleanCapWord === clausepartTermClean;
        },
      );
      const {isPlural, withApostrophe} = foundTermInBaseDocumentCapitalizedWords
        ? isClausepartTermPlural(
            clausepartTermClean,
            foundTermInBaseDocumentCapitalizedWords,
          )
        : {isPlural: false, withApostrophe: false};
      if (foundTermInBaseDocumentCapitalizedWords) {
        bestValue = {
          value: isPlural
            ? pluralise(foundTermInBaseDocumentCapitalizedWords, withApostrophe)
            : foundTermInBaseDocumentCapitalizedWords,
          origin: "capitalized_term",
        };
      } else {
        bestValue = {value: null};
      }

      options = constructOptions(
        [],
        baseDocumentDefinitions,
        baseDocumentCapitalizedWords,
        isPlural,
        withApostrophe,
      );
      isSubstituted = Boolean(foundTermInBaseDocumentCapitalizedWords);
    }

    // setting results
    result = setResult(
      result,
      whatToSubstitute,
      precedentTerms,
      clausepartTerm,
      type, // term type
      isClausepartTermCleanInSynonyms,
      isSubstituted,
      bestValue,
      options,
      isClausepartTextCapitalized,
    );
  });

  return result;
}

function addType(terms, type) {
  return terms.map(term => ({term, type}));
}

function constructOptions(
  synonyms,
  definitions,
  documentCapitalizedWords,
  shouldPluralise,
  shouldPluraliseWithApostrophe,
  parties,
) {
  const options = [
    ...(synonyms || []).map(synonym => ({value: synonym, origin: "synonym"})),
    ...(definitions || []).map(definition => ({
      value: definition.term ? definition.term : definition,
      origin: "definition",
    })),
    ...(parties || []).map(party => ({
      value: party,
      origin: "party",
    })),
    ...(documentCapitalizedWords || []).map(capitalizedTerm => ({
      value: capitalizedTerm,
      origin: "capitalized_term",
    })),
  ];
  if (shouldPluralise) {
    return options.map(option => ({
      ...option,
      value: pluralise(option.value, shouldPluraliseWithApostrophe),
    }));
  }
  return options;
}

function partiesArrayFuncNoArticles(item) {
  return (item.term || "").trim().replace(/^(the|a|an) /gi, "");
}
function partiesArrayFunc(item) {
  return (item.term || "").trim();
}

function setResult(
  result,
  whatToSubstitute,
  precedentTerms,
  clausepartTerm,
  termType,
  isInSynonyms,
  isSubstituted,
  bestValue,
  _options,
  isClausepartTextCapitalized,
) {
  const savedTerm = precedentTerms[clausepartTerm];
  // first check if eligible for substitution (whatToSubstitute)
  if (
    !(savedTerm && savedTerm.value !== undefined) &&
    ((whatToSubstitute === "default" &&
      !isInSynonyms &&
      termType !== "definition" &&
      termType !== "party") ||
      (whatToSubstitute === "definitions_only" && termType !== "definition"))
  ) {
    return result;
  }

  let options = _options;
  if (!result.substitutionMap[clausepartTerm]) {
    result.substitutionMap[clausepartTerm] = {
      isSubstituted,
      type: termType,
      value: bestValue || {value: null},
      ...(savedTerm && savedTerm.value !== undefined
        ? {userValue: {value: savedTerm.value, source: savedTerm.source}}
        : {}),
      ...(savedTerm && typeof savedTerm.substitutedByUser === "boolean"
        ? {substitutedByUser: savedTerm.substitutedByUser}
        : {}),
    };

    if (
      isClausepartTextCapitalized &&
      result.substitutionMap[clausepartTerm].value.value
    ) {
      result.substitutionMap[
        clausepartTerm
      ].value.value = result.substitutionMap[
        clausepartTerm
      ].value.value.toUpperCase();
    }

    const calculatedBestValue = _.get(
      result.substitutionMap[clausepartTerm],
      "value",
      {},
    );

    if (calculatedBestValue.value && options && options.length > 0) {
      options = options.map(
        option =>
          option.value === calculatedBestValue.value &&
          option.origin === calculatedBestValue.origin
            ? {...option, isRecommended: true}
            : option,
      );
    }
    result.optionsMap[clausepartTerm] = options;
  }
  return result;
}

export function isClausepartTermPlural(clausepartTerm, value) {
  const valueClean = (value || "").toLowerCase().trim();
  const isPlural =
    pluralize.isPlural(clausepartTerm) ||
    Boolean(
      clausepartTerm &&
        valueClean &&
        clausepartTerm.length !== valueClean.length &&
        clausepartTerm.endsWith("s"),
    );
  const withApostrophe = isPlural
    ? Boolean(clausepartTerm.match(possessiveRegex))
    : false;
  return {isPlural, withApostrophe};
}

export function pluralise(_valueStr, withApostrophe) {
  const splitted = _valueStr.trim().split(" ");
  const lastWordIndex = splitted.length - 1;
  const lastWord = splitted[splitted.length - 1];

  let lastWordPluralized;
  if (!withApostrophe) {
    lastWordPluralized = pluralize(lastWord);
  } else {
    lastWordPluralized =
      !lastWord || lastWord.endsWith("s")
        ? lastWord
        : `${lastWord}${withApostrophe ? "'" : ""}s`;
  }
  splitted[lastWordIndex] = lastWordPluralized;
  return splitted.join(" ");
}

function isAcronym(word) {
  return word === word.toUpperCase() && word.length <= 3;
}

function isTermInSynonyms(clausepartTermClean, allSynonymsSingular) {
  const singularClausepartTermClean = pluralize.singular(clausepartTermClean);
  return Boolean(
    allSynonymsSingular.find(
      synonym => synonym === singularClausepartTermClean,
    ),
  );
}

function singularizeNoApostrophize(clausepartTermClean) {
  return pluralize.singular(clausepartTermClean.replace(possessiveRegex, ""));
}

function getResultsFromDefinitionGroup(
  dg,
  clausepartTerm,
  clausepartTermClean,
  linkedDefinitions,
  baseDocumentDefinitions,
  baseDocumentCapitalizedWords,
  baseDocumentParties,
) {
  let bestValue = null;
  let options = null;
  let isSubstituted = false;

  const synonyms = dg ? dg.synonyms : [];
  const clausepartTermCleanSingularNoApostrophe = singularizeNoApostrophize(
    clausepartTermClean,
  );

  const foundSynonym = synonyms.find(synonym => {
    const synonymClean = singularizeNoApostrophize(
      synonym.toLowerCase().trim(),
    );
    return clausepartTermCleanSingularNoApostrophe === synonymClean;
  });

  if (foundSynonym) {
    const {isPlural, withApostrophe} = isClausepartTermPlural(
      clausepartTermClean,
      foundSynonym,
    );
    const {cleanSynonyms, cleanSynonymsObj} = synonyms.reduce(
      (accum, synonym) => {
        if (synonym !== foundSynonym) {
          const cleanSynonym = synonym.toLowerCase().trim();
          accum.cleanSynonyms.push(cleanSynonym);
          accum.cleanSynonymsObj[cleanSynonym] = true;
        }
        return accum;
      },
      {cleanSynonyms: [], cleanSynonymsObj: {}},
    );

    if ((linkedDefinitions || {})[dg.id]) {
      const value = linkedDefinitions[dg.id];
      bestValue = {
        value: isPlural ? pluralise(value, withApostrophe) : value,
        origin: "synonym",
      };
      options = constructOptions(
        dg.synonyms,
        baseDocumentDefinitions,
        baseDocumentCapitalizedWords,
        isPlural,
        withApostrophe,
      );
      isSubstituted = true;
    } else {
      if (
        (baseDocumentDefinitions && baseDocumentDefinitions.length > 0) ||
        (baseDocumentParties && baseDocumentParties.length > 0)
      ) {
        // if no linked definition we search for best value in document definitions
        const definitionsRaw = baseDocumentDefinitions.map(def => def.term);
        const definitionsAndPartiesRaw = [
          ...baseDocumentParties,
          ...definitionsRaw,
        ];
        const plainClausepart = clausepartTermClean.replace(
          possessiveRegex,
          "",
        );
        const foundTermInDefinitions = definitionsAndPartiesRaw.find(def => {
          const cleanDefinitionTerm = def.toLowerCase().trim();
          return cleanDefinitionTerm === plainClausepart;
        });
        if (foundTermInDefinitions) {
          const {isPlural, withApostrophe} = isClausepartTermPlural(
            clausepartTermClean,
            foundTermInDefinitions,
          );
          bestValue = {
            value: isPlural
              ? pluralise(foundTermInDefinitions, withApostrophe)
              : foundTermInDefinitions,
            origin: "synonym",
          };
          options = constructOptions(
            dg.synonyms,
            definitionsRaw,
            baseDocumentCapitalizedWords,
            isPlural,
            withApostrophe,
          );
          isSubstituted = true;
        } else {
          // if term not found in definitions we search if definitions are in synonyms
          const cleanDefinitions = definitionsAndPartiesRaw.map(def =>
            def.toLowerCase().trim(),
          );
          const definitionsInSynonyms = _.intersection(
            cleanSynonyms,
            cleanDefinitions,
          );
          if (definitionsInSynonyms.length > 0) {
            // if definitions are in synonyms best value is the first definition in synonyms and
            // options are taken from synonyms

            const firstDefinitionInSynonyms = definitionsAndPartiesRaw.find(
              def => def.toLowerCase().trim() === definitionsInSynonyms[0],
            );

            bestValue = {
              value: isPlural
                ? pluralise(firstDefinitionInSynonyms, withApostrophe)
                : firstDefinitionInSynonyms,
              origin: "synonym",
            };
            options = constructOptions(
              dg.synonyms,
              definitionsRaw,
              baseDocumentCapitalizedWords,
              isPlural,
              withApostrophe,
            );
            isSubstituted = true;
          } else {
            // if definitions are not in synonyms best value is null
            bestValue = {
              value: null,
            };
            options = constructOptions(
              dg.synonyms,
              definitionsRaw,
              baseDocumentCapitalizedWords,
              isPlural,
              withApostrophe,
            );
            isSubstituted = false;
          }
        }
      }

      if (
        baseDocumentCapitalizedWords &&
        baseDocumentCapitalizedWords.length > 0 &&
        (!bestValue || (bestValue && !bestValue.value))
      ) {
        const foundTermInBaseDocumentCapitalizedWords = baseDocumentCapitalizedWords.find(
          capWord => {
            const cleanCapWord = capWord.toLowerCase().trim();
            return cleanCapWord === clausepartTermClean;
          },
        );
        if (foundTermInBaseDocumentCapitalizedWords) {
          bestValue = {
            value: isPlural
              ? pluralise(
                  foundTermInBaseDocumentCapitalizedWords,
                  withApostrophe,
                )
              : foundTermInBaseDocumentCapitalizedWords,
            origin: "capitalized_term",
          };
          options = constructOptions(
            dg.synonyms,
            baseDocumentDefinitions,
            baseDocumentCapitalizedWords,
            isPlural,
            withApostrophe,
          );
          isSubstituted = true;
        } else {
          const cleanbaseDocumentCapitalizedWords = baseDocumentCapitalizedWords.map(
            capWord => capWord.toLowerCase().trim(),
          );

          const capWordsInSynonyms = cleanbaseDocumentCapitalizedWords.filter(
            capWord => cleanSynonymsObj[capWord],
          );

          if (capWordsInSynonyms.length > 0) {
            const foundTermInSynonyms = baseDocumentCapitalizedWords.find(
              capWord => capWord.toLowerCase().trim() === capWordsInSynonyms[0],
            );
            bestValue = {
              value: isPlural
                ? pluralise(foundTermInSynonyms, withApostrophe)
                : foundTermInSynonyms,
              origin: "synonym",
            };
            options = constructOptions(
              dg.synonyms,
              baseDocumentDefinitions,
              baseDocumentCapitalizedWords,
              isPlural,
              withApostrophe,
            );
            isSubstituted = true;
          } else {
            bestValue = {value: null};
            options = constructOptions(
              dg.synonyms,
              baseDocumentDefinitions,
              baseDocumentCapitalizedWords,
              isPlural,
              withApostrophe,
            );
            isSubstituted = false;
          }
        }
      }
    }
    // if best value not found and definition group.use_lowercase is true
    // we use lowercase term as value
    if (
      (!bestValue || bestValue.value === null) &&
      dg.use_lowercase &&
      !isAcronym(clausepartTerm)
    ) {
      const lowerCaseWords = clausepartTerm
        .split(" ")
        .map(word => (isAcronym(word) ? word : word.toLowerCase()))
        .join(" ");
      bestValue = {
        value: isPlural
          ? pluralise(lowerCaseWords, withApostrophe)
          : lowerCaseWords,
      };
      options = constructOptions(
        dg.synonyms,
        baseDocumentDefinitions,
        baseDocumentCapitalizedWords,
        isPlural,
        withApostrophe,
      );
      isSubstituted = true;
    }
  }

  return {
    bestValue,
    options,
    isSubstituted,
  };
}

function flipAndNormalizeLinkedDefinitions(linkedDefinitions) {
  // linkedDefinitions = {1: "Clause", 4; "Order"} => {"clause": 1, "order": 4}
  if (!linkedDefinitions) {
    return {};
  }
  const result = {};
  Object.keys(linkedDefinitions).forEach(idStr => {
    result[linkedDefinitions[idStr].toLowerCase()] = parseInt(idStr, 10);
  });
  return result;
}

export default getClausepartSubstitutionData;

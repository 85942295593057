import React from "react";

import FlatButton from "material-ui/FlatButton";
import ChevronRight from "material-ui/svg-icons/navigation/chevron-right";
import ExpandMore from "material-ui/svg-icons/navigation/expand-more";

import * as colors from "material-ui/styles/colors";

import getClauseAtomsByTopic from "routes/document_detail/utils/applicable_clauses/get_clause_atoms_by_topic";
import renderApplicableClauses from "routes/document_detail/utils/applicable_clauses/render_applicable_clauses";
import clampLine from "routes/document_detail/utils/clamp_line";

const styles = {
  item: isHovered => ({
    cursor: "pointer",
    background: isHovered ? colors.grey300 : "none",
  }),
  heading: nestingLevel => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "3px 0px",
    fontSize: "13px",
    fontWeight: 100,
    paddingLeft: `${nestingLevel * 8}px`,
  }),
  itemName: (isDisabled, isDescription = false) => ({
    fontWeight: 100,
    fontFamily: "Roboto, sans-serif",
    color: isDisabled ? "#bfbfbf" : isDescription ? "#555" : null,
    fontStyle: isDescription && "italic",
  }),
  chevronStyle: isDisabled => ({
    height: 14,
    width: 14,
    visibility: isDisabled ? "hidden" : "visible",
  }),
  openResult: nestingLevel => ({
    background: "#ebf6ff",
    marginLeft: `${nestingLevel * 8}px`,
  }),
};

export default class TopicListItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isItemHovered: false,
    };
  }

  componentDidUpdate() {
    this.clamp();
  }

  clamp() {
    const elements = document.querySelectorAll(".clamped-clause");
    clampLine(elements);
  }

  render() {
    const {topic} = this.props;

    return (
      <div>
        <div
          onMouseEnter={this.hoverNameStart}
          onMouseLeave={this.hoverNameFinish}
          style={styles.item(this.state.isItemHovered)}
          onClick={!this.props.topic.isDisabled && this.onTriggerOpen}
        >
          <div style={styles.heading(topic.nestingLevel)}>
            <div style={{display: "flex", padding: "2px 0"}}>
              {this.renderArrow()}
              <div style={styles.itemName(topic.isDisabled || !topic.count)}>
                {topic.name}
              </div>
            </div>
            {this.renderOpenAllButton()}
          </div>
          {this.renderDescription()}
        </div>
        {topic.isItemOpened && this.renderChildren()}
      </div>
    );
  }
  n;

  renderDescription = () => {
    const {id} = this.props.topic;
    const topicData = this.props.topicsById[id];

    if (id && topicData.description && topicData.description.description) {
      return (
        <div style={styles.heading(this.props.topic.nestingLevel + 3)}>
          <div
            style={styles.itemName(
              this.props.topic.isDisabled || !this.props.topic.count,
              true,
            )}
          >
            {topicData.description.description}
          </div>
        </div>
      );
    }

    return null;
  };

  renderArrow = () => {
    const {count, isDisabled, isItemOpened} = this.props.topic;
    return isItemOpened ? (
      <ExpandMore
        color={!count ? colors.grey400 : "#000000"}
        style={styles.chevronStyle(isDisabled)}
      />
    ) : (
      <ChevronRight
        color={!count ? colors.grey400 : "#000000"}
        style={styles.chevronStyle(isDisabled)}
      />
    );
  };

  renderOpenAllButton = () => {
    if (
      this.state.isItemHovered &&
      !this.props.topic.isDisabled &&
      this.props.topic.count &&
      this.props.topic.children
    ) {
      const areAllChildrenOpen = !this.props.topic.children.some(
        child => !child.isItemOpened,
      );
      const {isItemOpened} = this.props.topic;
      return (
        <FlatButton
          label={
            !areAllChildrenOpen || !isItemOpened ? "Open All" : "Close All"
          }
          primary={true}
          onClick={
            !areAllChildrenOpen || !isItemOpened
              ? this.onOpenAll
              : this.onCloseAll
          }
          style={{
            minWidth: 0,
            height: "100%",
            lineHeight: "inherit",
            borderRadius: "0px",
          }}
          labelStyle={{
            fontSize: "9px",
            paddingLeft: "6px",
            paddingRight: "6px",
          }}
        />
      );
    }
    return null;
  };

  renderChildren = () => {
    const {topic} = this.props;
    if (topic.children) {
      return (
        <div>
          {topic.children.map((child, index) => (
            <TopicListItem
              key={index}
              topic={child}
              documentClauses={this.props.documentClauses}
              topicsById={this.props.topicsById}
              onClauseClick={this.props.onClauseClick}
              onItemClick={this.props.onItemClick}
              onChildrenOpen={this.props.onChildrenOpen}
              onChildrenClose={this.props.onChildrenClose}
              document={this.props.document}
            />
          ))}
        </div>
      );
    }
    const atoms = getClauseAtomsByTopic(this.props.documentClauses, topic);
    return (
      <div style={styles.openResult(topic.nestingLevel)}>
        {renderApplicableClauses(
          atoms,
          this.props.onClauseClick,
          this.props.topicsById,
          true,
          this.props.document.file_extension,
        )}
      </div>
    );
  };

  hoverNameStart = () => this.setState(() => ({isItemHovered: true}));
  hoverNameFinish = () => this.setState(() => ({isItemHovered: false}));

  onTriggerOpen = () => {
    const {name, nestingLevel} = this.props.topic;
    this.props.onItemClick({name, nestingLevel});
  };

  onOpenAll = e => {
    e.stopPropagation();
    const {name, nestingLevel} = this.props.topic;
    this.props.onChildrenOpen({name, nestingLevel});
  };

  onCloseAll = e => {
    e.stopPropagation();
    const {name, nestingLevel} = this.props.topic;
    this.props.onChildrenClose({name, nestingLevel});
  };
}

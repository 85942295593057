import * as TopicParameters from "plugins/topicparameters";

function reasonViewerTopicparameter(reason) {
  const result = [];
  Object.keys(reason.topics || {}).forEach(topicId => {
    if (topicId) {
      Object.keys(reason.topics[topicId]).forEach(parameterId => {
        if (parameterId) {
          const topic = (reason.topicsById || {})[topicId] || {parameters: []};
          const parameter = (topic.parameters || []).find(
            param => param.id === parseInt(parameterId, 10),
          );
          const parameterType = (parameter || {}).parameter_type;
          const baseValues = reason.topics[topicId][parameterId];
          baseValues.forEach((parameterBaseValue, parameterBaseValueIndex) => {
            const path = `topics.[${topicId}].[${parameterId}].[${parameterBaseValueIndex}]`;
            result.push(
              // eslint-disable-next-line import/namespace
              TopicParameters[parameterType].makeIssueReasonValue(
                path,
                parameterBaseValue,
              ),
            );
          });
        }
      });
    }
  });
  return result;
}

function getDefaultIssueRuleValues(rules) {
  const topic = (rules.topicsById || {})[rules.topic_id] || {parameters: []};
  const parameter = (topic.parameters || []).find(
    param => param.id === parseInt(rules.parameter_id, 10),
  );
  const parameterType = (parameter || {}).parameter_type;
  const path = `topics.[${rules.topic_id}].[${rules.parameter_id}].[0]`;
  // eslint-disable-next-line import/namespace
  return [TopicParameters[parameterType].makeIssueReasonValue(path, "")];
}

export default {
  reasonViewer: reasonViewerTopicparameter,
  getDefaultIssueRuleValues,
};

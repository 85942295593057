/* eslint-disable camelcase */
import and_on_clause from "./and_on_clause";
import and_on_document from "./and_on_document";
import and_on_whole_clause from "./and_on_whole_clause";
import or from "./or";
import not from "./not";
import topic_not_present from "./topic_not_present";
import topic_not_present_with_parameter from "./topic_not_present_with_parameter";
import topic_present from "./topic_present";
import topic_with_parameter_value_in_list from "./topic_with_parameter_value_in_list";
import topic_with_parameter_value_not_in_list from "./topic_with_parameter_value_not_in_list";
import topic_present_and_duration_in_range from "./topic_present_and_duration_in_range";
import topic_present_and_number_in_range from "./topic_present_and_number_in_range";
import topic_present_with_monetary_value from "./topic_present_with_monetary_value";
import compare_parameter_values from "./compare_parameter_values";
import topic_present_with_date_in_range from "./topic_present_with_date_in_range";
import topic_with_any_parameter_present from "./topic_with_any_parameter_present";
import topic_without_parameter_present from "./topic_without_parameter_present";
import empty_parent from "./empty_parent";
import topic_with_parameter_with_any_value from "./topic_with_parameter_with_any_value";
import topic_with_parameters_with_any_value from "./topic_with_parameters_with_any_value";
import topic_exists_and_role_matches from "./topic_exists_and_role_matches";

const Issues = {
  and_on_clause,
  and_on_document,
  or,
  not,
  topic_not_present,
  topic_not_present_with_parameter,
  topic_present,
  topic_with_parameter_value_in_list,
  topic_with_parameter_value_not_in_list,
  topic_present_and_duration_in_range,
  topic_present_and_number_in_range,
  topic_present_with_monetary_value,
  compare_parameter_values,
  topic_present_with_date_in_range,
  topic_with_any_parameter_present,
  topic_without_parameter_present,
  empty_parent,
  topic_with_parameter_with_any_value,
  topic_with_parameters_with_any_value,
  and_on_whole_clause,
  topic_exists_and_role_matches,
};

export default Issues;

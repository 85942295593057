import React from "react";
import _ from "lodash";

import {DocumentSearchIcon} from "constants/icons";

import modules from "../modules";
import TabHeader from "./tab_header";

const styles = {
  results: {
    flexGrow: 1,
    paddingTop: 23,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
  },
};

export default class CurrentDocumentTab extends React.Component {
  constructor(props) {
    super(props);
    this.loadedModules = this.loadModules(props);
    this.state = {
      resultsCount: this.countResults(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchValue !== this.props.searchValue) {
      this.loadedModules = this.loadModules();
      this.setState({resultsCount: this.countResults()});
    }
  }

  loadModules = _props => {
    return modules.map(Module => {
      const items = Module.findMatches(_props || this.props);
      return {Module, items};
    });
  };

  countResults = () => {
    return _.sum(this.loadedModules.map(({items}) => items.length));
  };

  render() {
    return (
      <React.Fragment>
        <TabHeader
          title="This Document"
          resultsCount={this.state.resultsCount}
          Icon={<DocumentSearchIcon />}
          {...this.props}
        />
        {this.renderBody()}
      </React.Fragment>
    );
  }

  renderBody() {
    if (!this.props.isActive) {
      return null;
    }
    return (
      <div style={styles.results}>
        {this.loadedModules.map(({Module, items}, index) => (
          <Module
            key={index}
            {...this.props}
            isLast={index === this.loadedModules.length - 1}
            items={items}
          />
        ))}
      </div>
    );
  }
}

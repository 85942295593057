import React from "react";
import radium from "radium";

const style = {
  base: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "22px",
    textShadow: "0px 0px 2px rgba(0, 0, 0, 0.15)",
    color: "rgba(0, 0, 0, 0.4)",
    marginLeft: "1px",
    display: "block",
    top: "7px",
  },
};

class ReactSelectLabel extends React.Component {
  render() {
    return (
      <label style={[{...style.base, ...this.props.style}]}>
        {this.props.children}
      </label>
    );
  }
}

export default radium(ReactSelectLabel);

/**
 * Deletes empty nested objects. It iterates from the end object at `path` to `root`.
 *
 * @param {Object} root The root object to modify.
 * @param {string} path The path of the nested objects to delete if empty.
 * @returns {void}
 *
 * @example
 *
 * const object = { a: { b: { c1: { d1: {} }, c2: { d2: {} } } } };
 *
 * deleteNestedObjectsIfEmpty(object, "a.b.c2.d2");
 * // => { a: { b: { c1: { d1: {} } } } }
 */

/* eslint-disable no-prototype-builtins */
function deleteNestedObjectsIfEmpty(root, path) {
  const propNames = path.split(".");
  let current = {object: root};
  const list = [];

  while (propNames.length) {
    const propName = propNames.shift();
    if (current.object.hasOwnProperty(propName)) {
      const value = current.object[propName];
      if (value === null || typeof value !== "object") {
        return;
      }
      current = {
        propName,
        object: current.object[propName],
        parentObject: current.object,
      };
      list.push(current);
    } else {
      break;
    }
  }

  /* eslint-disable no-plusplus */
  for (let index = list.length - 1; index > -1; index--) {
    const item = list[index];
    if (Object.keys(item.object).length === 0) {
      delete item.parentObject[item.propName];
    } else {
      return;
    }
  }
  /* eslint-disable no-plusplus */
}

export default deleteNestedObjectsIfEmpty;

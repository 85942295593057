import UNINITIALISED from "utils/uninitialised";
import ActionTypes from "../constants/action_types";

export default function clauseBankReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.CLAUSE_BANK_FETCH.SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

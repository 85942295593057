import _ from "lodash";

export function splitCoordinatedChars(node, charList = []) {
  try {
    if (node.nodeType !== node.TEXT_NODE) {
      node.childNodes.forEach(childNode =>
        splitCoordinatedChars(childNode, charList),
      );
    } else {
      const range = document.createRange();
      range.selectNodeContents(node);
      let currentPosition = 0;
      const {endOffset: endPosition} = range;
      while (currentPosition < endPosition) {
        const index = charList.length;
        range.setStart(node, currentPosition);
        range.setEnd(node, currentPosition + 1);
        const char = range.toString();
        const prevChar = index > 0 ? charList[index - 1] : null;
        const rect = _.pick(range.getBoundingClientRect(), [
          "x",
          "y",
          "left",
          "right",
          "top",
          "bottom",
          "width",
          "height",
        ]);
        const isSpace = Boolean(char.match(/\s/));
        const isBreakSpace =
          isSpace &&
          prevChar &&
          rect.left < prevChar.rect.left &&
          rect.top < prevChar.rect.bottom;
        const charData = {
          index,
          char,
          prevChar,
          nextChar: null,
          rect,
          isSpace,
          isBreakSpace,
        };
        if (prevChar) {
          prevChar.nextChar = charData;
        }
        charList.push(charData);
        currentPosition += 1;
      }
    }
  } catch {
    // handle error here
  }
  return charList;
}

export function findCharByXY(charList, x, y) {
  return charList.find(char => {
    const {isBreakSpace} = char;
    const {left, right, top, bottom} = char.rect;
    return !isBreakSpace && left <= x && right >= x && top <= y && bottom >= y;
  });
}

export function getEditorCaretOffsetByChar(char) {
  if (char.isSpace && char.nextChar) {
    return getEditorCaretOffsetByChar(char.nextChar);
  } else if (!char.isSpace) {
    if (!char.prevChar || char.prevChar.isSpace) {
      return char.index;
    }
    return getEditorCaretOffsetByChar(char.prevChar);
  }
}

export function getEditorCaretOffsetByXY(node, x, y) {
  const charList = splitCoordinatedChars(node);
  const char = findCharByXY(charList, x, y);
  if (char) {
    return getEditorCaretOffsetByChar(char);
  }
}

export default {
  DOCUMENT_EDIT: "DOCUMENT_EDIT",
  DOCUMENT_DOWNLOAD: "DOCUMENT_DOWNLOAD",
  DOCUMENT_ALTERED_DOWNLOAD: "DOCUMENT_ALTERED_DOWNLOAD",
  DOCUMENT_UPDATE: "DOCUMENT_UPDATE",
  INITIAL_ISSUES: "INITIAL_ISSUES",
  DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
  DOCUMENT_CREATE: "DOCUMENT_CREATE",
  DOCUMENT_REVIEW: "DOCUMENT_REVIEW",
  DOCUMENT_DELETE: "DOCUMENT_DELETE",
  FORM_TEMPLATE_UPLOAD: "FORM_TEMPLATE_UPLOAD",
  DOCUMENT_CLAUSE_TOPIC_ADD: "DOCUMENT_CLAUSE_TOPIC_ADD",
  DOCUMENT_CLAUSE_TOPIC_REMOVE: "DOCUMENT_CLAUSE_TOPIC_REMOVE",
  DOCUMENT_CLAUSE_TOPIC_CONFIRM: "DOCUMENT_CLAUSE_TOPIC_CONFIRM",
  TOPIC_CLASSIFIER_REGENERATED: "TOPIC_CLASSIFIER_REGENERATED",
  EMAIL_REPORT: "EMAIL_REPORT",
  DOCUMENT_UPLOAD_TO_API: "DOCUMENT_UPLOAD_TO_API",
  DOCUMENT_STATE_REVERT: "DOCUMENT_STATE_REVERT",
  DOCUMENT_RECLASSIFY: "DOCUMENT_RECLASSIFY",

  PROJECT_ADD: "PROJECT_ADD",
  PROJECT_UPDATE: "PROJECT_UPDATE",
  PROJECT_DELETE: "PROJECT_DELETE",

  CONTRACT_TYPE_ADD: "CONTRACT_TYPE_ADD",
  CONTRACT_TYPE_UPDATE: "CONTRACT_TYPE_UPDATE",
  CONTRACT_TYPE_REMOVE: "CONTRACT_TYPE_REMOVE",

  ISSUESET_ADD: "ISSUESET_ADD",
  ISSUESET_UPDATE: "ISSUESET_UPDATE",
  ISSUESET_REMOVE: "ISSUESET_REMOVE",

  TOPIC_UPDATE: "TOPIC_UPDATE",
  TOPICS_UPDATE: "TOPICS_UPDATE",
  TOPIC_CLASSIFIER_UPDATE: "TOPIC_CLASSIFIER_UPDATE",

  ISSUE_ADD: "ISSUE_ADD",
  ISSUE_UPDATE: "ISSUE_UPDATE",
  ISSUE_REMOVE: "ISSUE_REMOVE",
  ISSUES_UPDATE: "ISSUES_UPDATE",
  ISSUES_FIND: "ISSUES_FIND",

  DOCUMENT_ISSUE_VERIFY: "DOCUMENT_ISSUE_VERIFY",
  DOCUMENT_ISSUE_UPDATE: "DOCUMENT_ISSUE_UPDATE",
  DOCUMENT_ISSUES_UPDATE: "DOCUMENT_ISSUES_UPDATE",

  DOCUMENT_ISSUE_MANUAL_CORRECT: "DOCUMENT_ISSUE_MANUAL_CORRECT",

  DOCUMENT_ISSUE_GENERATE_PROMPT: "DOCUMENT_ISSUE_GENERATE_PROMPT",
  DOCUMENT_GENERATE_PROMPT: "DOCUMENT_GENERATE_PROMPT",

  TOPICPARAMETER_ADD: "TOPICPARAMETER_ADD",
  TOPICPARAMETER_UPDATE: "TOPICPARAMETER_UPDATE",
  TOPICPARAMETER_DELETE: "TOPICPARAMETER_DELETE",

  TOPICPARAMETER_ACTUAL_VALUES_UPDATE: "TOPICPARAMETER_ACTUAL_VALUES_UPDATE",
  TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE:
    "TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE",
  TOPICPARAMETER_CLASSIFY: "TOPICPARAMETER_CLASSIFY",

  CLIENT_ADD: "CLIENT_ADD",
  DOCUMENT_CLAUSE_CHANGE_STATE_REVERT: "DOCUMENT_CLAUSE_CHANGE_STATE_REVERT",
  CLAUSEPART_COMMENT_ADD: "CLAUSEPART_COMMENT_ADD",
  CLAUSEPART_COMMENT_DELETE: "CLAUSEPART_COMMENT_DELETE",
  CLAUSEPART_COMMENT_UPDATE: "CLAUSEPART_COMMENT_UPDATE",

  DEFINITION_GROUP_ADD: "DEFINITION_GROUP_ADD",
  DEFINITION_GROUP_UPDATE: "DEFINITION_GROUP_UPDATE",
  DEFINITION_GROUP_REMOVE: "DEFINITION_GROUP_REMOVE",

  ISSUE_RESPONSE_ADD: "ISSUE_RESPONSE_ADD",
  ISSUE_RESPONSE_UPDATE: "ISSUE_RESPONSE_UPDATE",
  ISSUE_RESPONSE_DELETE: "ISSUE_RESPONSE_DELETE",

  ISSUE_COMMENT_ADD: "ISSUE_COMMENT_ADD",
  ISSUE_COMMENT_UPDATE: "ISSUE_COMMENT_UPDATE",
  ISSUE_COMMENT_DELETE: "ISSUE_COMMENT_DELETE",

  ROLE_ADD: "ROLE_ADD",
  ROLE_UPDATE: "ROLE_UPDATE",
  ROLE_REMOVE: "ROLE_REMOVE",

  CLAUSE_TOPIC_LOGS_FETCH: "CLAUSE_TOPIC_LOGS_FETCH",

  CLAUSE_BANK_FETCH: "CLAUSE_BANK_FETCH",

  TOPIC_LIST_TOPIC_ADD: "TOPIC_LIST_TOPIC_ADD",
};

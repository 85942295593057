// from: https://gist.github.com/Craga89/5215e0e5079741b856d8
import _ from "underscore";
import escapeRegex from "utils/escape_regex";

function isSelected(values, selectedValue) {
  if (!values || values.length < 1) {
    return false;
  }
  let idx;
  let valuesLength;
  for (idx = 0, valuesLength = values.length; idx < valuesLength; idx += 1) {
    const value = values[idx].value;
    if (selectedValue === value) {
      return true;
    }
  }

  return false;
}

export function filterOptions(options, filter, currentValues) {
  const filterNormalized = filter.toLowerCase().trim();
  const filteredOptions = options.reduce((memo, option) => {
    // Optgroup support
    const isOptionSelected = isSelected(currentValues, option.value);
    if (!("value" in option)) {
      memo.push(option);
    } else if (filter && filter.length >= 1) {
      // If a filter is present
      const valueNormalized = option.label.toLowerCase().trim();

      if (
        valueNormalized.match(
          new RegExp(escapeRegex(filterNormalized).replace(/\s/g, ".*")),
        ) &&
        !isOptionSelected
      ) {
        memo.push(option);
      }
    } else if (!isOptionSelected) {
      // Show everything available that isn't already selected
      // if no filter is used
      memo.push(option);
    }

    return memo;
  }, []);

  const sortedOptions = _.sortBy(filteredOptions, topic => {
    const nameGroups = topic.label.split("/");
    const lastGroup = nameGroups[nameGroups.length - 1].toLowerCase().trim();
    if (filterNormalized === lastGroup) {
      return -3;
    }
    if (lastGroup.startsWith(filterNormalized)) {
      return -2;
    }
    return lastGroup.indexOf(filterNormalized) > 0 ? -1 : 0;
  });

  const extraOptions = [];
  if (
    (sortedOptions.length === 0 && (filter && filter.length > 0)) ||
    (filter && filter.length >= 2)
  ) {
    extraOptions.push({
      label: `Add ${filter}`,
      value: filter,
      create: true,
    });
  }

  return extraOptions.concat(sortedOptions);
}

export function updateSelectionSingle(value) {
  if (value.create) {
    value.create = false;
    value.label = value.value.trim();
    value.value = value.label;
  }
  return value;
}
export function updateSelection(values) {
  if (values) {
    return values.map(value => {
      return updateSelectionSingle(value);
    });
  }
  return [];
}

import React from "react";

import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import IssuesetInfoWidget from "common_components/issueset_info_widget";

const autocompleteStyles = {
  root: {
    width: "100%",
  },
  popper: {
    width: "auto !important",
  },
  paper: {
    borderRadius: "0px",
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    fontSize: "14px",
    padding: "6px",
  },
};
const autocompleteStylesFlipped = {
  ...autocompleteStyles,
  root: {
    width: "100%",
    "& .MuiInput-underline:before": {
      borderColor: "#bdbdbd !important",
    },
  },
  input: {
    color: "#424242 !important",
  },
  popupIndicator: {
    "& .MuiSvgIcon-root": {
      color: "#bdbdbd !important",
    },
  },
};

const useAutocompleteBaseStyles = makeStyles(autocompleteStyles);
const useAutocompleteBaseStylesFlipped = makeStyles(autocompleteStylesFlipped);

const useAutocompleteTextfieldStyles = makeStyles({
  underline: {
    "&:before": {
      borderBottom: "1px solid #fff",
      transition: "unset",
    },
    "&&:hover:before": {
      borderBottom: "2px solid #fff",
    },
    "&:after": {
      borderBottom: "1px solid #fff",
      transition: "unset",
    },
  },
});

function PopperBottomStart(props) {
  return <Popper placement="bottom-start" {...props} />;
}

function IssuesetSelectorAutocomplete(props) {
  const {
    issuesets,
    isOnIssueDetailPage,
    showSelectChecklistDialog,
    isFlipChecklistIcons,
    user,
    currentIssueset,
  } = props;

  function onIssuesetChange(e, option) {
    if (option.value === "other_checklists_dialog") {
      showSelectChecklistDialog && showSelectChecklistDialog();
    } else {
      props.onCurrentIssuesetChange(null, null, option.value);
    }
  }
  const baseClasses = isFlipChecklistIcons
    ? useAutocompleteBaseStylesFlipped()
    : useAutocompleteBaseStyles();
  const textFieldClasses = useAutocompleteTextfieldStyles();

  function renderInput(params) {
    const newInputLabelProps = {
      ...params.InputLabelProps,
      style: {color: "#9ca1a5"},
    };
    const newInputProps = {
      ...params.inputProps,
      style: {color: "#fff", width: "auto"},
    };
    const InputPropsNew = {
      ...params.InputProps,
      classes: textFieldClasses,
    };
    return (
      <TextField
        {...params}
        style={{width: "100%"}}
        InputLabelProps={newInputLabelProps}
        InputProps={InputPropsNew}
        inputProps={newInputProps}
        label="Checklist"
      />
    );
  }

  function getOptionDisabled(option) {
    if (option.value === "divider") {
      return true;
    }
    if (option.value === "other_checklists_dialog") {
      return false;
    }
    return isOnIssueDetailPage
      ? option.disabled || !option.projects
      : option.disabled;
  }

  function getOptionLabel(option) {
    return option.label || option.primaryText || "";
  }

  function renderOption(item) {
    if (item.value === "divider") {
      return (
        <div
          style={{
            height: "2px",
            width: "100%",
            borderBottom: "1px solid #fff",
          }}
        />
      );
    } else if (item.value === "other_checklists_dialog") {
      return (
        <div
          style={{display: "flex", alignItems: "center"}}
          onClick={props.showSelectChecklistDialog}
        >
          <AddIcon style={{width: "18px", height: "18px", color: "#fff"}} />
          <div style={{color: "#fff", margin: "0 12px"}}>
            View Other Checklists
          </div>
        </div>
      );
    }
    return (
      <div
        onClick={
          !item.isInSelectedProject &&
          item.projects &&
          props.getIssuesetsFromContractTypes
            ? props.onIssuesetItemProjectAndDocumentChange(item)
            : undefined
        }
        disabled={
          isOnIssueDetailPage ? item.disabled || !item.projects : item.disabled
        }
        style={{
          color: isFlipChecklistIcons ? "#424242" : "#fff",
          overflow: "hidden",
          whiteSpace: "nowrap",
          marginRight: "8px",
        }}
      >
        <div
          style={{
            fontWeight: item.isInSelectedProject ? "600" : "400",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{paddingLeft: item.level * 15}} />
          <span>{item.primaryText}</span>
          {user.is_admin && (
            <IssuesetInfoWidget
              isInStarIssueset={item.is_star}
              isHot={item.is_hot}
              ignoreIssueStar={true}
            />
          )}
          {isOnIssueDetailPage &&
            item.level !== 0 &&
            !item.projects && (
              <span style={{fontStyle: "italic"}}>
                {" (Not in any project)"}
              </span>
            )}
        </div>
      </div>
    );
  }
  const selectedValue = issuesets.find(
    issueset => issueset.value === currentIssueset,
  );
  return (
    <>
      <Autocomplete
        disableClearable={true}
        blurOnSelect={true}
        popupIcon={<ArrowDropDownIcon style={{color: "#fff"}} />}
        classes={baseClasses}
        value={selectedValue}
        options={issuesets}
        onChange={onIssuesetChange}
        renderInput={renderInput}
        getOptionLabel={getOptionLabel}
        PopperComponent={PopperBottomStart}
        getOptionDisabled={getOptionDisabled}
        ListboxProps={{
          style: {
            backgroundColor: isFlipChecklistIcons ? "#fff" : "#333d46",
            fontSize: "14px",
          },
        }}
        renderOption={renderOption}
      />
      {user.is_admin &&
        selectedValue && (
          <IssuesetInfoWidget
            isInStarIssueset={selectedValue.is_star}
            isHot={selectedValue.is_hot}
            ignoreIssueStar={true}
          />
        )}
    </>
  );
}

export default IssuesetSelectorAutocomplete;

import UNINITIALISED from "utils/uninitialised";

import UserActionTypes from "modules/user/constants/action_types";
import OrganisationActionTypes from "modules/organisation/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function topicUpdatesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.TOPICS_FETCH.SUCCESS:
    case ActionTypes.TOPICS_INFO_FETCH.SUCCESS:
      if (state === UNINITIALISED) {
        return 0;
      }
      return state;
    case OrganisationActionTypes.ORGANISATION_CHANGE.SUCCESS:
    case ActionTypes.TOPIC_DELETE.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD.REQUEST:
    case ActionTypes.CLAUSES_TOPIC_ADD.REQUEST:
    case ActionTypes.TOPIC_UPDATE.REQUEST:
    case ActionTypes.TOPICS_UPDATE.REQUEST: {
      if (state === UNINITIALISED) {
        return state;
      }
      /* eslint-disable-next-line no-param-reassign */
      return ++state;
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

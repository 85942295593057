const {Writable} = require("stream");

export class DumpStream extends Writable {
  constructor() {
    super();
    this._data = [];
  }

  _write(chunk, encoding, callback) {
    this._data.push(chunk);
    callback();
  }

  getData() {
    return Buffer.concat(this._data);
  }
}

import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";

export default function logsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.REPORTS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.REPORTS_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.DOCUMENT_REPORT_FETCH.SUCCESS: {
      if (!state?.reports) {
        return state;
      }
      return {
        ...state,
        reports: state.reports.map(document => {
          if (document.id === action.payload.document.id) {
            const result = {
              ...document,
              ...action.payload.document,
              report_issues: action.payload.document.report_issues,
            };
            return result;
          }
          return document;
        }),
      };
    }
    case ActionTypes.REPORTS_FETCH.REQUEST:
      return UNINITIALISED;
    default:
      return state;
  }
}

import {
  FixedIcon,
  GuidanceIcon,
  IgnoreIcon,
  IssueIcon,
  NoIssueIcon,
  WarningIcon,
} from "constants/icons";

export const initialFiltersState = {
  issue: false,
  warning: false,
  notIssues: false,
  guidance: false,
  actioned: false,
  ignored: false,
  sort: "Default",
};

export const filterIssueState = [
  {
    title: "Issues",
    icon: IssueIcon,
    iconStyle: {color: "#ff0000"},
    type: "issueState",
    value: "issue",
  },
  {
    title: "Warning",
    icon: WarningIcon,
    iconStyle: {color: "#ff8800"},
    type: "issueState",
    value: "warning",
  },
  {
    title: "Not Issues",
    icon: NoIssueIcon,
    iconStyle: {color: "#00c853"},
    type: "issueState",
    value: "notIssues",
  },
  {
    title: "Guidance",
    icon: GuidanceIcon,
    iconStyle: {color: "#9e9e9e"},
    type: "issueState",
    value: "guidance",
  },
  {
    title: "Actioned",
    icon: FixedIcon,
    iconStyle: {color: "#42a4f5"},
    type: "issueState",
    value: "actioned",
  },
  {
    title: "Ignored",
    icon: IgnoreIcon,
    iconStyle: {color: "#9e9e9e"},
    type: "issueState",
    value: "ignored",
  },
];

export const filterReviewState = [
  {
    title: "Needs Review",
    type: "escalatedReviewState",
    value: "needReview",
  },
  {
    title: "Does Not Need Review",
    type: "escalatedReviewState",
    value: "dontNeedReview",
  },
];

export const filterReadState = [
  {
    title: "Unread",
    type: "reviewState",
    value: "unread",
  },
  {
    title: "Read",
    type: "reviewState",
    value: "read",
  },
];

export const sortBy = [
  {
    title: "Default",
    description: "Ordered - As issues appear in the document",
    type: "sort",
    value: "Default",
  },
  {
    title: "State",
    description: "Ordered - Red, Amber, Blue, Grey, Green, Ignored",
    type: "sort",
    value: "State",
  },
  {
    title: "Needs Review",
    description: "Ordered - Marked for review, Unread",
    type: "sort",
    value: "Needs Review",
  },
];

export const filtersState = [
  ...filterIssueState,
  ...filterReviewState,
  ...filterReadState,
];

import convertUpdatesToOverridesIfAvailable from "./convert_updates_to_overrides_if_available";

const createIssuesetOverrideData = (item, options, issue, updates) => {
  if (options && options.merge_update_object) {
    options.data_path = !item.is_duplicate_on_master
      ? `${item.master_id}.master`
      : `${item.master_id}.${item.remote_client_id}`;
    options.update_override_document_issue_values = true;
  } else {
    convertUpdatesToOverridesIfAvailable(issue, updates, item);
  }
};

export default createIssuesetOverrideData;

function getUsername(user) {
  if (!user) {
    return "";
  }
  const {first_name: firstName, last_name: lastName, username: userName} = user;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return userName || "";
}

module.exports = {getUsername};

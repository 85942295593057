import cleanProjectName from "./clean_project_name";

export default function getSegmentedProjectName(name) {
  const parts = cleanProjectName(name).split(" / ");
  const wholeName = parts.join(" / ");
  let parentPath = null;
  let lastSegment = null;
  if (parts.length < 2) {
    lastSegment = parts[0];
  } else {
    parentPath = parts.slice(0, -1).join(" / ");
    lastSegment = parts.slice(-1).shift();
  }
  return {wholeName, parentPath, lastSegment};
}

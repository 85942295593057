import UNINITIALISED from "utils/uninitialised";
import ActionTypes from "../constants/action_types";

export default function searchReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.SEARCH.REQUEST:
      return {...state, processing: true};
    case ActionTypes.SEARCH.SUCCESS:
      return {results: action.payload, processing: false};
    case ActionTypes.SEARCH_CLEAR.SUCCESS:
      return UNINITIALISED;
    default:
      return state === UNINITIALISED
        ? {results: UNINITIALISED, processing: false}
        : state;
  }
}

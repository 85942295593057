import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import {GetLlmRunAction} from "../actions/llm_run_fetch";
import actionTypes from "../constants/action_types";
import {EnhancedLlmRun} from "common/flowmaster/types/llm_run";

export default function workflowsReducer(
  state: MaybeUninitialised<EnhancedLlmRun> = UNINITIALISED,
  action: GetLlmRunAction,
): typeof state {
  if (action.type === actionTypes.LLM_RUN_FETCH.SUCCESS) {
    return (action as GetLlmRunAction).payload;
  }
  return state;
}

import {Document} from "common/types/document";
import {Project} from "common/types/project";

function areReportsEnabled(
  document: Document & {are_roles_confirmed: boolean},
  project: Project,
): boolean {
  return document.are_roles_confirmed || !project.force_party_confirmation;
}

export default areReportsEnabled;

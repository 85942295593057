/* eslint-disable no-console */
function log(arg1, arg2) {
  if (arguments.length === 3) {
    console.log(arg1, JSON.stringify(arg2));
    return arg2;
  }
  console.log(JSON.stringify(arg1));
  return arg1;
}

export default log;

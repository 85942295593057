import Accept from "icons/Accept";
import CirclePause from "icons/CirclePause";
import DocumentApproval from "icons/DocumentApproval";
import DocumentSearch from "icons/DocumentSearch";
import Flag from "icons/Flag";
import Search from "icons/Search";
import SearchAndApps from "icons/SearchAndApps";
import Warning from "icons/Warning";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InfoIcon from "@material-ui/icons/Info";

export const IssueIcon = Warning;
export const NoIssueIcon = Accept;
export const WarningIcon = Flag;
export const GuidanceIcon = InfoIcon;
export const FixedIcon = DocumentApproval;
export const IgnoreIcon = CirclePause;
export const DocumentSearchIcon = DocumentSearch;
export const SearchIcon = Search;
export const SearchAndAppsIcon = SearchAndApps;
export const HiddenIcon = VisibilityOff;

export const issueIcons = {
  IssueIcon: Warning,
  NoIssueIcon: Accept,
  WarningIcon: Flag,
  FixedIcon: DocumentApproval,
  IgnoreIcon: CirclePause,
  HiddenIcon: VisibilityOff,
  GuidanceIcon: InfoIcon,
};

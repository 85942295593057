import React from "react";
import * as jsDiff from "diff";

import InfoIcon from "@material-ui/icons/Info";
import Popover from "@material-ui/core/Popover";

import {makeStyles} from "@material-ui/core/styles";

const styles = {
  infoIcon: {
    cursor: "pointer",
    color: "#616161",
  },
};

const useStyles = makeStyles(() => ({
  popover: {
    // this is used to prevent onMouseLeave firing immediately afte mouseEnter
    pointerEvents: "none",
  },
  paper: {
    borderRadius: 0,
  },
}));

function TopicparameterTextValueDiff(props) {
  const classes = useStyles();

  const [isDiffShown, updateIsDiffShown] = React.useState(false);
  const triggerIsDiffshown = () => {
    updateIsDiffShown(!isDiffShown);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    updateIsDiffShown(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const renderDiff = () => {
    const diff = jsDiff.diffChars(props.liveValue, props.value);
    return diff.map((item, index) => (
      <span
        key={index}
        style={{color: item.added ? "green" : item.removed ? "red" : "black"}}
      >
        {item.value}
      </span>
    ));
  };

  return (
    <>
      <InfoIcon
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={triggerIsDiffshown}
        style={{
          ...styles.infoIcon,
          ...(props.iconStyles || {}),
        }}
      />
      <Popover
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div style={{padding: 12}}>
          <div
            style={{
              marginBottom: 8,
              fontSize: 12,
              color: "#9e9e9e",
              textAlign: "right",
            }}
          >
            Click on the (i) icon to see diff/prev value
          </div>
          {isDiffShown ? renderDiff() : props.liveValue}
        </div>
      </Popover>
    </>
  );
}

export default TopicparameterTextValueDiff;

import Tooltip from "@material-ui/core/Tooltip";
import ReplayIcon from "@material-ui/icons/Replay";
import React from "react";

const styles = {
  icon: {
    cursor: "pointer",
    marginLeft: "4px",
    width: "18px",
    height: "18px",
  },
};

const RevertButton = ({onRevertChanges}) => {
  return (
    <Tooltip title={"Revert changes"} enterDelay={1000} placement="top" arrow>
      <ReplayIcon
        onMouseDown={onRevertChanges}
        style={{
          ...styles.icon,
          color: "#757575",
        }}
      />
    </Tooltip>
  );
};

export default RevertButton;

import React from "react";
import _ from "underscore";

import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import {List, ListItem} from "material-ui/List";

import NavItem from "routes/logged_in/components/sidebar/nav_item";
import SidebarPanel from "routes/logged_in/components/sidebar/panel";

import isSectionInSelectedGroup from "../../../../utils/is_section_in_selected_group";

const styles = {
  list: {
    padding: 10,
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
  },
  mainContentItem: {
    padding: 0,
  },
  listItem: {
    paddingLeft: "3px",
    paddingRight: "3px",
    paddingTop: "3px",
    paddingBottom: "3px",
    fontSize: "13px",
    fontWeight: 100,
  },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};

styles.sectionListItem = {
  ...styles.listItem,
  fontWeight: 400,
  textAlign: "center",
  textTransform: "uppercase",
};

export default class ContentItem extends React.Component {
  render() {
    const contents = this.props.documentSections
      .filter(section => !section.is_hidden)
      .map(section => {
        const isSectionSelected =
          this.props.selectedChecklistSectionId &&
          isSectionInSelectedGroup(
            section.id,
            this.props.selectedChecklistSectionId,
            this.props.checklistSections,
          );

        const backgroundStyle = this.props.selectedChecklistSectionId
          ? isSectionSelected ? {} : {background: "#eeeeee"}
          : {};
        const headings = this.props.documentHeadings.filter(
          heading => heading.section_id === section.id,
        );
        if (headings.length === 0) {
          return null;
        }
        const onSectionScroll = () => {
          this.props.scrollToSection(section);
        };
        const sectionHeading = (
          <SelectableListItem
            innerDivStyle={{
              ...styles.sectionListItem,
              ...backgroundStyle,
            }}
            key={section.id}
            primaryText={
              section.name
                ? `${section.reference} - ${section.name}`
                : section.reference
            }
            onClick={onSectionScroll}
          />
        );
        const clauseHeadings = headings.map(heading => {
          const text = headingText(heading);

          const onHeadingScroll = () => {
            this.props.scrollToHeading(heading);
          };
          return (
            <SelectableListItem
              className="list-item"
              innerDivStyle={{
                ...styles.listItem,
                ...backgroundStyle,
              }}
              key={heading.id}
              primaryText={<div style={styles.text}>{text}</div>}
              onClick={onHeadingScroll}
              selected={heading.reference === this.props.selectedHeading}
              visible={_.find(
                this.props.headingVisibility[heading.fullReference],
                view => view.isVisible,
              )}
              collapsed={this.props.hiddenHeadings[heading.reference]}
            />
          );
        });
        return [sectionHeading, ...clauseHeadings];
      });

    return (
      <NavItem
        isCollapsed={true}
        icon={<FormatListNumberedIcon />}
        collapsedTitle={"Table of Contents"}
        panelChildren={
          <SidebarPanel headerText="Contents">
            <List style={styles.list}>{_.flatten(contents)}</List>
          </SidebarPanel>
        }
      />
    );
  }
}

ContentItem.Icon = FormatListNumberedIcon;

class SelectableListItem extends React.Component {
  render() {
    const {selected, visible, collapsed} = this.props;
    return (
      <ListItem
        {..._.omit(this.props, "visible", "collapsed")}
        style={this.determineStyle(selected, visible, this.props.style)}
        innerDivStyle={this.determineInnerStyle(
          selected,
          visible,
          collapsed,
          this.props.innerDivStyle,
        )}
      />
    );
  }

  determineStyle = _.memoize(
    (selected, visible, style) => ({
      ...(style || {}),
      ...(selected ? {backgroundColor: "red"} : {}),
    }),
    (...args) => JSON.stringify([...args]),
  );

  determineInnerStyle = _.memoize(
    (selected, visible, collapsed, style) => ({
      ...(style || {}),
      ...(visible ? {fontWeight: 400} : {}),
      ...(collapsed ? {color: "#999"} : {}),
    }),
    (...args) => JSON.stringify([...args]),
  );
}

function headingText(heading) {
  return [
    heading.prefixCounter ? `${heading.prefixCounter}.` : "",
    " ",
    heading.text.charAt(0).toUpperCase(),
    heading.text.substring(1).toLowerCase(),
  ].join("");
}

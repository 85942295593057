import _ from "underscore";

function calculateClauseReferences(clauses) {
  const refs = _.uniq(
    clauses.map(clause => {
      return clause.display_reference;
    }),
  );
  if (refs.length === 1) {
    return refs[0];
  }
  if (refs.length === 2) {
    return refs.join(" & ");
  }
  if (refs.length >= 3) {
    return `${_.initial(refs).join(", ")} & ${_.last(refs)}`;
  }
  return null;
}

export default calculateClauseReferences;

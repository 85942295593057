function groupClausesByTopics(
  clauses,
  topicsById,
  useParameters,
  additionalClauses = {}, // mainly used by commonDefinitions
) {
  const result = {};
  clauses.forEach(clause => {
    if (clause.topics.length === 0) {
      if (!result.no_topic) {
        result.no_topic = [];
      }
      result.no_topic = [clause, ...result.no_topic];
    }
    clause.topics.forEach(topic => {
      const topicFullName =
        topic.name || (topicsById[topic.topic_id] || {}).name || "";
      const topicName = getTopicName(topicFullName);
      if (!result[topicName]) {
        result[topicName] = [clause];
      } else {
        result[topicName].push(clause);
      }

      if (useParameters) {
        // TODO: FYI: this logic does not accout for same parameter
        // names across different topics. All clauses with the same
        // parameter name are grouped by one name
        (topic.topicparameters || []).forEach(parameter => {
          const {
            name: parameterName,
            actual_values: actualValues,
            values,
          } = parameter;
          const isDeleted =
            values &&
            values.length &&
            actualValues &&
            actualValues.length === 0;
          if (!isDeleted) {
            if (!result[parameterName]) {
              result[parameterName] = [clause];
              result[parameterName].isParameter = true;
            } else {
              result[parameterName].push(clause);
            }
          }
        });
      }
    });
  });

  return {
    ...result,
    ...additionalClauses,
  };
}

function getTopicName(fullName) {
  if (!fullName) {
    return "Topic Name Not Found";
  }
  const topicNameArray = fullName.split("/");
  return topicNameArray[topicNameArray.length - 1].trim();
}

export default groupClausesByTopics;

function pluraliseHelper(value, unit) {
  if (value === undefined || value === null) {
    return "";
  }
  if (!unit) {
    return value;
  }
  return `${value} ${unit}${unit && parseFloat(value) <= 1 ? "" : "s"}`.trim();
}

export default pluraliseHelper;

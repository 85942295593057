import clauseMatchesPriority from "./clause_matches_priority";

export default function(clauses, clausePriorities, issueId) {
  let highestPriority = 999999999;

  const clausesWithPriority = clauses.map(clause => {
    const clausePriority = clausePriorities.find(priority => {
      return clauseMatchesPriority(clause, priority, issueId);
    });
    if (clausePriority) {
      if (clausePriority.priority < highestPriority) {
        highestPriority = clausePriority.priority;
      }
      return {...clause, clausePriority};
    }
    return clause;
  });

  return clausesWithPriority.map(clause => {
    if (clause?.clausePriority?.priority === highestPriority) {
      clause.clausePriority.hasHighestPriority = true;
    }
    return clause;
  });
}

import React, {useRef, useEffect} from "react";
import {TextareaAutosize as MaterialTextareaAutosize} from "@material-ui/core";

const ACTIVE_BORDER = "2px solid #aaa";
const INACTIVE_BORDER = "1px solid #ddd";
const ACTIVE_MARGIN = "0px";
const INACTIVE_MARGIN = "1px";

const predefinedStyles = {
  extraLanguageAddTextField: {
    fontSize: "13px",
    fontFamily: "Roboto, sans-serif",
    color: "#555",
    border: "1px solid #ddd",
    padding: "0.5em 0.25em",
    outline: "none",
    margin: "1px",
  },
};

export default function TextareaAutosize({
  value,
  onChange,
  onClick,
  onBlur,
  disabled,
  styles,
  rows,
  autoFocus,
  placeholder,
}) {
  const ref = useRef(null);
  useEffect(
    () => {
      if (ref.current && autoFocus) {
        ref.current.focus();
      }
    },
    [autoFocus],
  );
  function onTextareaFocus() {
    setTimeout(() => {
      ref.current.style.border = ACTIVE_BORDER;
      ref.current.style.margin = ACTIVE_MARGIN;
    }, 50);
  }
  function onTextareaBlur() {
    setTimeout(() => {
      ref.current.style.border = INACTIVE_BORDER;
      ref.current.style.margin = INACTIVE_MARGIN;
    }, 50);
    typeof onBlur === "function" && onBlur();
  }
  return (
    <MaterialTextareaAutosize
      ref={ref}
      variant="outlined"
      style={{...predefinedStyles.extraLanguageAddTextField, ...(styles || {})}}
      value={value}
      onChange={onChange}
      onClick={onClick}
      onBlur={onTextareaBlur}
      onFocus={onTextareaFocus}
      rowsMax={10}
      disabled={disabled}
      placeholder={placeholder}
      {...(rows ? {rows} : {})}
    />
  );
}

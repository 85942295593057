import React, {useEffect, useState} from "react";
import InputBase from "@material-ui/core/InputBase";
import getTextFromClipboard from "../../../utils/text/get_text_from_clipboard";
import moveReferencesToTheTextLine from "../../../utils/text/move_references_to_text_line";
import fixLockedTextValue from "../utils/fix_locked_text_value";
import formatTrackedChanges from "../utils/format_tracked_changes";
import TextValue from "./text_value";
import Markdown from "common_components/markdown";

const styles = {
  input: {
    padding: 0,
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
  },
  inputPropsStyle: {
    style: {
      fontSize: 15,
    },
  },
  icon: {
    width: 18,
    height: 18,
    cursor: "pointer",
    borderRadius: "50%",
    marginLeft: "2px",
    color: "#757575",
  },
};

const EditableField = ({
  value,
  onEditSave,
  showButtonsHandler,
  saveTrigger,
  cancelTrigger,
  isTextFieldOnEditing,
  isTrackChangeMode,
  lockedTextValue,
  isMarkdown,
  issueTriggerType,
}) => {
  const [isTextfieldValueShown, onChangeIsTextfieldValueShown] = useState(
    false,
  );
  const [textValue, onTextValueUpdate] = useState(value);

  useEffect(() => {
    if (value !== textValue) {
      onTextValueUpdate(value);
    }
  }, [value]);

  useEffect(() => {
    onTextfieldBlur();
  }, [saveTrigger]);

  useEffect(() => {
    onCancelChanges();
  }, [cancelTrigger]);

  useEffect(() => {
    if (value !== textValue && isTextfieldValueShown) {
      if (showButtonsHandler) {
        showButtonsHandler(true);
      }
    }
  }, [textValue]);

  useEffect(() => {
    if (!lockedTextValue) {
      onTextValueUpdate(value);
    }
  }, [lockedTextValue]);

  useEffect(() => {
    if (isTrackChangeMode) {
      showTextfield();
    }
  }, [isTrackChangeMode]);

  const onCancelChanges = () => {
    hideTextField();
    onTextValueUpdate(value);
  };

  const showTextfield = () => {
    if (lockedTextValue) {
      onTextValueUpdate(fixLockedTextValue(lockedTextValue));
    }
    onChangeIsTextfieldValueShown(true);
    isTextFieldOnEditing(true);
  };

  const hideTextField = () => {
    if (showButtonsHandler) {
      showButtonsHandler(false);
    }
    onChangeIsTextfieldValueShown(false);
    isTextFieldOnEditing(false);
  };

  const onTextfieldBlur = () => {
    if (isTextfieldValueShown) {
      hideTextField();
      if (textValue !== value) {
        if (isTrackChangeMode || lockedTextValue) {
          onEditSave(value, formatTrackedChanges(textValue, value));
        } else {
          onEditSave(textValue);
        }
      }
    }
  };

  const moveCaretAtEnd = e => {
    const t = e.target.value;
    e.target.value = "";
    e.target.value = t;
  };

  const onTextValueChange = e => {
    onTextValueUpdate(e.target.value);
  };

  const onTextPaste = e => {
    e.preventDefault();
    const pastedText = getTextFromClipboard(e);
    const newTextValue = `${textValue.slice(
      0,
      e.target.selectionStart,
    )}${moveReferencesToTheTextLine(pastedText)}${textValue.slice(
      e.target.selectionEnd,
      textValue.length,
    )}`;
    onTextValueUpdate(newTextValue);
  };

  return (
    <div onDoubleClick={showTextfield}>
      {isTextfieldValueShown ? (
        <InputBase
          value={textValue === "Blank" ? "" : textValue}
          onChange={onTextValueChange}
          onBlur={onTextfieldBlur}
          onFocus={moveCaretAtEnd}
          multiline={true}
          autoFocus={true}
          inputProps={styles.inputPropsStyle}
          onPaste={onTextPaste}
          style={styles.input}
        />
      ) : isMarkdown ? (
        <Markdown ignoreLegacy={issueTriggerType === "LLM"}>
          {lockedTextValue ? lockedTextValue : textValue}
        </Markdown>
      ) : (
        <TextValue value={lockedTextValue ? lockedTextValue : textValue} />
      )}
    </div>
  );
};

export default EditableField;

import {useEffect} from "react";

type Options = {
  callback: () => void;
  delay: number;
  disabled?: boolean;
};

// WARN: Remember to memoize @param callback!
function useInterval({callback, delay, disabled}: Options) {
  useEffect(() => {
    if (disabled) {
      return;
    }
    const id = setInterval(callback, delay);
    return () => clearInterval(id);
  }, [callback, disabled, delay]);
}

export default useInterval;

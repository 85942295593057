import React from "react";

function initialise() {
  return {};
}

function validate() {
  return null;
}

class Component extends React.Component {
  render() {
    return <div>No Rules for Empty Parent issue type</div>;
  }
}

export default {
  initialise,
  validate,
  component: Component,
};

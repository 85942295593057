import UNINITIALISED from "utils/uninitialised";
import _ from "lodash";
import ActionTypes from "../constants/action_types";
import DocumentActionTypes from "../../documents/constants/action_types";

export default function searchByIssueReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.SEARCH_BY_ISSUE.REQUEST:
      return {...state, processing: true};
    case ActionTypes.SEARCH_BY_ISSUE.SUCCESS:
      return {results: action.payload, processing: false};
    case DocumentActionTypes.DOCUMENT_UPDATE.SUCCESS: {
      if (
        state &&
        state.results &&
        state.results !== UNINITIALISED &&
        action.payload &&
        action.payload.id
      ) {
        return {
          ...state,
          results: state.results.map(project => ({
            ...project,
            documents: project.documents.map(doc => {
              if (doc.id === action.payload.id) {
                return {
                  ...doc,
                  ..._.pick(action.payload, [
                    "last_edited",
                    "linked_definitions",
                  ]),
                };
              }
              return doc;
            }),
          })),
        };
      }
      return state;
    }
    case ActionTypes.SEARCH_BY_ISSUE_CLEAR.SUCCESS:
      return UNINITIALISED;
    default:
      return state === UNINITIALISED
        ? {results: UNINITIALISED, processing: false}
        : state;
  }
}

import UNINITIALISED from "utils/uninitialised";

import {
  updateStateWithRequest,
  updateStateWithSuccess,
} from "utils/ensure_fresh_update";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

/* eslint-disable complexity */
export default function topicCategoriesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.TOPIC_CATEGORIES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.TOPIC_CATEGORIES_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.TOPIC_UPDATE.SUCCESS: {
      if (action.payload.topiccategory_name) {
        return state.concat([
          {
            id: action.payload.topiccategory_id,
            name: action.payload.topiccategory_name,
            last_edited: action.payload.last_edited,
          },
        ]);
      }
      return state;
    }
    case ActionTypes.TOPIC_CATEGORY_UPDATE.REQUEST: {
      return state.map(category => {
        if (category.id === action.payload.id) {
          return {
            ...category,
            ...updateStateWithRequest(category, action.payload, [
              "id",
              "last_edited",
            ]),
          };
        }
        return category;
      });
    }
    case ActionTypes.TOPIC_CATEGORY_UPDATE.SUCCESS: {
      return state.map(category => {
        if (category.id === action.payload.id) {
          return updateStateWithSuccess(category, action.payload);
        }
        return category;
      });
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD.SUCCESS: {
      if (action.payload.topiccategory_name) {
        if (!state.find(cat => cat.id === action.payload.topiccategory_id)) {
          return state.concat([
            {
              id: action.payload.topiccategory_id,
              name: action.payload.topiccategory_name,
              last_edited: action.payload.last_edited,
            },
          ]);
        }
      }
      return state;
    }
    case ActionTypes.TOPICS_UPDATE.SUCCESS: {
      const topicWithNewCategory = action.payload.find(topic =>
        Boolean(topic.topiccategory_name),
      );
      if (topicWithNewCategory) {
        return state.concat([
          {
            id: topicWithNewCategory.topiccategory_id,
            name: topicWithNewCategory.topiccategory_name,
            last_edited: topicWithNewCategory.last_edited,
          },
        ]);
      }
      return state;
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

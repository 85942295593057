import {get} from "lodash";

function getIssueApplicableClausesByPath(issue, applicableClausesPath) {
  if (!issue || !applicableClausesPath) {
    return null;
  }
  const [masterIssuesetId, clientIssuesetId] = getIssuesetIds(
    applicableClausesPath,
  );
  const {applicable_clauses: applicableClauses} = issue;

  let result = get(
    applicableClauses,
    `${masterIssuesetId}.${clientIssuesetId}`,
  );

  if (!result) {
    result = get(applicableClauses, `${masterIssuesetId}.master`);
  }

  result = result ? result : get(applicableClauses, "base", []);
  return result.filter(clausepart => clausepart.partial_text);
}

function getIssuesetIds(issuesetPath) {
  if (issuesetPath === "base") {
    return ["base", null];
  }
  const splittedPath = issuesetPath.split(".");
  const masterIssuesetId = splittedPath[0];
  const clientIssuesetId = splittedPath[1];
  return [masterIssuesetId, clientIssuesetId];
}

export default getIssueApplicableClausesByPath;

import React from "react";
import SplitButton from "common_components/buttons/split_button";

type DownloadButtonProps = {
  onDownloadDocx: () => void;
  onDownloadXlsx?: () => void;
};

const DownloadButton = ({
  onDownloadDocx,
  onDownloadXlsx,
}: DownloadButtonProps) => (
  <SplitButton
    options={[
      {id: "docx", label: "Download as Word .docx"},
      ...(onDownloadXlsx ? [{id: "xlsx", label: "...as Excel .xlsx"}] : []),
    ]}
    containerStyles={{
      marginTop: "5px",
    }}
    size="small"
    color="primary"
    onClick={onDownloadDocx}
    onMenuCloseHandler={option =>
      option.id === "docx" ? onDownloadDocx() : onDownloadXlsx?.()
    }
    disableChangingSelectedOption
    excludeSelectedOptionFromMenu
    clickWithValue
  />
);
export default DownloadButton;

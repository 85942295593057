import template from "./template";
export default function extract(...args) {
  let offset = 0;
  const hasData = typeof args[0] === "object";
  if (!hasData) {
    offset += 1;
  }
  const topicId = args[1 - offset];
  const parameterId = args[2 - offset];
  const options = hasData ? {data: {root: {data: args[0]}}} : args[3 - offset];

  return template(
    null,
    `{{{join
      (unique
        (templateEach
          (listRaw data.[${topicId}].[${parameterId}] "value")
          "{{{pc value}}}"
        )
      )
    }}}`,
    options,
  );
}

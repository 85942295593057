/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-returns */
import {Result} from "common/types/util";

/**
 * This is a trival utility function to allow JSON to be parsed without the
 * caller writing their own try-catch. I think this reads better in cases where
 * there's no strong expectation for the JSON to actually be JSON, making it
 * likely that JSON.parse will throw. In such cases, errors are hardly
 * "exceptions" to the implied rule, and I don't like using the special
 * mechanism of throwing to implicitly handle what is effectively a normal branch
 * of the application logic. Besides, try/catch is ugly. Many braces. Sad!
 */
export const tryParseJSON = (
  input: string,
): Result<object | unknown[] | string | number | boolean | null> => {
  try {
    return {ok: true, value: JSON.parse(input)};
  } catch (e) {
    return {ok: false};
  }
};

export default tryParseJSON;

import React from "react";
import _ from "underscore";
import Paper from "material-ui/Paper";

import SvgIcon from "material-ui/SvgIcon";
import ExpandLessIcon from "material-ui/svg-icons/navigation/expand-less";
import ExpandMoreIcon from "material-ui/svg-icons/navigation/expand-more";

import getClauseTopicsGroups from "./utils/get_clause_topics_groups";
import constructRelatedClausesData from "utils/clauses/related_clauses/get_clause_atoms_data_by_topic_groups";
import renderRelatedClauses from "../../../utils/related_clauses/render_related_clauses";
import groupRelatedClausesByTopics from "../../../utils/related_clauses/group_related_clauses_by_topics_and_topic_groups";
import clampLine from "../../../utils/clamp_line";

const styles = {
  mainContainer: {
    paddingLeft: "0.2rem",
  },
  expandIcon: {
    height: "15px",
    width: "15px",
    position: "absolute",
    right: "16px",
  },
};

class RelatedClasues extends React.Component {
  state = {
    relatedClausesOpen: false,
  };

  componentDidMount() {
    this.clamp();
  }
  componentDidUpdate() {
    this.clamp();
  }
  clamp() {
    const elements = document.querySelectorAll(".clamped-clause");
    clampLine(elements);
  }

  render() {
    const {currentClause, headingStyles} = this.props;
    return (
      <div className="related-clauses" style={styles.mainContainer}>
        <Paper
          style={{
            ...headingStyles,
            zIndex: 0,
          }}
          onClick={this.onRelatedClausesOpen}
        >
          Related Clauses
          {this.state.relatedClausesOpen ? (
            <SvgIcon style={styles.expandIcon}>
              <ExpandMoreIcon />
            </SvgIcon>
          ) : (
            <SvgIcon style={styles.expandIcon}>
              <ExpandLessIcon />
            </SvgIcon>
          )}
        </Paper>
        {this.state.relatedClausesOpen &&
          this.getRelatedClausesList(currentClause)}
      </div>
    );
  }

  getRelatedClausesList(currentClause) {
    const topicGroups = getClauseTopicsGroups(
      currentClause.clause.nodes,
      this.props.topicsById,
      this.props.acceptedTopicCategoryIds,
    );
    // eliminating current selected clause from the clause list
    const documentClausesFiltered = _.mapObject(
      this.props.documentClauses,
      group => group.filter(clause => clause.id !== currentClause.clause.id),
    );
    // TODO: getting related clauses by related_topics. See document_detail
    const relatedClausesData = constructRelatedClausesData(
      topicGroups,
      documentClausesFiltered,
      this.props.topicsById,
      this.props.acceptedTopicCategoryIds,
    );
    const relatedClauses = groupRelatedClausesByTopics(relatedClausesData.data);
    return renderRelatedClauses(
      relatedClauses,
      this.props.scrollToClause,
      this.props.topicsById,
    );
  }

  onRelatedClausesOpen = () => {
    this.setState(() => ({relatedClausesOpen: !this.state.relatedClausesOpen}));
  };
}

export default RelatedClasues;

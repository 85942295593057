import getItemReturnValue from "./utils/get_item_return_value";

function getClauseReference(reasonData = [], searchType, value) {
  const matchedAtom = reasonData.find(
    atom => getItemReturnValue(atom, searchType) === value,
  );
  return matchedAtom ? matchedAtom.reference : "";
}

export default getClauseReference;

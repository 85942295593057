import _ from "underscore";

import topicGroupName from "utils/topic/topic_name_utils";
import getRelatedClauseAtomsByTopicGroups from "utils/clauses/related_clauses/get_related_clause_atoms_by_topic_group";

function constructRelatedClausesData(
  referencedTopics,
  documentClauses,
  topicsById,
  categoryIds,
  applicableClauses,
) {
  const topicGroupNames = _.chain(referencedTopics)
    .map(topicId =>
      _.first(topicsById[topicId].name.split("/"), 2)
        .join("/")
        .trim(),
    )
    .uniq()
    .value();

  const relatedClauses = {};
  topicGroupNames.forEach(groupName => {
    relatedClauses[
      topicGroupName.getTopicGroupName(groupName)
    ] = getRelatedClauseAtomsByTopicGroups(
      [groupName],
      documentClauses,
      topicsById,
      categoryIds,
      referencedTopics,
      applicableClauses,
    );
  });
  const data = _.pick(relatedClauses, group => group.length > 0);
  let clauseCount = 0;
  _.each(data, group => (clauseCount += group.length));
  return {data, clauseCount};
}

export default constructRelatedClausesData;

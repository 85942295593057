import _ from "underscore";
import path from "common/utils/path";

import issueDetailUtils from "./issue_detail_utils";

function findIssueInGroupedIssues(
  issueId,
  selectedChecklistSectionId,
  groupedIssues,
  shouldSearchInSubissues = false,
) {
  if (!issueId || !groupedIssues) {
    return;
  }
  let foundIssue;
  groupedIssues.forEach(finalIssue => {
    if (foundIssue) {
      return;
    }
    const {isGroup, item} = finalIssue;
    if (isGroup) {
      foundIssue = item.find(documentIssue => {
        if (
          issueDetailUtils.isSameDocumentIssueBySection(
            issueId,
            selectedChecklistSectionId,
            documentIssue,
          )
        ) {
          return true;
        } else if (documentIssue.underlyingIssues) {
          return Boolean(
            documentIssue.underlyingIssues.find(underlyingIssue =>
              issueDetailUtils.isSameDocumentIssueBySection(
                issueId,
                selectedChecklistSectionId,
                underlyingIssue,
              ),
            ),
          );
        }
        return false;
      });

      if (shouldSearchInSubissues) {
        for (const issue of item) {
          const subissues = path(issue, ["subissueData", "subissues"]);
          if (_.isArray(subissues)) {
            const subissue = subissues.find(
              _subissue =>
                _subissue &&
                issueDetailUtils.isSameDocumentIssueBySection(
                  issueId,
                  selectedChecklistSectionId,
                  _subissue,
                ),
            );
            if (subissue) {
              foundIssue = subissue;
              return;
            }
          }
        }
      }
    } else {
      if (
        item[0] &&
        issueDetailUtils.isSameDocumentIssueBySection(
          issueId,
          selectedChecklistSectionId,
          item[0],
        )
      ) {
        foundIssue = item[0];
      }
    }
  });
  return foundIssue;
}

export default findIssueInGroupedIssues;

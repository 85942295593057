import _ from "underscore";

function isEqual(valueA, valueB) {
  return (
    (valueA === null && valueB === null) ||
    (valueA?.role_id === valueB?.role_id &&
      _.intersection(
        Object.values(_.pick(valueA, ["value", "party_name", "party_term"])),
        Object.values(_.pick(valueB, ["value", "party_name", "party_term"])),
      ).length >= 1)
  );
}

export default isEqual;

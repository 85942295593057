import React from "react";

import GraphNode from "./graph_node";
import calculateNodeKey from "../../../../utils/calculate_node_key";

function TableRow(props) {
  const {node} = props;
  return (
    <tr>
      {node.clauseNodes.map((child, index) => (
        <GraphNode
          key={`${calculateNodeKey(props)}-${index}`}
          {...props}
          node={child}
          isTableRow={true}
        />
      ))}
    </tr>
  );
}

export default TableRow;

function getMatchingAtoms(reasonData, returnType, references) {
  if (
    returnType === "clausepart_reference" ||
    returnType === "clause_reference"
  ) {
    const refValues = references.map(ref => {
      const parts = ref.split("-");
      return {sectionId: parseInt(parts[0], 10), reference: parts[1]};
    });

    switch (returnType) {
      case "clausepart_reference":
        return reasonData.filter(item =>
          refValues.find(
            ref =>
              item.section_id === ref.sectionId &&
              item.reference === ref.reference,
          ),
        );
      case "clause_reference":
        return reasonData.filter(item =>
          refValues.find(
            ref =>
              item.section_id === ref.sectionId &&
              item.clause_reference === ref.reference,
          ),
        );
    }
  }

  switch (returnType) {
    case "clause_id":
      return reasonData.filter(item =>
        references.find(reference => item.clause_id === reference),
      );
    case "clausepart_id":
    default:
      return reasonData.filter(item =>
        references.find(reference => item.id === reference),
      );
  }
}

function getClausepartIds(reasonData = [], returnType, reference) {
  return getMatchingAtoms(reasonData, returnType, reference).map(
    item => item.id,
  );
}

export default getClausepartIds;

import React from "react";
import {get} from "lodash";

import getClausepartTextByReference from "common/utils/clauses/get_clausepart_text_by_reference";

import styles from "constants/text_changes_styles";
import companyDetailPlaceholderRegexString from "constants/company_detail_placeholder_regex_string";

import getFormatTextData from "./get_format_text_data";
import applyChanges from "utils/text/apply_text_changes";

import TextContainer from "./text_container";

function getStyles(classes, editModeOn) {
  let resultStyles = {};
  classes
    .filter(
      className =>
        className === "company_detail_placeholder" ? editModeOn : true,
    )
    .forEach(className => {
      resultStyles = {
        ...resultStyles,
        ...styles.textChanges[className],
      };
    });
  return resultStyles;
}

function getSubstringRegexIndex(str, substringRegex, n) {
  const searchRegex = new RegExp(substringRegex, "g");
  let match;
  let times = 0;
  let index = -1;
  while (times <= n && (match = searchRegex.exec(str)) !== null) {
    index = match.index;
    times += 1;
  }
  return index;
}

export default function formatText(
  fileExtension,
  textBeforeChanges,
  props,
  findText,
  hideTextDeletions,
  formattedText,
  templateValues = {},
  clausepartTextChanges,
) {
  const formatTextData = getFormatTextData(
    fileExtension,
    textBeforeChanges,
    props,
    findText,
    hideTextDeletions,
    formattedText,
    templateValues,
  );
  const {editModeOn} = props;
  const textAfterChanges = applyChanges(
    textBeforeChanges,
    clausepartTextChanges,
  );

  const companyDetail = get(props, "organisation.company_detail", {});
  const contextMenuItems = [
    companyDetail.company_name && {
      label: "Set Company Name",
      key: "company_name",
    },
    companyDetail.company_number && {
      label: "Set Company Number",
      key: "company_number",
    },
    companyDetail.address && {label: "Set Address", key: "address"},
  ].filter(item => item);

  function getOnCompanyDetailPlaceholderSave(
    companyDetailPlaceholderIndex,
    text,
  ) {
    return function onCompanyDetailPlaceholderSave(key) {
      if (!key) {
        return;
      }
      const companyDetailPlaceholderStart = getSubstringRegexIndex(
        textAfterChanges,
        companyDetailPlaceholderRegexString,
        companyDetailPlaceholderIndex,
      );
      const result = [
        textAfterChanges.substring(0, companyDetailPlaceholderStart),
        companyDetail[key],
        textAfterChanges.slice(companyDetailPlaceholderStart + text.length),
      ].join("");

      props.alterDocumentClausepartBinded(props.clause, result);
    };
  }

  const formattedSegments = formatTextData.map(
    (textSegment, textSegmentIndex) => {
      const {classes, text, companyDetailPlaceholderIndex} = textSegment;
      const key = `${text}_${textSegmentIndex}`;

      if (classes.includes("definition")) {
        const definition = props.documentDefinitions.find(
          def => def.id === textSegment.definitionId,
        );
        return (
          <a
            key={key}
            title={(definition || {}).meaning}
            style={getStyles(classes, editModeOn)}
          >
            {text}
          </a>
        );
      }

      if (classes.includes("reference")) {
        const clausepartText = getClausepartTextByReference(
          props.clauses,
          textSegment.reference,
        );
        return (
          <a
            key={key}
            title={clausepartText}
            style={getStyles(classes, editModeOn)}
          >
            {text}
          </a>
        );
      }

      const useContextMenu =
        editModeOn &&
        contextMenuItems.length > 0 &&
        classes.includes("company_detail_placeholder") &&
        companyDetailPlaceholderIndex !== undefined;

      return (
        <TextContainer
          key={key}
          text={text}
          useContextMenu={useContextMenu}
          styles={getStyles(classes, editModeOn)}
          title={textSegment.key}
          onCompanyDetailPlaceholderSave={getOnCompanyDetailPlaceholderSave(
            companyDetailPlaceholderIndex,
            text,
          )}
          contextMenuItems={contextMenuItems}
          references={props.clause && props.clause.references}
          clauses={props.clauses}
          searchValue={props.searchValue}
        />
      );
    },
  );

  return (
    <>
      {formattedSegments}
    </>
  );
}

import React from "react";
import _ from "underscore";

import * as colors from "@material-ui/core/colors";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";

import Checklist from "common_components/checklist";
import ChecklistActionsPanel from "common_components/checklist/checklist_actions_panel/";
import HeaderTitle from "common_components/checklist/header_title";
import HeaderMenu from "common_components/checklist/header_menu";
import FrontPageDetail from "./front_page_detail";
import ClauseDetail from "./clause_detail";
import LlmChatPanel from "./llm_chat";
import IssueDetail from "common_containers/issue_detail";
import MenuHeading from "common_components/menu_heading";
import ComparePanel from "common_components/compare_panel/";
import ComparisonDetail from "common_components/comparison_detail/";
import RefreshIssuesButton from "common_components/document/refresh_issues_button";
import issueHeaderUtils from "utils/issues/issue_header_utils";
import renderTextSplits from "utils/render_text_splits";
import getPositiveReasonData from "common/utils/issues/reason/get_positive_reason_data";
import constructFormData from "../../utils/construct_form_data";
import onIssueCheckboxCheck from "../../utils/issue/on_issue_checkbox_check";
import isNarrow from "utils/is_screen_narrow";
import ResizableBlock from "common_components/resizable_block";
import DocumentDetailContext from "common_components/context/document_detail_context";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import SectionSelector from "common_components/checklist/section_selector";
import Report from "common_components/report";
import LlmStatus from "common_components/llm_status/component";
import {isInitialised} from "utils/uninitialised";

const styles = {
  rightHandMenu: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    justifyContent: "flex-end",
    flexShrink: 1,
    flexGrow: 1,
    boxSizing: "border-box",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
  },
  "rightHandMenu--oneColumn": {
    width: "26rem",
    minWidth: 280,
    maxWidth: "50%",
  },
  "rightHandMenu--twoColumns": {
    width: "52rem",
    minWidth: "38rem",
    maxWidth: "65%",
  },
  rightPanelContainer: {
    display: "flex",
    flexDirection: "column",
    zIndex: "2",
    minWidth: "14rem",
    flexGrow: 1,
  },
  topMenu: {
    flex: "10 0 0px",
    minHeight: "50%",
  },
  topMenuCompare: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  rightPanel: isFlipChecklistIcons => ({
    flexBasis: "270px",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    backgroundColor: isFlipChecklistIcons ? "#fff" : "#232e38",
    color: "#fff",
    borderRadius: 0,
  }),
  leftPanel: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    minWidth: "18rem",
  },
  heading: {
    position: "relative",
    height: "4em",
    backgroundColor: "#f3f3f3",
    padding: "0 2px",
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
  },
  headingText: {
    cursor: "pointer",
    fontSize: "16px",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 400,
  },
  rightPanelBody: {
    overflow: "auto",
    flexGrow: 1,
  },
  underline: {
    height: "0.2rem",
    background: "#1e88e5",
  },
};

function isNarrowWithZoom(zoom) {
  const minWidth = getZoomedFontSize(1400, "checklist", zoom, 0.92);
  return isNarrow(minWidth);
}

class RightHandMenu extends React.Component {
  static contextType = DocumentDetailContext;
  constructor(props, context) {
    super(props);
    this.state = {
      narrow: isNarrowWithZoom(context.zoom),
      bottomPaddingOffset: 0,
      issuesExpanded: null,
      llmStatusDismissed: false,
    };
    this.lastScrollTop = 0;
    this.scrollOffset = 0;
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }
  updateDimensions = () => {
    this.setState({narrow: isNarrowWithZoom(this.context.zoom)});
  };

  render() {
    const {
      panelData,
      isChecklistLocationToggled,
      issuesUpdating,
      partiesValidating,
    } = this.props;
    const hasLeftPanel =
      panelData &&
      [
        "llm_chat",
        "issue_detail",
        "clause_detail",
        "comparison_detail",
      ].includes(panelData.type);
    const shouldHideRightPanel =
      hasLeftPanel && this.state.narrow && !this.props.showTwoPanes;
    const numberOfColumns =
      !hasLeftPanel || shouldHideRightPanel || this.props.editPlaybookMode
        ? 1
        : 2;
    const storageSubName = numberOfColumns === 1 ? "oneColumn" : "twoColumns";
    const panels = [];
    if (hasLeftPanel) {
      panels.push(this.renderLeftPanel(numberOfColumns));
    }
    panels.push(this.renderPrimaryPanel(shouldHideRightPanel, numberOfColumns));
    if (isChecklistLocationToggled) {
      panels.reverse();
    }
    return (
      <ResizableBlock
        side={!isChecklistLocationToggled ? "left" : "right"}
        storageName={`document_detail.right_hand_menu.${storageSubName}`}
        style={{
          ...styles.rightHandMenu,
          ...styles[`rightHandMenu--${storageSubName}`],
        }}
        zoom={this.context.zoom && this.context.zoom.checklist}
        baseSize={416 * numberOfColumns}
      >
        {partiesValidating
          ? this.renderPendingHover()
          : issuesUpdating && this.renderUpdatingHover()}
        {panels}
      </ResizableBlock>
    );
  }

  renderPendingHover() {
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.2)",
          zIndex: 10,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          backdropFilter: "blur(2px)",
          textShadow:
            "0 0 12px white, 0 0 12px white, 0 0 12px white, 0 0 12px white",
          fontSize: "1.5em",
        }}
      >
        Confirm parties
      </div>
    );
  }

  renderUpdatingHover() {
    return (
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0,0,0,0.2)",
          zIndex: 10,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  renderPrimaryPanel(shouldHide, numberOfColumns) {
    return (
      <div
        key="primary_panel"
        style={{
          ...styles.rightPanelContainer,
          ...(shouldHide ? {display: "none"} : {}),
          ...(numberOfColumns === 2 && {width: "50%"}),
          ...(this.props.editPlaybookMode && {minWidth: null}),
        }}
      >
        <div
          className="PRIMARY_INNER"
          style={
            this.props.primaryPanel === "compare"
              ? styles.topMenuCompare
              : styles.topMenu
          }
        >
          {this.renderPrimaryPanelBody()}
        </div>
      </div>
    );
  }

  renderPrimaryPanelBody = () => {
    const {panelData} = this.props;
    const issueDetailData =
      panelData && panelData.type === "issue_detail" && panelData.data;
    const checklistProps = {
      issueDetailData,
      onIssueCheckboxCheck,
      updateBottomPaddingOffset: this.updateBottomPaddingOffset,
      expandAllIssueState: this.state.issuesExpanded,
      bottomPaddingOffset: this.state.bottomPaddingOffset,
      itemIssuesFilterValues: this.props.itemIssuesFilterValues,
      isDocumentDetailPageShown: true,
      ..._.pick(this.props, [
        "user",
        "project",
        "organisation",
        "issues",
        "groupedIssues",
        "outstandingIssues",
        "areIssuesCollapsed",
        "unselectIssue",
        "updateDocumentIssue",
        "documentClauses",
        "documentClauseparts",
        "topicsById",
        "document",
        "showIssueDetail",
        "updateIssueDetail",
        "showIssuesInChecklist",
        "viewMode",
        "showClausesWithoutTopics",
        "positiveReasonData",
        "currentIssueset",
        "currentIssuesetItem",
        "documentHeadings",
        "displayHiddenIssues",
        "clientModeOn",
        "editPlaybookMode",
        "togglePlaybookState",
        "switchEditPlaybookMode",
        "playbookState",
        "selectedReportId",
        "isFlipChecklistIcons",
        "correctDocumentIssueManually",
        "showComparisonDetail",
        "reviewTrackingOn",
        "scrollToParties",
        "editingReport",
        "updateEditState",
      ]),
    };

    const headerMenuProps = {
      ..._.pick(this.props, [
        "project",
        "issues",
        "document",
        "user",
        "updateDocumentIssues",
        "isFlipChecklistIcons",
        "showFlipChecklistIcons",
        "primaryPanel",
        "triggerCompareToOtherDocument",
        "onDocumentIssuesFindRun",
        "onDownload",
      ]),
      setIssueExpandState: this.setIssueExpandState,
    };

    if (this.props.isReportShownInRightSide) {
      return this.renderReport();
    }

    switch (this.props.primaryPanel) {
      case "compare":
        return this.renderCompare(checklistProps, headerMenuProps);
      case "checklist":
      default:
        return this.renderChecklist(checklistProps, headerMenuProps);
    }
  };

  renderCompare = (checklistProps, headerMenuProps) => {
    const {documents} = this.props;
    let firstUploadedDocument = (documents || [])[0] || {};
    documents.forEach(doc => {
      if (doc.creation_date < firstUploadedDocument.creation_date) {
        firstUploadedDocument = doc;
      }
    });

    return (
      <ComparePanel
        checklistProps={checklistProps}
        organisationId={this.props.organisationId}
        document={this.props.document}
        documentSections={this.props.documentSections}
        documents={this.props.documents}
        project={this.props.project}
        contractTypesById={this.props.contractTypesById}
        isFlipChecklistIcons={this.props.isFlipChecklistIcons}
        zoom={this.context.zoom}
        currentIssueset={this.props.currentIssueset}
        areIssuesCollapsed={this.props.areIssuesCollapsed}
        firstUploadedDocument={firstUploadedDocument}
        updateComparisonDocumentId={this.props.updateComparisonDocumentId}
        isDarkMode={!this.state.isFlipChecklistIcons}
        fetchIssueComparisonData={this.props.fetchIssueComparisonData}
        issueComparisonData={this.props.issueComparisonData}
        applicableClausesPath={this.props.applicableClausesPath}
        onCurrentIssuesetChange={this.props.onCurrentIssuesetChange}
        viewMode={this.props.viewMode}
        headerMenuProps={headerMenuProps}
        onChecklistScroll={this.onChecklistScroll}
        showChecklistPrimaryPanel={this.props.showChecklistPrimaryPanel}
        runDocumentIssuesFind={headerMenuProps.onDocumentIssuesFindRun}
        user={this.props.user}
        selectedChecklistSectionId={this.props.selectedChecklistSectionId}
        onSelectedChecklistSectionIdChange={
          this.props.onSelectedChecklistSectionIdChange
        }
      />
    );
  };

  renderReport = () => {
    const reportSettings =
      this.props.document.report_settings.find(
        rep => rep.id === this.props.selectedReportId,
      ) ||
      this.props.project.report_settings.find(
        rep => rep.id === this.props.selectedReportId,
      ) ||
      {};

    return (
      <Paper>
        <Report
          {...this.props}
          reportSettings={reportSettings}
          toggleReportPanelVisibility={() => {
            this.props.toggleReportOpenInRightSidebar();
            this.props.toggleReportPanelVisibility();
          }}
          isReportShownInRightSide={this.props.isReportShownInRightSide}
        />
      </Paper>
    );
  };

  renderChecklist = (checklistProps, headerMenuProps) => {
    const {viewMode, project} = this.props;

    const organisation = this.props.user.organisations.find(
      ({id}) => id === this.props.organisationId,
    );

    return (
      <Paper square style={styles.rightPanel(this.props.isFlipChecklistIcons)}>
        <MenuHeading
          containerStyle={{
            borderBottom: "1px solid #4f575f",
            minHeight: "unset",
            flexShrink: 0,
            ...(this.props.isFlipChecklistIcons ? {color: "black"} : {}),
          }}
        >
          {this.props.user.is_admin && (
            <RefreshIssuesButton
              project={this.props.project}
              document={this.props.document}
              runDocumentIssuesFind={headerMenuProps.onDocumentIssuesFindRun}
              styles={{marginRight: 4}}
            />
          )}
          <HeaderTitle
            hideFrontPage={false}
            project={this.props.project}
            document={this.props.document}
            viewMode={this.props.viewMode}
            onViewModeChange={this.props.onViewModeChange}
            currentIssueset={this.props.currentIssueset}
            onCurrentIssuesetChange={this.props.onCurrentIssuesetChange}
            contractTypesById={this.props.contractTypesById}
            showSelectChecklistDialog={this.props.showSelectChecklistDialog}
            isIssuesetContractTypeNameHidden={!organisation.is_central_admin}
            isFlipChecklistIcons={this.props.isFlipChecklistIcons}
            user={this.props.user}
          />
          <HeaderMenu {...headerMenuProps} />
        </MenuHeading>
        {project.should_show_per_schedule_analysis && (
          <SectionSelector
            isFlipChecklistIcons={this.props.isFlipChecklistIcons}
            sections={this.props.documentSections}
            selectedChecklistSectionId={this.props.selectedChecklistSectionId}
            onSelectedChecklistSectionIdChange={
              this.props.onSelectedChecklistSectionIdChange
            }
            document={this.props.document}
            issues={this.props.documentIssues}
            runDocumentIssuesFind={headerMenuProps.onDocumentIssuesFindRun}
          />
        )}
        <div
          id="checklistMenu"
          style={styles.rightPanelBody}
          onScroll={this.onChecklistScroll}
        >
          {viewMode === "FORM" && (
            <FrontPageDetail
              menuItems={constructFormData(
                getSectionClauses("Front Page", this.props.document),
              )}
              metaDataFeedback={this.props.document.meta_data_feedback}
              verifyIssues={this.props.verifyIssues}
              updateDocumentMetaDataFeedback={
                this.props.updateDocumentMetaDataFeedback
              }
            />
          )}
          {viewMode === "FORM" && <div style={styles.underline} />}
          <ChecklistActionsPanel
            zoom={this.props.zoom}
            isFlipChecklistIcons={this.props.isFlipChecklistIcons}
            onZoomUpdate={this.props.onZoomUpdate}
            areIssueGroupsExpanded={this.state.issuesExpanded}
            expandIssueGroups={this.triggerExpandIssueGroups}
            showTwoPanes={this.props.showTwoPanes}
            checklistProps={this.checklistProps}
            changeItemIssuesFilterValues={
              this.props.changeItemIssuesFilterValues
            }
            itemIssuesFilterValues={this.props.itemIssuesFilterValues}
            user={this.props.user}
            reviewTrackingOn={this.props.reviewTrackingOn}
          />
          <Checklist {...{...checklistProps, isSorting: true}} />
        </div>
        {this.shouldShowLlmStatus() ? (
          <MenuHeading
            onCloseClick={this.dismissLlmStatus}
            containerStyle={{
              borderTop: "1px solid #4f575f",
              borderBottom: "0px",
              minHeight: "unset",
              padding: "1rem",
              ...(this.props.isFlipChecklistIcons
                ? {color: "black", background: "#f1f1f1"}
                : {}),
            }}
            wrapperStyle={{
              alignItems: "center",
            }}
            closeIconStyle={{
              fill: this.props.isFlipChecklistIcons
                ? colors.grey[800]
                : colors.grey[200],
            }}
          >
            <LlmStatus statuses={this.props.llmStatuses}></LlmStatus>
          </MenuHeading>
        ) : (
          <></>
        )}
      </Paper>
    );
  };

  dismissLlmStatus = () => {
    this.setState({
      llmStatusDismissed: true,
    });
  };

  renderLeftPanel = numberOfColumns => {
    const {panelData} = this.props;
    if (!panelData) {
      return null;
    }
    const style = {
      ...styles.leftPanel,
      ...(numberOfColumns === 2 && {width: "50%"}),
      ...(this.props.editPlaybookMode && {minWidth: null, flexGrow: null}),
    };
    switch (panelData.type) {
      case "issue_detail": {
        return (
          <div key="left_panel" style={style}>
            <IssueDetail
              user={this.props.user}
              updateIssueDetail={this.props.updateIssueDetail}
              clearPanelData={this.props.clearPanelData}
              onIssueCheckboxCheck={onIssueCheckboxCheck}
              isIssueCorrectnessPanelShown={
                this.props.isIssueCorrectnessPanelShown
              }
              currentIssuesetItem={this.props.currentIssuesetItem}
              additionalReportClausesState={
                this.props.additionalReportClausesState
              }
              {...{
                ...this.props,
                ...panelData.data,
              }}
            />
          </div>
        );
      }
      case "llm_chat": {
        return (
          <div key="left_panel" style={style}>
            <LlmChatPanel {...this.props} />
          </div>
        );
      }
      case "clause_detail": {
        return (
          <div key="left_panel" style={style}>
            <ClauseDetail
              currentClause={panelData.data}
              topicMasks={this.props.topicMasks}
              topicMasksById={this.props.topicMasksById}
              topicsById={this.props.topicsById}
              documentHeadings={this.props.documentHeadings}
              documentClauses={this.props.documentClauses}
              documentClauseparts={this.props.documentClauseparts}
              topicCategories={this.props.topicCategories}
              scrollToClause={this.props.scrollToClause}
              selectedTopics={this.props.selectedTopics}
              topicHoverStart={this.props.topicHoverStart}
              topicHoverEnd={this.props.topicHoverEnd}
              hoveredTopicIds={this.props.hoveredTopicIds}
              toggleTopicSelection={this.props.toggleTopicSelection}
              issues={this.props.documentIssues}
              showIssueDetail={this.props.showIssueDetail}
              clearPanelData={this.props.clearPanelData}
            />
          </div>
        );
      }
      case "comparison_detail": {
        const comparisonIssue = this.props.issueComparisonData.issues.find(
          issue => issue.id === panelData.data.issue.id,
        );
        const reasonText = issueHeaderUtils
          .getReasonTextArray(
            comparisonIssue,
            this.props.issueComparisonData.clauses,
            this.props.topicsById,
            getPositiveReasonData(this.props.issueComparisonData.clauses),
            this.props.selectedReportId,
            this.props.currentIssuesetItem,
            this.props.issueComparisonData.clauseparts,
            this.props.issueComparisonData.parties,
            false,
            false,
            false,
            false,
            this.props.document.last_classification_changed,
          )
          .map((reasonText, index, reasonTextArray) =>
            renderTextSplits(
              reasonText,
              index === 0,
              index === reasonTextArray.length - 1,
            ),
          );
        return (
          <div key="left_panel" style={style}>
            <ComparisonDetail
              {...panelData.data} // issue and comparisonDetailData
              project={this.props.project}
              zoom={this.context.zoom}
              showIssuesInChecklist={this.props.showIssuesInChecklist}
              selectedReportId={this.props.selectedReportId}
              clearPanelData={this.props.clearPanelData}
              user={this.props.user}
              issueComparisonData={this.props.issueComparisonData}
              documentClauseparts={this.props.documentClauseparts}
              documentSections={this.props.documentSections}
              scrollToClause={this.props.scrollToClause}
              currentIssuesetItem={this.props.currentIssuesetItem}
              isComparisonBetweenRevisions={
                this.props.document.revisions?.find(
                  doc => doc.id === this.props.document.comparison_document_id,
                ) !== undefined
              }
              reasonText={reasonText}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  onChecklistScroll = () => {
    // When closing items on checklist when scrolled to bottom,
    // prevent checklist height from changing (items jump around)
    const scrollOffset = document.getElementById("checklistMenu").scrollTop;

    // logic for hiding stricky chekclist actions panel when scrolling
    if (this.scrollOffset > scrollOffset) {
      document.getElementById("checklist-actions-panel").style.top = "0";
    } else {
      document.getElementById("checklist-actions-panel").style.top = "-100px";
    }
    // logic for checklist height mentioned above
    if (scrollOffset < 50 && this.state.bottomPaddingOffset !== 0) {
      return this.updateBottomPaddingOffset(0);
    }
    if (
      scrollOffset < this.lastScrollTop &&
      this.state.bottomPaddingOffset > 0
    ) {
      const scrollDist = this.lastScrollTop - scrollOffset;
      const newBottomPaddingOffset =
        this.state.bottomPaddingOffset - scrollDist;
      this.updateBottomPaddingOffset(newBottomPaddingOffset);
    }
    this.lastScrollTop = scrollOffset <= 0 ? 0 : scrollOffset;
    this.scrollOffset = scrollOffset;
  };

  updateBottomPaddingOffset = newValue => {
    this.setState(() => ({
      bottomPaddingOffset: newValue < 0 ? 0 : newValue,
    }));
  };

  setIssueExpandState = issuesExpanded => {
    this.setState({issuesExpanded});
  };
  triggerExpandIssueGroups = () =>
    this.setState(prevState => ({issuesExpanded: !prevState.issuesExpanded}));

  shouldShowLlmStatus() {
    if (!this.props.organisation.use_llm || !this.props.project.enable_llms) {
      return false;
    }
    if (!this.props.llmStatuses || !isInitialised(this.props.llmStatuses)) {
      return false;
    }

    return (
      !this.state.llmStatusDismissed &&
      this.props?.llmStatuses?.some(
        ({status: {indicator}}) =>
          indicator === "minor" || indicator === "major",
      )
    );
  }
}

function getSectionClauses(sectionName, doc) {
  const {clauses, sections} = doc;
  const section = sections.find(
    section =>
      section.reference.toLowerCase().indexOf(sectionName.toLowerCase()) !== -1,
  );
  const sectionId = section ? section.id : "__section_not_found";
  const sectionClauses = clauses[sectionId] || [];
  return sectionClauses.filter(clause => clause.reference !== "all");
}

export default RightHandMenu;

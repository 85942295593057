import React from "react";
import _ from "lodash";

import {withStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import {SearchIcon as SearchIconMS} from "constants/icons";

import SwitchStyled from "common_components/inputs/switch_styled";

import NavItem from "routes/logged_in/components/sidebar/nav_item";
import SidebarPanel from "routes/logged_in/components/sidebar/panel";
import clampLine from "routes/document_detail/utils/clamp_line";
import topicName from "utils/topic/topic_name_utils";
import getIssueNames from "utils/issues/get_issue_names";

import CurrentDocumentTab from "./tabs/current_document";
import AllDocumentsTab from "./tabs/all_documents";

import isSearchValue from "./utils/is_search_value";

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "hidden",
    "& > div:last-child": {
      border: "none",
    },
  },
  header: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    backgroundColor: "#fff",
    top: 58,
    left: 15,
    right: 15,
    zIndex: 1,
    padding: "11px 0",
    boxShadow: "0px 1px 9px rgba(0,0,0,0.2)",
    fontSize: 12,
    maxHeight: "50vh",
    overflowX: "hidden",
    overflowY: "auto",
    borderRadius: 3,
    "& article + article": {
      marginTop: 10,
    },
    "& article": {
      "& h5": {
        color: "#ababab",
        margin: "0 0 4px",
        fontSize: 12,
        fontWeight: 400,
      },
      "& ul": {
        padding: 0,
        margin: 0,
        listStyle: "none",
        "& li": {
          paddingTop: 3,
          paddingBottom: 3,
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
          },
        },
      },
      "& h5, & ul li": {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
  },
  textField: {
    padding: "8px 15px 4px",
    boxSizing: "border-box",
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      backgroundColor: "#fcfcfc",
      "& fieldset": {
        borderColor: "#c6ccd4",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 8,
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 17,
      padding: "15px 10px",
    },
    "& .MuiIconButton-root": {
      transition: theme.transitions.create("background-color"),
    },
    "& .MuiSvgIcon-root": {
      fontSize: 16,
      color: "#babdc0",
      transition: theme.transitions.create("color"),
      "&:hover": {
        color: "#1f88e5",
      },
    },
  },
  headerTabs: {
    display: "flex",
    textAlign: "center",
  },
  headerTab: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: "1px solid #e6e6e6",
    height: 54,
    width: "50%",
    color: "#787f85",
    cursor: "pointer",
    "&:first-child": {
      borderRight: "1px solid #e6e6e6",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
    "& h5": {
      margin: 0,
      fontSize: 14,
      fontWeight: 500,
    },
    "& p": {
      margin: 0,
      fontSize: 10,
    },
  },
  activeTab: {
    borderBottom: "1px solid #099cff",
    color: "#1f88e5",
    "&:hover": {
      backgroundColor: "initial",
    },
  },
  noResults: {
    height: "100%",
    color: "#333333",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& h5": {
      margin: "0 0 4px",
      fontSize: 21,
      fontWeight: 500,
    },
    "& p": {
      margin: 0,
      fontSize: 14,
      width: 190,
      textAlign: "center",
    },
  },
});

class SearchItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      isUpdated: false,
      inputSearchValue: "",
      searchValue: "",
      searchOptions: null,
      activeTabIndex: 0,
      tmpData: null,
      exactMatch: false,
      helperText: null,
    };
    this.headerTabsRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleWindowKeydown);
    this.clamp();
    if (this.props.addCustomEventListener) {
      this.props.addCustomEventListener(
        "showSearchItemSidebarPanel",
        this.showSidebarPanel,
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleWindowKeydown);
    if (this.props.removeCustomEventListener) {
      this.props.removeCustomEventListener(
        "showSearchItemSidebarPanel",
        this.showSidebarPanel,
      );
    }
  }

  handleWindowKeydown = event => {
    if (
      event.keyCode === 70 &&
      (event.metaKey || event.ctrlKey) &&
      this.itemRef
    ) {
      event.preventDefault();
      this.showSidebarPanel();
    }
  };

  showSidebarPanel = (searchValue, searchOptions = null) => {
    if (searchValue) {
      const issue = this.props.documentIssues.find(
        issue => searchValue === issue.id.toString(),
      );
      const inputSearchValue = issue
        ? this.tidyIssueName(issue.display_name)
        : "";
      this.setState({
        inputSearchValue,
        searchValue,
        searchOptions,
        activeTabIndex: 1,
      });
    }
    if (this.itemRef) {
      this.props.showSidebarPanel(this.itemRef);
    }
  };

  tidyIssueName(issueName) {
    const slashCount = issueName.replace(/[^/]/g, "").length;
    if (slashCount === 0) {
      return issueName;
    }
    if (slashCount === 1) {
      return issueName.substring(issueName.indexOf("/") + 1).trim();
    }
    if (slashCount === 2) {
      return issueName.match(/\/(.*)\//)[1].trim();
    }
  }

  componentDidUpdate() {
    this.clamp();
    if (this.state.tmpData) {
      setTimeout(() => {
        this.setState({tmpData: null});
      }, 0);
    }
  }

  clamp() {
    const elements = document.querySelectorAll(".clamped-clause");
    clampLine(elements);
  }

  handleKeyPress = event => {
    if (event.key === "Enter") {
      event.target.blur();
    }
  };

  handleFocus = () => {
    this.setState({isFocused: true});
  };

  handleBlur = () => {
    this.setState({
      isFocused: false,
      isUpdated: false,
    });
    this.doSearch();
  };

  clear = () => {
    this.setState(
      () => ({
        inputSearchValue: "",
        searchValue: "",
        searchOptions: null,
      }),
      this.doSearch,
    );
  };

  doSearch = () => {
    this.setState({
      helperText: null,
    });
    if (!isSearchValue(this.state.inputSearchValue)) {
      this.setState({
        helperText: "Type at least 3 letters to start searching",
      });
      return;
    }
    this.setState({
      searchValue: this.state.inputSearchValue,
      searchOptions: this.state.exactMatch ? {exactMatch: true} : null,
    });
  };

  render() {
    const {appType} = this.props;
    return appType === "wordTaskpane" ? (
      <>
        {this.renderHeader()}
        {this.renderResults()}
      </>
    ) : (
      <NavItem
        ref={instance => (this.itemRef = instance)}
        isCollapsed={true}
        icon={<SearchIcon />}
        collapsedTitle={"Search"}
        panelChildren={
          <SidebarPanel
            headerIcon={<SearchIcon />}
            headerChildren={this.renderHeader()}
          >
            <div className={this.props.classes.root}>
              {this.renderResults()}
            </div>
          </SidebarPanel>
        }
      />
    );
  }

  renderHeader() {
    const {inputSearchValue, exactMatch} = this.state;
    const {classes} = this.props;
    return (
      <div className={classes.header}>
        <TextField
          className={classes.textField}
          placeholder="Type search here"
          value={inputSearchValue}
          onChange={event =>
            this.setState({
              inputSearchValue: event.target.value,
              isUpdated: true,
            })
          }
          helperText={this.state.helperText}
          onKeyPress={this.handleKeyPress}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          fullWidth
          autoFocus
          variant="outlined"
          InputProps={{
            endAdornment: !inputSearchValue ? null : (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onMouseDown={event => event.preventDefault()}
                  onClick={() => this.clear()}
                >
                  <CancelIcon />
                </IconButton>
              </InputAdornment>
            ),
            startAdornment: <SearchIconMS style={{color: "#0574d6"}} />,
          }}
        />
        {this.renderDropdown()}
        <div
          style={{
            paddingLeft: 15,
            paddingBottom: 4,
            display: "flex",
            alignItems: "center",
          }}
        >
          <SwitchStyled
            checked={exactMatch}
            onChange={(event, value) => this.setState({exactMatch: value})}
          />
          <div
            style={{
              fontSize: 14,
              marginLeft: 4,
              color: "#424242",
              cursor: "pointer",
            }}
            onClick={this.onTriggerExactMatch}
          >
            {exactMatch ? "Exact Match" : "Close Match"}
          </div>
        </div>
        <div ref={this.headerTabsRef} className={classes.headerTabs} />
      </div>
    );
  }

  renderDropdown() {
    const {isFocused, isUpdated, inputSearchValue} = this.state;
    if (!isSearchValue(inputSearchValue) || !isFocused || !isUpdated) {
      return null;
    }
    const itemLists = {};
    const addItem = (listName, name, fullName) => {
      if (name.toLowerCase().indexOf(inputSearchValue.toLowerCase()) !== -1) {
        if (!_.has(itemLists, listName)) {
          itemLists[listName] = [];
        }
        itemLists[listName].push({
          name,
          fullName: fullName ? fullName.replace(/\s+/g, " ") : "",
        });
      }
    };
    this.props.issues.forEach(issue => {
      addItem("Issues", issue.name, issue.display_name);
    });
    this.props.documentTopics.forEach(topic => {
      addItem("Topics", topic.topicName, topic.topic.name);
    });
    if (!_.keys(itemLists).length) {
      return null;
    }
    return (
      <div
        className={this.props.classes.dropdown}
        onMouseDown={event => event.preventDefault()}
      >
        {_.map(itemLists, (list, listName) => (
          <article key={listName}>
            <h5>{listName}</h5>
            <ul>
              {list.map((item, index) => (
                <li
                  key={index}
                  onClick={() => {
                    this.setState({
                      isUpdated: false,
                      inputSearchValue: item.name,
                      searchValue: item.name,
                      searchOptions: null,
                      activeTabIndex: 1,
                      tmpData: {
                        activeGroupName: `${listName}.${item.fullName}`,
                      },
                    });
                  }}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          </article>
        ))}
      </div>
    );
  }

  renderResults() {
    const {searchValue, searchOptions, tmpData, exactMatch} = this.state;
    const {classes, ...otherProps} = this.props;
    if (!searchValue.length) {
      return (
        <div className={classes.noResults}>
          <SearchIconMS
            style={{
              color: "#0574d6",
              height: 40,
              width: 40,
            }}
          />
          <h5>No Results</h5>
          <p>You need to type something in search field above</p>
        </div>
      );
    }
    const tabProps = {
      ...otherProps,
      searchValue,
      searchOptions,
      tmpData,
      exactMatch,
      headerClasses: {
        tab: classes.headerTab,
        activeTab: classes.activeTab,
      },
      headerRef: this.headerTabsRef,
    };

    const tabs = [
      this.props.appType !== "wordTaskpane" && CurrentDocumentTab,
      AllDocumentsTab,
    ].filter(item => item);

    return tabs.map((Tab, index) => (
      <Tab
        key={index}
        tabIndex={index}
        isActive={
          this.props.appType === "wordTaskpane"
            ? true
            : index === this.state.activeTabIndex
        }
        activate={() => this.setState({activeTabIndex: index})}
        {...tabProps}
      />
    ));
  }

  onTriggerExactMatch = () =>
    this.setState(prevState => ({exactMatch: !prevState.exactMatch}));
}

SearchItem.Icon = SearchIcon;

SearchItem.passProps = props => {
  const documentTopicsWithNames = props.documentTopics.map(topic => ({
    ...topic,
    topicName: topicName.getTopicName(topic.topic.name),
    topicGroupName: topicName.getTopicGroupName(topic.topic.name),
  }));
  const issuesWithNames = props.issues.map(issue => ({
    ...issue,
    ...getIssueNames(issue),
  }));
  return {
    documentTopics: documentTopicsWithNames,
    issues: issuesWithNames,
    ..._.pick(props, [
      "scrollToClause",
      "scrollToHeading",
      "topicsById",
      "topicCategories",
      "document",
      "documentSections",
      "documentHeadings",
      "documentClauses",
      "documentDefinitions",
      "documentIssues",
      "onIssueClick",
      "search",
      "searchResults",
      "organisationId",
      "projectId",
      "addCustomEventListener",
      "removeCustomEventListener",
    ]),
  };
};

export default withStyles(styles)(SearchItem);

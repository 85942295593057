import getIssueUiOrderIds from "./get_issue_ui_order_ids";
import {makeUiOrderId, makeIdPattern} from "./make_ui_order_id";

export default function getNextAfterLastId(rules, path = "") {
  const ui_order_ids = getIssueUiOrderIds({rules});
  const pattern = `^${makeIdPattern(path, "(\\d+)")}$`;
  const matchedIndexes = ui_order_ids
    .map(ui_order_id => ui_order_id.match(new RegExp(pattern)))
    .filter(match => match && match[1])
    .map(match => parseInt(match[1], 10))
    .filter(index => !isNaN(index));
  if (matchedIndexes.length === 0) {
    return makeUiOrderId(path, 0);
  }
  const maxIndex = Math.max(...matchedIndexes);
  return makeUiOrderId(path, maxIndex + 1);
}

import getApplicableIssuesetIds from "utils/issuesets/get_applicable_issueset_ids";
import getCurrentIssuesetItem from "utils/issuesets/get_current_issueset_item";
import getIssues from "utils/issues/get_issues_for_checklist";
import groupIssuesForChecklist from "common/utils/issues/group_issues_for_checklist";
import addSubissueDataToGroupedIssues from "common/utils/issues/add_subissue_data_to_grouped_issues";
import doesIssueMatchFilter from "./does_issue_match_filter";

function getIssuesData(
  props,
  currentIssueset,
  areIssuesCollapsed,
  dontFilterIssuesByIssuesets,
  selectedChecklistSectionId,
  itemIssuesFilterValues = {},
  showOnlyIssues,
) {
  const applicableIssuesetIds = getApplicableIssuesetIds(
    props.document,
    props.project,
    props.contractTypesById,
  );
  const currentIssuesetItem = getCurrentIssuesetItem(
    currentIssueset,
    props.document,
    props.contractTypesById,
  );
  const issues = getIssues(
    applicableIssuesetIds,
    props.documentIssues,
    currentIssuesetItem,
    areIssuesCollapsed,
    false,
    false, // display hidden issues
    false, // show archived issuesets
    dontFilterIssuesByIssuesets,
    selectedChecklistSectionId,
  );
  const groupedIssues = groupIssuesForChecklist(issues);
  const groupedIssuesWithSubissues = areIssuesCollapsed
    ? addSubissueDataToGroupedIssues(groupedIssues, false)
    : groupedIssues;

  const filteredGroupedIssuesWithSubissues = groupedIssuesWithSubissues
    .map(issue => {
      if (issue.item) {
        issue.item = issue.item.filter(childIssue =>
          doesIssueMatchFilter(
            childIssue,
            itemIssuesFilterValues,
            currentIssuesetItem,
            props.project,
            showOnlyIssues,
          ),
        );
        return issue;
      } else {
        return doesIssueMatchFilter(
          issue,
          itemIssuesFilterValues,
          currentIssuesetItem,
          props.project,
          showOnlyIssues,
        );
      }
    })
    .filter(issue => !issue.item || issue.item.length);
  return {
    groupedIssues: filteredGroupedIssuesWithSubissues,
    issues,
    currentIssuesetItem,
  };
}

export default getIssuesData;

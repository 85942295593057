import React from "react";

import IssueListButton from "../../common_components/issue_list_button";

export default function IssueListButtons(props) {
  const {issues} = props;
  if (!issues.length) {
    return null;
  }

  return issues.map(issue => {
    const issueName = issue.display_name || issue.name;
    return (
      <IssueListButton
        key={issue.id}
        name={issueName}
        issue={issue}
        clausepart={props.clausepart}
        showIssueDetail={props.showIssueDetail}
        selectedReportId={props.selectedReportId}
        currentIssuesetItem={props.currentIssuesetItem}
        showIssuesInChecklist={props.showIssuesInChecklist}
        project={props.project}
        user={props.user}
        documentClauses={props.documentClauses}
        topicsById={props.topicsById}
        positiveReasonData={props.positiveReasonData}
        documentClauseparts={props.documentClauseparts}
      />
    );
  });
}

// function filterName(name) {
//   return name.replace(/\s*[\[\{].*[\]\}]/g, "");
// }

// function getSecondPartIssueName(issueName) {
//   const issueNameParts = issueName.split("/");
//   if (issueNameParts.length > 1) {
//     return issueNameParts[1].trim();
//   }
//   return issueName;
// }

import _ from "underscore";
import issueHeaderUtils from "utils/issues/issue_header_utils";
import copy from "copy-to-clipboard";

export default function downloadChecklist(
  groupedIssues,
  issues,
  currentIssuesetItem,
  props,
) {
  const result = printChecklist(
    groupedIssues,
    issues,
    currentIssuesetItem,
    props,
  );
  copy(JSON.stringify(result, null, 2));
  return result;
}

function printChecklist(groupedIssues, issues, currentIssuesetItem, props) {
  return groupedIssues.map(issueGroup => {
    if (issueGroup.isGroup) {
      return {
        ..._.omit(issueGroup, ["item"]),
        children: printChecklist(
          issueGroup.item,
          issues,
          currentIssuesetItem,
          props,
        ),
      };
    } else {
      return printIssue(
        issueGroup?.item?.[0] ?? issueGroup,
        issues,
        currentIssuesetItem,
        props,
      );
    }
  });
}

function printIssue(
  issueGroup,
  issues,
  currentIssuesetItem,
  props,
  isSubissue,
) {
  const issue = issues.find(
    ii => ii.id === issueGroup.id && ii.section_id === issueGroup.section_id,
  );
  const issueset = currentIssuesetItem;
  const applicableClauses =
    issue.applicable_clauses?.[issueset.master_id]?.[issueset.client_id] ??
    issue.applicable_clauses?.[issueset.master_id]?.master ??
    issue.applicable_clauses.base;
  const result = {
    ..._.pick(issueGroup, ["shouldBeHidden"]),
    name: issueGroup.display_name,
    icon: issueHeaderUtils.getIconName(issue, issueset, false, props.project),
    displayText: issueHeaderUtils.getReasonTextArray(
      issue,
      props.documentClauses,
      props.topicsById,
      props.positiveReasonData,
      props.selectedReport,
      currentIssuesetItem,
      props.documentClauseparts,
      props.parties,
      false,
      false,
      false,
      false,
    ),
    detailedDisplayText: issueHeaderUtils.getReasonTextArray(
      issue,
      props.documentClauses,
      props.topicsById,
      props.positiveReasonData,
      props.selectedReport,
      currentIssuesetItem,
      props.documentClauseparts,
      props.parties,
      false,
      true,
      false,
      false,
    ),
    applicableClauses: applicableClauses.map(applicableClause => {
      // const clauses = props.documentClauseparts.find(cp => cp.id === applicableClause.id);
      const section = props.documentSections.find(
        ds => ds.id === applicableClause.section_id,
      );
      return {
        reference: applicableClause.reference,
        text: applicableClause.partial_text,
        ...(section ? {section: section.name || section.reference} : {}),
      };
    }),
  };
  if (issue.subissueData && !isSubissue) {
    result.subissues = issue.subissueData.subissues.map(subissue =>
      printIssue(subissue, issues, currentIssuesetItem, props, true),
    );
  }
  return result;
}

import _ from "underscore";

function getClauseAtomsByTopics(
  referencedTopics,
  documentClauses,
  topicsById,
  acceptedTopicCategories,
  applicableClauses,
) {
  const clauseAtoms = [];
  function getAtoms(node, clause) {
    const nodeText = node.text;
    if (nodeText && nodeText !== "and" && nodeText !== "or") {
      if (node.is_conjunction) {
        return;
      }
      let topicMatches = 0; // needed for the case of several topics in one atom
      let clauseAtom;
      node.topics &&
        node.topics.forEach(nodeTopic => {
          if (
            referencedTopics.includes(nodeTopic.topic_id) &&
            !nodeTopic.is_deleted &&
            acceptedTopicCategories.includes(nodeTopic.topiccategory_id) &&
            !applicableClauses.find(clause => clause.id === node.id)
          ) {
            if (topicMatches === 0) {
              clauseAtom = {
                reference: node.reference,
                text: node.partial_text,
                full_text: node.text,
                file_index: clause.file_index,
                topics: [topicsById[nodeTopic.topic_id]],
                id: node.id,
                level: node.level,
                clause_full_reference: clause.full_reference,
                clause_reference: clause.reference,
                clause_order: node.order,
                clause_id: clause.id,
                clause_section_id: clause.section_id,
                nodes: clause.nodes,
              };
            } else {
              clauseAtom.topics.push(topicsById[nodeTopic.topic_id]);
            }
            topicMatches++;
          }
        });
      if (clauseAtom && clauseAtom.id) {
        clauseAtom.topics = _.uniq(clauseAtom.topics);
        clauseAtoms.push(clauseAtom);
      }
    }
    if (node.clauseNodes) {
      node.clauseNodes.forEach(child => getAtoms(child, clause));
    }
  }
  _.forEach(documentClauses, clauses => {
    clauses.forEach(clause => {
      getAtoms(clause.nodes, clause);
    });
  });
  return clauseAtoms;
}

export default getClauseAtomsByTopics;

import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import areReportsEnabled from "utils/are_reports_enabled";
import getAddedToReportIssues from "utils/issues/get_added_to_report_issues";

export default class EmailButton extends React.Component {
  render() {
    const {
      project,
      selectedReportId,
      isReportPanelShown,
      isReportShownInRightSide,
      responseAddedToReport,
    } = this.props;
    const reportSettings = project.report_settings.find(
      report => report.id === selectedReportId,
    );
    const issuesAddedToReport = getAddedToReportIssues(
      this.props.issues,
      this.props.showIssuesInChecklist,
      reportSettings,
    );
    const reportLabel = reportSettings.label;

    return (
      <div
        style={{position: "relative", marginLeft: "15px", marginRight: "2px"}}
      >
        <Tooltip
          title={`Response added to ${reportLabel}`}
          open={responseAddedToReport}
          disableFocusListener
          disableHoverListenerarrow
          disableTouchListener
          placement="bottom"
          arrow
        >
          <Button
            disabled={!areReportsEnabled(this.props.document, project)}
            variant="contained"
            onClick={() => {
              if (isReportShownInRightSide) {
                this.props.toggleReportOpenInRightSidebar();
              } else {
                this.props.toggleReportPanelVisibility();
              }
            }}
            color={
              isReportPanelShown || isReportShownInRightSide
                ? "primary"
                : issuesAddedToReport.length !== 0
                ? "default"
                : "default"
            }
          >
            {reportLabel}
          </Button>
        </Tooltip>
      </div>
    );
  }
}

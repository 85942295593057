/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */
import getRandomColor from "../../../utils/get_random_color";

const styles = {
  topic: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#888",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "#ccc",
    padding: "0.25em 1em",
    margin: "0.25em",
    flexGrow: 0,
    textAlign: "center",
    fontWeight: 100,
    cursor: "pointer",
    backgroundColor: "white",
    fontSize: "0.85em",
    width: "10em",
  },
  topicHighlight: {
    color: "#333",
  },
};

function renderTopic(props) {
  const {topic, reference} = props;
  const {id: topicId} = topic;
  let highlights = {};
  if (
    (props.hoveredTopicIds && props.hoveredTopicIds.includes(topicId)) ||
    props.selectedTopics[topicId]
  ) {
    const {fullName} = topic;
    const {colour, lightColour} = getRandomColor(fullName);

    highlights = {
      ...styles.topicHighlight,
      backgroundColor: lightColour,
      borderColor: colour,
    };
  }

  return (
    <div
      key={topicId}
      className="topic"
      style={{...styles.topic, ...highlights}}
      onMouseOver={() => props.topicHoverStart([topicId])}
      onMouseOut={() => props.topicHoverEnd([topicId])}
      onClick={() => props.toggleTopicSelection(topicId, reference)}
    >
      <span
        style={{
          verticalAlign: "middle",
          display: "flex",
        }}
      >
        {topic.name}
      </span>
    </div>
  );
}

export default renderTopic;

import _ from "underscore";
import escapeRegex from "utils/escape_regex";

export default function findReferenceInstances(clauseText, references) {
  if (!clauseText || !references || references.length === 0) {
    return [];
  }

  const referencePositions = [];
  references.forEach(({text, id}) => {
    if (!text || !text.length) {
      return;
    }
    const regexp = new RegExp(escapeRegex(text), "g");
    let result;
    while ((result = regexp.exec(clauseText)) !== null) {
      referencePositions.push({
        match: result[0],
        id,
        text,
        index: result.index,
      });
    }
  });

  return _.sortBy(referencePositions, "index").map(({match, index, id}) => ({
    type: "reference",
    start: index,
    end: index + match.length,
    reference: id,
  }));
}

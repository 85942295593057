function createDate(date) {
  return new Date(date || null);
}

export default function getDocumentIssuesOutOfDateStatus(document, project) {
  const {
    issues_last_processed: issuesLastProcessed,
    last_classification_changed: lastClassificationChanged,
    issues_last_changed: issuesLastChanged,
    issues_last_kicked_off: issuesLastKickedOff,

    roles_out_of_date: rolesOutOfDate,
    roles_last_kicked_off: rolesLastKickedOff,
    roles_last_processed: rolesLastProcessed,
    role_last_edited: rolesLastEdited,
    justClicked,
  } = document;

  if (project.should_reprocess_issues) {
    return "out_of_date";
  }

  if (justClicked) {
    return "refresh_pending";
  }

  const issuesLastProcessedDate = createDate(issuesLastProcessed);
  const lastClassificationChangedDate = createDate(lastClassificationChanged);
  const issuesLastChangedDate = createDate(issuesLastChanged);
  const issuesLastKickedOffDate = createDate(issuesLastKickedOff);

  const rolesLastProcessedDate = createDate(rolesLastProcessed);
  const rolesLastKickedOffDate = createDate(rolesLastKickedOff);
  const rolesLastEditedDate = createDate(rolesLastEdited);

  const issuesOutOfDate =
    (issuesLastProcessed || issuesLastProcessed === null) &&
    (((lastClassificationChanged || lastClassificationChanged === null) &&
      issuesLastProcessedDate < lastClassificationChangedDate) ||
      ((issuesLastChanged || issuesLastChanged === null) &&
        issuesLastProcessedDate < issuesLastChangedDate));

  if (issuesOutOfDate || rolesOutOfDate) {
    const issuePending =
      issuesLastKickedOff &&
      (lastClassificationChanged &&
        issuesLastKickedOffDate > lastClassificationChangedDate);

    const rolesPending =
      rolesLastKickedOff &&
      (!rolesLastProcessed ||
        rolesLastProcessedDate < rolesLastKickedOffDate) &&
      rolesLastKickedOffDate > rolesLastEditedDate;

    if (
      (!rolesOutOfDate && issuesOutOfDate && issuePending) ||
      (rolesOutOfDate && rolesPending)
    ) {
      return "refresh_pending";
    }
    return "out_of_date";
  }
  return null;
}

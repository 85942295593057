import {DocumentIssue} from "common/types/document_issue";

function getIssueTriggeredSpecificValue(
  issue: DocumentIssue,
  triggeredField: "display_topics" | "additional_applicable_clause_topics",
  nonTriggeredField:
    | "non_triggered_display_topics"
    | "non_triggered_additional_applicable_clause_topics",
): DocumentIssue[typeof triggeredField | typeof nonTriggeredField] {
  // when reasons exist, use triggered field
  if (issue.reason && Array.isArray(issue.reason) && issue.reason.length > 0) {
    return issue[triggeredField];
  }
  // if no reasons exist and non-triggered field is set use it
  if (issue[nonTriggeredField] && issue[nonTriggeredField].length) {
    return issue[nonTriggeredField];
  }
  // if no reasons are set but non-triggered field is not in use, use triggered field
  return issue[triggeredField];
}

export default getIssueTriggeredSpecificValue;

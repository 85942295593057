import byId from "common/utils/by_id";
import getClauseByPath from "common/utils/get_clause_by_path";
import normaliseClause from "common/utils/normalise_clause";
import updateClauseByPath from "common/utils/update_clause_by_path";
import {cloneDeep} from "lodash";
import UserActionTypes from "modules/user/constants/action_types";
import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";
import ActionTypes from "../constants/action_types";

function removeTopicParameter(topicparameterId, node) {
  let changed = false;
  let newNode = {
    ...node,
    ...(node.topics
      ? {
          topics: _.map(node.topics, topic => ({
            ...topic,
            topicparameters: topic.topicparameters.filter(tp => {
              const change = tp.topicparameter_id !== topicparameterId;

              changed = changed || change;
              return change;
            }),
          })),
        }
      : {}),
  };

  if (newNode.clauseNodes) {
    newNode = {
      ...newNode,
      clauseNodes: newNode.clauseNodes.map(clauseNode => {
        const update = removeTopicParameter(topicparameterId, clauseNode);

        if (update.changed) {
          changed = true;
          return update.node;
        }
        return clauseNode;
      }),
    };
  }
  return {
    changed,
    node: changed ? newNode : node,
  };
}
function updateClausepartTopicparameterActualValues(state, payload) {
  const newState = {
    documentId: state.documentId,
    clauses: {},
  };
  function updateClauseNode(node, payload) {
    if (node.clauseNodes) {
      return {
        ...node,
        clauseNodes: node.clauseNodes.map(node =>
          updateClauseNode(node, payload),
        ),
      };
    }
    if (node.text && node.id === payload.clausepart_id) {
      const newTopics = node.topics.map(topic => {
        if (topic.topic_id === payload.topic_id) {
          const {updates} = payload;
          const remainedUpdatesIds = Object.keys(updates).map(id =>
            parseInt(id, 10),
          );
          const newTopicparameters = topic.topicparameters
            .map(tp => {
              if (!updates[tp.topicparameter_id]) {
                return tp;
              }
              const tpUpdates = updates[tp.topicparameter_id];
              const newTp = {...tp, ...tpUpdates};
              const removeIndex = remainedUpdatesIds.indexOf(
                tp.topicparameter_id,
              );
              if (removeIndex > -1) {
                remainedUpdatesIds.splice(removeIndex, 1);
              }
              return newTp;
            })
            .filter(tp => Boolean(tp));
          if (remainedUpdatesIds.length > 0) {
            remainedUpdatesIds.forEach(tpId =>
              newTopicparameters.push({
                ...updates[tpId],
                topicparameter_id: parseInt(tpId, 10),
              }),
            );
          }
          return {
            ...topic,
            topicparameters: newTopicparameters,
          };
        }
        return topic;
      });
      return {
        ...node,
        topics: newTopics,
      };
    }
    return node;
  }

  _.each(state.clauses, (clauseGroup, sectionId) => {
    newState.clauses[sectionId] = clauseGroup.map(clause => {
      if (clause.id === payload.clause_id) {
        return {
          ...clause,
          nodes: updateClauseNode(clause.nodes, payload),
        };
      }
      return clause;
    });
  });

  return newState;
}

function updateClausepart(state, action, doUpdate) {
  if (state === UNINITIALISED) {
    return state;
  }
  const newState = {
    documentId: state.documentId,
    clauses: {},
  };

  _.each(state.clauses, (clauseGroup, sectionId) => {
    if (parseInt(sectionId, 10) !== action.payload.section_id) {
      newState.clauses[sectionId] = clauseGroup;
    } else {
      newState.clauses[sectionId] = clauseGroup.map(clause => {
        if (clause.id === action.payload.clause_id) {
          if (action.payload.path.length > 0) {
            const clausepart = getClauseByPath(clause, action.payload.path);
            const newClausePart = {
              ...clausepart,
              ...doUpdate(clausepart, action.payload),
            };

            return updateClauseByPath(
              {
                ...clause,
                nodes: JSON.parse(JSON.stringify(clause.nodes)),
                last_edited: newClausePart.last_edited,
              },
              newClausePart,
              action.payload.path,
            );
          }
        }
        return clause;
      });
    }
  });
  return newState;
}

function updateClause(state, action, doUpdate) {
  if (state === UNINITIALISED) {
    return state;
  }
  const newState = {
    documentId: state.documentId,
    clauses: {},
  };

  _.each(state.clauses, (clauseGroup, sectionId) => {
    if (parseInt(sectionId, 10) !== action.payload.section_id) {
      newState.clauses[sectionId] = clauseGroup;
    } else {
      newState.clauses[sectionId] = clauseGroup.map(clause => {
        if (clause.id === action.payload.clause_id) {
          return doUpdate(clause, action.payload);
        }
        return clause;
      });
    }
  });
  return newState;
}

function handleDocumentParse(state, action) {
  function getTopicsFromClauseparts(clause) {
    const topicsByClausepartId = {};

    function findTopics(node) {
      if (node.id) {
        topicsByClausepartId[node.id] = _.pick(node, "topics");
      }
      if (node.clauseNodes) {
        node.clauseNodes.forEach(childNode => findTopics(childNode));
      }
    }
    findTopics(clause);
    return topicsByClausepartId;
  }
  function addTopicsToClauseParts(clause, topicsByClausepartId) {
    function addTopics(node) {
      const topics = topicsByClausepartId[node.id];

      if (topics) {
        node.topics = _.uniq(
          [...(node.topics || []), ...(topics.topics || [])],
          el => el.topic_id,
        );
      }
      if (node.clauseNodes) {
        node.clauseNodes.forEach(childNode => addTopics(childNode));
      }
    }
    addTopics(clause.nodes);
    return clause;
  }
  const sectionsById = {};

  _.each(action.payload.document_updates.sections, section => {
    sectionsById[section.id] = section;
  });

  const newClauses = _.mapObject(state.clauses, (clauseGroup, sectionId) => {
    const newSection = sectionsById[sectionId];

    if (!newSection) {
      return clauseGroup;
    }
    return clauseGroup.map(clause => {
      const newClause = newSection.clauses[clause.reference];

      if (!newClause) {
        return clause;
      }
      newClause.nodes = newClause.rootClauseNode;
      const normalisedClause = {
        ...clause,
        ..._.omit(normaliseClause(newClause), "rootClauseNode"),
      };
      const topicsByClausepartId = getTopicsFromClauseparts(clause.nodes);
      const normalisedClauseWithTopics = addTopicsToClauseParts(
        normalisedClause,
        topicsByClausepartId,
      );

      return normalisedClauseWithTopics;
    });
  });
  return {
    clauses: newClauses,
    documentId: state.documentId,
  };
}

function confirmAllTopicsRecursively(clauseNodes, lastEdited) {
  clauseNodes.forEach(clauseNode => {
    if (typeof clauseNode.topics !== "undefined") {
      clauseNode.topics.forEach(topic => {
        topic.is_confirmed = true;
        topic.last_edited = lastEdited;
      });
    }
    if (
      typeof clauseNode.clauseNodes !== "undefined" &&
      clauseNode.clauseNodes.length !== 0
    ) {
      confirmAllTopicsRecursively(clauseNode.clauseNodes, lastEdited);
    }
  });
}

function confirmAllTopics(state, documentId, lastEdited) {
  if (state.documentId !== documentId) {
    return state;
  }

  const newState = JSON.parse(JSON.stringify(state));

  _.each(newState.clauses, clauseGroup => {
    clauseGroup.forEach(clause => {
      if (typeof clause.nodes.clauseNodes === "undefined") {
        clause.nodes.topics.forEach(topic => {
          topic.is_confirmed = true;
          topic.last_edited = lastEdited;
        });
      } else {
        confirmAllTopicsRecursively(clause.nodes.clauseNodes, lastEdited);
      }
    });
  });
  return newState;
}

function onDocumentClauseTopicConfirm(state, action) {
  return updateClausepart(state, action, (clausepart, update) => {
    if (clausepart.topics.find(topic => topic.topic_id === update.topic_id)) {
      const deletedTopics = clausepart.topics.filter(topic => topic.is_deleted);
      return {
        topics: update.topics.concat(deletedTopics),
        last_edited: update.clausepart_last_edited,
      };
    }
    return {};
  });
}

function setClauseDeleted(state, action, deleted) {
  const {sectionId, clauseId, last_edited: lastEdited} = action.payload;
  return {
    ...state,
    clauses: {
      ...state.clauses,
      [sectionId]: state.clauses[sectionId].map(clause => {
        if (clause.id === clauseId) {
          return {
            ...clause,
            last_edited: lastEdited,
            deleted,
          };
        }
        return {...clause};
      }),
    },
  };
}

function processAddTopicToClause(clausepart, newTopic) {
  const topic = newTopic.topics.find(tt => newTopic.id === tt.topic_id);
  return {
    topics: _.uniq([...[topic], ...clausepart.topics], tt => tt.topic_id),
    last_edited: newTopic.clausepart_last_edited,
  };
}

function processRemoveTopicFromClause(clausepart, update) {
  return {
    topics: clausepart.topics.map(topic => {
      if (topic.topic_id === update.topic_id) {
        topic.is_deleted = true;
      }
      return topic;
    }),
    last_edited: update.clausepart_last_edited,
  };
}

function populateTopicsWithMatchesData(
  clausepartId,
  topics,
  topicMatchesPerClausepartId,
) {
  const topicMatches = topicMatchesPerClausepartId[clausepartId];
  topics.forEach(topic => {
    if (topicMatches[topic.topic_id] !== undefined) {
      topic.has_match = topicMatches[topic.topic_id];
    }
  });
}

function populateTopicsWithMatchesDataRecursively(
  clauseNodes,
  topicMatchesPerClausepartId,
) {
  clauseNodes.forEach(clauseNode => {
    if (typeof clauseNode.topics !== "undefined") {
      populateTopicsWithMatchesData(
        clauseNode.id,
        clauseNode.topics,
        topicMatchesPerClausepartId,
      );
    }
    if (
      typeof clauseNode.clauseNodes !== "undefined" &&
      clauseNode.clauseNodes.length !== 0
    ) {
      populateTopicsWithMatchesDataRecursively(
        clauseNode.clauseNodes,
        topicMatchesPerClausepartId,
      );
    }
  });
}

/* eslint-disable complexity, max-statements */
export default function documentClausesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.DOCUMENT_FETCH.SUCCESS:
      // @TODO fix reducer for clausepart add and remove to deal only with clause where clausepart was added or removed
      return action.payload.clauses
        ? {
            clauses: action.payload.clauses,
            documentId: action.payload.document_id,
          }
        : state;
    case ActionTypes.DOCUMENT_CLAUSEPART_ADD.SUCCESS:
      return {
        clauses: action.payload.clauses,
        documentId: action.payload.document_id,
      };
    case ActionTypes.DOCUMENT_CLEAR.SUCCESS:
      return UNINITIALISED;

    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD.SUCCESS: {
      return updateClausepart(state, action, processAddTopicToClause);
    }
    case ActionTypes.DOCUMENT_CLAUSEPART_UPDATE.SUCCESS: {
      return state;
    }
    case ActionTypes.DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE.SUCCESS: {
      const {clauseparts = [], user_id, username} = action.payload;
      const clausepartItem = clauseparts.find(
        clausepart => clausepart.document_id === state.documentId,
      );
      if (clausepartItem) {
        const {added, removed, clausepart} = clausepartItem;
        const baseData = _.omit(clausepartItem, [
          "added",
          "removed",
          "clausepart",
        ]);
        let newState = state;
        added.forEach(addedTopic => {
          const payload = {
            ...clausepart,
            ...addedTopic,
            ...baseData,
            user_id,
            username,
          };
          newState = updateClausepart(
            newState,
            {payload},
            processAddTopicToClause,
          );
        });

        removed.forEach(removedTopic => {
          const payload = {
            ...clausepart,
            ...removedTopic,
            ...baseData,
            user_id,
            username,
          };
          newState = updateClausepart(
            newState,
            {payload},
            processRemoveTopicFromClause,
          );
        });
        return newState;
      }
      return state;
    }
    case ActionTypes.CLAUSES_TOPIC_ADD.SUCCESS: {
      if (
        action &&
        action.payload &&
        action.payload.clauseparts &&
        action.payload.clauseparts.length
      ) {
        let newState = {...state};
        action.payload.clauseparts.forEach(clausepart => {
          const clauseAction = {payload: clausepart};
          newState = updateClausepart(
            newState,
            clauseAction,
            processAddTopicToClause,
          );
        });
        return newState;
      }
      return state;
    }
    case ActionTypes.CLAUSES_TOPIC_REMOVE.SUCCESS: {
      if (
        action &&
        action.payload &&
        action.payload.clauseparts &&
        action.payload.clauseparts.length
      ) {
        let newState = {...state};
        action.payload.clauseparts.forEach(clausepart => {
          const clauseAction = {payload: clausepart};
          newState = updateClausepart(
            newState,
            clauseAction,
            processRemoveTopicFromClause,
          );
        });
        return newState;
      }
      return state;
    }
    case ActionTypes.CLAUSES_TOPIC_CONFIRM.SUCCESS: {
      if (
        action &&
        action.payload &&
        action.payload.clauseparts &&
        action.payload.clauseparts.length
      ) {
        let newState = {...state};
        action.payload.clauseparts.forEach(clausepart => {
          const clauseAction = {payload: clausepart};
          newState = onDocumentClauseTopicConfirm(newState, clauseAction);
        });
        return newState;
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_REMOVE.SUCCESS: {
      return updateClausepart(state, action, processRemoveTopicFromClause);
    }
    case ActionTypes.DOCUMENT_CLAUSE_UNCONFIRMED_TOPICS_REMOVE.SUCCESS: {
      return updateClausepart(state, action, (clausepart, update) => ({
        topics: clausepart.topics.filter(topic => topic.is_confirmed),
        last_edited: update.clausepart_last_edited,
      }));
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_CONFIRM.SUCCESS: {
      return onDocumentClauseTopicConfirm(state, action);
    }
    case ActionTypes.DOCUMENT_TOPICS_CONFIRM_ALL.SUCCESS: {
      return confirmAllTopics(
        state,
        action.payload.document_id,
        action.payload.last_edited,
      );
    }

    case ActionTypes.DOCUMENT_CLAUSE_TOPICS_REORDER.SUCCESS: {
      return updateClausepart(state, action, (clausepart, update) => {
        const updatesById = byId(update.topics, "topic_id");

        return {
          last_edited: update.clausepart_last_edited,
          topics: clausepart.topics.map(topic => ({
            ...topic,
            topic_order: updatesById[topic.topic_id]
              ? updatesById[topic.topic_id].topic_order
              : topic.topic_order,
          })),
        };
      });
    }
    case ActionTypes.DOCUMENT_CLAUSE_CLASSIFICATION.SUCCESS: {
      return updateClause(state, action, (clause, update) => {
        const newClause = {
          ...clause,
          last_edited: action.payload.last_edited || clause.last_edited,
          nodes: JSON.parse(JSON.stringify(clause.nodes)),
        };

        _.forEach(update.clauseparts, (value, path) => {
          const clausepart = getClauseByPath(clause, path);
          const newClausePart = {
            ...clausepart,
            last_edited: value.last_edited,
            topics: value.topics,
            load_state: clausepart.load_state | value.load_state,
          };

          updateClauseByPath(newClause, newClausePart, path);
        });
        return newClause;
      });
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_PARAMETER_CLASSIFICATION.SUCCESS: {
      return updateClausepart(state, action, (clausepart, update) => ({
        last_edited: update.clausepart_last_edited,
        topics: clausepart.topics.map(topic => {
          if (update.topic_id === topic.topic_id) {
            return {
              ...topic,
              topicparameters: _.map(
                update.topicparameter_values,
                (values, tpId) => {
                  const newTpId = parseInt(tpId, 10);
                  const oldParameter = (topic.topicparameters || []).find(
                    oldTp => oldTp.topicparameter_id === newTpId,
                  );
                  return {
                    topicparameter_id: parseInt(tpId, 10),
                    ...(oldParameter ? oldParameter : {}),
                    values,
                  };
                },
              ),
            };
          }
          return topic;
        }),
        load_state: clausepart.load_state | update.load_state,
      }));
    }
    case ActionTypes.DOCUMENT_CLAUSE_QUALIFICATION.SUCCESS: {
      return updateClausepart(state, action, (clausepart, update) => ({
        last_edited: update.clausepart_last_edited,
        qualifiers: update.qualifiers,
        load_state: clausepart.load_state | update.load_state,
      }));
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_TOGGLE_EXACT_MATCH.SUCCESS: {
      return updateClausepart(state, action, (clausepart, update) => ({
        last_edited: update.clausepart_last_edited,
        topics: clausepart.topics.map(topic => {
          if (update.topic_id === topic.topic_id) {
            return {
              ...topic,
              exact_match_id: update.exact_match_id,
              last_edited: update.last_edited,
            };
          }
          return topic;
        }),
      }));
    }
    case ActionTypes.TOPICPARAMETER_DELETE.SUCCESS: {
      return {
        clauses: _.mapObject(state.clauses, clauseGroup =>
          clauseGroup.map(clause => {
            const updatedNode = removeTopicParameter(
              action.payload.id,
              clause.nodes,
            );

            return updatedNode.changed
              ? {
                  ...clause,
                  nodes: updatedNode.node,
                }
              : clause;
          }),
        ),
        documentId: state.documentId,
      };
    }
    case ActionTypes.DOCUMENT_PARSE.SUCCESS: {
      if (!action.payload.document_updates) {
        return state;
      }
      return handleDocumentParse(state, action);
    }
    case ActionTypes.TOPICPARAMETER_ACTUAL_VALUES_UPDATE.SUCCESS: {
      const {payload} = action;
      if (state === UNINITIALISED || !payload || !payload.updates) {
        return state;
      }
      let newState = state;
      if (payload.clauseTopicConfirmData) {
        newState = onDocumentClauseTopicConfirm(newState, {
          payload: {
            ...payload.clauseTopicConfirmData,
            clausepart_last_edited: payload.clausepart_last_edited,
          },
        });
      }
      newState = updateClausepartTopicparameterActualValues(newState, payload);
      return newState;
    }

    case ActionTypes.DOCUMENT_STATE_REVERT.SUCCESS:
      return {
        documentId: action.payload.id,
        clauses: action.payload.clauses,
      };

    case ActionTypes.DOCUMENT_CLAUSE_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_UPDATE.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_ADDITION_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_DEFINITION_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_ADDED_UPDATE.SUCCESS:
    case ActionTypes.DOCUMENT_DEFINITION_ADDITION_REVERT.SUCCESS:
      return action.payload.clauses
        ? {...state, clauses: action.payload.clauses}
        : state;

    case ActionTypes.DOCUMENT_CLAUSE_DELETE.SUCCESS:
      return setClauseDeleted(state, action, true);
    case ActionTypes.DOCUMENT_CLAUSE_ADD.REQUEST: {
      const sectionId = action.payload[3];
      const clauseId = action.payload[4];
      const newClausepartPosition = action.payload[5];
      if (sectionId && clauseId && newClausepartPosition) {
        return {
          ...state,
          clauses: {
            ...state.clauses,
            [sectionId]: state.clauses[sectionId].reduce((result, clause) => {
              if (clause.id === clauseId) {
                const pendingSaveClause = {
                  type: "PendingSave",
                  action_type: "DOCUMENT_CLAUSE_ADD",
                  nodes: [],
                  id: "document_clause_add_pending_save",
                };
                if (newClausepartPosition === -1) {
                  result.push(pendingSaveClause);
                  result.push(clause);
                } else {
                  result.push(clause);
                  result.push(pendingSaveClause);
                }
              } else {
                result.push(clause);
              }
              return result;
            }, []),
          },
        };
      }
      return state;
    }
    case ActionTypes.DOCUMENT_DEFINITION_ADD.REQUEST:
    case ActionTypes.DOCUMENT_CLAUSEPART_ADD.REQUEST: {
      const sectionId = action.payload[3];
      const clauseId = action.payload[4];
      const newClausepartPath = action.payload[5];
      if (sectionId && clauseId && newClausepartPath) {
        const oldClauseIndex = state.clauses[sectionId].findIndex(
          sectionClause => sectionClause.id === clauseId,
        );
        if (oldClauseIndex > -1) {
          const oldClause = state.clauses[sectionId][oldClauseIndex];
          const newClause = {
            ...oldClause,
            nodes: addPendingClausepartItem(oldClause.nodes, newClausepartPath),
          };
          return {
            ...state,
            clauses: {
              ...state.clauses,
              [sectionId]: state.clauses[sectionId].map((clause, index) => {
                if (index === oldClauseIndex) {
                  return newClause;
                }
                return clause;
              }),
            },
          };
        }
        return state;
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSEPARTS_REGEX_MATCHES_FETCH.SUCCESS:
      if (action.payload) {
        const topicMatchesPerClausepartId = action.payload;
        const newClauses = cloneDeep(state.clauses);
        Object.keys(newClauses).forEach(sectionIdStr => {
          const sectionId = parseInt(sectionIdStr, 10);
          const section = newClauses[sectionId];
          section.forEach(clause => {
            if (
              typeof clause.nodes.clauseNodes === "undefined" &&
              clause.nodes.topics
            ) {
              populateTopicsWithMatchesData(
                clause.nodes.id,
                clause.nodes.topics,
                topicMatchesPerClausepartId,
              );
            } else {
              populateTopicsWithMatchesDataRecursively(
                clause.nodes.clauseNodes,
                topicMatchesPerClausepartId,
              );
            }
          });
        });
        return {
          documentId: state.documentId,
          clauses: newClauses,
        };
      }
      return state;
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

function addPendingClausepartItem(node, pathToAddRaw) {
  let shouldStopProcessing = false;
  const isZeroItemAdded = pathToAddRaw.indexOf("-1") > -1;
  const pathToAdd = isZeroItemAdded
    ? pathToAddRaw.replace("-1", "0")
    : pathToAddRaw;
  function addItem(node) {
    if (shouldStopProcessing) {
      return;
    }
    if (node.clauseNodes) {
      const nodeToUseIndex = node.clauseNodes.findIndex(
        clauseNode => clauseNode.path && clauseNode.path === pathToAdd,
      );
      if (nodeToUseIndex > -1) {
        const newClauseNodes = cloneDeep(node.clauseNodes);
        shouldStopProcessing = true;
        newClauseNodes.splice(nodeToUseIndex + (isZeroItemAdded ? 0 : 1), 0, {
          type: "PendingSave",
          action_type: "DOCUMENT_CLAUSEPART_ADD",
        });
        node.clauseNodes = newClauseNodes;
      } else {
        node.clauseNodes.forEach(child => addItem(child));
      }
    }
  }
  addItem(node);
  return node;
}

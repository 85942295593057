import React from "react";
import OpenClause from "./open";
import DocumentDetailContext from "common_components/context/document_detail_context";

import getZoomedFontSize from "utils/get_zoomed_font_size";

const styles = {
  clauseTextWrap: {
    width: "100%",
    fontFamily: "Roboto, sans-serif",
    lineHeight: "1.25em",
    fontWeight: 200,
  },
};

/* function printPath(node, deep = 0) {
  let shift = "";
  while (shift.length < deep) {
    shift += " ";
  }
  console.log(`${shift}${node.path}`);
  if(node.clauseNodes) {
    node.clauseNodes.forEach(cnode => printPath(cnode, deep + 1));
  }
} */

export default class Clause extends React.Component {
  static contextType = DocumentDetailContext;
  componentDidMount() {
    this.handleUpdate(null, true);
  }

  componentDidUpdate(prevProps) {
    this.handleUpdate(prevProps);
  }

  render() {
    return (
      <div
        className="clause-text-wrap"
        style={{
          ...styles.clauseTextWrap,
          fontSize: getZoomedFontSize(16, "document", this.context.zoom, 1.2),
        }}
      >
        <OpenClause
          {...this.props}
          clauseId={this.props.clause.id}
          nodes={this.props.clause.nodes}
          onNumberItemHoverStart={this.props.onNumberItemHoverStart}
          onNumberItemHoverFinish={this.props.onNumberItemHoverFinish}
          isNumberItemsHovered={this.props.isNumberItemsHovered}
        />
      </div>
    );
  }

  handleUpdate(prevProps, mounted) {
    if (this.isClauseIssueSelected(prevProps, mounted)) {
      this.props.showClauseDetail(this.props.clause);
    }
  }

  isClauseIssueSelected(prevProps, mounted) {
    const {selectedIssue} = this.props;
    if (
      selectedIssue &&
      (mounted || prevProps.selectedIssue !== selectedIssue)
    ) {
      const [_issueId, reasonIndex] = selectedIssue.split("_");
      const issueId = parseInt(_issueId, 10);
      const issue = this.props.issues.find(_index => _index.id === issueId);
      if (issue) {
        const reason = (issue.reason || {})[reasonIndex];
        if (reason) {
          return reason.clause_id === this.props.clause.id;
        }
      }
    }
    return false;
  }
}

import basePath from "base_path";
import ActionTypes from "../constants/action_types";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.ISSUE_RESPONSE_UPDATE,
  (organisationId, issueResponse) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/issue/${
          issueResponse.issue_id
        }/response/${issueResponse.id}`,
        issueResponse,
        {
          withCredentials: true,
        },
      )
      .then(response => response.data),
);

import {combineReducers, createStore, applyMiddleware, compose} from "redux";
import {createHistory, createHashHistory, useBasename} from "history";

import {
  routerMiddleware,
  syncHistoryWithStore,
  routerReducer,
} from "react-router-redux";
import promiseMiddleware from "redux-promise-middleware";

import handleTransitions from "redux-history-transitions";

import axios from "axios";
import requestor from "./requestor";
import {default as analytics} from "utils/analytics";
import wrapHttpMethods from "utils/wrap_http_methods";
import {sentryReduxEnhancer} from "utils/sentry_utils";
import userSetUnauthorizedAction from "modules/user/actions/user_set_unauthorized";

import * as appReducers from "modules/reducers";

const environment = process.env.NODE_ENV;

wrapHttpMethods(Object.assign(requestor, axios));

axios.defaults.headers.Pragma = "no-cache";

const unauthorizedHandler = store => next => action => {
  if (action && action.payload && action.payload.status === 401) {
    store.dispatch(userSetUnauthorizedAction());
  } else {
    return next(action);
  }
};

function generateAppSettings(basename, useHashHistory) {
  let finalCreateStore;
  const createAppHistory = useHashHistory ? createHashHistory : createHistory;
  const history = basename
    ? useBasename(createAppHistory)({basename})
    : createAppHistory();

  const routerMiddlewareInstance = routerMiddleware(history);

  const GOOGLE_ANALYTICS_ID =
    process.env[`REACT_APP_GOOGLE_ANALYTICS_ID_${environment.toUpperCase()}`];
  const VERSION = process.env.REACT_APP_VERSION;

  if (environment === "development") {
    const createLogger = require("redux-logger");
    const logger = createLogger({collapsed: true});
    /* eslint-disable no-underscore-dangle */
    const composeEnhancers =
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    /* eslint-enable no-underscore-dangle */
    finalCreateStore = composeEnhancers(
      applyMiddleware(
        promiseMiddleware({
          promiseTypeSuffixes: ["REQUEST", "SUCCESS", "ERROR"],
        }),
        routerMiddlewareInstance,
        unauthorizedHandler,
        analytics(GOOGLE_ANALYTICS_ID, VERSION),
        logger,
      ),
      handleTransitions(history),
    )(createStore);
  } else {
    finalCreateStore = compose(
      applyMiddleware(
        promiseMiddleware({
          promiseTypeSuffixes: ["REQUEST", "SUCCESS", "ERROR"],
        }),
        routerMiddlewareInstance,
        unauthorizedHandler,
        analytics(GOOGLE_ANALYTICS_ID, VERSION),
      ),
      sentryReduxEnhancer,
      handleTransitions(history),
    )(createStore);
  }

  const reducer = combineReducers({
    router: routerReducer,
    ...appReducers,
    environment: () => environment,
  });

  const store = finalCreateStore(reducer);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept(["./modules/reducers"], () => {
      const nextRootReducer = require("./modules/reducers");
      store.replaceReducer(
        combineReducers({
          router: routerReducer,
          ...nextRootReducer,
        }),
      );
    });
  }

  const syncedHistory = syncHistoryWithStore(history, store, {
    selectLocationState: state => state.router,
  });

  return {
    store,
    history: syncedHistory,
  };
}

export default generateAppSettings;

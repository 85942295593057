import JSONPath from "JSONPath";
import getClauseByPath from "./get_clause_by_path";

// @TODO: improve nodes utils, add AB tests, probaly made them use OOP (Nodes, Path, etc)

export function getLastDigit(path) {
  if (!path || path === "root") {
    return null;
  }
  const lastDigit = path.match(/\[(\d+)\]$/)[1];
  if (lastDigit) {
    return parseInt(lastDigit, 10);
  }
  return null;
}

export function allZeroes(path) {
  return path ? path.match(/root(?:\[0\])+/) : "";
}

export function getPreviousPathString(path) {
  const pathLastDigit = getLastDigit(path);
  return path.replace(/\[(\d+)\]$/, () => `[${pathLastDigit - 1}]`);
}

export function getLastPath(nodes, parentPath = "root") {
  const previousParent = getPreviousPathString(parentPath);
  const base = getClauseByPath({nodes}, previousParent);
  const lastPath = [];
  let node = base;
  while (node !== null) {
    if (node.clauseNodes) {
      const lastIndex = node.clauseNodes.length - 1;
      node = node.clauseNodes[lastIndex];
      lastPath.push(lastIndex);
    } else {
      node = null;
    }
  }
  return `${previousParent}${lastPath.map(el => `[${el}]`).join("")}`;
}

export function getFirstNode(nodes) {
  const path = getFirstPath(nodes);
  if (path) {
    return getClauseByPath({nodes}, path);
  }
  return null;
}

export function getLastNode(nodes) {
  const path = getLastPath(nodes);
  if (path) {
    return getClauseByPath({nodes}, path);
  }
  return null;
}

export function getParentPath(path) {
  if (!path) {
    return null;
  }
  if (path === "root") {
    return null;
  }
  return path.replace(/\[\d+\]$/, "");
}

export function getPreviousPath(nodes, path) {
  const pathLastDigit = getLastDigit(path);
  // it is like root[1], root[10], root[1][2][3][4]
  if (pathLastDigit > 0) {
    return getPreviousPathString(path);
  }
  // it is like root[0], root[0][0]
  if (path === "root" || allZeroes(path)) {
    return null;
  }
  // it is like root[1][0], root[2][2][0]
  let parentPath = getParentPath(path);
  while (parentPath && parentPath.endsWith("[0]")) {
    parentPath = getParentPath(parentPath);
  }
  if (parentPath) {
    return getLastPath(nodes, parentPath);
  }
  return null;
}

export function combinePaths(root, childPath) {
  return `${root}${childPath}`;
}

export function getFirstPath(nodes, path = "root") {
  let node = getClauseByPath({nodes}, path);
  const list = [];
  while (node.clauseNodes) {
    list.push("[0]");
    node = node.clauseNodes[0];
  }
  let firstPath = combinePaths(path, list.join(""));
  while (isNodeEmpty(node) && firstPath) {
    firstPath = getNextPath(nodes, firstPath);
    node = firstPath && getClauseByPath({nodes}, firstPath);
  }
  return firstPath;
}

export function isNodeEmpty(node) {
  return (
    !node ||
    !(node.partial_text || node.text) ||
    (node.partial_text || node.text).length === 0
  );
}

export function getNextPath(nodes, path) {
  const parentPath = getParentPath(path);
  if (parentPath === null) {
    return null;
  }
  const parentNode = getClauseByPath({nodes}, parentPath);
  if (!parentNode || !parentNode.clauseNodes) {
    return null;
  }
  const pathLastDigit = getLastDigit(path);
  // if there are 3 in last node, last path can only by [0] or [1]
  if (pathLastDigit + 1 < parentNode.clauseNodes.length) {
    // return combinePaths(parentPath, `[${pathLastDigit + 1}]`);
    return getFirstPath(
      nodes,
      combinePaths(parentPath, `[${pathLastDigit + 1}]`),
    );
  }
  return getNextPath(nodes, parentPath);
}

export function getPathDepth(path) {
  if (path && path.length > 0) {
    const indexeMatches = path.match(/\[\d+\]/g);
    if (indexeMatches) {
      return indexeMatches.length;
    }
  }
  return 0;
}

export function getPathIndexForDepth(path, depth) {
  if (path && path.length > 0 && !isNaN(depth) && depth >= 0) {
    const indexeMatches = path.match(/\[\d+\]/g);
    if (indexeMatches) {
      const indexStr = indexeMatches[depth];
      if (indexStr && indexStr.length > 0) {
        const index = Number(indexStr.slice(1, -1));
        if (!isNaN(index)) {
          return index;
        }
      }
    }
  }
  return -1;
}

export function getPreviousNode(nodes, path) {
  const previousPath = getPreviousPath(nodes, path);
  if (previousPath) {
    const subNodes = getClauseByPath({nodes}, previousPath);
    return subNodes;
  }
  return null;
}

export function getNextNode(nodes, path) {
  const nextPath = getNextPath(nodes, path);
  if (nextPath) {
    return getClauseByPath({nodes}, nextPath);
  }
  return null;
}

export function getParentNode(nodes, path) {
  const parentPath = getParentPath(path);
  if (parentPath === null) {
    return null;
  }
  const parentNode = getClauseByPath({nodes}, parentPath);
  return parentNode;
}

export function isConjunction(node) {
  if (!node) {
    return false;
  }
  const text = node.partial_text || node.text;
  if (!text) {
    return false;
  }
  const processedText = text
    .replace(new RegExp("[^\\d\\w]", "ig"), "")
    .toLowerCase();
  return (
    processedText === "and" ||
    processedText === "or" ||
    processedText === "andor" ||
    processedText === "orand"
  );
}

export function getTopLeaf(node) {
  if (node) {
    const text = node.partial_text || node.text;
    if (text && !isConjunction(node)) {
      return node;
    }
    const {clauseNodes} = node;
    if (typeof clauseNodes !== "undefined" && clauseNodes.length > 0) {
      for (let index = 0; index < clauseNodes.length; index += 1) {
        const childNode = clauseNodes[index];
        const leafNode = getTopLeaf(childNode);
        if (leafNode) {
          return leafNode;
        }
      }
    }
  }
  return null;
}

export function getBottomLeaf(node) {
  if (node) {
    const text = node.partial_text || node.text;
    if (text && !isConjunction(node)) {
      return node;
    }
    const {clauseNodes} = node;
    if (typeof clauseNodes !== "undefined" && clauseNodes.length > 0) {
      for (let index = 1; index <= clauseNodes.length; index += 1) {
        const childNode = clauseNodes[clauseNodes.length - index];
        const leafNode = getBottomLeaf(childNode);
        if (leafNode) {
          return leafNode;
        }
      }
    }
  }
  return null;
}

export function getNodeLeafs(nodes, path) {
  if (nodes && path) {
    const subNodes = getClauseByPath({nodes}, path);
    if (subNodes) {
      const topLeaf = getTopLeaf(subNodes);
      const bottomLeaf = getBottomLeaf(subNodes);
      return {top: topLeaf, bottom: bottomLeaf};
    }
  }
  return null;
}

export function getPreviousLeafs(nodes, path) {
  const previousPath = getPreviousPath(nodes, path);
  return getNodeLeafs(nodes, previousPath);
}

export function getNextLeaf(nodes, path) {
  const nextPath = getNextPath(nodes, path);
  const leafs = getNodeLeafs(nodes, nextPath);
  return leafs && leafs.top;
}

export function getLeafs(node) {
  if (!node) {
    return [];
  }
  if (node.partial_text || node.text) {
    return [node];
  }
  const leafs = JSONPath.eval(node, "$..[?(@.partial_text || @.text)]");
  return leafs.filter(leaf => !isConjunction(leaf));
}

export function getLeafText(node) {
  if (!node) {
    return null;
  }
  const text = node.partial_text || node.text;
  if (text) {
    return text.trim();
  }
  return null;
}

export function findPathByText(node, text, path = "root") {
  const nodeText = node.partial_text || node.text;
  if (nodeText === text) {
    return path;
  }
  if (node.clauseNodes) {
    const matchingChildIndex = node.clauseNodes.findIndex(item =>
      findPathByText(item, text),
    );
    if (matchingChildIndex >= 0) {
      return findPathByText(
        node.clauseNodes[matchingChildIndex],
        text,
        `${path}[${matchingChildIndex}]`,
      );
    }
  }
  return null;
}

// searches the closest list node
// it does not include current node
export function getClosestList(nodes, path) {
  if (nodes && path) {
    let parentPath = path;
    let prevParentPath;
    while (prevParentPath !== parentPath) {
      prevParentPath = parentPath;
      parentPath = getParentPath(parentPath);
      if (parentPath === null) {
        return null;
      }
      const parentNode = getClauseByPath({nodes}, parentPath);
      if (!parentNode) {
        return null;
      }
      if (parentNode.counterType) {
        return {
          node: parentNode,
          path: parentPath,
        };
      }
    }
  }
  return null;
}

export function findCounterType(change, _path) {
  if (change) {
    const {nodes, path: changePath} = change;
    const closestList = getClosestList(nodes, _path || changePath);
    if (closestList && closestList.node) {
      return closestList.node.counterType;
    }
  }
  return null;
}

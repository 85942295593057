function filterByWord(option, filterString) {
  const filterRegexes = filterString
    ? filterString
        .split(/\s/)
        .map(
          text =>
            new RegExp(
              text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&"),
              "i",
            ),
        )
    : null;
  if (filterRegexes) {
    return filterRegexes.every(filterRegex => filterRegex.test(option.label));
  }
  return true;
}

export default filterByWord;

import React, {ChangeEvent, useMemo} from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Link as LinkIcon, StarRate as StarRateIcon} from "@material-ui/icons";
import {Link} from "react-router";
import {
  ContextId,
  WorkflowId,
  WorkflowInfo,
} from "common/flowmaster/types/workflow";
import {OrganisationId} from "common/types/organisation";

interface WorkflowSelectorProps {
  organisationId: OrganisationId;
  workflows: WorkflowInfo[];
  contextTypeId?: ContextId;
  selectedWorkflowId: WorkflowId;
  updateselectedWorkflowId: (workflowId: WorkflowId) => void;
  label?: string;
  showStarredWorkflows?: boolean;
}

const WorkflowSelector = ({
  organisationId,
  workflows,
  contextTypeId,
  selectedWorkflowId,
  updateselectedWorkflowId,
  label = "Workflow",
  showStarredWorkflows = false,
}: WorkflowSelectorProps) => {
  const handleChange = (event: ChangeEvent<{value: number}>) => {
    updateselectedWorkflowId(event.target.value);
  };

  const filteredWorkflows = useMemo(
    () =>
      contextTypeId
        ? workflows?.filter(workflow => workflow.context_id === contextTypeId)
        : workflows,
    [workflows, contextTypeId],
  );

  const [starredWorkflows, nonStarredWorkflows] = useMemo(() => {
    return filteredWorkflows.reduce(
      (partitionedWorkflows, workflow) => {
        if (workflow.is_star) {
          partitionedWorkflows[0].push(workflow);
        } else {
          partitionedWorkflows[1].push(workflow);
        }
        return partitionedWorkflows;
      },
      [[], []] as [WorkflowInfo[], WorkflowInfo[]],
    );
  }, [filteredWorkflows]);

  const renderWorkflowMenuItem = (
    workflow: WorkflowInfo,
    isStarred = false,
  ) => (
    <MenuItem key={workflow.id} value={workflow.id}>
      {isStarred && (
        <StarRateIcon style={{marginRight: "8px", color: "#fbc02d"}} />
      )}
      {workflow.name}
    </MenuItem>
  );

  const workflowItems = useMemo(
    () =>
      filteredWorkflows.length > 0
        ? [
            ...(showStarredWorkflows
              ? starredWorkflows.map(workflow =>
                  renderWorkflowMenuItem(workflow, true),
                )
              : []),
            ...(showStarredWorkflows &&
            starredWorkflows.length > 0 &&
            nonStarredWorkflows.length > 0
              ? [<Divider key="divider" style={{margin: "8px 0"}} />]
              : []),
            ...(showStarredWorkflows
              ? nonStarredWorkflows
              : filteredWorkflows
            ).map(workflow => renderWorkflowMenuItem(workflow)),
          ]
        : [
            <MenuItem key="no-workflows" disabled>
              No workflows available
            </MenuItem>,
          ],
    [
      filteredWorkflows,
      showStarredWorkflows,
      starredWorkflows,
      nonStarredWorkflows,
    ],
  );

  const renderValue = (selectedId: number) => {
    const foundWorkflow = workflows.find(
      workflow => workflow.id === selectedId,
    );
    return foundWorkflow ? foundWorkflow.name : "";
  };

  return (
    <div style={{display: "flex", alignItems: "baseline"}}>
      <FormControl style={{width: "30em"}}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={selectedWorkflowId === -1 ? "" : selectedWorkflowId}
          onChange={handleChange}
          renderValue={renderValue}
          disabled={workflows.length === 0}
        >
          {workflowItems}
        </Select>
      </FormControl>
      {selectedWorkflowId > 0 && (
        <Link
          to={`/organisation/${organisationId}/workflow/${selectedWorkflowId}/detail`}
        >
          <IconButton size="small" style={{padding: 0}}>
            <LinkIcon />
          </IconButton>
        </Link>
      )}
    </div>
  );
};

export default WorkflowSelector;

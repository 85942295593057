export default class BaseRoute {
  constructor(path, store) {
    this.path = path;
    this.store = store || {};
  }

  setupTransitions() {}

  getChildRoutes(location, cb) {
    cb(null, this.getCachedChildRoutes());
  }

  getCachedChildRoutes() {
    if (!this.cachedChildRoutes) {
      this.cachedChildRoutes = this.createChildRoutes();
    }
    return this.cachedChildRoutes;
  }

  createChildRoutes() {
    return [];
  }

  onEnter(nextRouterState) {
    this.setupTransitionsFirstTime();
    this.doFetch = true;
    this.entryState = {router: nextRouterState};
    this.fetchData(this.entryState);
  }

  setupTransitionsFirstTime() {
    if (!this.areTransitionsSetup) {
      this.setupTransitions();
      this.areTransitionsSetup = true;
    }
  }

  onLeave(nextRouterState) {
    this.doFetch = false;
    this.clearActiveData(nextRouterState);
  }

  fetchData(nextState) {
    if (this.doFetch && nextState.router) {
      this.fetchActiveData(nextState);
    }
  }

  fetchActiveData(/* props */) {}
  clearActiveData() {}
}

import _ from "lodash";

const findClauseChildren = (nodes, counter, value) => {
  if (Array.isArray(nodes)) {
    nodes.forEach(item => {
      if (item.reference && item.reference.startsWith(`${value}.`)) {
        counter.push(item.clauseId);
      }
      if (item.clauseNodes) {
        findClauseChildren(item.clauseNodes, counter, value);
      }
    });
  }
};

const countAllClauses = (nodes, counter, value) => {
  if (Array.isArray(nodes)) {
    nodes.forEach(item => {
      if (item.clause_reference === value) {
        counter.push(item);
      }
      if (item.clauseNodes) {
        countAllClauses(item.clauseNodes, counter, value);
      }
    });
  }
};

const groupByClauseReference = clauseReference => [
  ..._.values(_.groupBy(clauseReference, "clause_reference")),
];
const filterByReferances = (references = []) => {
  return references.filter(
    item =>
      !references.find(ref =>
        (item?.reference ?? "").startsWith(`${ref?.reference}.`),
      ),
  );
};

const findGroups = (currentClauses, documentClauseparts) => {
  if (currentClauses.length > 1) {
    const result = [];
    const notGrouped = [...currentClauses];
    const references = [];

    groupByClauseReference(notGrouped).forEach(clauseGroup => {
      if (clauseGroup.length > 1) {
        const allClauseReferances = [];

        countAllClauses(
          documentClauseparts,
          allClauseReferances,
          clauseGroup[0].clause_reference,
        );
        if (
          Math.ceil((100 * clauseGroup.length) / allClauseReferances.length) >
          65
        ) {
          references.push(_.sortBy(allClauseReferances, ["id"])[0]);
        } else {
          _.sortBy(clauseGroup, ["id"]).forEach(item => {
            const flag = item.reference.split(".");
            const groupResult = [];
            if (flag.length > 2) {
              flag.splice(-1, 1);
              clauseGroup.forEach(item => {
                if (
                  item?.reference &&
                  item.reference.startsWith(`${flag.join(".")}.`)
                ) {
                  groupResult.push(item);
                }
              });

              if (groupResult.length) {
                const familiarValues = {
                  reference: flag.join("."),
                  sectionId: item?.clause_section_id,
                  values: groupResult,
                };

                const parent = documentClauseparts.find(
                  item => item.reference === familiarValues.reference,
                );
                const clausesCounter = [];
                if (parent) {
                  findClauseChildren(
                    parent.nodes.clauseNodes,
                    clausesCounter,
                    familiarValues.reference,
                  );
                  if (
                    Math.ceil(
                      (100 * familiarValues.values.length) /
                        _.uniq(clausesCounter).length,
                    ) > 65
                  ) {
                    references.push(parent);
                  }
                }
              }
            }
          });
        }
      }
    });

    const filteredReferences = filterByReferances(references);
    const filterednotGroupedReferences = notGrouped
      .flat()
      .filter(
        item =>
          !references.find(ref =>
            item.reference.startsWith(`${ref?.reference}.`),
          ),
      );

    return _.uniqBy(
      [...filterednotGroupedReferences, ..._.uniq(filteredReferences)].concat(
        result,
      ),
      "id",
    );
  } else {
    return currentClauses;
  }
};

const groupClausesReferences = (clauses, documentClauseparts) => {
  return clauses.map(item => {
    const groupedApplicableClauses = findGroups(
      item.applicableClauses,
      documentClauseparts,
    );
    return {...item, groupedApplicableClauses};
  });
};

export default groupClausesReferences;

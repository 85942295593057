import isIssueAlert from "common/utils/issues/is_issue_alert";

function doesClauseHaveIssue(
  issue,
  clause,
) {
  if (
    !(issue.issue_class_id === 1 || issue.issue_class_id === 3) ||
    !(issue.show_if_triggered_only === "always" || issue.show_if_triggered_only === "ifTriggered") ||
    !isIssueAlert(issue)
  ) {
    return false;
  }
  return issue.currentApplicableClauses.find(applicableClause => applicableClause.id === clause.id);

}

export default doesClauseHaveIssue;

import _ from "underscore";

import Issues from "../../issues";

function describeReason(clauses, topicsById, issue, showClause = true) {
  const pairs = _.zip(issue.reason, issue.rules.issues).filter(args => {
    const [reason] = args;
    return reason && reason.length > 0;
  });
  if (pairs[0]) {
    const [_reasons, rules] = pairs[0];
    const reason = _reasons.find(
      _reason => _reason && (Array.isArray(_reason) ? _reason.length : true),
    );
    return Issues[rules.issue_type.toLowerCase()].reason(
      clauses,
      topicsById,
      {...rules, reason},
      showClause,
    );
  }
  return "";
}

export default describeReason;

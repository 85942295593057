const paddingOffset = 64;
const marginOffset = 112;

// all sizes are specified in rem
export const mainHeights = {
  mainHeaderHeight: 4,
  documentHeaderHeight: 4,
  menuHeaderHeight: 2,
  tableOfContentsContent: 14,
  clauseMenuHeadingHeight: 1.6,
  rightHandMenuHeaderHeight: 3.6,
};

export {paddingOffset, marginOffset};

import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import React from "react";

import sentryConfig from "sentry_config";

let lastSentEvent = null;
export const debounceThreshold = 2 * 1000; // 2 seconds - can adjust

export const beforeSend = (event, hint) => {
  if (
    // To prevent spam, if an event...
    lastSentEvent !== null &&
    // ...with the same message...
    lastSentEvent.message === event.message &&
    // ...has been sent in the last N seconds...
    new Date().getTime() - lastSentEvent.timestamp < debounceThreshold
  ) {
    return null; // ...don't send another one.
  }

  const exceptionErrorMessages = [
    "Network Error",
    "jobs_amount failed",
    "find_issue_jobs_amount failed",
  ];

  const error = hint.originalException;
  if (
    error &&
    error.message &&
    exceptionErrorMessages.find(exceptionMessage =>
      error.message.match(new RegExp(exceptionMessage)),
    )
  ) {
    return null;
  }

  lastSentEvent = event;

  return event;
};

export function initSentry(appType) {
  const isProduction = process.env.NODE_ENV === "production";

  if (isProduction && sentryConfig.useInProduction) {
    Sentry.init({
      dsn: sentryConfig.dsn,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      environment: `${appType}-${isProduction ? "prod" : "dev"}`,
      beforeSend,
    });
  }
}

export function withSentry(InputComponent) {
  return function(props) {
    if (process.env.NODE_ENV === "production" && sentryConfig.useInProduction) {
      return (
        <Sentry.ErrorBoundary>
          <InputComponent {...props} />
        </Sentry.ErrorBoundary>
      );
    }
    return <InputComponent {...props} />;
  };
}

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: action => ({type: action.type}),
  stateTransformer: () => null,
});

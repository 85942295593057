import currencySymbols from "currency-symbol-map";

import _ from "underscore";
import React from "react";

import Paper from "material-ui/Paper";
import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";
import ReactSelectLabel from "common_components/react_select_label";
import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";

import DeleteIcon from "material-ui/svg-icons/action/delete";
import AddIcon from "material-ui/svg-icons/content/add";

import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

const {currencySymbolMap} = currencySymbols;

const currencies = Object.keys(currencySymbolMap);

function initialise(state) {
  return {
    topic_id: null,
    parameter_id: null,
    currencies: {},
    ..._.pick(state, "topic_id", "parameter_id", "currencies"),
  };
}

function validate(issue) {
  if (!(issue.rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  if (!(issue.rules.parameter_id > 0)) {
    return {rulesError: {parameter_id: "You must set a parameter id"}};
  }
  if (!(Object.keys(issue.rules.currencies).length > 0)) {
    return {
      rulesError: {
        currencies: "You must set a currency",
      },
    };
  }
  const {currencies: currenciesMap} = issue.rules;
  const currenciesUsed = Object.keys(currenciesMap);
  let currency;

  currency = _.find(
    currenciesUsed,
    curr => !currenciesMap[curr].max && !currenciesMap[curr].min,
  );
  if (currency) {
    return {
      rulesError: {
        currencies: `You must set a maximum or a minimum for ${currency}`,
      },
    };
  }

  currency = _.find(currenciesUsed, curr => {
    const {max, min} = currenciesMap[curr];
    return max && min && max < min;
  });
  if (currency) {
    return {
      rulesError: {
        currencies: `The minimum must be less than the maximum for ${currency}`,
      },
    };
  }
  return null;
}

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      rulesError,
      topics,
      isNonOverridableFieldsDisabled,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    return (
      <div>
        <div>
          <ReactSelectLabel>Topic</ReactSelectLabel>
          <Select
            className="topic"
            multi={false}
            value={rules.topic_id}
            options={topics
              .filter(topic =>
                topic.parameters.find(
                  parameter => parameter.parameter_type === "monetary",
                ),
              )
              .map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
            onChange={this.updateTopic}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getTopicLink(this.props.organisationId, rules.topic_id)}
            shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
          />
          {renderError(rulesError, "topic_id")}
        </div>
        <div>
          <ReactSelectLabel>Parameter</ReactSelectLabel>
          <Select
            className="parameter"
            multi={false}
            value={rules.parameter_id}
            options={
              currentTopic &&
              currentTopic.parameters
                .filter(parameter => parameter.parameter_type === "monetary")
                .map(parameter => ({
                  label: parameter.name,
                  value: parameter.id,
                }))
            }
            onChange={this.updateParameter}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getParameterLink(
              this.props.organisationId,
              rules.topic_id,
              rules.parameter_id,
            )}
          />
          {renderError(rulesError, "parameter_id")}
        </div>
        <Paper
          className="currencies"
          style={{
            margin: "1em",
            padding: "1em",
          }}
        >
          {this.renderCurrencies(rules.currencies)}
          {renderError(rulesError, "currencies")}
          <IconButton
            className="add"
            onClick={this.addCurrency}
            style={{zIndex: 0}}
            disabled={disabled}
          >
            <AddIcon />
          </IconButton>
        </Paper>
      </div>
    );
  }

  renderCurrencies(selectedCurrencies) {
    return _.map(selectedCurrencies, (...args) => this.renderCurrency(...args));
  }
  renderCurrency(rules, currency) {
    return (
      <div className="currency-item" key={currency}>
        <div
          style={{
            display: "inline-block",
            marginRight: "1em",
            marginBottom: "-9px",
          }}
        >
          <Select
            className="currency"
            multi={false}
            value={currency}
            placeholder="Select currency"
            options={[
              {label: "$", value: "$"},
              {label: "USD", value: "USD"},
              {label: "EUR", value: "EUR"},
              {label: "GBP", value: "GBP"},
              {label: "JPY", value: "JPY"},
              {label: "AUD", value: "AUD"},
              {label: "CAD", value: "CAD"},
            ].concat(
              currencies.map(curr => ({
                label: curr,
                value: curr,
              })),
            )}
            onChange={this.updateCurrency(currency)}
            style={{width: "7em", ...this.props.overridableFieldsStyle}}
            disabled={
              this.props.isNonOverridableFieldsDisabled || this.props.disabled
            }
          />
        </div>
        <TextField
          type="number"
          className="min"
          value={rules.min}
          onChange={this.updateMin(currency)}
          style={{width: "10em", ...this.props.overridableFieldsStyle}}
          name="min"
          floatingLabelText="Minimum amount"
          disabled={
            this.props.isNonOverridableFieldsDisabled || this.props.disabled
          }
        />
        <TextField
          type="number"
          className="max"
          value={rules.max}
          onChange={this.updateMax(currency)}
          style={{width: "10em", ...this.props.overridableFieldsStyle}}
          name="max"
          floatingLabelText="Maximum amount"
          disabled={
            this.props.isNonOverridableFieldsDisabled || this.props.disabled
          }
        />
        <IconButton
          className="delete"
          onClick={this.deleteCurrency(currency)}
          disabled={
            this.props.isNonOverridableFieldsDisabled || this.props.disabled
          }
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
      parameter_id: null,
    });
  };
  updateParameter = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_id: value.value,
    });
  };
  updateMin = _.memoize(
    currency => event => {
      const {editLevel, rules, onChange} = this.props;
      const value = event.target.value !== "" ? event.target.value : null;

      onChange({
        ...rules,
        ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
        currencies: {
          ...rules.currencies,
          ..._.object(
            [currency],
            [{...rules.currencies[currency], min: value}],
          ),
        },
      });
    },
    (...args) => JSON.stringify([...args]),
  );
  updateMax = _.memoize(
    currency => event => {
      const {editLevel, rules, onChange} = this.props;
      const value = event.target.value !== "" ? event.target.value : null;

      onChange({
        ...rules,
        ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
        currencies: {
          ...rules.currencies,
          ..._.object(
            [currency],
            [{...rules.currencies[currency], max: value}],
          ),
        },
      });
    },
    (...args) => JSON.stringify([...args]),
  );
  updateCurrency = _.memoize(
    currency => value => {
      const {editLevel, rules, onChange} = this.props;
      onChange({
        ...rules,
        ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
        currencies: {
          ..._.omit(rules.currencies, currency),
          ..._.object([value.value], [rules.currencies[currency]]),
        },
      });
    },
    (...args) => JSON.stringify([...args]),
  );
  addCurrency = () => {
    const {editLevel, rules, onChange} = this.props;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      currencies: {
        ...rules.currencies,
        ..._.object(["new"], [{}]),
      },
    });
  };
  deleteCurrency = _.memoize(
    currency => () => {
      const {editLevel, rules, onChange} = this.props;
      onChange({
        ...rules,
        ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
        currencies: _.omit(rules.currencies, currency),
      });
    },
    (...args) => JSON.stringify([...args]),
  );
  /* eslint-enable no-invalid-this */
}

export default {
  initialise,
  validate,
  component: Component,
};

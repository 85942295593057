/* Renders clauses grouped by topics */
import React from "react";
import _ from "underscore";
import {get, pad} from "lodash";

import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OpenGroupIcon from "@material-ui/icons/KeyboardArrowDown";
import CloseGroupIcon from "@material-ui/icons/KeyboardArrowRight";
import * as colors from "@material-ui/core/colors";
import FactCheck from "icons/FactCheck";
import CloseIcon from "@material-ui/icons/Close";
import PriorityIcon from "@material-ui/icons/SortByAlpha";
import OrderIcon from "@material-ui/icons/FormatListNumbered";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";

import DocumentDetailContext from "common_components/context/document_detail_context";

import groupClausesByHeadings from "utils/clauses/group_clauses_by_headings";
import groupClausesByDefinitions from "utils/clauses/group_clauses_by_definitions";
import addIndentationToClauses from "utils/clauses/add_indentation_to_clauses";
import renderClause from "utils/clauses/render_clause";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import {getUpdatedManualCorrections} from "utils/manual_corrections_utils";

import sortClausesByPriority from "utils/clauses/sort_clauses_by_priority";
import addPriorityToClauses from "utils/clauses/add_priority_to_clauses";

const styles = {
  groupContainer: {
    flipped: {
      backgroundColor: "#f4f4f4",
      marginTop: 10,
      padding: "12px 20px",
    },
    unflipped: {
      marginTop: 10,
    },
  },
  headingText: {
    flipped: {
      padding: "4px 0px",
      fontWeight: 600,
      textAlign: "center",
      color: "#212121",
    },
    unflipped: {
      padding: "4px 0px",
      fontWeight: 500,
      textAlign: "center",
      color: colors.grey[800],
    },
  },
  definition: {
    marginTop: "4px",
    fontStyle: "italic",
    fontWeight: "500",
    color: colors.grey[800],
  },
  moreClausesButton: {
    cursor: "pointer",
    textAlign: "center",
    textDecoration: "underline",
    marginBottom: "8px",
    color: "#757575",
    fontSize: "13px",
    fontWeight: "400",
  },
  selectValueLabel: {color: "#aaa"},
  selectValueText: {paddingRight: "1em"},
  selectItem: {textAlign: "right", display: "inherit"},
  editIcon: {
    marginLeft: "6px",
    marginBottom: "2px",
    cursor: "pointer",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  topicBlock: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

function addParentDataToClauses(groups) {
  return groups.map(clause => {
    const newItems = [];
    let parentIndex = null;
    clause.items.forEach((item, itemIndex) => {
      const itemItem = item.item;
      if (itemItem) {
        const nextItem = clause.items[itemIndex + 1]
          ? clause.items[itemIndex + 1].item
          : null;
        let newItem;
        if (nextItem && nextItem.indent > itemItem.indent) {
          newItem = {
            ...item,
            isParent: true,
            children: [],
          };
          parentIndex = itemIndex;
        } else if (
          parentIndex !== null &&
          itemItem.indent > clause.items[parentIndex].item.indent &&
          itemItem.clean_reference.startsWith(
            clause.items[parentIndex].item.clean_reference,
          ) &&
          newItems[newItems.length - 1] &&
          !newItems[newItems.length - 1].isDefinition
        ) {
          newItems[newItems.length - 1].children.push(item);
          return;
        } else {
          newItem = item;
          parentIndex = null;
        }
        newItems.push(newItem);
      } else {
        newItems.push(item);
      }
    });
    return {
      ...clause,
      items: newItems,
    };
  });
}

function getInitialSelectedTopicName(clauses) {
  const topicNames = getClausesTopicNames(clauses);
  return topicNames.length === 1
    ? topicNames[0]
    : topicNames.find(item => item === "Key")
    ? "Key"
    : "All";
}

function findFilesUsed(clauses) {
  return Object.values(clauses)
    .flat()
    ?.reduce((fileSet, clause) => {
      fileSet[clause.file_index - 1] = 1;
      return fileSet;
    }, {});
}
function selectInitialFile(filesUsed, fileIndex) {
  return filesUsed[fileIndex - 1]
    ? fileIndex - 1
    : parseInt(Object.keys(filesUsed)?.[0], 10);
}

class ClausesRenderer extends React.Component {
  static contextType = DocumentDetailContext;

  constructor(props) {
    super(props);
    const filesUsed = findFilesUsed(props.clauses);
    this.state = {
      selectedTopicName: getInitialSelectedTopicName(props.clauses),
      showPriorityOrder: false,
      filesUsed,
      shouldShowViewAllClauses: true,
      selectedFileIndex: selectInitialFile(filesUsed, props.fileIndex),
      fileOpen: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.clauses, this.props.clauses)) {
      const filesUsed = findFilesUsed(this.props.clauses);
      this.setState(() => ({
        selectedTopicName: getInitialSelectedTopicName(this.props.clauses),
        filesUsed,
        shouldShowViewAllClauses: true,
        selectedFileIndex: selectInitialFile(filesUsed, this.props.fileIndex),
        fileOpen: {},
      }));
    }
  }

  render() {
    const {clauses, documentChanges, disableNoClausesFoundMessage} = this.props;
    const {selectedTopicName} = this.state;
    const {clausesToRender, hasRemovedClauses} = this.getClausesToRender(
      clauses,
      selectedTopicName,
      documentChanges,
      this.props.selectedReport,
      this.props.manualCorrections,
      this.props.correctApplicableClause,
      this.props.documentClauseparts,
    );
    const clausesCount = clausesToRender.length;

    const clauseGroups = this.getClauseGroups(clausesToRender);

    return (
      <div
        style={{
          ...styles.wrapper,
          ...(this.props.styles ? this.props.styles : {}),
        }}
      >
        <div style={styles.topicBlock}>
          {clausesCount ? (
            <TopicSelector
              clauses={clauses}
              zoom={this.context.zoom}
              selectedTopicName={this.state.selectedTopicName}
              onTopicSelectorChange={this.onTopicSelectorChange}
            />
          ) : !disableNoClausesFoundMessage ? (
            <span style={{width: "100%", fontStyle: "italic"}}>
              No clauses found
            </span>
          ) : null}
          {this.renderEditIcon(hasRemovedClauses)}
          {this.renderPrioritySelector()}
        </div>
        {clausesCount
          ? this.renderClauseGroups(
              clauseGroups,
              this.props.onClauseClick,
              this.props.onHeadingClick,
              clausesCount,
              this.props.fileIndex,
              this.props.fileNames,
            )
          : null}
      </div>
    );
  }

  getClauseGroups(clausesToRender) {
    const clausePriorities = this.props?.issue?.clause_priority ?? [];
    const orderByPrioritisation =
      this.state.showPriorityOrder && clausePriorities.length;

    if (orderByPrioritisation) {
      return this.getPrioritisedClauses(clausesToRender);
    } else {
      return this.getUnprioritisedClauses(clausesToRender);
    }
  }

  getPrioritisedClauses(clausesToRender) {
    const {issue} = this.props;
    const sortedClauses = sortClausesByPriority(
      clausesToRender,
      issue.clause_priority,
      issue.id,
    );
    return [{items: sortedClauses.map(item => ({item}))}];
  }

  getUnprioritisedClauses(clausesToRender) {
    const {issue} = this.props;
    const {
      documentDefinitions,
      documentHeadings,
      documentSections,
    } = this.props;

    const clausesGroupedByHeadings = groupClausesByHeadings(
      addPriorityToClauses(
        clausesToRender,
        issue?.clause_priority ?? [],
        issue?.id,
      ),
      documentHeadings,
      documentSections,
    );
    const clausesGroupedByHeadingsAndDefinitions = groupClausesByDefinitions(
      clausesGroupedByHeadings,
      documentDefinitions,
      documentSections,
    );
    const clausesGroupedByHeadingsAndDefinitionsIndented = addIndentationToClauses(
      clausesGroupedByHeadingsAndDefinitions,
    );
    const clauseGroups = addParentDataToClauses(
      clausesGroupedByHeadingsAndDefinitionsIndented,
    );

    return clauseGroups;
  }

  triggerUpdateEditState = () =>
    this.props.updateEditState(!this.props.editingReport);

  renderEditIcon = hasRemovedClauses => {
    if (!this.props.correctApplicableClause) {
      return null;
    }
    const Icon = this.props.editingReport ? CloseIcon : FactCheck;
    return (
      <Icon
        style={{
          ...styles.editIcon,
          color: hasRemovedClauses ? "#FF0000" : "#757575",
        }}
        onClick={this.triggerUpdateEditState}
      />
    );
  };

  renderPrioritySelector() {
    const clausePriorities = this.props?.issue?.clause_priority ?? [];
    if (!clausePriorities.length) {
      return null;
    }
    const Icon = this.state.showPriorityOrder ? OrderIcon : PriorityIcon;
    return (
      <Icon
        style={{
          ...styles.editIcon,
        }}
        onClick={this.toggleSortOrder}
        text={
          this.state.showPriorityOrder
            ? "Toggle sort order to document order"
            : "Toggle sort order to priority order"
        }
      />
    );
  }

  toggleSortOrder = () => {
    this.setState({showPriorityOrder: !this.state.showPriorityOrder});
  };

  onTopicSelectorChange = e => {
    const newTopicName = e.target.value;
    this.setState(() => ({selectedTopicName: newTopicName}));
  };

  getFileset(groupItem) {
    const topItems = groupItem?.items;
    const item = topItems?.[0]?.item;
    if (item) {
      return item?.file_index ?? 1;
    }
    const itemsFirst = topItems?.[0]?.items?.[0];
    if (itemsFirst) {
      return itemsFirst?.file_index ?? 1;
    }
    return null;
  }

  renderClauseGroups = (
    clauseGroups,
    onClauseClick,
    onHeadingClick,
    clausesCount,
    fileIndex,
    fileNames,
  ) => {
    const {filesUsed} = this.state;

    return (
      <div>
        {this.renderFileGroupingSelector(fileNames, filesUsed, fileIndex)}
        {this.state.shouldShowViewAllClauses
          ? this.renderViewAllClauses(
              clauseGroups,
              fileIndex,
              fileNames,
              onHeadingClick,
              onClauseClick,
              clausesCount,
            )
          : this.renderSelectedFileClauses(
              clauseGroups,
              onHeadingClick,
              onClauseClick,
              clausesCount,
            )}
      </div>
    );
  };

  renderFileGroupingSelector(fileNames, filesUsed, fileIndex) {
    const usedFilenames = fileNames.map((filename, index) => ({
      filename,
      index,
      use: filesUsed[index],
      match: index - 1 === fileIndex,
    }));
    if (fileNames.length > 1) {
      const enabled = Object.keys(filesUsed).length > 1;
      return (
        <div style={{display: "flex", margin: "1em 0"}}>
          <div
            style={{
              width: "100%",
              flexShrink: 1,
              overflow: "hidden",
              marginRight: "0.5em",
            }}
          >
            <FileSelector
              usedFilenames={usedFilenames}
              disabled={this.state.shouldShowViewAllClauses || !enabled}
              selectedFileIndex={this.state.selectedFileIndex}
              selectFileIndex={selectedFileIndex =>
                this.setState({selectedFileIndex})
              }
            />
          </div>
          <div style={{flexShrink: 0}}>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.shouldShowViewAllClauses}
                  disabled={!enabled}
                  onChange={() =>
                    this.setState({
                      shouldShowViewAllClauses: !this.state
                        .shouldShowViewAllClauses,
                    })
                  }
                  name="File selector"
                  color="primary"
                />
              }
              label="All"
            />
          </div>
        </div>
      );
    }
    return null;
  }

  renderSelectedFileClauses(
    clauseGroups,
    onHeadingClick,
    onClauseClick,
    clausesCount,
  ) {
    return clauseGroups
      .filter(groupItem => {
        const currentFileIndex = this.getFileset(groupItem);
        return this.state.selectedFileIndex === currentFileIndex - 1;
      })
      .map((groupItem, clauseItemIndex) => {
        const el = (
          <>
            {this.renderGroupItem(
              this.getFileset(groupItem),
              clauseItemIndex,
              groupItem,
              onHeadingClick,
              onClauseClick,
              clausesCount,
            )}
          </>
        );
        return el;
      });
  }

  renderViewAllClauses(
    clauseGroups,
    fileIndex,
    fileNames,
    onHeadingClick,
    onClauseClick,
    clausesCount,
  ) {
    let lastFileset = null;
    const fileGroups = _.chain(clauseGroups)
      .groupBy(groupItem => this.getFileset(groupItem))
      .pairs()
      .value();
    const matchingIndex = fileGroups.findIndex(
      item => item[0] === fileIndex.toString(),
    );
    if (matchingIndex === -1) {
      fileGroups.unshift([fileIndex, []]);
    } else {
      const item = fileGroups.splice(matchingIndex, 1);
      fileGroups.unshift(...item);
    }
    return fileGroups.map(([currentFileIndexStr, groupItems], index) => {
      const currentFileIndex = parseInt(currentFileIndexStr, 10);
      const isOpen = this.state.fileOpen[currentFileIndex] ?? true;
      const el = (
        <div key={currentFileIndex}>
          {this.renderGroupFileName(
            currentFileIndex,
            fileNames,
            lastFileset,
            currentFileIndex,
            index === fileGroups.length - 1,
          )}
          {isOpen && (
            <div style={{marginLeft: "0em"}}>
              {groupItems.length === 0 ? (
                <span style={{fontStyle: "italic"}}>No clauses found</span>
              ) : (
                groupItems.map((groupItem, index) =>
                  this.renderGroupItem(
                    currentFileIndex,
                    `${currentFileIndex}_${index}`,
                    groupItem,
                    onHeadingClick,
                    onClauseClick,
                    clausesCount,
                  ),
                )
              )}
            </div>
          )}
        </div>
      );
      lastFileset = currentFileIndex;
      return el;
    });
  }

  renderGroupFileName(
    fileIndex,
    fileNames,
    lastFileset,
    currentFileIndex,
    isLast,
  ) {
    if (fileNames.length > 1 && lastFileset !== currentFileIndex) {
      const isOpen = this.state.fileOpen?.[fileIndex] ?? true;
      return (
        <div
          style={{
            fontWeight: 400,
            marginTop: "0.5em",
            paddingTop: "1em",
            paddingBottom: "1em",
            fontSize: "1.15em",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            cursor: "pointer",
            ...(isLast && !isOpen ? {} : {borderBottom: "1px solid #ddd"}),
            ...(isOpen ? {marginBottom: "1.5em"} : {}),
          }}
          onClick={() =>
            this.setState({
              fileOpen: {
                ...this.state.fileOpen,
                [fileIndex]: !(this.state.fileOpen?.[fileIndex] ?? true),
              },
            })
          }
        >
          {isOpen ? <OpenGroupIcon /> : <CloseGroupIcon />}
          <div style={{marginLeft: "0.5em"}}>
            {fileNames[currentFileIndex - 1]}
          </div>
        </div>
      );
    }
    return null;
  }

  renderGroupItem(
    fileIndex,
    clauseItemIndex,
    groupItem,
    onHeadingClick,
    onClauseClick,
    clausesCount,
  ) {
    const {isFlipChecklistIcons, zoom} = this.context;

    return (
      <div
        key={clauseItemIndex}
        style={{
          ...(isFlipChecklistIcons
            ? styles.groupContainer.flipped
            : styles.groupContainer.unflipped),
        }}
      >
        {groupItem.hasHeading && (
          <div
            style={{
              ...(isFlipChecklistIcons
                ? styles.headingText.flipped
                : styles.headingText.unflipped),
              fontSize: getZoomedFontSize(14, "checklist", zoom),
              cursor: onHeadingClick ? "pointer" : "default",
            }}
            onClick={
              onHeadingClick ? () => onHeadingClick(groupItem) : undefined
            }
          >
            {groupItem.headingText}
          </div>
        )}
        {this.renderGroupItemClauses(groupItem, onClauseClick, clausesCount)}
      </div>
    );
  }

  renderGroupItemClauses(groupItem, onClauseClick, clausesCount) {
    if (groupItem.item) {
      return this.renderClauses(groupItem.item, 0, null, onClauseClick);
    }
    return (
      groupItem.items &&
      groupItem.items.map((item, itemIndex) =>
        this.renderClauses(
          item,
          itemIndex,
          itemIndex &&
            groupItem.items[itemIndex - 1].item &&
            groupItem.items[itemIndex - 1].item.reference,
          onClauseClick,
          clausesCount,
        ),
      )
    );
  }

  renderClauses = (
    item,
    itemIndex,
    prevReference,
    onClauseClick,
    clausesCount,
  ) => {
    const result = [];
    const document = this.props.document || {};
    const {renderStatus, user} = this.props;
    if (item.isDefinition) {
      let lastReference = prevReference;
      result.push(
        <div key={itemIndex}>
          <div
            style={{
              ...styles.definition,
              fontSize: getZoomedFontSize(14, "checklist", this.context.zoom),
            }}
          >
            {item.definitionReference}
          </div>
          <div>
            {item.items.map((clause, clauseIndex) => {
              const shouldHideDefinitionReference =
                clause.is_section_unnumbered ||
                clause.reference === lastReference;
              lastReference = clause.reference;
              return (
                <div key={clauseIndex}>
                  {renderClause(
                    clause,
                    clauseIndex,
                    onClauseClick,
                    this.props.documentChanges || [],
                    shouldHideDefinitionReference,
                    document.file_extension || "",
                    this.context.zoom,
                    get(document, "template_values.values"),
                    clausesCount,
                    this.isUnrendered(clause, renderStatus),
                    user,
                  )}
                </div>
              );
            })}
          </div>
        </div>,
      );
    } else {
      const shouldHideReference =
        (item && item.item && item.item.is_section_unnumbered) ||
        (item && item.item && item.item.reference === prevReference);
      const clause = item.item;
      result.push(
        <div key={itemIndex}>
          <RenderedClause
            isParent={item.isParent}
            children={item.children}
            clause={clause}
            itemIndex={itemIndex}
            onClauseClick={onClauseClick}
            documentChanges={this.props.documentChanges || []}
            shouldHideReference={shouldHideReference}
            fileExtension={document.file_extension || ""}
            zoom={this.context.zoom}
            templateValues={get(document, "template_values.values")}
            clausesCount={clausesCount}
            isUnrendered={this.isUnrendered(clause, renderStatus)}
            user={user}
          />
        </div>,
      );
    }
    return result;
  };

  isUnrendered(clause, renderStatus) {
    return (
      renderStatus &&
      (renderStatus.sectionOrder < clause.section_order ||
        (renderStatus.sectionOrder === clause.section_order &&
          renderStatus.clauseOrder < clause.clause_order_in_section))
    );
  }

  getClausesToRender = (
    clauses,
    selectedTopicName,
    changes,
    selectedReport,
    issueManualCorrections,
    correctApplicableClause,
    documentClauseparts,
  ) => {
    const clausesOfSelectedTopic =
      selectedTopicName !== "All"
        ? clauses[selectedTopicName] || []
        : Object.values(clauses);

    const deletions = this.getRelevantDeletions(
      _.flatten(clausesOfSelectedTopic),
      changes,
    );
    const manualCorrections =
      (issueManualCorrections || {})[selectedReport] || {};

    const additions = (manualCorrections?.additional_report_clauses ?? [])
      .map(item =>
        documentClauseparts.find(clausepart => clausepart.id === item),
      )
      .map(item => ({isAddition: true, ...item}));
    let hasRemovedClauses = false;

    const clausesToRender = _.chain(
      clausesOfSelectedTopic.concat(deletions).concat(additions),
    )
      .flatten()
      .uniq(clause => clause.id)
      .sortBy(
        clause =>
          `${clause.full_reference}.${pad(clause.clause_order, 4, "0")}`,
      )
      .map(clause => {
        if (correctApplicableClause) {
          const {id: clausepartId} = clause;
          const {removedClauses = {}} = manualCorrections;
          const isRemoved = removedClauses
            ? removedClauses[clausepartId]
            : undefined;
          if (isRemoved && !hasRemovedClauses) {
            hasRemovedClauses = true;
          }
          const checked = !isRemoved;
          return {
            ...clause,
            checked,
            isRemoved,
            onCheck: function onItemCheck() {
              if (isRemoved) {
                delete removedClauses[clausepartId];
              } else {
                removedClauses[clausepartId] = true;
              }
              const newManualCorrections = getUpdatedManualCorrections(
                issueManualCorrections,
                selectedReport,
                null,
                null,
                {removedClauses},
              );
              if (newManualCorrections) {
                correctApplicableClause(newManualCorrections);
              }
            },
          };
        }
        return clause;
      })
      .filter(clause => !clause.isRemoved)
      .value();
    return {clausesToRender, hasRemovedClauses};
  };

  getRelevantDeletions(relevantClauses, changes /* documentClauses */) {
    const deletions = (changes || []).filter(
      change =>
        change.type === "clausepart_deletion" &&
        change.new_document_id !== change.old_document_id &&
        relevantClauses.find(
          clause =>
            clause.clause_id === change.new_clause_id &&
            change.old_reference &&
            change.old_reference.startsWith(clause.reference),
        ),
    );
    const deletionClauses = deletions.map(deletion => {
      const clause = relevantClauses.find(
        clause => clause.clause_id === deletion.new_clause_id,
      );
      // const clause = documentClauses[deletion.new_section_id].find(clause => clause.id === change.new_clause_id);
      return {
        reference: deletion.old_reference,
        text: deletion.old_text,
        full_text: deletion.old_text,
        topics: [],
        id: deletion.old_clause_part_id,
        level: 1,
        clause_full_reference: clause.full_reference,
        clause_reference: deletion.old_reference,
        clause_id: "", // clause.id,
        clause_section_id: deletion.new_section_id,
        clause_order: 9999,
        change_type: "clausepart_deletion",
      };
    });
    return deletionClauses;
  }
}

function getClausesTopicNames(clausesByTopics) {
  const topicNames = [];
  const parameterNames = [];

  Object.keys(clausesByTopics).forEach(topicOrParameterName => {
    const clauses = clausesByTopics[topicOrParameterName];
    if (clauses.isParameter) {
      parameterNames.push(topicOrParameterName);
    } else {
      topicNames.push(topicOrParameterName);
    }
  });

  return [..._.sortBy(topicNames), ..._.sortBy(parameterNames)].filter(
    topic => topic !== "no_topic",
  );
}

function RenderedClause(props) {
  const [allShown, updateAllShown] = React.useState(false);
  function onShowAll() {
    updateAllShown(true);
  }

  const baseClause = renderClause(
    props.clause,
    props.itemIndex,
    props.onClauseClick,
    props.documentChanges,
    props.shouldHideReference,
    props.fileExtension,
    props.zoom,
    props.templateValues,
    props.clausesCount,
    props.isUnrendered,
    props.user,
  );
  if (props.isParent) {
    const shownChildren =
      allShown || props.children.length === 3
        ? props.children
        : props.children.slice(0, 2);
    const remainingLength = props.children.length - shownChildren.length;
    return (
      <React.Fragment>
        {baseClause}
        {shownChildren.map((child, childIndex) => {
          const prevReference =
            shownChildren[childIndex - 1] &&
            shownChildren[childIndex - 1].item &&
            shownChildren[childIndex - 1].item.reference;
          const shouldHideReference =
            (child.item && child.item.is_section_unnumbered) ||
            (child.item && child.item.reference === prevReference);
          return renderClause(
            child.item,
            childIndex,
            props.onClauseClick,
            props.documentChanges,
            shouldHideReference,
            props.fileExtension,
            props.zoom,
            props.templateValues,
            props.clausesCount,
            props.isUnrendered,
            props.user,
          );
        })}
        {remainingLength && !allShown ? (
          <div style={styles.moreClausesButton} onClick={onShowAll}>
            {`${remainingLength} more clauses`}
          </div>
        ) : null}
      </React.Fragment>
    );
  }
  return baseClause;
}

const selectorStyles = {
  root: {
    "& .MuiInputBase-root": {
      lineHeight: "unset",
    },
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
    },
  },
};

const useSelectorStyles = makeStyles(selectorStyles);
function TopicSelector(props) {
  const {clauses, zoom, selectedTopicName, onTopicSelectorChange} = props;
  let topicNames = getClausesTopicNames(clauses);
  topicNames = topicNames.length > 1 ? ["All", ...topicNames] : topicNames;
  const valueFontSize = getZoomedFontSize(16, "checklist", zoom);
  const classes = useSelectorStyles();
  const isAllSelected = selectedTopicName === "All";
  return (
    <FormControl variant="outlined" style={{flexGrow: 1}} classes={classes}>
      <Select
        value={selectedTopicName}
        onChange={onTopicSelectorChange}
        renderValue={value => (
          <span
            style={{
              display: "flex",
              justifyContent: isAllSelected ? "space-between" : "flex-end",
            }}
          >
            {isAllSelected && (
              <span
                style={{
                  ...styles.selectValueLabel,
                  fontSize: valueFontSize,
                }}
              >
                Show Topics
              </span>
            )}
            <span
              style={{
                ...styles.selectValueText,
                fontSize: valueFontSize,
              }}
            >
              {value}
            </span>
          </span>
        )}
      >
        {topicNames.map((topicName, topicNameIndex) => (
          <MenuItem
            key={topicNameIndex}
            value={topicName}
            style={{
              ...styles.selectItem,
              fontSize: valueFontSize,
            }}
          >
            {topicName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function FileSelector({
  usedFilenames,
  disabled,
  selectedFileIndex,
  selectFileIndex,
}) {
  const classes = useSelectorStyles();
  return (
    <FormControl variant="outlined" style={{width: "100%"}} classes={classes}>
      <Select
        style={{width: "100%", paddingRight: "1em", fontSize: "0.75em"}}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        disabled={disabled}
        value={selectedFileIndex}
        onChange={event => selectFileIndex(event.target.value)}
        renderValue={index => (
          <Tooltip title={usedFilenames[index]?.filename ?? ""}>
            <div
              className="primary"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "left",
                direction: "rtl",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              {usedFilenames[index]?.filename}
            </div>
          </Tooltip>
        )}
      >
        {usedFilenames
          .filter(({use}) => use)
          .map(({filename, index}) => (
            <MenuItem
              key={index}
              value={index}
              style={{
                whiteSpace: "normal",
                fontSize: "0.75em",
              }}
            >
              {filename}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default ClausesRenderer;

function formatNumber(numStr, _delimeter) {
  const delimeter =
    !_delimeter || typeof _delimeter !== "string" ? "," : _delimeter;
  if (!numStr || (typeof numStr !== "string" && typeof numStr !== "number")) {
    return numStr;
  }
  return numStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, delimeter);
}

export default formatNumber;

function reasonViewer(reason) {
  return reason.topic_id
    ? [
        {
          path: `topic_id.[${reason.topic_id}]`,
          value: "N/A",
        },
      ]
    : [];
}

function getDefaultIssueRuleValues(rules) {
  return [{path: `topic_id.[${rules.topic_id}]`, value: ""}];
}

export default {
  reasonViewer,
  getDefaultIssueRuleValues,
};

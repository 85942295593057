import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateDocumentIssue(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_ISSUE_UPDATE,
    (
      organisationId,
      projectId,
      documentId,
      documentIssueId,
      lastEdited,
      updates,
      options,
    ) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            `/document_issue/${documentIssueId}`,
          {
            updates,
            last_edited: lastEdited,
            options: typeof options === "object" ? options : {},
          },
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}

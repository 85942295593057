function getIssueTypeName(Issues, type) {
  if (!Issues || !type) {
    return "N/A";
  }
  return (
    Issues[type.toLowerCase()].displayName ||
    [
      type.charAt(0),
      type
        .substring(1)
        .toLowerCase()
        .replace(/_/g, " "),
    ].join("")
  );
}

export default getIssueTypeName;

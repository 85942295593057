import getClausepartByReference from "./get_clausepart_by_reference";

function getLastPart(reference) {
  const lastIndex = reference.lastIndexOf(".");
  if (lastIndex) {
    return reference.slice(lastIndex + 1);
  }
  return reference;
}

function getClausepartChildrenText({
  partial_text: partialText,
  reference,
  clauseNodes,
}) {
  if (partialText) {
    return `\t${reference ? `${getLastPart(reference)}) ` : ""}${partialText}`;
  }
  if (clauseNodes && clauseNodes.length) {
    return clauseNodes.map(getClausepartChildrenText).join("\n");
  }
  return "";
}

export default function getClausepartTextByReference(clauses, reference) {
  const clausepart = getClausepartByReference(clauses, reference);
  if (clausepart) {
    const text = clausepart.partial_text || clausepart.text;
    if (clausepart.childrenClausesNode) {
      const childrenText = getClausepartChildrenText(
        clausepart.childrenClausesNode,
      );
      return `${text}\n${childrenText}`;
    }
    return text;
  }
  return "";
}

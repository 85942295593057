import React from "react";

import FrontPageMenuItem from "./front_page_menu_item";

const styles = {
  body: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  table: {
    width: "100%",
    tableLayout: "fixed",
  },
};

export default class FrontPageDetail extends React.Component {
  render() {
    const {menuItems} = this.props;
    const menuLength = menuItems.length;
    return (
      <div className="body" style={styles.body}>
        <table style={styles.table}>
          {menuItems.map((item, index) => (
            <FrontPageMenuItem
              key={index}
              item={item}
              itemFeedBack={this.props.metaDataFeedback[item.name] || {}}
              verifyIssues={this.props.verifyIssues}
              updateDocumentMetaDataFeedback={
                this.props.updateDocumentMetaDataFeedback
              }
              shouldShowBottomBorder={index + 1 !== menuLength}
            />
          ))}
        </table>
      </div>
    );
  }
}

import React, {useEffect, useState} from "react";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "../styles";

const EditButton = ({
  setEditModeOn,
  editModeOn,
  document
}) => {
  const [isDisableText, setDisableText] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const showTooltip = () => setOpenTooltip(true);
  const hideTooltip = () => setOpenTooltip(false);

  useEffect(() => {
    if (
      document.file_extension === "pdf"
    ) {
      setDisableText("Can't edit PDFs");
    } else if (
      document.plain_text_length > 100000
    ) {
      setDisableText("Can't edit document - Document too long for inline editing. Use the Word Addin");
    } else {
      setDisableText(false);
    }
  }, []);

  return (
    <Tooltip
      title={isDisableText ? isDisableText : editModeOn ? "Disable editing mode" : "Enable editing mode"}
      placement={"top"}
      open={openTooltip}
      arrow
    >
      <span
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        <IconButton
          style={isDisableText ? styles.disableIconButton : styles.iconButton}
          onClick={() => setEditModeOn(!editModeOn)}
          disabled={Boolean(isDisableText)}
        >
          <EditIcon {...editModeOn && {color: "primary"}} />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default EditButton;

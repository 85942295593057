import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const switchStyles = {
  root: {
    padding: 3,
    "& .MuiSwitch-track": {
      borderRadius: 9,
    },
    "& .MuiSwitch-switchBase": {
      "&.Mui-checked + .MuiSwitch-track": {
        opacity: 0.8,
      },
      padding: 7,
      "& .MuiSwitch-thumb": {
        height: 10,
        width: 10,
        color: "white",
      },
    },
  },
};

const useSwitchStyles = makeStyles(switchStyles);

function SwitchStyled(props) {
  const {checked, onChange, disabled} = props;
  const switchClasses = useSwitchStyles();
  return (
    <Switch
      classes={switchClasses}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
      color="primary"
      inputProps={{"aria-label": "primary checkbox"}}
      size="small"
    />
  );
}

export default SwitchStyled;

import {Document} from "../types/document";

type RemovePrefix<T extends Record<string, unknown>, Prefix extends string> = {
  [K in keyof T as K extends `${Prefix}${infer Rest}`
    ? Rest
    : K]: T[K] extends unknown[] ? T[K][number] : T[K];
};

export function stripPrefixObjectKeys<
  T extends {id: unknown} & Record<K, unknown>,
  Prefix extends string,
  K extends string
>(obj: T, prefix: Prefix, index: number): RemovePrefix<T, Prefix> {
  // Create an initial object without the prefixed keys
  const initialObj = Object.keys(obj).reduce<Record<string, unknown>>(
    (acc, key) => {
      if (!key.startsWith(prefix)) {
        acc[key] = obj[key];
      }
      return acc;
    },
    {},
  );

  // Add or overwrite keys after removing the prefix and setting the indexed element
  const result = Object.keys(obj).reduce<Record<string, unknown>>(
    (newObj, key) => {
      if (key.startsWith(prefix)) {
        // Remove the prefix and set the indexed element in the new object
        const newKey = key.substring(prefix.length);
        if (
          !Array.isArray(obj[key]) ||
          (obj[key] as unknown[]).length <= index
        ) {
          throw new Error(
            `The value for ${key} in ${obj?.id} is not an array or does not have the specified index.`,
          );
        }
        newObj[newKey] = (obj[key] as unknown[])[index];
      }
      return newObj;
    },
    initialObj,
  ) as RemovePrefix<T, Prefix>;

  return result;
}

export default function generateSingleDocument(doc: Document, index: number) {
  return stripPrefixObjectKeys<Document, "concat_", keyof Document>(
    doc,
    "concat_",
    index,
  );
}

/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react";
/* eslint-enable no-unused-vars */
import _ from "underscore";
import Paper from "material-ui/Paper";

import DocumentSection from "./document_section";
import PartiesSection from "./parties_section";
import MissingIssues from "./missing_issues";
import DocumentPreview from "common_components/document_review/document_preview";

const styles = {
  mainPaper: {
    borderRadius: "0px",
  },
  documentPreview: {
    padding: "0 2rem",
    fontFamily: "Roboto, sans-serif",
    fontSize: "16px",
    fontWeight: "200",
    lineHeight: "1.25em",
    color: "rgba(0, 0, 0, 0.87)",
  },
  wrapper: {
    padding: "1rem 0rem",
    display: "flex",
    flexDirection: "column",
  },
  stickyBlock: {
    top: 0,
    position: "sticky",
    backgroundColor: "#fff",
    zIndex: 5,
    transition: "all ease-in-out 0.8s",
  },
  hide: {
    top: -550,
  },
};

const RenderSections = props => {
  const {
    documentClauses: clauseGroups,
    documentSections: _sections,
    document,
    scrollTopValue,
  } = props;
  const [holdBlock, setHoldBlock] = useState(false);
  const [showBlock, setShowBlock] = useState(true);
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    if (scrollTopValue > scrollValue) {
      setScrollValue(scrollTopValue);
      setHoldBlock(false);
    }

    if (scrollTopValue > 550 && !holdBlock) {
      setShowBlock(false);
    } else {
      setShowBlock(true);
    }

    if (scrollValue > scrollTopValue) {
      setShowBlock(true);
      setHoldBlock(true);
      setScrollValue(scrollTopValue);
    }
  }, [scrollTopValue]);
  const sections = _sections.filter(
    section => !section.is_hidden && section.file_index === props.fileIndex,
  );
  if (sections[0] && !sections[0].order) {
    sections.sort((a, b) => {
      if (a.reference === "background") {
        return -1;
      }
      if (b.reference === "background") {
        return 1;
      }
      if (a.reference === "main") {
        return -1;
      }
      if (b.reference === "main") {
        return 1;
      }
      if (a.reference < b.reference) {
        return -1;
      }
      if (a.reference > b.reference) {
        return 1;
      }
      return 0;
    });
  }

  const visibleSections = sections.reduce(
    (data, section) => {
      const {reference} = section;
      if (reference.endsWith("_background")) {
        const sectionGroupName = reference.substring(
          0,
          reference.indexOf("_background"),
        );
        data.lastGroup = sectionGroupName;
        data.list.push({...section, showSectionHeading: true});
      } else if (
        reference === data.lastGroup ||
        reference.endsWith("_execution") ||
        reference.startsWith("main_")
      ) {
        data.list.push({...section, showSectionHeading: false});
      } else {
        data.lastGroup = reference;
        data.list.push({...section, showSectionHeading: true});
      }
      return data;
    },
    {list: [], lastGroup: ""},
  ).list;

  return (
    <Paper className="document-sections" style={styles.mainPaper}>
      <div
        style={
          showBlock
            ? styles.stickyBlock
            : {...styles.stickyBlock, ...styles.hide}
        }
      >
        <PartiesSection
          organisationId={props.organisationId}
          document={props.document}
          project={props.project}
          user={props.user}
          updateDocument={props.updateDocument}
          parties={props.parties}
          roles={props.roles}
          reprocessRoles={props.reprocessRoles}
          resetRoles={props.resetRoles}
          forcePartyConfirmation={props.project.force_party_confirmation}
        />
      </div>
      <MissingIssues {...props} />
      <div style={styles.wrapper}>
        {props.viewDocx || (
          <>
            {_.map(visibleSections, section => (
              <DocumentSection
                {...props}
                isMain={section.reference === "main"}
                hasSectionFilledHeadings={Boolean(
                  props.documentHeadings.find(heading => {
                    return heading.section_id === section.id && heading.text;
                  }),
                )}
                key={section.id}
                section={section}
                clauses={clauseGroups[section.id]}
                showReference={
                  !section.reference.match(
                    /(parties)|(background)|((.)+_execution)/,
                  )
                }
              />
            ))}
          </>
        )}
        {props.viewDocx && (
          <div style={styles.documentPreview}>
            <DocumentPreview document={document} />
          </div>
        )}
      </div>
    </Paper>
  );
};

export default RenderSections;

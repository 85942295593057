function convertUnrecognisedClausesToIssues(
  clauses,
  docHeadingsByClauseReference,
) {
  const baseIssues = clauses
    .filter(clause =>
      shouldShow(
        clause.reference,
        docHeadingsByClauseReference[clause.clause_reference],
      ),
    )
    .map(clause => ({
      checklistType: "unrecognised_clause",
      id: clause.id,
      name: `Clause ${clause.reference}`,
      current_state: null,
      groupName: "Unrecognised Clauses",
      reason: [],
      referenced_topics: [],
      reference: clause.reference,
      applicable_clauses: [
        {
          id: clause.id,
          text: clause.text,
          topics: [
            {id: "xxx", topic_id: "xxx", name: "Unrecognised clauses topic"},
          ],
        },
      ],
    }));
  const result = [];
  baseIssues.forEach(baseIssue => {
    const {reference: baseIssueReference} = baseIssue;
    const resultIssue = result.find(
      issue => issue.reference === baseIssueReference,
    );
    if (resultIssue) {
      resultIssue.applicable_clauses.push(baseIssue.applicable_clauses[0]);
      return;
    }
    result.push(baseIssue);
  });
  return result;
}

function shouldShow(reference, heading) {
  if (reference.indexOf(".meaning") !== -1) {
    return false;
  }
  if (heading && heading.text.toLowerCase().indexOf("definition") !== -1) {
    return false;
  }
  const refArray = reference.split(".");
  const firstSymbol = refArray[0];
  switch (firstSymbol) {
    case "b":
    case "e":
      return false;
    default:
      return true;
  }
}

export default convertUnrecognisedClausesToIssues;

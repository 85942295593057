import React from "react";

const styles = {
  dotContainer: {
    display: "flex",
    alignItems: "center",
    width: "16px",
  },
  dot: {
    display: "block",
    width: 14,
    height: 14,
    borderRadius: "50%",
    margin: "0.3rem auto",
    cursor: "pointer",
  },
};

const UnreadIssueDot = ({showRead, issueStatus, onClick}) => (
  <div style={styles.dotContainer} onClick={onClick ? onClick : null}>
    {issueStatus === 1 || issueStatus === null ? (
      <div style={{...styles.dot, backgroundColor: "#a2c3e0"}} />
    ) : showRead && issueStatus === 2 ? (
      <div
        style={{
          ...styles.dot,
          backgroundColor: "#f3f3f3",
          border: "1px dashed #ddd",
        }}
      />
    ) : issueStatus === 3 ? (
      <div style={{...styles.dot, backgroundColor: "#fc6166"}} />
    ) : null}
  </div>
);

export default React.memo(UnreadIssueDot);

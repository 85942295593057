import React from "react";

import IconButton from "@material-ui/core/IconButton";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";

function getRoundMultiplier(value) {
  const numberArray = value.toString().split(".");
  if (numberArray[1]) {
    return Math.pow(10, numberArray[1].length);
  }
  return 1;
}

function ZoomControl(props) {
  const {value, step = 0.1, minValue = 0.4, maxValue = 1.8} = props;
  const roundMultiplier = getRoundMultiplier(step);

  function onDecrementValue() {
    const newValue = value * roundMultiplier - step * roundMultiplier;
    if (newValue >= minValue * roundMultiplier) {
      props.onChange(newValue / roundMultiplier);
    }
  }

  function onIncrementValue() {
    const newValue = value * roundMultiplier + step * roundMultiplier;
    if (newValue <= maxValue * roundMultiplier) {
      props.onChange(newValue / roundMultiplier);
    }
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "10px 14px",
        ...(props.containerStyle ? props.containerStyle : {}),
      }}
    >
      <div>{props.label}</div>
      <div style={{display: "flex", alignItems: "center", marginLeft: "8px"}}>
        <IconButton
          onClick={onDecrementValue}
          size="small"
          style={{marginRight: "4px"}}
        >
          <MinusIcon />
        </IconButton>
        <div style={{borderRight: "1px solid lightgray", height: "16px"}} />
        <IconButton
          onClick={onIncrementValue}
          size="small"
          style={{marginLeft: "2px"}}
        >
          <PlusIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ZoomControl;

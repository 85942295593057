/* eslint-disable @typescript-eslint/no-unused-vars */

function getKey(item, joinKey) {
  let key;
  if (joinKey === "clause_id") {
    key = joinKey;
  } else if (joinKey === "clausepart_reference") {
    key = "reference";
  } else {
    key = "id";
  }
  return item[key];
}

export default function areConceptsInDocumentRules(data) {
  return {
    Exp(e) {
      return e.eval();
    },
    Rule(e) {
      return e.eval();
    },
    Rule_and(e) {
      return e.eval();
    },
    Rule_or(e) {
      return e.eval();
    },
    AndExp(left, list, right) {
      return list.eval().every(item => item);
    },
    NonemptyListOf(first, connector, rest) {
      return [first.eval()].concat(rest.eval());
    },
    _iter(...list) {
      return list.map(item => item.eval());
    },
    OrExp(left, list, right) {
      return list.eval().find(item => item);
    },
    ConceptDescriptor_topic(topicId) {
      return Boolean(data[topicId.eval()]);
    },
    ConceptDescriptor_negated_topic(not, topicId) {
      return !data[topicId.eval()];
    },
    ConceptDescriptor_topic_and_param(topicId, paramId) {
      return Boolean(data?.[topicId.eval()]?.[paramId.eval()]);
    },
    ConceptDescriptor_negated_topic_and_param(not, topicId, paramId) {
      return !data[topicId.eval()][paramId.eval()];
    },
    ConceptDescriptor_topic_and_param_value(topicId, paramId, _value) {
      const unQuotedValue = _value.eval();
      const value = unQuotedValue.match(/^'(.*)'$/)
        ? unQuotedValue.replace(/^'(.*)'$/, "$1")
        : unQuotedValue;
      return Boolean(
        data[topicId.eval()][paramId.eval()].find(item => item === value),
      );
    },
    ConceptDescriptor_negated_topic_and_param_value(
      not,
      topicId,
      paramId,
      _value,
    ) {
      const value = _value.eval();
      return !data[topicId.eval()][paramId.eval()].find(item => item === value);
    },
    TopicId(value) {
      return value.eval();
    },
    ParameterId(value) {
      return value.eval();
    },
    Value(value) {
      return this.sourceString;
    },
    number(value) {
      return this.sourceString;
    },
  };
}

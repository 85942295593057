/* eslint-disable no-invalid-this */

function normalise(value) {
  if (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return "";
  }
  return value;
}

function compare(v1, v2) {
  return normalise(v1) === normalise(v2);
}

export default compare;

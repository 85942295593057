import areConceptsInDocument from "./are_concepts_in_document";

/* eslint-disable @typescript-eslint/no-unused-vars */
export default function ifConceptsInDocument(
  _data,
  _rules,
  _positive,
  _negative,
  _context,
) {
  const hasData = typeof _data !== "string";
  const rules = hasData ? _rules : _data;
  const positive = hasData ? _positive : _rules;
  const negative = hasData ? _negative : _positive;
  const context = hasData ? context : _negative;

  const result = hasData
    ? areConceptsInDocument(_data, rules, context)
    : areConceptsInDocument(rules, context);
  return result ? positive : negative;
}

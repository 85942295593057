import _ from "lodash";

function getOverriddenIssueField(
  baseFieldValue,
  issueOverrideValues,
  issuesetData,
  fieldName,
) {
  if (!issueOverrideValues || !issuesetData || !fieldName) {
    return baseFieldValue;
  }
  const {master_id: masterId, remote_client_id: remoteClientId} = issuesetData;
  let fieldValue;
  if (remoteClientId && masterId) {
    fieldValue = _.get(
      issueOverrideValues,
      `${masterId}.${remoteClientId}.${fieldName}`,
    );
  } else if (masterId && !remoteClientId) {
    fieldValue = _.get(issueOverrideValues, `${masterId}.master.${fieldName}`);
  }
  return fieldValue ? fieldValue : baseFieldValue;
}

export default getOverriddenIssueField;

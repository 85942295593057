import _ from "lodash";
import setNestedObjects from "./setNestedObjects";
import deleteNestedObjectsIfEmpty from "./deleteNestedObjectsIfEmpty";

// !!!! This function changes value by reference
function updateOverrideValues(fieldName, fieldValue, dataPath, overrideValues) {
  if (fieldValue !== undefined && fieldValue !== null) {
    if (!_.has(overrideValues, dataPath)) {
      setNestedObjects(overrideValues, dataPath);
    }
    _.set(overrideValues, `${dataPath}.${fieldName}`, fieldValue);
  } else if (_.has(overrideValues, dataPath)) {
    if (fieldName.indexOf(".") > 0) {
      delete _.get(
        overrideValues,
        `${dataPath}.${fieldName.substring(0, fieldName.lastIndexOf("."))}`,
      )[fieldName.substring(fieldName.lastIndexOf(".") + 1)];
    } else {
      delete _.get(overrideValues, dataPath)[fieldName];
    }
    deleteNestedObjectsIfEmpty(overrideValues, dataPath);
  }
}

export default updateOverrideValues;

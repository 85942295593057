import Describer from "../../../utils/issues/reason/describer";
import getRoleName from "./get_role_name";

function reason(clauses, topicsById, issue, showClause = true, props = {}) {
  const describer = new Describer(clauses, topicsById, issue);
  const {reasonValue} = describer;
  return (
    [
      `Topic '${describer.topicName}'`,
      reasonValue.role_id
        ? `has role value ${getRoleName(reasonValue.role_id, props.roles)}`
        : `has value '${reasonValue} for '${
            describer.parameterName
          }' parameter'`,
    ].join(" ") + (showClause ? ` in clause ${describer.clauseReference}` : "")
  );
}

export default reason;

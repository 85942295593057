export default {
  TOPIC_NOT_PRESENT: "TOPIC_NOT_PRESENT",
  TOPIC_NOT_PRESENT_WITH_PARAMETER: "TOPIC_NOT_PRESENT_WITH_PARAMETER",
  TOPIC_PRESENT: "TOPIC_PRESENT",
  TOPIC_WITH_PARAMETER_VALUE_IN_LIST: "TOPIC_WITH_PARAMETER_VALUE_IN_LIST",
  TOPIC_WITH_PARAMETER_VALUE_NOT_IN_LIST:
    "TOPIC_WITH_PARAMETER_VALUE_NOT_IN_LIST",
  TOPIC_PRESENT_AND_DURATION_IN_RANGE: "TOPIC_PRESENT_AND_DURATION_IN_RANGE",
  TOPIC_PRESENT_WITH_MONETARY_VALUE: "TOPIC_PRESENT_WITH_MONETARY_VALUE",
  TOPIC_PRESENT_AND_NUMBER_IN_RANGE: "TOPIC_PRESENT_AND_NUMBER_IN_RANGE",
  AND_ON_CLAUSE: "AND_ON_CLAUSE",
  AND_ON_DOCUMENT: "AND_ON_DOCUMENT",
  AND_ON_WHOLE_CLAUSE: "AND_ON_WHOLE_CLAUSE",
  OR: "OR",
  NOT: "NOT",
  COMPARE_PARAMETER_VALUES: "COMPARE_PARAMETER_VALUES",
  TOPIC_PRESENT_WITH_DATE_IN_RANGE: "TOPIC_PRESENT_WITH_DATE_IN_RANGE",
  TOPIC_WITHOUT_PARAMETER_PRESENT: "TOPIC_WITHOUT_PARAMETER_PRESENT",
  TOPIC_WITH_ANY_PARAMETER_PRESENT: "TOPIC_WITH_ANY_PARAMETER_PRESENT",
  EMPTY_PARENT: "EMPTY_PARENT",
  TOPIC_WITH_PARAMETER_WITH_ANY_VALUE: "TOPIC_WITH_PARAMETER_WITH_ANY_VALUE",
  TOPIC_WITH_PARAMETERS_WITH_ANY_VALUE: "TOPIC_WITH_PARAMETERS_WITH_ANY_VALUE",
  TOPIC_EXISTS_AND_ROLE_MATCHES: "TOPIC_EXISTS_AND_ROLE_MATCHES",
};

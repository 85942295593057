import _ from "underscore";
import React from "react";

import PendingSave from "./clause/open/pending_save";
import increaseReference from "common/utils/increase_clause_reference";
import SingleClause from "./single_clause";
import ClauseHeading from "./clause_heading";
import AddClauseForm from "./add_clause_form";
import bottomOffset from "./bottom_offset";
import calculateDocumentTopics from "utils/topic/calculate_document_topics";
import ScrollUpdater from "../../utils/scroll_updater";
import getManualCorrectionsIcons from "../../utils/issue/get_manual_correction_icons";

const listStyleTypeImportanceArray = [
  "LOWER_ROMAN",
  "LOWER_LETTER",
  "DECIMAL",
  "UPPER_LETTER",
  "UPPER_ROMAN",
];

const styles = {
  headingContainer: {
    margin: "0 2rem",
  },
};

export default class Clauses extends React.Component {
  constructor(props) {
    super(props);
    if (props.reference) {
      this.scrollUpdater = new ScrollUpdater(
        this,
        props => props.reference,
        props => {
          const {reference, selectedId} = props;
          return (
            reference === selectedId ||
            (props.hiddenHeadings[reference] &&
              selectedId.indexOf(".") > 0 &&
              selectedId.substring(0, selectedId.indexOf(".")) === reference)
          );
        },
        bottomOffset,
      );
    }
  }

  componentDidMount() {
    if (this.props.reference) {
      this.scrollUpdater.componentDidMount(this.props);
    }
  }

  shouldComponentUpdate(nextProps) {
    const {props} = this;

    /* eslint-disable no-underscore-dangle */
    return (
      (props.reference &&
        this.scrollUpdater.shouldComponentUpdate(props, nextProps)) ||
      (nextProps.clauseToRender !== props.clauseToRender &&
        nextProps.clauseToRender >= props.clauses[0].row_number &&
        nextProps.clauseToRender <=
          props.clauses[props.clauses.length - 1].row_number) ||
      (nextProps.clauseToRender >
        props.clauses[props.clauses.length - 1].row_number &&
        !this.hasRendered) ||
      nextProps.addingDefinition !== props.addingDefinition ||
      nextProps.hoveredTopicIds !== props.hoveredTopicIds ||
      nextProps.hiddenHeadings !== props.hiddenHeadings ||
      nextProps.announceVisibility !== props.announceVisibility ||
      nextProps.selectedHeading !== props.selectedHeading ||
      nextProps.selectedTopics !== props.selectedTopics ||
      nextProps.selectedIssue !== props.selectedIssue ||
      nextProps.headingVisibility !== props.headingVisibility ||
      !_.isEqual(nextProps.editableClausepart, props.editableClausepart) ||
      nextProps.issueState !== props.issueState ||
      !_.isEqual(
        this.props.documentIssues.map(di => di.updateCount),
        nextProps.documentIssues.map(di => di.updateCount),
      ) ||
      nextProps.documentChanges !== props.documentChanges ||
      nextProps.selectedId !== props.selectedId ||
      nextProps.showClausesTopicsSelectorValue !==
        props.showClausesTopicsSelectorValue ||
      !_.isEqual(nextProps.newClausepartData, props.newClausepartData) ||
      nextProps.additionClause !== props.additionClause ||
      !_.isEqual(
        this.props.editableClauseHeading,
        nextProps.editableClauseHeading,
      ) ||
      nextProps.editableClauseId !== props.editableClauseId ||
      !_.isEqual(nextProps.clauseToHighlight, props.clauseToHighlight) ||
      !_.isEqual(nextProps.documentClauses, props.documentClauses) ||
      !_.isEqual(this.props.clauseToHighlight, nextProps.clauseToHighlight) ||
      nextProps.findAndReplace.find !== props.findAndReplace.find ||
      nextProps.editModeOn !== props.editModeOn ||
      nextProps.areIssuesCollapsed !== props.areIssuesCollapsed ||
      nextProps.hideTextDeletions !== props.hideTextDeletions ||
      !_.isEqual(nextProps.document.comments, props.document.comments) ||
      nextProps.showClauseButtons !== props.showClauseButtons ||
      nextProps.documentLedReview !== props.documentLedReview ||
      nextProps.searchValue !== props.searchValue ||
      nextProps.addClausesToReportDocumentIssueId !==
      props.addClausesToReportDocumentIssueId ||
      nextProps.highlightFixes !== props.highlightFixes ||
      (props.showClauseButtons &&
        getManualCorrectionsIcons(
          nextProps.issues,
          nextProps.currentIssuesetItem,
          nextProps.selectedReportId,
        ) !==
          getManualCorrectionsIcons(
            props.issues,
            props.currentIssuesetItem,
            props.selectedReportId,
          ))
    );
    /* eslint-enable no-underscore-dangle */
  }

  componentDidUpdate(prevProps) {
    if (this.props.reference) {
      this.scrollUpdater.componentDidUpdate(this.props, prevProps);
    }
    if (
      this.props.clauseToRender >=
      this.props.clauses[this.props.clauses.length - 1].row_number
    ) {
      this.hasRendered = true;
    }
  }

  render() {
    const {clauses, documentHeadings: headings, section} = this.props;
    if (!clauses) {
      return null;
    }
    let showHeadingReferenceAsIncreased = false;
    const allClauses = this.addInHeadingsWithoutClauses(
      section.id,
      clauses,
      headings,
    );
    return (
      <div className="clauses">
        {allClauses.map((clause, index) => {
          if (clause.headingOnly) {
            return (
              <div
                key={`h-${clause.heading.reference}`}
                style={styles.headingContainer}
              >
                <ClauseHeading
                  reference={clause.heading.reference}
                  heading={clause.heading}
                  {...this.props}
                  documentHasHeadings={true}
                />
              </div>
            );
          }
          if (clause.type && clause.type === "PendingSave") {
            return <PendingSave key={`clause-${clause.id}-${index}`} />;
          }
          const usedListFormatStylesPerLevel = getUsedListFormatStylesPerLevel(
            clause.reference,
            getNumberingStats(this.props.clauses),
          );
          const children = [];
          const {additionClause} = this.props;
          const isAdditionClause =
            additionClause && additionClause.clause.id === clause.id;
          if (isAdditionClause && additionClause.position === -1) {
            children.push(
              this.renderAddClauseForm(
                clause.reference,
                usedListFormatStylesPerLevel,
              ),
            );
            showHeadingReferenceAsIncreased = true;
          }
          children.push(
            this.renderClause(
              clause,
              index,
              showHeadingReferenceAsIncreased,
              usedListFormatStylesPerLevel,
            ),
          );
          if (isAdditionClause && additionClause.position === 1) {
            children.push(
              this.renderAddClauseForm(
                increaseReference(clause.reference),
                usedListFormatStylesPerLevel,
              ),
            );
            showHeadingReferenceAsIncreased = true;
          }
          return (
            <div
              className="clauses"
              key={`clause-${clause.id}-${index}`}
              style={{
                // we use relative here to prevent button tooltips in clause editor
                // going out of the flow, refer to /clause_heading/index.js
                position: "relative",
              }}
            >
              {children}
            </div>
          );
        })}
      </div>
    );
  }

  addInHeadingsWithoutClauses(sectionId, clauses, headings) {
    const allClauses = [];
    if (clauses && clauses[0] && clauses[0].reference !== "1") {
      return clauses;
    }
    let lastClauseRef = "";
    let currentRef = 1;
    // prettier-ignore
    for (let idx = 0; idx < clauses.length;) {
      const clause = clauses[idx];
      const currentRefStr = currentRef.toString();
      const clauseRef = clause.reference.match(/_dup\d+?/)
        ? clause.reference.match(/(.*)_/)[1]
        : clause.reference;
      // sometimes clause.reference has PART as a reference prefix, hence the latter check;
      const clauseRefNotExpected =
        clauseRef !== currentRefStr &&
        `part${currentRefStr}` !== clauseRef.toLowerCase();
      // Second part handles top level clauses like 12A after a 12.
      const clauseRefSameAsPrevious =
        lastClauseRef === clauseRef ||
        Boolean(clauseRef.match(new RegExp(`^${lastClauseRef}[A-Z]`)));
      if (clauseRefNotExpected && !clauseRefSameAsPrevious) {
        const heading = headings.find(
          heading =>
            heading.section_id === sectionId &&
            heading.reference === currentRefStr,
        );
        if (heading) {
          allClauses.push({headingOnly: true, heading});
        } else {
          idx += 1;
        }
        currentRef += 1;
      } else {
        if (!clauseRefSameAsPrevious) {
          currentRef += 1;
        }
        allClauses.push(clause);
        lastClauseRef = clauseRef;
        idx += 1;
      }
    }
    return allClauses;
  }

  documentHasHeadings = () => {
    const headings = (this.props.documentHeadings || [])
      .filter(heading => heading.section_id === this.props.section.id)
      .filter(
        heading =>
          !(heading && !heading.text && heading.reference.startsWith("b")),
      );
    return headings.length > 0;
  };

  renderAddClauseForm = (reference, usedListFormatStylesPerLevel) => {
    return (
      <AddClauseForm
        key="add-clause-form"
        additionClause={this.props.additionClause}
        reference={reference}
        saveNewClause={this.saveNewClause}
        documentHasHeadings={this.documentHasHeadings()}
        hideAddClauseEditor={this.props.hideAddClauseEditor}
        usedListFormatStylesPerLevel={usedListFormatStylesPerLevel}
        startingLevel={1}
        section={this.props.section}
      />
    );
  };

  renderClause = (
    clause,
    index,
    showHeadingReferenceAsIncreased,
    usedListFormatStylesPerLevel,
  ) => {
    const sectionId = this.props.section.id;
    const documentClause = this.props.documentClauses[sectionId].find(
      c => c.id === clause.id,
    );
    const props = {
      ...this.props,
      alterDocumentClausepartBinded: this.props.alterDocumentClausepart(
        clause.section_id,
        clause.id,
      ),
      documentClause,
      saveNewClausepartBinded: this.props.saveNewClausepart(
        documentClause.section_id,
        documentClause.id,
      ),
      documentHasHeadings: this.documentHasHeadings(),
    };

    const {changes} = props;
    const {reference, full_reference: fullReference, id: clauseId} = clause;
    const topics = calculateTopics(clause, props);
    const isFirst = index === 0 && props.isFirst !== false && props.isMain;
    const clauseChanges = changes.filter(
      change => change.new_clause_id === clauseId,
    );
    const clauseAdditionChange = clauseChanges.find(
      change =>
        change.new_clause_id === props.documentClause.id &&
        change.type === "clause_addition",
    );
    const isClauseDeletion = Boolean(
      clauseChanges.find(change => change.type === "clause_deletion"),
    );
    if (isClauseDeletion && this.props.hideTextDeletions) {
      return null;
    }

    const {read_plain: readPlain} = this.props.project;

    return (
      <SingleClause
        {...props}
        selectedId={props.selectedHeading}
        key={`${reference}_${index}`}
        clause={{...clause, ...(readPlain ? {clauseId: ""} : {})}}
        topics={topics}
        index={index}
        reference={readPlain ? "" : reference}
        showHeadingReferenceAsIncreased={showHeadingReferenceAsIncreased}
        fullReference={readPlain ? "" : fullReference}
        isFirst={isFirst}
        hideTopics={false}
        changes={clauseChanges}
        isClauseDeletion={isClauseDeletion}
        clauseAdditionChange={clauseAdditionChange}
        usedListFormatStylesPerLevel={usedListFormatStylesPerLevel}
      />
    );
  };

  saveNewClause = (additionClause, contents, headingText) => {
    const {props} = this;
    if (contents && additionClause) {
      const {clause, position} = additionClause;
      props.addClause(clause, position, contents, headingText);
    }
  };
}

export function calculateTopics(clause, props) {
  const {topicMasks, topicsById, topicMasksById} = props;
  const topics = _.uniq(
    calculateDocumentTopics(clause, topicMasks, topicsById, topicMasksById),
    topic => topic.id,
  );
  return topics.filter(
    topic => !props.topicCategoriesById[topic.topic.topiccategory_id].hidden,
  );
}

function getNumberingStats(clauses) {
  // 1. getting all counterTypes' frequencies per each level. e.g.:
  //
  // {
  //   2: {
  //     "DECIMAL": 3,  <- frequency
  //     "LOWER_LETTER": 2  <- frequency
  //   },
  //   4: {
  //     "LOWER_ROMAN": 1  <- frequency
  //   }
  // }
  const rawItems = {};
  function getRawItems(node) {
    if (node.type && node.type === "ClausePartNumberedList") {
      const {level, counterType} = node;
      if (!rawItems[level]) {
        rawItems[level] = {
          [counterType]: 1,
        };
      } else {
        if (!rawItems[level][counterType]) {
          rawItems[level][counterType] = 1;
        } else {
          rawItems[level][counterType] += 1;
        }
      }
    }
    if (node.clauseNodes) {
      node.clauseNodes.forEach(child => getRawItems(child));
    }
  }
  clauses.forEach(clause => getRawItems(clause.nodes));
  // 2. getting most frequently used countertypes per level e.g.:
  // {2: "DECIMAL", 4: "LOWER_ROMAN"}
  const result = {};
  let usedListTypes = [...listStyleTypeImportanceArray];
  _.sortBy(Object.keys(rawItems), item => item).forEach(level => {
    const item = rawItems[level];
    let maxFrequency = 0;
    let counterTypeWithMaxFrequency;
    Object.keys(item).forEach(counterType => {
      const counterTypeFrequency = item[counterType];
      if (counterTypeFrequency > maxFrequency) {
        maxFrequency = counterTypeFrequency;
        counterTypeWithMaxFrequency = counterType;
      }
    });
    if (
      Object.values(result).find(item => item === counterTypeWithMaxFrequency)
    ) {
      result[level] = usedListTypes.shift();
    } else {
      result[level] = counterTypeWithMaxFrequency;
      usedListTypes = usedListTypes.filter(
        type => type !== counterTypeWithMaxFrequency,
      );
    }
  });
  return result;
}

const getUsedListFormatStylesPerLevel = (clauseRef, numberStats) => {
  // getting first item counterType (counterType of clause itself - the one in the heading);
  const firstElementCounterType = getCounterTypeByReference(clauseRef);
  const result = [firstElementCounterType];
  const levels = Object.keys(numberStats).map(levelStr =>
    parseInt(levelStr, 10),
  );
  if (levels && levels.length > 0) {
    const maxLevel = Math.max(...levels);
    for (let i = 1; i <= maxLevel; i++) {
      if (numberStats[i]) {
        result.push(numberStats[i]);
      } else {
        result.push(undefined);
      }
    }
  }
  return result;
};

function getCounterTypeByReference(clauseRef) {
  const firstSymbol = clauseRef[0];
  if (!firstSymbol) {
    return null;
  }
  if (firstSymbol === "i" || firstSymbol === "v" || firstSymbol === "x") {
    return "LOWER_ROMAN";
  } else if (
    firstSymbol === "I" ||
    firstSymbol === "V" ||
    firstSymbol === "X"
  ) {
    return "UPPER_ROMAN";
  } else if (!isNaN(parseInt(firstSymbol, 10))) {
    return "DECIMAL";
  } else if (firstSymbol.match(/[a-z]/i)) {
    return "LOWER_LETTER";
  } else if (firstSymbol.match(/[A-Z]/i)) {
    return "UPPER_LETTER";
  }
}

import * as TopicParameters from "plugins/topicparameters";

function reasonViewer(reason) {
  const result = [];
  Object.keys(reason.topics || {}).forEach(topicId => {
    if (topicId) {
      Object.keys(reason.topics[topicId]).forEach(parameterId => {
        if (parameterId) {
          reason.topics[topicId][parameterId].forEach(
            (reasonItem, reasonItemIndex) => {
              const basePath = `topics.[${topicId}].[${parameterId}].[${reasonItemIndex}]`;
              const baseValue =
                reason.topics[topicId][parameterId][reasonItemIndex];
              result.push(
                // eslint-disable-next-line import/namespace
                TopicParameters["duration"].makeIssueReasonValue(
                  basePath,
                  baseValue,
                ),
              );
            },
          );
        }
      });
    }
  });
  return result;
}

function getDefaultIssueRuleValues(rules) {
  const basePath = `topics.[${rules.topic_id}].[${rules.parameter_id}].[0]`;
  const values = [
    {
      label: "unit",
      path: `${basePath}.unit`,
      value: "",
    },
    {
      label: "value",
      path: `${basePath}.value`,
      value: "",
    },
    {
      label: "original_value",
      path: `${basePath}.original_value`,
      value: "",
    },
  ];
  return [{path: basePath, values}];
}

export default {
  reasonViewer,
  getDefaultIssueRuleValues,
};

import _ from "underscore";
import React from "react";

import IconButton from "material-ui/IconButton";
import AddIcon from "material-ui/svg-icons/content/add";

import ISSUE_TYPES from "common/issue_types";
import Issues from "plugins/issues";

import Issue from "./issue";
import IssueDnd from "./issue_dnd";
import {getOnChangeObj, getAboveIssueType, isLogicIssue} from "./utils";
import {makeUiOrderId} from "common_components/issue_editor/utils/make_ui_order_id";
import replaceUiOrderIds from "common_components/issue_editor/utils/replace_ui_order_ids";
import getNextAfterLastId from "common_components/issue_editor/utils/get_next_after_last_id";

const getIssueComponent = _.memoize(
  editLevel => (editLevel === "base" ? IssueDnd : Issue),
);

export default function multiSelector(issueFilter = () => true) {
  const availalbleIssueKeys = Object.keys(ISSUE_TYPES).filter(issueFilter);

  function initialise(state) {
    return {
      issues: [],
      ..._.pick(state, ["issues", "topic_location"]),
    };
  }

  function validate(issue) {
    if (!(issue.rules.issues.length >= 2)) {
      return {rulesError: {all_issues: "You must have multiple issues"}};
    }
    const issues = issue.rules.issues.map(childIssue => {
      return Issues[childIssue.issue_type.toLowerCase()].editor.validate(
        childIssue,
      );
    });
    if (issues.find(error => error)) {
      return {rulesError: {issues}};
    }
    return null;
  }

  class Component extends React.Component {
    shouldComponentUpdate(prevProps, prevState) {
      return (
        !_.isEqual(prevProps, this.props) || !_.isEqual(prevState, this.state)
      );
    }

    render() {
      const {editLevel, rules, disabled, rulesError} = this.props;
      const issueProps = _.omit(this.props, "rules", "onChange");
      const errors = rulesError && rulesError.issues;
      const IssueComponent = getIssueComponent(this.props.editLevel);
      return (
        <>
          <div className="issue-list">
            {(rules.issues || []).map((issue, index) => (
              <IssueComponent
                {...issueProps}
                key={issue.ui_order_id}
                issue={issue}
                error={errors && errors[this.props.getIndex(issue.ui_order_id)]}
                availalbleIssueKeys={availalbleIssueKeys}
                updateRules={this.updateRules(issue.ui_order_id)}
                updateType={this.updateType(issue.ui_order_id)}
                deleteIssue={this.deleteIssue(issue.ui_order_id)}
                addIssueAbove={this.addIssueAbove(
                  issue.ui_order_id,
                  issue.issue_type,
                  this.props.parentIssueType,
                )}
                injectDownside={this.injectDownside(issue.ui_order_id)}
                injectUpside={this.injectUpside(issue.ui_order_id)}
                ejectDownsideToAncestor={this.ejectDownsideToAncestor(
                  issue.ui_order_id,
                )}
                ejectDownside={this.ejectDownside(issue.ui_order_id)}
                isPreviousIssueLogical={this.isLogicIssue(index - 1)}
                isNextIssueLogical={this.isLogicIssue(index + 1)}
              />
            ))}
          </div>
          {!this.props.isNonOverridableFieldsDisabled && (
            <IconButton
              className="add"
              onClick={this.addIssue}
              disabled={disabled || (editLevel && editLevel !== "base")}
            >
              <AddIcon />
            </IconButton>
          )}
        </>
      );
    }

    /* eslint-disable no-invalid-this */
    addIssue = () => {
      const {rules, path} = this.props;
      const issues = [...rules.issues];
      const additionId = getNextAfterLastId(rules, path);
      const type = availalbleIssueKeys[0];
      const editor = Issues[type.toLowerCase()].editor;
      const additionRules = {
        ...editor.initialise(),
        ...this.getTopicLocationObjWhenAdding(),
      };
      issues.push({
        ui_order_id: additionId,
        issue_type: type,
        rules: additionRules,
      });
      this.props.onChange(getOnChangeObj(issues, rules));
    };

    addIssueAbove = _.memoize(
      (issueId, issueType, parentIssueType) => e => {
        const aboveIssueType = getAboveIssueType(issueType, parentIssueType);
        if (aboveIssueType) {
          this.stopPropagation(e);
          const {rules} = this.props;
          const index = this.props.getIndex(issueId);
          const issues = [...rules.issues];
          const newId = makeUiOrderId(issueId, "0");
          const issue = JSON.parse(JSON.stringify(issues[index]));
          const newIssue = replaceUiOrderIds(
            issue,
            `^${issueId.replace(".", "\\.")}`,
            newId,
          );
          issues[index] = {
            ...issues[index],
            issue_type: aboveIssueType,
            rules: {
              issues: [newIssue],
            },
          };
          this.props.onChange(getOnChangeObj(issues, rules));
        }
      },
      (...argv) => JSON.stringify([argv]),
    );

    updateRules = _.memoize(issueId => change => {
      const {rules, getIndex} = this.props;
      const index = getIndex(issueId);
      const issues = [...rules.issues];
      issues[index] = {
        ...issues[index],
        rules: change,
      };
      this.props.onChange(getOnChangeObj(issues, rules));
    });

    updateType = _.memoize(issueId => (event, idx, value) => {
      const {rules, getIndex} = this.props;
      const index = getIndex(issueId);
      const issues = [...rules.issues];
      issues[index] = {
        ...issues[index],
        issue_type: value,
        rules: {
          ...Issues[value.toLowerCase()].editor.initialise(issues[index].rules),
          ...(rules.issues[index].rules.topic_location
            ? {topic_location: rules.issues[index].rules.topic_location}
            : {}),
        },
      };
      this.props.onChange(getOnChangeObj(issues, rules));
    });

    deleteIssue = _.memoize(issueId => e => {
      this.stopPropagation(e);
      const {rules, getIndex} = this.props;
      const index = getIndex(issueId);
      const issues = [...rules.issues];
      issues.splice(index, 1);
      this.props.onChange(getOnChangeObj(issues, rules));
    });
    /* eslint-enable no-invalid-this */

    getTopicLocationObjWhenAdding = () =>
      ["AND_ON_CLAUSE", "AND_ON_WHOLE_CLAUSE"].includes(
        this.props.parentIssueType,
      )
        ? {topic_location: "in_clause_only"}
        : {};

    injectDownside = _.memoize(issueId => e => {
      this.stopPropagation(e);
      const {props} = this;
      const index = props.getIndex(issueId);
      const nextIndex = index + 1;
      if (isLogicIssue(props.rules.issues[nextIndex])) {
        const rules = JSON.parse(JSON.stringify(props.rules));
        const {issues} = rules;
        const issue = issues[index];
        issues[nextIndex].rules.issues = [
          issue,
          ...issues[nextIndex].rules.issues,
        ];
        issues.splice(index, 1);
        props.onChange(rules);
      }
    });

    injectUpside = _.memoize(issueId => e => {
      this.stopPropagation(e);
      const {props} = this;
      const index = props.getIndex(issueId);
      const prevIndex = index - 1;
      if (isLogicIssue(props.rules.issues[prevIndex])) {
        const rules = JSON.parse(JSON.stringify(props.rules));
        const {issues} = rules;
        const issue = issues[index];
        issues[prevIndex].rules.issues = [
          ...issues[prevIndex].rules.issues,
          issue,
        ];
        issues.splice(index, 1);
        this.props.onChange(rules);
      }
    });

    /*
    * An issue ejects to higher level - to the ancestor.
    * That's why we should keep ejection handler from the previous iteration
    * to use it in the ejectIssue method
    */
    ejectDownsideToAncestor = _.memoize(parentIssueId => issueId => {
      const {props} = this;
      const rules = JSON.parse(JSON.stringify(props.rules));
      const parentIndex = props.getIndex(parentIssueId);
      const parentIssue = rules.issues[parentIndex];
      if (parentIssue) {
        const ejectedIndex = props.getIndex(issueId);
        const ejectedIssue = parentIssue.rules.issues[ejectedIndex];
        if (ejectedIssue) {
          parentIssue.rules.issues.splice(ejectedIndex, 1);
          rules.issues.splice(parentIndex + 1, 0, ejectedIssue);
          this.props.onChange(rules);
        }
      }
    });

    /*
    * Implementation of the issue injection with ejectDownsideToAncestor,
    * that is taken via props from the previous iteration.
    */
    ejectDownside = _.memoize(issueId => {
      if (typeof this.props.ejectDownsideToAncestor === "function") {
        return e => {
          this.stopPropagation(e);
          this.props.ejectDownsideToAncestor(issueId);
        };
      }
      return null;
    });

    isLogicIssue = index => {
      const {rules} = this.props;
      if (rules && rules.issues && rules.issues[index]) {
        return isLogicIssue(rules.issues[index]);
      }
      return false;
    };

    stopPropagation = e => {
      if (e) {
        e.stopPropagation();
      }
    };
  }

  return {
    initialise,
    validate,
    component: Component,
  };
}

import _ from "lodash";

function applyOverrideRulesChanges(rules, _templateChanges, _clientChanges) {
  const templateChanges = _templateChanges || [];
  const clientChanges = _clientChanges || [];
  if (!rules) {
    return rules;
  }
  const newRules = _.cloneDeep(rules);
  const newIssue = {rules: newRules};

  function applyChanges(issue) {
    const issueRules = issue.rules || {};
    if (issueRules.issues) {
      for (const issueRulesIssue of issueRules.issues) {
        applyChanges(issueRulesIssue);
      }
    } else if (issueRules.id) {
      for (const rulesFieldName of Object.keys(issueRules)) {
        const overriddenRules =
          clientChanges.find(
            change =>
              change.field_name === rulesFieldName &&
              change.id === issueRules.id,
          ) ||
          templateChanges.find(
            change =>
              change.field_name === rulesFieldName &&
              change.id === issueRules.id,
          );
        if (overriddenRules) {
          issue.rules[rulesFieldName] = overriddenRules.field_value;
        }
      }
    }
  }
  applyChanges(newIssue);
  return newIssue.rules;
}

export default applyOverrideRulesChanges;

import _ from "underscore";
import getIssueTriggeredSpecificValue from "common/utils/issues/get_issue_triggered_specific_value";
import hasClauseReason from "common/utils/issues/has_clause_reason";
import {
  DocumentClause,
  DocumentClauses,
  NodeTopic,
  Node,
  TopicId,
  TopicsById,
  Topic,
} from "common/types/document";
import {TopicCategoryId} from "common/types/topic_categories";
import {
  DocumentIssue,
  ForeignReference,
  ParameterFilter,
  Reason,
} from "common/types/document_issue";

type Atom = ReturnType<typeof constructAtom>;

function addAtomDataToAtomsIfNeeded(
  addedAtom: Atom | undefined,
  atoms: Atom[],
) {
  // This function operates values by reference, hence no retrun value
  // Here we merge topics/topicparameters of clauses found by different
  // conditions i.e. by reasons/additionalApplicableTopics/topicparameters
  if (!addedAtom) {
    return;
  }
  const atomInAtoms = atoms.find(atom => atom.id === addedAtom.id);
  if (atomInAtoms) {
    // merge topics
    const addedAtomTopics = addedAtom.topics;
    const atomInAtomsTopics = atomInAtoms.topics;

    for (const addedAtomTopic of addedAtomTopics) {
      const existingTopic = atomInAtomsTopics.find(
        topic => topic.topic_id === addedAtomTopic.topic_id,
      );
      if (existingTopic) {
        // merge parameters
        // Q: Does topicparameters exist on any of these values?
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        existingTopic.topicparameters = _.uniq(
          [
            // Q: Does topicparameters exist on any of these values?
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(existingTopic.topicparameters || []),
            // Q: Does topicparameters exist on any of these values?
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ...(addedAtomTopic.topicparameters || []),
          ],
          item => item.topicparameter_id,
        );
      } else {
        atomInAtomsTopics.push(addedAtomTopic);
      }
    }
  } else {
    atoms.push(addedAtom);
  }
}

function matchPath(reason, path) {
  if (reason.path) {
    return reason.path === path;
  }
  if (reason.reason) {
    return matchPath(reason.reason, path);
  }
  if (Array.isArray(reason)) {
    return reason.find(reasonInner => matchPath(reasonInner, path));
  }
  return false;
}

function getForeignReferencesInReason(
  reason: Reason | null,
  foreignReferences: unknown[],
) {
  if (reason && "reason" in reason && reason.reason) {
    if (Array.isArray(reason.reason)) {
      reason.reason.forEach(reasonItem =>
        getForeignReferencesInReason(reasonItem, foreignReferences),
      );
    } else {
      getForeignReferencesInReason(reason.reason, foreignReferences);
    }
  } else if (Array.isArray(reason)) {
    reason.forEach(reasonItem =>
      getForeignReferencesInReason(reasonItem, foreignReferences),
    );
  } else if (reason && reason.foreign_references) {
    reason.foreign_references.forEach(fr => {
      foreignReferences.push(fr);
    });
  }
}

function getIssueForeignReferences(reason: Reason | null): ForeignReference[] {
  const foreignReferences = [];
  getForeignReferencesInReason(reason, foreignReferences);
  return foreignReferences;
}

function constructAtom(
  clause: DocumentClause,
  nodes: Node,
  topicCategories: TopicCategoryId[],
  topicsById: TopicsById,
  hasReasons: boolean,
  topicId: TopicId | null,
  referencedTopics: TopicId[],
  foreignReferenceData: ForeignReference | object | null,
  useAdditionalApplicableClauseTopics: boolean,
  parameterFilters: ParameterFilter[],
) {
  const topics = constructTopics(
    nodes.topics,
    topicCategories,
    topicsById,
    hasReasons,
    topicId,
    referencedTopics,
    useAdditionalApplicableClauseTopics,
    parameterFilters,
  );

  return {
    // "3.b.i" - will contain the parent reference
    reference: nodes.reference,
    text: nodes.partial_text,
    full_text: nodes.text,
    topics,
    baseTopics: nodes.topics,
    id: nodes.id,
    level: nodes.level,
    clause_reference: clause.reference,
    file_index: clause.file_index,
    clause_full_reference: clause.full_reference,
    // This will be the same for parents of this atom
    clause_id: clause.id,
    clause_section_id: clause.section_id,
    clause_order: nodes.order,
    nodes: clause.nodes,
    ...(nodes.pageNumber ? {pageNumber: nodes.pageNumber} : {}),
    ...foreignReferenceData,
  };
}

function constructTopics(
  nodesTopics: NodeTopic[],
  topicCategories: TopicCategoryId[],
  topicsById: TopicsById,
  hasReasons: boolean,
  topicId: TopicId | null,
  referencedTopics: TopicId[],
  useAdditionalApplicableClauseTopics: boolean,
  parameterFilters: ParameterFilter[],
): Array<NodeTopic | (Topic & {topic_id: number})> {
  if (!hasReasons && !useAdditionalApplicableClauseTopics && topicId !== null) {
    return [
      {...topicsById[topicId], topic_id: topicId},
      ...parameterFilters.map(paramFilter => ({
        ...topicsById[paramFilter.topic_id],
        topic_id: paramFilter.topic_id,
      })),
    ];
  }
  const relevantTopics = nodesTopics.filter(
    topic =>
      !topic.is_deleted && topicCategories.includes(topic.topiccategory_id),
  );
  if (hasReasons) {
    return relevantTopics.filter(
      topic =>
        referencedTopics.includes(topic.topic_id) ||
        parameterFilters.find(
          paramFilter => paramFilter.topic_id === topic.topic_id,
        ),
    );
  } else if (useAdditionalApplicableClauseTopics && topicId) {
    return relevantTopics.filter(
      topic =>
        topic.topic_id === topicId ||
        parameterFilters.find(
          paramFilter => paramFilter.topic_id === topic.topic_id,
        ),
    );
  }
  return [];
}

function getAtomByTopics(
  topicsToUse: Array<TopicId | "none">,
  clause: DocumentClause,
  node: Node,
  topicsById: TopicsById,
  topicCategories: TopicCategoryId[],
  useAdditionalApplicableClauseTopics: boolean,
  parameterFilters: ParameterFilter[],
) {
  let atom: Atom;
  (node.topics || []).forEach(nodeTopic => {
    if (nodeTopic.is_deleted) {
      return;
    }
    topicsToUse.forEach(referencedTopicId => {
      if (referencedTopicId === nodeTopic.topic_id) {
        if (atom) {
          atom.topics.push({
            ...topicsById[referencedTopicId],
            topic_id: referencedTopicId,
          });
        } else {
          atom = constructAtom(
            clause,
            node,
            topicCategories,
            topicsById,
            false,
            referencedTopicId,
            [],
            null,
            useAdditionalApplicableClauseTopics,
            parameterFilters,
          );
        }
      }
    });
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return atom;
}

function getAtomByTopicparameters(
  wholeDocumentParameterFilters: ParameterFilter[],
  clause: DocumentClause,
  node: Node,
  topicsById: TopicsById,
  topicCategories: TopicCategoryId[],
  useAdditionalApplicableClauseTopics: boolean,
  allParameterFilters: ParameterFilter[],
) {
  let atom: Atom;
  (node.topics || []).forEach(nodeTopic => {
    if (nodeTopic.is_deleted) {
      return;
    }
    wholeDocumentParameterFilters.forEach(
      ({topic_id: topicId, parameters = []}) => {
        if (topicId === nodeTopic.topic_id) {
          const nodeTopicHasFilteredParameter = (
            nodeTopic.topicparameters || []
          ).find(topicparameter =>
            parameters.find(
              parameterId => parameterId === topicparameter.topicparameter_id,
            ),
          );
          if (nodeTopicHasFilteredParameter) {
            if (atom) {
              atom.topics.push({
                ...topicsById[topicId],
                topic_id: topicId,
              });
            } else {
              atom = constructAtom(
                clause,
                node,
                topicCategories,
                topicsById,
                false,
                topicId,
                [],
                null,
                useAdditionalApplicableClauseTopics,
                allParameterFilters,
              );
            }
          }
        }
      },
    );
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return atom;
}

/* eslint-disable max-statements, complexity */
function getClauseAtomsByIssue(
  issue: DocumentIssue,
  documentClauses: DocumentClauses,
  topicCategories: TopicCategoryId[],
  topicsById: TopicsById,
) {
  const {
    reason,
    referenced_topics: referencedTopics,
    parameter_filters: _parameterFilters,
    use_parameter_filter_clauses: useParameterFilterClauses,
  } = issue;
  const allParameterFilters = _parameterFilters || [];
  const wholeDocumentParameterFilters = allParameterFilters.filter(
    item => item.search_whole_document,
  );
  const displayTopics = getIssueTriggeredSpecificValue(
    issue,
    "display_topics",
    "non_triggered_display_topics",
  );
  const additionalApplicableClauseTopics =
    getIssueTriggeredSpecificValue(
      issue,
      "additional_applicable_clause_topics",
      "non_triggered_additional_applicable_clause_topics",
    ) || [];
  if (!referencedTopics || !documentClauses) {
    return [];
  }
  const foreignReferences = getIssueForeignReferences(
    reason /* , topicsById */,
  );
  let atoms: Atom[] = [];

  function getAtoms(
    node: Node,
    clause: DocumentClause,
    path: string,
    useDisplayTopics: boolean,
    useAdditionalTopics: boolean,
  ) {
    if (node.text) {
      if (node.is_conjunction) {
        return;
      }
      let atom: Atom | undefined;
      if (useAdditionalTopics) {
        const atomByAdditionalApplicableTopic = getAtomByTopics(
          // Not sure whether to infer from this that additionalApplicableClauseTopics is of type TopicId[][]
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          additionalApplicableClauseTopics,
          clause,
          node,
          topicsById,
          topicCategories,
          useAdditionalTopics,
          allParameterFilters,
        );
        addAtomDataToAtomsIfNeeded(atomByAdditionalApplicableTopic, atoms);
        const atomByTopicparameters = getAtomByTopicparameters(
          wholeDocumentParameterFilters,
          clause,
          node,
          topicsById,
          topicCategories,
          useAdditionalTopics,
          allParameterFilters,
        );
        addAtomDataToAtomsIfNeeded(atomByTopicparameters, atoms);

        return;
      } else if (useDisplayTopics) {
        atom = getAtomByTopics(
          displayTopics,
          clause,
          node,
          topicsById,
          topicCategories,
          useAdditionalTopics,
          allParameterFilters,
        );
      } else if (!reason || (Array.isArray(reason) && reason.length === 0)) {
        atom = getAtomByTopics(
          referencedTopics,
          clause,
          node,
          topicsById,
          topicCategories,
          useAdditionalTopics,
          allParameterFilters,
        );
      } else {
        (Array.isArray(reason)
          ? reason
          : Array.isArray(reason.reason)
          ? reason.reason
          : [reason.reason]
        ).forEach(reasonInner => {
          if (hasClauseReason(reasonInner, clause.id, path)) {
            if (matchPath(reasonInner, node.path)) {
              atom = constructAtom(
                clause,
                node,
                topicCategories,
                topicsById,
                true,
                null,
                referencedTopics,
                {},
                false,
                allParameterFilters,
              );
            }
          }
        });

        // look if the clause is a foreign reference
        const clauseForeignReference = foreignReferences.find(
          fr =>
            fr.clause_id === clause.id &&
            (fr.path === clause.path || fr.path === clause.nodes.path),
        );
        if (clauseForeignReference) {
          atom = constructAtom(
            clause,
            node,
            topicCategories,
            topicsById,
            true,
            null,
            referencedTopics,
            _.pick(clauseForeignReference, ["from_id", "from_clause_id"]),
            false,
            allParameterFilters,
          );
        }
      }
      addAtomDataToAtomsIfNeeded(atom, atoms);
    }
    if (node.clauseNodes) {
      node.clauseNodes.forEach(child =>
        getAtoms(child, clause, path, useDisplayTopics, useAdditionalTopics),
      );
    }
  }

  if (!(useParameterFilterClauses && displayTopics.length === 0)) {
    // useParameterFilterClauses is true we don't search applicable
    // clauses matches by reason/referenced topics
    _.forEach(documentClauses, (clauses: DocumentClause[]) => {
      clauses.forEach(clause => {
        const path = "root";
        getAtoms(clause.nodes, clause, path, false, false);
      });
    });
  }
  if (atoms.length === 0 && displayTopics && displayTopics.length > 0) {
    // this block runs only when issue has reasons but no atoms was found
    _.forEach(documentClauses, clauses => {
      clauses.forEach(clause => {
        const path = "root";
        getAtoms(clause.nodes, clause, path, true, false);
      });
    });
  }

  // Here, in case of populated display_topics we filter out all atoms
  // whose topics are not in display_topics.
  // Keep in mind that issue display_topics are chosen from referenced_topics.
  // This means that if display_topics has "none" topic selected
  // then we filter out all clauses of issue referenced_topics
  const isNoneInDisplayTopics = (displayTopics || []).find(
    item => typeof item === "string" && item === "none",
  );
  atoms =
    !displayTopics || displayTopics.length === 0
      ? atoms
      : atoms.reduce((accum, clause) => {
          if (isNoneInDisplayTopics) {
            const isClauseTopicInReferencedTopics = (
              clause.topics || []
            ).find(clauseTopic =>
              referencedTopics.find(
                referendedTopicId => referendedTopicId === clauseTopic.topic_id,
              ),
            );
            if (isClauseTopicInReferencedTopics) {
              return accum;
            }
          }

          const clauseTopicsOfDisplayTopics = (
            clause.topics || []
          ).filter(clauseTopic =>
            Boolean(
              displayTopics.find(
                displayTopicId => displayTopicId === clauseTopic.topic_id,
              ),
            ),
          );
          if (clauseTopicsOfDisplayTopics.length === 0) {
            return accum;
          }

          let allClauseTopics = clauseTopicsOfDisplayTopics;
          // if clause topics matches any of display topics we also need to look
          // if there are additionalApplicableClauseTopics that match clause topics
          // and if there is we keep that topic as well
          if (additionalApplicableClauseTopics.length > 0) {
            const clauseTopicsOfAdditionalApplicableClauseTopics = (
              clause.baseTopics || []
            ).filter(clauseTopic =>
              Boolean(
                additionalApplicableClauseTopics.find(
                  additionalTopicId =>
                    additionalTopicId === clauseTopic.topic_id,
                ),
              ),
            );
            allClauseTopics = allClauseTopics.concat(
              clauseTopicsOfAdditionalApplicableClauseTopics,
            );
          }

          // if clause topics matches any of display topics we also need to look
          // if there are parameterFilters that match clause topics
          // and if there is we keep that topic as well
          if (wholeDocumentParameterFilters.length > 0) {
            const clauseTopicsOfParameterFilters = (
              clause.baseTopics || []
            ).filter(clauseTopic => {
              if (
                !clauseTopic.topicparameters ||
                clauseTopic.topicparameters.length === 0
              ) {
                return false;
              }
              const filtersTopic = wholeDocumentParameterFilters.find(
                param => param.topic_id === clauseTopic.topic_id,
              );
              if (filtersTopic) {
                return filtersTopic.parameters.find(paramId =>
                  clauseTopic.topicparameters.find(
                    topicparam => topicparam.topicparameter_id === paramId,
                  ),
                );
              }
              return false;
            });
            allClauseTopics = allClauseTopics.concat(
              clauseTopicsOfParameterFilters,
            );
          }
          const newClause = {
            ...clause,
            topics: _.uniq(allClauseTopics, false, topic => topic.topic_id),
          };
          accum.push(newClause);
          return accum;
        }, [] as Atom[]);

  if (
    additionalApplicableClauseTopics.length > 0 ||
    wholeDocumentParameterFilters.length > 0
  ) {
    _.forEach(documentClauses, clauses => {
      clauses.forEach(clause => {
        const path = "root";
        getAtoms(clause.nodes, clause, path, false, true);
      });
    });
  }
  return atoms;
}

export default getClauseAtomsByIssue;

import React from "react";
import _ from "underscore";
import {get} from "lodash";

import * as colors from "material-ui/styles/colors";

import {withStyles} from "@material-ui/core/styles";
import Paper from "material-ui/Paper";
import PlaybookIcon from "icons/Playbook";
import MyClausesIcon from "icons/MyClauses";
import PrecedentSearchIcon from "icons/PrecedentSearch";
import ReportIcon from "@material-ui/icons/LibraryBooks";
import GroupIcon from "@material-ui/icons/Group";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import IssueDetailHeading from "./issue_detail_heading";

import GuidanceTab from "./tabs/guidance";
import DraftingTab from "./tabs/drafting";
import PrecedentLanguageTab from "./tabs/precedent_language";
import ReportTab from "./tabs/report";
import CreateTab from "./tabs/create";
import CollaborateTab from "./tabs/collaborate";

import PlaybookEditor from "./playbook_editor";
import {isIe11} from "utils/browser_test";
import Permissioner from "utils/permissioner";

import isIssueAlert from "common/utils/issues/is_issue_alert";
import DocumentDetailContext from "common_components/context/document_detail_context";

import getZoomedFontSize from "utils/get_zoomed_font_size";
import postBroadcastData from "../../routes/document_detail/utils/post_brodcast_data";
import keyCreator from "../../routes/document_detail/utils/key_creator";

const styles = {
  issueDetail: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 0,
    paddingLeft: 0,
    fontSize: "14px",
    fontWeight: "100",
    zIndex: 1,
    height: "100%",
    borderLeft: "1px solid lightgray",
    flexGrow: 1,
    position: "relative",
  },
  body: {
    overflow: "auto",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  reason: {
    color: colors.grey500,
    marginTop: "0.2rem",
    ...(isIe11() ? {width: "100%"} : {}),
  },
  tabs: {
    root: {
      flexShrink: 0,
      "& .MuiTabs-flexContainer": {
        justifyContent: "space-between",
      },
    },
  },
  tab: {
    root: {
      minWidth: "10px",
      padding: "6px 0",
      "&.Mui-selected .MuiTab-wrapper span": {
        fontWeight: 700,
      },
    },
    wrapper: {
      "& span": {
        color: "#333333",
        fontWeight: "normal",
        fontFamily: "Segoe UI, Roboto, sans-serif",
      },
      "& svg": {
        color: "#2196f3",
      },
    },
  },
  tabContents: {
    padding: "0",
    display: "flex",
    flexDirection: "column",
  },
};

class IssueDetail extends React.Component {
  static contextType = DocumentDetailContext;
  constructor(props) {
    super(props);
    const {issue} = props;
    this.state = {
      issue,
      shownTab: get(
        props,
        "panelData.shownTab",
        props.editModeOn ? "drafting" : "guidance",
      ),
      openTabs: {
        guidance: {
          applicableClausesOpen: true,
          relatedClausesOpen: false,
          guidanceOpen: true,
          aboutDescriptionOpen: false,
          fallbackOpen: false,
          standardLanguageOpen: true,
        },
        precedent_language: {
          precedentLanguageOpen: true,
        },
        create: {},
      },
      issueResponseId: null,
      shouldShrinkHeader: false,
      shouldShrinkCounter: 0, // used to prevent precedent language rerender
      historyPanel: true,
      commentsPanel: true,
      isNewIssueResponse: false,
    };
    if (props?.panelData?.selectedReportId) {
      this.state.openTabs.create[props.panelData.selectedReportId] = true;
    }
    this.shrinkDebounceTimeout = null;
    this.ref = React.createRef();
    this.tabBodyRef = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    const {issue: stateIssue} = state;
    const {issue: propsIssue, panelData: currentPanelData} = props;

    if (!_.isEqual(stateIssue, propsIssue)) {
      const {issue} = state;
      const stateUpdates = {issue: propsIssue};
      if (propsIssue.id !== issue.id) {
        stateUpdates.relatedClausesOpen = false;
        stateUpdates.guidanceOpen = true;
        stateUpdates.fallbackOpen = false;
        stateUpdates.standardLanguageOpen = true;
        stateUpdates.issueResponseId = null;
        stateUpdates.shouldShrinkCounter = 0;
      }
      if (
        currentPanelData &&
        currentPanelData.shownTab !== undefined &&
        currentPanelData.shownTab !== state.shownTab
      ) {
        stateUpdates.shownTab = currentPanelData.shownTab;
      }
      if (currentPanelData?.selectedReportId) {
        stateUpdates.openTabs = {
          ...state.openTabs,
          create: {
            ...state.openTabs.create,
            [currentPanelData.selectedReportId]: true,
          },
        };
      }
      return stateUpdates;
    }
    return null;
  }

  render() {
    const {isFlipChecklistIcons} = this.props;
    const paperStyles = {
      ...styles.issueDetail,
      ...this.props.style,
      ...(isFlipChecklistIcons
        ? {
            backgroundColor: "white",
            fontFamily: "Segoe UI, Roboto, sans-serif",
          }
        : {
            backgroundColor: "#f4f4f4",
            fontFamily: "Roboto, sans-serif",
          }),
    };
    return <Paper style={paperStyles}>{this.renderCommonPanels()}</Paper>;
  }

  shouldComponentUpdate(nextProps, nextState) {
    const shouldUpdate =
      !_.isEqual(_.omit(this.state, "issue"), _.omit(nextState, "issue")) ||
      this.props.issue.document_issue_id !==
        nextProps.issue.document_issue_id ||
      this.props.issue.manualUpdateCount !==
        nextProps.issue.manualUpdateCount ||
      this.props.issue.issue_responses !== nextProps.issue.issue_responses ||
      this.props.issue.issue_comments !== nextProps.issue.issue_comments ||
      this.props.searchByIssueResults !== nextProps.searchByIssueResults ||
      this.props.applicableClauses !== nextProps.applicableClauses ||
      this.props.editMode !== nextProps.editMode ||
      this.props.additionalReportClausesState !==
        nextProps.additionalReportClausesState ||
      this.props.addClausesToReportDocumentIssueId !==
        nextProps.addClausesToReportDocumentIssueId ||
      this.props.renderStatus !== nextProps.renderStatus ||
      this.props.editingReport !== nextProps.editingReport ||
      this.props.selectedClauses !== nextProps.selectedClauses ||
      this.props.fileIndex !== nextProps.fileIndex ||
      this.props.addClausesToReportDocumentIssueId !==
        nextProps.addClausesToReportDocumentIssueId;

    return shouldUpdate;
  }

  componentDidUpdate(prevProps) {
    if (this.props.issue.id !== prevProps.issue.id) {
      if (this.tabBodyRef && this.tabBodyRef.current) {
        this.tabBodyRef.current.scrollTop = 0;
      }
    }
  }

  renderCommonPanels() {
    const {issue, shownTab} = this.state;
    const {
      editPlaybookMode,
      project: {report_settings: reportSettings = []},
    } = this.props;
    const {zoom} = this.context;
    if (editPlaybookMode) {
      return <PlaybookEditor {...this.props} />;
    }

    const issueTriggered = isIssueAlert(issue);
    const isRagReport =
      reportSettings.length === 1 && reportSettings[0].type === "rag_report";

    const tabs = this.getTabs(isRagReport);

    const StyledTabs = withStyles(styles.tabs)(Tabs);

    postBroadcastData(keyCreator("report", this.props.document.document_id), {
      selectedIssue: issue.id,
    });

    return (
      <>
        <IssueDetailHeading
          issue={issue}
          project={this.props.project}
          showIssuesInChecklist={this.props.showIssuesInChecklist}
          zoom={zoom}
          closeButtonOnTheLeft={this.props.closeButtonOnTheLeft}
          onCloseClick={this.closePanel}
          shouldShrink={this.state.shouldShrinkHeader}
          updateDocumentIssue={this.props.updateDocumentIssue}
          correctDocumentIssueManually={this.props.correctDocumentIssueManually}
          isFlipChecklistIcons={this.props.isFlipChecklistIcons}
          user={this.props.user}
          currentIssuesetItem={this.props.currentIssuesetItem}
        />
        <StyledTabs
          value={
            !tabs.find(tab => tab.key === shownTab) ? "guidance" : shownTab
          }
          textColor="primary"
          indicatorColor="primary"
          onChange={this.handleTabChange}
          style={{padding: "0px 6px"}}
        >
          {tabs}
        </StyledTabs>
        <div
          ref={this.tabBodyRef}
          style={styles.body}
          onScroll={this.onBodyScroll}
        >
          <div style={styles.tabContents}>
            {this.renderTabContents(issue, issueTriggered, zoom, isRagReport)}
          </div>
        </div>
      </>
    );
  }

  onBodyScroll = e => {
    const {scrollTop} = e.target;
    const {shouldShrinkHeader} = this.state;
    function debounce() {
      this.shrinkDebounceTimeout = null;
    }
    if (scrollTop > 100 && !shouldShrinkHeader && !this.shrinkDebounceTimeout) {
      this.setState(prevProps => ({
        shouldShrinkHeader: true,
        shouldShrinkCounter: prevProps.shouldShrinkCounter + 1,
      }));
      this.shrinkDebounceTimeout = setTimeout(debounce.bind(this), 200);
    } else if (
      scrollTop < 100 &&
      shouldShrinkHeader &&
      !this.shrinkDebounceTimeout
    ) {
      this.setState(prevProps => ({
        shouldShrinkHeader: false,
        shouldShrinkCounter: prevProps.shouldShrinkCounter + 1,
      }));
      this.shrinkDebounceTimeout = setTimeout(debounce.bind(this), 200);
    }
  };

  getTabs = () => {
    const {
      user,
      project: {report_settings: reportSettings = []},
      issue,
    } = this.props;
    const {shouldShrinkHeader} = this.state;
    const canUseDrafting = new Permissioner(user).hasPermission(
      "can-use-drafting",
    );
    const canUsePrecedents = new Permissioner(user).hasPermission(
      "can-use-precedents",
    );
    const canViewCollaborate = new Permissioner(user).hasPermission(
      "view-collaborate-tab",
    );
    const isEmptyParent = issue.issue_type === "EMPTY_PARENT";
    const iconSize = 24;

    const iconStyles = {
      width: iconSize,
      height: iconSize,
    };
    const tabs = [
      {
        value: "guidance",
        label: "Playbook",
        icon: PlaybookIcon,
      },
      {
        value: "drafting",
        label: "My Clauses",
        icon: MyClausesIcon,
        disabled: !canUseDrafting && !user.is_admin,
      },
    ];
    if (canUsePrecedents || user.is_admin) {
      tabs.push({
        value: "precedent_language",
        label: "Precedents",
        icon: PrecedentSearchIcon,
        disabled: isEmptyParent,
      });
    }
    if (reportSettings.length > 0) {
      tabs.push({
        value: "create",
        label: "Reporting",
        icon: ReportIcon,
      });
    }
    if (canViewCollaborate) {
      tabs.push({
        value: "collaborate",
        label: "Collaborate",
        icon: GroupIcon,
      });
    }

    // TODO: change font size of tabs label based on the width of issue detail panel
    // Should address the issue of adding a div+ref to the root of issue detail component may
    // break the layout and issue detail scroll disappears
    const labelFontSize = tabs.length === 5 ? 11 : 13;

    const StyledTab = withStyles(styles.tab)(Tab);

    return tabs.map(tab => {
      const Icon = tab.icon;
      return (
        <StyledTab
          key={tab.value}
          value={tab.value}
          label={
            shouldShrinkHeader ? undefined : (
              <span style={{fontSize: labelFontSize}}>{tab.label}</span>
            )
          }
          icon={<Icon style={iconStyles} />}
        />
      );
    });
  };

  renderTabContents(issue, issueTriggered, zoom, isRagReport) {
    const {isFlipChecklistIcons} = this.props;
    const containerStyles = {
      flex: "1 0 auto",
      fontSize: getZoomedFontSize(13, "checklist", zoom),
      lineHeight: isFlipChecklistIcons
        ? "inherit"
        : `${getZoomedFontSize(15, "checklist", zoom)}px`,
    };

    const baseProps = {
      issue,
      issueTriggered,
      zoom,
      isRagReport,
      containerStyles,
      ...this.props,
    };

    const emailTextProps = {
      onIgnore: this.onIgnore,
      onAddToEmail: this.onAddToEmail,
      onRevert: this.onRevert,
    };

    switch (this.state.shownTab) {
      case "guidance":
        return (
          <GuidanceTab
            key="guidance-tab"
            onCorrectnessChange={this.onCorrectnessChange}
            updateIssueReviewState={this.props.updateIssueReviewState}
            reviewTrackingOn={this.props.reviewTrackingOn}
            openTabs={this.state.openTabs["guidance"] || {}}
            onItemOpen={this.onItemOpen("guidance")}
            {...baseProps}
          />
        );
      case "drafting":
        return (
          <DraftingTab
            key="drafting-tab"
            documentIssue={issue}
            onCorrectClick={this.onCorrectClick}
            onRevertClick={this.onRevertClick}
            findIssueDocuments={this.findIssueDocuments}
            searchByIssueResults={
              this.props.searchByIssue ? this.props.searchByIssueResults : []
            }
            openTabs={this.state.openTabs["drafting"] || {}}
            onItemOpen={this.onItemOpen("drafting")}
            {...emailTextProps}
            {...baseProps}
          />
        );
      case "precedent_language":
        return (
          <PrecedentLanguageTab
            key="precedent_language-tab"
            documentIssue={this.getDocumentIssue(issue)}
            findIssueDocuments={this.findIssueDocuments}
            searchByIssueResults={
              this.props.searchByIssue ? this.props.searchByIssueResults : []
            }
            openTabs={this.state.openTabs["precedent_language"] || {}}
            onItemOpen={this.onItemOpen("precedent_language")}
            shouldShrinkCounter={this.state.shouldShrinkCounter}
            {...baseProps}
          />
        );
      case "report":
        return (
          <ReportTab
            key="report-tab"
            openTabs={this.state.openTabs["report"] || {}}
            onItemOpen={this.onItemOpen("report")}
            {...baseProps}
          />
        );
      case "create":
        return (
          <CreateTab
            key="create-tab"
            openTabs={this.state.openTabs["create"] || {}}
            onItemOpen={this.onItemOpen("create")}
            {...emailTextProps}
            {...baseProps}
          />
        );
      case "collaborate":
        return (
          <CollaborateTab
            key="collaborate-tab"
            openTabs={this.state.openTabs["collaborate"] || {}}
            onItemOpen={this.onItemOpen("collaborate")}
            {...baseProps}
          />
        );
      default:
        return null;
    }
  }

  // extractValue(strOrArray) {
  //   const value = Array.isArray(strOrArray)
  //     ? strOrArray.join("\n")
  //     : strOrArray;
  //   return value;
  // }

  closePanel = async () => {
    await this.props.clearPanelData();
    if (this.props.searchByIssueResults) {
      this.props.searchByIssueClear();
    }
  };

  onItemOpen = _.memoize(pageName =>
    _.memoize(stateName => e => {
      e.stopPropagation();

      if (pageName === "create" && this.props.isShowReportTabHandler) {
        const {
          project: {report_settings: reportSettings = []},
        } = this.props;
        if (
          reportSettings.find(
            item => item.id === stateName && item.type === "rag_report",
          ) &&
          !this.state.openTabs?.[pageName]?.[stateName]
        ) {
          this.props.isShowReportTabHandler(stateName);
        } else {
          this.props.isShowReportTabHandler(null);
        }
        this.setState(prevState => ({
          openTabs: {
            ...prevState.openTabs,
            [pageName]: prevState.openTabs?.[pageName]?.[stateName]
              ? {}
              : {[stateName]: true},
          },
        }));
      } else {
        this.setState(prevState => ({
          openTabs: {
            ...prevState.openTabs,
            [pageName]: {
              ...prevState.openTabs[pageName],
              [stateName]: !(prevState.openTabs[pageName] || {})[stateName],
            },
          },
        }));
      }
    }),
  );

  onAddToEmail = async reportId => {
    if (!this.props.addIssueToReport) {
      return;
    }
    const result = await this.props.addIssueToReport(
      this.state.issue,
      reportId,
    );
    this.updateIssueReason(result.value, {confirmed: true});
  };

  onIgnore = async reportId => {
    if (!this.props.removeIssueFromReport) {
      return;
    }
    const result = await this.props.removeIssueFromReport(
      this.state.issue,
      reportId,
    );
    this.updateIssueReason(result.value, {rejected: true});
  };

  onRevert = async reportId => {
    if (!this.props.revertIssueActionState) {
      return;
    }
    const result = await this.props.revertIssueActionState(
      this.props.issue,
      reportId,
    );
    this.updateIssueReason(result.value, {rejected: false, confirmed: false});
  };

  updateIssueReason = (payload, issueState) => {
    if (this.state.issue.id === payload.issue_id) {
      const issueUpdated = {
        ...this.state.issue,
        ...(payload.updates || {}),
        last_edited: payload.last_edited,
        ...issueState,
      };
      this.props.updateIssueDetail(issueUpdated);
    }
  };

  onCorrectClick = () => {
    const {currentIssuesetItem} = this.props;
    const dataPath = `${currentIssuesetItem.master_id}.${
      currentIssuesetItem.remote_client_id
        ? currentIssuesetItem.remote_client_id
        : "master"
    }`;
    this.props.correctDocumentIssue(this.getDocumentIssue(), dataPath);
  };

  onRevertClick = () => {
    const {currentIssuesetItem} = this.props;
    const dataPath = `${currentIssuesetItem.master_id}.${
      currentIssuesetItem.remote_client_id
        ? currentIssuesetItem.remote_client_id
        : "master"
    }`;
    this.props.revertDocumentIssue(this.getDocumentIssue(), dataPath);
  };

  getDocumentIssue = (issue = this.state.issue) => {
    if (!issue || issue.document_issue_id === null) {
      return null;
    }
    return this.props.documentIssues.find(
      di => di.document_issue_id === issue.document_issue_id,
    );
  };

  handleTabChange = (e, shownTab) => {
    if (shownTab !== "create") {
      this.props.isShowReportTabHandler &&
        this.props.isShowReportTabHandler(null);
    } else {
      const id = Object.keys(this.state.openTabs?.create)[0];
      const {
        project: {report_settings: reportSettings = []},
      } = this.props;
      if (
        id &&
        reportSettings.find(
          item => item.id === id && item.type === "rag_report",
        )
      ) {
        this.props.isShowReportTabHandler &&
          this.props.isShowReportTabHandler(id);
      }
    }
    this.setState(() => ({shownTab}));
  };

  onCorrectnessChange = (e, newValue) => {
    const isTriggered = isIssueAlert(this.state.issue);
    let updates;
    switch (newValue) {
      case "is_correctly_applied":
        updates = {
          is_correctly_applied: true,
          should_be_issue: isTriggered,
        };
        break;
      case "incorrectly_presented":
        updates = {
          is_correctly_applied: false,
          should_be_issue: isTriggered,
        };
        break;
      case "incorrectly_applied":
        updates = {
          is_correctly_applied: false,
          should_be_issue: !isTriggered,
        };
        break;
      case "unsure":
      default:
        updates = {
          is_correctly_applied: null,
          should_be_issue: null,
        };
        break;
    }
    this.setState(
      prevState => ({...prevState.issue, ...updates}),
      () => {
        if (this.props.updateDocumentIssue) {
          this.props.updateDocumentIssue(this.props.issue, updates);
        }
      },
    );
  };

  findIssueDocuments = issueId => {
    if (this.props.searchByIssue && issueId) {
      this.props.searchByIssue(
        this.props.project.id,
        issueId,
        this.props.applicableClausesPath,
      );
    }
  };
}

export default IssueDetail;

import React from "react";
import EmailButton from "../helper_components/email_button";
import ItemContainer from "../helper_components/item_container";

import Describer from "common/utils/issues/reason/describer";
import getOverriddenIssueField from "common/utils/issues/get_overridden_issue_field";
import CORRECTION_ISSUE_TYPES from "common/correction_issue_types";

const styles = {
  button: {
    margin: "0.5rem 0",
    padding: "0.5rem",
    display: "inline-flex",
    alignItems: "center",
    height: "unset",
    lineHeight: "unset",
  },
  header: {},
  text: {
    fontFamily: "Roboto, sans-serif",
    fontWeight: 200,
  },
  textContainer: {
    margin: "0.5em 0",
  },
  boldText: {
    fontWeight: 400,
  },
};

export default function({
  documentIssue,
  headerStyles = {},
  containerStyles = {},
  onCorrectClick,
  onRevertClick,
  documentClauses,
  topicsById,
  issue,
  currentIssuesetItem,
  ...props
}) {
  const correctionSettings = getOverriddenIssueField(
    documentIssue.correction_settings,
    documentIssue.override_values,
    currentIssuesetItem,
    "correction_settings",
  );
  const corrections = correctionSettings.map((correction, index) => {
    const baseReason =
      issue.reason && Array.isArray(issue.reason)
        ? issue.reason.map(_reason => _reason.reason)
        : issue.reason;

    let eligibleIssueIndex = -1;
    if (issue.rules && issue.rules.issues) {
      eligibleIssueIndex = issue.rules.issues.findIndex(issue =>
        CORRECTION_ISSUE_TYPES.includes(issue.issue_type),
      );
    }
    const reason = getReason(baseReason, eligibleIssueIndex);
    const describer = new Describer(documentClauses, topicsById, {
      ...issue,
      reason,
    });
    const isCorrected = documentIssue.correction;
    const reasonValue = (() => {
      try {
        return describer.reasonValue;
      } catch {
        return "";
      }
    })();
    const reference = (() => {
      try {
        return describer.clausepartReference;
      } catch {
        return "";
      }
    })();
    const correctionValue = correction.value;
    const changeText = (
      <span>
        Change <span style={styles.boldText}>{reasonValue}</span> to{" "}
        <span style={styles.boldText}>{correctionValue}</span>
      </span>
    );
    const revertText = (
      <span>
        Revert <span style={styles.boldText}>{correctionValue}</span> back to{" "}
        <span style={styles.boldText}>{reasonValue}</span>
      </span>
    );
    const text = (
      <span style={styles.text}>
        <span>Clause {reference}: </span>
        {isCorrected ? revertText : changeText}
      </span>
    );
    return (
      <div key={index}>
        <div style={styles.textContainer}>{text}</div>
        {!isCorrected && (
          <EmailButton
            key="correct-button"
            onButtonClick={onCorrectClick}
            primary={true}
            buttonContainerStyles={styles.button}
          >
            Correct Issue
          </EmailButton>
        )}
        {isCorrected && (
          <EmailButton
            key="revert-button"
            onButtonClick={onRevertClick}
            primary={false}
            buttonContainerStyles={styles.button}
          >
            Revert Correction
          </EmailButton>
        )}
      </div>
    );
  });
  return (
    <ItemContainer
      title="Corrections"
      expandable={true}
      onExpandMenu={props.onOpen}
      isMenuExpanded={props.isOpen}
      headerStyles={{...styles.header, ...headerStyles}}
      containerStyles={containerStyles}
      useFlippedInnerBodyStyles={true}
    >
      {corrections}
    </ItemContainer>
  );
}

function getReason(baseReason, eligibleIssueIndex) {
  if (eligibleIssueIndex === -1) {
    return baseReason;
  }
  return baseReason.map(reason => {
    if (!reason || !reason.rules || !reason.rules[eligibleIssueIndex]) {
      return reason;
    }
    return {
      ...reason,
      ...reason.rules[eligibleIssueIndex],
    };
  });
}

// Sometimes when we listen and handle an event the markup can be re-rendered or
// a new popover, dialog and so on can be rendered under the cursor, and a next
// possible upcoming event can be happened on the new rendered markup (with new
// event target) and it can cause unwanted default behaviors under the cursor or
// unwanted event propagation through the new markup.
//
// Examples:
//   1) A render has been caused by the mousedown event, then the upcoming
//      mouseup event can cause the click event on the new markup. Possible
//      unwanted effects in popovers are instant item select and popover close.
//   2) A render has been caused by the single click then a possible upcoming
//      second click can cause the double click event on the new markup.
//      Possible unwanted effect in elements with text is the text selection.

function listen(eventType, target = window, listener) {
  target.addEventListener(eventType, listener, true);
  setTimeout(() => {
    target.removeEventListener(eventType, listener, true);
  }, 0);
}

export function preventDefault(eventType, target) {
  listen(eventType, target, event => event.preventDefault());
}

export function stopPropagation(eventType, target) {
  listen(eventType, target, event => event.stopPropagation());
}

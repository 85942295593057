import React, {CSSProperties} from "react";
import Grid from "@material-ui/core/Grid";
// TODO: Override for Typescript
// eslint-disable-next-line import/named
import Button, {ButtonProps} from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
// TODO: Override for Typescript
// eslint-disable-next-line import/named
import {PropTypes} from "@material-ui/core";

type Option = {id: string; label: string};

type SplitButtonProps = {
  baseValue?: string; // Either an ID in @prop options, or a string value
  defaultValue?: string;
  disabled?: boolean;
  options: Option[];

  onMenuCloseHandler?: (selectedOption: Option) => void;
  clickWithValue?: boolean;
  // option is only defined if clickWithValue is true
  onClick: (option?: Option) => void;

  color?: PropTypes.Color;
  size?: ButtonProps["size"];
  labelStyle?: CSSProperties;
  containerStyles?: CSSProperties;

  // HACK: This component was not designed to be used this way.
  excludeSelectedOptionFromMenu?: boolean;
  disableChangingSelectedOption?: boolean;
};

export default function SplitButton(props: SplitButtonProps) {
  const {options, disabled} = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(
    getBaseValueIndex(props.baseValue, options),
  );

  React.useEffect(() => {
    setSelectedIndex(getBaseValueIndex(props.baseValue, options));
  }, [props.baseValue]);

  const handleMenuItemClick = (event, index) => {
    if (!props.disableChangingSelectedOption) {
      setSelectedIndex(index);
    }
    setOpen(false);
    if (props.onMenuCloseHandler) {
      props.onMenuCloseHandler(options[index]);
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={props.containerStyles || {}}
    >
      <Grid style={{flexBasis: "auto"}} item xs={12}>
        <ButtonGroup
          variant="contained"
          color={props.color}
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            onClick={() => {
              props.onClick(
                props.clickWithValue ? options[selectedIndex] : undefined,
              );
            }}
            style={{flexShrink: 0}}
            disabled={disabled}
            size={props.size}
          >
            <span style={props.labelStyle}>
              {getButtonCaption(options, selectedIndex, props.defaultValue)}
            </span>
          </Button>
          <Button
            color={props.color}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            disabled={disabled}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          placement="bottom-end"
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{zIndex: 100}}
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options
                      // Preserve pre-filter indices...
                      .map((option, index) => ({...option, index}))
                      .filter(option =>
                        props.excludeSelectedOptionFromMenu
                          ? option.id !== options[selectedIndex].id
                          : true,
                      )
                      .map(({id, label, index}) => (
                        <MenuItem
                          key={id}
                          selected={index === selectedIndex}
                          onClick={event => handleMenuItemClick(event, index)}
                        >
                          <span style={{fontSize: "0.8em"}}> {label}</span>
                        </MenuItem>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

function getBaseValueIndex(baseValue: string | undefined, options: Option[]) {
  let resultIndex = 0;
  if (baseValue && options.length > 0) {
    if (
      typeof options[0] === "object" &&
      options[0] !== null &&
      "id" in options[0]
    ) {
      const foundIndex = (options as Array<{id: string | number}>).findIndex(
        op => op.id === baseValue,
      );
      if (foundIndex !== -1) {
        resultIndex = foundIndex;
      }
    }
  }
  return resultIndex;
}

function getButtonCaption(
  options: Option[],
  selectedIndex: number,
  defaultValue?: string,
) {
  const value = options[selectedIndex];
  if (!value) {
    return defaultValue || "Button";
  }
  return value.label;
}

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import NavItem from "./nav_item";

const styles = {
  label: {
    marginBottom: 4,
    fontSize: 12,
  },
};

export default class OrganisationSelector extends React.Component {
  render() {
    const {organisations} = this.props.user;
    if (!organisations || !organisations.length) {
      return null;
    }
    const currentOrganisation =
      organisations.length === 1
        ? organisations[0]
        : organisations.find(
            organisation => organisation.id === this.props.organisationId,
          );
    const items = _.chain(organisations)
      .filter(organisation => !organisation.is_archived)
      .sortBy(organisation => organisation.id)
      .value();
    return (
      <NavItem
        activeByNested={false}
        children={
          <div>
            <div style={styles.label}>Organisation</div>
            <div>{_.get(currentOrganisation, "name")}</div>
          </div>
        }
        {...items.length > 1 && {
          nestedChildren: (
            <div className="app-sidebar-popover__scrollable">
              {items.map((item, index) => {
                const isActive = item.id === this.props.organisationId;
                const url = this.props.getOrganisationUrl(
                  item.id,
                  item.client_db_id,
                );
                return (
                  <NavItem
                    key={index}
                    isActive={isActive}
                    {...!isActive && {
                      onClick: () => this.props.changeOrganisation(),
                    }}
                    to={!isActive && url ? url : undefined}
                  >
                    {`${item.id} - ${item.name}`}
                  </NavItem>
                );
              })}
            </div>
          ),
        }}
      />
    );
  }
}

OrganisationSelector.propTypes = {
  user: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
  getOrganisationUrl: PropTypes.func.isRequired,
  changeOrganisation: PropTypes.func.isRequired,
};

import UNINITIALISED from "utils/uninitialised";
import createTransition from "utils/create_transition";

const transition = createTransition(stateObserver => {
  const orgOnlyURL = /\/organisation\/\d+\/?$/;
  return stateObserver
    .distinctUntilChanged(
      state =>
        state.router.locationBeforeTransitions.pathname.match(orgOnlyURL) &&
        state.projects !== UNINITIALISED &&
        (state.user && Array.isArray(state.user.permissions)),
    )
    .filter(
      state =>
        state.router.locationBeforeTransitions.pathname.match(orgOnlyURL) &&
        state.projects !== UNINITIALISED &&
        (state.user && Array.isArray(state.user.permissions)),
    );
});

export default transition.getTransition;
export const reset = transition.resetAll;

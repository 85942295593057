import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

createReduxPromiseActionType(actionTypes, "PROMPTS_FETCH");
createReduxPromiseActionType(actionTypes, "PROMPTS_CLEAR");
createReduxPromiseActionType(actionTypes, "PROMPT_ADD");
createReduxPromiseActionType(actionTypes, "PROMPT_UPDATE");
createReduxPromiseActionType(actionTypes, "PROMPT_REMOVE");

export default actionTypes;

import React from "react";
import _ from "underscore";
import * as colors from "material-ui/styles/colors";
import {User} from "common/types/user";
import {Project} from "common/types/project";
import {Permission} from "common/types/permissions";

type PermissionGroup = {
  can_user_set: boolean;
};

const noPermissionStyle = {
  fontSize: "24px",
  margin: "2rem",
  color: colors.grey700,
};

export default class Permissioner {
  user: User;
  permissions: Record<string, Permission>;

  constructor(user: User) {
    this.user = user;
    this.permissions = (_.chain(user.permissions)
      .groupBy("name")
      .mapObject((permissions: Permission[]) => permissions[0])
      .value() as unknown) as Record<string, Permission>;
  }
  hasPermission(permission: string) {
    return this.permissions[permission];
  }
  isAdmin() {
    return this.user.is_admin;
  }
  getNoPermissionMessage() {
    return (
      <div style={noPermissionStyle}>
        Sorry, you are not permitted to access this page. Please consult service
        administrator if you think this is an error.
      </div>
    );
  }
  static getPermissionGroups(user: User, permissionGroups: PermissionGroup[]) {
    if (user.is_admin) {
      return permissionGroups;
    } else if (
      new Permissioner(user).hasPermission("can-update-user-permission-group")
    ) {
      return permissionGroups.filter(pg => pg.can_user_set);
    }
    return [];
  }

  canViewProject(project: Pick<Project, "accesstags">) {
    const projectPermission = this.permissions["get-projects"];

    // If backend returns permissions lacking new ACE features, assume all
    // projects are accessible
    if (
      !(
        "accesstags" in projectPermission || "global_scope" in projectPermission
      )
    ) {
      return true;
    }

    return (
      projectPermission &&
      ("global_scope" in projectPermission ||
        _.intersection(
          projectPermission.accesstags,
          project.accesstags.map(({id}) => id),
        ).length > 0)
    );
  }
}

export function hasPermission(permission: string, user: User) {
  return new Permissioner(user).hasPermission(permission);
}

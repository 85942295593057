import React from "react";
import * as colors from "@material-ui/core/colors";

const style = {
  buttonsContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  statusButton: {
    padding: "10px 15px",
    margin: "10px 15px",
    border: `1px solid ${colors.grey[300]}`,
    backgroundColor: "transparent",
    cursor: "pointer",
  },
};

const IssueStatusMenu = ({
  issueStatus,
  issue,
  updateIssueReviewState,
  reviewTrackingOn,
}) =>
  reviewTrackingOn ? (
    <div style={style.buttonsContainer}>
      <button
        key={"Mark Unread"}
        style={style.statusButton}
        onClick={
          issueStatus === 2
            ? () => updateIssueReviewState(issue, 1)
            : () => updateIssueReviewState(issue, 2)
        }
      >
        {issueStatus === 2 ? "Mark Unread" : "Mark Read"}
      </button>
      <button
        key={"Escalate"}
        style={style.statusButton}
        onClick={
          issueStatus === 3
            ? () => updateIssueReviewState(issue, 2)
            : () => updateIssueReviewState(issue, 3)
        }
      >
        {issueStatus === 3 ? "Deescalate" : "Escalate"}
      </button>
    </div>
  ) : null;

export default IssueStatusMenu;

import React from "react";

import CheckBoxIcon from "@material-ui/icons/CheckBoxSharp";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlankSharp";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    color: "#424242",
  },
  label: {
    marginTop: 2,
    userSelect: "none",
  },
  icon: {
    margin: "0px 4px",
    color: "#757575",
  },
};

function CheckboxBasic(props) {
  const {checked, onCheck, label, labelOnTheLeft, disabled} = props;

  const iconStyles = {
    ...styles.icon,
    ...props.iconStyles,
    cursor: disabled ? "default" : "pointer",
    opacity: disabled ? 0.4 : 1,
  };
  const labelStyles = {
    ...styles.label,
    ...props.labelStyles,
    cursor: disabled ? "default" : "pointer",
    opacity: disabled ? 0.7 : 1,
  };
  const handler = disabled ? () => null : onCheck;
  return (
    <div
      style={{
        ...styles.container,
        ...(props.containerStyles ? props.containerStyles : {}),
      }}
    >
      {label &&
        labelOnTheLeft && (
          <span onClick={handler} style={labelStyles}>
            {label}
          </span>
        )}
      {checked ? (
        <CheckBoxIcon
          className={props.iconClassName}
          onClick={handler}
          style={iconStyles}
        />
      ) : (
        <CheckBoxOutlineBlankIcon
          className={props.iconClassName}
          onClick={handler}
          style={iconStyles}
        />
      )}
      {label &&
        !labelOnTheLeft && (
          <span onClick={handler} style={labelStyles}>
            {label}
          </span>
        )}
    </div>
  );
}

export default CheckboxBasic;

import increaseReference from "common/utils/increase_reference";
import cleanReference from "utils/clauses/clean_reference";

function renderReference(numberParent, clause, prevReference, numberOffset) {
  const reference = findReference(clause);
  if (
    numberParent &&
    numberParent.reference &&
    reference &&
    reference.indexOf(numberParent.reference) === 0 &&
    numberParent.counterType !== "DECIMAL"
  ) {
    const splitClause = reference.split(".");
    const splitParent = numberParent.reference.split(".");
    const lastReference =
      splitClause.length === 1 && numberParent.counterType === "NUMERIC"
        ? reference
        : splitClause.slice(splitParent.length).join(".");
    const alteredReference = increaseReference(
      cleanReference(lastReference),
      prevReference,
      numberOffset,
    );
    return alteredReference;
  }
  const alteredReference = increaseReference(
    cleanReference(reference),
    prevReference,
    numberOffset,
  );
  return alteredReference;
}

function findReference(clause) {
  if (!clause) {
    return "";
  }
  if (clause.reference) {
    return clause.reference;
  }
  if (clause.clauseNodes && clause.clauseNodes.length) {
    return findReference(clause.clauseNodes[0]);
  }
  return "";
}

export default renderReference;

import React from "react";
import PropTypes from "prop-types";

import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import SidebarContext from "./context";
import userGuideLink from "constants/user_guide_link";

import NavItem from "./nav_item";

export default class UserNav extends React.Component {
  render() {
    return (
      <div className="app-sidebar__user-nav">
        <div>
          {(() => {
            if (!this.context.isExpanded && this.props.collapsedUserNav) {
              return this.props.collapsedUserNav;
            }
            return (
              <React.Fragment>
                {this.props.navItems.map((item, index) => (
                  <NavItem
                    key={index}
                    icon={item.icon}
                    pathname={item.pathname}
                    isActive={item.isActive}
                    isCollapsed={!this.context.isExpanded}
                  >
                    {item.text}
                  </NavItem>
                ))}
              </React.Fragment>
            );
          })()}
        </div>
        <div>
          <NavItem
            isCollapsed={!this.context.isExpanded}
            icon={<HelpOutlineIcon />}
            href={userGuideLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            User Guide
          </NavItem>
        </div>
      </div>
    );
  }
}

UserNav.contextType = SidebarContext;

UserNav.propTypes = {
  navItems: PropTypes.array.isRequired,
  projectId: PropTypes.number.isRequired,
  collapsedUserNav: PropTypes.element,
};

const allUnicodeSymbols = "●▲";
const allEntities = ["&apos;", "&quot;", "&amp;", "&lt;", "&gt;"].join("|");
const symbolsRegexp = `(?:${allEntities}|[^\\w\\d\\s${allUnicodeSymbols}])`;
export const pureReplacements = [
  {
    from: "\\s*[^a-zA-Z0-9\\s]*\\s*\\([a-zA-Z0-9]{1,3}\\)[^a-zA-Z0-9\\s]*\\s*",
    to: " ",
  },
  {
    from: `${symbolsRegexp}*(?:\\s+${symbolsRegexp}+)+\\s*`,
    to: " ",
  },
  {
    from: `\\s*(?:${symbolsRegexp}+\\s+)+${symbolsRegexp}*`,
    to: " ",
  },
  {from: `${symbolsRegexp}+`, to: ""},
  {
    from:
      "\\s{2,}|[\\t\\n\\v\\f\\r" +
      "\\u00a0\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007" +
      "\\u2008\\u2009\\u200a\\u200b\\u2028\\u2029\\u202F\\u3000]+",
    to: " ",
  },
].map(replacement => ({
  ...replacement,
  type: "trash",
}));

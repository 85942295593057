import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";

import {
  updateStateWithRequest,
  updateStateWithSuccess,
} from "utils/ensure_fresh_update";

import UserActionTypes from "modules/user/constants/action_types";
import OrganisationActionTypes from "modules/organisation/constants/action_types";
import ActionTypes from "../constants/action_types";
import documentLoadStates from "common/constants/documentLoadStates";
/* eslint-disable complexity  */
/* eslint-disable max-statements */
function getOutputTextObject(document, payload) {
  return {
    ...document,
    last_edited: payload.last_edited,
    output_text: {
      ...document.output_text,
      ...payload.output_text,
    },
  };
}

const documentIssuesRelatedFields = [
  "topics_changed_ids",
  "parameters_changed_ids",
  "issues_changed_ids",
  "issues_last_processed",
  "last_classification_changed",
  "issues_last_changed",
  "issues_last_kicked_off",
  "issues_last_edited",
];

function getRecentDocuments(docs) {
  const maxTime = 30; // in seconds
  const result = [];
  const nowDate = Date.now();
  for (let i = 0; i < docs.length; i++) {
    if (nowDate - docs[i].creation_date < maxTime * 1000) {
      result.push(docs[i]);
    }
  }
  return result;
}

export default function documentsReducer(state = UNINITIALISED, action) {
  if (
    state === UNINITIALISED &&
    action.type !== ActionTypes.DOCUMENTS_FETCH.SUCCESS &&
    action.type !== ActionTypes.DOCUMENTS_EMPTY_INIT.SUCCESS
  ) {
    return state;
  }

  switch (action.type) {
    case ActionTypes.DOCUMENTS_FETCH.SUCCESS:
    case ActionTypes.DOCUMENTS_ROLES_DELETE.SUCCESS: {
      let recentDocuments = getRecentDocuments(state);
      const documentsById = _.groupBy(state, "id");
      const documents =
        action.type === ActionTypes.DOCUMENTS_FETCH.SUCCESS
          ? action.payload.documents
          : action.payload;
      const payloadDocuments = documents.map(document => {
        recentDocuments = recentDocuments.filter(
          recentDoc => recentDoc.id !== document.id,
        );
        document.creation_date = new Date(document.creation_date);

        const oldDocument = documentsById?.[document.id]?.[0];
        if (oldDocument) {
          if (_.isEqual(document.parties, oldDocument.parties)) {
            document.partyUpdateCount = oldDocument?.partyUpdateCount ?? 1;
          } else {
            document.partyUpdateCount =
              (oldDocument?.partyUpdateCount ?? 1) + 1;
          }
        } else {
          document.partyUpdateCount = 1;
        }
        return document;
      });
      return [
        ...payloadDocuments.map(doc => ({
          ...doc,
          projectId:
            "projectId" in action.payload
              ? parseInt(action.payload.projectId, 10)
              : undefined,
        })),
        ...recentDocuments,
      ];
    }

    case ActionTypes.DOCUMENT_UPDATE.REQUEST: {
      if (action.payload.new_project_id) {
        return state.filter(document => document.id !== action.payload.id);
      }
      return state.map(document => {
        if (document.id === action.payload.id) {
          // TODO1: should properly fill document reducer without using this reducer
          // need to radically amend document_fetch sql query
          if (action.payload.meta_data_feedback) {
            return {...document, last_edited: action.payload.last_edited};
          } else if (action.payload.output_text !== undefined) {
            return getOutputTextObject(document, action.payload);
          }
          return {
            ...document,
            ...updateStateWithRequest(document, action.payload, [
              "id",
              "last_edited",
            ]),
          };
        }
        return document;
      });
    }

    case ActionTypes.DOCUMENT_UPDATE.SUCCESS: {
      return state.map(document => {
        if (document.id === action.payload.id) {
          // TODO1
          if (
            action.payload.meta_data_feedback &&
            !_.isEmpty(action.payload.meta_data_feedback)
          ) {
            return {...document, last_edited: action.payload.last_edited};
          } else if (
            action.payload.output_text !== undefined &&
            !_.isEmpty(action.payload.output_text)
          ) {
            return getOutputTextObject(document, action.payload);
          }
          if (
            action.payload.parties &&
            !_.isEqual(document.parties, action.payload.parties)
          ) {
            document.partyUpdateCount = (document?.partyUpdateCount ?? 1) + 1;
          }
          return updateStateWithSuccess(document, action.payload);
        }
        return document;
      });
    }
    case ActionTypes.DOCUMENT_CREATE.SUCCESS: {
      if (
        action.payload &&
        action.payload.id !== -1 &&
        !state.find(document => document.id === action.payload.id)
      ) {
        action.payload.creation_date = new Date(action.payload.creation_date);
        action.payload.issue_count = 0;
        return [
          action.payload,
          ...state.filter(document => document.id !== -1),
        ];
      } else if (action.payload.id === -1) {
        return [
          {
            id: -1,
            name: "Uploading document",
            concat_load_state: [documentLoadStates["Queued"]],
            creation_date: new Date(),
            last_edited: new Date(),
            contract_type: {},
          },
          ...state.filter(document => document.id !== -1),
        ];
      }
      return state;
    }
    /* eslint-disable prefer-template */
    case ActionTypes.DOCUMENT_UPLOAD.REQUEST: {
      const {progress} = action.payload;
      const filesUploaded = action.payload[2] || [];
      const newDocuments = [];
      filesUploaded.forEach(() => {
        newDocuments.push({
          id: -1,
          name:
            "Uploading document" +
            (progress && progress < 1
              ? ` (${(progress * 100).toFixed(0)}%)`
              : ""),
          concat_load_state: [documentLoadStates["Queued"]],
          creation_date: new Date(),
          last_edited: new Date().toISOString(),
          contract_type: {},
          group_id: action.payload[9],
          newly_uploaded: true,
        });
      });
      return [...newDocuments, ...state.filter(document => document.id !== -1)];
    }
    case ActionTypes.DOCUMENT_UPLOAD.SUCCESS: {
      if (action.payload && action.payload.length) {
        const newDocuments = action.payload.reduce((result, doc) => {
          if (doc.dont_fill_reducers) {
            return result;
          }
          result.push({
            ...doc,
            issue_count: 0,
            creation_date: new Date(doc.creation_date),
            newly_uploaded: true,
          });
          return result;
        }, []);
        return [
          ...newDocuments,
          ...state.filter(document => document.id !== -1),
        ];
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CREATE.REQUEST:
    case ActionTypes.DOCUMENT_UPLOAD_UPDATE.SUCCESS: {
      const {progress} = action.payload;
      return state.map(doc => {
        if (doc.id === -1) {
          return {
            ...doc,
            name:
              "Uploading document" +
              (progress && progress < 1
                ? ` (${(progress * 100).toFixed(0)}%)`
                : ""),
            concat_load_state: [documentLoadStates["Queued"]],
            creation_date: new Date(),
            last_edited: new Date().toISOString(),
            contract_type: {},
          };
        }
        return doc;
      });
      /* eslint-enable prefer-template */
    }

    case ActionTypes.DOCUMENT_PARSE.SUCCESS: {
      let found = false;
      let newState = state.map(document => {
        if (document.id === action.payload.id) {
          found = true;
          return updateStateWithSuccess(
            _.omit(document, ["organisation_id", "project_id", "type"]),
            {
              ...action.payload,
              creation_date:
                typeof action.payload.creation_date === "string"
                  ? new Date(action.payload.creation_date)
                  : action.payload.creation_date,
            },
          );
        }
        return document;
      });
      if (!found) {
        action.payload.creation_date = new Date(action.payload.creation_date);
        newState = newState.concat([action.payload]);
      }
      return newState;
    }

    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_PARAMETER_CLASSIFICATION.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_CLASSIFICATION.SUCCESS: {
      if (
        action.payload.document_load_state ===
        documentLoadStates["PendingApproval"]
      ) {
        return state.map(document => {
          if (document.id === action.payload.document_id) {
            return {
              ...document,
              last_edited: action.payload.document_last_edited,
              load_state: action.payload.document_load_state,
            };
          }
          return document;
        });
      }
      return state;
    }

    case ActionTypes.DOCUMENT_CLAUSE_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_ISSUE_CORRECT.SUCCESS:
    case ActionTypes.DOCUMENT_STATE_REVERT.SUCCESS: {
      return state.map(document => {
        if (document.id === action.payload.document_id) {
          return {
            ...document,
            last_edited: action.payload.document_last_edited,
            ...(action.payload.document_updates
              ? action.payload.document_updates
              : {}),
          };
        }
        return document;
      });
    }
    case ActionTypes.DOCUMENT_RECLASSIFY_START.REQUEST: {
      return state.map(document => {
        if (document.id === action.payload[2]) {
          return {
            ...document,
            concat_load_state: [documentLoadStates["Unclassified"]],
            clausepart_load_count: 0,
          };
        }
        return document;
      });
    }
    case ActionTypes.DOCUMENT_RECLASSIFY_START.SUCCESS: {
      return state.map(document => {
        if (document.id === action.payload.id) {
          return {
            ...document,
            ...action.payload,
            clausepart_load_count: 0,
          };
        }
        return document;
      });
    }
    case ActionTypes.DOCUMENT_UPDATE_LOAD_STATE.SUCCESS: {
      return state.map(document => {
        if (document.id === action.payload.id) {
          return {
            ...document,
            ...action.payload,
          };
        }
        return document;
      });
    }

    case ActionTypes.DOCUMENT_DELETE.SUCCESS: {
      return state.filter(document => document.id !== action.payload.id);
    }

    case ActionTypes.DOCUMENT_REFRESH.SUCCESS: {
      return state.map(document => {
        if (document.id === action.payload.document_id) {
          return {
            ...document,
            queue_order: null,
            ...action.payload,
          };
        }
        return document;
      });
    }
    case ActionTypes.DOCUMENT_FETCH.SUCCESS: {
      return state.map(document => {
        if (document.id === action.payload.document_id) {
          return {
            ...document,
            ..._.pick(action.payload, [
              "parse_diff",
              "parse_diff_length",
              "plain_text",
              "parties",
              ...documentIssuesRelatedFields,
            ]),
          };
        }
        return document;
      });
    }
    case ActionTypes.ISSUE_COMPARISON_DATA_FETCH.SUCCESS: {
      if (!action || !action.payload || !action.payload.document_id) {
        return state;
      }
      return state.map(document => {
        if (document.id === action.payload.document_id) {
          return {
            ...document,
            ..._.pick(action.payload, documentIssuesRelatedFields),
          };
        }
        return document;
      });
    }
    case ActionTypes.DOCUMENT_ISSUE_MANUAL_CORRECT.SUCCESS:
    case ActionTypes.DOCUMENT_ISSUES_UPDATE.SUCCESS:
    case ActionTypes.DOCUMENT_ISSUE_UPDATE.SUCCESS: {
      return state.map(document => {
        const {payload} = action;
        if (document.id === action.payload.document_id) {
          return {
            ...document,
            last_edited: payload.document_last_edited,
          };
        }
        return document;
      });
    }
    case ActionTypes.DOCUMENT_ISSUES_REFETCH.SUCCESS: {
      return state.map(document => {
        const {payload} = action;
        if (document.id === action.payload.document_id) {
          return {...document, issues_last_edited: payload.issues_last_edited};
        }
        return document;
      });
    }
    case ActionTypes.DOCUMENT_ISSUES_FETCH.SUCCESS: {
      if (action.payload && action.payload.issues_last_edited) {
        return state.map(document => {
          if (document.id === action.payload.document_id) {
            const result = {
              ...document,
              issues_last_edited: action.payload.issues_last_edited,
            };
            if (document.issue_count === 0 && action.payload.issues) {
              // when creating document from templated contract we get redirected to
              // document list with newely created document having 0 issues. After
              // issue finder finishes processing it sends issues to the client and
              // we update issue_count
              result.issue_count = action.payload.issues.length;
            }
            return result;
          }
          return document;
        });
      }
      return state;
    }
    case ActionTypes.DOCUMENTS_REPROCESS.REQUEST: {
      return state.map(document => ({
        ...document,
        justClicked: true,
      }));
    }
    case ActionTypes.DOCUMENT_ROLES_REPROCESS.SUCCESS: {
      if (action?.payload?.document_roles_last_processed) {
        return state.map(document => {
          if (document.id === action.payload.document_id) {
            const result = {
              ...document,
              roles_out_of_date: false,
              roles_last_processed:
                action.payload.document_roles_last_processed,
            };
            return result;
          }
          return document;
        });
      }
      return state;
    }
    case ActionTypes.DOCUMENTS_CLEAR.SUCCESS:
    case OrganisationActionTypes.ORGANISATION_CHANGE.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
    case ActionTypes.DOCUMENTS_FETCH.REQUEST:
      return UNINITIALISED;
    case ActionTypes.DOCUMENTS_EMPTY_INIT.SUCCESS: {
      return [];
    }
    default:
      return state;
  }
}

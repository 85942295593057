import Describer from "../../../utils/issues/reason/describer";

function reason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);
  const {rules} = issue;
  const parameterText = rules.ignore_params
    ? ""
    : rules.any
      ? "with some parameters"
      : rules.parameter_ids.length === 0
        ? "with no parameters"
        : `with ${describer.listParameterRules()}`;
  return [
    `Topic '${describer.topicName}' is present${
      parameterText.trim().length ? ` ${parameterText.trim()}` : ""
    }`,
    showClause && describer.clauseReferences
      ? ` in clause ${describer.clauseReferences}`
      : "",
  ].join("");
}

export default reason;

import _ from "underscore";
import getIssueApplicableClauses from "utils/issues/get_issue_applicable_clauses";
import addIsSectionUnnumberedToClauses from "utils/issues/add_section_unnumbered_to_clauses";
import groupClausesByTopics from "utils/clauses/group_clauses_by_topics";
import concatApplicableClausesRaw from "../concat_applicable_clauses_raw";
import {
  groupByCommonDefinitions,
  getCommonDefinitionApplicableClauseGroups,
} from "./common_definitions";

function distinctBasicAndReferencedApplicableClauses(clauses) {
  return clauses.reduce(
    (accum, clause) => {
      if (clause.from_clause_id) {
        accum.referencedApplicableClauses.push(clause);
      } else {
        accum.basicApplicableClauses.push(clause);
      }
      return accum;
    },
    {
      basicApplicableClauses: [],
      referencedApplicableClauses: [],
    },
  );
}

export default function calculateIssueApplicableClauses(
  issue,
  issueset,
  documentClauses,
  acceptedTopicCategoryIds,
  documentSections,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  parties,
  preventMemo,
  additionalReportClauses,
) {
  let applicableClausesRaw = [];
  if (issue.groupName === "Unrecognised Clauses") {
    applicableClausesRaw = issue.applicable_clauses;
  } else {
    applicableClausesRaw = getIssueApplicableClauses(
      issue,
      documentClauses,
      acceptedTopicCategoryIds,
      documentSections,
      topicsById,
    );
  }

  const additionalReportClausesRaw = concatApplicableClausesRaw(
    applicableClausesRaw,
    documentClauses,
    additionalReportClauses,
  );

  // add is_section_unnumbered to applicable clauses
  applicableClausesRaw = addIsSectionUnnumberedToClauses(
    additionalReportClausesRaw,
    documentSections,
    documentClauses,
  );

  const {
    basicApplicableClauses,
    referencedApplicableClauses,
  } = distinctBasicAndReferencedApplicableClauses(applicableClausesRaw);
  const commonDefinitionApplicableClauseGroups = getCommonDefinitionApplicableClauseGroups(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    parties,
    preventMemo,
  );
  const applicableClausesGroupedByCommonDefinitions = groupByCommonDefinitions(
    documentClauseparts,
    commonDefinitionApplicableClauseGroups,
  );

  const groupedApplicableClauses = groupClausesByTopics(
    basicApplicableClauses,
    topicsById,
    Boolean(issue.parameter_filters && issue.parameter_filters.length > 0), // use parameters
    applicableClausesGroupedByCommonDefinitions,
  );
  const applicableClausesReferenced = groupClausesByTopics(
    referencedApplicableClauses,
    topicsById,
  );

  const applicableClauses = _.chain(groupedApplicableClauses)
    .values()
    .flatten()
    .unique(item => item.id)
    .value();
  return {
    applicableClauses,
    groupedApplicableClauses,
    basicApplicableClauses,
    applicableClausesReferenced,
    referencedApplicableClauses,
    applicableClausesRaw,
  };
}

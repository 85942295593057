import basePath from "base_path";
import requestor from "requestor";
import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import {OrganisationId} from "common/types/organisation";
import {ProjectId} from "common/types/project";
import {DocumentId} from "common/types/document";
import {buildQuery} from "utils/uri";

type Filter = {
  /**
   * Optionally, a list of document IDs can be provided to specify specific
   * documents to fetch reports for. By default, a small set of documents is
   * selected by recency.
   */
  documents?: DocumentId[];
  search_term?: string;
  user_id?: number;
};

export default createReduxPromiseAction(
  ActionTypes.REPORTS_FETCH,
  (organisationId: OrganisationId, projectId: ProjectId, filter?: Filter) => {
    const query: Partial<{
      documents: string;
      search_term: string;
      user_id: number;
    }> = {};
    // You can't really use optional arguments in this
    // "createReduxPromiseAction" because it appends a positional argument
    // "now" that throws off the assumed order if the optional prop is omitted.
    // The "now" argument is a Date, so we crudely count that condition as
    // equivalent to the filter being omitted.
    if (filter && !(filter instanceof Date)) {
      if ("search_term" in filter && filter.search_term !== undefined) {
        query.search_term = filter.search_term;
      }
      if ("user_id" in filter && filter.user_id !== undefined) {
        query.user_id = filter.user_id;
      }
      if ("documents" in filter && filter.documents !== undefined) {
        query.documents = filter.documents.join(",");
      }
    }
    return requestor
      .get(
        `${basePath}/organisation/${organisationId}/project/${projectId}/reports${buildQuery(
          query,
        )}`,
        {
          withCredentials: true,
        },
      )
      .then(response => response.data);
  },
);

import _ from "underscore";
import React from "react";

import Checkbox from "material-ui/Checkbox";
import ReactSelectLabel from "common_components/react_select_label";

import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";
import ParameterValuesSelect from "./parameter_values_select";
import {updateSelection} from "common_components/select_handlers";

import renderError from "./render_error";
import filterByWord from "./filter_by_word";

function getRegexErrors(parameterValues) {
  const parameterValueErrors = {};
  parameterValues.forEach((parameterValue, parameterValueIndex) => {
    try {
      new RegExp(parameterValue);
    } catch {
      parameterValueErrors[parameterValueIndex] = "Invalid Regex";
    }
  });
  return parameterValueErrors;
}

function initialise(state) {
  return {
    topic_id: null,
    parameter_id: null,
    parameter_values: [],
    match_regexes: false,
    ..._.pick(
      state,
      "topic_id",
      "parameter_id",
      "parameter_values",
      "match_regexes",
    ),
  };
}

function validate(issue) {
  const {rules} = issue;
  if (!(rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  if (!(rules.parameter_id > 0)) {
    return {rulesError: {parameter_id: "You must set a parameter id"}};
  }
  const {parameter_values: parameterValues} = rules;
  if (!(parameterValues && parameterValues.length > 0)) {
    return {rulesError: {parameter_values: "You must set a value"}};
  }
  if (_.uniq(parameterValues).length !== parameterValues.length) {
    return {rulesError: {parameter_values: "Parameter values must be unique"}};
  }
  if (
    parameterValues.filter(pv => pv.trim()).length !== parameterValues.length
  ) {
    return {rulesError: {parameter_values: "Parameter values can't be blank"}};
  }
  if (
    rules.match_regexes &&
    Object.values(getRegexErrors(rules.parameter_values)).length > 0
  ) {
    return {rulesError: {parameter_values: "Please fix regex errors"}};
  }
  return null;
}

const styles = {
  parameterValuesContainer: {
    margin: "2em 1em",
    width: "50%",
    padding: "1rem",
    border: "1px solid lightgray",
    borderRadius: "4px",
  },
};

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      topics,
      isNonOverridableFieldsDisabled,
      overridableFieldsStyle,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    return (
      <div>
        <Checkbox
          label="Match Regexes"
          checked={Boolean(rules.match_regexes)}
          onCheck={this.onMatchRegexesCheck}
          style={{marginLeft: "1rem", marginBottom: "1rem"}}
          disabled={isNonOverridableFieldsDisabled || disabled}
        />
        <div style={{margin: "0 1em"}}>
          <ReactSelectLabel>Topic</ReactSelectLabel>
          <Select
            className="topic"
            multi={false}
            value={rules.topic_id}
            options={topics
              .filter(topic =>
                topic.parameters.find(
                  parameter => parameter.parameter_type === "regex",
                ),
              )
              .map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
            onChange={this.updateTopic}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getTopicLink(this.props.organisationId, rules.topic_id)}
            shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
          />
          {renderError(this.props.rulesError, "topic_id")}
        </div>
        <div style={{margin: "2em 1em"}}>
          <ReactSelectLabel>Parameter</ReactSelectLabel>
          <Select
            className="parameter"
            multi={false}
            value={rules.parameter_id}
            options={
              currentTopic &&
              currentTopic.parameters
                .filter(parameter => parameter.parameter_type === "regex")
                .map(parameter => ({
                  label: parameter.name,
                  value: parameter.id,
                }))
            }
            onChange={this.updateParameter}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getParameterLink(
              this.props.organisationId,
              rules.topic_id,
              rules.parameter_id,
            )}
          />
          {renderError(this.props.rulesError, "parameter_id")}
        </div>
        <div style={styles.parameterValuesContainer}>
          <ReactSelectLabel>Values</ReactSelectLabel>
          <ParameterValuesSelect
            className="parameter-values"
            value={(rules.parameter_values || [])
              .filter(value => value.trim())
              .map(value => ({value, label: value}))}
            options={this.getParameterValueOptions(rules)}
            onChange={this.updateParameterValues}
            getRegexErrors={rules.match_regexes ? getRegexErrors : () => ({})}
            clearable={false}
            style={overridableFieldsStyle}
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
        </div>
        {renderError(this.props.rulesError, "parameter_values")}
      </div>
    );
  }

  onMatchRegexesCheck = (e, isChecked) => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      match_regexes: isChecked,
    });
  };

  getParameterValueOptions(rules) {
    const {topic_id: topicId, parameter_id: parameterId} = rules;
    const parameter = this.getParameter(topicId, parameterId);
    let values = _.map(this.props.rules.parameter_values, value => ({
      label: value,
      value,
    }));
    if (parameter && parameter.known_values) {
      values = _.union(
        values,
        parameter.known_values.filter(value => value.trim()).map(value => ({
          label: value,
          value,
        })),
      );
    }
    return values;
  }

  getParameters(topicId) {
    if (!topicId) {
      return [];
    }
    return (
      (this.props.topicsById[topicId] &&
        this.props.topicsById[topicId].parameters) ||
      []
    );
  }
  getParameter(topicId, parameterId) {
    return this.getParameters(topicId, parameterId).find(
      param => param.id === parameterId,
    );
  }

  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
    });
  };
  updateParameter = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_id: value.value,
    });
  };
  updateParameterValues = _values => {
    const values = updateSelection(_values);
    const {editLevel, rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_values: values.map(value => value.value),
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
    });
  };
  /* eslint-enable no-invalid-this */
}

export default {
  initialise,
  validate,
  component: Component,
};

const {getPure} = require("./getPure");
const {getReplacementPositions} = require("./getReplacementPositions");
const {pureReplacements} = require("./replacements/pure");

export class FuzzyString {
  constructor(str, ...replacementLists) {
    this.original = str;
    this.replacementLists =
      replacementLists.length > 0 ? replacementLists : [pureReplacements];
    this.pure = this.replacementLists.reduce(
      (acc, replacementList) => getPure(acc, replacementList),
      str,
    );
    this.replacements = getReplacementPositions(str, ...this.replacementLists);
  }

  getShiftForPosition(position, isHungry = false) {
    return this.replacements.reduce((acc, {position: pos, to, shift, type}) => {
      const endPos = pos + to.length;
      if (
        (type === "significant" && pos < position) ||
        (type === "trash" &&
          ((isHungry && pos <= position) || (!isHungry && endPos < position)))
      ) {
        return acc + shift;
      }
      return acc;
    }, 0);
  }

  getOffsetForPosition(position, isHungry = false) {
    const shiftForPosition = this.getShiftForPosition(position, isHungry);
    return position + shiftForPosition;
  }

  getWrapper() {
    const startOffset = this.getOffsetForPosition(0, true);
    const endOffset = this.getOffsetForPosition(this.pure.length);
    const wrapper = {
      start: this.original.substring(0, startOffset),
      end: this.original.substring(endOffset),
    };
    return wrapper;
  }
}

import React from "react";
import ReactDOM from "react-dom";
import pluralise from "pluralize";

import DocumentTopPanel from "./document_top_panel";
import DocumentView from "./document_view";

const styles = {
  documentViews: {
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    flexGrow: 1,
    flexShrink: 1,
    fontFamily: "Roboto, sans-serif",
    position: "relative",
    overflow: "hidden",
    flexDirection: "column",
  },
  viewScrollerHolder: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    flexGrow: 1,
    width: "100%",
  },
  viewScroller: {
    overflowY: "scroll",
    overflowX: "hidden",
  },
};

export default class DocumentViews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibilityCount: 0,
      isScrolled: false,
      scrollTopValue: 0,
    };
  }

  handleScroll = event => {
    const isScrolled = event.target.scrollTop > 0;
    this.setState({scrollTopValue: event.target.scrollTop});
    if (isScrolled !== this.state.isScrolled) {
      this.setState({isScrolled});
    }
  };

  render() {
    const isTopPanelShown = Boolean(
      this.props.isReportPanelShown ||
        typeof this.props.editModeOn === "boolean",
    );
    const definitionsWithPlurals = addPluralsToDefinitinos(
      this.props.documentDefinitions,
    );
    return (
      <div
        className="document-views"
        style={styles.documentViews}
        onClick={this.props.clearPanelData}
      >
        {isTopPanelShown && (
          <DocumentTopPanel
            ref={ref => {
              this.documentTopPanelNode = ReactDOM.findDOMNode(ref);
            }}
            {...this.props}
            isScrolled={this.state.isScrolled}
          />
        )}
        <div className="view-scroller-holder" style={styles.viewScrollerHolder}>
          <div
            id="documentViews"
            className="view-scroller"
            style={styles.viewScroller}
            ref={node => (this.scrollNode = node)}
            onScroll={this.handleScroll}
            onClick={this.showElem}
          >
            <DocumentView
              {...this.props}
              documentDefinitions={definitionsWithPlurals}
              updateHeadingVisibility={this.props.updateHeadingVisibility(0)}
              announceVisibility={this.state.visibilityCount}
              shouldScroll={true}
              viewIndex={0}
              style={{paddingTop: isTopPanelShown ? 0 : "1.5rem"}}
              documentTopPanelNode={this.documentTopPanelNode}
              scrollTopValue={this.state.scrollTopValue}
            />
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate() {
    // from DocumentDetail.updateVisiblity()
    const nextVisibilityTimeoutLength = 100;
    const timeoutLength = nextVisibilityTimeoutLength + 50;
    const nextProps = this.props;
    if (nextProps.scrollCorrection !== this.state.scrollCorrection) {
      if (this.lastUpdateLocationTimeout) {
        clearTimeout(this.lastUpdateLocationTimeout);
      }
      this.lastUpdateLocationTimeout = setTimeout(
        () => this.correctLocation(),
        timeoutLength,
      );
      this.outstandingUpdate = true;
      this.setState({
        visibilityCount: this.state.visibilityCount + 1,
        scrollCorrection: nextProps.scrollCorrection,
      });
    } else if (this.outstandingUpdate) {
      clearTimeout(this.lastUpdateLocationTimeout);
      this.lastUpdateLocationTimeout = setTimeout(
        () => this.correctLocation(),
        timeoutLength,
      );
    }

    if (
      nextProps.hiddenHeadings !== this.state.hiddenHeadings ||
      nextProps.filterClauses !== this.state.filterClauses ||
      nextProps.selectedTopics !== this.state.selectedTopics
    ) {
      this.scrollHeight = null;
      this.setState({
        visibilityCount: this.state.visibilityCount + 1,
        hiddenHeadings: nextProps.hiddenHeadings,
        filterClauses: nextProps.filterClauses,
        selectedTopics: nextProps.selectedTopics,
      });
    }
  }

  correctLocation() {
    this.outstandingUpdate = false;
    this.lastUpdateLocationTimeout = null;
    const nextProps = this.props;
    const {scrollCorrection} = nextProps;
    if (scrollCorrection && scrollCorrection.reference) {
      const heading = document.querySelectorAll(
        `.clause-heading.r${scrollCorrection.reference.replace(/\./g, "_")}`,
      )[0];
      this.scrollNode.scrollTop =
        heading.offsetTop - scrollCorrection.offsetFromTop;
    }
  }
}

function addPluralsToDefinitinos(definitions) {
  const pluralDefinitions = [];
  definitions.forEach(def => {
    if (!pluralise.isPlural(def.term)) {
      pluralDefinitions.push({
        ...def,
        term: pluralise(def.term),
      });
    }
  });
  return [...definitions, ...pluralDefinitions];
}

import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};
createReduxPromiseActionType(actionTypes, "SEARCH");
createReduxPromiseActionType(actionTypes, "SEARCH_CLEAR");

createReduxPromiseActionType(actionTypes, "SEARCH_BY_ISSUE");
createReduxPromiseActionType(actionTypes, "SEARCH_BY_ISSUE_CLEAR");

export default actionTypes;

export default function render(parameter, parameterValue) {
  const {value} = parameterValue;
  const {unit} = parameterValue;
  let suffixSymbol = "";
  let displayedValue = value;
  if (unit && unit === "percentage") {
    suffixSymbol = "%";
    displayedValue = Math.round(value * 100);
  }
  return `${displayedValue}${suffixSymbol}`;
}

import React from "react";
import * as colors from "@material-ui/core/colors";

import ItemContainer from "../helper_components/item_container";

import getIe11ClampedText from "utils/text/get_ie11_clamped_text";
import Markdown from "common_components/markdown";

const styles = {
  bodyContainer: {
    cursor: "pointer",
    textAlign: "justify",
    color: colors.grey[600],
    fontWeight: 400,
  },
};

function Guidance(props) {
  const [isExpanded, updateIsExpanded] = React.useState(false);
  function triggerIsExpanded() {
    updateIsExpanded(!isExpanded);
  }
  const {guidance, headerStyles = {}, containerStyles = {}} = props;
  if (!guidance) {
    return null;
  }
  const clampedText = getIe11ClampedText(guidance, 8);
  const needsExpanding = guidance !== clampedText;
  return (
    <ItemContainer
      title="Guidance"
      expandable={true}
      onExpandMenu={props.onOpen}
      isMenuExpanded={props.isOpen}
      headerStyles={headerStyles}
      containerStyles={containerStyles}
      bodyStyles={styles.bodyContainer}
      bodyClickHandler={triggerIsExpanded}
      useFlippedInnerBodyStyles={true}
    >
      <div data-testid="guidance">
        <Markdown>{isExpanded ? guidance : clampedText}</Markdown>
        {needsExpanding && !isExpanded && (
          <div style={{marginTop: "0.5em", color: colors.blue[200]}}>
            Read More
          </div>
        )}
      </div>
    </ItemContainer>
  );
}

export default Guidance;

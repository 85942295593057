import React from "react";

import Permissioner from "utils/permissioner";
import {Link} from "react-router";
import {RadioButton, RadioButtonGroup} from "material-ui/RadioButton";
import LinkIcon from "material-ui/svg-icons/content/link";

import getZoomedFontSize from "utils/get_zoomed_font_size";
import isIssueAlert from "common/utils/issues/is_issue_alert";

const styles = {
  correctnessPanelContainer: {
    borderBottom: "1px solid lightgray",
    padding: "10px",
    flexShrink: 0,
  },
  radioButtonIcon: {
    width: "18px",
    height: "18px",
    top: "1px",
  },
  radioButtonLabel: {
    right: "10px",
  },
};

function getIssueCorrectnessRadioValue(issue) {
  const {
    is_correctly_applied: isCorrectlyApplied,
    should_be_issue: shouldBeIssue,
  } = issue;
  const isTriggered = isIssueAlert(issue);
  if (isCorrectlyApplied === true && shouldBeIssue === isTriggered) {
    return "is_correctly_applied";
  }
  if (isCorrectlyApplied === false && shouldBeIssue === isTriggered) {
    return "incorrectly_presented";
  }
  if (isCorrectlyApplied === false && shouldBeIssue === !isTriggered) {
    return "incorrectly_applied";
  }
  return "unsure";
}

export default function render(props) {
  const {
    organisationId,
    document,
    project,
    currentIssueset,
    issue,
    zoom,
    onCorrectnessChange,
  } = props;
  if (
    !(
      props.user.is_admin ||
      new Permissioner(props.user).hasPermission("view-issue-detail")
    ) ||
    !props.isIssueCorrectnessPanelShown ||
    !project ||
    !document ||
    props.clientModeOn ||
    props.hideDocumentRelatedItems
  ) {
    return null;
  }
  const pathname = `/organisation/${organisationId}/issue/${issue.id}`;
  const search = `?open_issue_list=true&project=${project.id}&document=${
    document.id
  }&issueset=${currentIssueset}`;
  return (
    <div key="correctness-panel" style={styles.correctnessPanelContainer}>
      <div style={{display: "flex", alignItems: "center"}}>
        <Link to={{pathname, search}} style={{textDecoration: "none"}}>
          <LinkIcon style={{marginTop: "3px", marginRight: "10px"}} />
        </Link>
        <div
          style={{
            flexGrow: "1",
            fontSize: getZoomedFontSize(14, "checklist", zoom),
          }}
        >
          <RadioButtonGroup
            name="correctness_panel"
            onChange={onCorrectnessChange}
            valueSelected={getIssueCorrectnessRadioValue(issue)}
          >
            <RadioButton
              value="is_correctly_applied"
              label="Is correctly applied"
              style={styles.radioButton}
              labelStyle={styles.radioButtonLabel}
            />
            <RadioButton
              value="incorrectly_presented"
              label="Incorrectly presented"
              style={styles.radioButton}
              labelStyle={styles.radioButtonLabel}
            />
            <RadioButton
              value="incorrectly_applied"
              label="Incorrectly applied"
              style={styles.radioButton}
              labelStyle={styles.radioButtonLabel}
            />
            <RadioButton
              value="unsure"
              label="Unsure"
              style={styles.radioButton}
              labelStyle={styles.radioButtonLabel}
            />
          </RadioButtonGroup>
        </div>
      </div>
    </div>
  );
}

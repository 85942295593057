import createReduxPromiseActionType from "utils/create_redux_promise_action_type";
import createReduxTrackActionType from "utils/create_redux_track_action_type";

const actionTypes = {};

createReduxPromiseActionType(actionTypes, "DOCUMENTS_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENTS_CLEAR");
createReduxPromiseActionType(actionTypes, "DOCUMENTS_EMPTY_INIT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_REFRESH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_DATA_FETCH");
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENT_CLAUSEPARTS_REGEX_MATCHES_FETCH",
);
createReduxPromiseActionType(actionTypes, "DOCUMENT_FILE_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_UPLOAD");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CREATE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_UPLOAD_UPDATE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_DOWNLOAD");
createReduxPromiseActionType(actionTypes, "DOCUMENT_UPLOAD_TO_API");
createReduxPromiseActionType(actionTypes, "DOCUMENT_UPDATE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_PARSE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_DELETE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_EMAIL_REPORT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLEAR");
createReduxPromiseActionType(actionTypes, "DOCUMENT_REPROCESS");
createReduxPromiseActionType(actionTypes, "DOCUMENT_RECLASSIFY_START");
createReduxPromiseActionType(actionTypes, "DOCUMENT_UPDATE_LOAD_STATE");

createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_ADD");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_UPDATE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_DELETE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_REVERT");

createReduxPromiseActionType(actionTypes, "CLAUSES_TOPIC_ADD");
createReduxPromiseActionType(actionTypes, "CLAUSES_TOPIC_REMOVE");
createReduxPromiseActionType(actionTypes, "CLAUSES_TOPIC_CONFIRM");

createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_TOPIC_ADD");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_TOPIC_REMOVE");
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE",
);
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSEPART_UPDATE");
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENT_CLAUSE_UNCONFIRMED_TOPICS_REMOVE",
);
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_TOPIC_CONFIRM");
createReduxPromiseActionType(actionTypes, "DOCUMENT_TOPICS_CONFIRM_ALL");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_TOPICS_REORDER");
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENT_CLAUSE_TOPIC_TOGGLE_EXACT_MATCH",
);
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_SPLIT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_REMOVE_SPLITS");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_CLASSIFICATION");
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENT_CLAUSE_TOPIC_PARAMETER_CLASSIFICATION",
);
createReduxPromiseActionType(actionTypes, "TOPICPARAMETER_BULK_CLASSIFICATION");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSE_QUALIFICATION");

createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_REFETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_REFRESH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_COMPLETE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUE_ADD");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUE_UPDATE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUE_MANUAL_CORRECT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUE_CORRECT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUE_REVERT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_STATE_REVERT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_RESET");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSEPART_ALTER");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSEPART_ADD");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSEPARTS_REMOVE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSEPART_CHANGES_REVERT");
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENT_CLAUSEPART_DELETIONS_REVERT",
);
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENT_CLAUSEPART_ADDITION_REVERT",
);
createReduxPromiseActionType(actionTypes, "DOCUMENT_CLAUSEPART_ADDED_UPDATE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_TEXT_REPLACE");

createReduxPromiseActionType(actionTypes, "CONVERSATION_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUE_GENERATE_PROMPT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_GENERATE_PROMPT");
createReduxPromiseActionType(actionTypes, "PROJECT_GENERATE_PROMPT");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CONVERSATIONS_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_CONVERSATION_ITEMS_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUE_CONVERSATION_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUE_CONVERSATION_CLEAR");
createReduxPromiseActionType(actionTypes, "PROJECT_CONVERSATIONS_FETCH");

createReduxPromiseActionType(actionTypes, "ISSUE_COMPARISON_DATA_FETCH");
createReduxPromiseActionType(actionTypes, "ISSUE_COMPARISON_DATA_CLEAR");

createReduxPromiseActionType(actionTypes, "CLAUSEPART_COMMENT_ADD");
createReduxPromiseActionType(actionTypes, "CLAUSEPART_COMMENT_DELETE");
createReduxPromiseActionType(actionTypes, "CLAUSEPART_COMMENT_UPDATE");

createReduxPromiseActionType(actionTypes, "DOCUMENT_DEFINITION_ADD");
createReduxPromiseActionType(actionTypes, "DOCUMENT_DEFINITION_DELETE");
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENT_DEFINITION_ADDITION_REVERT",
);
createReduxPromiseActionType(
  actionTypes,
  "DOCUMENT_DEFINITION_DELETION_REVERT",
);

createReduxPromiseActionType(actionTypes, "DOCUMENT_HEADING_ADD");
createReduxPromiseActionType(actionTypes, "DOCUMENT_HEADING_ALTER");
createReduxPromiseActionType(actionTypes, "DOCUMENT_HEADING_REMOVE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_HEADING_CHANGES_REVERT");

createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_CONFIRM_ALL");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_UPDATE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_FIND_RUN");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ISSUES_UPDATE_RUN");
createReduxPromiseActionType(actionTypes, "DOCUMENTS_REPROCESS");
createReduxPromiseActionType(actionTypes, "DOCUMENT_EMBEDDINGS_REGENERATE");

createReduxPromiseActionType(actionTypes, "FORM_TEMPLATES_FETCH");
createReduxPromiseActionType(actionTypes, "FORM_TEMPLATES_CLEAR");
createReduxPromiseActionType(actionTypes, "FORM_TEMPLATE_UPLOAD");

createReduxPromiseActionType(actionTypes, "TOPICS_FETCH");
createReduxPromiseActionType(actionTypes, "TOPICS_INFO_FETCH");
createReduxPromiseActionType(actionTypes, "TOPICS_SCORES_FETCH");
createReduxPromiseActionType(actionTypes, "TOPICS_CLEAR");
createReduxPromiseActionType(actionTypes, "TOPICS_UPDATE");

createReduxPromiseActionType(actionTypes, "TOPIC_TAGS_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_TAGS_CLEAR");
createReduxPromiseActionType(actionTypes, "TOPIC_CATEGORIES_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_CATEGORIES_CLEAR");
createReduxPromiseActionType(actionTypes, "TOPIC_CATEGORY_UPDATE");

createReduxPromiseActionType(actionTypes, "TOPIC_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_STATS_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_INFO_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_REGEX_CLASSIFIER_STATS_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_REGEX_CLASSIFIER_STATS_CLEAR");
createReduxPromiseActionType(actionTypes, "TOPIC_PARAMETER_STATS_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_CLEAR");
createReduxPromiseActionType(actionTypes, "TOPIC_UPDATE");
createReduxPromiseActionType(actionTypes, "TOPIC_DELETE");

createReduxPromiseActionType(actionTypes, "TOPICPARAMETER_ADD");
createReduxPromiseActionType(actionTypes, "TOPICPARAMETER_UPDATE");
createReduxPromiseActionType(actionTypes, "TOPICPARAMETER_DELETE");
createReduxPromiseActionType(
  actionTypes,
  "TOPICPARAMETER_ACTUAL_VALUES_UPDATE",
);
createReduxPromiseActionType(
  actionTypes,
  "TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE",
);
createReduxPromiseActionType(actionTypes, "TOPICPARAMETERS_PROCESS");

createReduxPromiseActionType(actionTypes, "ISSUES_FETCH");
createReduxPromiseActionType(actionTypes, "ISSUES_INFO_FETCH");
createReduxPromiseActionType(actionTypes, "ISSUES_CLEAR");
createReduxPromiseActionType(actionTypes, "ISSUE_FETCH");
createReduxPromiseActionType(actionTypes, "ISSUE_INFO_FETCH");
createReduxPromiseActionType(actionTypes, "ISSUE_USAGES_FETCH");
createReduxPromiseActionType(actionTypes, "ISSUE_CLEAR");
createReduxPromiseActionType(actionTypes, "ISSUE_ADD");
createReduxPromiseActionType(actionTypes, "ISSUE_UPDATE");
createReduxPromiseActionType(actionTypes, "ISSUE_REMOVE");
createReduxPromiseActionType(actionTypes, "ISSUES_UPDATE");

createReduxPromiseActionType(actionTypes, "CLASSIFIERS_FETCH");
createReduxPromiseActionType(actionTypes, "TRAINING_MODES_FETCH");
createReduxPromiseActionType(actionTypes, "DATASET_MODES_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_CLASSIFIERS_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_CLASSIFIER_ADD");
createReduxPromiseActionType(actionTypes, "TOPIC_CLASSIFIER_UPDATE");
createReduxPromiseActionType(actionTypes, "TOPIC_CLASSIFIER_LOCK");
createReduxPromiseActionType(actionTypes, "TOPIC_CLASSIFIER_REDEPLOY");
createReduxPromiseActionType(actionTypes, "TOPIC_CLASSIFIER_JOB_INFO_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_CLASSIFIER_JOB_LOGS_FETCH");

createReduxPromiseActionType(actionTypes, "CLASSIFIER_RUN_BATCH_ADD");
createReduxPromiseActionType(actionTypes, "CLASSIFIER_RUN_BATCH_ADD_REGEX");
createReduxPromiseActionType(actionTypes, "CLASSIFIER_RUN_STATE_UPDATE");
createReduxPromiseActionType(actionTypes, "CLASSIFIER_GENERATED");

createReduxTrackActionType(actionTypes, "NAVIGATION_TOGGLE");

createReduxPromiseActionType(actionTypes, "DOCUMENT_TYPES_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_TYPES_CLEAR");

createReduxPromiseActionType(actionTypes, "CONTRACT_TYPES_FETCH");
createReduxPromiseActionType(actionTypes, "CONTRACT_TYPES_FOR_CLIENTS_FETCH");
createReduxPromiseActionType(actionTypes, "CONTRACT_TYPES_CLEAR");
createReduxPromiseActionType(actionTypes, "CONTRACT_TYPE_ADD");
createReduxPromiseActionType(actionTypes, "CONTRACT_TYPE_UPDATE");
createReduxPromiseActionType(actionTypes, "CONTRACT_TYPE_REMOVE");

createReduxPromiseActionType(actionTypes, "DEFINITION_GROUPS_FETCH");
createReduxPromiseActionType(actionTypes, "DEFINITION_GROUPS_CLEAR");
createReduxPromiseActionType(actionTypes, "DEFINITION_GROUP_ADD");
createReduxPromiseActionType(actionTypes, "DEFINITION_GROUP_UPDATE");
createReduxPromiseActionType(actionTypes, "DEFINITION_GROUP_REMOVE");

createReduxPromiseActionType(actionTypes, "ROLES_FETCH");
createReduxPromiseActionType(actionTypes, "ROLES_CLEAR");
createReduxPromiseActionType(actionTypes, "ROLE_ADD");
createReduxPromiseActionType(actionTypes, "ROLE_UPDATE");
createReduxPromiseActionType(actionTypes, "ROLE_REMOVE");
createReduxPromiseActionType(actionTypes, "DOCUMENT_ROLES_REPROCESS");
createReduxPromiseActionType(actionTypes, "DOCUMENTS_ROLES_DELETE");

createReduxPromiseActionType(actionTypes, "ISSUESET_ADD");
createReduxPromiseActionType(actionTypes, "ISSUESET_UPDATE");
createReduxPromiseActionType(actionTypes, "ISSUESET_REMOVE");
createReduxPromiseActionType(actionTypes, "ISSUESET_ADD_TO_ISSUE");

createReduxPromiseActionType(actionTypes, "TOPIC_PARAMETERS_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_PARAMETERS_CLEAR");

createReduxPromiseActionType(actionTypes, "TOPIC_PARAMETERS_BY_TOPIC_ID_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_USES_BY_TOPIC_ID_FETCH");
createReduxPromiseActionType(actionTypes, "TOPIC_USES_SET_EMPTY");

createReduxPromiseActionType(actionTypes, "DOCUMENT_STATE_FETCH");
createReduxPromiseActionType(actionTypes, "DOCUMENT_STATE_CLEAR");

createReduxPromiseActionType(actionTypes, "ISSUE_RESPONSE_ADD");
createReduxPromiseActionType(actionTypes, "ISSUE_RESPONSE_UPDATE");
createReduxPromiseActionType(actionTypes, "ISSUE_RESPONSE_DELETE");

createReduxPromiseActionType(actionTypes, "ISSUE_COMMENT_ADD");
createReduxPromiseActionType(actionTypes, "ISSUE_COMMENT_UPDATE");
createReduxPromiseActionType(actionTypes, "ISSUE_COMMENT_DELETE");

createReduxPromiseActionType(actionTypes, "CLAUSE_TOPIC_LOGS_FETCH");

createReduxPromiseActionType(actionTypes, "TOPIC_LIST_TOPIC_ADD");

createReduxPromiseActionType(actionTypes, "EMBEDDING_TOPICS_FETCH");

createReduxPromiseActionType(actionTypes, "PREREQUISITE_WORKFLOWS_FETCH");
createReduxPromiseActionType(actionTypes, "PREREQUISITE_WORKFLOWS_CLEAR");
createReduxPromiseActionType(actionTypes, "PREREQUISITE_WORKFLOW_RUN");

export default actionTypes;

import * as React from "react";
import createSvgIcon from "./utils/create_svg_icon";

export default createSvgIcon(
  <path
    fill="currentColor"
    d="M 15.921875 5.625 L 15.921875 6.5625 L 9.128906 6.5625 C 8.945312 5.988281 8.757812 5.417969 8.570312 4.851562 C 8.378906 4.289062 8.191406 3.71875 8 3.140625 C 7.808594 3.71875 7.621094 4.289062 7.429688 4.851562 C 7.242188 5.417969 7.054688 5.988281 6.871094 6.5625 L 2.988281 6.5625 C 3.511719 6.945312 4.035156 7.324219 4.550781 7.703125 C 5.066406 8.085938 5.589844 8.464844 6.121094 8.839844 C 5.914062 9.445312 5.710938 10.046875 5.515625 10.644531 C 5.320312 11.242188 5.125 11.84375 4.929688 12.453125 L 8 10.210938 L 8 11.398438 L 3.046875 15 L 4.96875 9.183594 L 0.078125 5.625 L 6.144531 5.625 L 8 0 L 9.855469 5.625 Z M 8.992188 8.4375 L 15.921875 8.4375 L 15.921875 9.375 L 8.992188 9.375 Z M 8.992188 11.25 L 15.921875 11.25 L 15.921875 12.1875 L 8.992188 12.1875 Z M 8.992188 11.25 "
  />,
  "MyClauses",
);

import React from "react";
import _ from "underscore";

import RelatedClausesGroup from "./related_clauses_group";

const styles = {
  noRelatedClauses: {
    fontStyle: "italic",
    textAlign: "center",
    margin: "5px",
    fontSize: "13px",
  },
};
function renderRelatedClauses(
  relatedClauses,
  onClauseClick,
  topicsById,
  isHeaderHidden,
  document,
  renderStatus,
) {
  if (Object.keys(relatedClauses).length === 0) {
    return <div style={styles.noRelatedClauses}>No related clauses found</div>;
  }
  const result = [];
  _.forEach(relatedClauses, (relatedClausesGroup, groupName) => {
    result.push(
      <RelatedClausesGroup
        key={groupName}
        isOpen={Object.keys(relatedClauses).length === 1}
        relatedClausesGroup={relatedClausesGroup}
        groupName={groupName}
        onClauseClick={onClauseClick}
        topicsById={topicsById}
        isHeaderHidden={isHeaderHidden}
        document={document}
        renderStatus={renderStatus}
      />,
    );
  });
  return result;
}

export default renderRelatedClauses;

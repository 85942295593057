import Describer from "../../../utils/issues/reason/describer";

function reason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);
  const parameterText =
    issue.rules.parameter_ids.length === 0
      ? "s"
      : ` ${describer.listParameters()}`;
  return [
    `Topic '${
      describer.topicName
    }' is present without parameter${parameterText}`,
    showClause && describer.clauseReferences
      ? ` in clause ${describer.clauseReferences}`
      : "",
  ].join("");
}

export default reason;

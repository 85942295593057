import EmailIcon from "@material-ui/icons/Email";
import TrafficIcon from "@material-ui/icons/Traffic";

export default {
  none: {
    label: "None",
  },
  email: {
    label: "Email Report",
    icon: EmailIcon,
  },
  rag_report: {
    label: "RAG Report",
    icon: TrafficIcon,
  },
  notice_of_assignment: {
    icon: EmailIcon,
  },
  transfer_certificate: {
    icon: EmailIcon,
  },
  assign_consent: {
    icon: EmailIcon,
  },
  transfer_consent: {
    icon: EmailIcon,
  },
  amendment: {
    icon: EmailIcon,
  },
};

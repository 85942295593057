import convertContentsToListItems from "./convert_contents_to_list_items";

function convertContentsToText(contents, useTabs = false) {
  let fullText = "";
  const listItems = convertContentsToListItems(contents);
  listItems.forEach(listItem => {
    if (typeof listItem === "string" || listItem instanceof String) {
      fullText += `${listItem}\n`;
    } else if (typeof listItem === "object") {
      const {text, indent, reference} = listItem;
      const tabs = useTabs ? "\t".repeat((indent || 0) + 1) : " ";
      if (reference[0].toLowerCase().match(/[a-z]/i)) {
        fullText += `${tabs}(${reference}) ${text}\n`;
      } else {
        fullText += `${tabs}${reference}. ${text}\n`;
      }
    }
  });
  if (useTabs) {
    return fullText;
  }
  return fullText
    .replace("\n", " ")
    .replace(/\s+/g, " ")
    .trim();
}

export default convertContentsToText;

import _ from "underscore";
export default function stripTextBeforeDefinitions(text, definitions) {
  const longestTerm = _.chain(definitions)
    .map(({term, meaning}) => {
      const termIndex = text.indexOf(term);
      const meaningSentences = meaning.split(/\n|[.;] /);
      const longMeaningSentences =
        meaningSentences.length === 1
          ? meaningSentences
          : meaningSentences.filter(sentence => sentence.length > 15);
      const meaningSentence = longMeaningSentences.find(
        sentence => text.indexOf(sentence) > 0,
      );
      const meaningIndex = text.indexOf(meaningSentence);
      if (meaningIndex < termIndex) {
        return null;
      }
      return termIndex >= 0 && meaningSentence ? term : null;
    })
    .filter(item => item)
    .sortBy(term => -term.length)
    .first()
    .value();
  if (longestTerm) {
    return text.substring(text.indexOf(longestTerm));
  }
  return text;
}

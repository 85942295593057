export function romanToInt(str) {
  if (str === null) {
    return -1;
  }
  const fStr = str.toUpperCase();
  let num = charToInt(fStr.charAt(0));

  for (let index = 1; index < fStr.length; index += 1) {
    const curr = charToInt(fStr.charAt(index));
    const pre = charToInt(fStr.charAt(index - 1));
    if (curr <= pre) {
      num += curr;
    } else {
      num = num - pre * 2 + curr;
    }
  }

  return num;
}

function charToInt(char) {
  switch (char) {
    case "I":
      return 1;
    case "V":
      return 5;
    case "X":
      return 10;
    case "L":
      return 50;
    case "C":
      return 100;
    case "D":
      return 500;
    case "M":
      return 1000;
    default:
      return -1;
  }
}

/* eslint-disable id-length */
export function intToRoman(num) {
  let number = num;
  const romans = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };
  let str = "";

  for (const index of Object.keys(romans)) {
    const part = Math.floor(number / romans[index]);
    number -= part * romans[index];
    str += index.repeat(part);
  }

  return str;
}
/* eslint-enable id-length */

export default {romanToInt, intToRoman};

import sortRagReportTableItems from "./sort_rag_report_table_items";

function filterRagReportTable(rows, filterValues) {
  const filteredRows = rows.filter(item =>
    filterRagReportTableItems(item, filterValues),
  );
  if (filterValues.sort !== "Default") {
    return sortRagReportTableItems(filteredRows, filterValues.sort);
  } else {
    return (
      filteredRows
        // eslint-disable-next-line array-callback-return
        .map((item, index) => {
          if (!item.issue && filteredRows[index + 1]?.issue) {
            return item;
          } else if (item.issue) {
            return item;
          }
        })
        .filter(item => item)
    );
  }
}

function filterRagReportTableItems(row, filter) {
  if (!row.issue) {
    return true;
  }
  if (filter.issue || filter.warning || filter.notIssues || filter.guidance) {
    if (filter.issue !== true) {
      if (row.issue_status === "red") {
        return false;
      }
    }
    if (filter.warning !== true) {
      if (row.issue_status === "amber") {
        return false;
      }
    }
    if (filter.guidance !== true) {
      if (row.issue_status === "grey") {
        return false;
      }
    }
    if (filter.notIssues !== true) {
      if (row.issue_status === "green") {
        return false;
      }
    }
    if (row.issue_status === undefined) {
      return false;
    }
  }
  if (filter.reviewState) {
    if (filter.reviewState !== "unread") {
      if (row.review_state === 1) {
        return false;
      }
    }
    if (filter.reviewState !== "read") {
      if (row.review_state !== 1) {
        return false;
      }
    }
    if (row.review_state === undefined) {
      return false;
    }
  }
  if (filter.escalatedReviewState) {
    if (filter.escalatedReviewState !== "needReview") {
      if (row.review_state === 3) {
        return false;
      }
    }
    if (filter.escalatedReviewState !== "dontNeedReview") {
      if (row.review_state !== 3) {
        return false;
      }
    }
    if (row.review_state === undefined) {
      return false;
    }
  }
  return true;
}

export default filterRagReportTable;

import React from "react";

import LocalOfferIcon from "@material-ui/icons/LocalOffer";

import FlatButton from "material-ui/FlatButton";

import NavItem from "routes/logged_in/components/sidebar/nav_item";
import SidebarPanel from "routes/logged_in/components/sidebar/panel";
import TopicList from "./topic_list";

const styles = {
  navigationMenu: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
  },
  navigationHeading: {
    height: "2rem",
    zIndex: 0,
    backgroundColor: "#f3f3f3",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  showAllButton: {
    height: "100%",
    lineHeight: "inherit",
    borderRadius: "0px",
  },
};

export default class TopicsItem extends React.Component {
  render() {
    return (
      <NavItem
        isCollapsed={true}
        icon={<LocalOfferIcon />}
        collapsedTitle={"Topics"}
        panelChildren={
          <SidebarPanel headerText="Topics">
            <div style={styles.navigationMenu}>
              <div style={styles.navigationHeading}>
                <FlatButton
                  label={
                    this.props.areAllTopicsShown
                      ? "Show Topics in Use"
                      : "Show All Topics"
                  }
                  onClick={this.props.onShowAllTopicsClick}
                  style={styles.showAllButton}
                />
              </div>
              <TopicList
                topics={this.props.documentTopics}
                isTopicSelected={topic => this.props.selectedTopics[topic.id]}
                documentClauses={this.props.documentClauses}
                topicsById={this.props.topicsById}
                onClauseClick={this.props.onClauseClick}
              />
            </div>
          </SidebarPanel>
        }
      />
    );
  }
}

TopicsItem.Icon = LocalOfferIcon;

import React from "react";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as colors from "material-ui/styles/colors";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  unitContainer: {
    width: "10rem",
    margin: "0 0.5rem",
  },
  valueContainer: {
    width: "10rem",
    marginRight: "0.5rem",
    marginLeft: "4px",
  },
  monthSelectorLabel: {
    left: "10px",
  },
  deleteIcon: {
    cursor: "pointer",
    color: colors.grey700,
    flexShrink: "0",
  },
};

class ValueEditor extends React.Component {
  render() {
    const dateInArray = this.props.value.value.split("-");
    return (
      <div style={styles.container}>
        <TextField
          floatingLabelText="Day"
          type="number"
          value={
            parseInt(dateInArray[2], 10) === 0
              ? ""
              : parseInt(dateInArray[2], 10)
          }
          onChange={this.onItemChange(2)}
          style={styles.valueContainer}
          min={1}
          max={31}
        />
        <SelectField
          floatingLabelText="Month"
          value={parseInt(dateInArray[1], 10)}
          onChange={this.onItemChange(1)}
          autoWidth={true}
          style={styles.unitContainer}
          floatingLabelStyle={styles.monthSelectorLabel}
        >
          {months.map((month, index) => (
            <MenuItem key={month} value={index + 1} primaryText={month} />
          ))}
        </SelectField>
        <TextField
          floatingLabelText="Year"
          type="number"
          value={
            parseInt(dateInArray[0], 10) === 0
              ? ""
              : parseInt(dateInArray[0], 10)
          }
          onChange={this.onItemChange(0)}
          style={styles.valueContainer}
        />
        <DeleteIcon
          onMouseDown={this.onDeleteValue}
          style={styles.deleteIcon}
        />
      </div>
    );
  }

  onItemChange = index => (e, i, value) => {
    let targetValue = index !== 1 ? parseInt(e.target.value, 10) : value;
    let finalValue;
    switch (index) {
      case 0: {
        if (targetValue > 9999) {
          targetValue = new Date().getFullYear();
        }
        const targetValueString = String(targetValue);
        finalValue = !isNaN(targetValue)
          ? `${"0".repeat(4 - targetValueString.length)}${targetValue}`
          : "0000";
        break;
      }
      case 1:
        finalValue = targetValue < 10 ? `0${targetValue}` : `${targetValue}`;
        break;
      case 2:
        if (targetValue > 31) {
          targetValue = 31;
        }
        finalValue = targetValue < 10 ? `0${targetValue}` : `${targetValue}`;
        if (isNaN(targetValue)) {
          finalValue = "00";
        }
        break;
      default:
        break;
    }
    const dateInArray = this.props.value.value.split("-");
    dateInArray[index] = finalValue;
    this.props.onUpdateValue(
      {
        ...this.props.value,
        value: dateInArray.join("-"),
      },
      this.props.valueIndex,
    );
  };

  onDeleteValue = this.props.onDeleteValue(this.props.valueIndex);
}

export default ValueEditor;

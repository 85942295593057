export default function getIssueValue(issue, issueset, key) {
  const overrides = issue.override_values[issueset.master_id];
  if (overrides) {
    const clientOverride = overrides[issue.client_id];
    if (
      clientOverride &&
      Object.prototype.hasOwnProperty.call(clientOverride, key)
    ) {
      return clientOverride[key];
    }
    const templateOverride = overrides.master;
    if (
      templateOverride &&
      Object.prototype.hasOwnProperty.call(templateOverride, key)
    ) {
      return templateOverride[key];
    }
  }
  return issue[key];
}

import globalStore from "common/utils/global_store";

export default function clauseMatchesPriority(clause, priority, issueId) {
  switch (priority.value.type) {
    case "topic": {
      return (clause.baseTopics ?? []).find(
        topic => topic.topic_id === priority.value.topic_id,
      );
    }
    case "parameter": {
      const topic = (clause.baseTopics ?? []).find(
        topic => topic.topic_id === priority.value.topic_id,
      );
      if (topic) {
        return topic.topicparameters.find(
          param => param.topicparameter_id === priority.value.parameter_id,
        );
      }
      return false;
    }
    case "definition_group": {
      const definitions = {
        ...(globalStore?.checklistDefinitions?.[issueId] ?? []),
        ...(globalStore?.commonDefinitions?.[issueId] ?? []),
      };
      const group =
        definitions[`group_${priority.value.definition_group}`] ?? [];
      return Boolean(group.find(id => id === clause.id));
    }
    default:
      return false;
  }
}

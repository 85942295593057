function zip(...args) {
  const lists = [];
  let longestList = null;
  args.forEach(argList => {
    if (Array.isArray(argList)) {
      lists.push(argList);
      if (!longestList) {
        longestList = argList;
      } else if (longestList.length < argList.length) {
        longestList = argList;
      }
    }
  });

  const result = [];
  for (let itemIndex = 0; itemIndex < longestList.length; itemIndex += 1) {
    const group = [];
    lists.forEach(list => {
      group.push(list[itemIndex]);
    });
    result.push(group);
  }

  return result;
}

export default zip;

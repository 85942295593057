import _ from "lodash";

const getFiltersTitles = (state, filters) => {
  const filterValues = filters ?? {};
  const filterTitles = item => {
    if (
      item.value === Object.values(filterValues).find(val => val === item.value)
    ) {
      return item.title;
    } else if (item.value === "issue" && filterValues.issue) {
      return item.title;
    } else if (item.value === "warning" && filterValues.warning) {
      return item.title;
    } else if (item.value === "notIssues" && filterValues.notIssues) {
      return item.title;
    } else if (item.value === "guidance" && filterValues.guidance) {
      return item.title;
    } else if (item.value === "actioned" && filterValues.actioned) {
      return item.title;
    } else if (item.value === "ignored" && filterValues.ignored) {
      return item.title;
    }
  };
  return _.map(state.filter(filterTitles), item => item.title);
};

export default getFiltersTitles;

const commonStyles = {
  checkbox: {
    container: {},
    iconStyles: {
      margin: 0,
      height: 32,
      width: 32,
      color: "#42A5F5",
    },
    labelStyles: {
      marginLeft: 4,
      marginTop: 0,
      wordBreak: "break-all",
    },
  },
};

export default commonStyles;

import globalStore from "common/utils/global_store";
import _ from "lodash";
import setNestedObjects from "common/utils/setNestedObjects";

function setVariable(varName, varValue, options) {
  if (globalStore.setting_common_definitions) {
    const issue = _.get(options, "data.root.issue");
    if (issue) {
      addIssueCommonDefinition(issue.id, varName, varValue);
    }
  }
  if (globalStore.setting_checklist_definitions) {
    const issueset = _.get(options, "data.root.issueset");
    if (issueset) {
      addIssuesetCommonDefinition(issueset.id, varName, varValue);
    }
  }
  options.data.root[varName] = varValue;
}

function addIssueCommonDefinition(issueId, varName, varValue) {
  const path = `commonDefinitions.${issueId}`;
  if (!_.has(globalStore, path)) {
    setNestedObjects(globalStore, path);
  }
  const currentValue = _.get(globalStore, path);
  const newValue = {
    ...currentValue,
    [varName]: varValue,
  };
  _.set(globalStore, path, newValue);
}

function addIssuesetCommonDefinition(issuesetId, varName, varValue) {
  const path = `checklistDefinitions.${issuesetId}`;
  if (!_.has(globalStore, path)) {
    setNestedObjects(globalStore, path);
  }
  const currentValue = _.get(globalStore, path);
  const newValue = {
    ...currentValue,
    [varName]: varValue,
  };
  _.set(globalStore, path, newValue);
}

export default setVariable;

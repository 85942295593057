import {
  DeletedTextRun,
  Document,
  InsertedTextRun,
  PageOrientation,
  Paragraph,
  TextRun,
  Table,
  TableRow,
  TableCell,
  WidthType,
  AlignmentType,
  VerticalAlign,
} from "docx";
import moment from "moment";
import remarkParse from "remark-parse";
import remarkGfm from "remark-gfm";
import {unified} from "unified";
import _ from "underscore";

import getTemplateFormat from "utils/get_template_format";
import capitalize from "utils/capitalize";

import parseQuillHTML from "common_components/gui_text_editor/utils/parse_quill_html";

const colors = {
  amber: "ffa000",
  red: "ff0000",
  green: "00ff00",
  blue: "039be5",
  grey: "666666",
  white: "#ffffff",
};

function unescape(text) {
  return _.unescape(text).replace(/&#x2F;/g, "/");
}

/**
 * Decrements the start and end line positions by 1 to make them 0-indexed.
 * @param {*} syntaxTree a syntax tree where child node line positions are 1-indexed
 * @returns {*} a syntaxTree where line positions are 0-indexed.
 */
const zeroIndexNodePositions = ({children, ...restOfTreeRoot}) => {
  return {
    children: children.map(({position: {start, end}, ...restOfChild}) => {
      return {
        position: {
          start: {
            line: start.line - 1,
          },
          end: {
            line: end.line - 1,
          },
        },
        ...restOfChild,
      };
    }),
    ...restOfTreeRoot,
  };
};

/**
 * The positions of nodes in the syntax tree do not contrain whitespace. This function
 * redifines non-table nodes to include whitespace.
 * @param {string[]} itemTextLines An array of lines of text in the item.
 * @param {*} itemSyntaxTree A syntax tree corresponding to the text.
 * @returns {*} a list of nodes whose position spans the entire text, including whitespace lines
 */
const makeNodesWithWhitespace = (itemTextLines, itemSyntaxTree) => {
  const tableNodes = itemSyntaxTree.children.filter(
    node => node.type === "table",
  );

  if (tableNodes.length === 0) {
    return [
      {
        type: "paragraph",
        position: {start: {line: 0}, end: {line: itemTextLines.length - 1}},
      },
    ];
  }

  // By using only table nodes, we can fill in the gaps between them by creating new paragraph nodess
  const extendedNodes = tableNodes.reduce(
    (result, currentTableNode, index, tableNodes) => {
      const currentTableStartLine = currentTableNode.position.start.line;

      // Include any lines before the first table
      if (index === 0 && currentTableStartLine > 0) {
        result.push({
          type: "paragraph",
          position: {start: {line: 0}, end: {line: currentTableStartLine - 1}},
        });
      }

      // Include this table
      result.push(currentTableNode);

      const currentTableEndLine = currentTableNode.position.end.line;

      // Include any lines up to the next table
      if (index < tableNodes.length - 1) {
        const nextTableStartLine = tableNodes[index + 1].position.start.line;

        if (currentTableEndLine + 1 < nextTableStartLine) {
          result.push({
            type: "paragraph",
            position: {
              start: {line: currentTableEndLine + 1},
              end: {line: nextTableStartLine - 1},
            },
          });
        }
      }

      // Include any lines from the last table up to the end
      if (index === tableNodes.length - 1) {
        if (currentTableEndLine < itemTextLines.length - 1) {
          result.push({
            type: "paragraph",
            position: {
              start: {line: currentTableEndLine + 1},
              end: {line: itemTextLines.length - 1},
            },
          });
        }
      }

      return result;
    },
    [],
  );

  return extendedNodes;
};

/**
 * Given a table node from a syntax tree, returns a corresponding docx object
 * @param {*} tableNode a node object corresponding to the parsed markdown
 * @returns {Table} a docx table object
 */
const constructTable = tableNode => {
  return new Table({
    rows: tableNode.children.map((tableRow, rowIndex) => {
      return new TableRow({
        children: tableRow.children.map(tableCell => {
          return new TableCell({
            children: tableCell.children.map(
              ({value: text}) =>
                new Paragraph({
                  children: [new TextRun({text, bold: rowIndex === 0})],
                }),
            ),
          });
        }),
      });
    }),
    width: {size: 100, type: WidthType.PERCENTAGE},
  });
};

/**
 * Given some text that contains markdown tables, such as from an LLM, returns a list of docx
 * paragraphs corresponding to the text. Supports converting markdown tables to docx tables.
 * @param {string} itemText the text from an LLM to construct docx paragraphs with
 * @param {*} cellItemChild child elements of this cell
 * @param {boolean} useDoubleAsterisk whether the text in the cell uses double asterisks for formatting
 * @returns {*} an array of docx paragraphs corresponding to the supplied text
 */
const constructLLMParagraphs = (itemText, cellItemChild, useDoubleAsterisk) => {
  const itemTextLines = itemText.split("\n");
  const itemSyntaxTree = zeroIndexNodePositions(
    unified().use(remarkParse).use(remarkGfm).parse(itemText),
  );

  const itemSyntaxTreeWithWhitespace = makeNodesWithWhitespace(
    itemTextLines,
    itemSyntaxTree,
  );

  return itemSyntaxTreeWithWhitespace
    .map(node => {
      if (node.type === "table") {
        return constructTable(node);
      }

      const {
        start: {line: startLine},
        end: {line: endLine},
      } = node.position;
      const itemTextSlice = itemTextLines.slice(startLine, endLine + 1);

      return constructParagraphs(
        itemTextSlice,
        cellItemChild,
        useDoubleAsterisk,
      );
    })
    .flat();
};

const constructParagraphs = (
  itemTextLines,
  cellItemChild,
  useDoubleAsterisk,
) => {
  const color = getCellItemChildColor(cellItemChild);
  return itemTextLines.reduce((result, itemTextLine) => {
    const isListItem = itemTextLine.trim().startsWith("-");
    const textStr = isListItem
      ? itemTextLine.replace(/^ *- */, "")
      : itemTextLine;
    const textItems = getTemplateFormat(textStr, useDoubleAsterisk);
    const paragraphChildren = textItems.map(item => {
      const RunType = item.isAdded
        ? InsertedTextRun
        : item.isDeleted
        ? DeletedTextRun
        : TextRun;
      return new RunType({
        text: item.text,
        color: color ? color : item.isTick ? colors.blue : undefined,
        bold: cellItemChild.bold ? true : item.isAsterisk,
      });
    });
    const paragraph = new Paragraph({
      bullet: isListItem ? {level: 0} : undefined,
      children: paragraphChildren,
      ...(cellItemChild.type === "circle"
        ? {
            alignment: AlignmentType.CENTER,
          }
        : {}),
      ...(cellItemChild.spacing ? {spacing: cellItemChild.spacing} : {}),
    });
    result.push(paragraph);
    return result;
  }, []);
};

function constructReportDocxDocument(
  ragHeaderRowItems,
  ragRows,
  reportLabel,
  reportSettings,
) {
  const doc = new Document({
    sections: [
      {
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: reportLabel,
                bold: true,
              }),
              new TextRun({
                text: `GENERATED: ${moment().format("YYYY-MM-DD HH:mm:ss")}`,
                size: 18,
                color: "757575",
                break: true,
              }),
            ],
            spacing: {after: 300},
          }),
          ...createSummary(reportSettings),
          constructRagReportDocxTable(ragHeaderRowItems, ragRows),
        ],
      },
    ],
  });
  return doc;
}

function createSummary(reportSettings) {
  return _.flatten(
    (reportSettings?.summary_fields ?? []).map(summaryField =>
      renderSummaryField(
        summaryField,
        findSummaryFieldData(summaryField, reportSettings.summary_text),
      ),
    ),
  );
}

function findSummaryFieldData(summaryField, summaryTexts) {
  return summaryTexts.find(textObject => summaryField.id === textObject.id);
}

function renderSummaryField(summaryField, summaryText) {
  const lines = summaryText?.text?.split("\n") ?? [];

  return [
    new Paragraph({
      children: [
        new TextRun({text: `${summaryField.summary_title}: `, bold: true}),
        new TextRun({text: lines[0]}),
      ],
    }),
    ..._.rest(lines || []).map(
      line =>
        new Paragraph({
          children: [new TextRun({text: line})],
        }),
    ),
    new Paragraph({children: []}),
  ];
}

function constructRagReportDocxTable(headerRowCells, baseRows) {
  const headerRow = headerRowCells
    ? new TableRow({
        tableHeader: true,
        children: headerRowCells
          .filter(headerCell => headerCell.type !== "review_state")
          .map(
            headerCell =>
              new TableCell({
                children: [
                  new Paragraph({
                    text: headerCell.title,
                    alignment: AlignmentType.CENTER,
                  }),
                ],
                margins: getCellMargins(),
              }),
          ),
      })
    : null;

  const tableRows = baseRows.map(
    baseRow =>
      new TableRow({
        children: baseRow.children
          .filter(item => item.fieldName !== "review_state")
          .map(cellItem => {
            const firstChild = cellItem.children[0];
            let cellItemChildren;

            if (cellItem?.additional_item) {
              if (cellItem.children[0].type === "additional_status") {
                cellItem.children[0].color =
                  cellItem.children[0].value === "Blank"
                    ? "white"
                    : cellItem.children[0].value;
              }
              const text = cellItem.children[0].value;
              cellItem.children[0].value = text.replace(/<p>|<\/p>/g, "");
            }

            if (cellItem.isEditableInGui && cellItem.correctedValue) {
              // convert quill HTML text to docx js format
              const parsedHtmlTokens = parseQuillHTML(cellItem.correctedValue);
              cellItemChildren = parsedHtmlTokens.map(token => {
                const paragraphChildren = token.children.map(
                  item =>
                    new TextRun({
                      text: unescape(item.value),
                      bold: item.bold,
                      italics: item.italics,
                    }),
                );
                return new Paragraph({
                  children: paragraphChildren,
                  ...(token.indent ? {indent: {left: 250 * token.indent}} : {}),
                });
              });
            } else {
              cellItemChildren = cellItem.children
                .filter(item => item.type !== "review_state_circle")
                .reduce((cellObjectChildren, cellItemChild) => {
                  let itemText = getCellItemChildText(cellItemChild);
                  if (cellItemChild.children) {
                    const childrenStr = cellItemChild.children
                      .map(child => child.value)
                      .join(", ");
                    itemText = `${itemText}${
                      itemText ? ": " : ""
                    }${childrenStr}`;
                  }

                  const paragraphs = itemText.includes("|")
                    ? constructLLMParagraphs(itemText, cellItemChild)
                    : constructParagraphs(
                        itemText.split("/n"),
                        cellItemChild,
                        false,
                      );

                  return cellObjectChildren.concat(paragraphs);
                }, []);
            }

            const cellObject = {
              children: cellItemChildren,
              ...(firstChild &&
              (firstChild.type === "circle" ||
                firstChild.type === "additional_status")
                ? {shading: {fill: getCircleItemColor(firstChild)}}
                : {}),
              ...(cellItem.isManuallyCorrected
                ? {shading: {fill: "#fcffd9"}}
                : {}),
              margins: getCellMargins(),
            };
            if (
              firstChild &&
              (firstChild.type === "circle" ||
                firstChild.type === "additional_status")
            ) {
              cellObject.verticalAlign = VerticalAlign.CENTER;
            }
            return new TableCell(cellObject);
          }),
      }),
  );

  const rows = headerRow ? [headerRow, ...tableRows] : [...tableRows];
  const columnWidths = headerRow
    ? headerRowCells.map(rowCell => rowCell.width.replace(/%$/g, "pc"))
    : rows.map(() => 100 / rows.length);
  return new Table({
    rows,
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    columnWidths,
  });
}

function getCellItemChildText(cellItemChild) {
  if (!cellItemChild) {
    return "";
  }
  switch (cellItemChild.type) {
    case "paragraph":
      return cellItemChild.value === "Blank" ? "" : cellItemChild.value;
    case "circle":
      return cellItemChild.value
        ? cellItemChild.color
          ? capitalize(cellItemChild.color)
          : "Red"
        : "Green";
    case "additional_status":
      return cellItemChild.color;
    default:
      return "";
  }
}

function getCellItemChildColor(cellItemChild) {
  if (
    cellItemChild &&
    (cellItemChild.type === "circle" ||
      cellItemChild.type === "additional_status")
  ) {
    return getCircleItemColor(cellItemChild);
  }
}

function getCircleItemColor(cellItem) {
  return cellItem.color
    ? colors[cellItem.color]
    : cellItem.value
    ? colors.red
    : colors.green;
}

function getCellMargins() {
  return {
    top: 100,
    bottom: 100,
    left: 100,
    right: 100,
  };
}

export default constructReportDocxDocument;

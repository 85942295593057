import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function documentReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.DOCUMENT_FETCH.SUCCESS:
      return state === UNINITIALISED
        ? {...action.payload, partyUpdateCount: 0}
        : {...state, ...(action.payload || {}), partyUpdateCount: 0};
    case ActionTypes.DOCUMENT_UPDATE.SUCCESS: {
      const {payload} = action;
      if (state.id === payload.id && payload.last_edited > state.last_edited) {
        let resultState;
        if (
          payload.meta_data_feedback !== undefined &&
          Object.keys(payload.meta_data_feedback).length !== 0
        ) {
          const {name} = payload.meta_data_feedback;
          resultState = {
            ...state,
            last_edited: payload.last_edited,
            meta_data_feedback: {
              ...state.meta_data_feedback,
              [name]: {
                ...(state.meta_data_feedback[name]
                  ? state.meta_data_feedback[name]
                  : {}),
                ...payload.meta_data_feedback.updates,
              },
            },
          };
        } else if (
          payload.output_text !== undefined &&
          Object.keys(payload.output_text).length !== 0
        ) {
          resultState = {
            ...state,
            last_edited: payload.last_edited,
            output_text: {
              ...state.output_text,
              ...payload.output_text,
            },
          };
        } else {
          resultState = {
            ...state,
            ...payload,
            partyUpdateCount: _.isEqual(payload.parties, state.parties)
              ? state.partyUpdateCount
              : state.partyUpdateCount + 1,
          };
        }
        return resultState;
      }
      return state;
    }
    case ActionTypes.DOCUMENT_ISSUE_MANUAL_CORRECT.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      if (
        payload &&
        payload.document_id &&
        state.id === payload.document_id &&
        payload.document_last_edited
      ) {
        return {...state, last_edited: payload.document_last_edited};
      }
      return state;
    }
    case ActionTypes.ISSUESET_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        issuesets: state.issuesets.filter(id => id !== action.payload.id),
      };
    }
    case ActionTypes.DOCUMENT_DATA_FETCH.SUCCESS:
      return {...state, documentData: action.payload};
    case ActionTypes.DOCUMENT_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.DOCUMENT_CLAUSE_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_ISSUE_CORRECT.SUCCESS:
      return {...state, last_edited: action.payload.document_last_edited};
    case ActionTypes.DOCUMENT_STATE_REVERT.SUCCESS: {
      if (state.id === action.payload.id) {
        return {
          ...state,
          clauses: action.payload.clauses,
          last_edited: action.payload.document_last_edited,
          ...(action.payload.document_updates
            ? action.payload.document_updates
            : {}),
        };
      }
      return state;
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.CLAUSEPART_COMMENT_ADD.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        comments: [...(state.comments || []), action.payload],
      };
    }
    case ActionTypes.CLAUSEPART_COMMENT_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const updatedComment = action.payload;
      return {
        ...state,
        comments: (state.comments || []).map(comment => {
          if (comment.id === updatedComment.id) {
            return updatedComment;
          }
          return comment;
        }),
      };
    }
    case ActionTypes.CLAUSEPART_COMMENT_DELETE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        comments: (state.comments || []).filter(
          ({id}) => id !== action.payload.id,
        ),
      };
    }
    case ActionTypes.DOCUMENT_FILE_FETCH.SUCCESS: {
      if (state === UNINITIALISED) {
        return action.payload;
      }
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case ActionTypes.DOCUMENT_ROLES_REPROCESS.SUCCESS: {
      if (state === UNINITIALISED || !action.payload) {
        return state;
      }
      return {
        ...state,
        parties: action.payload.parties,
        partyUpdateCount: state.partyUpdateCount + 1,
      };
    }
    case ActionTypes.DOCUMENT_ISSUES_REFRESH.SUCCESS: {
      if (
        state === UNINITIALISED ||
        !action.payload ||
        action.payload.document_id !== state.id
      ) {
        return state;
      }
      return {
        ...state,
        issues_last_processed: action.payload.issues_last_processed,
      };
    }
    case ActionTypes.DOCUMENT_ISSUE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionTypes.DOCUMENT_CONVERSATIONS_FETCH.SUCCESS: {
      return {
        ...state,
        conversations: action.payload.conversations,
      };
    }
    case ActionTypes.DOCUMENT_CONVERSATION_ITEMS_FETCH.SUCCESS:
    case ActionTypes.DOCUMENT_GENERATE_PROMPT.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      if (state.document_id !== payload.document_id) {
        return state;
      }
      const isGenerated =
        action.type === ActionTypes.DOCUMENT_GENERATE_PROMPT.SUCCESS;
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [payload.conversation_type]: {
            ...state.conversations?.[payload.conversation_type],
            [payload.conversation.id]: {
              ...state.conversations?.[payload.conversation_type]?.[
                payload.conversation.id
              ],
              id: payload.conversation.id,
              ...(isGenerated ? {isNew: true} : {}),
              ...(payload.conversation.first_item_id
                ? {first_item_id: payload.conversation.first_item_id}
                : {}),
              items: payload.conversation.items,
            },
          },
        },
      };
    }
    default:
      return state;
  }
}

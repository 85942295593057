function constructFormData(clauses) {
  return clauses.map(clause => {
    return processClause(clause.nodes);
  });
}

function processClause(nodes) {
  if (nodes.text) {
    return getResultItem(nodes.text);
  }
  if (nodes.clauseNodes) {
    const foundList = nodes.clauseNodes.find(
      cn => cn.type === "ClausePartNumberedList",
    );
    if (foundList) {
      return getListResultItem(nodes.clauseNodes[0], foundList);
    }
  }
}

function getResultItem(rawText) {
  let text = rawText;
  if (rawText.match(/[A-Z]{2,2}=/)) {
    text = rawText.substr(3);
  }

  if (
    text
      .toLowerCase()
      .trim()
      .indexOf("contract for the sale and purchase of land") === 0
  ) {
    return {
      name: "Edition",
      value: text,
    };
  }

  const colonPosition = text.indexOf(":");
  return {
    name: text.substring(0, colonPosition),
    value: text
      .substring(colonPosition + 1)
      .trim()
      .replace(/\.$/, ""),
  };
}

function getListResultItem(baseClause, list) {
  const values = list.clauseNodes
    .map(node => {
      if (node.text) {
        return getResultItem(node.text);
      }
      return null;
    })
    .filter(value => value);
  return {
    ...(baseClause.text
      ? getResultItem(baseClause.text)
      : {name: "Menu item", value: ""}),
    values,
  };
}

export default constructFormData;

import React from "react";
import {getUpdatedManualCorrections} from "../../../utils/manual_corrections_utils";
import ItemContainer from "../helper_components/item_container";
import ClausesPanel from "./clauses_panel";

function ApplicableClauses(props) {
  const {
    issue,
    selectedReport,
    onApplicableClausesOpen,
    applicableClausesOpen,
    headerStyles,
  } = props;

  const onManualCorrectDocumentIssue = (
    updates,
    shouldMergeUpdateObject,
    prevIssue,
  ) => {
    const documentIssue = prevIssue || issue;

    if (
      documentIssue.groupName === "Unrecognised Clauses" ||
      documentIssue.correction
    ) {
      return;
    }
    const newManualCorrections = getUpdatedManualCorrections(
      documentIssue.manual_corrections,
      selectedReport,
      null,
      null,
      updates,
    );
    if (newManualCorrections) {
      if (shouldMergeUpdateObject) {
        const updateObject = {[selectedReport]: {...updates}};
        const options = {
          merge_update_object: true,
          resetSelectedApplicableClauses: Boolean(
            updates.additional_report_clauses,
          ),
        };
        props.correctDocumentIssueManually(
          documentIssue,
          updateObject,
          options,
        );
      } else {
        props.correctDocumentIssueManually(documentIssue, {
          manual_corrections: newManualCorrections,
        });
      }
    }
  };

  return (
    <ItemContainer
      title="Applicable Clauses"
      expandable={true}
      onExpandMenu={onApplicableClausesOpen}
      isMenuExpanded={applicableClausesOpen}
      helpText="This section shows clauses in the document with direct relevance"
      headerStyles={headerStyles}
      containerStyles={{
        flex: "1 0 auto",
      }}
    >
      <ClausesPanel
        {...props}
        onManualCorrectDocumentIssue={onManualCorrectDocumentIssue}
      />
    </ItemContainer>
  );
}

export default ApplicableClauses;

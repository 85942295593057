import Describer from "../../../utils/issues/reason/describer";

function reason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);
  return (
    [
      `Topic '${describer.topicName}' does not have a value`,
      `for '${describer.parameterName}' parameter`,
    ].join(" ") + (showClause ? ` in clause ${describer.clauseReference}` : "")
  );
}

export default reason;

export default function(text, qualifiers, totalOffset) {
  if (qualifiers && qualifiers.length) {
    const offset = totalOffset ? totalOffset : 0;
    const result = [];
    const qualifiersLength = qualifiers.length;
    let idx;
    for (idx = 0; idx < qualifiersLength; idx += 1) {
      const qualifier = qualifiers[idx];
      const start = qualifier.location - offset;
      result.push({
        type: "qualifier",
        start,
        end: start + qualifier.string.length,
      });
    }
    return result;
  }
  return [];
}

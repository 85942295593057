import Describer from "../../../utils/issues/issue_description/describer";

function render(issue, topics) {
  const describer = new Describer.IssueDescriber(issue, topics);
  return [
    `On any clause, ${describer.topicName} is present`,
    `with ${describer.parameterName}`,
    "not having a value",
  ].join(" ");
}

export default render;

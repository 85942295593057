import UNINITIALISED from "utils/uninitialised";
import ActionTypes from "../constants/action_types";

export default function analyticsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.ANALYTICS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.ANALYTICS_CLEAR.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import React from "react";

import Clauses, {calculateTopics} from "./clauses";
import SingleClause from "./single_clause";

import getUnmatchedChanges from "../../utils/changes/get_unmatched_changes";
import isSectionInSelectedGroup from "../../utils/is_section_in_selected_group";
const styles = {
  heading: {
    textTransform: "capitalize",
  },
};

const hiddenHeadings = {
  title_parties: true,
  background: true,
  main: true,
};

export default function DocumentSection(props) {
  const {
    documentChanges: changes,
    section,
    clauses,
    selectedChecklistSectionId,
  } = props;
  const {reference, id: sectionId, name} = section;
  const showHeading =
    !hiddenHeadings[reference] &&
    section.showSectionHeading &&
    props.clauseToRender >= clauses[0].row_number;

  const sectionChanges = changes.filter(
    change =>
      change.new_section_id === sectionId ||
      (change.old_reference &&
        change.old_reference.startsWith(section.reference) &&
        change.type === "clause_deletion"),
  );
  // unmatchedChanges are needed for the case of removed clause and nothing
  // new put on its place
  const unmatchedChanges = getUnmatchedChanges(sectionChanges, clauses).filter(
    change => {
      const {old_clause_data: clause} = change;
      const originalChange = clauses.find(_clause => _clause.id === clause.id);
      return originalChange && !originalChange.deleted;
    },
  );
  const shoudShowUnmatchedChanges =
    unmatchedChanges && unmatchedChanges.length > 0;
  return (
    <div
      className="document-section"
      id={`document-section-${sectionId}`}
      style={
        selectedChecklistSectionId
          ? isSectionInSelectedGroup(
              sectionId,
              selectedChecklistSectionId,
              props.checklistSections,
            )
            ? {}
            : {
                background: "#e0e0e0",
                color: "#bdbdbd",
              }
          : {}
      }
    >
      {showHeading && (
        <h2 style={styles.heading}>
          {cleanReference(reference)}
          {name ? `: ${name}` : ""}
        </h2>
      )}
      <Clauses
        {...props}
        visibility={props.headingVisibility}
        updateVisibility={props.updateHeadingVisibility}
        changes={sectionChanges}
      />

      {shoudShowUnmatchedChanges &&
        unmatchedChanges.map((change, index) => {
          const {old_clause_data: clause} = change;
          const topics = calculateTopics(clause, props);
          return (
            <SingleClause
              {...props}
              visibility={props.headingVisibility}
              updateVisibility={props.updateHeadingVisibility}
              selectedId={props.selectedHeading}
              key={`${clause.reference}_${index}_deleted`}
              clause={clause}
              topics={topics}
              index={index}
              reference={clause.reference}
              fullReference={clause.full_reference}
              hideTopics={false}
              changes={changes}
              isClauseDeletion={true}
            />
          );
        })}
    </div>
  );
}

function cleanReference(reference) {
  if (reference.endsWith("_background")) {
    return reference.substring(0, reference.indexOf("_background"));
  }
  return reference;
}

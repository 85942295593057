import React, {useState} from "react";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import CheckboxBasic from "common_components/inputs/checkbox_basic";

import DefinitionItem from "./definition_item";

import commonStyles from "./common_styles";

const styles = {
  ...commonStyles,
  bodyContainer: {
    borderTop: "1px solid #e0e0e0",
    background: "#fafafa",
  },
  headerLabel: {
    cursor: "pointer",
    display: "flex",
    userSelect: "none",
    msUserSelect: "none",
    fontWeight: 500,
  },
  openIcon: {
    height: 16,
    width: 16,
    color: "#9e9e9e",
    cursor: "pointer",
    marginRight: 2,
  },
};

function DefinitionsBlock(props) {
  const {
    substitutionMap,
    optionsMap,
    precedentTerms,
    terms,
    title,
    areDefinitionsSubstituted = false,
    onPrecedentTermUpdate,
    updateSubstitutionMapValue,
    openByDefault,
    precedentDefinitions,
  } = props;

  const [showBody, updateShowBody] = useState(openByDefault);
  function triggerShowBody() {
    updateShowBody(!showBody);
  }

  const noTermsAvailable = terms.length === 0;
  const stats = terms.reduce(
    (result, term) => {
      const substitutionMapItem = substitutionMap[term];
      if (!substitutionMapItem) {
        return result;
      }
      const isSubstituted =
        substitutionMapItem.substitutedByUser !== undefined
          ? substitutionMapItem.substitutedByUser
          : substitutionMapItem.isSubstituted;

      const value = substitutionMapItem.userValue || substitutionMapItem.value;

      if (!value.value) {
        result.disabled += 1;
      } else if (isSubstituted) {
        result.substituted += 1;
      }
      return result;
    },
    {
      disabled: 0,
      substituted: 0,
      total: terms.length,
    },
  );

  const areAllSubstituted = stats.total - stats.disabled === stats.substituted;

  function selectAll() {
    const updates = {};
    terms.forEach(term => {
      const substitutionMapItem = substitutionMap[term];
      const {isSubstituted} = substitutionMapItem;
      const value = substitutionMapItem.userValue || substitutionMapItem.value;
      const precendetTermsItem = precedentTerms[term] || {};
      const {substitutedByUser} = precendetTermsItem;

      const isSubstitutedFinal =
        substitutedByUser !== undefined ? substitutedByUser : isSubstituted;
      // if not disabled item
      if (value.value) {
        if (areAllSubstituted) {
          updates[term] = {
            substitutedByUser: substitutedByUser ? undefined : false,
          };
        } else if (!areAllSubstituted && !isSubstitutedFinal) {
          updates[term] = {
            substitutedByUser: substitutedByUser === false ? undefined : true,
          };
        }
      }
    });
    if (Object.keys(updates).length > 0) {
      onPrecedentTermUpdate(updates);
    }
  }

  function renderSelectAllCheckbox() {
    if (noTermsAvailable) {
      return null;
    }
    return (
      <div style={{display: "flex", alignItems: "center", fontWeight: 500}}>
        <div style={{marginRight: 10}}>Select All</div>
        <CheckboxBasic
          checked={areAllSubstituted}
          onCheck={selectAll}
          iconStyles={{
            ...styles.checkbox.iconStyles,
            ...(areAllSubstituted
              ? {color: "#42A5F5"}
              : {color: "#616161", stroke: "#f4f4f4"}),
          }}
          disabled={stats.disabled === stats.total}
        />
      </div>
    );
  }

  function renderHeader() {
    const Icon = showBody ? RemoveIcon : AddIcon;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px",
          fontWeight: 600,
        }}
      >
        <div onClick={triggerShowBody} style={styles.headerLabel}>
          <Icon style={styles.openIcon} />
          <div>{`${title} (${terms.length})`}</div>
        </div>
        {renderSelectAllCheckbox()}
      </div>
    );
  }

  function renderBody() {
    if (!showBody) {
      return null;
    }

    return terms.map((foundTerm, foundTermIndex) => {
      const substitutionMapItem = substitutionMap[foundTerm];
      if (!substitutionMapItem) {
        return null;
      }
      const precendetTermsItem = precedentTerms[foundTerm] || {};
      const {isSubstituted} = substitutionMapItem;

      function triggerIsSubstituted() {
        const substitutedByUser =
          precendetTermsItem.substitutedByUser !== undefined
            ? undefined
            : !isSubstituted;

        onPrecedentTermUpdate({
          [foundTerm]: {substitutedByUser},
        });
      }

      function updateValue(_newValue, source) {
        const newValue =
          _newValue.value !== undefined ? _newValue : {value: _newValue};
        newValue.source = source;

        const baseValue = substitutionMapItem.value;
        const isValueNew = newValue.value !== baseValue.value;

        const newPrecendetTermValue = isValueNew ? newValue.value : undefined;

        const substitutedByUserBase = newValue.value !== null;
        const substitutedByUser =
          substitutedByUserBase !== isSubstituted
            ? substitutedByUserBase
            : undefined;

        updateSubstitutionMapValue(foundTerm, {
          userValue: isValueNew ? newValue : undefined,
          substitutedByUser,
        });

        onPrecedentTermUpdate({
          [foundTerm]: {
            value: newPrecendetTermValue,
            substitutedByUser,
            source,
          },
        });
      }

      function clearUserSubstitution() {
        onPrecedentTermUpdate({
          [foundTerm]: {
            substitutedByUser: undefined,
            value: undefined,
            source: undefined,
          },
        });
      }

      const isChangedByUser =
        precendetTermsItem && Object.keys(precendetTermsItem).length > 0;

      const isSubstitutedFinal =
        substitutionMapItem.substitutedByUser !== undefined
          ? substitutionMapItem.substitutedByUser
          : substitutionMapItem.isSubstituted;
      const precedentDefinition = precedentDefinitions.find(
        def => def.term.toLowerCase() === foundTerm.toLowerCase(),
      );
      return (
        <DefinitionItem
          key={`definition-item-${foundTermIndex}`}
          term={foundTerm}
          value={substitutionMapItem.value}
          userValue={substitutionMapItem.userValue}
          updateValue={updateValue}
          options={optionsMap[foundTerm]}
          isSubstituted={isSubstitutedFinal}
          updateIsSubstituted={triggerIsSubstituted}
          termSubstitutedByUser={
            substitutionMapItem.substitutedByUser !== undefined
          }
          shouldRenderClear={isChangedByUser}
          clearUserSubstitution={clearUserSubstitution}
          precedentTerms={precedentTerms}
          precedentDefinition={precedentDefinition}
        />
      );
    });
  }

  return (
    <div
      style={{
        ...styles.bodyContainer,
        opacity: areDefinitionsSubstituted ? 1 : 0.3,
        paddingBottom: noTermsAvailable || !showBody ? 0 : 15,
      }}
    >
      {renderHeader()}
      {!noTermsAvailable &&
        showBody && (
          <hr
            style={{
              borderTop: "1px solid #E0E0E0",
              borderLeft: "none",
              borderRight: "none",
              margin: "0px 15px 6px 15px",
            }}
          />
        )}
      {renderBody()}
    </div>
  );
}

export default DefinitionsBlock;

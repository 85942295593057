import React, {Component} from "react";
import Dialog from "material-ui/Dialog";
import RaisedButton from "material-ui/RaisedButton";

const styles = {
  button: {
    margin: "0 .3em",
  },
};

type ConfirmDialogProps = {
  cancelButtonCaption: string;
  open: boolean;
  title: string;
  description: string;
  okButtonCaption: string;
  onClose: () => void;
  onSuccess: () => void;
};

export default class ConfirmDialog extends Component<ConfirmDialogProps> {
  render() {
    return (
      <Dialog
        title={this.props.title}
        open={this.props.open}
        onRequestClose={this.props.onClose}
        actions={[
          <RaisedButton
            key="confirm-dialog-cancel-button"
            label={this.props.cancelButtonCaption || "Cancel"}
            secondary={true}
            style={styles.button}
            onClick={this.props.onClose}
          />,
          <RaisedButton
            key="confirm-dialog-ok-button"
            label={this.props.okButtonCaption}
            primary={true}
            style={styles.button}
            onClick={this.onSuccess}
          />,
        ]}
      >
        {this.props.description}
      </Dialog>
    );
  }

  onSuccess = () => {
    this.props.onClose();
    this.props.onSuccess();
  };
}

import React from "react";

import DocumentDetailContext from "common_components/context/document_detail_context";
import CheckboxBasic from "common_components/inputs/checkbox_basic";
import Markdown from "common_components/markdown";

import Tooltip from "@material-ui/core/Tooltip";
import * as colors from "@material-ui/core/colors";
import formatText from "utils/text/format_text";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import getClampedClauseStyles from "utils/text/get_clamped_clause_styles";
import getIe11ClampedText from "utils/text/get_ie11_clamped_text";
import {isIe11} from "utils/browser_test";
import PriorityIcon from "@material-ui/icons/PriorityHigh";

const blueColor = colors.lightBlue[600];
const styles = {
  clauseText: {
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
  },
  clauseId: {},
  packetSection: {
    fontWeight: "bold",
  },
};

const packetClausePrefixes = {
  FP: "Front Page",
  TPS: "Title Search (Strata)",
  TPT: "Title Search (Torrens)",
  TPC: "Title Search (Common)",
  PC: "Planning Certificate",
};

function getText(
  clauseTextBase,
  clauseReferenceBase,
  isClauseExpanded,
  packetSection,
  shouldHideReference,
  haveRemovedPrefix,
  groupClausesCount,
) {
  const clauseReference =
    packetSection ||
    shouldHideReference ||
    haveRemovedPrefix ||
    !clauseReferenceBase
      ? ""
      : clauseReferenceBase;

  const referenceText = getReferenceText(clauseReference);

  const clauseText = referenceText + clauseTextBase;
  if (isClauseExpanded) {
    return clauseText;
  } else if (isIe11()) {
    return getIe11ClampedText(clauseText, groupClausesCount > 4 ? 3 : 10);
  }
  return clauseText;
}

function getReferenceText(reference) {
  if (!reference) {
    return "";
  }

  const cleanReference = reference.replace(/\.([a-zA-Z ]{5,}(\.|$))/g, "");
  const endPeriod =
    reference.indexOf(".") === -1
      ? // NOTE: this is a non-standard period so doesn't get
        // treated as numeration by markdown
        "․"
      : "";

  return `${cleanReference}${endPeriod} `;
}

function renderClause(
  clause,
  index,
  onClauseClick,
  documentChanges = [],
  shouldHideReference,
  fileExtension,
  zoom?: unknown,
  documentTemplateValues?: unknown,
  groupClausesCount?: unknown,
  isUnrendered?: unknown,
  user?: unknown,
) {
  return (
    <Clause
      clause={clause}
      key={index}
      onClauseClick={onClauseClick}
      documentChanges={documentChanges}
      shouldHideReference={shouldHideReference}
      fileExtension={fileExtension}
      zoom={zoom}
      documentTemplateValues={documentTemplateValues}
      groupClausesCount={groupClausesCount}
      isUnrendered={isUnrendered}
      user={user}
    />
  );
}

const extractedTextTest = /^[A-Z]{2,3}(?:_ALL)?=/;

interface Props {
  clause: {
    [key: string]: unknown;
    /**
     * Optional boolean flag to enable rendering of clause text as markdown.
     * This feature is currently related to the mocked up "LLM trigger type".
     */
    isMarkdown?: boolean;
  };
  documentChanges: unknown;
  shouldHideReference: unknown;
  fileExtension: unknown;
  zoom: unknown;
  documentTemplateValues: unknown;
  groupClausesCount: unknown;
  isUnrendered: unknown;
  user: unknown;
  onClauseClick: (clause: Props["clause"]) => void;
}

export class Clause extends React.Component<Props> {
  static contextType = DocumentDetailContext;

  constructor(props) {
    super(props);
    this.state = {
      isClauseExpanded: false,
      isChecked: props.clause.checked,
    };
  }

  componentDidUpdate(prevProps) {
    const {clause: prevClause} = prevProps;
    const {clause: currentClause} = this.props;
    if (prevClause && currentClause && prevClause.id !== currentClause.id) {
      this.setState(() => ({isChecked: currentClause.checked}));
    }
  }

  triggerOnCheck = () => {
    const {clause} = this.props;
    this.setState(
      prevState => ({isChecked: !prevState.isChecked}),
      () => clause.onCheck(),
    );
  };

  render() {
    const {
      clause,
      documentChanges,
      shouldHideReference,
      fileExtension,
      zoom,
      documentTemplateValues,
      groupClausesCount,
      isUnrendered,
    } = this.props;
    const {isClauseExpanded} = this.state;
    const {isFlipChecklistIcons} = this.context;
    // change packet clause prefixes to more meaningful text
    let clauseText = clause && clause.text ? clause.text : "";
    const clauseFullText = clause && clause.full_text ? clause.full_text : "";
    let packetSection;
    if (extractedTextTest.test(clauseText)) {
      const arrayText = clauseText.split("=");
      packetSection = `${packetClausePrefixes[arrayText[0]]}: `;
      clauseText = arrayText.slice(1).join("");
    } else if (extractedTextTest.test(clauseFullText)) {
      const arrayFullText = clauseFullText.split("=");
      const sectionRef = arrayFullText[0].replace(/_ALL/, "");
      packetSection = `${packetClausePrefixes[sectionRef]}: `;
    }
    const haveRemovedPrefix = clause.text !== clauseText;
    const fontStyles = this.getFontStyles();
    const templateChanges = (documentChanges || []).filter(
      filterTemplateChanges,
    );
    const changes = !fontStyles && findMatchingEdits(clause, templateChanges);

    const changeText =
      (changes || documentTemplateValues) &&
      formatText(
        fileExtension || "",
        clauseText,
        {changes, documentDefinitions: []},
        null,
        {display: "inline"},
        false,
        "",
        documentTemplateValues,
      );
    const text = getText(
      clauseText,
      clause.clean_reference || clause.reference,
      isClauseExpanded,
      packetSection,
      shouldHideReference,
      haveRemovedPrefix,
      groupClausesCount,
    );
    const {indent: clauseIndent, clausePriority} = clause;

    const renderedClause = (
      <div
        style={{
          ...styles.clauseText,
          paddingLeft: clauseIndent !== undefined && clauseIndent !== 0 ? 3 : 0,
          paddingTop: 4,
          paddingBottom: 4,
          borderLeftWidth:
            clauseIndent !== undefined && !clause.isEditOn
              ? `${clauseIndent * 0.3}rem`
              : 0,
          borderLeftStyle: "solid",
          borderLeftColor: clause.highlight ? "#bbb" : "#ddd",
          fontSize: getZoomedFontSize(13, "checklist", zoom),
          ...(isFlipChecklistIcons
            ? {}
            : {
                color: colors.grey[600],
                lineHeight: `${getZoomedFontSize(15, "checklist", zoom)}px`,
              }),
          ...fontStyles,
          ...(clause.isAddition ? {fontStyle: "italic"} : {}),
          ...(isUnrendered ? {cursor: "wait"} : {cursor: "pointer"}),
          ...(clausePriority?.hasHighestPriority
            ? {
                backgroundColor: "#FFC",
              }
            : {}),
        }}
      >
        {clause.isEditOn && clause.onCheck && (
          <CheckboxBasic
            iconStyles={{
              width: "18px",
              height: "18px",
              margin: "0px 4px 0px 0px",
            }}
            checked={this.state.isChecked}
            onCheck={this.triggerOnCheck}
          />
        )}
        {packetSection && (
          <span style={styles.packetSection}>{packetSection}</span>
        )}
        <span
          style={{
            ...(isClauseExpanded
              ? {}
              : getClampedClauseStyles(groupClausesCount > 4 ? 3 : 10)),

            ...(clausePriority?.is_high_priority
              ? {
                  fontWeight: "bold",
                }
              : {}),
          }}
          onClick={this.onExpandClause}
        >
          {changeText ??
            (clause.isMarkdown ? <Markdown>{text}</Markdown> : text)}
        </span>
        {this?.props?.user?.is_admin && clausePriority && (
          <span title={`${JSON.stringify(clausePriority)}`}>
            <PriorityIcon />
          </span>
        )}
      </div>
    );
    const highlightedClause = clause.highlight ? (
      <div style={{backgroundColor: "#dfdfdf"}}>{renderedClause}</div>
    ) : (
      renderedClause
    );

    return isUnrendered ? (
      <Tooltip title="Clause not shown yet - please wait">
        {highlightedClause}
      </Tooltip>
    ) : (
      highlightedClause
    );
  }

  getFontStyles() {
    if (!this.props.documentChanges) {
      return {};
    }
    const {clause, documentChanges: changes} = this.props;
    if (
      isClauseDeletion(clause, changes) ||
      isClausepartDeletion(clause, changes)
    ) {
      return {color: blueColor, textDecoration: "line-through"};
    }
    if (
      isClauseAddition(clause, changes) ||
      isClausepartAddition(clause, changes)
    ) {
      return {color: blueColor};
    }

    return null;
  }

  onExpandClause = () =>
    this.setState(
      prevState => ({isClauseExpanded: !prevState.isClauseExpanded}),
      () => {
        if (this.props.onClauseClick) {
          this.props.onClauseClick(this.props.clause);
        }
      },
    );
}

export default renderClause;

function isClauseDeletion(clause, changes) {
  const relevantChanges = changes
    .filter(change => change.type === "clause_deletion")
    .filter(change => change.new_clause_id === clause.clause_id);
  return relevantChanges.length > 0;
}
function isClausepartDeletion(clause, changes) {
  if (clause.change_type === "clausepart_deletion") {
    return true;
  }
  const relevantChanges = changes
    .filter(change => change.type === "clausepart_deletion")
    .filter(change => change.new_clausepart_id === clause.id);
  return relevantChanges.length > 0;
}
function isClauseAddition(clause, changes) {
  const relevantChanges = changes
    .filter(change => change.type === "clause_addition")
    .filter(change => change.new_clause_id === clause.clause_id);

  return relevantChanges.length > 0;
}
function isClausepartAddition(clause, changes) {
  const relevantChanges = changes
    .filter(change => change.type === "clausepart_addition")
    .filter(change => change.new_clausepart_id === clause.id);

  return relevantChanges.length > 0;
}
function filterTemplateChanges(change) {
  return change.new_document_id !== change.old_document_id;
}

function findMatchingEdits(clause, changes) {
  const relevantChanges = changes
    .filter(change => change.type.startsWith("clausepart_text"))
    .filter(change => change.new_clausepart_id === clause.id);
  return relevantChanges.length ? relevantChanges : null;
}

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const styles = {
  toolbar: {
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 1px 15px 0 rgba(0, 0, 0, 0.06)",
    borderBottom: "1px solid #e6e6e6",
    minHeight: 73,
    height: 73,
    position: "relative",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    zIndex: 3,
    fontFamily: "Roboto, sans-serif",
    color: "#232e38",
  },
  inner: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    padding: "0px 35px",
    overflow: "hidden",
  },
  icon: {
    fontSize: 32,
    marginRight: 16,
    color: "#1f88e5",
  },
  title: {
    flexGrow: 1,
    fontSize: 20,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
  },
  children: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    flexShrink: 0,
  },
  right: {
    flexShrink: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: "0px 12px",
    borderLeft: "1px solid #e6e6e6",
    marginLeft: "auto",
  },
};

export default class Toolbar extends React.Component {
  render() {
    if (this.props.dontUsePortal) {
      return this.renderComponent();
    }
    return ReactDOM.createPortal(
      this.renderComponent(),
      document.querySelector(".app-toolbar-container"),
    );
  }

  renderComponent = () => {
    return (
      <div style={styles.toolbar}>
        <div style={{...styles.inner, ...this.props.innerStyle}}>
          {this.props.icon &&
            React.cloneElement(this.props.icon, {
              style: {
                ...styles.icon,
                ...this.props.icon.props.style,
              },
            })}
          {this.props.title && (
            <div style={styles.title}>{this.props.title}</div>
          )}
          {this.props.children && (
            <div
              style={{...styles.children, ...(this.props.childrenStyles || {})}}
            >
              {this.props.children}
            </div>
          )}
        </div>
        {this.props.rightChildren && (
          <div style={styles.right}>{this.props.rightChildren}</div>
        )}
      </div>
    );
  };
}

Toolbar.propTypes = {
  icon: PropTypes.element,
  rightChildren: PropTypes.element,
  innerStyle: PropTypes.object,
};

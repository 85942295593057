import _ from "lodash";
import doesClauseReferenceMatch from "./utils/does_clause_reference_match";

function getReferringClauses(reasonData = [], searchType, clauseReference) {
  if (searchType === "clausepart_reference") {
    const sectionId = clauseReference.split("-")[0];
    const clauseRef = clauseReference.split("-")[1];
    return _.chain(reasonData)
      .filter(atom => {
        const atomSectionId = atom.section_id.toString();
        return (atom.references || []).find(atomReference => {
          return (
            atomSectionId === sectionId &&
            doesClauseReferenceMatch(clauseRef, atomReference.id)
          );
        });
      })
      .map(atom => `${atom.section_id}-${atom.reference}`)
      .uniq()
      .value();
  }
  if (searchType === "clausepart_id") {
    const clause = reasonData.find(
      item => item.id.toString() === clauseReference.toString(),
    );
    return _.chain(reasonData)
      .filter(atom => {
        return (atom.references || []).find(atomReference => {
          return doesClauseReferenceMatch(clause.reference, atomReference.id);
        });
      })
      .map(atom => atom.id.toString())
      .uniq()
      .value();
  }
  return [];
}

export default getReferringClauses;

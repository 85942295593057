import _ from "lodash";
import issueHeaderUtils from "utils/issues/issue_header_utils";

function sortIssueItems(value, sortKey, currentIssuesetItem, project) {
  const items = value.reduce((total, item) => [...total, ...item.item], []);
  if (sortKey === "Needs Review") {
    const order = {3: 0, 2: 10, 1: 5};
    return _.sortBy(items, item => order[item.review_state]);
  }
  if (sortKey === "State") {
    return _.sortBy(items, item => {
      const issueIconName = issueHeaderUtils.getIconName(
        item,
        currentIssuesetItem,
        false,
        project,
      );
      if (issueIconName === "IssueIcon") {
        return 0;
      }
      if (issueIconName === "WarningIcon") {
        return 5;
      }
      if (issueIconName === "FixedIcon") {
        return 10;
      }
      if (issueIconName === "GuidanceIcon") {
        return 15;
      }
      if (issueIconName === "NoIssueIcon") {
        return 20;
      }
      if (issueIconName === "IgnoreIcon") {
        return 25;
      }
    });
  }
  return items;
}

export default sortIssueItems;

import React from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from "material-ui/TextField";

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default class ForgotPasswordDialog extends React.Component {
  state = {
    email: this.props.email,
  };
  static propTypes = {
    email: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    passwordExpired: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
  };
  componentDidUpdate(prevProps) {
    if (this.props.email !== prevProps.email) {
      this.setState({email: this.props.email || ""});
    }
  }
  render() {
    const actions = [
      <FlatButton
        key="close-button"
        label="Close"
        onClick={this.props.onClose}
        primary={true}
      />,
      <FlatButton
        key="confirm-button"
        label="Confirm"
        primary={true}
        onClick={this.onSubmit}
      />,
    ];
    if (this.props.shouldBeDialog) {
      return (
        <Dialog
          open={this.props.isOpen}
          className="forgot-password-dialog"
          title={
            this.props.passwordExpired
              ? "Reset your password"
              : "Forgot your password?"
          }
          actions={actions}
          onRequestClose={this.props.onClose}
        >
          {this.renderContents()}
        </Dialog>
      );
    }
    return (
      <div style={styles.main}>
        {this.renderContents()}
        <div style={styles.buttons}>{actions}</div>
      </div>
    );
  }

  renderContents() {
    return (
      <>
        <p style={{width: "100%", textAlign: "justify"}}>
          Please provide your email address and we will send you password reset
          link. Note, the link will be available for <strong>1 hour</strong>.
        </p>
        <TextField
          className="email"
          floatingLabelText="Email"
          value={this.state.email}
          onChange={this.onEmailChange}
        />
      </>
    );
  }

  onEmailChange = e => {
    const email = e.target.value;
    this.setState(() => ({email}));
  };

  onSubmit = () => {
    this.props.onConfirm(this.state.email);
  };
}

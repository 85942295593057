import React from "react";
import generateSingleDocument from "common/document/generate_single_document";

const TIME_PER_LLM_ISSUE_SECONDS = 5;

const statusMessages = {
  PARSE_ERROR: "Document could not be read. An admin will contact you shortly.",
  FILE_TOO_LONG: "Document too long to be read.",
  NO_TEXT: "Document only contains images",
  NO_SPECIAL_CONDITIONS: "Special conditions have not been detected",
  UNRECOGNISED_FORMAT: "Unrecognised file format",
};

function toOrdinal(number) {
  const lastNumber = number % 10;
  let ordinalStr = "th";
  if (!(number > 10 && number < 19)) {
    if (lastNumber === 1) {
      ordinalStr = "st";
    }
    if (lastNumber === 2) {
      ordinalStr = "nd";
    }
    if (lastNumber === 3) {
      ordinalStr = "rd";
    }
  }
  return `${number}${ordinalStr}`;
}

// TODO: should add a new timestamp to the db (after analysis has started)
// and use that here
function getAnalysingTimeRemaining(textLength: number, creationDate: Date) {
  if (textLength < 1000) {
    return 0;
  }
  const pages = textLength / 2700;
  const timePerPage = 4;
  const estimatedTime = pages * timePerPage;
  const timeTaken =
    (new Date().valueOf() - new Date(creationDate).valueOf()) / 1000;
  const timeRemaining = Math.max(20, estimatedTime - timeTaken);
  return timeRemaining;
}

function generateTimeEstimateMessage(timeRemaining: number) {
  const timeRemainingText =
    timeRemaining > 0
      ? timeRemaining < 60
        ? `${Math.round(timeRemaining)} secs`
        : `${Math.round(timeRemaining / 60)} mins`
      : "";
  return timeRemainingText ? `Est time remaining: ${timeRemainingText}` : "";
}

function renderProcessingUploadedDocumentStatus(uploadedDocument) {
  if (!uploadedDocument) {
    return null;
  }

  const {
    errorMessage,
    phaseMessage,
    progressAmount,
    timeEstimate,
    amountComplete,
    queueStatusMessage,
  } = getMultiFileStatus(uploadedDocument);
  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }
  if (
    amountComplete === uploadedDocument.concat_filenames.length &&
    progressAmount > 99
  ) {
    return null;
  }
  if (phaseMessage) {
    return (
      <div>
        <div style={{textAlign: "center", fontSize: "1.2em", color: "#544"}}>
          {amountComplete
            ? `${amountComplete}/${uploadedDocument.concat_filenames.length} Complete`
            : phaseMessage}
        </div>
        <div
          style={{
            marginTop: 6,
            height: 3,
            width: "100%",
            backgroundColor: "#ccc",
          }}
        >
          {progressAmount ? (
            <div
              style={{
                height: "100%",
                backgroundColor: "#1f88e5",
                width: `${progressAmount}%`,
              }}
            />
          ) : (
            <></>
          )}
        </div>
        <div style={{fontStyle: "italic", marginTop: 4}}>
          {queueStatusMessage
            ? queueStatusMessage
            : generateTimeEstimateMessage(timeEstimate)}
        </div>
      </div>
    );
  }
}

function getMultiFileStatus(uploadedDocument) {
  if ((uploadedDocument?.concat_filenames?.length ?? 1) === 1) {
    const status = getDocumentStatus(
      generateSingleDocument(uploadedDocument, 0),
    );
    return {...status, amountComplete: null};
  }

  let allErrorMessage: string | null = null;
  let bestPhaseMessage: string | null = null;
  let bestQueueMessage: string | null = null;
  let bestProgressAmount: number | null = null;
  let totalProgressAmount = 0;
  let totalReadAmount = 0;
  let totalTimeEstimate = 0;
  let amountComplete = 0;

  uploadedDocument.concat_filenames.find((filename, index) => {
    const indexDocument = generateSingleDocument(uploadedDocument, index);
    const {
      errorMessage,
      phaseMessage,
      progressAmount,
      timeEstimate,
      queueStatusMessage,
      awaitingIssues,
    } = getDocumentStatus(indexDocument, index);
    if (errorMessage) {
      allErrorMessage = errorMessage;
      return true;
    } else {
      if (awaitingIssues) {
        amountComplete += 1;
      }
      if (bestProgressAmount === null || progressAmount > bestProgressAmount) {
        bestPhaseMessage = phaseMessage;
        bestProgressAmount = progressAmount;
        bestQueueMessage = queueStatusMessage;
      }
      const readAmount = indexDocument.text_length ?? 0;
      totalProgressAmount =
        (totalProgressAmount * totalReadAmount + progressAmount * readAmount) /
        (totalReadAmount + readAmount);
      totalReadAmount += readAmount;
      totalTimeEstimate = Math.max(totalTimeEstimate, timeEstimate ?? 0);
    }
    return false;
  });
  return {
    errorMessage: allErrorMessage,
    phaseMessage: bestPhaseMessage,
    progressAmount: totalProgressAmount,
    timeEstimate: totalTimeEstimate,
    queueStatusMessage: bestQueueMessage,
    amountComplete,
  };
}

interface DocumentStatus {
  errorMessage: string;
  phaseMessage: string;
  queueStatusMessage: string;
  progressAmount: number;
  timeEstimate: number;
}

function scaleProgressAmount(
  phase: "parsing" | "classifying" | "issuing",
  amount: number,
  useLlms: boolean,
): number {
  const llmScale = {
    parsing: {
      initialAmount: 0,
      increase: 30,
    },
    classifying: {
      initialAmount: 30,
      increase: 50,
    },
    issuing: {
      initialAmount: 80,
      increase: 20,
    },
  };

  const classicScale = {
    parsing: {
      initialAmount: 0,
      increase: 50,
    },
    classifying: {
      initialAmount: 50,
      increase: 49,
    },
    issuing: {
      initialAmount: 99,
      increase: 1,
    },
  };

  const scale = useLlms ? llmScale : classicScale;

  return scale[phase].initialAmount + (amount / 100) * scale[phase].increase;
}

function getDocumentStatus(uploadedDocument, index = 0): DocumentStatus {
  const useLlms = (uploadedDocument.llm_issues_count ?? 0) > 0;

  const {
    load_state: loadState,
    parse_state: parseState,
    status,
    analyser_progress: analyserProgress,
    // should store a new value to say when analysis started
    creation_date: creationDate,
    text_length: textLength,
  } = uploadedDocument;
  let errorMessage;
  let phaseMessage;
  let progressAmount = 0;
  let timeEstimate: number | null = null;
  let queueStatusMessage;
  let awaitingIssues = false;
  if (Object.keys(statusMessages).includes(status)) {
    errorMessage = statusMessages[status];
  } else if (uploadedDocument.id === -1) {
    errorMessage = " ";
  } else if (!uploadedDocument.file_extension) {
    errorMessage = "Unknown file extension";
  } else if (status === "EMPTY_DOCUMENT" || status === "FILE_EMPTY") {
    errorMessage = "Document Empty";
  } else if (loadState === 9) {
    awaitingIssues = true;
    phaseMessage = "Finalising";
    if (useLlms) {
      const percentComplete =
        (uploadedDocument.llm_issues_count_complete /
          uploadedDocument.llm_issues_count) *
        100;
      timeEstimate =
        (uploadedDocument.llm_issues_count -
          uploadedDocument.llm_issues_count_complete) *
        TIME_PER_LLM_ISSUE_SECONDS;
      progressAmount = scaleProgressAmount("issuing", percentComplete, true);
    } else {
      progressAmount = scaleProgressAmount("issuing", 0, useLlms);
    }
  } else if (loadState === 4) {
    phaseMessage = "Queued";
    progressAmount = 1;
    if (uploadedDocument.queue_order && index === 0) {
      phaseMessage = `Queued (${toOrdinal(uploadedDocument.queue_order)})`;
    }
  } else if (loadState === 3) {
    progressAmount = 100;
  } else if (loadState === 2) {
    progressAmount = 100;
    errorMessage = "Document is being reviewed and will be unlocked shortly.";
  } else if (loadState === 5) {
    errorMessage = "Document could not be classified.";
  } else if (loadState === 0) {
    if (parseState) {
      if (parseState >= 200 && parseState <= 299) {
        phaseMessage = "Reading Document";
      } else if (parseState >= 300 && parseState <= 399) {
        phaseMessage = "OCR images";
        progressAmount = scaleProgressAmount("parsing", 10, useLlms);
      } else if (parseState >= 400 && parseState <= 499) {
        phaseMessage = "Analysing structure";
        progressAmount = scaleProgressAmount("parsing", 14, useLlms);
      } else if (parseState >= 500 && parseState <= 599) {
        phaseMessage = "Analysing clauses";
        progressAmount = scaleProgressAmount("parsing", 20, useLlms);
      } else {
        // phaseMessage = "ScanningX"+parseState;
        phaseMessage = "Scanning";
      }
      timeEstimate = getTimeEstimateWithLlms(
        getAnalysingTimeRemaining(textLength, creationDate),
        uploadedDocument,
        useLlms,
      );
    } else {
      // phaseMessage = "Scanning0_"+parseState;
      phaseMessage = "Scanning";
    }
  } else if (loadState === 6) {
    // phaseMessage = "Scanning6_"+parseState;
    phaseMessage = "Analysing structure";
    progressAmount = scaleProgressAmount("parsing", 14, useLlms);
    timeEstimate = getTimeEstimateWithLlms(
      getAnalysingTimeRemaining(textLength, creationDate),
      uploadedDocument,
      useLlms,
    );
  } else if (loadState === 7) {
    phaseMessage = "Analysing clauses";
    progressAmount = scaleProgressAmount("parsing", 16, useLlms);
    queueStatusMessage = `${
      uploadedDocument.queue_order && index === 0
        ? `(${toOrdinal(uploadedDocument.queue_order)} in queue)`
        : ""
    }`;
    timeEstimate = getTimeEstimateWithLlms(
      getAnalysingTimeRemaining(textLength, creationDate),
      uploadedDocument,
      useLlms,
    );

    if (analyserProgress && analyserProgress > 0) {
      progressAmount = scaleProgressAmount(
        "parsing",
        24 + Math.floor(Math.min(analyserProgress, 1) * 100 - 24),
        true,
      );
    } else {
      progressAmount = scaleProgressAmount("parsing", 24, useLlms);
    }
  } else if (loadState === 8) {
    phaseMessage = "Storing clauses";
    progressAmount = scaleProgressAmount("parsing", 98, useLlms);
  } else if (loadState === 1) {
    phaseMessage = "Classifying";
    progressAmount = scaleProgressAmount("classifying", 0, useLlms);
    if (uploadedDocument.classification_start) {
      const timeRemaining = getClassifyingTimeRemaining(
        uploadedDocument.clausepart_last_edited,
        uploadedDocument.classification_start,
        uploadedDocument.clausepart_load_count,
        uploadedDocument.clausepart_load_total,
      );
      progressAmount = scaleProgressAmount(
        "classifying",
        getClassifyingAmount(
          uploadedDocument.clausepart_load_count,
          uploadedDocument.clausepart_load_total,
        ),
        useLlms,
      );

      timeEstimate = getTimeEstimateWithLlms(
        timeRemaining,
        uploadedDocument,
        useLlms,
      );
    }
  }

  return {
    errorMessage,
    phaseMessage,
    progressAmount,
    timeEstimate,
    queueStatusMessage,
    awaitingIssues,
  };
}

function getClassifyingAmount(
  clausepartLoadCount: number,
  clausepartLoadTotal: number,
): number {
  return clausepartLoadCount === 0
    ? 0
    : clausepartLoadCount === clausepartLoadTotal
    ? 100
    : Math.round((clausepartLoadCount * 100 * 0.99) / clausepartLoadTotal);
}

function getClassifyingTimeRemaining(
  clausepartLastEdited: string,
  classificationStart: string,
  clausepartLoadCount: number,
  clausepartLoadTotal: number,
): number {
  const lastEdited = new Date(clausepartLastEdited);
  const classificationStartDate = new Date(classificationStart);
  const timeTaken =
    (lastEdited.getTime() - classificationStartDate.getTime()) / 1000;
  const clausesClassified = clausepartLoadCount;
  const clausesRemaining = clausepartLoadTotal - clausesClassified;
  const timeToClassifySingleClause = timeTaken / clausesClassified;
  return clausesRemaining * timeToClassifySingleClause;
}

function getTimeEstimateWithLlms(timeRemaining, uploadedDocument, useLlms) {
  return useLlms
    ? timeRemaining +
        uploadedDocument.llm_issues_count * TIME_PER_LLM_ISSUE_SECONDS
    : timeRemaining;
}

export default renderProcessingUploadedDocumentStatus;

import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import * as colors from "@material-ui/core/colors";

const styles = {
  container: {
    boxSizing: "border-box",
    padding: "0.5rem 1rem",
    borderBottom: "1px solid lightgray",
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  wrapper: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  leftElement: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontSize: "14px",
    width: "100%",
  },
  closeIcon: {
    cursor: "pointer",
    height: "28px",
    width: "28px",
    fill: colors.grey[800],
  },
  bottom: {
    marginTop: 3,
  },
};

function MenuHeading(props) {
  const {containerStyle = {}, wrapperStyle = {}, closeIconStyle = {}} = props;

  const usedContainerStyles = {
    ...styles.container,
    ...containerStyle,
    cursor: props.onMenuClick ? "pointer" : "default",
  };

  const usedWrapperStyle = {
    ...styles.wrapper,
    ...wrapperStyle,
  };

  const usedCloseIconStyle = {
    ...styles.closeIcon,
    ...closeIconStyle,
  };

  return (
    <div style={usedContainerStyles} onClick={props.onMenuClick}>
      <div style={usedWrapperStyle}>
        {props.onCloseClick && props.closeButtonOnTheLeft && (
          <ArrowBackIcon
            style={styles.closeIcon}
            onClick={props.onCloseClick}
          />
        )}
        <div style={styles.leftElement}>
          {props.menuIcon}
          {props.children}
        </div>
        {props.right}
        {props.onCloseClick && !props.closeButtonOnTheLeft && (
          <CloseIcon style={usedCloseIconStyle} onClick={props.onCloseClick} />
        )}
      </div>
      {props.bottom && <div style={styles.bottom}>{props.bottom}</div>}
    </div>
  );
}

export default MenuHeading;

import _ from "underscore";

function getClauseAtoms(documentClauses = {}) {
  const atoms = [];
  function getAtoms(node, clause) {
    if (node.text) {
      atoms.push({
        reference: node.reference,
        references: node.references || [],
        text: node.partial_text,
        partial_text: node.partial_text,
        full_text: node.text,
        id: node.id,
        clause_reference: clause.reference,
        clause_id: clause.id,
        section_id: clause.section_id,
        clause_section_id: clause.section_id,
        nodes: clause.nodes,
        clause_full_reference: clause.full_reference,
        clause_order: node.order,
        topics: transformTopics(node.topics),
        file_index: clause.file_index,
      });
    }
    if (node.clauseNodes) {
      const headerNode =
        (node.type.endsWith("Node") && node.clauseNodes[0]) || {};
      const footerNode =
        (node.type.endsWith("Node") && node.clauseNodes[2]) || {};
      node.clauseNodes.forEach((childNode, index) =>
        getAtoms(
          {
            ...childNode,
            references: [
              ...(node.references || []),
              ...((index === 1 && headerNode.references) || []),
              ...((index === 1 && footerNode.references) || []),
              ...(childNode.references || []),
            ],
          },
          clause,
        ),
      );
    }
  }
  _.forEach(documentClauses, clauses => {
    clauses.forEach(clause => {
      getAtoms(clause.nodes, clause);
    });
  });
  return atoms;
}

function transformTopics(topics) {
  return (topics || []).reduce((accum, topic) => {
    if (topic.is_deleted) {
      return accum;
    }
    const topicResult = {};
    const parameters = topic.topicparameters || [];
    if (parameters.length > 0) {
      topicResult.parameters = parameters.reduce((result, param) => {
        const values = param.actual_values
          ? param.actual_values
          : param.locked_values
          ? param.locked_values
          : param.values || [];
        if (!values.length) {
          return result;
        }
        result[param.topicparameter_id] = {
          name: param.name,
          values: values.reduce((paramValuesAccum, paramValue) => {
            const key =
              typeof paramValue === "object"
                ? JSON.stringify(paramValue)
                : paramValue;
            paramValuesAccum[key] = true;
            return paramValuesAccum;
          }, {}),
        };
        return result;
      }, {});
    }
    accum[topic.topic_id] = topicResult;
    return accum;
  }, {});
}

export default getClauseAtoms;

import React, {useEffect, useRef, useState} from "react";

import TextField from "@material-ui/core/TextField";
import TextValue from "../../../report/report_item/text_value";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneIcon from "@material-ui/icons/Done";
import ReplayIcon from "@material-ui/icons/Replay";
import PendingActions from "../../../../icons/PendingActions";
import SaveTrackedChanges from "../../../../icons/SaveTrackedChanges";
import {getUpdatedManualCorrections} from "../../../../utils/manual_corrections_utils";
import getTextFromClipboard from "../../../../utils/text/get_text_from_clipboard";
import moveReferencesToTheTextLine from "../../../../utils/text/move_references_to_text_line";
import fixLockedTextValue from "../../../report/utils/fix_locked_text_value";
import formatTrackedChanges from "../../../report/utils/format_tracked_changes";
import constructIssueNameTextValue from "common_components/report/utils/construct_issue_name_text_value";
import Editor from "common_components/editor";

const styles = {
  greenIcon: {
    cursor: "pointer",
    marginLeft: "4px",
    width: "18px",
    height: "18px",
    color: "#00c853",
  },
  greyIcon: {
    cursor: "pointer",
    marginLeft: "4px",
    width: "18px",
    height: "18px",
    color: "#757575",
  },
  input: {
    borderRadius: 0,
    minHeight: "50px",
    padding: "10px",
  },
  textField: {
    margin: "0.75em 0",
    width: "100%",
  },
  inputProps: {
    style: {
      fontSize: "13px",
      fontWeight: 100,
    },
  },
  endAdornmentWrapper: {
    width: "18px",
    display: "flex",
    flexDirection: "column",
  },
  endAdornmentIconBlock: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "3px",
  },
  textFieldBlock: {
    marginBottom: "10px",
  },
  editorWrapper: {
    marginBottom: "10px",
  },
};

const TextFieldWithStateValue = ({
  issue,
  selectedReportId,
  onSave,
  fieldValue,
  correctDocumentIssueManually,
  lockedTextValue,
  fieldName,
  onRevert,
  defaultValue,
  label,
  isMarkdown,
}) => {
  const [value, onValueChange] = useState(fieldValue);
  const [maxRow, setMaxRow] = useState(4);
  const [showSaveIcon, setShowSaveIcon] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [onHover, setOnHover] = useState(false);
  const [isTrackChangeMode, setIsTrackChangeMode] = useState(false);
  const defaultValueString = defaultValue || "";
  const hasValueChanged = value !== defaultValueString;
  const textValueInput = useRef(null);
  const ref = useRef({clearEventOn: false});

  const showAndHideSaveIcon = () => {
    setShowSaveIcon(true);
    setTimeout(() => {
      setShowSaveIcon(false);
    }, 3000);
  };

  useEffect(() => {
    if (lockedTextValue) {
      onTrackChanges();
    }
  }, []);

  useEffect(() => {
    onValueChange(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    if (lockedTextValue) {
      onValueChange(fixLockedTextValue(lockedTextValue));
      setIsTrackChangeMode(true);
    } else {
      setIsTrackChangeMode(false);
    }
  }, [lockedTextValue]);

  const onTextFieldChange = e => onValueChange(e.target.value);
  const onHoverStart = () => setOnHover(true);
  const onHoverFinish = () => setOnHover(false);
  const onTrackChangesStart = () => setIsTrackChangeMode(true);
  const onTrackChangesFinish = () => setIsTrackChangeMode(false);
  const onTrackChanges = () => {
    onTrackChangesStart();
    setTimeout(() => {
      const textEnd = textValueInput.current.value.length;
      textValueInput.current.setSelectionRange(textEnd, textEnd);
      textValueInput?.current?.focus();
    }, 100);
  };

  const onBlur = e => {
    setMaxRow(4);
    setOnEdit(false);
    if (ref.current.clearEventOn) {
      ref.current.clearEventOn = false;
    } else {
      if (fieldValue !== value) {
        if (isTrackChangeMode || lockedTextValue) {
          onSaveWithTrackedChanges();
        } else {
          onSave(e);
          showAndHideSaveIcon();
        }
      } else {
        if (isTrackChangeMode) {
          onTrackChangesFinish();
        }
      }
    }
  };

  const onRevertTrackedChanges = () => {
    correctDocumentIssueManually(issue, {
      manual_corrections: getUpdatedManualCorrections(
        issue.manual_corrections,
        selectedReportId,
        fieldName,
        fieldValue,
        null,
        false,
        null,
        "revert",
      ),
    });
    onValueChange(defaultValue);
    onTrackChangesFinish();
  };

  const onSaveTrackedChanges = () => {
    const lockedValue = fixLockedTextValue(lockedTextValue);
    correctDocumentIssueManually(issue, {
      manual_corrections: getUpdatedManualCorrections(
        issue.manual_corrections,
        selectedReportId,
        fieldName,
        lockedValue,
        null,
        false,
        null,
        "delete",
      ),
    });
    onTrackChangesFinish();
  };

  const onSaveWithTrackedChanges = () => {
    correctDocumentIssueManually(issue, {
      manual_corrections: getUpdatedManualCorrections(
        issue.manual_corrections,
        selectedReportId,
        fieldName,
        fieldName === "issue_name"
          ? constructIssueNameTextValue(fieldValue, issue)
          : fieldValue,
        false,
        null,
        formatTrackedChanges(value, fieldValue),
      ),
    });
  };

  const onChangeRowsMax = () => {
    setMaxRow(10);
    setOnEdit(true);
  };

  const onRevertField = () => {
    ref.current.clearEventOn = true;
    onValueChange(defaultValueString);
    if (fieldValue) {
      onRevert();
    }
  };

  const onAbortField = () => onValueChange(fieldValue);
  const onAbortLockedText = () =>
    onValueChange(fixLockedTextValue(lockedTextValue));

  const onTextPaste = e => {
    e.preventDefault();
    const pastedText = getTextFromClipboard(e);
    const newTextValue = `${value.slice(
      0,
      e.target.selectionStart,
    )}${moveReferencesToTheTextLine(pastedText)}${value.slice(
      e.target.selectionEnd,
      value.length,
    )}`;
    onValueChange(newTextValue);
  };

  const onScroll = e => e.stopPropagation();

  const labelWithStatus = `${label}${hasValueChanged ? " *" : ""}${
    isTrackChangeMode ? " (Tracking changes)" : ""
  }`;

  return (
    <>
      {isMarkdown ? (
        <div style={styles.editorWrapper}>
          <Editor
            value={value}
            onChange={newValue =>
              onTextFieldChange({target: {value: newValue}})
            }
            onBlur={() => onBlur({target: {value}})}
            label={labelWithStatus}
          />
        </div>
      ) : (
        <TextField
          label={labelWithStatus}
          style={styles.textField}
          value={value}
          onClick={onChangeRowsMax}
          onChange={onTextFieldChange}
          onPaste={onTextPaste}
          onBlur={onBlur}
          onMouseEnter={onHoverStart}
          onMouseLeave={onHoverFinish}
          inputRef={textValueInput}
          multiline
          variant="outlined"
          rowsMax={maxRow}
          inputProps={styles.inputProps}
          InputProps={{
            endAdornment: (
              <div style={styles.endAdornmentWrapper}>
                {showSaveIcon ? (
                  <Tooltip title={"Saved!"} placement="top" arrow>
                    <CheckCircleIcon style={styles.greenIcon} />
                  </Tooltip>
                ) : null}

                {onHover &&
                !onEdit &&
                !isTrackChangeMode &&
                !lockedTextValue &&
                !showSaveIcon ? (
                  <Tooltip title="Track changes" placement="top" arrow>
                    <div style={{width: "18px"}} onMouseDown={onTrackChanges}>
                      <PendingActions style={styles.greyIcon} />
                    </div>
                  </Tooltip>
                ) : null}

                {lockedTextValue && onHover && !showSaveIcon ? (
                  <div style={styles.endAdornmentWrapper}>
                    {fixLockedTextValue(lockedTextValue) !== value ? (
                      <div style={styles.endAdornmentIconBlock}>
                        <Tooltip title={"Save changes"} placement="top" arrow>
                          <DoneIcon
                            onMouseDown={onBlur}
                            style={styles.greenIcon}
                          />
                        </Tooltip>
                        <Tooltip title={"Abort changes"} placement="top" arrow>
                          <CancelIcon
                            onMouseDown={onAbortLockedText}
                            style={styles.greyIcon}
                          />
                        </Tooltip>
                      </div>
                    ) : null}

                    <Tooltip title="Save tracked changes" placement="top" arrow>
                      <div
                        style={{width: "18px"}}
                        onMouseDown={onSaveTrackedChanges}
                      >
                        <SaveTrackedChanges style={styles.greyIcon} />
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={"Revert tracked changes"}
                      placement="top"
                      arrow
                    >
                      <ReplayIcon
                        onMouseDown={onRevertTrackedChanges}
                        style={styles.greyIcon}
                      />
                    </Tooltip>
                  </div>
                ) : null}

                {!lockedTextValue &&
                hasValueChanged &&
                fieldValue !== value &&
                !showSaveIcon ? (
                  <div style={styles.endAdornmentWrapper}>
                    <Tooltip title={"Save changes"} placement="top" arrow>
                      <DoneIcon onMouseDown={onBlur} style={styles.greenIcon} />
                    </Tooltip>
                    <Tooltip title={"Abort changes"} placement="top" arrow>
                      <CancelIcon
                        onMouseDown={onAbortField}
                        style={styles.greyIcon}
                      />
                    </Tooltip>
                  </div>
                ) : null}

                {onHover &&
                !lockedTextValue &&
                !isTrackChangeMode &&
                hasValueChanged &&
                !onEdit ? (
                  <Tooltip title={"Revert changes"} placement="top" arrow>
                    <ReplayIcon
                      onMouseDown={onRevertField}
                      style={styles.greyIcon}
                    />
                  </Tooltip>
                ) : null}
              </div>
            ),
            style: styles.input,
            onScroll,
          }}
        />
      )}
      {isTrackChangeMode && fieldValue !== value ? (
        <div style={styles.textFieldBlock}>
          <TextValue value={formatTrackedChanges(value, fieldValue)} />
        </div>
      ) : lockedTextValue ? (
        <div style={styles.textFieldBlock}>
          <TextValue
            value={
              isTrackChangeMode
                ? formatTrackedChanges(value, fieldValue)
                : lockedTextValue
            }
          />
        </div>
      ) : null}
    </>
  );
};

export default TextFieldWithStateValue;

import React from "react";

import EditModePanel from "./edit_mode_panel";

import Permissioner from "utils/permissioner";

class DocumentHeading extends React.Component {
  hasPermission = permission => {
    return new Permissioner(this.props.user).hasPermission(permission);
  };

  render() {
    return (
      <EditModePanel
        document={this.props.document}
        editModeOn={this.props.editModeOn}
        setEditModeOn={this.props.setEditModeOn}
        findAndReplace={this.props.findAndReplace}
        onFindAndReplaceChange={this.props.onFindAndReplaceChange}
        documentTextReplace={this.props.documentTextReplace}
        hideTextDeletions={this.props.hideTextDeletions}
        triggerHideTextDeletions={this.props.triggerHideTextDeletions}
        downloadDocument={this.props.downloadDocument}
        editableClauseId={this.props.editableClauseId}
        editableClausepart={this.props.editableClausepart}
        onAddClauseBeforeCurrent={this.onAddClauseBeforeCurrent}
        onAddClauseAfterCurrent={this.onAddClauseAfterCurrent}
        onDeleteClause={this.onDeleteClause}
        onAddClausepartBeforeCurrent={this.onAddClausepartBeforeCurrent}
        onAddClausepartAfterCurrent={this.onAddClausepartAfterCurrent}
        onDeleteClausepart={this.onDeleteClausepart}
        hasDownloadPermission={this.hasPermission("download-altered-document")}
        documentHasChanges={this.props.documentHasChanges}
        onRevertDocumentChangesDialogShow={
          this.props.onRevertDocumentChangesDialogShow
        }
      />
    );
  }

  onAddClausepartBeforeCurrent = e => {
    e.stopPropagation();
    this.onAddClausepart("before");
  };

  onAddClausepartAfterCurrent = e => {
    e.stopPropagation();
    this.onAddClausepart();
  };

  onAddClausepart = position => {
    const {editableClausepart} = this.props;
    const pathToUse = position === "before" ? "previousPath" : "currentPath";
    if (editableClausepart && editableClausepart[pathToUse]) {
      this.props.showAddClausepartEditor(
        editableClausepart.documentClauseId,
        editableClausepart[pathToUse],
      );
    }
  };

  onDeleteClausepart = e => {
    e.stopPropagation();
    const {editableClausepart} = this.props;
    if (
      editableClausepart &&
      editableClausepart.childrenData &&
      editableClausepart.childrenPaths
    ) {
      const editableClause = findDocumentClauseById(
        this.props.editableClauseId || editableClausepart.documentClauseId,
        this.props.documentClauses,
      );
      let subheading;
      if (
        editableClausepart.clausePartNumber === 1 &&
        editableClausepart.level > 1
      ) {
        subheading = this.props.documentHeadings.find(
          heading =>
            editableClause.section_id === heading.section_id &&
            editableClausepart.reference === heading.reference,
        );
      }
      this.props.deleteClauseparts(
        editableClause,
        editableClausepart.childrenPaths,
        editableClausepart.childrenData,
        subheading,
      );
    }
  };

  onAddClauseBeforeCurrent = e => {
    e.stopPropagation();
    this.onAddClause("before");
  };

  onAddClauseAfterCurrent = e => {
    e.stopPropagation();
    this.onAddClause();
  };

  onAddClause = position => {
    const editableClause = findDocumentClauseById(
      this.props.editableClauseId ||
        this.props.editableClausepart.documentClauseId,
      this.props.documentClauses,
    );
    if (editableClause) {
      this.props.showAddClauseEditor(
        editableClause,
        position === "before" ? -1 : 1,
      )();
    }
  };

  onDeleteClause = e => {
    e.stopPropagation();
    const editableClause = findDocumentClauseById(
      this.props.editableClauseId,
      this.props.documentClauses,
    );
    if (editableClause) {
      this.props.deleteClause(editableClause.section_id, editableClause.id)();
    }
  };
}

function findDocumentClauseById(clauseId, documentClauses) {
  let documentClausesArray = [];
  Object.keys(documentClauses).forEach(sectionId => {
    documentClausesArray = documentClausesArray.concat(
      documentClauses[sectionId],
    );
  });
  return documentClausesArray.find(clause => clause.id === clauseId);
}

export default DocumentHeading;

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function userUpdate(requestor) {
  return createReduxPromiseAction(ActionTypes.USER_UPDATE, (userId, data) => {
    if (!data.last_edited) {
      throw new Error("data must contain a last_edited key");
    }

    return requestor
      .patch(`${basePath}/user/${userId}`, data, {
        withCredentials: true,
      })
      .then(response => response.data);
  });
}

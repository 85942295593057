const findIndexClauseId = (clauseparts, selection, isStart) => {
  let index;
  if (selection.startsWith("HEADER_", 0)) {
    index = clauseparts.findIndex(
      item => item.clause_id === Number(selection.substr(7)),
    );
    index = isStart ? index : index - 1;
  } else {
    index = clauseparts.findIndex(item => item.id === Number(selection));
  }
  return index;
};

export default findIndexClauseId;

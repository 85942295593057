import {Document, Paragraph, TextRun, AlignmentType} from "docx";
import moment from "moment";

function constructDocument() {
  const doc = new Document({
    numbering: {
      config: [
        {
          reference: "numbering",
          levels: [
            {
              level: 0,
              format: "decimal",
              text: "%2.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: {
                    // left: 720,
                    hanging: 260,
                  },
                },
              },
            },
          ],
        },
      ],
    },
  });
  doc.addSection({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: "Notice of Assignment",
            bold: true,
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "To: Software Ventures PLC as Borrower",
          }),
        ],
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "From: B Bank",
          }),
        ],
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "CC: C Bank, A Bank as Agent",
          }),
        ],
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: `Date: ${moment().format("MMMM Do YYYY")}`,
          }),
        ],
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text:
              'Software Ventures PLC – facilities agreement dated 31st December, 2019 (the "Agreement")',
            bold: true,
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: {after: 300},
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 0,
        },
        children: [
          new TextRun({
            text:
              "We refer to the Agreement. Terms defined in the Agreement have the same meaning in this Notice of Assignment unless given a different meaning in this Notice of Assignment.",
          }),
        ],
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 0,
        },
        children: [
          new TextRun({
            text:
              "In accordance with the Agreement, we hereby give you notice that we have assigned all of our rights under the Agreement to C Bank.",
          }),
        ],
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 0,
        },
        children: [
          new TextRun({
            text:
              "This notice of assignment and any non-contractual obligations arising out of or in connection with it are governed by English law.",
          }),
        ],
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "B Bank",
          }),
        ],
        spacing: {after: 100},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "By:...............................................",
          }),
        ],
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "We acknowledge receipt of this notice of assignment",
          }),
        ],
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "C Bank",
          }),
        ],
        spacing: {after: 100},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "By:...............................................",
          }),
        ],
        spacing: {after: 300},
      }),
    ],
  });
  return doc;
}

export default constructDocument;

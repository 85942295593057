import React from "react";

import convertContentsToText from "common/utils/contents/convert_contents_to_text";
import DraftingExamples from "./components/drafting_examples";
import LanguageText from "./components/language_text";
import getContents from "./utils/get_contents";

export default function LanguageDraftingExamples({
  issue,
  user,
  isFlipChecklistIcons,
  addIssueResponse,
  saveIssueResponse,
  deleteIssueResponse,
  insertIssueResponseIntoDocument,
  zoom,
  currentIssuesetItem,
}) {
  function getValue(issueResponse) {
    return convertContentsToText(
      getContents(issueResponse.language || []),
      true,
    );
  }

  function getComponent(issueResponse, limit) {
    return (
      <LanguageText language={issueResponse.language || []} limit={limit} />
    );
  }

  return (
    <DraftingExamples
      title="Drafting Examples"
      user={user}
      issue={issue}
      getValue={getValue}
      getComponent={getComponent}
      isFlipChecklistIcons={isFlipChecklistIcons}
      noItemsLabel="You currently have no drafting notes"
      addButtonCaption="Add Drafting Example"
      addIssueResponse={addIssueResponse}
      saveIssueResponse={saveIssueResponse}
      deleteIssueResponse={deleteIssueResponse}
      insertIssueResponseIntoDocument={insertIssueResponseIntoDocument}
      zoom={zoom}
      currentIssuesetItem={currentIssuesetItem}
    />
  );
}

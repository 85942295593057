import React from "react";
import {cloneDeep, isEqual, sortBy} from "lodash";

import RegexEditor from "utils/topicparameters/editor/regex_editor";
import ValueEditor from "./value_editor/";

import Toggle from "material-ui/Toggle";

const styles = {
  toggleMain: {
    width: "unset",
  },
};

function initialiseFilters() {
  return ["number", "percentage"];
}

function initialise() {
  return {
    whitelist: [],
    blacklist: [],
    filters: initialiseFilters(),
    exclude_values: [],
  };
}

function validate() {
  return true;
}

class Renderer extends RegexEditor.renderer {
  renderExcludeValues = () => {
    const {value, diffValue} = this.props;
    const exclude_values =
      (diffValue && diffValue.exclude_values) ||
      (value && value.exclude_values) ||
      [];

    return (
      <div style={{margin: "1rem 0"}}>
        <div style={{marginBottom: "0.5rem", fontWeight: "500"}}>
          exclude values
        </div>
        <ValueEditor
          values={exclude_values}
          onValuesChange={this.updateExcludedValues}
          mainContainerStyles={{
            backgroundColor:
              diffValue &&
              diffValue.live_exclude_values &&
              !isEqual(diffValue.live_exclude_values, diffValue.exclude_values)
                ? "#fff9c4"
                : "inherit",
          }}
        />
      </div>
    );
  };

  renderFilters = () => {
    const {value, diffValue} = this.props;
    const filters =
      (diffValue && diffValue.filters) ||
      (value && value.filters) ||
      initialiseFilters();
    return (
      <div
        style={{
          display: "flex",
          marginBottom: "0.7rem",
          width: 356,
          backgroundColor:
            diffValue &&
            diffValue.live_filters &&
            !isEqual(sortBy(diffValue.live_filters), sortBy(filters))
              ? "#fff9c4"
              : "inherit",
        }}
      >
        <Toggle
          label="Plain numbers"
          defaultToggled={Boolean(filters.find(type => type === "number"))}
          onToggle={this.updateNumberToggle}
          style={styles.toggleMain}
        />
        <Toggle
          label="Percentages"
          defaultToggled={Boolean(filters.find(type => type === "percentage"))}
          onToggle={this.updatePercentageToggle}
          style={{...styles.toggleMain, marginLeft: 24}}
        />
      </div>
    );
  };

  updateExcludedValues = excludeValues => {
    const {value, onChange, diffValue} = this.props;

    const newValue = cloneDeep(value);
    newValue.exclude_values = excludeValues;

    const newDiffValue = cloneDeep(diffValue || value);
    newDiffValue.exclude_values = excludeValues;

    if (!newDiffValue.live_exclude_values) {
      newDiffValue.live_exclude_values = value.exclude_values;
    } else if (
      newDiffValue.live_exclude_values &&
      isEqual(newDiffValue.live_exclude_values, newValue.exclude_values)
    ) {
      delete newDiffValue.live_exclude_values;
    }
    onChange(newValue, null, newDiffValue);
  };

  updateFilters(key, use) {
    const {value, onChange, diffValue} = this.props;
    const newValue = cloneDeep(value);
    let filters = newValue.filters || initialiseFilters();
    filters = filters.filter(type => type !== key);
    if (use) {
      filters.push(key);
    }
    newValue.filters = filters;

    const newDiffValue = cloneDeep(diffValue || value);
    newDiffValue.filters = filters;

    if (!newDiffValue.live_filters) {
      newDiffValue.live_filters = value.filters;
    } else if (
      newDiffValue.live_filters &&
      isEqual(newDiffValue.live_filters, newValue.filters)
    ) {
      delete newDiffValue.live_filters;
    }

    onChange(newValue, null, newDiffValue);
  }
  updateNumberToggle = (event, value) => {
    this.updateFilters("number", value);
  };
  updatePercentageToggle = (event, value) => {
    this.updateFilters("percentage", value);
  };
}

export default {
  initialise,
  validate,
  renderer: Renderer,
};

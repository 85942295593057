import React, {useState} from "react";
import Checkbox from "@material-ui/core/Checkbox";

function WordSelectedClauses({
  documentClauseparts,
  usedClauses,
  selectedClauses,
  save,
}) {
  const [selectedClauseparts, setSelectedClauseparts] = useState({});
  const clausesToUse = selectedClauses.filter(
    item => !usedClauses.find(usedClause => usedClause.id === item.id),
  );
  if (!clausesToUse.length) {
    return null;
  }

  const toggleClauseSelection = clausepartId => () => {
    const currentValue = selectedClauseparts[clausepartId];
    const isEmpty = currentValue === undefined;
    const newValue = isEmpty ? false : !currentValue;
    setSelectedClauseparts({...selectedClauseparts, [clausepartId]: newValue});
  };

  const isSelected = clauseId => {
    return (
      selectedClauseparts[clauseId] === undefined ||
      selectedClauseparts[clauseId]
    );
  };

  const addSelectedClauses = () => {
    save(
      selectedClauses
        .filter(clause => isSelected(clause.id))
        .map(clause => clause.id),
    );
  };

  const clausesToAdd = clausesToUse.filter(clause => isSelected(clause.id));
  return (
    <div
      style={{
        marginTop: "10px",
        border: "1px solid #888",
        backgroundColor: "#fafafa",
        padding: "5px",
      }}
    >
      <div
        style={{
          fontWeight: "400",
          textAlign: "center",
          color: "black",
          padding: "4px 0",
        }}
      >
        Add Selected Clauses to Report
      </div>
      <div style={{fontSize: "12px", marginLeft: "10px"}}>
        {clausesToUse.map((clause, index) => {
          const clausepart = documentClauseparts.find(
            cp => cp.id === clause.id,
          );
          const text = clausepart.partial_text;
          return (
            <div key={index} style={{marginBottom: "5px"}}>
              <Checkbox
                checked={isSelected(clause.id)}
                color="primary"
                onChange={toggleClauseSelection(clause.id)}
                style={{padding: 0}}
              />
              <span style={{marginRight: "0.5em"}}>{clausepart.reference}</span>
              <span>
                {text.substring(0, 100)}
                {text.length > 100 ? "…" : ""}
              </span>
            </div>
          );
        })}
      </div>
      <button
        style={{
          fontWeight: "400",
          border: "1px solid black",
          textAlign: "center",
          backgroundColor: clausesToAdd.length ? "#2196f3" : "#555",
          color: "white",
          marginTop: "10px",
          padding: "4px 0",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={addSelectedClauses}
      >
        ADD {clausesToAdd.length} clauses
      </button>
    </div>
  );
}

export default WordSelectedClauses;

import React from "react";

import DraftingExamples from "./components/drafting_examples";

export default function CommentDraftingExamples({
  issue,
  user,
  addIssueResponse,
  saveIssueResponse,
  deleteIssueResponse,
  isFlipChecklistIcons,
  zoom,
  currentIssuesetItem,
}) {
  function getValue(issueResponse) {
    return issueResponse.predefined_comment && !issueResponse.language
      ? issueResponse.predefined_comment
      : null;
  }

  return (
    <DraftingExamples
      issue={issue}
      user={user}
      getValue={getValue}
      title="Standard Comments"
      hiddenFields={["document_text"]}
      isFlipChecklistIcons={isFlipChecklistIcons}
      noItemsLabel="You currently have no standard coments"
      hideCopyComment={true}
      addButtonCaption="Add Comment"
      addIssueResponse={addIssueResponse}
      saveIssueResponse={saveIssueResponse}
      deleteIssueResponse={deleteIssueResponse}
      zoom={zoom}
      currentIssuesetItem={currentIssuesetItem}
    />
  );
}

const {pureReplacements} = require("./replacements/pure");
const {getPure} = require("./getPure");

// it creates a list of replacements in the string str according to replacement patterns;
// the order in replacement patterns list is important,
// most significant replacement patterns should be first
function getReplacementMatches(str, replacementList) {
  const replacementMatches = replacementList.reduce((acc, {from, to, type}) => {
    const newAcc = [...acc];
    const replacementRegexp = new RegExp(from, "ig");
    let replacementMatch;
    while ((replacementMatch = replacementRegexp.exec(str))) {
      replacementMatch.to = to;
      replacementMatch.type = type;
      if (!matchOverlap(newAcc, replacementMatch)) {
        newAcc.push(replacementMatch);
      }
    }
    return newAcc;
  }, []);
  return replacementMatches;
}

// test overlapping of the section (start, end) with any list item [(start, end)]
function matchOverlap(all, match) {
  const startMatch = match.index;
  const endMatch = match.index + match[0].length;
  for (const any of all) {
    const startAny = any.index;
    const endAny = any.index + any[0].length;
    if (
      (startMatch >= startAny || endMatch > startAny) &&
      (startMatch < endAny || endMatch <= endAny)
    ) {
      return true;
    }
  }
  return false;
}

function sortReplacementMatches(replacementMatches) {
  const sortedReplacementMatches = replacementMatches.sort(
    ({index: index1}, {index: index2}) =>
      index1 < index2 ? -1 : index1 === index2 ? 0 : 1,
  );
  return sortedReplacementMatches;
}

function makeReplacementPositions(sortedReplacementMatches) {
  let position = 0;
  let prevReplacementPosition;
  const replacementPositions = sortedReplacementMatches.map(match => {
    const {index, to, type} = match;
    const from = match[0];
    const shift = from.length - to.length;
    position +=
      index -
      (prevReplacementPosition
        ? prevReplacementPosition.index + prevReplacementPosition.shift
        : 0);
    const replacementPosition = {index, position, shift, from, to, type};
    prevReplacementPosition = replacementPosition;
    return replacementPosition;
  });
  return replacementPositions;
}

function mixReplacements(replacementsToFix = [], replacementsToAdd = []) {
  if (replacementsToAdd && replacementsToAdd.length > 0) {
    let fixedReplacements = [...replacementsToFix];
    for (const replacementToAdd of replacementsToAdd) {
      if (replacementToAdd) {
        const {position: positionToAdd, shift} = replacementToAdd;
        if (shift) {
          fixedReplacements = fixedReplacements.map(replacement => {
            if (
              replacement &&
              replacement.position &&
              replacement.position > positionToAdd
            ) {
              return {...replacement, position: replacement.position - shift};
            }
            return replacement;
          });
        }
      }
    }
    return [...fixedReplacements, ...replacementsToAdd];
  }
  return replacementsToFix;
}

export function getReplacementPositions(
  initialStr,
  ...initialReplacementLists
) {
  if (typeof initialStr === "string") {
    const replacementLists =
      initialReplacementLists.length > 0
        ? initialReplacementLists
        : [pureReplacements];
    let str = initialStr;
    const allReplacementPositions = replacementLists.reduce(
      (acc, replacementList) => {
        const replacementMatches = getReplacementMatches(str, replacementList);
        const sortedReplacementMatches = sortReplacementMatches(
          replacementMatches,
        );
        const replacementPositions = makeReplacementPositions(
          sortedReplacementMatches,
        );
        const newAcc = mixReplacements(acc, replacementPositions);
        str = getPure(str, replacementList);
        return newAcc;
      },
      [],
    );
    return allReplacementPositions;
  }
  return [];
}

import stateObserver, {reset as resetStateObserver} from "state_observer";

export default function(fn) {
  let transition;
  const transitionManager = {
    getTransition: store => {
      if (!transition) {
        transition = fn(stateObserver(store));
      }
      return transition;
    },
    reset: () => {
      transition = null;
    },
    resetAll: () => {
      resetStateObserver();
      transitionManager.reset();
    },
  };
  return transitionManager;
}

import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import {ActionDefinitionsFetchAction} from "../actions/action_definitions_fetch";
import actionTypes from "../constants/action_types";
import {ActionDefinition} from "common/flowmaster/types/task_config";

export default function actionDefinitionsReducer(
  state: MaybeUninitialised<ActionDefinition[]> = UNINITIALISED,
  action: ActionDefinitionsFetchAction,
): typeof state {
  if (action.type === actionTypes.ACTION_DEFINITION_FETCH.SUCCESS) {
    return (action as ActionDefinitionsFetchAction).payload;
  }
  return state;
}

import React from "react";

export default ({text, rootStyle = {}, highlightedStyle = {}}) => {
  const tags = [];
  const bSplitArray = text.split("<b>");
  if (bSplitArray.length > 0) {
    bSplitArray[0] && tags.push(<span key="0">{bSplitArray[0]}</span>);
    if (bSplitArray.length > 1) {
      bSplitArray.slice(1).forEach((bElement, key) => {
        const [bText, etcText] = bElement.split("</b>");
        bText &&
          tags.push(
            <span
              key={`${key + 1}-1`}
              style={highlightedStyle}
              className="highlight"
            >
              {bText}
            </span>,
          );
        etcText && tags.push(<span key={`${key + 1}-2`}>{etcText}</span>);
      });
    }
  }
  return <div style={rootStyle}>{tags}</div>;
};

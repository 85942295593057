/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-param-type */
/* eslint-disable jsdoc/require-returns */
import isIssueAlert from "common/utils/issues/is_issue_alert";
import {GroupedIssueItem, ReportIssue} from "common/types/issue";
import {RagThreshold} from "common/types/project";

/**
 * This type represents the overall RAG state of a document, as an aggregate of
 * the RAG states of its issues.
 */
type DocumentRagState = {
  color: string;
  score: number;
  totalMaxRagScore: number;
};

function calculateDocumentRagState(
  issues: Array<GroupedIssueItem | ReportIssue>,
  threshold: RagThreshold = {red: 0.25, amber: 0.5},
  useOverrides = true,
): DocumentRagState {
  const {totalMaxRagScore, score} = getScore(issues, useOverrides);

  return {
    color: getColor(score, threshold),
    totalMaxRagScore,
    score,
  };
}

function isAlert(issue, useOverrides) {
  if (issue.trigger_type === "LLM") {
    return (
      issue.llm_response?.rag_state === "red" ||
      issue.llm_response?.rag_state === "amber"
    );
  }
  if (useOverrides) {
    if (issue?.manual_corrections?.global?.new_state === "alert") {
      return true;
    } else if (issue?.manual_corrections?.global?.new_state === "non-alert") {
      return false;
    }
  } else if ("is_alert" in issue) {
    return issue.is_alert;
  }
  return isIssueAlert(issue);
}

function getScore(
  allIssues: Array<GroupedIssueItem | ReportIssue>,
  useOverrides: boolean,
) {
  const issues = allIssues.filter(
    issue =>
      issue.issue_class_id !== 2 ||
      (useOverrides ? issue?.manual_corrections?.global?.new_state : false),
  );
  const totalMaxRagScore = issues.reduce(
    (max, issue) => max + parseInt(issue.max_rag_score.toString(), 10),
    0,
  );
  const totalRagScore = issues.reduce(
    (max, issue) =>
      max +
      (isAlert(issue, useOverrides)
        ? parseInt(issue.rag_score.toString(), 10)
        : 0),
    0,
  );

  const score =
    totalMaxRagScore === 0
      ? // Not sure what this case likely represents. Probably ambiguous, so
        // any default policy we implement here may be misleading some of the
        // time.
        0
      : // Inverse of proportion of total CURRENT rag score to total MAX rag
        // score. Basically, "how FEW points we got, given how many we could
        // have had", or "how GOOD the score is".
        1 - totalRagScore / totalMaxRagScore;
  return {totalMaxRagScore, score};
}

function getColor(score: number, threshold: RagThreshold) {
  if (score <= threshold.red) {
    return "red";
  }
  if (score <= threshold.amber) {
    return "amber";
  }
  return "green";
}

export default calculateDocumentRagState;

import React from "react";
import _ from "underscore";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";

import MenuItem from "./upload_document_components/menus/upload_menu_item";

import getIssuesets from "utils/issuesets/get_project_issuesets";
import issuesetUtils from "common/utils/issues/issueset_utils";

const styles = {
  actions: {
    zIndex: 0,
  },
};

class SelectChecklistDialog extends React.Component {
  constructor(props) {
    super(props);
    let issuesets = [];
    if (props.documentIssuesets && props.documentIssuesets.length > 0) {
      const issuesetsById = issuesetUtils.getIssuesetsById(
        props.contractTypesById,
      );
      issuesets = issuesetUtils.constructIssuesetSelectorItems(
        props.documentIssuesets.map(issuesetId => issuesetId),
        issuesetsById,
      );
    }
    this.state = {issuesets};
  }

  render() {
    return (
      <Dialog
        title="Select Checklist"
        className="dialog"
        onDismiss={this.props.onDismiss}
        open={true}
        actions={[
          <FlatButton
            label="Cancel"
            key="select-checklist-dialog-cancel-button"
            primary={true}
            onClick={this.props.onDismiss}
            style={styles.actions}
          />,
          <FlatButton
            label="Continue"
            key="select-checklist-dialog-continue-button"
            primary={true}
            onClick={this.onSelectCheckboxFinish}
            style={styles.actions}
            disabled={this.state.issuesets.length === 0}
          />,
        ]}
        bodyStyle={{overflowY: "inherit"}}
      >
        <div style={{maxHeight: "24rem", overflow: "auto"}}>
          {getIssuesets(this.props.project, this.props.contractTypesById).map(
            option => (
              <MenuItem
                key={option.id}
                id={option.id}
                selectedItemId={
                  (
                    this.state.issuesets.find(
                      issueset => issueset.id === option.id,
                    ) || {}
                  ).id
                }
                selectItem={this.onItemSelect(option)}
                multiselect={true}
              >
                {option.name}
              </MenuItem>
            ),
          )}
        </div>
      </Dialog>
    );
  }

  onItemSelect = option => () => {
    const isSelected = this.state.issuesets.find(
      issueset => issueset.id === option.id,
    );
    if (isSelected) {
      this.setState(prevState => ({
        issuesets: prevState.issuesets.filter(
          issueset => issueset.id !== option.id,
        ),
      }));
    } else {
      this.setState(prevState => ({
        issuesets: [...prevState.issuesets, option],
      }));
    }
  };

  getIssuesetOptions = () => {
    const {
      id: projectDefaultContractTypeId,
    } = this.props.project.default_contract_type;
    const issuesetsById = issuesetUtils.getIssuesetsById(
      this.props.contractTypesById,
      projectDefaultContractTypeId,
    );
    const {issuesets: projectIssuesets} = this.props.project;
    if (projectIssuesets && projectIssuesets.length > 0) {
      return issuesetUtils.constructIssuesetSelectorItems(
        projectIssuesets,
        issuesetsById,
      );
    }
    return issuesetUtils.constructIssuesetSelectorItems(
      Object.keys(issuesetsById).map(issuesetId => parseInt(issuesetId, 10)),
      issuesetsById,
    );
  };

  onSelectCheckboxFinish = () => {
    const issuesetIds = _.chain(this.state.issuesets)
      .map(issueset => issueset.id)
      .sortBy(item => item)
      .value();
    const baseDocumentIssuesetIds = _.sortBy(
      this.props.documentIssuesets,
      item => item,
    );
    if (_.isEqual(issuesetIds, baseDocumentIssuesetIds)) {
      this.props.onDismiss();
    } else {
      this.props.onFinish(issuesetIds);
    }
  };
}

export default SelectChecklistDialog;

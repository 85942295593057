import React, {Component} from "react";

import Popover from "@material-ui/core/Popover";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Forum from "@material-ui/icons/Forum";

import {getUsername} from "utils/user";
import Comment from "./comment";
import CommentTextEditor from "./comment_text_editor";
import BoxWithArrow from "./box_with_arrow";

const id = "COMMENTS-POPOVER";

const styles = {
  rootAdd: {
    position: "absolute",
    right: "3px",
  },
  rootList: {},
  popover: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: ".3em",
    width: "330px",
  },
  icon: {
    color: "#999",
    cursor: "pointer",
  },
  topContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  username: {
    margin: ".3em 0",
    fontSize: ".9em",
    fontStyle: "italic",
  },
  iconWithLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  iconLabel: {
    marginLeft: ".3em",
  },
};

export default class CommentButton extends Component {
  state = {
    anchorEl: null,
    text: "",
    external: true,
  };

  render() {
    const {props, state} = this;
    const {comments} = props;
    const hasComments = comments && comments.length > 0;
    if (!props.isHovered && !hasComments && !state.anchorEl) {
      return null;
    }
    const iconStyle = {...styles.icon};
    if (hasExternalComment(props.comments)) {
      iconStyle.color = "#2196f3";
    }
    return (
      <div style={props.shouldShowLabel ? styles.rootList : styles.rootAdd}>
        {props.shouldShowLabel ? (
          <div onClick={this.handleOpen} style={styles.iconWithLabel}>
            <Forum
              style={{
                ...styles.icon,
                color: "#2196f3",
              }}
            />
            <span style={styles.iconLabel}>{comments.length} Comments</span>
          </div>
        ) : (
          <BoxWithArrow onClick={this.handleOpen}>
            <Forum
              style={{...styles.icon, margin: "1rem .7rem .5rem"}}
              fontSize="small"
            />
          </BoxWithArrow>
        )}
        <Popover
          id={id}
          anchorEl={state.anchorEl}
          open={Boolean(state.anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div style={styles.popover}>
            {hasComments ? (
              props.comments.map(comment => (
                <Comment
                  key={comment.id}
                  comment={comment}
                  onDelete={props.deleteClausepartComment(comment.id)}
                  onUpdate={props.updateClausepartComment(comment)}
                  hasDeletePermission={
                    props.hasDeleteClausepartCommentPermission
                  }
                  hasEditPermission={props.hasEditClausepartCommentPermission}
                />
              ))
            ) : (
              <div style={styles.topContainer}>
                <span style={styles.username}>{getUsername(props.user)}</span>
                <FormControlLabel
                  control={
                    <Switch
                      size="small"
                      color="primary"
                      checked={state.external}
                      onChange={() => this.toggleExternal()}
                      label="External"
                    />
                  }
                  label="External"
                />
              </div>
            )}
            <CommentTextEditor
              id="add-comment"
              label={hasComments ? "Add Reply" : "Add Comment"}
              confirmLabel="Comment"
              declineLabel="Close"
              text={state.text}
              onChange={this.onTextChange}
              onConfirm={this.addComment}
              onDecline={this.clearText}
            />
          </div>
        </Popover>
      </div>
    );
  }

  handleClose = () =>
    this.setState(
      () => ({anchorEl: null}),
      () => {
        const {onClose} = this.props;
        if (typeof onClose === "function") {
          onClose();
        }
      },
    );

  handleOpen = e => {
    e.persist();
    this.setState(
      () => ({anchorEl: e.target}),
      () => {
        if (this.state.anchorEl) {
          const {onOpen} = this.props;
          if (typeof onOpen === "function") {
            onOpen();
          }
        }
      },
    );
  };

  toggleExternal = () => {
    this.setState({external: !this.state.external});
  };

  onTextChange = event => this.setState({text: event.target.value});
  addComment = () => {
    const {onSave} = this.props;
    if (typeof onSave === "function") {
      const {text, external} = this.state;
      onSave(text, external);
      this.setState({text: "", external: true});
    }
  };
  clearText = () => this.setState(() => ({text: ""}), this.handleClose);
}

function hasExternalComment(comments) {
  return comments.reduce((acc, comment) => acc || comment.external, false);
}

import _ from "underscore";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import IssueItemIconMenu from "common_components/checklist/issue_item_icon_menu";
import issueHeaderUtils from "utils/issues/issue_header_utils";
import {getCommonDefinitionApplicableClauses} from "utils/issues/common_definitions";

const useStyles = makeStyles({
  listItem: ({isSmallerSize}) => ({
    position: "relative",
    backgroundColor: "#fff",
    marginRight: "12px",
    marginTop: "6px",
    marginBottom: "6px",
    overflow: "visible",
    border: `1px ${isSmallerSize ? "dashed" : "solid"} #bbb`,
    "& *": isSmallerSize
      ? {
          fontSize: ".92rem",
        }
      : {},
  }),
  listItemIcon: {
    minWidth: "40px",
  },
  issueIcon: {
    color: "#f00",
  },
  warningIcon: {
    color: "#f80",
  },
  issueStatistic: ({isSmallerSize}) => ({
    border: `1px ${isSmallerSize ? "dashed" : "solid"} #bbb`,
    borderRadius: ".7rem",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    position: "absolute",
    right: "0",
    bottom: "-.8em",
    zIndex: "2",
    backgroundColor: "#fff",
  }),
});

export default function(props) {
  const {
    project,
    name,
    issue,
    currentIssuesetItem: issueset,
    showIssueDetail,
    selectedReportId,
    currentIssuesetItem,
    showIssuesInChecklist,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    document,
  } = props;
  const {number, amount} = getIssuePositionStatistic(
    issue,
    issueset,
    props.clausepart,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    document?.parties ?? [],
  );
  const isSmallerSize = number > 1;

  const classes = useStyles({isSmallerSize});

  const issueIconName = issueHeaderUtils.getIconName(
    issue,
    currentIssuesetItem,
    showIssuesInChecklist,
    project,
  );

  const IssueIcon = issueHeaderUtils.getIcon(issueIconName, selectedReportId);

  if (!IssueIcon) {
    return null;
  }

  const issueIconColor = issueHeaderUtils.getIconColor(
    issue,
    currentIssuesetItem,
    project,
    showIssuesInChecklist,
    false, // displayHiddenIssues
  );

  const iconStyles = {
    width: 24,
    height: 24,
    color: issueIconColor,
    flexShrink: 0,
  };

  return (
    <MenuItem
      key={issue.id}
      classes={{root: classes.listItem}}
      onClick={() => {
        showIssueDetail({...issue, name: name.replace(/^.*?\//, "")});
      }}
    >
      <IssueItemIconMenu
        Icon={IssueIcon}
        iconStyles={iconStyles}
        project={project}
        issue={issue}
        selectedReportId={selectedReportId}
        disabled={true}
        user={props.user}
        issueset={currentIssuesetItem}
      />
      <ListItemText
        primary={
          <div>
            {isSmallerSize || (
              <div style={{fontSize: "12px", color: "#aaa"}}>
                {getFirstPartIssueName(name)}
              </div>
            )}
            <div>{getSecondPartIssueName(name)}</div>
            {amount > 1 && (
              <div
                className={classes.issueStatistic}
              >{`${number} of ${amount}`}</div>
            )}
          </div>
          // issue.display_name
          // getSecondPartIssueName(
          //   props.areIssuesCollapsed ? filterName(issue.display_name) : issue.display_name,
          // )
        }
      />
    </MenuItem>
  );
}

function getFirstPartIssueName(issueName) {
  const issueNameParts = issueName.split("/");
  if (issueNameParts.length >= 3) {
    return `${issueNameParts[0]} / ${issueNameParts[1]}`;
  }
  if (issueNameParts.length === 2) {
    return issueNameParts[0];
  }
  return "";
}

function getSecondPartIssueName(issueName) {
  const issueNameParts = issueName.split("/");
  return issueNameParts[issueNameParts.length - 1].replace(/\[.*\]/, "").trim();
}

function getIssuePositionStatistic(
  issue,
  issueset,
  clausepart,
  documentClauses,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  parties,
) {
  if (!clausepart || !issue || !issue.applicable_clauses) {
    return {number: 0, amount: 0};
  }
  const applicableClauses = getApplicableClauses(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    parties,
  );
  const groupedClauses = groupClauses(applicableClauses);
  const number = groupedClauses.findIndex(({ids}) =>
    ids.find(({id}) => id === clausepart.id),
  );
  const amount = groupedClauses.length;
  const stats = {number: number + 1, amount};
  return stats;
}

function getApplicableClauses(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  documentClauseparts,
  parties,
) {
  const normalApplicableClauses =
    issue.applicable_clauses?.[issueset.master_id]?.[
      issueset.client_id ?? "master"
    ] ?? [];
  const commonDefinitionApplicableClauses = getCommonDefinitionApplicableClauses(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    parties,
  );
  return _.uniq(
    [...normalApplicableClauses, ...commonDefinitionApplicableClauses],
    item => item.id,
  );
}

function groupClauses(applicableClauses) {
  const groupedClauses = [];
  for (const clause of applicableClauses) {
    if (clause.partial_text) {
      if (groupedClauses.length > 0 && isNextClause(clause, groupedClauses)) {
        groupedClauses[groupedClauses.length - 1].ids.push(
          getGroupedClausesItem(clause),
        );
      } else {
        groupedClauses.push({
          ids: [getGroupedClausesItem(clause)],
          clauseId: clause.clause_id,
        });
      }
    }
  }
  return groupedClauses;
}

function getGroupedClausesItem(clause) {
  return {
    id: clause.id,
    order: clause.order,
    is_next_conjunction: clause.is_next_conjunction,
  };
}

function isNextClause(clause, groupedClauses) {
  // clausepart are neighbours if the distance between their order is 1
  // or 2 (conjunction case)
  // @TODO find a better way to determine conjunction
  if (groupedClauses.length === 0) {
    return false;
  }
  const {ids, clauseId} = groupedClauses[groupedClauses.length - 1];
  const next = ids[ids.length - 1];
  if (!clause.order || !next.order) {
    return false;
  }
  if (!clause.clause_id || clause.clause_id !== clauseId) {
    return false;
  }
  const diff = Math.abs(clause.order - next.order);
  const thresh = next.is_next_conjunction ? 2 : 1;
  return diff <= thresh;
}

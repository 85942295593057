import escapeRegex from "utils/escape_regex";

const excludeWords = [
  "on",
  "in",
  "after",
  "or",
  "and",
  "of",
  "is",
  "are",
  "set",
  "get",
  "has",
  "have",
  "as",
];

// indicators - array of strings e.g. ["term", "clause", "article"]
function getReferenceIndicatorFromText(text, indicators) {
  if (!indicators || indicators.length === 0 || !text) {
    return {};
  }
  const indicatorsStr = indicators
    .filter(item => item)
    .map(item => escapeRegex(item))
    .join("|");
  const testRegex = new RegExp(
    `(?:^| )(this )?(${indicatorsStr})(s|es)?\\s([^\\s]*)`,
    "gi",
  );

  let result;
  let returnValue = {};
  while ((result = testRegex.exec(text)) !== null) {
    const thisWordText = result[1];
    const indicatorCandidate = result[2];
    const pluralEnding = result[3];
    const referenceCandidate = result[4];

    const isPlural = Boolean(pluralEnding);
    const resultData = {
      fullMatch: result[0],
      indicator: indicatorCandidate,
      reference: referenceCandidate,
      isPlural,
    };

    if (/[\.\d]/.test(referenceCandidate) || thisWordText) {
      returnValue = resultData;
    } else if (
      referenceCandidate &&
      !excludeWords.find(word => {
        const searchRegex = new RegExp(`\\(?${word}`, "i");
        return searchRegex.test(referenceCandidate);
      }) &&
      referenceCandidate.length < 4
    ) {
      returnValue = resultData;
    }

    if (!isPlural && returnValue.indicator) {
      return resultData;
    }
  }
  return returnValue;
}

export default getReferenceIndicatorFromText;

import * as colors from "material-ui/styles/colors";

export default {
  iconButtonMain: {
    width: 12,
    height: 12,
    transition: "unset",
    padding: 0,
  },
  iconStyle: {
    width: 12,
    height: 12,
    color: "#696969",
    transition: "unset",
  },
  iconHoverColor: colors.grey900,
  textFieldMain: {
    width: "100%",
    height: "auto",
    cursor: "pointer",
  },
  textAreaStyle: {
    fontSize: "16px",
    lineHeight: "17px",
    marginTop: 0,
    color: colors.grey600,
    fontFamily: "Roboto,sans-serif",
    textAlign: "justify",
    width: "100%",
    marginBottom: 0,
    overflow: "hidden",
  },
  actionIconContainer: {
    verticalAlign: "top",
    paddingTop: "3px",
    width: "3%",
  },
};

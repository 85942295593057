import _ from "underscore";

function getDefinitionInstancesToEliminate(instances) {
  const instancesToEliminate = [];
  instances.forEach(instanceA => {
    const instanceATermLength = instanceA.term.length;
    instances.forEach(instanceB => {
      if (instanceA.term === instanceB.term) {
        return;
      }
      const instanceBTermLength = instanceB.term.length;
      const longerInstance =
        instanceATermLength > instanceBTermLength ? instanceA : instanceB;
      const shorterInstance =
        instanceATermLength < instanceBTermLength ? instanceA : instanceB;
      const {start: longerInstanceStart} = longerInstance;
      const longerInstanceEnd =
        longerInstanceStart + longerInstance.term.length;
      const {start: shorterInstanceStart} = shorterInstance;
      if (
        longerInstance.term.indexOf(shorterInstance.term) !== -1 &&
        (shorterInstanceStart > longerInstanceStart &&
          shorterInstanceStart < longerInstanceEnd)
      ) {
        instancesToEliminate.push(shorterInstance);
      }
    });
  });
  return _.uniq(instancesToEliminate, false, instance => instance.term);
}

export default getDefinitionInstancesToEliminate;

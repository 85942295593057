import _ from "underscore";

function getValues(tp) {
  if (tp.actual_values) {
    return tp.actual_values;
  }
  return tp.locked_values || tp.values || [];
}

function getPositiveReasonData(documentClauses) {
  if (!documentClauses) {
    return {};
  }
  const result = {};
  function getData(nodes) {
    if (nodes.text && !nodes.is_conjunction) {
      (nodes.topics || []).forEach(topic => {
        if (topic.is_deleted) {
          return;
        }
        const resultTopic = result[topic.topic_id];
        if (!resultTopic) {
          const newParameters = {};
          (topic.topicparameters || []).forEach(tp => {
            newParameters[tp.topicparameter_id] = getValues(tp);
          });
          result[topic.topic_id] = newParameters;
        } else {
          (topic.topicparameters || []).forEach(tp => {
            if (!resultTopic[tp.topicparameter_id]) {
              resultTopic[tp.topicparameter_id] = getValues(tp);
            } else {
              resultTopic[tp.topicparameter_id] = resultTopic[
                tp.topicparameter_id
              ].concat(getValues(tp));
            }
          });
        }
      });
    }
    if (nodes.clauseNodes) {
      nodes.clauseNodes.forEach(child => getData(child));
    }
  }

  _.forEach(documentClauses, clauses => {
    clauses.forEach(clause => getData(clause.nodes));
  });

  return result;
}

export default getPositiveReasonData;

function makeIssueReasonValue(path, baseValue) {
  return {
    path,
    values: [
      {
        label: "value",
        path: `${path}.value`,
        value: baseValue.value,
      },
      {
        label: "symbol",
        path: `${path}.symbol`,
        value: baseValue.symbol,
      },
      {
        label: "currency",
        path: `${path}.currency`,
        value: baseValue.currency,
      },
    ],
  };
}

export default makeIssueReasonValue;

import areConceptsInClause from "./are_concepts_in_clause";

const joinKeyValues = {
  clausepart_id: true,
  clausepart_reference: true,
  clause_id: true,
};

/* eslint-disable no-unused-vars, max-statements */
export default function ifConceptsInClause(...args) {
  let argsOffset = 0;
  const hasData = typeof args[0] !== "string";
  if (!hasData) {
    argsOffset += 1;
  }
  const rules = args[1 - argsOffset];
  const potentialJoinKey = args[2 - argsOffset];
  const hasJoinKey = joinKeyValues[potentialJoinKey];
  if (!hasJoinKey) {
    argsOffset += 1;
  }
  const joinKey = hasJoinKey ? potentialJoinKey : null;
  const positive = args[3 - argsOffset];
  const negative = args[4 - argsOffset];
  const context = args[5 - argsOffset];

  const fnArgs = [];
  if (hasData) {
    fnArgs.push(args[0]);
  }
  fnArgs.push(rules);
  if (hasJoinKey) {
    fnArgs.push(joinKey);
  }
  fnArgs.push(context);

  const result = areConceptsInClause(...fnArgs);

  return result ? positive : negative;
}

function clearStr(str = "") {
  const spanRegex = new RegExp("</?span.*?>", "g");
  return str.replace(spanRegex, "");
}

function parseQuillHTML(str) {
  const paragraphSplitRegex = new RegExp("<p.*?</p>", "gi");
  const tagsRegex = new RegExp("(<strong>|</strong>|<em>|</em>|<br/>)", "g");

  const paragraphs = [];
  let match;
  while ((match = paragraphSplitRegex.exec(clearStr(str))) !== null) {
    paragraphs.push(match[0]);
  }
  return paragraphs.reduce((accum, parStr) => {
    const result = {type: "paragraph"};
    const parOpeningTag = getParagraphOpeningTag(parStr);
    const indent = getIndent(parOpeningTag);
    if (indent) {
      result.indent = indent;
    }

    const clearString = parStr.substring(
      parOpeningTag.length,
      parStr.length - 4,
    );
    const children = [];
    let match;
    let strStart = 0;
    while ((match = tagsRegex.exec(clearString)) !== null) {
      const str = clearString.substring(strStart, match.index);
      if (str) {
        children.push({value: str, type: "text"});
      }
      const baseMatch = match[0];
      if (baseMatch === "</strong>") {
        children[children.length - 1].bold = true;
      } else if (baseMatch === "</em>") {
        children[children.length - 1].italics = true;
      } else if (baseMatch === "<br/>") {
        children.push({value: "\n", type: "break"});
      }
      strStart = match.index + match[0].length;
    }
    const endStr = clearString.substring(strStart, clearString.length);
    if (endStr) {
      children.push({value: endStr, type: "text"});
    }
    const splittedChildren = splitChildrenByBreaks(children);
    const paragraphs = splittedChildren.map(children => ({
      ...result,
      children,
    }));
    return accum.concat(paragraphs);
  }, []);
}

function getParagraphOpeningTag(str) {
  if (!str.startsWith("<p")) {
    return null;
  }
  const closingBracketIndex = str.indexOf(">");
  return str.substring(0, closingBracketIndex + 1);
}

function getIndent(openingTag) {
  const indentRegex = new RegExp("ql-indent-(\\d)", "g");
  const match = indentRegex.exec(openingTag);
  if (match && match[1]) {
    return parseInt(match[1], 10);
  }
  return null;
}

function splitChildrenByBreaks(children) {
  const result = [];
  let bucket = [];
  children.forEach(child => {
    if (child.type === "break") {
      result.push([...bucket]);
      bucket = [];
    } else {
      bucket.push(child);
    }
  });

  if (bucket) {
    result.push(bucket);
  }
  return result.filter(item => item.length);
}

export default parseQuillHTML;

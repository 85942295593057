function filterOut(baseList = [], ...args) {
  if (typeof args[args.length - 1] === "object") {
    args.pop();
  }
  return baseList.filter(
    item => args.toString().indexOf(item.toString()) === -1,
  );
}

export default filterOut;

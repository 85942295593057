import React, {useState, useEffect, useRef} from "react";
import _ from "lodash";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import StopIcon from "@material-ui/icons/Stop";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import getClauseAtomsByIds from "routes/document_detail/utils/applicable_clauses/get_clause_atoms_by_ids";
import sortClausesByReference from "../utils/sort_clauses_by_referance";

import GuiLight from "common_components/gui_text_editor/gui_light";
import ContentsLight from "common_components/gui_text_editor/contents_light";
import constructHtmlFromChildren from "common_components/report/utils/construct_html_from_children";
import usePrevious from "utils/hooks/use_previous";
import {
  getIssueManualCorrections,
  getUpdatedManualCorrections,
} from "utils/manual_corrections_utils";
import constructReportChildrenClauses from "../utils/construct_report_children_clauses";
import ReportClause from "./report_clause";
import WordSelectedClauses from "./additional_clauses";

const styles = {
  baseIcon: {
    margin: "0px 0px 0px 10px",
    cursor: "pointer",
  },
  editIcon: {
    cursor: "pointer",
    color: "#757575",
    width: 18,
    height: 18,
    marginLeft: "6px",
  },
  dialogBtns: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  editMessage: {
    textAlign: "center",
    border: "1px solid #bbb",
    backgroundColor: "#eee",
    padding: "5px",
    marginBottom: "10px",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  updateBtn: {
    flexGrow: 1,
    margin: "10px 0",
    borderRadius: 0,
  },
  closeEditIcon: {
    cursor: "pointer",
    width: 18,
    height: 18,
    marginLeft: "6px",
  },
  noClauses: {
    textAlign: "center",
    color: "#757575",
  },
};

function isUpdateReportDisabled(
  additionalReportClauseIds,
  additionalReportClausesState,
  removedClauses,
  removedClausesState,
) {
  return (
    _.isEqual(additionalReportClauseIds, additionalReportClausesState) &&
    _.isEqual(removedClauses, removedClausesState)
  );
}

function ReportClauses(props) {
  const {
    applicableClauses,
    issue,
    addClausesToReportDocumentIssueId,
    reportSettings,
    currentIssuesetItem: issueset,
    isGuiEdited,
    openTextInGuiEditor,
    closeTextInGuiEditor,
    scrollToClause,
    selectedReportId,
    selectedReport,
    setAddClausesToReportDocumentIssueId,
  } = props;
  const prevIssue = usePrevious(issue);

  const manualCorrections = getIssueManualCorrections(
    issue,
    issueset,
    selectedReportId || selectedReport,
  );
  const {
    additional_report_clauses: additionalReportClauseIds = [],
    removedClauses = {},
    altered_clauses,
  } = manualCorrections;
  const additionalReportClausesState =
    props.additionalReportClausesState || additionalReportClauseIds;
  const removedClausesPrev = usePrevious(removedClauses);
  const additionalReportClauseIdsPrev = usePrevious(additionalReportClauseIds);
  const additionalReportClausesStatePrev = usePrevious(
    additionalReportClausesState,
  );
  const [removedClausesState, updateRemovedClausesState] = useState(
    removedClauses,
  );
  const [hasUpdated, setHasUpdated] = useState(false);
  const [hasSelectClauses, setHasSelectClauses] = useState(true);
  const [clauses, setClauses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [alteredClausesText, setAlteredClausesText] = useState(altered_clauses);

  const handleChangeSelectClauses = () => {
    if (hasSelectClauses) {
      let newRemovedClausesState = {};
      clauses.forEach(clause => {
        newRemovedClausesState = {...newRemovedClausesState, [clause.id]: true};
      });
      setHasUpdated(true);
      updateRemovedClausesState(newRemovedClausesState);
      setHasSelectClauses(false);
    } else {
      setHasUpdated(true);
      updateRemovedClausesState({});
      setHasSelectClauses(true);
    }
  };

  const stateRef = useRef();
  stateRef.current = {
    removedClausesState,
    additionalReportClausesState,
    removedClauses,
  };

  useEffect(
    () => {
      setClauses(
        _.chain(Object.values(applicableClauses))
          .flatten()
          .uniq(clause => clause.id)
          .sortBy(clause => clause.id)
          .value()
          .filter(
            clause =>
              !additionalReportClausesState.find(id => clause.id === id),
          ),
      );
    },
    [applicableClauses],
  );

  useEffect(
    () => {
      if (Object.keys(removedClausesState).length === clauses.length) {
        setHasSelectClauses(false);
      } else {
        setHasSelectClauses(true);
      }
    },
    [clauses, removedClausesState],
  );

  const {clause_text: correctedApplicableClauses} = manualCorrections;

  const noClausesSelected =
    clauses.length === 0 && additionalReportClausesState.length === 0;

  const additionalReportClauses = getClauseAtomsByIds(
    additionalReportClausesState,
    props.documentClauses,
  );

  const onClausepartCheck = _.memoize(clausepartId => (e, checked) => {
    const newRemovedClausesState = {...removedClausesState};
    if (checked) {
      delete newRemovedClausesState[clausepartId];
    } else {
      newRemovedClausesState[clausepartId] = true;
    }
    setHasUpdated(true);
    updateRemovedClausesState(newRemovedClausesState);
  });

  const removeAdditionalClauseFromReport = _.memoize(clausepartId => () => {
    setHasUpdated(true);
    props.onRemoveAdditionalClauseFromReport(clausepartId);
  });

  const openTextEditor = () => {
    handleCloseModal();
    openTextInGuiEditor();
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const onTriggerAddClausesToReportDocumentIssueId = () => {
    if (addClausesToReportDocumentIssueId) {
      onUpdateReport();
    }
    const newValue = addClausesToReportDocumentIssueId
      ? null
      : issue.document_issue_id;
    setAddClausesToReportDocumentIssueId &&
      setAddClausesToReportDocumentIssueId(newValue);
  };

  const saveStateValues = (
    clausesRemoved,
    clausesAdditionalReport,
    alteredClauses,
  ) => {
    props.onManualCorrectDocumentIssue(
      {
        removedClauses: {...clausesRemoved},
        additional_report_clauses: [...clausesAdditionalReport],
        altered_clauses: {...alteredClauses},
      },
      true,
      prevIssue,
      props,
    );
  };

  const onUpdateReport = () => {
    saveStateValues(
      removedClausesState,
      additionalReportClausesState,
      alteredClausesText,
    );
  };

  const onUpdateAlteredClause = value => {
    const newAlteredClauses = {...alteredClausesText};
    if (newAlteredClauses?.[value.id]) {
      newAlteredClauses[value.id].text = value.text;
      newAlteredClauses[value.id].reference = value.reference;
    } else {
      newAlteredClauses[value.id] = {
        text: value.text,
        reference: value.reference,
      };
    }
    onUpdateAlteredClauses(newAlteredClauses);
  };

  const onRevertAlteredClause = id => {
    const newAlteredClauses = {...alteredClausesText};
    delete newAlteredClauses[id];
    onUpdateAlteredClauses(newAlteredClauses);
  };

  const onUpdateAlteredClauses = newAlteredClauseValue => {
    setAlteredClausesText(newAlteredClauseValue);
    saveStateValues(
      removedClausesState,
      additionalReportClausesState,
      newAlteredClauseValue,
    );
  };

  const closeReportClauses = () => props.updateEditState(false);

  const isUpdateDisabled = isUpdateReportDisabled(
    additionalReportClauseIds,
    additionalReportClausesState,
    removedClauses,
    removedClausesState,
  );

  useEffect(
    () => {
      if (
        !prevIssue ||
        issue.document_issue_id === prevIssue.document_issue_id
      ) {
        setHasUpdated(true);
      }
    },
    [additionalReportClauseIds, additionalReportClausesState],
  );

  useEffect(
    () => {
      if (
        prevIssue &&
        issue.document_issue_id !== prevIssue.document_issue_id
      ) {
        if (hasUpdated) {
          const shouldUpdateValues = !isUpdateReportDisabled(
            additionalReportClauseIdsPrev,
            additionalReportClausesStatePrev,
            removedClausesPrev,
            removedClausesState,
          );
          if (shouldUpdateValues) {
            saveStateValues(
              removedClausesState,
              additionalReportClausesStatePrev,
            );
          }
          setAddClausesToReportDocumentIssueId &&
            props.setAddClausesToReportDocumentIssueId(null);
        }
        updateRemovedClausesState(removedClauses);
        setHasUpdated(false);
      }
    },
    [issue.document_issue_id],
  );

  useEffect(() => {
    return () => {
      const {
        additionalReportClausesState,
        removedClausesState,
        removedClauses,
      } = stateRef.current;
      const shouldUpdateValues = !isUpdateReportDisabled(
        additionalReportClauseIds,
        additionalReportClausesState,
        removedClauses,
        removedClausesState,
      );
      if (shouldUpdateValues) {
        saveStateValues(
          removedClausesState,
          additionalReportClausesState,
          alteredClausesText,
        );
      }
    };
  }, []);

  if (isGuiEdited) {
    const onGuiValueSave = newValue => {
      props.correctDocumentIssueManually(issue, {
        manual_corrections: getUpdatedManualCorrections(
          issue.manual_corrections,
          reportSettings.id,
          "clause_text",
          newValue,
        ),
      });
    };
    const onRevertValue = () => onGuiValueSave(undefined);
    const reportClauses = constructReportChildrenClauses(
      clauses,
      additionalReportClauses,
      Object.keys(removedClausesState),
      alteredClausesText,
    );
    return (
      <GuiLight
        contentsHTML={
          correctedApplicableClauses || constructHtmlFromChildren(reportClauses)
        }
        isValueChanged={Boolean(correctedApplicableClauses)}
        onClose={closeTextInGuiEditor}
        onSave={onGuiValueSave}
        onRevert={onRevertValue}
      />
    );
  } else if (correctedApplicableClauses && !isGuiEdited) {
    const reportClauses = constructReportChildrenClauses(
      clauses,
      additionalReportClauses,
      [],
      {},
    );
    return (
      <>
        <ContentsLight
          contentsHTML={
            correctedApplicableClauses ||
            constructHtmlFromChildren(reportClauses)
          }
        />
      </>
    );
  }

  const sortedClauses = sortClausesByReference(
    [...additionalReportClauses, ...clauses],
    props.documentSections,
  );
  const AdditionIcon = addClausesToReportDocumentIssueId ? StopIcon : AddIcon;
  return (
    <>
      <div style={styles.container}>
        {!noClausesSelected ? (
          <Tooltip
            title={hasSelectClauses ? "Deselect all" : "Select all"}
            style={{paddingLeft: 0}}
            placement="left"
            arrow
            enterDelay={1000}
          >
            <Checkbox
              color="primary"
              checked={hasSelectClauses}
              onChange={handleChangeSelectClauses}
            />
          </Tooltip>
        ) : null}
        <Button
          variant="outlined"
          style={styles.updateBtn}
          disabled={isUpdateDisabled}
          onClick={onUpdateReport}
        >
          Update Report
        </Button>
        {setAddClausesToReportDocumentIssueId ? (
          <Tooltip
            title={
              addClausesToReportDocumentIssueId
                ? "Stop adding clauses"
                : "Add clauses from the document. Click on a clause in the document to add it"
            }
            placement="top"
            arrow
          >
            <AdditionIcon
              style={
                addClausesToReportDocumentIssueId
                  ? {
                      ...styles.baseIcon,
                      color: "#2196f3",
                    }
                  : {
                      ...styles.baseIcon,
                      color: "#757575",
                    }
              }
              onClick={onTriggerAddClausesToReportDocumentIssueId}
            />
          </Tooltip>
        ) : null}
        <Tooltip title="Edit clause text" placement="top" arrow>
          <EditIcon style={styles.editIcon} onClick={handleOpenModal} />
        </Tooltip>
        {props.editingReport ? (
          <Tooltip title="Close report clauses" placement="top" arrow>
            <CloseIcon
              style={styles.closeEditIcon}
              onClick={closeReportClauses}
            />
          </Tooltip>
        ) : null}
      </div>
      {addClausesToReportDocumentIssueId ? (
        <div style={styles.editMessage}>
          Click on clauses to add clause text, or clause ids to add parents /
          children
        </div>
      ) : null}
      {noClausesSelected ? (
        <div style={styles.noClauses}>No clauses selected</div>
      ) : null}
      {!noClausesSelected
        ? sortedClauses.map(clause => {
            if (clauses.find(item => clause.id === item.id)) {
              const onCheck = onClausepartCheck(clause.id);
              const isCheckedState = !removedClausesState[clause.id];
              const isCheckedBase = !removedClauses[clause.id];
              return (
                <ReportClause
                  key={clause.id}
                  id={clause.id}
                  clause={clause}
                  checked={isCheckedState}
                  onClick={onCheck}
                  useDeleteIcon={false}
                  isChanged={isCheckedState !== isCheckedBase}
                  scrollToClause={scrollToClause}
                  alteredClausesText={alteredClausesText}
                  onUpdateAlteredClause={onUpdateAlteredClause}
                  onRevertAlteredClause={onRevertAlteredClause}
                />
              );
            } else {
              const onCheck = removeAdditionalClauseFromReport(clause.id);
              const isInAdditionalReportClausesState = Boolean(
                additionalReportClausesState.find(cpId => cpId === clause.id),
              );
              const isInAdditionalReportClausesSaved = Boolean(
                additionalReportClauseIds.find(cpId => cpId === clause.id),
              );
              return (
                <ReportClause
                  key={clause.id}
                  id={clause.id}
                  clause={clause}
                  checked={true}
                  onClick={onCheck}
                  useDeleteIcon={true}
                  isChanged={
                    isInAdditionalReportClausesState !==
                    isInAdditionalReportClausesSaved
                  }
                  scrollToClause={scrollToClause}
                  alteredClausesText={alteredClausesText}
                  onUpdateAlteredClause={onUpdateAlteredClause}
                  onRevertAlteredClause={onRevertAlteredClause}
                />
              );
            }
          })
        : null}
      {props.selectedClauses && (
        <WordSelectedClauses
          documentClauseparts={props.documentClauseparts}
          usedClauses={sortedClauses}
          selectedClauses={props.selectedClauses}
          save={clauseIds => {
            saveStateValues(
              removedClausesState,
              [...additionalReportClausesState, ...clauseIds],
              alteredClausesText,
            );
          }}
        />
      )}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Edit text manually</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This removes the link between clause text and our intelligent
            engine. Doing this will allow you to edit the text in any way, but
            will mean that we can't automatically extract clause references.
          </DialogContentText>
        </DialogContent>
        <DialogActions style={styles.dialogBtns}>
          <Button
            onClick={openTextEditor}
            color="primary"
            size="small"
            variant="contained"
          >
            Confirm
          </Button>
          <Button
            onClick={handleCloseModal}
            color="primary"
            size="small"
            variant="contained"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ReportClauses;

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function logsFetch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.LOGS_FETCH,
    (organisationId, query) => {
      const queryString = query ? `?${query}` : "";
      return requestor
        .get(`${basePath}/organisation/${organisationId}/logs${queryString}`, {
          withCredentials: true,
        })
        .then(response => {
          return response.data;
        });
    },
  );
}

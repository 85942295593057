import _ from "lodash";

function constructReportChildrenClauses(
  clauses,
  additionalReportClauses,
  removedReports,
  alteredClauses,
) {
  return _.chain([...clauses, ...additionalReportClauses])
    .filter(item => !removedReports.find(id => parseInt(id, 10) === item.id))
    .sortBy(item => item.id)
    .map(clause => {
      const text = alteredClauses?.[clause.id]?.text
        ? alteredClauses[clause.id].text
        : clause.text;
      const reference = alteredClauses?.[clause.id]?.reference
        ? alteredClauses[clause.id].reference
        : clause.reference;
      return {
        type: "paragraph",
        value: `${reference} ${text}`,
        id: clause.id,
      };
    })
    .value();
}

export default constructReportChildrenClauses;

import basePath from "base_path";
import ActionTypes from "../../constants/action_types";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.ISSUE_COMMENT_ADD,
  (organisationId, documentIssueId, text) =>
    requestor
      .post(
        `${basePath}/organisation/${organisationId}/document_issue/${documentIssueId}/comment`,
        {text},
        {
          withCredentials: true,
        },
      )
      .then(response => response.data),
);

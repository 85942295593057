import _ from "underscore";

function getIssueReasonCount(issue) {
  return Object.keys(_.groupBy(issue.reason, reason => getClauseId(reason)))
    .length;
}

function getClauseId(reason) {
  if (reason.clause_id) {
    return reason.clause_id;
  }
  if (reason.reason || reason.rules) {
    return getClauseId(reason.reason || reason.rules);
  }
  return null;
}

export default getIssueReasonCount;

import React from "react";
import Atom from "./atom";
import Node from "./node";
import List from "./list";
import Definition from "../definition";
import PendingSave from "./pending_save";
import Table from "./table";
import TableRow from "./table_row";

export default function renderGraphNode(props) {
  const {node, parentNode, path, index, isDefinition} = props;
  if (node && node.type) {
    const type = getType(node);
    const nodePath = props.isChildLevel
      ? index !== undefined ? `${path}[${index}]` : path
      : "root";
    const allProps = {
      ...props,
      is_top_level_subclause:
        props.clause.is_top_level_subclause || props.is_top_level_subclause,
      clause: node,
      parent: parentNode,
      path: nodePath,
      isChildLevel: true,
    };

    if (type !== "Atom") {
      delete allProps.showReferenceForNodeAtoms;
    }
    // TODO: This is used to prevent rendering Add Clausepart After/Before buttons
    // in clausepart editor (not gui) for clauses having direct List or Atom as the
    // first level node (not typical "ClausePartNode") as currently we don't have proper
    // server side functionality . Also, for these cases creating clauseAtom on doubleEnter
    // (when editing last list item) won't work (need to create ClausePartNode construct in this case)
    const isFirstNodeAtomOrList =
      props.isFirstNodeAtomOrList !== undefined ? false : props.firstNode;
    switch (type) {
      case "Atom": {
        const atomChanges = props.changes.filter(
          change =>
            ((change.type !== "clause_deletion" ||
              change.type !== "definition_deletion") &&
              change.new_clausepart_id === node.id) ||
            (isDefinition &&
              (change.type === "definition_deletion" ||
                change.type === "definition_addition")) ||
            (change.type === "clause_deletion" &&
              change.new_clause_id === props.documentClause.id) ||
            (props.clauseAdditionChange &&
              change.type === "pending_save" &&
              change.new_clause_id ===
                props.clauseAdditionChange.new_clause_id),
        );
        const otherChanges = props.changes.filter(
          change =>
            change.old_document_id !== change.new_document_id &&
            change.old_path &&
            change.old_path.startsWith(`${path}[${index}]`),
        );

        // deletedClausepartPaths are used to determine if clauseAtom children clauses are deleted
        // in order to render delete icon. NOTE: paths are related to all deletions across clause,
        // but not only current node
        const deletedClausepartPaths = props.changes.reduce(
          (result, change) => {
            if (change.type === "clausepart_deletion") {
              result.push(change.old_path);
            }
            return result;
          },
          [],
        );

        const atom = (
          <Atom
            {...allProps}
            isFirstNodeAtomOrList={isFirstNodeAtomOrList}
            changes={atomChanges}
            deletedClausepartPaths={deletedClausepartPaths}
          />
        );
        if (otherChanges.length) {
          return (
            <div>
              {atom}
              {renderOtherChanges(otherChanges, parentNode, allProps)}
            </div>
          );
        }

        return atom;
      }
      case "Node":
        return <Node {...allProps} />;
      case "List": {
        if (node.counterType === "DEFINITION" || node.isDefinitionList) {
          // TO DO: check definition changes for correct rendering
          return <Definition {...allProps} isOpen={true} />;
        }
        return (
          <List {...allProps} isFirstNodeAtomOrList={isFirstNodeAtomOrList} />
        );
      }
      case "Table":
        return <Table {...allProps} />;
      case "TableRow":
        return <TableRow {...allProps} />;
      case "Save":
        return <PendingSave index={index} />;
    }
  }
  return null;
}

function renderOtherChanges(otherChanges, parent, props) {
  return otherChanges
    .map(change => {
      if (change.type === "clausepart_deletion") {
        return (
          <Atom
            {...props}
            isClauseDeletion={true}
            clause={{
              id: change.new_clausepart_id,
              type: `${change.old_reference && "Numbered"}ClauseAtom`,
              partial_text: change.old_text,
              text: change.old_text,
              load_state: 7,
              topics: change.old_clausepart_topics,
              last_edited: "",
              changes: [],
              reference:
                change.old_reference &&
                parent.reference !== change.old_reference
                  ? change.old_reference
                  : null,
              // counterType: parentClause.counterType,
              level: props.clause.level,
            }}
          />
        );
      }
      return null;
    })
    .filter(item => item);
}

function getType(node) {
  const {type, counterType} = node;
  if (counterType === "TABLE") {
    return "Table";
  } else if (counterType === "TABLE_ROW") {
    return "TableRow";
  }
  return type.substring(node.type.length - 4);
}

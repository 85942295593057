import * as OfficeHelpers from "@microsoft/office-js-helpers";
import ActionTypes from "../constants/action_types";

import basePath from "base_path";

import createReduxPromiseAction from "utils/create_redux_promise_action";

function login(requestor, token) {
  return requestor
    .get(`${basePath}/auth/authenticate-aad`, {
      headers: {
        authorization: `Bearer ${token.access_token}`,
        withCredentials: true,
      },
    })
    .then(response => response.data);
}

export default function initUser(requestor) {
  return createReduxPromiseAction(
    ActionTypes.USER_AUTHENTICATE_AAD_TOKEN,
    async () => {
      const authenticator = new OfficeHelpers.Authenticator();

      const isProd = process.env.NODE_ENV === "production";
      const isStaging =
        document?.location?.host === "staging.lexicallabs.com" ||
        document?.location?.host === "uat-api.lexicallabs.com";
      const clientId = isStaging
        ? "67db3f82-6819-443b-9b8f-91c9489c5521"
        : "7ecbb74b-b8ec-4274-a7ec-421db259c9a8";
      authenticator.endpoints.registerMicrosoftAuth(clientId, {
        redirectUrl: isStaging
          ? "https://uat-api.lexicallabs.com/word/taskpane.html"
          : isProd
          ? "https://api.lexicallabs.com/word/taskpane.html"
          : "https://localhost:5012/word/taskpane.html",
        scope: isStaging
          ? "api://67db3f82-6819-443b-9b8f-91c9489c5521/data.read"
          : "api://35e34d30-1766-4670-8ba0-5850d4b53e5b/data.read",
      });

      const token = authenticator.tokens.get("Microsoft");

      if (!token) {
        const newToken = await authenticator.authenticate(
          OfficeHelpers.DefaultEndpoints.Microsoft,
          true,
        );
        return await login(requestor, newToken);
      }
      return await login(requestor, token);
    },
  );
}

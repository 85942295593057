function getAddedToReportIssues(issues, showIssuesInChecklist, reportSettings) {
  const allIssues = issues;
  if (reportSettings.add_all_items_to_report) {
    return allIssues.filter(issue => {
      const selectedReportActionState = (issue.action_state || {})[
        reportSettings.id
      ];
      return selectedReportActionState !== 0;
    });
  } else if (showIssuesInChecklist) {
    return allIssues.filter(issue => {
      const {current_state: currentState} = issue;
      if (!issue.reason) {
        return (issue.action_state || {})[reportSettings.id] === 1 || false;
      }
      if (currentState === null) {
        return Boolean(issue.reason);
      }
      return currentState;
    });
  }
  return allIssues.filter(
    issue => (issue.action_state || {})[reportSettings.id] === 1,
  );
}

export default getAddedToReportIssues;

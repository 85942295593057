function getIe11ClampedText(text, linesCount) {
  const singleLineChars = 54;
  const desiredVisibleChars = singleLineChars * linesCount;

  let visibleCharsCount = 0;
  let position = 0;
  let isInLinkText = false;
  let isInUrl = false;

  // Iterate through the string until we reach the desired clamp length in visible characters
  while (position < text.length && visibleCharsCount < desiredVisibleChars) {
    if (text[position] === "[") {
      isInLinkText = true;
    } else if (text[position] === "]") {
      isInLinkText = false;
    } else if (
      text[position] === "(" &&
      !isInUrl &&
      position > 0 &&
      text[position - 1] === "]"
    ) {
      isInUrl = true;
    } else if (text[position] === ")" && isInUrl) {
      isInUrl = false;
    }

    if (!isInUrl) {
      visibleCharsCount++;
    }

    position++;
  }

  // Adjust clamp point if we're inside a Markdown link or URL
  if (isInLinkText) {
    const urlEnd = text.indexOf(")", position);
    if (urlEnd !== -1) {
      position = urlEnd + 1;
    }
  } else if (isInUrl) {
    const urlEnd = text.indexOf(")", position);
    if (urlEnd !== -1) {
      position = urlEnd + 1;
    }
  }

  // Adjust clamp point if it's in the middle of a word
  while (position < text.length && !/\W/.test(text[position])) {
    position++;
  }

  if (position < text.length) {
    return `${text.substring(0, position)}...`;
  } else {
    return text;
  }
}

export default getIe11ClampedText;

import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import {FindDocumentsAction, FoundDocument} from "../actions/find_documents";
import actionTypes from "../constants/action_types";

export default function foundDocumentsReducer(
  state: MaybeUninitialised<FoundDocument[]> = UNINITIALISED,
  action: FindDocumentsAction,
): typeof state {
  if (action.type === actionTypes.FIND_DOCUMENTS.SUCCESS) {
    return action.payload;
  }
  return state;
}

import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";
import UserActionTypes from "modules/user/constants/action_types";

export default function documentTypesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.DOCUMENT_TYPES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.DOCUMENT_TYPES_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

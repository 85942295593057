import byId from "common/utils/by_id";

function getChecklistOrder(groupedIssues) {
  return groupedIssues.reduce((result, item) => {
    if (item.isGroup) {
      item.item.forEach(issue => {
        result.push(issue.id);
        if (issue.subissueData) {
          issue.subissueData.subissues.forEach(subissue => {
            result.push(subissue.id);
          });
        }
      });
    } else {
      result.push(item.item[0].id);
    }
    return result;
  }, []);
}

function getReportIssues(issuesAddedToReport, groupedIssues) {
  const applicableIssuesById = byId(issuesAddedToReport);
  const issuesInChecklistOrder = getChecklistOrder(groupedIssues);
  return issuesInChecklistOrder.reduce((result, issueId) => {
    const issue = applicableIssuesById[issueId];
    if (issue) {
      result.push(issue);
    }
    return result;
  }, []);
}

export default getReportIssues;

function getTextFromClipboard(e) {
  let pastedText = "";
  if (window.clipboardData && window.clipboardData.getData) {
    // IE
    pastedText = window.clipboardData.getData("Text");
  } else if (e.clipboardData && e.clipboardData.getData) {
    pastedText = e.clipboardData.getData("text/plain");
  }
  return pastedText;
}

export default getTextFromClipboard;

import evaluationRules from "./utils/are_concepts_in_clause_rules";
import evaluateRules from "./utils/evaluate_rules";

const joinKeyValues = {
  clausepart_id: true,
  clausepart_reference: true,
  clause_id: true,
};

/* eslint-disable @typescript-eslint/no-unused-vars, max-statements */
export default function areConceptsInClause(...args) {
  let argsOffset = 0;

  const hasData = typeof args[0] !== "string";
  if (!hasData) {
    argsOffset += 1;
  }
  const rules = args[1 - argsOffset];

  const potentialJoinKey = args[2 - argsOffset];
  const hasJoinKey = joinKeyValues[potentialJoinKey];
  const joinKey = hasJoinKey ? potentialJoinKey : "clausepart_id";
  if (!hasJoinKey) {
    argsOffset += 1;
  }

  const data = hasData
    ? args[0]
    : args[3 - argsOffset]?.data?.root?.reasonData ?? [];

  return evaluateRules(rules, evaluationRules(data, joinKey));
}

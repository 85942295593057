import localStorage from "../../../utils/local_storage";

const getLocalStorageData = key => {
  const clientMode = localStorage.getItem(key);
  return clientMode !== null && clientMode !== undefined
    ? JSON.parse(clientMode)
    : null;
};

export default getLocalStorageData;

import React from "react";
import Tooltip from "common_components/tooltip";

import getZoomedFontSize from "utils/get_zoomed_font_size";
import DocumentDetailContext from "common_components/context/document_detail_context";

const styles = {
  ultimateGroup: {
    border: "1px solid #ccc",
    padding: "0.25em 0.5em",
    margin: "0.25em",
    backgroundColor: "white",
    fontWeight: 400,
    width: "auto",
    height: "100%",
  },
  topicGroup: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 1,
    padding: "0.25em 0.5em",
    margin: "0.25em",
    backgroundColor: "#f6f6fa",
    fontWeight: 100,
    width: "12.5em",
  },
  topicGroupOpen: {
    flexWrap: "wrap",
    flexDirection: "column",
    flexShrink: 1,
    border: "none",
    backgroundColor: "#f6f6fa",
    maxWidth: "25em",
    padding: "0.25em 1.9em",
    width: "100%",
    margin: "0.25em auto",
  },
  topicsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  topicsContainerOneItem: {
    justifyContent: "center",
  },
  topicGroupNameContainer: {
    textAlign: "center",
    color: "#555",
    cursor: "pointer",
  },
  topicGroupNameContainerWithTopicsChildren: {},
  topicGroupName: {
    verticalAlign: "middle",
    overflowWwrap: "break-word",
  },
  topicCount: {
    verticalAlign: "middle",
  },
};

class TopicGroup extends React.Component {
  static contextType = DocumentDetailContext;
  constructor(props) {
    super(props);
    this.state = {
      childrenOpen: 0,
      isOpen:
        props.topic.nestingLevel === 0 ||
        props.topic.hasSelections ||
        props.areAllGroupsOpen,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.topic.hasSelections && this.props.topic.hasSelections) {
      this.setState(() => ({isOpen: true}));
    }
  }

  render() {
    const {
      topic,
      nestedItems,
      topicContainerStyles,
      topicGroupStyles,
      ultimateTopicGroupStyles,
    } = this.props;
    const {zoom} = this.context;
    return (
      <Tooltip
        className="topic-group"
        disabled={this.state.isOpen}
        key={topic.name}
        tooltipContent={this.getTooltipList()}
        rootDivStyle={{
          ...styles.topicGroup,
          ...(this.state.isOpen ? styles.topicGroupOpen : {}),
          ...(topic.nestingLevel === 0 ? styles.ultimateGroup : {}),
          fontSize: getZoomedFontSize(
            topic.nestingLevel === 0 ? 14 : 12,
            "document",
            zoom,
          ),
          ...(topicGroupStyles ? topicGroupStyles : {}),
          ...(ultimateTopicGroupStyles && topic.nestingLevel === 0
            ? ultimateTopicGroupStyles
            : {}),
        }}
        tooltipContainerStyle={{top: "24px"}}
      >
        <div
          className="topics-group-name-container"
          style={styles.topicGroupNameContainer}
          onClick={this.toggleGroupOpen}
          onMouseEnter={this.onTopicGroupEnter}
          onMouseLeave={this.onTopicGroupLeave}
        >
          <span
            className="topics-group-name-text"
            style={styles.topicGroupName}
          >
            {topic.name}
            {!this.state.isOpen && (
              <span
                style={{
                  ...styles.topicCount,
                  fontSize: getZoomedFontSize(10, "document", zoom),
                }}
              >
                {` (${topic.count})`}
              </span>
            )}
          </span>
        </div>
        {this.state.isOpen &&
          (topic.children[0].isTopic ? (
            <div
              style={{
                ...styles.topicsContainer,
                ...(topic.children.length > 1
                  ? {}
                  : styles.topicsContainerOneItem),
                ...(topicContainerStyles ? topicContainerStyles : {}),
              }}
            >
              {nestedItems}
            </div>
          ) : (
            <div
              style={{
                ...styles.topicsContainer,
                ...(topicContainerStyles ? topicContainerStyles : {}),
              }}
            >
              {nestedItems}
            </div>
          ))}
      </Tooltip>
    );
  }

  onTopicGroupEnter = () => {
    this.props.topicHoverStart(this.props.topic.nestedChildrenIds);
  };

  onTopicGroupLeave = () => {
    this.props.topicHoverEnd();
  };

  areTopicsInChildren = () => {
    let areTopicsInChildren = false;
    this.props.topic.children.forEach(item => {
      if (item.isTopic) {
        areTopicsInChildren = true;
      }
    });
    return areTopicsInChildren;
  };

  toggleGroupOpen = () => {
    clearTimeout(this.clearId);
    this.props.topicHoverEnd();
    this.setState(() => ({isOpen: !this.state.isOpen}));
  };

  getTooltipList = () => {
    return (
      <div>
        {this.props.topic.children.map((child, index) => (
          <div key={index}>{child.name}</div>
        ))}
      </div>
    );
  };
}

export default TopicGroup;

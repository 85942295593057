import currencySymbolMap from "currency-symbol-map";
const {getSymbolFromCurrency, getCurrencyFromSymbol} = currencySymbolMap;
export default function render(parameter, parameterValue) {
  const {value} = parameterValue;
  let {symbol, currency} = parameterValue;
  if (!symbol && currency) {
    symbol = getSymbolFromCurrency(currency);
  } else if (!currency && symbol) {
    if (symbol === "$") {
      currency = "";
    } else {
      currency = getCurrencyFromSymbol(symbol);
    }
  } else if (!symbol && !currency) {
    symbol = "$";
    currency = "AUD";
  }
  return `${symbol}${(value || 0).toLocaleString()} ${currency}`.trim();
}

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initDocument(requestor, update) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_UPLOAD,
    (
      organisationId,
      projectId,
      files,
      source,
      isTemplate,
      isOutputTemplate,
      connectionId,
      templateDocumentId,
      formTemplateId,
      issuesets,
      groupId,
      concatenateFiles,
      options,
    ) => {
      const formData = new FormData();
      files.forEach(file => {
        formData.append("file", file, file.name);
      });
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/project/${projectId}` +
            "/document/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              connection_id: connectionId,
              is_template: isTemplate,
              source,
              is_output_template: isOutputTemplate,
              ...(templateDocumentId
                ? {template_document_id: templateDocumentId}
                : {}),
              ...(formTemplateId ? {form_template_id: formTemplateId} : {}),
              ...(issuesets ? {issuesets} : {}),
              ...(groupId ? {group_id: groupId} : {}),
              concatenate_files: concatenateFiles,
            },
            withCredentials: true,
            progress: event => {
              update({
                id: -1,
                progress: event.loaded / event.total,
              });
            },
          },
        )
        .then(response => {
          if (options && response.data) {
            return response.data.map(doc => ({...doc, ...options}));
          }
          return response.data;
        });
    },
  );
}

import React from "react";

import TextField from "material-ui/TextField";
import Undo from "material-ui/svg-icons/content/undo";

import {setCaretPosition} from "utils/dom_operations";
import * as PossibleUpcomingEventCatcher from "utils/possible_upcoming_event_catcher";
import applyChanges from "utils/text/apply_text_changes";
import formatText from "utils/text/format_text";

const styles = {
  actionButton: {
    color: "rgb(3, 155, 229)",
    cursor: "pointer",
    width: "20px",
    height: "20px",
  },
  editorDiv: {
    display: "flex",
    alignItems: "center",
  },
  notSet: {
    marginRight: "0.5rem",
    flexGrow: 1,
  },
  rootDiv: {
    fontWeight: 400,
    marginBottom: "0.5em",
  },
  staticDiv: {
    cursor: "pointer",
  },
  textField: {
    marginBottom: -13,
    marginTop: -14,
    width: "100%",
  },
  textFieldInput: {
    lineHeight: 21.875,
  },
};

export default class ClauseSubHeading extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.isEditable !== this.props.isEditable &&
      this.props.isEditable
    ) {
      if (this.props.isDeleted) {
        window.addEventListener("click", this.handleEditorOutsideClick);
      } else {
        const {input} = this.subheadingTextRef;
        input.focus();
        setCaretPosition(input, input.value.length);
        if (this.props.doesHaveEditableClause) {
          this.props.hideClauseEditor();
        }
      }
    }
  }

  componentWillUnmount() {
    this.removeEditorOutsideClickListener();
  }

  handleEditorOutsideClick = event => {
    if (!this.subheadingEditorRef.contains(event.target)) {
      this.removeEditorOutsideClickListener();
      this.props.unsetEditableClauseHeading();
    }
  };

  removeEditorOutsideClickListener = () => {
    window.removeEventListener("click", this.handleEditorOutsideClick);
  };

  render() {
    return (
      <div className="heading" style={styles.rootDiv}>
        {!this.props.isEditable ? this.renderStatic() : this.renderEditor()}
      </div>
    );
  }

  renderStatic() {
    const {
      changes,
      documentDefinitions,
      editModeOn,
      isClauseDeletion,
    } = this.props;
    const formattedText = formatText(
      this.props.document.file_extension,
      this.props.subheading.text || "",
      {changes, documentDefinitions},
      this.props.findAndReplace.find,
    );
    if (isClauseDeletion) {
      return <div>{formattedText}</div>;
    }
    return (
      <div
        onClick={
          editModeOn
            ? event => {
                // Prevent a text selection in the subheading editor
                PossibleUpcomingEventCatcher.preventDefault("mousedown");
                // Avoid click propagation to the rendered editor
                PossibleUpcomingEventCatcher.stopPropagation("click");
                this.onSubheadingEdit(event);
              }
            : undefined
        }
        onDoubleClick={editModeOn ? undefined : this.onSubheadingEdit}
        onMouseDown={event => {
          if (event.detail > 1) {
            event.preventDefault();
          }
        }}
        style={styles.staticDiv}
      >
        {formattedText}
      </div>
    );
  }

  renderEditor() {
    const textWithChanges = this.getText();
    const canBeReverted =
      this.props.subheading &&
      this.props.subheading.text &&
      this.props.subheading.text !== textWithChanges;
    return (
      <div
        ref={this.createSubheadingEditorRef}
        style={styles.editorDiv}
        onClick={event => event.stopPropagation()}
      >
        {this.props.isDeleted ? (
          <div style={styles.notSet}>Subheading not set</div>
        ) : (
          <TextField
            defaultValue={this.getText()}
            inputStyle={styles.textFieldInput}
            name="subheading"
            onBlur={this.onSave}
            ref={this.createSubheadingTextRef}
            style={styles.textField}
          />
        )}
        {canBeReverted && (
          <Undo
            style={styles.actionButton}
            onMouseDown={this.onChangesRevert}
          />
        )}
      </div>
    );
  }

  onSubheadingEdit = event => {
    event.stopPropagation();
    this.props.setEditableClauseHeading(this.props.subheading);
  };

  getText = () => {
    return applyChanges(
      (this.props.subheading && this.props.subheading.text) || "",
      this.props.changes || [],
    );
  };

  onSave = event => {
    const newValue = event.target.value;
    if (newValue !== this.getText()) {
      if (!newValue) {
        this.props.onDelete(this.props.subheading);
      } else {
        this.props.onAlter(this.props.subheading, newValue);
      }
    }
    this.props.unsetEditableClauseHeading();
  };

  onChangesRevert = () => {
    if (this.props.isDeleted) {
      this.removeEditorOutsideClickListener();
    }
    this.props.onRevert(this.props.subheading);
    this.props.unsetEditableClauseHeading();
  };

  createSubheadingEditorRef = node => (this.subheadingEditorRef = node);
  createSubheadingTextRef = node => (this.subheadingTextRef = node);
}

import React, {useEffect, useState} from "react";
import _ from "lodash";

import * as colors from "@material-ui/core/colors";
import LaunchIcon from "@material-ui/icons/Launch";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import usePrevious from "utils/hooks/use_previous";

import SubstituteDefinitions from "./substitute_definitions";
import ExpandableClausepart from "./expandable_clausepart";

import substituteAndStyleText from "./substitute_and_style_text";
import SwitchStyled from "common_components/inputs/switch_styled";

const styles = {
  circle: {
    width: "0.8rem",
    height: "0.8rem",
    borderRadius: "50%",
    flexShrink: 0,
    marginLeft: 6,
  },
  projectName: {
    fontSize: 14,
    fontWeight: 500,
    color: colors.grey[800],
  },
  documentListItem: {
    fontWeight: 400,
    marginTop: 14,
    paddingBottom: 14,
    borderBottom: `1px solid ${colors.grey[300]}`,
  },
  documentName: {
    fontWeight: 500,
    wordBreak: "break-all",
    margin: "0px 4px",
  },
  clausepart: {
    fontSize: 13,
    fontWeight: 400,
    color: colors.grey[600],
  },
  baseFlexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  baseIconSize: {
    width: 18,
    height: 18,
  },
  openIcon: {
    height: 16,
    width: 16,
    marginTop: 2,
    color: "#9e9e9e",
    cursor: "pointer",
  },
  expandAllButton: {
    cursor: "pointer",
    marginRight: "2px",
    marginBottom: "2px",
  },
};

function getMaxTextLength(groupClausesCount) {
  const singleLineChars = 54;
  return singleLineChars * (groupClausesCount > 4 ? 3 : 10);
}

function setExpandValueForAllClauseparts(clauseparts, newValue) {
  return (clauseparts || []).reduce((accum, clausepart) => {
    accum[clausepart.id] = newValue;
    return accum;
  }, {});
}

function getClausepartText(clausepart, getReference = false) {
  if (getReference) {
    return `${clausepart.reference}. ${clausepart.highlighted_text}`;
  }
  return clausepart.highlighted_text;
}

function DocumentListItem(props) {
  const {
    definitionGroups,
    showIntelligentDefinitions,
    item,
    precedentTerms,
    onPrecedentTermUpdate,
    isFlipChecklistIcons,
    whatToSubstitute,
  } = props;
  const {
    clauseparts,
    substitutionMap,
    optionsMap,
    activeSubstitutions,
    otherSubstitutions,
    noSubstitutions,
    whatToSubstituteStats,
    precedentDefinitions,
  } = item;

  const [showBody, updateShowBody] = useState(true);
  function triggerShowBody() {
    updateShowBody(!showBody);
  }

  const [stateSubstitutionMap, updateStateSubstitutionMap] = useState(
    substitutionMap,
  );

  const prevPrecedentTerms = usePrevious(precedentTerms);
  const prevWhatToSubstitute = usePrevious(whatToSubstitute);

  useEffect(() => {
    if (
      !_.isEqual(precedentTerms, prevPrecedentTerms) ||
      whatToSubstitute !== prevWhatToSubstitute
    ) {
      updateStateSubstitutionMap(substitutionMap);
    }
  }, [precedentTerms, whatToSubstitute]);

  const [areDefinitionsSubstituted, updateAreDefinitionsSubstituted] = useState(
    false,
  );
  function triggerSubstituteDefinitions(event, value) {
    updateAreDefinitionsSubstituted(value);
  }

  const [expandedItems, updateExpandedItems] = useState(
    setExpandValueForAllClauseparts(clauseparts, false),
  );

  function getTriggerExpandClausepart(id) {
    return function triggerExpandClausepart() {
      updateExpandedItems({
        ...expandedItems,
        [id]: !expandedItems[id],
      });
    };
  }

  const [showAllClauseparts, updateShowAllClauseparts] = useState(false);

  const shownClauseparts =
    showAllClauseparts || clauseparts.length === 5
      ? clauseparts
      : clauseparts.slice(0, 4);

  function updateSubstitutionMapValue(term, newValue = {}) {
    if (!term || !newValue) {
      return;
    }
    const oldValue = stateSubstitutionMap[term];
    const newSubstitutions = {
      ...stateSubstitutionMap,
      [term]: {
        ...oldValue,
        ...newValue,
      },
    };
    updateStateSubstitutionMap(newSubstitutions);
  }

  const areAllExpanded =
    Object.values(expandedItems).find(item => !item) !== false;
  function triggerAllExpanded() {
    if (areAllExpanded) {
      updateExpandedItems(setExpandValueForAllClauseparts(clauseparts, false));
    } else {
      updateExpandedItems(setExpandValueForAllClauseparts(clauseparts, true));
    }
  }
  function triggerShowAllClauseparts() {
    updateShowAllClauseparts(!showAllClauseparts);
  }

  function getTriggerSelectClausepart(id) {
    return function triggerSelectClausepart() {
      const items = {
        ...(props.item.id === props.selectedClauseparts.id
          ? props.selectedClauseparts.items
          : {}),
        [id]: !props.selectedClauseparts.items[id],
      };
      const selected = clauseparts.filter(clausepart => items[clausepart.id]);
      const clausepartsToCopy = selected.length === 0 ? clauseparts : selected;

      const text = clausepartsToCopy
        .map(clausepart =>
          substituteAndStyleText(
            getClausepartText(clausepart),
            "", // don't pass reference when copying
            areDefinitionsSubstituted,
            stateSubstitutionMap,
            true, // useTextOnly
            null, // optionsMap
            null, // precedentDefinitions,
          ).join(""),
        )
        .join("\n");
      props.setSelectedClauseparts({
        id: props.item.id,
        text,
        items,
      });
    };
  }

  const clausesCount = clauseparts.length;

  const updateValueFromText = (term, value) => {
    const valueClean = value.toLowerCase();
    // check definition groups whose synonyms has such value
    const presentInDgs = [];
    definitionGroups.forEach(dg => {
      if (dg.synonyms.find(syn => syn.toLowerCase() === valueClean)) {
        presentInDgs.push(dg.id);
      }
    });

    if (presentInDgs.length === 1 && props.updateOtherDocument) {
      const {
        last_edited: precedentDocLastEdited,
        id: precedentDocId,
        linked_definitions: precedentDocLinkedDefinitions,
      } = item;

      const newLinkedDefinitions = {
        ...(precedentDocLinkedDefinitions || {}),
        [presentInDgs[0]]: term,
      };

      props.updateOtherDocument(precedentDocId, precedentDocLastEdited, {
        linked_definitions: newLinkedDefinitions,
      });
    }
    onPrecedentTermUpdate({
      [term]: {
        value,
        substitutedByUser: true,
        source: "option",
      },
    });

    // update current state value
    updateSubstitutionMapValue(term, {
      userValue: {value, source: "option"},
      substitutedByUser: true,
    });
  };

  const clearUserSubstitution = term => {
    onPrecedentTermUpdate({
      [term]: {
        substitutedByUser: undefined,
        value: undefined,
        source: undefined,
      },
    });

    // update current state value
    updateSubstitutionMapValue(term, {
      userValue: undefined,
      substitutedByUser: undefined,
    });
  };

  const areClausepartsTooMany = clauseparts.length > 5;
  function renderBody() {
    return (
      <>
        <div style={{display: "flex"}}>
          {showIntelligentDefinitions && (
            <div style={{flexGrow: 1}}>
              <SubstituteDefinitions
                shouldLog={item.id === 10638}
                substitutionMap={stateSubstitutionMap}
                optionsMap={optionsMap}
                areDefinitionsSubstituted={areDefinitionsSubstituted}
                triggerSubstituteDefinitions={triggerSubstituteDefinitions}
                updateSubstitutionMapValue={updateSubstitutionMapValue}
                precedentTerms={precedentTerms}
                onPrecedentTermUpdate={onPrecedentTermUpdate}
                isFlipChecklistIcons={isFlipChecklistIcons}
                activeSubstitutions={activeSubstitutions}
                otherSubstitutions={otherSubstitutions}
                noSubstitutions={noSubstitutions}
                whatToSubstitute={whatToSubstitute}
                updateWhatToSubstitute={props.updateWhatToSubstitute}
                whatToSubstituteTypes={props.whatToSubstituteTypes}
                whatToSubstituteStats={whatToSubstituteStats}
                precedentDefinitions={precedentDefinitions}
              />
            </div>
          )}
          <div
            style={{
              backgroundColor: isFlipChecklistIcons ? "#f4f4f4" : "#eeeeee",
              flexGrow: 1,
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: "12px",
              marginLeft: "12px",
              padding: "0 1em",
              justifyContent: "space-between",
              cursor: areClausepartsTooMany ? "pointer" : "default",
              ...(isFlipChecklistIcons ? {} : {border: "1px solid #e0e0e0"}),
            }}
            onClick={
              areClausepartsTooMany
                ? () => triggerAllExpanded() || triggerShowAllClauseparts()
                : () => null
            }
          >
            <span style={areClausepartsTooMany ? {} : {color: "#888"}}>
              Show All Clauses
            </span>
            <SwitchStyled
              checked={showAllClauseparts}
              disabled={!areClausepartsTooMany}
            />
          </div>
        </div>
        <div
          style={{
            ...styles.clausepart,
            ...(isFlipChecklistIcons
              ? {
                  // background: "#f4f4f4",
                  padding: "12px 0 0 0",
                  color: "#212121",
                }
              : {padding: "12px 0px"}),
          }}
        >
          {shownClauseparts.map((clausepart, index) => {
            const {id: clausepartId} = clausepart;
            const isExpanded = expandedItems[clausepartId];
            const textWithReference = getClausepartText(clausepart, false);
            const maxTextLength = getMaxTextLength(clausesCount);
            const nonExpandedText = textWithReference.slice(0, maxTextLength);
            const text = isExpanded ? textWithReference : nonExpandedText;
            const substitutedStyledTextElements = substituteAndStyleText(
              text,
              clausepart.reference,
              areDefinitionsSubstituted,
              stateSubstitutionMap,
              false,
              optionsMap,
              precedentDefinitions,
              {updateValueFromText, clearUserSubstitution},
            );
            const substitutedPlainTextElements = substituteAndStyleText(
              textWithReference,
              "",
              areDefinitionsSubstituted,
              stateSubstitutionMap,
              true,
              optionsMap,
              precedentDefinitions,
              {updateValueFromText, clearUserSubstitution},
            ).join("");

            return (
              <ExpandableClausepart
                key={index}
                reference={clausepart.reference}
                textElements={substitutedStyledTextElements}
                text={substitutedPlainTextElements}
                isExpanded={isExpanded}
                isExpandable={
                  nonExpandedText.length !== textWithReference.length
                }
                onExpand={getTriggerExpandClausepart(clausepartId)}
                isSelected={props.selectedClauseparts.items[clausepartId]}
                onSelect={getTriggerSelectClausepart(clausepartId)}
                isFlipChecklistIcons={isFlipChecklistIcons}
              />
            );
          })}
        </div>
        {areClausepartsTooMany && (
          <div
            style={{
              ...styles.baseFlexContainer,
              color: colors.blue[400],
              padding: "7px 0px",
              ...(isFlipChecklistIcons
                ? {
                    padding: "7px 20px",
                    background: "#fff",
                    border: "1px solid #e0e0e0",
                  }
                : {}),
            }}
          >
            <div
              onClick={triggerShowAllClauseparts}
              style={{
                cursor: "pointer",
                fontSize: 12,
                fontWeight: 500,
                width: "100%",
                textAlign: "center",
              }}
            >
              {showAllClauseparts
                ? "Hide"
                : `Show ${clauseparts.length - 4} more clauses`}
            </div>
          </div>
        )}
      </>
    );
  }

  const DocumentNameOpenIcon = showBody ? RemoveIcon : AddIcon;
  return (
    <div
      style={{
        ...styles.documentListItem,
        ...(item.holds_clause_templates
          ? {border: "2px solid #afd9fb", padding: "2px 2px 0 2px"}
          : {padding: "4px"}),
      }}
    >
      <div style={{...styles.baseFlexContainer, marginBottom: 10}}>
        <div
          style={{
            ...styles.baseFlexContainer,
            fontSize: 14,
            color: isFlipChecklistIcons ? "#333333" : colors.grey[500],
          }}
        >
          <div
            onClick={triggerShowBody}
            style={{...styles.baseFlexContainer, cursor: "pointer"}}
          >
            <DocumentNameOpenIcon style={styles.openIcon} />
            <div style={styles.documentName}>
              {item.name}
              {item.holds_clause_templates ? (
                <span
                  style={{
                    padding: "0 0.5em",
                    color: "#2196f3",
                    fontStyle: "italic",
                  }}
                >
                  (Template)
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
          <a
            className="document-name-link"
            target="_blank"
            href={item.documentLink}
            style={{color: isFlipChecklistIcons ? "#333333" : colors.grey[500]}}
          >
            <LaunchIcon style={{height: 12, width: 12}} />
          </a>
        </div>
        <div
          style={{
            ...styles.circle,
            background: item.color,
          }}
        />
      </div>
      {showBody ? renderBody() : null}
    </div>
  );
}

export default DocumentListItem;

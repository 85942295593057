function getApplicableClausesPath(currentIssuesetItem) {
  if (!currentIssuesetItem) {
    return "base";
  }
  return `${currentIssuesetItem.master_id}.${
    currentIssuesetItem.remote_client_id
      ? currentIssuesetItem.remote_client_id
      : "master"
  }`;
}

export default getApplicableClausesPath;

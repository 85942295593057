import _ from "underscore";
import ActionTypes from "../constants/action_types";
import OrganisationActionTypes from "../../organisation/constants/action_types";

const defaultState = {
  "logged-in": "unknown",
  organisations: [],
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case ActionTypes.USER_FETCH.SUCCESS:
      return {...state, ...action.payload};
    case ActionTypes.USER_CLEAR.SUCCESS: {
      return defaultState;
    }
    case OrganisationActionTypes.ORGANISATION_UPDATE.SUCCESS: {
      return {
        ...state,
        organisations: state.organisations.map(organisation => {
          if (action.payload.id === organisation.id) {
            return {...organisation, name: action.payload.name};
          }
          return organisation;
        }),
      };
    }
    case OrganisationActionTypes.ORGANISATION_USER_UPDATE.SUCCESS: {
      if (state.id === action.payload.id) {
        const organisation_values = {...state.organisation_values};
        if (action.payload.default_project_id) {
          organisation_values[action.payload.organisationId] = {
            ...organisation_values[action.payload.organisationId],
            default_project_id: action.payload.default_project_id,
          };
        }
        return {
          ...state,
          ..._.omit(action.payload, [
            "default_project_id",
            "organisationId",
            "id",
          ]),
          organisation_values,
        };
      }
      return state;
    }
    case ActionTypes.USER_REGISTER.SUCCESS:
    case ActionTypes.USER_LOGIN.SUCCESS:
    case ActionTypes.USER_AUTHENTICATE_AAD_TOKEN.SUCCESS:
    case ActionTypes.USER_UPDATE.SUCCESS:
    case OrganisationActionTypes.SIGNUP_ADD.SUCCESS:
      return {...state, ...action.payload};
    case ActionTypes.USER_LOGIN.REQUEST:
      return {...state, error: null};
    case ActionTypes.USER_LOGIN.ERROR:
      return {
        "logged-in": false,
        error: "login_fail",
        errorMessage: action.payload.data,
      };
    case ActionTypes.USER_PASSWORD_FORGOT.ERROR: {
      return {
        ...state,
        error: "password_reset_fail",
        errorMessage: action.payload.data,
      };
    }
    case OrganisationActionTypes.SIGNUP_ADD.ERROR:
      return {
        "logged-in": false,
        error: "signup_fail",
        errorMessage: action.payload.data,
      };
    case OrganisationActionTypes.SIGNUP_ADD.REQUEST:
      return {
        "logged-in": false,
        error: null,
        errorMessage: null,
      };
    case ActionTypes.USER_REGISTER.ERROR:
      return {...state, "logged-in": false, error: "register_fail"};
    case ActionTypes.USER_LOGOUT.SUCCESS:
      return {"logged-in": false, organisations: []};
    case ActionTypes.USER_PASSWORD_UPDATE.SUCCESS:
      return {
        ...state,
        last_edited: action.payload.last_edited,
      };
    case ActionTypes.USER_PASSWORD_UPDATE.ERROR:
      return {...state};
    case OrganisationActionTypes.CLIENT_UPDATE.SUCCESS: {
      const {is_archived: isArchived, id: clientId} = action.payload;
      if (typeof isArchived !== "undefined") {
        return {
          ...state,
          organisations: state.organisations.map(organisation => {
            if (organisation.id === clientId) {
              return {
                ...organisation,
                is_archived: isArchived,
              };
            }
            return organisation;
          }),
        };
      }
      return state;
    }
    case ActionTypes.USER_SET_UNAUTHORIZED.SUCCESS:
      return {
        "logged-in": "unauthorized",
        organisations: [],
      };
    default:
      return state;
  }
}

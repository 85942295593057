import _ from "underscore";

function getClauseReasons(reason) {
  if (!reason) {
    return [];
  }
  if (Array.isArray(reason)) {
    return [].concat(...reason.map(subreason => getClauseReasons(subreason)));
  }
  if (reason.reason) {
    return getClauseReasons(reason.reason);
  }
  if (reason.clause_id) {
    return [reason];
  }
  return [];
}

function getClauseSortKey(documentClauses, reason) {
  const reference = documentClauses[reason.section_id].find(
    clause => clause.id === reason.clause_id,
  ).sort_reference;
  return reference;
}

function sortIssues(issues, documentClauses) {
  return _.sortBy(issues, issue => {
    const first = _.chain(getClauseReasons(issue.reason))
      .map(_.bind(getClauseSortKey, {}, documentClauses))
      .sortBy()
      .first()
      .value();

    return first || "0000";
  });
}

export default sortIssues;

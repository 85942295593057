import React from "react";
import _ from "underscore";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import ISSUE_TYPES from "common/issue_types";
import Issues from "plugins/issues";
import getIssueTypeName from "utils/issues/editor/get_issue_type_name";

function getAvailalbleIssueKeys() {
  return Object.keys(ISSUE_TYPES).filter(type => type !== "NOT");
}

function initialise() {
  const issueType = getAvailalbleIssueKeys()[0];
  return {
    issue_type: issueType,
    rules: {
      ...Issues[issueType.toLowerCase()].editor.initialise(),
    },
  };
}

function validate(issue) {
  const childIssue = issue.rules;
  return Issues[childIssue.issue_type.toLowerCase()].editor.validate(
    childIssue,
  );
}

class Component extends React.Component {
  render() {
    const {rules} = this.props;
    return (
      <div>
        <div className="issue-list">
          <div>{this.renderIssue(rules)}</div>
        </div>
      </div>
    );
  }

  renderIssue(issue, error, index) {
    if (!issue.issue_type) {
      return null;
    }
    const IssueComponent =
      issue.issue_type &&
      Issues[issue.issue_type.toLowerCase()].editor.component;
    if (!IssueComponent) {
      return null;
    }
    return (
      <div
        className="issue"
        key={index}
        style={{
          border: "1px solid #ddd",
          margin: "1em",
          padding: "1em",
        }}
      >
        <SelectField
          value={issue.issue_type}
          onChange={this.updateType}
          className="issue-type"
          style={{
            width: "20em",
            margin: "1em",
          }}
          floatingLabelText="Issue type"
          disabled={
            this.props.isNonOverridableFieldsDisabled || this.props.disabled
          }
        >
          {_.sortBy(
            getAvailalbleIssueKeys(),
            type => Issues[type.toLowerCase()].order,
          ).map(type => (
            <MenuItem
              value={type}
              primaryText={getIssueTypeName(Issues, type)}
              key={type}
            />
          ))}
        </SelectField>
        <IssueComponent
          {...this.props}
          path={`${this.props.path}.0`}
          rules={issue.rules}
          rulesError={error && error.rulesError}
          onChange={this.updateRules}
        />
      </div>
    );
  }

  /* eslint-disable no-invalid-this, no-spaced-func, func-call-spacing */
  updateRules = change => {
    this.props.onChange({
      ...this.props.rules,
      rules: change,
    });
  };
  updateType = (event, idx, value) => {
    const rules = {
      ui_order_id: `${this.props.path}.0`,
      issue_type: value,
      rules: Issues[value.toLowerCase()].editor
        .initialise
        // issues[index].rules,
        (),
    };
    this.props.onChange(rules);
  };
  /* eslint-enable no-invalid-this */
}

export default {
  initialise,
  validate,
  component: Component,
};

import React from "react";
import _ from "underscore";
import {cloneDeep} from "lodash";

import FlatButton from "material-ui/FlatButton";
import AddIcon from "material-ui/svg-icons/av/playlist-add";
import * as colors from "material-ui/styles/colors";

import EditorItem from "./editor_item";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "24rem",
  },
  addItemButton: {
    margin: "0.2rem",
  },
};

class ValueEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {values: props.values};
  }

  componentDidUpdate(prevProps) {
    const {values: prevValues} = prevProps;
    const {values: currentValues} = this.props;
    if (prevValues && currentValues && !_.isEqual(prevValues, currentValues)) {
      this.setState(() => ({values: currentValues}));
    }
  }

  render() {
    return (
      <div
        style={{
          ...styles.mainContainer,
          ...(this.props.mainContainerStyles || {}),
        }}
      >
        {this.state.values &&
          this.state.values.map((value, index) => (
            <EditorItem
              key={index}
              value={value}
              valueIndex={index}
              onUpdateValue={this.onUpdateValue}
              onDeleteValue={this.onDeleteValue}
            />
          ))}
        <FlatButton
          backgroundColor={colors.grey100}
          icon={<AddIcon color={colors.grey700} />}
          style={styles.addItemButton}
          onClick={this.onAddValue}
        />
      </div>
    );
  }

  onDeleteValue = index => () =>
    this.setState(prevState => {
      const {values} = prevState;
      values.splice(index, 1);
      return {values};
    }, this.changeValues);

  onAddValue = () =>
    this.setState(
      prevState => ({
        values: [
          ...(prevState.values ? prevState.values : []),
          {value: 0, unit: "number"},
        ],
      }),
      this.changeValues,
    );

  onUpdateValue = (updatedItem, index) => {
    this.setState(prevState => {
      const newValues = cloneDeep(prevState.values);
      newValues[index] = updatedItem;
      return {values: newValues};
    }, this.changeValues);
  };

  changeValues = () => {
    if (this.props.onValuesChange) {
      this.props.onValuesChange(this.state.values);
    }
  };
}

export default ValueEditor;

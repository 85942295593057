// TODO: this function will treat (i) as Roman thus all
// items after it will be shown based on it. This might
// cause collisions for long lists having i item.
import {romanToInt, intToRoman} from "./roman_number";

function isDecimal(str) {
  return new RegExp("^\\d+$").test(str);
}

function isRoman(str) {
  return new RegExp("^[ivx]+$", "i").test(str);
}

function isAlpabetical(str) {
  return new RegExp("^\\w+$").test(str);
}

function splitReference(ref) {
  const refArray = ref.split(".");
  return {
    prefix: refArray.slice(0, refArray.length - 1).join("."),
    last: refArray[refArray.length - 1],
  };
}

/* eslint-disable complexity */
function increaseReference(reference, prevReference, numberOffset = 1) {
  if (prevReference) {
    const splitedRef = splitReference(reference);
    const lastRef = splitedRef.last;
    const lastPrevRef =
      typeof prevReference === "string" && splitReference(prevReference).last;
    let newLast;

    if (
      isDecimal(lastRef) &&
      (prevReference === true ||
        (isDecimal(lastPrevRef) &&
          parseInt(lastPrevRef, 10) + 1 === parseInt(lastRef, 10)))
    ) {
      newLast = `${parseInt(lastRef, 10) + numberOffset}`;
    } else if (
      isRoman(lastRef) &&
      (prevReference === true ||
        (isRoman(lastPrevRef) &&
          romanToInt(lastPrevRef) + 1 === romanToInt(lastRef)) ||
        lastRef === lastPrevRef)
    ) {
      newLast = `${intToRoman(romanToInt(lastRef) + numberOffset)}`;
    } else if (
      isAlpabetical(lastRef) &&
      (prevReference === true || isAlpabetical(lastPrevRef))
    ) {
      newLast = `${lastRef.slice(0, lastRef.length - 1)}${String.fromCharCode(
        lastRef.charCodeAt(lastRef.length - 1) + numberOffset,
      )}`;
    } else {
      return reference;
    }
    const isUpper = lastRef === lastRef.toUpperCase();
    const isLower = lastRef === lastRef.toLowerCase();
    return `${splitedRef.prefix ? `${splitedRef.prefix}.` : ""}${
      isUpper
        ? newLast.toUpperCase()
        : isLower ? newLast.toLowerCase() : newLast
    }`;
  }
  return reference;
}
/* eslint-enable complexity */

export default increaseReference;

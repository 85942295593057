import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function sectionsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.DOCUMENT_FETCH.SUCCESS:
      return !action.payload.sections ? state : action.payload.sections;
    case ActionTypes.DOCUMENT_CLEAR.SUCCESS:
      return UNINITIALISED;
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

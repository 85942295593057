export default function zipObject(...args) {
  const names = [];
  const lists = [];
  const defaults = [];
  const takeFirstValue = [];
  let longestList = null;
  args.forEach(pair => {
    if (pair && Array.isArray(pair[1])) {
      names.push(pair[0]);
      lists.push(pair[1]);
      defaults.push(pair[2]);
      takeFirstValue.push(pair[3]);
      if (!longestList) {
        longestList = pair[1];
      } else if (longestList.length < pair[1].length) {
        longestList = pair[1];
      }
    }
  });
  const result = [];
  for (let itemIndex = 0; itemIndex < longestList.length; itemIndex += 1) {
    const group = {};
    lists.forEach((list, index) => {
      group[names[index]] =
        (takeFirstValue[index] ? list[itemIndex]?.[0] : list[itemIndex]) ??
        defaults[index];
    });
    result.push(group);
  }
  return result;
}

import Describer from "../../../utils/issues/reason/describer";

function reason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);
  return [
    `Topic '${describer.topicName}' is present`,
    showClause && describer.clauseReferences
      ? ` in clause ${describer.clauseReferences}`
      : "",
  ].join("");
}

export default reason;

import React from "react";
import _ from "lodash";

import SidebarContext from "routes/logged_in/components/sidebar/context";
import NavItem from "routes/logged_in/components/sidebar/nav_item";

import SearchItem from "common_components/search";
import TopicsItem from "./items/topics";
import ContentItem from "./items/content";
import ViewModesItem from "./items/view_modes";

export default class SidebarCollapsedNav extends React.Component {
  constructor(props) {
    super(props);
    this.items = [SearchItem, ContentItem, TopicsItem];
    if (
      !this.props.isCollapsedNavDisabled &&
      this.props.project.document_type.id === 4
    ) {
      this.items.push(ViewModesItem);
    }
  }

  render() {
    if (this.props.isCollapsedNavDisabled) {
      return this.items.map((Item, index) => {
        const {Icon} = Item;
        return (
          <NavItem
            key={index}
            isCollapsed={true}
            isDisabled={true}
            icon={<Icon />}
          />
        );
      });
    }
    return this.items.map((Item, index) => {
      const props = _.isFunction(Item.passProps)
        ? Item.passProps(this.props)
        : this.props;
      return (
        <Item
          key={index}
          showSidebarPanel={this.context.showPanel}
          {...props}
        />
      );
    });
  }
}

SidebarCollapsedNav.contextType = SidebarContext;

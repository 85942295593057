import _ from "underscore";
import logger from "../../logger";

import getClauseByPath from "../../get_clause_by_path";
import padClausepartId from "../../pad_clausepart_id";

function createClauseResult(clause, path) {
  const clauseResult = {...clause};
  if (path) {
    const clausepart = getClauseByPath(clause, path);
    if (clausepart && clausepart.clauseId) {
      clauseResult.display_reference = clausepart.clauseId;
      clauseResult.clausepart_sort_reference = padClausepartId(
        clausepart.clauseId,
      );
    } else {
      clauseResult.display_reference = clause.reference;
      clauseResult.clausepart_sort_reference = clause.sort_reference;
    }
  } else {
    clauseResult.display_reference = clause.reference;
    clauseResult.clausepart_sort_reference = clause.sort_reference;
  }
  return [clauseResult];
}

function getClause(reason, documentClauses) {
  try {
    const section = documentClauses ? documentClauses[reason.section_id] : null;
    if (section) {
      const clause =
        section && section.find(_clause => _clause.id === reason.clause_id);
      const {path} = reason;
      if (clause) {
        return createClauseResult(clause, path);
      }
    }
  } catch (ex) {
    logger.error("failed to get clause for reason", reason, ex);
  }
  return [];
}

function getClauses(reason, documentClauses) {
  if (reason) {
    if (Array.isArray(reason)) {
      return reason
        .map(_reason => getClauses(_reason, documentClauses))
        .filter(_reason => _reason);
    }
    if (reason instanceof Object) {
      if (reason.section_id && reason.clause_id) {
        return getClause(reason, documentClauses);
      }
      return _.map(reason, value => getClauses(value, documentClauses));
    }
  }
  return [];
}

function getIssueClauses(issue, documentClauses) {
  const res = _.flatten(getClauses(issue.reason, documentClauses));
  return res;
}

export default getIssueClauses;

import React from "react";
import Paper from "material-ui/Paper";
import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import getIssueNames from "utils/issues/get_issue_names";

import * as colors from "material-ui/styles/colors";

const styles = {
  mainContainer: {
    paddingLeft: "0.2rem",
  },
  issueContainer: {
    padding: "4px",
    fontFamily: "Roboto, sans-serif",
    textAlign: "justify",
    cursor: "pointer",
    backgroundColor: "#fdf7f8",
  },
  issueName: {
    fontSize: "12px",
    fontWeight: 400,
  },
  issueReason: {
    fontSize: "10px",
    fontWeight: 100,
    fontStyle: "italic",
  },
  noIssues: {
    fontStyle: "italic",
    textAlign: "center",
    padding: "5px",
    fontSize: "13px",
    backgroundColor: "#fdf7f8",
  },
};

function Issues(props) {
  const {
    clauseIssues,
    documentClauses,
    topicsById,
    positiveReasonData,
    showIssueDetail,
    documentClauseparts,
    currentIssuesetItem,
    document,
  } = props;
  const issuesWithGroupNames = clauseIssues.map(issue => {
    const names = getIssueNames(issue);
    return {
      ...issue,
      name: names.name,
      groupName: names.groupName,
    };
  });

  return (
    <div className="issues" style={styles.mainContainer}>
      <Paper
        style={{
          ...props.headingStyles,
          backgroundColor:
            issuesWithGroupNames.length > 0 ? colors.red400 : colors.red50,
          color: issuesWithGroupNames.length > 0 ? "#fff" : "#000000",
        }}
      >
        Issues
      </Paper>
      {issuesWithGroupNames.length > 0 ? (
        renderIssues(
          issuesWithGroupNames,
          currentIssuesetItem,
          documentClauses,
          topicsById,
          positiveReasonData,
          showIssueDetail,
          documentClauseparts,
          document?.parties || [],
        )
      ) : (
        <div style={styles.noIssues}>No issues found</div>
      )}
    </div>
  );
}
function renderIssues(
  issues,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  showIssueDetail,
  documentClauseparts,
  parties,
) {
  return issues.map(issue => (
    <div
      key={issue.name}
      style={styles.issueContainer}
      onClick={createClicker(issue, showIssueDetail)}
    >
      <span style={styles.issueName}>{getIssueName(issue.name)}</span>
      <span> - </span>
      <span style={styles.issueReason}>
        {!issue.reason || issue.reason.length === 0
          ? issue.reason_template
          : getIssueTemplatedText(
              issue,
              issueset,
              "reason_template",
              documentClauses,
              topicsById,
              positiveReasonData,
              documentClauseparts,
              parties,
              false,
            )[0]}
      </span>
    </div>
  ));
}
function createClicker(issue, onClick) {
  return () => onClick(issue);
}
function getIssueName(name) {
  const nameArray = name.split("/");
  return nameArray.length > 1
    ? nameArray[nameArray.length - 1].trim()
    : nameArray[0].trim();
}
export default Issues;

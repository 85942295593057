import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function rolesFetch(requestor) {
  return createReduxPromiseAction(ActionTypes.ROLES_FETCH, organisationId =>
    requestor
      .get(`${basePath}/organisation/${organisationId}/role`, {
        withCredentials: true,
      })
      .then(response => {
        return response.data;
      }),
  );
}

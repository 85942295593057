import _ from "underscore";
import React from "react";

import ReactSelectLabel from "common_components/react_select_label";
import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";

import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

function initialise(state) {
  return {
    topic_id: null,
    parameter_id: null,
    role_id: null,
    ..._.pick(state, "topic_id", "parameter_id", "role_id"),
  };
}

function validate(issue) {
  if (!(issue.rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  if (!(issue.rules.parameter_id > 0)) {
    return {rulesError: {parameter_id: "You must set a parameter id"}};
  }
  return null;
}

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      rulesError,
      topics,
      isNonOverridableFieldsDisabled,
      disabled,
      shouldTopicSelectBeHighlighted,
      roles = [],
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    return (
      <div>
        <div>
          <ReactSelectLabel>Topic</ReactSelectLabel>
          <Select
            className="topic"
            multi={false}
            value={rules.topic_id}
            options={topics
              .filter(topic =>
                topic.parameters.find(
                  parameter => parameter.parameter_type === "role",
                ),
              )
              .map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
            onChange={this.updateTopic}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getTopicLink(this.props.organisationId, rules.topic_id)}
            shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
          />
          {renderError(rulesError, "topic_id")}
        </div>
        <div>
          <ReactSelectLabel>Parameter</ReactSelectLabel>
          <Select
            className="parameter"
            multi={false}
            value={rules.parameter_id}
            options={
              currentTopic &&
              currentTopic.parameters
                .filter(parameter => parameter.parameter_type === "role")
                .map(parameter => ({
                  label: parameter.name,
                  value: parameter.id,
                }))
            }
            onChange={this.updateParameter}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getParameterLink(
              this.props.organisationId,
              rules.topic_id,
              rules.parameter_id,
            )}
          />
          {renderError(rulesError, "parameter_id")}
        </div>
        <div>
          <ReactSelectLabel>Role</ReactSelectLabel>
          <Select
            className="role"
            multi={false}
            value={rules.role_id}
            options={roles.map(role => ({
              label: role.name,
              value: role.id,
            }))}
            onChange={this.updateRole}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getRoleLink(this.props.organisationId, rules.role_id)}
          />
        </div>
      </div>
    );
  }

  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
      parameter_id: null,
    });
  };
  updateParameter = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_id: value.value,
    });
  };
  updateRole = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      role_id: value.value,
    });
  };
  /* eslint-enable no-invalid-this */
}

export function getRoleLink(organisationId, roleId) {
  return organisationId && roleId
    ? `/organisation/${organisationId}/role/${roleId}/detail`
    : null;
}

export default {
  initialise,
  validate,
  component: Component,
};

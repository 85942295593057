import ActionTypes from "../constants/action_types";

export default function clearDocument(organisationId, projectId, documentId) {
  return {
    type: ActionTypes.DOCUMENT_CLEAR.SUCCESS,
    organisation_id: organisationId,
    project_id: projectId,
    document_id: documentId,
  };
}

import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import {ClickAwayListener, Popper} from "@material-ui/core";
import {Paper} from "material-ui";
import {containerStyles, topPanelStyles} from "./styles";

const FiltersContainer = ({children, title, theme, btnIcon, reportView}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => setOpen(prevOpen => !prevOpen);
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div
      style={
        reportView === "rag_report"
          ? {
              ...containerStyles.filterContainer,
              borderRight: "1px solid lightgrey",
            }
          : reportView === "top_panel"
          ? topPanelStyles.filterContainer
          : theme
          ? {
              ...containerStyles.filterContainer,
              borderLeft: "1px solid lightgrey",
            }
          : {
              ...containerStyles.filterContainer,
              borderLeft: "1px solid #4F575f",
            }
      }
    >
      <Button
        ref={anchorRef}
        style={containerStyles.buttonContainer}
        onClick={handleToggle}
        startIcon={btnIcon}
      >
        <div
          style={
            reportView === "rag_report"
              ? containerStyles.buttonTitle
              : {
                  ...containerStyles.buttonTitle,
                  fontSize: "12px",
                }
          }
        >
          {title}
        </div>
      </Button>
      <Popper
        style={{zIndex: 10}}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
      >
        <Paper style={containerStyles.itemsContainer}>
          <ClickAwayListener onClickAway={handleClose}>
            <div>{children}</div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

export default FiltersContainer;

import React from "react";
import _ from "underscore";

import SvgIcon from "material-ui/SvgIcon";
import ArrowRightIcon from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import ArrowDownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import * as colors from "material-ui/styles/colors";

import renderClause from "utils/clauses/render_clause";
import topicGroupName from "utils/topic/topic_name_utils";

const styles = {
  mainContainer: {},
  arrowIcon: {
    width: "13px",
    height: "13px",
  },
  topicClauses: {
    padding: "0.8rem 0rem",
    borderBottom: `1px solid ${colors.grey200}`,
  },
  topicName: {
    fontSize: "14px",
    marginBottom: "0.4rem",
    fontWeight: "600",
    color: colors.blueA700,
  },
};

class RelatedClausesGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
    };
  }

  render() {
    return (
      <div>
        {!this.props.isHeaderHidden && this.renderHeader()}
        {(this.props.isHeaderHidden || this.state.isOpen) &&
          this.renderTopicClauses(this.props.relatedClausesGroup)}
      </div>
    );
  }

  renderHeader = () => (
    <div
      style={topicGroupName.topicGroupNameStyles}
      onClick={this.triggerShowGroup}
    >
      <span style={{fontWeight: this.state.isOpen ? 500 : 200}}>
        {topicGroupName.getTopicGroupName(this.props.groupName)}
      </span>
      <span style={{position: "relative"}}>
        <SvgIcon style={styles.arrowIcon}>
          {this.state.isOpen ? <ArrowDownIcon /> : <ArrowRightIcon />}
        </SvgIcon>
      </span>
    </div>
  );

  renderTopicClauses = relatedClausesGroup => {
    const result = [];
    _.each(relatedClausesGroup, (topicClauses, topicName) => {
      result.push(
        <div
          style={styles.topicClauses}
          key={topicName}
          className="topicClauses"
        >
          <div style={styles.topicName}>{topicName}</div>
          <div>
            {topicClauses.map((clause, index) =>
              renderClause(
                clause,
                index,
                this.props.onClauseClick,
                [],
                false,
                this.props.document.file_extension,
              ),
            )}
          </div>
        </div>,
      );
    });
    return result;
  };

  triggerShowGroup = () => {
    this.setState(prevState => ({isOpen: !prevState.isOpen}));
  };
}

export default RelatedClausesGroup;

import _ from "underscore";
import {get, set} from "lodash";
import logger from "../logger";
import Handlebars from "handlebars";

import globalStore from "common/utils/global_store";

import registerHandlebarsHelpers from "../handlebars_helpers/register";

import getIssueClauses from "./reason/get_issue_clauses";
import replaceNewlineToken from "./reason/replace_newline_token";

import calculateClauseReferences from "./reason/calculate_clause_references";

function addTopics(el, topicsById) {
  if (el.topic_id) {
    const topic = topicsById[el.topic_id];
    return {...el, topic};
  }
  return _.mapObject(el, child => {
    if (Array.isArray(child)) {
      return child.map(arrayEl => addTopics(arrayEl, topicsById));
    }
    if (child instanceof Object) {
      return addTopics(child, topicsById);
    }
    return child;
  });
}

/* eslint-disable max-statements */
function getIssueTemplatedTextInternalUnMemo(
  issue,
  issueset,
  templateField,
  clauses,
  topicsById,
  topicParameterData,
  reasonData,
  parties,
  showClause = true,
  logInfo = false,
  preventMemo = false,
) {
  registerHandlebarsHelpers();
  const rules = addTopics(issue.rules, topicsById);
  const issueClauses = getIssueClauses(issue, clauses);
  const clauseReference = issueClauses.map(clause => clause.reference);
  const clauseReferences = calculateClauseReferences(issueClauses);
  try {
    const detailedIssue = {
      ...issue, // todo: this is slow
      rules,
      reason: (issue.reason || []).map(reason => {
        const detailedReason = {
          ...reason,
          clauseReferences: calculateClauseReferences(
            getIssueClauses({reason}, clauses),
          ),
        };
        return detailedReason;
      }),
    };
    let templateText =
      templateField === "checklist_definitions"
        ? issueset.template_definitions ||
          issueset.master_issueset_template_definitions
        : issue[templateField];

    if (templateField === "reason_template") {
      templateText = showClause
        ? issue.reason_template
        : (issue.reason_template || "").replace(
            /Clause \{\{\{clauseReferences\}\}\}:/g,
            "",
          );
    }
    const template = Handlebars.compile(templateText || "", ["<%=", "%>"]);
    if (logInfo) {
      logger.verbose("issue", detailedIssue);
      logger.verbose("data", topicParameterData);
      logger.verbose("clauses", clauses);
    }
    let checklistDefinitions = {};
    let commonDefinitions = {};
    if (
      templateField !== "checklist_definitions" &&
      templateField !== "common_definitions"
    ) {
      set(globalStore, "setting_checklist_definitions", true);
      getIssueTemplatedTextInternal(
        issue,
        issueset,
        "checklist_definitions",
        clauses,
        topicsById,
        topicParameterData,
        reasonData,
        parties,
        showClause,
        logInfo,
        preventMemo,
      );
      set(globalStore, "setting_checklist_definitions", false);
      checklistDefinitions = get(
        globalStore,
        `checklistDefinitions.${issueset.id}`,
      );

      set(globalStore, "setting_common_definitions", true);
      getIssueTemplatedTextInternal(
        issue,
        issueset,
        "common_definitions",
        clauses,
        topicsById,
        topicParameterData,
        reasonData,
        parties,
        showClause,
        logInfo,
        preventMemo,
      );
      set(globalStore, "setting_common_definitions", false);
      commonDefinitions = get(globalStore, `commonDefinitions.${issue.id}`);
    }
    const string = template({
      ...checklistDefinitions,
      ...commonDefinitions,
      issue: detailedIssue,
      issueset,
      clauses,
      clauseReference,
      clauseReferences,
      topicsById,
      data: topicParameterData,
      reasonData,
      parties,
      showClause,
    });
    return string
      .split("__split__")
      .map(str => str.trim())
      .map(replaceNewlineToken)
      .filter(str => str.length > 0);
  } catch (ex) {
    logger.error(
      `Error in issue ${issue.id} (${issue.true_name ||
        issue.name}) - field: "${templateField}"`,
    );
    logger.error(ex);
    return [];
  }
}

const getIssueTemplatedTextInternalMemo = _.memoize(
  getIssueTemplatedTextInternalUnMemo,
  (
    issue,
    issueset,
    templateField,
    _1,
    _2,
    _3,
    _4,
    _5,
    _6,
    _7,
    _8,
    last_classification_changed,
  ) =>
    [
      issue.id,
      issue.last_edited,
      issueset?.id ?? -1,
      templateField,
      last_classification_changed,
    ].join("_"),
);

function getIssueTemplatedTextInternal(
  issue,
  issueset,
  templateField,
  clauses,
  topicsById,
  topicParameterData,
  reasonData,
  parties,
  showClause,
  logInfo,
  preventMemo,
  last_classification_changed,
) {
  if (preventMemo) {
    return getIssueTemplatedTextInternalUnMemo(
      issue,
      issueset,
      templateField,
      clauses,
      topicsById,
      topicParameterData,
      reasonData,
      parties,
      showClause,
      logInfo,
      preventMemo,
    );
  }
  return getIssueTemplatedTextInternalMemo(
    issue,
    issueset,
    templateField,
    clauses,
    topicsById,
    topicParameterData,
    reasonData,
    parties,
    showClause,
    logInfo,
    preventMemo,
    last_classification_changed,
  );
}

function getIssueTemplatedTextUnMemo(
  issue,
  issueset,
  templateField,
  clauses,
  topicsById,
  topicParameterData,
  reasonData = [],
  parties = [],
  showClause,
  logInfo,
  preventMemo,
  last_classification_changed,
) {
  const reason =
    issue.reason && Array.isArray(issue.reason)
      ? issue.reason.map(_reason => _reason.reason)
      : issue.reason;
  return getIssueTemplatedTextInternal(
    {...issue, reason},
    issueset,
    templateField,
    clauses,
    topicsById,
    topicParameterData,
    reasonData,
    parties,
    showClause,
    logInfo,
    preventMemo,
    last_classification_changed,
  );
}

const memoFn = (
  issue,
  issueset,
  templateField,
  _1,
  _2,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8,
  last_classification_changed,
) =>
  [
    issue.id,
    issue.last_edited,
    issueset.roles_last_processed,
    issueset.issues_last_processed,
    issueset?.id ?? -1,
    templateField,
    last_classification_changed,
  ].join("_");

const getIssueTemplatedTextMemo = _.memoize(
  getIssueTemplatedTextUnMemo,
  memoFn,
);

function getIssueTemplatedText(
  issue,
  issueset,
  templateField,
  clauses,
  topicsById,
  topicParameterData,
  reasonData = [],
  parties = [],
  showClause,
  logInfo,
  preventMemo,
  last_classification_changed,
) {
  if (preventMemo) {
    return getIssueTemplatedTextUnMemo(
      issue,
      issueset,
      templateField,
      clauses,
      topicsById,
      topicParameterData,
      reasonData,
      parties,
      showClause,
      logInfo,
      preventMemo,
      last_classification_changed,
    );
  }
  return getIssueTemplatedTextMemo(
    issue,
    issueset,
    templateField,
    clauses,
    topicsById,
    topicParameterData,
    reasonData,
    parties,
    showClause,
    logInfo,
    preventMemo,
    last_classification_changed,
  );
}

export default getIssueTemplatedText;

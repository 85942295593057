import React, {useState} from "react";
import _ from "lodash";
import copy from "copy-to-clipboard";

import CopyIcon from "@material-ui/icons/FileCopy";
import DoneIcon from "@material-ui/icons/Done";
import grey from "@material-ui/core/colors/grey";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import Permissioner from "utils/permissioner";
import ItemContainer from "../helper_components/item_container";
import getIssueReasonData from "../utils/get_issue_reason_data";

import Issues from "plugins/issues";

const styles = {
  baseContainer: {
    flex: "0 0 auto",
    fontWeight: "400",
    wordWrap: "break-word",
    color: grey[600],
  },
  bodyContainer: {
    unflipped: {
      border: "1px solid lightgray",
      padding: "8px",
      margin: "4px 0",
    },
  },
  getIssueContainerStyle: isReasonParent => ({
    ...(isReasonParent
      ? {}
      : {
          borderLeft: "1px solid lightgray",
          marginLeft: "6px",
          padding: "6px",
        }),
    display: "flex",
    flexDirection: "column",
  }),
  reasonText: {
    wordBreak: "break-word",
    wordWrap: "break-word",
  },
  icon: {height: 13, width: 13},
};

function convertToTemplatePath(rawPath) {
  if (!rawPath) {
    return rawPath;
  }
  let path = rawPath;
  // 1) remove all reason. except the first one
  const noReasonPath = rawPath.replace(/reason\.?/g, "");
  path = `issue.reason${noReasonPath}`;

  // 2) replace each [ with .[
  path = path.replace(/\.\[/g, "[").replace(/\[/g, ".[");

  // 3) remove dot in the end if exists
  path = path.replace(/\.$/, "");

  return path;
}

function BaseCopyableItem({label, hint, copyValue, textStyles = {}}) {
  const [isItemHovered, setIsItemHovered] = useState(false);
  function hoverStart() {
    setIsItemHovered(true);
  }
  function hoverFinish() {
    setIsItemHovered(false);
  }
  function onPathCopy() {
    copy(copyValue, {format: "text/plain"});
  }

  return (
    <span
      onMouseEnter={hoverStart}
      onMouseLeave={hoverFinish}
      onClick={onPathCopy}
      title={hint && hint.toString().replace(/^\"|\"$/g, "")}
      style={{
        cursor: "pointer",
        textDecoration: isItemHovered ? "underline" : "none",
        ...textStyles,
      }}
    >
      {label}
    </span>
  );
}

function CopyablePath({
  path,
  displayPath,
  rootStyles = {},
  textStyles = {},
  underlineOnHover = false,
  hint,
}) {
  const [isItemHovered, setIsItemHovered] = useState(false);
  const [isOkIconShown, setOkIconShown] = useState(false);

  function showCopyIcon() {
    setIsItemHovered(true);
  }

  function hideCopyIcon() {
    setIsItemHovered(false);
  }

  function onPathCopy() {
    copy(path, {format: "text/plain"});
    setOkIconShown(true);
    setTimeout(() => {
      setOkIconShown(false);
    }, 1000);
  }

  return (
    path && (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          ...rootStyles,
        }}
        onMouseEnter={showCopyIcon}
        onMouseLeave={hideCopyIcon}
        onClick={onPathCopy}
      >
        <span
          title={hint && hint.replace(/^\"|\"$/g, "")}
          style={{
            textDecoration:
              isItemHovered && underlineOnHover ? "underline" : "none",
            ...textStyles,
          }}
        >
          {displayPath || path}
        </span>
        {isItemHovered && !isOkIconShown && <CopyIcon style={styles.icon} />}
        {isOkIconShown && <DoneIcon style={styles.icon} />}
      </div>
    )
  );
}

function renderIssueReasonItem(
  reasonItem,
  itemKey,
  isReasonParent,
  onClauseClick,
  applicableClauses,
  topicsById,
  showUnused,
  isComposite,
) {
  const {clausepart_id: clausepartId, issue_type: issueType = ""} = reasonItem;
  const reasonValues =
    !issueType || !reasonItem.reason
      ? []
      : Issues[issueType.toLowerCase()].reasonViewer({
          ...reasonItem.reason,
          topicsById,
        });
  const applicableClause = clausepartId
    ? applicableClauses.find(clausepart => clausepart.id === clausepartId)
    : null;

  const onClauseReferenceClick = () => {
    if (applicableClause && onClauseClick) {
      onClauseClick(applicableClause);
    }
  };
  const templatePath = convertToTemplatePath(reasonItem.path);
  const reasonValuesComponent =
    isComposite &&
    reasonItem.reason &&
    renderReasonValues(reasonValues, itemKey, templatePath, showUnused);
  const nonCompositeReasonItemComponent =
    !isComposite &&
    renderNonCompositeReasonItem(
      reasonItem,
      reasonValues,
      itemKey,
      templatePath,
      topicsById,
      showUnused,
    );
  const childrenComponents = (reasonItem.children || []).map(
    (reasonItemChild, reasonItemChildIndex) => {
      const args = [
        reasonItemChild,
        `${itemKey}-${reasonItemChildIndex},`,
        false,
        onClauseClick,
        applicableClauses,
        topicsById,
        showUnused,
      ];
      return reasonItemChild.children
        ? renderIssueReasonItem(...args, true)
        : renderIssueReasonItem(...args, false);
    },
  );
  if (
    !reasonValuesComponent &&
    !nonCompositeReasonItemComponent &&
    !childrenComponents.find(item => item)
  ) {
    return null;
  }
  return (
    <div key={itemKey} style={styles.getIssueContainerStyle(isReasonParent)}>
      {issueType && (
        <span style={{color: grey[800]}} className="issue-type">
          {Issues[issueType.toLowerCase()].displayName}
        </span>
      )}
      <CopyablePath path={templatePath} />
      {applicableClause && (
        <div
          title={onClauseClick ? "Scroll to clause" : undefined}
          style={{cursor: onClauseClick ? "pointer" : "default"}}
          onClick={onClauseReferenceClick}
        >
          {`Cl. ${applicableClause.reference}`}
        </div>
      )}

      {reasonValuesComponent}
      {nonCompositeReasonItemComponent}
      {childrenComponents}
    </div>
  );
}

function renderReasonValues(
  reasonValues = [],
  itemKey,
  baseTemplatePath,
  showUnused,
  rootStyles = {},
  textStyles = {},
) {
  return reasonValues
    .map((reasonValue, valuePathIndex) => {
      if (reasonValue.value !== undefined) {
        return (
          <CopyablePath
            key={`${itemKey}-${valuePathIndex}`}
            path={`${baseTemplatePath}.${reasonValue.path}`}
            hint={reasonValue.value && JSON.stringify(reasonValue.value)}
            displayPath={reasonValue.path}
            rootStyles={{paddingLeft: "10px", ...rootStyles}}
            textStyles={textStyles}
            underlineOnHover={true}
          />
        );
      } else if (reasonValue.values) {
        return (
          <div
            key={`${itemKey}-${valuePathIndex}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              ...rootStyles,
            }}
          >
            <div style={{flexShrink: 0, ...textStyles}}>{`${baseTemplatePath}.${
              reasonValue.path
            }`}</div>
            <div style={{flexShrink: 0, ...textStyles}}>
              {reasonValue.values.reduce((accum, value, valueIndex) => {
                const baseItem = (
                  <BaseCopyableItem
                    key={`item-${valuePathIndex}-${valueIndex}`}
                    label={value.label}
                    hint={value.value}
                    copyValue={`${baseTemplatePath}.${value.path}`}
                  />
                );
                if (valueIndex !== reasonValue.values.length - 1) {
                  return accum.concat(
                    baseItem,
                    <span key={`divider-${valuePathIndex}-${valueIndex}`}>
                      {" / "}
                    </span>,
                  );
                }
                return accum.concat(baseItem);
              }, [])}
            </div>
          </div>
        );
      }
      return null;
    })
    .filter(item => item);
}

function renderNonCompositeReasonItem(
  reasonItem,
  reasonValues,
  itemKey,
  templatePath,
  topicsById,
  showUnused,
) {
  if (!reasonItem.reason && reasonItem.not_triggered_rules) {
    const nonTriggeredRules = reasonItem.not_triggered_rules;
    const rules = _.omit(
      nonTriggeredRules.rules ? nonTriggeredRules.rules : nonTriggeredRules,
      "topic_location",
    );

    const issueType = nonTriggeredRules.issue_type
      ? nonTriggeredRules.issue_type
      : reasonItem.issue_type;

    const ruleValues =
      !issueType || !rules
        ? []
        : Issues[issueType.toLowerCase()].getDefaultIssueRuleValues({
            ...rules,
            topicsById,
          });
    if (showUnused) {
      return null;
    }
    return renderReasonValues(
      ruleValues,
      itemKey,
      templatePath,
      showUnused,
      {color: "#BDBDBD"},
      {background: "#FFF6F6"},
    );
  } else if (reasonItem.reason) {
    return renderReasonValues(reasonValues, itemKey, templatePath, showUnused);
  }
  return <span style={styles.reasonText}>Issue not triggered</span>;
}

function IssueReasons(props) {
  if (
    !new Permissioner(props.user).hasPermission("view-issue-reason") ||
    props.clientModeOn
  ) {
    return null;
  }
  const {
    issue,
    onClauseClick,
    applicableClauses,
    headerStyles = {},
    containerStyles = {},
    isFlipChecklistIcons,
  } = props;

  function renderReasonData(reasonData, topicsById, showUnused) {
    return reasonData.map((reasonItem, reasonItemIndex) => {
      const itemKey = `base-${reasonItemIndex}`;
      const args = [
        reasonItem,
        itemKey,
        true,
        onClauseClick,
        applicableClauses,
        topicsById,
        showUnused,
      ];
      return reasonItem.children && reasonItem.children.length > 0
        ? renderIssueReasonItem(...args, true)
        : renderIssueReasonItem(...args, false);
    });
  }

  const [showUnused, setShowUnused] = useState(false);

  const reasonData = getIssueReasonData(issue);
  return (
    <ItemContainer
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>Issue Reasons</span>
          <FormControlLabel
            label={`${showUnused ? "Show" : "Hide"} unused`}
            control={
              <Switch
                color="primary"
                checked={showUnused}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowUnused(!showUnused);
                }}
              />
            }
          />
        </div>
      }
      expandable={true}
      onExpandMenu={props.onOpen}
      isMenuExpanded={props.isOpen}
      headerStyles={headerStyles}
      containerStyles={{
        ...styles.baseContainer,
        ...containerStyles,
      }}
      innerBodyStyles={
        isFlipChecklistIcons ? {} : styles.bodyContainer.unflipped
      }
      useFlippedInnerBodyStyles={true}
    >
      {!reasonData || reasonData.length === 0 ? (
        <div>No issue reasons</div>
      ) : (
        renderReasonData(reasonData, props.topicsById, showUnused)
      )}
    </ItemContainer>
  );
}

export default IssueReasons;

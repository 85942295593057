import Quill from "quill";
import applyListTypeChangesOnSameLevel from "./delta/apply_list_type_changes_on_same_level";
import getTextFromClipboard from "utils/text/get_text_from_clipboard";
import moveReferencesToTheTextLine from "utils/text/move_references_to_text_line";
const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

const Parchment = Quill.import("parchment");
const ListStyleTypeClass = new Parchment.Attributor.Class(
  "list-style-type",
  "ql-list-style-type",
  {
    scope: Parchment.Scope.BLOCK,
    whitelist: [
      "lower-latin",
      "upper-latin",
      "decimal",
      "lower-roman",
      "upper-roman",
    ],
  },
);

class PlainClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const range = this.quill.getSelection();
    const pastedText = getTextFromClipboard(e);

    const delta = new Delta()
      .retain(range.index)
      .delete(range.length)
      .insert(moveReferencesToTheTextLine(pastedText));
    this.quill.updateContents(delta);
  }
}

Quill.register("formats/list-style-type", ListStyleTypeClass);
Quill.register({"modules/clipboard": PlainClipboard}, true);

const linkListItemsOnSameLevel = quill => {
  quill.on("text-change", (delta, oldContents, source) => {
    if (source === "user") {
      const contents = quill.getContents();
      const updateOps = applyListTypeChangesOnSameLevel(delta, contents);
      if (updateOps && updateOps.length > 0) {
        quill.updateContents({ops: updateOps});
      }
    }
  });
  return quill;
};

export {Quill as default, linkListItemsOnSameLevel};

import {cloneDeep} from "lodash";
const keyRegex = /LL_key_\S+/g;

function findTemplateInstances(textRaw, templateValues, changes) {
  const keys = Object.keys(templateValues);
  const result = {
    textWithTemplateValues: textRaw,
    templateInstances: [],
    changes: cloneDeep(changes),
  };
  if (keys.length === 0) {
    return result;
  }

  let match;
  while ((match = keyRegex.exec(result.textWithTemplateValues)) !== null) {
    const key = match[0];
    const value = templateValues[key];
    if (value && typeof value !== "boolean") {
      const matchStart = match.index;
      const matchEnd = matchStart + value.length - 1;
      const lenDiff = value.length - key.length;
      // shift changes start/end
      result.changes = result.changes.map(change => {
        if (change.new_start_offset >= matchStart) {
          change.new_start_offset += lenDiff;
          change.new_end_offset += lenDiff;
          change.old_start_offset += lenDiff;
          change.old_end_offset += lenDiff;
        }
        return change;
      });
      result.templateInstances.push({
        start: matchStart,
        end: matchEnd,
        text: value,
        key,
      });
      result.textWithTemplateValues = result.textWithTemplateValues.replace(
        key,
        value,
      );
    }
  }
  return result;
}

export default findTemplateInstances;

import React from "react";
import Quill from "utils/quill";
import _ from "lodash";

import "./style.css";

class ContentsLight extends React.Component {
  componentDidMount() {
    this.addQuill();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.contentsHTML, this.props.contentsHTML)) {
      // first we set contents to empty delta because dangerouslyPasteHTML
      // appends text to the existing contents after contentsHTML updates
      this.quill.setContents([]);
      this.quill.clipboard.dangerouslyPasteHTML(0, this.props.contentsHTML);
    }
  }

  addQuill() {
    if (this.quillRef) {
      this.initQuill();
      if (this.props.contentsHTML) {
        this.quill.clipboard.dangerouslyPasteHTML(0, this.props.contentsHTML);
      }
      const editorEls = document.querySelectorAll(".contents-light .ql-editor");
      for (const editorEl of editorEls) {
        editorEl.style.padding = 0;
      }
    }
  }

  initQuill() {
    this.quill = new Quill(this.quillRef, {
      modules: {toolbar: false},
      theme: "snow",
      readOnly: true,
    });
  }

  render() {
    return (
      <div
        className="contents-light"
        ref={_ref => {
          this.quillRef = _ref;
        }}
        style={{
          fontFamily: "Roboto, sans-serif",
          border: "none",
          fontSize: 16,
          wordBreak: "break-all",
        }}
      />
    );
  }
}

export default ContentsLight;

import isNextDay from "./is_next_day";

const initialiseClientMode = (
  storage,
  isCentralAdmin,
  documentId,
  organisationId,
  switchClientMode,
) => {
  if (isCentralAdmin === true && isNextDay(storage.time)) {
    return switchClientMode(true);
  }
  if (storage.documentId !== documentId) {
    if (isCentralAdmin === false) {
      return switchClientMode(true);
    }
  }
  if (storage.organisationId !== organisationId) {
    return switchClientMode(true);
  }
};

export default initialiseClientMode;

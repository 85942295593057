import _ from "underscore";
import React, {useEffect, useState} from "react";

import readDocx from "common/utils/docx/read_docx";
import DocxComponent from "./components";
import getDocxJson from "./utils/docx_json";

export default function DocumentReview({document}) {
  const [docxJson, setDocxJson] = useState(null);
  const [data, setData] = useState(null);
  useEffect(() => {
    if (!_.isEqual(document.data, data)) {
      setData(document.data);
      readDocx(Buffer.from(document.data)).then(fileContents =>
        setDocxJson(getDocxJson(fileContents)),
      );
    }
  });
  if (!docxJson) {
    return null;
  }
  return <DocxComponent docxJson={docxJson} document={document} />;
}

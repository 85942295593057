import React from "react";

import IssueItemIconMenu from "common_components/checklist/issue_item_icon_menu";
import MenuHeading from "common_components/menu_heading";

import issueHeaderUtils from "utils/issues/issue_header_utils";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import {getIssueManualCorrections} from "utils/manual_corrections_utils";
import globalOverrideKey from "common/utils/issues/global_override_key";

function IssueDetailHeading(props) {
  const {
    issue,
    currentIssuesetItem,
    project,
    showIssuesInChecklist,
    shouldHideIcon,
    shouldShrink,
  } = props;

  const isAlert = isIssueAlert(issue);

  const manualCorrections = getIssueManualCorrections(
    issue,
    currentIssuesetItem,
    globalOverrideKey,
  );
  let iconToRender;
  if (!shouldHideIcon) {
    const issueIconName = issueHeaderUtils.getIconName(
      issue,
      currentIssuesetItem,
      showIssuesInChecklist,
      project,
    );
    const HeaderIcon = issueHeaderUtils.getIcon(issueIconName);
    const headerColor = issueHeaderUtils.getIconColor(
      issue,
      currentIssuesetItem,
      project,
      showIssuesInChecklist,
      false,
    );
    const baseSize = shouldShrink ? 16 : 40;
    const iconSize = baseSize;
    const iconStyles = {
      width: iconSize,
      height: iconSize,
      color: headerColor,
      flexShrink: 0,
    };

    iconToRender = (
      <div style={{display: "flex"}}>
        {showIssuesInChecklist ? (
          <HeaderIcon style={iconStyles} />
        ) : (
          <IssueItemIconMenu
            Icon={HeaderIcon}
            iconStyles={iconStyles}
            iconContainerStyles={{marginLeft: 30}}
            project={project}
            issue={issue}
            updateDocumentIssue={props.updateDocumentIssue}
            correctDocumentIssueManually={props.correctDocumentIssueManually}
            isFlipChecklistIcons={props.isFlipChecklistIcons}
            disabled={issue.issue_class_id === 4}
            user={props.user}
            issueset={currentIssuesetItem}
          />
        )}
      </div>
    );
  }
  return (
    <MenuHeading
      onCloseClick={props.onCloseClick}
      closeButtonOnTheLeft={props.closeButtonOnTheLeft}
      containerStyle={{
        height: "auto",
        minHeight: shouldShrink ? "52px" : "3.6rem",
        padding: "0.5rem 1rem",
        flexShrink: 0,
        ...(manualCorrections && manualCorrections.new_state
          ? {backgroundColor: "#fee"}
          : {}),
        ...(isAlert && issue.styling ? issue.styling.summary : {}),
      }}
      wrapperStyle={
        shouldShrink
          ? {
              alignItems: "center",
              justifyContent: "space-between",
            }
          : {}
      }
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: shouldShrink ? "row" : "column",
          alignItems: "center",
        }}
      >
        {iconToRender}
        {renderIssueName(issue, showIssuesInChecklist, shouldShrink)}
      </div>
    </MenuHeading>
  );
}

export function renderIssueName(issue, showIssuesInChecklist, shouldShrink) {
  if (shouldShrink) {
    return (
      <div
        style={{
          marginLeft: 5,
          fontSize: 16,
          fontWeight: 400,
          color: issueHeaderUtils.getTextColor(issue, showIssuesInChecklist),
        }}
      >
        {issue.name}
      </div>
    );
  }
  const {issueName, issueParent} = splitIssueName(issue.name);
  return (
    <div
      style={{
        marginTop: "0.5em",
        marginBottom: "0.5em",
        marginLeft: "5px",
        fontWeight: 400,
      }}
    >
      {issueParent && (
        <div
          style={{
            color: "#888",
            textAlign: "center",
            fontStyle: "italic",
            fontSize: 12,
            marginBottom: "0.5em",
          }}
        >
          {issueParent}
        </div>
      )}
      <div
        style={{
          color: issueHeaderUtils.getTextColor(issue, showIssuesInChecklist),
          fontSize: 16,
        }}
      >
        {issueName}
      </div>
    </div>
  );
}

function splitIssueName(issueName) {
  const nameParts = issueName.split("/");
  return {
    issueName: nameParts.length > 1 ? nameParts[1] : issueName,
    issueParent: nameParts.length > 1 ? nameParts[0] : null,
  };
}

export default IssueDetailHeading;

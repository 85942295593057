import React from "react";
import Quill from "utils/quill";

import {QuillDeltaToHtmlConverter} from "quill-delta-to-html";

import CloseIcon from "@material-ui/icons/Close";
import DoneIcon from "@material-ui/icons/Done";
import ReplayIcon from "@material-ui/icons/Replay";
import "./style.css";
// https://github.com/quilljs/quill/issues/1680
const styles = {
  actionButton: {
    cursor: "pointer",
    width: 20,
    height: 20,
    marginLeft: 5,
  },
};

class GuiTextEditorLight extends React.Component {
  componentDidMount() {
    this.addQuill();
  }

  addQuill() {
    if (this.quillRef) {
      this.initQuill();
      if (this.props.contentsHTML) {
        this.quill.clipboard.dangerouslyPasteHTML(0, this.props.contentsHTML);
      }
      if (this.props.onChange) {
        this.quill.on("text-change", this.onTextChange);
      }
      if (this.props.saveOnBlur) {
        this.quill.on("selection-change", range => {
          if (!range) {
            this.onDone();
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.onChange &&
      this.props.contentsHTML &&
      this.props.contentsHTML !== prevProps.contentsHTML &&
      this.props.editorId &&
      prevProps.editorId &&
      prevProps.editorId !== this.props.editorId
    ) {
      this.quill.setContents([]);
      this.quill.clipboard.dangerouslyPasteHTML(0, this.props.contentsHTML);
    }
  }

  initQuill() {
    this.quill = new Quill(this.quillRef, {
      modules: {
        toolbar: {container: this.toolbarRef},
      },
      theme: "snow",
      placeholder: this.props.placeholder || "",
    });
  }

  render() {
    return (
      <div style={{width: "100%", height: "100%"}}>
        {this.renderToolbar()}
        <div
          style={{height: "auto"}}
          ref={_ref => {
            this.quillRef = _ref;
          }}
          onPaste={this.onPaste}
        />
      </div>
    );
  }

  renderToolbar = () => (
    <div
      ref={_ref => {
        this.toolbarRef = _ref;
      }}
      style={{
        display: "flex",
        ...(this.props.saveOnBlur
          ? {
              height: 0,
              padding: 0,
              borderBottom: 0,
            }
          : {}),
      }}
    >
      {!this.props.saveOnBlur && (
        <>
          <div>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-indent" value="-1" />
            <button className="ql-indent" value="+1" />
          </div>

          <div style={{marginLeft: "auto"}}>
            {this.props.onRevert &&
              this.props.isValueChanged && (
                <ReplayIcon
                  style={styles.actionButton}
                  onClick={this.onRevert}
                />
              )}
            {this.props.onSave && (
              <DoneIcon style={styles.actionButton} onClick={this.onDone} />
            )}
            {this.props.onClose && (
              <CloseIcon
                style={styles.actionButton}
                onClick={this.props.onClose}
              />
            )}
          </div>
        </>
      )}
    </div>
  );

  getCurrentHtml = () => {
    const delta = this.quill.getContents();
    const converter = new QuillDeltaToHtmlConverter(delta.ops, {
      encodeHtml: false,
    });
    return converter.convert();
  };

  onPaste = () => {
    if (this.props.onChange) {
      this.onTextChange(null, null, "clipboard");
    }
  };

  onDone = () => {
    this.props.onSave(this.getCurrentHtml());
    this.props.onClose();
  };

  onRevert = () => {
    if (this.props.onRevert) {
      this.props.onRevert();
    }
    this.props.onClose();
  };

  onTextChange = (delta, oldContents, source) => {
    if (source !== "api") {
      this.props.onChange(this.getCurrentHtml());
      this.quill.focus();
    }
  };
}

export default GuiTextEditorLight;

import {Document, Paragraph, TextRun, AlignmentType} from "docx";
import moment from "moment";

function constructDocument() {
  const doc = new Document({
    numbering: {
      config: [
        {
          reference: "numbering",
          levels: [
            {
              level: 0,
              format: "decimal",
              text: "%2.",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: {
                    // left: 720,
                    hanging: 260,
                  },
                },
              },
            },
            {
              level: 1,
              format: "lowerLetter",
              text: "%3)",
              alignment: AlignmentType.START,
              style: {
                paragraph: {
                  indent: {
                    left: 420,
                    hanging: 360,
                  },
                },
              },
            },
          ],
        },
      ],
    },
  });
  doc.addSection({
    children: [
      new Paragraph({
        children: [
          new TextRun({
            text: "Transfer Certificate",
            bold: true,
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: {after: 300},
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: "To: A Bank as Agent",
          }),
        ],
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "From: B Bank (the ",
          }),
          new TextRun({
            text: '"Existing Lender"',
            bold: true,
          }),
          new TextRun({
            text: ") and C Bank (the ",
          }),
          new TextRun({
            text: '"New Lender"',
            bold: true,
          }),
          new TextRun({
            text: ")",
          }),
        ],
        spacing: {after: 400},
      }),
      new Paragraph({
        children: [
          new TextRun({
            text:
              'Software Ventures PLC – facilities agreement dated 31st December, 2019 (the "Agreement")',
            bold: true,
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: {after: 300},
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 0,
        },
        children: [
          new TextRun({
            text:
              "We refer to the Agreement. This is a Transfer Certificate. Terms defined in the Agreement have the same meaning in this Transfer Certificate unless given a different meaning in this Transfer Certificate.",
          }),
        ],
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 0,
        },
        children: [
          new TextRun({
            text: "We refer to Clause 25.5 (Procedure for transfer):",
          }),
        ],
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 1,
        },
        children: [
          new TextRun({
            text:
              "The Existing Lender and the New Lender agree to the Existing Lender transferring to the New Lender by novation all or part of the Existing Lender's Commitment, rights and obligations referred to in the Schedule in accordance with Clause 25.5 (Procedure for transfer).",
          }),
        ],
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 1,
        },
        children: [
          new TextRun({
            text: `The proposed Transfer Date is ${moment().format(
              "MMMM Do YYYY",
            )}.`,
          }),
        ],
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 1,
        },
        children: [
          new TextRun({
            text:
              "The Facility Office and address, email address and attention details for notices of the New Lender are set out in the Schedule.",
          }),
        ],
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 0,
        },
        children: [
          new TextRun({
            text:
              "This Transfer Certificate may be executed in any number of counterparts and this has the same effect as if the signatures on the counterparts were on a single copy of this Transfer Certificate.",
          }),
        ],
      }),

      new Paragraph({
        numbering: {
          reference: "numbering",
          level: 0,
        },
        children: [
          new TextRun({
            text:
              "This Transfer Certificate and any non-contractual obligations arising out of or in connection with it are governed by English law.",
          }),
        ],
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "The Schedule",
            bold: true,
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: {after: 100},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "COMMITMENT/RIGHTS AND OBLIGATIONS TO BE TRANSFERRED",
            bold: true,
          }),
        ],
        alignment: AlignmentType.CENTER,
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text:
              "All rights and obligations under the Agreement with effect from the Transfer Date",
          }),
        ],
        spacing: {after: 200},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text:
              "Address of New Lender: 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ, United Kingdom liam@lexicallabs.com Attn: Liam",
          }),
        ],
        spacing: {after: 200},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "B Bank as Existing Lender",
          }),
        ],
        spacing: {after: 100},
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: "By:...............................................",
          }),
        ],
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "C Bank as New Lender",
          }),
        ],
        spacing: {after: 100},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "By:...............................................",
          }),
        ],
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: `This Transfer Certificate is accepted by the Agent and the Transfer Date is confirmed as of ${moment().format(
              "MMMM Do YYYY",
            )}`,
          }),
        ],
        spacing: {after: 300},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "[Agent]",
          }),
        ],
        spacing: {after: 100},
      }),

      new Paragraph({
        children: [
          new TextRun({
            text: "By:...............................................",
          }),
        ],
        spacing: {after: 300},
      }),
    ],
  });
  return doc;
}

export default constructDocument;

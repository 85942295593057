import React from "react";

import Paper from "material-ui/Paper";
import IconButton from "material-ui/IconButton";
import ArrowRightIcon from "material-ui/svg-icons/hardware/keyboard-arrow-right";
import ArrowDownIcon from "material-ui/svg-icons/hardware/keyboard-arrow-down";
import HelpIcon from "material-ui/svg-icons/action/help";
import Tooltip from "common_components/tooltip";

import clampLine from "../../../utils/clamp_line";
import getClauseAtomsByTopic from "../../../utils/applicable_clauses/get_clause_atoms_by_topic";
import renderClauses from "./utils/render_clauses";

const styles = {
  container: item => ({
    margin: "0.5em 0",
    marginRight: item.nestingLevel === 0 && "-3px",
  }),
  singularItemContainer: index => ({
    cursor: "pointer",
    backgroundColor: index % 2 === 0 ? "inherit" : "#eff8ff",
  }),
  heading: (groupOpened, item) => ({
    padding: "1em",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    backgroundColor:
      groupOpened && (item.nestingLevel % 2 === 0 ? "inherit" : "#f8f8f8"),
  }),
  singularHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.5em 1em",
  },
  topLevelName: (groupOpened, item) => ({
    fontWeight: groupOpened && "bold",
    fontSize: item.nestingLevel > 0 ? "11px" : "13px",
  }),
  singularName: {
    fontSize: "11px",
  },
  singularRightContainer: {
    display: "flex",
    alignItems: "center",
    flexShrink: 0,
  },
  singularHelpContainer: item => ({
    position: "relative",
    visibility: item.help ? "visible" : "hidden",
  }),
  itemCount: {
    marginLeft: "4px",
    fontSize: "10px",
    color: "#9E9E9E",
  },
  iconContainer: {
    height: "13px",
    width: "13px",
    padding: 0,
    zIndex: 0,
  },
  iconStyles: {
    height: "13px",
    width: "13px",
    position: "absolute",
    bottom: "-2px",
    left: "-1px",
  },
  childrenContainer: item => ({
    marginLeft: item.nestingLevel === 0 ? "1em" : 0,
    paddingBottom: item.nestingLevel === 0 ? "0.5em" : 0,
  }),
  topicUses: {
    paddingLeft: "6px",
    textDecoration: "underline",
    color: "#9E9E9E",
    fontSize: "10px",
  },
  clausesContainer: {
    backgroundColor: "white",
    padding: "1px",
  },
};
styles.iconContainerIcon = {
  ...styles.iconStyles,
  left: "3px",
  bottom: "-3px",
};

class TopicsItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groupOpened: props.item.children && props.item.children.length === 1,
      isUsesShown: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.item.name !== prevProps.item.name) {
      this.setState(() => ({groupOpened: false, isUsesShown: false}));
    }
    this.clamp();
  }

  componentDidMount() {
    this.clamp();
  }

  clamp() {
    const elements = document.querySelectorAll(".clamped-clause");
    clampLine(elements);
  }

  render() {
    const {item} = this.props;
    return item.children
      ? this.renderGroupItem(item)
      : this.renderSingularItem(item);
  }

  renderGroupItem = item => {
    const {groupOpened} = this.state;
    return (
      <Paper style={styles.container(item)}>
        <div
          onClick={this.onGroupClick}
          style={styles.heading(groupOpened, item)}
        >
          <span>
            <span style={styles.topLevelName(groupOpened, item)}>
              {clampName(item.name, 38)}
            </span>
            {item.nestingLevel === 0 &&
              !groupOpened && (
                <span style={styles.itemCount}>({item.count})</span>
              )}
          </span>
          <IconButton
            style={styles.iconContainer}
            iconStyle={styles.iconStyles}
          >
            {groupOpened ? <ArrowDownIcon /> : <ArrowRightIcon />}
          </IconButton>
        </div>
        <div style={styles.childrenContainer(item)}>
          {groupOpened &&
            item.children.map((child, index) => (
              <TopicsItem
                key={index}
                index={index}
                item={child}
                documentClauses={this.props.documentClauses}
                scrollToClause={this.props.scrollToClause}
              />
            ))}
        </div>
      </Paper>
    );
  };

  renderSingularItem = item => {
    const clauseAtoms = getClauseAtomsByTopic(this.props.documentClauses, item);
    return (
      <div style={styles.singularItemContainer(this.props.index)}>
        <div style={styles.singularHeading} onClick={this.onUsesTrigger}>
          <span style={styles.singularName}>{item.name}</span>
          <span style={styles.singularRightContainer}>
            <span style={styles.singularHelpContainer(item)}>
              <Tooltip
                tooltipContent={item.help}
                delay={0}
                tooltipContainerStyle={{
                  top: "-10px",
                  right: "14px",
                  width: "120px",
                }}
              >
                <IconButton
                  style={styles.iconContainer}
                  iconStyle={styles.iconContainerIcon}
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </span>
            <span style={styles.topicUses}>
              {`(Uses: ${clauseAtoms.length})`}
            </span>
          </span>
        </div>
        {this.state.isUsesShown && (
          <div style={styles.clausesContainer}>
            {renderClauses(clauseAtoms, this.props.scrollToClause)}
          </div>
        )}
      </div>
    );
  };

  onGroupClick = () => {
    this.setState(prevState => ({groupOpened: !prevState.groupOpened}));
  };

  onUsesTrigger = () => {
    this.setState(prevState => ({isUsesShown: !prevState.isUsesShown}));
  };
}

function clampName(name, acceptedLength) {
  return name.length > acceptedLength
    ? `${name.substr(0, acceptedLength)}...`
    : name;
}

export default TopicsItem;

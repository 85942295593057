import React, {useState, useEffect, useRef} from "react";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import PlusIcon from "@material-ui/icons/AddCircle";
import Tooltip from "@material-ui/core/Tooltip";

import LoadingCircular from "common_components/loading_circular";

import {Conversation} from "common/types/conversation";

export default function render({
  document: {conversations},
  fetchDocumentConversations,
  fetchDocumentConversationItems,
  generateDocumentLlmPrompt,
}) {
  const chats = conversations?.document_chat ?? {};
  const [
    selectedConversation,
    setSelectedConversation,
  ] = useState<Conversation>(null);
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const selectedConversationRef = useRef(null);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const getConversationItems = conversationId => {
    return conversations?.document_chat?.[conversationId]?.items;
  };

  useEffect(() => {
    fetchDocumentConversations();
  }, []);
  useEffect(() => {
    if (selectedConversation?.id && !selectedConversation.isNew) {
      fetchDocumentConversationItems(
        selectedConversation.id,
        "document_chat",
        selectedConversation.first_item_id,
      );
    }
  }, [selectedConversation?.id]);

  useEffect(() => {
    const conversation =
      conversations?.document_chat?.[selectedConversation?.id];
    const recentMessage = conversation?.items?.at(-1);
    let interval;
    if (recentMessage?.role === "user") {
      setIsLoading(true);
      interval = setInterval(() => {
        fetchDocumentConversationItems(
          conversation.id,
          "document_chat",
          conversation.first_item_id,
        );
      }, 5000);
    } else {
      setIsLoading(false);
      clearInterval(interval);
      setTimeout(() => {
        textFieldRef?.current?.focus();
      }, 10);
    }
    return () => {
      setIsLoading(false);
      clearInterval(interval);
    };
  }, [
    selectedConversation &&
      getConversationItems(selectedConversation.id)?.at(-1)?.id,
  ]);
  useEffect(() => {
    if (selectedConversationRef?.current) {
      const scrollHeight = selectedConversationRef.current.scrollHeight;
      const height = selectedConversationRef.current.clientHeight;
      const maxScrollTop = scrollHeight - height;
      selectedConversationRef.current.scrollTop =
        maxScrollTop > 0 ? maxScrollTop : 0;
    }
  }, [selectedConversation?.id, selectedConversation?.items?.length]);

  const generatePrompt = async () => {
    setIsLoading(true);
    const newConversation = await generateDocumentLlmPrompt(
      prompt,
      "document_chat",
      selectedConversation?.id,
      getConversationItems(selectedConversation?.id)?.at(-1)?.id,
    );
    setPrompt("");
    setSelectedConversation({
      isNew: true,
      ...newConversation?.action?.payload?.conversation,
    });
  };

  return (
    <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
      {renderChatHistory(chats, selectedConversation, setSelectedConversation)}
      {renderCurrentChat(
        selectedConversationRef,
        chats[selectedConversation?.id],
        generatePrompt,
        prompt,
        setPrompt,
        isLoading,
        textFieldRef,
      )}
    </div>
  );
}

function renderChatHistory(
  chats,
  selectedConversation,
  setSelectedConversation,
) {
  return (
    <div
      style={{
        maxHeight: "33%",
        overflowY: "auto",
      }}
    >
      {renderNewChatButton(setSelectedConversation)}
      {renderChatHistoryItems(
        chats,
        selectedConversation,
        setSelectedConversation,
      )}
    </div>
  );
}
function renderNewChatButton(setSelectedConversation) {
  return (
    <div
      style={{
        display: "flex",
        columnGap: "0.5rem",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: "1rem",
      }}
    >
      <div style={{fontWeight: "bold"}}>Query History</div>
      <div
        title="Make a new query"
        style={{
          padding: "0.25rem",
          borderRadius: "0.5rem",
          backgroundColor: "rgb(253, 253, 253)",
          border: "0.2rem solid rgb(253, 253, 253)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={() => setSelectedConversation(null)}
      >
        <PlusIcon style={{color: "#747474"}} />
      </div>
    </div>
  );
}
function renderChatHistoryItems(
  chats,
  selectedConversation,
  setSelectedConversation,
) {
  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        rowGap: "1rem",
        padding: "1rem",
        backgroundColor: "rgb(230, 230, 230)",
      }}
    >
      {Object.keys(chats)
        .sort(
          (a, b) =>
            (!chats[b].creation_date ? 1 : 0) -
              (!chats[a].creation_date ? 1 : 0) ||
            new Date(chats[b].creation_date).valueOf() -
              new Date(chats[a].creation_date).valueOf(),
        )
        .map(conversationId =>
          renderConversationSummary(
            chats[conversationId],
            conversationId,
            selectedConversation,
            setSelectedConversation,
          ),
        )}
    </div>
  );
}

function renderConversationSummary(
  conversation,
  index,
  selectedConversation,
  setSelectedConversation,
) {
  return (
    <div
      key={index}
      onClick={() => setSelectedConversation(conversation)}
      style={{
        cursor: "pointer",
        padding: "0.6rem",
        borderRadius: "0.5rem",
        fontFamily: "Roboto",
        backgroundColor: "#fdfdfd",
        border: `0.3rem solid ${
          selectedConversation?.id === conversation.id
            ? "rgb(188, 217, 240)"
            : "transparent"
        }`,
      }}
    >
      <div
        style={{
          fontWeight: "bold",
          fontSize: "0.85rem",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          paddingBottom: "0.2rem",
        }}
      >
        {conversation?.text ?? conversation?.items?.[0]?.text}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          color: "rgb(83, 83, 83)",
          fontSize: "0.7rem",
        }}
      >
        {conversation?.creation_date
          ? new Date(conversation?.creation_date).toLocaleString()
          : "Just now"}
      </div>
    </div>
  );
}

function renderCurrentChat(
  selectedConversationRef,
  conversation,
  generateDocumentLlmPrompt,
  prompt,
  setPrompt,
  isLoading,
  textFieldRef,
) {
  return (
    <div
      style={{
        flexGrow: 2,
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        overflowY: "auto",
      }}
      ref={selectedConversationRef}
    >
      <div
        style={{
          flexGrow: 1,
        }}
      >
        {(conversation?.items ?? []).map(item => renderConversationItem(item))}
      </div>
      {isLoading && <LoadingCircular />}
      {renderNewPrompt(
        prompt,
        setPrompt,
        generateDocumentLlmPrompt,
        isLoading,
        textFieldRef,
      )}
    </div>
  );
}

function renderConversationItem(message) {
  return <div key={message.id}>{renderMessage(message)}</div>;
}

function renderMessage(message) {
  return (
    <Tooltip
      title={
        <ReactMarkdown
          children={message?.metadata?.detailed_response}
          remarkPlugins={[remarkGfm]}
        />
      }
    >
      <div
        style={
          message.role === "assistant"
            ? {
                paddingLeft: "4px",
                marginLeft: "2px",
                borderLeft: "2px solid #42a5f5",
              }
            : {}
        }
      >
        <ReactMarkdown children={message.text} remarkPlugins={[remarkGfm]} />
      </div>
    </Tooltip>
  );
}

function renderNewPrompt(
  prompt,
  setPrompt,
  generateDocumentLlmPrompt,
  isLoading,
  textFieldRef,
) {
  return (
    <div
      style={{flexGrow: 0, flexShrink: 0, display: "flex", padding: "0.5em"}}
    >
      <TextField
        inputRef={input => (textFieldRef.current = input)}
        disabled={isLoading}
        value={prompt}
        multiline
        variant="outlined"
        autoFocus
        onChange={event => setPrompt(event.target.value)}
        onKeyDown={event => {
          if (event.key === "Enter") {
            event.preventDefault();
            generateDocumentLlmPrompt();
          }
        }}
        style={{flexGrow: 1}}
      />
      <Button
        variant="outlined"
        style={{marginLeft: "1em"}}
        disabled={isLoading}
        onClick={() => {
          generateDocumentLlmPrompt();
        }}
      >
        Send
      </Button>
    </div>
  );
}

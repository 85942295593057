import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";

import {
  updateStateWithRequest,
  updateStateWithSuccess,
} from "utils/ensure_fresh_update";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";
import byId from "common/utils/by_id";
import addDefinitionsAndHeadings from "common/utils/add_definitions_and_headings";

/* eslint-disable complexity */
/* eslint-disable max-statements */

function getConfirmedTopics(usage) {
  return usage.other_topics.filter(topic => topic.is_confirmed);
}

function getUnconfirmedTopics(usage) {
  return usage.other_topics.filter(topic => !topic.is_confirmed);
}

function getUsageWithTopicparameterUpdates(state, usage, payload) {
  if (
    usage.clause_id === payload.clause_id &&
    usage.clausepart_id === payload.clausepart_id
  ) {
    const {updates} = payload;
    if (state.id === payload.topic_id) {
      return {
        ...usage,
        clausepart_last_edited: payload.clausepart_last_edited,
        parameter_values: getNewTopicParameters(
          usage.parameter_values,
          updates,
        ),
      };
    }
    const newOtherTopics = usage.other_topics.map(otherTopic => {
      if (otherTopic.topic_id === payload.topic_id) {
        return {
          ...otherTopic,
          topicparameters: getNewTopicParameters(
            otherTopic.topicparameters,
            updates,
          ),
        };
      }
      return otherTopic;
    });
    return {
      ...usage,
      other_topics: newOtherTopics,
      clausepart_last_edited: payload.clausepart_last_edited,
    };
  }
  return usage;
}

function updateParameter(
  state,
  topicId,
  parameterId,
  topicLastEdited,
  updateFn,
  payload,
) {
  if (state.id === topicId) {
    let parameter_logs = state.parameter_logs;
    if (payload && payload.log_id) {
      parameter_logs = {
        ...state.parameter_logs,
        [payload.id]: [
          {
            id: payload.log_id,
            time: payload.last_edited,
            parameter_type: payload.parameter_type,
            username: payload.username,
            value: payload.value.values ? payload.value.values : payload.value,
          },
          ...(state.parameter_logs && state.parameter_logs[payload.id]
            ? state.parameter_logs[payload.id]
            : []),
        ],
      };
    }

    return {
      ...state,
      parameters: state.parameters.map(param => {
        if (param.id === parameterId) {
          return updateFn(param);
        }
        return param;
      }),
      last_edited: topicLastEdited,
      parameter_logs,
    };
  }
  return state;
}

function onClauseTopicConfirm(state, payload) {
  if (state.usage) {
    let changed = false;
    const updatedState = {
      ...state,
      usage: state.usage.map(usage => {
        if (usage.clausepart_id === payload.clausepart_id) {
          if (usage.clausepart_last_edited === payload.clausepart_last_edited) {
            return usage;
          }
          changed = true;
          return {
            ...usage,
            is_confirmed: true,
            clausepart_last_edited: payload.clausepart_last_edited,
            other_topics: _.uniq(
              [
                ..._.map(usage.other_topics, topic => {
                  if (topic.topic_id === payload.topic_id) {
                    return {...topic, is_confirmed: true};
                  }
                  return topic;
                }),
                {
                  topic_id: payload.topic_id,
                  is_confirmed: true,
                  topicparameters: [],
                },
              ],
              topic => topic.topic_id,
            ),
          };
        }
        return usage;
      }),
      fp: (state.fp || []).filter(
        result => result.clausepart_id !== payload.clausepart_id,
      ),
    };
    return changed ? updatedState : state;
  }
  return state;
}

function processDocumentClauseTopicAdd(state, clausepart) {
  function getFreshUsageAdd(usage, clausepart) {
    return {
      ...usage,
      // seems to be a webpack bug that I can't refer to payload here.
      clausepart_last_edited: clausepart.clausepart_last_edited,
      other_topics: _.uniq(
        [
          ...(usage.other_topics || []),
          {
            topic_id: clausepart.id,
            is_confirmed: true,
            topicparameters: [],
          },
        ],
        topic => topic.topic_id,
      ),
    };
  }

  if (clausepart.id !== state.id) {
    let foundInUsage = false;
    const newUsage = state.usage.map(usage => {
      // seems to be a webpack bug that I can't refer to payload here.
      if (clausepart.clausepart_id === usage.clausepart_id) {
        foundInUsage = true;
        return getFreshUsageAdd(usage, clausepart);
      }
      return usage;
    });

    let newFp = state.fp;
    if (!foundInUsage && state.fp) {
      newFp = (state.fp || []).map(fp => {
        if (clausepart.clausepart_id === fp.clausepart_id) {
          foundInUsage = true;
          return getFreshUsageAdd(fp, clausepart);
        }
        return fp;
      });
    }

    return {
      ...state,
      usage: newUsage,
      fp: newFp,
    };
  }
  const alreadyExists = state.usage.find(
    usage => clausepart.clausepart_id === usage.clausepart_id,
  );
  if (!alreadyExists) {
    const clauseTopic = clausepart.topics.find(
      topic => topic.topic_id === state.id,
    );
    return {
      ...state,
      usage: [
        ...state.usage,
        {
          ..._.omit(clausepart, "id", "topics"),
          other_topics: [
            ...clausepart.topics.filter(topic => topic.topic_id !== state.id),
          ],
          clausepart_partial_text: clausepart.partial_text,
          clausepart_text: clausepart.text,
          parameter_values: [],
          has_topic: true,
          ..._.omit(clauseTopic, "topic_id"),
          definitions: [],
          definitionAndHeadingsText: addDefinitionsAndHeadings(
            clausepart.text,
            clausepart.partial_text,
            [],
            [],
            null,
            null,
          ),
          contract_type_id: clausepart.contract_type_id
            ? clausepart.contract_type_id
            : clausepart.contract_types &&
              clausepart.contract_types[0].contract_type_id,
        },
      ],
      fp: (state.fp || []).filter(
        result => result.clausepart_id !== clausepart.clausepart_id,
      ),
    };
  }
  return state;
}

function processDocumentClauseTopicRemove(
  state,
  clausepart,
  shouldUpdateLastUpdate,
) {
  function getFreshUsageRemove(usage, clausepart) {
    return {
      ...usage,
      clausepart_last_edited: clausepart.clausepart_last_edited,
      other_topics: usage.other_topics.filter(
        topic => topic.topic_id !== clausepart.topic_id,
      ),
    };
  }

  if (clausepart.clausepart_last_edited > state.last_update) {
    const lastUpdate = shouldUpdateLastUpdate
      ? clausepart.clausepart_last_edited
      : state.last_update;
    if (clausepart.topic_id === state.id) {
      const id = clausepart.clausepart_id;
      return {
        ...state,
        last_update: lastUpdate,
        usage: state.usage.filter(use => use.clausepart_id !== id),
        fp: state.fp
          ? state.fp.filter(fp => fp.clausepart_id !== id)
          : state.fp,
      };
    }

    let foundInUsage = false;
    const newUsage = state.usage.map(usage => {
      if (usage.clausepart_id === clausepart.clausepart_id) {
        foundInUsage = true;
        return getFreshUsageRemove(usage, clausepart);
      }
      return usage;
    });

    let newFp = state.fp;
    if (!foundInUsage && state.fp) {
      newFp = state.fp.map(fp => {
        if (fp.clausepart_id === clausepart.clausepart_id) {
          return getFreshUsageRemove(fp, clausepart);
        }
        return fp;
      });
    }

    return {
      ...state,
      last_update: lastUpdate,
      usage: newUsage,
      fp: newFp,
    };
  }
  return state;
}

function getUpdatedUsageList(usages, payload) {
  return usages.map(stateUsage => {
    let newClassifierScores;
    if (payload.shouldUseSpecifiedClauseparts && payload.clauseparts) {
      // specified clauseparts run
      const payloadClausepartClassifierScores = payload.clauseparts.filter(
        payloadItem =>
          payloadItem.project_id === stateUsage.project_id &&
          payloadItem.document_id === stateUsage.document_id &&
          payloadItem.clausepart_id === stateUsage.clausepart_id,
      );
      newClassifierScores = stateUsage.classifier_scores.map(
        stateClassifierScore => {
          const payloadClausepartClassifierScoreIndex = payloadClausepartClassifierScores.findIndex(
            payloadScore =>
              payloadScore.configuration_id ===
              stateClassifierScore.configuration_id,
          );
          if (payloadClausepartClassifierScoreIndex !== -1) {
            payloadClausepartClassifierScores.splice(
              payloadClausepartClassifierScoreIndex,
              1,
            );
            return {
              ...stateClassifierScore,
              state: payload.state,
              training_mode_id: payload.training_mode_id,
            };
          }
          return stateClassifierScore;
        },
      );
      if (
        payloadClausepartClassifierScores &&
        payloadClausepartClassifierScores.length > 0
      ) {
        const newItem = {
          classifier_result: {score: 0},
          classifier_settings: {},
          configuration_id: payload.configuration_id,
          is_classified: false,
          score: 0,
          state: payload.state,
          training_mode_id: payload.training_mode_id,
        };
        newClassifierScores = [...newClassifierScores, newItem];
      }
    } else {
      // all clauseparts run
      newClassifierScores = (stateUsage.classifier_scores || []).map(
        stateClassifierScore => {
          const shouldUsageBeRefreshed = Boolean(
            (payload.configuration_ids || []).find(
              payloadConfigurationId =>
                payloadConfigurationId ===
                stateClassifierScore.configuration_id,
            ),
          );
          return shouldUsageBeRefreshed
            ? {
                ...stateClassifierScore,
                state: payload.state,
                training_mode_id: payload.training_mode_id,
              }
            : stateClassifierScore;
        },
      );
    }
    return {
      ...stateUsage,
      classifier_scores: newClassifierScores,
    };
  });
}

function getTextWithDefinitionsAndHeadings(use) {
  return addDefinitionsAndHeadings(
    use.clausepart_text,
    use.clausepart_partial_text,
    use.definitions,
    use.headings,
    use.section_id,
    use.clausepart_reference,
  );
}

function getTpClassificationMapCallSameTopic(action) {
  return use => {
    if (use.clausepart_id !== action.payload.id) {
      return use;
    }
    let found = false;
    const newUse = {
      ...use,
      clausepart_last_edited: action.payload.clausepart_last_edited,
      parameter_values: use.parameter_values.map(values => {
        if (action.payload.topicparameter_values[values.topicparameter_id]) {
          found = true;
          return {
            ...values,
            values:
              action.payload.topicparameter_values[values.topicparameter_id],
          };
        }
        return values;
      }),
    };
    const topicparameterIds = Object.keys(action.payload.topicparameter_values);
    if (!found && topicparameterIds.length === 1) {
      const topicparameterId = parseInt(topicparameterIds[0], 10);
      newUse.parameter_values.push({
        topicparameter_id: topicparameterId,
        values: action.payload.topicparameter_values[topicparameterId],
      });
    }
    return newUse;
  };
}

function getTpClassificationMapCallDifferentTopic(action) {
  return use => {
    if (use.clausepart_id !== action.payload.id) {
      return use;
    }
    let changed = false;
    const otherTopics = use.other_topics.map(topic => {
      if (topic.topic_id === action.payload.topic_id) {
        changed = true;
        return {
          ...topic,
          topicparameters: _.uniq(
            [
              ...topic.topicparameters.map(tp => {
                const values =
                  action.payload.topicparameter_values[tp.topicparameter_id];
                if (values) {
                  return {
                    ...tp,
                    values,
                  };
                }
                return tp;
              }),
              ..._.map(action.payload.topicparameter_values, (values, id) => ({
                topicparameter_id: parseInt(id, 10),
                values,
              })),
            ],
            tp => tp.topicparameter_id,
          ),
        };
      }
      return topic;
    });
    return {
      ...use,
      other_topics: otherTopics,
      clausepart_last_edited: changed
        ? action.payload.clausepart_last_edited
        : use.clausepart_last_edited,
    };
  };
}

export default function topicReducer(state = UNINITIALISED, action) {
  if (
    state === UNINITIALISED &&
    (action.type !== ActionTypes.TOPIC_FETCH.SUCCESS &&
      action.type !== ActionTypes.TOPIC_INFO_FETCH.SUCCESS)
  ) {
    return state;
  }
  if (action.type === ActionTypes.TOPIC_STATS_FETCH.SUCCESS) {
    return {...state, ...action.payload};
  }
  switch (action.type) {
    case ActionTypes.TOPIC_INFO_FETCH.REQUEST:
    case ActionTypes.TOPIC_FETCH.REQUEST: {
      return {
        ...state,
        last_fp_progress: null,
      };
    }
    case ActionTypes.TOPIC_INFO_FETCH.SUCCESS:
    case ActionTypes.TOPIC_FETCH.SUCCESS:
      return {
        ...(action.payload.omit_param_stats
          ? {
              ..._.omit(action.payload, ["parameters"]),
              ..._.pick(state, ["parameters"]),
            }
          : action.payload),
        last_update: (() => {
          if (_.isArray(action.payload.usage)) {
            const clausepartLastEdited = action.payload.usage
              .map(usage => usage.clausepart_last_edited)
              .sort()
              .pop();
            if (clausepartLastEdited) {
              return clausepartLastEdited;
            }
          }
          return new Date().toISOString();
        })(),
        usage: (action.payload.usage || []).map(use => ({
          ...use,
          definitionAndHeadingsText: getTextWithDefinitionsAndHeadings(use),
        })),
        fp: (action.payload.fp || []).map(use => ({
          ...use,
          definitionAndHeadingsText: getTextWithDefinitionsAndHeadings(use),
        })),
      };
    case ActionTypes.TOPIC_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.TOPIC_UPDATE.REQUEST: {
      return {
        usage: [],
        ...state,
        ...updateStateWithRequest(
          state,
          _.omit(action.payload, ["new_tags", "removed_tags"]),
          ["id", "last_edited"],
        ),
      };
    }
    case ActionTypes.TOPIC_UPDATE.SUCCESS: {
      if (action.payload.id === state.id) {
        return updateStateWithSuccess(
          state,
          _.omit(action.payload, ["new_tags", "removed_tags"]),
        );
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_CONFIRM.SUCCESS: {
      return onClauseTopicConfirm(state, action.payload);
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPICS_REORDER.SUCCESS: {
      if (state.usage) {
        return {
          ...state,
          usage: state.usage.map(usage => {
            const topicsById = byId(action.payload.topics, "topic_id");
            if (action.payload.clausepart_id === usage.clausepart_id) {
              return {
                ...usage,
                clausepart_last_edited: action.payload.clausepart_last_edited,
                other_topics: usage.other_topics.map(topic => ({
                  ...topic,
                  topic_order: topicsById[topic.topic_id]
                    ? topicsById[topic.topic_id].topic_order
                    : topic.topic_order,
                })),
              };
            }
            return usage;
          }),
        };
      }
      return state;
    }
    case ActionTypes.CLAUSES_TOPIC_ADD.SUCCESS: {
      if (
        Boolean(state.usage) &&
        action.payload &&
        action.payload.clauseparts
      ) {
        let newState = {...state};
        action.payload.clauseparts.forEach(clausepart => {
          newState = processDocumentClauseTopicAdd(newState, clausepart);
        });
        return newState;
      }
      return state;
    }
    case ActionTypes.CLAUSES_TOPIC_REMOVE.SUCCESS: {
      if (action.payload && action.payload.clauseparts) {
        const {clauseparts} = action.payload;
        let newState = {...state};
        const lastClausepartsIndex = clauseparts.length - 1;
        clauseparts.forEach((clausepart, index) => {
          newState = processDocumentClauseTopicRemove(
            newState,
            clausepart,
            index === lastClausepartsIndex,
          );
        });
        return newState;
      }
      return state;
    }
    case ActionTypes.CLAUSES_TOPIC_CONFIRM.SUCCESS: {
      if (action.payload && action.payload.clauseparts) {
        const {clauseparts} = action.payload;
        let newState = {...state};
        clauseparts.forEach(clausepart => {
          newState = onClauseTopicConfirm(newState, {...clausepart});
        });
        return newState;
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD.SUCCESS: {
      if ((Boolean(state.usage) || Boolean(state.fp)) && action.payload) {
        const clausepart = _.omit(action.payload, ["user_id", "username"]);
        return processDocumentClauseTopicAdd(state, clausepart);
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_REMOVE.SUCCESS: {
      const clausepart = _.omit(action.payload, ["user_id", "username"]);
      if (clausepart) {
        return processDocumentClauseTopicRemove(state, clausepart, true);
      }
      return state;
    }
    case ActionTypes.DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE.SUCCESS: {
      const {clauseparts = []} = action.payload;
      if (clauseparts.length > 0) {
        let newState = state;
        clauseparts.forEach(clausepartItem => {
          const baseData = _.omit(clausepartItem, [
            "added",
            "removed",
            "clausepart",
          ]);
          const {added, removed, clausepart} = clausepartItem;

          added.forEach(addedTopic => {
            const payload = {
              ...clausepart,
              ...addedTopic,
              ...baseData,
            };
            newState = processDocumentClauseTopicAdd(newState, payload);
          });

          removed.forEach(removedTopic => {
            const payload = {
              ...clausepart,
              ...removedTopic,
              ...baseData,
            };
            newState = processDocumentClauseTopicRemove(
              newState,
              payload,
              true,
            );
          });
        });
        return newState;
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_UNCONFIRMED_TOPICS_REMOVE.SUCCESS: {
      return {
        ...state,
        usage: state.usage
          .filter(
            usage =>
              !(
                usage.clausepart_id === action.payload.clausepart_id &&
                usage.is_confirmed === false
              ),
          )
          .map(usage => {
            if (
              usage.clausepart_id !== action.payload.clausepart_id ||
              getUnconfirmedTopics(usage).length === 0
            ) {
              return usage;
            }
            return {
              ...usage,
              clausepart_last_edited: action.payload.clausepart_last_edited,
              other_topics: getConfirmedTopics(usage),
            };
          }),
      };
    }
    case ActionTypes.TOPICPARAMETER_BULK_CLASSIFICATION.SUCCESS: {
      const {payload} = action;
      if (payload && payload.topic_id && payload.topic_id === state.id) {
        return {
          ...state,
          last_topicparameter_bulk_classification:
            payload.last_topicparameter_bulk_classification,
          bulkClassifiedTopicparameters: payload.topicparameters,
        };
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_PARAMETER_CLASSIFICATION.SUCCESS: {
      const callback =
        action.payload.topic_id === state.id
          ? getTpClassificationMapCallSameTopic(action)
          : getTpClassificationMapCallDifferentTopic(action);

      return {
        ...state,
        usage: state.usage.map(callback),
        fp: state.fp ? state.fp.map(callback) : state.fp,
      };
    }
    case ActionTypes.TOPICPARAMETER_ADD.SUCCESS: {
      const {payload} = action;
      if (state.id === payload.topic_id) {
        return {
          ...state,
          parameters: state.parameters
            .filter(param => param.id !== payload.id)
            .concat([_.omit(payload, "topic_id", "topic_last_edited")]),
          last_edited: payload.topic_last_edited,
          parameter_logs: payload.log_id
            ? {
                ...state.parameter_logs,
                [payload.id]: [
                  {
                    id: payload.log_id,
                    time: payload.last_edited,
                    parameter_type: payload.parameter_type,
                    username: payload.username,
                    value: payload.value.values
                      ? payload.value.values
                      : payload.value,
                  },
                ],
              }
            : state.parameter_logs,
        };
      }
      return state;
    }

    case ActionTypes.TOPICPARAMETER_UPDATE.REQUEST: {
      return updateParameter(
        state,
        action.payload.topic_id,
        action.payload.id,
        state.last_edited,
        parameter => {
          const newParam = {
            ...updateStateWithRequest(
              parameter,
              _.omit(action.payload, ["topic_id"]),
              ["id", "last_edited"],
            ),
          };
          return newParam;
        },
      );
    }

    case ActionTypes.TOPICPARAMETER_UPDATE.SUCCESS: {
      return updateParameter(
        state,
        action.payload.topic_id,
        action.payload.id,
        action.payload.topic_last_edited,
        parameter => {
          return updateStateWithSuccess(
            parameter,
            _.omit(action.payload, ["topic_id", "topic_last_edited"]),
          );
        },
        action.payload,
      );
    }

    case ActionTypes.TOPICPARAMETERS_PROCESS.SUCCESS: {
      if (action.payload.topic_id === state.id) {
        const topicparametersById = byId(action.payload.topicparameters);
        return {
          ...state,
          parameters: state.parameters.map(param => ({
            ...param,
            ...topicparametersById[param.id],
          })),
        };
      }
      return state;
    }

    case ActionTypes.TOPICPARAMETER_DELETE.SUCCESS: {
      if (state.id === action.payload.topic_id) {
        return {
          ...state,
          parameters: state.parameters.filter(
            param => param.id !== action.payload.id,
          ),
          last_edited: action.payload.topic_last_edited,
          usage: state.usage.map(use => ({
            ...use,
            parameter_values: use.parameter_values.filter(
              value => value.topicparameter_id !== action.payload.id,
            ),
          })),
        };
      }

      return {
        ...state,
        usage: state.usage.map(use => ({
          ...use,
          other_topics: use.other_topics.map(topic => {
            if (topic.topic_id !== action.payload.topic_id) {
              return topic;
            }
            return {
              ...topic,
              topicparameters: topic.topicparameters.filter(
                tp => tp.topicparameter_id !== action.payload.topicparameter_id,
              ),
            };
          }),
        })),
      };
    }
    case ActionTypes.CLASSIFIER_RUN_STATE_UPDATE.SUCCESS: {
      if (state.id === action.payload.topic_id) {
        let newState = {...state};
        action.payload.scores.forEach(item => {
          let exists = false;
          newState = {
            ...newState,
            usage: newState.usage.map(use => {
              if (use.clausepart_id === item.clausepart_id) {
                exists = true;
                const existingScore = (use.classifier_scores || []).find(
                  score =>
                    score.configuration_id === action.payload.configuration_id,
                );
                const classifierScores = existingScore
                  ? use.classifier_scores.map(score => {
                      if (
                        score.configuration_id ===
                        action.payload.configuration_id
                      ) {
                        return {
                          ...score,
                          state: action.payload.state,
                          training_mode_id: action.payload.training_mode_id,
                          classifier_settings:
                            action.payload.classifierSettings,
                          score: item.score,
                          is_classified: item.is_classified,
                          classifier_result: item.classifierResult,
                        };
                      }
                      return score;
                    })
                  : [].concat(use.classifier_scores, [
                      {
                        configuration_id: action.payload.configuration_id,
                        state: action.payload.state,
                        training_mode_id: action.payload.training_mode_id,
                        classifier_settings: action.payload.classifierSettings,
                        score: item.score,
                        is_classified: item.is_classified,
                        classifier_result: item.classifierResult,
                      },
                    ]);
                const newUse = {
                  ...use,
                  classifier_scores: classifierScores,
                };
                return newUse;
              }
              return use;
            }),
            fp: exists
              ? newState.fp
              : (newState.fp || []).map(fp => {
                  if (fp.clausepart_id === item.clausepart_id) {
                    exists = true;
                    const existingScore = fp.classifier_scores.find(
                      score =>
                        score.configuration_id ===
                        action.payload.configuration_id,
                    );
                    const classifierScores = existingScore
                      ? fp.classifier_scores.map(score => {
                          if (
                            score.configuration_id ===
                            action.payload.configuration_id
                          ) {
                            return {
                              ...score,
                              state: action.payload.state,
                              training_mode_id: action.payload.training_mode_id,
                              classifier_settings:
                                action.payload.classifierSettings,
                              score: item.score,
                              is_classified: item.is_classified,
                              classifier_result: item.classifierResult,
                            };
                          }
                          return score;
                        })
                      : [].concat(fp.classifier_scores, [
                          {
                            configuration_id: action.payload.configuration_id,
                            state: action.payload.state,
                            training_mode_id: action.payload.training_mode_id,
                            classifier_settings:
                              action.payload.classifierSettings,
                            score: item.score,
                            is_classified: item.is_classified,
                            classifier_result: item.classifierResult,
                          },
                        ]);
                    const newFp = {
                      ...fp,
                      classifier_scores: classifierScores,
                    };
                    return newFp;
                  }
                  return fp;
                }),
          };
          if (!exists) {
            newState.usage.push({
              clausepart_text: item.text,
              clausepart_partial_text: item.partial_text,
              clausepart_id: item.clausepart_id,
              id: item.clausepart_id,
              clause_id: item.clause_id,
              document_id: item.document_id,
              project_id: item.project_id,
              classifier_scores: [
                {
                  configuration_id: action.payload.configuration_id,
                  state: action.payload.state,
                  training_mode_id: action.payload.training_mode_id,
                  classifier_settings: action.payload.classifierSettings,
                  score: item.score,
                  is_classified: item.is_classified,
                  classifier_result: item.classifierResult,
                },
              ],
            });
          }
        });

        return newState;
      }
      return state;
    }
    case ActionTypes.CLASSIFIER_RUN_BATCH_ADD_REGEX.REQUEST: {
      if (state.id === action.payload[3]) {
        return {
          ...state,
          last_fp_update_date: new Date().toISOString(),
          last_fp_duration: null,
          last_fp_progress: 0,
        };
      }
      return state;
    }
    case ActionTypes.CLASSIFIER_RUN_BATCH_ADD_REGEX.SUCCESS: {
      if (state.id === action.payload.topic_id) {
        let newFps = action.payload.results
          .filter(
            result =>
              result.regex_match === true &&
              (result.has_topic === false || result.is_confirmed === false),
          )
          .map(use => ({
            ...use,
            definitionAndHeadingsText: getTextWithDefinitionsAndHeadings(use),
            classifier_scores: [
              {
                classifier_result: {score: use.regex_match ? 1 : 0},
                classifier_settings: {},
                configuration_id: action.payload.configuration_id,
                is_classified: Boolean(use.regex_match),
                score: use.regex_match ? 1 : 0,
                state: use.state,
                training_mode_id: use.training_mode_id,
              },
            ],
          }));
        if (action.payload.selected_regex) {
          newFps = _.uniq(
            newFps.concat(state.fp),
            usage => usage.clausepart_id,
          );
        }
        return {
          ...state,
          ..._.pick(action.payload, [
            "last_fp_update_date",
            "last_fp_duration",
          ]),
          usage: action.payload.selected_regex
            ? state.usage
            : state.usage.map(use => {
                const match = action.payload.results.find(
                  result => use.clausepart_id === result.clausepart_id,
                );
                return {
                  ...use,
                  definitionAndHeadingsText: getTextWithDefinitionsAndHeadings(
                    use,
                  ),
                  classifier_scores: (use.classifier_scores || [])
                    .filter(
                      score =>
                        score.configuration_id !==
                        action.payload.configuration_id,
                    )
                    .concat(
                      match
                        ? [
                            {
                              classifier_result: {
                                score: match.regex_match ? 1 : 0,
                              },
                              classifier_settings: {},
                              configuration_id: action.payload.configuration_id,
                              is_classified: Boolean(match.regex_match),
                              score: match.regex_match ? 1 : 0,
                              state: match.state,
                              training_mode_id: match.training_mode_id,
                            },
                          ]
                        : [],
                    ),
                };
              }),
          fp: newFps,
        };
      }
      return state;
    }
    case ActionTypes.CLASSIFIER_RUN_BATCH_ADD.SUCCESS: {
      const {payload} = action;
      if (payload) {
        if (payload.textMode) {
          return {...state, textTestResult: payload};
        }
        return {
          ...state,
          classifier_job_summary: state.classifier_job_summary
            .filter(
              item => item.configuration_id !== action.payload.configuration_id,
            )
            .concat([
              {
                configuration_id: action.payload.configuration_id,
                creation_date: new Date().toISOString(),
                document_count: 1,
                docs_finished: 0,
                last_fp_duration: null,
              },
            ]),
          usage: getUpdatedUsageList(state.usage, payload),
          fp: getUpdatedUsageList(state.fp, payload),
        };
      }
      return state;
    }
    case ActionTypes.CLASSIFIER_GENERATED.SUCCESS: {
      if (
        state.id === action.payload.topic_id &&
        state.classifier_state_logs.length
      ) {
        const lastLog = state.classifier_state_logs[0];
        if (lastLog) {
          if (lastLog.time !== action.payload.log.time) {
            return {
              ...state,
              classifier_state_logs: [
                action.payload.log,
                ...state.classifier_state_logs,
              ],
            };
          }
        }
      }
      return state;
    }
    case ActionTypes.CONTRACT_TYPE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const newTopicContractTypes = state.contract_types.map(ct => {
        if (ct.id === action.payload.id) {
          return {
            ...ct,
            ...action.payload,
          };
        }
        return ct;
      });
      return {
        ...state,
        contract_types: newTopicContractTypes,
      };
    }
    case ActionTypes.CONTRACT_TYPE_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const newTopicContractTypes = state.contract_types.filter(
        topicContractType =>
          topicContractType.contract_type_id !== action.payload.id,
      );
      return {
        ...state,
        contract_types: newTopicContractTypes,
      };
    }
    case ActionTypes.TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE.SUCCESS: {
      const {payload} = action;
      if (
        state === UNINITIALISED ||
        !state.usage ||
        !payload ||
        !payload.clauseparts ||
        payload.clauseparts.length === 0
      ) {
        return state;
      }
      const payloadClausepartsById = byId(payload.clauseparts, "clausepart_id");

      const newUsage = state.usage.map(usage => {
        const payloadClausepart = payloadClausepartsById[usage.clausepart_id];
        if (payloadClausepart) {
          return getUsageWithTopicparameterUpdates(
            state,
            usage,
            payloadClausepart,
          );
        }
        return usage;
      });
      return {
        ...state,
        last_value_update: payload.clausepart_last_edited,
        usage: newUsage,
      };
    }
    case ActionTypes.TOPICPARAMETER_ACTUAL_VALUES_UPDATE.SUCCESS: {
      const {payload} = action;
      if (
        state === UNINITIALISED ||
        !state.usage ||
        !payload ||
        !payload.updates
      ) {
        return state;
      }
      if (state.last_value_update === payload.clausepart_last_edited) {
        return state;
      }
      const newUsage = state.usage.map(usage =>
        getUsageWithTopicparameterUpdates(state, usage, payload),
      );
      let newState = {
        ...state,
        last_value_update: payload.clausepart_last_edited,
        usage: newUsage,
      };
      if (payload.clauseTopicConfirmData) {
        newState = onClauseTopicConfirm(newState, {
          ...payload.clauseTopicConfirmData,
          clausepart_last_edited: payload.clausepart_last_edited,
        });
      }
      return newState;
    }
    case ActionTypes.TOPIC_PARAMETERS_BY_TOPIC_ID_FETCH.SUCCESS: {
      return {
        ...state,
        parameters: action.payload,
      };
    }
    case ActionTypes.TOPIC_USES_BY_TOPIC_ID_FETCH.SUCCESS: {
      if (
        (action.payload.fetchTopicUsesTime > state.fetchTopicUsesTime ||
          !state.fetchTopicUsesTime) &&
        (action.payload && action.payload.usages)
      ) {
        return {
          ...state,
          usage: action.payload.usages.map(use => ({
            ...use,
            definitionAndHeadingsText: getTextWithDefinitionsAndHeadings(use),
          })),
          ..._.omit(action.payload, ["usages"]),
          fetchTopicUsesTime: action.payload.fetchTopicUsesTime,
        };
      }
      return state;
    }
    case ActionTypes.TOPIC_USES_SET_EMPTY.SUCCESS: {
      return {
        ..._.omit(state, ["stats"]),
        usage: [],
      };
    }
    case ActionTypes.TOPIC_PARAMETER_STATS_FETCH.REQUEST: {
      return {
        ...state,
        parameters: [
          ...state.parameters.map(item =>
            _.omit(item, ["stats", "stats_dev", "uses", "uses_dev"]),
          ),
        ],
      };
    }
    case ActionTypes.TOPIC_PARAMETER_STATS_FETCH.SUCCESS: {
      return {
        ...state,
        parameters: [
          ...action.payload,
          ...state.parameters.filter(
            item => !action.payload.find(param => param.id === item.id),
          ),
        ],
      };
    }
    case ActionTypes.DOCUMENT_CLAUSEPART_UPDATE.SUCCESS: {
      if (action.payload.is_badly_parsed) {
        return {
          ...state,
          usage: state.usage.filter(
            item => item.clausepart_id !== action.payload.id,
          ),
          fp: state.fp.filter(item => item.clausepart_id !== action.payload.id),
        };
      }
      return state;
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

function getNewTopicParameters(topicparameters, updates) {
  const remainedUpdatesIds = Object.keys(updates).map(id => parseInt(id, 10));
  const newTopicparameters = topicparameters
    .map(tp => {
      if (!updates[tp.topicparameter_id]) {
        return tp;
      }
      const tpUpdates = updates[tp.topicparameter_id];
      const newTp = {...tp, ...tpUpdates};
      const removeIndex = remainedUpdatesIds.indexOf(tp.topicparameter_id);
      if (removeIndex > -1) {
        remainedUpdatesIds.splice(removeIndex, 1);
      }
      return newTp;
    })
    .filter(tp => Boolean(tp));
  if (remainedUpdatesIds.length > 0) {
    remainedUpdatesIds.forEach(tpId =>
      newTopicparameters.push({
        ...updates[tpId],
        topicparameter_id: parseInt(tpId, 10),
      }),
    );
  }
  return newTopicparameters;
}

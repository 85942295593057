import _ from "underscore";
import isEmptyParent from "common/utils/issues/is_empty_parent";
import getEmptyParentApplicableIssues from "common/utils/issues/get_empty_parent_applicable_issues";
import getClauseAtomsByIssue from "common/utils/clauses/get_clause_atoms_by_issue";
import convertAtomsToApplicableClauses from "utils/clauses/applicable_clauses/convert_atoms_to_applicable_clauses";
import {DocumentIssue} from "common/types/document_issue";
import {
  DocumentClauses,
  DocumentSection,
  TopicsById,
} from "common/types/document";
import {TopicCategoryId} from "common/types/topic_categories";

function getIssueApplicableClauses(
  issue: DocumentIssue,
  documentClauses: DocumentClauses,
  acceptedTopicCategoryIds: TopicCategoryId[],
  documentSections: DocumentSection[],
  topicsById: TopicsById,
) {
  if (isEmptyParent(issue)) {
    return _.chain(getEmptyParentApplicableIssues(issue))
      .map(issue =>
        convertAtomsToApplicableClauses(
          getClauseAtomsByIssue(
            issue,
            documentClauses,
            acceptedTopicCategoryIds,
            topicsById,
          ),
        ),
      )
      .flatten()
      .uniq(clausepart => clausepart.id)
      .value();
  }
  return convertAtomsToApplicableClauses(
    getClauseAtomsByIssue(
      issue,
      documentClauses,
      acceptedTopicCategoryIds,
      topicsById,
    ),
  );
}

export default getIssueApplicableClauses;

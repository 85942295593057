import _ from "lodash";

function sortRagReportTableItems(value, sortKey) {
  // TODO: This takes the table items and sorts them, so needs to filter out
  // table headings when its not the default ordering
  // We should change this so it sorts the issues before we generate
  // the table items
  const sortedItems = value.filter(row => row.children.length > 1);
  let result;
  if (sortKey === "Needs Review") {
    const order = {
      1: 5,
      2: 10,
      3: 0,
    };
    result = _.sortBy(sortedItems, item => order[item.review_state]);
  } else if (sortKey === "State") {
    result = _.sortBy(sortedItems, item => {
      const state = item.issue_status;
      if (state === "red") {
        return 0;
      }
      if (state === "amber") {
        return 5;
      }
      if (state === "grey") {
        return 10;
      }
      return 15;
    });
  } else {
    result = value;
  }
  return result;
}

export default sortRagReportTableItems;

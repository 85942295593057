const {pureReplacements} = require("./replacements/pure");

export function getPure(str, replacementList = pureReplacements) {
  if (typeof str === "string") {
    return replacementList
      .reduce(
        (acc, {from, to}) =>
          from ? acc.replace(new RegExp(from, "ig"), to) : acc,
        str,
      )
      .replace(/\s+/g, " ")
      .trim()
      .toLowerCase();
  }
  return "";
}

import _ from "lodash";
import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

import updateOverrideDocumentIssueValues from "common/utils/update_override_document_issue_values";

function updateSingleIssue(state, action) {
  const newIssue = action.payload.issues[0];
  const issueId = newIssue ? newIssue.id : action.payload.issue_id;
  const oldIssue = state.issues.find(issue => issue.id === issueId) || {};
  const issues = state.issues
    .filter(issue => issue.id !== issueId)
    .concat({...oldIssue, reason: null, ...newIssue});
  return {
    ...state,
    issues,
  };
}

const unimportantKeys = [
  "manual_corrections",
  "override_document_issue_values",
  "last_edited",
  "action_state",
  "review_state",
  "is_correctly_applied",
  "should_be_issue",
  "user_verified",
];
const manualKeys = ["manual_corrections"];

function isOverrideImportant(manualOverrideMap, manualUpdates) {
  if (!manualOverrideMap) {
    return false;
  }
  const hasImportantOverrides = Object.keys(manualOverrideMap).some(
    issuesetId =>
      Object.keys(manualOverrideMap[issuesetId]).some(clientKey => {
        return hasImportantChange(
          manualOverrideMap[issuesetId][clientKey],
          manualUpdates,
        );
      }),
  );
  return Boolean(hasImportantOverrides);
}

export function hasImportantChange(updates, manualUpdates) {
  const values = manualUpdates
    ? Object.keys(_.pick(updates, manualKeys))
    : Object.keys(_.omit(updates, unimportantKeys));
  const updateHasImportantChange = values.length > 0;
  const overrides = updates.override_document_issue_values;
  return (
    updateHasImportantChange ||
    (overrides ? isOverrideImportant(overrides, manualUpdates) : false)
  );
}

function advanceUpdateCount(updateCount, updates, manualUpdates) {
  if (!hasImportantChange(updates, manualUpdates)) {
    return updateCount;
  }
  return updateCount + 1;
}

function setUpdateCounts(state) {
  state.updateCount = state.issues.reduce(
    (total, issue) => total + issue.updateCount,
    0,
  );
  state.manualUpdateCount = state.issues.reduce(
    (total, issue) => total + issue.manualUpdateCount,
    0,
  );
  return state;
}

/* eslint-disable complexity */
/* eslint-disable max-statements */
export default function issuesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.DOCUMENT_FETCH.SUCCESS: {
      const value = _.pick(action.payload, "document_id", "issues");
      value.issues = (value?.issues ?? []).map(item => ({
        ...item,
        updateCount: 1,
        manualUpdateCount: 1,
      }));
      value.updateCount = value.issues.length;
      value.manualUpdateCount = value.issues.length;
      return value;
    }
    case ActionTypes.DOCUMENT_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.DOCUMENT_ISSUES_FETCH.SUCCESS: {
      if (state === UNINITIALISED) {
        return _.pick(action.payload, "document_id", "issues");
      }
      if (action.payload.single_issue_update) {
        return updateSingleIssue(state, action);
      }
      const newIssues = action.payload.issues.filter(
        newIssue =>
          !(state.issues || []).find(issue => issue.id === newIssue.id),
      );
      const newValue = {
        document_id: action.payload.document_id,
        issues: (state.issues || [])
          .map(issue => {
            const newIssue = action.payload.issues.find(
              newIssue => newIssue.id === issue.id,
            );
            if (!newIssue) {
              return {
                ...issue,
                reason: null,
              };
            }
            return {
              ...issue,
              ...newIssue,
              updateCount: issue.updateCount || 1,
              manualUpdateCount: issue.manualUpdateCount || 1,
            };
          })
          .concat(
            newIssues.map(issue => ({
              ...issue,
              updateCount: 1,
              manualUpdateCount: 1,
            })),
          ),
      };
      setUpdateCounts(newValue);
      if (!_.isEqual(newValue, state)) {
        return newValue;
      }
      return state;
    }
    case ActionTypes.DOCUMENT_ISSUES_REFETCH.SUCCESS:
    case ActionTypes.DOCUMENT_ISSUES_REFRESH.SUCCESS: {
      if (
        state === UNINITIALISED ||
        state.document_id !== action.payload?.document_id
      ) {
        return state;
      }
      if (action.payload.single_issue_update) {
        return updateSingleIssue(state, action);
      }
      const newValue = {
        document_id: action.payload.document_id,
        issues: (state.issues || []).map(stateIssue => {
          const foundPayloadIssue = action.payload.issues.find(
            payloadIssue => payloadIssue.id === stateIssue.id,
          );
          if (foundPayloadIssue) {
            return {
              updateCount: 0,
              manualUpdateCount: 0,
              ...stateIssue,
              ...foundPayloadIssue,
            };
          }
          return {...stateIssue, reason: null};
        }),
      };
      setUpdateCounts(newValue);
      return newValue;
    }
    case ActionTypes.ISSUE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        issues: state.issues.map(issue => {
          if (issue.id === action.payload.id) {
            // document issue is taken from 2 tables: document_issue (if available) or issue.
            // if there is document_issue_id then the issue was taken from document_issue table
            // thus we don't change last_edited if an entry in issue table changed
            const last_edited = issue.document_issue_id
              ? issue.last_edited
              : action.payload.last_edited;
            return {
              ...issue,
              ...action.payload,
              name: action.payload.display_name
                ? action.payload.display_name
                : action.payload.name,
              true_name: action.payload.name,
              last_edited,
            };
          }
          return issue;
        }),
      };
    }
    case ActionTypes.DOCUMENT_ISSUE_MANUAL_CORRECT.SUCCESS:
    case ActionTypes.DOCUMENT_ISSUE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      if (state.document_id !== payload.document_id) {
        return state;
      }
      const newState = {
        ...state,
        issues: state.issues.map(issue => {
          if (issue.document_issue_id === payload.document_issue_id) {
            if (
              payload.options &&
              payload.options.update_override_document_issue_values &&
              payload.options.merge_update_object
            ) {
              const overrideDocumentIssueValues = _.cloneDeep(
                issue.override_document_issue_values,
              );
              updateOverrideDocumentIssueValues(
                payload.updates,
                `${payload.options.data_path}${
                  payload.options.is_manual_correction
                    ? ".manual_corrections"
                    : ""
                }`,
                overrideDocumentIssueValues,
              );
              return {
                ...issue,
                last_edited: payload.last_edited,
                document_issue_id: payload.document_issue_id,
                override_document_issue_values: overrideDocumentIssueValues,
                manualUpdateCount: issue.manualUpdateCount + 1,
              };
            }
            const newIssue = {
              ...issue,
              last_edited: payload.last_edited,
              document_issue_id: payload.document_issue_id,
              updateCount: advanceUpdateCount(
                issue.updateCount,
                payload.updates,
              ),
              manualUpdateCount: advanceUpdateCount(
                issue.manualUpdateCount,
                payload.updates,
                true,
              ),
              ...payload.updates,
            };

            return newIssue;
          }
          return issue;
        }),
      };
      setUpdateCounts(newState);
      return newState;
    }
    case ActionTypes.DOCUMENT_ISSUE_GENERATE_PROMPT.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      if (state.document_id !== payload.document_id) {
        return state;
      }
      return {
        ...state,
        issues: state.issues.map(issue => {
          if (issue.document_issue_id === payload.id) {
            return payload?.conversation
              ? {
                  ...issue,
                  conversations: {
                    ...issue.conversations,
                    [payload.conversation_type]: {
                      ...issue.conversations?.[payload.conversation_type],
                      [payload.issueset_master_id]: {
                        ...issue.conversations?.[payload.conversation_type]?.[
                          payload.issueset_master_id
                        ],
                        [payload.issueset_remote_client_id ?? "master"]: {
                          ...issue.conversations?.[payload.conversation_type]?.[
                            payload.issueset_master_id
                          ]?.[payload.issueset_remote_client_id ?? "master"],
                          items: (
                            issue.conversations?.[payload.conversation_type]?.[
                              payload.issueset_master_id
                            ]?.[payload.issueset_remote_client_id ?? "master"]
                              .items ?? []
                          ).concat(payload.conversation.items),
                        },
                      },
                    },
                  },
                }
              : issue;
          }
          return issue;
        }),
      };
    }
    case ActionTypes.DOCUMENT_ISSUE_CONVERSATION_CLEAR.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      if (state.document_id !== payload.document_id) {
        return state;
      }
      return {
        ...state,
        issues: state.issues.map(issue => {
          if (issue.id === payload.issue_id) {
            return {
              ...issue,
              conversations: {},
            };
          }
          return issue;
        }),
      };
    }
    case ActionTypes.DOCUMENT_ISSUE_CONVERSATION_FETCH.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      if (state.document_id !== payload.document_id) {
        return state;
      }
      return {
        ...state,
        issues: state.issues.map(issue => {
          if (issue.document_issue_id === payload.id) {
            return payload?.conversation
              ? {
                  ...issue,
                  conversations: {
                    ...issue.conversations,
                    [payload.conversation_type]: {
                      ...issue.conversations?.[payload.conversation_type],
                      [payload.issueset_master_id]: {
                        ...issue.conversations?.[payload.conversation_type]?.[
                          payload.issueset_master_id
                        ],
                        [payload.issueset_remote_client_id ?? "master"]: {
                          ...issue.conversations?.[payload.conversation_type]?.[
                            payload.issueset_master_id
                          ]?.[payload.issueset_remote_client_id ?? "master"],
                          items: payload.conversation,
                        },
                      },
                    },
                  },
                }
              : issue;
          }
          return issue;
        }),
      };
    }
    case ActionTypes.DOCUMENT_ISSUES_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      if (state.document_id !== payload.document_id) {
        return state;
      }
      const newState = {
        ...state,
        issues: state.issues.map(issue => {
          if (payload.issues[issue.document_issue_id]) {
            if (
              payload.options &&
              payload.options.update_override_document_issue_values &&
              payload.options.merge_update_object
            ) {
              const overrideDocumentIssueValues = _.cloneDeep(
                issue.override_document_issue_values,
              );
              updateOverrideDocumentIssueValues(
                payload.updates,
                payload.options.data_path,
                overrideDocumentIssueValues,
              );
              return {
                ...issue,
                ...payload.updates,
                ...payload.issues[issue.document_issue_id],
                override_document_issue_values: overrideDocumentIssueValues,
                manualUpdateCount: issue.manualUpdateCount + 1,
              };
            }
            return {
              ...issue,
              ...payload.issues[issue.document_issue_id], // individual updates are received in this object
              ...payload.updates, // general updates - updates applied for all issues
              // TODO: do we need to handle specific issue updates heree??
              updateCount: advanceUpdateCount(
                issue.updateCount,
                payload.updates,
              ),
              manualUpdateCount: advanceUpdateCount(
                issue.manualUpdateCount,
                payload.updates,
                true,
              ),
            };
          }
          return issue;
        }),
      };
      setUpdateCounts(newState);
      return newState;
    }
    case ActionTypes.DOCUMENT_STATE_REVERT.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      if (
        state.document_id !== payload.document_id ||
        payload.revertOnlyChanges
      ) {
        return state;
      }
      return {
        ...state,
        issues: state.issues.map(issue => ({
          ...issue,
          last_edited: payload.document_last_edited,
          action_state: {},
          correction: null,
          user_text: null,
        })),
      };
    }
    case ActionTypes.ISSUE_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        issues: state.issues.filter(issue => {
          return issue.id !== action.payload.id;
        }),
      };
    }
    case ActionTypes.DOCUMENT_ISSUE_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_ISSUE_CORRECT.SUCCESS: {
      if (state === UNINITIALISED) {
        return UNINITIALISED;
      }
      return {
        ...state,
        issues: state.issues.map(issue =>
          issue.document_issue_id === action.payload.document_issue.id
            ? {
                ...issue,
                ..._.omit(action.payload.document_issue, [
                  "rules",
                  "id",
                  "issue_id",
                ]),
              }
            : issue,
        ),
      };
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;

    case ActionTypes.ISSUESET_ADD_TO_ISSUE.SUCCESS: {
      const addedIssues = action.payload || [];
      if (addedIssues && addedIssues.length > 0) {
        const newIssues = [...state.issues];
        for (const addedIssue of addedIssues) {
          const newIssuesIndex = newIssues.findIndex(
            newIssue =>
              addedIssue.document_issue_id === newIssue.document_issue_id,
          );
          if (newIssuesIndex > -1) {
            newIssues[newIssuesIndex] = addedIssue;
          } else {
            newIssues.push(addedIssue);
          }
        }
        return {...state, issues: newIssues};
      }
      return state;
    }

    case ActionTypes.ISSUE_RESPONSE_ADD.SUCCESS: {
      const issueResponse = action.payload;
      const {issue_id: issueId} = issueResponse;
      if (issueId) {
        const {issues} = state;
        return {
          ...state,
          issues: issues.map(issue => {
            if (issue.id === issueId) {
              return {
                ...issue,
                issue_responses: [
                  ...(issue.issue_responses || []),
                  issueResponse,
                ],
              };
            }
            return issue;
          }),
        };
      }
      return state;
    }

    case ActionTypes.ISSUE_RESPONSE_UPDATE.SUCCESS: {
      const issueResponse = action.payload;
      const issueId = issueResponse.issue_id;
      if (issueId) {
        const {issues} = state;
        return {
          ...state,
          issues: issues.map(issue => {
            if (issue.id === issueId) {
              return {
                ...issue,
                issue_responses: issue.issue_responses.map(response => {
                  if (response.id === issueResponse.id) {
                    return issueResponse;
                  }
                  return response;
                }),
              };
            }
            return issue;
          }),
        };
      }
      return state;
    }

    case ActionTypes.ISSUE_RESPONSE_DELETE.SUCCESS: {
      const issueResponse = action.payload;
      const issueId = issueResponse.issue_id;
      if (issueId) {
        const {issues} = state;
        return {
          ...state,
          issues: issues.map(issue => {
            if (issue.id === issueId) {
              return {
                ...issue,
                issue_responses: issue.issue_responses.filter(
                  response => response.id !== issueResponse.id,
                ),
              };
            }
            return issue;
          }),
        };
      }
      return state;
    }

    case ActionTypes.ISSUE_COMMENT_ADD.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        issues: state.issues.map(issue => {
          if (issue.document_issue_id === action.payload.document_issue_id) {
            return {
              ...issue,
              issue_comments: [...(issue.issue_comments || []), action.payload],
            };
          }
          return issue;
        }),
      };
    }

    case ActionTypes.ISSUE_COMMENT_DELETE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const issueIndex = state.issues.findIndex(
        ({document_issue_id}) =>
          document_issue_id === action.payload.document_issue_id,
      );
      if (issueIndex === -1) {
        return state;
      }
      const issue = state.issues[issueIndex];
      const commentIndex = issue.issue_comments.findIndex(
        ({id}) => id === action.payload.id,
      );
      if (commentIndex === -1) {
        return state;
      }
      const newIssues = [...state.issues];
      newIssues[issueIndex] = {...issue};
      newIssues[issueIndex].issue_comments = [...issue.issue_comments];
      newIssues[issueIndex].issue_comments.splice(commentIndex, 1);
      return {
        ...state,
        issues: newIssues,
      };
    }

    case ActionTypes.ISSUE_COMMENT_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const issueIndex = state.issues.findIndex(
        ({document_issue_id}) =>
          document_issue_id === action.payload.document_issue_id,
      );
      if (issueIndex === -1) {
        return state;
      }
      const issue = state.issues[issueIndex];
      const commentIndex = issue.issue_comments.findIndex(
        ({id}) => id === action.payload.id,
      );
      if (commentIndex === -1) {
        return state;
      }
      const newIssues = [...state.issues];
      newIssues[issueIndex] = {...issue};
      newIssues[issueIndex].issue_comments = [...issue.issue_comments];
      newIssues[issueIndex].issue_comments[commentIndex] = action.payload;
      return {
        ...state,
        issues: newIssues,
      };
    }

    default:
      return state;
  }
}

import React, {useState} from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import Forward from "@material-ui/icons/Forward";
import {Link} from "react-router";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CloseIcon from "@material-ui/icons/Close";
import CheckboxesMenu from "./checkboxes_menu";
import DownloadButton from "./download_button";

const styles = {
  iconSize: {
    width: "20px",
  },
  buttonSize: {
    width: "30px",
    height: "30px",
  },
  iconGroup: {
    display: "flex",
    backgroundColor: "rgb(227 227 227)",
    alignItems: "center",
    border: "1px solid #aaa",
    borderRadius: "2px",
    marginTop: "5px",
  },
};

const IconButtons = ({
  // hasLeftCheckbox,
  showDialog,
  getLinkPath,
  onDownloadXlsx,
  onDownloadDocx,
  startSelectedReportEditing,
  toggleReportPanelVisibility,
  toggleReportOpenInRightSidebar,
  highlightManualCorrections,
  triggerHighlightManualCorrections,
  showDeletedIssues,
  triggerShowDeletedIssues,
  reportType,
  addNewReportItem,
  isReportShownInRightSide,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const startEditingReport = () => {
    closeMenu();
    startSelectedReportEditing();
  };

  return (
    <div
      style={{
        // not sure what this styling was for, uncommented for now to see if any changes are noticed
        // width: hasLeftCheckbox ? 450 : "unset",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: "10px",
        marginRight: "5px",
      }}
    >
      {" "}
      {onDownloadDocx && (
        <DownloadButton
          onDownloadXlsx={onDownloadXlsx}
          onDownloadDocx={onDownloadDocx}
        />
      )}
      <div style={styles.iconGroup}>
        <Tooltip title="Open report" enterDelay={1000} placement="top" arrow>
          <IconButton onClick={showDialog} style={styles.buttonSize}>
            <ZoomOutMapIcon style={styles.iconSize} />
          </IconButton>
        </Tooltip>
        <Link to={{pathname: getLinkPath}} target="_blank" onlyActiveOnIndex>
          <Tooltip
            title="Open report in new tab"
            enterDelay={1000}
            placement="top"
            arrow
          >
            <IconButton style={styles.buttonSize}>
              <OpenInNewIcon style={styles.iconSize} />
            </IconButton>
          </Tooltip>
        </Link>
        {!isReportShownInRightSide && (
          <Tooltip
            title="Open report in sidebar"
            enterDelay={1000}
            placement="top"
            arrow
          >
            <IconButton
              onClick={() => {
                toggleReportOpenInRightSidebar();
                toggleReportPanelVisibility();
              }}
              style={styles.buttonSize}
            >
              <Forward style={styles.iconSize} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      <CheckboxesMenu
        openMenu={openMenu}
        anchorEl={anchorEl}
        closeMenu={closeMenu}
        startEditingReport={startEditingReport}
        highlightManualCorrections={highlightManualCorrections}
        triggerHighlightManualCorrections={triggerHighlightManualCorrections}
        reportType={reportType}
        showDeletedIssues={showDeletedIssues}
        triggerShowDeletedIssues={triggerShowDeletedIssues}
        addNewReportItem={addNewReportItem}
      />
      <Tooltip title="Close report" enterDelay={1000} placement="top" arrow>
        <IconButton
          onClick={toggleReportPanelVisibility}
          style={styles.buttonSize}
        >
          <CloseIcon style={styles.iconSize} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default IconButtons;

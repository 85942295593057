import UNINITIALISED from "utils/uninitialised";
import byId from "common/utils/by_id";

import {
  updateStateWithRequest,
  updateStateWithSuccess,
} from "utils/ensure_fresh_update";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";
/* eslint-disable complexity */
export default function issuesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.ISSUES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.ISSUES_INFO_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.ISSUE_ADD.SUCCESS: {
      if (
        state === UNINITIALISED ||
        !action.payload ||
        !action.payload.added_issues
      ) {
        return state;
      }
      const {added_issues: addedIssues} = action.payload;
      const issueExists = state.find(issue => issue.id === addedIssues[0].id);
      if (issueExists) {
        return state;
      }
      return [...addedIssues, ...state];
    }
    case ActionTypes.ISSUE_UPDATE.REQUEST: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(issue => {
        if (issue.id === action.payload.id) {
          return updateStateWithRequest(
            issue,
            {
              ...issue,
              ...action.payload,
            },
            ["id", "last_edited"],
          );
        }
        return issue;
      });
    }
    case ActionTypes.ISSUES_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const issuesById = byId(action.payload);
      return state.map(
        issue =>
          issuesById[issue.id] ? {...issue, ...issuesById[issue.id]} : issue,
      );
    }
    case ActionTypes.ISSUE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(issue => {
        if (issue.id === action.payload.id) {
          return updateStateWithSuccess(issue, {
            ...action.payload,
            true_name: action.payload.name,
          });
        }
        return issue;
      });
    }
    case ActionTypes.ISSUE_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.filter(issue => issue.id !== action.payload.id);
    }
    case ActionTypes.CONTRACT_TYPE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(issue => {
        const newIssueContractTypes = issue.contract_types.map(
          issueContractType => {
            if (issueContractType.contract_type_id === action.payload.id) {
              return {
                ...issueContractType,
                ...action.payload,
              };
            }
            return issueContractType;
          },
        );
        return {
          ...issue,
          contract_types: newIssueContractTypes,
        };
      });
    }
    case ActionTypes.CONTRACT_TYPE_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(issue => {
        const newIssueContractTypes = issue.contract_types.filter(
          issueContractType =>
            issueContractType.contract_type_id !== action.payload.id,
        );
        return {
          ...issue,
          contract_types: newIssueContractTypes,
        };
      });
    }
    case ActionTypes.ISSUES_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import React from "react";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import FormatClearIcon from "@material-ui/icons/FormatClear";
import FormatSizeIcon from "@material-ui/icons/FormatSize";

import ZoomControl from "common_components/zoom_control";

function ZoomMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FormatSizeIcon
        onClick={handleClick}
        style={{cursor: "pointer", color: "#50B2FF", height: 20, width: 20}}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ZoomControlItem {...props} />
      </Popover>
    </>
  );
}

function ZoomControlItem(props) {
  const {zoom} = props;

  function updateZoomItem(key) {
    return function(newValue) {
      props.onZoomUpdate({
        ...zoom,
        [key]: newValue,
      });
    };
  }

  function resetZoom() {
    props.onZoomUpdate({
      ...zoom,
      checklist: 1,
    });
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "4px 7px 4px 0px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ZoomControl
          name="checklist"
          value={zoom.checklist}
          onChange={updateZoomItem("checklist")}
          containerStyle={{margin: "0px"}}
          step={0.2}
          minValue={0.6}
          maxValue={2.2}
        />
        <div
          style={{
            borderRight: "1px solid lightgray",
            height: "16px",
            marginLeft: "2px",
          }}
        />
        <IconButton
          onClick={resetZoom}
          size="small"
          style={{marginLeft: "2px"}}
        >
          <FormatClearIcon />
        </IconButton>
      </div>
    </div>
  );
}

export default ZoomMenu;

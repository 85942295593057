import _ from "underscore";
import getIssueOrder from "./get_issue_order";

import getIssuesSortingFunction from "common/utils/issues/get_issues_sorting_function";

/* eslint-disable no-lonely-if */
const groupIssues = rawIssues => {
  const issues = rawIssues.map(issue => ({
    ...issue,
    reason: issue.reason ? issue.reason : [],
    ...getIssueOrder(issue.issue_order),
  }));
  const groupedIssues = {};
  issues.forEach(issue => {
    const fullIssueName = issue.name;
    if (fullIssueName.includes(" / ")) {
      const fullIssueNameSplitted = fullIssueName.split("/");
      const groupName = fullIssueNameSplitted[0].trim();
      const issueToGroup = {
        ...issue,
        name: !fullIssueNameSplitted[2]
          ? fullIssueNameSplitted[1].trim()
          : `${fullIssueNameSplitted[1]}/${fullIssueNameSplitted[2]}`.trim(),
        groupName,
      };
      if (!groupedIssues[groupName]) {
        groupedIssues[groupName] = [issueToGroup];
      } else {
        groupedIssues[groupName] = [...groupedIssues[groupName], issueToGroup];
      }
    } else {
      if (!groupedIssues["__noGroup"]) {
        groupedIssues["__noGroup"] = [issue];
      } else {
        groupedIssues["__noGroup"] = [...groupedIssues["__noGroup"], issue];
      }
    }
  });
  // create sorted array of elements (groups and singular elements)
  const groupedIssuesArrayRaw = [];
  _.forEach(groupedIssues, (group, groupName) => {
    if (groupName !== "__noGroup") {
      let groupOrder = 999999;
      group.forEach(issue => {
        if (issue.groupOrder < groupOrder) {
          groupOrder = issue.groupOrder;
        }
      });
      groupedIssuesArrayRaw.push({
        name: groupName,
        isGroup: true,
        item: group.sort(getIssuesSortingFunction("issueItemOrder")),
        groupOrder,
      });
    } else {
      group.forEach(issue => {
        groupedIssuesArrayRaw.push({
          name: issue.name,
          isGroup: false,
          item: [issue],
          groupOrder: issue.groupOrder,
        });
      });
    }
  });
  return groupedIssuesArrayRaw.sort(getIssuesSortingFunction("groupOrder"));
};

export default groupIssues;

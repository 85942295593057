import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import {QueryRunAction, QueryResult} from "../actions/query_run";
import actionTypes from "../constants/action_types";

export default function searcherReducer(
  state: MaybeUninitialised<QueryResult[]> = UNINITIALISED,
  action: QueryRunAction,
): typeof state {
  if (action.type === actionTypes.SEARCHER_QUERY_RUN.SUCCESS) {
    return (action as QueryRunAction).payload;
  }
  if (action.type === actionTypes.SEARCHER_QUERY_CLEAR.SUCCESS) {
    return UNINITIALISED;
  }
  return state;
}

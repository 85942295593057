import {ActionTypes} from "modules/redux_types";
import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

const types = [
  "PROJECTS_FETCH",

  "PROJECT_FETCH",
  "PROJECT_DELETE",
  "PROJECT_CREATE",
  "PROJECT_UPDATE",
  "PROJECT_CLEAR",

  "PARTIES_FETCH",
  "PROJECT_PARTY_ADD",
  "PROJECT_PARTY_UPDATE",
  "PROJECT_PARTY_REMOVE",

  "PROJECT_ADD_ACCESS_TAG",
  "PROJECT_CREATE_ACCESS_TAG",
  "PROJECT_REMOVE_ACCESS_TAG",

  "PROJECTROLES_FETCH",
] as const;

types.forEach(type => createReduxPromiseActionType(actionTypes, type));

export type ActionType = typeof types[number];

export type ProjectActionTypes = ActionTypes<ActionType>;

export default actionTypes as ProjectActionTypes;

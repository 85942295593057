import React from "react";

import * as colors from "material-ui/styles/colors";
import IssueIcon from "./issue_icon";
import formatTopicName from "routes/document_detail/utils/format_topic_name";

import isIssueAlert from "common/utils/issues/is_issue_alert";

const styles = {
  item: {
    padding: "7px 0",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    fontSize: 14,
    color: "#232e38",
    lineHeight: 1.36,
  },
  title: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
  },
  searchResult: {
    margin: "0.1rem 0.1rem 0.1rem 1.3rem",
    background: colors.grey100,
    fontSize: "11px",
    fontWeight: 100,
    maxHeight: "20rem",
    overflow: "auto",
  },
  description: {
    cursor: "pointer",
    textAlign: "justify",
    fontSize: "11px",
    lineHeight: "12px",
    marginLeft: "22px",
    marginRight: "4px",
    fontWeight: 100,
    fontFamily: "Roboto, sans-serif",
  },
  fullName: {
    padding: "4px",
    backgroundColor: "rgb(255, 213, 79)",
    color: "rgb(17, 17, 17)",
    textAlign: "center",
    fontWeight: "500",
    borderRadius: "5px",
  },
};

export default class SearchItem extends React.Component {
  state = {
    isMouseOver: false,
  };
  render() {
    const {item, dataToRender} = this.props;
    const isHighlightBackground =
      this.state.isMouseOver || (dataToRender && dataToRender[item.id]);
    return (
      <div>
        <div
          onMouseEnter={this.onHoverStart}
          onMouseLeave={this.onHoverFinish}
          style={{
            ...styles.item,
            ...(this.props.itemStyle ? this.props.itemStyle : {}),
            background: isHighlightBackground ? "rgba(0, 0, 0, 0.03)" : "none",
          }}
          onClick={this.onItemClick}
        >
          <div style={styles.title}>
            {this.props.leftIcon}
            <span>{item.name}</span>
          </div>
          {this.renderIssuesIcon()}
        </div>
        {this.renderDescription(isHighlightBackground)}
        {this.props.resultRenderer &&
          dataToRender[item.id] &&
          this.renderSearchResult()}
      </div>
    );
  }
  renderIssuesIcon() {
    const {item} = this.props;
    if (item && item.issue_type && isIssueAlert(item)) {
      return <IssueIcon />;
    }
  }
  renderTopicGroup = () => {
    const {item} = this.props;
    if (item.fullName) {
      return (
        <div style={styles.fullName}>
          <i>{formatTopicName(item.fullName)}</i>
        </div>
      );
    }
  };
  renderDescription = isHighlightBackground => {
    const {item} = this.props;
    if (item.topicDescription && item.topicDescription.description) {
      return (
        <div
          style={{
            background: isHighlightBackground ? colors.grey300 : "none",
          }}
          onMouseEnter={this.onHoverStart}
          onMouseLeave={this.onHoverFinish}
          onClick={this.onItemClick}
        >
          <div style={styles.description}>
            <i>{item.topicDescription.description}</i>
          </div>
        </div>
      );
    }
  };
  renderSearchResult = () => {
    if (this.props.dataToRender[this.props.item.id].length === 0) {
      return <div style={styles.searchResult}>No Results</div>;
    }
    return (
      <div style={styles.searchResult}>
        {this.renderTopicGroup()}
        {this.props.resultRenderer(this.props.dataToRender[this.props.item.id])}
      </div>
    );
  };
  onHoverStart = () => this.setState(() => ({isMouseOver: true}));
  onHoverFinish = () => this.setState(() => ({isMouseOver: false}));
  onItemClick = () => {
    this.props.onMatchItemClick(this.props.item);
  };
}

import React from "react";
import _ from "lodash";

import SettingsDialog from "./components/settings_dialog";

import EmailReport from "./email_report";
import RagReport from "./rag_report";
import CustomReport from "./custom_report";
import NoticeOfAssignment from "./notice_of_assignment";
import TransferCertificate from "./transfer_certificate";
import AmendmentLetter from "./amendment_letter";
import getIssueNames from "utils/issues/get_issue_names";
import getAddedToReportIssues from "utils/issues/get_added_to_report_issues";
import getReportIssues from "./utils/get_report_issues";
import groupChecklistSections from "common/utils/document/group_checklist_sections";

const styles = {
  editIcon: {
    cursor: "pointer",
    height: 16,
    width: 16,
    color: "#616161",
  },
  closeIcon: {
    height: 16,
    width: 16,
    cursor: "pointer",
    color: "#616161",
    position: "relative",
    right: -18,
    alignSelf: "flex-start",
  },
  header: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "10px 2rem 10px 10px",
    backgroundColor: "#fbfbfb",
  },
  label: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "10rem",
  },
  rootBlock: {
    boxShadow: "none",
    borderBottom: "1px solid #e8e8e8",
  },
};

export default class Report extends React.Component {
  state = {
    highlightManualCorrections: false,
    editedReport: null,
  };

  render() {
    return (
      <div>
        {this.renderReportComponent()}
        {this.state.editedReport && (
          <SettingsDialog
            reportSettings={this.props.reportSettings}
            editedReport={this.state.editedReport}
            project={this.props.project}
            issues={this.props.issues}
            contractTypes={this.props.contractTypes}
            document={this.props.document}
            updateStateEditedReport={this.updateStateEditedReport}
            onRemoveExistingDocumentReportSettings={
              this.onRemoveExistingDocumentReportSettings
            }
            onSaveEditedReport={this.onSaveEditedReport}
            cancelSelectedReportEditing={this.cancelSelectedReportEditing}
          />
        )}
      </div>
    );
  }

  renderReportComponent = () => {
    const {selectedReportId, reportSettings} = this.props;
    if (!selectedReportId) {
      return null;
    }

    const groupedSections = groupChecklistSections(
      this.props.documentSections || [],
    );

    const commonProps = {
      renderInSeparateWindow: this.props.renderInSeparateWindow,
      closeButtonHandler: this.props.toggleReportPanelVisibility,
      rootDivStyles: styles.rootBlock,
      document: this.props.document,
    };

    const project =
      this.props.document.report_settings &&
      this.props.document.report_settings.length > 0
        ? mergeDocumentReportSettingsIntoProject(
            this.props.project,
            this.props.document.report_settings,
          )
        : this.props.project;
    switch (reportSettings.type) {
      case "email": {
        const issuesAddedToReport = getAddedToReportIssues(
          this.props.issues,
          this.props.showIssuesInChecklist,
          this.props.reportSettings,
        );
        const reportIssues = getReportIssues(
          issuesAddedToReport,
          this.props.groupedIssues,
        );
        return (
          <EmailReport
            {...this.props}
            reportIssues={reportIssues}
            startItemEdit={this.startItemEdit}
            finishItemEdit={this.finishItemEdit}
            project={project}
          />
        );
      }
      case "rag_report":
        return (
          <RagReport
            // reportIssues={reportIssues} separate calculation of report issues
            {...this.props}
            project={project}
            reportType={reportSettings.type}
            groupedSections={groupedSections}
            documentId={this.props.document.id}
            onShowIssueDetail={this.onShowIssueDetail}
            organisationId={this.props.organisationId}
            isDocumentReport={this.props.isDocumentReport}
            startSelectedReportEditing={this.startSelectedReportEditing}
            highlightManualCorrections={this.state.highlightManualCorrections}
            triggerHighlightManualCorrections={
              this.triggerHighlightManualCorrections
            }
            reportId={reportSettings.id}
            toggleReportPanelVisibility={this.props.toggleReportPanelVisibility}
            toggleReportOpenInRightSidebar={
              this.props.toggleReportOpenInRightSidebar
            }
            isReportShownInRightSide={this.props.isReportShownInRightSide}
          />
        );
      case "custom_report":
        return (
          <CustomReport
            {...this.props}
            reportType={reportSettings.type}
            groupedSections={groupedSections}
            onShowIssueDetail={this.onShowIssueDetail}
            organisationId={this.props.organisationId}
            isDocumentReport={this.props.isDocumentReport}
            highlightManualCorrections={this.state.highlightManualCorrections}
            triggerHighlightManualCorrections={
              this.triggerHighlightManualCorrections
            }
            toggleReportPanelVisibility={this.props.toggleReportPanelVisibility}
            reportId={reportSettings.id}
            startSelectedReportEditing={this.startSelectedReportEditing}
          />
        );
      case "notice_of_assignment":
      case "assign_consent":
        return <NoticeOfAssignment {...commonProps} />;
      case "transfer_certificate":
      case "transfer_consent":
        return <TransferCertificate {...commonProps} />;
      case "amendment":
        return <AmendmentLetter {...commonProps} />;
      case "none":
      default:
        return null;
    }
  };

  onShowIssueDetail = documentIssueId => {
    const panelDataIssueId = _.get(this.props.panelData, "data.issue.id");
    if (panelDataIssueId === documentIssueId) {
      return;
    }
    const issue = this.props.issues.find(
      issue => issue.document_issue_id === documentIssueId,
    );
    if (issue) {
      this.props.showIssueDetail(
        {
          ...issue,
          ...getIssueNames(issue),
        },
        true, // preventUsingCallback
        "create", // shownTab for Reports Tab
        this.props.selectedReportId,
      );
    }
  };

  triggerHighlightManualCorrections = () =>
    this.setState(prevState => ({
      highlightManualCorrections: !prevState.highlightManualCorrections,
    }));
  updateStateEditedReport = editedReport =>
    this.setState(() => ({editedReport}));
  startSelectedReportEditing = () =>
    this.updateStateEditedReport(_.cloneDeep(this.props.reportSettings));
  cancelSelectedReportEditing = () => this.updateStateEditedReport(null);

  onSaveEditedReport = () => {
    const {report_settings: documentReportSettings} = this.props.document;
    const documentReportSettingsIndex = documentReportSettings.findIndex(
      report => report.id === this.state.editedReport.id,
    );
    let newDocumentReportSettings;
    if (documentReportSettingsIndex !== -1) {
      newDocumentReportSettings = documentReportSettings.map(report =>
        report.id === this.state.editedReport.id
          ? this.state.editedReport
          : report,
      );
    } else {
      newDocumentReportSettings = [
        ...documentReportSettings,
        this.state.editedReport,
      ];
    }
    if (this.props.updateDocument) {
      this.props.updateDocument({report_settings: newDocumentReportSettings});
    }
    this.cancelSelectedReportEditing();
  };

  onRemoveExistingDocumentReportSettings = () => {
    const {report_settings: documentReportSettings} = this.props.document;
    const newDocumentReportSettings = documentReportSettings.filter(
      report => report.id !== this.state.editedReport.id,
    );
    if (this.props.updateDocument) {
      this.props.updateDocument({report_settings: newDocumentReportSettings});
    }
    this.cancelSelectedReportEditing();
  };
}

function mergeDocumentReportSettingsIntoProject(
  project,
  documentReportSettings,
) {
  const newProject = _.cloneDeep(project);
  documentReportSettings.forEach(report => {
    const projectReportSettingsIndex = newProject.report_settings.findIndex(
      reportSettings => report.id === reportSettings.id,
    );
    if (projectReportSettingsIndex === -1) {
      newProject.report_settings.push(report);
    } else {
      newProject.report_settings[projectReportSettingsIndex] = report;
    }
  });

  return newProject;
}

import React from "react";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as colors from "material-ui/styles/colors";

const units = {
  second: 1 / 24 / 60 / 60,
  minute: 1 / 24 / 60,
  hour: 1 / 24,
  day: 1,
  week: 7,
  fortnight: 14,
  month: 30,
  quarter: 30 * 3,
  year: 365,
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  unitContainer: {
    width: "10rem",
    margin: "0 0.5rem",
  },
  valueContainer: {
    width: "10rem",
    marginRight: "0.5rem",
    marginLeft: "4px",
  },
  unitSelectorLabel: {
    left: "10px",
  },
  deleteIcon: {
    cursor: "pointer",
    color: colors.grey700,
  },
};

class ValueEditor extends React.Component {
  render() {
    const {value} = this.props;
    return (
      <div style={styles.container}>
        <TextField
          type="number"
          value={value.original_value}
          onChange={this.onValueChange}
          floatingLabelText="Value"
          style={styles.valueContainer}
        />
        <SelectField
          floatingLabelText="Unit"
          value={value.unit}
          onChange={this.onUnitChange}
          autoWidth={true}
          style={styles.unitContainer}
          floatingLabelStyle={styles.unitSelectorLabel}
        >
          {Object.keys(units).map(unit => (
            <MenuItem key={unit} value={unit} primaryText={unit} />
          ))}
        </SelectField>
        <DeleteIcon
          onMouseDown={this.onDeleteValue}
          style={styles.deleteIcon}
        />
      </div>
    );
  }

  onUnitChange = (e, i, value) => {
    this.props.onUpdateValue(
      {
        ...this.props.value,
        unit: value,
        value: this.props.value.original_value * units[value],
      },
      this.props.valueIndex,
    );
  };

  onValueChange = e => {
    const {value} = e.target;
    this.props.onUpdateValue(
      {
        ...this.props.value,
        original_value: parseInt(value, 10),
        value: parseInt(value, 10) * units[this.props.value.unit],
      },
      this.props.valueIndex,
    );
  };

  onDeleteValue = this.props.onDeleteValue(this.props.valueIndex);
}

export default ValueEditor;

const tests = [
  {
    type: "main",
    testStrings: [
      {testString: "^(background)$"},
      {testString: "^(main)", isMain: true},
      {testString: "^(main_execution)$"},
    ],
  },
  {
    type: "not-main",
    testStrings: [
      {testString: "^(.*)_background$"},
      {testString: "^(.*(?!.*(background|execution)$).*)$", isMain: true},
    ],
  },
];

function getChecklistSections(sections) {
  const grouped = {};
  const usedSections = {};
  for (const section of sections) {
    const {reference, id} = section;
    for (const test of tests) {
      const {type, testStrings} = test;
      for (const testItem of testStrings) {
        const {testString, isMain} = testItem;
        const regExp = new RegExp(testString, "gi");
        const match = regExp.exec(reference);
        if (match && !usedSections[id]) {
          usedSections[id] = true;
          const resultSectionName = type === "main" ? "main" : match[1];
          const sectionData = {...section, isMain};
          grouped[resultSectionName] = grouped[resultSectionName]
            ? [...grouped[resultSectionName], sectionData]
            : [sectionData];
        }
      }
    }
  }
  const result = [];
  for (const sectionName of Object.keys(grouped)) {
    const groupedSections = grouped[sectionName];
    const mainSection = groupedSections.find(section => section.isMain);
    const resultSection = mainSection || groupedSections[0];
    result.push({
      id: resultSection.id,
      reference: sectionName,
      sections: groupedSections,
    });
  }
  return result;
}

export default getChecklistSections;

import escapeRegex from "utils/escape_regex";

function filterCapitalizedTermsByOtherTerms(definitions, otherTerms) {
  if (!definitions || definitions.length === 0) {
    return otherTerms;
  }

  const joinedDefinitions = definitions
    .map(item => `(^|\\W)${escapeRegex(item)}(s|es|'s|cs)?(\\W|$)`)
    .join("|");

  const definitionsRegex = new RegExp(
    joinedDefinitions,
    // `(^|\\W)${joinedDefinitions}(s|es|'s|’s|cs)?(\\W|$)`,
    "g",
  );
  return otherTerms.filter(term => !term.match(definitionsRegex));
}

export default filterCapitalizedTermsByOtherTerms;

interface Item {
  id: number;
}

interface FilterOption {
  type: string;
  handler: (item: Item) => boolean;
  resultLength?: number;
}

interface Filter {
  type: string;
  items: FilterOption[];
}

interface Filters {
  status: "all" | "issues_only" | "not_issue_only";
  parties: string[];
}

function filterItemsAndUpdateFilters(
  items: Item[],
  availableFilters: Filter[],
  filters: Filters,
): {filterListWithResultsLengths: Filter[]; filteredItems: Item[]} {
  const filtersMap = new Map(
    availableFilters.map(filter => [filter.type, filter]),
  );
  const statusFilter = filtersMap.get("status");

  if (!statusFilter) {
    throw new Error("Status filter configuration is missing.");
  }

  // Filter by status first
  const itemsFilteredByStatus = items.filter(item => {
    if (filters.status === "all") {
      return true;
    }
    const statusFilterOption = statusFilter.items.find(
      option => option.type === filters.status,
    );
    return statusFilterOption ? statusFilterOption.handler(item) : false;
  });

  // Creates list of filters along with the count of items that match each option
  const filterListWithResultsLengths = availableFilters.map(filter => {
    const isPartiesFilter =
      filter.type === "parties" && filters.status === "all";

    const filterOptions = filter.items
      .map(filterOption => {
        let result;
        if (filter.type === "parties") {
          result = itemsFilteredByStatus.filter(filterOption.handler);
        } else {
          result = items.filter(filterOption.handler);
        }
        return {...filterOption, resultLength: result.length};
      })
      // removes parties with 0 results when all status is selected
      .filter(
        filterOption => !isPartiesFilter || filterOption.resultLength > 0,
      );

    return {...filter, items: filterOptions};
  });

  // Final filtering based on all selected filter values
  const filteredItems = itemsFilteredByStatus.filter(item => {
    return availableFilters.every(filter => {
      const selectedFilterValue = filters[filter.type];
      if (
        !selectedFilterValue ||
        (Array.isArray(selectedFilterValue) && selectedFilterValue.length === 0)
      ) {
        return true;
      }
      if (filter.type === "parties" && Array.isArray(selectedFilterValue)) {
        return selectedFilterValue.some(selection =>
          filter.items.some(
            filterOption =>
              filterOption.type === selection && filterOption.handler(item),
          ),
        );
      } else {
        const filterOption = filter.items.find(
          option => option.type === selectedFilterValue,
        );
        return filterOption ? filterOption.handler(item) : true;
      }
    });
  });

  return {filterListWithResultsLengths, filteredItems};
}

export default filterItemsAndUpdateFilters;

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _ from "lodash";

const style = {
  position: "fixed",
  backgroundColor: "rgba(0, 0, 0, 0.7)",
  border: "1px solid black",
  zIndex: 11000,
  whiteSpace: "nowrap",
  pointerEvents: "none",
  borderRadius: 3,
  padding: "5px 8px",
  top: "50%",
  left: "50%",
  fontSize: 10,
  color: "#fff",
};

export default class FixedTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.showTimeoutId = null;
    this.hideTimeoutId = null;
    this.layer = null;
    this.contentRef = React.createRef();
  }

  componentWillUnmount() {
    this.reset();
  }

  componentDidUpdate() {
    this.applyPosition();
  }

  addEventListeners = () => {
    window.addEventListener("resize", this.reset);
    window.addEventListener("scroll", this.reset);
  };

  removeEventListeners = () => {
    window.removeEventListener("resize", this.reset);
    window.removeEventListener("scroll", this.reset);
  };

  clearTimeouts = () => {
    clearTimeout(this.showTimeoutId);
    clearTimeout(this.hideTimeoutId);
    this.showTimeoutId = null;
    this.hideTimeoutId = null;
  };

  handleMouseEnter = () => {
    this.clearTimeouts();
    if (this.props.showDelay) {
      this.showTimeoutId = setTimeout(this.show, this.props.showDelay);
    } else {
      this.show();
    }
  };

  handleMouseLeave = () => {
    this.clearTimeouts();
    if (this.props.hideDelay) {
      this.hideTimeoutId = setTimeout(this.hide, this.props.hideDelay);
    } else {
      this.hide();
    }
  };

  reset = () => {
    this.clearTimeouts();
    this.hide();
  };

  show = () => {
    this.hide();
    if (this.props.content) {
      this.layer = document.createElement("div");
      document.body.appendChild(this.layer);
      ReactDOM.unstable_renderSubtreeIntoContainer(
        this,
        <div style={{...style, ...this.props.style}}>{this.props.content}</div>,
        this.layer,
      );
      this.applyPosition();
      this.addEventListeners();
    }
  };

  hide = () => {
    if (this.layer) {
      ReactDOM.unmountComponentAtNode(this.layer);
      document.body.removeChild(this.layer);
      this.layer = null;
      this.removeEventListeners();
    }
  };

  getTooltipNode = () => {
    return _.get(this, "layer.children[0]");
  };

  applyPosition = () => {
    if (this.layer) {
      const tooltip = this.getTooltipNode();
      const content =
        this.contentRef.current instanceof Node
          ? this.contentRef.current
          : ReactDOM.findDOMNode(this.contentRef.current);
      if (tooltip && content) {
        const rect = content.getBoundingClientRect();
        const {position} = this.props;
        let top;
        let left;
        switch (position) {
          case "top":
          case "bottom":
            left = Math.round(
              rect.right - rect.width / 2 - tooltip.offsetWidth / 2,
            );
            if (position === "top") {
              top = Math.round(rect.top - tooltip.offsetHeight);
            } else {
              top = Math.round(rect.bottom);
            }
            break;
          case "left":
          case "right":
            top = Math.round(
              rect.bottom - rect.height / 2 - tooltip.offsetHeight / 2,
            );
            if (position === "left") {
              left = Math.round(rect.left - tooltip.offsetWidth);
            } else {
              left = Math.round(rect.right);
            }
            break;
        }
        tooltip.style.top = `${top}px`;
        tooltip.style.left = `${left}px`;
      }
    }
  };

  render() {
    const children = React.Children.only(this.props.children);
    return React.cloneElement(children, {
      ref: this.contentRef,
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    });
  }
}

FixedTooltip.defaultProps = {
  showDelay: 0,
  hideDelay: 0,
  position: "top",
};

FixedTooltip.propTypes = {
  showDelay: PropTypes.number.isRequired,
  hideDelay: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.element.isRequired,
  ]),
  style: PropTypes.object,
  position: PropTypes.oneOf(["top", "right", "bottom", "left"]),
};

import React from "react";

import LocalStorageContext from "./context";

export default function withLocalStorage(Component) {
  return props => (
    <LocalStorageContext.Consumer>
      {context => <Component localStorageContext={context} {...props} />}
    </LocalStorageContext.Consumer>
  );
}

import reasonViewerTopicparameter from "utils/issues/reason_viewer_topicparameter";

function getDefaultIssueRuleValues(rules) {
  return [
    {
      path: `topics.[${rules.topic_a.topic_id}].[${
        rules.topic_a.parameter_id
      }]`,
      value: "",
    },
  ];
}

export default {
  reasonViewer: reasonViewerTopicparameter,
  getDefaultIssueRuleValues,
};

import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function prerequisiteWorkflowsReducer(
  state = UNINITIALISED,
  action,
) {
  switch (action.type) {
    case ActionTypes.PREREQUISITE_WORKFLOWS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PREREQUISITE_WORKFLOWS_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import _ from "lodash";
import React, {useState} from "react";
import moment from "moment";

import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import ConfirmDialog from "common_components/confirm_dialog";
import GuiTextEditor from "common_components/gui_text_editor";
import convertContentsToText from "common/utils/contents/convert_contents_to_text";
import Permissioner from "utils/permissioner";
import getZoomedFontSize from "utils/get_zoomed_font_size";

import getContents from "../../utils/get_contents";
import getLanguage from "../../utils/get_language";
import IssueResponseTextField from "./issue_response_text_field";
import LanguageText from "../language_text";
import useStyles from "./styles";

export default function DraftingExample({
  isNewIssueResponse,
  issueResponse: initialIssueResponseFromParam,
  user,
  returnToList,
  saveIssueResponse,
  deleteIssueResponse,
  insertIssueResponseIntoDocument,
  hiddenFields,
  zoom,
}) {
  const initialIssueResponse = isNewIssueResponse
    ? getInitialIssueResponse()
    : initialIssueResponseFromParam;
  const [issueResponse, setIssueResponse] = useState(
    _.cloneDeep(initialIssueResponse),
  );

  const setIssueResponseField = fieldName => value =>
    setIssueResponse({
      ...issueResponse,
      [fieldName]: value,
    });

  const guiEditorUpdateHandler = (props, prevProps, state) => {
    if (state && state.contents) {
      const language = getLanguage(state.contents);
      setIssueResponse({
        ...issueResponse,
        language,
      });
    }
  };

  const [shouldShowDeleteDialog, showDeleteDialog] = useState(false);
  const toggleDeleteDialog = () => showDeleteDialog(!shouldShowDeleteDialog);

  const classes = useStyles();

  const {
    id,
    language,
    creation_date: creationDate,
    description,
    name,
    is_public: isPublic,
    predefined_comment: predefinedComment,
  } = issueResponse;
  const formattedCreationDate = moment(creationDate).format(
    "DD/MM/YYYY - HH:mm:ss",
  );
  const username = getUsername(issueResponse);

  const isAdmin = hasPermission(user, "edit-all-saved-clauses");
  const isOwner = user.id === issueResponse.user_id || isNewIssueResponse;
  const isNew = id === "new";

  const isEditable = isAdmin || isOwner || isNew;
  const shouldShowDeleteButton = isOwner && !isNewIssueResponse;
  const shouldShowInsertButton = Boolean(insertIssueResponseIntoDocument);
  const shouldHideDocumentText = Boolean(
    hiddenFields && hiddenFields.find(_name => _name === "document_text"),
  );

  const onSaveIssueResponse = () => {
    saveIssueResponse(issueResponse);
    returnToList();
  };
  const onCancel = () => {
    setIssueResponse(_.cloneDeep(initialIssueResponse));
    returnToList();
  };
  const onDelete = () => {
    deleteIssueResponse(issueResponse);
    returnToList();
  };
  const onInsert = () => {
    insertIssueResponseIntoDocument(issueResponse);
  };
  const shouldShowSaveButton =
    !_.isEqual(issueResponse, initialIssueResponse) &&
    (!isLanguageEmpty(issueResponse.language) ||
      (shouldHideDocumentText &&
        predefinedComment &&
        predefinedComment.length > 0));
  const canCreatePublic = hasPermission(
    user,
    "can-create-public-issue-response",
  );

  return (
    <>
      <div className={classes.wrapper}>
        <div
          className={classes.body}
          style={{fontSize: getZoomedFontSize(14, "checklist", zoom)}}
        >
          <IssueResponseTextField
            name="Name"
            value={name}
            id={id}
            editMode={true}
            disabled={!isEditable}
            onChange={e => setIssueResponseField("name")(e.target.value)}
          />
          <IssueResponseTextField
            name="Description"
            value={description}
            id={id}
            editMode={true}
            disabled={!isEditable}
            onChange={e => setIssueResponseField("description")(e.target.value)}
          />
          {shouldHideDocumentText || (
            <IssueResponseTextField
              name="Document Text"
              value={<LanguageText language={language} />}
              id={id}
              text={convertContentsToText(getContents(language), true)}
              showCopy={true}
              editMode={true}
              editor={
                <GuiTextEditor
                  contents={getContents(initialIssueResponse.language)}
                  updateHandler={guiEditorUpdateHandler}
                  disabled={!isEditable}
                  usedListFormatStylesPerLevel={[]}
                  skipSaveButton={true}
                  toolbarPosition="bottom"
                  placeholder="Type here"
                  showListButtonsOnly={true}
                />
              }
            />
          )}
          <IssueResponseTextField
            name="Comment (internal &/or external)"
            value={predefinedComment || ""}
            id={id}
            editMode={true}
            showCopy={true}
            onChange={e =>
              setIssueResponseField("predefined_comment")(e.target.value)
            }
          />
          <IssueResponseTextField
            name="Created"
            value={
              <>
                {username && <div>{username}</div>}
                <div>{formattedCreationDate}</div>
              </>
            }
            id={id}
          />
          {canCreatePublic && (
            <div className={classes.checkbox}>
              <FormControl>
                <RadioGroup
                  name="is_public"
                  value={isPublic ? "1" : "2"}
                  onChange={e =>
                    setIssueResponseField("is_public")(e.target.value === "1")
                  }
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Publicly viewable"
                    classes={{label: classes.label}}
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Private (only you can see this)"
                    classes={{label: classes.label}}
                  />
                </RadioGroup>
              </FormControl>
            </div>
          )}
          <div className={classes.buttonWrapper}>
            {shouldShowInsertButton && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                disabled={!isEditable}
                onClick={onInsert}
              >
                Insert
              </Button>
            )}
            {shouldShowDeleteButton && (
              <Button
                variant="outlined"
                className={classes.deleteButton}
                disabled={!isEditable}
                onClick={toggleDeleteDialog}
              >
                Delete
              </Button>
            )}
            <Button
              variant="outlined"
              className={classes.button}
              disabled={!isEditable}
              onClick={onCancel}
            >
              Cancel
            </Button>
            {shouldShowSaveButton && (
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                disabled={!isEditable}
                onClick={onSaveIssueResponse}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
      <ConfirmDialog
        open={shouldShowDeleteDialog}
        onSuccess={onDelete}
        onClose={toggleDeleteDialog}
        title="Delete Issue Response"
        description="Are you sure you want to delete this issue response?"
        okButtonCaption="Yes"
        cancelButtonCaption="No"
      />
    </>
  );
}

function getUsername(issueResponse) {
  const {username, first_name: firstName, last_name: lastName} = issueResponse;
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  return username;
}

function hasPermission(user, permission) {
  return new Permissioner(user).hasPermission(permission);
}

function isLanguageEmpty(language) {
  return (
    !language ||
    !Array.isArray(language) ||
    language.length === 0 ||
    (language.length === 1 && !language[0].text)
  );
}

function getInitialIssueResponse() {
  return {
    language: getLanguage({ops: []}),
    description: "",
    name: "New Issue Response",
    is_public: false,
    predefined_comment: "",
  };
}

import React from "react";
import {cloneDeep, get, memoize, set} from "lodash";

import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReplayIcon from "@material-ui/icons/Replay";
import * as colors from "@material-ui/core/colors";
import TextField from "material-ui/TextField";
import Toggle from "material-ui/Toggle";

import isBadRegex from "utils/bad_regexes";
import TopicparameterTextValueDiff from "./topicparameter_text_value_diff";
import getUpdatedColor from "./get_updated_color";
import DeleteModal from "routes/topic_analysis/components/delete_modal";

const styles = {
  textFieldContainer: {
    display: "flex",
    width: "48%",
    margin: "0rem 0rem 0.6rem 1rem",
  },
  regexTextFieldTextarea: {
    marginBottom: 0,
    marginTop: 0,
  },
  regexTextFieldHint: {
    bottom: "unset",
  },
  itemIndex: {
    marginTop: "2px",
    fontSize: "16px",
    width: "1.5rem",
    textAlign: "right",
    color: "#9e9e9e",
  },
  deleteIcon: {
    cursor: "pointer",
    color: "#616161",
    width: 24,
    marginLeft: 16,
  },
};

const baseOptions = ["whitelist", "blacklist"];

function initialise() {
  return {
    whitelist: [],
    blacklist: [],
    swallowPunctuation: true,
  };
}

function isRegexValid(regex) {
  try {
    new RegExp(regex);
    return true;
  } catch {
    return false;
  }
}

function validate(value) {
  let isValueValid = true;
  baseOptions.forEach(optionName => {
    value[optionName].forEach(item => {
      if (!isRegexValid(item.pre) || !isRegexValid(item.post)) {
        isValueValid = false;
      }
      const badPreRegex = isBadRegex(item.pre);
      if (badPreRegex) {
        // eslint-disable-next-line no-console
        console.log(
          "Regex is invalid because it had a potential problem in it - Regex:",
          item.pre,
          "Problem: matches",
          badPreRegex,
        );
        isValueValid = false;
      }
      const badPostRegex = isBadRegex(item.post);
      if (badPostRegex) {
        // eslint-disable-next-line no-console
        console.log(
          "Regex is invalid because it had a potential problem in it - Regex:",
          item.post,
          "Problem: matches",
          badPostRegex,
        );
        isValueValid = false;
      }
    });
  });
  return isValueValid;
}

class Renderer extends React.Component {
  state = {
    regexErrors: {},
    hoveredItemIndex: null,
    deleteDialogIsOpen: false,
    deleteValues: {},
  };

  render() {
    return (
      <Card>
        <CardHeader title="Parameter Values" style={{paddingBottom: 0}} />
        <CardContent>
          {this.renderFilters()}
          {this.renderExcludeValues()}
          {baseOptions.map((optionName, optionIndex) =>
            this.renderValue(optionName, optionIndex),
          )}
          <div
            style={{
              width: "20rem",
            }}
            title={
              "Determines whether to ignore only whitespace (toggle off), or both whitespace " +
              "and punctuation (toggle on) that appear between your regex and any value to extract." +
              "\n\n" +
              'Example: A duration parameter pre-regex "with a duration of" will work as follows:' +
              "\n" +
              '- Toggle ON:  Will extract BOTH "with a duration of 3 years" and "with a duration of: 3 years".\n' +
              '- Toggle OFF:  Will extract "with a duration of 3 years", but will NOT extract ' +
              '"with a duration of: 3 years".'
            }
          >
            <Toggle
              label="Ignore leading/trailing punctuation"
              toggled={this.props.value.swallowPunctuation}
              onToggle={() =>
                this.props.onChange(
                  {
                    ...this.props.value,
                    swallowPunctuation: !this.props.value.swallowPunctuation,
                  },
                  null,
                  null,
                )
              }
              style={{padding: "1rem 0rem"}}
            />
          </div>
        </CardContent>
        <DeleteModal
          isOpen={this.state.deleteDialogIsOpen}
          handleClose={this.handleClose}
          handleDelete={this.onValueItemDelete(
            this.state.deleteValues.optionName,
            this.state.deleteValues.itemIndex,
            this.state.deleteValues.realItemIndex,
          )}
        />
      </Card>
    );
  }

  renderValue = (optionName, optionIndex) => {
    const valueItems = (this.props.diffValue || this.props.value)[optionName];
    let realItemIndex = -1;
    return (
      <div key={optionIndex} style={{marginBottom: "1rem"}}>
        <div
          style={{
            marginBottom: "6px",
            fontWeight: 500,
          }}
        >
          {optionName}
        </div>
        <div>
          {valueItems.map((item, itemIndex) => {
            realItemIndex += 1;
            if (item.type && item.type === "deleted") {
              realItemIndex -= 1;
            }
            return this.renderValueItem(
              item,
              optionName,
              itemIndex,
              this.onValueItemUpdate(
                optionName,
                itemIndex,
                realItemIndex < 0 ? 0 : realItemIndex,
              ),
              realItemIndex < 0 ? 0 : realItemIndex,
            );
          })}
          {this.renderValueItem(
            {
              pre: "",
              post: "",
            },
            "",
            null,
            this.onValueItemAdd(
              optionName,
              valueItems.length - 1,
              realItemIndex < 0 ? 0 : realItemIndex,
            ),
          )}
        </div>
      </div>
    );
  };

  renderValueItem = (
    item,
    optionName,
    itemIndex,
    onHandleChange,
    realItemIndex,
  ) => {
    const {selectedRegex} = this.props;
    const basePath = `${optionName}_${itemIndex}`;
    const prePath = `${basePath}_pre`;
    const postPath = `${basePath}_post`;
    const regexText = `${item.pre || ""}.{1,100}${item.post || ""}`;
    const {type: diffType} = item;

    const isCurrentRegexSelected = regexText === selectedRegex;
    const isItemHovered = basePath === this.state.hoveredItemIndex;
    const selectPreRegex = () => this.props.onRegexSelect(regexText, item.post);
    return (
      <div key={itemIndex}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            opacity: !selectedRegex || isCurrentRegexSelected ? 1 : 0.3,
          }}
          onMouseEnter={this.onItemEnter(basePath)}
          onMouseLeave={this.onItemLeave(basePath)}
        >
          <div style={styles.itemIndex}>
            {itemIndex !== null ? `${itemIndex + 1}.` : ""}
          </div>
          <div style={styles.textFieldContainer}>
            <div
              style={{
                display: "flex",
                width: "100%",
              }}
            >
              <TextField
                ref={this.createPathRef(prePath)}
                hintText="PreRegex"
                errorText={this.state.regexErrors[prePath]}
                onChange={onHandleChange("pre")}
                onBlur={this.validateRegex(prePath)}
                value={item.pre}
                name="pre"
                multiLine={true}
                style={{
                  width: "100%",
                  backgroundColor: getUpdatedColor(
                    diffType,
                    item.live_pre,
                    item.pre,
                  ),
                }}
                textareaStyle={styles.regexTextFieldTextarea}
                hintStyle={styles.regexTextFieldHint}
                rows={parseInt(item.pre.length / 60, 10) + 1}
                disabled={diffType === "deleted"}
              />
              {item.live_pre !== undefined && item.live_pre !== item.pre ? (
                <TopicparameterTextValueDiff
                  value={item.pre}
                  liveValue={item.live_pre}
                  iconStyles={{
                    marginLeft: 2,
                    marginRight: 8,
                  }}
                />
              ) : (
                <div style={{width: 34}} />
              )}
            </div>
            {item.live_pre !== undefined && item.live_pre !== item.pre ? (
              <ReplayIcon
                onClick={this.onRevertItem(
                  optionName,
                  itemIndex,
                  realItemIndex,
                  "pre",
                )}
                style={{
                  cursor: "pointer",
                  width: 24,
                  marginRight: 8,
                  color: colors.grey[700],
                }}
              />
            ) : (
              <div
                style={{
                  width: 34,
                  flexShrink: 0,
                }}
              />
            )}
          </div>
          <div style={styles.textFieldContainer}>
            <TextField
              ref={this.createPathRef(postPath)}
              hintText="PostRegex"
              errorText={this.state.regexErrors[postPath]}
              onChange={onHandleChange("post")}
              onBlur={this.validateRegex(postPath)}
              value={item.post}
              name="post"
              multiLine={true}
              style={{
                width: "100%",
                backgroundColor: getUpdatedColor(
                  diffType,
                  item.live_post,
                  item.post,
                ),
              }}
              textareaStyle={styles.regexTextFieldTextarea}
              hintStyle={styles.regexTextFieldHint}
              rows={parseInt(item.post.length / 60, 10) + 1}
              disabled={diffType === "deleted"}
            />
            {item.live_post !== undefined && item.live_post !== item.post ? (
              <TopicparameterTextValueDiff
                value={item.post}
                liveValue={item.live_post}
                iconStyles={{
                  marginLeft: 2,
                  marginRight: 8,
                }}
              />
            ) : (
              <div
                style={{
                  width: 34,
                  flexShrink: 0,
                }}
              />
            )}
            {this.renderRightRevertAction(
              item,
              diffType,
              optionName,
              itemIndex,
              realItemIndex,
            )}
            {(isCurrentRegexSelected || isItemHovered) &&
            (item.pre || item.post) ? (
              <VisibilityIcon
                style={{
                  cursor: "pointer",
                  color: isCurrentRegexSelected
                    ? colors.grey[700]
                    : isItemHovered
                    ? colors.grey[400]
                    : "inherit",
                  width: 24,
                  flexShrink: 0,
                }}
                onClick={selectPreRegex}
              />
            ) : (
              <div
                style={{
                  width: 24,
                  flexShrink: 0,
                }}
              />
            )}
          </div>
          {itemIndex !== null && diffType !== "deleted" ? (
            <DeleteIcon
              onClick={() =>
                this.handleOpen(optionName, itemIndex, realItemIndex)
              }
              style={styles.deleteIcon}
            />
          ) : (
            <div
              style={{
                width: styles.deleteIcon.width + styles.deleteIcon.marginLeft,
              }}
            />
          )}
        </div>
      </div>
    );
  };

  renderExcludeValues() {
    return null;
  }

  renderFilters() {
    return null;
  }

  renderRightRevertAction = (
    item,
    diffType,
    optionName,
    itemIndex,
    realItemIndex,
  ) => {
    let shouldRenderIcon = false;
    let handler;
    if (item.live_post !== undefined && item.live_post !== item.post) {
      shouldRenderIcon = true;
      handler = this.onRevertItem(optionName, itemIndex, realItemIndex, "post");
    } else if (diffType === "deleted") {
      shouldRenderIcon = true;
      handler = this.onRevertDelete(optionName, itemIndex, realItemIndex);
    }

    return shouldRenderIcon ? (
      <ReplayIcon
        onClick={handler}
        style={{
          cursor: "pointer",
          width: 24,
          color: colors.grey[700],
          marginRight: 8,
        }}
      />
    ) : (
      <div
        style={{
          width: 34,
          flexShrink: 0,
        }}
      />
    );
  };

  handleOpen = (optionName, itemIndex, realItemIndex) => {
    this.setState(() => ({
      deleteDialogIsOpen: true,
      deleteValues: {
        optionName,
        itemIndex,
        realItemIndex,
      },
    }));
  };

  handleClose = () => {
    this.setState(() => ({deleteDialogIsOpen: false}));
  };

  onValueItemAdd = (optionName, lastItemIndex) => itemName => e => {
    const newValue = cloneDeep(this.props.value);
    const newItem = {
      [itemName]: e.target.value,
      [itemName === "pre" ? "post" : "pre"]: "",
    };
    newValue[optionName].push(newItem);

    const newDiffValue = cloneDeep(this.props.diffValue || this.props.value);
    newDiffValue[optionName].push({
      ...newItem,
      type: "added",
    });

    this.props.onChange(
      newValue,
      () => this[`${optionName}_${lastItemIndex + 1}_${itemName}`].focus(),
      newDiffValue,
    );
    e.target.value = null;
  };

  onValueItemUpdate = (
    optionName,
    itemIndex,
    realItemIndex,
  ) => itemName => e => {
    const targetValue = e.target.value;
    const newValue = cloneDeep(this.props.value);
    newValue[optionName][realItemIndex][itemName] = targetValue;

    const newDiffValue = cloneDeep(this.props.diffValue || this.props.value);
    const liveItem = this.props.value[optionName][realItemIndex];
    const liveValue = liveItem[`live_${itemName}`] || liveItem[itemName];

    if (
      !newDiffValue[optionName][itemIndex].type &&
      newDiffValue[optionName][itemIndex][`live_${itemName}`] === undefined
    ) {
      newDiffValue[optionName][itemIndex].type = "updated";
      newDiffValue[optionName][itemIndex][`live_${itemName}`] = liveValue;
    }

    newDiffValue[optionName][itemIndex][itemName] = targetValue;
    if (newDiffValue[optionName][itemIndex].type === "updated") {
      if (
        newDiffValue[optionName][itemIndex][itemName] ===
        newDiffValue[optionName][itemIndex][`live_${itemName}`]
      ) {
        delete newDiffValue[optionName][itemIndex][`live_${itemName}`];
      } else if (
        newDiffValue[optionName][itemIndex][`live_${itemName}`] === undefined
      ) {
        newDiffValue[optionName][itemIndex][`live_${itemName}`] = liveValue;
      }
    }

    this.props.onChange(newValue, null, newDiffValue);
  };

  onValueItemDelete = (optionName, index, realItemIndex) => () => {
    const newValue = cloneDeep(this.props.value);
    newValue[optionName].splice(realItemIndex, 1);

    const newDiffValue = cloneDeep(this.props.diffValue || this.props.value);
    const diffValueItem = newDiffValue[optionName][index];
    if (diffValueItem.type === "added") {
      newDiffValue[optionName].splice(index, 1);
    } else if (diffValueItem.type === "updated") {
      Object.keys(newDiffValue[optionName][index]).forEach(key => {
        if (key.startsWith("live_")) {
          const keyName = key.slice(5);
          newDiffValue[optionName][index][keyName] =
            newDiffValue[optionName][index][key];
          delete newDiffValue[optionName][index][key];
        }
      });
      diffValueItem.type = "deleted";
    } else if (!diffValueItem.type) {
      diffValueItem.type = "deleted";
    }
    this.props.onChange(newValue, null, newDiffValue);
  };

  onRevertDelete = (optionName, itemIndex, realItemIndex) => () => {
    const newDiffValue = cloneDeep(this.props.diffValue || this.props.value);
    delete newDiffValue[optionName][itemIndex].type;
    const newValue = cloneDeep(this.props.value);
    newValue[optionName][realItemIndex] = newDiffValue[optionName][itemIndex];
    this.props.onChange(newValue, null, newDiffValue);
  };

  onRevertItem = (optionName, itemIndex, realItemIndex, regexType) => () => {
    const newValue = cloneDeep(this.props.value);
    const newDiffValue = cloneDeep(this.props.diffValue || this.props.value);

    const newValueValue = get(newDiffValue, [
      optionName,
      realItemIndex,
      `live_${regexType}`,
    ]);

    set(newValue, [optionName, realItemIndex, regexType], newValueValue);
    set(newDiffValue, [optionName, itemIndex, regexType], newValueValue);
    delete newDiffValue[optionName][itemIndex][`live_${regexType}`];
    if (
      !get(newDiffValue, [
        optionName,
        itemIndex,
        `live_${regexType === "pre" ? "post" : "pre"}`,
      ])
    ) {
      delete newDiffValue[optionName][itemIndex].type;
    }
    this.props.onChange(newValue, null, newDiffValue);
  };

  validateRegex = itemPath => e => {
    const regex = e.target.value;
    try {
      new RegExp(regex);
      this.setState(prevState => ({
        regexErrors: {
          ...prevState.regexErrors,
          [itemPath]: null,
        },
      }));
    } catch {
      this.setState(prevState => ({
        regexErrors: {
          ...prevState.regexErrors,
          [itemPath]: "Bad regex",
        },
      }));
      return false;
    }

    const badRegex = isBadRegex(regex);
    if (badRegex) {
      // eslint-disable-next-line no-console
      console.log(
        "Regex is invalid because it had a potential problem in it - Regex:",
        regex,
        "Problem: matches",
        badRegex,
      );
      this.setState(prevState => ({
        regexErrors: {
          ...prevState.regexErrors,
          [itemPath]: `Matches bad regex: ${badRegex.toString()}`,
        },
      }));
      return false;
    }

    return true;
  };

  createPathRef = path => node => (this[path] = node);

  onItemEnter = memoize(
    index => () => this.setState(() => ({hoveredItemIndex: index})),
    (...args) => JSON.stringify([...args]),
  );
  onItemLeave = memoize(
    () => () => this.setState(() => ({hoveredItemIndex: null})),
    (...args) => JSON.stringify([...args]),
  );
}

export default {
  initialise,
  validate,
  renderer: Renderer,
};

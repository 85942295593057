import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import actionTypes from "../constants/action_types";
import {WorkflowTask} from "common/flowmaster/types/task_config";
import {
  Workflow,
  WorkflowId,
  WorkflowInput,
  WorkflowInstance,
} from "common/flowmaster/types/workflow";
import {WorkflowFetchAction} from "../actions/workflow_fetch";
import {WorkflowUpdateAction} from "../actions/workflow_update";
import {WorkflowInputAddAction} from "../actions/workflow_input_add";
import {WorkflowInputUpdateAction} from "../actions/workflow_input_update";
import {WorkflowInputRemoveAction} from "../actions/workflow_input_remove";
import {WorkflowInputReorderAction} from "../actions/workflow_input_reorder";
import {WorkflowTaskAddAction} from "../actions/workflow_task_add";
import {WorkflowTaskUpdateAction} from "../actions/workflow_task_update";
import {WorkflowTaskRemoveAction} from "../actions/workflow_task_remove";
import {WorkflowTaskReorderAction} from "../actions/workflow_task_reorder";
import {WorkflowPrerequisiteAddAction} from "../actions/workflow_prerequisite_add";
import {WorkflowPrerequisiteRemoveAction} from "../actions/workflow_prerequisite_remove";
import {WorkflowPrerequisiteUpdateInputValueAction} from "../actions/workflow_prerequisite_update_input_value";
import {WorkflowPrerequisiteFetchAction} from "../actions/workflow_prerequisite_fetch";
import {WorkflowInstanceUpdateNameAction} from "../actions/workflow_instance_update_name";

export default function workflowReducer(
  state: MaybeUninitialised<Workflow> = UNINITIALISED,
  action:
    | WorkflowFetchAction
    | WorkflowUpdateAction
    | WorkflowInputAddAction
    | WorkflowInputUpdateAction
    | WorkflowInputRemoveAction
    | WorkflowInputReorderAction
    | WorkflowPrerequisiteAddAction
    | WorkflowPrerequisiteFetchAction
    | WorkflowPrerequisiteRemoveAction
    | WorkflowPrerequisiteUpdateInputValueAction
    | WorkflowInstanceUpdateNameAction
    | WorkflowTaskAddAction
    | WorkflowTaskUpdateAction
    | WorkflowTaskRemoveAction
    | WorkflowTaskReorderAction,
): typeof state {
  if (
    (action.type === actionTypes.WORKFLOW_FETCH.SUCCESS) |
    (action.type === actionTypes.WORKFLOW_UPDATE.SUCCESS)
  ) {
    return action.payload;
  }
  if (action.type === actionTypes.WORKFLOW_INPUT_ADD.SUCCESS) {
    const newWorkflowInput = action.payload as WorkflowInput;
    if (isInitialised([state]) && state.id === newWorkflowInput.workflow_id) {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          values: state.inputs.values.concat([newWorkflowInput]),
        },
      };
    }
    return state;
  }
  if (action.type === actionTypes.WORKFLOW_INPUT_UPDATE.SUCCESS) {
    const newWorkflowInput = action.payload as WorkflowInput;
    if (isInitialised([state]) && state.id === newWorkflowInput.workflow_id) {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          values: state.inputs.values.map(input =>
            input.id === newWorkflowInput.id ? newWorkflowInput : input,
          ),
        },
      };
    }
    return state;
  }
  if (action.type === actionTypes.WORKFLOW_INPUT_REMOVE.SUCCESS) {
    const removeAction = action as WorkflowInputRemoveAction;
    if (
      isInitialised([state]) &&
      state.id === removeAction.payload.workflow_id
    ) {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          values: state.inputs.values.filter(
            input => input.id !== removeAction.payload.id,
          ),
        },
      };
    }
    return state;
  }
  if (action.type === actionTypes.WORKFLOW_INPUT_REORDER.SUCCESS) {
    const reorderAction = action as WorkflowInputReorderAction;
    if (
      isInitialised([state]) &&
      state.id === reorderAction.payload[0].workflow_id
    ) {
      return {
        ...state,
        inputs: {
          ...state.inputs,
          values: reorderAction.payload,
        },
      };
    }
    return state;
  }

  if (
    action.type === actionTypes.WORKFLOW_PREREQUISITE_ADD.SUCCESS ||
    action.type === actionTypes.WORKFLOW_PREREQUISITE_REMOVE.SUCCESS ||
    action.type ===
      actionTypes.WORKFLOW_PREREQUISITE_UPDATE_INPUT_VALUE.SUCCESS ||
    action.type === actionTypes.WORKFLOW_INSTANCE_UPDATE_NAME.SUCCESS
  ) {
    const addPrerequisiteAction = action.payload as {
      workflow_id: WorkflowId;
      prerequisites: WorkflowInstance[];
    };
    if (
      isInitialised([state]) &&
      state.id === addPrerequisiteAction.workflow_id
    ) {
      return {
        ...state,
        prerequisites: addPrerequisiteAction.prerequisites,
      };
    }
    return state;
  }
  if (
    action.type === actionTypes.WORKFLOW_TASK_ADD.SUCCESS ||
    action.type === actionTypes.WORKFLOW_TASK_UPDATE.SUCCESS ||
    action.type === actionTypes.WORKFLOW_TASK_REMOVE.SUCCESS ||
    action.type === actionTypes.WORKFLOW_TASK_REORDER.SUCCESS
  ) {
    const newWorkflowTasks = action.payload as {
      workflow_id: WorkflowId;
      tasks: WorkflowTask[];
    };
    if (isInitialised([state]) && state.id === newWorkflowTasks.workflow_id) {
      return {
        ...state,
        tasks: {
          ...state.tasks,
          values: Object.fromEntries(
            newWorkflowTasks.tasks.map(item => [item.id, item]),
          ),
        },
      };
    }
    return state;
  }
  return state;
}

export default function addIsSectionUnnumberedToClauses(
  clauses = [],
  sections = [],
  documentClauses = {},
) {
  return clauses.map(rawClause => {
    const {clause_section_id: clauseSectionId} = rawClause;
    const section = sections.find(section => section.id === clauseSectionId);
    const clauseIndex = documentClauses[section.id].findIndex(
      item => item.id === rawClause.clause_id,
    );
    return {
      ...rawClause,
      is_section_unnumbered: section && section.is_unnumbered,
      section_order: section?.order,
      clause_order_in_section: clauseIndex + 1,
    };
  });
}

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

import rateLimit, {getStandardTimeout} from "utils/rate_limit";
import {createChangeTimeWrapper} from "utils/ensure_fresh_update";

function doSave(
  requestor,
  organisationId,
  projectId,
  documentId,
  data,
  changeTime,
) {
  return requestor
    .patch(
      `${basePath}/organisation/${organisationId}/project/${projectId}` +
        `/document/${documentId}`,
      data,
      {withCredentials: true},
    )
    .then(response => ({
      ...createChangeTimeWrapper(changeTime),
      id: documentId,
      ...response.data,
    }));
}

const save = rateLimit(doSave, getStandardTimeout());

export default function updateDocument(requestor) {
  const action = createReduxPromiseAction(
    ActionTypes.DOCUMENT_UPDATE,
    (organisationId, projectId, documentId, data, onlyRequest, changeTime) => {
      if (!data.last_edited) {
        throw new Error("data must contain a last_edited key");
      }
      if (!onlyRequest) {
        return save(
          requestor,
          organisationId,
          projectId,
          documentId,
          data,
          changeTime,
        );
      }
      return new Promise(() => {});
    },
    (organisationId, projectId, documentId, data, changeTime) => ({
      ...createChangeTimeWrapper(changeTime),
      id: documentId,
      ...data,
    }),
  );

  return action;
}

import _ from "underscore";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import requestor from "requestor";
import getDomain from "utils/get_domain";
import userActionTypes from "modules/user/constants/action_types";
import projectActionTypes from "modules/projects/constants/action_types";
import documentActionTypes from "modules/documents/constants/action_types";
import templateModulesActionTypes from "modules/template_modules/constants/action_types";
import documentFetchAction from "modules/documents/actions/document_fetch";
import fetchIssueComparisonDataAction from "modules/documents/actions/issue_comparison_data_fetch";
import ReconnectingWebSocket from "bundled/reconnecting-websocket";
import {isInitialised} from "utils/uninitialised";

import Action from "common/actions/realtime";

import RealtimeAlerts from "../components/realtime_alerts";

const rerouteOnUpload = false;

class Realtime extends React.Component {
  /* eslint-disable max-statements */
  constructor(props) {
    super(props);
    this.props = props;
    this.eventWhitelist = {};
    this.eventWhitelist[Action.PROJECT_ADD] =
      projectActionTypes.PROJECT_CREATE.SUCCESS;
    this.eventWhitelist[Action.PROJECT_UPDATE] =
      projectActionTypes.PROJECT_UPDATE.SUCCESS;
    this.eventWhitelist[Action.PROJECT_DELETE] =
      projectActionTypes.PROJECT_DELETE.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_UPLOAD] =
      documentActionTypes.DOCUMENT_UPLOAD.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_PARSE] =
      documentActionTypes.DOCUMENT_PARSE.SUCCESS;
    this.eventWhitelist[Action.FORM_TEMPLATE_UPLOAD] =
      documentActionTypes.FORM_TEMPLATE_UPLOAD.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_UPDATE] =
      documentActionTypes.DOCUMENT_UPDATE.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_DELETE] =
      documentActionTypes.DOCUMENT_DELETE.SUCCESS;
    this.eventWhitelist[Action.CLAUSE_CLASSIFICATION] =
      documentActionTypes.DOCUMENT_CLAUSE_CLASSIFICATION.SUCCESS;
    this.eventWhitelist[Action.CLAUSE_TOPIC_PARAMETER_CLASSIFICATION] = {
      type:
        documentActionTypes.DOCUMENT_CLAUSE_TOPIC_PARAMETER_CLASSIFICATION
          .SUCCESS,
      ignored_keys: ["topicId"],
    };
    this.eventWhitelist[Action.TOPICPARAMETER_BULK_CLASSIFICATION] =
      documentActionTypes.TOPICPARAMETER_BULK_CLASSIFICATION.SUCCESS;
    this.eventWhitelist[Action.CLAUSE_QUALIFICATION] =
      documentActionTypes.DOCUMENT_CLAUSE_QUALIFICATION.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_CLAUSE_TOPIC_ADD] =
      documentActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD.SUCCESS;
    this.eventWhitelist[Action.DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE] =
      documentActionTypes.DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_CLAUSEPART_UPDATE] =
      documentActionTypes.DOCUMENT_CLAUSEPART_UPDATE.SUCCESS;
    this.eventWhitelist[Action.CLAUSES_TOPIC_ADD] =
      documentActionTypes.CLAUSES_TOPIC_ADD.SUCCESS;
    this.eventWhitelist[Action.CLAUSES_TOPIC_REMOVE] =
      documentActionTypes.CLAUSES_TOPIC_REMOVE.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_CLAUSE_UNCONFIRMED_TOPICS_REMOVE] =
      documentActionTypes.DOCUMENT_CLAUSE_UNCONFIRMED_TOPICS_REMOVE.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_CLAUSE_TOPIC_REMOVE] = {
      type: documentActionTypes.DOCUMENT_CLAUSE_TOPIC_REMOVE.SUCCESS,
      ignored_keys: ["topicId"],
    };
    this.eventWhitelist[Action.DOCUMENT_CLAUSE_TOPIC_CONFIRM] =
      documentActionTypes.DOCUMENT_CLAUSE_TOPIC_CONFIRM.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_TOPICS_CONFIRM_ALL] =
      documentActionTypes.DOCUMENT_TOPICS_CONFIRM_ALL.SUCCESS;

    this.eventWhitelist[Action.TOPIC_UPDATE] = {
      type: documentActionTypes.TOPIC_UPDATE.SUCCESS,
      ignored_keys: ["topicId"],
    };
    this.eventWhitelist[Action.TOPIC_DELETE] = {
      type: documentActionTypes.TOPIC_DELETE.SUCCESS,
      ignored_keys: ["topicId"],
    };
    this.eventWhitelist[Action.TOPICPARAMETER_ADD] = {
      type: documentActionTypes.TOPICPARAMETER_ADD.SUCCESS,
      ignored_keys: ["topicId"],
    };
    this.eventWhitelist[Action.TOPICPARAMETER_UPDATE] = {
      type: documentActionTypes.TOPICPARAMETER_UPDATE.SUCCESS,
      ignored_keys: ["topicId"],
    };
    this.eventWhitelist[Action.TOPICPARAMETER_REMOVE] = {
      type: documentActionTypes.TOPICPARAMETER_DELETE.SUCCESS,
      ignored_keys: ["topicId"],
    };

    this.eventWhitelist[Action.TOPICPARAMETER_ACTUAL_VALUES_UPDATE] =
      documentActionTypes.TOPICPARAMETER_ACTUAL_VALUES_UPDATE.SUCCESS;

    this.eventWhitelist[Action.TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE] =
      documentActionTypes.TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE.SUCCESS;

    this.eventWhitelist[Action.TOPICPARAMETERS_PROCESS] =
      documentActionTypes.TOPICPARAMETERS_PROCESS.SUCCESS;

    this.eventWhitelist[Action.TOPIC_CATEGORY_UPDATE] =
      documentActionTypes.TOPIC_CATEGORY_UPDATE.SUCCESS;

    this.eventWhitelist[Action.TOPIC_CLASSIFIER_UPDATE] =
      documentActionTypes.TOPIC_CLASSIFIER_UPDATE.SUCCESS;

    this.eventWhitelist[Action.CLASSIFIER_GENERATED] =
      documentActionTypes.CLASSIFIER_GENERATED.SUCCESS;

    this.eventWhitelist[Action.DOCUMENT_ISSUES_FETCH] =
      documentActionTypes.DOCUMENT_ISSUES_REFETCH.SUCCESS;

    this.eventWhitelist[Action.DOCUMENT_ISSUES_COMPLETE] =
      documentActionTypes.DOCUMENT_ISSUES_COMPLETE.SUCCESS;

    this.eventWhitelist[Action.ISSUE_ADD] =
      documentActionTypes.ISSUE_ADD.SUCCESS;
    this.eventWhitelist[Action.ISSUE_UPDATE] =
      documentActionTypes.ISSUE_UPDATE.SUCCESS;
    this.eventWhitelist[Action.ISSUE_REMOVE] =
      documentActionTypes.ISSUE_REMOVE.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_ISSUE_UPDATE] =
      documentActionTypes.DOCUMENT_ISSUE_UPDATE.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_ISSUES_UPDATE] =
      documentActionTypes.DOCUMENT_ISSUES_UPDATE.SUCCESS;
    this.eventWhitelist[Action.DOCUMENT_STATE_REVERT] =
      documentActionTypes.DOCUMENT_STATE_REVERT.SUCCESS;

    this.eventWhitelist[Action.CLASSIFIER_RUN_STATE_UPDATE] =
      documentActionTypes.CLASSIFIER_RUN_STATE_UPDATE.SUCCESS;
    this.eventWhitelist[Action.TOPIC_CLASSIFIER_LOCK] =
      documentActionTypes.TOPIC_CLASSIFIER_LOCK.SUCCESS;
    this.eventWhitelist[Action.TEMPLATE_MODULE_CREATE] =
      templateModulesActionTypes.TEMPLATE_MODULE_CREATE.SUCCESS;
    this.eventWhitelist[Action.TEMPLATE_MODULE_UPDATE] =
      templateModulesActionTypes.TEMPLATE_MODULE_UPDATE.SUCCESS;
    this.eventWhitelist[Action.CONTRACT_TYPE_ADD] =
      documentActionTypes.CONTRACT_TYPE_ADD.SUCCESS;
    this.eventWhitelist[Action.CONTRACT_TYPE_UPDATE] =
      documentActionTypes.CONTRACT_TYPE_UPDATE.SUCCESS;
    this.eventWhitelist[Action.CONTRACT_TYPE_REMOVE] =
      documentActionTypes.CONTRACT_TYPE_REMOVE.SUCCESS;

    this.eventWhitelist[Action.ISSUESET_ADD] =
      documentActionTypes.ISSUESET_ADD.SUCCESS;
    this.eventWhitelist[Action.ISSUESET_UPDATE] =
      documentActionTypes.ISSUESET_UPDATE.SUCCESS;
    this.eventWhitelist[Action.ISSUESET_REMOVE] =
      documentActionTypes.ISSUESET_REMOVE.SUCCESS;

    this.eventWhitelist[Action.DEFINITION_GROUP_ADD] =
      documentActionTypes.DEFINITION_GROUP_ADD.SUCCESS;
    this.eventWhitelist[Action.DEFINITION_GROUP_UPDATE] =
      documentActionTypes.DEFINITION_GROUP_UPDATE.SUCCESS;
    this.eventWhitelist[Action.DEFINITION_GROUP_REMOVE] =
      documentActionTypes.DEFINITION_GROUP_REMOVE.SUCCESS;
    this.eventWhitelist[Action.FIND_TOPIC_REGEX_FPS] =
      documentActionTypes.CLASSIFIER_RUN_BATCH_ADD.SUCCESS;

    this.eventWhitelist[Action.ROLE_ADD] = documentActionTypes.ROLE_ADD.SUCCESS;
    this.eventWhitelist[Action.ROLE_UPDATE] =
      documentActionTypes.ROLE_UPDATE.SUCCESS;
    this.eventWhitelist[Action.ROLE_REMOVE] =
      documentActionTypes.ROLE_REMOVE.SUCCESS;
  }
  /* eslint-enable max-statements */

  render() {
    const dontRender = false;
    if (dontRender && this.shouldRender()) {
      return (
        <RealtimeAlerts
          document={this.getMostRecentDocument(this.props.documents)}
        />
      );
    }
    return <div />;
  }

  shouldRender() {
    return isInitialised([this.props.documents]);
  }

  getMostRecentDocument(documents) {
    let maxDate = new Date(0);
    let mostRecentDocument;
    documents.forEach(document => {
      if (document.creation_date > maxDate) {
        maxDate = document.creation_date;
        mostRecentDocument = document;
      }
    });
    return mostRecentDocument;
  }

  componentDidMount() {
    this.initialiseWebSocket(this.props.environment);
  }

  initialiseWebSocket(environment) {
    this.socket = this.createWebSocket(environment);
    this.socket.onopen = this.handleOnOpen;
    this.socket.onmessage = this.handleEvent;
  }

  createWebSocket(environment) {
    const protocol = `ws${
      environment === "production" || environment === "public" ? "s" : ""
    }`;
    const hostname =
      environment === "production"
        ? `api.${getDomain()}.com`
        : document.location.hostname;
    const port = environment === "production" ? "" : ":5010";
    return new this.props.WebSocket(`${protocol}://${hostname}${port}/ws`);
  }

  /* eslint-disable no-invalid-this */
  handleOnOpen = (/* event */) => {
    const {organisationId: organisationIdStr} = this.props.params;
    const organisationId = parseInt(organisationIdStr, 10);
    this.socket.send(
      JSON.stringify({
        type: "init",
        organisation_id: organisationId,
      }),
    );
  };

  handleEvent = event => {
    const data = JSON.parse(event.data);
    switch (data.type) {
      case Action.INIT_SUCCESS: {
        const action = this.createInitSuccessAction(data);
        this.props.dispatch(action);
        break;
      }
      case Action.DOCUMENT_ISSUES_FETCH: {
        const action = this.createDocumentIssueFetchAction(data);
        if (action) {
          this.props.dispatch(action);
        }
        break;
      }
      case Action.DOCUMENT_ISSUES_COMPLETE: {
        const {
          organisation_id: organisationId,
          project_id: projectId,
          document_id: documentId,
        } = data;

        const {issueComparisonData} = this.props;

        if (
          organisationId &&
          projectId &&
          documentId &&
          (this.onDocumentList() || this.onDocumentPage(documentId))
        ) {
          this.props.fetchDocument(
            this.props.params.organisationId,
            projectId,
            documentId,
            {fetch_only_document_issues: true},
          );
        } else if (
          organisationId &&
          projectId &&
          documentId &&
          issueComparisonData &&
          issueComparisonData.document_id === documentId
        ) {
          this.props.fetchIssueComparisonData(
            this.props.params.organisationId,
            projectId,
            documentId,
          );
        }
        break;
      }
      case Action.TOPICS_UPDATE: {
        const action = this.createBulkUpdateAction(
          documentActionTypes.TOPICS_UPDATE.SUCCESS,
          data.topics,
        );
        this.props.dispatch(action);
        break;
      }
      case Action.ISSUES_UPDATE: {
        const action = this.createBulkUpdateAction(
          documentActionTypes.ISSUES_UPDATE.SUCCESS,
          data.issues,
        );
        this.props.dispatch(action);
        break;
      }
      default: {
        const actionType = this.eventWhitelist[data.type];
        if (actionType) {
          const action = this.createActionHandler(actionType)(data);
          if (action !== null) {
            this.props.dispatch(action);
          }
        }
      }
    }
  };
  /* eslint-enable no-invalid-this */

  createInitSuccessAction(data) {
    return {
      type: userActionTypes.WEBSOCKET_INIT.SUCCESS,
      payload: _.omit(data, "type"),
    };
  }

  onDocumentList = () =>
    this.props.location.pathname.indexOf("document/list") > 0;

  onDocumentPage = documentId => {
    if (!documentId) {
      return false;
    }
    const regex = new RegExp(`document[\/=]${documentId}`);
    const {pathname, search} = this.props.location;
    return regex.test(`${pathname}${search}`);
  };

  createDocumentIssueFetchAction(data) {
    const onDocumentList = this.onDocumentList();
    const onDocumentPage = this.onDocumentPage(data.document_id);

    const type =
      onDocumentPage || onDocumentList ? " " : "DOCUMENT_ISSUES_REFETCH";
    if (
      type === "DOCUMENT_ISSUES_REFETCH" &&
      this.props.location.pathname.match("document/d+")
    ) {
      return null;
    }

    const result = {type: documentActionTypes[type].SUCCESS, payload: data};
    if (rerouteOnUpload && onDocumentList) {
      result.meta = {
        transition: (oldState, newState, _action) => {
          const {
            payload: {
              organisation_id: organisationId,
              project_id: projectId,
              document_id: documentId,
              user_id: userId,
              connection_id: connectionId,
            },
          } = _action;
          const documentPath = [
            "/organisation",
            organisationId,
            "project",
            projectId,
            "document",
          ].join("/");
          const documentListPath = `${documentPath}/list`;
          if (
            userId === this.props.user.id &&
            connectionId === this.props.websocket.connection_id &&
            documentListPath ===
              oldState.router.locationBeforeTransitions.pathname
          ) {
            const pathname = `${documentPath}/${documentId}/detail`;
            return {pathname};
          }
          return null;
        },
      };
    }
    return result;
  }

  createBulkUpdateAction(type, payload) {
    return {type, payload};
  }

  createActionHandler(type) {
    return data => {
      const match = key => {
        const otherKey = key.replace(/Id/, "_id");
        const dataValue = data[key] || data[otherKey];
        return (
          !dataValue ||
          parseInt(this.props.params[key], 10) === parseInt(dataValue, 10)
        );
      };
      const matchesPath = Object.keys(
        _.omit(this.props.params, type.ignored_keys),
      ).every(match);
      if (matchesPath) {
        // const isCurrent = Object.keys(this.props.params).every(match);
        return {
          type: type.type ? type.type : type,
          payload: _.omit(data, "type"),
          ...(type.meta ? {meta: type.meta} : {}),
        };
      }
      return null;
    };
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  }
}

function select(state) {
  return {
    environment: state.environment,
    user: state.user,
    websocket: state.websocket,
    documents: state.documents,
    issueComparisonData: state.issueComparisonData,
    WebSocket: ReconnectingWebSocket,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchDocument: documentFetchAction(requestor),
        fetchIssueComparisonData: fetchIssueComparisonDataAction(requestor),
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(Realtime);
export const Component = Realtime;

import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";

import UNINITIALISED from "utils/uninitialised";
import requestor from "requestor";
import issueResponseAdd from "modules/documents/actions/issue_response_add";
import issueResponseDelete from "modules/documents/actions/issue_response_delete";
import issueResponseSave from "modules/documents/actions/issue_response_save";
import issueCommentAdd from "modules/documents/actions/issue_comment/add";
import issueCommentDelete from "modules/documents/actions/issue_comment/delete";
import issueCommentUpdate from "modules/documents/actions/issue_comment/update";
import logsFetchAction from "modules/logs/actions/logs_fetch";
import IssueDetail from "common_components/issue_detail";

function IssueDetailContainer({
  issueResponseAdd,
  issueResponseSave,
  issueResponseDelete,
  issueCommentAdd,
  issueCommentDelete,
  issueCommentUpdate,
  ...props
}) {
  const {params: {organisationId}, issue} = props;
  function addIssueResponse(issueResponse) {
    issueResponseAdd(organisationId, {...issueResponse, issue_id: issue.id});
  }
  function saveIssueResponse(issueResponse) {
    issueResponseSave(organisationId, issueResponse);
  }
  function deleteIssueResponse(issueResponse) {
    issueResponseDelete(organisationId, issueResponse);
  }
  function addIssueComment(text) {
    issueCommentAdd(organisationId, issue.document_issue_id, text);
  }
  function updateIssueComment(issueComment, updates) {
    issueCommentUpdate(organisationId, issueComment, updates);
  }
  function deleteIssueComment(issueComment) {
    issueCommentDelete(organisationId, issueComment);
  }
  return (
    <IssueDetail
      {...props}
      addIssueResponse={addIssueResponse}
      saveIssueResponse={saveIssueResponse}
      deleteIssueResponse={deleteIssueResponse}
      addIssueComment={addIssueComment}
      updateIssueComment={updateIssueComment}
      deleteIssueComment={deleteIssueComment}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    logs: state.logs === UNINITIALISED ? [] : state.logs,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      issueResponseAdd,
      issueResponseSave,
      issueResponseDelete,
      logsFetch: logsFetchAction(requestor),
      issueCommentAdd,
      issueCommentDelete,
      issueCommentUpdate,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(IssueDetailContainer),
);

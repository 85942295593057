import React from "react";
import ReactDOM from "react-dom";

import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
  root: {
    position: "relative",
  },
  loadingRoot: {
    color: "rgba(120,127,133,0.3)",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -10,
  },
};

export default class TabHeader extends React.Component {
  render() {
    const {
      headerClasses,
      isActive,
      showLoading,
      headerRef: {current: targetContainer},
    } = this.props;
    if (!targetContainer) {
      return null;
    }
    const className = `${headerClasses.tab}${
      isActive ? ` ${headerClasses.activeTab}` : ""
    }`;
    return ReactDOM.createPortal(
      <div
        className={className}
        style={{
          ...styles.root,
          ...(showLoading && styles.loadingRoot),
        }}
        {...!isActive && {
          onClick: () => this.props.activate(),
        }}
      >
        {this.props.Icon}
        <p style={{color: "#333333"}}>{`${this.props.title} (${
          this.props.resultsCount
        })`}</p>
        {showLoading && <CircularProgress size={20} style={styles.progress} />}
      </div>,
      targetContainer,
    );
  }
}

TabHeader.defaultProps = {
  showLoading: false,
};

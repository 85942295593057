import React from "react";
import TextField from "material-ui/TextField";
import Done from "material-ui/svg-icons/action/done";
import CloseIcon from "material-ui/svg-icons/navigation/close";
import * as colors from "material-ui/styles/colors";

import ClausepartControl, {
  tooltipStyles,
} from "common_components/gui_text_editor/clausepart_control";
import Tooltip from "common_components/tooltip";

import {setCaretPosition} from "utils/dom_operations";
import {callIfExists} from "common/func";

const lightBlueColor = "#f8fbff";
const lightGrayBorder = "1px solid lightgray";

const styles = {
  clauseEditor: {
    width: "100%",
    fontWeight: "200",
    fontSize: "1em",
    height: "unset !important",
    background: lightBlueColor,
  },
  textareaStyle: {
    overflowY: "hidden",
    background: lightBlueColor,
    marginTop: 0,
    marginBottom: 0,
  },
  editorHeader: {
    background: "#fafafa",
    border: "1px solid #ccc",
    display: "flex",
    padding: "8px",
    alignItems: "center",
  },
  editorBody: {
    background: lightBlueColor,
    padding: "12px 15px",
    borderLeft: lightGrayBorder,
    borderBottom: lightGrayBorder,
    borderRight: lightGrayBorder,
  },
  menuItemIcon: {
    width: "19px",
    height: "19px",
    color: colors.grey800,
    cursor: "pointer",
    position: "relative",
    top: "2px",
  },
};

export default class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    const text = props.text || "";
    this.state = {
      text,
      initText: text,
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickOutsideEditor);
    this.focus();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickOutsideEditor);
  }

  componentDidUpdate(prevProps) {
    const {props} = this;
    if (prevProps.text !== props.text) {
      this.setState(() => ({text: props.text}));
    }

    if (prevProps.editModeOn !== props.editModeOn && !props.editModeOn) {
      this.props.hideEditor();
    }
  }

  handleClickOutsideEditor = event => {
    const editModePanelRef = document.getElementById("edit-mode-panel");
    if (
      this.rootTextEditorContainerRef &&
      !this.rootTextEditorContainerRef.contains(event.target) &&
      editModePanelRef &&
      !editModePanelRef.contains(event.target)
    ) {
      callIfExists(this.onEditorClose);
    }
  };

  getOnRevertHandler = () => {
    const {clausepartTextChanges, revertDocumentClausepartChanges} = this.props;
    if (
      !clausepartTextChanges ||
      clausepartTextChanges.length === 0 ||
      !revertDocumentClausepartChanges
    ) {
      return;
    }
    return () => {
      revertDocumentClausepartChanges();
      this.props.hideEditor();
    };
  };
  render() {
    const {props, state} = this;
    const haveContentsChanged = state.text !== state.initText;
    return (
      <div ref={this.createRootTextEditorContainerRef} style={{width: "100%"}}>
        <div style={styles.editorHeader}>
          <ClausepartControl
            itemType={this.props.itemType}
            onAddItemBefore={this.props.onAddItemBefore}
            onAddItemAfter={this.props.onAddItemAfter}
            onRevert={this.getOnRevertHandler()}
            onDelete={this.props.onDeleteClausepart}
            haveContentsChanged={haveContentsChanged}
            onAbortEdit={this.onAbortEdit}
          />
          <Tooltip
            tooltipContent="Save"
            rootDivStyle={tooltipStyles.rootDiv}
            tooltipContainerStyle={tooltipStyles.getTooltipContainerStyles({
              right: "16px",
            })}
            tooltipContentStyle={tooltipStyles.getTooltipContentStyles()}
            delay={300}
          >
            <Done
              style={{
                ...styles.menuItemIcon,
                marginLeft: "15px",
                ...(!haveContentsChanged
                  ? {color: colors.grey400, cursor: "unset"}
                  : {}),
              }}
              onClick={this.onEditorClose}
            />
          </Tooltip>
          <Tooltip
            tooltipContent="Close without saving"
            rootDivStyle={{
              ...tooltipStyles.rootDiv,
              marginLeft: "1.5rem",
              paddingLeft: "0.36rem",
              borderLeft: "1px solid #ccc",
            }}
            tooltipContainerStyle={tooltipStyles.getTooltipContainerStyles({
              right: "16px",
              width: "7rem",
            })}
            tooltipContentStyle={tooltipStyles.getTooltipContentStyles()}
            delay={300}
          >
            <CloseIcon style={styles.menuItemIcon} onClick={this.onAbortEdit} />
          </Tooltip>
        </div>
        <div style={styles.editorBody}>
          <TextField
            id={this.getId()}
            className="text-editor-text-field"
            value={state.text}
            onChange={this.onTextChange}
            onKeyPress={this.onKeyPress}
            ref={_ref => {
              this.ref = _ref;
            }}
            style={styles.clauseEditor}
            textareaStyle={styles.textareaStyle}
            disabled={props.disabled}
            underlineShow={false}
            multiLine
            fullWidth
          />
        </div>
      </div>
    );
  }

  onEditorClose = () => {
    const {props, state} = this;
    const haveToChange = state.text !== state.initText;
    if (haveToChange) {
      this.setState(
        () => ({initText: state.text}),
        () => props.changeHandler(state.text),
      );
    } else {
      this.props.hideEditor();
    }
  };

  onTextChange = (e, text) =>
    !this.props.disabled && this.setState(() => ({text}));

  getId() {
    return this.props.id || "clause-editor";
  }

  focus() {
    if (this.ref && !this.props.disabled) {
      this.ref.select();
      const node = this.ref.getInputNode();
      const {caretOffset} = this.props;
      setCaretPosition(
        node,
        typeof caretOffset === "number" ? caretOffset : node.value.length,
      );
    }
  }

  onKeyPress = event => {
    const {props, state} = this;
    return callIfExists(props.onKeyPress(this.ref), event, state.text);
  };

  onAbortEdit = () => this.props.hideEditor();
  createRootTextEditorContainerRef = node =>
    (this.rootTextEditorContainerRef = node);
}

import _ from "underscore";

import localStorage from "utils/local_storage";

const getOrganisationStorage = () => {
  try {
    return JSON.parse(localStorage.getItem("organisationStorage")) || {};
  } catch {
    return {};
  }
};

const setOrganisationStorage = organisationStorage =>
  localStorage.setItem(
    "organisationStorage",
    JSON.stringify(organisationStorage),
  );
const isOrganisationStorageAnObject = storage =>
  typeof storage === "object" && !(storage instanceof Array);

export const get = (organisationId, key) => {
  return getOrganisationStorage()[`${organisationId}_${key}`];
};

export const set = (organisationId, key, value) => {
  let organisationStorage = getOrganisationStorage();
  if (!isOrganisationStorageAnObject(organisationStorage)) {
    organisationStorage = {};
  }
  organisationStorage[`${organisationId}_${key}`] = value;
  setOrganisationStorage(organisationStorage);
};

export const remove = (organisationId, key) => {
  const organisationStorage = getOrganisationStorage();
  if (!isOrganisationStorageAnObject(organisationStorage)) {
    return;
  }
  delete organisationStorage[`${organisationId}_${key}`];
  setOrganisationStorage(organisationStorage);
};

export const clear = organisationId => {
  const organisationStorage = getOrganisationStorage();
  if (!isOrganisationStorageAnObject(organisationStorage)) {
    return;
  }
  setOrganisationStorage(
    _.omit(
      organisationStorage,
      (item, key) => organisationId === key.split("_")[0],
    ),
  );
};

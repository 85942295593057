import _ from "underscore";
import findDefinitionInstances from "routes/document_clauses/utils/find_definition_instances";
import findSearchInstances from "routes/document_clauses/utils/find_search_instances";
import findTemplateInstances from "routes/document_clauses/utils/find_template_instances";
import findReferenceInstances from "routes/document_clauses/utils/find_reference_instances";
import getClassesPerCharPdf from "./get_classes_per_char_pdf";
import companyDetailPlaceholderRegexString from "constants/company_detail_placeholder_regex_string";

function getFormatTextData(
  fileExtension,
  textBeforeChanges,
  {changes: rawChanges, documentDefinitions, clause},
  findText,
  hideTextDeletions,
  formattedText,
  templateValues = {},
) {
  let classesPerChar = [];
  if (
    fileExtension === "pdf" &&
    formattedText &&
    textBeforeChanges !== formattedText
  ) {
    classesPerChar = getClassesPerCharPdf(textBeforeChanges, formattedText);
  } else {
    classesPerChar = getClassesPerChar(
      textBeforeChanges,
      rawChanges,
      documentDefinitions,
      clause && clause.references,
      findText,
      hideTextDeletions,
      templateValues,
    );
  }
  return mergeSymbolsWithSameClasses(classesPerChar);
}

function getDefinitionIdFromPair(searchIndex, definitionPairs) {
  const pair = definitionPairs.find(
    defPair => searchIndex >= defPair[0] && searchIndex <= defPair[1],
  );
  return pair ? pair.id : null;
}

function getKeyFromPair(searchIndex, pairs, key) {
  const pair = pairs.find(
    _pair => searchIndex >= _pair[0] && searchIndex <= _pair[1],
  );
  return pair ? pair[key] : undefined;
}

function getLastBlockItemObject(text, lastBlockItem) {
  return {
    text,
    classes: lastBlockItem.classes,
    ...(lastBlockItem.definitionId
      ? {definitionId: lastBlockItem.definitionId}
      : {}),
    ...(lastBlockItem.key ? {key: lastBlockItem.key} : {}),
    ...(lastBlockItem.companyDetailPlaceholderIndex !== undefined
      ? {
          companyDetailPlaceholderIndex:
            lastBlockItem.companyDetailPlaceholderIndex,
        }
      : {}),
    ...(lastBlockItem.reference ? {reference: lastBlockItem.reference} : {}),
  };
}

function getTextAfterChanges(text, changes) {
  let resultText = text;
  changes.forEach(change => {
    if (change.old_document_id !== change.new_document_id) {
      return;
    }
    switch (change.type) {
      case "clauseheading_text_addition":
      case "clausepart_text_addition":
        resultText = insertStringIntoTextByIndex(
          resultText,
          change.new_text,
          change.new_start_offset,
        );
        break;
      case "clauseheading_text_deletion":
      case "clausepart_text_deletion":
        resultText = removeStringFromTextByIndex(
          resultText,
          change.old_text,
          change.new_start_offset,
        );
        break;
      case "clauseheading_text_alteration":
      case "clausepart_text_alteration":
        resultText = replaceTextByIndex(
          resultText,
          change.old_text,
          change.new_text,
          change.new_start_offset,
        );
        break;
      case "clauseheading_deletion":
      case "clause_deletion":
      case "clausepart_deletion":
        resultText = "";
        break;
      default:
        break;
    }
  });
  return resultText;
}

function getStartEndOffsetPairs(instances, shouldSaveText) {
  return instances.map(instance => {
    const result = [instance.start, instance.end];
    if (shouldSaveText) {
      result.text = instance.text;
    }
    return result;
  });
}

function getDefinitionStartEndOffsetPairs(instances) {
  // We need to keep definition id through all processing for when
  // having the definition broken into several segments we could
  // show the meaning for each definition segment
  return instances.map(instance => {
    const offsetPair = [instance.start, instance.end];
    offsetPair.id = instance.definition.id;
    return offsetPair;
  });
}

function getTemplatePairs(instances) {
  return instances.map(instance => {
    const offsetPair = [instance.start, instance.end];
    offsetPair.key = instance.key;
    return offsetPair;
  });
}

function getReferencePairs(instances) {
  return instances.map(instance => {
    const pair = [instance.start, instance.end];
    pair.id = instance.reference;
    return pair;
  });
}

function transformTextAndPairsByChanges(
  basePairs,
  changes,
  textBeforeChanges,
  textAfterChanges,
  hideTextDeletions,
) {
  let pairs = basePairs;
  let textWithChanges = textAfterChanges;
  const textDeletionPairs = [];
  const textAdditionPairs = [];
  const textAlterationPairs = [];

  let deletionShiftOffset = 0;
  let additionShiftOffset = 0;
  changes.forEach(change => {
    switch (change.type) {
      case "clauseheading_text_addition":
      case "clausepart_text_addition": {
        const start = change.new_start_offset + deletionShiftOffset;
        const end = change.new_end_offset + deletionShiftOffset - 1;
        textAdditionPairs.push([start, end]);
        additionShiftOffset += change.new_text.length;
        break;
      }
      case "clauseheading_text_deletion":
      case "clausepart_text_deletion": {
        if (hideTextDeletions) {
          // in case we don't show text deletions we dont shift and split pairs
          break;
        }
        const start = change.old_start_offset + additionShiftOffset;
        const end = change.old_end_offset - 1 + additionShiftOffset;
        textWithChanges = insertStringIntoTextByIndex(
          textWithChanges,
          change.old_text,
          start,
        );
        textDeletionPairs.push([start, end]);
        deletionShiftOffset += change.old_text.length;
        pairs = shiftAndSplitPairs(
          pairs,
          start,
          change.old_text.length,
          deletionShiftOffset,
        );
        break;
      }
      case "clauseheading_text_alteration":
      case "clausepart_text_alteration": {
        // we treat text alteration as addition+deletion with added alteration styling
        // addition
        const additionStart = change.new_start_offset + deletionShiftOffset;
        const additionEnd = additionStart + change.new_text.length - 1;
        textAdditionPairs.push([additionStart, additionEnd]);
        additionShiftOffset += change.new_text.length;

        if (!hideTextDeletions) {
          // in case we don't show text deletions we dont shift and split pairs and
          // also we don't show text alteration pairs

          // deletion
          const deletionStart = change.old_start_offset + additionShiftOffset;
          const deletionEnd = deletionStart + change.old_text.length - 1;

          textWithChanges = insertStringIntoTextByIndex(
            textWithChanges,
            change.old_text,
            deletionStart,
          );
          textDeletionPairs.push([deletionStart, deletionEnd]);
          deletionShiftOffset += change.old_text.length;
          pairs = shiftAndSplitPairs(
            pairs,
            deletionStart,
            change.old_text.length,
            deletionShiftOffset,
          );

          // alteration
          textAlterationPairs.push([additionStart, deletionEnd]);
        }
        break;
      }
      case "clauseheading_deletion":
      case "clause_deletion":
      case "definition_deletion":
      case "clausepart_deletion": {
        textWithChanges = textBeforeChanges;
        const deletionStart = 0;
        const deletionEnd = textBeforeChanges.length - 1;
        textDeletionPairs.push([deletionStart, deletionEnd]);
        break;
      }
      default:
        break;
    }
  });

  return {
    text: textWithChanges,
    textAdditionPairs,
    textDeletionPairs,
    textAlterationPairs,
    ...pairs,
  };
}

function shiftAndSplitPairs(
  pairsObj,
  deletionStart,
  deletionLength,
  shiftOffset,
) {
  const deletionEnd = deletionStart + deletionLength - 1;
  const result = {};
  Object.keys(pairsObj).forEach(pairName => {
    const pairs = pairsObj[pairName];
    const newPairs = [];
    pairs.forEach(pair => {
      const pairsToAdd = [];
      const pairStart = pair[0];
      const pairEnd = pair[1];
      if (pairStart < deletionStart && pairEnd + deletionLength > deletionEnd) {
        const splittedLeftPair = [pairStart, deletionStart - 1];
        const splittedRightPair = [deletionEnd + 1, pairEnd + deletionLength];
        pairsToAdd.push(splittedLeftPair);
        pairsToAdd.push(splittedRightPair);
      } else if (pairStart + shiftOffset > deletionEnd) {
        const shiftedStart = pairStart + deletionLength;
        const shiftedEnd = pairEnd + deletionLength;
        pairsToAdd.push([shiftedStart, shiftedEnd]);
      } else {
        pairsToAdd.push([pairStart, pairEnd]);
      }
      pairsToAdd.forEach(pairToAdd => {
        if (pair.id) {
          pairToAdd.id = pair.id;
        } else if (pair.key) {
          pairToAdd.key = pair.key;
        }
        if (pair.text) {
          pairToAdd.text = pair.text;
        }
        newPairs.push(pairToAdd);
      });
    });
    result[pairName] = newPairs;
  });
  return result;
}

function insertStringIntoTextByIndex(text, insertion, index) {
  const startText = text.substring(0, index);
  const endText = text.substring(index);
  return `${startText}${insertion}${endText}`;
}

function replaceTextByIndex(text, replaceFrom, replaceTo, index) {
  const startText = text.substring(0, index);
  const endText = text.substring(startText.length + replaceFrom.length);
  return `${startText}${replaceTo}${endText}`;
}

function removeStringFromTextByIndex(text, removedString, index) {
  const startText = text.substring(0, index);
  const endText = text.substring(startText.length + removedString.length);
  return `${startText}${endText}`;
}

function isOffsetInPairs(offset, pairs) {
  return Boolean(pairs.find(pair => offset >= pair[0] && offset <= pair[1]));
}

function getClassesPerChar(
  textBeforeChanges,
  rawChanges,
  documentDefinitions,
  references,
  findText,
  hideTextDeletions,
  templateValues,
) {
  // 1. Since we need to look for search and definition results based on
  // text after changes (with excluded deletions but included additions)
  // here we get:
  // - text after changes (deletions are excluded from the text!!)
  // - search and definition offset pairs
  // OFFSET PAIR (startOffset, endOffset) - starting and ending indexes
  // of the text (inclusive) where the condition is applicable

  // changes' offsets are dependent on each other i.e. the offset of each
  // consecutive change is calculated based on baseText+previous changes
  // but not baseText. Therefore order matters => we sort changes
  const changes = _.sortBy(rawChanges, change => change.new_start_offset);
  const textAfterChanges = getTextAfterChanges(textBeforeChanges, changes);
  const {
    templateInstances,
    textWithTemplateValues: textWithTemplatesAfterChanges,
    changes: correctedChanges,
  } = findTemplateInstances(textAfterChanges, templateValues, changes);

  const searchInstances = findSearchInstances(
    textWithTemplatesAfterChanges,
    findText,
  );

  // triple underscores or blank brakets (with/without space)
  // are used to substitute text with company data
  const companyDetailPlaceholderInstances = findSearchInstances(
    textWithTemplatesAfterChanges,
    companyDetailPlaceholderRegexString,
    true,
  );

  const definitionInstances = findDefinitionInstances(
    textWithTemplatesAfterChanges,
    documentDefinitions,
  );
  const baseTemplatePairs = getTemplatePairs(templateInstances);
  const baseSearchPairs = getStartEndOffsetPairs(searchInstances);
  const baseCompanyDetailPlaceholderPairs = getStartEndOffsetPairs(
    companyDetailPlaceholderInstances,
    true,
  );
  const baseDefinitionPairs = getDefinitionStartEndOffsetPairs(
    definitionInstances,
  );

  const referenceInstances = findReferenceInstances(
    textWithTemplatesAfterChanges,
    references,
  );
  const baseReferencePairs = getReferencePairs(referenceInstances);

  // 2. Changes (text_deletions in particular) affect the overall text
  // composition (strikethrough segments are added to the text but they
  // don't participate in looking for search and definition segments)
  // Therefore changes might affect the previously calculated offset pairs.
  // here we get:
  // - text after changes (deletions are included!!)
  // - search and definition changes corrected by these changes (deletion
  //   inclusions)
  // - offset pairs for text additions/deletions/alterations
  const {
    text,
    searchPairs,
    companyDetailPlaceholderPairs,
    definitionPairs,
    referencePairs,
    templatePairs,
    textAdditionPairs,
    textDeletionPairs,
    textAlterationPairs,
  } = transformTextAndPairsByChanges(
    {
      searchPairs: baseSearchPairs,
      definitionPairs: baseDefinitionPairs,
      templatePairs: baseTemplatePairs,
      referencePairs: baseReferencePairs,
      companyDetailPlaceholderPairs: baseCompanyDetailPlaceholderPairs,
    },
    correctedChanges,
    textBeforeChanges,
    textWithTemplatesAfterChanges,
    hideTextDeletions,
  );
  let companyDetailPlaceholderIndex = 0;
  let companyDetailPlaceholderCount = 0;
  const result = [];
  for (let i = 0; text && i < text.length; i++) {
    const isSearch = isOffsetInPairs(i, searchPairs);
    const isCompanyDetailPlaceholder = isOffsetInPairs(
      i,
      companyDetailPlaceholderPairs,
    );
    const isDefinition = isOffsetInPairs(i, definitionPairs);
    const isReference = isOffsetInPairs(i, referencePairs);
    const isAddition = isOffsetInPairs(i, textAdditionPairs);
    const isDeletion = isOffsetInPairs(i, textDeletionPairs);
    const isAlteration = isOffsetInPairs(i, textAlterationPairs);
    const isTemplate = isOffsetInPairs(i, templatePairs);
    const classes = [
      ...(isTemplate ? ["template"] : []),
      ...(isSearch ? ["search"] : []),
      ...(isCompanyDetailPlaceholder ? ["company_detail_placeholder"] : []),
      ...(isDefinition ? ["definition"] : []),
      ...(isReference ? ["reference"] : []),
      ...(isAddition ? ["text_addition"] : []),
      ...(isDeletion ? ["text_deletion"] : []),
      ...(isAlteration ? ["text_alteration"] : []),
    ];
    const resultItem = {
      symbol: text[i],
      classes,
      definitionId: isDefinition
        ? getDefinitionIdFromPair(i, definitionPairs)
        : undefined,
      key: isTemplate ? getKeyFromPair(i, templatePairs, "key") : undefined,
      text: isCompanyDetailPlaceholder
        ? getKeyFromPair(i, companyDetailPlaceholderPairs, "text")
        : undefined,
      reference: isReference
        ? getKeyFromPair(i, referencePairs, "id")
        : undefined,
    };
    if (isCompanyDetailPlaceholder && resultItem.text) {
      companyDetailPlaceholderCount += 1;
      resultItem.companyDetailPlaceholderIndex = companyDetailPlaceholderIndex;
      if (companyDetailPlaceholderCount === resultItem.text.length) {
        companyDetailPlaceholderCount = 0;
        companyDetailPlaceholderIndex += 1;
      }
    }
    result.push(resultItem);
  }
  return result;
}

function mergeSymbolsWithSameClasses(classesPerSymbolArray) {
  // takes an array of type:
  // [
  //   {symbol: "s", classes: ["a"]},
  //   {symbol: "e", classes: ["a"]},
  //   {symbol: "v", classes: ["a", "bb"]},
  //   {symbol: "e", classes: ["a", "bb"]},
  //   {symbol: "n", classes: ["c"]},
  // ]
  // and returns merged segments, e.g:
  // [
  //   {text: "se", classes: ["a"]},
  //   {text: "ve", classes: ["a", "bb"]},
  //   {text: "n", classes: ["c"]},
  // ]
  const result = [];
  if (classesPerSymbolArray.length === 0) {
    return result;
  }
  let block = [classesPerSymbolArray[0]];
  let blockFullText = classesPerSymbolArray[0].symbol;
  for (let i = 1; i < classesPerSymbolArray.length; i++) {
    const baseResultItem = classesPerSymbolArray[i];
    const lastBlockItem = block[block.length - 1];
    if (_.isEqual(baseResultItem.classes, lastBlockItem.classes)) {
      blockFullText += baseResultItem.symbol;
    } else {
      result.push(getLastBlockItemObject(blockFullText, lastBlockItem));
      block = [baseResultItem];
      blockFullText = baseResultItem.symbol;
    }
  }
  if (blockFullText) {
    const lastBlockItem = block[block.length - 1];
    result.push(getLastBlockItemObject(blockFullText, lastBlockItem));
  }
  return result;
}

export default getFormatTextData;

function reasonViewer(/* reason */) {
  return [{path: "N/A", value: "N/A"}];
}

function getDefaultIssueRuleValues() {
  return [];
}

export default {
  reasonViewer,
  getDefaultIssueRuleValues,
};

import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import * as colors from "@material-ui/core/colors";
import {makeStyles} from "@material-ui/core/styles";

import ItemContainer from "../../helper_components/item_container";

const useStyles = makeStyles({
  root: {
    borderRadius: 0,
    marginBottom: "8px",
    "& .MuiOutlinedInput-input": {
      padding: "9px 32px 9px 6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.grey[300],
    },
  },
  select: {backgroundColor: "#fff", padding: "0.75em"},
  selectValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 13,
    fontWeight: 400,
    marginLeft: 4,
  },
  selectValueLabel: {color: colors.grey[400]},
  selectValueText: {
    color: colors.grey[700],
  },
  selectItem: {textAlign: "right", display: "inherit"},
});

function PrecedentLanguageSort(props) {
  const {
    sortBy,
    sortByTypes,
    updateSortBy,
    isExpanded,
    triggerIsExpanded,
  } = props;

  function updateFilterValue(e) {
    const newValue = e.target.value;
    updateSortBy(newValue);
  }

  const selectedItem = sortByTypes.find(item => item.type === sortBy);

  const classes = useStyles();
  return (
    <ItemContainer
      title={isExpanded ? "Sort By" : `Sort By: ${selectedItem.label}`}
      expandable={true}
      onExpandMenu={triggerIsExpanded}
      isMenuExpanded={isExpanded}
      headerStyles={{height: "3rem"}}
      containerStyles={{
        padding: "0 0.6rem",
        border: `1px solid ${colors.grey[300]}`,
        backgroundColor: "#fcfcfc",
        marginTop: 12,
      }}
      titleStyles={{color: colors.grey[700]}}
      bodyStyles={{
        padding: "12px 0px",
      }}
    >
      <FormControl variant="outlined" style={{width: "100%"}}>
        <Select
          value={selectedItem.type}
          onChange={updateFilterValue}
          className={classes.root}
          renderValue={() => (
            <span className={classes.selectValueContainer}>
              {selectedItem.label}
            </span>
          )}
        >
          {sortByTypes.map((sortByTypeItem, sortByTypeItemIndex) => (
            <MenuItem
              key={sortByTypeItemIndex}
              value={sortByTypeItem.type}
              className={classes.selectItem}
            >
              {sortByTypeItem.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ItemContainer>
  );
}

export default PrecedentLanguageSort;

import isEmptyParent from "common/utils/issues/is_empty_parent";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getEmptyParentColor from "utils/issues/get_empty_parent_color";

function getDefaultColor(issue, issueset) {
  const {issue_class_id: issueClassId} = issue;
  if (isEmptyParent(issue)) {
    const emptyParentColor = getEmptyParentColor(issue, issueset);
    if (emptyParentColor === "red" && issueClassId === 3) {
      return "amber";
    }
    return emptyParentColor;
  }
  if (issueClassId === 2) {
    return "grey";
  }
  if (isIssueAlert(issue)) {
    return issueClassId === 1 ? "red" : "amber";
  }
  return "green";
}

export default getDefaultColor;

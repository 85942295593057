const MAX_DEEP = 10;

export default function makeIndexProducer() {
  const stack = [];
  for (let index = 0; index < MAX_DEEP; index += 1) {
    stack[index] = 0;
  }
  let prevLevel = 0;
  return function getIndex(level) {
    if (prevLevel > level) {
      stack[prevLevel] = 0;
    }
    stack[level] += 1;
    prevLevel = level;
    return stack[level] - 1;
  };
}

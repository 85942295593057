import React from "react";
import Typography from "@material-ui/core/Typography";

interface LlmStatusObject {
  sourceName: string;
  status: {
    indicator: "minor" | "major" | "none";
    description: string;
  };
}

interface LlmStatusProps {
  statuses: LlmStatusObject[];
}

const styles = {
  statusContainer: {
    display: "flex",
    alignItems: "center",
  },
  statusCircle: {
    width: "15px",
    height: "15px",
    minWidth: "15px",
    borderRadius: "50%",
    marginRight: "10px",
  },
  statusesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: ".5em",
  },
  description: {
    lineHeight: 1,
  },
};

const LlmStatus = ({statuses}: LlmStatusProps) => {
  return (
    <div style={styles.statusesContainer}>
      {statuses.map(({sourceName, status: {indicator, description}}, index) => (
        <div key={index} style={styles.statusContainer}>
          <div
            style={{
              ...styles.statusCircle,
              backgroundColor: indicator === "minor" ? "orange" : "red",
            }}
          ></div>
          <Typography
            style={styles.description}
          >{`${sourceName} API Status: ${description}`}</Typography>
        </div>
      ))}
    </div>
  );
};

export default LlmStatus;

import React from "react";

import * as colors from "material-ui/styles/colors";

import getClauseTopicsNames from "./get_clause_topics_names";
import renderClause from "utils/clauses/render_clause";

const styles = {
  noMatchingClauses: {
    marginTop: "0.25em",
    textAlign: "center",
    fontStyle: "italic",
    fontSize: "13px",
    fontWeight: 400,
    color: colors.grey600,
  },
  topicName: {
    fontSize: "14px",
    marginBottom: "0.4rem",
    fontWeight: "600",
    color: colors.blueA700,
  },
};
function renderApplicableClauses(
  clauses,
  onClauseClick,
  topicsById,
  hideTopicNames,
  fileExtension,
) {
  const clausesLength = clauses.length;
  if (clauses && clausesLength === 0) {
    return <div style={styles.noMatchingClauses}>No Matching Clauses</div>;
  }
  return (
    <div>
      {clauses.map((clause, index) => {
        let clauseTopicsNames;
        const clauseTopics = clause.topics.filter(
          topic => topic.is_deleted === false,
        );
        if (
          clauseTopics[0] &&
          clauseTopics[0].name &&
          clauseTopics[0].name.indexOf("/") === -1
        ) {
          clauseTopicsNames = [clauseTopics[0].name];
        } else {
          clauseTopicsNames = topicsById
            ? getClauseTopicsNames(clauseTopics, topicsById)
            : null;
        }
        return (
          <div
            style={{
              borderBottom: `${
                index + 1 === clausesLength ? "0px" : "1px"
              } solid ${colors.grey200}`,
            }}
            key={index}
          >
            {!hideTopicNames &&
              topicsById &&
              renderClauseTopicsNames(clauseTopicsNames)}
            {renderClause(
              clause,
              index,
              onClauseClick,
              [],
              null,
              fileExtension,
            )}
          </div>
        );
      })}
    </div>
  );
}

function renderClauseTopicsNames(names) {
  return names.map((name, index) => (
    <div key={index} style={styles.topicName}>
      {name}
    </div>
  ));
}

export default renderApplicableClauses;

function getAcceptedTopicCategoryIds(categories) {
  const acceptedCategories = [];
  categories.forEach(category => {
    if (acceptedCategories.includes(category.id)) {
      return;
    }
    if (!category.hidden) {
      acceptedCategories.push(category.id);
    }
  });
  return acceptedCategories;
}

export default getAcceptedTopicCategoryIds;

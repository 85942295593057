import _ from "underscore";
import getDefinitionInstancesToEliminate from "./get_definition_instances_to_eliminate";

function comp(fn, nextComp) {
  return (a, b) => {
    const fnA = fn(a);
    const fnB = fn(b);

    if (fnA > fnB) {
      return 1;
    } else if (fnA === fnB) {
      if (nextComp) {
        return nextComp(a, b);
      }
      return 0;
    }
    return -1;
  };
}

export default function findDefinitionInstances(text, definitions) {
  if (!text) {
    return [];
  }

  let instances = [];
  definitions.forEach(definition => {
    const {term} = definition;
    const termLength = term.length;
    let start = 0;
    while ((start = text.indexOf(term, start)) >= 0) {
      const end = start + termLength - 1;
      instances.push({start, end, definition, term, type: "definition"});
      start += 1;
    }
  });

  // order by start asc, then term length desc
  instances.sort(
    comp(instance => instance.start, comp(instance => -instance.term.length)),
  );

  // the following operations rely on it being ordered.

  // since ordered by term length desc, removing uniques keeps the first one
  // (the one we want)
  instances = _.uniq(instances, true, instance => instance.start);

  // remove instances which overlap another one, eg:
  // ab cd de, if "ab cd" and "cd de" are terms, keep only the first
  let lastInstance;
  instances = instances.filter(instance => {
    let isAfter = true;
    if (lastInstance) {
      isAfter = instance.start >= lastInstance.start + lastInstance.term.length;
    }
    lastInstance = instance;
    return isAfter;
  });
  // remove instances which are inside of other instances, eg:
  // if we have "ABCDEFG" and "CDE" instances and CDEstart > ABCDEFGstart and CDEstart < ABCDEFGend,
  // keep only the larger one
  const instancesToEliminate = getDefinitionInstancesToEliminate(instances).map(
    inst => inst.start,
  );
  return instances.filter(
    instance => !instancesToEliminate.includes(instance.start),
  );
}

import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";
import ActionTypes, {ActionType} from "../constants/action_types";
import UserActionTypes from "modules/user/constants/action_types";
import OrganisationActionTypes from "modules/organisation/constants/action_types";
import DocumentsActionTypes from "modules/documents/constants/action_types";
import {updateStateWithSuccess} from "utils/ensure_fresh_update";
import {Project} from "common/types/project";
import {MaybeUninitialised, Triplet} from "modules/redux_types";
import {AddAccesstagAction} from "../actions/project_add_accesstag";
import {CreateAccesstagAction} from "../actions/project_create_accesstag";
import {RemoveAccesstagAction} from "../actions/project_remove_accesstag";

// We can eliminate this concept when all types are annotated.
type KnownProjectAction =
  | AddAccesstagAction
  | CreateAccesstagAction
  | RemoveAccesstagAction;

// TODO: Annotate types for all actions.
type UnknownProjectAction = {
  // Crudely excluding the types we CAN narrow.
  type: Exclude<Triplet<ActionType>[number], KnownProjectAction["type"]>;
  payload: unknown;
};

type ProjectAction = KnownProjectAction | UnknownProjectAction;

export default function projectsReducer(
  state: MaybeUninitialised<Project> = UNINITIALISED,
  action: ProjectAction,
) {
  switch (action.type) {
    case ActionTypes.PROJECT_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PROJECT_UPDATE.SUCCESS: {
      if (state && action.payload && action.payload.id === state.id) {
        return updateStateWithSuccess(state, action.payload);
      }
      return state;
    }
    case OrganisationActionTypes.ORGANISATION_CHANGE.SUCCESS:
    case ActionTypes.PROJECT_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.PROJECT_PARTY_ADD.SUCCESS: {
      const project = {parties: [], ...state};
      const {payload} = action;
      project.parties.push({
        id: payload.party_id,
        name: payload.party_name,
        ..._.omit(payload, ["party_id", "party_name"]),
      });
      return project;
    }
    case ActionTypes.PROJECT_PARTY_UPDATE.SUCCESS: {
      const project = {...state};
      project.parties = project.parties.map(party => {
        if (party.id === action.payload.party_id) {
          return {
            ...party,
            projectrole_id: action.payload.projectrole_id,
            last_edited: action.payload.last_edited,
          };
        }
        return party;
      });
      return project;
    }
    case ActionTypes.PROJECT_PARTY_REMOVE.SUCCESS: {
      const project = {...state};
      project.parties = project.parties.filter(
        party => party.id !== action.payload.party_id,
      );
      return project;
    }
    case DocumentsActionTypes.CONTRACT_TYPE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      if (
        action.payload.is_deleted &&
        state.default_contract_type &&
        state.default_contract_type.id === action.payload.id
      ) {
        return {
          ...state,
          default_contract_type: null,
        };
      }
      return state;
    }
    case DocumentsActionTypes.ISSUESET_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        issuesets: state.issuesets.filter(id => id !== action.payload.id),
      };
    }
    case DocumentsActionTypes.DOCUMENTS_REPROCESS.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      if (action.payload && action.payload.project_id === state.id) {
        return {
          ...state,
          ..._.omit(action.payload, ["project_id"]),
        };
      }
      return state;
    }
    case DocumentsActionTypes.PROJECT_GENERATE_PROMPT.REQUEST: {
      // HACK: Dodgy assumption of parameter order
      const [
        organisationId,
        projectId,
        prompt,
        conversationId,
      ] = action.payload;
      const isNew = conversationId === null;
      if (
        state === UNINITIALISED ||
        parseInt(projectId, 10) !== state.id ||
        !isNew
      ) {
        return state;
      }

      return {
        ...state,
        conversations: [
          {
            organisation_id: organisationId,
            project_id: projectId,
            // HACK: Weird encoding of "new" conversation state
            id: -1,
            items: [
              {
                // HACK: Weird encoding of "new" conversation state
                id: -1,
                // HACK: Weird encoding of "new" conversation state
                conversation_id: -1,
                creation_date: new Date(),
                role: "user",
                text: prompt,
                item_order: 0,
                user_id: null, // TODO: Where do we get this?
                previous_item_id: null,
                meta_params: null,
              },
            ],
          },
          ...("conversations" in state ? state.conversations : []),
        ],
      };
    }
    case DocumentsActionTypes.PROJECT_GENERATE_PROMPT.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      if (action.payload && action.payload.project_id === state.id) {
        return {
          ...state,
          conversations: action.payload.is_new
            ? state.conversations.map(conversation =>
                conversation.id === -1 ? action.payload : conversation,
              )
            : (state.conversations || []).map(conversation => {
                if (conversation.id === action.payload.id) {
                  return {
                    ...conversation,
                    items: conversation.items.concat(action.payload.items),
                  };
                }
                return conversation;
              }),
        };
      }
      return state;
    }
    case DocumentsActionTypes.PROJECT_CONVERSATIONS_FETCH.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      if (action.payload && action.payload.id === state.id) {
        return {
          ...state,
          conversations: action.payload.conversations,
        };
      }
      return state;
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    case DocumentsActionTypes.DOCUMENT_UPLOAD.SUCCESS: {
      return {...state, documents_count: state.documents_count + 1};
    }
    case ActionTypes.PROJECT_CREATE_ACCESS_TAG.SUCCESS:
    case ActionTypes.PROJECT_ADD_ACCESS_TAG.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {...state, accesstags: state.accesstags.concat(action.payload)};
    }
    case ActionTypes.PROJECT_REMOVE_ACCESS_TAG.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return {
        ...state,
        accesstags: state.accesstags.filter(
          tag => tag.id !== action.payload.id,
        ),
      };
    }
    case DocumentsActionTypes.DOCUMENTS_FETCH.SUCCESS: {
      const {documentsCount, projectId} = action.payload as {
        documentsCount: number;
        projectId: number;
      };
      if (state === UNINITIALISED || projectId !== state.id) {
        return state;
      }
      return {
        ...state,
        filtered_documents_count: documentsCount,
      };
    }
    default:
      return state;
  }
}

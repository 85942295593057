export default function findGroupedIssue(issue, groupedIssues) {
  const groupedIssue = groupedIssues.reduce((result, groupedIssue) => {
    if (!result) {
      const findIssue = groupedIssueItem => {
        if (issue.id === groupedIssueItem.id) {
          return groupedIssueItem;
        }
        if (!groupedIssueItem?.subissueData?.subissues) {
          const singleItem =
            groupedIssueItem.item &&
            groupedIssueItem.item.find(
              groupedItem => groupedItem.id === issue.id,
            );
          return singleItem ?? null;
        }
        return groupedIssueItem.subissueData.subissues.reduce(
          (result, subissue) => {
            if (!result) {
              if (subissue.id === issue.id) {
                return issue;
              }
            }
            return result;
          },
          null,
        );
      };

      const match = groupedIssue.isGroup
        ? groupedIssue.item.find(findIssue)
        : findIssue(groupedIssue);
      return match;
    }
    return result;
  }, null);
  return groupedIssue;
}

import React from "react";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as colors from "material-ui/styles/colors";

const units = ["number", "percentage"];

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  unitContainer: {
    width: "140px",
  },
  valueContainer: {
    width: "140px",
    marginRight: "0.5rem",
    marginLeft: "4px",
  },
  unitSelectorLabel: {
    left: "10px",
  },
  deleteIcon: {
    cursor: "pointer",
    color: colors.grey700,
  },
};

class ValueEditor extends React.Component {
  render() {
    const {value} = this.props;
    return (
      <div style={styles.container}>
        <TextField
          type="number"
          value={value.value}
          onChange={this.onValueChange}
          floatingLabelText="Value"
          style={styles.valueContainer}
          min={0}
          max={value.unit === "percentage" ? 100 : null}
        />
        <SelectField
          floatingLabelText="Unit"
          value={value.unit}
          onChange={this.onUnitChange}
          autoWidth={true}
          style={styles.unitContainer}
          floatingLabelStyle={styles.unitSelectorLabel}
        >
          {units.map(unit => (
            <MenuItem key={unit} value={unit} primaryText={unit} />
          ))}
        </SelectField>
        <DeleteIcon
          onMouseDown={this.onDeleteValue}
          style={styles.deleteIcon}
        />
      </div>
    );
  }

  onUnitChange = (e, i, value) => {
    this.props.onUpdateValue(
      {...this.props.value, unit: value},
      this.props.valueIndex,
    );
  };

  onValueChange = e => {
    let value = parseFloat(e.target.value);
    if (this.props.value.unit === "percentage" && value > 100) {
      value = 100;
    }
    if (!value || value < 0 || typeof value !== "number") {
      value = 0;
    }
    this.props.onUpdateValue(
      {...this.props.value, value},
      this.props.valueIndex,
    );
  };

  onDeleteValue = this.props.onDeleteValue(this.props.valueIndex);
}

export default ValueEditor;

function getClassesPerCharPdf(text, formattedText) {
  const result = [];
  if (formattedText) {
    let formattedTextIndex = 0;
    let currentTags = [];
    for (let i = 0; text && i < text.length; i++) {
      const baseStringSymbol = text[i];
      const formattedTextSymbol = formattedText[formattedTextIndex];
      if (
        baseStringSymbol !== formattedTextSymbol &&
        formattedTextSymbol === "<"
      ) {
        const closeIndex = formattedText.indexOf(">", formattedTextIndex);
        const fullTag = formattedText.slice(formattedTextIndex, closeIndex + 1);
        const isClosingTag = fullTag.indexOf("/") !== -1;
        const tag = fullTag.replace(/[ \/]/g, "");
        if (isClosingTag) {
          currentTags = currentTags.filter(currentTag => currentTag !== tag);
        } else {
          currentTags.push(fullTag);
        }
        formattedTextIndex = closeIndex + 2;
      } else {
        formattedTextIndex += 1;
      }
      result.push({symbol: text[i], classes: [...currentTags]});
    }
  } else {
    text.split("").forEach(symbol => {
      result.push({symbol, classes: []});
    });
  }
  return result;
}

export default getClassesPerCharPdf;

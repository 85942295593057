import React from "react";
import TopPanelContainer from "../top_panel_container";

function onDocxDownload() {}

function AmendmentLetter(props) {
  // const {id: documentId} = props.document;
  return (
    <TopPanelContainer
      renderInSeparateWindow={props.renderInSeparateWindow}
      triangleRightOffset="82"
      rootDivStyles={props.rootDivStyles}
      hasExpandableView={true}
      onDocxDownload={onDocxDownload}
    >
      <div>
        <p style={{textAlign: "center"}}>Dated 24th December 2020</p>
        <p style={{textAlign: "center"}}>
          <strong>AMENDMENT AGREEMENT TO RIG CONSTRUCTION CONTRACT</strong>
        </p>
        <p style={{textAlign: "center"}}>
          (Pacific Class® 400 jack up drilling rig)
        </p>

        <p style={{textAlign: "center"}}>Between</p>
        <p style={{textAlign: "center"}}>
          <strong>BORR JACK-UP INC. (as “Buyer”)</strong>
        </p>
        <p style={{textAlign: "center"}}>and</p>

        <p style={{textAlign: "center"}}>
          <strong>PPL SHIPYARD PTE LTD (as “Builder”)</strong>
        </p>

        <p style={{textAlign: "center"}}>
          <strong>RIG CONSTRUCTION CONTRACT</strong>
        </p>

        <p>
          This agreement for the construction, sale and purchase of a rig (the
          “Contract”) is originally made this 31st day of October 2017 and the
          Buyer and Builder have agreed to make the following amendments:
        </p>
        <table style={{width: "100%", border: "1px solid black"}}>
          <tr>
            <td style={{borderRight: "1px solid black"}}>
              Delete clause 8.2 and replace as opposite
            </td>
            <td>
              If the delivery of the Rig is delayed beyond the Delivery Date,
              then Builder shall pay to Buyer as liquidated and agreed damages,
              the amount of United States Dollars Twenty Thousand Dollars
              (US$20,000.00) per day for each and every day the delivery of the
              Rig is so delayed more than sixty (60) days (grace period) beyond
              the said Delivery Date, subject to a maximum of United States
              Dollars: Two Million Only (US$2,000,000.00).
            </td>
          </tr>
        </table>

        <p>
          IN WITNESS WHEREOF, the parties hereto have executed this Contract in
          multiple originals effective as of the day and year first above
          written.
        </p>

        <table style={{width: "100%"}}>
          <tr>
            <td>For and on behalf of Buyer BORR JACK-UP No 23 INC</td>
            <td>For and on behalf of Builder PPL SHIPYARD PTE LTD</td>
          </tr>
          <tr>
            <td>...........................</td>
            <td>...........................</td>
          </tr>
        </table>
      </div>
    </TopPanelContainer>
  );
}

export default AmendmentLetter;

import ActionTypes from "../constants/action_types";

import basePath from "base_path";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function userPasswordForgot(requestor) {
  return createReduxPromiseAction(ActionTypes.USER_PASSWORD_FORGOT, email =>
    requestor
      .post(
        `${basePath}/auth/forgot-password`,
        {email},
        {withCredentials: true},
      )
      .then(response => response.data),
  );
}

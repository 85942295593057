import React from "react";
import _ from "underscore";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import ReactSelectLabel from "common_components/react_select_label";
import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";

import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

const matchLogics = {
  a_equals_b: "A equals B exactly",
  a_contains_b: "A contains B",
  a_does_not_contain_b: "A does not contain B",
  a_ends_with_b: "A ends with B",
  a_starts_with_b: "A starts with B",
  a_does_not_equal_b_exactly: "A does not equal B exactly",
  a_matches_list_b: "A is the same list as B",
  a_does_not_match_list_b: "A is not the same list as B",
};

function initialise(state) {
  return {
    topic_a: {
      topic_id: null,
      parameter_id: null,
    },
    topic_b: {
      topic_id: null,
      parameter_id: null,
    },
    match_logic: null,
    ..._.pick(state, "topic_a", "topic_b", "match_logic"),
  };
}

function validate(issue) {
  if (!issue.rules.topic_a.topic_id || !issue.rules.topic_a.parameter_id) {
    return {rulesError: {topic_a: "You must set topicA and its parameter"}};
  }
  if (!issue.rules.topic_b.topic_id || !issue.rules.topic_b.parameter_id) {
    return {rulesError: {topic_b: "You must set topicB and its parameter"}};
  }
  if (
    issue.rules.topic_a.topic_id &&
    issue.rules.topic_b.topic_id &&
    issue.rules.topic_a.topic_id === issue.rules.topic_b.topic_id &&
    issue.rules.topic_a.parameter_id === issue.rules.topic_b.parameter_id
  ) {
    return {rulesError: {topic_b: "Topics' parameters must be different"}};
  }
  if (!issue.rules.match_logic) {
    return {rulesError: {match_logic: "You must set match logic"}};
  }
}

class Component extends React.Component {
  render() {
    const {rules, rulesError, topics, editLevel} = this.props;
    const topicA =
      rules.topic_a && this.props.topicsById[rules.topic_a.topic_id];
    const topicB =
      rules.topic_b && this.props.topicsById[rules.topic_b.topic_id];
    const {
      isNonOverridableFieldsDisabled,
      overridableFieldsStyle,
      disabled,
    } = this.props;
    return (
      <div>
        <div>
          <div>Topic A</div>
          <div style={{margin: "0 1em"}}>
            <ReactSelectLabel>Name</ReactSelectLabel>
            <Select
              className="topic"
              multi={false}
              value={rules.topic_a && rules.topic_a.topic_id}
              options={topics
                .filter(topic =>
                  topic.parameters.find(
                    parameter => parameter.parameter_type === "regex",
                  ),
                )
                .map(topic => ({
                  label: topic.name,
                  value: topic.id,
                }))}
              onChange={this.updateTopic("topic_a")}
              clearable={false}
              disabled={
                isNonOverridableFieldsDisabled ||
                disabled ||
                (editLevel && editLevel !== "base")
              }
              filterOption={filterByWord}
              link={getTopicLink(
                this.props.organisationId,
                rules.topic_a && rules.topic_a.topic_id,
              )}
            />
          </div>
          <div style={{margin: "1rem"}}>
            <ReactSelectLabel>Parameter</ReactSelectLabel>
            <Select
              className="parameter"
              multi={false}
              value={rules.topic_a && rules.topic_a.parameter_id}
              options={
                topicA &&
                topicA.parameters
                  .filter(parameter => parameter.parameter_type === "regex")
                  .map(parameter => ({
                    label: parameter.name,
                    value: parameter.id,
                  }))
              }
              onChange={this.updateParameter("topic_a")}
              clearable={false}
              disabled={
                isNonOverridableFieldsDisabled ||
                disabled ||
                (editLevel && editLevel !== "base")
              }
              filterOption={filterByWord}
              link={getParameterLink(
                this.props.organisationId,
                rules.topic_a && rules.topic_a.topic_id,
                rules.topic_a && rules.topic_a.parameter_id,
              )}
            />
          </div>
          {renderError(rulesError, "topic_a")}
        </div>

        <div>
          <div>Topic B</div>
          <div style={{margin: "0 1em"}}>
            <ReactSelectLabel>Name</ReactSelectLabel>
            <Select
              className="topic"
              multi={false}
              value={rules.topic_b && rules.topic_b.topic_id}
              options={topics
                .filter(topic =>
                  topic.parameters.find(
                    parameter => parameter.parameter_type === "regex",
                  ),
                )
                .map(topic => ({
                  label: topic.name,
                  value: topic.id,
                }))}
              onChange={this.updateTopic("topic_b")}
              clearable={false}
              disabled={
                isNonOverridableFieldsDisabled ||
                disabled ||
                (editLevel && editLevel !== "base")
              }
              filterOption={filterByWord}
              link={getTopicLink(
                this.props.organisationId,
                rules.topic_b && rules.topic_b.topic_id,
              )}
            />
          </div>
          <div style={{margin: "1rem"}}>
            <ReactSelectLabel>Parameter</ReactSelectLabel>
            <Select
              className="parameter"
              multi={false}
              value={rules.topic_b && rules.topic_b.parameter_id}
              options={
                topicB &&
                topicB.parameters
                  .filter(parameter => parameter.parameter_type === "regex")
                  .map(parameter => ({
                    label: parameter.name,
                    value: parameter.id,
                  }))
              }
              onChange={this.updateParameter("topic_b")}
              clearable={false}
              disabled={
                isNonOverridableFieldsDisabled ||
                disabled ||
                (editLevel && editLevel !== "base")
              }
              filterOption={filterByWord}
              link={getParameterLink(
                this.props.organisationId,
                rules.topic_b && rules.topic_b.topic_id,
                rules.topic_b && rules.topic_b.parameter_id,
              )}
            />
          </div>
          {renderError(rulesError, "topic_b")}
        </div>
        <SelectField
          value={rules.match_logic}
          onChange={this.updateMatchLogic}
          errorText={rulesError && rulesError.match_logic}
          style={{
            width: "20em",
            zIndex: "0",
            ...overridableFieldsStyle,
          }}
          floatingLabelText="Match Logic"
          disabled={
            isNonOverridableFieldsDisabled ||
            disabled ||
            (editLevel && editLevel !== "base")
          }
        >
          {Object.keys(matchLogics).map(ml => (
            <MenuItem key={ml} value={ml} primaryText={matchLogics[ml]} />
          ))}
        </SelectField>
      </div>
    );
  }
  updateTopic = topic => value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      [topic]: {
        topic_id: value.value,
        parameter_id: null,
      },
    });
  };
  updateParameter = topic => value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      [topic]: {
        ...rules[topic],
        parameter_id: value.value,
      },
    });
  };
  updateMatchLogic = (e, i, value) => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      match_logic: value,
    });
  };
}

export default {
  initialise,
  validate,
  component: Component,
};

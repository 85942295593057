export default function joinNonEmptyLists(...args) {
  let result = [];
  args.forEach(argList => {
    if (Array.isArray(argList)) {
      if (
        !(
          argList.length === 1 &&
          Array.isArray(argList[0]) &&
          argList[0].length === 0
        )
      ) {
        result = result.concat(argList);
      }
    }
  });
  return result;
}

import JSONPath from "JSONPath";

function updateClauseByPath(clause, clausePart, _path) {
  const path = _path
    .split("[")
    .join(".clauseNodes[")
    .replace("root", "$.nodes");

  const el = JSONPath.eval(clause, path)[0];

  Object.assign(el, clausePart);

  return clause;
}

export default updateClauseByPath;

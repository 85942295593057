import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes, {LlmStatusActionTypes} from "../constants/action_types";
import basePath from "base_path";

export type LlmStatusAction = {
  type: LlmStatusActionTypes["FETCH_LLM_STATUS"]["SUCCESS"];
  payload: string;
};

export default function fetchLlmStatus(requestor) {
  return createReduxPromiseAction(ActionTypes.FETCH_LLM_STATUS, () =>
    requestor
      .get(`${basePath}/llm/status`, {
        withCredentials: true,
      })
      .then(response => response.data),
  );
}

import _ from "underscore";

function getClauseAtomsByTopic(documentClauses, searchTopic) {
  const {id: topicId} = searchTopic;

  const atoms = [];
  function getAtoms(nodes, clause) {
    if (
      nodes.text &&
      nodes.text.toLowerCase() !== "and" &&
      nodes.text.toLowerCase() !== "or"
    ) {
      nodes.topics &&
        nodes.topics.forEach(topic => {
          if (topic.topic_id === topicId && !topic.is_deleted) {
            atoms.push({
              reference: nodes.reference,
              text: nodes.partial_text,
              full_text: nodes.text,
              id: nodes.id,
              clause_full_reference: clause.full_reference,
              clause_reference: clause.reference,
              clause_id: clause.id,
              clause_order: nodes.order,
              nodes: clause.nodes,
              topics: [searchTopic],
            });
          }
        });
    }

    if (nodes.clauseNodes) {
      nodes.clauseNodes.forEach(child => getAtoms(child, clause));
    }
  }

  _.forEach(documentClauses, clauses => {
    clauses.forEach(clause => {
      getAtoms(clause.nodes, clause);
    });
  });
  return atoms;
}

export default getClauseAtomsByTopic;

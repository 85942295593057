const _ = require("underscore");

/* eslint-disable @typescript-eslint/no-unused-vars */

function getKey(item, joinKey) {
  let key;
  if (joinKey === "clause_id") {
    key = joinKey;
  } else if (joinKey === "clausepart_reference") {
    key = "reference";
  } else {
    key = "id";
  }
  return item[key];
}

module.exports = function areConceptsInClauseRules(
  data,
  joinKey,
  returnValues,
) {
  return {
    Exp(e) {
      const result = e.eval();
      return returnValues ? result : result.length > 0;
    },
    Rule(e) {
      const result = e.eval();
      return result;
    },
    Rule_and(e) {
      const result = e.eval();
      return result;
    },
    Rule_or(e) {
      return e.eval();
    },
    AndExp(left, list, right) {
      const result = list.eval().reduce((state, item) => {
        if (!state) {
          return item;
        }
        return _.intersection(state, item);
      });
      return result;
    },
    NonemptyListOf(first, connector, rest) {
      return [first.eval()].concat(rest.eval());
    },
    _iter(...list) {
      return list.map(item => item.eval());
    },
    OrExp(left, list, right) {
      const result = list.eval().reduce((state, item) => {
        if (!state) {
          return item;
        }
        return _.union(state, item);
      });
      return result;
    },
    ConceptDescriptor_topic(_topicId) {
      const topicId = _topicId.eval();
      return data
        .filter(item => item.topics[topicId])
        .map(item => getKey(item, joinKey));
    },
    ConceptDescriptor_negated_topic(not, _topicId) {
      const topicId = _topicId.eval();
      return data
        .filter(item => !item.topics[topicId])
        .map(item => getKey(item, joinKey));
    },
    ConceptDescriptor_topic_and_param(_topicId, _paramId) {
      const topicId = _topicId.eval();
      const paramId = _paramId.eval();
      return data
        .filter(item => item.topics?.[topicId]?.parameters?.[paramId])
        .map(item => getKey(item, joinKey));
    },
    ConceptDescriptor_negated_topic_and_param(not, _topicId, _paramId) {
      const topicId = _topicId.eval();
      const paramId = _paramId.eval();
      return data
        .filter(item => !item.topics?.[topicId]?.parameters?.[paramId])
        .map(item => getKey(item, joinKey));
    },
    ConceptDescriptor_topic_and_param_value(_topicId, _paramId, _value) {
      const topicId = _topicId.eval();
      const paramId = _paramId.eval();
      const unQuotedValue = _value.eval();
      const value = unQuotedValue.match(/^'(.*)'$/)
        ? unQuotedValue.replace(/^'(.*)'$/, "$1")
        : unQuotedValue;
      return data
        .filter(
          item =>
            (item.topics?.[topicId]?.parameters?.[paramId]?.values ?? {})[
              value
            ],
        )
        .map(item => getKey(item, joinKey));
    },
    ConceptDescriptor_negated_topic_and_param_value(
      not,
      _topicId,
      _paramId,
      _value,
    ) {
      const topicId = _topicId.eval();
      const paramId = _paramId.eval();
      const unQuotedValue = _value.eval();
      const value = unQuotedValue.match(/^'(.*)'$/)
        ? unQuotedValue.replace(/^'(.*)'$/, "$1")
        : unQuotedValue;
      return data
        .filter(
          item =>
            !(item.topics?.[topicId]?.parameters?.[paramId]?.values ?? {})[
              value
            ],
        )
        .map(item => getKey(item, joinKey));
    },
    TopicId(value) {
      return value.eval();
    },
    ParameterId(value) {
      return value.eval();
    },
    Value(value) {
      return this.sourceString;
    },
    number(value) {
      return this.sourceString;
    },
  };
};

import React, {useCallback, useEffect, useState, useRef} from "react";
import requestor from "requestor";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

import usePrevious from "utils/hooks/use_previous";
import basePath from "base_path";
import useInterval from "utils/hooks/use_interval";

const FETCH_INTERVAL_TIME = 10 * 60 * 1000;
const REFRESH_INTERVAL_TIME = 10 * 1000;

const isNotLoggedIn = status =>
  [false, "unknown", "unauthorized"].indexOf(status) !== -1;

const fetchData = () =>
  requestor
    .get(`${basePath}/auth/current-user`, {withCredentials: true})
    .then(response => response.data)
    .catch(() => null);

function TimeoutLogoutDialog(props) {
  const {user = {}} = props;
  const [isOpen, updateIsOpen] = useState(false);
  const logOutInterval = useRef(null);

  const prevUser = usePrevious(user);

  const onButtonClick = () => {
    updateIsOpen(false);
    props.clearUser();
    props.router.push("/login");
  };

  useEffect(() => {
    if (
      user &&
      prevUser &&
      isNotLoggedIn(prevUser["logged-in"]) &&
      user["logged-in"] === true
    ) {
      if (logOutInterval.current) {
        return;
      }
      logOutInterval.current = setInterval(async () => {
        const res = await fetchData();
        if (!res || !res["logged-in"]) {
          updateIsOpen(true);
          clearInterval(logOutInterval.current);
          logOutInterval.current = null;
        }
      }, FETCH_INTERVAL_TIME);
    }
    if (
      logOutInterval.current &&
      user &&
      prevUser &&
      prevUser["logged-in"] === true &&
      isNotLoggedIn(user["logged-in"])
    ) {
      clearInterval(logOutInterval.current);
      logOutInterval.current = null;
    }

    if (
      prevUser &&
      prevUser["logged-in"] === true &&
      user["logged-in"] === "unauthorized"
    ) {
      updateIsOpen(true);
    }
    return () => {
      clearInterval(logOutInterval.current);
      logOutInterval.current = null;
    };
  }, [user["logged-in"]]);

  const refresh = useCallback(async () => {
    const res = await fetchData();
    if (res["logged-in"] === true && isNotLoggedIn(user["logged-in"])) {
      updateIsOpen(false);
    }
  }, []);

  useInterval({
    callback: refresh,
    delay: REFRESH_INTERVAL_TIME,
    disabled: !isOpen,
  });

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Please login again</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session has been logged out after 24 hours. Please click here to
          login again or if you are logged in on a different tab refresh the
          page.
        </DialogContentText>
        <div
          style={{
            margin: "10px 0px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onButtonClick}
            style={{borderRadius: 0}}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={refresh}
            style={{borderRadius: 0, marginLeft: "10px"}}
          >
            Refresh
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default TimeoutLogoutDialog;

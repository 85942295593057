const postBroadcastData = (key, value) => {
  if (!("BroadcastChannel" in self)) {
    return;
  }
  try {
    const channel = new self.BroadcastChannel(key);
    channel.postMessage(value);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("BroadcastChannel\n", error);
  }
};

export default postBroadcastData;

/* eslint-disable jsdoc/require-param */
/* eslint-disable jsdoc/require-param-type */
/* eslint-disable jsdoc/require-param-description */
/* eslint-disable jsdoc/require-returns */
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import styled from "styled-components";

import * as colors from "material-ui/styles/colors";

/**
 * This is an awkward cleaning step to achieve backwards compatibility with the
 * old template format, which permitted the use of asterisks followed by
 * whitespace to signify inline emphasis, whereas markdown would interpret this
 * as a list item.
 */
export const trimTrailingWhiteSpaceFromAsterisks = (markdown: string) =>
  markdown.replace(/^[ \t]*\*[ \t]+/gm, "*");

/**
 * This is another hack to preserve backwards compatibility with the old
 * template format. In markdown, newlines preceded by a backslash are
 * preserved. We want all newlines to be preserved, matching the old template
 * rendering behaviour which included none of markdown's newline squashing.
 */
const preserveWhitespace = (markdown: string) => {
  // let lastIndex = 0;
  const result = markdown
    .trim()
    .replace(/[\n\r][ \t]+[\n\r]/gm, "\n\n")
    .replace(/\n/gm, (match, index, wholeString) => {
      // const startOfPrevLine = wholeString.substring(lastIndex, index);
      const startOfNextLine = wholeString.substring(
        index + match.length,
        index + match.length + 10,
      );
      // lastIndex = index + match.length;
      // if (
      //   lastIndex > 0 &&
      //   shouldOmitBreak(startOfPrevLine)
      // ) {
      //   console.log("break: omit for prev", {startOfPrevLine});
      //   return "\n\n";
      // }
      if (shouldOmitBreak(startOfNextLine)) {
        // console.log("break: omit for next", {startOfPrevLine, startOfNextLine});
        return "\n\n";
      }
      // if (startOfPrevLine === "\n" || startOfNextLine.charAt(0) === "\n") {
      if (startOfNextLine.charAt(0) === "\n") {
        // console.log("break: double new", {startOfPrevLine, startOfNextLine});
        return "\n\n<br />\n\n";
      }
      // console.log("break: nothing", {index, wholeString, startOfPrevLine, startOfNextLine});
      return "\n\n";
    });
  // console.log({markdown, result});
  return `${result}\n`;
};

const markdownCommands = {
  "-": true,
  "*": true,
  "#": true,
  "1": true,
  "2": true,
  "3": true,
  "4": true,
  "5": true,
  "6": true,
  "7": true,
  "8": true,
  "9": true,
};
function shouldOmitBreak(line: string): boolean {
  const lineFirstChar = line.replace(/^\s+/, "").charAt(0);
  if (lineFirstChar === "*") {
    const newlinePosition = line.replace(/\r/g, "\n").indexOf("\n");
    const wholeLine =
      newlinePosition === -1 ? line : line.substring(0, newlinePosition);
    const isDotPoint = wholeLine.split("*").length % 2 === 0;
    return isDotPoint;
  }
  return markdownCommands[lineFirstChar] ?? false;
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  code {
    color: rgb(3, 155, 229);
  }
  strong {
    font-weight: 700;
  }
  h3,
  h4 {
    margin: 0.5em 0;
  }
  p {
    margin: 0.33em 0;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  ul,
  ol {
    margin: 0.25em 0;
    margin-block-start: 0.25em;
    margin-block-end: 0;
  }
  ol {
    padding-inline-start: 2em;
  }
  ul {
    padding-inline-start: 3em;
  }
`;

interface MarkdownProps {
  children: string;
  ignoreLegacy: boolean;
}

const Blue = styled.span`
  color: ${colors.lightBlue600};
`;

const CustomReactMarkdown = ({
  children,
  isNested,
}: {
  isNested?: boolean;
  children: string;
}) => (
  <ReactMarkdown
    skipHtml={false}
    children={children}
    components={{
      p: ({children}) =>
        // NOTE: This is a hack to prevent nested instances of this component
        // inserting paragraph wrappers at the top level.
        isNested ? <span>{children}</span> : <p>{children}</p>,
      // Backticks should retain old meaning ("blue" color), not markdown code blocks
      // NOTE: We have an awkward requirement to ensure markup contained inside
      // backticks is still rendered, resulting in the recursion here.
      // NOTE: This is a mess. It would be better to use a markdown renderer
      // that is more easily customisable to our relatively specific use case
      code: ({node}) => (
        <Blue>
          <CustomReactMarkdown isNested>
            {node.children
              .map(node => ("value" in node ? node.value : ""))
              .join("")}
          </CustomReactMarkdown>
        </Blue>
      ),
      // These override components are provided simply to (crudely) swap the
      // meaning of the single and double asterisk syntax, preserving the
      // meaning of asterisks in our legacy markup format.
      em: ({node}) => (
        <b>
          <CustomReactMarkdown isNested>
            {node.children
              .map(node => ("value" in node ? node.value : ""))
              .join("")}
          </CustomReactMarkdown>
        </b>
      ),
      strong: ({node}) => (
        <em>
          <CustomReactMarkdown isNested>
            {node.children
              .map(node => ("value" in node ? node.value : ""))
              .join("")}
          </CustomReactMarkdown>
        </em>
      ),
    }}
    rehypePlugins={[rehypeRaw]}
    remarkPlugins={[remarkGfm]}
  />
);

const Markdown = ({children, ignoreLegacy}: MarkdownProps) =>
  ignoreLegacy ? (
    <ReactMarkdown
      skipHtml={false}
      children={children}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
    />
  ) : (
    <Container>
      <CustomReactMarkdown>
        {preserveWhitespace(trimTrailingWhiteSpaceFromAsterisks(children))}
      </CustomReactMarkdown>
    </Container>
  );

export default Markdown;

import React, {Component} from "react";
import ReactDOM from "react-dom";
import scrollIntoView from "scroll-into-view";
import TextField from "material-ui/TextField";

import GuiTextEditor from "common_components/gui_text_editor";
import {isElementEntirelyInViewport} from "../../utils/is_visible";

const styles = {
  addClauseBlock: {
    padding: "0 2rem",
    fontSize: "1.25em",
  },
  headerBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    marginBottom: "0.4rem",
    cursor: "pointer",
    fontFamily: "Roboto, sans-serif",
    fontSize: "1.25em",
    fontWeight: "400",
  },
  subheadingReference: {
    marginRight: ".5em",
  },
  dot: {
    marginRight: "1.5em",
  },
  text: {},
};

export default class AddClauseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {headingText: ""};
    this.additionClause = props.additionClause;
  }

  componentDidMount() {
    if (!this.isEntirelyVisible()) {
      const node = ReactDOM.findDOMNode(this);
      scrollIntoView(node);
    }
    if (this.props.documentHasHeadings && this.headerTextFieldRef) {
      this.headerTextFieldRef.focus();
    }
  }

  render() {
    const {props, state} = this;
    const {reference} = props;
    const isUnnumberedSection = this.props.section.is_unnumbered;
    return (
      <div ref={this.createRootAddClauseFormRef} style={styles.addClauseBlock}>
        {props.documentHasHeadings &&
          !isUnnumberedSection && (
            <div style={styles.headerBlock}>
              <div style={styles.subheadingReference}>{props.reference}</div>
              <div style={styles.dot}>.</div>
              <TextField
                ref={this.createHeaderTextFieldRef}
                name="clauseCreationHeader"
                style={{fontSize: 19, width: "100%"}}
                value={state.headingText}
                onChange={this.onHeadingTextChange}
              />
            </div>
          )}
        <div
          style={{marginLeft: !props.documentHasHeadings ? "4.5em" : "unset"}}
        >
          <GuiTextEditor
            changeHandler={this.onSaveNewClause}
            closeHandler={this.props.hideAddClauseEditor}
            usedListFormatStylesPerLevel={
              this.props.usedListFormatStylesPerLevel
            }
            startingLevel={this.props.startingLevel}
            getRootEditorRef={this.getRootEditorRef}
            decimalPrefix={/^[\d.]+$/.test(reference) ? reference : undefined}
          />
        </div>
      </div>
    );
  }

  getRootEditorRef = () => this.rootAddClauseFormRef;

  onHeadingTextChange = (e, headingText) =>
    this.setState(() => ({headingText}));

  onSaveNewClause = contents => {
    this.props.saveNewClause(
      this.additionClause,
      contents,
      this.state.headingText,
    );
  };

  isEntirelyVisible() {
    const node = ReactDOM.findDOMNode(this);
    return isElementEntirelyInViewport(node, 0, findParentScroller);
  }

  createHeaderTextFieldRef = node => (this.headerTextFieldRef = node);
  createRootAddClauseFormRef = node => (this.rootAddClauseFormRef = node);
}

function findParentScroller(target) {
  return (
    target.className &&
    target.className.split(" ").find(className => className === "view-scroller")
  );
}

import React from "react";

import Topic from "./topic";
import TopicGroup from "./topic_group";
import constructHeadingTopicsData from "./utils/construct_heading_topics_data";

const styles = {
  topics: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "0.5em",
    justifyContent: "flex-start",
  },
};

export class HeadingItem extends React.Component {
  render() {
    const {
      topic,
      topicHoverEnd,
      topicHoverStart,
      topicContainerStyles,
      topicGroupStyles,
      ultimateTopicGroupStyles,
      areAllGroupsOpen,
    } = this.props;
    return topic.children ? (
      <TopicGroup
        nestedItems={topic.children.map((child, i) => (
          <HeadingItem {...this.props} key={i} topic={child} />
        ))}
        topic={topic}
        topicHoverEnd={topicHoverEnd}
        topicHoverStart={topicHoverStart}
        topicContainerStyles={topicContainerStyles}
        ultimateTopicGroupStyles={ultimateTopicGroupStyles}
        topicGroupStyles={topicGroupStyles}
        areAllGroupsOpen={areAllGroupsOpen}
      />
    ) : (
      <Topic {...this.props} topic={topic} hideGroup={true} />
    );
  }
}

function generateHeadingContent(props, showClausesTopicsSelectorValue) {
  const headingRawData = constructHeadingTopicsData(
    props.topics,
    props.selectedTopics,
    showClausesTopicsSelectorValue === "selected",
  );
  return headingRawData.length > 0 ? (
    <div className="topics" style={styles.topics}>
      {headingRawData.map((item, i) => (
        <HeadingItem key={i} topic={item} {...props} />
      ))}
    </div>
  ) : null;
}

export default generateHeadingContent;

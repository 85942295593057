import React from "react";
import Search from "material-ui/svg-icons/action/search";
import * as colors from "material-ui/styles/colors";

const style = {
  height: 16,
  width: 16,
  fill: colors.blue500,
  marginRight: 1,
};

function SearchIcon() {
  return <Search style={style} color={colors.grey50} />;
}

export default SearchIcon;

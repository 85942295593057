import React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

function RevertDocumentChangesDialog(props) {
  const actions = [
    <FlatButton
      key="revert-doc-changes-dialog-yes-button"
      label="YES"
      onClick={props.onConfirm}
    />,
    <FlatButton
      key="revert-doc-changes-dialog-no-button"
      label="NO"
      onClick={props.onDismiss}
    />,
  ];
  return (
    <Dialog
      className="dialog"
      title="Revert Document Changes"
      onRequestClose={props.onDismiss}
      open={true}
      actions={actions}
      bodyStyle={{overflowY: "inherit"}}
      modal={true}
    >
      <div style={{textAlign: "justify"}}>
        Are you sure you want to revert all changes?
      </div>
    </Dialog>
  );
}

export default RevertDocumentChangesDialog;

import Describer from "../../../utils/issues/issue_description/describer";
import getDateStatement from "./get_date_statement";

function describeReason(clauses, topicsById, issue) {
  const {rules} = issue;
  const describer = new Describer.IssueDescriber(
    issue,
    Object.values(topicsById),
  );
  return [
    `Parameter ${describer.parameterName}`,
    `of topic ${describer.topicName}`,
    "is",
    getDateStatement(rules.from, rules.to, rules.date),
  ].join(" ");
}

export default describeReason;

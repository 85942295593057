import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};
createReduxPromiseActionType(actionTypes, "USER_FETCH");
createReduxPromiseActionType(actionTypes, "USER_LOGIN");
createReduxPromiseActionType(actionTypes, "USER_AUTHENTICATE_AAD_TOKEN");
createReduxPromiseActionType(actionTypes, "USER_REGISTER");
createReduxPromiseActionType(actionTypes, "USER_LOGOUT");
createReduxPromiseActionType(actionTypes, "USER_PASSWORD_UPDATE");
createReduxPromiseActionType(actionTypes, "USER_PASSWORD_FORGOT");
createReduxPromiseActionType(actionTypes, "USER_PASSWORD_RESET");
createReduxPromiseActionType(actionTypes, "USER_UPDATE");
createReduxPromiseActionType(actionTypes, "USER_SET_UNAUTHORIZED");
createReduxPromiseActionType(actionTypes, "USER_CLEAR");

createReduxPromiseActionType(actionTypes, "WEBSOCKET_INIT");

createReduxPromiseActionType(actionTypes, "ERROR_CLEAR");

export default actionTypes;

import React, {useState} from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import * as colors from "@material-ui/core/colors";
import CloseIcon from "@material-ui/icons/Close";
import CheckboxesMenu from "./components/checkboxes_menu";
import IconButtons from "./components/icon_buttons";
import SaveCancelButtons from "./components/save_cancel_buttons";
import TopPanel from "./components/top_panel";
import DocumentReportPanel from "./components/document_report_panel";
import FiltersPanel from "../filters";
import getFiltersTitles from "./utils/get_filters_titles";
import RiskScore from "../risk_score";
import {filtersState} from "../filters/constants";
import ClearIcon from "@material-ui/icons/Clear";
import {filterTitlesStyles} from "../filters/styles";
import {makeStyles} from "@material-ui/core/styles";

const styles = {
  topPanelMain: {
    transition: "height 400ms linear",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fbfbfb",
    paddingTop: 0,
    position: "relative",
  },
  container: {
    backgroundColor: "white",
    border: `1px solid ${colors.grey[200]}`,
    margin: "0.25em",
  },
  topPanelScroller: {
    padding: "0",
    color: "#214a7c",
  },
  triangle: {
    width: "0",
    height: "0",
    borderStyle: "solid",
    borderWidth: "0 9px 10px 9px",
    borderColor: "transparent transparent #f2f8ff transparent",
    position: "absolute",
    top: "-10px",
  },
  topPanelContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: "100",
    flexWrap: "wrap",
  },
  size: {
    width: "16px",
    height: "16px",
  },
  filterBlock: {
    display: "flex",
    gap: "10px",
  },
  dialogWrapper: {
    display: "flex",
    position: "relative",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fbfbfb",
    borderBottom: "1px solid #e8e8e8",
    padding: "5px",
  },
  dialogActions: {
    padding: 10,
    justifyContent: "flex-start",
    backgroundColor: "#fbfbfb",
    borderTop: "1px solid #e8e8e8",
  },
  iconSize: {
    width: "20px",
  },
  buttonSize: {
    width: "30px",
    height: "30px",
  },
};

const dialogStyles = {
  root: {
    "& .MuiDialog-paperScrollPaper": {
      display: "flex",
      height: "calc(100% - 64px)",
      flexDirection: "column",
    },
  },
};

const useDevDialogStyles = makeStyles(dialogStyles);

function TopPanelContainer(props) {
  const {
    renderInSeparateWindow,
    triangleRightOffset,
    rootDivStyles,
    onDownloadXlsx,
    onDownloadDocx,
    hasExpandableView,
    children,
    triggerHighlightManualCorrections,
    highlightManualCorrections,
    showDeletedIssues,
    triggerShowDeletedIssues,
    itemIssuesFilterValues,
    changeItemIssuesFilterValues,
    reportType,
    reportId,
    reviewTrackingOn,
    startSelectedReportEditing,
    toggleReportPanelVisibility,
    toggleReportOpenInRightSidebar,
    isReportShownInRightSide,
    isSaveButtonShown,
    saveButtonHandler,
    cancelButtonHandler,
    addNewReportItem,
    reportLabel,
    projectId,
    issueset,
    reportSettings,
    issues,
  } = props;
  const classes = useDevDialogStyles();
  function getLinkPath() {
    return (
      `/organisation/${props.organisationId}/project/${projectId}` +
      `/document/${props.documentId}/report?id=${reportId}`
    );
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogShown, updateIsDialogShown] = useState(false);
  const showDialog = () => updateIsDialogShown(true);
  const hideDialog = () => updateIsDialogShown(false);
  const openMenu = event => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);
  const startEditingReport = () => {
    closeMenu();
    startSelectedReportEditing();
  };

  const showSelectionTriangle = false;

  const handleClearFilter = item =>
    changeItemIssuesFilterValues(
      filtersState.find(filter => filter.title === item),
    );
  const handleClearAllFilters = () => changeItemIssuesFilterValues("delete");

  const renderCheckedFiltersItems = () => (
    <div>
      {getFiltersTitles(filtersState, itemIssuesFilterValues).length ? (
        <div style={filterTitlesStyles.filtersPanel}>
          {getFiltersTitles(filtersState, itemIssuesFilterValues).map(item => (
            <div key={item} style={filterTitlesStyles.filterClips}>
              {item}
              <ClearIcon
                style={filterTitlesStyles.clearIcon}
                onClick={() => handleClearFilter(item)}
              />
            </div>
          ))}
          <div
            style={filterTitlesStyles.clearFiltersBtn}
            onClick={handleClearAllFilters}
          >
            <ClearIcon style={{height: "15px"}} />
            Clear All
          </div>
        </div>
      ) : null}
    </div>
  );

  const renderTopMenu = () => {
    if (props.footer) {
      return props.footer;
    }

    const hasLeftCheckbox =
      triggerHighlightManualCorrections || triggerShowDeletedIssues;

    return (
      <>
        <div style={styles.topPanelContainer}>
          <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
            {reportType === "rag_report" ? (
              <>
                <FiltersPanel
                  isFlipChecklistIcons={true}
                  changeItemIssuesFilterValues={changeItemIssuesFilterValues}
                  itemIssuesFilterValues={itemIssuesFilterValues}
                  reviewTrackinOn={reviewTrackingOn}
                  reportView={"top_panel"}
                />
                <RiskScore
                  issues={issues}
                  issueset={issueset}
                  isShow={reportSettings?.show_document_risk_score}
                />
              </>
            ) : null}
          </div>
          {reportType === "rag_report" ? (
            <SaveCancelButtons
              isShow={isSaveButtonShown}
              onSave={saveButtonHandler}
              onCancel={cancelButtonHandler}
              reportLabel={reportLabel || "Email"}
            />
          ) : (
            reportLabel || "Email"
          )}
          {props.isDocumentReport ? null : hasExpandableView &&
            !renderInSeparateWindow ? (
            <div style={{display: "flex"}}>
              <IconButtons
                onDownloadXlsx={onDownloadXlsx}
                onDownloadDocx={onDownloadDocx}
                hasLeftCheckbox={hasLeftCheckbox}
                showDialog={showDialog}
                getLinkPath={getLinkPath()}
                startSelectedReportEditing={startSelectedReportEditing}
                toggleReportPanelVisibility={toggleReportPanelVisibility}
                toggleReportOpenInRightSidebar={toggleReportOpenInRightSidebar}
                isReportShownInRightSide={isReportShownInRightSide}
                triggerHighlightManualCorrections={
                  triggerHighlightManualCorrections
                }
                highlightManualCorrections={highlightManualCorrections}
                triggerShowDeletedIssues={triggerShowDeletedIssues}
                showDeletedIssues={showDeletedIssues}
                reportType={reportType}
                addNewReportItem={addNewReportItem}
              />
            </div>
          ) : (
            <span
            // not sure what this styling was for, uncommented for now to see if any changes are noticed
            // style={{width: hasLeftCheckbox ? 290 : "unset"}}
            />
          )}
        </div>
        <div>{renderCheckedFiltersItems()}</div>

        <Dialog
          open={isDialogShown}
          onClose={hideDialog}
          fullWidth
          maxWidth="xl"
          classes={classes}
        >
          <div style={styles.dialogWrapper}>
            <div style={styles.filterBlock}>
              {reportType === "rag_report" ? (
                <>
                  <FiltersPanel
                    isFlipChecklistIcons={true}
                    changeItemIssuesFilterValues={changeItemIssuesFilterValues}
                    itemIssuesFilterValues={itemIssuesFilterValues}
                    reviewTrackinOn={reviewTrackingOn}
                    reportView={"top_panel"}
                  />
                  <RiskScore
                    issueset={issueset}
                    issues={issues}
                    isShow={reportSettings?.show_document_risk_score}
                  />
                </>
              ) : null}
            </div>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              {reportType === "rag_report" ? (
                <SaveCancelButtons
                  isShow={isSaveButtonShown}
                  onSave={saveButtonHandler}
                  onCancel={cancelButtonHandler}
                  reportLabel={reportLabel || "Email"}
                />
              ) : (
                reportLabel || "Email"
              )}
            </div>
            <div style={{display: "flex"}}>
              <CheckboxesMenu
                openMenu={openMenu}
                anchorEl={anchorEl}
                closeMenu={closeMenu}
                startEditingReport={startEditingReport}
                highlightManualCorrections={highlightManualCorrections}
                triggerHighlightManualCorrections={
                  triggerHighlightManualCorrections
                }
                reportType={reportType}
                showDeletedIssues={showDeletedIssues}
                triggerShowDeletedIssues={triggerShowDeletedIssues}
                addNewReportItem={addNewReportItem}
              />
              <IconButton style={styles.buttonSize} onClick={hideDialog}>
                <CloseIcon style={styles.iconSize} />
              </IconButton>
            </div>
          </div>
          {renderCheckedFiltersItems()}
          <DialogContent style={{paddingTop: 0}}>{children}</DialogContent>
        </Dialog>
      </>
    );
  };

  return props.isDocumentReport ? (
    <DocumentReportPanel
      scrollerStyle={styles.topPanelScroller}
      children={children}
      onContainerClick={props.onContainerClick}
      onDownloadXlsx={onDownloadXlsx}
      onDownloadDocx={onDownloadDocx}
      triggerHighlightManualCorrections={triggerHighlightManualCorrections}
      highlightManualCorrections={highlightManualCorrections}
      triggerShowDeletedIssues={triggerShowDeletedIssues}
      showDeletedIssues={showDeletedIssues}
      hasExpandableView={hasExpandableView}
      renderInSeparateWindow={renderInSeparateWindow}
      itemIssuesFilterValues={itemIssuesFilterValues}
      changeItemIssuesFilterValues={changeItemIssuesFilterValues}
      project={props.project}
      renderTopMenu={renderTopMenu}
      reviewTrackingOn={reviewTrackingOn}
      isSaveButtonShown={isSaveButtonShown}
      saveButtonHandler={saveButtonHandler}
      cancelButtonHandler={cancelButtonHandler}
      startSelectedReportEditing={startSelectedReportEditing}
      reportType={reportType}
      issueset={issueset}
      reportSettings={reportSettings}
      issues={issues}
      addNewReportItem={addNewReportItem}
    />
  ) : (
    <TopPanel
      styles={styles}
      rootDivStyles={rootDivStyles}
      isReportShownInRightSide={isReportShownInRightSide}
      renderInSeparateWindow={renderInSeparateWindow}
      showSelectionTriangle={showSelectionTriangle}
      triangleRightOffset={triangleRightOffset}
      renderTopMenu={renderTopMenu}
      children={children}
      onContainerClick={props.onContainerClick}
      itemIssuesFilterValues={itemIssuesFilterValues}
      changeItemIssuesFilterValues={changeItemIssuesFilterValues}
      reportType={reportType}
      projectId={projectId}
      issueset={issueset}
    />
  );
}

export default TopPanelContainer;

import _ from "lodash";
import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function changesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case UserActionTypes.USER_LOGOUT.SUCCESS:
    case ActionTypes.DOCUMENT_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.DOCUMENT_FETCH.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_DEFINITION_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_HEADING_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_DELETE.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_UPDATE.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_ADDED_UPDATE.SUCCESS:
    case ActionTypes.DOCUMENT_DEFINITION_ADDITION_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_TEXT_REPLACE.SUCCESS:
    case ActionTypes.DOCUMENT_HEADING_ALTER.SUCCESS:
      return !action.payload.changes ? state : action.payload.changes;
    case ActionTypes.DOCUMENT_CLAUSEPART_ALTER.SUCCESS:
    case ActionTypes.DOCUMENT_DEFINITION_DELETE.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPARTS_REMOVE.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_DELETIONS_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_DEFINITION_DELETION_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_HEADING_REMOVE.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_CHANGES_REVERT.SUCCESS:
      return action.payload;
    case ActionTypes.DOCUMENT_STATE_REVERT.SUCCESS:
      return [];
    case ActionTypes.DOCUMENT_HEADING_CHANGES_REVERT.SUCCESS:
      return state !== UNINITIALISED && action.payload && action.payload.id
        ? state.filter(change => change.new_heading_id !== action.payload.id)
        : state;
    case ActionTypes.DOCUMENT_ISSUE_CORRECT.SUCCESS:
      return state === UNINITIALISED
        ? action.payload.documentChanges
        : [...state, ...action.payload.document_changes];
    case ActionTypes.DOCUMENT_ISSUE_REVERT.SUCCESS:
      return state === UNINITIALISED
        ? state
        : state
            .slice()
            .filter(
              change =>
                change.document_issue_id !== action.payload.document_issue.id,
            );
    case ActionTypes.DOCUMENT_CLAUSEPART_ADDITION_REVERT.SUCCESS:
      return Array.isArray(action.payload.changes)
        ? action.payload.changes
        : state;
    case ActionTypes.DOCUMENT_CLAUSEPARTS_REMOVE.REQUEST: {
      const deletionChanges = action.payload.find(
        payloadItem =>
          Array.isArray(payloadItem) &&
          payloadItem[0] &&
          payloadItem[0].type === "clausepart_deletion",
      );
      if (deletionChanges) {
        const headingData = action.payload.find(payloadItem =>
          _.isPlainObject(payloadItem),
        );
        return [
          ...state,
          ...deletionChanges,
          ...(headingData ? [headingData.deletionChange] : []),
        ];
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSEPART_DELETIONS_REVERT.REQUEST: {
      const clausepartIdsToRevert = action.payload.find(
        payloadItem =>
          Array.isArray(payloadItem) &&
          payloadItem[0] &&
          typeof payloadItem[0] === "number",
      );
      if (clausepartIdsToRevert) {
        const headingData = action.payload.find(payloadItem =>
          _.isPlainObject(payloadItem),
        );
        return state.filter(
          change =>
            !(
              (change.type === "clausepart_deletion" &&
                clausepartIdsToRevert.includes(change.new_clausepart_id)) ||
              (headingData && headingData.id === change.new_heading_id)
            ),
        );
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_DELETE.REQUEST: {
      const deletionChange = action.payload.find(
        payloadItem => payloadItem.type === "clause_deletion",
      );
      if (deletionChange) {
        return [...state, deletionChange];
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_REVERT.REQUEST: {
      const clauseIdToRevert = action.payload[4];
      if (clauseIdToRevert) {
        return state.filter(
          change =>
            !(
              change.type === "clause_deletion" &&
              change.new_clause_id === clauseIdToRevert
            ),
        );
      }
      return state;
    }
    case ActionTypes.DOCUMENT_HEADING_REMOVE.REQUEST: {
      const deletionChange = action.payload.find(
        payloadItem => payloadItem.type === "clauseheading_deletion",
      );
      if (deletionChange) {
        return [...state, deletionChange];
      }
      return state;
    }
    case ActionTypes.DOCUMENT_HEADING_CHANGES_REVERT.REQUEST: {
      const headingIdToRevert = action.payload[4];
      if (headingIdToRevert) {
        return state.filter(
          change => headingIdToRevert !== change.new_heading_id,
        );
      }
      return state;
    }
    case ActionTypes.DOCUMENT_DEFINITION_DELETE.REQUEST: {
      const deletionChange = action.payload.find(
        payloadItem => payloadItem.type === "definition_deletion",
      );
      if (deletionChange) {
        return [...state, deletionChange];
      }
      return state;
    }
    case ActionTypes.DOCUMENT_DEFINITION_DELETION_REVERT.REQUEST: {
      const clauseId = action.payload[4];
      const term = action.payload[5];
      if (clauseId && term) {
        return state.filter(
          change =>
            !(
              change.type === "definition_deletion" &&
              clauseId === change.new_clause_id &&
              term === change.new_reference
            ),
        );
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_UPDATE.REQUEST: {
      const sectionId = action.payload[3];
      const clauseId = action.payload[4];
      if (sectionId && clauseId) {
        const pendingSaveChange = {
          type: "pending_save",
          action_type: "DOCUMENT_CLAUSE_UPDATE",
          new_section_id: sectionId,
          new_clause_id: clauseId,
        };
        return [...state, pendingSaveChange];
      }
      return state;
    }
    case ActionTypes.DOCUMENT_DEFINITION_ADDITION_REVERT.REQUEST: {
      const sectionId = action.payload[3];
      const clauseId = action.payload[4];
      const clausepartId = action.payload[6];
      if (sectionId && clauseId && clausepartId) {
        const pendingSaveChange = {
          type: "pending_save",
          action_type: "DOCUMENT_DEFINITION_ADDITION_REVERT",
          new_section_id: sectionId,
          new_clause_id: clauseId,
          new_clausepart_id: clausepartId,
        };
        return [...state, pendingSaveChange];
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSEPART_ADDED_UPDATE.REQUEST:
      return getStateWithPendingChange(
        state,
        action.payload,
        "DOCUMENT_CLAUSEPART_ADDED_UPDATE",
      );
    case ActionTypes.DOCUMENT_CLAUSEPART_CHANGES_REVERT.REQUEST:
      return getStateWithPendingChange(
        state,
        action.payload,
        "DOCUMENT_CLAUSEPART_CHANGES_REVERT",
      );
    case ActionTypes.DOCUMENT_CLAUSEPART_ADDITION_REVERT.REQUEST:
      return getStateWithPendingChange(
        state,
        action.payload,
        "DOCUMENT_CLAUSEPART_ADDITION_REVERT",
      );
    case ActionTypes.DOCUMENT_CLAUSEPART_ALTER.REQUEST:
      return getStateWithPendingChange(
        state,
        action.payload,
        "DOCUMENT_CLAUSEPART_ALTER",
      );
    default:
      return state;
  }
}

function getStateWithPendingChange(state, payload, actionType) {
  const sectionId = payload[3];
  const clauseId = payload[4];
  const clausepartId = payload[5];
  if (sectionId && clauseId && clausepartId) {
    const pendingSaveChange = {
      type: "pending_save",
      action_type: actionType,
      new_section_id: sectionId,
      new_clause_id: clauseId,
      new_clausepart_id: clausepartId,
    };
    return [...state, pendingSaveChange];
  }
  return state;
}

import React from "react";

import * as colors from "@material-ui/core/colors";

import ItemContainer from "../helper_components/item_container";

const styles = {
  bodyContainer: {
    fontWeight: 400,
    color: colors.grey[600],
  },
  link: {
    color: colors.lightBlue[700],
    marginLeft: "0.36rem",
    cursor: "pointer",
  },
};

function RelatedSearches(props) {
  const {relatedSearches: relatedSearchesRaw = [], headerStyles = {}} = props;

  const relatedSearches = relatedSearchesRaw.filter(
    rs => rs.name.trim() && rs.search_text.trim(),
  );

  if (relatedSearches.length === 0) {
    return null;
  }

  return (
    <ItemContainer
      title="Related Searches"
      expandable={true}
      onExpandMenu={props.onOpen}
      isMenuExpanded={props.isOpen}
      headerStyles={headerStyles}
      containerStyles={{
        flex: "1 0 auto",
      }}
      innerBodyStyles={styles.bodyContainer}
      useFlippedInnerBodyStyles={true}
    >
      {relatedSearches.map((item, itemIndex) => (
        <div key={`${item.name}-${itemIndex}`}>
          <span>{itemIndex + 1}.</span>
          <span
            style={styles.link}
            onClick={() => {
              const searchText =
                item.search_text.indexOf("&") >= 0
                  ? item.search_text.substring(0, item.search_text.indexOf("&"))
                  : item.search_text;
              props.triggerCustomEvent(
                "showSearchItemSidebarPanel",
                searchText,
                {ignoreContractType: true, searchType: "not_issue"},
              );
            }}
          >
            {item.name}
          </span>
        </div>
      ))}
    </ItemContainer>
  );
}

export default RelatedSearches;

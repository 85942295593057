import React, {useEffect} from "react";
import getIssueColorValue from "../../../utils/issues/get_issue_color_value";
import constructIssueNameTextValue from "../utils/construct_issue_name_text_value";
import {getUpdatedManualCorrections} from "../../../utils/manual_corrections_utils";
import UnreadIssueDot from "../../checklist/icons/unread_issue_dot";
import IssueItemIconMenu from "../../checklist/issue_item_icon_menu";
import EditableField from "./editable_field";
import TextValue from "./text_value";
import RagScoreField from "./rag_score_field";
import AdditionalCircle from "../../checklist/additional_circle";

const styles = {
  circle: {
    width: 14,
    height: 14,
    borderRadius: "50%",
    margin: "0.3rem auto",
  },
  block: {
    display: "flex",
    justifyContent: "center",
  },
};

const CellChild = ({
  cellChild,
  rowChild,
  cellChildIndex,
  updateReviewState,
  isTextFieldOnEditing,
  lockedTextValue,
  getTextValue,
  isTrackChangeMode,
  project,
  updateDocumentIssue,
  correctDocumentIssueManually,
  user,
  scrollToClause,
  selectedReportId,
  issueset,
  showButtonsHandler,
  saveTrigger,
  cancelTrigger,
  updateFreeTextFieldValue,
  updateAdditionalReportItems,
  issue,
  isMarkdown,
}) => {
  const {isEditable, fieldName} = rowChild;
  const CircleIcon = ({style}) => <div style={{...styles.circle, ...style}} />;
  const getCellClicker = (clause, scrollToClause) => {
    if (!clause || !scrollToClause) {
      return;
    }
    return () => scrollToClause(clause);
  };

  useEffect(() => {
    if (cellChild?.value) {
      getTextValue(cellChild?.value);
    }
  }, []);

  const onEditSave = (newTextValue, isLocked) => {
    if (rowChild?.additional_item) {
      return updateAdditionalReportItems({
        id: rowChild.id,
        value: newTextValue,
        type: rowChild.fieldName,
      });
    }
    if (
      rowChild.fieldName === "free_text" &&
      rowChild.field_id &&
      updateFreeTextFieldValue
    ) {
      updateFreeTextFieldValue(rowChild.field_id, newTextValue);
    } else {
      if (issue.correction) {
        return;
      }

      if (isLocked && cellChild?.value) {
        getTextValue(cellChild.value);
      }

      correctDocumentIssueManually(issue, {
        // TODO issuesets - need to save this per issueset properly??
        manual_corrections: getUpdatedManualCorrections(
          issue.manual_corrections,
          selectedReportId,
          fieldName,
          fieldName === "issue_name"
            ? constructIssueNameTextValue(newTextValue, issue)
            : newTextValue,
          null,
          false,
          isLocked,
        ),
      });
    }
  };

  switch (cellChild.type) {
    case "paragraph": {
      if (cellChild.children) {
        return (
          <div key={cellChildIndex} style={{wordBreak: "break-word"}}>
            {cellChild.value && <span>{cellChild.value}:&nbsp;</span>}
            {cellChild.children.map((child, childIndex) => (
              <span
                key={`${cellChildIndex}-${childIndex}`}
                onClick={
                  child.applicableClause && scrollToClause
                    ? getCellClicker(child.applicableClause, scrollToClause)
                    : undefined
                }
                style={{
                  cursor:
                    child.applicableClause && scrollToClause
                      ? "pointer"
                      : "default",
                }}
              >
                {`${child.value}${
                  cellChild.children.length - 1 === childIndex ? "" : ", "
                }`}
              </span>
            ))}
          </div>
        );
      }
      const {applicableClause} = cellChild;
      const itemClicker =
        applicableClause && scrollToClause
          ? getCellClicker(applicableClause, scrollToClause)
          : undefined;
      return (
        <div
          key={cellChildIndex}
          onClick={itemClicker}
          style={{
            ...(cellChild.spacing && cellChild.spacing.after
              ? {marginBottom: "10px"}
              : {}),
            ...(cellChild.bold ? {fontWeight: "bold"} : {}),
            borderLeft: `${(cellChild.indent || 0) * 10}px solid transparent`,
            paddingLeft: `${cellChild.indent > 0 ? 2 : 0}px`,
          }}
        >
          {isEditable ? (
            <EditableField
              fieldName={fieldName}
              onEditSave={onEditSave}
              value={cellChild.value.trim()}
              showButtonsHandler={showButtonsHandler}
              saveTrigger={saveTrigger}
              cancelTrigger={cancelTrigger}
              isTextFieldOnEditing={isTextFieldOnEditing}
              isTrackChangeMode={isTrackChangeMode}
              lockedTextValue={lockedTextValue}
              isMarkdown={isMarkdown}
              issueTriggerType={issue?.trigger_type ?? "Classic"}
            />
          ) : (
            <TextValue value={cellChild.value} />
          )}
        </div>
      );
    }
    case "review_state_circle": {
      return (
        <div key={cellChildIndex} style={styles.block}>
          <UnreadIssueDot
            issueStatus={issue.review_state}
            showRead={true}
            onClick={updateReviewState}
          />
        </div>
      );
    }
    case "circle": {
      const color = getIssueColorValue(
        cellChild.color || (cellChild.value ? "red" : "green"),
      );
      const iconStyles = {
        background: color,
        flexShrink: 0,
        cursor: "pointer",
      };
      return (
        <IssueItemIconMenu
          key={cellChildIndex}
          hideDefaultIcon={true}
          hideSelector={true}
          hideResolveActions={true}
          Icon={CircleIcon}
          iconStyles={iconStyles}
          project={project}
          issue={issue}
          selectedReportId={selectedReportId}
          updateDocumentIssue={updateDocumentIssue}
          correctDocumentIssueManually={correctDocumentIssueManually}
          isFlipChecklistIcons={true}
          disabled={issue.issue_class_id === 4}
          user={user}
          issueset={issueset}
          fromReport={true}
        />
      );
    }
    case "rag_score": {
      return (
        <RagScoreField
          key={cellChildIndex}
          cellChild={cellChild}
          onEditSave={onEditSave}
        />
      );
    }
    case "additional_status": {
      return (
        <AdditionalCircle
          key={cellChildIndex}
          value={cellChild.value}
          onEditSave={onEditSave}
          status
        />
      );
    }
    case "additional_review_state": {
      return (
        <AdditionalCircle
          key={cellChildIndex}
          value={cellChild.value}
          onEditSave={onEditSave}
        />
      );
    }
    default:
      return null;
  }
};

export default CellChild;

export default function replaceUiOrderIds(issue, pattern, to) {
  if (pattern === "root") {
    issue.ui_order_id =
      issue.ui_order_id === "root" ? to : `${to}.${issue.ui_order_id}`;
  } else {
    issue.ui_order_id = issue.ui_order_id.replace(new RegExp(pattern), to);
  }
  if (issue.rules && issue.rules.issues && issue.rules.issues.length > 0) {
    issue.rules.issues.forEach(issue => replaceUiOrderIds(issue, pattern, to));
  }
  return issue;
}

import ActionTypes from "../constants/action_types";
import UNINITIALISED, {isInitialised} from "utils/uninitialised";
import {AccessControlEntry} from "common/types/accesscontrolentry";
import {FetchAccessControlEntriesAction} from "../actions/fetch";
import {MaybeUninitialised} from "modules/redux_types";
import {AddAccessControlEntryAction} from "../actions/add";
import {RemoveAccessControlEntryAction} from "../actions/remove";

type Action =
  | FetchAccessControlEntriesAction
  | AddAccessControlEntryAction
  | RemoveAccessControlEntryAction;

export default function accessControlEntryReducer(
  state: MaybeUninitialised<AccessControlEntry[]> = UNINITIALISED,
  action: Action,
) {
  switch (action.type) {
    case ActionTypes.ACCESS_CONTROL_ENTRIES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.ACCESS_CONTROL_ENTRY_ADD.SUCCESS: {
      if (!isInitialised(state)) {
        return state;
      }
      const ace = action.payload;
      return state.concat(ace);
    }
    case ActionTypes.ACCESS_CONTROL_ENTRY_REMOVE.SUCCESS: {
      if (!isInitialised(state)) {
        return state;
      }
      const ace = action.payload;
      return state.filter(({id}) => id !== ace.id);
    }
    default:
      return state;
  }
}

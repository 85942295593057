import React from "react";

import * as colors from "material-ui/styles/colors";

const styles = {
  main: (isMouseOver, isSelected) => ({
    margin: "1rem",
    padding: "1rem",
    borderWidth: isSelected ? 0 : "1px",
    boxShadow: isSelected
      ? "0px 0px 3px 1px #64B5F6"
      : isMouseOver ? "0px 0px 0px 1px #90CAF9" : "none",
    borderStyle: "solid",
    borderColor: isMouseOver ? "white" : colors.grey300,
    borderRadius: "3px",
    fontWeight: "500",
    cursor: "pointer",
  }),
};

class UploadMenuItem extends React.Component {
  state = {isMouseOver: false};

  onHoverStart = () => this.setState(() => ({isMouseOver: true}));
  onHoverFinish = () => this.setState(() => ({isMouseOver: false}));
  onItemSelect = () => {
    const isSelected = this.props.id === this.props.selectedItemId;
    if (this.props.multiselect) {
      return this.props.selectItem(this.props.id);
    }
    return isSelected ? null : this.props.selectItem(this.props.id);
  };

  render() {
    const isSelected = this.props.id === this.props.selectedItemId;
    return (
      <div
        onMouseEnter={this.onHoverStart}
        onMouseLeave={this.onHoverFinish}
        onClick={this.onItemSelect}
        style={styles.main(this.state.isMouseOver, isSelected)}
      >
        {this.props.children}
      </div>
    );
  }
}

export default UploadMenuItem;

import {get} from "lodash";
import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import getPositiveReasonIssueTemplatedText from "utils/issues/get_positive_reason_issue_templated_text";
import {getIssueManualCorrections} from "utils/manual_corrections_utils";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import isEmptyParent from "common/utils/issues/is_empty_parent";

function getItem(
  issue,
  issueset,
  selectedReport,
  shouldUseManualCorrections,
  baseKey,
  manualKey,
) {
  const {[baseKey]: baseItem} = issue;
  const manualItem = getManualItem(issue, issueset, selectedReport, manualKey);
  return shouldUseManualCorrections && manualItem && manualItem !== baseItem
    ? manualItem
    : baseItem;
}

function getManualItem(issue, issueset, selectedReport, manualKey) {
  const manualCorrections = getIssueManualCorrections(
    issue,
    issueset,
    selectedReport,
  );
  return get(manualCorrections, manualKey, "");
}

function getFallbackTemplateText(
  _issue,
  issueset,
  positiveReasonData,
  reasonData,
  parties,
  selectedReport,
  shouldUseManualCorrections = true,
) {
  const issue = {
    ..._issue,
    fallback_guidance: getItem(
      _issue,
      issueset,
      selectedReport,
      shouldUseManualCorrections,
      "fallback_guidance",
      "fallback",
    ),
  };
  return getPositiveReasonIssueTemplatedText(
    issue,
    "fallback_guidance",
    positiveReasonData,
    reasonData,
    parties,
    true,
  );
}

function getStandardLanguageTemplateText(
  _issue,
  issueset,
  positiveReasonData,
  reasonData,
  parties,
  selectedReport,
  shouldUseManualCorrections = true,
) {
  const issue = {
    ..._issue,
    standard_language: getItem(
      _issue,
      issueset,
      selectedReport,
      shouldUseManualCorrections,
      "standard_language",
      "standard_language",
    ),
  };
  return getPositiveReasonIssueTemplatedText(
    issue,
    "standard_language",
    positiveReasonData,
    reasonData,
    parties,
    true,
  );
}

function getGuidanceTemplateText(
  _issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  selectedReport,
  shouldUseManualCorrections = true,
) {
  if (_issue.reason && _issue.reason.length > 0) {
    const issue = {
      ..._issue,
      guidance: getItem(
        _issue,
        issueset,
        selectedReport,
        shouldUseManualCorrections,
        "guidance",
        "guidance",
      ),
    };
    return getIssueTemplatedText(
      issue,
      issueset,
      "guidance",
      documentClauses,
      topicsById,
      positiveReasonData,
      reasonData,
      parties,
      true,
      false,
    )[0];
  }

  const issue = {
    ..._issue,
    positive_guidance: getItem(
      _issue,
      issueset,
      selectedReport,
      shouldUseManualCorrections,
      "positive_guidance",
      "positive_guidance",
    ),
  };
  return getPositiveReasonIssueTemplatedText(
    issue,
    "positive_guidance",
    positiveReasonData,
    reasonData,
    parties,
    true,
  );
}

const getRequiredChangeTemplatedText = (
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  selectedReport,
  shouldUseManualCorrections = true,
) => {
  if (shouldUseManualCorrections) {
    const manualValue = getManualItem(
      issue,
      issueset,
      selectedReport,
      "required_change",
    );
    if (manualValue) {
      return manualValue;
    }
  }
  const issueText = getRequiredChangeText(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    reasonData,
    parties,
    selectedReport,
  );
  if (isEmptyParent(issue)) {
    const isParentAlert = isIssueAlert(issue);
    const childIssues = issue.subissueData.subissues.filter(
      childIssue => isIssueAlert(childIssue) === isParentAlert,
    );
    const childItems = childIssues.map(childIssue =>
      getRequiredChangeText(
        childIssue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        selectedReport,
      ),
    );
    return [issueText, ...childItems].join("\n\n");
  }
  return issueText;
};

function getRequiredChangeText(
  issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  selectedReport,
) {
  const isAlert = isIssueAlert(issue);
  return isAlert
    ? getTriggeredRequiredChangedText(
        issue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        selectedReport,
      )
    : getNotTriggeredRequiredChangedText(
        issue,
        issueset,
        documentClauses,
        topicsById,
        positiveReasonData,
        reasonData,
        parties,
        selectedReport,
      );
}

function getTriggeredRequiredChangedText(
  _issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  selectedReport,
) {
  const shouldUseManualCorrections = false;
  const issue = {
    ..._issue,
    required_change: getItem(
      _issue,
      issueset,
      selectedReport,
      shouldUseManualCorrections,
      "required_change",
      "required_change",
    ),
  };
  const triggeredText = getIssueTemplatedText(
    issue,
    issueset,
    "required_change",
    documentClauses,
    topicsById,
    positiveReasonData,
    reasonData,
    parties,
    true,
    false,
  )?.[0];
  return triggeredText;
}

function getNotTriggeredRequiredChangedText(
  _issue,
  issueset,
  documentClauses,
  topicsById,
  positiveReasonData,
  reasonData,
  parties,
  selectedReport,
) {
  const shouldUseManualCorrections = false;
  const notTriggeredIssue = {
    ..._issue,
    required_change_not_triggered: getItem(
      _issue,
      issueset,
      selectedReport,
      shouldUseManualCorrections,
      "required_change_not_triggered",
      "required_change",
    ),
  };
  const notTriggeredText = getPositiveReasonIssueTemplatedText(
    notTriggeredIssue,
    "required_change_not_triggered",
    positiveReasonData,
    reasonData,
    parties,
    true,
  );
  return notTriggeredText;
}

export default {
  getFallbackTemplateText,
  getGuidanceTemplateText,
  getStandardLanguageTemplateText,
  getRequiredChangeTemplatedText,
};

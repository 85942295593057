import _ from "lodash";
import HEADINGS_SEPARATOR from "common/headings_separator";
import stripTextBeforeDefinitions from "./strip_text_before_definitions";
import containsMeaning from "./contains_meaning";

function addDefinitionsAndHeadings(
  _baseText,
  partialText,
  definitions = [],
  headings = [],
  sectionId,
  clausepartReference = "",
) {
  const baseText = stripTextBeforeDefinitions(_baseText, definitions);
  const clausepartHeadings = getClausepartHeadingsByReference(
    clausepartReference,
    headings,
    sectionId,
  ).map(heading => heading.text);

  const headingPrefix =
    clausepartHeadings.length > 0
      ? `${clausepartHeadings.join(HEADINGS_SEPARATOR)}${HEADINGS_SEPARATOR}`
      : "";
  const text = `${headingPrefix}${baseText}`;

  const meaningInstances =
    (clausepartReference || "").endsWith(partialText) ||
    (partialText?.split(" ") ?? []).length <= 4
      ? []
      : findMatchingDefinitionMeanings(baseText, partialText, definitions);
  const termInstances = findMatchingDefinitions(baseText, definitions).filter(
    termDef =>
      !meaningInstances.find(meaningDef => meaningDef.term === termDef.term),
  );
  const instances = _.sortBy(meaningInstances.concat(termInstances), "term");
  return [
    text,
    ..._.sortBy(instances, instance => instance.term).map(instance => {
      let hasMeaning = containsMeaning(instance.meaning, text, partialText);
      if (hasMeaning && instance.type === "NounPhrase") {
        hasMeaning = text.indexOf(instance.term) >= 0;
      }
      return `${instance.term}>>${hasMeaning ? "@@" : ""}${instance.meaning}`;
    }),
  ].join("||");
}

function findMatchingDefinitions(text, definitions) {
  const matches = {};
  definitions.forEach(definition => {
    const {term} = definition;

    let stillLooking = true;
    let lastIndex = -1;
    while (stillLooking) {
      const index = text.indexOf(term, lastIndex + 1);
      if (index >= 0 && isEndOfWord(text, term, index)) {
        if (!matches[index]) {
          matches[index] = definition;
        } else if (matches[index].term.length < definition.term.length) {
          matches[index] = definition;
        }
        lastIndex = index;
      } else {
        stillLooking = false;
      }
    }
  });

  removeOverlappingMatches(matches);

  return getMatchIndexes(matches).map(index => matches[index]);
}

function findMatchingDefinitionMeanings(text, partialText, definitions) {
  return definitions
    .filter(definition =>
      containsMeaning(definition.meaning, text, partialText),
    )
    .map(definition => ({...definition, isMeaning: true}));
}

function removeOverlappingMatches(matches) {
  const indexes = getMatchIndexes(matches);

  for (let idx = 0; idx < indexes.length - 1; idx += 1) {
    const index = indexes[idx];
    const currentTermLength = matches[index].term.length;
    const endOfTerm = index + currentTermLength;
    const nextIndex = indexes[idx + 1];

    if (nextIndex < endOfTerm) {
      const nextTermLength = matches[nextIndex].term.length;
      if (nextTermLength > currentTermLength) {
        delete matches[index];
      } else {
        delete matches[nextIndex];
      }
      idx += 1;
    }
  }
}

function getMatchIndexes(matches) {
  const indexes = _.chain(Object.keys(matches))
    .map(index => parseInt(index, 10))
    .sortBy(index => index)
    .value();

  return indexes;
}

function isEndOfWord(text, term, index) {
  return (
    text.charAt(index + term.length).match(/\W/) ||
    text.length === index + term.length
  );
}

export function getClausepartHeadingsByReference(
  clausepartReference,
  headings = [],
  sectionId,
) {
  const result = [];
  if (headings && headings.length > 0) {
    const splittedReference = clausepartReference.split(".");
    const baseRef = splittedReference.shift();
    const baseHeadings = headings.reduce((accum, heading) => {
      const headingReference = heading.tempReference || heading.reference;
      const splittedHeadingReference = headingReference.split(".");
      const headingBaseRef = splittedHeadingReference.shift();
      if (baseRef === headingBaseRef && heading.section_id === sectionId) {
        const remainingHeadingReference = splittedHeadingReference.join(".");
        if (remainingHeadingReference.length > 0) {
          const newHeading = {
            ...heading,
            tempReference: remainingHeadingReference,
          };
          accum.push(newHeading);
        } else {
          result.push(_.omit(heading, "tempReference"));
        }
      }
      return accum;
    }, []);
    return result.concat(
      getClausepartHeadingsByReference(
        splittedReference.join("."),
        baseHeadings,
        sectionId,
      ),
    );
  }
  return result;
}

export default addDefinitionsAndHeadings;

import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateDocumentUploadStatus() {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_UPLOAD_UPDATE,
    data => new Promise(resolve => resolve(data)),
  );
}

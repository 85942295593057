import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import requestor from "requestor";
import PropTypes from "prop-types";
import _ from "lodash";

import {isInitialised} from "utils/uninitialised";
import {get, clear} from "utils/organisation_store";

import userPasswordUpdateAction from "modules/user/actions/user_password_update";
import userLogoutAction from "modules/user/actions/user_logout";
import organisationChangeAction from "modules/organisation/actions/organisation_change";
import userUpdateAction from "modules/user/actions/user_update";

import * as Navigation from "routes/navigation";
import OverridablePortalsContext from "routes/app/overridable_portals/context";
import SidebarComponent from "../components/sidebar";

class Sidebar extends React.Component {
  componentDidMount() {
    this.context.registerOverridablePortal("sidebar", this);
  }

  componentWillUnmount() {
    this.context.unregisterOverridablePortal("sidebar", this);
  }

  shouldRender() {
    return (
      isInitialised([this.props.user, this.props.projects]) &&
      this.props.user["logged-in"] === true
    );
  }

  render() {
    if (!this.shouldRender()) {
      return <div />;
    }
    const organisationId = parseInt(
      this.props.router.params.organisationId,
      10,
    );
    return (
      <SidebarComponent
        isCollapsedLight={this.isCollapsedLight()}
        user={this.props.user}
        environment={this.props.environment}
        location={this.props.router.location}
        organisationId={organisationId}
        projectId={this.getCurrentProjectId()}
        routePath={this.getRoutePath()}
        userNavItems={this.getNavItems(Navigation.userItems)}
        adminNavItems={this.getNavItems(Navigation.adminItems)}
        onUserPasswordUpdate={this.onUserPasswordUpdate}
        onLogout={this.onLogout}
        getOrganisationUrl={this.getOrganisationUrl}
        changeOrganisation={this.props.changeOrganisation}
        collapsedUserNav={this.props.collapsedUserNav}
        onUserUpdate={this.onUserUpdate}
        clientModeOn={this.props.clientModeOn}
      />
    );
  }

  isCollapsedLight = () => {
    const route = Navigation.getCurrentRoute(this.props.router);
    return (
      this.props.isCollapsedLight ||
      Boolean(route && route.isSidebarCollapsedLight)
    );
  };

  getPermissions = () => {
    return this.props.user.permissions.map(permission => permission.name);
  };

  getCurrentProjectId = () => {
    const projectId = this.getProjectId();
    return typeof projectId === "string" ? parseInt(projectId, 10) : projectId;
  };

  getProjectId = () => {
    const {user, projects, router} = this.props;
    const {organisationId, projectId} = router.params;
    if (projectId) {
      return projectId;
    }

    const organisationStoreProjectId = get(organisationId, "projectId");
    if (organisationStoreProjectId) {
      return organisationStoreProjectId;
    }

    const organisationValues =
      user &&
      user.organisation_values &&
      _.find(
        user.organisation_values,
        (_organisationValues, _organisationId) =>
          parseInt(_organisationId, 10) === parseInt(organisationId, 10),
      );
    const defaultUserProject = organisationValues
      ? projects.find(
          project => project.id === organisationValues.default_project_id,
        )
      : null;
    const defaultProject =
      defaultUserProject || projects.find(project => project.is_default);
    if (defaultProject) {
      return defaultProject.id;
    }

    return projects[0].id;
  };

  getRoutePath = () => {
    return Navigation.getRoutePath(this.props.router);
  };

  getNavItems = items => {
    const routePath = this.getRoutePath();
    const permissions = this.getPermissions();
    const organisationId = parseInt(
      this.props.router.params.organisationId,
      10,
    );
    const projectId = this.getCurrentProjectId();
    const params = {
      ...this.props.router.params,
      organisationId,
      projectId,
    };
    return items
      .filter(item => {
        return !item.permission || permissions.includes(item.permission);
      })
      .map(item => {
        const paths = [
          item.route.path,
          ...(!item.childRoutes
            ? []
            : item.childRoutes.map(childRoute => childRoute.path)),
        ];
        return {
          ...item,
          pathname: paths[0].replace(/:([^\/]+)/g, (match, group) => {
            return params[group];
          }),
          isActive: Boolean(paths.find(path => path === routePath)),
        };
      });
  };

  onUserPasswordUpdate = (userId, data) => {
    this.props.userPasswordUpdateAction(userId, data);
  };

  onLogout = () => {
    const {organisationId} = this.props.router.params;
    clear(organisationId);
    this.props.userLogoutAction();
  };

  getOrganisationUrl = (organisationId, remoteOrganisationId) => {
    if (remoteOrganisationId) {
      return `https://app.lexicallabs.com/organisation/${remoteOrganisationId}`;
    }
    const {router} = this.props;
    const routePath = this.getRoutePath();
    const isProjectRoute = /project\/:projectId/.test(routePath);
    const currentNavItem = Navigation.getCurrentItem(router);
    let url;
    if (isProjectRoute || !currentNavItem) {
      url = Navigation.getUrl(router, "organisationId", organisationId);
    } else if (currentNavItem) {
      const paramValues = {
        ...router.params,
        organisationId,
      };
      const targetPath = currentNavItem.route.path;
      url = targetPath.replace(/:([^\/]+)/g, (match, group) => {
        return paramValues[group];
      });
    }
    return url;
  };

  onUserUpdate = (userId, data) => {
    const newData = {
      ...data,
      last_edited: this.props.user.last_edited,
    };
    this.props.userUpdateAction(userId, newData);
  };
}

Sidebar.contextType = OverridablePortalsContext;

Sidebar.propTypes = {
  isCollapsedLight: PropTypes.bool,
};

function select(state, props) {
  return {
    router: props.router,
    user: state.user,
    projects: state.projects,
    environment: state.environment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        userPasswordUpdateAction: userPasswordUpdateAction(requestor),
        userLogoutAction: userLogoutAction(requestor),
        userUpdateAction: userUpdateAction(requestor),
        changeOrganisation: organisationChangeAction,
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(Sidebar);
export const Component = Sidebar;

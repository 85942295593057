import React from "react";
import List from "@material-ui/core/List";
import {Checkbox} from "material-ui";
import {optionsStyles} from "./styles";
import Radio from "@material-ui/core/Radio";

const FiltersMenu = ({
  items,
  title,
  changeItemIssuesFilterValues,
  itemIssuesFilterValues,
  radio,
}) => {
  const handleToggle = item => () => changeItemIssuesFilterValues(item);

  return (
    <List dense>
      {title ? <span style={optionsStyles.optionsTitle}>{title}</span> : null}
      {items.map(item => {
        return (
          <div
            key={item.title}
            onClick={handleToggle(item)}
            style={optionsStyles.optionsContainer}
          >
            <div>
              <div style={optionsStyles.optionsIconStyle}>
                {item.icon ? (
                  <item.icon style={{...item.iconStyle, width: "16px"}} />
                ) : null}
                {item.title}
              </div>
              {item.description ? (
                <span style={optionsStyles.optionsDescription}>
                  {item.description}
                </span>
              ) : null}
            </div>
            {radio ? (
              <Radio
                checked={
                  itemIssuesFilterValues[item.type] === item.value ||
                  itemIssuesFilterValues[item.value] === true
                }
                color={"primary"}
                style={optionsStyles.optionsRadio}
              />
            ) : (
              <Checkbox
                checked={
                  itemIssuesFilterValues[item.type] === item.value ||
                  itemIssuesFilterValues[item.value] === true
                }
                style={optionsStyles.optionsCheckbox}
              />
            )}
          </div>
        );
      })}
    </List>
  );
};

export default FiltersMenu;

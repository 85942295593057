import React from "react";
import TextField from "@material-ui/core/TextField";
import InfoIcon from "@material-ui/icons/Info";

import CheckboxBasic from "common_components/inputs/checkbox_basic";

const changedKeyColor = "#fffff3";

function EmailReportSettings(props) {
  if (!props.settings) {
    return null;
  }
  const {fields = []} = props.settings;

  const {fields: baseFields = []} = props.baseSettings;

  const onSettingCheck = key => () => {
    const newSettings = {
      ...props.settings,
      [key]: !props.settings[key],
    };
    props.onSettingsChange(newSettings);
  };

  function onFieldChange(fieldType, useValueTarget) {
    return function(event) {
      const newValue = useValueTarget
        ? event.target.value
        : event.target.checked;

      const newFields = fields.map(
        field =>
          field.type === fieldType ? {...field, value: newValue} : field,
      );

      const newSettings = {
        ...props.settings,
        fields: newFields,
      };
      props.onSettingsChange(newSettings);
    };
  }

  function getFieldValue(key, fields) {
    return (fields.find(field => field.type === key) || {}).value || "";
  }

  const renderInfoIcon = hintMessage => (
    <div title={hintMessage}>
      <InfoIcon
        style={{
          color: "#9e9e9e",
          width: 18,
          height: 18,
          marginLeft: 4,
          marginTop: 4,
        }}
      />
    </div>
  );

  return (
    <div style={{display: "flex", flexDirection: "column"}}>
      <div style={{display: "flex", alignItems: "center"}}>
        <CheckboxBasic
          label={"Include item numbers"}
          onCheck={onSettingCheck("are_report_items_numbered")}
          checked={props.settings["are_report_items_numbered"]}
          containerStyles={{
            margin: "12px 0px",
            background:
              props.settings["are_report_items_numbered"] !==
              props.baseSettings["are_report_items_numbered"]
                ? changedKeyColor
                : "inherit",
          }}
        />
        {renderInfoIcon(
          "Add a first column to the report which numbers report items",
        )}
      </div>
      <div style={{display: "flex", alignItems: "center"}}>
        <CheckboxBasic
          label={"Automatically include all items"}
          onCheck={onSettingCheck("add_all_items_to_report")}
          checked={props.settings["add_all_items_to_report"]}
          containerStyles={{
            margin: "12px 0px",
            background:
              props.settings["add_all_items_to_report"] !==
              props.baseSettings["add_all_items_to_report"]
                ? changedKeyColor
                : "inherit",
          }}
        />
        {renderInfoIcon(
          "If selected all items in the checklist are added by default and can be removed individually. Otherwise issues can be added individually from the checklist",
        )}
      </div>
      <TextField
        label="Header"
        multiline={true}
        rows={1}
        rowsMax={2}
        fullWidth={true}
        onChange={onFieldChange("header_content", true)}
        value={getFieldValue("header_content", fields)}
        style={{
          marginTop: "4px",
          width: "100%",
          borderRadius: "2px",
          background:
            getFieldValue("header_content", fields) !==
            getFieldValue("header_content", baseFields)
              ? changedKeyColor
              : "inherit",
        }}
      />
      <TextField
        label="Footer"
        multiline={true}
        rows={1}
        rowsMax={2}
        fullWidth={true}
        onChange={onFieldChange("footer_content", true)}
        value={getFieldValue("footer_content", fields)}
        style={{
          marginTop: "4px",
          width: "100%",
          borderRadius: "2px",
          background:
            getFieldValue("footer_content", fields) !==
            getFieldValue("footer_content", baseFields)
              ? changedKeyColor
              : "inherit",
        }}
      />
    </div>
  );
}

export default EmailReportSettings;

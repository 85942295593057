import basePath from "base_path";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";
import ActionTypes from "../../constants/action_types";

export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_STATE_FETCH,
  organisationId =>
    requestor
      .get(`${basePath}/organisation/${organisationId}/document_state`, {
        withCredentials: true,
      })
      .then(response => {
        return response.data;
      }),
);

import React from "react";

import RefreshIcon from "@material-ui/icons/Refresh";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Tooltip from "@material-ui/core/Tooltip";

import getDocumentIssuesOutOfDateStatus from "common/utils/document/get_document_issues_out_of_date_status";

function RefreshIssuesButton(props) {
  const {
    document,
    project,
    runDocumentIssuesFind,
    reprocessDocumentRoles,
  } = props;
  const [hasClicked, updateHasClicked] = React.useState(false);
  React.useEffect(
    () => {
      if (hasClicked) {
        updateHasClicked(false);
      }
    },
    [props.document && props.document.issues_last_processed],
  );

  const baseStyles = {
    height: 18,
    width: 18,
    ...(props.styles || {}),
  };

  const status = getDocumentIssuesOutOfDateStatus(document, project);

  if (
    status === "refresh_pending" ||
    (status === "out_of_date" && hasClicked)
  ) {
    return <QueryBuilderIcon style={baseStyles} />;
  } else if (status === "out_of_date") {
    const onIssuesRefresh = e => {
      e.stopPropagation();
      e.preventDefault();
      updateHasClicked(true);
      if (document.roles_out_of_date) {
        reprocessDocumentRoles(document.id);
      } else {
        runDocumentIssuesFind(document.id);
      }
    };
    return (
      <Tooltip
        title={
          document.roles_out_of_date ? "Reprocess Roles" : "Reprocess Issues"
        }
        enterDelay={800}
      >
        <RefreshIcon
          onClick={onIssuesRefresh}
          style={{
            ...baseStyles,
            ...{color: document.roles_out_of_date ? "#F80" : "#F00"},
            cursor: "pointer",
          }}
        />
      </Tooltip>
    );
  }
  return props.renderEmptyDivIfNull ? <div style={baseStyles} /> : null;
}

export default RefreshIssuesButton;

import React from "react";
import _ from "underscore";

import Menu from "@material-ui/core/Menu";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import DoneIcon from "@material-ui/icons/Done";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import RefreshIcon from "@material-ui/icons/Refresh";

import Permissioner from "utils/permissioner";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getMenuItem from "./get_menu_item";

const styles = {
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  anchor: {
    vertical: "bottom",
    horizontal: "center",
  },
  transform: {
    vertical: "top",
    horizontal: "center",
  },
};

export function getMenuItems(props) {
  const {
    issues,
    project,
    user,
    updateDocumentIssues,
    setIssueExpandState,
    isFlipChecklistIcons,
    showFlipChecklistIcons,
  } = props;
  const hasShowChecklistMenuItemsPermission = new Permissioner(
    user,
  ).hasPermission("show-checklist-menu-items");

  const verifyIssues = project.verify_issues;

  const onVerifyAll = () => updateRemaining(issues, updateDocumentIssues, true);
  const onRevertVefiryState = () =>
    updateRemaining(issues, updateDocumentIssues, null);
  const onRejectAll = () =>
    updateRemaining(issues, updateDocumentIssues, false);
  const onConfirmRemaining = () =>
    confirmRemaining(issues, updateDocumentIssues);

  const expandAll = () => setIssueExpandState(true);
  const collapseAll = () => setIssueExpandState(false);

  const nullCounts = getNullCounts(issues, verifyIssues, user);

  const menuItems = [];
  if (verifyIssues && hasShowChecklistMenuItemsPermission) {
    if (nullCounts.user_verified > 0) {
      menuItems.push({
        key: "verify-all",
        clickHandler: onVerifyAll,
        primaryText: "Verify Remaining",
        leftIcon: <ThumbUpIcon />,
        usePostHandler: true,
      });
      menuItems.push({
        key: "reject-all",
        clickHandler: onRejectAll,
        primaryText: "Reject Remaining",
        leftIcon: <ThumbDownIcon />,
        usePostHandler: true,
      });
    }
    if (nullCounts.user_verified !== issues.length) {
      menuItems.push({
        key: "revert-verify-state",
        clickHandler: onRevertVefiryState,
        primaryText: "Revert Verify State",
        leftIcon: <ThumbsUpDownIcon />,
        usePostHandler: true,
      });
    }
  }

  if (user.is_admin && nullCounts.should_be_issue !== 0) {
    menuItems.push({
      key: "confirm-remaining",
      clickHandler: onConfirmRemaining,
      primaryText: "Confirm All Remaining Issues",
      leftIcon: <DoneIcon />,
      usePostHandler: true,
    });
  }

  if (menuItems.length) {
    menuItems.push({type: "divider"});
  }

  if (hasShowChecklistMenuItemsPermission) {
    menuItems.push({
      key: "collapse-all",
      clickHandler: collapseAll,
      primaryText: "Collapse All",
      leftIcon: <ExpandLess />,
      usePostHandler: true,
    });

    menuItems.push({
      key: "expand-all",
      clickHandler: expandAll,
      primaryText: "Expand All",
      leftIcon: <ExpandMore />,
      usePostHandler: true,
    });
  }

  if (props.appType !== "wordTaskpane") {
    menuItems.push({
      key: "flip-checklist-icons",
      clickHandler: showFlipChecklistIcons,
      primaryText: "Flip checklist icons",
      usePostHandler: true,
      leftIcon: isFlipChecklistIcons ? (
        <CheckBoxIcon />
      ) : (
        <CheckBoxOutlineBlankIcon />
      ),
    });

    menuItems.push({
      key: "compare-to-other-document",
      clickHandler: props.triggerCompareToOtherDocument,
      primaryText: "Compare To Other Document",
      usePostHandler: true,
      leftIcon:
        props.primaryPanel === "compare" ? (
          <CheckBoxIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        ),
    });

    if (user.is_admin && props.onDocumentIssuesFindRun) {
      const clickHandler = () => {
        props.onDocumentIssuesFindRun(props.document.id);
      };
      menuItems.push({
        key: "reprocess-issues",
        clickHandler,
        primaryText: "Reprocess Issues",
        leftIcon: <RefreshIcon />,
        usePostHandler: true,
      });
    }
    if (user.is_admin) {
      const clickHandler = () => {
        props.onDownload();
      };
      menuItems.push({
        key: "download",
        clickHandler,
        primaryText: "Download as JSON",
        leftIcon: <DownloadIcon />,
        usePostHandler: true,
      });
    }
  }

  return menuItems;
}

function HeaderMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const baseMenuItems = getMenuItems(props);
  const menuItems = baseMenuItems.map(
    item => (item.usePostHandler ? {...item, postHandler: handleClose} : item),
  );

  const Icon = props.Icon || MoreHorizIcon;
  return (
    <div style={styles.wrapper}>
      {menuItems.length > 0 && (
        <>
          <Icon onClick={handleClick} />
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{style: {borderRadius: 0}}}
            getContentAnchorEl={null}
            anchorOrigin={styles.anchor}
            transformOrigin={styles.transform}
          >
            {menuItems.map((menuItem, menuItemIndex) =>
              getMenuItem(menuItem, menuItemIndex),
            )}
          </Menu>
        </>
      )}
    </div>
  );
}

function getNullCounts(issues = [], verifyIssues, user = {}) {
  return issues.reduce(
    (result, issue) => {
      if (verifyIssues && issue.user_verified === null) {
        result.user_verified += 1;
      }
      if (user.is_admin && issue.should_be_issue === null) {
        result.should_be_issue += 1;
      }
      if (user.is_admin && issue.is_correctly_applied === null) {
        result.is_correctly_applied += 1;
      }
      return result;
    },
    {user_verified: 0, should_be_issue: 0, is_correctly_applied: 0},
  );
}

function confirmRemaining(issues, updateDocumentIssues) {
  const uncollapsedIssues = uncollapseIssues(issues);
  const filteredIssues = uncollapsedIssues.filter(
    issue => issue.should_be_issue === null,
  );
  const issuesWithReasons = [];
  const issuesWithoutReasons = [];
  filteredIssues.forEach(issue => {
    const issueData = {
      id: issue.document_issue_id,
      last_edited: issue.last_edited,
    };
    if (issue.reason && issue.reason.length > 0) {
      issuesWithReasons.push(issueData);
    } else {
      issuesWithoutReasons.push(issueData);
    }
  });
  if (issuesWithReasons.length > 0) {
    updateDocumentIssues(issuesWithReasons, {
      should_be_issue: true,
      is_correctly_applied: true,
    });
  }
  if (issuesWithoutReasons.length > 0) {
    updateDocumentIssues(issuesWithoutReasons, {
      should_be_issue: false,
      is_correctly_applied: true,
    });
  }
}

function updateRemaining(issues, updateDocumentIssues, newVerifyState) {
  const updates = {
    user_verified: newVerifyState,
    is_correctly_applied: newVerifyState,
  };
  let filteredIssues;
  const uncollapsedIssues = uncollapseIssues(issues);
  if (newVerifyState !== null) {
    filteredIssues = uncollapsedIssues.filter(
      issue => issue.user_verified === null,
    );
  } else {
    filteredIssues = uncollapsedIssues.filter(
      issue => issue.user_verified !== null,
    );
  }

  const issuesToUpdate = filteredIssues.map(issue => ({
    id: issue.document_issue_id,
    last_edited: issue.last_edited,
    updates: {
      should_be_issue:
        newVerifyState === null
          ? null
          : isIssueAlert(issue) ? newVerifyState : !newVerifyState,
    },
  }));
  updateDocumentIssues(issuesToUpdate, updates);
}

function uncollapseIssues(issues) {
  return issues.reduce((result, issue) => {
    if (issue.underlyingIssues) {
      return [...result, ...issue.underlyingIssues];
    }
    return _.uniq([...result, issue], issue => issue.id);
  }, []);
}

export default HeaderMenu;

import React from "react";

import PrecedentLanguage from "../panels/precedent_language";

import Permissioner from "utils/permissioner";

function canViewIntelligentDefinitions(user) {
  return (
    user.is_admin ||
    new Permissioner(user).hasPermission("view-intelligent-definitions")
  );
}

export default function render({
  issue,
  containerStyles,
  openTabs,
  onItemOpen,
  ...props
}) {
  const items = [
    <PrecedentLanguage
      key="precedent-language"
      appType={props.appType}
      organisationId={props.organisationId}
      project={props.project}
      document={props.document}
      issue={issue}
      containerStyles={containerStyles}
      onOpen={onItemOpen("precedentLanguageOpen")}
      isOpen={openTabs.precedentLanguageOpen}
      findIssueDocuments={props.findIssueDocuments}
      searchByIssueClear={props.searchByIssueClear}
      searchByIssueResults={props.searchByIssueResults}
      selectedReport={props.selectedReport}
      hideDocumentRelatedItems={props.hideDocumentRelatedItems}
      definitionGroups={props.definitionGroups}
      documentDefinitions={props.documentDefinitions}
      documentClauseparts={props.documentClauseparts}
      updateDocument={props.updateDocument}
      updateOtherDocument={props.updateOtherDocument}
      isFlipChecklistIcons={props.isFlipChecklistIcons}
      showIntelligentDefinitions={canViewIntelligentDefinitions(props.user)}
      shouldShrinkCounter={props.shouldShrinkCounter}
      currentIssueset={props.currentIssueset}
    />,
  ];

  return items;
}

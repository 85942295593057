import React from "react";

import Correctness from "../panels/correctness";
import AboutDescription from "../panels/about_description";
import DetailedReason from "../panels/detailed_reason";
import ApplicableClauses from "../panels/applicable_clauses";
import RelatedClauses from "../panels/related_clauses";
import IssueReasons from "../panels/issue_reasons";
import Guidance from "../panels/guidance";
import Fallback from "../panels/fallback";
import Accuracy from "../panels/accuracy";
import StandardLanguage from "../panels/standard_language";
import LlmResponse from "../panels/llm_response";
import MockLLMResponseInput from "../panels/mock_llm_response_input";

import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import issueTemplateUtils from "utils/issues/issue_template_utils";
import {shouldUseManualCorrections} from "utils/manual_corrections_utils";
import IssueStatusMenu from "../helper_components/issue_status_menu";

function collapseToArray(applicableClausesObj) {
  if (!applicableClausesObj) {
    return [];
  }
  let result = [];
  Object.keys(applicableClausesObj).forEach(applicableClausesGroup => {
    result = [
      ...result,
      ...(applicableClausesObj[applicableClausesGroup] || []),
    ];
  });
  return result;
}

export default function render({
  appType,
  issue,
  zoom,
  containerStyles,
  openTabs,
  onItemOpen,
  selectedReportId,
  project,
  isFlipChecklistIcons,
  organisationId,
  ...props
}) {
  const {
    positiveReasonData,
    documentClauseparts,
    hideDocumentRelatedItems,
  } = props;

  const {
    applicableClauses,
    relatedClauses,
    relatedClausesCount,
  } = calculateClauses(
    issue,
    props.documentClauseparts,
    props.applicableClauses,
    props.relatedClauses,
    props.relatedClausesCount,
  );

  return [
    <Correctness
      key="correctness"
      issue={issue}
      project={project}
      zoom={zoom}
      organisationId={organisationId}
      {...props}
    />,
    <DetailedReason
      key="detailed-reason"
      issue={issue}
      project={project}
      zoom={zoom}
      document={props.document}
      documentClauses={props.documentClauses}
      documentClauseparts={props.documentClauseparts}
      topicsById={props.topicsById}
      positiveReasonData={props.positiveReasonData}
      currentIssuesetItem={props.currentIssuesetItem}
      selectedReport={selectedReportId}
      isFlipChecklistIcons={isFlipChecklistIcons}
      preventMemo={props.preventMemo}
    />,
    <IssueStatusMenu
      key="review-tracking"
      issueStatus={issue.review_state}
      issue={issue}
      updateIssueReviewState={props.updateIssueReviewState}
      reviewTrackingOn={props.reviewTrackingOn}
    />,
    ...(props.organisation.use_llm && project.show_llm_response ? (
      <LlmResponse
        key="llm-response"
        fetchConversation={props.fetchConversation}
        generateDocumentIssueLlmPrompt={props.generateDocumentIssueLlmPrompt}
        fetchDocumentIssueConversation={props.fetchDocumentIssueConversation}
        clearDocumentIssueConversation={props.clearDocumentIssueConversation}
        issue={issue}
        issueset={props.currentIssuesetItem}
      />
    ) : (
      []
    )),
    <AboutDescription
      key="about-description"
      containerStyles={containerStyles}
      issue={issue}
      show_how_issue_works={
        project.show_how_issue_works || props.force_show_how_issue_works
      }
      aboutDescription={
        getIssueTemplatedText(
          issue,
          props.currentIssuesetItem,
          "about_description",
          props.documentClauses,
          props.topicsById,
          props.positiveReasonData,
          props.documentClauseparts,
          props.document.parties,
          true,
          false,
        )[0]
      }
      onOpen={onItemOpen("aboutDescriptionOpen")}
      isOpen={openTabs.aboutDescriptionOpen}
    />,
    <Guidance
      key="guidance"
      containerStyles={containerStyles}
      guidance={issueTemplateUtils.getGuidanceTemplateText(
        issue,
        props.currentIssuesetItem,
        props.documentClauses,
        props.topicsById,
        props.positiveReasonData,
        props.documentClauseparts,
        selectedReportId,
        true,
      )}
      onOpen={onItemOpen("guidanceOpen")}
      isOpen={openTabs.guidanceOpen}
    />,
    <ApplicableClauses
      key="applicable-clauses"
      applicableClausesReferenced={props.applicableClausesReferenced}
      applicableClausesOpen={openTabs.applicableClausesOpen}
      onApplicableClausesOpen={onItemOpen("applicableClausesOpen")}
      onClauseClick={props.scrollToClause}
      onHeadingClick={props.scrollToHeading}
      topicsById={props.topicsById}
      documentHeadings={props.baseDocumentHeadings}
      documentSections={props.documentSections}
      documentDefinitions={props.documentDefinitions}
      documentChanges={props.documentChanges}
      document={props.document}
      parties={props.document.parties}
      roles={props.roles}
      issue={issue}
      selectedReport={selectedReportId}
      correctDocumentIssueManually={props.correctDocumentIssueManually}
      renderStatus={props.renderStatus}
      scrollToParties={props.scrollToParties}
      {...props}
      applicableClauses={applicableClauses}
    />,
    <RelatedClauses
      key="related-clauses"
      relatedClauses={relatedClauses}
      issue={issue}
      onRelatedClausesOpen={onItemOpen("relatedClausesOpen")}
      onClauseClick={props.scrollToClause}
      topicsById={props.topicsById}
      relatedClausesOpen={openTabs.relatedClausesOpen}
      relatedClausesCount={relatedClausesCount}
      documentHeadings={props.baseDocumentHeadings}
      documentSections={props.documentSections}
      documentDefinitions={props.documentDefinitions}
      documentChanges={props.documentChanges}
      document={props.document}
      fileNames={props.fileNames}
      fileIndex={props.fileIndex}
      renderStatus={props.renderStatus}
    />,
    <StandardLanguage
      key="standard-language"
      issue={issue}
      containerStyles={containerStyles}
      standardLanguageText={issueTemplateUtils.getStandardLanguageTemplateText(
        issue,
        props.currentIssuesetItem,
        positiveReasonData,
        documentClauseparts,
        selectedReportId,
        shouldUseManualCorrections(
          "standard_language",
          project,
          selectedReportId,
        ),
      )}
      onOpen={onItemOpen("standardLanguageOpen")}
      isOpen={openTabs.standardLanguageOpen}
      templateDocumentLink={
        hideDocumentRelatedItems ? null : project.template_document_link
      }
    />,
    <Fallback
      key="fallback"
      containerStyles={containerStyles}
      fallback={issueTemplateUtils.getFallbackTemplateText(
        issue,
        props.currentIssuesetItem,
        positiveReasonData,
        documentClauseparts,
        selectedReportId,
        shouldUseManualCorrections("fallback", project, selectedReportId),
      )}
      onOpen={onItemOpen("fallbackOpen")}
      isOpen={openTabs.fallbackOpen}
      organisationId={organisationId}
    />,
    appType !== "wordTaskpane" && (
      <IssueReasons
        key="issue-reasons"
        user={props.user}
        topicsById={props.topicsById}
        containerStyles={containerStyles}
        onOpen={onItemOpen("issueReasonsOpen")}
        isOpen={openTabs.issueReasonsOpen}
        applicableClauses={collapseToArray(props.applicableClauses)}
        onClauseClick={props.scrollToClause}
        issue={issue}
        clientModeOn={props.clientModeOn}
        isFlipChecklistIcons={isFlipChecklistIcons}
      />
    ),
    props.verifyIssues && (
      <Accuracy
        key="accuracy"
        zoom={zoom}
        issue={issue}
        updateDocumentIssue={props.updateDocumentIssue}
        isFlipChecklistIcons={isFlipChecklistIcons}
        user={props.user}
      />
    ),
    issue.trigger_type === "LLM" && !props.clientModeOn && (
      <MockLLMResponseInput
        value={issue.llm_response}
        onChange={llm_response =>
          props.updateDocumentIssue(issue, {llm_response})
        }
        issueId={issue.id}
      />
    ),
  ].filter(item => item);
}

function calculateClauses(
  issue,
  documentClauseparts,
  _applicableClauses,
  _relatedClauses,
  _relatedClausesCount,
) {
  const hasLlmResponse = issue.trigger_type === "LLM" && issue.llm_response;
  const generateEntries = (applicableClauses, filter) =>
    Object.fromEntries(
      Object.entries(issue.llm_response.applicable_clauses ?? {}).map(
        ([topic, clauses]) => [
          topic,
          (Array.isArray(clauses) ? clauses : [])
            .filter(filter)
            .map(clause => {
              const documentClausepart = documentClauseparts.find(
                ({id}) => id === clause.id,
              );
              return {
                ...clause,
                text: clause.text ?? documentClausepart?.partial_text ?? "",
                reference: documentClausepart?.reference ?? "",
                // Markdown syntax is supported for applicable clauses
                // sourced from the mock LLM response field
                isMarkdown: true,
              };
            })
            .sort((a, b) => a.id - b.id),
        ],
      ),
    );
  const applicableClauses = hasLlmResponse
    ? generateEntries(issue, clause => (clause?.score ?? 5) >= 4)
    : _applicableClauses;
  const relatedClauses = hasLlmResponse
    ? generateEntries(issue, clause => (clause?.score ?? 5) === 3)
    : _relatedClauses;
  const relatedClausesCount = hasLlmResponse
    ? relatedClauses?.All?.length
    : _relatedClausesCount;
  return {
    applicableClauses,
    relatedClauses,
    relatedClausesCount,
  };
}

import convertListIndex from "./convert_list_index";

class Counters {
  constructor() {
    this._length = 10;
    this._counters = [];
    for (let index = 0; index < this._length; index += 1) {
      this._counters[index] = 0;
    }
  }

  add(p_index = 0) {
    const id = this._counters[p_index];
    this._counters[p_index] += 1;
    for (let index = p_index + 1; index < this._length; index += 1) {
      this._counters[index] = 0;
    }
    return id;
  }

  get(index) {
    if (index >= 0 && index < this._length) {
      return this._counters[index];
    }
    return null;
  }

  convert(index, listStyleType) {
    return convertListIndex(this.add(index), listStyleType);
  }
}

/* eslint-disable no-loop-func, max-depth, max-statements */
function convertContentsToListItems(contents) {
  const results = [];
  if (contents && contents.ops) {
    const {ops} = contents;
    if (ops.length > 0) {
      let counters = new Counters();
      let text = "";
      for (const op of ops) {
        const {insert, attributes} = op;
        if (insert) {
          const lines = insert.split("\n");
          if (lines.length > 0) {
            if (attributes && attributes["list"]) {
              if (lines.length > 1) {
                const {
                  list,
                  indent,
                  "list-style-type": listStyleType,
                } = attributes;
                lines.slice(0, lines.length - 1).forEach(line => {
                  const reference =
                    list === "ordered"
                      ? counters.convert(indent, listStyleType)
                      : undefined;
                  results.push({
                    indent: indent || null,
                    reference,
                    text: `${text}${line}`,
                  });
                  text = "";
                });
              }
            } else if (lines.length > 1) {
              counters = new Counters();
              if (text) {
                lines[0] = `${text}${lines[0]}`;
                text = "";
              }
              results.push(...lines.slice(0, lines.length - 1));
            }
            text = `${text}${lines[lines.length - 1]}`;
          }
        }
      }
      if (text) {
        results.push(text);
      }
    }
  }
  return results;
}
/* eslint-enable no-loop-func, max-depth, max-statements */

export default convertContentsToListItems;

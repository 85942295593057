import UNINITIALISED from "utils/uninitialised";
import DocumentActionTypes from "modules/documents/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function user(state = UNINITIALISED, action) {
  const {type} = action;
  if (type.endsWith("ERROR")) {
    const {data} = action.payload;
    const processedData =
      typeof data === "object" && data.constructor.name === "ArrayBuffer"
        ? JSON.parse(String.fromCharCode.apply(null, new Uint8Array(data)))
        : data;
    return {
      ...action.payload,
      message: processedData && processedData.message,
      time: new Date().getTime(),
      type: type.replace("_ERROR", ""),
    };
  } else if (
    type === DocumentActionTypes.DOCUMENT_DOWNLOAD.SUCCESS &&
    action.payload.wasMarkupInInitialXml
  ) {
    return {
      message: "Original markup in the document has been accepted",
      time: new Date().getTime(),
    };
  } else if (type === ActionTypes.ERROR_CLEAR.SUCCESS) {
    return UNINITIALISED;
  }
  return state;
}

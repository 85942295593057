import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function contractTypesFetch(requestor) {
  return createReduxPromiseAction(
    ActionTypes.CONTRACT_TYPES_FETCH,
    (organisationId, useArchivedIssues) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/contract_type${
            typeof useArchivedIssues === "boolean"
              ? `?use_archived_issues=${useArchivedIssues}`
              : ""
          }`,
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        }),
  );
}

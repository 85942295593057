import React from "react";
import _ from "underscore";

import TopicListItem from "./topic_list_item";
import constructTopicListData from "routes/document_detail/utils/construct_topic_list_data";
import updateStateOnItemClick from "./utils/update_state_on_item_click";
import recursivelyUpdateStateOnItemClick from "./utils/recursively_update_state_on_item_click";

const styles = {
  main: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: "#FAFAFA",
    padding: 10,
  },
};

export default class TopicList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topicsData: constructTopicListData(props.topics, props.isTopicSelected),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.topics.length !== prevProps.topics.length) {
      this.setState(() => ({
        topicsData: constructTopicListData(
          this.props.topics,
          this.props.isTopicSelected,
        ),
      }));
    }
  }

  render() {
    return (
      <div style={styles.main}>
        {this.state.topicsData.map((topic, index) => (
          <TopicListItem
            key={index}
            topic={topic}
            documentClauses={this.props.documentClauses}
            document={this.props.document}
            topicsById={this.props.topicsById}
            onClauseClick={this.props.onClauseClick}
            onItemClick={this.onItemClick}
            onChildrenOpen={this.createChildrenClicker(
              recursivelyUpdateStateOnItemClick,
              true,
              true,
            )}
            onChildrenClose={this.createChildrenClicker(
              recursivelyUpdateStateOnItemClick,
              false,
            )}
          />
        ))}
      </div>
    );
  }

  onItemClick = item => {
    this.setState(prevState => {
      const newData = updateStateOnItemClick(prevState.topicsData, item);
      return {topicsData: newData};
    });
  };

  createChildrenClicker = (handler, newStateValue, openCurrentItem) =>
    _.memoize(item => {
      this.setState(prevState => {
        const newData = handler(
          prevState.topicsData,
          item,
          newStateValue,
          openCurrentItem,
        );
        return {topicsData: newData};
      });
    });
}

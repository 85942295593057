import ActionTypes from "../constants/action_types";

import basePath from "base_path";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function registerUser(requestor) {
  return createReduxPromiseAction(
    ActionTypes.USER_REGISTER,
    (username, password) =>
      requestor
        .post(
          `${basePath}/auth/register`,
          {username, password},
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}

import React from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import {validate as validateEmail} from "email-validator";
import validatePassword, {
  getValidationMessage,
} from "common/utils/password_validator";
import isContractReview from "utils/is_contract_review";

import Paper from "material-ui/Paper";
import TextField from "material-ui/TextField";
import VisibilityIcon from "material-ui/svg-icons/action/visibility";
import VisibilityOffIcon from "material-ui/svg-icons/action/visibility-off";

import ForgotPasswordDialog from "./forgot_password_dialog";
import MsLogo from "./ms_logo";
import GoogleLogo from "./google_logo";
import Upload from "icons/Upload";
import Edit from "icons/Edit";
import Report from "icons/Report";
import Playbook from "icons/Playbook";

import Button from "@material-ui/core/Button";

const styles = {
  forgotPasswordButton: {
    border: "none",
    background: "none",
    cursor: "pointer",
    outline: "none",
    color: "rgba(0, 0, 0, 0.54)",
    fontSize: "0.8em",
    textDecoration: "underline",
    display: "block",
  },
  triggerShowPasswordIcon: {
    position: "absolute",
    right: "0px",
    bottom: "14px",
    height: "18px",
    width: "18px",
    cursor: "pointer",
    color: "#757575",
  },
  label: {
    position: "relative",
    zIndex: "99",
    top: "12px",
    fontSize: "12px",
    color: "#9E9E9E",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    width: "266px",
  },
  loginButtonsContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    margin: "0 25px",
  },
  loginButtonsBlock: {
    display: "flex",
    justifyContent: "space-around",
    width: "216px",
  },
  signInButton: {
    marginTop: "1em",
    width: "216px",
  },
  signInLink: {
    display: "flex",
    textDecoration: "none",
    border: "1px solid #8c8c8c",
    height: "39px",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  buttonTextStyle: {
    fontWeight: "500",
    color: "#5e5e5e",
    fontSize: "16px",
    whiteSpace: "nowrap",
  },
  errorMessage: {
    border: "1px solid #9a0034",
    backgroundColor: "#f48fb1",
    padding: "0.5em",
    textAlign: "center",
    margin: "1em 25px",
    color: "#9a0034",
  },
  paperBlock: {
    marginLeft: "auto",
    marginRight: "auto",
    padding: "1em 2em",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
};

function getErrorMessage(email, password) {
  let errorMessage = "";
  if (!validateEmail(email)) {
    errorMessage = "Please provide a valid email address";
  } else if (!validatePassword(password)) {
    errorMessage = getValidationMessage(password);
  }
  return errorMessage;
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    const match = decodeURI(props.queryString).match(
      new RegExp("\\?username=(.*)&password=(.*)"),
    );
    const errorMessage = decodeURI(props.queryString).match(
      new RegExp("\\?error=(.*)"),
    );
    this.state = {
      username: match ? match[1] : "",
      password: match ? match[2] : "",
      isForgotPasswordDialogOpen: false,
      errorMessage: errorMessage ? errorMessage[1] : "",
      isPasswordShown: false,
      showErrorMessage: true,
    };
  }

  componentDidUpdate(prevProps) {
    const {user: prevUser} = prevProps;
    const {user: curUser} = this.props;
    const {error} = curUser && curUser;
    if (
      (!_.isEqual(prevUser, curUser) && error) ||
      (!this.state.errorMessage && error)
    ) {
      this.setState(() => ({
        errorMessage: this.getErrorMessage(curUser),
      }));
    }
  }

  getErrorMessage(user) {
    if (user.errorMessage === "user locked") {
      return "Your account has been locked after too many failed logins";
    }
    if (user.errorMessage === "password expired") {
      return (
        "Your password has expired, please click on the " +
        '"Reset Password" button to reset your password.'
      );
    }
    return `You failed to${
      user.error === "login_fail"
        ? " log in"
        : user.error === "password_reset_fail"
        ? " reset your password"
        : " register"
    }. Please ${
      user.error === "password_reset_fail" ? "wait 60 seconds and " : ""
    }try again`;
  }

  render() {
    const {shouldForgotPasswordBeDialog} = this.props;
    const {isPasswordShown} = this.state;
    const {
      isPanelDisplay,
      aadLoginLink,
      aadLoginAction,
      googleLoginLink,
    } = this.props;
    const TriggerShowPasswordIcon = isPasswordShown
      ? VisibilityOffIcon
      : VisibilityIcon;
    const passwordExpired = this.props.user.errorMessage === "password expired";

    return (
      <div
        style={{
          ...styles.wrapper,
          ...(isPanelDisplay ? {alignItems: "flex-start"} : {}),
        }}
      >
        <div
          style={{
            width: isPanelDisplay ? "30em" : 330,
            marginBottom: 60,
          }}
        >
          <div
            style={{
              marginBottom: 30,
              textAlign: "center",
              fontSize: 24,
              fontFamily: "Raleway",
              fontWeight: 800,
              color: "#232e38",
            }}
          >
            {isContractReview() ? (
              <span>
                CONTRACT<span style={{color: "#2096f3"}}>-REVIEW.COM</span>
              </span>
            ) : (
              <span>
                LEXICAL <span style={{color: "#2096f3"}}>LABS</span>
              </span>
            )}
          </div>
          <Paper style={styles.paperBlock}>
            {(shouldForgotPasswordBeDialog ||
              !this.state.isForgotPasswordDialogOpen) && (
              <>
                <div
                  style={{
                    ...styles.fieldContainer,
                    ...(isPanelDisplay ? {marginTop: "2em"} : {}),
                  }}
                >
                  <label style={styles.label}>Email</label>
                  <TextField
                    name="username"
                    className="username"
                    ref={this.createUsernameRef}
                    onKeyUp={this.testKeyUp}
                    value={this.state.username}
                    onChange={this.handleUsernameChange}
                    onFocus={this.moveCaretAtEnd}
                    spellCheck="false"
                    style={{width: "100%"}}
                  />
                </div>
                <div style={{...styles.fieldContainer, position: "relative"}}>
                  <label style={styles.label}>Password</label>
                  <TextField
                    name="password"
                    className="password"
                    spellCheck="false"
                    type={isPasswordShown ? "text" : "password"}
                    onKeyUp={this.testKeyUp}
                    value={this.state.password}
                    onChange={this.handlePasswordChange}
                    inputStyle={{width: "98%"}}
                    style={{width: "100%"}}
                  />
                  <TriggerShowPasswordIcon
                    style={styles.triggerShowPasswordIcon}
                    onClick={this.onTriggerShowPassword}
                  />
                </div>
                <div style={styles.loginButtonsContainer}>
                  <div style={styles.loginButtonsBlock}>
                    <Button onClick={this.login} color="primary">
                      LOGIN
                    </Button>
                    {this.props.allowRegistration && (
                      <Button onClick={this.register} color="primary">
                        REGISTER
                      </Button>
                    )}
                  </div>
                  <div>
                    <Button
                      style={styles.forgotPasswordButton}
                      onClick={this.onForgotPasswordDialogOpen}
                    >
                      {passwordExpired ? "Reset Password" : "Forgot password"}
                    </Button>
                  </div>
                  {(aadLoginLink || aadLoginAction) && (
                    <div style={styles.signInButton}>
                      <a
                        href={aadLoginLink}
                        onClick={aadLoginAction}
                        style={styles.signInLink}
                      >
                        <span style={{paddingTop: "4px"}}>
                          <MsLogo />
                        </span>
                        <div style={{marginLeft: "12px"}}>
                          <span
                            style={{
                              ...styles.buttonTextStyle,
                              fontFamily: "Segoe UI, Roboto, sans-serif",
                            }}
                          >
                            Sign in with Microsoft
                          </span>
                        </div>
                      </a>
                    </div>
                  )}
                  {googleLoginLink && !isPanelDisplay && (
                    <div style={styles.signInButton}>
                      <a href={googleLoginLink} style={styles.signInLink}>
                        <span style={{paddingTop: "4px"}}>
                          <GoogleLogo />
                        </span>
                        <div style={{marginLeft: "12px"}}>
                          <span
                            style={{
                              ...styles.buttonTextStyle,
                              fontFamily: "Roboto",
                            }}
                          >
                            Sign in with Google
                          </span>
                        </div>
                      </a>
                    </div>
                  )}
                </div>
                {this.state.errorMessage && this.state.showErrorMessage && (
                  <div className="error-message" style={styles.errorMessage}>
                    <p>{this.state.errorMessage}</p>
                  </div>
                )}
              </>
            )}
            {this.state.isForgotPasswordDialogOpen && (
              <ForgotPasswordDialog
                isOpen={true}
                onClose={this.onForgotPasswordDialogClose}
                onConfirm={this.onForgotPasswordDialogConfirm}
                email={this.state.username}
                passwordExpired={passwordExpired}
                shouldBeDialog={shouldForgotPasswordBeDialog}
              />
            )}

            {isPanelDisplay &&
              (aadLoginLink || aadLoginAction) &&
              this.renderInfoPanel()}
          </Paper>
        </div>
      </div>
    );
  }

  renderInfoPanel() {
    const styles = {
      listItem: {display: "flex", alignItems: "flex-end", paddingBottom: "1em"},
      listItemText: {marginLeft: "0.75em", display: "inline-block"},
    };
    return (
      <div
        style={{
          marginTop: "2em",
          fontFamily: "Segoe UI Light, Segoe UI, Roboto, sans-serif",
          fontSize: "18px",
          display: "flex",
          fontWeight: 100,
          flexDirection: "column",
        }}
      >
        <hr style={{width: "100%"}} />
        <h2
          style={{
            fontWeight: 100,
            fontSize: "1.75em",
            textAlign: "center",
            marginBottom: "0.25em",
          }}
        >
          Welcome to{" "}
          {isContractReview() ? "Contract-Review.com" : "Lexical Labs"}
        </h2>
        <h3
          style={{
            fontWeight: 100,
            fontSize: "1em",
            textAlign: "center",
            margin: 0,
            marginBottom: "1em",
          }}
        >
          Contract review and negotiation
        </h3>
        <div style={{textAlign: "center", marginBottom: "1em"}}>
          Arrange a demo & sign up at{" "}
          <a target="_blank" href="https://www.lexicallabs.com">
            www.lexicallabs.com
          </a>
        </div>
        <ul
          style={{
            fontSize: "1em",
            paddingLeft: "0",
            marginLeft: "auto",
            marginRight: "auto",
            listStyleType: "none",
            marginBottom: 0,
          }}
        >
          <li style={styles.listItem}>
            <Upload style={{color: "black"}} />
            <span style={styles.listItemText}>Upload contracts</span>
          </li>
          <li style={styles.listItem}>
            <Edit style={{color: "black"}} />
            <span style={styles.listItemText}>Edit clauses</span>
          </li>
          <li style={styles.listItem}>
            <Playbook style={{color: "black"}} />
            <span style={styles.listItemText}>Playbook review</span>
          </li>
          <li style={styles.listItem}>
            <Report style={{color: "black"}} />
            <span style={styles.listItemText}>Generate reports</span>
          </li>
        </ul>
        <a
          href="https://www.lexicallabs.com/whitepapers"
          target="_blank"
          style={{
            margin: "0 auto",
            display: "flex",
            color: "#0065b3",
            textDecoration: "none",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              padding: "0 2em",
              margin: "1em auto",
              fontSize: "0.75em",
              fontWeight: 600,
            }}
          >
            View our latest whitepaper
          </Button>
        </a>
      </div>
    );
  }

  onForgotPasswordDialogClose = () =>
    this.setState(() => ({isForgotPasswordDialogOpen: false}));
  onForgotPasswordDialogOpen = () =>
    this.setState(() => ({isForgotPasswordDialogOpen: true}));
  onForgotPasswordDialogConfirm = email => {
    this.props.onForgotPassword(email);
    this.onForgotPasswordDialogClose();
  };
  componentDidMount() {
    if (this.usernameRef) {
      this.usernameRef.focus();
    }
  }

  /* eslint-disable no-invalid-this */
  handleUsernameChange = event =>
    this.setState({showErrorMessage: false, username: event.target.value});
  handlePasswordChange = event =>
    this.setState({showErrorMessage: false, password: event.target.value});

  testKeyUp = event => {
    if (event.keyCode === 13) {
      this.login();
    }
  };

  login = () => {
    if (this.props.clearError) {
      this.props.clearError();
    }
    if (
      this.lastUsedPassword !== this.state.password ||
      this.lastUsedUsername !== this.state.username
    ) {
      this.setState({showErrorMessage: true, errorMessage: ""});
      this.props.onLogin(this.state.username, this.state.password);
    } else {
      this.setState({showErrorMessage: true});
    }
    this.lastUsedPassword = this.state.password;
    this.lastUsedUsername = this.state.username;
  };

  register = () => {
    const {onRegister} = this.props;
    const {username, password} = this.state;
    const errorMessage = getErrorMessage(username, password);
    if (errorMessage) {
      return this.setState(() => ({errorMessage, showErrorMessage: true}));
    }
    this.setState(
      () => ({errorMessage: ""}),
      () => onRegister(username, password),
    );
  };

  onTriggerShowPassword = e => {
    e.stopPropagation();
    this.setState(prevState => ({isPasswordShown: !prevState.isPasswordShown}));
  };

  moveCaretAtEnd(e) {
    const t = e.target.value;
    e.target.value = "";
    e.target.value = t;
  }

  createUsernameRef = node => (this.usernameRef = node);
  /* eslint-enable no-invalid-this */
}

Login.propTypes = {
  user: PropTypes.shape({
    error: PropTypes.string,
  }),
  onLogin: PropTypes.func.isRequired,
};

export default Login;

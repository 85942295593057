import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";

import {withStyles} from "@material-ui/core/styles";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {SearchAndAppsIcon} from "constants/icons";

import TabHeader from "./tab_header";
import mergeClausepartsAndHeadings from "./merge_clauseparts_and_headings";
import MarkupText from "routes/search/components/markup_text";
import {projectPath, setParamsToPath} from "routes/navigation";
import escapeRegex from "utils/escape_regex";

const styles = theme => ({
  progressContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 105,
  },
  noResults: {
    color: "#8d9398",
    fontSize: 13,
    textAlign: "center",
  },
  tabs: {
    flexShrink: 0,
    margin: 0,
    padding: 0,
    position: "relative",
    height: 40,
    display: "flex",
    boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.07)",
    borderBottom: "1px solid #dbdbdb",
    "& li": {
      listStyle: "none",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "1%",
      textAlign: "center",
      color: "#787f85",
      borderLeft: "1px solid #e6e6e6",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.03)",
      },
      "&.active-tab": {
        color: "#1f88e5",
        borderBottom: "1px solid #099cff",
        "&:hover": {
          background: "none",
        },
      },
      "&.disabled-tab": {
        color: "#c1c6ca",
        cursor: "not-allowed",
        "&:hover": {
          background: "none",
        },
      },
      "&:first-child": {
        borderLeft: "none",
      },
      "& strong": {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1,
      },
      "& p": {
        margin: 0,
        fontSize: 10,
      },
    },
  },
  toolbar: {
    boxSizing: "border-box",
    flexShrink: 0,
    padding: "7px 0",
    margin: 0,
    height: 60,
    borderBottom: "1px solid #e6e6e6",
    backgroundColor: "#f1f1f2",
    display: "flex",
    justifyContent: "flex-end",
    color: "#232e38",
    "& li": {
      listStyle: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeft: "1px solid #e6e6e6",
      padding: "0 12px",
    },
    "& p": {
      fontSize: 8,
      fontWeight: 500,
      margin: 0,
    },
    "& .MuiSwitch-root": {
      padding: 0,
      width: 36,
      height: 20,
      overflow: "visible",
      "& .MuiSwitch-switchBase": {
        padding: 3,
        "&.Mui-checked": {
          transform: "translateX(14px)",
          "& .MuiSwitch-thumb": {
            border: "1px solid #1f88e5",
            backgroundColor: "#2096f3",
          },
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        boxShadow: "0 0 12px rgba(0, 0, 0, 0.3)",
        border: "1px solid rgba(35, 46, 56, 0.2)",
        backgroundColor: "#60666b",
      },
      "& .MuiSwitch-track": {
        borderRadius: 14,
        backgroundColor: "rgba(170, 170, 170, 0.15)",
        border: "1px solid rgba(170, 170, 170, 0.3)",
        transition: theme.transitions.create(["background-color", "border"]),
      },
    },
  },
  activeGroup: {
    maxHeight: 158,
    marginBottom: 10,
    flexShrink: 0,
    flexGrow: 0,
    overflowX: "hidden",
    overflowY: "auto",
    "& > header": {
      marginTop: 8,
      padding: "0 19px",
      color: "#232e38",
      "& h4": {
        margin: "0 0 6px",
        fontSize: 13,
      },
      "& ul": {
        overflow: "auto",
        maxHeight: 118,
        margin: "0 -3px",
        padding: 0,
        listStyle: "none",
        fontSize: 11,
        "& li": {
          cursor: "pointer",
          padding: "2px 4px",
          "&:not(.active-subgroup):hover": {
            backgroundColor: "#eaebeb",
          },
          "& > div": {
            display: "flex",
            alignItems: "center",
            "& > div": {
              flexGrow: 1,
              marginRight: 5,
            },
            "& > span": {
              borderRadius: "50%",
              backgroundColor: "#d2d5d8",
              width: 18,
              height: 18,
              flexShrink: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 11,
            },
          },
        },
        "& li + li": {
          borderTop: "1px dashed rgba(0,0,0,0)",
        },
        "& li:not(.active-subgroup) + li:not(.active-subgroup)": {
          borderColor: "#ccc",
        },
        "& li.active-subgroup": {
          borderRadius: 3,
          backgroundColor: "#07355f",
          color: "#fff",
          cursor: "default",
          fontWeight: "bold",
          "& > div > div > span": {
            color: "#ccc !important",
          },
          "& > div > div > span.highlight": {
            backgroundColor: "#85bef1 !important",
            color: "#fff !important",
          },
          "& > div > span": {
            backgroundColor: "#fff",
            color: "#333",
          },
        },
      },
    },
  },
  results: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
    overflowY: "auto",
  },
  project: props => ({
    marginBottom: 13,
    padding: "0 19px 10px 21px",
    fontFamily:
      props.appType === "wordTaskpane"
        ? "Segoe UI, Roboto, sans-serif"
        : "Roboto, sans-serif",
    fontSize: "13px",
    transition: theme.transitions.create(["padding-bottom", "margin-bottom"]),
    borderBottom: "1px solid #c8c8c8",
  }),
  collapsedProject: {
    border: "none",
    paddingBottom: 0,
    marginBottom: 0,
  },
  projectName: {
    color: "#1f88e5",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 12,
    cursor: "pointer",
    lineHeight: "31px",
    borderBottom: "1px solid #e6e6e6",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
  },
  expandIcon: {
    color: "#82888e",
    fontSize: "1.2rem",
    marginRight: -2,
  },
  documentList: {
    padding: "5px 0 0",
  },
  documentRoot: {
    position: "relative",
    borderRadius: 4,
    borderBottom: "1px solid #e6e6e6",
    padding: "10px 12px",
    marginLeft: -10,
    marginRight: -10,
    "& > div h3": {
      fontSize: 14,
      fontWeight: 400,
      margin: "0 0 7px",
      color: "#232e38",
      lineHeight: 1.36,
      overflow: "hidden",
      wordBreak: "break-all",
    },
    "&.selectable:hover": {
      cursor: "pointer",
      backgroundColor: "#eaebeb",
      "& .MuiSvgIcon-root": {
        display: "block",
      },
    },
    "& .documentName:hover .MuiSvgIcon-root.rightIcon": {
      visibility: "visible",
    },
    "& .MuiSvgIcon-root": {
      width: 16,
      height: 16,
      color: "#1f88e5",
    },
    "& .MuiSvgIcon-root.absoluteBottomIcon": {
      display: "none",
      position: "absolute",
      bottom: 10,
      right: 8,
    },
    "& .MuiSvgIcon-root.rightIcon": {
      visibility: "hidden",
    },
    "&.selected": {
      backgroundColor: "#232e38",
      "& > h3": {
        color: "#fff",
      },
      "& $numberOfClauseparts": {
        color: "#91979c",
      },
      "& .MuiSvgIcon-root": {
        display: "block",
      },
    },
  },
  clausepartRoot: {
    color: "#4f5860",
    textAlign: "justify",
    "& > small": {
      fontSize: 11,
      marginBottom: 1,
      color: "#8d9398",
    },
    "& > div": {
      fontSize: 11,
      color: "#4f5860",
      marginTop: 3,
      marginBottom: 9,
      lineHeight: 1.36,
    },
  },
  numberOfClauseparts: {
    marginTop: 9,
    fontSize: 10,
    color: "#8a8f95",
  },
  moreClauseparts: {
    marginTop: 9,
    fontSize: 10,
    color: "#1f88e5",
    "& span": {
      cursor: "pointer",
    },
  },
  panel: props => ({
    position: "fixed",
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    left: 342,
    width: 470,
    backgroundColor: "#f8f8f8",
    zIndex: 1201,
    boxShadow:
      "0px 1px 10px rgba(0, 0, 0, 0.17), 0px 1px 15px rgba(0, 0, 0, 0.04)",
    fontFamily:
      props.appType === "wordTaskpane"
        ? "Segoe UI, Roboto, sans-serif"
        : "Roboto, sans-serif",
  }),
  panelHeader: {
    flexShrink: 0,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "23px 7px 23px 33px",
    minHeight: 95,
    borderBottom: "1px solid #dadde3",
    boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.07)",
    "& h2": {
      fontSize: 20,
      fontWeight: 500,
      color: "#232e38",
      margin: "0 0 10px",
    },
    "& a": {
      display: "inline-block",
      color: "#1f88e5",
      fontSize: 11,
      textDecoration: "none",
      "& > *": {
        verticalAlign: "middle",
      },
      "& .MuiSvgIcon-root": {
        width: 18,
        height: 18,
      },
      "& span": {
        marginLeft: 3,
      },
    },
    "& .MuiIconButton-root": {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "& .MuiSvgIcon-root": {
        color: "#b8bbbe",
      },
    },
  },
  panelBody: {
    flexGrow: 1,
    overflowX: "hidden",
    overflowY: "auto",
    padding: "24px 27px 24px 33px",
    "& h5": {
      fontSize: 13,
      fontWeight: 500,
      color: "#232e38",
      margin: "0 0 15px",
    },
    "& ul": {
      margin: 0,
      padding: 0,
      "& li": {
        paddingTop: 18,
        paddingBottom: 23,
        borderTop: "1px solid #e6e6e6",
        listStyle: "none",
        "& > small": {
          fontSize: 12,
          marginBottom: 1,
          color: "#8d9398",
        },
        "& > div": {
          fontSize: 14,
          color: "#4f5860",
          marginTop: 3,
          lineHeight: 1.36,
        },
      },
    },
  },
  heading: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "24rem",
  },
});

const highlightedStyle = {
  backgroundColor: "#85bef1",
  color: "#383c40",
  padding: "0 2px",
};

class AllDocumentsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultsCount: 0,
      hasLoading: false,
      collapsedProjects: [],
      expandedDocuments: [],
      showText: true,
      selectedDocumentId: null,
      selectedProjectId: null,
      activeGroupName: "Text",
      nextActiveGroupName: null,
    };
    if (props.searchValue.length) {
      this.state.hasLoading = true;
      props.search(props.searchValue, props.searchOptions);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.tmpData &&
      this.props.tmpData.activeGroupName &&
      this.props.tmpData.activeGroupName !== this.state.nextActiveGroupName
    ) {
      this.setState({nextActiveGroupName: this.props.tmpData.activeGroupName});
    }
    if (
      this.state.selectedDocumentId &&
      (prevState.showText !== this.state.showText ||
        prevProps.isActive !== this.props.isActive)
    ) {
      this.removeDocumentSelection();
    }
    let activeGroupData = this.getActiveGroupData();
    if (
      prevProps.searchValue !== this.props.searchValue ||
      prevProps.exactMatch !== this.props.exactMatch
    ) {
      if (activeGroupData.subGroupName) {
        this.setState({activeGroupName: activeGroupData.groupName});
      }
      this.removeDocumentSelection();
      if (this.props.searchValue.length) {
        return this.setState(
          () => ({hasLoading: true}),
          () => {
            let newSearchOptions = this.props.searchOptions;
            if (this.props.exactMatch !== undefined) {
              newSearchOptions = {
                ...(newSearchOptions || {}),
                exactMatch: this.props.exactMatch,
              };
            }
            this.props.search(this.props.searchValue, newSearchOptions);
          },
        );
      }
      return this.props.search("");
    }
    if (prevProps.searchResults !== this.props.searchResults) {
      if (this.state.nextActiveGroupName) {
        this.setState({
          activeGroupName: this.state.nextActiveGroupName,
          nextActiveGroupName: null,
        });
        activeGroupData = this.getActiveGroupData(
          this.state.nextActiveGroupName,
        );
      }
      this.setState({
        hasLoading: false,
        resultsCount: this.countResults(),
      });
      if (activeGroupData.groupName !== "Text") {
        const groupedSearchResults = this.getGroupedSearchResults();
        const activeGroup = groupedSearchResults[activeGroupData.groupName];
        if (activeGroup.count === 0) {
          this.setState({activeGroupName: "Text"});
        } else {
          const cleanedSubGroupNames = _.keys(activeGroup.subGroups).map(
            subGroupName => cleanHighlightedText(subGroupName),
          );
          if (!cleanedSubGroupNames.includes(activeGroupData.subGroupName)) {
            this.setState({activeGroupName: activeGroupData.groupName});
          }
        }
      }
    }
  }

  getActiveGroupData = _activeGroupName => {
    const activeGroupName = _activeGroupName || this.state.activeGroupName;
    const parts = activeGroupName.split(".");
    return {
      groupName: parts[0],
      subGroupName: parts[1],
    };
  };

  countResults = _props => {
    const props = _props || this.props;
    let counter = 0;
    if (_.isArray(props.searchResults) && props.searchResults.length) {
      props.searchResults.forEach(project => {
        project.documents.forEach(document => {
          counter += document.clauseparts.length;
          counter += document.matched_headings.length;
          if (document.document_name_matches) {
            counter += 1;
          }
        });
      });
    }
    return counter;
  };

  toggleProjectCollapsing = projectId => {
    const collapsedProjects = [...this.state.collapsedProjects];
    if (collapsedProjects.includes(projectId)) {
      collapsedProjects.splice(collapsedProjects.indexOf(projectId), 1);
    } else {
      collapsedProjects.push(projectId);
    }
    this.setState({collapsedProjects});
  };

  expandDocument = documentId => {
    const expandedDocuments = [...this.state.expandedDocuments];
    if (!expandedDocuments.includes(documentId)) {
      expandedDocuments.push(documentId);
    }
    this.setState({expandedDocuments});
  };

  removeDocumentSelection = () => {
    this.setState({
      selectedDocumentId: null,
      selectedProjectId: null,
    });
  };

  getGroupedSearchResults = () => {
    const groupNames = ["Text", "Issues", "Topics"];
    const list = {};
    _.forEach(groupNames, groupName => {
      list[groupName] = {count: 0};
    });
    if (
      _.isArray(this.props.searchResults) &&
      this.props.searchResults.length
    ) {
      const addClausepart = (groupName, project, document, clausepart) => {
        const groupNameParts = groupName.split(".");
        const group = list[groupNameParts[0]];
        group.count += 1;
        let results;
        if (groupNameParts.length === 1) {
          if (!_.has(group, "results")) {
            group.results = [];
          }
          results = group.results;
        } else {
          const subGroupName = groupNameParts[1];
          if (!_.has(group, "subGroups")) {
            group.subGroups = {};
          }
          if (!_.has(group.subGroups, subGroupName)) {
            group.subGroups[subGroupName] = {
              count: 0,
              results: [],
            };
          }
          results = group.subGroups[subGroupName].results;
          group.subGroups[subGroupName].count += 1;
        }
        let groupProject = results.find(({id}) => id === project.id);
        if (!groupProject) {
          groupProject = {
            ...project,
            documents: [],
          };
          results.push(groupProject);
        }
        let groupDocument = groupProject.documents.find(
          ({id}) => id === document.id,
        );
        if (!groupDocument) {
          groupDocument = {
            ...document,
            clauseparts: [],
          };
          groupProject.documents.push(groupDocument);
        }
        if (clausepart) {
          groupDocument.clauseparts.push(clausepart);
        }
      };
      this.props.searchResults.forEach(project => {
        project.documents.forEach(document => {
          const clauseparts = mergeClausepartsAndHeadings(
            document.clauseparts,
            document.matched_headings,
          );

          if (!clauseparts || clauseparts.length === 0) {
            addClausepart("Text", project, document, null);
          }
          clauseparts.forEach(clausepart => {
            if (clausepart.join_type === "NO_NAMES") {
              addClausepart("Text", project, document, clausepart);
            }
            const {issue_names, topic_names} = clausepart;
            if (_.isArray(issue_names) && issue_names.length) {
              issue_names.forEach(issueName => {
                addClausepart(
                  `Issues.${issueName}`,
                  project,
                  document,
                  clausepart,
                );
              });
            }
            if (_.isArray(topic_names) && topic_names.length) {
              topic_names.forEach(topicName => {
                addClausepart(
                  `Topics.${topicName}`,
                  project,
                  document,
                  clausepart,
                );
              });
            }
          });
        });
      });
      _.forEach(list, group => {
        if (group.subGroups) {
          group.subGroups = _.chain(group.subGroups)
            .map((subGroup, subGroupName) => ({
              subGroup,
              subGroupName,
            }))
            .sortBy("subGroupName")
            .map(({subGroup, subGroupName}) => [subGroupName, subGroup])
            .fromPairs()
            .value();
        }
      });
    }
    return list;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.appType !== "wordTaskpane" && (
          <TabHeader
            title="All Documents"
            resultsCount={this.state.resultsCount}
            showLoading={!this.props.isActive && this.state.hasLoading}
            Icon={<SearchAndAppsIcon />}
            {...this.props}
          />
        )}
        {this.renderBody()}
      </React.Fragment>
    );
  }

  renderBody() {
    if (!this.props.isActive) {
      return null;
    }
    const {classes, searchValue} = this.props;
    const showProgress =
      this.state.hasLoading ||
      (searchValue.length && !_.isArray(this.props.searchResults));
    if (showProgress) {
      return (
        <div className={classes.progressContainer}>
          <CircularProgress size={30} />
        </div>
      );
    }
    if (!searchValue.length) {
      return <div className={classes.noResults}>No Matches</div>;
    }
    const groupedSearchResults = this.getGroupedSearchResults();

    // active group data
    const activeGroupData = this.getActiveGroupData();
    const activeGroup = groupedSearchResults[activeGroupData.groupName];
    const hasSubGroups = _.has(activeGroup, "subGroups");
    const firstSubGroupName = hasSubGroups
      ? cleanHighlightedText(_.keys(activeGroup.subGroups)[0])
      : null;
    const activeSubGroupName = hasSubGroups
      ? activeGroupData.subGroupName || firstSubGroupName
      : null;
    const activeSubGroup = _.find(
      activeGroup.subGroups,
      (subGroup, subGroupName) => {
        return cleanHighlightedText(subGroupName) === activeSubGroupName;
      },
    );

    return (
      <React.Fragment>
        {this.renderTabs(groupedSearchResults)}
        {this.renderToolbar()}
        {hasSubGroups
          ? this.renderActiveGroupHeading(
              activeGroupData,
              activeGroup,
              activeSubGroupName,
              firstSubGroupName,
            )
          : null}
        <div style={{overflow: "auto"}}>
          {this.renderActiveGroupResults(
            hasSubGroups ? activeSubGroup.results : activeGroup.results,
          )}
          {this.renderPanel()}
        </div>
      </React.Fragment>
    );
  }

  renderTabs(groupedSearchResults) {
    const activeGroupData = this.getActiveGroupData();
    return (
      <ul className={this.props.classes.tabs}>
        {_.map(groupedSearchResults, (group, groupName) => {
          const classNames = [];
          const isDisabled = group.count === 0;
          const isActive =
            !isDisabled && groupName === activeGroupData.groupName;
          if (isDisabled) {
            classNames.push("disabled-tab");
          }
          if (isActive) {
            classNames.push("active-tab");
          }
          return (
            <li
              key={groupName}
              {...classNames.length && {className: classNames.join(" ")}}
              {...Boolean(!isActive && !isDisabled) && {
                onClick: () => this.setState({activeGroupName: groupName}),
              }}
            >
              <strong>{group.count}</strong>
              <p>{groupName}</p>
            </li>
          );
        })}
      </ul>
    );
  }

  renderToolbar() {
    if (this.props.appType === "wordTaskpane") {
      return null;
    }
    return (
      <ul className={this.props.classes.toolbar}>
        <li style={{flexDirection: "column"}}>
          <Switch
            checked={this.state.showText}
            onChange={(event, value) => this.setState({showText: value})}
            color="primary"
            inputProps={{"aria-label": "primary checkbox"}}
            size="small"
          />
          <p
            style={{
              textAlign: "center",
              marginTop: 5,
            }}
          >
            {this.state.showText ? "Show Text" : "Hide Text"}
          </p>
        </li>
      </ul>
    );
  }

  renderActiveGroupHeading(
    activeGroupData,
    activeGroup,
    activeSubGroupName,
    firstSubGroupName,
  ) {
    return (
      <div className={this.props.classes.activeGroup}>
        <header>
          <h4>{`Matched ${activeGroupData.groupName}:`}</h4>
          <ul>
            {_.map(activeGroup.subGroups, (subGroup, subGroupName) => {
              const cleanedSubGroupName = cleanHighlightedText(subGroupName);
              const isActive = cleanedSubGroupName === activeSubGroupName;
              const namePath = `${activeGroupData.groupName}${
                subGroupName === firstSubGroupName
                  ? ""
                  : `.${cleanedSubGroupName}`
              }`;
              return (
                <li
                  key={subGroupName}
                  {...isActive && {
                    className: "active-subgroup",
                  }}
                  {...!isActive && {
                    onClick: () => this.setState({activeGroupName: namePath}),
                  }}
                >
                  <div>
                    <MarkupText
                      text={subGroupName}
                      highlightedStyle={highlightedStyle}
                    />
                    <span>{subGroup.count}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </header>
      </div>
    );
  }

  renderActiveGroupResults(searchResults = []) {
    const {classes} = this.props;
    return (
      <div className={classes.results}>
        {searchResults.map((project, index) => {
          const isCollapsed = this.state.collapsedProjects.includes(project.id);
          const ExpandIcon = !isCollapsed ? ExpandLess : ExpandMore;
          const className = `${classes.project}${
            isCollapsed ? ` ${classes.collapsedProject}` : ""
          }`;
          const isLast = index === searchResults.length - 1;
          return (
            <div
              key={index}
              className={className}
              style={{
                ...(isLast && {borderBottom: "none"}),
              }}
            >
              <div
                className={classes.projectName}
                onClick={() => this.toggleProjectCollapsing(project.id)}
                style={{
                  ...(isCollapsed && {border: "none"}),
                }}
              >
                {`${project.name} (${project.documents.length})`}
                <ExpandIcon className={classes.expandIcon} />
              </div>
              <Collapse in={!isCollapsed} timeout="auto" unmountOnExit>
                <div className={classes.documentList}>
                  {project.documents.map((document, docIndex) => {
                    const isLast = docIndex === project.documents.length - 1;
                    if (this.state.showText) {
                      return this.renderDetailedDocument(
                        project,
                        document,
                        docIndex,
                        isLast,
                      );
                    }
                    return this.renderDocument(
                      project,
                      document,
                      docIndex,
                      isLast,
                    );
                  })}
                </div>
              </Collapse>
            </div>
          );
        })}
      </div>
    );
  }

  renderDetailedDocument(project, document, docIndex, isLast) {
    const {classes} = this.props;
    const isExpanded = this.state.expandedDocuments.includes(document.id);
    const clauseparts = !isExpanded
      ? [...document.clauseparts].splice(0, 2)
      : document.clauseparts;
    const diff = document.clauseparts.length - clauseparts.length;
    return (
      <div
        key={docIndex}
        className={classes.documentRoot}
        style={{
          ...(isLast && {
            borderBottom: "none",
            marginBottom: 0,
          }),
          ...(!this.state.showText && {borderBottom: "none"}),
        }}
      >
        {this.renderDocumentName(document, project.id, true)}
        {clauseparts.map((clausepart, clausepartIndex) => {
          const isLast = clausepartIndex === clauseparts.length - 1;
          return (
            <div key={clausepartIndex} className={classes.clausepartRoot}>
              <small>{clausepart.reference}</small>
              <MarkupText
                text={clausepart.highlighted_text || ""}
                rootStyle={{
                  flexGrow: 1,
                  ...(isLast && {marginBottom: 0}),
                }}
                highlightedStyle={highlightedStyle}
              />
            </div>
          );
        })}
        {diff > 0 && (
          <div className={classes.moreClauseparts}>
            <span onClick={() => this.expandDocument(document.id)}>
              {`+${diff} Clauses`}
            </span>
          </div>
        )}
      </div>
    );
  }

  renderDocumentName = (doc, projectId, withLink) => {
    const {name} = doc;
    let content = <span>{name}</span>;
    if (doc.document_name_matches) {
      const searchRegex = new RegExp(escapeRegex(this.props.searchValue), "gi");
      const markupName = name.replace(searchRegex, "<b>$&</b>");
      content = (
        <MarkupText text={markupName} highlightedStyle={highlightedStyle} />
      );
    }

    return (
      <div
        className="documentName"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h3>{content}</h3>
        {withLink && this.renderDocumentLink(doc.id, projectId, false, false)}
      </div>
    );
  };

  renderDocument(project, document, docIndex, isLast) {
    const {classes} = this.props;
    const isSelected = document.id === this.state.selectedDocumentId;
    const areClausepartsPresent =
      document.clauseparts && document.clauseparts.length > 0;
    const clicker = areClausepartsPresent
      ? () => {
          if (this.props.appType === "wordTaskpane") {
            return null;
          }
          this.setState({
            selectedDocumentId: document.id,
            selectedProjectId: project.id,
          });
        }
      : undefined;
    return (
      <div
        key={docIndex}
        className={`${classes.documentRoot} ${
          !isSelected ? "selectable" : "selected"
        }`}
        style={{
          ...(isLast && {
            borderBottom: "none",
            marginBottom: 0,
          }),
          ...(!this.state.showText && {borderBottom: "none"}),
        }}
        onClick={clicker}
      >
        {this.renderDocumentName(document, project.id, false)}
        <div className={classes.numberOfClauseparts}>
          {`${document.clauseparts.length} Clauses`}
        </div>
        {this.renderDocumentLink(document.id, project.id, false, true)}
      </div>
    );
  }

  renderPanel() {
    const {selectedDocumentId} = this.state;
    if (!selectedDocumentId) {
      return null;
    }
    const {classes} = this.props;
    const selectedDocument = _.chain(this.props.searchResults)
      .map(item => item.documents)
      .flatten()
      .find(({id}) => id === this.state.selectedDocumentId)
      .value();
    const {id: documentId} = selectedDocument;
    return ReactDOM.createPortal(
      <React.Fragment>
        <div className={classes.panel}>
          <div className={classes.panelHeader}>
            <div>
              <h2 className={classes.heading}>{selectedDocument.name}</h2>
              {this.renderDocumentLink(
                documentId,
                this.state.selectedProjectId,
                true,
                true,
              )}
            </div>
            <IconButton onClick={() => this.removeDocumentSelection()}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.panelBody}>
            <h5>{`${selectedDocument.clauseparts.length} Results`}</h5>
            <ul>
              {selectedDocument.clauseparts.map((clausepart, index) => (
                <li key={index}>
                  <small>{clausepart.reference}</small>
                  <MarkupText
                    text={clausepart.highlighted_text}
                    highlightedStyle={highlightedStyle}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div
          className="app-sidebar__overlay"
          onClick={() => this.removeDocumentSelection()}
        />
      </React.Fragment>,
      document.querySelector(".app-sidebar-container"),
    );
  }

  renderDocumentLink = (
    documentId,
    projectId,
    withMessage,
    isIconAbsoluteBottom,
  ) => {
    const {organisationId} = this.props;
    return (
      <a
        href={setParamsToPath(
          {
            organisationId,
            projectId,
            documentId,
          },
          `${projectPath}/document/:documentId/detail`,
        )}
        target="_blank"
      >
        <OpenInNewIcon
          className={isIconAbsoluteBottom ? "absoluteBottomIcon" : "rightIcon"}
        />
        {withMessage && <span>Open document</span>}
      </a>
    );
  };
}

function cleanHighlightedText(text) {
  const div = document.createElement("div");
  div.innerHTML = text.replace(/\s+/g, " ");
  return div.innerText;
}

export default withStyles(styles)(AllDocumentsTab);

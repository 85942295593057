import React from "react";
import _ from "lodash";

import DescriptionIcon from "@material-ui/icons/Description";
import SearchIcon from "@material-ui/icons/Search";
import PlaybookIcon from "@material-ui/icons/AssignmentTurnedIn";
import WorkIcon from "@material-ui/icons/Work";
import PeopleIcon from "@material-ui/icons/People";

import DocumentSidebar from "routes/document_detail/components/sidebar";
import ClauseBankIcon from "../icons/ClauseBankIcon";

export const organisationPath = "/organisation/:organisationId";
export const projectPath = `${organisationPath}/project/:projectId`;

export const userItems = [
  {
    icon: <WorkIcon />,
    text: "Projects",
    permission: "get-projects",
    route: {path: `${organisationPath}/project/list`},
    childRoutes: [
      {
        path: `${projectPath}/detail`,
      },
    ],
  },
  {
    icon: <DescriptionIcon />,
    text: "Documents",
    permission: "get-documents",
    route: {path: `${projectPath}/document/list`},
    childRoutes: [
      {
        path: `${projectPath}/document/:documentId`,
        isSidebarCollapsedLight: true,
      },
      {
        path: `${projectPath}/document/:documentId/detail`,
        CustomSidebar: DocumentSidebar,
      },
    ],
  },
  {
    icon: <ClauseBankIcon />,
    text: "Clause Bank",
    permission: "view-clausebank",
    route: {path: `${organisationPath}/clause-bank`},
  },
  {
    icon: <SearchIcon />,
    text: "Search",
    permission: "search",
    route: {path: `${organisationPath}/search`},
  },
  {
    icon: <PlaybookIcon />,
    text: "Playbook",
    permission: "view-playbook",
    route: {path: `${organisationPath}/playbooks`},
  },
  {
    icon: <PeopleIcon />,
    text: "Users",
    permission: "get-users",
    route: {path: `${organisationPath}/user/list`},
    childRoutes: [
      {
        path: `${organisationPath}/user/:userId`,
      },
    ],
  },
];

export const adminItems = [
  {
    text: "Contract Types",
    permission: "get-contract-type",
    route: {path: `${organisationPath}/contract_type/list`},
    childRoutes: [
      {
        path: `${organisationPath}/contract_type/detail/:contractTypeId`,
        isSidebarCollapsedLight: true,
      },
    ],
  },
  {
    text: "Definition Groups",
    permission: "get-definition-group",
    route: {path: `${organisationPath}/definition_group/list`},
    childRoutes: [
      {
        path: `${organisationPath}/definition_group/:definitionGroupId/detail`,
        isSidebarCollapsedLight: true,
      },
    ],
  },
  {
    text: "Roles",
    permission: "get-role",
    route: {path: `${organisationPath}/role/list`},
    childRoutes: [
      {
        path: `${organisationPath}/role/:roleId/detail`,
        isSidebarCollapsedLight: true,
      },
    ],
  },
  {
    text: "Topic Categories",
    permission: "list-topic-categories",
    route: {path: `${organisationPath}/topiccategory/list`},
  },
  {
    text: "Topics",
    permission: "list-topics",
    route: {path: `${organisationPath}/topic/list`},
    childRoutes: [
      {path: `${organisationPath}/topic/analysis_matrix`},
      {
        path: `${organisationPath}/topic/:topicId/detail`,
        isSidebarCollapsedLight: true,
      },
      {
        path: `${organisationPath}/topic/:topicId/analysis`,
        isSidebarCollapsedLight: true,
      },
    ],
  },
  {
    text: "Parameters",
    permission: "list-topics",
    route: {path: `${organisationPath}/topic/parameters`},
  },
  {
    text: "Issues",
    permission: "list-issues",
    route: {path: `${organisationPath}/issue/list`},
    childRoutes: [
      {
        path: `${organisationPath}/issue/:issueId`,
        isSidebarCollapsedLight: true,
      },
    ],
  },
  {
    text: "Prompts",
    permission: "update-prompt",
    route: {path: `${organisationPath}/prompt/list`},
  },
  {
    text: "Workflows",
    permission: "get-workflows",
    route: {path: `${organisationPath}/workflow/list`},
  },
  {
    text: "LLM Runs",
    permission: "get-workflows",
    route: {path: `${organisationPath}/llm_run/list`},
    childRoutes: [
      {
        path: `${organisationPath}/llm_run/:llmRunId/detail`,
        isSidebarCollapsedLight: true,
      },
    ],
  },
  {
    text: "Modules",
    route: {path: `${organisationPath}/modules`},
  },
  {
    text: "Clients",
    permission: "list-clients",
    route: {path: `${organisationPath}/clients`},
  },
  {
    text: "Permissions",
    permission: "list-permissions",
    route: {path: `${organisationPath}/permissions`},
  },
  {
    text: "Config",
    permission: "organisation-admin",
    route: {path: `${organisationPath}/config/detail`},
    childRoutes: [
      {
        path: `${organisationPath}/config/users/:userId`,
        isSidebarCollapsedLight: true,
      },
    ],
  },
  {
    text: "Logs",
    permission: "get-logs",
    route: {path: `${organisationPath}/logs`},
  },
  {
    text: "LLM Search",
    permission: "get-workflows",
    route: {path: `${organisationPath}/searcher`},
  },
  {
    text: "Analytics",
    permission: "get-analytics",
    route: {path: `${organisationPath}/analytics`},
  },
  {
    text: "User groups",
    permission: "view-usergroups",
    route: {path: `${organisationPath}/usergroup`},
  },
  {
    text: "Access control",
    permission: "view-access-control-entries",
    route: {path: `${organisationPath}/accesscontrolentry`},
  },
];

export const allItems = [...userItems, ...adminItems];

export function getCurrentItem(router) {
  const routePath = getRoutePath(router);
  for (const item of allItems) {
    const routes = [item.route, ...(!item.childRoutes ? [] : item.childRoutes)];
    const route = routes.find(_route => _route.path === routePath);
    if (route) {
      return item;
    }
  }
}

export function getCurrentRoute(router) {
  const currentNavItem = getCurrentItem(router);
  if (currentNavItem) {
    const routePath = getRoutePath(router);
    const routes = [
      currentNavItem.route,
      ...(!currentNavItem.childRoutes ? [] : currentNavItem.childRoutes),
    ];
    return routes.find(_route => _route.path === routePath);
  }
}

export function getRoutePath(router) {
  return router.routes
    .filter(item => item.path)
    .map(item => _.trim(item.path, "/"))
    .join("/");
}

export function replaceUrlParam(router, paramName, paramValue, childPath = "") {
  router.replace(getUrl(router, paramName, paramValue, childPath));
}

export function getUrl(router, paramName, paramValue, childPath = "") {
  const paramValues = {
    ...router.params,
    [paramName]: paramValue,
  };
  const routePath = getRoutePath(router);
  const parts = routePath.split(paramName);
  const hasChildParams = /:/.test(parts[1]);
  if (hasChildParams) {
    parts[1] = childPath;
  }
  return setParamsToPath(paramValues, parts.join(paramName));
}

export function setParamsToPath(params, path) {
  return path.replace(/:([^\/?&]+)/g, (match, group) => {
    return params[group];
  });
}

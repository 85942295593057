import ExcelJS from "exceljs";

const columnWidthMap = {
  clause_display: 30,
  clause_number: 8,
  detailed_reason: 30,
  guidance: 30,
  issue_name: 20,
  status: 7,
};

const constructRagReportSpreadsheet = (ragRowData, fields, numberRows) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("RAG Report");
  sheet.columns = [
    // The first column is unlabelled, and contains the clause numbers
    ...(numberRows ? [{header: "", key: "", width: 5}] : []),
    ...fields.map(field => ({
      header: field.label,
      key: field.type,
      // Crude fallback for most fields - expand if needed
      width: columnWidthMap[field.type] ?? 8,
      // Lots of long paragraphs, so better wrap text...
      style: {alignment: {vertical: "top", wrapText: true}},
    })),
  ];
  // Bold header row
  sheet.getRow(1).font = {bold: true, name: "Calibri"};

  ragRowData.forEach(row => {
    const newRow = sheet.addRow();
    row.children.forEach((cellData, i) => {
      const columnIndex = cellData.fieldType
        ? newRow.getCell(cellData.fieldType).col
        : // If the cell is not labelled with a column key, use the index. In
          // most expected scenarios this will be 1, representing either a header
          // row that spans most columns, or the (unlabelled) clause number.
          i + 1;
      const cell = newRow.getCell(columnIndex);

      // 1. Insert the cells content
      const paragraphText = cellData.children
        .filter(child => child.type === "paragraph")
        .map(child =>
          "children" in child
            ? child.children.map(child => child.value).join(", ")
            : child.value,
        )
        .join("\n");
      if (paragraphText !== "Blank") {
        cell.value = paragraphText;
      }

      // 2. If the cell spans multiple columns, merge them
      if (cellData.columnSpan && cellData.columnSpan > 1) {
        sheet.mergeCells(
          newRow.number,
          columnIndex,
          newRow.number,
          columnIndex + cellData.columnSpan - 1,
        );
      }

      // 3. If the cell is a "status" cell, color it according to its "color" property
      if (cellData.fieldType === "status") {
        cell.fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: {
            argb: {
              red: "FF0000",
              green: "00FF00",
              amber: "FFFF00",
              grey: "888888",
            }[cellData.children[0].color],
          },
        };
      }
    });
  });

  return workbook;
};

export default constructRagReportSpreadsheet;

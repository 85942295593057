import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import OrganisationActionTypes from "modules/organisation/constants/action_types";
import ActionTypes from "../constants/action_types";
import DocumentsActionTypes from "modules/documents/constants/action_types";

export default function projectsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.PARTIES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PROJECT_PARTY_ADD.SUCCESS: {
      if (!state.find(party => party.id === action.payload.party_id)) {
        return state.concat([
          {
            id: action.payload.party_id,
            name: action.payload.party_name,
            // TODO: need party last_edited here,
            // only have the project party last edited
          },
        ]);
      }
      return state;
    }
    case DocumentsActionTypes.DOCUMENT_UPDATE.SUCCESS: {
      const {parties} = action.payload;
      if (parties && parties.length) {
        const allIds = state.map(({id}) => id);
        return [
          ...state,
          ...parties.filter(({id}) => id && !allIds.includes(id)),
        ];
      }
      return state;
    }
    case OrganisationActionTypes.ORGANISATION_CHANGE.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

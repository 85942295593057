function makeIssueReasonValue(path, baseValue) {
  return {
    path,
    values: [
      {
        label: "unit",
        path: `${path}.unit`,
        value: baseValue.unit,
      },
      {
        label: "value",
        path: `${path}.value`,
        value: baseValue.value,
      },
      {
        label: "original_value",
        path: `${path}.original_value`,
        value: baseValue.original_value,
      },
    ],
  };
}

export default makeIssueReasonValue;

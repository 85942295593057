import reasonViewerTopic from "utils/issues/reason_viewer_topic";

function getDefaultIssueRuleValues(rules) {
  return [{path: `topics.[${rules.topic_id}]`, value: ""}];
}

export default {
  reasonViewer: reasonViewerTopic,
  getDefaultIssueRuleValues,
};

/* eslint-disable jsdoc/require-param-description */
/* eslint-disable jsdoc/require-param-type */
/* eslint-disable jsdoc/require-returns */
import React, {useState, useMemo, useRef} from "react";
import styled from "styled-components";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import EasyMDE from "easymde";
import useConstRef from "utils/hooks/useConstRef";
import uuid from "utils/uuid";

/**
 * This is regrettable. CodeMirror swallows the native "tab" keydown event,
 * preventing it from bubbling up to the browser and triggering the usual
 * input tabbing behaviour. I couldn't find another workaround for this other
 * than this boilerplate manual solution. In retrospect, I might have picked
 * another library just to avoid clobbering this important default behaviour.
 * @param isShiftPressed used to reverse tab indexing direction as usual
 */
function focusNextElement(isShiftPressed: boolean) {
  const focusableElements = Array.from(
    document.querySelectorAll("input, textarea"),
  )
    .filter(el => !el.hasAttribute("disabled"))
    .filter(el => window.getComputedStyle(el).display !== "none");

  const focusedElement = document.activeElement;
  if (!focusedElement) {
    return;
  }
  const index = focusableElements.indexOf(focusedElement);

  if (index > -1 && index < focusableElements.length - 1) {
    const nextElement = focusableElements[index + (!isShiftPressed ? 1 : -1)];
    (nextElement as HTMLInputElement).focus();
  }
}

// TODO: Downgrade styled components to remove warning about new transient props behaviour (unacceptable)
const Container = styled.div<{disabled: boolean; focused: boolean}>`
  width: 100%;
  height: 100%;
  & > div,
  .CodeMirror-scroll {
    height: 100%;
    min-height: unset !important;
  }
  .EasyMDEContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    pointer-events: ${props => (props.disabled ? "none" : "initial")};
    border: 1px solid #ced4dabf;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 5px 5px 0 0;
  }
  .EasyMDEContainer .CodeMirror {
    flex-grow: 1;
    font-size: 0.9rem;
  }
  .CodeMirror {
    background: none;
    border: none;
  }
  .editor-toolbar {
    opacity: ${props => (props.focused && !props.disabled ? 1 : 0.1)};
    border: none;
    transition: opacity 0.2s;
  }
`;

const Label = styled.label`
  font-family: "Roboto";
  display: block;
  color: rgba(0, 0, 0, 0.54);

  transform: scale(0.75);
  transform-origin: top left;
  transition: transform 0.2s;
`;

interface EditorProps {
  disabled?: boolean;
  onChange?: (newValue: string) => void;
  onBlur?: () => void;
  value: string;
  label: string;
}

const Editor = ({value, disabled, onChange, onBlur, label}: EditorProps) => {
  const [focused, setFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const id = useConstRef(uuid());

  return (
    <Container
      ref={containerRef}
      onFocus={() => setFocused(true)}
      onBlur={event => {
        setFocused(false);
        // Don't allow children to steal focus (e.g. when clicking toolbar buttons)
        if (!containerRef.current?.contains(event.relatedTarget)) {
          onBlur?.();
        }
      }}
      focused={focused}
      disabled={disabled}
    >
      <Label htmlFor={id}>{label}</Label>
      <SimpleMDE
        events={useMemo(
          () => ({
            keydown: (_, event) => {
              if (event.key === "Tab") {
                event.preventDefault();
                focusNextElement(event.shiftKey);
              }
            },
          }),
          [],
        )}
        id={id}
        options={useMemo(
          () => ({
            status: false,
            spellChecker: false,
            toolbar: [
              {
                name: "bold",
                // HACK: Bold and italics are switched in our markup.
                action: EasyMDE.toggleItalic,
                className: "fa fa-bold",
                title: "Bold",
              },
              {
                name: "italic",
                // HACK: Bold and italics are switched in our markup.
                action: EasyMDE.toggleBold,
                className: "fa fa-italic",
                title: "Italic",
              },

              "strikethrough",
              "code",
              "|",
              "unordered-list",
              "ordered-list",
              "|",
              "quote",
              "link",
              "|",
              "undo",
              "redo",
              "|",
              "guide",
            ],
            overlayMode: {
              combine: false,
              mode: {
                startState() {
                  return {
                    inBold: false,
                    inItalic: false,
                  };
                },
                token(stream, state) {
                  // Check for italics (important to check for this BEFORE bold)
                  if (stream.match("**")) {
                    state.inItalic = !state.inItalic;
                    return "em"; // Token class for italic text
                  }

                  // Check for bold
                  if (stream.match("*")) {
                    state.inBold = !state.inBold;
                    return "strong"; // Token class for bold text
                  }

                  // If we're inside a bold or italic state, continue with that style.
                  if (state.inBold) {
                    stream.next();
                    return "strong";
                  } else if (state.inItalic) {
                    stream.next();
                    return "em";
                  }

                  // If none of the above rules apply, continue to the next character.
                  stream.next();
                  return null; // Return null for unstyled tokens./
                },
              },
            },
          }),
          [],
        )}
        value={value}
        onChange={onChange}
      />
    </Container>
  );
};

export default Editor;

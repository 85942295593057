import React from "react";

import * as colors from "material-ui/styles/colors";

const styles = {
  buttonContainer: {
    display: "inline-block",
    height: "2.8rem",
    lineHeight: "2.8rem",
    padding: "0 1rem",
    boxSizing: "border-box",
    fontWeight: "500",
    textAlign: "center",
    color: "white",
    backgroundColor: colors.blue700,
    cursor: "pointer",
    transition: "0.3s",
    fontSize: 14,
  },
  disabled: {
    color: colors.grey500,
    backgroundColor: colors.grey300,
    opacity: 1,
  },
};
class EmailButton extends React.Component {
  state = {isMouseOver: false};

  render() {
    const {disabled} = this.props;
    const opacity = this.getOpacity();
    return (
      <div
        style={{
          ...styles.buttonContainer,
          ...this.props.buttonContainerStyles,
          opacity,
          ...(disabled ? styles.disabled : {}),
        }}
        onMouseEnter={this.onHoverStart}
        onMouseLeave={this.onHoverFinish}
        onClick={!disabled ? this.props.onButtonClick : () => null}
      >
        {this.props.children}
      </div>
    );
  }

  getOpacity = () => {
    const {primary} = this.props;
    const {isMouseOver} = this.state;
    return isMouseOver ? 0.45 : !primary ? 0.6 : 1;
  };

  onHoverStart = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState(() => ({isMouseOver: true}));
  };

  onHoverFinish = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState(() => ({isMouseOver: false}));
  };
}

export default EmailButton;

import React from "react";

import WhatshotIcon from "@material-ui/icons/Whatshot";
import StarRateIcon from "@material-ui/icons/StarRate";
import StarsIcon from "@material-ui/icons/Stars";
import StarsOutlinedIcon from "@material-ui/icons/StarsOutlined";
import StarBorderIcon from "@material-ui/icons/StarBorder";

const starDescriptor = {
  star_issue: {
    icon: StarRateIcon,
    title: "Issue is starred",
  },
  star_issueset: {
    icon: StarsOutlinedIcon,
    title: "Issue is in a starred checklist",
  },
  star_issue_and_issueset: {
    icon: StarsIcon,
    title: "Issue is both starred and in a starred checklist",
  },
  no_star: {
    icon: StarBorderIcon,
    title: "Star current issue",
  },
};
function IssuesetInfoWidget(props) {
  const {
    isHot,
    isInStarIssueset,
    isStarIssue,
    updateIssueIsStar,
    ignoreIssueStar,
  } = props;

  const starType =
    isInStarIssueset && isStarIssue
      ? "star_issue_and_issueset"
      : isInStarIssueset
        ? "star_issueset"
        : isStarIssue ? "star_issue" : ignoreIssueStar ? null : "no_star";
  const StarIcon = starType && starDescriptor[starType].icon;

  return (
    <div style={{marginLeft: 2, display: "flex", alignItems: "center"}}>
      {starType && (
        <div
          title={starDescriptor[starType].title}
          onClick={updateIssueIsStar}
          style={{
            ...(updateIssueIsStar ? {cursor: "pointer"} : {}),
          }}
        >
          <StarIcon
            style={{
              height: 20,
              width: 20,
              color: "#fbc02d",
            }}
          />
        </div>
      )}
      {isHot && (
        <WhatshotIcon
          style={{
            height: 16,
            width: 16,
            color: "#ef5350",
            marginLeft: 2,
          }}
        />
      )}
    </div>
  );
}

export default IssuesetInfoWidget;

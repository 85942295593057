import ActionTypes from "../constants/action_types";
import UNINITIALISED from "utils/uninitialised";
import {CreateUsergroupAction} from "../actions/create";
import {DeleteUsergroupAction} from "../actions/delete";
import {FetchUsergroupsAction} from "../actions/fetch";
import {MaybeUninitialised} from "modules/redux_types";
import {MaybeWithUsers, Usergroup, WithUserCount} from "common/types/usergroup";
import {EditUsergroupAction} from "../actions/edit";
import {FetchUsergroupUsersAction} from "../actions/fetchUsers";
import {AddUserToUsergroupAction} from "../actions/addUser";
import {RemoveUserFromUsergroupAction} from "../actions/removeUser";

type Action =
  | FetchUsergroupsAction
  | CreateUsergroupAction
  | DeleteUsergroupAction
  | EditUsergroupAction
  | FetchUsergroupUsersAction
  | AddUserToUsergroupAction
  | RemoveUserFromUsergroupAction;

export default function usergroupsReducer(
  state: MaybeUninitialised<
    Array<MaybeWithUsers<WithUserCount<Usergroup>>>
  > = UNINITIALISED,
  action: Action,
) {
  switch (action.type) {
    case ActionTypes.USERGROUPS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.USERGROUP_CREATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }

      const usergroup = action.payload;

      // We (probably reasonably) assume that the usergroup we just created
      // has no users in it. If this turns out to be a false assumption, we
      // should provide it in this action, likely returned from the API.
      return state.concat({...usergroup, user_count: 0});
    }

    case ActionTypes.USERGROUP_DELETE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.filter(({id}) => id !== action.payload.id);
    }

    case ActionTypes.USERGROUP_EDIT.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }

      const {id, name} = action.payload;

      return state.map(usergroup =>
        usergroup.id === id ? {...usergroup, name} : usergroup,
      );
    }

    case ActionTypes.USERGROUP_USERS_FETCH.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }

      const {usergroupId, users} = action.payload;

      return state.map(usergroup =>
        usergroup.id === usergroupId ? {...usergroup, users} : usergroup,
      );
    }
    case ActionTypes.USERGROUP_USER_ADD.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }

      const {user, usergroupId} = action.payload;

      return state.map(usergroup =>
        usergroup.id === usergroupId
          ? {
              ...usergroup,
              // NOTE: Feels slightly questionable at this point to even fetch
              // and store a separate count. Currently, we have that more often
              // than the whole set of users, but I'm not sure if there's a
              // really good reason for that.
              user_count: usergroup.user_count + 1,
              users: ("users" in usergroup ? usergroup.users : []).concat(user),
            }
          : usergroup,
      );
    }
    case ActionTypes.USERGROUP_USER_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }

      const {usergroupId, user} = action.payload;

      return state.map(usergroup =>
        usergroup.id === usergroupId && "users" in usergroup
          ? {
              ...usergroup,
              // NOTE: Feels slightly questionable at this point to even fetch
              // and store a separate count. Currently, we have that more often
              // than the whole set of users, but I'm not sure if there's a
              // really good reason for that.
              user_count: usergroup.user_count - 1,
              users: usergroup.users.filter(({id}) => id !== user.id),
            }
          : usergroup,
      );
    }
    default:
      return state;
  }
}

import {DocumentParty, Role} from "common/types/document";

function calculateShowOnlyRelevantParties(
  documentParties: DocumentParty[],
  roles: Role[],
) {
  const allRolesAssigned = roles.every(role =>
    documentParties.some(party => party.role_id === role.id),
  );
  const hasMorePartiesThanRoles = documentParties.length > roles.length;
  const hasRolesDefined = roles.length > 0;

  return allRolesAssigned && hasMorePartiesThanRoles && hasRolesDefined;
}

export default calculateShowOnlyRelevantParties;

import UNINITIALISED from "utils/uninitialised";

import UserActionTypes from "modules/user/constants/action_types";
import OrganisationActionTypes from "modules/organisation/constants/action_types";
import ActionTypes from "../constants/action_types";
/* eslint-disable complexity  */
/* eslint-disable max-statements */
export default function documentsReducer(state = UNINITIALISED, action) {
  if (
    state === UNINITIALISED &&
    action.type !== ActionTypes.FORM_TEMPLATES_FETCH.SUCCESS
  ) {
    return state;
  }

  switch (action.type) {
    case ActionTypes.FORM_TEMPLATES_FETCH.SUCCESS:
      return action.payload;

    case ActionTypes.FORM_TEMPLATE_UPLOAD.SUCCESS: {
      if (!state.find(formTemplate => formTemplate.id === action.payload.id)) {
        return state.concat(action.payload);
      }
      return state;
    }
    case OrganisationActionTypes.ORGANISATION_CHANGE.SUCCESS:
    case ActionTypes.FORM_TEMPLATES_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

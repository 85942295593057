import React from "react";

export default function makeComponentHoverable(InputComponent) {
  return class extends React.Component {
    state = {isHovered: false};
    render() {
      return (
        <InputComponent
          {...this.props}
          isHovered={this.state.isHovered}
          onHoverStart={this.onHoverStart}
          onHoverFinish={this.onHoverFinish}
        />
      );
    }

    switchIsHovered = () => {
      const newValue = !this.state.isHovered;
      this.setState(() => ({isHovered: newValue}));
    };
    onHoverStart = () => this.setState(() => ({isHovered: true}));
    onHoverFinish = () => this.setState(() => ({isHovered: false}));
  };
}

import React from "react";

import * as colors from "@material-ui/core/colors";
import ItemContainer from "../helper_components/item_container";

import Markdown from "common_components/markdown";

const styles = {
  bodyContainer: {
    cursor: "pointer",
    textAlign: "justify",
    color: colors.grey[600],
    fontWeight: 400,
  },
};

function Fallback(props) {
  const {fallback, headerStyles = {}, containerStyles = {}} = props;
  if (!fallback) {
    return null;
  }
  return (
    <ItemContainer
      title={
        props.organisationId === 74
          ? "Post signature: Next Best Actions"
          : "Fallback & Escalation"
      }
      expandable={true}
      onExpandMenu={props.onOpen}
      isMenuExpanded={props.isOpen}
      headerStyles={headerStyles}
      containerStyles={containerStyles}
      bodyStyles={styles.bodyContainer}
      useFlippedInnerBodyStyles={true}
    >
      <div data-testid="fallback_and_escalation">
        <Markdown>{fallback}</Markdown>
      </div>
    </ItemContainer>
  );
}

export default Fallback;

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Snackbar from "material-ui/Snackbar";

class RealtimeAlerts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isOpen: false};
  }
  render() {
    return (
      <div
        style={{
          fontFamily: "Roboto, sans-serif",
        }}
      >
        <Snackbar
          className="document-added"
          message={this.getDocumentAddedMessage()}
          autoHideDuration={5000}
          open={this.state.isOpen}
          onRequestClose={this.closeSnackbar}
        />
      </div>
    );
  }

  getDocumentAddedMessage() {
    if (this.state && this.state.mostRecentDocument) {
      return `${this.state.mostRecentDocument.name} was uploaded`;
    }
    return "";
  }

  componentDidMount() {
    this.updateDocumentState();
  }

  componentDidUpdate() {
    this.updateDocumentState();
  }

  updateDocumentState() {
    const {document} = this.props;

    const refDate = moment(this.props.now)
      .subtract(moment(this.props.now).utcOffset(), "seconds")
      .subtract(10, "seconds");
    if (
      (!this.state || document !== this.state.mostRecentDocument) &&
      document &&
      moment(document.creation_date).isAfter(refDate)
    ) {
      this.setState({isOpen: true, mostRecentDocument: document});
    }
  }

  /* eslint-disable no-invalid-this */
  closeSnackbar = () => {
    this.setState({isOpen: false});
  };
  /* eslint-enable no-invalid-this */
}

RealtimeAlerts.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    creation_date: PropTypes.instanceOf(Date).isRequired,
  }),
};

export default RealtimeAlerts;

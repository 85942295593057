import _ from "underscore";

import tinycolor from "tinycolor2";

function hashString(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}

function lightenColour(colour) {
  let lightColour = tinycolor(colour);
  const targetLuminance = 0.9;
  while (lightColour.getLuminance() < targetLuminance) {
    lightColour = lightColour.lighten(5);
  }
  while (lightColour.getLuminance() > targetLuminance) {
    lightColour = lightColour.darken(1);
  }
  return lightColour.toString();
}

const getRandomColor = _.memoize(seed => {
  const hash = hashString(seed);
  const letters = "0123456789ABCDEF";
  let colour = "#";
  for (let index = 0; index < 6; index += 1) {
    const hashIndex = (hash >> (index * 4)) & 15;
    colour += letters[hashIndex];
  }
  const lightColour = lightenColour(colour);
  return {colour, lightColour};
});

export default getRandomColor;

import _ from "lodash";

function setNestedObjects(root, path) {
  const propNames = path.split(".");
  let current = root;

  while (propNames.length) {
    const propName = propNames.shift();
    if (!_.isObject(current)) {
      break;
    }
    if (!_.has(current, propName)) {
      current[propName] = {};
    }
    current = current[propName];
  }
}

export default setNestedObjects;

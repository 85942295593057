const calculateHoveredClauseparts = (clause, hoveredItem) => {
  const result = [];
  const references = hoveredItem.reference.split(".");
  const referenceValues = parseReferenceValues(references);
  if (references.length === 1) {
    if (clause.nodes.clauseNodes) {
      findAllIds(clause.nodes.clauseNodes, result);
    } else {
      result.push(clause.nodes.id);
    }
  } else {
    findAllIdsWithReferences(clause.nodes.clauseNodes, result, referenceValues);
  }
  return result;
};

const findAllIdsWithReferences = (nodes, result, ref) => {
  let references = ref;
  nodes.forEach(item => {
    if (item.id && !item.is_conjunction) {
      if (Array.isArray(references)) {
        if (references.includes(item.reference)) {
          references.shift();
          references = !references.length ? item.reference : references;
          result.push(item.id);
        }
      } else {
        if (
          item.reference &&
          (item.reference.startsWith(`${references}.`) ||
            item.reference === references)
        ) {
          result.push(item.id);
        }
      }
    }
    if (item.clauseNodes) {
      findAllIdsWithReferences(item.clauseNodes, result, references);
    }
  });
};

const parseReferenceValues = arr => {
  const result = [];
  const totalSum = arr.reduce(function(sum, current, index) {
    result.push(sum);
    if (index < 1) {
      return sum + current;
    } else {
      return `${sum}.${current}`;
    }
  });
  result.push(totalSum);
  return result;
};

const findAllIds = (node, result) => {
  node.forEach(item => {
    if (item.id && !item.is_conjunction) {
      result.push(item.id);
    }
    if (item.clauseNodes) {
      findAllIds(item.clauseNodes, result);
    }
  });
};

export default calculateHoveredClauseparts;

import _ from "underscore";
import {get} from "lodash";
import React from "react";
import ReactDom from "react-dom";

import RaisedButton from "material-ui/RaisedButton";
import ContentLink from "material-ui/svg-icons/content/link";
import Permissioner from "utils/permissioner";

import grey from "@material-ui/core/colors/grey";

import {getEditorCaretOffsetByXY} from "utils/coordinated_chars";
import constantStyles from "constants/text_changes_styles";
import getTextStyle from "../utils/get_text_style";
import formatText from "utils/text/format_text";
import doesClauseHaveIssue from "utils/clauses/does_clause_have_issue";
import getNodeAtoms from "../utils/get_node_atoms";
import AtomClauseEditor from "./atom_clause_editor";
import ClauseSubHeading from "../../clause_subheading";
import {isIe11} from "utils/browser_test";
import * as PossibleUpcomingEventCatcher from "utils/possible_upcoming_event_catcher";
import ConfirmDialog from "common_components/confirm_dialog";
import Contents from "common_components/gui_text_editor/contents";
import makeComponentHoverable from "common_components/hocs/make_component_hoverable";
import VerticalClausepartControl from "../../common_components/vertical_clausepart_control";
import PendingSave from "./pending_save";
import CommentButton from "../utils/comment_popover";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import cleanReference from "utils/clauses/clean_reference";
import DocumentDetailContext from "common_components/context/document_detail_context";
import IssueListButtons from "./issue_list_buttons";
import {getNextNode} from "common/utils/node_path";
import getIssueValue from "utils/issues/get_issue_value";

const linkToSearchButtonWidth = 202;

const styles = {
  clauseAtom: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "top",
    textAlign: "justify",
    whiteSpace: "pre-wrap",
    marginBottom: ".3em",
    transition: "background 0.6s",
    ...(isIe11() ? {width: "100%"} : {}),
  },
  reference: {
    display: "inline-block",
    width: "4em",
    textAlign: "center",
    marginRight: ".5em",
    fontWeight: 400,
    textIndent: "0",
    flexShrink: 0,
    flexGrow: 0,
    alignSelf: "flex-start",
  },
  decimalReference: {
    display: "inline-block",
    width: "2em",
    marginRight: ".5em",
    textIndent: "0",
    flexShrink: 0,
    flexGrow: 0,
  },
  withReference: {
    display: "flex",
  },
  indent: {
    marginLeft: "4.5em",
    ...(isIe11() ? {width: "calc(100% - 4.5em)"} : {}),
  },
  linkToSearch: {
    position: "absolute",
    height: 30,
    whiteSpace: "nowrap",
    zIndex: 10000,
    width: linkToSearchButtonWidth,
  },
  linkToSearchOverlay: {
    lineHeight: "26px",
  },
  linkToSearchLabel: {
    fontSize: 11,
    paddingRight: 7,
    paddingLeft: 12,
    color: "#fff",
  },
  linkToSearchIcon: {
    color: "#fff",
    marginRight: 9,
    marginTop: 1,
  },
  clausepartBottomMenu: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    overflow: "hidden",
    fontSize: ".9em",
    color: grey[700],
  },
  atomWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  addButton: {
    transition: "width .3s",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

class Atom extends React.Component {
  static contextType = DocumentDetailContext;
  state = {
    isShownDeleteClausepartDialog: false,
    linkToSearch: {
      isShown: false,
      text: null,
      left: null,
      top: null,
    },
  };

  componentDidMount() {
    if (this.hasPermission("search")) {
      window.addEventListener("mouseup", this.handleWindowMouseUp, true);
      window.addEventListener("mousedown", this.handleWindowMouseDown, true);
    }
  }

  componentWillUnmount() {
    if (this.hasPermission("search")) {
      window.removeEventListener("mouseup", this.handleWindowMouseUp, true);
      window.removeEventListener("mousedown", this.handleWindowMouseDown, true);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.hasPermission("search") &&
      prevProps.editModeOn !== this.props.editModeOn &&
      this.props.editModeOn &&
      this.state.linkToSearch.isShown
    ) {
      this.hideLinkToSearch();
    }
  }

  hasPermission = name => {
    return Boolean(new Permissioner(this.props.user).hasPermission(name));
  };

  handleWindowMouseUp = () => {
    setTimeout(() => {
      if (!this.props.editModeOn) {
        this.showLinkToSearch();
      }
    }, 0);
  };

  handleWindowMouseDown = event => {
    if (this.state.linkToSearch.isShown) {
      if (this.linkToSearchRef) {
        const button = ReactDom.findDOMNode(this.linkToSearchRef);
        const ancestors = this.getAncestorList(event.target);
        if (button && ancestors.includes(button)) {
          return;
        }
      }
      this.hideLinkToSearch();
    }
  };

  getAncestorList = node => {
    const list = [];
    let current = node;
    while (current) {
      list.push(current);
      current = current.parentNode;
    }
    return list;
  };

  showLinkToSearch = () => {
    const selection = window.getSelection();
    if (selection.rangeCount) {
      const range = selection.getRangeAt(0);
      if (range.collapsed === false) {
        const ancestors = this.getAncestorList(
          range.commonAncestorContainer,
        ).filter(node => node.nodeType === 1);
        const container = this.clauseContents;
        if (ancestors.includes(container)) {
          const clone = range.cloneRange();
          clone.setStart(clone.endContainer, clone.endOffset);
          const containerRect = container.getBoundingClientRect();
          const rangeRect = clone.getBoundingClientRect();
          const maxLeft = Math.round(
            container.offsetWidth - linkToSearchButtonWidth,
          );
          let left = Math.round(rangeRect.right - containerRect.left);
          if (left > maxLeft) {
            left = maxLeft;
          }
          const top = Math.round(rangeRect.bottom - containerRect.top);
          this.setState({
            linkToSearch: {
              isShown: true,
              text: range.toString(),
              left,
              top,
            },
          });
        }
      }
    }
  };

  hideLinkToSearch = () => {
    this.setState(() => ({
      linkToSearch: {
        isShown: false,
        text: null,
        left: null,
        top: null,
      },
    }));
  };

  render() {
    const {props, state} = this;
    const {
      clause,
      parent,
      firstNode,
      lastNode,
      indentFooter,
      changes,
      isClauseDeletion,
      isDefinitionDeletion,
      clauseAdditionChange,
      editableClausepart,
      documentClause,
      findAndReplace,
      editModeOn,
      hideTextDeletions,
      issues,
      highlightFixes,
    } = props;
    const isClausepartDeletion = Boolean(
      changes.find(
        change =>
          change.type === "clausepart_deletion" &&
          change.new_clausepart_id === clause.id,
      ),
    );
    if (isClausepartDeletion && hideTextDeletions) {
      return null;
    }

    const referenceText = this.getReference(clause, parent);
    const willRenderReference = this.getWillRenderReference();
    const clausepartAdditionChange = changes.find(
      change =>
        change.new_clausepart_id === clause.id &&
        change.type === "clausepart_addition",
    );
    const definitionAdditionChange = changes.find(
      change =>
        change.type === "definition_addition" &&
        change.new_meaning_clausepart_id === clause.id,
    );
    const clausepartTextChanges = getClausepartTextChanges(changes);

    const isDeletion =
      isClauseDeletion || isClausepartDeletion || isDefinitionDeletion;

    const isEditable =
      editableClausepart &&
      clause.id === editableClausepart.id &&
      !(isClauseDeletion || isDefinitionDeletion);

    const showAddButtons =
      willRenderReference || this.shouldAtomButtonsBeShown();

    const isSavePending = checkIsSavePending(
      changes,
      clauseAdditionChange,
      documentClause.id,
      clause.id,
    );

    const comments = props.document.comments
      ? props.document.comments.filter(
          ({clausepart_id}) => clausepart_id === clause.id,
        )
      : [];
    const hasComments = comments.length > 0;

    const isHovered = props.lastHoveredClausepartId === clause.id;
    let nextNode = getNextNode(props.nodes, props.clause.path);
    while (nextNode && nextNode.is_conjunction) {
      nextNode = getNextNode(props.nodes, nextNode.path);
    }
    const nextClausepartIssues = nextNode
      ? issues.filter(issue => doesClauseHaveIssue(issue, nextNode))
      : [];

    const clausepartIssues = issues
      .filter(issue => doesClauseHaveIssue(issue, clause))
      .map(issue => ({
        ...issue,
        haveLinkBelow: !nextClausepartIssues.find(({id}) => id === issue.id),
      }));

    const hasIssues = clausepartIssues.length > 0;
    const textStyle = getTextStyle(clause, props, clausepartIssues);

    const result = (
      <>
        <div
          className="graph-item clause-atom clause-id"
          id={`clause-atom-${clause.id}`}
          style={{
            ...styles.clauseAtom,
            ...(willRenderReference ? styles.withReference : {}),
            ...(!willRenderReference && indentFooter ? styles.indent : {}),
            ...(isHovered ? {backgroundColor: "#F4F4F4"} : {}),
            ...(highlightFixes && (clause.parseError || clause.altered)
              ? {backgroundColor: "#F00"}
              : {}),
          }}
          clauseid={clause.id}
          onMouseOver={this.clauseMouseOverHandler(clause.id)}
        >
          {!isEditable &&
            this.renderAtomActionButtons(
              clausepartAdditionChange,
              clausepartTextChanges,
              definitionAdditionChange,
              showAddButtons,
              isClauseDeletion,
              clauseAdditionChange,
              willRenderReference,
              isSavePending,
              isClausepartDeletion,
            )}
          {willRenderReference && (
            <span
              style={{
                ...(clause.counterType === "DECIMAL"
                  ? styles.decimalReference
                  : styles.reference),
                ...(clausepartAdditionChange
                  ? constantStyles.textChanges.text_addition
                  : {}),
                ...(isDeletion ? constantStyles.textChanges.text_deletion : {}),
                ...(props.addClausesToReportDocumentIssueId
                  ? {cursor: "pointer"}
                  : {}),
              }}
              onMouseEnter={this.onAddClauseToHighlight}
              onMouseLeave={props.onNumberItemHoverFinish}
              onClick={() =>
                props.onAddClauseToReport(props.isNumberItemsHovered)
              }
            >
              {referenceText}
            </span>
          )}
          <div
            style={{
              ...styles.atomWrapper,
              ...(!isEditable && clausepartAdditionChange
                ? constantStyles.textChanges.text_addition
                : {}),
            }}
            onDoubleClick={
              !isEditable && (editModeOn || isDeletion || isSavePending)
                ? undefined
                : this.onTextEditDisabled
            }
            onClick={
              !isEditable &&
              editModeOn &&
              !this.props.newClausepartData &&
              !editableClausepart &&
              !isDeletion &&
              !isSavePending
                ? event => {
                    this.preventUnwantedTextSelection();
                    this.onTextEdit(event);
                  }
                : undefined
            }
          >
            {isEditable ? (
              <>
                {this.renderSubheading()}
                <AtomClauseEditor
                  itemType={this.getEditorItemType(
                    willRenderReference,
                    props.isDefinition,
                  )}
                  isFirstClauseItem={firstNode}
                  isLastClauseItem={lastNode}
                  clause={clause}
                  changes={changes}
                  clausepartTextChanges={clausepartTextChanges}
                  clauseId={documentClause.id}
                  listItemPath={props.listItemPath}
                  alterDocumentClausepartBinded={
                    props.alterDocumentClausepartBinded
                  }
                  isClauseDeletion={isClauseDeletion}
                  clauseAdditionChange={clauseAdditionChange}
                  clausepartAdditionChange={clausepartAdditionChange}
                  definitionAdditionChange={definitionAdditionChange}
                  isClausepartDeletion={isClausepartDeletion}
                  showAddClauseEditor={props.showAddClauseEditor}
                  onAddClausepartBeforeCurrent={
                    showAddButtons
                      ? this.onAddClausepartBeforeCurrent
                      : undefined
                  }
                  onAddClausepartAfterCurrent={
                    showAddButtons
                      ? this.onAddClausepartAfterCurrent
                      : undefined
                  }
                  onDeleteClausepart={this.showDeleteClausepartDialog(true)}
                  revertClausepartAddition={
                    clausepartAdditionChange
                      ? this.revertClausepartAddition
                      : undefined
                  }
                  onAddDefinitionBeforeCurrent={
                    this.props.onAddDefinitionBeforeCurrent
                  }
                  onAddDefinitionAfterCurrent={
                    this.props.onAddDefinitionAfterCurrent
                  }
                  hideClausepartEditor={props.hideClausepartEditor}
                  updateAddedClause={this.updateAddedClause}
                  updateAddedClausepart={this.updateAddedClausepart}
                  onAddClauseBeforeCurrent={this.onAddClauseBeforeCurrent}
                  onAddClauseAfterCurrent={this.onAddClauseAfterCurrent}
                  onRevertClause={this.onRevertClause}
                  onDeleteClause={this.onDeleteClause}
                  isDefinition={props.isDefinition}
                  isFirstNodeAtomOrList={props.isFirstNodeAtomOrList}
                  caretOffset={editableClausepart.caretOffset}
                  revertDocumentClausepartChanges={
                    this.revertDocumentClausepartChanges
                  }
                  usedListFormatStylesPerLevel={
                    this.props.usedListFormatStylesPerLevel
                  }
                  isFirstParentListNumbered={
                    this.props.isFirstParentListNumbered
                  }
                  isInNumberedList={this.props.isInNumberedList}
                  reference={this.props.reference}
                />
              </>
            ) : (
              <>
                {this.renderSubheading()}
                <div
                  ref={element => (this.clauseContents = element)}
                  style={{position: "relative", ...textStyle}}
                  onMouseEnter={props.onHoverStart}
                  onMouseLeave={props.onHoverFinish}
                  onClick={
                    props.addClausesToReportDocumentIssueId && !editModeOn
                      ? this.onAddClauseToReport
                      : undefined
                  }
                >
                  {clauseAdditionChange && clauseAdditionChange.contents ? (
                    <Contents
                      decimalPrefix={this.getDecimalPrefix()}
                      value={clauseAdditionChange.contents}
                    />
                  ) : clausepartAdditionChange &&
                  clausepartAdditionChange.contents ? (
                    <Contents
                      decimalPrefix={this.getDecimalPrefix()}
                      value={clausepartAdditionChange.contents}
                    />
                  ) : (
                    formatText(
                      this.props.document.file_extension,
                      clause.partial_text,
                      props,
                      findAndReplace.find,
                      hideTextDeletions,
                      clause.formattedText,
                      get(props.document, "template_values.values"),
                      clausepartTextChanges,
                    )
                  )}
                  {this.renderLinkToSearchButton()}
                </div>
              </>
            )}
            {isSavePending && (
              <PendingSave rootDivStyle={{margin: "0.5rem 14rem"}} />
            )}
          </div>
          {this.props.showClauseButtons &&
            !hasComments &&
            this.renderCommentsButton(comments, isHovered, false)}
          <ConfirmDialog
            open={state.isShownDeleteClausepartDialog}
            onSuccess={this.onSuccessDeleteClausepartDialog(false)}
            onClose={this.showDeleteClausepartDialog(false)}
            title="Delete Clausepart"
            description="Are you sure you want to delete clausepart?"
            okButtonCaption="Delete"
          />
        </div>
        {this.props.showClauseButtons &&
          (hasIssues || hasComments) && (
            <div
              style={{
                ...styles.clausepartBottomMenu,
                paddingBottom: clause.partial_text.trim() ? "0.5em" : "1.5em",
              }}
            >
              {hasIssues && (
                <IssueListButtons
                  issues={clausepartIssues.filter(
                    issue =>
                      issue.haveLinkBelow &&
                      getIssueValue(
                        issue,
                        this.props.currentIssuesetItem,
                        "show_in_clause_buttons",
                      ),
                  )}
                  clausepart={clause}
                  showIssueDetail={this.props.showIssueDetail}
                  areIssuesCollapsed={this.props.areIssuesCollapsed}
                  user={this.props.user}
                  showIssuesInChecklist={this.props.showIssuesInChecklist}
                  project={this.props.project}
                  selectedReportId={this.props.selectedReportId}
                  currentIssuesetItem={this.props.currentIssuesetItem}
                  documentClauses={this.props.documentClauses}
                  topicsById={this.props.topicsById}
                  positiveReasonData={this.props.positiveReasonData}
                  documentClauseparts={this.props.documentClauseparts}
                />
              )}
              {hasComments &&
                this.renderCommentsButton(comments, isHovered, true)}
            </div>
          )}
      </>
    );

    return props.isTableRow ? (
      <td
        style={{border: "1px solid #e0e0e0", padding: "4px"}}
        colSpan={clause.colSpan || 1}
      >
        {result}
      </td>
    ) : (
      result
    );
  }

  onAddClauseToReport = () => {
    const {onAddClauseToReport} = this.props;
    if (onAddClauseToReport) {
      onAddClauseToReport(this.props.node.id);
    }
  };

  onAddClauseToHighlight = () => {
    return this.props.onNumberItemHoverStart({
      id: this.props.node.id,
      reference: this.props.node.reference,
    });
  };

  renderLinkToSearchButton = () => {
    const {isShown, text, left, top} = this.state.linkToSearch;
    if (!isShown) {
      return null;
    }
    const {organisationId} = this.props.params;
    const href = `/organisation/${organisationId}/search?query=${text}&search_type=clause`;
    return (
      <RaisedButton
        ref={this.createLinkToSearchRef}
        label="search for selected text"
        labelPosition="before"
        target="_blank"
        href={href}
        backgroundColor="#333"
        style={{
          ...styles.linkToSearch,
          left,
          top,
        }}
        overlayStyle={styles.linkToSearchOverlay}
        labelStyle={styles.linkToSearchLabel}
        icon={<ContentLink style={styles.linkToSearchIcon} />}
      />
    );
  };

  // If the mousedown event happens after first click then it
  // means the doubleclick event will happen on the Text Editor nodes
  // and a random text will be selected in the textarea
  // and the set caret offset will be removed
  preventUnwantedTextSelection = () => {
    PossibleUpcomingEventCatcher.preventDefault("mousedown");
  };

  renderAtomActionButtons = (
    isClausepartAddition,
    clausepartTextChanges = [],
    definitionAdditionChange,
    showAddButtons,
    isClauseDeletion,
    clauseAdditionChange,
    willRenderReference,
    isSavePending,
    isClausepartDeletion,
  ) => {
    if (
      !this.shouldRenderActionButtons(
        definitionAdditionChange,
        willRenderReference,
        isSavePending,
      )
    ) {
      return null;
    }
    // if willRenderReference is true we are dealing with the document looking
    // like list where each clause looks like list item. On these clauses we
    // render buttons related to clause actions
    const hasClausepartTextChanges = clausepartTextChanges.length > 0;
    const isNodeDeletion = this.isNodeDeletion();
    const shouldRevert =
      isClausepartAddition ||
      isNodeDeletion ||
      hasClausepartTextChanges ||
      (willRenderReference && (isClauseDeletion || clauseAdditionChange)) ||
      isClausepartDeletion;
    return (
      <VerticalClausepartControl
        itemName={willRenderReference ? "clause" : "subclause"}
        shouldRevert={shouldRevert}
        showAddButtons={showAddButtons}
        onTopButtonClick={this.getActionButtonHandler(
          isClausepartAddition,
          isNodeDeletion,
          hasClausepartTextChanges,
          isClauseDeletion,
          clauseAdditionChange,
          willRenderReference,
          isClausepartDeletion,
        )}
        onTopButtonHover={this.onActionButtonHover}
        onTopButtonHoverFinish={this.onActionButtonHoverEnd}
        onAddAboveClick={
          willRenderReference
            ? this.onAddClauseBeforeCurrent
            : this.onAddClausepartBeforeCurrent
        }
        onAddBelowClick={
          willRenderReference
            ? this.onAddClauseAfterCurrent
            : this.onAddClausepartAfterCurrent
        }
        innerContainerStyles={{
          left: this.props.hasNumber
            ? getZoomedFontSize(-70, "document", this.context.zoom, 1.08)
            : "-30px",
          top: showAddButtons ? "-12px" : "2px",
        }}
      />
    );
  };

  renderCommentsButton(comments, isHovered, shouldShowLabel) {
    const {props} = this;
    return (
      <CommentButton
        isHovered={isHovered}
        comments={comments}
        user={props.user}
        shouldShowLabel={shouldShowLabel}
        onSave={this.addClausepartComment}
        onClose={this.clauseMouseOverHandler(null)}
        deleteClausepartComment={props.deleteClausepartComment}
        updateClausepartComment={props.updateClausepartComment}
        hasDeleteClausepartCommentPermission={
          props.hasDeleteClausepartCommentPermission
        }
        hasEditClausepartCommentPermission={
          props.hasEditClausepartCommentPermission
        }
      />
    );
  }

  getActionButtonHandler = (
    isClausepartAddition,
    isNodeDeletion,
    hasClausepartTextChanges,
    isClauseDeletion,
    clauseAdditionChange,
    willRenderReference,
    isClausepartDeletion,
  ) => {
    if (hasClausepartTextChanges) {
      return this.revertDocumentClausepartChanges;
    } else if (isClausepartDeletion) {
      return this.revertClausepartDeletion;
    } else if (
      willRenderReference &&
      (isClauseDeletion || clauseAdditionChange)
    ) {
      return this.onRevertClause;
    } else if (willRenderReference) {
      return this.onDeleteClause;
    } else if (isClausepartAddition) {
      return this.revertClausepartAddition;
    } else if (isNodeDeletion) {
      return this.revertClausepartDeletion;
    }
    return this.onSuccessDeleteClausepartDialog(true);
  };

  onTextEditDisabled = () => {};

  onTextEdit = e => {
    const {changes, clause, document} = this.props;
    if (!(document.file_extension === "pdf" || document.plain_text_length > 100000)) {
      e.stopPropagation();
      e.preventDefault();
      const caretOffset = getEditorCaretOffsetByXY(
        this.clauseContents,
        e.pageX,
        e.pageY,
      );
      const childrenData = this.getNodeData([
        "id",
        "path",
        "partial_text",
        "reference",
        "order",
      ]);
      const isClausepartDeletion = Boolean(
        changes.find(
          change =>
            change.type === "clausepart_deletion" &&
            change.new_clausepart_id === clause.id,
        ),
      );
      const isClausepartAddition = Boolean(
        changes.find(
          change =>
            change.new_clausepart_id === clause.id &&
            change.type === "clausepart_addition",
        ),
      );
      const hasClausepartTextChanges =
        getClausepartTextChanges(changes).length > 0;
      const clauseData = {
        ...this.props.clause,
        currentPath: this.getCurrentPath(),
        previousPath: this.getPreviousPath(),
        isClauseAddition: Boolean(this.props.clauseAdditionChange),
        isClauseDeletion: this.props.isClauseDeletion,
        childrenData,
        childrenPaths: this.getNodeData("path"),
        isFirstNodeAtomOrList: this.props.isFirstNodeAtomOrList,
        isClausepartAddition,
        isClausepartDeletion,
        hasClausepartTextChanges,
        willRenderReference: this.getWillRenderReference(),
        shouldClausepartButtonsBeShown: this.shouldAtomButtonsBeShown(),
      };
      this.props.setEditableClausepart(
        this.props.documentClause.id,
        clauseData,
        caretOffset,
      );
    }
  };

  shouldAtomButtonsBeShown = () =>
    this.props.isInNumberedList &&
    !(!this.props.isFirstParentListNumbered && this.props.index !== 0);

  shouldRenderActionButtons = (
    definitionAdditionChange,
    willRenderReference,
    isSavePending,
  ) => {
    const {
      clause,
      lastHoveredClausepartId,
      editModeOn,
      clauseAdditionChange,
      editableClausepart,
    } = this.props;
    if (
      lastHoveredClausepartId !== clause.id ||
      (!clause.partial_text &&
        !(clause.childrenClausesNode || clause.childrenClausesOfListItem)) ||
      !editModeOn ||
      (clauseAdditionChange && !willRenderReference) ||
      definitionAdditionChange ||
      isSavePending ||
      (editableClausepart && editableClausepart.id !== clause.id)
    ) {
      return false;
    }
    return true;
  };

  getWillRenderReference = () => {
    const {
      firstNode,
      showReference,
      showReferenceForNodeAtoms,
      clause,
      parent,
      documentHasHeadings,
      section,
      hasNumber,
    } = this.props;
    const referenceText = this.getReference(clause, parent);
    return (
      ((firstNode && showReference && !documentHasHeadings && !hasNumber) ||
        showReferenceForNodeAtoms ||
        (firstNode && clause.type && clause.type.startsWith("Numbered")) ||
        clause.type === "NumberedClauseAtom") &&
      (!section.is_unnumbered || clause.level > 1) &&
      referenceText &&
      referenceText.length > 0 &&
      referenceText !== "text"
    );
  };

  getReference(clause, parent) {
    if (parent && parent.reference) {
      if (clause && clause.reference) {
        if (clause.reference.startsWith(parent.reference)) {
          if (clause.reference !== parent.reference) {
            if (clause.counterType === "DECIMAL") {
              return clause.reference;
            }
            return clause.reference.substring(parent.reference.length + 1);
          }
        }
      }
    }
    return clause.reference || this.props.reference;
  }

  getEditorItemType(willRenderReference, isDefinition) {
    if (willRenderReference) {
      return "clause";
    } else if (isDefinition) {
      return "definition";
    }
    // otherwise undefined which is default for "subclause"
  }

  showDeleteClausepartDialog = _.memoize(isShown => () => {
    this.setState(() => ({isShownDeleteClausepartDialog: isShown}));
  });

  onSuccessDeleteClausepartDialog = shouldDeleteChildren => () => {
    // shouldDeleteChildren:
    // 1) when deleting from delete dialog we delete only subclause from the editor
    // 2) when deleting using vertical hover menu - delete subclause with children
    this.props.setHoveredClausepartChildrenIds([]);
    // we use ["id", "path", "partial_text", "reference"] to construct clausepart_deletion change
    // on server request
    const getOnlyParentData = !shouldDeleteChildren;
    const clausepartsToDelete = this.getNodeData(
      ["id", "path", "partial_text", "reference", "order"],
      getOnlyParentData,
    );
    this.setState(
      () => ({isShownDeleteClausepartDialog: false}),
      () =>
        this.props.deleteClauseparts(
          this.props.documentClause,
          this.getNodeData("path", getOnlyParentData),
          clausepartsToDelete,
          this.getSubheading(),
        ),
    );
  };

  revertDocumentClausepartChanges = () => {
    const {
      clause,
      documentClause,
      revertDocumentClausepartChanges,
    } = this.props;
    return revertDocumentClausepartChanges(
      documentClause.section_id,
      documentClause.id,
      clause,
    );
  };

  revertClausepartDeletion = () => {
    const clause = this.props.documentClause;
    return this.props.revertClausepartDeletions(
      clause.section_id,
      clause.id,
      this.getNodeData("id"),
      this.getSubheading(),
    );
  };

  onActionButtonHover = () => {
    this.props.setHoveredClausepartChildrenIds(this.getNodeData("id"));
  };

  onActionButtonHoverEnd = () => {
    this.props.setHoveredClausepartChildrenIds([]);
  };

  revertClausepartAddition = () => {
    const {clause, documentClause, revertClausepartAddition} = this.props;
    return revertClausepartAddition(
      documentClause.section_id,
      documentClause.id,
      clause,
    );
  };

  updateAddedClause = async contents => {
    const clause = this.props.documentClause;
    await this.props.updateClause(clause.section_id, clause.id, contents);
  };

  updateAddedClausepart = async contents => {
    const {props} = this;
    if (
      props.clause &&
      contents &&
      contents.ops &&
      contents.ops[0] &&
      contents.ops[0].insert === props.clause.partial_text
    ) {
      return;
    }
    const {documentClause} = this.props;
    await props.updateAddedClausepart(
      documentClause.section_id,
      documentClause.id,
      props.clause,
      contents,
    );
    await props.hideClausepartEditor();
  };

  getNodeData = (identifierNames, getOnlyParent) => {
    const {clause} = this.props;
    const {childrenClausesNode, childrenClausesOfListItem} = this.props.node;
    const childrenClausesContainer = getOnlyParent
      ? null
      : childrenClausesOfListItem
        ? childrenClausesOfListItem
        : childrenClausesNode;
    if (Array.isArray(identifierNames) && identifierNames.length > 0) {
      return childrenClausesContainer
        ? [...getNodeAtoms(childrenClausesContainer), clause].map(atom =>
            _.pick(atom, identifierNames),
          )
        : [_.pick(clause, identifierNames)];
    }

    return childrenClausesContainer
      ? [
          ...getNodeAtoms(childrenClausesContainer).map(
            child => child[identifierNames],
          ),
          clause[identifierNames],
        ]
      : [clause[identifierNames]];
  };

  onAddClausepartAfterCurrent = () => {
    const {props} = this;
    const currentPath = this.getCurrentPath();
    if (currentPath) {
      props.showAddClausepartEditor(props.clauseId, currentPath);
    }
  };

  onAddClausepartBeforeCurrent = () => {
    const {props} = this;
    const prevPath = this.getPreviousPath();
    if (prevPath) {
      props.showAddClausepartEditor(props.clauseId, prevPath);
    }
  };

  onAddClauseAfterCurrent = () => {
    const clause = this.props.documentClause;
    this.props.showAddClauseEditor(clause.section_id, clause.id, 1)();
  };

  onAddClauseBeforeCurrent = () => {
    const clause = this.props.documentClause;
    this.props.showAddClauseEditor(clause.section_id, clause.id, -1)();
  };

  onRevertClause = () => {
    const clause = this.props.documentClause;
    this.props.revertClause(clause.section_id, clause.id)();
  };

  onDeleteClause = () => {
    const clause = this.props.documentClause;
    this.props.deleteClause(clause.section_id, clause.id)();
  };

  getCurrentPath = () => {
    const {parent: parentNode} = this.props;
    if (
      !this.props.isFirstParentListNumbered &&
      parentNode &&
      parentNode.type &&
      parentNode.type.toLowerCase().indexOf("list") !== -1
    ) {
      return parentNode.path;
    }
    // if no listItemPath then we've got a direct atom being a clauseNode item of ClausePartNode array
    return this.props.listItemPath
      ? this.props.listItemPath
      : this.props.clause.path;
  };

  getPreviousPath = () => {
    const currentPath = this.getCurrentPath();
    if (!currentPath) {
      return null;
    }
    return currentPath.replace(/\[\d+\]$/, match => {
      return `[${parseInt(match.replace(/[\[\]]/g, ""), 10) - 1}]`;
    });
  };

  isNodeDeletion = () => {
    const {deletedClausepartPaths} = this.props;
    const clausepartChildrenPaths = this.getNodeData("path");
    const notRemovedPaths = clausepartChildrenPaths.filter(
      clausePath =>
        !deletedClausepartPaths.find(deletedPath => clausePath === deletedPath),
    );
    return notRemovedPaths.length === 0;
  };

  getSubheading = () => {
    if (
      !this.props.isTableRow &&
      this.props.clause.clausePartNumber === 1 &&
      this.props.clause.level > (this.props.is_top_level_subclause ? 2 : 1)
    ) {
      return this.props.documentHeadings.find(
        heading =>
          this.props.section.id === heading.section_id &&
          cleanReference(this.props.clause.reference).toLowerCase() ===
            heading.reference.toLowerCase(),
      );
    }
  };

  renderSubheading = () => {
    const subheading = this.getSubheading();
    if (!subheading) {
      return null;
    }
    const reference = this.props.clause.reference;
    const isEditable = Boolean(
      this.props.editableClauseHeading &&
        this.props.editableClauseHeading.reference === reference,
    );
    return (
      <ClauseSubHeading
        document={this.props.document}
        changes={this.getSubheadingChanges(subheading)}
        documentDefinitions={this.props.documentDefinitions}
        doesHaveEditableClause={this.props.doesHaveEditableClause}
        editModeOn={this.props.editModeOn}
        findAndReplace={this.props.findAndReplace}
        hideClauseEditor={this.props.hideClauseEditor}
        isClauseDeletion={this.props.isClauseDeletion}
        isDeleted={this.isSubheadingDeleted(subheading)}
        isEditable={isEditable}
        onAlter={this.props.alterDocumentHeading}
        onDelete={this.props.deleteDocumentHeading}
        onRevert={this.props.revertDocumentHeadingChanges}
        setEditableClauseHeading={this.props.setEditableClauseHeading}
        subheading={subheading}
        unsetEditableClauseHeading={this.props.unsetEditableClauseHeading}
      />
    );
  };

  getSubheadingChanges = subheading => {
    return this.props.documentChanges.filter(
      change =>
        (change.type !== "clauseheading_addition" &&
          (change.type.startsWith("clauseheading") &&
            change.new_heading_id === subheading.id)) ||
        (change.type === "clause_deletion" &&
          change.new_clause_id === this.props.clauseId),
    );
  };

  isSubheadingDeleted = subheading => {
    return Boolean(
      this.props.documentChanges.find(
        change =>
          change.type === "clauseheading_deletion" &&
          change.new_heading_id === subheading.id,
      ),
    );
  };

  getDecimalPrefix = () => {
    const {reference} = this.props;
    return /^[\d.]+$/.test(reference) ? reference : undefined;
  };

  createLinkToSearchRef = node => (this.linkToSearchRef = node);

  clauseMouseOverHandler = _.memoize(id => () =>
    this.props.setLastHoveredClausepartId(id),
  );

  addClausepartComment = (text, isExternal) =>
    this.props.addClausepartComment(this.props.clause.id, text, isExternal);
}

function getClausepartTextChanges(changes) {
  return (changes || []).filter(
    ({type}) =>
      type === "clausepart_text_addition" ||
      type === "clausepart_text_alteration" ||
      type === "clausepart_text_deletion",
  );
}

function checkIsSavePending(
  changes,
  clauseAdditionChange,
  clauseId,
  clausepartId,
) {
  return Boolean(
    changes.find(change => {
      if (change.type !== "pending_save") {
        return false;
      }
      return (
        (change.new_clausepart_id === clausepartId &&
          change.new_clause_id === clauseId) ||
        (clauseAdditionChange &&
          clauseAdditionChange.new_clause_id === change.new_clause_id)
      );
    }),
  );
}

export default makeComponentHoverable(Atom);

import _ from "underscore";
import React, {Component} from "react";

import convertContentsToListItems from "common/utils/contents/convert_contents_to_list_items";
import {styles as numberedStyles} from "routes/document_detail/components/document_views/clause/open/numbered_item";

const contentsStyle = {
  main: {
    ...numberedStyles.main,
    marginBottom: ".5em",
  },
  numberText: {
    ...numberedStyles.numberText,
    color: "rgb(3, 155, 229)",
  },
  justText: {
    marginBottom: ".5em",
  },
};

class Contents extends Component {
  render() {
    const {props} = this;
    let listItems = convertContentsToListItems(props.value);
    if (props.decimalPrefix) {
      listItems = listItems.map(item => {
        if (!item.indent && parseInt(item.reference, 10)) {
          return {
            ...item,
            reference: `${props.decimalPrefix}.${item.reference}`,
          };
        }
        return item;
      });
    }
    return <div>{_.map(listItems, this.renderListItem)}</div>;
  }

  renderListItem = (listItem, key) => {
    if (typeof listItem === "string" || listItem instanceof String) {
      return (
        <div key={key} style={contentsStyle.justText}>
          {listItem}
        </div>
      );
    } else if (typeof listItem === "object") {
      const {text, indent, reference} = listItem;
      const shift = (indent || 0) * 4.5;
      return (
        <div key={key} style={contentsStyle.main}>
          <span
            style={{
              ...numberedStyles.number,
              marginLeft: `${shift}em`,
            }}
          >
            <span className="number-text" style={contentsStyle.numberText}>
              {reference[0].toLowerCase().match(/[a-z]/i)
                ? `(${reference})`
                : `${reference}.`}
            </span>
          </span>
          {text}
        </div>
      );
    }
    return null;
  };
}

export default Contents;

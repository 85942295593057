import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function headingsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.DOCUMENT_FETCH.SUCCESS:
      return !action.payload.headings ? state : action.payload.headings;
    case ActionTypes.DOCUMENT_HEADING_ADD.SUCCESS:
      if (action.payload.new_heading) {
        return [...state, action.payload.new_heading];
      }
      return state;
    case ActionTypes.DOCUMENT_HEADING_ALTER.SUCCESS: {
      if (action.payload && action.payload.documentHeading) {
        const {documentHeading: alteredHeading} = action.payload;
        return state.map(heading => {
          if (heading.id === alteredHeading.id) {
            return {
              ...heading,
              ...alteredHeading,
            };
          }
          return heading;
        });
      }
      return state;
    }
    case ActionTypes.DOCUMENT_HEADING_REMOVE.SUCCESS:
    case ActionTypes.DOCUMENT_HEADING_CHANGES_REVERT.SUCCESS: {
      if (action.payload && action.payload.removed_heading_id) {
        return (state || []).filter(
          heading => heading.id !== action.payload.removed_heading_id,
        );
      }
      return state;
    }
    case ActionTypes.DOCUMENT_TEXT_REPLACE.SUCCESS: {
      if (action.payload && action.payload.headings) {
        return action.payload.headings;
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLAUSE_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSE_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_ADD.SUCCESS:
    case ActionTypes.DOCUMENT_CLAUSEPART_ADDITION_REVERT.SUCCESS:
    case ActionTypes.DOCUMENT_STATE_REVERT.SUCCESS: {
      if (action.payload && action.payload.headings) {
        return action.payload.headings;
      }
      return state;
    }
    case ActionTypes.DOCUMENT_CLEAR.SUCCESS:
      return UNINITIALISED;
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import copy from "copy-to-clipboard";

const select = (nodeFullText, elementId) => e => {
  e.preventDefault();
  e.stopPropagation();
  let sel;
  if (document.getSelection) {
    sel = document.getSelection();
  } else {
    sel = document.selection.createRange();
  }
  let selectedText = "";
  const element = document.getElementById(elementId);
  if (sel.rangeCount > 0) {
    const range = sel.getRangeAt(0);
    let clonedSelection = range.cloneContents().querySelector(`#${elementId}`);
    if (clonedSelection) {
      selectedText = getSelectedText(clonedSelection);
    } else {
      clonedSelection = range.cloneContents();
      const startNode = sel.getRangeAt(0).startContainer.parentNode;
      if (isChild(startNode, element)) {
        const div = document.createElement("div");
        div.appendChild(clonedSelection);
        selectedText = getSelectedText(div);
      }
    }
  }
  if (selectedText.trim()) {
    copy(selectedText, {format: "text/plain"});
  } else {
    copy(nodeFullText, {format: "text/plain"});
  }

  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE
    document.selection.empty();
  }
};

function isChild(child, parent) {
  if (child === parent) {
    return true;
  }
  let current = child;
  while (current) {
    if (current === parent) {
      return true;
    }
    current = current.parentNode;
  }
  return false;
}

function getSelectedText(node) {
  const nodeChildren = [].slice.call(node.children);
  if (nodeChildren.length > 0) {
    const divChildrenTexts = nodeChildren.map(child => child.innerHTML.trim());
    return divChildrenTexts.join("\n");
  }
  return node.innerHTML;
}

export default select;

import React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";

import groupChecklistSections from "common/utils/document/group_checklist_sections";

import "./section_selector.css";

const styles = {
  dropDown: {
    height: "auto",
  },
  dropDownLabel: {
    whiteSpace: "normal",
    lineHeight: 1.2,
    height: "auto",
    paddingRight: 48,
    paddingLeft: 6,
    color: "inherit",
    fontSize: 16,
  },
  dropDownIcon: {
    top: "50%",
    marginTop: -24,
    right: 0,
  },
  headerTitleDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontSize: 14,
  },
};

const mainSections = ["main", "background", "main_execution"];

function SectionSelector(props) {
  const {
    sections,
    selectedChecklistSectionId,
    onSelectedChecklistSectionIdChange,
    isFlipChecklistIcons,
    issues,
  } = props;
  const hasScheduleSections = (sections || []).find(
    ({reference}) => mainSections.indexOf(reference.toLowerCase()) === -1,
  );
  const [reprocessClicked, updateReprocessClicked] = React.useState(false);
  if (!hasScheduleSections) {
    return null;
  }
  const checklistSections = !sections ? [] : groupChecklistSections(sections);
  const haveProcessedIssues = (issues || []).find(
    docIssue => docIssue.section_id,
  );

  const dropDownMenuStyles = {
    backgroundColor: isFlipChecklistIcons ? "#fff" : "#333d46",
  };
  const dropDownMenuItemStyles = {
    fontSize: 14,
    color: isFlipChecklistIcons ? "#424242" : "#fff",
  };

  function onReprocessClick() {
    if (props.document) {
      updateReprocessClicked(true);
      props.runDocumentIssuesFind(props.document.id);
    }
  }

  function renderSectionSelector() {
    return (
      <>
        <div style={styles.headerTitleDiv}>Section</div>
        <DropDownMenu
          value={selectedChecklistSectionId || null}
          onChange={onSelectedChecklistSectionIdChange}
          underlineStyle={{display: "none"}}
          style={styles.dropDown}
          labelStyle={styles.dropDownLabel}
          iconStyle={styles.dropDownIcon}
          menuStyle={dropDownMenuStyles}
          menuItemStyle={dropDownMenuItemStyles}
        >
          <MenuItem
            value={null}
            key={"whole-document"}
            primaryText="Whole Document"
          />
          {checklistSections.map(section => (
            <MenuItem
              key={section.id}
              value={section.id}
              primaryText={
                section.reference === "main" ? "Agreement" : section.reference
              }
            />
          ))}
        </DropDownMenu>
      </>
    );
  }

  function renderReprocessText() {
    return (
      <div style={{display: "flex", alignItems: "center"}}>
        <div style={{fontSize: 13}}>
          Schedules have not been processed for this document,
          <span
            className="reprocess-button"
            style={{color: reprocessClicked ? "inherit" : "#50B2FF"}}
            onClick={reprocessClicked ? undefined : onReprocessClick}
          >
            Reprocess
          </span>
          ?
        </div>
        {reprocessClicked ? (
          <CircularProgress style={{width: 18, height: 18}} />
        ) : (
          <div style={{width: 18, height: 18}} />
        )}
      </div>
    );
  }
  return (
    <div
      style={{
        boxSizing: "border-box",
        padding: "0.5rem 2.5rem 0.5rem 1rem",
        minHeight: 52,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        borderBottom: "1px solid #4f575f",
        flexShrink: 0,
        ...(isFlipChecklistIcons ? {color: "black"} : {color: "#fff"}),
      }}
    >
      {!haveProcessedIssues ? renderReprocessText() : renderSectionSelector()}
    </div>
  );
}

export default SectionSelector;

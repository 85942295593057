import _ from "underscore";
import getAcceptedTopicCategoryIds from "utils/topic/get_accepted_topic_category_ids";
import getIssueApplicableClauses from "utils/issues/get_issue_applicable_clauses";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import issueHeaderUtils from "utils/issues/issue_header_utils";
import issueTemplateUtils from "utils/issues/issue_template_utils";
import {
  getIssueManualCorrections,
  shouldUseManualCorrections,
} from "utils/manual_corrections_utils";
import getClauseAtomsByIds from "routes/document_detail/utils/applicable_clauses/get_clause_atoms_by_ids";
import groupChecklistSections from "common/utils/document/group_checklist_sections";
import globalOverrideKey from "common/utils/issues/global_override_key";

import {
  groupByCommonDefinitions,
  getCommonDefinitionApplicableClauseGroups,
} from "utils/issues/common_definitions";

function getIssue(issue, groupedIssues) {
  if (issue.issue_type === "EMPTY_PARENT") {
    let foundIssue;
    groupedIssues.forEach(group => {
      group.item.forEach(item => {
        if (item.id === issue.id) {
          foundIssue = item;
        }
      });
    });
    if (foundIssue) {
      return foundIssue;
    }
  }
  return issue;
}

function getIssuesReportData(issues, props) {
  const {
    selectedReportId,
    selectedChecklistSectionId,
    project,
    groupedIssues,
    documentClauses: documentClausesPerSection,
    documentClauseparts,
    document,
    documentSections,
    topicCategories,
    topicsById,
    positiveReasonData,
    currentIssuesetItem,
  } = props;

  let documentClauses = documentClausesPerSection;
  if (selectedChecklistSectionId) {
    const checklistSections = groupChecklistSections(documentSections) || [];
    const selectedChecklistSection = checklistSections.find(
      section => section.id === selectedChecklistSectionId,
    );
    if (
      selectedChecklistSection &&
      selectedChecklistSection.sections &&
      selectedChecklistSection.sections.length > 0
    ) {
      documentClauses = selectedChecklistSection.sections.reduce(
        (result, section) => {
          result[section.id] = documentClausesPerSection[section.id];
          return result;
        },
        {},
      );
    }
  }
  return issues.map(issue =>
    getIssueReportData(
      issue,
      groupedIssues,
      project,
      documentClauses,
      documentClauseparts,
      document?.parties ?? [],
      documentSections,
      topicCategories,
      topicsById,
      positiveReasonData,
      selectedReportId,
      currentIssuesetItem,
    ),
  );
}

function getIssueReportData(
  issue,
  groupedIssues,
  project,
  documentClauses,
  documentClauseparts,
  parties,
  documentSections,
  topicCategories,
  topicsById,
  positiveReasonData,
  selectedReportId,
  issueset,
) {
  const globalManualCorrections = getIssueManualCorrections(
    issue,
    issueset,
    globalOverrideKey,
  );
  const reportManualCorrections = getIssueManualCorrections(
    issue,
    issueset,
    selectedReportId,
  );

  const issueWithSubissueData = getIssue(issue, groupedIssues);

  const llmIssues = issue?.llm_response?.applicable_clauses
    ? _.chain(issue?.llm_response?.applicable_clauses)
        .values()
        .flatten()
        .unique(item => item.id)
        .map(item => {
          const clausepart = documentClauseparts.find(cp => cp.id === item.id);
          if (!clausepart) {
            return item;
          }
          return {
            ...item,
            text: clausepart.text,
            clause_section_id: item.section_id,
            clause_reference: clausepart.clause_reference,
          };
        })
        .filter(item => item.text)
        .value()
    : null;
  const applicableClausesRaw =
    llmIssues ??
    getIssueApplicableClauses(
      issueWithSubissueData,
      documentClauses,
      getAcceptedTopicCategoryIds(topicCategories),
      documentSections,
      topicsById,
    );

  // getting applicable clauses
  const baseApplicableClauses = _.chain(applicableClausesRaw)
    .uniq(clause => clause.text)
    .sortBy(clause => parseInt(clause.reference.split(".")[0], 10))
    .value();
  const additionalReportClauseIds =
    reportManualCorrections.additional_report_clauses || [];

  const alteredClauses = reportManualCorrections.altered_clauses || {};
  const alteredClausesId = Object.keys(alteredClauses);

  const additionalReportClauses = getClauseAtomsByIds(
    additionalReportClauseIds,
    documentClauses,
  );

  const commonDefinitionApplicableClauseGroups = getCommonDefinitionApplicableClauseGroups(
    issue,
    issueset,
    documentClauses,
    topicsById,
    positiveReasonData,
    documentClauseparts,
    parties,
  );
  const applicableClausesGroupedByCommonDefinitions = groupByCommonDefinitions(
    documentClauseparts,
    commonDefinitionApplicableClauseGroups,
  );
  const applicableClauses = [
    ...baseApplicableClauses,
    ...additionalReportClauses,
    ..._.flatten(
      Object.values(applicableClausesGroupedByCommonDefinitions ?? {}),
    ),
  ];

  // if there is alternative text then replaces current
  if (alteredClausesId.length) {
    applicableClauses.forEach(clause => {
      if (alteredClausesId.find(itemId => Number(itemId) === clause.id)) {
        clause.text = alteredClauses[clause.id]?.text;
        clause.reference = alteredClauses[clause.id]?.reference;
      }
    });
  }

  const hasReasons = issue.reason && issue.reason.length > 0;
  const isTriggered = globalManualCorrections.new_state
    ? globalManualCorrections.new_state === "alert"
    : isIssueAlert(issue);

  return {
    ..._.pick(issue, ["id", "document_issue_id", "shouldBeHidden"]),
    applicableClauses,
    isTriggered,
    hasReasons,
    comment: shouldUseManualCorrections("comment", project, selectedReportId)
      ? reportManualCorrections.comment || issue.comment
      : issue.comment,
    removedClauses: reportManualCorrections.removedClauses || {},
    issue_class_id: issue.issue_class_id,
    issue_name: shouldUseManualCorrections(
      "issue_name",
      project,
      selectedReportId,
    )
      ? reportManualCorrections.issue_name || issue.name
      : issue.name,
    description: shouldUseManualCorrections(
      "description",
      project,
      selectedReportId,
    )
      ? reportManualCorrections.description || issue.description
      : issue.description,
    standard_language: shouldUseManualCorrections(
      "standard_language",
      project,
      selectedReportId,
    )
      ? reportManualCorrections.standard_language || issue.standard_language
      : issue.standard_language,
    reason:
      (hasReasons
        ? reportManualCorrections.reason
        : reportManualCorrections.positive_reason) ||
      (
        issueHeaderUtils.getReasonTextArray(
          issueWithSubissueData,
          documentClauses,
          topicsById,
          positiveReasonData,
          selectedReportId,
          issueset,
          documentClauseparts,
          parties,
          false, // logDebugInfo,
          false, // showDetailedReason
          false, // shouldUseManualCorrections
          false, // preventMemo
        ) || []
      ).join("\n"),
    detailed_reason:
      (hasReasons
        ? reportManualCorrections.detailed_reason
        : reportManualCorrections.detailed_positive_reason) ||
      (
        issueHeaderUtils.getReasonTextArray(
          issueWithSubissueData,
          documentClauses,
          topicsById,
          positiveReasonData,
          selectedReportId,
          issueset,
          documentClauseparts,
          parties,
          false, // logDebugInfo,
          true, // showDetailedReason
          false, // shouldUseManualCorrections
          false, // preventMemo
        ) || []
      ).join("\n"),
    guidance:
      (hasReasons
        ? reportManualCorrections.guidance
        : reportManualCorrections.positive_guidance) ||
      shouldUseManualCorrections("guidance", project, selectedReportId)
        ? reportManualCorrections.guidance ||
          issueTemplateUtils.getGuidanceTemplateText(
            issueWithSubissueData,
            issueset,
            documentClauses,
            topicsById,
            positiveReasonData,
            documentClauseparts,
            parties,
            selectedReportId,
            true, // shouldUseManualCorrections
          )
        : issueTemplateUtils.getGuidanceTemplateText(
            issueWithSubissueData,
            issueset,
            documentClauses,
            topicsById,
            positiveReasonData,
            documentClauseparts,
            parties,
            selectedReportId,
            true, // shouldUseManualCorrections
          ),
    required_change: shouldUseManualCorrections(
      "required_change",
      project,
      selectedReportId,
    )
      ? reportManualCorrections.required_change ||
        issueTemplateUtils.getRequiredChangeTemplatedText(
          issueWithSubissueData,
          issueset,
          documentClauses,
          topicsById,
          positiveReasonData,
          documentClauseparts,
          parties,
          selectedReportId,
          true, // shouldUseManualCorrections
        )
      : issueTemplateUtils.getRequiredChangeTemplatedText(
          issueWithSubissueData,
          issueset,
          documentClauses,
          topicsById,
          positiveReasonData,
          documentClauseparts,
          parties,
          selectedReportId,
          true, // shouldUseManualCorrections
        ),
    fallback: shouldUseManualCorrections("fallback", project, selectedReportId)
      ? reportManualCorrections.fallback ||
        issueTemplateUtils.getFallbackTemplateText(
          issueWithSubissueData,
          issueset,
          positiveReasonData,
          documentClauseparts,
          parties,
          selectedReportId,
          true, // shouldUseManualCorrections
        )
      : issueTemplateUtils.getFallbackTemplateText(
          issueWithSubissueData,
          issueset,
          positiveReasonData,
          documentClauseparts,
          parties,
          selectedReportId,
          true, // shouldUseManualCorrections
        ),
    action_state: globalManualCorrections,
    alertColor: globalManualCorrections.alert_color,
    selectedReportId,
    isResolved: issue.resolved_state === 1,
    isIgnored: issue.resolved_state === 0,
    manualCorrections: reportManualCorrections,
    isDeleted: issue.isDeleted,
    manualUpdateCount: issue.manualUpdateCount,
    review_state: issue.review_state,
    rag_score: reportManualCorrections?.rag_score
      ? reportManualCorrections.rag_score
      : 0,
  };
}

export default getIssuesReportData;

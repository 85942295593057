import React, {useState} from "react";
import styled from "styled-components";
import TextField from "material-ui/TextField";

import {LLMResponse, isLLMResponse, IssueId} from "common/types/issue";
import tryParseJSON from "common/utils/try_parse";

const defaultLLMResponse = JSON.stringify(
  {
    rag_state: "Amber",
    issue_summary: "Example issue summary",
    issue_detail: "Example issue detail",
    applicable_clauses: {
      "Example Topic": [
        {
          id: 1,
          text: "'id' should be a clausepart ID",
        },
      ],
    },
  },
  null,
  2,
);

const Container = styled.div`
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`;

const Example = styled.pre`
  font-size: 0.75rem;
`;

const Title = styled.div`
  color: rgb(66, 165, 245);
  font-weight: 500;
  font-size: 15px;
`;

interface Props {
  /**
   * Callback invoked with new LLMResponse value on change. This callback is
   * only invoked when the user's input parses to valid JSON of the correct
   * structure for @type LLMResponse.
   */
  onChange: (newValue: LLMResponse) => void;
  /**
   * Controlled value of this input. Currently synchronised crudely (on-mount).
   * A better policy in general would be to override the local state of this
   * component with this value as a simple side-effect of it changing.
   */
  value: LLMResponse;

  issueId: IssueId;
}

function getDefaultState(value) {
  return value ? JSON.stringify(value) : defaultLLMResponse;
}

const MockLLMResponseInput = ({onChange, value, issueId}: Props) => {
  const [state, setState] = useState(getDefaultState(value));
  React.useEffect(() => setState(getDefaultState(value)), [issueId]);

  const [hasError, setHasError] = useState(false);

  return (
    <Container>
      <Title>Mock LLM Response</Title>
      <TextField
        style={{width: "100%", fontFamily: "monospace", fontSize: "0.8rem"}}
        value={state}
        onChange={event => setState(event.target.value)}
        onBlur={() => {
          const parsed = tryParseJSON(state);
          if (!parsed.ok || !isLLMResponse(parsed.value)) {
            setHasError(true);
            return;
          }
          onChange(parsed.value);
          setHasError(false);
        }}
        multiLine
        rows={6}
        name="Mock LLM Response"
      />
      {hasError && (
        <>
          <span>Sorry, that didn't parse right. Here's an example:</span>
          <Example>{defaultLLMResponse}</Example>
        </>
      )}
    </Container>
  );
};

export default MockLLMResponseInput;

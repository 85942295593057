import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";
import UserActionTypes from "modules/user/constants/action_types";

export default function organisationReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.ORGANISATION_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.ORGANISATION_UPDATE.SUCCESS: {
      return {...state, ...action.payload};
    }
    case ActionTypes.ORGANISATION_USER_UPDATE.SUCCESS: {
      return {
        ...state,
        users: state.users.map(user => {
          if (user.id === action.payload.id) {
            return {...user, ...action.payload};
          }
          return user;
        }),
      };
    }
    case ActionTypes.ORGANISATION_USER_DELETE.SUCCESS: {
      return {
        ...state,
        users: state.users.filter(user => {
          if (user.id === action.payload.userId) {
            return false;
          }
          return true;
        }),
      };
    }
    case ActionTypes.ORGANISATION_USER_ADD.SUCCESS: {
      return {...state, users: [action.payload, ...state.users]};
    }
    case ActionTypes.ORGANISATION_USER_UNLOCK.SUCCESS: {
      return {
        ...state,
        users: state.users.map(
          user =>
            user.id === action.payload.user_id
              ? {...user, is_locked: false}
              : user,
        ),
      };
    }
    case ActionTypes.ORGANISATION_CHANGE.SUCCESS:
    case ActionTypes.ORGANISATION_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import _ from "underscore";
import unmemoizedById from "common/utils/by_id";

export default function(type, list) {
  return _.memoize(
    unmemoizedById,
    item =>
      type +
      (item === "UNINITIALISED"
        ? "UNINITIALISED"
        : item.reduce(
            (state, next) => Math.max(state?.last_edited, next?.last_edited),
            "",
          )),
  )(list);
}

import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function LoadingCircular(props) {
  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <CircularProgress />
    </div>
  );
}

import _ from "underscore";
import getListStyleType from "./get_list_style_type";

export default function getContents(language) {
  const ops = [];
  if (_.isArray(language)) {
    let contText = "";
    for (const lang of language) {
      const {level, text} = lang;
      contText = `${contText ? `${contText}\n` : ""}${text}`;
      if (level > 1) {
        ops.push({insert: contText});
        contText = "";
        ops.push({insert: "\n", attributes: makeAttributes(level)});
      }
    }
    if (contText.length) {
      ops.push({insert: contText});
    }
  }
  return {ops};
}

function makeAttributes(level) {
  const attributes = {
    list: "ordered",
    "list-style-type": getListStyleType(level),
  };
  if (level > 2) {
    attributes.indent = level - 2;
  }
  return attributes;
}

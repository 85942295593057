function pcHelper(number) {
  let str = `${number * 100}`;
  // handle floating point weirdness eg: 0.07*100, 0.141/100
  str = str
    .replace(/\.?0{4,100}\d$/, "")
    .replace(
      /(\.)?(\d)?9{4,100}\d$/,
      (match, p1, p2) =>
        (p1 ? "." : "") + (p2 ? `${parseInt(p2, 10) + 1}` : "1"),
    );
  return `${str}%`;
}

export default pcHelper;

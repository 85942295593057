import _ from "underscore";
import reportCellUtils from "../utils/report_cell_utils";

const {
  getHeadingText,
  getCellData,
  constructRow,
  constructCell,
  constructCellChild,
  getStatusColor,
  removeHeading,
  extractValue,
} = reportCellUtils;

function constructRagReportTable(issues, settingsFields, props, customRows) {
  const {reportSettings = {}} = props;
  const {hide_issue_group_headings: hideIssueGroupHeadings} = reportSettings;
  let lastHeadingText = null;
  const cellCount = settingsFields.reduce(
    (count, item) =>
      count + (item.value === true || item.type?.startsWith("clause_") ? 1 : 0),
    0,
  );

  const items = _.chain(issues.concat(customRows))
    .map((issue, issueIndex) => {
      const headingText = hideIssueGroupHeadings
        ? null
        : getHeadingText(issue.issue_name);
      let heading;
      if (headingText && headingText !== lastHeadingText) {
        lastHeadingText = headingText;
        heading = constructRow([
          constructCell(
            [constructCellChild("paragraph", headingText, {bold: true})],
            {
              columnSpan:
                cellCount + (reportSettings.are_report_items_numbered ? 1 : 0),
            },
          ),
        ]);
      }

      const rowChildren = settingsFields.reduce((resultCells, field) => {
        if (!field.value) {
          return resultCells;
        }

        if (issue?.additional_item) {
          const value = issue?.[field.type]
            ? field.type === "issue_name"
              ? removeHeading(
                  extractValue(issue.issue_name),
                  getHeadingText(issue.issue_name),
                )
              : issue[field.type]
            : "Blank";
          const type =
            field.type === "status"
              ? "additional_status"
              : field.type === "review_state"
              ? "additional_review_state"
              : "paragraph";

          resultCells.push(
            constructCell([constructCellChild(type, value)], {
              id: issue.id,
              isEditable: !["review_state", "status", "clause_display"].find(
                item => item === field.type,
              ),
              isEditableInGui: ["clause_display", "clause_text"].find(
                item => item === field.type,
              ),
              fieldName:
                field.type === "clause_display" ? "clause_text" : field.type,
              additional_item: true,
            }),
          );
        } else {
          const {cellContent, fieldOptions} = getCellData(field, issue, props);
          resultCells.push(constructCell(cellContent, fieldOptions));
        }

        return resultCells;
      }, []);

      const numberChild = [
        ...(reportSettings.are_report_items_numbered
          ? [
              constructCell(
                [constructCellChild("paragraph", `${issueIndex + 1}.`)],
                {},
                "number",
              ),
            ]
          : []),
      ];

      return [
        ...(heading ? [heading] : []),
        constructRow([...numberChild, ...rowChildren], {
          document_issue_id: issue.document_issue_id,
          manualUpdateCount: issue.manualUpdateCount,
          review_state: issue.review_state,
          issue_status: getStatusColor(issue, props),
          issue: true,
          additional_item: issue?.additional_item ? issue.id : false,
        }),
      ];
    })
    .flatten()
    .filter(item => item)
    .value();

  return items;
}

export default constructRagReportTable;

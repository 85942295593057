import Tooltip from "@material-ui/core/Tooltip";
import React from "react";
import {withRouter} from "react-router";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import BugReportIcon from "@material-ui/icons/BugReport";

import Permissioner from "utils/permissioner";
import areReportsEnabled from "utils/are_reports_enabled";
import Toolbar from "common_components/toolbar";
import StateSelect from "common_components/document/state_select";
import EmailButton from "./buttons/email";
import ToolbarProjectSelector from "./project_selector";
import ToolbarDocumentSelector from "./document_selector";
import ReportSelector from "common_components/report_selector";
import EditButton from "./buttons/edit";
import SettingsButton from "./buttons/settings";
import DownloadButton from "./buttons/download";
import LlmChatButton from "./buttons/llm_chat";
import ExternalClauseLibraryButton from "./buttons/external_clause_library";
import ZoomButton from "./buttons/zoom";
import {organisationPath, setParamsToPath} from "routes/navigation";

import styles from "./styles";

class DocumentToolbar extends React.Component {
  render() {
    const {project, currentIssueset} = this.props;
    const {report_settings: reportSettings = []} = project;
    const filteredReportSettings = reportSettings.filter(report => {
      if (report.type === "custom_report" && currentIssueset) {
        return report.referenced_issueset_id
          ? report.referenced_issueset_id === currentIssueset
          : true;
      }
      return true;
    });
    return (
      <Toolbar
        title={this.renderTitle(project)}
        children={this.renderChildren(project, filteredReportSettings)}
        rightChildren={this.renderButtons(project, filteredReportSettings)}
        innerStyle={styles.toolbarInner}
        dontUsePortal={true}
      />
    );
  }

  renderTitle(project) {
    return (
      <div style={styles.titleWrapper}>
        {this.renderBadParsingIndicator()}
        <ToolbarDocumentSelector
          renderingStatus={this.props.renderingStatus}
          fileIndex={this.props.fileIndex}
          setFileIndex={this.props.setFileIndex}
          document={this.props.document}
          documents={this.props.documents}
          router={this.props.router}
          onFieldUpdate={this.props.updateDocument}
          hasPermissionToEdit={this.hasPermission("update-document")}
          processTextBeforeUpdate={preventStrippingDocumentExtension}
        />
        {this.props.user.is_admin ? (
          <>
            <ToolbarProjectSelector
              autoOpen
              issueEditorHref={
                this.props.issues.length > 0 &&
                this.props.organisation.is_central_admin &&
                this.hasPermission("direct-issue-access")
                  ? setParamsToPath(
                      {
                        organisationId: this.props.organisation.id,
                        projectId: project.id,
                        documentId: this.props.document.id,
                        // NOTE: This is a link to the issue editor, but there
                        // is no obvious choice of which issue to open by
                        // default. Therefore, we arbitrarily choose to link to
                        // the first issue.
                        issueId: this.props.issues[0]?.id,
                        issuesetId: this.props.currentIssueset.id,
                      },
                      `${organisationPath}/issue/:issueId?open_issue_list=true&project=:projectId&document=:documentId&issueset=:issuesetId`,
                    )
                  : undefined
              }
              useLinkItems={true}
              projectId={project.id}
              hideLeftIcon
            />
          </>
        ) : null}
      </div>
    );
  }

  renderBadParsingIndicator() {
    if (this.props.isBadlyParsed && this.props.user.is_admin) {
      return (
        <>
          <Tooltip title={"Document is badly parsed"} arrow>
            <BugReportIcon
              style={{
                ...styles.toolbarIcon,
                color: "#f00",
              }}
            />
          </Tooltip>
        </>
      );
    }
    return null;
  }

  renderChildren = (project, reportSettings) => {
    return (
      <>
        {!this.props.user.is_admin || this.props.clientModeOn ? null : (
          <FormControlLabel
            control={
              <Checkbox
                checked={this.props.areIssuesCollapsed}
                onChange={this.props.collapseIssues}
                color="primary"
              />
            }
            label="Collapse Issues"
            style={styles.formLabel}
          />
        )}
        {reportSettings.length > 1 && (
          <ReportSelector
            project={project}
            isReportShownInRightSide={this.props.isReportShownInRightSide}
            isReportPanelShown={this.props.isReportPanelShown}
            toggleReportPanelVisibility={this.props.toggleReportPanelVisibility}
            toggleReportOpenInRightSidebar={
              this.props.toggleReportOpenInRightSidebar
            }
            selectedReportId={this.props.selectedReportId}
            onSelectedReportChange={this.props.onSelectedReportChange}
            responseAddedToReport={this.props.responseAddedToReport}
            currentIssueset={this.props.currentIssueset}
            disabled={!areReportsEnabled(this.props.document, project)}
          />
        )}
        {reportSettings.length === 1 && <EmailButton {...this.props} />}
      </>
    );
  };

  renderButtons(project) {
    return (
      <>
        {project.show_state_selector ? (
          <StateSelect
            states={this.props.documentStates}
            stateId={this.props.document.state_id}
            updateState={this.updateDocumentState}
            style={styles.stateSelect}
          />
        ) : null}
        <ExternalClauseLibraryButton
          externalClauseLibrary={this.props.project.external_clause_library}
          showIcon
        />
        <EditButton {...this.props} />
        <DownloadButton {...this.props} />
        <ZoomButton {...this.props} />
        <LlmChatButton {...this.props} />
        <SettingsButton {...this.props} />
      </>
    );
  }

  updateDocumentState = newStateId => {
    const {props} = this;
    props.updateDocument({state_id: newStateId});
  };

  hasPermission = permission => {
    return new Permissioner(this.props.user).hasPermission(permission);
  };
}

function preventStrippingDocumentExtension(oldName, newName) {
  const oldExtension = getFilenameExtension(oldName);
  const newExtension = getFilenameExtension(newName);
  if (oldExtension && oldExtension !== newExtension) {
    return `${newName}.${oldExtension}`;
  }
  return newName;
}

function getFilenameExtension(name) {
  const nameArray = name.split(".");
  return nameArray[nameArray.length - 1];
}

export default withRouter(DocumentToolbar);

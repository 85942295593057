import React from "react";

import AutoComplete from "material-ui/AutoComplete";
import TextField from "material-ui/TextField";
import ArrowDown from "material-ui/svg-icons/navigation/arrow-drop-down";
import MenuItem from "material-ui/MenuItem";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as colors from "material-ui/styles/colors";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  regexValueContainer: {
    position: "relative",
    display: "flex",
  },
  dropDownArrowIcon: {
    cursor: "pointer",
    color: colors.grey500,
    position: "absolute",
    right: "0px",
    top: "10px",
    height: "28px",
    width: "28px",
  },
  deleteIcon: {
    cursor: "pointer",
    color: colors.grey700,
  },
  hrule: {
    borderTop: "none rgb(224, 224, 224)",
    borderLeft: "none rgb(224, 224, 224)",
    borderRight: "none rgb(224, 224, 224)",
    borderBottom: "1px solid rgb(224, 224, 224)",
    bottom: "8px",
    boxSizing: "content-box",
    margin: "0px",
    position: "absolute",
    width: "2rem",
  },
};

class ValueEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {inputText: this.props.value};
  }

  render() {
    return (
      <div style={styles.container}>
        {this.props.displayValues
          ? this.renderDropdown()
          : this.renderTextField()}
        <DeleteIcon
          onMouseDown={this.onDeleteValue}
          style={styles.deleteIcon}
        />
      </div>
    );
  }

  renderDropdown = () => {
    return (
      <div style={styles.regexValueContainer}>
        <AutoComplete
          ref={this.createRegexValueRef}
          name="regex"
          filter={AutoComplete.caseInsensitiveFilter}
          searchText={this.state.inputText || ""}
          onUpdateInput={this.handleUpdateInput}
          onNewRequest={this.onUpdateValue}
          dataSource={this.getDataSource()}
          openOnFocus={true}
          underlineFocusStyle={{width: "0px"}}
          style={{width: "20rem", marginLeft: "4px"}}
          textFieldStyle={{width: "20rem"}}
          listStyle={{maxHeight: "20rem", overflowY: "auto", width: "21rem"}}
          onBlur={this.onAutocompleteBlur}
          multiLine={true}
        />
        <div style={{width: "2rem"}}>
          <hr style={styles.hrule} />
        </div>
        <ArrowDown
          style={styles.dropDownArrowIcon}
          onMouseDown={this.onArrowClick}
        />
      </div>
    );
  };

  renderTextField = () => {
    return (
      <TextField
        name="regex"
        value={this.state.inputText}
        onChange={this.handleTextFieldChange}
        style={{width: "93%"}}
      />
    );
  };

  getDataSource = () => {
    const {previousValues} = this.props;
    const previousValuesData = previousValues.map(previousValue => ({
      text: previousValue,
      value: <MenuItem key={previousValue} primaryText={previousValue} />,
    }));

    let addItem;
    if (
      this.state.inputText &&
      !previousValues.includes(this.state.inputText) &&
      !this.props.currentValues.includes(this.state.inputText)
    ) {
      addItem = {
        text: this.state.inputText,
        value: (
          <MenuItem
            key="add-new"
            primaryText={
              <div style={{color: colors.blue600}}>{`Add ${
                this.state.inputText
              }`}</div>
            }
          />
        ),
      };
    }
    return addItem ? [addItem, ...previousValuesData] : previousValuesData;
  };

  handleUpdateInput = inputText => this.setState(() => ({inputText}));

  onAutocompleteBlur = () => {
    this.state.inputText &&
      this.props.onUpdateValue(this.state.inputText, this.props.valueIndex);
  };
  onUpdateValue = value => {
    this.props.onUpdateValue(value.text, this.props.valueIndex);
  };
  onDeleteValue = this.props.onDeleteValue(this.props.valueIndex);

  onArrowClick = () => this.regexValueRef.focus();

  handleTextFieldChange = e => {
    const {onUpdateValue, valueIndex} = this.props;
    const inputText = e.target.value;
    this.setState(
      () => ({inputText}),
      () => onUpdateValue(inputText, valueIndex),
    );
  };
  createRegexValueRef = node => (this.regexValueRef = node);
}

export default ValueEditor;

const initSelectedReportState = project => {
  const {report_settings: reportSettings = []} = project;
  if (!reportSettings || reportSettings.length === 0) {
    return {selectedReportId: null};
  }
  return {
    selectedReportId: reportSettings[0].id,
  };
};

export default initSelectedReportState;

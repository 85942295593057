import _ from "underscore";

export const PENDING_KEYS = "__PENDING_KEYS__";
export const UPDATE_IN_PROGRESS = "__UPDATE_IN_PROGRESS__";
export const CHANGETIME = "__CHANGETIME__";

function updatePendingObj(existingPendingKeys = {}, changedKeys, changeTime) {
  const changeTimes = _.object(changedKeys, changedKeys.map(() => changeTime));
  const pendingObj = {};
  pendingObj[PENDING_KEYS] = {
    ...existingPendingKeys,
    ...changeTimes,
  };
  return pendingObj;
}

export function updateStateWithRequest(state, payload, keysToIgnore = []) {
  const changedKeys = Object.keys(
    _.omit(payload, keysToIgnore.concat([CHANGETIME])),
  );
  return {
    ...state,
    ...updatePendingObj(state[PENDING_KEYS], changedKeys, payload[CHANGETIME]),
    ..._.omit(payload, CHANGETIME),
    ..._.object([UPDATE_IN_PROGRESS], [true]),
  };
}

export function updateStateWithSuccess(state, payload) {
  if (state[PENDING_KEYS]) {
    const newState = (() => {
      const newKeys = Object.keys(_.omit(payload, CHANGETIME));
      const newChangetime = payload[CHANGETIME];

      const okKeys = newKeys.filter(key => {
        const keyTime = state[PENDING_KEYS][key];
        return !keyTime || keyTime <= newChangetime;
      });

      return {
        ..._.object(okKeys, okKeys.map(key => payload[key])),
        ...updatePendingObj(state[PENDING_KEYS], okKeys, payload[CHANGETIME]),
      };
    })();
    return {
      ..._.omit(state, UPDATE_IN_PROGRESS),
      ...newState,
    };
  }
  return {
    ..._.omit(state, UPDATE_IN_PROGRESS),
    ..._.omit(payload, CHANGETIME),
  };
}

export function createChangeTimeWrapper(date) {
  const obj = {};
  obj[CHANGETIME] = date;
  return obj;
}

import getAtomsFromClauses from "../clause/get_atoms";
import getDocxText from "./get_text";
import getIntersections from "./get_intersections";
import {fuzzySearch} from "common/utils/docx/fuzzy_search";

export default function searchAtomsInDocx(paragraphs, clauses) {
  const allAtoms = getAtomsFromClauses(clauses);
  const atomTexts = allAtoms.map(({partial_text}) => partial_text || "");
  const docxText = getDocxText(paragraphs);
  const atomTextFuzzyPositions = getAtomTextPositions(docxText, atomTexts);
  const intersections = getIntersections(paragraphs, atomTextFuzzyPositions);
  const notFoundAtoms = atomTextFuzzyPositions.filter(
    ({end, text}) => end === -1 && text,
  );
  return {paragraphs, intersections, notFoundAtoms};
}

function getAtomTextPositions(docxText, atomTexts) {
  let offset = 0;
  return atomTexts.map(substring => {
    const fuzzyPosition = fuzzySearch(docxText.substring(offset), substring);
    if (fuzzyPosition.start !== -1 && fuzzyPosition.end !== -1) {
      fuzzyPosition.start += offset;
      fuzzyPosition.end += offset;
      offset = fuzzyPosition.end;
    }
    fuzzyPosition.text = substring;
    return fuzzyPosition;
  });
}

import _ from "lodash";

function getIssueReasonData(issue) {
  if (!issue.reason || issue.reason.length === 0) {
    return [
      describeReason(
        issue.issue_type,
        issue.rules,
        null,
        "reason[0].reason",
        "reason[0]",
      ),
    ];
  }
  return issue.reason.map((issueReason, issueReasonIndex) =>
    describeReason(
      issue.issue_type,
      issue.rules,
      issueReason.reason,
      `reason[${issueReasonIndex}].reason`,
      `reason[${issueReasonIndex}]`,
    ),
  );
}

function describeReason(issueType, rules, reason, path = "", parentPath = "") {
  switch (issueType) {
    case "AND_ON_CLAUSE":
      return describeAndOnClauseReason(
        issueType,
        rules,
        reason,
        path,
        parentPath,
      );
    case "AND_ON_WHOLE_CLAUSE":
      return describeAndOnWholeClauseReason(
        issueType,
        rules,
        reason,
        path,
        parentPath,
      );
    case "AND_ON_DOCUMENT":
      return describeAndOnDocumentReason(
        issueType,
        rules,
        reason,
        path,
        parentPath,
      );
    case "OR":
      return describeOrReason(issueType, rules, reason, path, parentPath);
    case "NOT":
      return describeNotReason(issueType, rules, reason, path, parentPath);
    default:
      return describeSimpleReason(issueType, rules, reason, path);
  }
}

function describeAndOnClauseReason(issueType, rules, reason, path, parentPath) {
  return {
    issue_type: issueType,
    path: parentPath,
    clausepart_id: reason && reason.clause_id ? reason.id : null,
    children: getAndIssueChildren(
      rules,
      reason ? _.get(reason, "rules", {}) : [],
      `${path}.rules`,
    ),
  };
}

function describeAndOnWholeClauseReason(
  issueType,
  rules,
  reason,
  rawPath,
  parentPath,
) {
  let children = [];
  if (!reason) {
    children = [
      {
        path: `${rawPath}[0]`,
        clausepart_id: null,
        children: getAndIssueChildren(rules, [], `${rawPath}[0].rules`),
      },
    ];
  } else if (Array.isArray(reason)) {
    children = reason.map((reason, reasonIndex) => {
      const basePath = `${rawPath}[${reasonIndex}]`;
      const reasonRules = _.get(reason, "rules", {});
      const path = `${basePath}.rules`;
      return {
        path: basePath,
        clausepart_id: reason && reason.clause_id ? reason.id : null,
        children: getAndIssueChildren(rules, reasonRules, path),
      };
    });
  }
  return {
    issue_type: issueType,
    path: parentPath,
    children,
  };
}

function describeAndOnDocumentReason(
  issueType,
  rules,
  reason,
  rawPath,
  parentPath,
) {
  const children = (rules.issues || []).map((issue, issueIndex) => {
    const reasonItem = reason && reason[issueIndex] && reason[issueIndex][0];
    const path = `${rawPath}[${issueIndex}][0]`;
    return describeReason(
      issue.issue_type,
      issue.rules,
      reasonItem,
      path,
      path,
    );
  });
  return {
    issue_type: issueType,
    path: parentPath,
    children,
  };
}

function getAndIssueChildren(rules, reasonRules, path) {
  return (rules.issues || []).map((issue, issueIndex) => {
    const itemRule = reasonRules[issueIndex];
    const childPath = `${path}[${issueIndex}]`;
    return describeReason(
      issue.issue_type,
      issue.rules,
      itemRule,
      childPath,
      childPath,
    );
  });
}

function describeOrReason(issueType, rules, reason, rawPath, parentPath) {
  const children = rules.issues.map((issue, issueIndex) => {
    const reasonItem = reason && reason[issueIndex] && reason[issueIndex][0];
    const path = `${rawPath}[${issueIndex}][0]`;
    return describeReason(
      issue.issue_type,
      issue.rules,
      reasonItem,
      path,
      path,
    );
  });
  return {
    children,
    issue_type: issueType,
    path: parentPath,
  };
}

function describeNotReason(issueType, rules, reason, rawPath, parentPath) {
  return {
    issue_type: issueType,
    path: parentPath || rawPath,
    reason,
    children: [describeReason(rules.issue_type, rules.rules, "", "")],
  };
}

function describeSimpleReason(issueType, rules, reason, path) {
  const result = {
    issue_type: issueType,
    path,
  };
  if (Array.isArray(reason) && issueType === "COMPARE_PARAMETER_VALUES") {
    if (reason.length > 0) {
      result.children = reason.map((reasonItem, reasonItemIndex) =>
        constructSimpleReasonObject(
          reasonItem,
          `${path}[${reasonItemIndex}]`,
          reasonItemIndex === 0
            ? {...rules.topic_a, match_logic: rules.match_logic}
            : {...rules.topic_b, match_logic: rules.match_logic},
        ),
      );
      return result;
    }
    return null;
  }
  return {
    ...constructSimpleReasonObject(reason, path, rules),
    ...result,
  };
}

function constructSimpleReasonObject(reason, path, rules) {
  return {
    ...(reason && reason.clause_id
      ? {clausepart_id: reason.clause_id ? reason.id : null}
      : {}),
    reason: reason
      ? _.omit(reason, ["id", "clause_id", "path", "section_id"])
      : null,
    path,
    ...(!reason ? {not_triggered_rules: rules} : {}),
  };
}

export default getIssueReasonData;

import _ from "underscore";

export default function getIntersections(paragraphs, atomTextFuzzyPositions) {
  const intersections = [];

  const textElements = getTextElementsArray(paragraphs);
  textElements.forEach((te, index) => {
    te.id = index;
    intersections[index] = [];
  });

  let tIndex = 0;
  atomTextFuzzyPositions.forEach(({start: startClause, end: endClause}) => {
    while (tIndex < textElements.length) {
      const t = textElements[tIndex];
      if (!t) {
        return null;
      }
      const {start: startText, end: endText} = t;

      const maxStart = Math.max(startText, startClause);
      const minEnd = Math.min(endText, endClause);
      if (maxStart < minEnd) {
        intersections[tIndex].push({
          start: maxStart - startText,
          end: minEnd - startText,
        });
      }

      if (endText > endClause) {
        return null;
      }
      tIndex += 1;
    }
  });
  return intersections;
}

function getTextElementsArray(paragraphs) {
  const textElements = [];
  getField(paragraphs, ({r}) =>
    getField(r, ({t}) => {
      if (t && t["#text"]) {
        textElements.push(t);
      }
    }),
  );
  return textElements;
}

function getField(value, mapper) {
  if (_.isArray(value)) {
    return value.map(mapper);
  }
  if (_.isObject(value)) {
    return mapper(value, null);
  }
  return null;
}

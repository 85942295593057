import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";

import DocumentLogsTable from "./document_logs_table";

const useStyles = makeStyles({
  overlay: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
});

export default function DocumentLogsDialog({
  logs,
  clear,
  title,
  LogsComponent,
}) {
  const classes = useStyles();
  if (!logs) {
    return (
      <div className={classes.overlay}>
        <CircularProgress color="primary" size={60} />
      </div>
    );
  }
  const documentName =
    !LogsComponent && logs && logs.length ? logs[0].data.name : "";
  const Component = LogsComponent || DocumentLogsTable;
  return (
    <Dialog
      onClose={clear}
      aria-labelledby="document-logs"
      open={true}
      maxWidth="lg"
      fullWidth={true}
    >
      <DialogTitle id="document-logs-dialog-title">
        <div style={{textAlign: "center"}}>
          {title || `${documentName ? `${documentName} ` : ""}Logs`}
        </div>
      </DialogTitle>
      <DialogContent>
        <Component logs={logs} />
      </DialogContent>
    </Dialog>
  );
}

import React from "react";
import {get, omit} from "lodash";

import ExclamationIcon from "material-ui/svg-icons/notification/priority-high";
import InfoIcon from "material-ui/svg-icons/action/info";
import ExpandLess from "material-ui/svg-icons/navigation/expand-less";
import ExpandMore from "material-ui/svg-icons/navigation/expand-more";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import * as colors from "material-ui/styles/colors";

import IssueItem from "./issue_item";

import {
  FixedIcon,
  IgnoreIcon,
  IssueIcon,
  NoIssueIcon,
  WarningIcon,
} from "constants/icons";
import issueHeaderUtils from "utils/issues/issue_header_utils";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import isEmptyParent from "common/utils/issues/is_empty_parent";
import shouldIssueBeShownAsAlert from "utils/issues/should_issue_be_shown_as_alert";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import getEmptyParentColor from "utils/issues/get_empty_parent_color";
import checkGroupContainsProblem from "utils/issues/check_group_contains_problem";

import DocumentDetailContext from "common_components/context/document_detail_context";
import {generalIssueClasses} from "modules/documents/constants/issue_classes";
import {
  getIssueManualCorrections,
  getOverride,
} from "utils/manual_corrections_utils";
import UnreadIssueDot from "./icons/unread_issue_dot";
import globalOverrideKey from "common/utils/issues/global_override_key";

const styles = {
  itemGroupContainer: isFlipChecklistIcons => ({
    fontWeight: 500,
    // border: "1px solid red",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: isFlipChecklistIcons ? "0" : -1,
    marginBottom: isFlipChecklistIcons ? 0 : "10px",
    ...(isFlipChecklistIcons
      ? {}
      : {
          borderLeft: "1px solid #7b8187",
          borderRight: "1px solid #7b8187",
        }),
  }),
  groupHeading: isFlipChecklistIcons => ({
    fontWeight: 400,
    display: "flex",
    justifyContent: "space-between",
    ...(isFlipChecklistIcons
      ? {
          borderBottom: "1px solid #d1d1d1",
          marginBottom: 0,
        }
      : {
          borderTop: "1px solid #7b8187",
          borderBottom: "1px solid #7b8187",
          padding: "0 12px",
        }),
  }),
  issueList: {},
  nameIconContainer: isFlipChecklistIcons => ({
    display: "flex",
    flex: 1,
    wordBreak: "break-word",
    wordWrap: "break-word",
    marginLeft: isFlipChecklistIcons ? 2 : 16,
    flexDirection: "column",
  }),
  groupIconContainer: {
    alignSelf: "center",
    padding: "12px 0px",
    display: "flex",
  },
  groupName: isFlipChecklistIcons => ({
    fontWeight: isFlipChecklistIcons ? 600 : 400,
    display: "flex",
    alignItems: "center",
  }),
  expandLessMoreContainer: {
    display: "flex",
    alignItems: "center",
  },
  expandIconContainer: {
    width: "3.5rem",
    margin: "-1rem 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
  },
  openGroupIcon: isFlipChecklistIcons => ({
    height: "28px",
    width: "28px",
    color: "#a7abaf",
    fill: "#aaa",
    stroke: "#fff",
    marginLeft: isFlipChecklistIcons ? -5 : 0,
  }),
  issueCountContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    whiteSpace: "nowrap",
    flexShrink: 0,
    minWidth: 0,
    paddingRight: 8,
  },
  issueCount: {
    color: colors.grey500,
    fontWeight: 400,
  },
  redColor: "#ff0000",
  amberColor: "#ff8800",
};

function getIsGroupOpenedStateObject(
  group,
  documentTypeId,
  isOpenByDefault,
  user,
  checklistDisplay,
) {
  const triggeredCount = getTriggeredIssuesCount(group.item);
  const groupContainsProblem = checkGroupContainsProblem(group.item);
  return {
    isGroupOpened:
      isOpenByDefault ||
      (triggeredCount && documentTypeId === 4) ||
      group.name === "Unrecognised Clauses" ||
      (user.is_admin && groupContainsProblem) ||
      (checklistDisplay === "open_issue_groups" && documentTypeId !== 4),
  };
}

class IssueGroup extends React.Component {
  static contextType = DocumentDetailContext;
  constructor(props) {
    super(props);
    const {group, project, isOpenByDefault} = props;
    this.state = {
      ...getIsGroupOpenedStateObject(
        group,
        project.document_type_id,
        isOpenByDefault,
        props.user,
        project.checklist_display,
      ),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.expandAllIssueState !== prevProps.expandAllIssueState) {
      this.setState({isGroupOpened: this.props.expandAllIssueState});
    }
    if (this.props.isOpenByDefault !== prevProps.isOpenByDefault) {
      this.setState({
        isGroupOpened: this.state.isGroupOpened || this.props.isOpenByDefault,
      });
    }
  }

  render() {
    const {isGroupOpened} = this.state;
    const {
      editPlaybookMode,
      highlightIssueIds,
      isFlipChecklistIcons,
      project,
      displayHiddenIssues,
      showCompareIssues,
    } = this.props;
    const {zoom} = this.context;
    const group = this.addTriggeredInfoToGroup(
      this.props.group,
      project.resolve_issues,
    );

    const {
      triggeredIssueCount,
      comparisonDiffCount,
      hasTextChangesCount,
    } = group;

    const items = (group.item || []).filter(item =>
      displayHiddenIssues || (showCompareIssues && item.showForComparison)
        ? true
        : !item.shouldBeHidden,
    );
    const flatGroupIssues = getFlatGroupItems(items);
    const groupHasNonWarningAlertIssues = hasNonWarningAlertIssues(
      flatGroupIssues,
      this.props.currentIssuesetItem,
    );

    const isIssueInGroup = group.item.find(
      item =>
        generalIssueClasses.indexOf(item.issue_class_id) !== -1 &&
        (!item.shouldBeHidden ||
          (item.shouldBeHidden &&
            (displayHiddenIssues ||
              (showCompareIssues && item.showForComparison)))),
    );

    const GroupIcon = this.getGroupIcon(
      group,
      this.props.showIssuesInChecklist,
      isIssueInGroup,
      groupHasNonWarningAlertIssues,
    );
    if (items.length === 0) {
      return null;
    }
    const shouldBeHighlighted =
      highlightIssueIds &&
      highlightIssueIds.reduce(
        (acc, highlightIssueId) =>
          acc || items.find(({id}) => id === highlightIssueId),
        false,
      );

    const groupIcon = this.renderGroupIcon(
      GroupIcon,
      group,
      isIssueInGroup,
      groupHasNonWarningAlertIssues,
    );

    const filterItemsForIssueDot = items =>
      items.find(item => item.review_state === 1) ? 1 : 0;

    return (
      <div style={styles.itemGroupContainer(isFlipChecklistIcons)}>
        <div
          style={{
            ...styles.groupHeading(isFlipChecklistIcons),
            fontSize: getZoomedFontSize(17, "checklist", zoom),
          }}
          onClick={this.triggerGroupOpen}
        >
          <div
            style={{
              ...styles.expandLessMoreContainer,
              ...(isFlipChecklistIcons
                ? {}
                : {paddingRight: "12px", borderRight: "1px solid #7b8187"}),
            }}
          >
            {this.renderExpandLessMore()}
          </div>
          <div
            style={{
              ...styles.nameIconContainer(isFlipChecklistIcons),
              justifyContent:
                comparisonDiffCount > 0 || hasTextChangesCount > 0
                  ? "space-between"
                  : "center",
            }}
          >
            <div
              style={{
                ...styles.groupName(isFlipChecklistIcons),
                ...(!editPlaybookMode && {
                  color: this.getGroupTextColor(
                    group,
                    this.props.showIssuesInChecklist,
                    isIssueInGroup,
                  ),
                }),
                fontSize: getZoomedFontSize(14, "checklist", zoom),
                ...(shouldBeHighlighted ? {fontWeight: 600} : {}),
                marginTop:
                  comparisonDiffCount > 0 || hasTextChangesCount > 0 ? 14 : 0,
              }}
            >
              {this.props.reviewTrackingOn ? (
                <div style={{marginRight: "10px", float: "left"}}>
                  <UnreadIssueDot issueStatus={filterItemsForIssueDot(items)} />
                </div>
              ) : null}
              {group.name}
            </div>
            {this.renderComparisonCaption(
              comparisonDiffCount,
              hasTextChangesCount,
              zoom,
            )}
          </div>
          <div style={styles.issueCountContainer}>
            {!editPlaybookMode && triggeredIssueCount > 0 ? (
              <span
                style={{
                  ...styles.issueCount,
                  fontSize: getZoomedFontSize(14, "checklist", zoom),
                }}
              >
                {`${triggeredIssueCount} issue${
                  triggeredIssueCount > 1 ? "s" : ""
                }`}
              </span>
            ) : (
              <div />
            )}
          </div>
          <div style={styles.groupIconContainer}>{groupIcon}</div>
        </div>
        {isGroupOpened && (
          <div
            id={`${group.name.replace(/\s+/g, "_")}-issues`}
            style={styles.issueList}
          >
            {items.map(item => (
              <IssueItem
                key={item.id}
                user={this.props.user}
                project={this.props.project}
                document={this.props.document}
                issue={item}
                documentClauses={this.props.documentClauses}
                documentClauseparts={this.props.documentClauseparts}
                topicsById={this.props.topicsById}
                selectedIssueId={this.props.selectedIssueId}
                showIssueDetail={this.props.showIssueDetail}
                updateIssueDetail={this.props.updateIssueDetail}
                showIssuesInChecklist={this.props.showIssuesInChecklist}
                viewMode={this.props.viewMode}
                updateDocumentIssue={this.props.updateDocumentIssue}
                subissueData={item.subissueData}
                positiveReasonData={this.props.positiveReasonData}
                onIssueCheckboxCheck={this.props.onIssueCheckboxCheck}
                isOpenByDefault={this.props.isOpenByDefault}
                location={this.props.location}
                issueIdToHighlight={this.props.issueIdToHighlight}
                currentIssuesetItem={this.props.currentIssuesetItem}
                showIssue={this.props.showIssue}
                displayHiddenIssues={this.props.displayHiddenIssues}
                clientModeOn={this.props.clientModeOn}
                masterIssuesetAmount={this.props.masterIssuesetAmount}
                contractTypesById={this.props.contractTypesById}
                issuesById={this.props.issuesById}
                editPlaybookMode={this.props.editPlaybookMode}
                togglePlaybookState={this.props.togglePlaybookState}
                playbookState={this.props.playbookState}
                isDocumentDetailPageShown={this.props.isDocumentDetailPageShown}
                selectedReport={this.props.selectedReport}
                selectedReportId={this.props.selectedReportId}
                highlightIssueIds={highlightIssueIds}
                isFlipChecklistIcons={this.props.isFlipChecklistIcons}
                correctDocumentIssueManually={
                  this.props.correctDocumentIssueManually
                }
                showCompareIssues={showCompareIssues}
                showComparisonDetail={this.props.showComparisonDetail}
                canViewIssueErrors={this.props.canViewIssueErrors}
                reviewTrackingOn={this.props.reviewTrackingOn}
                preventMemo={this.props.preventMemo}
              />
            ))}
          </div>
        )}
      </div>
    );
  }

  renderExpandLessMore() {
    const {isFlipChecklistIcons} = this.props;
    const {isGroupOpened} = this.state;
    const style = styles.openGroupIcon(isFlipChecklistIcons);

    let Icon = isGroupOpened ? ExpandLess : ExpandMore;
    if (isFlipChecklistIcons) {
      Icon = isGroupOpened ? RemoveIcon : AddIcon;
    }
    return <Icon style={style} />;
  }

  renderGroupIcon(
    GroupIcon,
    group,
    isIssueInGroup,
    groupHasNonWarningAlertIssues,
  ) {
    if (this.props.editPlaybookMode || !GroupIcon) {
      return null;
    }

    const iconSize = getZoomedFontSize(24, "checklist", this.context.zoom);

    const showInfoOverlay =
      GroupIcon === NoIssueIcon &&
      this.props.project.roll_up_grey_state &&
      group.noteCount >= 1;

    const icon = (
      <GroupIcon
        style={{
          color: this.getGroupIconColor(
            group,
            this.props.showIssuesInChecklist,
            isIssueInGroup,
            groupHasNonWarningAlertIssues,
          ),
          width: iconSize,
          height: iconSize,
        }}
      />
    );
    if (!showInfoOverlay) {
      return icon;
    }
    return (
      <div style={{position: "relative"}}>
        {icon}
        <InfoIcon
          style={{
            color: "#aaa",
            position: "absolute",
            width: "12px",
            left: "14px",
            top: "10px",
          }}
        />
      </div>
    );
  }

  renderComparisonCaption(comparisonDiffCount, hasTextChangesCount, zoom) {
    let caption = "";
    if (comparisonDiffCount > 0) {
      caption = `${comparisonDiffCount} issue${
        comparisonDiffCount > 1 ? "s" : ""
      }`;
      if (hasTextChangesCount > 0) {
        caption = `${caption}, ${hasTextChangesCount} clause${
          hasTextChangesCount > 1 ? "s" : ""
        }`;
      }
      caption = `${caption} changed`;
    } else {
      if (hasTextChangesCount > 0) {
        caption = `${hasTextChangesCount} clause${
          hasTextChangesCount > 1 ? "s" : ""
        } changed`;
      }
    }

    if (caption) {
      return (
        <div
          style={{
            color: issueHeaderUtils.colors["blue1"],
            fontSize: getZoomedFontSize(9, "checklist", zoom),
            marginBottom: 4,
          }}
        >
          {caption}
        </div>
      );
    }
  }

  getGroupIcon(
    group,
    showIssuesInChecklist,
    isIssueClassInGroup,
    hasNonWarningAlertIssues,
  ) {
    // TODO: temporary demo hack - remove this
    if (this.isDemoDocument() && group.name.indexOf("Term") === 0) {
      return NoIssueIcon;
    }

    const {
      ignoredAlertCount,
      triggeredIssueCount,
      triggeredIssueCountEffective,
      resolvedCount,
      ignoredCount,
    } = group;
    if (triggeredIssueCount > 0 && triggeredIssueCountEffective === 0) {
      // group has alertIssues and each of them is resolved/ignored
      if (ignoredAlertCount === triggeredIssueCount) {
        return IgnoreIcon;
      }
      return FixedIcon;
    }

    if (group.name === "Unrecognised Clauses") {
      return ExclamationIcon;
    }
    if (showIssuesInChecklist) {
      return null;
    }
    if (!isIssueClassInGroup) {
      return InfoIcon;
    }

    return triggeredIssueCountEffective === 0
      ? resolvedCount
        ? FixedIcon
        : ignoredCount
        ? IgnoreIcon
        : NoIssueIcon
      : hasNonWarningAlertIssues
      ? IssueIcon
      : WarningIcon;
  }

  isDemoDocument() {
    const demoDocs = {
      14927: true,
      14949: true,
      14861: true,
    };
    return this.props.document && demoDocs[this.props.document.id];
  }

  getGroupIconColor(
    group,
    showIssuesInChecklist,
    isIssueInGroup,
    hasNonWarningAlertIssues,
  ) {
    // TODO: temporary demo hack - remove this
    if (this.isDemoDocument() && group.name.indexOf("Term") === 0) {
      return "#00c853";
    }

    const ignoredColor = "#9e9e9e";
    const resolvedColor = "#42a5f5";
    const noIssueColor = "#00c853";

    const {
      ignoredAlertCount,
      triggeredIssueCount,
      triggeredIssueCountEffective,
      resolvedCount,
      ignoredCount,
    } = group;
    if (triggeredIssueCount > 0 && triggeredIssueCountEffective === 0) {
      // group has alertIssues and each of them is resolved/ignored
      if (ignoredAlertCount === triggeredIssueCount) {
        return ignoredColor; // grey, ignored
      }
      return resolvedColor; // blue, resolved
    }

    if (group.name === "Unrecognised Clauses") {
      return styles.redColor;
    }
    if (showIssuesInChecklist) {
      return colors.grey800;
    }

    if (!isIssueInGroup) {
      return colors.grey500;
    }
    return group.triggeredIssueCountEffective === 0
      ? resolvedCount
        ? resolvedColor
        : ignoredCount
        ? ignoredColor
        : noIssueColor
      : hasNonWarningAlertIssues
      ? styles.redColor
      : styles.amberColor;
  }

  getGroupTextColor() {
    return this.props.isFlipChecklistIcons ? colors.grey800 : colors.white;
  }

  triggerGroupOpen = () => {
    const issueItemsDiv = document.getElementById(
      `${this.props.group.name}-issues`,
    );
    if (issueItemsDiv && this.props.updateBottomPaddingOffset) {
      // When closing items on checklist when scrolled to bottom,
      // prevent checklist height from changing (items jump around)
      const issueItemsDivHeight = issueItemsDiv.offsetHeight;
      const checklistMenuDiv = document.getElementById("checklistMenu");
      const scrollOffset = checklistMenuDiv.scrollTop;
      const checklistMenuHeight = checklistMenuDiv.offsetHeight;
      const checklistDiv = document.getElementById("checklistBody");
      const checklistDivScrollHeight = checklistDiv.scrollHeight;
      const scrollToBottomDist =
        checklistDivScrollHeight - scrollOffset - checklistMenuHeight;
      if (scrollToBottomDist < issueItemsDivHeight) {
        const newBottomOffset =
          this.props.bottomPaddingOffset + issueItemsDiv.offsetHeight;
        this.props.updateBottomPaddingOffset(newBottomOffset);
      }
    }
    this.setState(prevState => ({isGroupOpened: !prevState.isGroupOpened}));
  };

  addTriggeredInfoToGroup(group, projectResolveIssues) {
    const info = {
      triggeredIssueCount: 0, // alertCount
      // alertCountEffective - the number of triggered issues that should participate
      // in calculation of group icon. (resolved/ignored issues are
      // excluded from this calculation)
      triggeredIssueCountEffective: 0, // alertCountEffective
      resolvedAlertCount: 0,
      ignoredAlertCount: 0,
      resolvedCount: 0,
      ignoredCount: 0,
      noteCount: 0,
    };
    const issueset = this.props.currentIssuesetItem;
    group.item.forEach(issue => {
      if (issue.shouldBeHidden) {
        return;
      }

      const overrides = getOverride(issue, issueset);
      const isResolved = isEmptyParent(issue)
        ? issueHeaderUtils.isEmptyParentResolved(issue, issueset)
        : overrides?.resolved_state === 1;

      const isIgnored = isEmptyParent(issue)
        ? issueHeaderUtils.isEmptyParentIgnored(issue, issueset)
        : overrides?.resolved_state === 0;

      if (projectResolveIssues) {
        if (isResolved) {
          info.resolvedCount += 1;
        }
        if (isIgnored) {
          info.ignoredCount += 1;
        }
      }

      if (shouldIssueBeShownAsAlert(issue, issueset)) {
        info.triggeredIssueCount += 1;

        if (projectResolveIssues) {
          if (isResolved) {
            info.resolvedAlertCount += 1;
          } else if (isIgnored) {
            info.ignoredAlertCount += 1;
          } else {
            info.triggeredIssueCountEffective += 1;
          }
        } else {
          info.triggeredIssueCountEffective += 1;
        }
      } else if (
        issueHeaderUtils.getIconName(
          issue,
          issueset,
          false,
          this.props.project,
        ) === "GuidanceIcon"
      ) {
        info.noteCount += 1;
      }
    });

    return {
      ...group,
      ...info,
    };
  }
}

function hasNonWarningAlertIssues(issues, issueset) {
  const selectedReport = globalOverrideKey;
  return Boolean(
    issues.find(issue => {
      const subissues = get(issue, "subissueData.subissues");
      const isEmptyParentValue = isEmptyParent(issue);
      const issueManualCorrections = getIssueManualCorrections(
        issue,
        issueset,
        selectedReport,
      );
      if (subissues && subissues.length > 0 && !isEmptyParentValue) {
        return hasNonWarningAlertIssues(subissues, issueset);
      } else if (isEmptyParentValue) {
        if (issueManualCorrections.alert_color) {
          return issueManualCorrections.alert_color === "red";
        }
        return (
          getEmptyParentColor(issue, issueset) === "red" &&
          issue.issue_class_id !== 3
        );
      }
      const manuallyCorrectedColor = issueManualCorrections.alert_color;
      const {issue_class_id: issueClassId} = issue;
      if (issueClassId === 1) {
        return (
          manuallyCorrectedColor !== "amber" &&
          shouldIssueBeShownAsAlert(issue, issueset)
        );
      } else if (issueClassId === 3) {
        return manuallyCorrectedColor === "red";
      }
      return false;
    }),
  );
}

function getFlatGroupItems(issues) {
  return issues.reduce((result, issue) => {
    const subissues = get(issue, "subissueData.subissues");
    if (subissues && issue.issue_type !== "EMPTY_PARENT") {
      result.push(omit(issue, "subissueData"));
      return result.concat(subissues);
    }
    result.push(issue);
    return result;
  }, []);
}

/* eslint-disable */
function getTriggeredIssuesCount(issues) {
  return issues.reduce((issuesCount, issue) => {
    if (!issue.shouldBeHidden && isIssueAlert(issue)) {
      issuesCount++;
    }
    return issuesCount;
  }, 0);
}

// TODO:
// group icon should be calculated explicitly with 1 round of children issues
// iteration. Therefore we need to have stats that counts various children
// attributes
// function getGroupStats(issues = [], selectedReport) {
//   const stats = {
//     resolved: 0,
//     ignored: 0,
//     shouldBeShownAsAlert: 0,
//     alert: 0,
//     warningType: 0,
//     issueType: 0,
//     noIssueType: 0,
//     noteClass: 0,
//     total: 0,
//   };

//   issues.forEach(issue => {
//     if (issue.shouldBeHidden) {
//       return;
//     }

//     const issueManualCorrections = getIssueManualCorrections(
//       issue,
//       selectedReport,
//     );
//     const {new_state: manuallyCorrectedState, alert_color: manuallyCorrectedColor} = issueManualCorrections;

//     const isAlert = isIssueAlert(issue);
//     if (isAlert) {
//       stats.alert++;
//     }
//     const shouldBeShownAsAlert = shouldIssueBeShownAsAlert(issue);
//     if (shouldBeShownAsAlert) {
//       stats.shouldBeShownAsAlert++;
//     }

//     if (isEmptyParent(issue)) {
//       if (issueHeaderUtils.isEmptyParentResolved(issue, selectedReport)) {
//         stats.resolved++;
//       } else if (issueHeaderUtils.isEmptyParentIgnored(issue, selectedReport)) {
//         stats.ignored++;
//       }

//       if (manuallyCorrectedState || manuallyCorrectedColor) {
//         if (manuallyCorrectedState === "alert" && manuallyCorrectedColor === "red") {
//           stats.issueType++;
//         } else if (manuallyCorrectedState === "alert" && manuallyCorrectedColor === "amber") {
//           stats.warningType++;
//         } else if (manuallyCorrectedState === "non-alert" && manuallyCorrectedColor === "green") {
//           stats.noIssueType++;
//         }
//       } else {
//         // should process subissue stats data here ??? OR emptyParent issue class???
//       }
//     } else {
//       if (isAlert) {
//         const {resolved_state: resolvedState} = issue;
//         if (resolvedState === 1) {
//           stats.resolved++;
//         } else if (resolvedState === 0) {
//           stats.ignored++;
//         }
//       }

//       if (manuallyCorrectedState || manuallyCorrectedColor) {
//         if (manuallyCorrectedState === "alert" && manuallyCorrectedColor === "red") {
//           stats.issueType++;
//         } else if (manuallyCorrectedState === "alert" && manuallyCorrectedColor === "amber") {
//           stats.warningType++;
//         } else if (manuallyCorrectedState === "non-alert" && manuallyCorrectedColor === "green") {
//           stats.noIssueType++;
//         }
//       } else {
//         const {issue_class_id: issueClass} = issue;
//         if (issueClass === 1) {
//           stats.issueType++;
//         } else if (issueClass === 2) {
//           stats.noteClass++;
//         } else if (issueClass === 3) {
//           stats.warningType++;
//         }
//       }
//       // if manually corrected then use manual correction instead of class

//     }

//     stats.total++;
//   })
//   return stats;
// }

export default IssueGroup;

import _ from "underscore";

export default function path(obj, fields) {
  if (_.isArray(fields)) {
    return fields.reduce((acc, field) => acc && acc[field], obj);
  }
  if (obj && _.isString(fields)) {
    return obj[fields];
  }
  return obj;
}

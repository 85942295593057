function findSearchInstances(text, search, useSearchAsRegex = false) {
  if (!search) {
    return [];
  }
  const result = [];

  if (useSearchAsRegex) {
    const searchRegex = new RegExp(search, "g");
    let match;
    while ((match = searchRegex.exec(text)) !== null) {
      result.push({
        start: match.index,
        end: match.index + match[0].length - 1,
        text: match[0],
      });
    }
  } else {
    const searchLength = search.length;
    const searchUpToIndex = text.length - searchLength + 1;
    let startIndex = 0;
    while (startIndex < searchUpToIndex) {
      const index = text.indexOf(search, startIndex);
      if (index === -1) {
        break;
      }
      result.push({start: index, end: index + searchLength - 1, text: search});
      startIndex = index + searchLength;
    }
  }
  return result;
}

export default findSearchInstances;

import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";
import "./precendent_language.css";

import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ItemContainer from "../../helper_components/item_container";
import PrecedentLanguageDocumentList from "./precedent_language_document_list";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getIssueColorValue from "utils/issues/get_issue_color_value";
import DocumentDetailContext from "common_components/context/document_detail_context";
import usePrevious from "utils/hooks/use_previous";
import getCapitalizedWordsFromText from "utils/text/get_capitalized_words_from_text";
import getZoomedFontSize from "utils/get_zoomed_font_size";

import appHost from "app_host";

const isHybrid = issue =>
  "subissueData" in issue &&
  issue.subissueData.subissues.every(
    child =>
      child.issue_class_id === 4 || child.show_if_triggered_only === "never",
  );

function PrecedentLanguage(props) {
  const {issue} = props;
  const isEmptyParentOpened = props.issue.issue_type === "EMPTY_PARENT";

  const [selectedIssue, updateSelectedIssue] = useState(
    isEmptyParentOpened ? {} : props.issue,
  );

  useEffect(() => {
    updateSelectedIssue(isEmptyParentOpened ? {} : props.issue);
  }, [issue.id]);

  const containerProps = {
    title: "Precedent Language",
    expandable: true,
    onExpandMenu: props.onOpen,
    isMenuExpanded: props.isOpen,
    containerStyles: {flex: "1 0 auto"},
    bodyStyles: {paddingTop: 0},
  };

  if (selectedIssue.issue_type === "EMPTY_PARENT") {
    return (
      <ItemContainer {...containerProps}>
        <div style={{fontStyle: "italic", textAlign: "center"}}>
          Click on a child issue to see precedents
        </div>
      </ItemContainer>
    );
  }

  const [precedentTerms, updatePrecedentTerms] = useState(
    props.document.linked_precedent_terms || {},
  );

  const prevPropsPrecedentTerms = usePrevious(
    props.document.linked_precedent_terms,
  );

  useEffect(() => {
    if (
      props.document.linked_precedent_terms &&
      !_.isEqual(props.document.linked_precedent_terms, prevPropsPrecedentTerms)
    ) {
      updatePrecedentTerms(props.document.linked_precedent_terms);
    }
  }, [props.document.linked_precedent_terms]);

  function onPrecedentTermUpdate(termsObj) {
    let newPrecedentTerms = {...precedentTerms} || {};
    Object.keys(termsObj).forEach(term => {
      const prevTerm = precedentTerms[term];
      const newTerm = {...prevTerm, ...(termsObj[term] || {})};

      Object.keys(newTerm).forEach(key => {
        if (newTerm[key] === undefined) {
          delete newTerm[key];
        }
      });

      newPrecedentTerms = {
        ...newPrecedentTerms,
        [term]: newTerm,
      };
    });

    props.updateDocument({linked_precedent_terms: newPrecedentTerms});
    updatePrecedentTerms(newPrecedentTerms);
  }

  const context = useContext(DocumentDetailContext);
  const prevIssueId = usePrevious(selectedIssue.id);

  async function refetchResults() {
    await props.searchByIssueClear();

    props.findIssueDocuments(
      isHybrid(issue)
        ? issue.subissueData.subissues.map(({id}) => id)
        : [selectedIssue.id],
    );
  }

  /* eslint-disable no-inner-declarations */
  useEffect(() => {
    if (
      isHybrid(issue) ||
      (selectedIssue.id && prevIssueId !== selectedIssue.id)
    ) {
      refetchResults();
    } else if (prevIssueId && !selectedIssue.id) {
      async function clearSearchResults() {
        await props.searchByIssueClear();
      }
      clearSearchResults();
    }
  }, [selectedIssue.id]);

  // if the component is mounted and selected issueset changes we
  // refetch data
  const currentIssuesetPrev = usePrevious(props.currentIssueset);
  useEffect(() => {
    if (
      currentIssuesetPrev &&
      props.currentIssueset &&
      props.currentIssueset !== currentIssuesetPrev
    ) {
      refetchResults();
    }
  }, [props.currentIssueset]);

  function renderProgress() {
    return (
      <div
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "1rem 0rem",
        }}
      >
        <CircularProgress size={20} />
      </div>
    );
  }

  function renderPrecedentLanguage() {
    if (
      // props.searchByIssueResults && (!isEmptyParentOpened || selectedIssue.id)
      // If we DON'T either have search results OR have an empty parent opened (and no selected issue)
      !(
        props.searchByIssueResults ||
        (isEmptyParentOpened && !isHybrid(issue) && !selectedIssue.id)
      )
    ) {
      return renderProgress();
    }
    const {searchByIssueResults, document} = props;
    const resultProjects = constructItemsData(
      props.organisationId,
      props.project.id,
      document.id,
      props.selectedReport,
      selectedIssue.issue_class_id,
      searchByIssueResults,
      props.appType,
    );
    if (
      !resultProjects ||
      !resultProjects.length ||
      !resultProjects.find(project => (project.documents?.length ?? 0) > 0)
    ) {
      return (
        <div
          style={{
            margin: "10px 0px",
            textAlign: "center",
            fontStyle: "italic",
            fontSize: getZoomedFontSize(14, "checklist", context.zoom),
          }}
        >
          Precedent Language Not Available
        </div>
      );
    }
    const {
      contract_type: {id: documentContractTypeId},
      parties,
    } = document;

    // document reference indicator logic
    const referenceIndicatorGroupName = "Reference Indicator";
    const propsDefinitionGroups = props.definitionGroups || [];

    const documentDefinitionGroups = propsDefinitionGroups.filter(
      dg =>
        dg.contract_types.includes(documentContractTypeId) &&
        dg.name !== referenceIndicatorGroupName,
    );

    const referenceIndicatorGroup =
      propsDefinitionGroups.find(
        dg => dg.name === referenceIndicatorGroupName,
      ) || {};

    const documents = _.chain(resultProjects)
      .map(project =>
        project.documents.map(document => ({
          ...document,
          holds_clause_templates: project.holds_clause_templates,
          project_id: project.id,
        })),
      )
      .flatten()
      .sortBy(item => !item.holds_clause_templates)
      .value();

    return (
      <PrecedentLanguageDocumentList
        showIntelligentDefinitions={props.showIntelligentDefinitions}
        items={documents}
        hideDocumentRelatedItems={props.hideDocumentRelatedItems}
        definitionGroups={documentDefinitionGroups}
        documentDefinitions={props.documentDefinitions}
        documentCapitalizedWords={getDocumentCapitalizedWords(
          props.documentClauseparts,
        )}
        documentParties={parties}
        documentReferenceIndicator={document.reference_indicator}
        referenceIndicatorGroup={referenceIndicatorGroup}
        precedentTerms={precedentTerms}
        onPrecedentTermUpdate={onPrecedentTermUpdate}
        isFlipChecklistIcons={props.isFlipChecklistIcons}
        updateOtherDocument={props.updateOtherDocument}
      />
    );
  }

  /* eslint-disable no-inner-declarations */
  function renderBody() {
    if (isEmptyParentOpened) {
      const subissues = _.get(issue, "subissueData.subissues", []);
      if (subissues.length === 0) {
        return (
          <div
            style={{
              margin: "10px 0px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            Selected issue has no subissues present
          </div>
        );
      }

      function onItemChange(event) {
        const subissueId = event.target.value;
        if (subissueId) {
          updateSelectedIssue(
            subissues.find(subissue => subissue.id === subissueId) || {},
          );
        }
      }

      return (
        <div>
          {!isHybrid(issue) && (
            <Select
              value={selectedIssue.id || "no_value"}
              onChange={onItemChange}
              style={{width: "100%"}}
            >
              <MenuItem value={"no_value"}>
                <em>Select Item</em>
              </MenuItem>
              {subissues.map(subissue => (
                <MenuItem key={subissue.id} value={subissue.id}>
                  {clearName(subissue.name)}
                </MenuItem>
              ))}
            </Select>
          )}

          {!selectedIssue.id && !isHybrid(issue) ? (
            <div
              style={{
                margin: "10px 0px",
                fontStyle: "italic",
                textAlign: "center",
              }}
            >
              Please select issue to begin
            </div>
          ) : (
            renderPrecedentLanguage()
          )}
        </div>
      );
    }
    return renderPrecedentLanguage();
  }

  return <ItemContainer {...containerProps}>{renderBody()}</ItemContainer>;
}

function constructItemsData(
  organisationId,
  projectId,
  excludedDocumentId,
  selectedReport,
  issueClassId,
  projects,
  appType,
) {
  const projectsToUse = (projects || []).filter(
    project => project.id === projectId || project.holds_clause_templates,
  );

  if (!projectsToUse.length) {
    return null;
  }
  const result = projects.map(foundProject => ({
    ...foundProject,
    documents: foundProject.documents.reduce((accum, document) => {
      const {clauseparts, document_issue: documentIssue} = document;
      if (
        !clauseparts ||
        clauseparts.length === 0 ||
        !documentIssue ||
        (excludedDocumentId && excludedDocumentId === document.id)
      ) {
        return accum;
      }
      const colorName = getIssueColor(
        documentIssue,
        issueClassId,
        selectedReport,
      );
      const sortedClauseparts = _.sortBy(document.clauseparts, "id");
      const documentLink = `${
        appType === "wordTaskpane" ? appHost : ""
      }/organisation/${organisationId}/project/${foundProject.id}/document/${
        document.id
      }/detail?clausepart_id=${sortedClauseparts[0].id}`;

      const newDocument = {
        ..._.omit(document, "document_issue"),
        clauseparts: sortedClauseparts,
        documentLink,
        colorName,
        color: getIssueColorValue(colorName),
      };
      accum.push(newDocument);
      return accum;
    }, []),
  }));
  return result;
}

function getDocumentCapitalizedWords(clauseparts) {
  const capitalizedWordsFreq = clauseparts.reduce((accum, clausepart) => {
    const capitalizedWords = getCapitalizedWordsFromText(clausepart.text);
    capitalizedWords.forEach(term => {
      if (accum[term]) {
        accum[term] += 1;
      } else {
        accum[term] = 1;
      }
    });
    return accum;
  }, {});

  return _.chain(Object.keys(capitalizedWordsFreq))
    .map(item => ({label: item, freq: capitalizedWordsFreq[item]}))
    .sortBy("freq")
    .reverse()
    .map(item => item.label)
    .value();
}

function getIssueColor(issue, issueClassId, selectedReport) {
  const manualCorrections = selectedReport
    ? _.get(issue, `manual_corrections.${selectedReport}`, {})
    : {};

  if (manualCorrections.alert_color) {
    return manualCorrections.alert_color;
  }

  const isAlert = manualCorrections.new_state
    ? manualCorrections.new_state === "alert"
    : isIssueAlert(issue);

  if (issueClassId === 3 && isAlert) {
    return "amber";
  } else if (issueClassId === 2) {
    return "grey";
  }
  return isAlert ? "red" : "green";
}

function clearName(name) {
  const nameArr = name.split("/");
  nameArr.shift();
  return nameArr.join("/");
}

export default React.memo(PrecedentLanguage, (props, nextProps) => {
  if (
    !_.isEqual(props.searchByIssueResults, nextProps.searchByIssueResults) ||
    (props.issue && nextProps.issue && props.issue.id !== nextProps.issue.id)
  ) {
    return false;
  }

  if (props.shouldShrinkCounter !== nextProps.shouldShrinkCounter) {
    // don't re-render/update
    return true;
  }
});

import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import {DialogContent} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";

const styles = {
  title: {
    display: "flex",
    justifyContent: "center",
  },
  contentText: {
    textAlign: "center",
    textOverflow: "ellipsis",
  },
  btnBlock: {
    margin: "10px 0px",
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
};

const DeleteModal = ({isOpen, handleClose, handleDelete, text}) => {
  const deleteValue = () => {
    handleDelete();
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle style={styles.title}>
        Are you sure you want to delete this value?
      </DialogTitle>
      {text && (
        <DialogContent>
          <DialogContentText style={styles.contentText}>
            {text}
          </DialogContentText>
        </DialogContent>
      )}
      <div style={styles.btnBlock}>
        <Button onClick={deleteValue} variant="outlined" color="primary">
          Yes
        </Button>
        <Button onClick={handleClose} variant="outlined" color="primary">
          No
        </Button>
      </div>
    </Dialog>
  );
};

export default DeleteModal;

import React from "react";
import * as colors from "@material-ui/core/colors";

import ItemContainer from "../helper_components/item_container";

import Markdown from "common_components/markdown";

const styles = {
  bodyContainer: {
    cursor: "pointer",
    textAlign: "justify",
    color: colors.grey[600],
    fontWeight: 400,
  },
};

function AboutDescription(props) {
  const [isExpanded, updateIsExpanded] = React.useState(true);
  function triggerIsExpanded() {
    updateIsExpanded(!isExpanded);
  }
  const {aboutDescription, headerStyles = {}, containerStyles = {}} = props;
  if (!aboutDescription || !props.show_how_issue_works) {
    return null;
  }
  return (
    <ItemContainer
      title="How This Issue Works"
      expandable={true}
      onExpandMenu={props.onOpen}
      isMenuExpanded={props.isOpen}
      headerStyles={headerStyles}
      containerStyles={containerStyles}
      bodyStyles={styles.bodyContainer}
      bodyClickHandler={triggerIsExpanded}
      useFlippedInnerBodyStyles={true}
    >
      <div data-testid="about_description">
        <Markdown>{aboutDescription}</Markdown>
      </div>
    </ItemContainer>
  );
}

export default AboutDescription;

import _ from "underscore";
import React from "react";

import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import ReactSelectLabel from "common_components/react_select_label";
import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";

import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

function initialise(state) {
  return {
    topic_id: null,
    parameter_id: null,
    max: null,
    min: null,
    unit: "month",
    ..._.pick(state, "topic_id", "parameter_id", "max", "min", "unit"),
  };
}

function validate(issue) {
  if (!(issue.rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  if (!(issue.rules.parameter_id > 0)) {
    return {rulesError: {parameter_id: "You must set a parameter id"}};
  }
  if (!(issue.rules.max > 0 || issue.rules.min > 0)) {
    return {
      rulesError: {
        max: "You must set a maximum or minimum duration",
        min: "You must set a maximum or minimum duration",
      },
    };
  }
  if (issue.rules.max && issue.rules.max < issue.rules.min) {
    return {
      rulesError: {
        max: "The maximum should be more than the minimum",
      },
    };
  }
  if (!issue.rules.unit) {
    return {
      rulesError: {
        unit: "You must set a unit",
      },
    };
  }
  return null;
}

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      rulesError,
      topics,
      isNonOverridableFieldsDisabled,
      overridableFieldsStyle,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    return (
      <div>
        <div>
          <ReactSelectLabel>Topic</ReactSelectLabel>
          <Select
            className="topic"
            multi={false}
            value={rules.topic_id}
            options={topics
              .filter(topic =>
                topic.parameters.find(
                  parameter => parameter.parameter_type === "duration",
                ),
              )
              .map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
            onChange={this.updateTopic}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getTopicLink(this.props.organisationId, rules.topic_id)}
            shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
          />
          {renderError(this.props.rulesError, "topic_id")}
        </div>
        <div>
          <ReactSelectLabel>Parameter</ReactSelectLabel>
          <Select
            className="parameter"
            multi={false}
            value={rules.parameter_id}
            options={
              currentTopic &&
              currentTopic.parameters
                .filter(parameter => parameter.parameter_type === "duration")
                .map(parameter => ({
                  label: parameter.name,
                  value: parameter.id,
                }))
            }
            onChange={this.updateParameter}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getParameterLink(
              this.props.organisationId,
              rules.topic_id,
              rules.parameter_id,
            )}
          />
          {renderError(this.props.rulesError, "parameter_id")}
        </div>
        <div>
          <TextField
            type="number"
            className="min"
            value={rules.min || ""}
            onChange={this.updateMin}
            errorText={rulesError && rulesError.min}
            style={{width: "100%", ...overridableFieldsStyle}}
            name="min"
            floatingLabelText="Minimum duration"
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
        </div>
        <div>
          <TextField
            type="number"
            className="max"
            value={rules.max || ""}
            onChange={this.updateMax}
            errorText={rulesError && rulesError.max}
            style={{width: "100%", ...overridableFieldsStyle}}
            name="max"
            floatingLabelText="Maximum duration"
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
        </div>
        <div>
          <SelectField
            value={rules.unit}
            onChange={this.updateUnit}
            errorText={rulesError && rulesError.unit}
            className="unit"
            style={{width: "20em", ...overridableFieldsStyle}}
            floatingLabelText="Unit"
            disabled={isNonOverridableFieldsDisabled || disabled}
          >
            <MenuItem value="second" primaryText={"Seconds"} />
            <MenuItem value="minute" primaryText={"Minutes"} />
            <MenuItem value="hour" primaryText={"Hours"} />
            <MenuItem value="day" primaryText={"Days"} />
            <MenuItem value="month" primaryText={"Months"} />
            <MenuItem value="year" primaryText={"Years"} />
          </SelectField>
        </div>
      </div>
    );
  }

  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
      parameter_id: null,
    });
  };
  updateParameter = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_id: value.value,
    });
  };
  updateMin = event => {
    const {editLevel, rules, onChange} = this.props;
    const value = event.target.value !== "" ? event.target.value : null;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      min: value,
    });
  };
  updateMax = event => {
    const {editLevel, rules, onChange} = this.props;
    const value = event.target.value !== "" ? event.target.value : null;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      max: value,
    });
  };
  updateUnit = (event, index, value) => {
    const {editLevel, rules, onChange} = this.props;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      unit: value,
    });
  };
  /* eslint-enable no-invalid-this */
}

export default {
  initialise,
  validate,
  component: Component,
};

import React from "react";

import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import DeleteIcon from "material-ui/svg-icons/action/delete";
import * as colors from "material-ui/styles/colors";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  roleContainer: {
    width: "90%",
    margin: "0 0.5rem",
    textAlign: "left",
  },
  selectorLabel: {
    left: "10px",
  },
  deleteIcon: {
    cursor: "pointer",
    color: colors.grey700,
  },
};

class ValueEditor extends React.Component {
  render() {
    const {value} = this.props;
    return (
      <div style={styles.container}>
        <SelectField
          floatingLabelText="Role"
          value={value.role_id}
          onChange={this.onValueChange}
          autoWidth={true}
          style={styles.roleContainer}
          floatingLabelStyle={styles.selectorLabel}
        >
          {this.props.roles.map(role => (
            <MenuItem
              key={role.role_id}
              value={role.role_id}
              primaryText={`${role.role_name}${
                value.role_id === role.role_id
                  ? ` - ${value.value === "<BLANK>" ? role.term : role.value}`
                  : ` - ${role.term ? role.term : role.name}`
              }${role.isUnavailable ? " (Not set on document)" : ""}`}
            />
          ))}
        </SelectField>
        <DeleteIcon
          onMouseDown={this.onDeleteValue}
          style={styles.deleteIcon}
        />
      </div>
    );
  }

  onValueChange = (e, i, value) => {
    const role = (this.props.roles || []).find(role => role.role_id === value);
    if (role) {
      this.props.onUpdateValue(
        {
          role_id: value,
          role_name: role.role_name,
          value: role.isUnavailable ? "" : role.name || role.term,
          party_name: role.name,
          party_term: role.term,
        },
        this.props.valueIndex,
      );
    }
  };

  onDeleteValue = this.props.onDeleteValue(this.props.valueIndex);
}

export default ValueEditor;

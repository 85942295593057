import _ from "underscore";
import React from "react";
import {cloneDeep} from "lodash";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Checkbox from "@material-ui/core/Checkbox";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as colors from "@material-ui/core/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReplayIcon from "@material-ui/icons/Replay";

import TopicparameterTextValueDiff from "utils/topicparameters/editor/topicparameter_text_value_diff";
import getUpdatedColor from "utils/topicparameters/editor/get_updated_color";
import shouldHighlightRegexWithSpaces from "utils/should_highlight_regex_with_spaces";
import isBadRegex from "utils/bad_regexes";
import DeleteModal from "routes/topic_analysis/components/delete_modal";

const styles = {
  index: {
    width: "1.5rem",
    lineHeight: "32px",
    marginRight: "0.5rem",
    textAlign: "right",
    alignSelf: "flex-end",
    fontSize: "16px",
  },
  downArrow: {
    height: "28px",
    width: "28px",
    cursor: "pointer",
    color: "rgb(158, 158, 158)",
    transition: "none",
  },
  matches: {
    marginLeft: "auto",
    marginRight: "1.5rem",
    fontSize: 14,
  },
};

function initialise() {
  return {
    values: [],
    display_values: true,
    match_first_only: true,
  };
}

function validate(value) {
  return (
    value.values &&
    value.values.length > 0 &&
    value.values.every(
      val =>
        val.value &&
        val.value.length > 0 &&
        val.pattern &&
        val.pattern.filter(item => item).length > 0 &&
        !val.pattern.find(regex => !isValidRegex(regex)),
    )
  );
}

function isValidRegex(regex) {
  try {
    new RegExp(regex);
  } catch {
    return false;
  }
  const badRegex = isBadRegex(regex);
  if (badRegex) {
    // eslint-disable-next-line no-console
    console.log(
      "Regex is invalid because it had a potential problem in it - Regex:",
      regex,
      "Problem: matches",
      badRegex,
    );
    return false;
  }
  return true;
}

class Renderer extends React.Component {
  state = {
    hoveredItemIndex: null,
    deleteDialogIsOpen: false,
    deleteValues: {},
  };

  render() {
    const value = this.props.diffValue || this.props.value;
    return (
      <Card>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CardHeader title="Parameter Values" />
        </div>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={value.match_first_only}
                onChange={this.onMatchFirstOnlySwitch}
                color="primary"
              />
            }
            label="Match First Only"
            style={{
              marginLeft: "0rem",
              paddingRight: 2,
              backgroundColor: getUpdatedColor(
                null,
                value.live_match_first_only,
                value.match_first_only,
              ),
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={
                  value.display_values === undefined
                    ? false
                    : value.display_values
                }
                onChange={this.updateDisplayValues}
                color="primary"
              />
            }
            style={{
              width: 162,
              backgroundColor: getUpdatedColor(
                null,
                value.live_display_values,
                value.display_values,
              ),
            }}
            label="Display values"
          />
        </div>
        <CardContent>{this.renderValueList()}</CardContent>
        <CardActions>
          <Button onClick={this.addValue} color="secondary">
            Add Another
          </Button>
        </CardActions>
        <DeleteModal
          isOpen={this.state.deleteDialogIsOpen}
          handleClose={this.handleClose}
          handleDelete={this.deleteValue(
            this.state.deleteValues.index,
            this.state.deleteValues.realIndex,
          )}
        />
      </Card>
    );
  }

  renderValueList() {
    const values = (this.props.diffValue || this.props.value).values;
    let realIndex = -1;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
        className="parameter-values"
      >
        <div style={styles.matches}>
          <div>matches/</div>
          <div>applications</div>
        </div>
        {values.map((value, index) => {
          realIndex += 1;
          if (value.type && value.type === "deleted") {
            realIndex -= 1;
          }
          return this.renderValue(
            value,
            index,
            values.length - 1,
            realIndex < 0 ? 0 : realIndex,
          );
        })}
      </div>
    );
  }

  renderValue(value, index, maxIndex, realIndex) {
    const {isDevModeOn} = this.props;
    const {
      type: diffType,
      value: valueValue,
      live_value: liveValueValue,
      pattern,
      live_pattern: livePattern,
    } = value;
    const regexValue = (pattern && pattern[0]) || "";
    const liveRegexValue = (livePattern && livePattern[0]) || "";
    const isValueInvalid = (() => {
      try {
        new RegExp(regexValue);
      } catch {
        return "Invalid regex";
      }
      const badRegex = isBadRegex(regexValue);
      if (badRegex) {
        // eslint-disable-next-line no-console
        console.log(
          "Regex is invalid because it had a potential problem in it - Regex:",
          regexValue,
          "Problem: matches",
          badRegex,
        );
        return `Matches bad regex ${badRegex.toString()}`;
      }
      return null;
    })();

    const selectRegex = () => this.props.onRegexSelect(regexValue);
    const isCurrentRegexSelected = regexValue === this.props.selectedRegex;
    const isItemHovered = index === this.state.hoveredItemIndex;
    return (
      <div
        className="parameter-value"
        key={index}
        style={{
          display: "flex",
          alignItems: "center",
          opacity:
            !this.props.selectedRegex || isCurrentRegexSelected ? 1 : 0.3,
        }}
        onMouseEnter={this.onItemEnter(index)}
        onMouseLeave={this.onItemLeave(index)}
      >
        <ArrowDownwardIcon
          style={{
            ...styles.downArrow,
            visibility:
              diffType !== "deleted" && index !== maxIndex && isItemHovered
                ? "visible"
                : "hidden",
          }}
          onClick={this.moveValueDown(index, realIndex)}
        />
        <div style={styles.index}>{index + 1}.</div>
        <div
          style={{
            marginRight: "2rem",
            width: "40%",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <TextField
            disabled={diffType === "deleted"}
            onChange={this.updateValueName(index, realIndex)}
            // errorText={this.state && this.props.nameError}
            value={value.value}
            label="Value Label"
            style={{
              width: "100%",
              backgroundColor: getUpdatedColor(
                diffType,
                liveValueValue,
                valueValue,
              ),
            }}
            multiline
          />
          {liveValueValue &&
            liveValueValue !== valueValue && (
              <TopicparameterTextValueDiff
                value={valueValue}
                liveValue={liveValueValue}
                iconStyles={{
                  position: "absolute",
                  right: -28,
                }}
              />
            )}
        </div>
        <div
          style={{
            width: "53%",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          <TextField
            disabled={diffType === "deleted"}
            onChange={this.updateValuePattern(index, realIndex)}
            error={Boolean(isValueInvalid)}
            helperText={isValueInvalid}
            value={regexValue}
            label="Pattern (Regular Expression)"
            style={{
              width: "100%",
              backgroundColor: getUpdatedColor(
                diffType,
                liveRegexValue || undefined,
                regexValue,
              ),
            }}
            InputProps={{
              style: {
                ...shouldHighlightRegexWithSpaces.getStyle(regexValue),
                fontFamily: "monospace",
              },
            }}
            multiline
          />
          {liveRegexValue && liveRegexValue !== regexValue ? (
            <TopicparameterTextValueDiff
              value={regexValue}
              liveValue={liveRegexValue}
              iconStyles={{
                marginLeft: 2,
                marginRight: 8,
              }}
            />
          ) : (
            <div style={{width: 36}} />
          )}
          {(liveRegexValue && liveRegexValue !== regexValue) ||
          diffType === "added" ||
          diffType === "deleted" ? (
            <ReplayIcon
              onClick={this.revertValuePattern(
                index,
                realIndex,
                regexValue,
                liveRegexValue,
                diffType,
              )}
              style={{
                flexShrink: 0,
                cursor: "pointer",
                color: colors.grey[700],
                marginRight: 4,
              }}
            />
          ) : (
            <div
              style={{
                width: 32,
                flexShrink: 0,
              }}
            />
          )}
        </div>
        {isCurrentRegexSelected || isItemHovered ? (
          <VisibilityIcon
            style={{
              cursor: "pointer",
              color: isCurrentRegexSelected
                ? colors.grey[700]
                : isItemHovered ? colors.grey[400] : "inherit",
              width: 24,
              flexShrink: 0,
            }}
            onClick={selectRegex}
          />
        ) : (
          <div
            style={{
              width: 24,
              flexShrink: 0,
            }}
          />
        )}
        <div
          style={{
            ...styles.index,
            fontSize: 14,
            width: "5rem",
            marginRight: 0,
            marginLeft: 20,
            alignSelf: "unset",
            display: "flex",
          }}
        >
          <div title={isDevModeOn ? "Dev" : "Live"}>
            {value.matchesCount === undefined ? "-" : value.matchesCount}/{value.applications ===
            undefined
              ? "-"
              : value.applications}
          </div>
          {isDevModeOn ? (
            <div
              title="Live"
              style={{
                marginLeft: 6,
                color: colors.grey[500],
              }}
            >
              {value.live_matchesCount === undefined
                ? "-"
                : value.live_matchesCount}/{value.live_applications ===
              undefined
                ? "-"
                : value.live_applications}
            </div>
          ) : null}
        </div>
        <DeleteIcon
          onClick={() => this.handleOpen(index, realIndex)}
          style={{
            color: "#616161",
            cursor: "pointer",
            visibility: diffType === "deleted" ? "hidden" : "visible",
          }}
        />
      </div>
    );
  }

  handleOpen = (index, realIndex) => {
    this.setState(() => ({
      deleteDialogIsOpen: true,
      deleteValues: {
        index,
        realIndex,
      },
    }));
  };

  handleClose = () => {
    this.setState(() => ({deleteDialogIsOpen: false}));
  };

  /* eslint-disable no-invalid-this */
  updateDisplayValues = e => {
    const isInputChecked = e.target.checked;
    const {value, onChange, diffValue} = this.props;
    const newValue = {
      ...value,
      display_values: isInputChecked,
    };

    const newDiffValue = cloneDeep(diffValue || value);
    newDiffValue.display_values = isInputChecked;
    if (newDiffValue.live_display_values === undefined) {
      newDiffValue.live_display_values = value.display_values;
    } else {
      delete newDiffValue.live_display_values;
    }
    onChange(newValue, null, newDiffValue);
  };

  onMatchFirstOnlySwitch = e => {
    const targetChecked = e.target.checked;
    const {value, onChange, diffValue} = this.props;

    const newValue = {
      ...value,
      match_first_only: targetChecked,
    };

    const newDiffValue = cloneDeep(diffValue || value);
    newDiffValue.match_first_only = targetChecked;

    if (newDiffValue.live_match_first_only === undefined) {
      newDiffValue.live_match_first_only = value.match_first_only;
    } else {
      delete newDiffValue.live_match_first_only;
    }

    onChange(newValue, null, newDiffValue);
  };

  addValue = () => {
    const {value, onChange, diffValue} = this.props;
    const newValue = {
      ...value,
      values: value.values.concat([
        {
          value: "",
          pattern: [],
        },
      ]),
    };

    const newDiffValue = cloneDeep(diffValue || value);
    newDiffValue.values = newDiffValue.values.concat([
      {
        value: "",
        pattern: [],
        type: "added",
      },
    ]);

    onChange(newValue, null, newDiffValue);
  };

  updateValueName = _.memoize(
    (index, realIndex) => event => {
      const targetValue = event.target.value;
      const {value, onChange, diffValue} = this.props;
      const newValues = [...value.values];
      newValues[realIndex] = {
        ...newValues[realIndex],
        value: targetValue,
      };
      const newValue = {
        ...value,
        values: newValues,
      };

      const newDiffValue = cloneDeep(diffValue || value);
      const liveItem = newDiffValue.values[index];
      const liveValue = liveItem.live_value || liveItem.value;

      if (
        !newDiffValue.values[index].type &&
        !newDiffValue.values[index].live_value
      ) {
        newDiffValue.values[index].type = "updated";
        newDiffValue.values[index].live_value = liveValue;
      }

      newDiffValue.values[index].value = targetValue;

      if (newDiffValue.values[index].type === "updated") {
        if (
          newDiffValue.values[index].value ===
          newDiffValue.values[index].live_value
        ) {
          delete newDiffValue.values[index].live_value;
        } else if (!newDiffValue.values[index].live_value) {
          newDiffValue.values[index].live_value = liveValue;
        }
      }
      onChange(newValue, null, newDiffValue);
    },
    (...args) => JSON.stringify([...args]),
  );
  updateValuePattern = _.memoize(
    (index, realIndex) => event => {
      const targetValue = event.target.value;
      const {value, onChange, diffValue} = this.props;

      const newValues = [...value.values];
      newValues[realIndex] = {
        ...newValues[realIndex],
        pattern: [targetValue],
      };
      const newValue = {
        ...value,
        values: newValues,
      };

      // diffValue logic
      const newDiffValue = cloneDeep(diffValue || value);
      const liveItem = newDiffValue.values[index];
      const livePattern = liveItem.live_pattern || liveItem.pattern;
      if (
        !newDiffValue.values[index].type &&
        !newDiffValue.values[index].live_pattern
      ) {
        newDiffValue.values[index].type = "updated";
        newDiffValue.values[index].live_pattern = livePattern;
      }

      newDiffValue.values[index].pattern = [targetValue];

      if (newDiffValue.values[index].type === "updated") {
        if (
          _.isEqual(
            newDiffValue.values[index].pattern,
            newDiffValue.values[index].live_pattern,
          )
        ) {
          delete newDiffValue.values[index].live_pattern;
        } else if (!newDiffValue.values[index].live_pattern) {
          newDiffValue.values[index].live_pattern = livePattern;
        }
      }
      onChange(newValue, null, newDiffValue);
    },
    (...args) => JSON.stringify([...args]),
  );
  deleteValue = _.memoize(
    (index, realIndex) => () => {
      const {value, onChange, diffValue} = this.props;
      const newValues = [...value.values];
      newValues.splice(realIndex, 1);

      const newValue = {
        ...value,
        values: newValues,
      };

      const newDiffValue = cloneDeep(diffValue || value);
      const newDiffValueValue = newDiffValue.values[index];

      if (newDiffValueValue.type === "added") {
        newDiffValue.values.splice(index, 1);
      } else if (newDiffValueValue.type === "updated") {
        Object.keys(newDiffValueValue).forEach(key => {
          if (key.startsWith("live_")) {
            const keyName = key.slice(5);
            newDiffValueValue[keyName] = newDiffValueValue[key];
            delete newDiffValueValue[key];
          }
        });
        newDiffValueValue.type = "deleted";
      } else if (!newDiffValueValue.type) {
        newDiffValueValue.type = "deleted";
      }
      onChange(newValue, null, newDiffValue);
    },
    (...args) => JSON.stringify([...args]),
  );
  moveValueDown = _.memoize(
    (index, realIndex) => () => {
      const {value, onChange, diffValue} = this.props;
      const newValues = [...value.values];

      const nextValue = newValues[realIndex + 1];
      let hasValueChanges = false;
      const newValue = {
        ...value,
        values: newValues,
      };
      if (nextValue) {
        hasValueChanges = true;

        newValues[realIndex + 1] = newValues[realIndex];
        newValues[realIndex] = nextValue;

        newValue.values = newValues;
      }

      const newDiffValue = cloneDeep(diffValue || value);
      const nextNonDeletedValueIndex = newDiffValue.values.findIndex(
        (item, itemIndex) => itemIndex > index && item.type !== "deleted",
      );

      let hasDiffChanges = false;
      if (!nextNonDeletedValueIndex !== -1) {
        hasDiffChanges = true;
        const currentValue = newDiffValue.values[index];
        const nextNonDeletedValue =
          newDiffValue.values[nextNonDeletedValueIndex];
        if (!currentValue.type || currentValue.type === "updated") {
          // set current value as deleted
          const clearCurrentValue = clearDiffValue(currentValue, true);
          clearCurrentValue.type = "deleted";
          newDiffValue.values[index] = clearCurrentValue;
          // move current value
          const addedValue = currentValue;
          addedValue.type = "added";
          // remove all live_ keys from added value if available
          Object.keys(addedValue).forEach(key => {
            if (key.startsWith("live_")) {
              delete addedValue[key];
            }
          });
          newDiffValue.values.splice(
            nextNonDeletedValueIndex + 1,
            0,
            addedValue,
          );
        } else if (currentValue.type === "added") {
          newDiffValue.values[index] = nextNonDeletedValue;
          newDiffValue.values[nextNonDeletedValueIndex] = currentValue;
        }
      }

      if (hasValueChanges || hasDiffChanges) {
        onChange(
          hasValueChanges ? newValue : undefined,
          null,
          hasDiffChanges ? newDiffValue : undefined,
        );
      }
    },
    (...args) => JSON.stringify([...args]),
  );
  onItemEnter = _.memoize(
    index => () => this.setState(() => ({hoveredItemIndex: index})),
    (...args) => JSON.stringify([...args]),
  );
  onItemLeave = _.memoize(
    () => () => this.setState(() => ({hoveredItemIndex: null})),
    (...args) => JSON.stringify([...args]),
  );

  revertValuePattern = _.memoize(
    (index, realIndex, regexValue, liveRegexValue, diffType) => () => {
      const {value, onChange, diffValue} = this.props;
      const newValue = cloneDeep(value);
      const newDiffValue = cloneDeep(diffValue);
      if (
        liveRegexValue &&
        liveRegexValue !== regexValue &&
        diffType === "updated"
      ) {
        newValue.values[realIndex].pattern = [liveRegexValue];
        newDiffValue.values[index].pattern = [liveRegexValue];
      } else if (diffType === "deleted") {
        delete newDiffValue.values[index].type;
        newValue.values.splice(realIndex, 0, newDiffValue.values[index]);
      } else if (diffType === "added") {
        newValue.values.splice(realIndex, 1);
        newDiffValue.values.splice(index, 1);
      }
      onChange(newValue, null, newDiffValue);
    },
    (...args) => JSON.stringify([...args]),
  );
  /* eslint-enable no-invalid-this */
}

// removes live_ values from diff value,
// optionally removes type
function clearDiffValue(baseDiffValue, shouldRemoveType) {
  const resultValue = cloneDeep(baseDiffValue);
  Object.keys(resultValue).forEach(key => {
    if (key.startsWith("live_")) {
      const keyName = key.slice(5);
      resultValue[keyName] = resultValue[key];
      delete resultValue[key];
    }
  });
  if (shouldRemoveType) {
    delete resultValue.type;
  }
  return resultValue;
}

export default {
  initialise,
  validate,
  renderer: Renderer,
};

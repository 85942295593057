import React from "react";

import RagReportPanel from "../panels/rag_report";
import EmailTextPanel from "../panels/email_text";
import ItemContainer from "../helper_components/item_container";

function CreateTab(props) {
  const {project: {report_settings: reportSettings = []}} = props;

  function renderRagReportPanel(report, props) {
    const currentReportSettings =
      props.document.report_settings.find(
        documentReport => documentReport.id === report.id,
      ) || report;
    return <RagReportPanel {...props} reportSettings={currentReportSettings} />;
  }

  return reportSettings.map(report => {
    const key = report.id;
    switch (report.type) {
      case "email": {
        return (
          <EmailTextPanel
            key={key}
            title={report.label}
            issue={props.issue}
            project={props.project}
            documentClauses={props.documentClauses}
            documentClauseparts={props.documentClauseparts}
            onIgnore={props.onIgnore}
            onAddToEmail={props.onAddToEmail}
            onRevert={props.onRevert}
            updateDocumentIssue={props.updateDocumentIssue}
            showIssuesInChecklist={props.showIssuesInChecklist}
            topicCategories={props.topicCategories}
            documentSections={props.documentSections}
            topicsById={props.topicsById}
            currentIssuesetItem={props.currentIssuesetItem}
            zoom={props.zoom}
            isFlipChecklistIcons={props.isFlipChecklistIcons}
            onOpen={props.onItemOpen(key)}
            isOpen={props.openTabs[key]}
            containerStyles={props.containerStyles}
            reportSettings={report}
          />
        );
      }
      case "rag_report":
        return (
          <ItemContainer
            key={key}
            title={report.label}
            expandable={true}
            containerStyles={{flex: "1 0 auto", ...props.containerStyles}}
            onExpandMenu={props.onItemOpen(key)}
            isMenuExpanded={props.openTabs[key]}
          >
            {renderRagReportPanel(report, props)}
          </ItemContainer>
        );
      default:
        return null;
    }
  });
}

export default CreateTab;

import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";
import UserActionTypes from "modules/user/constants/action_types";

export default function organisationReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.CONTRACT_TYPES_FETCH.SUCCESS:
    case ActionTypes.CONTRACT_TYPES_FOR_CLIENTS_FETCH.SUCCESS:
      return action && action.payload ? action.payload : state;
    case ActionTypes.CONTRACT_TYPE_ADD.SUCCESS: {
      const contractTypeExists = state.find(
        ct => ct.name === action.payload.name,
      );
      if (contractTypeExists) {
        return state;
      }
      return [...state, action.payload];
    }
    case ActionTypes.CONTRACT_TYPE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(contractType => {
        if (contractType.id === action.payload.id) {
          return {
            ...contractType,
            ...action.payload,
          };
        }
        return contractType;
      });
    }
    case ActionTypes.CONTRACT_TYPE_REMOVE.SUCCESS: {
      if (action.payload.is_deleted) {
        return state.filter(
          contractType => contractType.id !== action.payload.id,
        );
      }
      return state;
    }
    case ActionTypes.ISSUESET_ADD.SUCCESS: {
      if (action.payload.contract_type_id) {
        return state.map(contractType => {
          const issuesetAlreadyExists = contractType.issuesets.find(
            is => is.name === action.payload.issueset.name,
          );
          if (issuesetAlreadyExists) {
            return contractType;
          }
          if (contractType.id === action.payload.contract_type_id) {
            return {
              ...contractType,
              issuesets: [...contractType.issuesets, action.payload.issueset],
            };
          }
          return contractType;
        });
      }
      return state;
    }
    case ActionTypes.ISSUESET_UPDATE.SUCCESS: {
      const {payload} = action;
      if (payload.id && payload.contract_type_id && payload.updates) {
        return state.map(contractType => {
          if (contractType.id === payload.contract_type_id) {
            return {
              ...contractType,
              issuesets: contractType.issuesets.map(is => {
                if (is.id === payload.id) {
                  return {
                    ...is,
                    ...payload.updates,
                  };
                }
                return is;
              }),
            };
          }
          return contractType;
        });
      }
      return state;
    }
    case ActionTypes.ISSUESET_REMOVE.SUCCESS: {
      const {payload} = action;
      if (payload.id && payload.contract_type_id) {
        return state.map(contractType => {
          if (contractType.id === payload.contract_type_id) {
            return {
              ...contractType,
              issuesets: contractType.issuesets.filter(
                is => is.id !== payload.id,
              ),
            };
          }
          return contractType;
        });
      }
      return state;
    }
    case ActionTypes.CONTRACT_TYPES_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import _ from "underscore";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import formatDate from "./utils/format_date";
import getPersonName from "./utils/get_person_name";
import ItemContainer from "../helper_components/item_container";
import IssueCommentComponent from "./components/issue_comment_component";
import Paragraphs from "./components/paragraphs";

export default function CommentsPanel({
  issue: {issue_comments: issueComments},
  onItemOpen,
  openTabs,
  updateIssueComment,
  deleteIssueComment,
}) {
  return (
    <ItemContainer
      title="Comments"
      expandable={true}
      onExpandMenu={onItemOpen("commentsPanel")}
      isMenuExpanded={openTabs.commentsPanel}
      containerStyles={{flex: "1 0 auto"}}
    >
      {issueComments && issueComments.length ? (
        issueComments.map((issueComment, index) => (
          <Comment
            key={index}
            text={issueComment.text}
            author={getPersonName(issueComment)}
            date={formatDate(issueComment.creation_date)}
            updateIssueComment={text =>
              updateIssueComment(issueComment, {text})
            }
            deleteAction={() => deleteIssueComment(issueComment)}
          />
        ))
      ) : (
        <div>No comments</div>
      )}
    </ItemContainer>
  );
}

const useTextWithAuthorAndDateStypes = makeStyles({
  main: {
    margin: "0 0 .5em",
    padding: "1em 0 .7em",
    backgroundColor: "#f1f1f1",
  },
  mainTextArea: {
    padding: "0 1em",
  },
  type: {
    marginBottom: ".5em",
    fontWeight: "bold",
  },
  sign: {
    display: "flex",
    fontSize: ".75em",
    "& > *": {
      marginRight: ".7em",
    },
  },
  author: {
    fontWeight: "bold",
  },
  text: {
    margin: "0.5em 0",
  },
  actionPanel: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1em",
    borderTop: "1px solid rgb(221, 221, 221)",
    padding: ".7em 2.2em 0",
    fontSize: ".75em",
  },
  buttons: {
    display: "flex",
  },
  iconButton: {
    display: "flex",
    alignItems: "baseline",
    marginRight: "1.5em",
    color: "#136bdb",
    cursor: "pointer",
    "& > *": {
      margin: "auto 0",
    },
  },
  icon: {
    marginRight: ".2em",
    fontSize: "1.4em",
  },
});

function Comment({text, author, date, deleteAction, updateIssueComment}) {
  const classes = useTextWithAuthorAndDateStypes();
  const [isEditMode, setEditMode] = useState(false);
  const [value, setValue] = useState(text);
  const onCancel = () => {
    setValue(text);
    setEditMode(false);
  };
  const onSave = () => {
    updateIssueComment(value);
    setEditMode(false);
  };
  return (
    <div className={classes.main}>
      <div className={classes.mainTextArea}>
        <div className={classes.sign}>
          <div className={classes.author}>{author}</div>
          <div>|</div>
          <div>{date}</div>
        </div>
        {isEditMode ? (
          <IssueCommentComponent
            value={value}
            rows={getLinesAmount(value)}
            isBlurred={true}
            style={{
              panel: {},
              textarea: {
                fontSize: "1em",
                lineHeight: "1.4",
              },
            }}
            onChange={e => setValue(e.target.value)}
            onCancel={onCancel}
            onSave={onSave}
          />
        ) : (
          <div className={classes.text}>
            <Paragraphs text={text} />
          </div>
        )}
      </div>
      <div className={classes.actionPanel}>
        <div className={classes.buttons}>
          <div className={classes.iconButton} onClick={() => setEditMode(true)}>
            <EditOutlinedIcon className={classes.icon} />
            <span>Edit</span>
          </div>
          <div className={classes.iconButton} onClick={deleteAction}>
            <DeleteForeverOutlinedIcon className={classes.icon} />
            <span>Delete</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function getLinesAmount(text) {
  const res = text.match(/\n/g);
  if (res && _.isNumber(res.length)) {
    return res.length;
  }
  return 1;
}

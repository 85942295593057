import ReactDOM from "react-dom";
import _ from "underscore";
import scrollIntoView from "scroll-into-view";

import isVisible from "./is_visible";

function areEqualShallow(a, b) {
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false;
    }
  }
  for (const key in b) {
    if (!(key in a) || a[key] !== b[key]) {
      return false;
    }
  }
  return true;
}

export default class ScrollUpdater {
  constructor(node, getId, isSelected, bottomOffset) {
    this.node = node;
    this.getId = getId;
    this.bottomOffset = bottomOffset;
    if (isSelected) {
      this.isSelected = isSelected;
    } else {
      this.isSelected = props => this.getId(props) === props.selectedId;
    }
  }

  componentDidMount(props) {
    if (props.shouldScroll && this.isSelected(props)) {
      this.scrollIntoView(props);
    }
    this.updateVisibility(props);
  }

  shouldComponentUpdate(props, prevProps) {
    if (prevProps.announceVisibility !== props.announceVisibility) {
      this.updateVisibility(props);
    }
    return !areEqualShallow(
      _.omit(prevProps, "announceVisibility"),
      _.omit(props, "announceVisibility"),
    );
  }

  componentDidUpdate(props, prevProps) {
    if (
      props.shouldScroll &&
      prevProps.selectedId !== props.selectedId &&
      this.isSelected(props)
    ) {
      this.scrollIntoView(props);
    }
  }

  updateVisibility(props) {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }
    this.lastTimeout = setTimeout(() => {
      const node = this.getReactNode();
      if (node) {
        // console.log('update for', this.getId(props));
        props.updateVisibility(this.getId(props))(
          isVisible(node, this.bottomOffset, this.findParentScroller),
          node.offsetTop,
        );
      } else {
        props.updateVisibility(this.getId(props))(false, -1);
      }
    }, 1);
  }

  scrollIntoView(props) {
    const isVisibleInColumn = Object.values(
      props.visibility[this.getId(props)] || {},
    ).find(value => value);
    if (!isVisibleInColumn || !isVisibleInColumn.isVisible) {
      const el = this.getReactNode();
      if (el) {
        setTimeout(() => {
          scrollIntoView(el, {
            time: 0,
            validTarget: this.findParentScroller,
            align: {
              top: 0,
              topOffset: 150,
            },
          });
        }, 1);
      }
    }
  }

  findParentScroller(target) {
    return (
      target.className &&
      target.className
        .split(" ")
        .find(className => className === "view-scroller")
    );
  }

  getReactNode() {
    try {
      return ReactDOM.findDOMNode(this.node);
    } catch {
      return null;
    }
  }
}

import isEmptyParent from "common/utils/issues/is_empty_parent";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getIssuesSortingFunction from "common/utils/issues/get_issues_sorting_function";

function constructSubissues(issues) {
  if (!issues || issues.length <= 1) {
    return issues;
  }
  const topLevelIssues = [];
  const potentialSubissues = [];
  issues.forEach(issue => {
    if (issue.name.includes(" / ")) {
      potentialSubissues.push(issue);
    } else {
      topLevelIssues.push(issue);
    }
  });

  const usedPotentialSubissueIds = [];
  potentialSubissues.forEach(potentialSubissue => {
    const parentName = potentialSubissue.name.split(" / ")[0];
    const topLevelIssue = topLevelIssues.find(
      tlIssue => tlIssue.name === parentName,
    );
    if (topLevelIssue) {
      const potentialSubissueWithData = {
        ...potentialSubissue,
        subissueData: {isSubissue: true},
      };
      if (!topLevelIssue.subissueData) {
        topLevelIssue.subissueData = {
          isSubissueParent: true,
          subissues: [potentialSubissueWithData],
        };
      } else {
        topLevelIssue.subissueData.subissues.push(potentialSubissueWithData);
      }
      usedPotentialSubissueIds.push(potentialSubissue.id);
    }
  });
  const finalTopLevelIssues = topLevelIssues
    .concat(
      potentialSubissues.filter(
        pS => !usedPotentialSubissueIds.includes(pS.id),
      ),
    )
    .map(topLevelIssue => {
      if (isEmptyParent(topLevelIssue)) {
        const isTriggered = Boolean(
          (topLevelIssue.subissueData.subissues || []).find(
            subissue =>
              isIssueAlert(subissue) &&
              (topLevelIssue.parent_settings
                ?.set_state_and_display_text_from === "all_sub_issues" ||
                !subissue.shouldBeHidden),
          ),
        );
        const {show_if_triggered_only: showIfTriggeredOnly} = topLevelIssue;
        const shouldBeHidden =
          showIfTriggeredOnly === "never" ||
          (showIfTriggeredOnly === "ifTriggered" && !isTriggered) ||
          (showIfTriggeredOnly === "ifNotTriggered" && isTriggered);
        return {...topLevelIssue, shouldBeHidden};
      }
      return topLevelIssue;
    })
    .reduce((result, topLevelIssue) => {
      if (!topLevelIssue.subissueData) {
        result.push(topLevelIssue);
        return result;
      }
      const isParentTriggered = isIssueAlert(topLevelIssue);
      const filteredSubissues = topLevelIssue.subissueData.subissues.reduce(
        (subissues, subissue) => {
          const {
            show_if_triggered_only: showIfTriggeredOnly,
            hide_subissue_when_parent: hideSubissueWhenParent,
          } = subissue;
          const isSubissueTriggered = isIssueAlert(subissue);
          const shouldHideByShowIfTriggeredOnly =
            showIfTriggeredOnly === "never" ||
            !(
              showIfTriggeredOnly === "always" ||
              (showIfTriggeredOnly === "ifTriggered" && isSubissueTriggered) ||
              (showIfTriggeredOnly === "ifNotTriggered" && !isSubissueTriggered)
            );
          const shouldHideByHideSubissueWhenParent =
            (hideSubissueWhenParent === "ifTriggered" && isParentTriggered) ||
            (hideSubissueWhenParent === "ifNotTriggered" && !isParentTriggered);
          const shouldBeHidden =
            shouldHideByShowIfTriggeredOnly ||
            shouldHideByHideSubissueWhenParent ||
            subissue.issue_class_id === 4; // hidden issue class

          subissues.push({...subissue, shouldBeHidden});

          return subissues;
        },
        [],
      );
      if (filteredSubissues.length === 0) {
        topLevelIssue.subissueData = undefined;
      } else {
        topLevelIssue.subissueData.subissues = filteredSubissues;
      }
      result.push(topLevelIssue);
      return result;
    }, []);

  finalTopLevelIssues.forEach(topLevelIssue => {
    if (topLevelIssue.subissueData && topLevelIssue.subissueData.subissues) {
      topLevelIssue.subissueData.subissues.sort(
        getIssuesSortingFunction("issueItemOrder"),
      );
    }
  });
  return finalTopLevelIssues.sort(getIssuesSortingFunction("issueItemOrder"));
}

export default constructSubissues;

import React from "react";
import formatTemplate from "../../../utils/templates/format_template";

const TextValue = ({value}) => {
  const style = value === "Blank" ? {fontStyle: "italic"} : {};
  return value
    .trim()
    .split(/\n/)
    .map((textline, index) => {
      const isListItem = textline.trim().startsWith("-");
      const line = textline || String.fromCharCode(160);
      return isListItem ? (
        <li key={index}>{formatTemplate(line.replace(/^ *- */, ""), style)}</li>
      ) : (
        <div key={index}>{formatTemplate(line, style)}</div>
      );
    });
};

export default TextValue;

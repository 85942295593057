import getClausepartSubstitutionData from "utils/clauses/get_clausepart_substitution_data";

function getClausepartsSubstitutionData(
  clauseparts,
  itemDefinitions,
  definitions,
  linkedDefinitions,
  definitionGroups,
  documentCapitalizedWords,
  precedentTerms,
  itemParties,
  documentParties,
  documentReferenceIndicator,
  referenceIndicatorGroup,
  whatToSubstitute = "default",
) {
  return clauseparts.reduce(
    (accum, clausepart) => {
      const {optionsMap, substitutionMap} = getClausepartSubstitutionData(
        clausepart.highlighted_text,
        whatToSubstitute,
        itemDefinitions,
        definitions,
        linkedDefinitions,
        definitionGroups,
        documentCapitalizedWords,
        precedentTerms,
        itemParties,
        documentParties,
        documentReferenceIndicator,
        referenceIndicatorGroup,
      );
      return {
        optionsMap: {
          ...accum.optionsMap,
          ...optionsMap,
        },
        substitutionMap: {
          ...accum.substitutionMap,
          ...substitutionMap,
        },
      };
    },
    {optionsMap: {}, substitutionMap: {}},
  );
}

export default getClausepartsSubstitutionData;

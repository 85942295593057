import _ from "lodash";

import cleanProjectName from "./clean_project_name";

export default function buildProjectsTree(_projects, reverseSorting = false) {
  const projects = _projects.map(project => ({
    ..._.cloneDeep(project),
    name: cleanProjectName(project.name),
  }));
  const tree = {};
  let sortedProjects = _.sortBy(projects, "name");
  if (reverseSorting) {
    sortedProjects = sortedProjects.reverse();
  }
  sortedProjects.forEach(project => {
    let store = tree;
    const nameParts = project.name.split(/\s*\/\s*/);
    nameParts.forEach((namePart, index) => {
      const isLastIndex = nameParts.length - 1 === index;
      if (!_.has(store, namePart)) {
        store[namePart] = {
          project: null,
          level: index,
          path: nameParts.slice(0, index + 1).join(" / "),
          childProjects: {},
        };
      }
      if (!isLastIndex) {
        store = store[namePart].childProjects;
      } else {
        store[namePart].project = project;
      }
    });
  });
  return tree;
}

const {XMLParser} = require("fast-xml-parser");

const xmlParserOptions = {
  attributeNamePrefix: "@_",
  attrNodeName: "attr",
  textNodeName: "#text",
  ignoreAttributes: false,
  ignoreNameSpace: true,
  allowBooleanAttributes: true,
  parseNodeValue: false,
  parseAttributeValue: false,
  trimValues: false,
  cdataTagName: "__cdata", // default is 'false'
  cdataPositionChar: "\\c",
  parseTrueNumberOnly: false,
  arrayMode: false, // "strict"
};

const parser = new XMLParser(xmlParserOptions);

function convertXmlToJson(xml) {
  return parser.parse(xml);
}
module.exports = convertXmlToJson;

import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";
import DocumentActionTypes from "../../documents/constants/action_types";
import LogActions from "common/actions/logs";

export default function logsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.LOGS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.LOGS_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.LOG_COMMENT_ADD.REQUEST: {
      const [, logId, text, username] = action.payload;
      return state.map(log => {
        if (log.id === logId) {
          return {
            ...log,
            comments: log.comments.concat([
              {
                text,
                username,
                time: new Date().toUTCString(),
              },
            ]),
          };
        }
        return log;
      });
    }
    case DocumentActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD.SUCCESS: {
      return processDocumentClauseTopicAction(
        state,
        action.payload,
        LogActions.DOCUMENT_CLAUSE_TOPIC_ADD,
        true,
      );
    }
    case DocumentActionTypes.DOCUMENT_CLAUSE_TOPIC_REMOVE.SUCCESS: {
      return processDocumentClauseTopicAction(
        state,
        action.payload,
        LogActions.DOCUMENT_CLAUSE_TOPIC_REMOVE,
        false,
      );
    }
    case DocumentActionTypes.DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE.SUCCESS: {
      return state;
    }
    case DocumentActionTypes.DOCUMENT_CLAUSE_TOPIC_PARAMETER_CLASSIFICATION
      .SUCCESS: {
      const {
        id: clausepartId,
        clausepart_last_edited: clausepartLastEdited,
      } = action.payload;
      if (state !== UNINITIALISED && clausepartLastEdited) {
        return state.map(stateLog => {
          if (stateLog.clausepart_id === clausepartId) {
            return {
              ...stateLog,
              clausepart_last_edited: clausepartLastEdited,
            };
          }
          return stateLog;
        });
      }
      return state;
    }
    default:
      return state;
  }
}

function processDocumentClauseTopicAction(
  state,
  payload,
  logAction,
  isTopicInClause,
) {
  if (state === UNINITIALISED) {
    return state;
  }

  const logAlreadyExists = Boolean(
    state.find(
      stateLog =>
        stateLog.time === payload.clausepart_last_edited &&
        stateLog.type === logAction,
    ),
  );
  if (logAlreadyExists) {
    return state;
  }

  const firstAvailableClausepartLog = state.find(
    stateLog => stateLog.clausepart_id === payload.clausepart_id,
  );
  if (
    firstAvailableClausepartLog &&
    typeof firstAvailableClausepartLog.is_topic_in_clause === "boolean"
  ) {
    const newItem = {
      ...firstAvailableClausepartLog,
      id: null,
      type: logAction,
      time: payload.clausepart_last_edited,
      clausepart_last_edited: payload.clausepart_last_edited,
      user_id: payload.user_id,
      username: payload.username,
      is_topic_in_clause: isTopicInClause,
    };
    const newState = state.map(stateLog => {
      if (stateLog.clausepart_id === payload.clausepart_id) {
        return {
          ...stateLog,
          is_topic_in_clause: isTopicInClause,
        };
      }
      return stateLog;
    });
    return [newItem, ...newState];
  }
  return state;
}

import React from "react";
import _ from "underscore";

import Clause from "./clause";
import calculateHoveredClauseparts from "./clause/utils/calculate_hovered_clauseparts";
import ClauseHeading from "./clause_heading/";

import ScrollUpdater from "../../utils/scroll_updater";
import doesClauseHaveIssue from "utils/clauses/does_clause_have_issue";
import getManualCorrectionsIcons from "../../utils/issue/get_manual_correction_icons";
import CircularProgress from "@material-ui/core/CircularProgress";

import bottomOffset from "./bottom_offset";

function getClauseAtoms(clause) {
  const atoms = [];
  function getAtoms(nodes, clause) {
    if (nodes && nodes.text) {
      atoms.push(nodes);
    }
    if (nodes && nodes.clauseNodes) {
      nodes.clauseNodes.forEach(child => getAtoms(child, clause));
    }
  }
  getAtoms(clause.nodes, clause);
  return atoms;
}

function getClauseAtomsWithDocumentLastEdited(documentLastEdited, clause) {
  return getClauseAtoms(clause);
}

const getClauseAtomsMemo = _.memoize(
  getClauseAtomsWithDocumentLastEdited,
  documentLastEdited => documentLastEdited,
);

function isIssueInClause(issue, clauseAtoms) {
  for (let i = 0; i < clauseAtoms.length; i++) {
    const atom = clauseAtoms[i];
    if (doesClauseHaveIssue(issue, atom)) {
      return true;
    }
  }
  return false;
}

export default class SingleClause extends React.Component {
  constructor(props) {
    super(props);
    if (props.fullReference) {
      this.scrollUpdater = new ScrollUpdater(
        this,
        props => props.fullReference,
        props => {
          const {fullReference, selectedId} = props;
          return (
            fullReference === selectedId ||
            (selectedId &&
              fullReference.substring(0, fullReference.indexOf(".")) ===
                selectedId) ||
            (props.hiddenHeadings[fullReference] &&
              selectedId.indexOf(".") > 0 &&
              selectedId.substring(0, selectedId.indexOf(".")) ===
                fullReference) ||
            props.reference === selectedId
          );
        },
        bottomOffset,
      );
    }
    this.state = {
      // the following is used for proper rendering of delete clausepart button
      // when hovering over
      lastHoveredClausepartId: null,
      hoveredClausepartChildrenIds: [],
      isClauseHeadingHovered: false,
      isClauseHovered: false,
      hoveredHeadingActionButton: null,
      isNumberItemsHovered: null,
    };
  }

  componentDidMount() {
    if (this.props.reference) {
      this.scrollUpdater.componentDidMount(this.props);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {props} = this;
    const shouldUpdate =
      nextState.isClauseHeadingHovered !== this.state.isClauseHeadingHovered ||
      (nextProps.clauseToRender !== props.clauseToRender &&
        nextProps.clauseToRender >= props.clause.row_number - 1 &&
        !this.hasRendered) ||
      (nextProps.editModeOn &&
        (nextState.lastHoveredClausepartId !==
          this.state.lastHoveredClausepartId ||
          !_.isEqual(
            nextState.hoveredClausepartChildrenIds,
            this.state.hoveredClausepartChildrenIds,
          ))) ||
      nextState.hoveredHeadingActionButton !==
        this.state.hoveredHeadingActionButton ||
      // (props.reference &&
      //   this.scrollUpdater.shouldComponentUpdate(props, nextProps)) ||
      nextProps.hoveredTopicIds !== props.hoveredTopicIds ||
      // nextProps.hiddenHeadings !== props.hiddenHeadings ||
      // nextProps.announceVisibility !== props.announceVisibility ||
      nextState.isNumberItemsHovered !== this.state.isNumberItemsHovered ||
      nextProps.selectedHeading !== props.selectedHeading ||
      // nextProps.selectedTopics !== props.selectedTopics ||
      (nextProps.selectedIssue !== props.selectedIssue &&
        (this.hasMatchingIssueClause(
          nextProps.selectedIssue,
          nextProps.documentIssues,
          nextProps.clause,
        ) ||
          this.hasMatchingIssueClause(
            props.selectedIssue,
            props.documentIssues,
            props.clause,
          ))) ||
      nextProps.issueState !== props.issueState ||
      !_.isEqual(nextProps.editableClausepart, props.editableClausepart) ||
      nextProps.editableClauseHeading !== props.editableClauseHeading ||
      !_.isEqual(
        this.props.documentIssues.map(di => di.updateCount),
        nextProps.documentIssues.map(di => di.updateCount),
      ) ||
      nextProps.documentChanges !== props.documentChanges ||
      nextProps.selectedId !== props.selectedId ||
      nextProps.additionClause !== props.additionClause ||
      nextProps.editableClauseId !== props.editableClauseId ||
      (!_.isEqual(nextProps.clauseToHighlight, props.clauseToHighlight) &&
        ((nextProps.clauseToHighlight &&
          nextProps.clauseToHighlight.clause_id === nextProps.clause.id) ||
          (props.clauseToHighlight &&
            props.clauseToHighlight.clause_id === props.clause.id))) ||
      !_.isEqual(nextProps.documentClauses, props.documentClauses) ||
      nextProps.findAndReplace.find !== this.props.findAndReplace.find ||
      !_.isEqual(nextProps.newClausepartData, props.newClausepartData) ||
      nextProps.editModeOn !== props.editModeOn ||
      nextProps.areIssuesCollapsed !== props.areIssuesCollapsed ||
      nextProps.hideTextDeletions !== props.hideTextDeletions ||
      !_.isEqual(nextProps.document.comments, props.document.comments) ||
      nextProps.showClauseButtons !== props.showClauseButtons ||
      nextProps.documentLedReview !== props.documentLedReview ||
      nextProps.searchValue !== props.searchValue ||
      nextProps.addClausesToReportDocumentIssueId !==
      props.addClausesToReportDocumentIssueId ||
      nextProps.highlightFixes !== props.highlightFixes ||
      (props.showClauseButtons &&
        getManualCorrectionsIcons(
          nextProps.issues,
          nextProps.currentIssuesetItem,
          nextProps.selectedReportId,
        ) !==
          getManualCorrectionsIcons(
            props.issues,
            props.currentIssuesetItem,
            props.selectedReportId,
          ));
    return shouldUpdate;
    /* eslint-enable no-underscore-dangle */
  }

  hasMatchingIssueClause(issueReasonId, issues, clause) {
    const issueId = issueReasonId && parseInt(issueReasonId.split("_")[0], 10);
    const issue = issues.find(is => is.issue_id === issueId);

    if (!issue) {
      return false;
    }

    const clauseAtoms = getClauseAtomsMemo(
      `${clause.id}.${clause.last_edited}`,
      clause,
    );

    return isIssueInClause(issue, clauseAtoms);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.issues !== this.props.issues) {
      this.issuesUpdated = true;
    }
    if (this.props.reference) {
      this.scrollUpdater.componentDidUpdate(this.props, prevProps);
    }
    if (
      this.props.editableClauseId &&
      this.props.editableClauseId !== prevProps.editableClauseId &&
      this.props.editableClauseId === this.props.clause.id
    ) {
      window.addEventListener("click", this.handleClickOutside);
    }

    if (
      this.props.editableClauseId !== prevProps.editableClauseId &&
      prevProps.editableClauseId === this.props.clause.id
    ) {
      window.removeEventListener("click", this.handleClickOutside);
    }
    if (this.props.clauseToRender >= this.props.clause.row_number) {
      this.hasRendered = true;
    }
  }

  render() {
    const {
      reference,
      index,
      topics,
      shouldScroll,
      selectedHeading,
      announceVisibility,
      isSubclause,
      documentHeadings,
      hiddenHeadings,
      isMain,
      hasSectionFilledHeadings,
      clauseToRender,
      clause,
    } = this.props;

    if (clauseToRender === clause.row_number - 1) {
      return (
        <CircularProgress
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
          }}
        />
      );
    }
    if (clauseToRender < clause.row_number) {
      return <div style={{height: "0em"}} />;
    }
    const {lastHoveredClausepartId} = this.state;

    let heading = documentHeadings.find(
      _heading =>
        this.props.section.id === _heading.section_id &&
        _heading.reference === reference,
    );
    const isHidden = hiddenHeadings[reference];
    const doesHaveEditableClause = this.getDoesHaveEditableClause();
    const doesHaveEditableClauseHeading = this.getDoesHaveEditableClauseHeading();

    if (
      (!heading || !heading.text) &&
      isMain &&
      hasSectionFilledHeadings &&
      !doesHaveEditableClause &&
      !doesHaveEditableClauseHeading
    ) {
      heading = {text: " "};
    }

    // it is [1] because [0] is empty, as the header of
    // the top level ClausePartNode
    const path = isSubclause ? `root[1][${index}]` : "root";
    const clauseIssues = this.calculateClauseIssues();
    const {hoveredHeadingActionButton} = this.state;
    const props = _.omit(this.props, "addClausepartComment");

    return (
      <div
        key={`${reference}_${index}`}
        ref={this.createClauseReferenceRef(reference, index)}
        style={{
          padding: "0rem 2rem",
          background:
            hoveredHeadingActionButton === "revert" ||
            hoveredHeadingActionButton === "delete"
              ? "#ffa6ca"
              : "unset",
          transition: "background 1s",
        }}
        onMouseEnter={
          this.props.clauseAdditionChange
            ? () => this.setState(() => ({isClauseHovered: true}))
            : undefined
        }
        onMouseLeave={() =>
          this.setState({
            lastHoveredClausepartId: null,
            hoveredClausepartChildrenIds: [],
            isClauseHovered: false,
          })
        }
      >
        <ClauseHeading
          {...props}
          reference={reference}
          topics={topics}
          heading={heading || {}}
          shouldScroll={shouldScroll}
          selectedId={selectedHeading}
          announceVisibility={announceVisibility}
          isHidden={isHidden}
          clauseVisible={!isHidden}
          doesHaveEditableClause={doesHaveEditableClause}
          doesHaveEditableClauseHeading={doesHaveEditableClauseHeading}
          isClauseHeadingHovered={this.state.isClauseHeadingHovered}
          clauseHeadingHoverStart={this.clauseHeadingHoverStart}
          clauseHeadingHoverFinish={this.clauseHeadingHoverFinish}
          isAddedClauseHovered={
            this.props.clauseAdditionChange && this.state.isClauseHovered
          }
          setHoveredHeadingActionButton={this.setHoveredHeadingActionButton}
          isNumberItemsHovered={this.state.isNumberItemsHovered}
          onNumberItemHoverStart={this.onNumberItemHoverStart}
          onNumberItemHoverFinish={this.onNumberItemHoverFinish}
        />
        {!isHidden && (
          <Clause
            {...props}
            showReference={
              this.props.showReference && (!heading || !heading.text)
            }
            issues={clauseIssues}
            path={path}
            heading={heading || {}}
            doesHaveEditableClause={doesHaveEditableClause}
            doesHaveEditableClauseHeading={doesHaveEditableClauseHeading}
            lastHoveredClausepartId={lastHoveredClausepartId}
            setLastHoveredClausepartId={this.setLastHoveredClausepartId}
            hoveredClausepartChildrenIds={
              this.state.hoveredClausepartChildrenIds
            }
            setHoveredClausepartChildrenIds={
              this.setHoveredClausepartChildrenIds
            }
            addClausepartComment={this.addClausepartComment}
            isNumberItemsHovered={this.state.isNumberItemsHovered}
            onNumberItemHoverStart={this.onNumberItemHoverStart}
            onNumberItemHoverFinish={this.onNumberItemHoverFinish}
          />
        )}
      </div>
    );
  }

  calculateClauseIssues() {
    if (this.issuesUpdated || !this.issuesInClause) {
      const {clause} = this.props;
      const clauseAtoms = getClauseAtomsMemo(
        `${clause.id}.${clause.last_edited}`,
        clause,
      );
      this.issuesUpdated = false;
      this.issuesInClause = _.chain(this.props.issues)
        .filter(issue =>
          (issue.currentApplicableClauses || []).find(applicableClause =>
            clauseAtoms.find(atom => applicableClause.id === atom.id),
          ),
        )
        .value();
    }
    return this.issuesInClause;
  }

  getDoesHaveEditableClause = () => {
    const {clause, editableClauseId} = this.props;
    return editableClauseId && editableClauseId === clause.id;
  };

  getDoesHaveEditableClauseHeading = () => {
    const {clause, editableClauseHeading} = this.props;
    return (
      editableClauseHeading &&
      editableClauseHeading.reference === clause.reference
    );
  };

  handleClickOutside = event => {
    const target = event.target;
    const currentClauseRef = this[
      `clause_${this.props.reference}_${this.props.index}`
    ];
    const {documentTopPanelNode} = this.props;
    if (
      currentClauseRef &&
      !currentClauseRef.contains(target) &&
      (documentTopPanelNode && !documentTopPanelNode.contains(target))
    ) {
      this.props.hideClauseEditor();
    }
  };

  setLastHoveredClausepartId = id => {
    if (id !== this.state.lastHoveredClausepartId) {
      this.setState(() => ({lastHoveredClausepartId: id}));
    }
  };

  setHoveredClausepartChildrenIds = ids => {
    if (this.props.editModeOn) {
      this.setState(() => ({hoveredClausepartChildrenIds: ids}));
    }
  };

  clauseHeadingHoverStart = () => {
    if (this.props.editModeOn) {
      this.setState(() => ({
        isClauseHeadingHovered: true,
        lastHoveredClausepartId: false,
        hoveredClausepartChildrenIds: [],
      }));
    }
  };

  clauseHeadingHoverFinish = () => {
    this.setState(() => ({isClauseHeadingHovered: false}));
  };

  setHoveredHeadingActionButton = hoveredHeadingActionButton =>
    this.setState(() => ({hoveredHeadingActionButton}));

  createClauseReferenceRef = (reference, index) => node =>
    (this[`clause_${reference}_${index}`] = node);

  addClausepartComment = (clausepartId, text, isExternal) =>
    this.props.addClausepartComment(
      this.props.clause.id,
      clausepartId,
      text,
      isExternal,
    );

  getClause = clause => {
    const obj = this.getClauseValues(clause);
    this.onNumberItemHoverStart(obj);
  };

  getClauseValues = clause => {
    if (!clause.clauseNodes) {
      return {
        id: clause.id,
        reference: clause.reference,
      };
    } else {
      return this.getClauseValues(clause.clauseNodes[0]);
    }
  };

  onNumberItemHoverStart = clause => {
    if (this.props.addClausesToReportDocumentIssueId) {
      const obj = this.getClauseValues(clause);
      const value = calculateHoveredClauseparts(this.props.clause, obj);
      this.setState({isNumberItemsHovered: value});
    }
  };

  onNumberItemHoverFinish = () => this.setState({isNumberItemsHovered: null});
}

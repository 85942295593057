import React from "react";
import moment from "moment";

import * as colors from "@material-ui/core/colors";

import TopPanelContainer from "../top_panel_container";
import downloadDocx from "../utils/download_docx";
import constructDocument from "./construct_document";

const styles = {
  highlight: {
    backgroundColor: colors.blue[50],
    padding: "2px",
  },
};

function constructFilename(document) {
  if (!document || !document.name) {
    return "Notice_Of_Assignment.docx";
  }
  const fullName = document.name;
  const lastDotIndex = fullName.lastIndexOf(".");
  const name =
    lastDotIndex > 0 ? fullName.substring(0, lastDotIndex) : fullName;
  return `${name}-notice_of_assignment.docx`;
}

const values = {
  14922: {
    "facilities agreement":
      "FACILITY AGREEMENT FOR A USD 126,000,000 TERM LOAN FACILITY",
    "31st December, 2019": "13th November 2014",
    "B Bank": "CREDIT SUISSE AG",
    "Software Venture PLC": "NAVIOS MARITIME MIDSTREAM PARTNERS L.P.",
    "C Bank": "CREDIT SUISSE (UK) LTD",
    "A Bank": "ABC Global Bank",
  },
  14956: {
    "facilities agreement": "Facility agreement",
    "31st December, 2019": "18th August 2019",
    "B Bank": "Premium Financial Ltd",
    "Software Venture PLC": "Global Company Ltd",
    "C Bank": "Fundamental Trust Ltd",
    "A Bank": "ABC Global Bank",
  },
  14960: {
    "facilities agreement":
      "Credit Agreement regulated by the Consumer Credit Act 1974. Running Account Credit. ",
    "31st December, 2019": "24th March 2016",
    "B Bank": "Next Retail Limited",
    "Software Venture PLC": "Paul Brown",
    "C Bank": "Next Sourcing Ltd",
    "A Bank": "ABC Global Bank",
  },
  14883: {
    "facilities agreement": "FACILITY AGREEMENT",
    "31st December, 2019": "40094",
    "B Bank ": "MGT CAPITAL INVESTMENTS LIMITED",
    "Software Venture PLC": "MONEYGATE GROUP LIMITED",
    // "C Bank": "N/A",
    // "A Bank": "N/A",
  },
  14961: {
    "facilities agreement": "Facility AGREEMENT",
    "31st December, 2019": "April 6th 2008",
    "B Bank": "The Council of the City of York of the Guildhall York",
    "Software Venture PLC": "SCIENCE CITY YORK",
    "C Bank": "Global Networks Bank",
    "A Bank": "ABC Global Bank",
  },
};

function getValue(documentId, text) {
  if (values[documentId]) {
    const value = values[documentId][text];
    return value !== undefined ? value : text;
  }
  return text;
}

function NoticeOfAssignment(props) {
  const onDocxDownload = () => {
    downloadDocx(constructDocument(), constructFilename(props.document));
  };

  const {id: documentId} = props.document;
  const lookup = str => getValue(documentId, str);
  return (
    <TopPanelContainer
      renderInSeparateWindow={props.renderInSeparateWindow}
      triangleRightOffset="82"
      rootDivStyles={props.rootDivStyles}
      hasExpandableView={true}
      onDownloadDocx={onDocxDownload}
    >
      <div>
        <p style={{textAlign: "center"}}>
          <strong>Notice of Assignment</strong>
        </p>
        <p>
          To:{" "}
          <span style={styles.highlight}>{lookup("Software Venture PLC")}</span>{" "}
          as Borrower
        </p>
        <p>
          From: <span style={styles.highlight}>{lookup("B Bank")}</span>
        </p>
        <p>
          CC:{" "}
          <span style={styles.highlight}>
            {lookup("C Bank")}, {lookup("A Bank")}
          </span>{" "}
          as Agent
        </p>
        <p>Date: {moment().format("MMMM Do YYYY")}</p>

        <p style={{textAlign: "center"}}>
          <strong>
            <span style={styles.highlight}>
              {lookup("Software Venture PLC")} –{" "}
              {lookup("facilities agreement")}
            </span>{" "}
            dated{" "}
            <span style={styles.highlight}>
              {lookup("31st December, 2019")}
            </span>
            (the "Agreement")
          </strong>
        </p>

        <ol>
          <li>
            <p style={{textAlign: "justify"}}>
              We refer to the Agreement. Terms defined in the Agreement have the
              same meaning in this Notice of Assignment unless given a different
              meaning in this Notice of Assignment.
            </p>
          </li>
          <li>
            <p style={{textAlign: "justify"}}>
              In accordance with the Agreement, we hereby give you notice that
              we have assigned all of our rights under the Agreement to{" "}
              {lookup("C Bank")}.
            </p>
          </li>
          <li>
            <p style={{textAlign: "justify"}}>
              This notice of assignment and any non-contractual obligations
              arising out of or in connection with it are governed by English
              law.
            </p>
          </li>
        </ol>

        <p>
          <span style={styles.highlight}>{lookup("B Bank")}</span>
        </p>
        <p>By:...............................................</p>

        <p>We acknowledge receipt of this notice of assignment</p>
        <p>
          <span style={styles.highlight}>{lookup("C Bank")}</span>
        </p>
        <p>By:...............................................</p>
      </div>
    </TopPanelContainer>
  );
}

export default NoticeOfAssignment;

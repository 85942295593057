import React from "react";
import {Route} from "react-router";

export default function(store, AppRoute) {
  const appRoute = new AppRoute(store);

  return (
    <Route
      path="/"
      component={appRoute.component}
      getChildRoutes={(...args) => appRoute.getChildRoutes(...args)}
    />
  );
}

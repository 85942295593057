export default function getAtomsFromClauses(sections) {
  const clauses = [];
  Object.values(sections).forEach(_clauses => clauses.push(..._clauses));

  const atoms = [];
  function getAtoms(nodes) {
    if (nodes && nodes.clauseNodes) {
      return nodes.clauseNodes.forEach(getAtoms);
    }
    return atoms.push(nodes);
  }
  clauses.forEach(section => {
    if (section && section.nodes) {
      getAtoms(section.nodes);
    }
  });

  return atoms;
}

import React, {useEffect, useRef} from "react";

import * as colors from "@material-ui/core/colors";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import TextField from "@material-ui/core/TextField";
import {blue} from "@material-ui/core/colors";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

import isIssueAlert from "common/utils/issues/is_issue_alert";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import {MenuItem, Select, InputLabel, FormControl} from "@material-ui/core";

const styles = {
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: "500",
    color: colors.blue[400],
    height: "4em",
  },
  thumbIcon: {
    height: "15px",
    width: "15px",
    margin: "0.14rem 0rem",
    cursor: "pointer",
  },
  author: {
    fontStyle: "italic",
    fontSize: "0.75rem",
    opacity: 0.7,
    paddingTop: "1rem",
    textAlign: "right",
  },
};

function AccuracyPanel(props) {
  const {issue, updateDocumentIssue, isFlipChecklistIcons} = props;
  const {user_verified: userVerified} = issue;
  const [isRequestPending, updateIsRequestPending] = React.useState(false);
  const [rejectReason, onRejectReasonUpdate] = React.useState(
    issue.reject_reason_details?.reason || "",
  );
  const [showThankyou, setShowThankyou] = React.useState(false);
  const rejectReasonRef = React.useRef();
  const hasRejectClicked = useRef(false);
  const showThumbsUp = false;

  useEffect(() => {
    if (userVerified === false && rejectReasonRef && hasRejectClicked.current) {
      hasRejectClicked.current = false;
      rejectReasonRef.current.focus();
    }
  }, [userVerified]);

  useEffect(() => {
    onRejectReasonUpdate(issue.reject_reason_details?.reason || "");
  }, [issue.id, issue.reject_reason_details?.reason]);

  const onRejectReasonChange = e => onRejectReasonUpdate(e.target.value);
  const onRejectReasonBlur = async () => {
    if (issue.reject_reason_details?.reason !== rejectReason) {
      updateIsRequestPending(true);
      const updates = {
        reject_reason_details: {
          ...issue.reject_reason_details,
          reason: rejectReason,
          user: {id: props.user.id, email: props.user.username},
        },
      };
      await updateDocumentIssue(issue, updates);
      updateIsRequestPending(false);
      showThankyouMessage();
    }
  };

  function showThankyouMessage() {
    setShowThankyou(true);
    setTimeout(() => setShowThankyou(false), 2000);
  }

  function revertUserVerified(issue) {
    const updates = {
      user_verified: null,
      should_be_issue: null,
      is_correctly_applied: null,
      reject_reason_details: null,
    };
    return updateDocumentIssue(issue, updates);
  }

  async function onIssueVerify() {
    if (isRequestPending) {
      return;
    }
    updateIsRequestPending(true);
    if (userVerified === null || userVerified === false) {
      const updates = {
        user_verified: true,
        is_correctly_applied: true,
        should_be_issue: isIssueAlert(issue),
      };
      await updateDocumentIssue(issue, updates);
    } else {
      await revertUserVerified(issue);
    }
    updateIsRequestPending(false);
  }

  async function onIssueReject() {
    if (isRequestPending) {
      return;
    }
    updateIsRequestPending(true);
    if (userVerified === null || userVerified === true) {
      const updates = {
        user_verified: false,
        is_correctly_applied: false,
        should_be_issue: !isIssueAlert(issue),
      };
      hasRejectClicked.current = true;
      updateDocumentIssue(issue, updates);
    } else {
      revertUserVerified(issue);
    }
    updateIsRequestPending(false);
  }

  return (
    <div
      style={{
        padding: "0rem 1rem",
        fontSize: getZoomedFontSize(15, "checklist", props.zoom),
      }}
    >
      <div
        style={{
          ...styles.headerContainer,
          color: isFlipChecklistIcons ? "#333333" : "#42a5f5",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <span>Feedback</span>
          <Tooltip title="Tell us how we did. All feedback is reviewed and incorporated to improve our models.">
            <InfoIcon style={{marginLeft: "0.5em", color: "#aaa"}} />
          </Tooltip>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {showThumbsUp && (
            <ThumbUpIcon
              onClick={onIssueVerify}
              style={{
                ...styles.thumbIcon,
                color: userVerified === true ? "#1d7400" : colors.grey[400],
              }}
            />
          )}
          <ThumbDownIcon
            onClick={onIssueReject}
            style={{
              ...styles.thumbIcon,
              color: userVerified === false ? "#bc0000" : colors.grey[400],
            }}
          />
        </div>
      </div>
      {userVerified === false && (
        <>
          <TextField
            inputRef={rejectReasonRef}
            label="Please tell us what is wrong"
            multiline
            fullWidth
            value={rejectReason}
            onChange={onRejectReasonChange}
            onBlur={onRejectReasonBlur}
            helperText={
              showThankyou ? (
                <span style={{color: blue[400]}}>Thanks for the feedback!</span>
              ) : null
            }
          />
          {props.user.is_admin && (
            <FormControl fullWidth style={{paddingTop: 5}}>
              <InputLabel id="reject-reason-status-label">Status</InputLabel>
              <Select
                fullWidth
                labelId="reject-reason-status-label"
                value={issue.reject_reason_details?.status}
                onChange={async ({target: {value}}) => {
                  updateIsRequestPending(true);
                  await updateDocumentIssue(issue, {
                    reject_reason_details: {
                      ...issue.reject_reason_details,
                      status: value,
                    },
                  });
                  updateIsRequestPending(false);
                  showThankyouMessage();
                }}
              >
                {["In Progress", "Resolved"].map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <div style={styles.author}>
            Last edited by: {issue.reject_reason_details?.user?.email}
          </div>
        </>
      )}
    </div>
  );
}

export default AccuracyPanel;

import React, {useCallback, useEffect, useState} from "react";
import LoopIcon from "@material-ui/icons/Loop";
import ReplayIcon from "@material-ui/icons/Replay";
import SaveTrackedChanges from "../../../icons/SaveTrackedChanges";
import PendingActions from "../../../icons/PendingActions";
import CellChild from "./cell_child";
import ContentsLight from "../../gui_text_editor/contents_light";
import GuiLight from "../../gui_text_editor/gui_light";
import {getUpdatedManualCorrections} from "../../../utils/manual_corrections_utils";
import constructHtmlFromChildren from "../utils/construct_html_from_children";
import fixLockedTextValue from "../utils/fix_locked_text_value";
import constructIssueNameTextValue from "../utils/construct_issue_name_text_value";

const styles = {
  icon: {
    width: 18,
    height: 18,
    cursor: "pointer",
    borderRadius: "50%",
    marginLeft: "2px",
  },
  block: {
    display: "flex",
    justifyContent: "space-between",
  },
};

const RowChild = props => {
  const {
    rowChild,
    rowChildIndex,
    issue,
    correctDocumentIssueManually,
    selectedReportId,
    showButtonsHandler,
    saveTrigger,
    cancelTrigger,
    updateIssueReviewState,
    updateFreeTextFieldValue,
    areAllItemsNumbered,
    updateAdditionalReportItems,
    saveButtonHandler,
  } = props;
  const isApplicableClauses =
    rowChild.fieldName === "clause_references" ||
    rowChild.fieldName === "clause_text";
  const isApplicableClausesBlank =
    isApplicableClauses && rowChild?.children?.[0]?.value === "Blank";
  const rowChildChildren = rowChild.children || [];
  const [isGuiEdited, updateIsGuiEdited] = useState(false);
  const selectedManualCorrections =
    issue?.manual_corrections?.[selectedReportId];
  const previousFieldValues =
    selectedManualCorrections?.previous_values?.[rowChild?.fieldName];
  const lockedTextValue =
    selectedManualCorrections?.locked_value?.[rowChild?.fieldName];

  const openTextInGuiEditor = () => {
    if (showButtonsHandler) {
      showButtonsHandler(true);
    }
    updateIsGuiEdited(true);
  };
  const closeTextInGuiEditor = () => {
    if (showButtonsHandler) {
      showButtonsHandler(false);
    }
    updateIsGuiEdited(false);
  };
  const [isHovered, updateIsHovered] = useState(false);
  const [isTextFieldEditing, setIsTextFieldEditing] = useState(false);
  const [isTrackChangeMode, setIsTrackChangeMode] = useState(false);
  const [cellChildTextValue, setCellChildTextValue] = useState(null);
  const onHoverStart = () => updateIsHovered(true);
  const onHoverFinish = () => updateIsHovered(false);

  const onTriggerClick = () => {
    if (issue && isApplicableClauses) {
      const baseFieldName = rowChild.fieldName;
      const oppositeFieldName =
        baseFieldName === "clause_text" ? "clause_references" : "clause_text";
      const correctedFieldValue =
        issue?.manual_corrections?.[selectedReportId]?.[
          "applicable_clauses_display_mode"
        ];
      const newCorrectedFieldValue = correctedFieldValue
        ? correctedFieldValue === baseFieldName
          ? null
          : oppositeFieldName
        : oppositeFieldName;

      correctDocumentIssueManually(issue, {
        manual_corrections: getUpdatedManualCorrections(
          issue.manual_corrections,
          selectedReportId,
          "applicable_clauses_display_mode",
          newCorrectedFieldValue,
        ),
      });
    }
  };

  const onRevertChanges = () => {
    const newValue = previousFieldValues.pop();
    correctDocumentIssueManually(issue, {
      manual_corrections: getUpdatedManualCorrections(
        issue.manual_corrections,
        selectedReportId,
        rowChild.fieldName,
        newValue,
        null,
        previousFieldValues,
      ),
    });
  };

  const onRevertTrackedChanges = () => {
    correctDocumentIssueManually(issue, {
      manual_corrections: getUpdatedManualCorrections(
        issue.manual_corrections,
        selectedReportId,
        rowChild.fieldName,
        rowChild.fieldName === "issue_name"
          ? constructIssueNameTextValue(cellChildTextValue, issue)
          : cellChildTextValue,
        null,
        false,
        null,
        "revert",
      ),
    });
    setIsTrackChangeMode(false);
  };

  const onSaveTrackedChanges = () => {
    const textValue = fixLockedTextValue(lockedTextValue);
    correctDocumentIssueManually(issue, {
      manual_corrections: getUpdatedManualCorrections(
        issue.manual_corrections,
        selectedReportId,
        rowChild.fieldName,
        rowChild.fieldName === "issue_name"
          ? constructIssueNameTextValue(textValue, issue)
          : textValue,
        null,
        false,
        null,
        "delete",
      ),
    });
    setIsTrackChangeMode(false);
  };

  const onTrackChanges = () => setIsTrackChangeMode(true);
  const getTextValue = value => setCellChildTextValue(value);
  const isTextFieldOnEditing = isEdit => setIsTextFieldEditing(isEdit);
  const onLoopIconDoubleClick = e => e.stopPropagation();

  const updateReviewState = useCallback(() => {
    const newValue = issue.review_state === 3 ? 1 : issue.review_state + 1;
    updateIssueReviewState(issue, newValue);
  }, [issue?.updateCount ?? 0, issue?.manualUpdateCount ?? 0]);
  const markdownFields = {
    detailed_positive_reason: true,
    detailed_reason: true,
  };
  const renderRowChildChildren = rowChildren =>
    rowChildren.map((cellChild, cellChildIndex) => (
      <CellChild
        key={`${rowChildIndex}_${cellChildIndex}`}
        cellChild={cellChild}
        rowChild={rowChild}
        cellChildIndex={`${rowChildIndex}-${cellChildIndex}`}
        updateReviewState={updateReviewState}
        isTextFieldOnEditing={isTextFieldOnEditing}
        lockedTextValue={lockedTextValue}
        getTextValue={getTextValue}
        isTrackChangeMode={isTrackChangeMode}
        project={props.project}
        updateDocumentIssue={props.updateDocumentIssue}
        correctDocumentIssueManually={props.correctDocumentIssueManually}
        user={props.user}
        scrollToClause={props.scrollToClause}
        selectedReportId={selectedReportId}
        issueset={props.issueset}
        showButtonsHandler={showButtonsHandler}
        saveTrigger={saveTrigger}
        cancelTrigger={cancelTrigger}
        updateFreeTextFieldValue={updateFreeTextFieldValue}
        updateAdditionalReportItems={updateAdditionalReportItems}
        issue={issue}
        isMarkdown={markdownFields[rowChild.fieldName]}
      />
    ));

  const renderEditableInGuiRowChild = () => {
    const isFreeText = rowChild.fieldName === "free_text";
    let manuallyCorrectedValue;
    let value;
    if (isFreeText) {
      value = rowChild.children[0].value;
    } else {
      manuallyCorrectedValue = rowChild.correctedValue;
      value =
        manuallyCorrectedValue || constructHtmlFromChildren(rowChildChildren);
    }

    const onGuiValueSave = newValue => {
      if (isGuiEdited) {
        closeTextInGuiEditor();
        if (rowChild.additional_item) {
          return updateAdditionalReportItems({
            id: rowChild.id,
            value: newValue,
            type: "clause_display",
          });
        }
        if (isFreeText) {
          if (rowChild.field_id) {
            updateFreeTextFieldValue(rowChild.field_id, newValue);
          }
        } else {
          correctDocumentIssueManually(issue, {
            manual_corrections: getUpdatedManualCorrections(
              issue.manual_corrections,
              selectedReportId,
              rowChild.fieldName,
              newValue,
            ),
          });
        }
      }
    };

    const [newTextValue, setNewTextValue] = useState(null);

    useEffect(() => {
      if (isGuiEdited) {
        onGuiValueSave(newTextValue);
      }
    }, [saveTrigger]);

    useEffect(() => {
      if (isGuiEdited) {
        closeTextInGuiEditor();
      }
    }, [cancelTrigger]);

    if (isGuiEdited) {
      const onRevertValue = () => onGuiValueSave(undefined);
      const addGuiValue = () => saveButtonHandler();

      return (
        <GuiLight
          contentsHTML={value === "<p>Blank</p>" ? "" : value}
          onChange={setNewTextValue}
          isValueChanged={Boolean(manuallyCorrectedValue)}
          onClose={closeTextInGuiEditor}
          onSave={addGuiValue}
          onRevert={onRevertValue}
          saveOnBlur={true}
        />
      );
    } else if (
      (manuallyCorrectedValue || isFreeText || rowChild.additional_item) &&
      !isGuiEdited
    ) {
      return <ContentsLight contentsHTML={value} />;
    }
    return renderRowChildChildren(rowChildChildren);
  };

  switch (rowChild.type) {
    case "td": {
      return (
        <td
          key={rowChildIndex}
          style={{
            padding: "0.3rem",
            ...(rowChild.isManuallyCorrected ? {background: "#fcffd9"} : {}),
          }}
          colSpan={rowChild.columnSpan ? rowChild.columnSpan + 1 : null}
          onDoubleClick={
            rowChild.isEditableInGui ? openTextInGuiEditor : undefined
          }
          onMouseEnter={!isApplicableClausesBlank ? onHoverStart : undefined}
          onMouseLeave={!isApplicableClausesBlank ? onHoverFinish : undefined}
        >
          <div style={styles.block}>
            <div style={{width: "100%"}}>
              {rowChild.isEditableInGui
                ? renderEditableInGuiRowChild()
                : renderRowChildChildren(rowChildChildren)}
            </div>
            {isApplicableClauses ? (
              <div title="Flip between clauses and references">
                <LoopIcon
                  style={{
                    ...styles.icon,
                    visibility:
                      !isApplicableClausesBlank && isHovered && !isGuiEdited
                        ? "visible"
                        : "hidden",
                  }}
                  onClick={onTriggerClick}
                  onDoubleClick={onLoopIconDoubleClick}
                />
              </div>
            ) : null}
            <div style={{width: "18px", marginLeft: "5px"}}>
              {(previousFieldValues || lockedTextValue) &&
              !isTextFieldEditing ? (
                <div
                  title={
                    lockedTextValue
                      ? "Revert tracked changes"
                      : "Revert changes"
                  }
                >
                  <ReplayIcon
                    style={{
                      ...styles.icon,
                      visibility:
                        !isApplicableClausesBlank && isHovered && !isGuiEdited
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={
                      lockedTextValue ? onRevertTrackedChanges : onRevertChanges
                    }
                  />
                </div>
              ) : null}
              {lockedTextValue && !isTextFieldEditing ? (
                <div style={{width: "18px"}} title="Save tracked changes">
                  <SaveTrackedChanges
                    style={{
                      ...styles.icon,
                      visibility:
                        !isApplicableClausesBlank && isHovered && !isGuiEdited
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={onSaveTrackedChanges}
                  />
                </div>
              ) : null}
              {rowChild.isEditable &&
              !rowChild.additional_item &&
              !isTrackChangeMode &&
              !isTextFieldEditing &&
              !lockedTextValue ? (
                <div style={{width: "18px"}} title="Track changes">
                  <PendingActions
                    style={{
                      ...styles.icon,
                      visibility:
                        !isApplicableClausesBlank && !isGuiEdited && isHovered
                          ? "visible"
                          : "hidden",
                    }}
                    onClick={onTrackChanges}
                  />
                </div>
              ) : null}
            </div>
          </div>
        </td>
      );
    }
    case "number": {
      return areAllItemsNumbered ? (
        <td key={`number_${rowChildIndex}`} style={{padding: "0.3rem"}}>
          {renderRowChildChildren(rowChildChildren)}
        </td>
      ) : null;
    }
    default:
      return null;
  }
};

export default RowChild;

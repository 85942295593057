import {get} from "lodash";

export function filterConditionFn(match) {
  return (baseArray = [], ...args) => {
    /* const context = */ args.pop();

    const pairs = [];
    for (let idx = 0; idx < args.length; idx += 2) {
      pairs.push({key: args[idx], condition: args[idx + 1]});
    }
    const filteredArray = baseArray.filter(item =>
      pairs.every(filter => {
        const rawValue = fetchValue(item, filter.key);
        if (
          typeof rawValue === "number" &&
          typeof filter.condition === "number"
        ) {
          return rawValue === filter.condition;
        }
        const value =
          (Array.isArray(rawValue) ||
          typeof rawValue === "object" ||
          typeof rawValue === "boolean" ||
          typeof rawValue === "boolean" ||
          typeof rawValue === "number"
            ? JSON.stringify(rawValue)
            : rawValue) || "";
        const result = Array.isArray(filter.condition)
          ? filter.condition.some(condition =>
              value.match(escapeRegex(condition)),
            )
          : value.match(escapeRegex(filter.condition));
        return match ? result : !result;
      }),
    );
    return filteredArray;
  };
}

function escapeRegex(text) {
  return text.replace("[", "\\[").replace("]", "\\]");
}

function fetchValue(item, rawPath) {
  if (rawPath === "root") {
    return item;
  }

  const path = rawPath
    .toString()
    .replace(/\[/g, "")
    .replace(/\]/g, ".")
    .replace(/\.\./g, ".")
    .replace(/\.$/g, "");
  return get(item, path);
}

const filterCondition = filterConditionFn(true);

export default filterCondition;

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {withStyles} from "@material-ui/core/styles";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ReplayIcon from "@material-ui/icons/Replay";
import HelpIcon from "@material-ui/icons/Help";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import WarningIcon from "@material-ui/icons/Warning";

import getZoomedFontSize from "utils/get_zoomed_font_size";
import Permissioner from "utils/permissioner";
import getRoleColor from "../report/utils/get_role_color";

import Party from "./party";
import calculateShowOnlyRelevantParties from "common/utils/calculate_show_only_relevant_parties";

const PARTY_NAME_MAX_LENGTH = 20;
const MAX_PARTIES = 6;
const BLANK_PARTY = "<BLANK>";

const partyText = {
  method: {
    project_role_alias: "Project settings",
    role_alias: "Role settings",
  },
  source: {
    document_party_name: "Party name",
    document_party_term: "Party term",
  },
};

const styles = {
  list: {
    margin: 0,
    paddingLeft: 15,
  },
  editorRoot: {
    "& > .MuiIconButton-root, & $editorItem > .MuiIconButton-root": {
      "&": {
        marginRight: 8,
        color: "#8e959b",
      },
      "& svg": {
        fontSize: 16,
      },
    },
  },
  editorItem: {
    display: "flex",
    alignItems: "center",
    maxWidth: 327,
    "&:first-child": {
      marginTop: 8,
    },
    "&, & > div": {
      width: "100%",
    },
    "& + &": {
      marginTop: 10,
    },
    "& .MuiAutocomplete-root": {
      flexGrow: 1,
      "& .MuiTextField-root": {
        width: "100%",
      },
      "& .MuiFormLabel-root": {
        transform: "translate(14px, 12px) scale(1)",
        fontSize: 15,
        "&.MuiInputLabel-shrink": {
          transform: "translate(14px, -6px) scale(0.75)",
        },
      },
      "& .MuiInputBase-root": {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 14,
      },
    },
    "& + .MuiIconButton-root": {
      marginTop: 4,
    },
    "& .MuiSelect-root": {
      padding: "0.5em 14px",
    },
  },
  errorMessage: {
    color: "#e0173c",
    fontSize: 11,
    padding: "0.5rem 0.25rem",
  },
  mergeTermsButtonLabel: {
    textTransform: "uppercase",
    fontSize: 12,
  },
  newValue: {
    "& .MuiInputBase-root": {
      color: "#1f88e5",
    },
  },
  duplicateValue: {
    "& .MuiInputBase-root": {
      color: "#e0173c",
    },
    "& .MuiFormLabel-root": {
      color: "#e0173c",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#e0173c !important",
    },
  },
  tableHeading: {
    textAlign: "left",
    fontWeight: 400,
    color: "#9e9e9e",
  },
  tableElement: {
    width: "18rem",
    fontSize: "16px",
    overflowY: "auto",
  },
  nameWrapper: {
    maxHeight: "43px",
    overflowY: "auto",
  },
  noParties: {
    padding: "0.5em",
    border: "1px solid #797979",
    borderRadius: "5px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "1em",

    textAlign: "center",
    fontWeight: "bold",
    fontStyle: "italic",

    backgroundColor: "#ccc",
  },
  headerButton: {
    padding: "5px",
  },
  headerButtonLabel: {
    lineHeight: "1.2em",
  },
};

const isDuplicateParty = (partyA, partyB) =>
  partyA !== partyB &&
  partyA.name === partyB.name &&
  partyA.name !== "" &&
  partyA.name !== null;

function PartyMoreMenu({resetRoles, revertChanges, hasChanges, isAdmin}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <IconButton size="small" ref={anchorRef} onClick={handleToggle}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorRef.current} open={open} onClose={handleClose}>
        {isAdmin ? <MenuItem onClick={resetRoles}>Reset roles</MenuItem> : null}
        <MenuItem disabled={!hasChanges} onClick={revertChanges}>
          Revert Changes
        </MenuItem>
      </Menu>
    </div>
  );
}

class PartyList extends React.Component {
  static propTypes = {
    organisationParties: PropTypes.array.isRequired,
    documentParties: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    const showOnlyRelevantParties = calculateShowOnlyRelevantParties(
      props.documentParties,
      props.roles,
    );

    this.state = {
      updateCount: props.updateCount,
      parties: _.chain(props.documentParties)
        .map(party => {
          const values = _.pick(party, [
            "name",
            "term",
            "role_id",
            "id",
            "is_role_empty",
            "role_match_text",
            "role_match_source",
            "role_match_method",
            "document_party_id",
          ]);
          return {
            ...values,
            role_name: this.getRoleName(party),
          };
        })
        .sortBy(["role_name", "name"])
        .value(),
      isEditing: props.editingMode ?? false,
      isHovered: false,
      isRolesConfirmed: true,
      showOnlyRelevantParties,
    };
  }

  componentDidMount() {
    if (
      this.props.areRolesConfirmed === false &&
      this.props.forcePartyConfirmation
    ) {
      this.setState(() => {
        return {
          isEditing: true,
          isHovered: false,
          isRolesConfirmed: false,
        };
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.updateCount !== state.updateCount) {
      const showOnlyRelevantParties = calculateShowOnlyRelevantParties(
        props.documentParties,
        props.roles,
      );
      const updates = {
        updateCount: props.updateCount,
        showOnlyRelevantParties,
        parties: state.parties.map(stateParty => {
          const propParty = props.documentParties.find(
            docParty =>
              (stateParty.id !== null && docParty.id === stateParty.id) ||
              (!stateParty.id &&
                docParty.document_party_id &&
                docParty.document_party_id === stateParty.document_party_id) ||
              (!stateParty.id &&
                !stateParty.document_party_id &&
                (docParty.term === stateParty.term ||
                  docParty.name === stateParty.name)) ||
              (stateParty.id !== docParty.id &&
                stateParty.name === docParty.name),
          );
          if (
            propParty &&
            (propParty.role_id !== stateParty.role_id ||
              propParty.is_role_empty !== stateParty.is_role_empty ||
              propParty.term !== stateParty.term)
          ) {
            return {
              ..._.omit(stateParty, ["new_role_id"]),
              ..._.pick(propParty, [
                "term",
                "is_role_empty",
                "role_id",
                "role_match_text",
                "role_match_source",
                "role_match_method",
                "document_party_id",
              ]),
            };
          } else if (!stateParty.id) {
            const termPropParty = props.documentParties.find(
              docParty => docParty.term === stateParty.term,
            );
            return {
              ...stateParty,
              name: stateParty.name || BLANK_PARTY,
              ..._.pick(termPropParty, ["name", "document_party_id"]),
            };
          }
          return stateParty;
        }),
      };
      return updates;
    }
    return null;
  }

  render() {
    return this.state.isEditing ? this.renderEditor() : this.renderList();
  }

  renderList() {
    let content;
    const {context, isHorizontal, user} = this.props;
    const {parties, showOnlyRelevantParties} = this.state;
    const canEdit = this.canUserEditParties();
    if (!parties || !parties.length) {
      content = isHorizontal ? <div>No Parties Identified</div> : <div>—</div>;
    } else {
      const filteredParties = showOnlyRelevantParties
        ? parties.filter(party => party.role_id !== null)
        : parties;

      const partyNames = filteredParties.map(party => {
        const termName = `Term: ${party.term}`;
        return {
          role_id: party.role_id,
          fullName: party.name ?? termName,
          shortName: party.name
            ? party.name?.length > PARTY_NAME_MAX_LENGTH
              ? `${party.name.slice(0, PARTY_NAME_MAX_LENGTH)}...`
              : party.name
            : termName,
        };
      });
      content = isHorizontal
        ? this.renderHorizontalList(filteredParties, partyNames, context)
        : this.renderVerticalList(filteredParties, partyNames, user);
    }
    return (
      <div
        onMouseEnter={this.onHoverStart}
        onMouseLeave={this.onHoverFinish}
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "default",
        }}
        onDoubleClick={
          this.state.isEditing
            ? undefined
            : this.canUserEditParties() && this.onEditingStart
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",

            overflowX: "hidden",
            flexShrink: 1,
          }}
        >
          {isHorizontal && (
            <div
              style={{
                marginRight: 24,
                fontSize: 17,
                fontWeight: 500,
                color: "#757575",
              }}
            >
              PARTIES
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              ...(this.props.isSmall ? {} : {flexWrap: "wrap"}),
            }}
          >
            {content}
          </div>
        </div>
        <div style={{display: "flex", textAlign: "center"}}>
          {canEdit && this.state.isHovered && !this.state.isEditing ? (
            <IconButton size="small">
              <EditIcon
                style={{
                  width: 18,
                  height: 18,
                  marginRight: 4,
                  flexShrink: 0,
                  cursor: "pointer",
                }}
                onClick={this.onEditingStart}
              />
            </IconButton>
          ) : null}
          {isHorizontal && !this.areRolesAssigned() && (
            <div style={{flexShrink: 0}}>
              <Tooltip
                title="Parties are not correctly setup"
                placement="bottom-end"
              >
                <IconButton size="small">
                  <WarningIcon
                    style={
                      this.props.isSmall
                        ? {
                            width: 18,
                            height: 18,
                          }
                        : {}
                    }
                  />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderHorizontalList(parties, partyNames, context) {
    const containerStyle = this.props.isSmall
      ? {
          fontSize: context
            ? getZoomedFontSize(13, "document", context.zoom)
            : 13,
          maxWidth: "10em",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }
      : {
          fontSize: context
            ? getZoomedFontSize(16, "document", context.zoom)
            : 14,
        };

    const textStyle = this.props.isSmall
      ? {
          fontSize: context
            ? getZoomedFontSize(13, "document", context.zoom)
            : 13,
        }
      : {
          fontSize: context
            ? getZoomedFontSize(14, "document", context.zoom)
            : 12,
        };
    return parties.map((party, index) => {
      const colors = getRoleColor(
        party,
        this.props.roles,
        this.props.projectRoles,
      );
      return (
        <div
          key={`${party.id || party.term} - ${index}`}
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: 10,
            padding: "5px",
            borderRadius: "3px",
            ...colors,
          }}
        >
          <div
            style={{
              ...styles.nameWrapper,
              color: colors?.color ? colors.color : "#424242",
              ...containerStyle,
            }}
            title={party.term}
          >
            {partyNames[index].shortName}
          </div>
          <div
            style={{
              ...textStyle,
              color:
                colors?.color && colors.color === "#fff"
                  ? party.role_match_method === "manual"
                    ? "#b4b4b4"
                    : "#f2f2f2"
                  : party.role_match_method === "manual"
                  ? "#333"
                  : "#757575",
            }}
            title={this.getMatchDescription(party)}
          >
            {party.role_id
              ? this.props.roles.find(role => role.id === party.role_id)?.name
              : "No Role Set"}
          </div>
        </div>
      );
    });
  }

  renderVerticalList(parties, partyNames, user) {
    const extraParties =
      !user.is_admin && partyNames.length > 3 && partyNames.length;
    if (extraParties) {
      partyNames.length = 2;
    }
    return (
      <ul className={this.props.classes.list}>
        {partyNames.map((partyName, index) => (
          <li key={index} title={partyName.fullName}>
            {partyName.shortName}
            {user.is_admin ? ` (${this.getRoleName(partyName, document)})` : ""}
          </li>
        ))}
        {extraParties && (
          <li title={_.map(parties, "name").join(", ")}>
            {extraParties - 2} More Parties
          </li>
        )}
      </ul>
    );
  }

  getRoleName(party) {
    const {roles} = this.props;
    if (!party.role_id) {
      return "No Role Set";
    }
    const matchingRole = roles.find(role => role.id === party.role_id);
    if (matchingRole) {
      return matchingRole.name;
    }
    return `Role not found (${party.role_id})`;
  }

  canUserEditParties() {
    const permissions = new Permissioner(this.props.user);
    return (
      this.props.isHorizontal &&
      (permissions.isAdmin() || permissions.hasPermission("can-edit-parties"))
    );
  }

  getMatchDescription(party) {
    if (!party.role_id && !party.is_role_empty) {
      return null;
    }
    if (party.is_role_empty || party.role_match_method === "manual") {
      return "Manually assigned";
    }
    if (party.role_match_method === "remainder") {
      return "Matched automatically, as one party and one role was left";
    }
    return [
      `Matched ${partyText.source[party.role_match_source]}`,
      `from ${partyText.method[party.role_match_method]}`,
      `using alias "${party.role_match_text}"`,
    ].join(" ");
  }

  renderEditor() {
    const {
      organisationId,
      organisationParties,
      classes,
      isHorizontal,
      isSmall,
      roles,
      hideTerm,
    } = this.props;

    const nameOptions = _.chain(organisationParties).sortBy("name").value();

    const {parties, isEditing, showOnlyRelevantParties} = this.state;

    const partiesToRender = showOnlyRelevantParties
      ? parties.filter(party => party.role_id)
      : parties;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            flexShrink: 0,
          }}
        >
          {this.renderButtonBar()}
          <div
            style={{
              margin: this.props.isSmall ? "0" : "0 1em",
            }}
          >
            {roles.length > 0 && (
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: this.props.isSmall ? "1em" : "1.25em",
                  margin: "0.5em",
                }}
              >
                {organisationId === 74
                  ? "Please check and confirm the parties’ details"
                  : `Please confirm which parties are the ${this.joinList(
                      this.getRolesNames(roles),
                      "and",
                    )}`}
              </div>
            )}
            {this.renderHelpPrompts()}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: isHorizontal ? "row" : "column",
            alignItems: "center",
            flexWrap: "wrap",
            overflow: "auto",
            ...(isSmall ? {} : {paddingRight: "28px"}),
          }}
          className={classes.editorRoot}
        >
          {!partiesToRender.length && isEditing ? (
            <div style={styles.noParties}>No Parties</div>
          ) : (
            partiesToRender.map((party, index) => (
              <Party
                key={index}
                index={index}
                party={party}
                hideTerm={hideTerm}
                classes={classes}
                nameOptions={nameOptions}
                parties={parties}
                organisationParties={this.props.organisationParties}
                roles={roles}
                updatePartyName={this.updatePartyName}
                updatePartyTerm={this.updatePartyTerm}
                updatePartyRole={this.updatePartyRole}
                removeParty={this.removeParty}
                duplicateParty={parties.find(isDuplicateParty)}
                mergeParty={this.mergeParty}
              />
            ))
          )}
        </div>
        {this.state.confirmingFinish && this.renderConfirmationDialog()}
      </div>
    );
  }

  renderButtonBar() {
    const {isHorizontal} = this.props;
    const {parties, showOnlyRelevantParties, isRolesConfirmed} = this.state;

    const areRolesAssigned = this.areRolesAssigned();

    const renderButtonsAtTop = isHorizontal;
    if (!renderButtonsAtTop) {
      return null;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingRight: "15px",
          backgroundColor: "#f8f8f8",
          padding: "11px",
          margin: "3px",
          border: "1px solid #ddd",
          borderRadius: "5px",
          paddingBottom: "0",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            marginBottom: "10px",
            marginRight: "10px",
            gap: "10px",
          }}
        >
          {this.renderPartySave(parties, isRolesConfirmed, areRolesAssigned)}
        </div>
        <div
          style={{
            display: "inline-flex",
            marginBottom: "10px",
            gap: "10px",
          }}
        >
          {this.renderAddPartyButton(parties, showOnlyRelevantParties)}
          {this.renderShowAllPartiesButton(parties, showOnlyRelevantParties)}
          <PartyMoreMenu
            isAdmin={this.props?.user?.is_admin}
            resetRoles={this.resetRoles}
            revertChanges={this.onRevertParties}
            hasChanges={this.haveChanges()}
          />
        </div>
      </div>
    );
  }

  renderAddPartyButton(parties, showOnlyRelevantParties) {
    return !showOnlyRelevantParties && parties.length < MAX_PARTIES ? (
      <Button
        variant="contained"
        size="small"
        onClick={this.addParty}
        style={styles.headerButton}
      >
        <span style={styles.headerButtonLabel}>Add Party</span>
      </Button>
    ) : null;
  }

  renderShowAllPartiesButton(parties, showOnlyRelevantParties) {
    if (showOnlyRelevantParties) {
      return (
        <Button
          variant="contained"
          size="small"
          onClick={this.showAllParties}
          style={styles.headerButton}
        >
          <span style={styles.headerButtonLabel}>Show Hidden Parties</span>
        </Button>
      );
    }
  }

  renderRevertButton() {
    const {isHorizontal} = this.props;
    return this.haveChanges() ? (
      isHorizontal ? (
        <Button
          variant="contained"
          size="small"
          onClick={this.onRevertParties}
          style={styles.headerButton}
        >
          <span style={styles.headerButtonLabel}>Revert changes</span>
        </Button>
      ) : (
        <IconButton
          size="small"
          onClick={this.onRevertParties}
          title="Revert changes"
        >
          <ReplayIcon />
        </IconButton>
      )
    ) : null;
  }

  renderPartySave(parties, isRolesConfirmed, areRolesAssigned) {
    const {isHorizontal} = this.props;
    const hideDeletePartiesWithoutRole =
      !areRolesAssigned ||
      parties.every(party => "role_id" in party || "new_role_id" in party);

    return !isRolesConfirmed ? (
      <>
        {hideDeletePartiesWithoutRole ? null : (
          <Tooltip
            title={
              areRolesAssigned
                ? ""
                : "Please assign roles to parties before deleting those remaining"
            }
          >
            {/** Non-disabled wrapper required for Tooltip to detect hover... */}
            <span>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() =>
                  parties
                    .filter(party => !party.new_role_id && !party.role_id)
                    .forEach(this.removeParty)
                }
                style={styles.headerButton}
              >
                <span style={styles.headerButtonLabel}>
                  Delete Parties without role
                </span>
              </Button>
            </span>
          </Tooltip>
        )}
        <Tooltip
          title={
            areRolesAssigned
              ? ""
              : "Please assign roles to parties before confirming"
          }
        >
          <Button
            variant="contained"
            color={areRolesAssigned ? "primary" : ""}
            size="small"
            onClick={
              areRolesAssigned ? this.onEditingFinish : this.confirmFinish
            }
          >
            <span style={styles.headerButtonLabel}>Confirm Parties</span>
          </Button>
        </Tooltip>
      </>
    ) : this.haveChanges() ? (
      isHorizontal ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={areRolesAssigned ? this.onEditingFinish : this.confirmFinish}
        >
          Save
        </Button>
      ) : (
        <IconButton
          size="small"
          onClick={areRolesAssigned ? this.onEditingFinish : this.confirmFinish}
          title="Save changes"
        >
          <DoneIcon />
        </IconButton>
      )
    ) : isHorizontal ? (
      <Button variant="contained" size="small" onClick={this.onDoneEditing}>
        Close
      </Button>
    ) : (
      <IconButton size="small" onClick={this.onDoneEditing} title="Close">
        <CloseIcon />
      </IconButton>
    );
  }

  confirmFinish = () => {
    this.setState({confirmingFinish: true});
  };

  renderConfirmationDialog() {
    const {roles} = this.props;
    const {partiesWithRoleButNoTerm, unsetRoles} = this.calculateRoleErrors();

    const errorMessage =
      unsetRoles.length > 0 ? (
        <>
          <p>
            The {this.joinList(this.getRolesNames(unsetRoles), "and")}{" "}
            {unsetRoles.length > 1 ? "have" : "has"} not been identified.
          </p>
          <p>
            If parties are not correctly set, the accuracy of the checklist may
            be affected.
          </p>
        </>
      ) : partiesWithRoleButNoTerm.length > 0 ? (
        <>
          <p>
            A Defined Term has not been set for the{" "}
            {this.joinList(
              this.getRolesNames(
                partiesWithRoleButNoTerm
                  .map(party =>
                    roles.find(
                      role => role.id === (party.new_role_id || party.role_id),
                    ),
                  )
                  .filter(party => party),
              ),
              "and",
            )}
            .
          </p>
          <p>
            The terms used to identify parties should be included, otherwise the
            accuracy of the checklist may be affected.
          </p>
        </>
      ) : (
        "There is something wrong"
      );

    return (
      <Dialog onClose={this.closeDialog} open={true} maxWidth="md">
        <DialogTitle>
          <div style={{textAlign: "center"}}>Please complete party entry.</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>{" "}
        <DialogActions>
          <Button onClick={this.closeDialog} color="primary" autoFocus>
            Close
          </Button>
          <Button onClick={this.overrideDialog}>Ignore and Save</Button>
        </DialogActions>
      </Dialog>
    );
  }

  closeDialog = () => {
    this.setState({confirmingFinish: false});
  };
  overrideDialog = () => {
    this.closeDialog();
    this.onEditingFinish();
  };

  areRolesAssigned() {
    const good = _.chain(this.state.parties)
      .groupBy(party => party.new_role_id || party.role_id)
      .value();
    const areRolesAssigned =
      Object.keys(_.omit(good, ["undefined", "null", "-1"])).length ===
      this.props.roles.length;
    const doRolesHaveTerms =
      this.props.roles.length ===
      this.props.roles.filter(role =>
        this.state.parties.find(
          party =>
            (party.new_role_id || party.role_id) === role.id &&
            party?.term?.length,
        ),
      ).length;
    return areRolesAssigned && doRolesHaveTerms;
  }

  renderHelpPrompts() {
    const {roles} = this.props;
    const {
      partiesWithRoleButNoTerm,
      multipleSetRoles,
      unsetRoles,
      usedRoles,
    } = this.calculateRoleErrors();

    const prompts = [];

    if (multipleSetRoles.length >= 1) {
      prompts.push({
        text: `${this.joinList(this.getRolesNames(multipleSetRoles), "and")} ${
          multipleSetRoles.length === 1 ? "has" : "have"
        } been set multiple times - each role should only be set once.`,
        help:
          "Assigning a role indicates which party has what purpose in a contract. Each role should be assigned to a party only once, so that the system understands whose rights and obligations it should be analysing. If a party has different defined terms assigned to it in different schedules of the contract, specify them all in a comma separated list.",
      });
    }
    if (unsetRoles.length >= 1) {
      if (this.state.parties.length > 2 && this.state.showOnlyRelevantParties) {
        prompts.push({
          text: "Extra parties have been detected.",
          help:
            "Extra parties might exist because there are more than 2 parties to the contract, because the detection algorithm has detected different parties in other schedules to the contract, or may have mistook a similar looking paragraph. The system will only analyse the parties with roles assigned to it, you may either ignore other parties, or remove them if they have been added in error.",
        });
      }
      let partialPrompt = "";
      if (roles.length === unsetRoles.length) {
        prompts.push({
          text: "No roles have been set.",
          help: (
            <>
              <p>
                The system relies on assigning roles to parties for the purpose
                of understanding the different kinds of rights and obligations
                in the contract.
              </p>
              <p>
                If roles have not been able to be automatically assigned, please
                manually set them on the correct parties (or add parties if they
                are missing).
              </p>
              <p>
                You may find the parties in either the parties section at the
                top of the contract, the defined terms or you may need to read
                some clauses to see which parties are used.
              </p>
            </>
          ),
        });
      } else {
        partialPrompt = `Only ${this.joinList(this.getRolesNames(usedRoles))} ${
          unsetRoles.length === 1 ? "has" : "have"
        } been set. `;
      }
      prompts.push({
        text: `${partialPrompt}Please identify which part${
          unsetRoles.length > 1 ? "ies are" : "y is"
        } the ${this.joinList(this.getRolesNames(unsetRoles), "and")}.`,
        help:
          "When the system hasn't been able to identify the roles of the parties in the contract, it is necessary to do this before you commence the review so that the system appropriately assigns rights and obligations to the correct parties.",
      });
    }
    if (partiesWithRoleButNoTerm.length) {
      prompts.push({
        text:
          this.props.organisationId === 74
            ? "Please add the defined term for each party"
            : `Please set a Defined Term for the ${this.joinList(
                this.getRolesNames(
                  partiesWithRoleButNoTerm
                    .map(party =>
                      roles.find(
                        role =>
                          role.id === (party.new_role_id || party.role_id),
                      ),
                    )
                    .filter(party => party),
                ),
                "and",
              )}`,
        help:
          "The system requires each party with a role assigned to list the Defined Term, as it is used in the contract to describe the parties. This is used when analysing clauses to determine to whom rights and obligations belong.",
      });
    }
    return prompts.length ? (
      <div
        style={{
          border: "1px solid #7a98b5",
          backgroundColor: "#eaf5ff",
          maxWidth: "47.5em",
          borderRadius: "5px",
          marginLeft: "2px",
          marginRight: "2px",
          fontStyle: "italic",
          fontSize: "14px",
          marginBottom: "10px",
        }}
      >
        <ul
          style={{
            paddingLeft: this.props.isSmall ? "1em" : "2em",
            paddingRight: "0.5em",
            marginTop: "0.5em",
            marginBottom: "0.5em",
            listStyle: this.props.isSmall ? "none" : "initial",
          }}
        >
          {prompts.map((prompt, index) => (
            <li key={index} style={{marginBottom: "0.4em"}}>
              <div style={{display: "flex", alignItems: "center"}}>
                {prompt.text}
                <Tooltip title={prompt.help}>
                  <IconButton size="small" style={{marginLeft: "0.5em"}}>
                    <HelpIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </li>
          ))}
        </ul>
      </div>
    ) : null;
  }

  calculateRoleErrors() {
    const {roles} = this.props;
    const unsetRoles = roles.filter(
      role =>
        !this.state.parties.find(
          party => (party.new_role_id || party.role_id) === role.id,
        ),
    );
    const usedRoles = this.props.roles.filter(
      role => !unsetRoles.find(unsetRole => unsetRole.id === role.id),
    );
    const multipleSetRoles = _.chain(this.state.parties)
      .groupBy(party => party.new_role_id || party.role_id)
      .filter(partiesWithSameRole => partiesWithSameRole.length > 1)
      .map(([firstParty]) => firstParty.new_role_id || firstParty.role_id)
      .map(roleId => roles.find(role => role.id === roleId))
      .filter(value => value)
      .value();
    const partiesWithRoleButNoTerm = _.chain(this.state.parties)
      .filter(party => party.new_role_id || party.role_id)
      .filter(party => (party.new_role_id || party.role_id) > 0)
      .filter(party => !party.term || party.term.trim().length === 0)
      .value();

    return {
      partiesWithRoleButNoTerm,
      multipleSetRoles,
      unsetRoles,
      usedRoles,
    };
  }

  getRolesNames(roles) {
    return roles.map(role => role.name);
  }

  joinList(list, endJoiner = "or") {
    if (list.length === 1) {
      return list[0];
    }
    return `${list.slice(0, -1).join(", ")} ${endJoiner} ${list.slice(-1)}`;
  }

  reprocessRoles = () => {
    this.props.reprocessRoles();
    this.setState({isEditing: false});
  };

  resetRoles = () => {
    this.props.resetRoles();
    this.setState({isEditing: false});
  };

  onDoneEditing = () =>
    this.setState(() => ({
      isEditing: false,
      showOnlyRelevantParties: calculateShowOnlyRelevantParties(
        this.props.documentParties,
        this.props.roles,
      ),
    }));
  onHoverStart = () => this.setState(() => ({isHovered: true}));
  onHoverFinish = () => this.setState(() => ({isHovered: false}));

  haveChanges = () => {
    const propsDocs = _.sortBy(
      this.props.documentParties.map(p => _.pick(p, "name", "term", "role_id")),
      p => p.name,
    );

    const stateDocs = _.sortBy(
      this.state.parties.map(party => ({
        ..._.pick(party, "name", "term"),
        role_id: party.new_role_id || party.role_id,
      })),
      p => p.name,
    );
    return !_.isEqual(propsDocs, stateDocs);
  };

  onEditingStart = () => {
    const stateChanges = {isEditing: true, isHovered: false};
    if (
      !this.props.documentParties ||
      this.props.documentParties.length === 0
    ) {
      stateChanges.parties = [{name: ""}];
    }
    this.setState(() => stateChanges);
  };

  onEditingFinish = () => {
    const partiesDescribed = describePartiesChanges(
      this.props.documentParties,
      this.state.parties,
      this.props.organisationParties,
    );

    const changedParties = partiesDescribed.filter(party => party.type);
    if (!this.state.isRolesConfirmed) {
      this.props.onUpdate({
        partiesChanges: changedParties,
        are_roles_confirmed: true,
      });
    } else {
      if (changedParties.length > 0) {
        this.props.onUpdate({partiesChanges: changedParties});
      }
    }
    this.props.onChange && this.props.onChange(this.state.parties);
    this.setState({isEditing: false, isRolesConfirmed: true});
  };

  updatePartyName = (party, newName) => {
    this.setState(prev => ({
      parties: prev.parties.map(p => {
        return p === party
          ? {
              ...party,
              name: newName,
              role_id: p.role_id || null,
            }
          : p;
      }),
    }));
  };

  mergeParty = async (party, withParty) => {
    const combinedTerms = _.uniq(
      [party.term, withParty.term]
        .filter(term => term)
        .map(terms => terms.split(/, ?/))
        .flat(),
    ).join(", ");
    this.updatePartyTerm(party, combinedTerms);
    this.removeParty(withParty);
  };

  updatePartyTerm = (party, newTerm) => {
    this.setState({
      parties: this.state.parties.map(_party => {
        return _party !== party
          ? _party
          : {...party, term: newTerm, role_id: _party.role_id || null};
      }),
    });
  };

  updatePartyRole = (party, newRoleId) => {
    const newRoleIdInt = parseInt(newRoleId, 10);
    this.setState(prev => ({
      parties: prev.parties.map(_party => {
        return _party.id !== party.id ||
          (!party.id && _party.term !== party.term)
          ? _party
          : {..._party, new_role_id: newRoleIdInt < 0 ? -1 : newRoleIdInt};
      }),
    }));
  };

  addParty = () => {
    this.setState({
      parties: [...this.state.parties, ...[{name: ""}]],
    });
  };

  showAllParties = () => {
    this.setState({showOnlyRelevantParties: false});
  };

  onRevertParties = () => {
    this.setState(() => ({
      parties: this.props.documentParties.map(({id, term, name, role_id}) => ({
        id,
        name,
        term,
        role_id,
      })),
    }));
  };

  removeParty = party => {
    this.setState(prev => ({
      parties: prev.parties.filter(_party => _party !== party),
    }));
  };
}

function describePartiesChanges(
  propsParties,
  stateParties,
  organisationParties,
) {
  const uniqNameStateParties = _.chain(stateParties)
    .map(party => ({
      ..._.omit(
        party,
        "new_role_id",
        "role_match_source",
        "role_match_method",
        "role_match_text",
      ),
      name: party.name,
      is_name_blank: !party.name,
      role_id: party.new_role_id || party.role_id,
    }))
    .uniq(({name}) => name)
    .value();
  const updatedParties = [];
  uniqNameStateParties.forEach(stateParty => {
    const {name, term, role_id, document_party_id} = stateParty;
    const propsParty = propsParties.find(
      party =>
        (party.name !== null && party.name === name) ||
        // (!party.name && party.document_party_id === document_party_id),
        party.document_party_id === document_party_id,
    );
    const organisationParty = organisationParties.find(party => {
      return party.name === name;
    });
    if (propsParty) {
      const updatedParty = _.pick(propsParty, ["id", "name"]);
      const partyMatchKeys = ["name", "role_id", "term"];
      const isUpdated = !_.isEqual(
        _.pick(propsParty, partyMatchKeys),
        _.pick(stateParty, partyMatchKeys),
      );
      if (isUpdated) {
        updatedParty.type = "update";
        if (stateParty.term !== propsParty.term) {
          updatedParty.term = term;
          if (!propsParty.id) {
            updatedParty.document_party_id = document_party_id;
          }
        }
        if (stateParty.role_id !== propsParty.role_id) {
          updatedParty.role_id = role_id;
          updatedParty.prev_role_id = propsParty.role_id;
        }
        if (stateParty.name !== propsParty.name) {
          updatedParty.document_party_id = document_party_id;
          if (organisationParty) {
            updatedParty.id = organisationParty.id;
            updatedParty.name = organisationParty.name;
          } else {
            updatedParty.name = name;
          }
        }
      }
      updatedParties.push(updatedParty);
    } else if (organisationParty) {
      updatedParties.push({
        ..._.pick(organisationParty, "id", "name"),
        role_id,
        term,
        type: "add",
      });
    } else {
      updatedParties.push({
        name,
        term,
        type: "new",
        role_id,
      });
    }
  });
  propsParties.forEach(party => {
    if (
      !uniqNameStateParties.find(
        stateParty => stateParty.document_party_id === party.document_party_id,
      )
    ) {
      updatedParties.push({...party, type: "delete"});
    }
  });
  return updatedParties.map(party =>
    party.role_id === -1 ? {...party, role_id: null} : party,
  );
}

export default withStyles(styles)(PartyList);

function getIssuesetId(
  viewMode,
  documentTypeId,
  defaultIssuesetId,
  storedIssuesetId,
) {
  // documentTypeId is 4 for `Extract contract from packet` document type
  if (documentTypeId === 4) {
    return viewMode === "checklist"
      ? defaultIssuesetId
      : storedIssuesetId || defaultIssuesetId;
  }
  return storedIssuesetId || defaultIssuesetId;
}

export default getIssuesetId;

import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function embeddingTopicsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.EMBEDDING_TOPICS_FETCH.SUCCESS:
      return action.payload;
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import React from "react";

const styles = {
  matchText: {
    backgroundColor: "#85bef1",
    color: "#383c40",
  },
  textWrap: {
    fontSize: "11px",
    textAlign: "justify",
    color: "#383c40",
  },
  clauseId: {
    fontSize: 11,
    marginBottom: 1,
    color: "#8d9398",
  },
};

const renderClause = (clause, content, onClauseClick, definitionsOmitRegex) => {
  return (
    <div
      style={styles.textWrap}
      onClick={onClauseClick ? onClauseClick(clause) : undefined}
    >
      <div style={styles.clauseId}>
        {formatReference(clause.reference, definitionsOmitRegex)}
      </div>
      {content}
    </div>
  );
};

export default (
  text,
  searchText,
  clause,
  onClauseClick,
  definitionsOmitRegex,
) => {
  const startIndex = text.toLowerCase().indexOf(searchText.toLowerCase());
  const endIndex = startIndex + searchText.length;
  let content = null;

  if (startIndex === 0 && endIndex === text.length) {
    content = <span style={styles.textWrap}>{text}</span>;
  } else if (startIndex > 0 && endIndex === text.length) {
    content = (
      <span>
        {formatBeginning(text, startIndex)}
        <span style={styles.matchText}>{text.slice(startIndex)}</span>
      </span>
    );
    return renderClause(clause, content, onClauseClick, definitionsOmitRegex);
  } else if (startIndex === 0 && endIndex < text.length) {
    content = (
      <span style={styles.textWrap}>
        <span style={styles.matchText}>{text.slice(0, endIndex)}</span>
        {formatEnding(text, endIndex)}
      </span>
    );
  } else {
    content = (
      <span style={styles.textWrap}>
        {formatBeginning(text, startIndex)}
        <span style={styles.matchText}>{text.slice(startIndex, endIndex)}</span>
        {formatEnding(text, endIndex)}
      </span>
    );
  }

  return renderClause(clause, content, onClauseClick, definitionsOmitRegex);
};

const formatBeginning = (text, index) => {
  const wordsBefore = text.slice(0, index).split(" ");
  if (wordsBefore.length > 10) {
    return `...${wordsBefore.slice(-10).join(" ")}`;
  }

  return `${wordsBefore.join(" ")}`;
};

const formatEnding = (text, index) => {
  const wordsAfter = text.slice(index).split(" ");
  if (wordsAfter.length > 10) {
    return `${wordsAfter.slice(0, 10).join(" ")}...`;
  }

  return `${wordsAfter.join(" ")}`;
};

function formatReference(reference, definitionsOmitRegex) {
  if (!reference) {
    return "";
  }
  let ref = reference;
  if (definitionsOmitRegex) {
    ref = reference.replace(definitionsOmitRegex, "");
  }
  return `${ref}. `;
}

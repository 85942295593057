import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";

export default function permissionGroupsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.PERMISSION_GROUPS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PERMISSION_GROUP_UPDATE.SUCCESS: {
      const {permissionGroupId, name} = action.payload;
      return state.map(group => {
        if (group.id === permissionGroupId) {
          return {...group, name};
        }
        return group;
      });
    }
    case ActionTypes.PERMISSION_GROUP_ADD.SUCCESS: {
      return [...state, action.payload];
    }
    case ActionTypes.PERMISSION_GROUPS_CLEAR.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import React, {useEffect, useState} from "react";
import _ from "underscore";
import moment from "moment";
import scrollIntoView from "scroll-into-view";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";

import getZoomedFontSize from "utils/get_zoomed_font_size";

import heights from "word/taskpane/constants/heights";
import "./compare_panel_select.css";

const styles = {
  mainContainer: {
    padding: "18px 28px 28px 28px",
    overflow: "auto",
    height: `calc(100vh - ${heights.topBar}px)`,
    display: "flex",
    flexDirection: "column",
  },
  radioRoot: {
    padding: 4,
  },
  headerHeight: 132,
};

function OptionItem(props) {
  const {
    option,
    selectedOptionId,
    selectOption,
    zoom,
    isFlipChecklistIcons,
    shouldUseNameAsLabel,
  } = props;

  function selectHandler() {
    if (selectedOptionId !== option.id) {
      selectOption(option.id);
    }
  }
  return (
    <div
      className={
        isFlipChecklistIcons ? "revision-item-main" : "revision-item-main-dark"
      }
      id={props.id}
      style={{
        ...(option.id === selectedOptionId
          ? {background: isFlipChecklistIcons ? "#bdbdbd" : "#37474f"}
          : {}),
        ...(props.isLastItem ? {marginBottom: 0} : {}),
      }}
      onClick={selectHandler}
    >
      <div
        style={{
          fontWeight: 600,
          fontSize: getZoomedFontSize(14, "checklist", zoom),
          wordBreak: "break-all",
        }}
      >
        {shouldUseNameAsLabel
          ? option.name
          : moment(option.creation_date).format("DD/MM/YYYY HH:mm")}
      </div>
      <div
        style={{
          fontSize: getZoomedFontSize(12, "checklist", zoom),
        }}
      >
        {`${option.user_email}${
          shouldUseNameAsLabel
            ? ` @ ${moment(option.creation_date).format("DD/MM/YYYY HH:mm")}`
            : ""
        }`}
      </div>
    </div>
  );
}

function ComparePanelSelect(props) {
  const {
    document: currentDocument,
    documents = [],
    isFlipChecklistIcons,
    firstUploadedDocumentId,
    zoom,
  } = props;
  const {comparison_document_id: currentComparisonDocumentId} = currentDocument;

  useEffect(() => {
    const scroller = document.getElementById("selected-option-item");
    if (scroller) {
      scrollIntoView(scroller, {
        time: 100,
        align: {
          top: 0.3,
          topOffset: 0,
        },
      });
    }
  }, []);

  const [filterText, setFilterText] = useState("");

  function onFilterTextChange(e) {
    setFilterText(e.target.value);
  }

  function onClearFilterText() {
    setFilterText("");
  }

  const [compareType, setCompareType] = useState(
    getInitialCompareType(
      currentComparisonDocumentId,
      firstUploadedDocumentId,
      currentDocument,
    ),
  );
  const [
    selectedComparisonDocumentId,
    updateSelectedComparisonDocumentId,
  ] = useState(currentComparisonDocumentId || firstUploadedDocumentId);

  const handleCompareTypeChange = event => {
    const newValue = event.target.value;
    if (newValue === "standard_terms") {
      updateSelectedComparisonDocumentId(firstUploadedDocumentId);
    } else {
      updateSelectedComparisonDocumentId(currentComparisonDocumentId || null);
    }
    onClearFilterText();
    setCompareType(newValue);
  };

  function onCompare() {
    props.updateComparisonDocumentId(selectedComparisonDocumentId);
  }

  function renderCompareTypeSelector() {
    const compareTypeItems = [
      {
        value: "standard_terms",
        label: "Standard Terms",
        disabled: false,
      },
      {
        value: "this_document_version",
        label: "Version of this document",
        disabled:
          !currentDocument.revisions || currentDocument.revisions.length === 0,
      },
      {
        value: "other_document",
        label: "Other document",
        disabled: false,
      },
    ];
    return (
      <FormControl style={{paddingLeft: 6}} component="fieldset">
        <RadioGroup value={compareType} onChange={handleCompareTypeChange}>
          {compareTypeItems.map(formControlItem => (
            <FormControlLabel
              className=""
              key={formControlItem.value}
              value={formControlItem.value}
              control={<Radio style={{padding: 4}} color="primary" />}
              label={formControlItem.label}
              disabled={formControlItem.disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }

  function renderFilterInput() {
    if (compareType !== "other_document") {
      return null;
    }
    return (
      <TextField
        value={filterText}
        onChange={onFilterTextChange}
        placeholder="Filter By Name"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CloseIcon
                style={{cursor: "pointer"}}
                onClick={onClearFilterText}
              />
            </InputAdornment>
          ),
          style: {color: isFlipChecklistIcons ? "#424242" : "white"},
        }}
      />
    );
  }

  function renderOptions() {
    if (compareType === "standard_terms") {
      return null;
    }
    const filterTextTrimmedLower = filterText.trim().toLowerCase();
    const optionsRaw =
      compareType === "this_document_version"
        ? currentDocument.revisions || []
        : documents.filter(
            doc =>
              filterTextTrimmedLower
                ? doc.id !== firstUploadedDocumentId &&
                  doc.id !== currentDocument.id &&
                  doc.name.toLowerCase().indexOf(filterTextTrimmedLower) !== -1
                : doc.id !== firstUploadedDocumentId &&
                  doc.id !== currentDocument.id,
          );
    const options = _.sortBy(optionsRaw, item => -item.creation_date);
    const shouldUseNameAsLabel = compareType === "other_document";
    return (
      <div style={{overflow: "auto", marginTop: 12, flex: 1}}>
        {options.map((option, optionIndex) => (
          <OptionItem
            id={
              selectedComparisonDocumentId === option.id
                ? "selected-option-item"
                : undefined
            }
            key={option.id}
            option={option}
            selectedOptionId={selectedComparisonDocumentId}
            selectOption={updateSelectedComparisonDocumentId}
            zoom={zoom}
            isFlipChecklistIcons={isFlipChecklistIcons}
            shouldUseNameAsLabel={shouldUseNameAsLabel}
            isLastItem={options.length - 1 === optionIndex}
          />
        ))}
      </div>
    );
  }

  const isCompareButtonDisabled =
    selectedComparisonDocumentId === null ||
    currentComparisonDocumentId === selectedComparisonDocumentId;
  return (
    <div
      style={{
        ...styles.mainContainer,
        ...(isFlipChecklistIcons ? {} : {background: "#232e38", color: "#fff"}),
      }}
    >
      <div>
        <div
          style={{
            paddingBottom: 10,
            color: isFlipChecklistIcons ? "#424242" : "#e0e0e0",
            fontSize: 20,
          }}
        >
          Compare this document with
        </div>
        {renderCompareTypeSelector()}
      </div>
      {renderFilterInput()}
      {renderOptions()}
      <div style={{display: "flex", justifyContent: "center", paddingTop: 24}}>
        <Button
          color="primary"
          variant="contained"
          style={{
            borderRadius: 0,
            width: 136,
            ...(isCompareButtonDisabled && !isFlipChecklistIcons
              ? {background: "#616161", color: "white"}
              : {}),
          }}
          disabled={isCompareButtonDisabled}
          onClick={onCompare}
        >
          Compare
        </Button>

        <Button
          color="primary"
          variant="contained"
          style={{
            borderRadius: 0,
            width: 136,
            marginLeft: 8,
          }}
          onClick={props.setComparisonMode}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

function getInitialCompareType(
  currentComparisonDocumentId,
  firstUploadedDocumentId,
  document,
) {
  if (!currentComparisonDocumentId) {
    return "standard_terms";
  } else if (currentComparisonDocumentId === firstUploadedDocumentId) {
    return "standard_terms";
  } else if (
    (document.revisions || []).find(
      revision => revision.id === currentComparisonDocumentId,
    )
  ) {
    return "this_document_version";
  }
  return "other_document";
}

export default ComparePanelSelect;

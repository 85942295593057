import reportCellUtils from "../utils/report_cell_utils";

const {
  getCellData,
  constructRow,
  constructCell,
  constructCellChild,
  descriptors,
} = reportCellUtils;

function constructCustomReportTable(issues, reportRows, props) {
  return reportRows
    .filter(
      reportRow =>
        reportRow.type.indexOf("custom_field") === 0 ||
        descriptors[reportRow.type],
    )
    .map(reportRow => {
      const issue = issues.find(
        issue => issue.document_issue_id === reportRow.document_issue_id,
      );
      const {cellContent, fieldOptions} = getCellData(reportRow, issue, props);
      return constructRow(
        [
          constructCell([constructCellChild("paragraph", reportRow.label)]),
          constructCell(cellContent, fieldOptions),
        ],
        issue ? {document_issue_id: issue.document_issue_id} : {},
      );
    });
}

export default constructCustomReportTable;

import _ from "underscore";

const getRouteIds = _.memoize(
  router => {
    if (!router) {
      return {};
    }
    const location = router.location ? "location" : "locationBeforeTransitions";
    if (!router[location] || router[location].search.length < 3) {
      return {};
    }
    return router[location].search
      .substring(1)
      .split("&")
      .reduce((state, param) => {
        const params = param.split("=");
        state[params[0]] = !params[1] ? true : params[1];
        return state;
      }, {});
  },
  router => {
    const location = router.location ? "location" : "locationBeforeTransitions";
    return JSON.stringify([
      location,
      router && router[location] && router[location].search,
    ]);
  },
);

export default getRouteIds;

import _ from "underscore";
const defaultJoin = ", ";
function joinHelper(list, _normalJoiner, _finalJoiner) {
  let normalJoiner = _normalJoiner;
  let finalJoiner = _finalJoiner;

  if (typeof normalJoiner !== "string") {
    normalJoiner = defaultJoin;
    finalJoiner = defaultJoin;
  }
  if (typeof finalJoiner !== "string") {
    finalJoiner = normalJoiner;
  }
  if (list.length === 0) {
    return "";
  }
  if (list.length === 1) {
    return list[0].toString();
  }
  const last = _.last(list);
  const result = `${_.initial(list).join(normalJoiner)}${finalJoiner}${last}`;
  return result;
}

export default joinHelper;

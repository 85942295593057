import React from "react";
import _ from "underscore";

import Delete from "material-ui/svg-icons/action/delete";
import Undo from "material-ui/svg-icons/content/undo";
import AddAbove from "material-ui/svg-icons/editor/vertical-align-top";
import AddBelow from "material-ui/svg-icons/editor/vertical-align-bottom";
import * as colors from "material-ui/styles/colors";

import Tooltip from "common_components/tooltip";

const styles = {
  mainContainer: {
    position: "relative",
  },
  innerContainer: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    background: colors.grey700,
    borderRadius: "15px",
    width: "16px",
  },
  iconButtonBase: {
    width: "14px",
    height: "14px",
    cursor: "pointer",
    color: colors.grey200,
    transition: "none",
    flexShrink: 0,
  },
  rootDivStyleBase: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "4px",
    position: "relative",
  },
  tooltip: {
    display: "flex",
    justifyContent: "center",
    width: 100,
    left: 20,
    padding: 0,
    color: "white",
    fontFamily: "roboto",
    top: "-6px",
  },
  tooltipContent: {
    lineHeight: "12px",
    margin: "6px 0px",
  },
};
function VerticalClausepartControl(props) {
  const {
    itemName,
    shouldRevert,
    showAddButtons,
    onTopButtonClick,
    onTopButtonHover,
    onTopButtonHoverFinish,
    onAddAboveClick,
    onAddBelowClick,
    mainContainerStyles,
    innerContainerStyles,
  } = props;
  const TopIcon = shouldRevert ? Undo : Delete;
  const buttonsData = [
    {
      rootDivStyle: {
        ...styles.rootDivStyleBase,
        marginTop: shouldRevert ? "4px" : "5px",
        marginBottom: "5px",
      },
      tooltipMessage: shouldRevert ? "Revert changes" : `Delete ${itemName}`,
      element: (
        <TopIcon
          style={styles.iconButtonBase}
          onClick={onTopButtonClick}
          onMouseEnter={onTopButtonHover}
          onMouseLeave={onTopButtonHoverFinish}
        />
      ),
    },
    showAddButtons && {
      rootDivStyle: {...styles.rootDivStyleBase, marginBottom: "2px"},
      tooltipMessage: `Add ${itemName} above`,
      element: (
        <AddAbove style={styles.iconButtonBase} onClick={onAddAboveClick} />
      ),
    },
    showAddButtons && {
      rootDivStyle: {...styles.rootDivStyleBase, marginBottom: "7px"},
      tooltipMessage: `Add ${itemName} below`,
      element: (
        <AddBelow style={styles.iconButtonBase} onClick={onAddBelowClick} />
      ),
    },
  ];
  return (
    <div
      style={{
        ...styles.mainContainer,
        ...mainContainerStyles,
      }}
    >
      <div
        style={{
          ...styles.innerContainer,
          ...innerContainerStyles,
        }}
      >
        {_.compact(buttonsData).map(item => renderItemWithTooltip(item))}
      </div>
    </div>
  );
}

function renderItemWithTooltip(item) {
  return (
    <Tooltip
      key={item.tooltipMessage}
      tooltipContent={item.tooltipMessage}
      tooltipContainerStyle={styles.tooltip}
      tooltipContentStyle={styles.tooltipContent}
      rootDivStyle={item.rootDivStyle ? item.rootDivStyle : undefined}
      delay={300}
    >
      {item.element}
    </Tooltip>
  );
}

export default VerticalClausepartControl;

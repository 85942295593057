import _ from "underscore";

function transformSectionName(sectionName) {
  const sectionNames = sectionName.split("_");
  sectionNames[0] =
    sectionNames[0].charAt(0).toUpperCase() + sectionNames[0].slice(1);
  return sectionNames.join(" ");
}

function getTopSubclauseFullReference(reference, fullReferenceList) {
  if (!fullReferenceList) {
    return reference;
  }
  return fullReferenceList.find(item => item.indexOf(reference) === 0);
}

function generateDocumentHeadings(
  headings,
  clausesRaw,
  sections,
  fullReferenceList,
) {
  const clauses = _.clone(clausesRaw);
  const mainSection = sections.find(section => section.reference === "main");
  const mainSectionId = mainSection ? mainSection.id : null;
  const backgroundSection = sections.find(
    section => section.reference === "background",
  );
  const backgroundSectionId = backgroundSection ? backgroundSection.id : null;
  const finalHeadings = [];

  _.each(clauses, (sectionClauses, _sectionId) => {
    const sectionId = parseInt(_sectionId, 10);
    const section = sections.find(section => section.id === sectionId);
    if (!section || section.reference.indexOf("title_parties") >= 0) {
      return;
    }
    if (
      (sectionClauses.length < 3 && sectionId !== mainSectionId) ||
      sectionId === backgroundSectionId
    ) {
      finalHeadings.push({
        id: `grouped-section-${sectionId}`,
        section_id: sectionId,
        text: transformSectionName(section.reference),
        hasText: true,
        reference: sectionClauses[0].reference,
        fullReference: getTopSubclauseFullReference(
          sectionClauses[0].full_reference,
          fullReferenceList,
        ),
        prefixCounter: 0,
        isSectionGrouped: true,
      });
    } else {
      let clauseNumber = 1;
      const sectionHasHeadings = headings.some(
        heading => heading.section_id === sectionClauses[0].section_id,
      );
      if (sectionHasHeadings) {
        let prefixCounter = 1;
        sectionClauses.forEach(clause => {
          const heading = headings.find(
            heading =>
              heading.reference !== "undefined" &&
              heading.section_id === sectionId &&
              heading.reference === clause.reference,
          );
          if (heading) {
            finalHeadings.push({
              id: `${sectionId}_${clauseNumber}`,
              section_id: sectionId,
              reference: clause.reference,
              fullReference: getTopSubclauseFullReference(
                clause.full_reference,
                fullReferenceList,
              ),
              text: `${heading.text || `Clause ${clauseNumber}`}`,
              hasText: true,
              prefixCounter,
              isSectionGrouped: false,
            });
            clauseNumber++;
            prefixCounter++;
          } else {
            finalHeadings.push({
              id: `${sectionId}_${clauseNumber}`,
              section_id: sectionId,
              reference: clause.reference,
              fullReference: getTopSubclauseFullReference(
                clause.full_reference,
                fullReferenceList,
              ),
              text: `Clause ${clauseNumber}`,
              hasText: false,
              prefixCounter,
              isSectionGrouped: false,
            });
            clauseNumber++;
            prefixCounter++;
          }
        });
      } else {
        sectionClauses.forEach(clause => {
          finalHeadings.push({
            id: `${sectionId}_${clauseNumber}`,
            section_id: sectionId,
            reference: clause.reference,
            fullReference: getTopSubclauseFullReference(
              clause.full_reference,
              fullReferenceList,
            ),
            text: `Clause ${clauseNumber}`,
            hasText: false,
            prefixCounter: 0,
            isSectionGrouped: false,
          });
          clauseNumber++;
        });
      }
    }
  });
  return finalHeadings;
}

export default generateDocumentHeadings;

import {makeStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

const useStyles = makeStyles({
  wrapper: {
    backgroundColor: "#f4f4f4",
    paddingTop: "1px",
    paddingBottom: "1px",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: ".5em 1em",
    backgroundColor: "#eee",
    color: "#999",
  },
  arrowBackIcon: {
    width: ".8em",
    height: ".8em",
    cursor: "pointer",
  },
  headingText: {
    color: "#000",
  },
  editIcon: {
    width: ".8em",
    height: ".8em",
    cursor: "pointer",
  },
  body: {
    marginLeft: "1em",
    marginRight: "1em",
    "& > *": {
      marginTop: "1em",
      marginBottom: "1em",
    },
  },
  fieldHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top",
    marginBottom: ".5em",
  },
  copyIcon: {
    color: "#bbb",
    width: ".8em",
    height: ".8em",
    cursor: "pointer",
  },
  fieldHeadingText: {
    color: "#2196f3",
    fontWeight: "bold",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
    "& > *": {
      marginRight: ".5em",
    },
  },
  deleteButton: {
    color: red[400],
    borderColor: red[400],
    borderRadius: 0,
  },
  button: {
    borderRadius: 0,
  },
  italic: {
    fontStyle: "italic",
  },
  label: {
    fontSize: ".95em",
  },
});

export default useStyles;

import getClauseAtomsByIds from "../routes/document_detail/utils/applicable_clauses/get_clause_atoms_by_ids";

const concatApplicableClausesRaw = (
  applicableClauses,
  documentClauses,
  additionalReportClauses,
) => {
  if (!additionalReportClauses) {
    return applicableClauses;
  } else {
    const additionalReportClausesRaw = getClauseAtomsByIds(
      additionalReportClauses,
      documentClauses,
    );
    return [...applicableClauses, ...additionalReportClausesRaw];
  }
};

export default concatApplicableClausesRaw;

import eliminateSimilarIssues from "common/utils/issues/eliminate_similar_issues";
import shouldIgnoreIssue from "common/utils/issues/should_ignore_issue";
import calculateIssueOverrides from "utils/issues/calculate_issue_overrides";
import getIssueReasonCount from "utils/issues/get_issue_reason_count";

function getIssues(
  issuesets,
  documentIssues,
  currentIssueset,
  areIssuesCollapsed,
  useMasterName,
  displayHiddenIssues = false,
  showArchivedIssues = false,
  dontFilterIssuesByIssuesets,
  selectedChecklistSectionId,
  preventMemo = false,
) {
  let issues = (documentIssues || []).filter(
    docIssue =>
      selectedChecklistSectionId
        ? docIssue.section_id === selectedChecklistSectionId
        : !docIssue.section_id,
  );
  if (issues.length === 0) {
    issues = documentIssues;
  }
  if (issuesets.length > 0 && currentIssueset && !dontFilterIssuesByIssuesets) {
    issues = issues.filter(issue => {
      let keepTheIssueInList = false;
      if (issue.is_archived === false || showArchivedIssues) {
        issue.contract_types.forEach(ct => {
          ct.issuesets.forEach(is => {
            if (is.id === currentIssueset.id) {
              keepTheIssueInList = true;
            }
          });
        });
      }
      return keepTheIssueInList;
    });
  }

  // add overrides and auxiliary data
  issues = issues.map(issue => {
    const issueWithOverrides = calculateIssueOverrides(
      issue,
      currentIssueset,
      useMasterName,
      preventMemo,
    );
    const reasonCount = getIssueReasonCount(issueWithOverrides);
    return {...issueWithOverrides, reasonCount};
  });

  issues = areIssuesCollapsed
    ? eliminateSimilarIssues(issues, displayHiddenIssues)
    : issues.map(issue => ({
        ...issue,
        shouldBeHidden: shouldIgnoreIssue(issue) || issue.issue_class_id === 4,
      }));

  return issues;
}

export default getIssues;

import {get, set} from "lodash";
import Handlebars from "handlebars";
import registerHandlebarsHelpers from "../../../utils/handlebars_helpers/register";
import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import globalStore from "../../global_store";
import logger from "../../logger";
import replaceNewlineToken from "./replace_newline_token";

registerHandlebarsHelpers();

function getPositiveReasonText(
  reasonTemplate,
  issue,
  issueset,
  documentClauses,
  topicsById,
  data,
  reasonData,
  parties,
  logInfo = false,
  preventMemo = false,
  last_classification_changed,
) {
  if (!reasonTemplate || !data) {
    return reasonTemplate;
  }
  try {
    let checklistDefinitions = {};
    let commonDefinitions = {};
    set(globalStore, "setting_checklist_definitions", true);
    getIssueTemplatedText(
      issue,
      issueset,
      "checklist_definitions",
      documentClauses,
      topicsById,
      data,
      reasonData,
      parties,
      false,
      logInfo,
      preventMemo,
      last_classification_changed,
    );
    set(globalStore, "setting_checklist_definitions", false);
    checklistDefinitions = get(
      globalStore,
      `checklistDefinitions.${issueset.id}`,
    );

    set(globalStore, "setting_common_definitions", true);
    getIssueTemplatedText(
      issue,
      issueset,
      "common_definitions",
      documentClauses,
      topicsById,
      data,
      reasonData,
      parties,
      false,
      logInfo,
      preventMemo,
      last_classification_changed,
    );
    set(globalStore, "setting_common_definitions", false);
    commonDefinitions = get(globalStore, `commonDefinitions.${issue.id}`);

    const template = Handlebars.compile(reasonTemplate);
    if (logInfo) {
      logger.verbose("issue", issue);
      logger.verbose("data", data);
    }
    return replaceNewlineToken(
      template({
        ...checklistDefinitions,
        ...commonDefinitions,
        issue,
        data,
        reasonData,
        parties,
      }),
    );
  } catch {
    const errorMessage = [
      `Positive reason of issue ${issue.name} (id: ${
        issue.id
      }) seems to be corrupted.`,
      "Please check the template.",
    ].join(" ");
    logger.error(errorMessage);
    return "";
  }
}

export default getPositiveReasonText;

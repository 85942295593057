import React from "react";
import formatTemplate from "utils/templates/format_template";

export default (text, isStart, isEnd) => {
  if (text === null || text === undefined) {
    return text;
  }

  const trimmedTextStart = isStart ? text.replace(/^\s+/, "") : text;
  const trimmedTextEnd = isEnd
    ? trimmedTextStart.replace(/\s+$/, "")
    : trimmedTextStart;

  return trimmedTextEnd
    .split(/\n/)
    .map((line, index) => (
      <div key={index}>{formatTemplate(line || String.fromCharCode(160))}</div>
    ));
};

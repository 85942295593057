import * as colors from "@material-ui/core/colors";

const blueColor = colors.lightBlue[500];

const textChanges = {
  text_addition: {
    fontWeight: 400,
    color: blueColor,
  },
  text_deletion: {
    fontWeight: 400,
    color: blueColor,
    textDecoration: "line-through",
    textDecorationLine: "line-through",
  },
  text_alteration: {
    borderBottom: `1px solid ${blueColor}`,
    borderTop: `1px solid ${blueColor}`,
  },
  definition: {
    borderBottom: "1px solid #999",
  },
  reference: {
    borderBottom: "1px solid #999",
  },
  search: {
    background: colors.orange[200],
    borderRadius: 0,
  },
  template: {
    background: colors.lightBlue[200],
    padding: "0px 6px",
    borderRadius: "6px",
  },
  "<b>": {
    fontWeight: "bold",
  },
  company_detail_placeholder: {
    cursor: "pointer",
    background: colors.blue[50],
  },
};

export default {textChanges};

import {memoize} from "underscore";

function makeTextSortable(string) {
  return string.replace(/\[/g, "\u0015");
}

/* eslint-disable curly */
export default memoize(function getSortingFunction(sortingField) {
  return function sortingFunction(item1, item2) {
    if (item1[sortingField] > item2[sortingField]) return 1;
    if (item1[sortingField] < item2[sortingField]) return -1;
    if (item1[sortingField] === item2[sortingField]) {
      const item1Name = makeTextSortable(item1.name);
      const item2Name = makeTextSortable(item2.name);
      if (item1Name > item2Name) return 1;
      if (item1Name < item2Name) return -1;
    }
    return 0;
  };
}, sortingField => sortingField);
/* eslint-disable curly */

import React from "react";
import {Link} from "react-router";

import {IconButton} from "@material-ui/core";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import LinkIcon from "@material-ui/icons/Link";
import * as colors from "@material-ui/core/colors";

import ItemContainer from "../helper_components/item_container";

import copySelection from "utils/copy_selected_text_inside_element";
import CopyTooltip from "./components/copy_tooltip";
import Markdown from "common_components/markdown";

const styles = {
  bodyContainer: {
    textAlign: "justify",
    color: colors.grey[600],
    fontWeight: 400,
  },
  iconStyle: {
    height: "18px",
    width: "18px",
  },
};

function StandardLanguage(props) {
  const {
    standardLanguageText,
    templateDocumentLink,
    headerStyles = {},
    containerStyles = {},
    issue,
  } = props;

  if (issue.true_name.indexOf("showPrecedent") >= 0 || !standardLanguageText) {
    return null;
  }

  const actionButtons = (
    <div style={{marginRight: "0.5rem", display: "flex", alignItems: "center"}}>
      <CopyTooltip>
        <IconButton
          onClick={copySelection(
            standardLanguageText.replace(/[\*`]/g, ""),
            "standard-language",
          )}
          style={{width: "42px", height: "42px"}}
          title="Copy Text"
        >
          <CopyIcon />
        </IconButton>
      </CopyTooltip>
      {templateDocumentLink && (
        <Link
          to={{pathname: templateDocumentLink}}
          style={{textDecoration: "none"}}
          title="Template Document"
          target="_blank"
        >
          <LinkIcon
            style={{
              ...styles.iconStyle,
              position: "relative",
              top: "3px",
            }}
          />
        </Link>
      )}
    </div>
  );

  return (
    <ItemContainer
      key={1}
      title="Standard Language"
      actionButtons={actionButtons}
      expandable={true}
      headerStyles={headerStyles}
      containerStyles={containerStyles}
      onExpandMenu={props.onOpen}
      isMenuExpanded={props.isOpen}
      bodyStyles={styles.bodyContainer}
      useFlippedInnerBodyStyles={true}
    >
      <div data-testid="standard_language">
        <Markdown>{standardLanguageText}</Markdown>
      </div>
    </ItemContainer>
  );
}

export default StandardLanguage;

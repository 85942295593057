import React, {useContext} from "react";
import {makeStyles} from "@material-ui/core/styles";

import DocxContext from "./docx_context";

const useStyles = makeStyles({
  text: {},
});

const greenColor = "green";

export default function Text({"#text": tText, attr, id}) {
  const classes = useStyles();
  const {intersections} = useContext(DocxContext);
  if (!tText) {
    return null;
  }

  const shouldAddSpace = attr && attr["@_space"] === "preserve";
  const texts = splitText(`${tText}`, intersections[id]);
  return (
    <span className={classes.text}>
      {texts.map(({text, color}, index) => (
        <span key={index} style={color ? {color} : {}}>{`${text}${
          shouldAddSpace ? " " : ""
        }`}</span>
      ))}
    </span>
  );
}

function splitText(text, intersections) {
  if (text) {
    const texts = [];
    let offset = 0;
    if (intersections && intersections.length > 0) {
      for (const intersection of intersections) {
        if (offset < intersection.start) {
          texts.push({
            text: text.substring(offset, intersection.start),
          });
        }
        texts.push({
          text: text.substring(intersection.start, intersection.end),
          color: greenColor,
        });
        offset += intersection.end;
      }
    }
    if (offset < text.length) {
      texts.push({text: text.substring(offset, text.length)});
    }
    return texts;
  }
  return [{text}];
}

import isIssueAlert from "common/utils/issues/is_issue_alert";

// We define problem as issue having either of:
// 1) thumb down (user_verified is false)
// 2) should_be_issue mismatches issue's alert state

function checkGroupContainsProblem(group) {
  const groupContainsProblem = group.find(issue => {
    if (issue.user_verified === false) {
      return true;
    }
    const issueIsTriggered = isIssueAlert(issue);
    if (
      issue.should_be_issue !== null &&
      issueIsTriggered !== issue.should_be_issue
    ) {
      return true;
    }

    if (issue.subissueData && issue.subissueData.subissues) {
      const subissueParentContainsProblem = checkGroupContainsProblem(
        issue.subissueData.subissues,
      );
      return subissueParentContainsProblem;
    }
    return false;
  });
  return Boolean(groupContainsProblem);
}

export default checkGroupContainsProblem;

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import upperFirst from "utils/upper_first";

const styles = {
  root: {
    position: "relative",
  },
  rootRequired: {
    flexGrow: 0,
    flexShrink: 0,
  },
  resizer: {
    position: "absolute",
    zIndex: 100,
    backgroundColor: "#1f88e5",
    opacity: 0,
    transition: "opacity 150ms ease 0s",
  },
  "resizer--top": {
    top: 0,
    right: 0,
    left: 0,
    height: 3,
    cursor: "ns-resize",
  },
  "resizer--bottom": {
    bottom: 0,
    right: 0,
    left: 0,
    height: 3,
    cursor: "ns-resize",
  },
  "resizer--right": {
    top: 0,
    right: 0,
    bottom: 0,
    width: 3,
    cursor: "ew-resize",
  },
  "resizer--left": {
    top: 0,
    bottom: 0,
    left: 0,
    width: 3,
    cursor: "ew-resize",
  },
};

export default class ResizableBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isResizerHovered: false,
      isResizeStarted: false,
    };
    this.rootRef = React.createRef();
    this.resizerRef = React.createRef();
    this.startCoordinate = null;
    this.startSize_PX = null;
  }

  componentDidMount() {
    const {baseSize, zoom} = this.props;
    const storageValue = this.getStorageValue();
    if (!baseSize || !zoom || storageValue) {
      return;
    }
    const rootNode = this.rootRef.current;
    rootNode.style[this.getSizeName()] = `${baseSize * zoom}px`;
  }

  componentWillUnmount() {
    this.reset();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.zoom &&
      prevProps.zoom &&
      this.props.zoom !== prevProps.zoom
    ) {
      this.handleZoomChange(prevProps.zoom, this.props.zoom);
    }
  }

  render() {
    const props = _.omit(
      this.props,
      Object.keys(ResizableBlock.propTypes || {}),
    );
    return (
      <div
        {...props}
        ref={this.rootRef}
        style={{
          ...styles.root,
          ...this.props.style,
          ...styles.rootRequired,
          ...this.getStorageSizeAsObject(),
        }}
      >
        {this.props.children}
        {this.renderResizer()}
      </div>
    );
  }

  renderResizer() {
    const {isResizerHovered, isResizeStarted} = this.state;
    const shouldShowResizer = isResizerHovered || isResizeStarted;
    return (
      <div
        ref={this.resizerRef}
        onMouseEnter={() => this.setState({isResizerHovered: true})}
        onMouseLeave={() => this.setState({isResizerHovered: false})}
        onMouseDown={this.handleResizerMouseDown}
        onDoubleClick={() => this.removeStorageSizeValue()}
        style={{
          ...styles.resizer,
          ...styles[`resizer--${this.props.side}`],
          ...(shouldShowResizer && {
            opacity: 1,
          }),
        }}
      />
    );
  }

  getStorageName = () => {
    if (!this.props.storageName) {
      return null;
    }
    return `resizable_block.${this.props.storageName}.${this.props.side}`;
  };

  getStorageValue = () => {
    const storageName = this.getStorageName();
    if (storageName) {
      const storageValue = sessionStorage.getItem(storageName);
      if (storageValue !== null) {
        return storageValue;
      }
    }
  };

  getStorageSizeAsObject = () => {
    const object = {};
    const storageValue = this.getStorageValue();
    if (storageValue) {
      object[this.getSizeName()] = storageValue;
    }
    return object;
  };

  setStorageSizeValue = value => {
    const storageName = this.getStorageName();
    if (storageName) {
      sessionStorage.setItem(storageName, value);
    }
  };

  removeStorageSizeValue = () => {
    const storageValue = this.storageValue;
    if (storageValue) {
      sessionStorage.removeItem(this.getStorageName());
      this.forceUpdate();
    }
  };

  handleResizerMouseDown = event => {
    event.preventDefault();
    this.startCoordinate = this.getEventCoordinate(event);
    const rootNode = this.rootRef.current;
    this.startSize_PX =
      rootNode[this.isVertical() ? "offsetHeight" : "offsetWidth"];
    window.addEventListener("mousemove", this.handleWindowMouseMove, true);
    window.addEventListener("mouseup", this.handleWindowMouseUp, true);
    this.setState({isResizeStarted: true});
  };

  getEventCoordinate = event => {
    return this.isVertical() ? event.pageY : event.pageX;
  };

  handleWindowMouseMove = event => {
    const coordinateOffset_PX =
      this.getEventCoordinate(event) - this.startCoordinate;
    const moveSize_PX = this.applySizeOffset(
      this.startSize_PX,
      coordinateOffset_PX,
    );
    const rootNode = this.rootRef.current;
    const sizeName = this.getSizeName();
    const moveSize_PCT = convertSize(sizeName, moveSize_PX, rootNode, "%");
    const nextSize_PCT = getNextSizeInPct(sizeName, moveSize_PCT, rootNode);
    const nextSize_PX = convertSize(sizeName, nextSize_PCT, rootNode, "px");

    rootNode.style[sizeName] = `${nextSize_PX}px`;
    this.setGlobalCursor();
  };

  handleZoomChange = (prevZoom, currentZoom) => {
    const {baseSize, zoom} = this.props;
    const storageValue = this.getStorageValue();

    if (!baseSize || !zoom || storageValue) {
      return;
    }
    const sizeName = this.getSizeName();
    const rootNode = this.rootRef.current;
    const isZoomBeingIncreased = currentZoom > prevZoom;

    const currentSize = rootNode[`offset${upperFirst(sizeName)}`];
    const newSize = baseSize * zoom;

    if (
      (isZoomBeingIncreased && currentSize > newSize) ||
      (!isZoomBeingIncreased && currentSize < newSize)
    ) {
      return;
    }
    rootNode.style[sizeName] = `${newSize}px`;
  };

  getSizeName = () => {
    return this.isVertical() ? "height" : "width";
  };

  handleWindowMouseUp = () => {
    const sizeName = this.getSizeName();
    const rootNode = this.rootRef.current;
    this.setStorageSizeValue(rootNode.style[sizeName]);
    this.setState({isResizeStarted: false});
    this.reset();
  };

  applySizeOffset = (size, offset) => {
    switch (this.props.side) {
      case "top":
      case "left":
        return size - offset;
      case "bottom":
      case "right":
        return size + offset;
    }
  };

  isVertical = () => {
    return ["top", "bottom"].includes(this.props.side);
  };

  setGlobalCursor = () => {
    if (!this.styleNode) {
      this.styleNode = document.createElement("style");
      document.querySelector("head").appendChild(this.styleNode);
      this.styleNode.innerText = `
        * {
          cursor: ${this.isVertical() ? "ns-resize" : "ew-resize"} !important;
          pointer-events: none;
        }
      `;
    }
  };

  removeGlobalCursor = () => {
    if (this.styleNode) {
      document.querySelector("head").removeChild(this.styleNode);
      this.styleNode = null;
    }
  };

  reset = () => {
    this.startCoordinate = null;
    this.startSize_PX = null;
    this.removeGlobalCursor();
    this.removeWindowListeners();
  };

  removeWindowListeners = () => {
    window.removeEventListener("mousemove", this.handleWindowMouseMove, true);
    window.removeEventListener("mouseup", this.handleWindowMouseUp, true);
  };
}

ResizableBlock.defaultProps = {
  side: "right",
};

ResizableBlock.propTypes = {
  style: PropTypes.object,
  storageName: PropTypes.string,
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]).isRequired,
  baseSize: PropTypes.number,
};

function convertSize(sizeName, sizeValue, node, toUnit) {
  const parentSize = node.offsetParent[`offset${upperFirst(sizeName)}`];
  const step_PX = parentSize / 100;
  switch (toUnit) {
    case "px":
      return sizeValue * step_PX;
    case "%":
      return sizeValue / step_PX;
  }
}

function getComputedMinOrMaxSizeInPct(sizeType, sizeName, node, defaultValue) {
  let value = null;
  const addValue = () => {
    value = getComputedStyle(node)[`${sizeType}${upperFirst(sizeName)}`];
  };
  window.addEventListener("DOMContentLoaded", addValue);
  if (/^[\d.]+%$/.test(value)) {
    window.removeEventListener("DOMContentLoaded", addValue);
    return parseFloat(value);
  }
  if (/^[\d.]+px$/.test(value)) {
    window.removeEventListener("DOMContentLoaded", addValue);
    const parentSizeValue = node.offsetParent[`offset${upperFirst(sizeName)}`];
    return parseFloat(value) / (parentSizeValue / 100);
  }
  return defaultValue;
}

function getMaxSizeInPct(sizeName, node) {
  const maxSizeBySiblings =
    100 -
    [...node.parentNode.children]
      .filter(_node => _node !== node)
      .map(_node => getComputedMinOrMaxSizeInPct("min", sizeName, _node))
      .filter(value => typeof value === "number")
      .reduce((a, b) => a + b, 0);
  const maxSize = getComputedMinOrMaxSizeInPct("max", sizeName, node);
  if (maxSize) {
    return Math.min(maxSize, maxSizeBySiblings);
  }
  return maxSizeBySiblings;
}

function getNextSizeInPct(sizeName, newValue_PCT, node) {
  const minSize_PCT = getComputedMinOrMaxSizeInPct("min", sizeName, node, 0);
  const sizeValue_PCT = Math.max(newValue_PCT, minSize_PCT);
  const maxSize_PCT = getMaxSizeInPct(sizeName, node);
  if (maxSize_PCT) {
    return Math.min(sizeValue_PCT, maxSize_PCT);
  }
  return sizeValue_PCT;
}

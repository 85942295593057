import getSimilarityData from "./get_similarity_data";

function getMatchesByExactReference(
  applicableClauses,
  comparisonClauses,
  invertPairConstruction,
) {
  const matchesByExactReference = [];
  applicableClauses.forEach(applicableClause => {
    const sameReferenceClauseIndex = comparisonClauses.findIndex(
      comparisonClause =>
        comparisonClause.reference === applicableClause.reference,
    );

    if (sameReferenceClauseIndex !== -1) {
      const comparisonClause = comparisonClauses[sameReferenceClauseIndex];

      const {areClausesSimilar, areClausesIdentical} = getSimilarityData(
        applicableClause,
        comparisonClause,
      );

      if (areClausesSimilar) {
        matchesByExactReference.push(
          constructPair(
            applicableClause,
            comparisonClause,
            invertPairConstruction,
            areClausesIdentical,
          ),
        );
      }
    }
  });
  return matchesByExactReference;
}

function getMatchesBySameReference(
  applicableClauses,
  _comparisonClauses,
  invertPairConstruction,
) {
  const comparisonClauses = [..._comparisonClauses];
  let sameClauseReferenceMatches = [];
  const usedClauses = {};
  applicableClauses.forEach(applicableClause => {
    const applicableClauseReference = getClauseReference(
      applicableClause.reference,
    );
    if (applicableClauseReference) {
      const result = processDataByReference(
        comparisonClauses,
        applicableClauseReference,
        applicableClause,
        invertPairConstruction,
        usedClauses,
      );
      sameClauseReferenceMatches = sameClauseReferenceMatches.concat(result);
    }
  });
  return sameClauseReferenceMatches;
}

function getNearbyMatches(
  applicableClauses,
  _comparisonClauses,
  invertPairConstruction,
) {
  const comparisonClauses = [..._comparisonClauses];
  let nearbyClauseReferenceMatches = [];
  const usedClauses = {};
  applicableClauses.forEach(applicableClause => {
    const applicableClauseReference = getClauseReference(
      applicableClause.reference,
    );
    if (applicableClauseReference) {
      const nearbyReferences = getNearbyReferences(
        applicableClauseReference,
        false,
      );
      nearbyReferences.forEach(nearbyRef => {
        const result = processDataByReference(
          comparisonClauses,
          nearbyRef,
          applicableClause,
          invertPairConstruction,
          usedClauses,
        );
        nearbyClauseReferenceMatches = nearbyClauseReferenceMatches.concat(
          result,
        );
      });
    }
  });
  return nearbyClauseReferenceMatches;
}

function geAllRemainingMatches(
  applicableClauses,
  _comparisonClauses,
  invertPairConstruction,
) {
  const comparisonClauses = [..._comparisonClauses];
  let remainingMatches = [];
  const usedClauses = {};
  applicableClauses.forEach(applicableClause => {
    const applicableClauseReference = getClauseReference(
      applicableClause.reference,
    );
    const referencesToExclude = getNearbyReferences(
      applicableClauseReference,
      true,
    );
    const remainingReferences = getRemainingReferences(
      comparisonClauses,
      referencesToExclude,
    );

    remainingReferences.forEach(remainingRef => {
      const result = processDataByReference(
        comparisonClauses,
        remainingRef,
        applicableClause,
        invertPairConstruction,
        usedClauses,
      );
      remainingMatches = remainingMatches.concat(result);
    });
  });
  return remainingMatches;
}

function getClauseReference(referenceRaw = "") {
  if (!referenceRaw) {
    return referenceRaw;
  }
  const numericRef = parseInt(referenceRaw, 10);
  if (numericRef) {
    return numericRef;
  }
  return referenceRaw.split(".")[0];
}

function getRemainingReferences(comparisonClauses, referencesToExclude) {
  const referencesToExcludeObj = referencesToExclude.reduce((result, item) => {
    result[item] = true;
    return result;
  }, {});

  const usedReferences = {};

  return comparisonClauses.reduce((accum, clause) => {
    const comparisonClauseReference = getClauseReference(clause.reference);
    if (
      comparisonClauseReference &&
      !referencesToExcludeObj[comparisonClauseReference] &&
      !usedReferences[comparisonClauseReference]
    ) {
      accum.push(comparisonClauseReference);
      usedReferences[comparisonClauseReference] = true;
    }
    return accum;
  }, []);
}

function getHandlers(startHandler, finishHandler) {
  const handlers = [
    {
      name: "getMatchesByExactReference",
      handler: getMatchesByExactReference,
    },
    {
      name: "getMatchesBySameReference",
      handler: getMatchesBySameReference,
    },
    {
      name: "getNearbyMatches",
      handler: getNearbyMatches,
    },
    {
      name: "geAllRemainingMatches",
      handler: geAllRemainingMatches,
    },
  ];

  const result = [];
  let hasStarted = false;
  let hasFinished = false;
  handlers.forEach(handler => {
    if (handler.name === finishHandler && (hasStarted && !hasFinished)) {
      result.push(handler);
      hasFinished = true;
    }

    if (handler.name === startHandler || (hasStarted && !hasFinished)) {
      result.push(handler);
      hasStarted = true;
    }
  });
  return result;
}

export function getSimilarPairsFromClauses(
  applicableClauses,
  comparisonClauses, // comparison document all clauses
  invertPairConstruction,
  startHandler = "getMatchesByExactReference",
  finishHandler = "geAllRemainingMatches",
) {
  const handlers = getHandlers(startHandler, finishHandler);

  for (const handlerItem of handlers) {
    const result = handlerItem.handler(
      applicableClauses,
      comparisonClauses,
      invertPairConstruction,
    );
    if (result.length > 0) {
      return result;
    }
  }
  return [];
}

function constructPair(
  applicableClause,
  comparisonClause,
  invertPairConstruction,
  areClausesIdentical,
) {
  if (invertPairConstruction) {
    return {
      clauseId: comparisonClause.id,
      comparisonClauseId: applicableClause.id,
      clause: comparisonClause,
      comparisonClause: applicableClause,
      areClausesIdentical,
    };
  }
  return {
    clauseId: applicableClause.id,
    comparisonClauseId: comparisonClause.id,
    clause: applicableClause,
    comparisonClause,
    areClausesIdentical,
  };
}

function getNearbyReferences(reference, includeBaseReference) {
  if (reference) {
    if (typeof reference === "number") {
      return [
        ...[reference - 2, reference - 1],
        ...(includeBaseReference ? [reference] : []),
        ...[reference + 1, reference + 2],
      ].filter(item => item > 0);
    }
    return includeBaseReference ? [reference] : [];
  }
  return [];
}

function processDataByReference(
  processedComparisonClauses,
  applicableClauseReference,
  applicableClause,
  invertPairConstruction,
  usedClauses = {}, // track which clauses already checked in order not to check again
) {
  const result = [];
  let shouldContinue = true;
  while (shouldContinue) {
    const sameReferenceClauseIndex = processedComparisonClauses.findIndex(
      comparisonClause => {
        const comparisonClauseReference = getClauseReference(
          comparisonClause.reference,
        );
        return (
          comparisonClauseReference &&
          comparisonClauseReference === applicableClauseReference &&
          !usedClauses[comparisonClause.id]
        );
      },
    );
    const comparisonClause =
      processedComparisonClauses[sameReferenceClauseIndex];

    if (sameReferenceClauseIndex !== -1 && !usedClauses[comparisonClause.id]) {
      const {areClausesSimilar, areClausesIdentical} = getSimilarityData(
        applicableClause,
        comparisonClause,
      );
      if (areClausesSimilar) {
        result.push(
          constructPair(
            applicableClause,
            comparisonClause,
            invertPairConstruction,
            areClausesIdentical,
          ),
        );
      }
      usedClauses[comparisonClause.id] = true;
    } else {
      shouldContinue = false;
    }
  }
  return result;
}

export default getSimilarPairsFromClauses;

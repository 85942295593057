import pluralise from "./utils/pluralise";

function listHelper(list, ..._keys) {
  const keys = _keys.splice(0, _keys.length - 1);
  return (list || []).map(value => {
    if (!keys || keys.length === 0) {
      return value;
    }
    let previousNumber = null;
    return keys
      .map(_key => {
        const shouldPluralise = _key.startsWith("pl_");
        const key = shouldPluralise ? _key.substring(3) : _key;
        const val = value[key] ?? "";
        if (parseInt(val, 10) === val || parseInt(val, 10).toString() === val) {
          previousNumber = val;
        }
        return `${shouldPluralise ? pluralise(previousNumber, val) : val}`;
      })
      .filter(item => item)
      .join(" ");
  });
}

export default listHelper;

export default function getPersonName({
  username,
  first_name: firstName,
  last_name: lastName,
}) {
  if (firstName && lastName) {
    return `${firstName} ${lastName} (${username || ""})`;
  }
  return username || "";
}

import React from "react";
import * as colors from "@material-ui/core/colors";
import {IssueIcon as IssueIconIcon} from "constants/icons";

const styles = {
  issueIconWrap: {
    position: "relative",
    height: 16,
    width: 16,
    borderRadius: "50%",
    flexShrink: 0,
    marginLeft: 3,
  },
  issueIcon: {
    height: 16,
    width: 16,
    position: "absolute",
    left: 0,
    top: 0,
    color: colors.red[500],
  },
};

function IssueIcon() {
  return (
    <span style={styles.issueIconWrap}>
      <IssueIconIcon style={styles.issueIcon} />
    </span>
  );
}

export default IssueIcon;

import React from "react";
import LoginContainer from "./containers/login";

export default function makeLogin(shouldForgotPasswordBeDialog = true) {
  return class Login {
    constructor() {
      this.path = "login";
    }

    getChildRoutes(state, cb) {
      cb(null, []);
    }

    getComponents(location, cb) {
      cb(null, {
        main: props => (
          <LoginContainer
            {...props}
            shouldForgotPasswordBeDialog={shouldForgotPasswordBeDialog}
          />
        ),
      });
    }
  };
}

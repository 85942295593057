import Describer from "../../../utils/issues/reason/describer";

function reason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);

  const value = describer.reasonValue.original_value;
  const unit = describer.reasonValue.unit;
  const min = issue.rules.min;
  const max = issue.rules.max;
  const issueUnit = issue.rules.unit;

  const minExists = min !== undefined && min !== null;
  const maxExists = max !== undefined && max !== null;

  const unitPlural = (minExists && maxExists) || max ? max > 1 : min > 1;

  return (
    [
      `Topic '${describer.topicName}'`,
      `has value '${value} ${unit}s'`,
      `for '${describer.parameterName}' parameter`,
      "which is",
      [
        minExists ? `more than ${min}` : "",
        minExists && maxExists ? " and " : "",
        maxExists ? `less than ${max}` : "",
      ].join(""),
      `${issueUnit}${unitPlural ? "s" : ""}`,
    ].join(" ") + (showClause ? ` in clause ${describer.clauseReference}` : "")
  );
}

export default reason;

import _ from "underscore";

function getRelatedClauseAtomsByTopicGroups(
  topicGroupNames,
  documentClauses,
  topicsById,
  acceptedTopicCategories,
  referencedTopics,
  applicableClauses = [],
) {
  const relatedAtoms = [];

  // for the case of a clause having several topics including
  // referenced topic we should keep track of all such clause
  // ids and eliminate them from the final result as they will
  // be shown in applicable clauses anyway
  const clauseWithReferenceTopicsIds = [];
  function getAtoms(node, clause) {
    const nodeText = node.text;
    if (nodeText && nodeText !== "and" && nodeText !== "or") {
      if (node.is_conjunction) {
        return;
      }
      let topicMatches = 0; // needed for the case of several topics in one atom
      let relatedAtom;
      node.topics &&
        node.topics.forEach(nodeTopic => {
          topicGroupNames.forEach(groupName => {
            if (
              topicsById[nodeTopic.topic_id] &&
              topicsById[nodeTopic.topic_id].name.includes(groupName) &&
              !nodeTopic.is_deleted &&
              acceptedTopicCategories.includes(nodeTopic.topiccategory_id) &&
              !applicableClauses.find(clause => clause.id === node.id)
            ) {
              if (topicMatches === 0) {
                relatedAtom = {
                  reference: node.reference,
                  text: node.partial_text || nodeText,
                  full_text: node.text || nodeText,
                  topics: [topicsById[nodeTopic.topic_id]],
                  id: node.id,
                  level: node.level,
                  clause_full_reference: clause.full_reference,
                  clause_reference: clause.reference,
                  clause_order: node.order,
                  clause_id: clause.id,
                  clause_section_id: clause.section_id,
                  nodes: clause.nodes,
                };
              } else {
                relatedAtom.topics.push(topicsById[nodeTopic.topic_id]);
              }
              topicMatches++;
            }
            if (
              referencedTopics &&
              referencedTopics.includes(nodeTopic.topic_id)
            ) {
              clauseWithReferenceTopicsIds.push(node.id);
            }
          });
        });
      if (relatedAtom && relatedAtom.id) {
        relatedAtom.topics = _.uniq(relatedAtom.topics);
        relatedAtoms.push(relatedAtom);
      }
    }
    if (node.clauseNodes) {
      node.clauseNodes.forEach(child => getAtoms(child, clause));
    }
  }
  _.forEach(documentClauses, clauses => {
    clauses.forEach(clause => {
      getAtoms(clause.nodes, clause);
    });
  });
  return relatedAtoms;
}

export default getRelatedClauseAtomsByTopicGroups;

import React, {useState, useEffect} from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import usePrevious from "utils/hooks/use_previous";

import validate, {getValidationMessage} from "common/utils/password_validator";

const styles = {
  textFieldBase: {
    marginBottom: "1rem",
    display: "block",
  },
  errorText: {
    fontSize: "14px",
    color: "#616161",
  },
  formControlWrapper: {
    width: "30%",
    margin: "8px 0px",
    marginLeft: "13px",
  },
  dialogActionsWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
};

function getErrorText(oldPassword, newPassword, newPasswordConfirm) {
  let errorMessage = "";
  if (!oldPassword) {
    errorMessage = "Please enter old password";
  } else if (!newPassword) {
    errorMessage = "Please enter new password";
  } else if (!newPasswordConfirm) {
    errorMessage = "Please confirm password";
  } else if (newPassword !== newPasswordConfirm) {
    errorMessage = "Passwords do not match";
  } else if (!validate(newPassword)) {
    errorMessage = getValidationMessage(newPassword);
  }
  return errorMessage;
}

function PasswordUpdateDialog(props) {
  const [isRequestPending, updateIsRequestPending] = useState(false);
  const [oldPassword, updateOldPassword] = useState("");
  const onOldPasswordChange = e => updateOldPassword(e.target.value);
  const [newPassword, updateNewPassword] = useState("");
  const onNewPasswordChange = e => updateNewPassword(e.target.value);
  const [newPasswordConfirm, updateNewPasswordConfirm] = useState("");
  const onNewPasswordConfirmChange = e =>
    updateNewPasswordConfirm(e.target.value);
  const [errorText, updateErrorText] = useState("");
  const {user} = props;
  const prevUser = usePrevious(user);
  useEffect(
    () => {
      updateIsRequestPending(false);
      if (
        user &&
        prevUser &&
        user.last_edited !== prevUser.last_edited &&
        user.use_analytics === prevUser.use_analytics
      ) {
        props.toggleDialog();
      }
    },
    [user],
  );

  const onSubmit = () => {
    const {user} = props;
    const errorText = getErrorText(
      oldPassword,
      newPassword,
      newPasswordConfirm,
    );
    if (errorText) {
      updateErrorText(errorText);
    } else {
      const {id, last_edited} = user;
      updateIsRequestPending(true);
      props.onUserPasswordUpdate(id, {
        old_password: oldPassword,
        new_password: newPassword,
        last_edited,
      });
    }
  };

  function renderOrganisationSelect() {
    if (!user.is_admin) {
      return null;
    }
    const {
      organisations = [],
      organisation_values: organisationValues = {},
    } = user;
    function onChange(e) {
      const newId = e.target.value;
      const newOrganisation = organisations.find(org => org.id === newId);
      props.onUserUpdate(user.id, {
        user_organisation: {
          id: newOrganisation.id,
          default_project_id: newOrganisation.default_project_id,
        },
      });
    }

    const currentOrganisationId =
      (Object.keys(organisationValues) || [])[0] ||
      ((user.organisations || [])[0] || {}).id;

    return (
      <FormControl style={styles.formControlWrapper}>
        <InputLabel>Organisation</InputLabel>
        <Select value={currentOrganisationId} onChange={onChange}>
          {organisations.map(org => (
            <MenuItem key={org.id} value={org.id}>
              {org.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  function toggleAnalytics() {
    props.onUserUpdate(user.id, {use_analytics: !user.use_analytics});
  }

  return (
    <Dialog fullWidth onClose={props.toggleDialog} open={true}>
      <DialogTitle>Change your password</DialogTitle>
      <DialogContent style={{padding: "0 24px"}}>
        <div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <form autoComplete="off" style={{width: "40%"}}>
              <TextField
                label="Old Password"
                type="password"
                disabled={isRequestPending}
                value={oldPassword}
                onChange={onOldPasswordChange}
                style={styles.textFieldBase}
                autoFocus={true}
              />
              <TextField
                label="New Password"
                type="password"
                disabled={isRequestPending}
                value={newPassword}
                onChange={onNewPasswordChange}
                style={styles.textFieldBase}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField
                  label="Confirm New Password"
                  type="password"
                  disabled={isRequestPending}
                  value={newPasswordConfirm}
                  onChange={onNewPasswordConfirmChange}
                  style={{...styles.textFieldBase, width: "100%"}}
                />
                {isRequestPending && <CircularProgress size={24} />}
              </div>
            </form>
            {renderOrganisationSelect()}
          </div>
          {errorText && <div style={styles.errorText}>{errorText}</div>}
        </div>
      </DialogContent>
      <DialogActions>
        <div style={styles.dialogActionsWrapper}>
          <div style={{marginLeft: "1em"}}>
            <FormControlLabel
              control={
                <Checkbox
                  onClick={toggleAnalytics}
                  checked={user.use_analytics}
                />
              }
              label="Accept analytics cookies"
            />
          </div>
          <div>
            <Button onClick={props.toggleDialog}>Cancel</Button>
            <Button color="primary" onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default PasswordUpdateDialog;

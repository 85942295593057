import reasonViewerTopicparameter from "utils/issues/reason_viewer_topicparameter";

function getDefaultIssueRuleValues(rules) {
  let path = `topics.[${rules.topic_id}].[${rules.parameter_id}]`;
  if (rules.role_id) {
    path = `${path}.[${rules.role_id}]`;
  }
  return [
    {
      path,
      value: "",
    },
  ];
}

export default {
  reasonViewer: reasonViewerTopicparameter,
  getDefaultIssueRuleValues,
};

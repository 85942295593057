import React, {useEffect, useState} from "react";
import Tooltip from "@material-ui/core/Tooltip";
import getRiskScoreIconColor from "../report/utils/get_risk_score_icon_color";
import calculateReportIssueStats from "../report/utils/calculate_report_issue_stats";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    color: "#0000008a",
  },
  dot: {
    display: "block",
    width: "0.8rem",
    height: "0.8rem",
    borderRadius: "50%",
    margin: "0 8px",
  },
};

const RiskScore = ({issues, issueset, isShow}) => {
  const [issueStats, setIssueStats] = useState(null);

  useEffect(() => {
    const issueStats = calculateReportIssueStats(issues, issueset);
    setIssueStats(issueStats);
  }, []);

  return (
    <div style={styles.container}>
      {isShow ? (
        <>
          <div>{"Risk"}</div>
          <Tooltip
            placement={"top"}
            title={issueStats ? `${issueStats} risk` : "...calculated"}
            arrow
          >
            <div style={{
              ...styles.dot,
              background: getRiskScoreIconColor(issueStats),
            }} />
          </Tooltip>
        </>
      ) : null}
    </div>
  );
};

export default RiskScore;

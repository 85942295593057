import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";

export default function templateModulesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.TEMPLATE_MODULES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.TEMPLATE_MODULE_CREATE.SUCCESS: {
      if (state.find(tm => tm.id === action.payload.id)) {
        return state;
      }
      return [...state, action.payload];
    }
    case ActionTypes.TEMPLATE_MODULE_UPDATE.SUCCESS:
      return state.map(
        module => (module.id !== action.payload.id ? module : action.payload),
      );
    default:
      return state;
  }
}

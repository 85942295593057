const issueClasses = {
  1: {name: "Issue"},
  2: {name: "Note"},
  3: {name: "Warning"},
  4: {name: "Hidden"},
};

export const generalIssueClasses = [1, 3, 4];

export default issueClasses;

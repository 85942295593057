import React from "react";
import _ from "underscore";

import DatePicker from "material-ui/DatePicker";
import TextField from "material-ui/TextField";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import ClearIcon from "material-ui/svg-icons/content/clear";

import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";
import ReactSelectLabel from "common_components/react_select_label";
import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

const compareTypes = {
  compare_to_upload: "Compare to upload",
  compare_to_absolute_date: "Compare to absolute date",
};

const rangeTypes = {
  before: "Before",
  after: "After",
};

const unitTypes = {
  day: "Day",
  month: "Month",
  year: "Year",
};

function initialise(state) {
  return {
    topic_id: null,
    parameter_id: null,
    type: "compare_to_upload",
    date: null,
    from: {
      unit: null,
      value: null,
      range: null,
    },
    to: {
      unit: null,
      value: null,
      range: null,
    },
    ..._.pick(state, "topic_id", "parameter_id", "type", "date", "from", "to"),
  };
}

function isDateFullyFilled(date) {
  return Boolean(date.unit && date.value && date.range);
}

function isDatePartiallyFilled(date) {
  return (date.unit || date.value || date.range) && !isDateFullyFilled(date);
}

function validate(issue) {
  const {rules} = issue;

  if (!(rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  if (!(rules.parameter_id > 0)) {
    return {rulesError: {parameter_id: "You must set a parameter id"}};
  }
  if (!rules.type) {
    return {rulesError: {type: "You must set compare type"}};
  }
  if (rules.type !== "compare_to_upload" && !rules.date) {
    return {rulesError: {date: "You must set a date to compare"}};
  }
  if (
    isDatePartiallyFilled(rules.from) ||
    isDatePartiallyFilled(rules.to) ||
    !(isDateFullyFilled(rules.from) || isDateFullyFilled(rules.to))
  ) {
    return {
      rulesError: {
        dates: "You must fill in all fields for FROM, TO or both dates",
      },
    };
  }
}

function getDateObject(strDate) {
  if (!strDate) {
    return null;
  }
  const dateArray = strDate.split("-");
  return new Date(
    parseInt(dateArray[0], 10),
    parseInt(dateArray[1], 10) - 1,
    parseInt(dateArray[2], 10),
  );
}

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      rulesError,
      topics,
      isNonOverridableFieldsDisabled,
      overridableFieldsStyle,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    return (
      <div>
        <div>
          <ReactSelectLabel>Topic</ReactSelectLabel>
          <Select
            className="topic"
            multi={false}
            value={rules.topic_id}
            options={topics
              .filter(topic =>
                topic.parameters.find(
                  parameter => parameter.parameter_type === "date",
                ),
              )
              .map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
            onChange={this.updateTopic}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getTopicLink(this.props.organisationId, rules.topic_id)}
            shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
          />
          {renderError(rulesError, "topic_id")}
        </div>
        <div>
          <ReactSelectLabel>Parameter</ReactSelectLabel>
          <Select
            className="parameter"
            multi={false}
            value={rules.parameter_id}
            options={
              currentTopic &&
              currentTopic.parameters
                .filter(parameter => parameter.parameter_type === "date")
                .map(parameter => ({
                  label: parameter.name,
                  value: parameter.id,
                }))
            }
            onChange={this.updateParameter}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getParameterLink(
              this.props.organisationId,
              rules.topic_id,
              rules.parameter_id,
            )}
          />
          {renderError(rulesError, "parameter_id")}
        </div>
        <SelectField
          value={rules.type}
          onChange={this.updateType}
          errorText={rulesError && rulesError.type}
          style={{
            width: "20em",
            zIndex: "0",
            ...overridableFieldsStyle,
          }}
          floatingLabelText="Type"
          disabled={isNonOverridableFieldsDisabled || disabled}
        >
          {Object.keys(compareTypes).map(ct => (
            <MenuItem key={ct} value={ct} primaryText={compareTypes[ct]} />
          ))}
        </SelectField>
        {rules.type === "compare_to_absolute_date" && (
          <div>
            <DatePicker
              hintText="Pick a date"
              container="inline"
              mode="landscape"
              value={getDateObject(rules.date)}
              onChange={this.updateDate}
              style={overridableFieldsStyle}
              disabled={isNonOverridableFieldsDisabled || disabled}
            />
            {renderError(rulesError, "date")}
          </div>
        )}
        <div style={{display: "flex"}}>
          <DateComponent
            title="From"
            dateRules={rules.from}
            clearDate={this.clearDate("from")}
            onUnitChange={this.updateDateValue("from", "unit")}
            onValueChange={this.updateDateValue("from", "value")}
            onRangeChange={this.updateDateValue("from", "range")}
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
          <DateComponent
            title="To"
            dateRules={rules.to}
            clearDate={this.clearDate("to")}
            onUnitChange={this.updateDateValue("to", "unit")}
            onValueChange={this.updateDateValue("to", "value")}
            onRangeChange={this.updateDateValue("to", "range")}
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
        </div>
        {renderError(rulesError, "dates")}
      </div>
    );
  }

  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
      parameter_id: null,
    });
  };
  updateParameter = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_id: value.value,
    });
  };
  updateType = (e, i, value) => {
    const {editLevel, rules, onChange} = this.props;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      type: value,
      date: null,
    });
  };
  updateDate = (e, date) => {
    const {editLevel, rules, onChange} = this.props;
    const formattedDate = `${date.getUTCFullYear()}-${zeroPad(
      date.getMonth() + 1,
    )}-${zeroPad(date.getDate())}`;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      date: formattedDate,
    });
  };
  updateDateValue = _.memoize(
    (dateType, itemType) => (e, i, value) => {
      const {editLevel, rules, onChange} = this.props;
      const newValue = itemType === "value" ? e.target.value : value;
      onChange({
        ...rules,
        ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
        [dateType]: {
          ...rules[dateType],
          [itemType]: newValue,
        },
      });
    },
    (...args) => JSON.stringify([...args]),
  );
  clearDate = _.memoize(
    dateType => () => {
      const {editLevel, rules, onChange} = this.props;
      onChange({
        ...rules,
        ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
        [dateType]: {
          unit: null,
          value: null,
          range: null,
        },
      });
    },
    (...args) => JSON.stringify([...args]),
  );
}

class DateComponent extends React.Component {
  render() {
    const {title, dateRules, overridableFieldsStyle} = this.props;
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "50%",
          alignItems: "center",
        }}
      >
        <div style={{display: "flex"}}>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "500",
              textDecoration: "underline",
            }}
          >
            {title}
          </div>
          <ClearIcon
            style={{
              height: "18px",
              width: "18px",
              position: "relative",
              top: "3px",
              marginLeft: "4px",
              color: "#757575",
              cursor: "pointer",
            }}
            onClick={this.props.clearDate}
          />
        </div>

        <SelectField
          value={dateRules && dateRules.unit}
          onChange={this.props.onUnitChange}
          style={{
            width: "10rem",
            zIndex: "0",
            ...overridableFieldsStyle,
          }}
          floatingLabelText="Unit"
          disabled={this.props.disabled}
        >
          {Object.keys(unitTypes).map(ut => (
            <MenuItem key={ut} value={ut} primaryText={unitTypes[ut]} />
          ))}
        </SelectField>
        <TextField
          type="number"
          value={(dateRules && dateRules.value) || ""}
          onChange={this.props.onValueChange}
          style={{width: "10rem", display: "block", ...overridableFieldsStyle}}
          name="amount"
          floatingLabelText="Amount"
          min={0}
          disabled={this.props.disabled}
        />
        <SelectField
          value={dateRules && dateRules.range}
          onChange={this.props.onRangeChange}
          style={{
            width: "10rem",
            zIndex: "0",
            ...overridableFieldsStyle,
          }}
          floatingLabelText="Range"
          disabled={this.props.disabled}
        >
          {Object.keys(rangeTypes).map(rt => (
            <MenuItem key={rt} value={rt} primaryText={rangeTypes[rt]} />
          ))}
        </SelectField>
      </div>
    );
  }
}

function zeroPad(d) {
  return `0${d}`.slice(-2);
}

export default {
  initialise,
  validate,
  component: Component,
};

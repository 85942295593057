import _ from "underscore";
import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import LoginComponent from "../components/login";
import requestor from "requestor";
import loginUser from "modules/user/actions/user_login";
import registerUser from "modules/user/actions/user_register";
import forgotPassword from "modules/user/actions/user_password_forgot";
import authenticateAadToken from "modules/user/actions/user_authenticate_aad_token";
import errorClearAction from "modules/user/actions/error_clear";
import localStorage from "utils/local_storage";
import getDomain from "utils/get_domain";

class Login extends React.Component {
  render() {
    const isProd = process.env.NODE_ENV === "production";
    const isInternal = document?.location?.host === "internal.lexicallabs.com";
    const isStaging =
      document?.location?.host === "staging.lexicallabs.com" ||
      document?.location?.host === "uat-api.lexicallabs.com";
    const domain = getDomain();
    const {shouldForgotPasswordBeDialog} = this.props;
    const isPanelDisplay = getIsPanelDisplay();
    return (
      <LoginComponent
        onLogin={this.onLogin}
        onRegister={this.onRegister}
        user={this.props.user}
        allowRegistration={this.doesAllowRegistration(localStorage)}
        onForgotPassword={this.onForgotPassword}
        queryString={this.props.location.search}
        clearError={this.props.clearError}
        isPanelDisplay={isPanelDisplay}
        aadLoginLink={
          (isProd && domain !== "lexicallabs") || isPanelDisplay
            ? undefined
            : `${
                isStaging
                  ? "https://uat-api.lexicallabs.com"
                  : isProd
                  ? "https://api.lexicallabs.com"
                  : "http://localhost:5010"
              }/api/1.0/login-aad`
        }
        aadLoginAction={
          isPanelDisplay ? () => this.props.authenticateAadToken() : undefined
        }
        googleLoginLink={`${
          isInternal
            ? "https://api.lexicallabs.com/api/1.0/google/internal/login"
            : isProd
            ? "https://api.lexicallabs.com/api/1.0/google/login"
            : "http://localhost:5010/api/1.0/google/login"
        }`}
        shouldForgotPasswordBeDialog={
          _.isBoolean(shouldForgotPasswordBeDialog)
            ? shouldForgotPasswordBeDialog
            : true
        }
      />
    );
  }

  doesAllowRegistration(locStorage) {
    const allowRegistrationFromLocalStorage =
      locStorage.getItem("register") === "true";

    return allowRegistrationFromLocalStorage;
  }

  /* eslint-disable no-invalid-this */
  onLogin = (username, password) => {
    this.props.loginUser(username, password);
  };
  onRegister = (username, password) => {
    this.props.registerUser(username, password);
  };
  onForgotPassword = email => {
    this.props.forgotPassword(email);
  };
  /* eslint-enable no-invalid-this */
}

function getIsPanelDisplay() {
  return window.location.href.indexOf("/word/taskpane") > -1;
}

function select(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      loginUser: loginUser(requestor),
      registerUser: registerUser(requestor),
      forgotPassword: forgotPassword(requestor),
      authenticateAadToken: authenticateAadToken(requestor),
      clearError: errorClearAction,
    },
    dispatch,
  );
}

export default connect(select, mapDispatchToProps)(Login);
export const Component = Login;

import React, {useEffect, useState} from "react";
import Menu from "@material-ui/core/Menu";
import {MenuItem} from "material-ui";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import {
  GuidanceIcon,
  IssueIcon,
  NoIssueIcon,
  WarningIcon,
} from "../../constants/icons";
import * as colors from "@material-ui/core/colors";
import uuid from "../../utils/uuid";

const styles = {
  dotContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  dot: {
    display: "block",
    width: 14,
    height: 14,
    borderRadius: "50%",
    margin: "0.3rem auto",
    cursor: "pointer",
  },
  icon: {
    width: 18,
    height: 18,
    marginRight: 10,
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
  },
};

const statusItems = {
  red: {
    color: "#ef5350",
    label: "Issue",
    Icon: IssueIcon,
    value: "red",
  },
  amber: {
    color: "#ff8800",
    label: "Warning",
    Icon: WarningIcon,
    value: "amber",
  },
  green: {
    color: "#66BB6A",
    label: "No issue",
    Icon: NoIssueIcon,
    value: "green",
  },
  grey: {
    color: colors.grey[500],
    label: "Guidance",
    Icon: GuidanceIcon,
    value: "grey",
  },
};

const reviewState = {
  "1": {
    color: "#a2c3e0",
    label: "Unread",
    Icon: FiberManualRecordIcon,
    value: 1,
  },
  "0": {
    color: "#f3f3f3",
    label: "Read",
    Icon: FiberManualRecordIcon,
    value: 0,
  },
  "3": {
    color: "#fc6166",
    label: "Escalate",
    Icon: FiberManualRecordIcon,
    value: 3,
  },
};

const AdditionalCircle = ({onEditSave, value, status}) => {
  const [menuItems, setMenuItems] = useState([]);
  const [itemValue, setItemValue] = useState("#cecece");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (status) {
      setMenuItems(statusItems);
      if (value !== "Blank") {
        setItemValue(statusItems[value]?.color);
      }
    } else {
      setMenuItems(reviewState);
      if (value !== "Blank") {
        setItemValue(reviewState[value]?.color);
      }
    }
  }, []);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleSave = (event) => {
    const value = event.currentTarget.getAttribute("flag");
    setAnchorEl(null);
    setItemValue(menuItems[value]?.color);
    onEditSave(value);
  };

  return (
    <div style={styles.dotContainer}>
      <div
        style={{
          ...styles.dot,
          backgroundColor: itemValue,
          border: itemValue === "#f3f3f3"
            ? "1px dashed #ddd"
            : "none"
          }}
        aria-controls="additional-menu"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id="additional-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          Object.values(menuItems).map(({color, label, Icon, value}) => (
            <MenuItem
              key={uuid()}
              onClick={handleSave}
              flag={value}
            >
              <div style={styles.menuItem}>
                <Icon style={{...styles.icon, color}} />{label}
              </div>
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  );
};

export default AdditionalCircle;

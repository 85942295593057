import React, {useState} from "react";
import _ from "lodash";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ArrowDropDown";
import SaveIcon from "@material-ui/icons/Save";

import {Menu, MenuItem} from "@material-ui/core";

const styles = {
  closeIcon: {
    cursor: "pointer",
  },
  tableHeader: {
    textAlign: "left",
  },
};

function caseSensitiveDifference(arr1, arr2) {
  const result = [];
  arr1.forEach(arr1Item => {
    const arr1ItemClean = arr1Item.trim().toLowerCase();
    let isArr1ItemInArr2 = false;
    arr2.forEach(arr2Item => {
      const arr2ItemClean = arr2Item.trim().toLowerCase();
      if (arr1ItemClean === arr2ItemClean) {
        isArr1ItemInArr2 = true;
      }
    });
    if (!isArr1ItemInArr2) {
      result.push(arr1Item);
    }
  });
  return result;
}

function constructLinkedDefinitionsList(
  definitionGroups,
  definitionTerms,
  linkedDefinitions,
) {
  const notLinkedDefinitionTerms = caseSensitiveDifference(
    definitionTerms,
    Object.values(linkedDefinitions),
  );

  return definitionGroups.map(dg => {
    const value = linkedDefinitions[dg.id] ? linkedDefinitions[dg.id] : null;
    return {
      ...dg,
      value,
      options: notLinkedDefinitionTerms,
    };
  });
}

function getInitialLinkedDefinitions(definitionGroups, definitionTerms) {
  const result = {};
  const remainingDefinitions = [...definitionTerms];

  definitionGroups.forEach(dg => {
    const {synonyms = []} = dg;
    let isValueFound = false;
    synonyms.forEach(synonym => {
      if (!isValueFound) {
        const synonymClean = synonym.trim().toLowerCase();
        let isTermFound = false;
        let foundTermIndex;
        remainingDefinitions.forEach(
          (remainingDefinition, remainingDefinitionIndex) => {
            const remainingDefinitionClean = remainingDefinition
              .trim()
              .toLowerCase();
            if (!isTermFound && remainingDefinitionClean === synonymClean) {
              isTermFound = true;
              isValueFound = true;
              foundTermIndex = remainingDefinitionIndex;
              result[dg.id] = remainingDefinition;
            }
            if (foundTermIndex !== undefined) {
              remainingDefinitions.splice(foundTermIndex, 1);
            }
          },
        );
      }
    });
  });
  return result;
}

function LinkedDefinitionsListItem(props) {
  const {options = []} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function onDelete() {
    props.updateLinkedDefinitionState(props.id, undefined);
  }

  return (
    <tr style={{borderBottom: "1px solid #e0e0e0"}}>
      <td>
        <div>{props.name}</div>
      </td>
      <td>
        <div style={{...(props.containerStyles ? props.containerStyles : {})}}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              position: "relative",
              left: "-6px",
              padding: "12px 0px",
            }}
            onClick={handleClick}
          >
            <ExpandMoreIcon />
            {props.value ? (
              <div>{props.value}</div>
            ) : (
              <div style={{fontStyle: "italic"}}>None Selected</div>
            )}
          </div>

          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{style: {borderRadius: 0}}}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            {options.map((option, optionIndex) => {
              function handler() {
                props.updateLinkedDefinitionState(props.id, option);
              }
              return (
                <MenuItem
                  key={optionIndex}
                  onClick={createItemClicker(handleClose, handler)}
                >
                  <div>{option}</div>
                </MenuItem>
              );
            })}
            {options.length === 0 ? (
              <MenuItem onClick={handleClose}>
                <div style={{fontStyle: "italic"}}>No options available</div>
              </MenuItem>
            ) : null}
          </Menu>
        </div>
      </td>
      <td>
        {props.value && (
          <DeleteIcon style={styles.closeIcon} onClick={onDelete} />
        )}
      </td>
    </tr>
  );
}

function createItemClicker(onClose, handler) {
  return e => {
    e.stopPropagation();
    if (handler) {
      handler();
    }
    onClose();
  };
}

function LinkdedDefinitions(props) {
  const {document, documentDefinitions, definitionGroups} = props;
  const {
    contract_type: {id: documentContractTypeId},
    linked_definitions: linkedDefinitions = {},
  } = document;
  const documentDefinitionGroups = definitionGroups.filter(dg =>
    dg.contract_types.includes(documentContractTypeId),
  );
  const definitionTerms = documentDefinitions.map(def => def.term);

  const initialDefinitions = getInitialLinkedDefinitions(
    documentDefinitionGroups,
    definitionTerms,
  );

  const [linkedDefinitionsState, onLinkedDefinitionsStateUpdate] = useState(
    Object.keys(linkedDefinitions).length === 0
      ? initialDefinitions
      : linkedDefinitions,
  );

  function updateLinkedDefinitionState(key, value) {
    const newValue = {
      ...linkedDefinitionsState,
      [key]: value,
    };
    if (!value) {
      delete newValue[key];
    }
    onLinkedDefinitionsStateUpdate(newValue);
  }

  const linkedDefinitionsList = constructLinkedDefinitionsList(
    documentDefinitionGroups,
    definitionTerms,
    linkedDefinitionsState,
  );

  function onSave() {
    const addedSynonyms = {};
    definitionGroups.forEach(dg => {
      const linkedDefinition = linkedDefinitionsState[dg.id];
      if (linkedDefinition) {
        const {synonyms} = dg;
        const foundSynonym = synonyms.find(
          synonym =>
            synonym.trim().toLowerCase() ===
            linkedDefinition.trim().toLowerCase(),
        );
        if (!foundSynonym) {
          addedSynonyms[dg.id] = addedSynonyms[dg.id]
            ? [...addedSynonyms[dg.id], linkedDefinition]
            : [linkedDefinition];
        }
      }
    });
    const updates = {
      linked_definitions: linkedDefinitionsState,
      added_synonyms: addedSynonyms,
    };
    if (Object.keys(addedSynonyms).length > 0) {
      updates.added_synonyms = addedSynonyms;
    }
    props.updateDocument(updates);
  }

  return (
    <div style={{color: "#616161", paddingBottom: "12px"}}>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
          marginBottom: "16px",
        }}
      >
        <thead>
          <tr style={{borderBottom: "1px solid #e0e0e0"}}>
            <th style={{...styles.tableHeader, width: "45%"}}>
              Definition Group Name
            </th>
            <th style={{...styles.tableHeader, width: "50%"}}>
              Definition Term
            </th>
            <th style={{...styles.tableHeader, width: "5%"}} />
          </tr>
        </thead>
        <tbody>
          {linkedDefinitionsList.map((listItem, listItemIndex) => (
            <LinkedDefinitionsListItem
              key={`linked-definitions-${listItemIndex}`}
              {...listItem}
              updateLinkedDefinitionState={updateLinkedDefinitionState}
            />
          ))}
        </tbody>
      </table>
      {!_.isEqual(linkedDefinitions, linkedDefinitionsState) && (
        <div style={{display: "flex", justifyContent: "center"}}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            onClick={onSave}
            style={{borderRadius: 0}}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
}

function LinkedDefinitionsDialog(props) {
  return (
    <Dialog
      open={props.isShown}
      onClose={props.onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{style: {borderRadius: 0}}}
    >
      <DialogTitle id="form-dialog-title">
        <div style={{display: "flex", justifyContent: "space-between"}}>
          <Typography variant="h6">Linked Definitions</Typography>
          <CloseIcon onClick={props.onClose} style={styles.closeIcon} />
        </div>
      </DialogTitle>
      <DialogContent>
        <LinkdedDefinitions
          documentDefinitions={props.documentDefinitions}
          definitionGroups={props.definitionGroups}
          contractTypesById={props.contractTypesById}
          document={props.document}
          updateDocument={props.updateDocument}
        />
      </DialogContent>
    </Dialog>
  );
}

export default LinkedDefinitionsDialog;

import React from "react";
import _ from "lodash";

import TextWithDropdown from "../components/text_with_dropdown";

import escapeRegex from "utils/escape_regex";

const constructOptions = (baseOptions = []) => {
  const definitions = {};
  const nonDefinitions = {};
  for (const option of baseOptions) {
    if (option.origin === "definition") {
      definitions[option.value] = option;
    } else {
      nonDefinitions[option.value] = option;
    }
  }
  const result = {
    ...nonDefinitions,
    ...definitions,
  };
  return Object.keys(result).map(key => result[key]);
};

function substituteAndStyleText(
  fullText,
  textReference = "",
  areDefinitionsSubstituted,
  substitutionMap,
  useTextOnly,
  optionsMap,
  precedentDefinitions,
  handlers = {},
) {
  const {updateValueFromText, clearUserSubstitution} = handlers;
  const textArrayByNewLines = fullText.split("\n");
  const termsList = Object.keys(substitutionMap);
  if (termsList.length === 0) {
    return useTextOnly
      ? [fullText]
      : textArrayByNewLines.map((text, index) => <div key={index}>{text}</div>);
  }
  const termsListJoined = _.chain(termsList)
    .sortBy(item => -item.length)
    .map(item => escapeRegex(item))
    .value()
    .join("|");
  const termsRegex = new RegExp(
    `(^|\\s|\\(|"|'|\`)(the |a |an |The |A |An )?(${termsListJoined})(s|es|'s|’s|\\)|"|'|\`|;|:|,|\\.|\\(s\\)){0,2}(?=$|\\s|\\.)`,
    "g",
  );

  let textArr = [];

  textArrayByNewLines.forEach((text, textIndex) => {
    const textArrItem = [];
    let prevEnd = -1;
    let match;
    let keyIndex = 0;
    while ((match = termsRegex.exec(text))) {
      const firstCapGroupMatch = match[1];
      const articleMatch = match[2];
      const matchedTerm = match[3] || ""; // [3] - index to match 3rd capture group

      let start = match.index;
      if (firstCapGroupMatch) {
        start += firstCapGroupMatch.length;
      }

      const unmatchedSegment = text.slice(prevEnd + 1, start);
      textArrItem.push(
        useTextOnly ? (
          unmatchedSegment
        ) : (
          <span key={keyIndex++}>{unmatchedSegment}</span>
        ),
      );

      // substitution and styling
      const substitutionMapItem = substitutionMap[matchedTerm];

      const isItemSubstituted =
        substitutionMapItem.substitutedByUser !== undefined
          ? substitutionMapItem.substitutedByUser
          : substitutionMapItem.isSubstituted;

      const userSavedValue = _.get(substitutionMapItem, "userValue.value");
      const bestMatchvalue = _.get(substitutionMapItem, "value.value");

      let valueToFill = matchedTerm;
      if (areDefinitionsSubstituted) {
        valueToFill = isItemSubstituted
          ? userSavedValue || bestMatchvalue
          : matchedTerm;
      }

      // if atom starts with capital letter OR
      // if the term starts with capital article
      // we capitalise substitution value;
      // we add 2 because after text reference
      // there are ". " dot and space
      const matchStartsFromBeginning = textReference
        ? start - (textReference.length + 2) === 0
        : start === 0;
      if (
        (matchStartsFromBeginning &&
          valueToFill &&
          matchedTerm &&
          matchedTerm[0].toUpperCase() === matchedTerm[0]) ||
        (articleMatch && articleMatch[0].toUpperCase() === articleMatch[0])
      ) {
        valueToFill = valueToFill[0].toUpperCase() + valueToFill.slice(1);
      }

      if (useTextOnly) {
        textArrItem.push(valueToFill);
      } else {
        textArrItem.push(
          areDefinitionsSubstituted ? (
            <TextWithDropdown
              key={keyIndex++}
              text={valueToFill}
              isItemSubstituted={isItemSubstituted}
              matchedTerm={matchedTerm}
              options={constructOptions(optionsMap[matchedTerm] || [])}
              updateValueFromText={updateValueFromText}
              clearUserSubstitution={clearUserSubstitution}
              precedentDefinition={precedentDefinitions.find(
                def => def.term.toLowerCase() === matchedTerm.toLowerCase(),
              )}
              shouldShowClear={substitutionMapItem.substitutedByUser}
            />
          ) : (
            <span key={keyIndex++}>{valueToFill}</span>
          ),
        );
      }
      prevEnd =
        start +
        matchedTerm.length -
        1 +
        (articleMatch ? articleMatch.length : 0);
    }

    if (prevEnd < text.length - 1) {
      // add tail
      const tail = text.slice(prevEnd + 1);
      textArrItem.push(useTextOnly ? tail : <span key={keyIndex}>{tail}</span>);
    }

    if (useTextOnly) {
      textArr = [
        ...textArr,
        textArr.length > 0 && textArrItem.length > 0 ? "\n" : null,
        ...textArrItem,
      ].filter(item => item);
    } else {
      textArr.push(<div key={`clausepart-${textIndex}`}>{textArrItem}</div>);
    }
  });

  return textArr;
}

export default substituteAndStyleText;

import React from "react";
import Paper from "material-ui/Paper";
import Report from "common_components/report";
import ReportSelector from "common_components/report_selector";

const styles = {
  root: {
    overflow: "hidden",
  },
  paper: {
    borderRadius: 0,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    backgroundColor: "#f1f1f1",
  },
};

const ReportView = props => {
  const {selectedReportId, project, document} = props;
  const reportSettings =
    document.report_settings.find(rep => rep.id === selectedReportId) ||
    project.report_settings.find(rep => rep.id === selectedReportId) ||
    {};
  return (
    <div style={styles.root}>
      <Paper style={styles.paper}>
        <div style={styles.header}>
          <p>{document.name}</p>
          {props.reportSettings.length > 1 ? (
            <ReportSelector
              project={project}
              isReportPanelShown={props.isReportPanelShown}
              toggleReportPanelVisibility={props.toggleReportPanelVisibility}
              selectedReportId={props.selectedReportId}
              onSelectedReportChange={props.onSelectedReportChange}
              responseAddedToReport={false}
              currentIssueset={props.currentIssueset}
            />
          ) : null}
        </div>
        <Report
          key={selectedReportId}
          reportSettings={reportSettings}
          reports={props.reportSettings}
          user={props.user}
          project={project}
          document={document}
          documentClauses={props.documentClauses}
          documentHeadings={props.documentHeadings}
          documentClauseparts={props.documentClauseparts}
          topicCategories={props.topicCategories}
          documentSections={props.documentSections}
          topicsById={props.topicsById}
          issues={props.issues}
          positiveReasonData={props.positiveReasonData}
          groupedIssues={props.groupedIssues}
          documentIssuesManualUpdateCount={
            props.documentIssuesManualUpdateCount
          }
          updateDocumentIssue={props.updateDocumentIssue}
          revertIssueActionState={props.revertIssueActionState}
          updateDocument={props.updateDocument}
          showIssueDetail={props.showIssueDetail}
          updateIssueDetail={props.updateIssueDetail}
          showIssuesInChecklist={props.showIssuesInChecklist}
          scrollToClause={props.scrollToClause}
          editModeOn={props.editModeOn}
          areReportItemsNumbered={reportSettings.are_report_items_numbered}
          addAllItemsToReport={reportSettings.add_all_items_to_report}
          currentIssuesetItem={props.currentIssuesetItem}
          correctDocumentIssueManually={props.correctDocumentIssueManually}
          panelData={props.panelData}
          selectedReportId={selectedReportId}
          toggleReportPanelVisibility={props.toggleReportPanelVisibility}
          toggleReportOpenInRightSidebar={props.toggleReportOpenInRightSidebar}
          removeIssueFromReport={props.removeIssueFromReport}
          addIssueToReport={props.addIssueToReport}
          selectedChecklistSectionId={props.selectedChecklistSectionId}
          contractTypes={props.contractTypes}
          updateIssueReviewState={props.updateIssueReviewState}
          organisationId={props.organisationId}
          isDocumentReport
          reviewTrackingOn={props.reviewTrackingOn}
          onSelectedReportChange={props.onSelectedReportChange}
        />
      </Paper>
    </div>
  );
};

export default ReportView;

import React from "react";
import LinkIcon from "@material-ui/icons/Link";

export default function renderTemplateDocumentLinks({templateDocumentLink}) {
  if (!templateDocumentLink) {
    return null;
  }
  if (templateDocumentLink.indexOf(";") > 0) {
    return templateDocumentLink.split(";").map(string => {
      return renderTemplateDocumentLinkString(string);
    });
  } else {
    return renderTemplateDocumentLinkString(templateDocumentLink);
  }
}

function renderTemplateDocumentLinkString(string) {
  if (string.indexOf("|") > 0) {
    const [name, link] = string.split("|");
    return renderTemplateDocumentLink(link, name);
  } else {
    return renderTemplateDocumentLink(string);
  }
}

function renderTemplateDocumentLink(link, name = "Template Document") {
  return (
    <div>
      <a
        href={link}
        style={{
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          color: "#42a5f5",
          fontWeight: 700,
          marginLeft: "24px",
        }}
        title={name}
        target="_blank"
      >
        <span>{name}</span>
        <LinkIcon
          style={{
            marginLeft: "0.25em",
          }}
        />
      </a>
    </div>
  );
}

// taken from:
//   https://philipwalton.com/articles/
//      the-google-analytics-setup-i-use-on-every-site-i-build/

import _ from "underscore";
import "autotrack/autotrack.js";
import getRouteIds from "utils/get_route_ids";
import logger from "common/utils/logger";
import uuid from "utils/uuid";

const dimensions = {
  TRACKING_VERSION: "dimension1",
  CLIENT_ID: "dimension2",
  WINDOW_ID: "dimension3",
  HIT_ID: "dimension4",
  HIT_TIME: "dimension5",
  HIT_TYPE: "dimension6",
  organisationId: "dimension7",
};

const metrics = {
  RESPONSE_END_TIME: "metric1",
  DOM_LOAD_TIME: "metric2",
  WINDOW_LOAD_TIME: "metric3",
};

const whitelist = /_(SUCCESS|TRACK)$/;
const blacklist = /_(FETCH|INIT|CLEAR|QUALIFICATION|CLASSIFICATION)_/;

/* eslint-disable func-style */
export default function createAnalytics(
  googleAnalyticsId,
  version,
  globalWindow,
) {
  return store => next => action => {
    /* eslint-enable func-style */
    if (action.type.match(whitelist) && !action.type.match(blacklist)) {
      if (!ignoreAnalytics()) {
        fireAnalytics(
          googleAnalyticsId,
          version,
          store.getState(),
          action,
          globalWindow,
        );
      }
    }
    return next(action);
  };
}

let firstTime = true;
let lastOrganisationId;
let lastTime;
let lastData;

function ignoreAnalytics() {
  return window.localStorage.ll_use_analytics === "false";
}

function fireAnalytics(
  googleAnalyticsId,
  version,
  state,
  action,
  globalWindow,
) {
  if (ignoreAnalytics()) {
    return;
  }
  initAnalytics(googleAnalyticsId, version, globalWindow);
  setOrganisation(state);
  trackAction(action);
}

function setOrganisation(state) {
  const routeIds = getRouteIds(state.router);
  const {organisationId} = routeIds;
  if (organisationId !== lastOrganisationId) {
    window.ga("set", dimensions.organisationId, organisationId);
    lastOrganisationId = organisationId;
  }
}

function filterData(data) {
  if (typeof data === "object") {
    return _.object(
      _.map(data, (value, key) => {
        if ((JSON.stringify(value)?.length ?? 0) > 100) {
          return [key, JSON.stringify(value)?.substring(0, 100) ?? ""];
        }
        return [key, value];
      }).filter(item => item),
    );
  }
  return data;
}

function trackAction(action) {
  const {type} = action;
  const words = type.split("_");
  const usefulWords = _.initial(words);
  const eventCategory = _.initial(usefulWords).join(" ");
  const eventAction = _.last(usefulWords);
  const now = new Date().valueOf();

  if (action.type === "USER_LOGIN_SUCCESS") {
    window.ga("send", "pageview", {sessionControl: "start"});
  }
  if (eventCategory && eventAction) {
    const eventLabel = filterData(action.payload);
    if (
      isDataDifferent(eventCategory, eventAction, eventLabel) ||
      isNewEvent(now)
    ) {
      const data = {
        hitType: "event",
        eventCategory,
        eventAction,
        ...(eventLabel ? {eventLabel: JSON.stringify(eventLabel)} : {}),
      };
      if (window.gaImmediate) {
        window.ga("send", data);
      } else {
        setTimeout(() => {
          window.ga("send", data);
        }, 1);
      }
      lastTime = now;
      lastData = {eventCategory, eventAction, eventLabel};
    }
  } else if (!eventCategory && eventAction) {
    logger.warn("Missing eventCategory for last event", type);
  } else {
    logger.warn(
      "Missing both eventCategory and eventAction for last event",
      type,
    );
  }
  if (action.type === "USER_LOGOUT_SUCCESS") {
    window.ga("send", "pageview", {sessionControl: "end"});
  }
}

function isDataDifferent(eventCategory, eventAction, eventLabel) {
  return (
    !lastData || !_.isEqual(lastData, {eventCategory, eventAction, eventLabel})
  );
}

function isNewEvent(now) {
  return lastTime && now - lastTime > 5000;
}

export function initAnalytics(
  googleAnalyticsId,
  version,
  globalWindow = window,
  environment = "production",
) {
  if (ignoreAnalytics()) {
    return;
  }
  if (!firstTime) {
    return;
  }
  /* eslint-disable id-length */
  globalWindow.ga =
    globalWindow.ga ||
    ((...args) => (globalWindow.ga.q = globalWindow.ga.q || []).push(args));
  /* eslint-enable id-length */

  globalWindow.ga(
    "create",
    googleAnalyticsId,
    environment === "production" ? "auto" : "none",
  );
  globalWindow.ga("require", "cleanUrlTracker");
  globalWindow.ga("require", "maxScrollTracker", {
    maxScrollMetricIndex: 4,
  });
  globalWindow.ga("require", "pageVisibilityTracker", {
    visibleMetricIndex: 5,
    pageLoadsMetricIndex: 6,
    sendInitialPageview: true,
  });
  globalWindow.ga("require", "urlChangeTracker");
  globalWindow.ga("set", dimensions.TRACKING_VERSION, version);
  globalWindow.ga("set", dimensions.WINDOW_ID, uuid());
  globalWindow.ga(tracker => {
    globalWindow.ga("set", "transport", "beacon");

    const clientId = tracker.get("clientId");
    tracker.set(dimensions.CLIENT_ID, clientId);

    const originalBuildHitTask = tracker.get("buildHitTask");
    tracker.set("buildHitTask", model => {
      model.set(dimensions.HIT_ID, uuid(), true);
      model.set(dimensions.HIT_TIME, new Date().valueOf().toString(), true);
      model.set(dimensions.HIT_TYPE, model.get("hitType"), true);

      originalBuildHitTask(model);
    });
  });
  sendNavigationTimingMetrics(globalWindow);
  trackErrors(globalWindow);
  firstTime = false;
}

export function sendNavigationTimingMetrics(globalWindow) {
  // Only track performance in supporting browsers.
  if (!(globalWindow.performance && globalWindow.performance.timing)) {
    return;
  }
  // If the window hasn't loaded, run this function after the `load` event.
  if (globalWindow.document.readyState !== "complete") {
    globalWindow.addEventListener("load", () =>
      sendNavigationTimingMetrics(globalWindow),
    );
    return;
  }

  const nt = globalWindow.performance.timing;
  const navStart = nt.navigationStart;

  const responseEnd = Math.round(nt.responseEnd - navStart);
  const domLoaded = Math.round(nt.domContentLoadedEventStart - navStart);
  const windowLoaded = Math.round(nt.loadEventStart - navStart);

  // In some edge cases browsers return very obviously incorrect NT values,
  // e.g. 0, negative, or future times. This validates values before sending.
  function allValuesAreValid(...values) {
    return values.every(value => value > 0 && value < 1e6);
  }
  if (allValuesAreValid(responseEnd, domLoaded, windowLoaded)) {
    globalWindow.ga("send", "event", {
      eventCategory: "Navigation Timing",
      eventAction: "track",
      nonInteraction: true,
      [metrics.RESPONSE_END_TIME]: responseEnd,
      [metrics.DOM_LOAD_TIME]: domLoaded,
      [metrics.WINDOW_LOAD_TIME]: windowLoaded,
    });
  }
}
export function trackErrors(globalWindow) {
  // This is set in the first line of html
  /* eslint-disable no-underscore-dangle */
  /* eslint-disable id-length */
  const loadErrorEvents = globalWindow.__e.q || [];
  /* eslint-enable no-underscore-dangle */
  /* eslint-enable id-length */
  const fieldsObj = {eventAction: "uncaught error"};
  // Replay any stored load error events.
  loadErrorEvents.forEach(event =>
    trackError(event.error, fieldsObj, globalWindow),
  );

  // Add a new listener to track event immediately.
  globalWindow.addEventListener("error", event => {
    trackError(event.error, fieldsObj, globalWindow);
  });
}

export function resetAnalytics() {
  firstTime = true;
}

export function trackError(error, fieldsObj = {}, globalWindow = window) {
  globalWindow.ga(
    "send",
    "event",
    Object.assign(
      {
        eventCategory: "Script",
        eventAction: "error",
        eventLabel: (error && error.stack) || "(not set)",
        nonInteraction: true,
      },
      fieldsObj,
    ),
  );
}

import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import requestor from "requestor";
import {isInitialised} from "utils/uninitialised";

import ErrorsComponent from "../components/errors";

import documentClearAction from "modules/documents/actions/document_clear";
import documentFetchAction from "modules/documents/actions/document_fetch";

function Errors(props) {
  const shouldRender = () => isInitialised([props.error]);

  const fetchDocument = async () => {
    const {organisationId, projectId, documentId} = props.params;
    await props.clearDocument();
    await props.fetchDocument(organisationId, projectId, documentId);
  };

  return shouldRender() ? (
    <ErrorsComponent
      {...props}
      fetchDocument={fetchDocument}
      now={new Date()}
    />
  ) : (
    <div />
  );
}

function select(state) {
  return {
    error: state.error,
    router: state.router,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        fetchDocument: documentFetchAction(requestor),
        clearDocument: documentClearAction,
      },
      dispatch,
    ),
  };
}

export default connect(select, mapDispatchToProps)(Errors);
export const Component = Errors;

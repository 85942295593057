export default function(topics, isSelected) {
  const data = topics.reduce((acc, topic) => {
    const names = topic.topic.name.split(/\s*\/\s*/i);
    const {isDisabled} = topic.clauseTopic;
    const namesLastIndex = names.length - 1;
    let item = acc;
    names.forEach((name, i) => {
      if (i < namesLastIndex) {
        const parent = item.find(element => element.name === name);
        if (!parent) {
          item.push({
            name,
            children: [],
            count: 0,
            selectionCount: 0,
            nestingLevel: 0,
            isItemOpened: true,
          });
        }
        const parentItem = parent || item[item.length - 1];
        if (!parentItem.children) {
          parentItem.children = [];
        }
        item = parentItem.children;
      } else {
        item.push({
          name,
          count: topic.count,
          id: topic.id,
          selected: isSelected({id: topic.id}) || false,
          isDisabled,
          isItemOpened: false,
        });
      }
    });
    return acc;
  }, []);
  function calculateCounts(item, nestingLevel) {
    if (item.children) {
      if (nestingLevel !== 0) {
        item.isItemOpened = false;
      }
      item.children.forEach(child => calculateCounts(child, nestingLevel + 1));
    }
    if (!item.children) {
      item.nestingLevel = nestingLevel;
      item.isItemOpened = false;
      return;
    }
    [item.count, item.selectionCount] = item.children.reduce(
      (acc, element) => {
        const count = acc[0] + (!element.isDisabled ? element.count : 0);
        let selectionCount;
        if (Object.keys(element).includes("selectionCount")) {
          selectionCount =
            acc[1] + (!element.isDisabled ? element.selectionCount : 0);
        } else {
          selectionCount = element.selected
            ? acc[1] + (!element.isDisabled ? element.count : 0)
            : acc[1];
        }

        return [count, selectionCount];
      },
      [0, 0],
    );
    item.nestingLevel = nestingLevel;
  }
  data.forEach(dataItem => calculateCounts(dataItem, 0));
  return data;
}

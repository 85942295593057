function getClampedClauseStyles(numLines) {
  return {
    display: "-webkit-box",
    WebkitLineClamp: numLines,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };
}

export default getClampedClauseStyles;

import _ from "underscore";

function filterHelper(baseArray = [], filterBy) {
  if ((!Array.isArray(filterBy) && typeof filterBy === "object") || !filterBy) {
    return baseArray;
  }

  if (Array.isArray(baseArray)) {
    let filterByItems = filterBy;
    if (!Array.isArray(filterBy)) {
      filterByItems = [filterBy];
    }
    return _.difference(baseArray, filterByItems);
  }
  return [];
}

export default filterHelper;

import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";
import UserActionTypes from "modules/user/constants/action_types";

export default function rolesReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.ROLES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.ROLE_ADD.SUCCESS: {
      const roleExists = state.find(role => role.name === action.payload.name);
      if (roleExists) {
        return state;
      }
      return [...state, action.payload];
    }
    case ActionTypes.ROLE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(role => {
        if (role.id === action.payload.id) {
          return {
            ...role,
            ...action.payload,
          };
        }
        return role;
      });
    }
    case ActionTypes.ROLE_REMOVE.SUCCESS: {
      if (action.payload && action.payload.id) {
        return state.filter(role => role.id !== action.payload.id);
      }
      return state;
    }
    case ActionTypes.ROLES_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import _ from "lodash";

import updateOverrideValues from "./update_override_values";

function updateOverrideDocumentIssueValues(
  updates,
  dataPath,
  overrideDocumentIssueValues,
) {
  Object.keys(updates).forEach(updateKey => {
    let newValue = updates[updateKey];
    if (typeof updates[updateKey] === "object") {
      const oldValue = _.get(
        overrideDocumentIssueValues,
        `${dataPath}.${updateKey}`,
      );
      if (oldValue) {
        newValue = oldValue
          ? Array.isArray(oldValue)
            ? [...oldValue, ...newValue]
            : {...oldValue, ...newValue}
          : newValue;
      }
    }
    updateOverrideValues(
      updateKey,
      newValue,
      dataPath,
      overrideDocumentIssueValues,
    );
  });
}

export default updateOverrideDocumentIssueValues;

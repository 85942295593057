import {ActionTypes} from "modules/redux_types";
import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

const types = ["ACCESSTAGS_FETCH"] as const;

types.forEach(type => createReduxPromiseActionType(actionTypes, type));

export type ActionType = typeof types[number];

export type AccesstagActionTypes = ActionTypes<ActionType>;

export default actionTypes as AccesstagActionTypes;

export default function(actionType, action, makeRequest, meta) {
  return (...args) => {
    const now = new Date();
    return {
      type: actionType.name,
      types: actionType.triplet,
      payload: {
        promise: action(...args, now),
        data: makeRequest ? makeRequest(...args, now) : [...args, now],
      },
      meta,
    };
  };
}

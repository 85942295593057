import React, {useEffect, useState} from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

export default function TextContainer({
  text,
  useContextMenu,
  contextMenuItems,
  searchValue,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [textFragment, setTextFragment] = useState(text);

  useEffect(
    () => {
      if (
        searchValue?.length &&
        text.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        const newText = text.replaceAll(
          new RegExp(searchValue, "gi"),
          '<span style="background-color: #85BEF1">$&</span>',
        );
        const tag = <div dangerouslySetInnerHTML={{__html: newText}} />;
        setTextFragment(tag);
      } else {
        setTextFragment(text);
      }
    },
    [searchValue],
  );

  const handleClick = e => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = key => e => {
    e.stopPropagation();
    if (props.onCompanyDetailPlaceholderSave && key) {
      props.onCompanyDetailPlaceholderSave(key);
    }
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <span
        onClick={useContextMenu ? handleClick : undefined}
        style={props.styles}
        title={props.title}
      >
        {textFragment}
      </span>
      {useContextMenu && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose()}
        >
          {contextMenuItems.map(item => (
            <MenuItem key={item.key} onClick={handleClose(item.key)}>
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </React.Fragment>
  );
}

function recursivelyUpdateStateOnItemClick(
  data,
  itemToUpdate,
  stateValue,
  openCurrentItem,
) {
  data.forEach(item => update(item, itemToUpdate, stateValue, openCurrentItem));
  return data;
}

function update(item, itemToUpdate, stateValue, openCurrentItem) {
  if (
    item.name === itemToUpdate.name &&
    item.nestingLevel === itemToUpdate.nestingLevel
  ) {
    if (item.children) {
      if (openCurrentItem) {
        item.isItemOpened = true;
      }
      return item.children.forEach(child => setStateValue(child, stateValue));
    }
    item.isItemOpened = stateValue;
    return;
  }
  if (item.children) {
    item.children.forEach(child =>
      update(child, itemToUpdate, stateValue, openCurrentItem),
    );
  }
}

function setStateValue(item, stateValue) {
  item.isItemOpened = stateValue;
  if (item.children) {
    item.children.forEach(child => setStateValue(child, stateValue));
  }
}

export default recursivelyUpdateStateOnItemClick;

import React from "react";
import scrollIntoView from "scroll-into-view-if-needed";
import _ from "underscore";
import {get} from "lodash";

import * as colors from "@material-ui/core/colors";

import PlusIcon from "@material-ui/icons/Add";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import MinusIcon from "@material-ui/icons/Remove";
import AssignmentIcon from "@material-ui/icons/Assignment";
import Popover from "material-ui/Popover";
import SvgIcon from "material-ui/SvgIcon";

import Switch from "@material-ui/core/Switch";
import RagIcon from "@material-ui/icons/Traffic";

import UnreadIssueDot from "./icons/unread_issue_dot";
import IssueItemIconMenu from "./issue_item_icon_menu";
import issueHeaderUtils from "utils/issues/issue_header_utils";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import checkGroupContainsProblem from "utils/issues/check_group_contains_problem";

import DocumentDetailContext from "common_components/context/document_detail_context";
import Markdown from "common_components/markdown";
import {generalIssueClasses} from "modules/documents/constants/issue_classes";
import "./issue_item.css";
import AddRagReport from "./icons/add_rag_report";
import RemoveRagReport from "./icons/remove_rag_report";

const styles = {
  container: {},
  textAndActionsContainer: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  issueNameAndButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttons: {
    paddingTop: 6,
    paddingBottom: 4,
    display: "flex",
    flexShrink: 0,
    alignItems: "center",
    alignSelf: "flex-start",
  },
  reason: isFlipChecklistIcons => ({
    fontWeight: 400,
    color: isFlipChecklistIcons ? "#666" : colors.grey[500],
    paddingTop: 4,
    paddingBottom: 10,
    paddingRight: 6,
  }),
  redoIcon: isFlipChecklistIcons => ({
    height: 16,
    width: 16,
    color: isFlipChecklistIcons ? "#666" : colors.grey[600],
  }),
  treeIndicatorBorder: "1px solid #BDBDBD",
  expandIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: "0",
  },
  openGroupIcon: isFlipChecklistIcons => ({
    height: "18px",
    width: "18px",
    viewBox: "0 0 18 18",
    marginRight: "9px",
    marginBottom: "6px",
    flexShrink: 0,
    color: isFlipChecklistIcons ? "#666" : colors.grey[600],
  }),
  infoPopover: isFlipChecklistIcons => ({
    backgroundColor: "#333",
    color: isFlipChecklistIcons ? "#666" : "#fff",
    padding: 10,
    fontWeight: 100,
    width: 180,
  }),
  infoPopoverSvgIcon: {
    position: "absolute",
    bottom: -20,
    width: 12,
    height: 10,
    zIndex: 1,
  },
  ragIcon: {
    width: "13px",
    position: "absolute",
    left: "-11px",
    top: "-3px",
    color: "#d8daf1",
  },
  selectedRagIcon: {
    width: "13px",
    position: "absolute",
    left: "-11px",
    top: "-3px",
    color: "#6c6c73",
  },
  sortedIssueTitle: {
    fontSize: "small",
    fontStyle: "italic",
    fontWeight: "300",
    padding: "8px 0 0 10px",
    borderLeft: "8px solid transparent",
  },
};

const showInRag = false;

export default class IssueItem extends React.Component {
  static contextType = DocumentDetailContext;

  constructor(props) {
    super(props);
    this.state = {
      areSubissuesShown: checkIfSubissuesShown(
        props.issue,
        props.isOpenByDefault,
        props.user,
      ),
      isInfoPopoverShown: false,
      infoPopoverTimeout: null,
    };
  }

  componentDidMount() {
    if (this.isIssueSelected() && this.issueRootRef) {
      scrollIntoView(this.issueRootRef, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "nearest",
        inline: "nearest",
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.updateIssueDetail &&
      (prevProps.issue.user_text !== this.props.issue.user_text ||
        prevProps.issue.current_state !== this.props.issue.current_state ||
        !_.isEqual(prevProps.issue.styling, this.props.issue.styling))
    ) {
      this.props.updateIssueDetail(this.props.issue);
    }
    if (
      this.infoPopoverRef &&
      this.infoPopoverRef.popoverRefs &&
      this.infoPopoverRef.popoverRefs.layer
    ) {
      const layer = this.infoPopoverRef.popoverRefs.layer.getLayer();
      if (layer) {
        const targetEl = layer.children && layer.children[0];
        const innerEl = targetEl && targetEl.children && targetEl.children[0];
        if (innerEl) {
          innerEl.style.overflowY = "";
        }
      }
    }
    if (
      this.isIssueSelected() &&
      this.props.selectedIssueId !== prevProps.selectedIssueId &&
      this.issueRootRef
    ) {
      scrollIntoView(this.issueRootRef, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "nearest",
        inline: "nearest",
      });
    }
  }

  render() {
    const {
      issue,
      showIssuesInChecklist: _showIssuesInChecklist,
      issueIdToHighlight,
      project,
      document,
      displayHiddenIssues,
      showCompareIssues,
      editPlaybookMode,
      togglePlaybookState,
      user,
      clientModeOn,
      selectedReportId,
      highlightIssueIds,
      isFlipChecklistIcons,
      currentIssuesetItem,
      isShowGroupTitle,
    } = this.props;
    const {zoom} = this.context;

    const {shouldBeHidden, issue_class_id: issueClassId} = issue;
    const notBeingCompared = !(
      !shouldBeHidden ||
      (shouldBeHidden && displayHiddenIssues) ||
      (shouldBeHidden && issue.showForComparison && showCompareIssues)
    );
    const inClientMode =
      issueClassId === 4 &&
      (!user.is_admin || clientModeOn || !displayHiddenIssues);

    if (notBeingCompared || inClientMode) {
      return null;
    }

    const showIssuesInChecklist =
      this.props.viewMode !== "checklist" ? false : _showIssuesInChecklist;
    const {isSubissue, isSubissueParent} = get(issue, "subissueData", {});
    const isItemLastSubissue = this.checkIsItemLastSubissue();
    const isIssueSelected = this.isIssueSelected();
    const isIssueOutdated = checkIssueOutdated(issue, document);
    const isIssueDisplayOutdated = checkIssueDisplayOutdated(issue, document);
    const issueIsTriggered = isIssueAlert(issue);
    const playbookStateValue =
      this.props.playbookState && this.props.playbookState[issue.id];
    const playbookState =
      playbookStateValue === undefined ? true : playbookStateValue;

    // TODO: address selection of individual reports in word app
    const reportSettings =
      project.report_settings.find(rs => rs.id === selectedReportId) || {};

    const issueReportActionState = (issue.action_state || {})[selectedReportId];
    const inRag =
      (reportSettings.add_all_items_to_report &&
        generalIssueClasses.indexOf(issue.issue_class_id) !== -1 &&
        issueReportActionState !== 0) ||
      (issue.issue_class_id === 2 && issueReportActionState === 1);

    const shouldBeHighlighted =
      highlightIssueIds && highlightIssueIds.find(id => id === issue.id);

    if (!editPlaybookMode && !playbookState) {
      return null;
    }
    const onIssueDetailShow = this.createItemClicker("showIssueDetail");

    const reasonTextArray = this.constructReasonTextArray();
    const hasReason = reasonTextArray && reasonTextArray.length > 0;

    const iconSize = getZoomedFontSize(16, "checklist", this.context.zoom);
    const subissues = this.getSubissues();

    const {
      master_id: issuesetMasterId,
      remote_client_id: issuesetClientId,
    } = currentIssuesetItem;
    const reviewState =
      get(
        issue,
        [
          "override_document_issue_values",
          issuesetMasterId,
          issuesetMasterId && !issuesetClientId ? "master" : issuesetClientId,
        ],
        {},
      ).review_state || issue.review_state;

    const ragReportActionState = () => {
      if (issue.action_state) {
        if (issue.action_state[reportSettings.id] !== undefined) {
          return issue.action_state[reportSettings.id];
        }
      }
    };

    const ragReportIconsColors = isFlipChecklistIcons
      ? "#000"
      : isIssueSelected
      ? "#000"
      : "#FFF";

    return (
      <div
        ref={this.createIssueRootRef}
        id={`checklist-issue-item-${issue.id}`}
      >
        <div
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          style={{
            display: "flex",
            flexDirection: "column",
            borderBottom: !isFlipChecklistIcons
              ? isSubissue
                ? ""
                : "1px solid #5a6066"
              : "",
            backgroundColor: this.getBackgroundColor(
              isIssueSelected,
              isItemLastSubissue,
            ),
            ...(user.is_admin &&
            issue.issue_type !== "EMPTY_PARENT" &&
            isIssueOutdated
              ? {border: "2px solid red"}
              : {}),
            ...(issueIsTriggered &&
              clearNullValues(get(issue, "styling.summary", {}))),
            marginTop: isFlipChecklistIcons && !isSubissue ? 10 : 0,
            marginBottom: isFlipChecklistIcons && isSubissue ? 10 : 0,
            transition: "background-color 100ms",
            ...(issue.report_only ? {border: "2px dashed green"} : {}),
          }}
        >
          {isShowGroupTitle ? (
            <div
              style={{
                ...styles.sortedIssueTitle,
                color: isIssueSelected
                  ? "#000"
                  : isFlipChecklistIcons
                  ? "#000"
                  : "#FFF",
                borderLeftColor: isIssueSelected ? "#1e88e5" : "transparent",
              }}
            >
              {issue.groupName}
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              borderBottom:
                !isFlipChecklistIcons &&
                isSubissueParent &&
                this.state.areSubissuesShown
                  ? "1px solid #5a6066"
                  : "",
              borderLeft: isIssueSelected
                ? "8px solid #1e88e5"
                : "8px solid transparent",
            }}
          >
            {this.renderInfoPopover()}
            {this.props.reviewTrackingOn && (
              <UnreadIssueDot issueStatus={reviewState} />
            )}
            {editPlaybookMode && (
              <Switch
                checked={playbookState}
                onChange={() => togglePlaybookState(issue)}
                value="checkedA"
                color="primary"
              />
            )}
            {!isFlipChecklistIcons &&
              this.renderTreeIndicator(isItemLastSubissue)}
            <div
              onClick={onIssueDetailShow}
              className="text-&-actions-container"
              style={{
                ...styles.textAndActionsContainer,
                fontWeight:
                  issueIdToHighlight === issue.id || shouldBeHighlighted
                    ? 600
                    : 600,
                fontSize: getZoomedFontSize(16, "checklist", zoom),
                marginLeft: isFlipChecklistIcons ? 20 : 6,
              }}
            >
              <div
                className="issue-name-&-buttons-container"
                style={styles.issueNameAndButtonsContainer}
              >
                {this.renderIssueName(
                  showIssuesInChecklist,
                  isIssueSelected,
                  zoom,
                  hasReason,
                  showCompareIssues,
                )}
                {ragReportActionState() === 0 ? (
                  <RemoveRagReport color={ragReportIconsColors} />
                ) : ragReportActionState() === 1 ? (
                  <AddRagReport color={ragReportIconsColors} />
                ) : null}
                <div
                  className="buttons"
                  style={styles.buttons}
                  onClick={
                    // this stops opening issue detail when clicking on buttons
                    event => event.stopPropagation()
                  }
                >
                  {this.renderIncorrectIssueIcon(issue)}
                  {this.renderGoToIssueLink(isIssueDisplayOutdated)}
                  {this.renderIssueIcon(
                    issue,
                    currentIssuesetItem,
                    showIssuesInChecklist,
                    iconSize,
                  )}
                  {inRag && showInRag && (
                    <span style={{position: "relative"}}>
                      <RagIcon
                        style={
                          isIssueSelected
                            ? styles.selectedRagIcon
                            : styles.ragIcon
                        }
                      />
                    </span>
                  )}
                </div>
              </div>
              {hasReason &&
                this.renderReason(reasonTextArray, zoom, showCompareIssues)}
              {!isFlipChecklistIcons && this.renderSubissueTrigger(subissues)}
              {this.renderComparisonDetailData(iconSize)}
            </div>
          </div>
          <div
            className="subissues-container"
            style={{
              background: "white",
              paddingLeft: isFlipChecklistIcons ? 20 : 0,
              ...getSubissueContainerBorders(isFlipChecklistIcons),
            }}
          >
            {isFlipChecklistIcons && this.renderSubissueTrigger(subissues)}

            {this.renderSubIssues(subissues)}
          </div>
        </div>
      </div>
    );
  }

  renderIssueName = (
    showIssuesInChecklist,
    isIssueSelected,
    zoom,
    hasReason,
    showCompareIssues,
  ) => {
    const {issue, isFlipChecklistIcons, user} = this.props;
    const name =
      issue.subissueData && issue.name.indexOf(" / ") !== -1
        ? issue.name.split(" / ")[1].trim()
        : issue.name;
    return (
      <div
        style={{
          flexGrow: 1,
          alignSelf: "flex-start",
          paddingTop: 10,
          paddingBottom: hasReason ? 0 : 10,
          color: issueHeaderUtils.getTextColor(
            issue,
            showIssuesInChecklist,
            isFlipChecklistIcons
              ? "#000"
              : issue.is_archived
              ? "#999"
              : isIssueSelected
              ? "#232e38"
              : "#fff",
          ),
          fontSize: getZoomedFontSize(
            isFlipChecklistIcons ? 14 : 16,
            "checklist",
            zoom,
          ),
          fontWeight: isFlipChecklistIcons ? 400 : "normal",
          ...(issue.shouldBeHidden && !showCompareIssues
            ? {color: "#b9b9b9"}
            : {}),
          ...(showCompareIssues && issue.shouldBeHidden ? {opacity: 0.4} : {}),
        }}
      >
        {`${name}${issue.is_archived ? " (Archived)" : ""}`}
        {user.is_admin &&
          !this.props.clientModeOn &&
          this.renderMutexCount(isIssueSelected)}
      </div>
    );
  };

  renderReason = (reasonTextArray, zoom, showCompareIssues) => (
    <div
      data-testid="issue_item_detailed_reason"
      style={{
        ...styles.reason(this.props.isFlipChecklistIcons),
        fontSize: getZoomedFontSize(12, "checklist", zoom),
        ...(this.props.issue.shouldBeHidden && !showCompareIssues
          ? {
              color: "#b9b9b9",
            }
          : {}),
        ...(showCompareIssues && this.props.issue.shouldBeHidden
          ? {opacity: 0.4}
          : {}),
      }}
    >
      <Markdown>{reasonTextArray.join("\n")}</Markdown>
    </div>
  );

  renderIssueIcon(issue, issueset, showIssuesInChecklist, iconSize) {
    const {project} = this.props;
    const issueIconName = issueHeaderUtils.getIconName(
      issue,
      issueset,
      showIssuesInChecklist,
      project,
    );
    const IssueIcon = issueHeaderUtils.getIcon(issueIconName);

    if (!IssueIcon || this.props.editPlaybookMode) {
      return null;
    }
    const issueIconColor = issueHeaderUtils.getIconColor(
      issue,
      issueset,
      project,
      showIssuesInChecklist,
      this.props.displayHiddenIssues,
    );

    const iconStyles = {
      width: iconSize,
      height: iconSize,
      color: issueIconColor,
      flexShrink: 0,
    };

    return (
      <div
        style={{
          flexShrink: 0,
          marginLeft: 4,
        }}
      >
        {showIssuesInChecklist ? (
          <IssueIcon style={iconStyles} onClick={this.onIssueIconClick} />
        ) : (
          <IssueItemIconMenu
            Icon={IssueIcon}
            iconStyles={iconStyles}
            project={this.props.project}
            issue={this.props.issue}
            updateDocumentIssue={this.props.updateDocumentIssue}
            correctDocumentIssueManually={
              this.props.correctDocumentIssueManually
            }
            isFlipChecklistIcons={this.props.isFlipChecklistIcons}
            disabled={issue.issue_class_id === 4}
            user={this.props.user}
            issueset={this.props.currentIssuesetItem}
          />
        )}
      </div>
    );
  }

  renderComparisonDetailData = iconSize => {
    const comparisonDetailData = this.props.issue.comparisonDetailData || {};
    const {
      shouldShowComparisonIcon,
      hasTextChanges,
      shouldBeHidden: comparisonIssueShouldBeHidden,
    } = comparisonDetailData;
    const {shouldBeHidden} = this.props.issue;

    if (
      !this.props.showCompareIssues ||
      (!shouldShowComparisonIcon && !hasTextChanges)
    ) {
      return null;
    }

    const containerStyles = {
      color: issueHeaderUtils.colors["blue1"],
      fontSize: getZoomedFontSize(9, "checklist", this.context.zoom),
      fontWeight: "normal",
      marginTop: 1,
      paddingBottom: 10,
      display: "flex",
    };

    const renderStatusChanges = areTextChangesPresent => {
      if (!shouldShowComparisonIcon) {
        return null;
      }

      const iconStyles = {
        width: iconSize - 2,
        height: iconSize - 2,
        flexShrink: 0,
        marginRight: 4,
      };

      const {baseComparisonIconName, comparisonIconName} = comparisonDetailData;

      const BaseComparisonIcon = issueHeaderUtils.getIcon(
        baseComparisonIconName,
      );

      let comparisonIconBlock = null;
      if (baseComparisonIconName !== comparisonIconName) {
        const ComparisonIcon = issueHeaderUtils.getIcon(comparisonIconName);
        comparisonIconBlock = (
          <>
            <div className="default-checklist-item-icon">
              <BaseComparisonIcon style={iconStyles} />
            </div>
            <ComparisonIcon
              style={{
                ...iconStyles,
                marginRight: 4,
              }}
            />
          </>
        );
      } else {
        comparisonIconBlock = <BaseComparisonIcon style={iconStyles} />;
      }

      return (
        <>
          {comparisonIconBlock}
          {!areTextChangesPresent && <div>Previous status</div>}
        </>
      );
    };

    const renderSeeChangesButton = areTextChangesPresent => {
      if (areTextChangesPresent) {
        return (
          <div
            style={{cursor: "pointer"}}
            onClick={this.onShowComparisonDetail}
            className="see-changes-button"
          >
            See Changes
          </div>
        );
      }
    };

    const renderHiddenStatus = () => {
      let hiddenStatus = null;
      if (comparisonIssueShouldBeHidden) {
        hiddenStatus = "hidden";
      } else if (shouldBeHidden) {
        hiddenStatus = "visible";
      }
      return hiddenStatus && <div>&nbsp; {`(${hiddenStatus})`}</div>;
    };

    return (
      <div style={containerStyles}>
        {renderStatusChanges(hasTextChanges)}
        {renderSeeChangesButton(hasTextChanges)}
        {renderHiddenStatus()}
      </div>
    );
  };

  renderMutexCount = isIssueSelected => {
    const {issue, isFlipChecklistIcons} = this.props;
    const style = {
      marginLeft: 4,
      backgroundColor: "#3f0375",
      fontSize: "12px",
      border: "1px solid",
      borderRadius: "10px",
      width: "16px",
      height: "17px",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      color: isIssueSelected || isFlipChecklistIcons ? "white" : "inherit",
    };
    if (issue.underlyingIssues) {
      return (
        <span style={style}>
          {issue.underlyingIssues.filter(issue => !issue.shouldBeHidden).length}
        </span>
      );
    }
    const {override_values: overrideValues = {}} = issue;
    let hasOverriddenMutex = false;
    for (const masterId of Object.keys(overrideValues)) {
      const masterOverrides = overrideValues[masterId];
      for (const clientId of Object.keys(masterOverrides)) {
        const clientOverrides = masterOverrides[clientId];
        if (
          clientOverrides &&
          clientOverrides.display_name &&
          clientOverrides.display_name.indexOf("[") !== -1
        ) {
          hasOverriddenMutex = true;
          break;
        }
      }
    }
    if (hasOverriddenMutex) {
      return <span style={style}>*</span>;
    }
  };

  getBackgroundColor = (isIssueSelected, isItemLastSubissue) => {
    const {
      issue,
      isFlipChecklistIcons,
      displayHiddenIssues,
      showCompareIssues,
    } = this.props;
    const {isHovered} = this.state;

    const shouldHideIssue =
      (issue.shouldBeHidden && !showCompareIssues) ||
      (issue.issue_class_id === 4 && displayHiddenIssues);

    // flip checklist style
    if (isFlipChecklistIcons) {
      if (isIssueSelected) {
        return "#d9d9d9";
      } else if (issue.do_not_sync) {
        return "#ffd5f3";
      } else if (isHovered) {
        return "#eaeaea";
      }
      return shouldHideIssue ? "#f1f1f1" : "#f4f4f4";
    }

    // non-flip checklist style
    if (isIssueSelected) {
      return "#f8f8f8";
    } else if (issue.do_not_sync) {
      return "#774067";
    } else if (isHovered) {
      return "#464f56";
    }
    return shouldHideIssue
      ? "#666"
      : isItemLastSubissue !== null
      ? "#404b54"
      : "#353f48";
  };

  constructReasonTextArray = () => {
    const {issue} = this.props;
    return issueHeaderUtils.getReasonTextArray(
      issue,
      this.props.documentClauses,
      this.props.topicsById,
      this.props.positiveReasonData,
      this.props.selectedReportId,
      this.props.currentIssuesetItem,
      this.props.documentClauseparts,
      this.props.document.parties,
      false,
      false,
      false,
      this.props.preventMemo,
      this.props.document.last_classification_changed,
    );
  };

  onMouseEnter = () => {
    this.setState({
      infoPopoverTimeout: setTimeout(() => {
        this.setState({
          isInfoPopoverShown: true,
          infoPopoverTimeout: null,
        });
      }, 800),
      isHovered: true,
    });
  };

  onMouseLeave = () => {
    clearTimeout(this.state.infoPopoverTimeout);
    this.setState({
      isInfoPopoverShown: false,
      infoPopoverTimeout: null,
      isHovered: false,
    });
  };

  renderInfoPopover() {
    const {contractTypesById, issuesById} = this.props;
    if (!_.has(issuesById, this.props.issue.id)) {
      return;
    }
    const {contract_types: contractTypes} = issuesById[this.props.issue.id];
    const allIssuesets = [];
    contractTypes.forEach(({contract_type_id, issuesets}) => {
      const contractType = contractTypesById[contract_type_id];
      const issuesetsById = {};
      contractType.issuesets.forEach(issueset => {
        issuesetsById[issueset.id] = issueset;
      });
      issuesets.forEach(issueset => {
        allIssuesets.push(issuesetsById[issueset.id]);
      });
    });
    const clientsById = {};
    allIssuesets.forEach(({remote_client_id, remote_client_name}) => {
      if (remote_client_id && !_.has(clientsById, remote_client_id)) {
        clientsById[remote_client_id] = {name: remote_client_name};
      }
    });
    const clientNames = _.chain(clientsById)
      .map(({name}) => name)
      .value();
    const maxClientNames = 6;
    let joinedClientNames;
    if (clientNames.length <= maxClientNames) {
      joinedClientNames = clientNames.join(", ");
    } else {
      joinedClientNames = [...clientNames]
        .splice(0, maxClientNames)
        .concat("...")
        .join(", ");
    }
    return (
      <Popover
        ref={this.createInfoPopoverRef}
        open={this.state.isInfoPopoverShown}
        anchorEl={this.issueRootRef}
        anchorOrigin={{
          horizontal: "middle",
          vertical: "top",
        }}
        targetOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        style={{
          ...styles.infoPopover(this.props.isFlipChecklistIcons),
          fontSize: getZoomedFontSize(11, "checklist", this.context.zoom),
        }}
        useLayerForClickAway={false}
      >
        <div>
          <div>{`Contract Types: ${contractTypes.length}`}</div>
          <div>{`Checklists: ${allIssuesets.length}`}</div>
          <div>{`Clients: ${clientNames.length}`}</div>
          <div>{joinedClientNames}</div>
        </div>
        <SvgIcon viewBox="0 0 12 10" style={styles.infoPopoverSvgIcon}>
          <path d="M12 0 L6 10 L0 0 Z" fill="#333" />
        </SvgIcon>
      </Popover>
    );
  }

  renderIncorrectIssueIcon = issue => {
    if (
      !(this.props.user.is_admin || this.props.canViewIssueErrors) ||
      this.props.clientModeOn
    ) {
      return null;
    }
    const {
      should_be_issue: shouldBeIssue,
      is_correctly_applied: isCorrectlyApplied,
      user_verified: userVerified,
    } = issue;
    const issueIsTriggered = isIssueAlert(issue);
    const incorrectlyApplied =
      isCorrectlyApplied === false &&
      ((issueIsTriggered && shouldBeIssue === false) ||
        (!issueIsTriggered && shouldBeIssue === true));
    const incorrectlyPresented =
      isCorrectlyApplied === false &&
      ((!issueIsTriggered && shouldBeIssue === false) ||
        (issueIsTriggered && shouldBeIssue === true));

    const baseStyles = {
      ...styles.openGroupIcon(this.props.isFlipChecklistIcons),
      marginLeft: "4px",
      marginBottom: "0",
      marginRight: "4px",
    };

    if (incorrectlyApplied || incorrectlyPresented) {
      return (
        <HighlightOffIcon
          style={{
            ...baseStyles,
            color: "#C40233",
          }}
        />
      );
    } else if (userVerified) {
      return (
        <CheckCircleOutlineIcon
          style={{
            ...baseStyles,
            color: "#1D7400",
          }}
        />
      );
    }
  };

  renderGoToIssueLink = highlight => {
    const uriRegex = /\/organisation\/\d+\/issue\/\d+/;
    const {location, showIssue} = this.props;
    if (showIssue && location && uriRegex.test(location.pathname)) {
      return (
        <span
          title={highlight ? "Applicable clauses processing" : ""}
          style={{
            alignItems: "center",
            display: "flex",
            margin: "0px 4px",
          }}
        >
          <AssignmentIcon
            style={{
              ...styles.redoIcon(this.props.isFlipChecklistIcons),
              border: highlight ? "1px solid #a00" : "unset",
            }}
            onClick={this.createItemClicker("showIssue")}
          />
        </span>
      );
    }
  };

  renderSubissueTrigger = subissues => {
    if (subissues) {
      const {isFlipChecklistIcons, showCompareIssues} = this.props;
      const {areSubissuesShown} = this.state;
      const changedCompareIssuesCount = showCompareIssues
        ? subissues.reduce(
            (result, subissue) =>
              get(
                subissue,
                "comparisonDetailData.shouldShowComparisonIcon",
                false,
              )
                ? result + 1
                : result,
            0,
          )
        : 0;

      const Icon = areSubissuesShown ? MinusIcon : PlusIcon;
      return (
        <div
          onClick={this.triggerShowSubissues}
          style={{
            paddingBottom: 6,
            paddingTop: isFlipChecklistIcons ? 6 : 0,
            display: "flex",
            alignItems: "center",
            color: isFlipChecklistIcons ? "#666" : colors.grey[600],
          }}
        >
          <Icon
            style={{
              marginLeft: "-4px",
              width: 20,
              height: 20,
              color: isFlipChecklistIcons ? colors.grey[400] : colors.grey[600],
            }}
          />
          <div
            style={{
              fontSize: getZoomedFontSize(12, "checklist", this.context.zoom),
              marginBottom: 1,
              marginLeft: "2px",
            }}
          >
            {areSubissuesShown
              ? "Hide"
              : `Show details ${
                  subissues.length > 1 ? `(${subissues.length})` : ""
                }`}
          </div>
          {changedCompareIssuesCount > 0 && (
            <div
              style={{
                color: issueHeaderUtils.colors["blue1"],
                fontSize: getZoomedFontSize(9, "checklist", this.context.zoom),
                fontWeight: "normal",
                marginBottom: 1,
                marginLeft: 6,
              }}
            >
              {`${changedCompareIssuesCount} Issue${
                changedCompareIssuesCount > 1 ? "s" : ""
              } changed`}
            </div>
          )}
        </div>
      );
    }
  };

  renderSubIssues = subissues => {
    if (!subissues || !this.state.areSubissuesShown) {
      return null;
    }

    return (
      <div>
        {subissues.map((item, index) => (
          <IssueItem
            key={item.id}
            user={this.props.user}
            project={this.props.project}
            document={this.props.document}
            issue={item}
            documentClauses={this.props.documentClauses}
            documentClauseparts={this.props.documentClauseparts}
            topicsById={this.props.topicsById}
            selectedIssueId={this.props.selectedIssueId}
            showIssueDetail={this.props.showIssueDetail}
            updateIssueDetail={this.props.updateIssueDetail}
            showIssuesInChecklist={this.props.showIssuesInChecklist}
            viewMode={this.props.viewMode}
            updateDocumentIssue={this.props.updateDocumentIssue}
            subissueGroupItems={subissues}
            index={index}
            subissueData={item.subissueData}
            positiveReasonData={this.props.positiveReasonData}
            onIssueCheckboxCheck={this.props.onIssueCheckboxCheck}
            isOpenByDefault={this.props.isOpenByDefault}
            location={this.props.location}
            issueIdToHighlight={this.props.issueIdToHighlight}
            showIssue={this.props.showIssue}
            displayHiddenIssues={this.props.displayHiddenIssues}
            clientModeOn={this.props.clientModeOn}
            contractTypesById={this.props.contractTypesById}
            issuesById={this.props.issuesById}
            editPlaybookMode={this.props.editPlaybookMode}
            togglePlaybookState={this.props.togglePlaybookState}
            playbookState={this.props.playbookState}
            isDocumentDetailPageShown={this.props.isDocumentDetailPageShown}
            selectedReportId={this.props.selectedReportId}
            isFlipChecklistIcons={this.props.isFlipChecklistIcons}
            correctDocumentIssueManually={
              this.props.correctDocumentIssueManually
            }
            showCompareIssues={this.props.showCompareIssues}
            showComparisonDetail={this.props.showComparisonDetail}
            reviewTrackingOn={this.props.reviewTrackingOn}
            currentIssuesetItem={this.props.currentIssuesetItem}
            preventMemo={this.props.preventMemo}
          />
        ))}
      </div>
    );
  };

  checkIsItemLastSubissue = () => {
    const {issue, index, subissueGroupItems} = this.props;
    if (!issue.subissueData || !issue.subissueData.isSubissue) {
      return null;
    }
    return subissueGroupItems.length - 1 === index;
  };

  renderTreeIndicator = isItemLastSubissue => {
    if (isItemLastSubissue === null) {
      return null;
    }
    return (
      <div
        style={{
          width: "1rem",
          marginLeft: this.props.reviewTrackingOn ? "10px" : "12px",
        }}
      >
        <div
          style={{
            height: "55%",
            borderBottom: styles.treeIndicatorBorder,
            borderLeft: styles.treeIndicatorBorder,
          }}
        />
        <div
          style={{
            height: "50%",
            borderLeft: isItemLastSubissue
              ? "unset"
              : styles.treeIndicatorBorder,
          }}
        />
      </div>
    );
  };

  getSubissues = () => {
    const {issue} = this.props;
    const hasData =
      issue.subissueData &&
      issue.subissueData.isSubissueParent &&
      !issue.hideSubissues;
    if (!hasData) {
      return;
    }
    const subissues = this.props.displayHiddenIssues
      ? issue.subissueData.subissues
      : issue.subissueData.subissues.filter(subissue =>
          this.props.showCompareIssues &&
          subissue.shouldBeHidden &&
          subissue.showForComparison
            ? true
            : !subissue.shouldBeHidden,
        );
    if (subissues && subissues.length) {
      return subissues;
    }
  };

  onIssueIconClick = e => {
    e.stopPropagation();
    const {issue, showIssuesInChecklist, project} = this.props;
    const {verify_issues: verifyIssues} = project;
    if (!showIssuesInChecklist) {
      return;
    }
    let updates = {};
    const {current_state: currentState, reasonCount} = issue;
    if (currentState === null) {
      if (reasonCount === 0) {
        updates = verifyIssues
          ? {
              current_state: true,
              user_verified: false,
            }
          : {current_state: true};
      } else {
        updates = verifyIssues
          ? {
              current_state: false,
              user_verified: false,
            }
          : {current_state: false};
      }
    } else {
      updates = verifyIssues
        ? {
            current_state: null,
            user_verified: null,
          }
        : {current_state: null};
    }
    return this.props.updateDocumentIssue(issue, updates);
  };

  createItemClicker = _.memoize(actionName => e => {
    e.stopPropagation();
    const handler = this.props[actionName];
    if (handler) {
      handler(this.props.issue);
    }
  });

  onShowComparisonDetail = e => {
    e.stopPropagation();
    const {issue} = this.props;
    this.props.showComparisonDetail({
      issue: _.omit(issue, "comparisonDetailData"),
      comparisonDetailData: issue.comparisonDetailData,
    });
  };

  triggerShowSubissues = e => {
    e.stopPropagation();
    this.setState(prevState => ({
      areSubissuesShown: !prevState.areSubissuesShown,
    }));
  };

  createInfoPopoverRef = node => (this.infoPopoverRef = node);
  createIssueRootRef = node => (this.issueRootRef = node);

  isIssueSelected() {
    const {props} = this;
    return !props.showIssue
      ? props.selectedIssueId === props.issue.id
      : props.issueIdToHighlight === props.issue.id;
  }
}

function checkIfSubissuesShown(issue, isOpenByDefault, user) {
  if (!issue.subissueData || !issue.subissueData.isSubissueParent) {
    return undefined;
  }
  if (isOpenByDefault) {
    return true;
  }
  const issueTriggered = isIssueAlert(issue);

  const subissueParentContainsProblem = checkGroupContainsProblem(
    issue.subissueData.subissues || [],
  );
  if (user.is_admin && subissueParentContainsProblem) {
    return true;
  }
  switch (issue.open_subissues_when) {
    case "always":
      return true;
    case "issue_is_triggered":
      return issueTriggered;
    case "issue_is_not_triggered":
      return !issueTriggered;
    case "never":
    default:
      return false;
  }
}

function checkIssueOutdated(issue, document) {
  if (!document || !issue) {
    return false;
  }
  const issueLastEdited = new Date(issue.important_last_edited);
  const documentLastEdited = new Date(document.issues_last_edited);

  return issueLastEdited > documentLastEdited;
}

function checkIssueDisplayOutdated(issue, document) {
  if (!document || !issue) {
    return false;
  }
  const issueLastUpdatedDisplayLogic = issue.issue_last_updated_display_logic
    ? new Date(issue.issue_last_updated_display_logic)
    : null;
  const documentIssueLastUpdatedDisplayLogic = issue.document_issue_last_updated_display_logic
    ? new Date(issue.document_issue_last_updated_display_logic)
    : null;

  return (
    (issueLastUpdatedDisplayLogic && !documentIssueLastUpdatedDisplayLogic) ||
    (issueLastUpdatedDisplayLogic &&
      documentIssueLastUpdatedDisplayLogic &&
      issueLastUpdatedDisplayLogic > documentIssueLastUpdatedDisplayLogic)
  );
}

function clearNullValues(summary) {
  const result = {};
  Object.keys(summary).forEach(key => {
    // In theory, empty string values should be prevented by upstream
    // validation of issue "styling" overrides. In practice, there has been at
    // least one reported case of such a value reaching this point, so we
    // crudely apply the same validation logic here as a catch-all.
    if (summary[key] !== null && summary[key] !== "") {
      result[key] = summary[key];
    }
  });
  return result;
}

function getSubissueContainerBorders(isFlipChecklistIcons) {
  if (!isFlipChecklistIcons) {
    return {};
  }
  const border = "1px solid #F4F4F4";
  return {
    borderLeft: border,
    borderRight: border,
    borderBottom: border,
  };
}

function reasonViewer(reason) {
  const result = [];
  Object.keys(reason.topics || {}).forEach(topicId => {
    if (topicId) {
      Object.keys(reason.topics[topicId]).forEach(parameterId => {
        if (parameterId) {
          reason.topics[topicId][parameterId].forEach((item, itemIndex) => {
            result.push({
              path: `topics.[${topicId}].[${parameterId}].[${itemIndex}].value`,
              value: reason.topics[topicId][parameterId][itemIndex].value,
            });
          });
        }
      });
    }
  });
  return result;
}

function getDefaultIssueRuleValues(rules) {
  return [
    {
      path: `topics.[${rules.topic_id}].[${rules.parameter_id}].[0].value`,
      value: "",
    },
  ];
}

export default {
  reasonViewer,
  getDefaultIssueRuleValues,
};

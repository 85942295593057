import React from "react";

import * as colors from "material-ui/styles/colors";

const styles = {
  base: {
    display: "inline-block",
    borderRadius: "5px",
    backgroundColor: colors.amber300,
    color: "#111",
    padding: "0 6px",
    margin: "1px 0.5em 1px 0px",
    textAlign: "start",
    fontWeight: 500,
  },
};

function TopicChip(props) {
  if (!props.text) {
    return null;
  }

  return (
    <span
      style={{
        ...styles.base,
        ...(props.chipStyles ? props.chipStyles : {}),
      }}
    >
      {props.text}
    </span>
  );
}

export default TopicChip;

import Describer from "../../../utils/issues/issue_description/describer";
import getRoleName from "./get_role_name";

function render(issue, topics, props = {}) {
  const {rules} = issue;
  const describer = new Describer.IssueDescriber(issue, topics);
  return `${describer.topicName} exists with role parameter ${
    describer.parameterName
  }${
    rules.role_id
      ? ` having value ${getRoleName(rules.role_id, props.roles)} role`
      : ""
  }`;
}

export default render;

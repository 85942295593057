function groupClausesBySection(clauses, groupedSections) {
  const groupedBySection = {};
  for (let i = 0; i < clauses.length; i++) {
    const clause = clauses[i];
    const sectionGroup = groupedSections.find(groupedSection =>
      groupedSection.sections.find(
        section => section.id === clause.clause_section_id,
      ),
    );
    groupedBySection[sectionGroup.id] = !groupedBySection[sectionGroup.id]
      ? [clause]
      : [...groupedBySection[sectionGroup.id], clause];
  }
  return groupedBySection;
}

export default groupClausesBySection;

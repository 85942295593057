import _ from "underscore";
import findInstances from "../../document_clauses/utils/find_instances";

function findDefinitions(
  textInput,
  clause,
  documentDefinitions,
  documentQualifiers,
  changes,
) {
  const text = textInput ? textInput : calculateTextNode(clause.nodes);
  const instances = findInstances(
    text,
    documentDefinitions,
    documentQualifiers,
    changes,
    0,
  );
  const definitions = [];
  instances.forEach(instance => {
    if (instance.type === "definition") {
      definitions.push(instance.definition);
    }
  });
  return _.uniq(definitions, definition => definition.term);
}

function calculateTextNode(node) {
  const text = [];
  if (node.text) {
    text.push(node.partial_text || node.text);
  }
  if (node.clauseNodes) {
    text.push(...node.clauseNodes.map(child => calculateTextNode(child)));
  }
  return text.join(" ");
}

export default findDefinitions;

import _ from "underscore";

import Describer from "../../../utils/issues/reason/describer";
import Issues from "../../issues";

function describeReason(clauses, topicsById, issue, showClause = true) {
  const reasonsPerRule = issue.reason[0].rules.map(rule => {
    if (!rule) {
      return null;
    }
    if (Array.isArray(rule)) {
      return rule.map(subRule => {
        if (!subRule) {
          return null;
        }
        if (Array.isArray(subRule)) {
          if (subRule.length === 0) {
            return null;
          }
          return [
            {
              ..._.omit(issue.reason[0], "rules"),
              ..._.first(subRule),
            },
          ];
        }
        return [
          {
            ..._.omit(issue.reason[0], "rules"),
            ...subRule,
          },
        ];
      });
    }
    return {
      ..._.omit(issue.reason[0], "rules"),
      ...rule,
    };
  });

  const describer = new Describer(clauses, topicsById, {
    reason: reasonsPerRule,
  });
  const reasonsTextArray = _.zip(reasonsPerRule, issue.rules.issues).map(
    args => {
      const [reason, rules] = args;
      return Issues[rules.issue_type.toLowerCase()].reason(
        clauses,
        topicsById,
        {...rules, reason},
        false,
      );
    },
  );

  const reasonsText =
    reasonsTextArray.join(" and ") +
    (showClause
      ? ` in clause ${describer.clauseReference}`
      : " in the same clause");

  return reasonsText;
}

export default describeReason;

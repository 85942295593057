import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import {splitCoordinatedChars, findCharByXY} from "./coordinated_chars";

export default class OverflowTextTruncater extends React.Component {
  componentDidMount() {
    this.truncateTextIfLong();
  }

  componentDidUpdate() {
    this.truncateTextIfLong();
  }

  truncateTextIfLong = () => {
    const textNode = (node => {
      if (node && node.nodeType === 3) {
        return node;
      }
    })(ReactDOM.findDOMNode(this));
    if (textNode && textNode.parentNode) {
      const parentNode = textNode.parentNode;
      const range = document.createRange();
      range.selectNode(textNode);
      const parentRect = parentNode.getBoundingClientRect();
      const rangeRect = range.getBoundingClientRect();
      if (parentRect.right < rangeRect.right) {
        const charList = splitCoordinatedChars(parentNode);
        const char = findCharByXY(charList, parentRect.right, parentRect.top);
        if (char && char.index > this.props.length) {
          const truncated = charList
            .slice(0, char.index - this.props.length)
            .map(_char => _char.char)
            .join("");
          parentNode.innerText = `${truncated}${this.props.ellipsis}`;
        }
      }
    }
  };

  render() {
    return this.props.children;
  }
}

OverflowTextTruncater.defaultProps = {
  ellipsis: "...",
  length: 1,
};

OverflowTextTruncater.propTypes = {
  ellipsis: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
};

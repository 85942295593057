export default {
  PROJECT_ADD: "PROJECT_ADD",
  INIT_SUCCESS: "INIT_SUCCESS",
  PROJECT_DELETE: "PROJECT_DELETE",
  PROJECT_UPDATE: "PROJECT_UPDATE",
  DOCUMENT_PARSE: "DOCUMENT_PARSE",
  DOCUMENT_UPDATE: "DOCUMENT_UPDATE",
  DOCUMENT_DELETE: "DOCUMENT_DELETE",
  CLAUSES_TOPIC_ADD: "CLAUSES_TOPIC_ADD",
  CLAUSES_TOPIC_REMOVE: "CLAUSES_TOPIC_REMOVE",
  CLAUSES_TOPIC_CONFIRM: "CLAUSES_TOPIC_CONFIRM",
  DOCUMENT_CLAUSE_TOPIC_ADD: "DOCUMENT_CLAUSE_TOPIC_ADD",
  DOCUMENT_CLAUSE_TOPIC_REMOVE: "DOCUMENT_CLAUSE_TOPIC_REMOVE",
  DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE: "DOCUMENTS_CLAUSEPARTS_TOPICS_UDPATE",
  DOCUMENT_CLAUSEPART_UPDATE: "DOCUMENT_CLAUSEPART_UPDATE",
  DOCUMENT_CLAUSE_TOPICS_REORDER: "DOCUMENT_CLAUSE_TOPICS_REORDER",
  DOCUMENT_CLAUSE_UNCONFIRMED_TOPICS_REMOVE:
    "DOCUMENT_CLAUSE_UNCONFIRMED_TOPICS_REMOVE",
  DOCUMENT_CLAUSE_TOPIC_CONFIRM: "DOCUMENT_CLAUSE_TOPIC_CONFIRM",
  CLAUSE_CLASSIFICATION: "CLAUSE_CLASSIFICATION",
  CLAUSE_TOPIC_PARAMETER_CLASSIFICATION:
    "CLAUSE_TOPIC_PARAMETER_CLASSIFICATION",
  TOPICPARAMETER_BULK_CLASSIFICATION: "TOPICPARAMETER_BULK_CLASSIFICATION",
  DOCUMENT_TOPICS_CONFIRM_ALL: "DOCUMENT_TOPICS_CONFIRM_ALL",
  DOCUMENT_ISSUES_CONFIRM_ALL: "DOCUMENT_ISSUES_CONFIRM_ALL",
  CLAUSE_QUALIFICATION: "CLAUSE_QUALIFICATION",
  TOPIC_UPDATE: "TOPIC_UPDATE",
  TOPIC_DELETE: "TOPIC_DELETE",
  TOPIC_CATEGORY_UPDATE: "TOPIC_CATEGORY_UPDATE",
  TOPICS_UPDATE: "TOPICS_UPDATE",
  TOPIC_CLASSIFIER_UPDATE: "TOPIC_CLASSIFIER_UPDATE",
  TOPIC_CLASSIFIER_LOCK: "TOPIC_CLASSIFIER_LOCK",
  CLASSIFIER_RUN_BATCH_ADD: "CLASSIFIER_RUN_BATCH_ADD",
  CLASSIFIER_RUN_STATE_UPDATE: "CLASSIFIER_RUN_STATE_UPDATE",
  EXACT_MATCH_ADD: "EXACT_MATCH_ADD",
  EXACT_MATCH_REMOVE: "EXACT_MATCH_REMOVE",
  TOPICPARAMETER_ADD: "TOPICPARAMETER_ADD",
  TOPICPARAMETER_UPDATE: "TOPICPARAMETER_UPDATE",
  TOPICPARAMETER_REMOVE: "TOPICPARAMETER_REMOVE",
  TOPICPARAMETER_ACTUAL_VALUES_UPDATE: "TOPICPARAMETER_ACTUAL_VALUES_UPDATE",
  TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE:
    "TOPICPARAMETER_ACTUAL_VALUES_IN_CLAUSES_UPDATE",
  TOPICPARAMETERS_PROCESS: "TOPICPARAMETERS_PROCESS",
  ISSUE_ADD: "ISSUE_ADD",
  ISSUE_UPDATE: "ISSUE_UPDATE",
  ISSUE_REMOVE: "ISSUE_REMOVE",
  ISSUES_UPDATE: "ISSUES_UPDATE",
  DOCUMENT_UPLOAD: "DOCUMENT_UPLOAD",
  DOCUMENT_ISSUES_FETCH: "DOCUMENT_ISSUES_FETCH",
  DOCUMENT_ISSUE_UPDATE: "DOCUMENT_ISSUE_UPDATE",
  DOCUMENT_ISSUES_UPDATE: "DOCUMENT_ISSUES_UPDATE",
  DOCUMENT_ISSUES_COMPLETE: "DOCUMENT_ISSUES_COMPLETE",
  DOCUMENT_ISSUE_MANUAL_CORRECT: "DOCUMENT_ISSUE_MANUAL_CORRECT",
  DOCUMENT_ISSUE_GENERATE_PROMPT: "DOCUMENT_ISSUE_GENERATE_PROMPT",
  DOCUMENT_GENERATE_PROMPT: "DOCUMENT_GENERATE_PROMPT",
  DOCUMENT_STATE_REVERT: "DOCUMENT_STATE_REVERT",
  DOCUMENT_CLAUSE_CHANGE_STATE_REVERT: "DOCUMENT_CLAUSE_CHANGE_STATE_REVERT",
  FORM_TEMPLATE_UPLOAD: "FORM_TEMPLATE_UPLOAD",
  TEMPLATE_MODULE_CREATE: "TEMPLATE_MODULE_CREATE",
  TEMPLATE_MODULE_UPDATE: "TEMPLATE_MODULE_UPDATE",
  CLASSIFIER_GENERATED: "CLASSIFIER_GENERATED",
  CONTRACT_TYPE_ADD: "CONTRACT_TYPE_ADD",
  CONTRACT_TYPE_UPDATE: "CONTRACT_TYPE_UPDATE",
  CONTRACT_TYPE_REMOVE: "CONTRACT_TYPE_REMOVE",
  ISSUESET_ADD: "ISSUESET_ADD",
  ISSUESET_UPDATE: "ISSUESET_UPDATE",
  ISSUESET_REMOVE: "ISSUESET_REMOVE",
  CLIENT_ADD: "CLIENT_ADD",
  DEFINITION_GROUP_ADD: "DEFINITION_GROUP_ADD",
  DEFINITION_GROUP_UPDATE: "DEFINITION_GROUP_UPDATE",
  DEFINITION_GROUP_REMOVE: "DEFINITION_GROUP_REMOVE",
  FIND_TOPIC_REGEX_FPS: "FIND_TOPIC_REGEX_FPS",
  ROLE_ADD: "ROLE_ADD",
  ROLE_UPDATE: "ROLE_UPDATE",
  ROLE_REMOVE: "ROLE_REMOVE",
};

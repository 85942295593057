function getCurrentIssuesetItem(currentIssueset, document, contractTypesById) {
  if (
    currentIssueset &&
    document &&
    document.contract_type &&
    contractTypesById
  ) {
    const contractType = contractTypesById[document.contract_type.id];
    const issueset = contractType
      ? contractType.issuesets.find(
          ({id}) => id === currentIssueset || id === currentIssueset?.id,
        ) ?? {}
      : {};
    issueset.roles_last_processed = document.roles_last_processed;
    issueset.issues_last_processed = document.issues_last_processed;
    return issueset;
  }
}

export default getCurrentIssuesetItem;

import React from "react";
import PropTypes from "prop-types";

import CreateIcon from "@material-ui/icons/Create";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import OverflowTextTruncater from "utils/overflow_text_truncater";
import upperFirst from "utils/upper_first";

import SidebarContext from "./context";

import NavItem from "./nav_item";
import PasswordUpdateDialog from "../password_update_dialog";

export default class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPasswordUpdaterDialogOpen: false,
    };
  }

  render() {
    const {avatarText, nameText} = this.getTexts();

    return (
      <React.Fragment>
        <NavItem
          className="app-sidebar-user-info__nav-item"
          icon={
            <div
              className={(() => {
                const classNames = [];
                const className = "app-sidebar-user-info__avatar";
                classNames.push(className);
                const isProduction = window.location.origin.indexOf(".") >= 0;
                if (isProduction && this.props.user.is_developer) {
                  classNames.push(`${className}--red`);
                }
                return classNames.join(" ");
              })()}
            >
              {avatarText}
            </div>
          }
          isCollapsed={!this.context.isExpanded}
          children={<OverflowTextTruncater>{nameText}</OverflowTextTruncater>}
          nestedChildren={
            <div className="app-sidebar-popover__header">
              <NavItem
                icon={<CreateIcon />}
                onClick={this.togglePasswordUpdaterDialog}
              >
                Edit user details
              </NavItem>
              <NavItem icon={<ExitToAppIcon />} onClick={this.props.onLogout}>
                Logout
              </NavItem>
            </div>
          }
        />
        {this.state.isPasswordUpdaterDialogOpen && (
          <PasswordUpdateDialog
            user={this.props.user}
            onUserPasswordUpdate={this.props.onUserPasswordUpdate}
            toggleDialog={this.togglePasswordUpdaterDialog}
            onUserUpdate={this.props.onUserUpdate}
          />
        )}
      </React.Fragment>
    );
  }

  getTexts = () => {
    const {
      first_name: firstName,
      last_name: lastName,
      username,
    } = this.props.user;
    let avatarText;
    let nameText;
    if (username) {
      avatarText = username.charAt(0);
      nameText = username;
      if (firstName && lastName) {
        avatarText = `${firstName.charAt(0)}${lastName.charAt(
          0,
        )}`.toUpperCase();
        nameText = upperFirst(firstName);
      } else if (firstName) {
        avatarText = firstName.charAt(0).toUpperCase();
        nameText = upperFirst(firstName);
      }
    }
    return {avatarText, nameText};
  };

  togglePasswordUpdaterDialog = () => {
    this.setState({
      isPasswordUpdaterDialogOpen: !this.state.isPasswordUpdaterDialogOpen,
    });
  };
}

UserInfo.contextType = SidebarContext;

UserInfo.propTypes = {
  user: PropTypes.object.isRequired,
  onUserPasswordUpdate: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

import {get} from "lodash";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import {generalIssueClasses} from "modules/documents/constants/issue_classes";
import {getIssueManualCorrections, getOverride} from "../manual_corrections_utils";
import globalOverrideKey from "common/utils/issues/global_override_key";

function shouldIssueBeShownAsAlert(issue, issueset) {
  if (!issue) {
    return false;
  }

  const manualCorrection = getIssueManualCorrections(
    issue,
    issueset,
    globalOverrideKey,
  );

  const overrides = getOverride(issue, issueset);
  const manuallyCorrectedColor = manualCorrection?.alert_color;
  const manualCorrectionResolvedState = overrides?.resolved_state ?? null;

  if (issue.issue_type === "EMPTY_PARENT") {
    if (manuallyCorrectedColor) {
      return isIssueManuallyCorrectedToAlert(manuallyCorrectedColor);
    }
    const subissues = get(issue, "subissueData.subissues", []);
    return Boolean(
      subissues.find(
        subissue =>
          generalIssueClasses.indexOf(issue.issue_class_id) !== -1 &&
          shouldIssueBeShownAsAlert(subissue, issueset),
      ),
    );
  }
  if (generalIssueClasses.indexOf(issue.issue_class_id) !== -1) {
    if (manualCorrectionResolvedState !== null) {
      return false;
    }
    return manuallyCorrectedColor
      ? isIssueManuallyCorrectedToAlert(manuallyCorrectedColor)
      : isIssueAlert(issue);
  }
  return false;
}

function isIssueManuallyCorrectedToAlert(manuallyCorrectedColor) {
  return ["red", "amber"].indexOf(manuallyCorrectedColor) !== -1;
}

export default shouldIssueBeShownAsAlert;

import React from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import requestor from "requestor";
import PropTypes from "prop-types";

import WorkIcon from "@material-ui/icons/Work";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import {set} from "utils/organisation_store";
import getSegmentedProjectName from "utils/projects/get_segmented_project_name";
import splitNestedProjectItems from "utils/projects/split_nested_project_items";
import * as Navigation from "routes/navigation";
import projectCreateAction from "modules/projects/actions/project_create";
import projectClearAction from "modules/projects/actions/project_clear";
import documentsClearAction from "modules/documents/actions/documents_clear";
import Permissioner from "utils/permissioner";

import withLocalStorage from "routes/app/local_storage/with_local_storage";

class ProjectSelector extends React.Component {
  constructor(props) {
    super(props);
    this.Icon = WorkIcon;
    if (props.user.is_admin) {
      props.localStorageContext.loadItem("arePrivateProjectsShown", true);
    }
    this.state = {arePrivateProjectsShown: this.arePrivateProjectsShown(props)};
  }

  componentDidUpdate() {
    if (
      this.props.user.is_admin &&
      this.arePrivateProjectsShown() !== this.state.arePrivateProjectsShown
    ) {
      this.setState({arePrivateProjectsShown: this.arePrivateProjectsShown()});
    }
  }

  render() {
    return this.props.children(this);
  }

  arePrivateProjectsShown = _props => {
    const props = _props || this.props;
    let arePrivateProjectsShown = true;
    if (props.user.is_admin) {
      arePrivateProjectsShown = props.localStorageContext.getItem(
        "arePrivateProjectsShown",
      );
      if (props.arePrivateProjectsShown !== undefined) {
        arePrivateProjectsShown = props.arePrivateProjectsShown;
      }
    }
    return arePrivateProjectsShown;
  };

  getProject = () => {
    return this.props.projects.find(({id}) => id === this.props.projectId);
  };

  getProjectItem = () => {
    const project = this.getProject();
    return this.getItems().find(item => item.projectId === project.id);
  };

  getProjects = () => {
    const arePrivateProjectsShown = this.arePrivateProjectsShown();
    const {projects: rawProjects = []} = this.props;
    return arePrivateProjectsShown
      ? rawProjects
      : rawProjects
          .filter(project => !project.is_private)
          .filter(
            project =>
              this.props.user.is_admin ||
              (!project.holds_clause_templates && !project.admin_only),
          )
          .filter(project =>
            new Permissioner(this.props.user).canViewProject(project),
          );
  };

  getSegmentedProjectName = () => {
    const project = this.getProject();
    return project && project.name ? getSegmentedProjectName(project.name) : "";
  };

  getActionItems = () => {
    const arePrivateProjectsShown = this.arePrivateProjectsShown();
    const list = [];
    list.push({
      Icon: AddIcon,
      onClick: () => this.createProject(),
      text: "Add project",
    });
    if (this.props.user.is_admin) {
      list.push({
        Icon: arePrivateProjectsShown ? VisibilityOffIcon : VisibilityIcon,
        onClick: () => this.togglePrivateProjectsVisibility(),
        text: `${arePrivateProjectsShown ? "Hide" : "Show"} Private Projects`,
      });
    }
    return list;
  };

  getItems = () =>
    splitNestedProjectItems(this.getProjects(), this.props.user.is_admin).map(
      item => {
        const isDisabled = !item.projectId;
        const isSelected = isDisabled
          ? false
          : item.projectId === this.props.projectId;
        if (!isDisabled && !isSelected) {
          item.onClick = () => this.selectProject(item.projectId);
        }
        return {
          ...item,
          isDisabled,
          isSelected,
          link: this.getLinkPath(item.projectId),
        };
      },
    );

  togglePrivateProjectsVisibility = () => {
    const arePrivateProjectsShown = !this.state.arePrivateProjectsShown;
    if (this.props.user.is_admin) {
      this.props.localStorageContext.setItem(
        "arePrivateProjectsShown",
        arePrivateProjectsShown,
      );
    }
    this.setState(
      () => ({arePrivateProjectsShown}),
      () => {
        if (!arePrivateProjectsShown) {
          this.props.handleHidePrivateProjects(this);
        }
      },
    );
  };

  createProject = () => {
    this.props.projectCreateAction(
      this.props.router.params.organisationId,
      this.props.projects ? this.props.projects.length + 1 : 1,
    );
  };

  selectFirstNonPrivateProject = () => {
    const project = this.props.projects.find(_project => !_project.is_private);
    if (project && project.id) {
      this.selectProject(project.id);
    }
  };

  selectProject = projectId => {
    const {router} = this.props;
    const {organisationId, projectId: currentProjectId} = router.params;
    this.props.documentsClearAction(organisationId, currentProjectId);
    this.props.projectClearAction(organisationId, currentProjectId);
    set(organisationId, "projectId", projectId);
    const currentNavItem = Navigation.getCurrentItem(router);

    if (!this.isProjectRoute()) {
      router.replace(router.location.pathname);
    } else if (!currentNavItem) {
      Navigation.replaceUrlParam(router, "projectId", projectId, "/detail");
    } else {
      const paramValues = {
        ...router.params,
        projectId,
      };
      const targetPath = currentNavItem.route.path;
      const url = targetPath.replace(/:([^\/]+)/g, (match, group) => {
        return paramValues[group];
      });
      router.replace(url);
    }
  };

  isProjectRoute = () =>
    /project\/:projectId/.test(Navigation.getRoutePath(this.props.router));

  getLinkPath = projectId => {
    const {router} = this.props;
    const currentNavItem = Navigation.getCurrentItem(router);
    let url = "";
    if (!this.isProjectRoute()) {
      url = router.location.pathname;
    } else if (!currentNavItem) {
      url = Navigation.getUrl(router, "projectId", projectId, "/detail");
    } else {
      const paramValues = {
        ...router.params,
        projectId,
      };
      const targetPath = currentNavItem.route.path;
      url = targetPath.replace(/:([^\/]+)/g, (match, group) => {
        return paramValues[group];
      });
    }
    return url;
  };
}

ProjectSelector.defaultProps = {
  handleHidePrivateProjects: () => {},
};

ProjectSelector.propTypes = {
  projectId: PropTypes.number.isRequired,
  children: PropTypes.func.isRequired,
  handleHidePrivateProjects: PropTypes.func.isRequired,
};

function select(state) {
  return {
    user: state.user,
    projects: state.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        projectCreateAction: projectCreateAction(requestor),
        documentsClearAction,
        projectClearAction,
      },
      dispatch,
    ),
  };
}

export default withLocalStorage(
  connect(select, mapDispatchToProps)(withRouter(ProjectSelector)),
);

import React from "react";
import _ from "underscore";
import scrollIntoView from "scroll-into-view";
import {cloneDeep, get} from "lodash";
import {getIssueManualCorrections} from "../../../utils/manual_corrections_utils";

import DocumentViews from "./document_views";
import ImagesPageView from "./images_page_view";
import RightHandMenu from "./right_hand_menu";
import ClausesAreTextualDialog from "./clauses_are_textual_dialog";
import EmailReportDialog from "./email_report_dialog";
import RevertDocumentChangesDialog from "./revert_document_changes_dialog";
import LinkedDefinitions from "./linked_definitions";
import {DocumentDetailContextProvider} from "common_components/context/document_detail_context";
import SelectChecklistDialog from "../../document_list/components/select_checklist_dialog";
import generateDocumentHeadings from "../utils/generate_document_headings";
import defaultZoom from "../constants/default_zoom";

import getApplicableClausesPath from "utils/issuesets/get_applicable_clauses_path";
import calculateDocumentTopics from "utils/topic/calculate_document_topics";
import getDocumentClausesTopics from "utils/topic/get_document_clauses_topics";
import calculateIssueId from "../utils/calculate_issue_id";
import getClauseAtomIds from "../utils/get_clause_atom_ids";
import findDefinitions from "../utils/find_definitions";
import localStorage from "utils/local_storage";
import sessionStorage from "utils/session_storage";
import issuesetUtils from "common/utils/issues/issueset_utils";
import getPositiveReasonData from "common/utils/issues/reason/get_positive_reason_data";
import getCurrentIssuesetItem from "utils/issuesets/get_current_issueset_item";
import {filterOutstanding} from "utils/issues/filter_issues";
import getIssuesData from "utils/issues/get_issues_data";
import constructIssueDetailData from "utils/issues/construct_issue_detail_data";
import findIssueInGroupedIssues from "utils/issues/find_issue_in_grouped_issues";
import getIssuesWithApplicableClauses from "utils/issues/get_issues_with_applicable_clauses";
import issuedDetailUtils from "utils/issues/issue_detail_utils";
import selectNodeText from "utils/select_node_text";

import DocumentSidebar from "./sidebar";
import DocumentToolbar from "./toolbar";
import DocumentLogs from "common_components/document_logs";
import groupChecklistSections from "common/utils/document/group_checklist_sections";
import createIssuesetOverrideData from "utils/issues/create_issueset_override_data";
import {initialFiltersState} from "common_components/filters/constants";
import getSelectedReportStateValues from "../utils/get_selected_report_state_values";

import initialiseClientMode from "../utils/initialise_client_mode";
import getLocalStorageData from "../utils/get_local_storage_data";

import downloadChecklist from "../utils/download_checklist";
import findFirstApplicableClause from "../utils/applicable_clauses/find_first_applicable_clause";
import PrerequisiteWorkflowsDialog from "./prerequisite_workflows_dialog";

const styles = {
  documentDetail: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "hidden",
    position: "relative",
  },
  documentDetailMain: {
    display: "flex",
    justifyContent: "flex-start",
    flexGrow: 1,
    overflow: "hidden",
  },
  documentMain: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflow: "hidden",
  },
};

const defaultState = {
  selectedHeading: "",
  selectedIssue: "",
};

const SCROLL_DELAY_TIME = 1200; // ms

const initSelectedReportState = project => {
  const {report_settings: reportSettings = []} = project;
  if (!reportSettings || reportSettings.length === 0) {
    return {selectedReportId: null};
  }
  return {
    selectedReportId: reportSettings[0].id,
  };
};

const INITIAL_SUBCLAUSES_TO_RENDER = 50;
const FURTHER_SUBCLAUSES_TO_RENDER = 10;

export default class DocumentDetail extends React.Component {
  constructor(props) {
    super(props);
    this.props.fetchDocumentData("page_image");

    this.nextVisibilityTimeout = {};
    this.nextVisibilityState = {};
    const selectedHeading = this.props.highlightedClause || "";
    const clientModeStorage = getLocalStorageData("clientModeOn");
    const issueCollapsedStorage = getLocalStorageData("areIssuesCollapsed");
    const clientModeOn = clientModeStorage ? clientModeStorage.value : true;
    const editPlaybookModeValue = sessionStorage.getItem("editPlaybookMode");
    const editPlaybookMode =
      editPlaybookModeValue !== null && editPlaybookModeValue !== undefined
        ? editPlaybookModeValue.toString() === "true"
        : false;
    const viewDocxValue = sessionStorage.getItem("viewDocx");
    const viewDocx = viewDocxValue && viewDocxValue === "true";
    this.state = {
      ...defaultState,
      fileIndex: 1,
      clauseToRender: props.project.read_plain
        ? 9999999
        : INITIAL_SUBCLAUSES_TO_RENDER,
      primaryPanel: "checklist",
      selectedHeading,
      filterClauses: true,
      hiddenHeadings: {},
      headingVisibility: {},
      scrollCorrection: {},
      selectedTopics: {},
      areAllTopicsShown: false,
      showLlmChat: false,
      showClausesTopicsSelectorValue:
        localStorage.getItem("showClausesTopicsSelectorValue") || "none",
      areIssuesCollapsed: clientModeOn
        ? true
        : issueCollapsedStorage !== null
        ? issueCollapsedStorage
        : true,
      isIssueCorrectnessPanelShown: localStorage.getItem(
        "isIssueCorrectnessPanelShown",
      )
        ? localStorage.getItem("isIssueCorrectnessPanelShown") === "true"
        : true,
      isFlipChecklistIcons:
        localStorage.getItem("isFlipChecklistIcons") === "true",
      isClauseDetailsShown: localStorage.getItem("isClauseDetailsShown")
        ? localStorage.getItem("isClauseDetailsShown") === "true"
        : false,
      isChecklistLocationToggled: localStorage.getItem(
        "isChecklistLocationToggled",
      )
        ? localStorage.getItem("isChecklistLocationToggled") === "true"
        : false,
      showTwoPanes: localStorage.getItem("showTwoPanes") === "true",
      panelData: null,
      responseAddedToReport: false,
      currentIssueset: props.currentIssueset,
      editableClausepart: null,
      shownDialogueName: "",
      newClausepartData: null,
      editableClauseHeading: null,
      clauseToHighlight: null,
      additionClause: null,
      editableClauseId: null,
      findAndReplace: {
        find: "",
        replace: "",
      },
      // hidden when undefined, visibile but toggled otherwise
      editModeOn: undefined,
      editingReport: false,
      // addClausesToReportDocumentIssueId is used when we add clauses to report
      addClausesToReportDocumentIssueId: null,
      hideTextDeletions: false,
      ...initSelectedReportState(this.props.project),
      // clientModeOn is used for demos, it hides: red circles near thumbs up/down,
      // collapse issues checkbox, issue correctness panel
      clientModeOn,
      context: {
        zoom: localStorage.getItem("documentDetailZoom")
          ? JSON.parse(localStorage.getItem("documentDetailZoom"))
          : defaultZoom,
      },
      editPlaybookMode,
      viewDocx,
      playbookState: {},
      highlightFixes: localStorage.getItem("highlightFixes") === "true",
      shouldShowDocumentLogs: false,
      shouldShowLinkedDefinitions: false,
      shouldShowKickOffWorkflowDialog: false,
      documentLedReview: this.props.project.show_clause_buttons,
      selectedChecklistSectionId: null,
      itemIssuesFilterValues: {
        ...initialFiltersState,
      },
      isReportTabShow: false,
      isReportShownInRightSide: false,
      selectedClauses: null,
      additionalReportClausesState: null,
      prevStateAdditionalReportClausesState: null,
      searchValue: "",
    };

    this.clauseToHighlightTimerId = null;
    this.correctDocumentIssueManually = correctDocumentIssueManually.bind(this);
    this.checklistSections = groupChecklistSections(
      this.props.documentSections,
    );
  }

  changeItemIssuesFilterValues = item => {
    if (item.value === this.state.itemIssuesFilterValues.sort) {
      return;
    }
    if (item === "delete") {
      this.setState({
        itemIssuesFilterValues: {
          ...initialFiltersState,
        },
      });
      return;
    }
    if (item.type === "issueState") {
      this.setState({
        itemIssuesFilterValues: {
          ...this.state.itemIssuesFilterValues,
          [item.value]: !this.state.itemIssuesFilterValues[item.value],
        },
      });
    } else {
      if (
        Object.values(this.state.itemIssuesFilterValues).find(
          value => value === item.value,
        )
      ) {
        const newState = {...this.state.itemIssuesFilterValues};
        delete newState[item.type];
        this.setState({
          itemIssuesFilterValues: {
            ...newState,
          },
        });
      } else {
        this.setState({
          itemIssuesFilterValues: {
            ...this.state.itemIssuesFilterValues,
            [item.type]: item.value,
          },
        });
      }
    }
  };

  getReviewTrackingOn = () => {
    return this.props.document.review_tracking_on === null
      ? this.props.project.review_tracking_on
      : this.props.document.review_tracking_on;
  };

  toggleMenuItem = value => {
    this.props.updateDocument({
      [value]: !this.props.document[value],
    });
  };

  componentDidMount() {
    window.onkeydown = e => {
      if (e.keyCode === 27) {
        // Esc
        if (this.state.editableClausepart) {
          return this.setState(() => ({editableClausepart: null}));
        }
      }
    };
    const clausepartIdQueryParam = get(this.props, "queryParams.clausepart_id");
    if (clausepartIdQueryParam) {
      this.scrollToClause({id: parseInt(clausepartIdQueryParam, 10)});
    }

    const clientModeStorage = getLocalStorageData("clientModeOn");
    if (clientModeStorage !== null) {
      initialiseClientMode(
        clientModeStorage,
        this.props.organisation.is_central_admin,
        this.props.document.id,
        this.props.organisationId,
        this.switchClientMode,
      );
    }
  }

  componentWillUnmount() {
    delete window.onkeydown;
  }

  componentDidUpdate(prevProps, prevState) {
    this.turnOffHighlight(prevState);

    if (this.lastRender) {
      clearTimeout(this.lastRender);
    }

    if (!this.maxClause) {
      const lastSection = this.props.documentSections[
        this.props.documentSections.length - 1
      ];
      if (lastSection) {
        const clauses = this.props.documentClauses[lastSection.id];
        this.maxClause = clauses[clauses.length - 1].row_number;
      }
    }

    if (this.isStillRendering()) {
      this.lastRender = setTimeout(() => this.setNextClauseToRender(), 500);
    }
  }

  resetClausesToRender() {
    this.setState(this.getResetClausesToRenderState());
  }

  getResetClausesToRenderState() {
    return {
      clauseToRender: INITIAL_SUBCLAUSES_TO_RENDER,
      renderStatus: null,
    };
  }

  incrementRenderStatus(renderStatus) {
    let newRenderStatus;
    let clause;
    if (!renderStatus) {
      const sectionId = this.props.documentSections[0].id;
      clause = this.props.documentClauses[sectionId][0];
      newRenderStatus = {
        clauseOrder: 1,
        clauseInSection: 1,
        sectionOrder: 1,
        clauseId: clause.id,
        sectionId,
      };
    } else {
      const lastClauseInSection =
        this.props.documentClauses[renderStatus.sectionId].length ===
        renderStatus.clauseInSection;
      if (lastClauseInSection) {
        const newSection = this.props.documentSections.find(
          section => section.order > renderStatus.sectionOrder,
        );
        clause = this.props.documentClauses[newSection.id][0];
        newRenderStatus = {
          clauseOrder: renderStatus.clauseOrder + 1,
          clauseInSection: 1,
          sectionOrder: newSection.order,
          clauseId: clause.id,
          sectionId: newSection.id,
        };
      } else {
        clause = this.props.documentClauses[renderStatus.sectionId][
          renderStatus.clauseInSection - 1
        ];
        newRenderStatus = {
          clauseOrder: renderStatus.clauseOrder + 1,
          clauseInSection: renderStatus.clauseInSection + 1,
          sectionOrder: renderStatus.sectionOrder,
          clauseId: clause.id,
          sectionId: renderStatus.sectionId,
        };
      }
    }
    const clauseCount = this.countClauseparts(clause.nodes);
    return [newRenderStatus, clauseCount];
  }

  countClauseparts(node) {
    if (node.type.endsWith("Atom")) {
      return 1;
    } else {
      return (node.clauseNodes ?? []).reduce(
        (sum, clauseNode) => sum + this.countClauseparts(clauseNode),
        0,
      );
    }
  }

  setNextClauseToRender() {
    if (this.isStillRendering()) {
      const {renderStatus} = this.state;

      let subclausesRendered = 0;
      let lastRenderStatus = renderStatus;
      let clausesRendered = 0;
      while (
        subclausesRendered < FURTHER_SUBCLAUSES_TO_RENDER &&
        this.state.clauseToRender + clausesRendered < this.maxClause
      ) {
        const [newRenderStatus, subclauseCount] = this.incrementRenderStatus(
          lastRenderStatus,
        );
        subclausesRendered += subclauseCount;
        lastRenderStatus = newRenderStatus;
        clausesRendered += 1;
      }

      const newState = {
        clauseToRender: this.state.clauseToRender + clausesRendered,
        renderStatus: lastRenderStatus,
      };

      this.setState(newState);
    } else {
      this.setState({
        clauseToRender: 9999999999,
        renderStatus: {
          clauseOrder: 9999999999,
          clauseInSection: 9999999999,
          sectionOrder: 9999999999,
        },
      });
    }
  }

  isStillRendering() {
    return this.state.clauseToRender < this.maxClause;
  }

  static getDerivedStateFromProps(props, state) {
    const oldIssue = state?.panelData?.data?.issue;
    if (!oldIssue) {
      return null;
    }
    const {groupedIssues} = getIssuesData(
      props,
      props.currentIssueset,
      state.areIssuesCollapsed,
      false,
      props.selectedChecklistSectionId,
      state.itemIssuesFilterValues,
    );
    if (
      (state.selectedChecklistSectionId ?? 0) !==
        (props.selectedChecklistSectionId ?? 0) ||
      state.currentIssueset !== props.currentIssueset
    ) {
      const findIssue = docIssue =>
        (docIssue.id === oldIssue?.id ||
          (docIssue.underlyingIssues &&
            docIssue.underlyingIssues.find(
              altIssue => altIssue.id === oldIssue?.id,
            ))) &&
        (props.selectedChecklistSectionId
          ? docIssue.section_id === props.selectedChecklistSectionId
          : !docIssue.section_id);

      const newIssue = groupedIssues.reduce((result, docIssue) => {
        if (!result) {
          const match = docIssue.isGroup
            ? docIssue.item.find(findIssue)
            : findIssue(docIssue) && docIssue;
          return match;
        }
        return result;
      }, null);
      if (newIssue) {
        return {
          ...DocumentDetail.getUpdatedIssueDetailState(
            state,
            props,
            newIssue,
            true,
          ),
        };
      }
      return null;
    }

    const newIssue = findIssueInGroupedIssues(
      oldIssue && oldIssue.id,
      props.selectedChecklistSectionId,
      groupedIssues,
      true,
    );
    if (
      issuedDetailUtils.isSameDetailShown(
        newIssue,
        oldIssue,
        props.selectedChecklistSectionId,
      ) &&
      !_.isEqual(newIssue, oldIssue)
    ) {
      return DocumentDetail.getUpdatedIssueDetailState(
        state,
        props,
        newIssue,
        true,
      );
    }
    return null;
  }

  turnOffHighlight(prevState) {
    if (!_.isEqual(this.state.clauseToHighlight, prevState.clauseToHighlight)) {
      if (this.clauseToHighlightTimerId) {
        clearTimeout(this.clauseToHighlightTimerId);
      }
      this.clauseToHighlightTimerId = setTimeout(() => {
        this.setState(
          () => ({clauseToHighlight: null}),
          () => {
            this.clauseToHighlightTimerId = null;
          },
        );
      }, 5000);
    }
  }

  render() {
    const {props} = this;
    const {groupedIssues, issues, currentIssuesetItem} = getIssuesData(
      props,
      this.props.currentIssueset,
      this.state.areIssuesCollapsed,
      false, // dontFilterIssuesByIssuesets
      this.state.selectedChecklistSectionId,
      this.state.itemIssuesFilterValues,
    );

    const positiveReasonData = getPositiveReasonData(
      this.props.documentClauses,
    );

    const issuesWithApplicableClauses = getIssuesWithApplicableClauses(
      issues,
      groupedIssues,
      currentIssuesetItem,
      positiveReasonData,
      this.state.additionalReportClausesState,
      props,
    );

    const outstandingIssues = issues
      .map(issue => {
        const reasons = filterOutstanding(issue);
        return [issue, reasons];
      })
      .filter(([, reasons]) => reasons.length > 0);
    this.documentTopics = getDocumentClausesTopics(
      props.documentClauses,
      props.topicMasks,
      props.topicsById,
      props.topicMasksById,
    );
    const topicsToShow = this.state.areAllTopicsShown
      ? this.getAllTopics(this.documentTopics, props.topics)
      : this.documentTopics;
    this.topicsToShowWithoutHidden = this.filterHiddenTopics(
      topicsToShow,
      props.topicCategoriesById,
    );
    const generatedDocumentHeadings = generateDocumentHeadings(
      props.documentHeadings,
      props.documentClauses,
      props.documentSections,
      Object.keys(this.state.headingVisibility).length > 0
        ? this.getFullReferencesList(this.state.headingVisibility)
        : null,
    );
    return (
      <DocumentDetailContextProvider
        value={this.getContextValue({
          ...this.state.context,
          isFlipChecklistIcons: this.state.isFlipChecklistIcons,
        })()}
      >
        <DocumentSidebar
          isCollapsedNavDisabled={false}
          router={props.router}
          documentTopics={this.topicsToShowWithoutHidden}
          issues={issuesWithApplicableClauses}
          scrollToClause={this.scrollToClause}
          topicsById={props.topicsById}
          topicCategories={props.topicCategories}
          document={props.document}
          documentSections={props.documentSections}
          documentClauses={props.documentClauses}
          documentDefinitions={this.props.documentDefinitions}
          onIssueClick={this.showIssueDetail}
          documentHeadings={generatedDocumentHeadings}
          documentIssues={props.documentIssues}
          onHeadingSelected={this.onHeadingSelected}
          selectedHeading={this.state.selectedHeading}
          headingVisibility={this.state.headingVisibility}
          hiddenHeadings={this.state.hiddenHeadings}
          selectedTopics={this.state.selectedTopics}
          areAllTopicsShown={this.state.areAllTopicsShown}
          scrollToHeading={this.scrollToHeading}
          scrollToSection={this.scrollToSection}
          onShowAllTopicsClick={this.onShowAllTopicsClick}
          project={this.props.project}
          viewMode={this.props.viewMode}
          onViewModeChange={this.onViewModeChange}
          search={this.search}
          searchResults={this.props.searchResults}
          organisationId={this.props.organisationId}
          projectId={this.props.project.id}
          addCustomEventListener={this.props.addCustomEventListener}
          removeCustomEventListener={this.props.removeCustomEventListener}
          selectedChecklistSectionId={this.state.selectedChecklistSectionId}
          checklistSections={this.checklistSections}
          clientModeOn={this.state.clientModeOn}
        />
        <div className="document-detail" style={styles.documentDetail}>
          {this.renderDialog()}
          {this.renderMain(
            groupedIssues,
            issuesWithApplicableClauses,
            outstandingIssues,
            generatedDocumentHeadings,
            currentIssuesetItem,
            positiveReasonData,
          )}
        </div>
      </DocumentDetailContextProvider>
    );
  }

  renderDialog = () => {
    switch (this.state.shownDialogueName) {
      case "email_report":
        return (
          <EmailReportDialog
            onDismiss={this.dismissDialog}
            sendEmailReport={this.props.sendEmailReport}
            issuesetId={this.props.currentIssueset}
            username={this.props.user && this.props.user.username}
          />
        );
      case "select_checklist":
        return (
          <SelectChecklistDialog
            onDismiss={this.dismissDialog}
            project={this.props.project}
            contractTypesById={this.props.contractTypesById}
            onFinish={this.updateDocumentIssuesets}
            documentIssuesets={this.props.document.issuesets}
          />
        );
      case "clauses_are_textual":
        return <ClausesAreTextualDialog onDismiss={this.dismissDialog} />;
      case "revert_document_changes":
        return (
          <RevertDocumentChangesDialog
            onDismiss={this.dismissDialog}
            onConfirm={this.onRevertDocumentChangesConfirm}
          />
        );
      default:
        return null;
    }
  };

  onRevertDocumentChangesConfirm = () => {
    this.props.revertAllIssues(true);
    this.dismissDialog();
  };

  renderMain = (
    groupedIssuesWithSubissues,
    issues,
    outstandingIssues,
    generatedDocumentHeadings,
    currentIssuesetItem,
    positiveReasonData,
  ) => {
    const applicableClausesPath = getApplicableClausesPath(currentIssuesetItem);
    const elements = [
      this.renderDocumentViews(
        groupedIssuesWithSubissues,
        issues,
        positiveReasonData,
        outstandingIssues,
        currentIssuesetItem,
        applicableClausesPath,
      ),
      this.renderRightHandMenu(
        issues,
        groupedIssuesWithSubissues,
        outstandingIssues,
        generatedDocumentHeadings,
        positiveReasonData,
        currentIssuesetItem,
        applicableClausesPath,
      ),
    ];
    if (this.state.isChecklistLocationToggled) {
      elements.reverse();
    }
    return (
      <div className="document-detail-main" style={styles.documentDetailMain}>
        {elements}
      </div>
    );
  };

  renderDocumentViews = (
    groupedIssues,
    issues,
    positiveReasonData,
    outstandingIssues,
    currentIssuesetItem,
    applicableClausesPath,
  ) => {
    const {props, state} = this;
    if (props.viewMode !== "checklist") {
      const {documentData} = props.document;
      let images = null;
      if (documentData) {
        images = _.chain(documentData)
          .filter(
            docDataItem => docDataItem.options.section_type === props.viewMode,
          )
          .sortBy(docDataItem => docDataItem.options.pageNumber)
          .map(docDataItem => docDataItem.data)
          .value();
      }
      return <ImagesPageView document={props.document} images={images} />;
    }
    const {panelData} = state;
    return (
      <div style={styles.documentMain} key="document-view">
        <DocumentToolbar
          showLlmChat={this.state.showLlmChat}
          toggleLlmChat={this.toggleLlmChat}
          document={this.props.document}
          documents={this.props.documents}
          router={this.props.router}
          project={this.props.project}
          verifyIssues={this.props.project.verify_issues}
          outstandingIssues={outstandingIssues}
          user={this.props.user}
          documentChanges={this.props.documentChanges}
          goToAnalystView={this.props.goToAnalystView}
          viewSummaryPage={this.props.viewSummaryPage}
          downloadDocument={this.props.downloadDocument}
          expandClauseHeadings={this.expandClauseHeadings}
          collapseClauseHeadings={this.collapseClauseHeadings}
          showClausesTopicsSelectorValue={
            this.state.showClausesTopicsSelectorValue
          }
          showClausesTopics={this.showClausesTopics}
          collapseIssues={this.collapseIssues}
          areIssuesCollapsed={this.state.areIssuesCollapsed}
          highlightFixes={this.state.highlightFixes}
          toggleHighlightFixes={this.toggleHighlightFixes}
          isIssueCorrectnessPanelShown={this.state.isIssueCorrectnessPanelShown}
          toggleReviewTrackingOn={() =>
            this.toggleMenuItem("review_tracking_on")
          }
          reviewTrackingOn={this.getReviewTrackingOn()}
          toggleIsBadlyParsed={() => this.toggleMenuItem("is_badly_parsed")}
          isBadlyParsed={this.props.document.is_badly_parsed}
          showIssueCorrectnessPanel={this.showIssueCorrectnessPanel}
          isClauseDetailsShown={this.state.isClauseDetailsShown}
          showClauseDetails={this.showClauseDetails}
          clientModeOn={this.state.clientModeOn}
          editPlaybookMode={this.state.editPlaybookMode}
          switchClientModeOn={this.switchClientModeOn}
          switchEditPlaybookMode={this.switchEditPlaybookMode}
          revertAllIssues={this.props.revertAllIssues}
          showEmailReportDialog={this.showDialog("email_report")}
          uploadDocumentToAPI={this.props.uploadDocumentToAPI}
          updateDocument={this.updateDocument}
          showIssuesInChecklist={this.props.project.show_issues_in_checklist}
          editModeOn={this.state.editModeOn}
          responseAddedToReport={this.state.responseAddedToReport}
          setEditModeOn={this.setEditModeOn}
          selectedReportId={this.state.selectedReportId}
          onSelectedReportChange={this.onSelectedReportChange}
          isReportPanelShown={this.state.isReportPanelShown}
          toggleReportPanelVisibility={this.toggleReportPanelVisibility}
          isReportShownInRightSide={this.state.isReportShownInRightSide}
          toggleReportOpenInRightSidebar={this.toggleReportOpenInRightSidebar}
          documentIssues={this.props.documentIssues}
          issues={issues}
          zoom={this.state.context.zoom}
          onZoomUpdate={this.onZoomUpdate}
          isChecklistLocationToggled={this.state.isChecklistLocationToggled}
          toggleChecklistLocation={this.toggleChecklistLocation}
          showTwoPanes={this.state.showTwoPanes}
          toggleShowTwoPanes={this.toggleShowTwoPanes}
          showClauseButtons={this.props.project.show_clause_buttons}
          documentStates={this.props.documentStates}
          showDocumentLogs={this.showDocumentLogs}
          viewDocx={this.state.viewDocx}
          switchViewDocx={this.switchViewDocx}
          showLinkedDefinitions={this.showLinkedDefinitions}
          showKickOffWorkflowDialog={this.showKickOffWorkflowDialog}
          documentLedReview={this.state.documentLedReview}
          toggleDocumentLedReview={this.toggleDocumentLedReview}
          reprocessDocumentRoles={this.props.reprocessDocumentRoles}
          currentIssueset={this.props.currentIssueset}
          renderingStatus={this.state.clauseToRender / this.maxClause}
          organisation={this.props.organisation}
          setFileIndex={this.setFileIndex}
          fileIndex={this.state.fileIndex}
        />
        <DocumentViews
          key="document_views"
          {...props}
          fileIndex={this.state.fileIndex}
          documentIssues={filterDocumentIssuesByIssueset(
            props.documentIssues,
            this.props.currentIssueset,
          )}
          issues={issues}
          positiveReasonData={positiveReasonData}
          filterClauses={state.filterClauses}
          hiddenHeadings={state.hiddenHeadings}
          onToggleClauseHeadingOpen={this.onToggleClauseHeadingOpen}
          topicHoverStart={this.topicHoverStart}
          topicHoverEnd={this.topicHoverEnd}
          hoveredTopicIds={state.hoveredTopicIds}
          toggleTopicSelection={this.toggleTopicSelection}
          selectedTopics={state.selectedTopics}
          selectedHeading={state.selectedHeading}
          selectedIssue={
            this.props.isDocumentLong ? undefined : state.selectedIssue
          }
          updateHeadingVisibility={this.updateHeadingVisibility}
          headingVisibility={state.headingVisibility}
          scrollCorrection={state.scrollCorrection}
          revertIssueActionState={this.revertIssueActionState}
          showClausesTopicsSelectorValue={state.showClausesTopicsSelectorValue}
          panelData={this.props.isDocumentLong ? undefined : panelData}
          currentClause={
            panelData && panelData.type === "clause_detail" && panelData.data
          }
          clearPanelData={this.improvedClearPanelData}
          showClauseDetail={this.showClauseDetail}
          groupedIssues={groupedIssues}
          showIssueDetail={this.showIssueDetail}
          showIssuesInChecklist={props.project.show_issues_in_checklist}
          updateDocument={this.updateDocument}
          updateIssueDetail={this.updateIssueDetail}
          responseAddedToReport={state.responseAddedToReport}
          editableClausepart={state.editableClausepart}
          setEditableClausepart={this.setEditableClausepart}
          addingDefinition={this.state.addingDefinition}
          setDefinitionBeingAdded={this.setDefinitionBeingAdded}
          unsetDefinitionBeingAdded={this.unsetDefinitionBeingAdded}
          newClausepartData={state.newClausepartData}
          showAddClausepartEditor={this.showAddClausepartEditor}
          saveNewClausepart={this.saveNewClausepart}
          deleteClauseparts={this.deleteClauseparts}
          alterDocumentClausepart={this.alterDocumentClausepart}
          hideClausepartEditor={this.hideClausepartEditor}
          scrollToClause={this.scrollToClause}
          editableClauseHeading={state.editableClauseHeading}
          setEditableClauseHeading={this.setEditableClauseHeading}
          unsetEditableClauseHeading={this.unsetEditableClauseHeading}
          clauseToHighlight={this.state.clauseToHighlight}
          additionClause={state.additionClause}
          showAddClauseEditor={this.showAddClauseEditor}
          editableClauseId={state.editableClauseId}
          hideClauseEditor={this.hideClauseEditor}
          onFindAndReplaceChange={this.onFindAndReplaceChange}
          findAndReplace={this.state.findAndReplace}
          hideAddClauseEditor={this.hideAddClauseEditor}
          documentTextReplace={this.documentTextReplace}
          hasDocumentFilledHeadings={this.hasDocumentFilledHeadings()}
          editModeOn={this.state.editModeOn}
          setEditModeOn={this.setEditModeOn}
          hideTextDeletions={this.state.hideTextDeletions}
          triggerHideTextDeletions={this.triggerHideTextDeletions}
          onRevertDocumentChangesDialogShow={this.showDialog(
            "revert_document_changes",
          )}
          currentIssuesetItem={currentIssuesetItem}
          applicableClausesPath={applicableClausesPath}
          toggleReportPanelVisibility={this.toggleReportPanelVisibility}
          isReportPanelShown={this.state.isReportPanelShown}
          toggleReportOpenInRightSidebar={this.toggleReportOpenInRightSidebar}
          isReportShownInRightSide={this.state.isReportShownInRightSide}
          selectedReportId={this.state.selectedReportId}
          correctDocumentIssueManually={this.correctDocumentIssueManually}
          areIssuesCollapsed={this.state.areIssuesCollapsed}
          showClauseButtons={this.state.documentLedReview}
          documentLedReview={this.state.documentLedReview}
          addClausesToReportDocumentIssueId={
            this.state.addClausesToReportDocumentIssueId
          }
          onAddClauseToReport={this.onAddClauseToReport}
          viewDocx={this.state.viewDocx}
          highlightFixes={this.state.highlightFixes}
          removeIssueFromReport={this.removeIssueFromReport}
          addIssueToReport={this.addIssueToReport}
          selectedChecklistSectionId={this.state.selectedChecklistSectionId}
          checklistSections={this.checklistSections}
          reprocessRoles={this.reprocessDocumentRoles}
          resetRoles={this.resetDocumentRoles}
          updateIssueReviewState={this.updateIssueReviewState}
          reviewTrackingOn={this.getReviewTrackingOn()}
          clauseToRender={this.state.clauseToRender}
          additionalReportClausesState={this.state.additionalReportClausesState}
          isReportTabShow={this.state.isReportTabShow}
          selectedClauses={this.state.selectedClauses}
          setSelectedClauses={this.setSelectedClauses}
          parties={this.props.parties}
          roles={this.props.roles}
          searchValue={this.state.searchValue}
        />
        {this.state.shouldShowDocumentLogs && (
          <DocumentLogs
            logs={this.props.documentLogs}
            clear={this.hideDocumentLogs}
          />
        )}
        <LinkedDefinitions
          documentDefinitions={props.documentDefinitions}
          definitionGroups={props.definitionGroups}
          contractTypesById={props.contractTypesById}
          document={props.document}
          isShown={this.state.shouldShowLinkedDefinitions}
          onClose={this.hideLinkedDefinitions}
          updateDocument={this.props.updateDocument}
        />
        {this.props.user.is_admin &&
          this.props.hasCanGetWorkflowsPermission && (
            <PrerequisiteWorkflowsDialog
              isShown={this.state.shouldShowKickOffWorkflowDialog}
              onClose={this.hideKickOffWorkflowDialog}
              fetchPrerequisiteWorkflows={this.props.fetchPrerequisiteWorkflows}
              organisationId={this.props.organisationId}
              documentId={this.props.document.id}
              onDocumentPrerequisiteWorkflowRun={
                this.props.onDocumentPrerequisiteWorkflowRun
              }
            />
          )}
      </div>
    );
  };

  setFileIndex = fileIndex => {
    this.setState({fileIndex});
  };

  toggleLlmChat = () => {
    const showLlmChat = !this.state.showLlmChat;
    this.setState({
      showLlmChat,
      panelData: showLlmChat ? {type: "llm_chat"} : this.state.prevPanelData,
      prevPanelData: showLlmChat ? this.state.panelData : null,
    });
  };

  showDocumentLogs = () =>
    this.setState(
      () => ({shouldShowDocumentLogs: true}),
      () => {
        const {organisationId, document, documentLogs, fetchLogs} = this.props;
        if (!documentLogs) {
          const query = `document_id=${document.id}`;
          fetchLogs(organisationId, query);
        }
      },
    );
  hideDocumentLogs = () => this.setState({shouldShowDocumentLogs: false});

  renderRightHandMenu = (
    issues,
    groupedIssuesWithSubissues,
    outstandingIssues,
    generatedDocumentHeadings,
    positiveReasonData,
    currentIssuesetItem,
    applicableClausesPath,
  ) => {
    return (
      <RightHandMenu
        key="right_hand_menu"
        user={this.props.user}
        project={this.props.project}
        organisation={this.props.organisation}
        organisationId={this.props.organisationId}
        issues={issues}
        groupedIssues={groupedIssuesWithSubissues}
        selectedIssue={this.state.selectedIssue}
        outstandingIssues={outstandingIssues}
        triggerCompareToOtherDocument={this.triggerCompareToOtherDocument}
        primaryPanel={this.state.primaryPanel}
        areIssuesCollapsed={this.state.areIssuesCollapsed}
        applicableClausesPath={applicableClausesPath}
        scrollToClause={this.scrollToClause}
        scrollToHeading={this.scrollToHeading}
        revertIssueActionState={this.revertIssueActionState}
        removeIssueFromReport={this.removeIssueFromReport}
        addIssueToReport={this.addIssueToReport}
        unselectIssue={this.unselectIssue}
        updateDocumentIssue={this.updateDocumentIssue}
        correctDocumentIssueManually={this.correctDocumentIssueManually}
        updateDocumentIssues={this.props.updateDocumentIssues}
        documentClauses={this.props.documentClauses}
        documentClauseparts={this.props.documentClauseparts}
        documentChanges={this.props.documentChanges}
        documentDefinitions={this.props.documentDefinitions}
        topicsById={this.props.topicsById}
        document={this.props.document}
        topicCategories={this.props.topicCategories}
        documentIssues={this.props.documentIssues}
        topicMasks={this.props.topicMasks}
        topicMasksById={this.props.topicMasksById}
        documentHeadings={generatedDocumentHeadings}
        baseDocumentHeadings={this.props.documentHeadings}
        documentSections={this.props.documentSections}
        selectedTopics={this.state.selectedTopics}
        topicHoverStart={this.topicHoverStart}
        topicHoverEnd={this.topicHoverEnd}
        hoveredTopicIds={this.state.hoveredTopicIds}
        toggleTopicSelection={this.toggleTopicSelection}
        updateDocument={this.props.updateDocument}
        updateOtherDocument={this.props.updateOtherDocument}
        panelData={this.state.panelData}
        showIssueDetail={this.showIssueDetail}
        updateIssueDetail={this.updateIssueDetail}
        clearPanelData={this.clearPanelData}
        showIssuesInChecklist={this.props.project.show_issues_in_checklist}
        verifyIssues={this.props.project.verify_issues}
        showClausesWithoutTopics={
          this.props.project.show_clauses_without_topics
        }
        onViewModeChange={this.onViewModeChange}
        viewMode={this.props.viewMode}
        updateDocumentMetaDataFeedback={this.updateDocumentMetaDataFeedback}
        currentIssueset={this.props.currentIssueset}
        currentIssuesetItem={currentIssuesetItem}
        defaultIssuesetId={this.defaultIssuesetId}
        onCurrentIssuesetChange={this.onCurrentIssuesetChange}
        positiveReasonData={positiveReasonData}
        isIssueCorrectnessPanelShown={this.state.isIssueCorrectnessPanelShown}
        isFlipChecklistIcons={this.state.isFlipChecklistIcons}
        contractTypesById={this.props.contractTypesById}
        showSelectChecklistDialog={this.showDialog("select_checklist")}
        correctDocumentIssue={this.props.correctDocumentIssue}
        revertDocumentIssue={this.props.revertDocumentIssue}
        displayHiddenIssues={
          (this.props.user.is_admin ||
            this.props.hasCanViewHiddenIssuesPermission) &&
          !this.state.clientModeOn
        }
        clientModeOn={this.state.clientModeOn}
        editPlaybookMode={this.state.editPlaybookMode}
        togglePlaybookState={this.togglePlaybookState}
        switchEditPlaybookMode={this.switchEditPlaybookMode}
        playbookState={this.state.playbookState}
        isChecklistLocationToggled={this.state.isChecklistLocationToggled}
        editModeOn={this.state.editModeOn}
        triggerCustomEvent={this.props.triggerCustomEvent}
        addClausesToReportDocumentIssueId={
          this.state.addClausesToReportDocumentIssueId
        }
        setAddClausesToReportDocumentIssueId={
          this.setAddClausesToReportDocumentIssueId
        }
        additionalReportClausesState={this.state.additionalReportClausesState}
        onRemoveAdditionalClauseFromReport={
          this.onRemoveAdditionalClauseFromReport
        }
        selectedReportId={this.state.selectedReportId}
        viewDocx={this.state.viewDocx}
        searchByIssueResults={this.props.searchByIssueResults}
        searchByIssue={this.props.searchByIssue}
        searchByIssueClear={this.props.searchByIssueClear}
        showFlipChecklistIcons={this.showFlipChecklistIcons}
        definitionGroups={this.props.definitionGroups}
        documents={this.props.documents}
        updateComparisonDocumentId={this.updateComparisonDocumentId}
        showComparisonDetail={this.showComparisonDetail}
        issueComparisonData={this.props.issueComparisonData}
        fetchIssueComparisonData={this.props.fetchIssueComparisonData}
        showChecklistPrimaryPanel={this.showChecklistPrimaryPanel}
        showTwoPanes={this.state.showTwoPanes}
        toggleShowTwoPanes={this.toggleShowTwoPanes}
        onDocumentIssuesFindRun={this.props.onDocumentIssuesFindRun}
        zoom={this.state.context.zoom}
        onZoomUpdate={this.onZoomUpdate}
        selectedChecklistSectionId={this.state.selectedChecklistSectionId}
        onSelectedChecklistSectionIdChange={
          this.onSelectedChecklistSectionIdChange
        }
        updateIssueReviewState={this.updateIssueReviewState}
        reviewTrackingOn={this.getReviewTrackingOn()}
        itemIssuesFilterValues={this.state.itemIssuesFilterValues}
        changeItemIssuesFilterValues={this.changeItemIssuesFilterValues}
        renderStatus={this.state.renderStatus}
        isShowReportTabHandler={this.isShowReportTabHandler}
        selectedClauses={this.state.selectedClauses}
        onAddClauseToReport={this.onAddClauseToReport}
        onDownload={this.downloadChecklist}
        scrollToParties={this.scrollToParties}
        issuesUpdating={this.state.rolesUpdating}
        partiesValidating={
          this.props.project.force_party_confirmation &&
          !this.props.document.are_roles_confirmed
        }
        editingReport={this.state.editingReport}
        updateEditState={this.setEditingReport}
        generateDocumentIssueLlmPrompt={
          this.props.generateDocumentIssueLlmPrompt
        }
        fetchDocumentIssueConversation={
          this.props.fetchDocumentIssueConversation
        }
        clearDocumentIssueConversation={
          this.props.clearDocumentIssueConversation
        }
        fileIndex={this.state.fileIndex}
        fileNames={this.props.document.concat_filenames}
        generateDocumentLlmPrompt={this.props.generateDocumentLlmPrompt}
        fetchDocumentConversations={this.props.fetchDocumentConversations}
        fetchDocumentConversationItems={
          this.props.fetchDocumentConversationItems
        }
        isReportShownInRightSide={this.state.isReportShownInRightSide}
        toggleReportOpenInRightSidebar={this.toggleReportOpenInRightSidebar}
        toggleReportPanelVisibility={this.toggleReportPanelVisibility}
        llmStatuses={this.props.llmStatuses}
      />
    );
  };

  downloadChecklist = () => {
    const {props} = this;
    const {groupedIssues, issues, currentIssuesetItem} = getIssuesData(
      props,
      this.props.currentIssueset,
      this.state.areIssuesCollapsed,
      false, // dontFilterIssuesByIssuesets
      this.state.selectedChecklistSectionId,
      this.state.itemIssuesFilterValues,
    );

    const positiveReasonData = getPositiveReasonData(
      this.props.documentClauses,
    );

    const issuesWithApplicableClauses = getIssuesWithApplicableClauses(
      issues,
      groupedIssues,
      currentIssuesetItem,
      positiveReasonData,
      this.state.additionalReportClausesState,
      this.props,
    );

    downloadChecklist(
      groupedIssues,
      issuesWithApplicableClauses,
      currentIssuesetItem,
      {...props, positiveReasonData},
    );
  };

  triggerCompareToOtherDocument = () =>
    this.setState(prevState => ({
      primaryPanel:
        prevState.primaryPanel === "compare" ? "checklist" : "compare",
    }));

  showChecklistPrimaryPanel = () =>
    this.setState(() => ({primaryPanel: "checklist"}));

  hasDocumentFilledHeadings = () => {
    return Boolean(
      this.props.documentSections.find(section => {
        return (
          !section.is_hidden &&
          this.props.documentHeadings.find(heading => {
            return heading.section_id === section.id && heading.text;
          })
        );
      }),
    );
  };

  onViewModeChange = (_event, index, _viewMode) => {
    const viewMode = typeof _event === "string" ? _event : _viewMode;
    this.props.onViewModeChange(viewMode);
  };

  onCurrentIssuesetChange = (event, index, currentIssueset) => {
    if (currentIssueset === "view_other_checklists") {
      return;
    }
    this.setState(
      () => ({currentIssueset}),
      () => {
        this.props.storeCurrentIssueset(
          this.props.document.id,
          currentIssueset,
        );
      },
    );
  };

  showIssueDetail = (
    issue,
    preventUsingCallback,
    shownTab,
    selectedReportId,
  ) => {
    const reasons = issue.reason || [];
    const reportSettingId = this.props.project.report_settings.find(
      report => report.type === "rag_report",
    )?.id;
    const currentIssuesetItem = getCurrentIssuesetItem(
      this.state.currentIssueset,
      this.props.document,
      this.props.contractTypesById,
    );
    const manualCorrections = getIssueManualCorrections(
      issue,
      currentIssuesetItem,
      reportSettingId,
    );

    const additionalReportClauses = manualCorrections?.additional_report_clauses
      ? manualCorrections?.additional_report_clauses
      : this.state.additionalReportClausesState
      ? this.state.additionalReportClausesState
      : [];

    const positiveReasonData = getPositiveReasonData(
      this.props.documentClauses,
    );

    const data = constructIssueDetailData(
      issue,
      this.props.currentIssueset,
      this.props.documentClauses,
      this.props.topicCategories,
      this.props.documentSections,
      this.props.topicsById,
      positiveReasonData,
      this.props.documentClauseparts,
      this.props.document.parties,
      this.state.selectedChecklistSectionId,
      false,
      additionalReportClauses,
      manualCorrections?.removedClauses,
    );
    this.onIssueSelected({
      ...issue,
      reasonIndex:
        reasons.length > 0 && reasons[0].index >= 0 ? reasons[0].index : -1,
    })();

    const applicableClausesObj = data.applicableClauses || {};
    const allTopicGroupsClauses = _.flatten(
      Object.values(applicableClausesObj),
    );

    const firstApplicableClause = findFirstApplicableClause(
      allTopicGroupsClauses,
      this.props.documentSections,
    );

    if (firstApplicableClause) {
      if (this.props.viewMode === "checklist") {
        const clauseEl = document.getElementById(
          `clause-atom-${firstApplicableClause.id}`,
        );
        this.scrollToClause(firstApplicableClause, clauseEl);
      } else {
        this.scrollToImage(
          firstApplicableClause.nodes.pageNumber ||
            firstApplicableClause.pageNumber,
        );
      }
    }

    const {isReportPanelShown} = this.state;
    this.setState(
      () => ({
        additionalReportClausesState: null,
        showLlmChat: false,
        panelData: {
          type: "issue_detail",
          data,
          shownTab,
          selectedReportId,
        },
      }),
      () => {
        if (!preventUsingCallback && isReportPanelShown) {
          this.scrollToRagReportItem(issue.document_issue_id);
        }
      },
    );
    if (issue.review_state === 1) {
      this.updateIssueReviewState(issue, 2);
    }
    if (!manualCorrections?.additional_report_clauses) {
      this.setState(() => ({
        additionalReportClausesState: [],
      }));
    } else {
      this.setState(() => ({
        additionalReportClausesState:
          manualCorrections.additional_report_clauses,
      }));
    }
  };

  updateIssueReviewState = (issue, item) => {
    if (!issue?.id) {
      return null;
    }
    const options = {merge_update_object: true};
    const currentIssuesetItem = getCurrentIssuesetItem(
      this.state.currentIssueset,
      this.props.document,
      this.props.contractTypesById,
    );

    if (currentIssuesetItem) {
      if (options && options.merge_update_object) {
        options.data_path = createIssuesetOverrideData(currentIssuesetItem);
        options.update_override_document_issue_values = true;
      }
    }
    this.updateDocumentIssue(issue, {review_state: item}, options);
  };

  updateIssueDetail = (issue, clearAddClausesState) => {
    const updates = this.constructor.getUpdatedIssueDetailState(
      this.state,
      this.props,
      issue,
      clearAddClausesState,
    );
    if (updates) {
      this.setState(updates);
    }
  };

  static getUpdatedIssueDetailState = (
    state,
    props,
    issue,
    clearAddClausesState,
  ) => {
    const {panelData, selectedReportId} = state;
    if (panelData && panelData.type === "issue_detail") {
      const currentIssuesetItem = getCurrentIssuesetItem(
        state.currentIssueset,
        props.document,
        props.contractTypesById,
      );
      const manualCorrections = getIssueManualCorrections(
        issue,
        currentIssuesetItem,
        selectedReportId,
      );
      const additionalReportClauses = manualCorrections?.additional_report_clauses
        ? manualCorrections?.additional_report_clauses
        : state.additionalReportClausesState
        ? state.additionalReportClausesState
        : [];

      const positiveReasonData = getPositiveReasonData(props.documentClauses);

      // TODO: Convert to Typescript
      const data = constructIssueDetailData(
        issue,
        props.currentIssueset,
        props.documentClauses,
        props.topicCategories,
        props.documentSections,
        props.topicsById,
        positiveReasonData,
        props.documentClauseparts,
        props.parties,
        props.selectedChecklistSectionId,
        false,
        additionalReportClauses,
        manualCorrections?.removedClauses,
      );

      const updates = {
        showLlmChat: false,
        currentIssueset: props.currentIssueset,
        selectedChecklistSectionId: props.selectedChecklistSectionId,
        panelData: {
          type: "issue_detail",
          data,
        },
      };
      if (clearAddClausesState) {
        updates.addClausesToReportDocumentIssueId = null;
        updates.additionalReportClausesState = null;
      }
      if (data) {
        const issueManualCorrections = data.issue.manual_corrections;
        updates.additionalReportClausesState =
          issueManualCorrections?.[state.selectedReportId]
            ?.additional_report_clauses || null;
      }
      return updates;
    }
  };

  selectApplicableClause = applicableClausesObj => {
    const applicableClauses = Object.values(applicableClausesObj)[0] || [];
    const clause = applicableClauses[0];
    if (clause) {
      const atomEl = document.getElementById(`clause-atom-${clause.id}`);
      selectNodeText(atomEl);
    }
  };

  clearPanelData = () =>
    this.setState(() => ({
      showLlmChat: false,
      panelData: null,
      selectedIssue: "",
      selectedTopics: {},
      addClausesToReportDocumentIssueId: null,
      additionalReportClausesState: null,
    }));

  showClausesTopics = toggleState =>
    _.memoize(() => {
      localStorage.setItem("showClausesTopicsSelectorValue", toggleState);
      this.setState(() => ({
        showClausesTopicsSelectorValue: toggleState,
      }));
    });

  getContextValue = _.memoize(
    context => () => context,
    (...args) => JSON.stringify([...args]),
  );

  filterHiddenTopics = (topics, topicCategories) => {
    return topics.filter(topic => {
      if (topicCategories[topic.topic.topiccategory_id].hidden) {
        return false;
      }
      return true;
    });
  };

  getAllTopics = (documentTopics, topics) => {
    const documentTopicIds = documentTopics.map(topic => topic.id);
    const disabledTopics = topics.filter(topic => {
      if (documentTopicIds.includes(topic.id)) {
        const topicIdIndex = documentTopicIds.indexOf(topic.id);
        if (topicIdIndex > -1) {
          documentTopicIds.splice(topicIdIndex, 1);
        }
        return false;
      }
      return true;
    });
    const disabledTopicsConverted = disabledTopics.map(topic => ({
      id: topic.id,
      count: 1,
      clauseTopic: {
        isDisabled: true,
      },
      topic: {
        id: topic.id,
        name: topic.name,
        topiccategory_id: topic.topiccategory_id,
      },
    }));
    return _.sortBy(
      [...documentTopics, ...disabledTopicsConverted],
      clauseTopic => clauseTopic.topic.name,
    );
  };

  onShowAllTopicsClick = () => {
    this.setState(() => ({areAllTopicsShown: !this.state.areAllTopicsShown}));
  };

  clearSelectedTopics = () => {
    this.setState(() => ({selectedTopics: {}}));
  };
  showClauseDetail = clause => {
    if (!this.state.isClauseDetailsShown) {
      return;
    }
    const definitions = findDefinitions(
      null,
      clause,
      this.props.documentDefinitions,
      this.props.documentQualifiers,
      this.props.documentChanges,
    );
    clause.atomIds = getClauseAtomIds(clause);
    this.setState(() => ({
      panelData: {
        type: "clause_detail",
        data: {
          clause,
          definitions: this.calculateDefinitions(definitions),
        },
      },
    }));
  };
  collapseIssues = () => {
    const areIssuesCollapsed = !this.state.areIssuesCollapsed;
    localStorage.setItem("areIssuesCollapsed", areIssuesCollapsed);
    this.setState(() => ({areIssuesCollapsed}));
  };
  showIssueCorrectnessPanel = () => {
    const isIssueCorrectnessPanelShown = !this.state
      .isIssueCorrectnessPanelShown;
    localStorage.setItem(
      "isIssueCorrectnessPanelShown",
      isIssueCorrectnessPanelShown,
    );
    this.setState(() => ({isIssueCorrectnessPanelShown}));
  };
  toggleHighlightFixes = () => {
    const highlightFixes = !this.state.highlightFixes;
    localStorage.setItem("highlightFixes", highlightFixes);
    this.setState(() => ({highlightFixes}));
  };
  showFlipChecklistIcons = () => {
    const isFlipChecklistIcons = !this.state.isFlipChecklistIcons;
    localStorage.setItem("isFlipChecklistIcons", isFlipChecklistIcons);
    this.setState(() => ({isFlipChecklistIcons}));
  };
  showClauseDetails = () => {
    const isClauseDetailsShown = !this.state.isClauseDetailsShown;
    localStorage.setItem("isClauseDetailsShown", isClauseDetailsShown);
    this.setState(() => ({isClauseDetailsShown}));
  };

  calculateDefinitions(definitions) {
    return definitions;
    // let d1 = definitions;
    // let d2 = this.calculateSubDefinitions(definitions);
    // while (d1.length < d2.length) {
    //   d1 = d2;
    //   d2 = this.calculateSubDefinitions(d1);
    // }
    // return d1;
  }

  calculateSubDefinitions(definitions) {
    const {documentDefinitions} = this.props;
    return _.chain(definitions)
      .map(definition => [
        definition,
        ...findDefinitions(definition.meaning, documentDefinitions).map(
          instance => instance.definition,
        ),
      ])
      .flatten()
      .uniq()
      .sortBy(definition => definition.term)
      .value();
  }

  calculateScrollCorrection(reference) {
    const hv = this.state.headingVisibility[reference];
    let scrollerIndex = null;
    let visibleOffset = null;
    if (hv) {
      for (const [key, value] of Object.entries(hv)) {
        if (value.isVisible) {
          scrollerIndex = key;
          visibleOffset = value.offset;
          break;
        }
      }
      if (scrollerIndex) {
        const scroller = document.getElementsByClassName(
          `view-scroller-${scrollerIndex}`,
        )[0];
        const topOfPage = scroller.scrollTop;
        const offsetFromTop = visibleOffset - topOfPage;
        return {
          scrollerIndex,
          offsetFromTop,
          reference,
        };
      }
    }
    return this.state.scrollCorrection;
  }

  /* eslint-disable no-invalid-this */

  toggleTopicSelection = (_topicId, reference) => {
    const topicIds = Array.isArray(_topicId) ? _topicId : [_topicId];
    this.setState((state, props) => {
      const selectedTopics = {...state.selectedTopics};
      const hiddenHeadings = {...state.hiddenHeadings};
      const scrollCorrection = this.calculateScrollCorrection(reference);

      topicIds.forEach(topicId => {
        if (selectedTopics[topicId]) {
          delete selectedTopics[topicId];
        } else {
          selectedTopics[topicId] =
            props.topicsById[topicId] ||
            props.topicMasksById[topicId.substring(2)];
          const {
            documentClauses,
            topicMasks,
            topicsById,
            topicMasksById,
          } = props;

          /* eslint-disable no-inner-declarations */
          function unhideClause(clause) {
            const topics = calculateDocumentTopics(
              clause,
              topicMasks,
              topicsById,
              topicMasksById,
            );
            if (topics.find(topic => topic.id === topicId)) {
              delete hiddenHeadings[clause.reference];
            }
          }

          _.forEach(documentClauses, clauses =>
            clauses.forEach(clause => {
              unhideClause(clause);
            }),
          );
        }
      });
      this.nextVisibilityState.heading = {};
      return {
        selectedTopics,
        hiddenHeadings,
        scrollCorrection,
        headingVisibility: {},
      };
    });
  };
  topicHoverStart = topicIds => {
    const {topicsById} = this.props;
    const sortedTopicIds = _.sortBy(topicIds, topic => topicsById[topic].name);
    this.setState({hoveredTopicIds: sortedTopicIds});
  };
  topicHoverEnd = (/* topicId */) => {
    this.setState({hoveredTopicIds: null});
  };
  onToggleClauseHeadingOpen = reference => {
    if (this.state.hiddenHeadings[reference]) {
      this.openClause(reference);
    } else {
      this.closeClause(reference);
    }
  };
  openClause = reference => {
    const hiddenHeadings = {...this.state.hiddenHeadings};
    delete hiddenHeadings[reference];
    if (reference.indexOf(".")) {
      delete hiddenHeadings[reference.substring(0, reference.indexOf("."))];
    }
    this.setState({hiddenHeadings});
  };
  closeClause = reference => {
    const hiddenHeadings = {...this.state.hiddenHeadings};
    hiddenHeadings[reference] = true;
    this.setState({hiddenHeadings});
  };
  expandClauseHeadings = () => {
    this.setState({hiddenHeadings: {}});
  };
  collapseClauseHeadings = () => {
    this.setState({
      hiddenHeadings: _.object(
        this.props.documentHeadings.map(heading => [heading.reference, true]),
      ),
    });
  };
  toggleFilterClauses = () => {
    this.setState({
      filterClauses: !this.state.filterClauses,
    });
  };
  updateHeadingVisibility = _.memoize(viewIndex => reference =>
    _.throttle(
      (isVisible, offset) =>
        this.updateVisibility(
          "heading",
          reference,
          viewIndex,
          isVisible,
          offset,
        ),
      900,
    ),
  );

  updateVisibility(type, id, viewIndex, isVisible, offset) {
    const key = `${type}Visibility`;
    const oldVisibility = this.nextVisibilityState[type] || {};
    if (
      !oldVisibility[id] ||
      !oldVisibility[id][viewIndex] ||
      oldVisibility[id][viewIndex].isVisible !== isVisible ||
      (isVisible && oldVisibility[id][viewIndex].offset !== offset)
    ) {
      const visibility = {
        ...oldVisibility,
        [id]: {
          ...(oldVisibility[id] ? oldVisibility[id] : {}),
          [viewIndex]: {
            isVisible,
            offset,
          },
        },
      };
      this.nextVisibilityState[type] = visibility;
      if (this.nextVisibilityTimeout[type]) {
        clearTimeout(this.nextVisibilityTimeout[type]);
      }
      this.nextVisibilityTimeout[type] = setTimeout(() => {
        this.setState({[key]: this.nextVisibilityState[type]});
      }, 100);
    }
  }

  onHeadingSelected = this.createItemClicker(
    "selectedHeading",
    heading => heading.fullReference,
    heading => {
      return this.openClause(heading.reference);
    },
  );
  onIssueSelected = this.createItemClicker(
    "selectedIssue",
    issue => calculateIssueId(issue),
    (issue, isSelected) => this.openRelatedTopics(issue, isSelected),
  );
  unselectIssue = () => {
    this.setState(() => ({
      selectedIssue: "",
      selectedTopics: {},
    }));
  };
  scrollToParties = () => {
    scrollIntoView(document.getElementById("parties-section"));
  };
  scrollToClause = (clause, clauseEl) => {
    const {isDocumentLong} = this.props;
    const scroller = clauseEl
      ? clauseEl
      : document.getElementById(`clause-atom-${clause.id}`);
    let top = 0.3;
    const emailPanel = document.getElementById("top-panel");
    if (emailPanel) {
      const viewPanel = document.querySelector(".view-scroller-holder");
      top = emailPanel.offsetHeight / viewPanel.offsetHeight + top;
    }
    if (this.state.isReportShownInRightSide) {
      top -= 0.5;
    }
    scrollIntoView(scroller, {
      time: SCROLL_DELAY_TIME,
      align: {
        top,
        topOffset: 0,
      },
    });
    if (isDocumentLong) {
      selectNodeText(scroller);
    } else {
      this.setState(() => ({clauseToHighlight: clause}));
    }
  };
  scrollToHeading = heading => {
    const scroller = document.getElementById(
      `clause-heading-${heading.reference}-${heading.text}`,
    );
    let top = 0.3;
    const emailPanel = document.getElementById("top-panel");
    if (emailPanel) {
      const viewPanel = document.querySelector(".view-scroller-holder");
      top = emailPanel.offsetHeight / viewPanel.offsetHeight + top;
    }
    scrollIntoView(scroller, {
      time: SCROLL_DELAY_TIME,
      align: {
        top,
        topOffset: 0,
      },
    });
  };
  scrollToSection = section => {
    const scroller = document.getElementById(`document-section-${section.id}`);
    let topOffset = 120;
    const emailPanel = document.getElementById("top-panel");
    if (emailPanel) {
      topOffset = emailPanel.offsetHeight + topOffset;
    }
    scrollIntoView(scroller, {
      time: SCROLL_DELAY_TIME,
      align: {
        top: 0,
        topOffset,
      },
    });
  };
  scrollToImage = pageNumber => {
    if (!pageNumber) {
      return;
    }
    const scroller = document.getElementById(`page-${pageNumber}`);
    scrollIntoView(scroller, {
      time: SCROLL_DELAY_TIME,
      align: {
        top: 0,
        topOffset: 0,
      },
    });
  };
  scrollToRagReportItem = documentIssueId => {
    const scroller = document.getElementById(
      `rag-report-item-${documentIssueId}`,
    );
    if (scroller) {
      scrollIntoView(scroller, {
        time: SCROLL_DELAY_TIME,
        align: {
          left: 0,
          top: 0,
          topOffset: 48,
        },
      });
    }
  };

  createItemClicker(stateName, findId, preFn) {
    return _.memoize(
      item => () => {
        const newState = {...defaultState};
        const id = findId(item);
        newState[stateName] = this.state[stateName] === id ? "" : id;
        if (preFn) {
          preFn(item, Boolean(newState[stateName]));
        }
        this.setState(newState);
      },
      findId,
    );
  }

  openRelatedTopics(issue, isSelected) {
    const {topicsById} = this.props;
    this.setState(() => ({selectedTopics: {}}));
    if (isSelected) {
      const {topicMasks} = this.props;
      const topicIds = _.chain(
        this.getSimilarTopics(issue.referenced_topics, topicsById),
      )
        .filter(topicId => topicsById[topicId])
        .map(topicId => {
          const mask = topicMasks.find(topicMask =>
            topicMask.topic_ids.find(
              containedTopicId => containedTopicId === topicId,
            ),
          );
          if (mask) {
            return `m_${mask.id}`;
          }
          return topicId;
        })
        .uniq()
        .value();
      this.toggleTopicSelection(topicIds);
    }
  }

  getSimilarTopics = (referencedTopics, topicsById) => {
    const referencedTopicPaths = referencedTopics
      .filter(topicId => topicsById[topicId])
      .map(topicId => {
        const topicPath = topicsById[topicId].name.split("/");
        if (topicPath.length > 1) {
          topicPath.pop();
        }
        return topicPath.join("/");
      });
    const documentTopicIds = this.documentTopics.map(topic => topic.id);

    const similarTopics = [];
    _.forEach(topicsById, (topic, topicId) => {
      referencedTopicPaths.forEach(referenceTopicPath => {
        if (
          topic.name.includes(referenceTopicPath) &&
          documentTopicIds.includes(parseInt(topicId, 10))
        ) {
          similarTopics.push(parseInt(topicId, 10));
        }
      });
    });
    return similarTopics;
  };

  getFullReferencesList = _.memoize(visibilityObject => {
    if (Object.keys(visibilityObject).length === 0) {
      return null;
    }
    return _.sortBy(Object.keys(visibilityObject), item => item);
  });

  updateDocument = async data => {
    const updatedDocument = await this.props.updateDocument(data);
    if (data.partiesChanges) {
      this.setState({rolesUpdating: updatedDocument.last_edited});
      setTimeout(() => this.refetchIssues(), 2000);
    }
  };

  refetchIssues = async () => {
    if (this.state.rolesUpdating) {
      const newIssues = await this.props.refreshDocumentIssues(
        this.state.rolesUpdating,
      );
      if (newIssues) {
        // Also refresh document (to reflect changes to clauses)
        await this.props.onDocumentFetch();
        this.setState({rolesUpdating: false});
      } else {
        setTimeout(() => this.refetchIssues(true), 2000);
      }
    }
  };

  updateDocumentIssue = async (issue, updates, _options = {}) => {
    const dataToUpdate = {};
    let options = _options;
    const currentIssuesetItem = getCurrentIssuesetItem(
      this.props.currentIssueset,
      this.props.document,
      this.props.contractTypesById,
    );

    if (currentIssuesetItem) {
      createIssuesetOverrideData(currentIssuesetItem, options, issue, updates);
    }
    let result;

    if (issue.underlyingIssues && issue.underlyingIssues.length > 0) {
      // used when in Checklist we collapse several issues into one by bracket naming
      const issuesToUpdate = issue.underlyingIssues.map(issue => ({
        id: issue.document_issue_id,
        last_edited: issue.last_edited,
      }));
      options = {
        ...options,
        underlyingIssuesUpdate: true,
      };
      result = await this.props.updateDocumentIssues(
        issuesToUpdate,
        updates,
        options,
      );
      dataToUpdate.issues = result.value.issues;
      dataToUpdate.updates = result.value.updates;
    } else {
      result = await this.props.updateDocumentIssue(issue, updates, options);
      dataToUpdate.issues = {
        [result.value.document_issue_id]: {
          id: result.value.issue_id,
          last_edited: result.value.last_edited,
        },
      };
      dataToUpdate.updates = result.value.updates;
    }
    const {panelData} = this.state;
    if (
      panelData &&
      panelData.type === "issue_detail" &&
      panelData.data.issue.id === issue.id
    ) {
      let updatedIssue = {
        ...cloneDeep(issue),
        ...dataToUpdate.updates,
        last_edited: dataToUpdate.issues[issue.document_issue_id].last_edited,
      };
      if (issue.underlyingIssues && issue.underlyingIssues.length > 0) {
        updatedIssue = {
          ...updatedIssue,
          underlyingIssues: updatedIssue.underlyingIssues.map(uIssue => ({
            ...uIssue,
            ...dataToUpdate.updates,
            last_edited:
              dataToUpdate.issues[uIssue.document_issue_id].last_edited,
          })),
        };
      }
      this.setState(prevState => {
        return {
          panelData: {
            ...prevState.panelData,
            data: {
              ...prevState.panelData.data,
              issue: updatedIssue,
            },
          },
        };
      });
    }
    return result;
  };

  updateDocumentMetaDataFeedback = (name, updates) => {
    const data = {
      meta_data_feedback: {
        name,
        updates,
      },
    };
    this.props.updateDocument(data);
  };

  getFirstClauseAtom = (node, definitionsByRef) => {
    if (node && node.type) {
      const type = node.type.slice(-4);
      switch (type) {
        case "Atom":
          return node;
        case "Node":
        case "List": {
          const atomNode = node.clauseNodes.find(
            clauseNode =>
              (clauseNode.text || !clauseNode.type.endsWith("Atom")) &&
              !definitionsByRef[clauseNode.clauseId],
          );
          return this.getFirstClauseAtom(atomNode, definitionsByRef);
        }
      }
    }
  };

  setEditableClausepart = (clauseId, clausepart, caretOffset) => {
    if (this.canEditDocument()) {
      this.setState(() => ({
        editableClausepart: {
          ...clausepart,
          documentClauseId: clauseId,
          caretOffset,
        },
        newClausepartData: null,
        editableClauseId: clauseId,
        editModeOn: true,
        addClausesToReportDocumentIssueId: null,
        prevStateAdditionalReportClausesState: this.state
          .additionalReportClausesState,
        additionalReportClausesState: null,
      }));
    }
  };

  setDefinitionBeingAdded = (clauseId, path) => {
    if (this.props.hasEditDocumentTextPermission) {
      this.setState({
        addingDefinition: {
          clauseId,
          path,
        },
      });
    }
  };
  unsetDefinitionBeingAdded = () =>
    this.setState(() => ({addingDefinition: null}));

  setEditableClauseHeading = heading => {
    if (this.canEditDocument()) {
      this.setState(() => ({
        editableClauseHeading: {...heading},
        editModeOn: true,
        addClausesToReportDocumentIssueId: null,
        prevStateAdditionalReportClausesState: this.state
          .additionalReportClausesState,
      }));
    }
  };

  unsetEditableClauseHeading = () =>
    this.setState(() => ({editableClauseHeading: null}));

  improvedClearPanelData = () =>
    this.state.panelData && this.state.panelData.type === "clause_detail"
      ? this.clearPanelData()
      : null;

  dismissDialog = () => this.setState(() => ({shownDialogueName: ""}));

  showDialog = _.memoize(
    shownDialogueName => () => this.setState(() => ({shownDialogueName})),
    (...argv) => JSON.stringify(argv),
  );

  alterDocumentClausepart = _.memoize(
    (sectionId, clauseId) => async (clausepart, text) => {
      const {props, state} = this;
      if (state.newClausepartData === null) {
        await props.alterDocumentClausepart(
          sectionId,
          clauseId,
          clausepart,
          text,
        );
      }
    },
    (...argv) => JSON.stringify(argv),
  );

  showAddClausepartEditor = (clauseId, path, oldPath, oldContents) => {
    if (this.props.hasEditDocumentTextPermission) {
      this.setState({
        // oldPath and oldContents is used for footer gui editor, please see node.js and list.js files
        newClausepartData: {
          path,
          clauseId,
          oldPath,
          oldContents,
        },
        editableClausepart: null,
        editableClauseId: clauseId,
      });
    }
  };

  hideClausepartEditor = () => {
    this.setState(() => ({
      editableClausepart: null,
    }));
  };

  updateDocumentIssuesets = issuesets => {
    this.setState(
      () => ({shownDialogueName: ""}),
      () => this.props.updateDocument({issuesets}),
    );
  };

  saveNewClausepart = _.memoize(
    (sectionId, clauseId) => async contents => {
      const clause = this.props.documentClauses[sectionId].find(
        c => c.id === clauseId,
      );
      const {newClausepartData} = this.state;
      if (contents && newClausepartData) {
        await this.props.addDocumentClausepart(
          clause.section_id,
          clause.id,
          newClausepartData.path,
          contents,
          clause.last_edited,
        );
      }
      await this.setState({newClausepartData: null});
    },
    (...argv) => JSON.stringify(argv),
  );

  deleteClauseparts = (clause, path, clausepartsToDelete, heading) => {
    const finishHandler = () =>
      this.props.deleteDocumentClauseparts(
        clause.section_id,
        clause.id,
        path,
        clause.last_edited,
        clausepartsToDelete,
        heading,
      );
    if (this.state.editableClausepart) {
      return this.setState(() => ({editableClausepart: null}), finishHandler);
    }
    return finishHandler();
  };

  showAddClauseEditor = _.memoize(
    (sectionId, clauseId, position) => () => {
      const clause = this.props.documentClauses[sectionId].find(
        c => c.id === clauseId,
      );
      if (this.props.hasEditDocumentTextPermission) {
        if (this.props.document.clauses_are_textual) {
          this.setState(() => ({
            shownDialogueName: "clauses_are_textual",
          }));
        }
        // The reason for the async call is to prevent blur event on clause text field
        setTimeout(
          () =>
            this.setState({
              additionClause: {
                clause,
                position,
              },
              editableClauseId: null,
              editableClausepart: null,
            }),
          0,
        );
      }
    },
    (...argv) => JSON.stringify(argv),
  );

  hideAddClauseEditor = () => this.setState({additionClause: null});

  hideClauseEditor = () =>
    this.setState({
      editableClauseId: null,
      editableClausepart: null,
    });

  onFindAndReplaceChange = _findAndReplace => {
    let findAndReplace = _findAndReplace;
    if (!Object.keys(_findAndReplace).includes("find")) {
      findAndReplace = {
        find: "",
        replace: "",
      };
    }
    this.setState(() => ({findAndReplace}));
  };

  documentTextReplace = () => {
    const {find, replace} = this.state.findAndReplace;
    if (!find) {
      return;
    }
    return this.props.documentTextReplace(find, replace);
  };

  setEditModeOn = editModeOn => {
    if (editModeOn) {
      this.setState({
        prevStateAdditionalReportClausesState: this.state
          .additionalReportClausesState,
      });
    } else {
      this.setState({
        additionalReportClausesState: this.state
          .prevStateAdditionalReportClausesState,
      });
    }

    if (this.state.editModeOn === editModeOn || !this.canEditDocument()) {
      return;
    }
    this.setState(prevState => ({
      editModeOn,
      addClausesToReportDocumentIssueId: editModeOn
        ? null
        : prevState.addClausesToReportDocumentIssueId,
    }));
  };

  canEditDocument() {
    return (
      this.props.document.plain_text_length < 100000 &&
      this.props.hasEditDocumentTextPermission &&
      (this.props.document.file_extension !== "pdf" ||
        this.props.hasEditPdfDocumentTextPermission)
    );
  }

  setEditingReport = isEditingReport => {
    this.setState({editingReport: isEditingReport});
  };

  setAddClausesToReportDocumentIssueId = documentIssueId => {
    if (this.state.addClausesToReportDocumentIssueId === documentIssueId) {
      return;
    }

    if (
      this.state.panelData &&
      this.state.panelData.type === "issue_detail" &&
      this.state.selectedReportId
    ) {
      if (!documentIssueId) {
        return this.setState(() => ({
          addClausesToReportDocumentIssueId: documentIssueId,
        }));
      }
      const updates = {
        addClausesToReportDocumentIssueId: documentIssueId,
        editModeOn: false,
      };

      if (!this.state.additionalReportClausesState) {
        const issueManualCorrections = this.state.panelData.data.issue
          .manual_corrections;
        const additionalReportClauseState =
          issueManualCorrections?.[this.state.selectedReportId]
            ?.additional_report_clauses;
        if (additionalReportClauseState) {
          updates.additionalReportClausesState = additionalReportClauseState;
        }
      }
      this.setState(() => updates);
    }
  };

  triggerHideTextDeletions = () => {
    this.setState(prevState => ({
      hideTextDeletions: !prevState.hideTextDeletions,
    }));
  };

  switchClientModeOn = () => this.switchClientMode(!this.state.clientModeOn);

  switchClientMode = newValue => {
    this.setState(() => ({
      clientModeOn: newValue,
    }));

    localStorage.setItem(
      "clientModeOn",
      JSON.stringify({
        value: newValue,
        time: Date.now(),
        organisationId: this.props.organisation.id,
        documentId: this.props.document.id,
      }),
    );

    if (!newValue || (newValue && !this.state.areIssuesCollapsed)) {
      localStorage.setItem("areIssuesCollapsed", true);
      this.setState(() => ({areIssuesCollapsed: true}));
    }
  };

  onZoomUpdate = zoom => {
    this.setState(() => ({context: {zoom}}));
    localStorage.setItem("documentDetailZoom", JSON.stringify(zoom));
  };

  switchEditPlaybookMode = () => {
    const newValue = !this.state.editPlaybookMode;
    sessionStorage.setItem("editPlaybookMode", newValue);
    this.setState(() => ({
      editPlaybookMode: newValue,
    }));
  };

  togglePlaybookState = issue => {
    const playbookState = this.state.playbookState || {};
    playbookState[issue.id] =
      playbookState[issue.id] === undefined ? false : !playbookState[issue.id];
    this.setState(() => ({playbookState}));
  };

  toggleChecklistLocation = () => {
    const isChecklistLocationToggled = !this.state.isChecklistLocationToggled;
    localStorage.setItem(
      "isChecklistLocationToggled",
      isChecklistLocationToggled,
    );
    this.setState({isChecklistLocationToggled});
  };

  toggleShowTwoPanes = () => {
    const showTwoPanes = !this.state.showTwoPanes;
    localStorage.setItem("showTwoPanes", showTwoPanes);
    this.setState({showTwoPanes});
  };

  onAddClauseToReport = clausepartId => {
    let clausepart = clausepartId;
    const applicableClausesValues = [];
    const {additionalReportClausesState, panelData} = this.state;
    _.chain(Object.values(panelData.data.applicableClauses))
      .flatten()
      .uniq(clause => clause.id)
      .value()
      .forEach(item => {
        if (item.id) {
          applicableClausesValues.push(item.id);
        }
      });

    if (Array.isArray(clausepartId)) {
      clausepart = clausepartId.filter(
        item => !applicableClausesValues.find(clause => clause === item),
      );
    }

    if (!applicableClausesValues.includes(clausepart)) {
      let newAdditionalReportClausesState = [];
      if (Array.isArray(clausepart)) {
        this.setState(() => ({
          additionalReportClausesState: _.uniq([
            ...clausepart,
            ...additionalReportClausesState,
          ]),
        }));
      } else {
        if (
          (additionalReportClausesState || []).find(cpId => cpId === clausepart)
        ) {
          newAdditionalReportClausesState = additionalReportClausesState.filter(
            cpId => cpId !== clausepart,
          );
        } else {
          newAdditionalReportClausesState = [
            ...additionalReportClausesState,
            clausepart,
          ];
        }
        this.setState(() => ({
          additionalReportClausesState: newAdditionalReportClausesState,
        }));
      }
    }
  };

  setSelectedClauses = clauses => {
    this.setState({selectedClauses: clauses});
  };

  onRemoveAdditionalClauseFromReport = clausepartId => {
    if (
      this.state.panelData &&
      this.state.panelData.type === "issue_detail" &&
      this.state.selectedReportId
    ) {
      let additionalReportClausesState = this.state
        .additionalReportClausesState;
      if (!additionalReportClausesState) {
        const issueManualCorrections = this.state.panelData.data.issue
          .manual_corrections;
        additionalReportClausesState =
          issueManualCorrections?.[this.state.selectedReportId]
            ?.additional_report_clauses || [];
      }
      const newAdditionalReportClausesState = additionalReportClausesState.filter(
        cpId => cpId !== clausepartId,
      );
      this.setState(() => ({
        additionalReportClausesState: newAdditionalReportClausesState,
      }));
    }
  };

  toggleReportPanelVisibility = () =>
    this.setState(prevState => ({
      isReportPanelShown: !prevState.isReportPanelShown,
    }));

  toggleReportOpenInRightSidebar = () =>
    this.setState(prevState => ({
      isReportShownInRightSide: !prevState.isReportShownInRightSide,
    }));

  onSelectedReportChange = selectedReportId => {
    this.setState(() =>
      getSelectedReportStateValues(this.props.project, selectedReportId),
    );
  };

  search = (queryValue, searchOptions) => {
    this.setState(() => ({searchValue: queryValue}));
    const {currentIssueset} = this.state;
    let issuesetPath = "base";
    if (currentIssueset && currentIssueset !== this.defaultIssuesetId) {
      const issuesetsById = issuesetUtils.getIssuesetsById(
        this.props.contractTypesById,
      );
      const selectedIssueset = issuesetsById[currentIssueset];
      issuesetPath = `${selectedIssueset.master_id}.${
        selectedIssueset.remote_client_id
          ? selectedIssueset.remote_client_id
          : "master"
      }`;
    }
    const newSearchOptions = {
      ...(searchOptions ? searchOptions : {}),
      issuesetPath,
    };
    return this.props.search(queryValue, newSearchOptions);
  };

  switchViewDocx = () => {
    const newValue = !this.state.viewDocx;
    this.setState(
      () => ({viewDocx: newValue}),
      () => sessionStorage.setItem("viewDocx", newValue),
    );
  };

  showKickOffWorkflowDialog = () => {
    this.setState(() => ({shouldShowKickOffWorkflowDialog: true}));
  };

  hideKickOffWorkflowDialog = () =>
    this.setState(() => ({shouldShowKickOffWorkflowDialog: false}));

  showLinkedDefinitions = () =>
    this.setState(() => ({shouldShowLinkedDefinitions: true}));

  hideLinkedDefinitions = () =>
    this.setState(() => ({shouldShowLinkedDefinitions: false}));

  updateComparisonDocumentId = comparisonDocumentId => {
    this.props.updateDocument({
      comparison_document_id: comparisonDocumentId,
    });
  };

  showComparisonDetail = issue => {
    this.setState(() => ({
      panelData: {
        type: "comparison_detail",
        data: issue,
      },
    }));
  };

  toggleDocumentLedReview = () =>
    this.setState(({documentLedReview}) => ({
      documentLedReview: !documentLedReview,
      ...this.getResetClausesToRenderState(),
    }));

  addIssueToReport = async (issue, reportId) => {
    const result = await this.updateDocumentIssueActionState(
      issue,
      reportId,
      1,
    );
    const that = this;
    this.setState(
      () => ({responseAddedToReport: true}),
      () => {
        setTimeout(
          () => that.setState(() => ({responseAddedToReport: false})),
          2000,
        );
      },
    );
    return result;
  };
  removeIssueFromReport = (issue, reportId) =>
    this.updateDocumentIssueActionState(issue, reportId, 0);
  revertIssueActionState = (issue, reportId) =>
    this.updateDocumentIssueActionState(issue, reportId, null);
  updateDocumentIssueActionState = (issue, reportId, newValue) => {
    const options = {merge_update_object: true};
    const currentIssuesetItem = getCurrentIssuesetItem(
      this.state.currentIssueset,
      this.props.document,
      this.props.contractTypesById,
    );

    if (currentIssuesetItem) {
      createIssuesetOverrideData(currentIssuesetItem, options);
    }

    return this.updateDocumentIssue(
      issue,
      {action_state: {...issue.action_state, [reportId]: newValue}},
      options,
    );
  };

  revertAllIssues = async _revertOnlyChanges => {
    const revertOnlyChanges =
      typeof _revertOnlyChanges === "boolean" ? _revertOnlyChanges : false;
    const {
      params: {organisationId, projectId, documentId},
    } = this.props;
    const {
      documentIssues: {issues},
      documentChanges: changes,
    } = this.props;
    const {last_edited: lastEdited} = this.props.document;
    if (changes || issues.length > 0) {
      const result = await this.props.revertAllIssues(
        organisationId,
        projectId,
        documentId,
        revertOnlyChanges,
        lastEdited,
      );
      if (!revertOnlyChanges) {
        const newLastEdited = result.value.document_last_edited;
        const issuesToChange = issues.reduce((result, issue) => {
          if (
            issue.current_state !== null ||
            issue.user_verified !== null ||
            issue.manual_corrections !== null ||
            issue.resolved_state !== null
          ) {
            result.push({
              id: issue.document_issue_id,
              last_edited: newLastEdited,
            });
          }
          return result;
        }, []);
        await this.updateDocumentIssues(issuesToChange, {
          current_state: null,
          user_verified: null,
          should_be_issue: null,
          manual_corrections: {},
          action_state: {},
          resolved_state: null,
        });
      }
    }
  };

  reprocessDocumentRoles = () => {
    this.props.reprocessDocumentRoles(false);
  };
  resetDocumentRoles = () => {
    this.props.reprocessDocumentRoles(true);
  };

  onSelectedChecklistSectionIdChange = (
    event,
    index,
    selectedChecklistSectionId,
  ) => {
    this.props.setSelectedChecklistSectionId(selectedChecklistSectionId);
    if (selectedChecklistSectionId) {
      this.scrollToSection({id: selectedChecklistSectionId});
    }
  };
  isShowReportTabHandler = value => this.setState({isReportTabShow: value});
}

const filterDocumentIssuesByIssueset = _.memoize(
  (issues, currentIssueset) => {
    return issues.filter(issue => {
      const issuesetIds = issuesetUtils.getIssuesetIdsPresentInContractTypes(
        issue.contract_types,
      );
      return issuesetIds.includes(currentIssueset);
    });
  },
  issues => JSON.stringify(issues.map(issue => [issue.id, issue.last_edited])),
);

export async function correctDocumentIssueManually(
  issue,
  updates,
  options = {},
) {
  const currentIssuesetItem = getCurrentIssuesetItem(
    this.props.currentIssueset,
    this.props.document,
    this.props.contractTypesById,
  );
  const dataPath = currentIssuesetItem
    ? `${currentIssuesetItem.master_id}.${
        currentIssuesetItem.remote_client_id
          ? currentIssuesetItem.remote_client_id
          : "master"
      }`
    : "";

  if (options.resetSelectedApplicableClauses) {
    this.setState({additionalReportClausesState: null});
  }

  if (options && options.merge_update_object) {
    options.update_override_document_issue_values = true;
    options.data_path = dataPath;
  } else if (dataPath) {
    updates.data_path = dataPath;
  }

  await this.props.correctDocumentIssueManually(issue, updates, options);
}

import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import _ from "lodash";

import Header from "./header";
import UserNav from "./user_nav";
import AdminNav from "./admin_nav";
import UserInfo from "./user_info";

import "./styles.css";

import SidebarContext from "./context";
import SidebarPanel from "./panel";

import {hasPermission} from "utils/permissioner";

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: !props.isCollapsedLight,
      isPanelShown: false,
      panelNavItem: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isCollapsedLight !== this.props.isCollapsedLight) {
      this.setState({isExpanded: !this.props.isCollapsedLight});
    }
    if (
      this.state.isPanelShown &&
      (prevProps.location !== this.props.location ||
        (prevState.isExpanded !== this.state.isExpanded &&
          this.state.isExpanded &&
          this.props.isCollapsedLight))
    ) {
      this.hidePanel();
    }
  }

  expand = () => {
    this.setState({isExpanded: true});
  };

  collapse = () => {
    this.setState({isExpanded: false});
  };

  toggleExpandability = () => {
    if (this.state.isExpanded) {
      this.collapse();
    } else {
      this.expand();
    }
  };

  showPanel = navItem => {
    this.setState({
      isPanelShown: true,
      panelNavItem: navItem,
    });
  };

  hidePanel = () => {
    this.setState({
      isPanelShown: false,
      panelNavItem: null,
    });
  };

  isPanelNavItemActive = navItem => {
    return (
      this.state.isPanelShown &&
      this.state.panelNavItem &&
      this.state.panelNavItem === navItem
    );
  };

  render() {
    const {isExpanded} = this.state;
    const isLight = !isExpanded && this.props.isCollapsedLight;
    const isOverlaid = isExpanded && this.props.isCollapsedLight;
    const isCentralAdmin = this.props.user.organisations.find(
      item => item.id === this.props.organisationId,
    ).is_central_admin;
    const isDeveloperInProduction =
      this.props.user.is_developer && this.props.environment === "production";
    const highlightClientMenus =
      Boolean(hasPermission("highlight-client-menus", this.props.user)) &&
      !this.props.location.pathname.endsWith("project/list") &&
      !isCentralAdmin &&
      !(
        this.props.location.pathname.endsWith("/detail") &&
        this.props.clientModeOn
      );

    return ReactDOM.createPortal(
      <SidebarContext.Provider
        value={{
          isExpanded,
          isLight,
          toggleExpandability: () => this.toggleExpandability(),
          isPanelShown: this.state.isPanelShown,
          showPanel: this.showPanel,
          hidePanel: this.hidePanel,
          isPanelNavItemActive: this.isPanelNavItemActive,
        }}
      >
        <div
          ref={node => (this.sidebarNode = node)}
          className={(() => {
            const classNames = [];
            const className = "app-sidebar";
            classNames.push(className);
            if (isExpanded) {
              classNames.push(`${className}--expanded`);
            }
            if (isLight) {
              classNames.push(`${className}--light`);
            }
            if (isOverlaid) {
              classNames.push(`${className}--overlaid`);
            }
            return classNames.join(" ");
          })()}
        >
          <div
            className="app-sidebar__inner"
            style={
              isDeveloperInProduction || highlightClientMenus
                ? {background: "#b11f15"}
                : {}
            }
          >
            {this.renderHeader()}
            {this.renderBody()}
            {this.renderFooter()}
          </div>
        </div>
        {this.renderPanel()}
        {this.renderOverlay()}
      </SidebarContext.Provider>,
      document.querySelector(".app-sidebar-container"),
    );
  }

  renderHeader() {
    return (
      <Header user={this.props.user} environment={this.props.environment} />
    );
  }

  renderBody() {
    return (
      <div className="app-sidebar__body">
        <UserNav
          navItems={this.props.userNavItems}
          projectId={this.props.projectId}
          collapsedUserNav={this.props.collapsedUserNav}
        />
        {this.props.user.is_admin ||
        parseInt(this.props.organisationId, 10) === 2 ||
        Boolean(hasPermission("can-view-admin-menu", this.props.user)) ? (
          <AdminNav
            navItems={this.props.adminNavItems}
            organisationId={this.props.organisationId}
            projectId={this.props.projectId}
            routePath={this.props.routePath}
            user={this.props.user}
            getOrganisationUrl={this.props.getOrganisationUrl}
            changeOrganisation={this.props.changeOrganisation}
          />
        ) : null}
      </div>
    );
  }

  renderFooter() {
    return (
      <UserInfo
        user={this.props.user}
        onUserPasswordUpdate={this.props.onUserPasswordUpdate}
        onLogout={this.props.onLogout}
        onUserUpdate={this.props.onUserUpdate}
      />
    );
  }

  renderPanel() {
    const panelChildren = _.get(this.state.panelNavItem, "props.panelChildren");
    if (!this.state.isPanelShown || panelChildren.type !== SidebarPanel) {
      return null;
    }
    return panelChildren;
  }

  renderOverlay() {
    if (!this.state.isExpanded || !this.props.isCollapsedLight) {
      return null;
    }
    return <div className="app-sidebar__overlay" onClick={this.collapse} />;
  }
}

Sidebar.propTypes = {
  isCollapsedLight: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  routePath: PropTypes.string.isRequired,
  userNavItems: PropTypes.array.isRequired,
  adminNavItems: PropTypes.array.isRequired,
  onUserPasswordUpdate: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  getOrganisationUrl: PropTypes.func.isRequired,
  changeOrganisation: PropTypes.func.isRequired,
  collapsedUserNav: PropTypes.element,
};

import React from "react";
import _ from "lodash";

import VisibilityIcon from "@material-ui/icons/Visibility";

import NavItem from "routes/logged_in/components/sidebar/nav_item";

const items = [
  {text: "Front Page", value: "FORM"},
  {text: "Contract", value: "checklist"},
  {text: "Planning Certificate", value: "PLANNING_CERTIFICATE"},
  {text: "Title Search", value: "TITLE_SEARCH"},
];

export default class ViewModesItem extends React.Component {
  render() {
    return (
      <NavItem
        activeByNested={false}
        isCollapsed={true}
        icon={<VisibilityIcon />}
        collapsedTitle={this.getCollapsedTitle()}
        nestedChildren={
          <div className="app-sidebar-popover__header">
            {items.map((item, index) => {
              const isActive = item.value === this.props.viewMode;
              return (
                <NavItem
                  key={index}
                  isActive={isActive}
                  {...!isActive && {
                    onClick: () => this.props.onViewModeChange(item.value),
                  }}
                >
                  {item.text}
                </NavItem>
              );
            })}
          </div>
        }
      />
    );
  }

  getCollapsedTitle = () => {
    const item = items.find(_item => _item.value === this.props.viewMode);
    return `View Mode: ${item.text}`;
  };
}

ViewModesItem.Icon = VisibilityIcon;

ViewModesItem.passProps = props => {
  return _.pick(props, ["viewMode", "onViewModeChange"]);
};

function increaseReference(reference, incrementer = 1, isFixedSize = true) {
  if (!reference) {
    return reference;
  }
  const lastNumber = reference.match(new RegExp("\\d+$"));
  if (lastNumber) {
    const number = parseInt(lastNumber[0], 10);
    if (!isNaN(number)) {
      const prefix = reference.slice(0, -lastNumber[0].length);
      const newCode = `${number + incrementer}`;
      const zeroStringLength = lastNumber[0].length - newCode.length;
      const zeroAggregate =
        zeroStringLength > 0 && isFixedSize ? "0".repeat(zeroStringLength) : "";
      return `${prefix}${zeroAggregate}${newCode}`;
    }
  }
  return `${reference.slice(0, -1)}${String.fromCharCode(
    reference.charCodeAt(reference.length - 1) + incrementer,
  )}`;
}

export default increaseReference;

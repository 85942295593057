import _ from "underscore";

export default (text, changes = []) => {
  let shift = 0;
  return _.sortBy(changes, "old_start_offset").reduce((changedText, change) => {
    const result =
      changedText.substring(0, change.old_start_offset - shift) +
      change.new_text +
      changedText.substring(change.old_end_offset - shift);
    shift +=
      change.old_text && change.new_text
        ? change.old_text.length - change.new_text.length
        : 0;
    return result;
  }, text);
};

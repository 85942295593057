import Handlebars from "handlebars";
import _ from "underscore";

import registerHandlebarsHelpers from "common/utils/handlebars_helpers/register";
import getClauseAtomsByIssue from "common/utils/clauses/get_clause_atoms_by_issue";
import convertAtomsToApplicableClauses from "utils/clauses/applicable_clauses/convert_atoms_to_applicable_clauses";
import getOverriddenIssueField from "common/utils/issues/get_overridden_issue_field";
import calculateClauseReferences from "common/utils/issues/reason/calculate_clause_references";
import getIssueClauses from "common/utils/issues/reason/get_issue_clauses";

import byId from "common/utils/by_id";
import pad from "common/utils/pad";

registerHandlebarsHelpers();

function getEmailTextTemplate(_issue, props) {
  const clauseIssue = getIssueData(_issue, props);
  const {issue, clauseReferences, clauses} = clauseIssue;
  const reason =
    issue.reason && Array.isArray(issue.reason)
      ? issue.reason.map(_reason => _reason.reason)
      : issue.reason;

  const emailTemplate = getOverriddenIssueField(
    issue.email_template,
    issue.override_values,
    props.currentIssuesetItem,
    "email_template",
  );
  const template = Handlebars.compile(emailTemplate || "");
  const applicableClausesAtoms =
    props.applicableClauses && Array.isArray(props.applicableClauses)
      ? props.applicableClauses
      : convertAtomsToApplicableClauses(
          getClauseAtomsByIssue(
            issue,
            props.documentClauses,
            props.topicCategories,
            props.topicsById,
          ),
        );

  const applicableClauses = constructApplicableClausesString(
    applicableClausesAtoms,
    props.documentSections,
  );
  return template({
    issue: {...issue, reason},
    clause: clauses[0],
    clauses,
    clauseReferences,
    applicable_clauses: applicableClauses || "",
    reasonData: props.documentClauseparts,
  });
}

function getIssueData(issue, props) {
  const {documentClauses} = props;
  const clauses = getIssueClauses(issue, documentClauses);
  const clauseReferences = calculateClauseReferences(clauses);
  const sortReference =
    clauses.length > 0
      ? _.sortBy(clauses, clause => clause.clausepart_sort_reference)[0]
          .clausepart_sort_reference
      : "zzz";
  return {clauses, clauseReferences, sortReference, issue};
}

function constructApplicableClausesString(clauses, sections) {
  const sectionsById = byId(sections);
  const displayedSections = {};
  function showSection(section) {
    if (displayedSections[section.id]) {
      return false;
    }
    displayedSections[section.id] = true;
    return (
      section.reference !== "main" &&
      section.reference.indexOf("background") === -1 &&
      section.reference.indexOf("title_parties") === -1
    );
  }
  function cleanReference(reference) {
    return reference.replace(".meaning", "");
  }
  return _.chain(clauses)
    .filter(clause => clause.text && clause.text.length)
    .map(clause => ({
      ...clause,
      section: sectionsById[clause.clause_section_id],
    }))
    .sortBy(
      clause => `${pad(clause.section.order, 4)}_${pad(clause.reference, 4)}`,
    )
    .map(
      clause =>
        `*${
          showSection(clause.section)
            ? `${clause.section.name || clause.section.reference} - `
            : ""
        }${cleanReference(clause.reference)}.* ${clause.text}`,
    )
    .filter(clause => clause)
    .value()
    .join(" ");
}

export default getEmailTextTemplate;

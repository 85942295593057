import getDomain from "utils/get_domain";
const isStaging =
  document?.location?.host === "staging.lexicallabs.com" ||
  document?.location?.host === "uat-api.lexicallabs.com";
const host = isStaging
  ? `https://uat-api.${getDomain()}.com`
  : process.env.NODE_ENV === "production"
  ? `https://api.${getDomain()}.com`
  : "";
const path = `${host}/api/1.0`;

export default path;

import _ from "underscore";
import React from "react";

import TextField from "material-ui/TextField";
import ReactSelectLabel from "common_components/react_select_label";
import Select, {
  getTopicLink,
  getParameterLink,
} from "common_components/select_with_link";
import Checkbox from "material-ui/Checkbox";

import renderError from "utils/issues/editor/render_error";
import filterByWord from "utils/issues/editor/filter_by_word";

function initialise(state) {
  return {
    topic_id: null,
    parameter_id: null,
    permitted_types: ["unspecified"],
    range: {},
    ..._.pick(state, "topic_id", "parameter_id", "permitted_types", "range"),
  };
}

function validate(issue) {
  if (!(issue.rules.topic_id > 0)) {
    return {rulesError: {topic_id: "You must set a topic id"}};
  }
  if (!(issue.rules.parameter_id > 0)) {
    return {rulesError: {parameter_id: "You must set a parameter id"}};
  }
  if (
    !issue.rules.permitted_types ||
    !Array.isArray(issue.rules.permitted_types) ||
    issue.rules.permitted_types.length === 0
  ) {
    return {rulesError: {permitted_types: "You must set some permitted_types"}};
  }

  if (
    !issue.rules.range ||
    (!issue.rules.range.max && !issue.rules.range.min)
  ) {
    return {
      rulesError: {
        range: "You must set a maximum or a minimum",
      },
    };
  }

  {
    const {max, min} = issue.rules.range;
    if (max && min && max < min) {
      return {
        rulesError: {
          range: "The minimum must be less than the maximum",
        },
      };
    }
  }
  return null;
}

class Component extends React.Component {
  render() {
    const {
      editLevel,
      rules,
      rulesError,
      topics,
      isNonOverridableFieldsDisabled,
      overridableFieldsStyle,
      disabled,
      shouldTopicSelectBeHighlighted,
    } = this.props;
    const currentTopic = this.props.topicsById[rules.topic_id];
    return (
      <div>
        <div>
          <ReactSelectLabel>Topic</ReactSelectLabel>
          <Select
            className="topic"
            multi={false}
            value={rules.topic_id}
            options={topics
              .filter(topic =>
                topic.parameters.find(
                  parameter => parameter.parameter_type === "numeric",
                ),
              )
              .map(topic => ({
                label: topic.name,
                value: topic.id,
              }))}
            onChange={this.updateTopic}
            clearable={false}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getTopicLink(this.props.organisationId, rules.topic_id)}
            shouldTopicSelectBeHighlighted={shouldTopicSelectBeHighlighted}
          />
          {renderError(rulesError, "topic_id")}
        </div>
        <div>
          <ReactSelectLabel>Parameter</ReactSelectLabel>
          <Select
            className="parameter"
            multi={false}
            value={rules.parameter_id}
            options={
              currentTopic &&
              currentTopic.parameters
                .filter(parameter => parameter.parameter_type === "numeric")
                .map(parameter => ({
                  label: parameter.name,
                  value: parameter.id,
                }))
            }
            onChange={this.updateParameter}
            disabled={
              isNonOverridableFieldsDisabled ||
              disabled ||
              (editLevel && editLevel !== "base")
            }
            filterOption={filterByWord}
            link={getParameterLink(
              this.props.organisationId,
              rules.topic_id,
              rules.parameter_id,
            )}
          />
          {renderError(rulesError, "parameter_id")}
        </div>
        <div>
          <TextField
            type="number"
            className="min"
            value={rules.range && rules.range.min}
            onChange={this.updateMin}
            style={{width: "10em", ...overridableFieldsStyle}}
            name="min"
            floatingLabelText="Minimum amount"
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
          <TextField
            type="number"
            className="max"
            value={rules.range && rules.range.max}
            onChange={this.updateMax}
            style={{width: "10em", ...overridableFieldsStyle}}
            name="max"
            floatingLabelText="Maximum amount"
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
        </div>
        <div>
          <Checkbox
            label="Unspecified"
            className="unspecified"
            checked={
              rules.permitted_types &&
              rules.permitted_types.indexOf("unspecified") >= 0
            }
            onCheck={this.updateUnspecified}
            style={overridableFieldsStyle}
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
          <Checkbox
            label="Percentage"
            className="percentage"
            checked={
              rules.permitted_types &&
              rules.permitted_types.indexOf("percentage") >= 0
            }
            onCheck={this.updatePercentage}
            style={overridableFieldsStyle}
            disabled={isNonOverridableFieldsDisabled || disabled}
          />
        </div>
      </div>
    );
  }
  /* eslint-disable no-invalid-this */
  updateTopic = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      topic_id: value.value,
      parameter_id: null,
    });
  };
  updateParameter = value => {
    const {rules, onChange} = this.props;
    onChange({
      ...rules,
      parameter_id: value.value,
    });
  };
  updateMin = event => {
    const {editLevel, rules, onChange} = this.props;
    const value = event.target.value !== "" ? event.target.value : null;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      range: {
        ...rules.range,
        min: value,
      },
    });
  };
  updateMax = event => {
    const {rules, editLevel, onChange} = this.props;
    const value = event.target.value !== "" ? event.target.value : null;
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      range: {
        ...rules.range,
        max: value,
      },
    });
  };
  updateUnspecified = event => {
    this.updateCheckbox(event, "unspecified");
  };
  updatePercentage = event => {
    this.updateCheckbox(event, "percentage");
  };
  updateCheckbox(event, type) {
    const {editLevel, rules, onChange} = this.props;
    const {permitted_types: types} = rules;
    let newTypes;
    if (event.target.checked) {
      newTypes = types.indexOf(type) === -1 ? types.concat([type]) : types;
    } else {
      newTypes = types.filter(_type => _type !== type);
    }
    onChange({
      ...rules,
      ...(editLevel && editLevel !== "base" ? {isOverridden: true} : {}),
      permitted_types: newTypes,
    });
  }
  /* eslint-enable no-invalid-this */
}

export default {
  initialise,
  validate,
  component: Component,
};

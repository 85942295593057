export default function hasClauseReason(
  reason,
  clauseId,
  path = "",
  isFirst = false,
) {
  if (reason.reason) {
    if (Array.isArray(reason.reason)) {
      return reason.reason.find(_reason =>
        hasClauseReason(_reason, clauseId, path, isFirst),
      );
    }
    return hasClauseReason(reason.reason, clauseId, path, isFirst);
  }
  return Array.isArray(reason)
    ? reason.find(_reason => hasClauseReason(_reason, clauseId, path, isFirst))
    : (reason.clause_id === clauseId &&
        (!reason.path || reason.path.indexOf(path) === 0)) ||
        (!reason.clause_id && isFirst);
}

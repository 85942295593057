function makeObject(...args) {
  args.pop();
  const obj = {};
  for (let idx = 0; idx < args.length; idx += 2) {
    obj[args[idx]] = args[idx + 1];
  }
  return obj;
}

export default makeObject;

import _ from "underscore";
import getAcceptedTopicCategoryIds from "utils/topic/get_accepted_topic_category_ids";
import constructRelatedClausesDataForRelatedTopics from "utils/clauses/get_clause_atoms_data_by_topics";
import constructRelatedClausesDataForTopicGroups from "utils/clauses/related_clauses/get_clause_atoms_data_by_topic_groups";
import groupClausesByTopics from "utils/clauses/group_clauses_by_topics";
import isEmptyParent from "common/utils/issues/is_empty_parent";
import getIssueTemplatedText from "common/utils/issues/get_issue_templated_text";
import getIssueTriggeredSpecificValue from "common/utils/issues/get_issue_triggered_specific_value";
import getEmptyParentIssues from "common/utils/issues/get_empty_parent_issues";
import addIsSectionUnnumberedToClauses from "utils/issues/add_section_unnumbered_to_clauses";
import calculateApplicableClauses from "utils/issues/calculate_applicable_clauses";
import groupChecklistSections from "common/utils/document/group_checklist_sections";

function constructIssueDetailData(
  issue,
  issueset,
  documentClausesPerSection,
  topicCategories,
  documentSections,
  topicsById,
  positiveReasonData,
  reasonData, // documentClauseparts
  parties,
  selectedChecklistSectionId,
  preventMemo = false,
  additionalReportClauses,
  // removedClauses,- TODO - do we need this here?
) {
  let documentClauses = documentClausesPerSection;
  if (selectedChecklistSectionId) {
    const checklistSections = groupChecklistSections(documentSections) || [];
    const selectedChecklistSection = checklistSections.find(
      section => section.id === selectedChecklistSectionId,
    );
    if (
      selectedChecklistSection &&
      selectedChecklistSection.sections &&
      selectedChecklistSection.sections.length > 0
    ) {
      documentClauses = selectedChecklistSection.sections.reduce(
        (result, section) => {
          result[section.id] = documentClausesPerSection[section.id];
          return result;
        },
        {},
      );
    }
  }
  const reasons = issue.reason || [];
  const acceptedTopicCategoryIds = getAcceptedTopicCategoryIds(topicCategories);

  const {
    groupedApplicableClauses,
    applicableClausesReferenced,
    applicableClausesRaw,
  } = calculateApplicableClauses(
    issue,
    issueset,
    documentClauses,
    acceptedTopicCategoryIds,
    documentSections,
    topicsById,
    positiveReasonData,
    reasonData,
    parties,
    preventMemo,
    additionalReportClauses,
  );

  // Getting related clauses
  const relatedClausesIssues = isEmptyParent(issue)
    ? getEmptyParentIssues(issue, "related_clauses")
    : [issue];
  let relatedClauses = _.chain(relatedClausesIssues)
    .reduce((accum, issue) => {
      const issueRelatedClauses = getIssueRelatedClauses(
        issue,
        topicsById,
        documentClauses,
        acceptedTopicCategoryIds,
        applicableClausesRaw,
      );
      return accum.concat(issueRelatedClauses);
    }, [])
    .uniq(clause => clause.id)
    .value();

  // add is_section_unnumbered to related clauses
  relatedClauses = addIsSectionUnnumberedToClauses(
    relatedClauses,
    documentSections,
    documentClauses,
  );
  const relatedClausesByTopics = groupClausesByTopics(relatedClauses);
  const distinctRelatedClauses = _.chain(relatedClauses || [])
    .map(clause => clause.clause_id)
    .uniq()
    .value();
  const reason =
    !reasons || reasons.length === 0
      ? null
      : {
          text: getIssueTemplatedText(
            issue,
            issueset,
            "reason_template",
            documentClauses,
            topicsById,
            positiveReasonData,
            reasonData,
            parties,
            false,
            false,
            preventMemo,
          )[0],
        };
  return {
    issue,
    reasons,
    applicableClauses: groupedApplicableClauses,
    applicableClausesReferenced,
    relatedClauses: relatedClausesByTopics,
    relatedClausesCount: distinctRelatedClauses.length,
    reason,
  };
}

function getIssueRelatedClauses(
  issue,
  topicsById,
  documentClauses,
  acceptedTopicCategoryIds,
  applicableClauses,
) {
  let referencedTopicsByType = {};
  const relatedTopics = getIssueTriggeredSpecificValue(
    issue,
    "related_topics",
    "non_triggered_related_topics",
  );
  if (relatedTopics && relatedTopics.length > 0) {
    referencedTopicsByType.relatedTopics = [...relatedTopics];
    referencedTopicsByType.topicGroups = [];
  } else {
    const useRelatedTopicsByName = false;

    if (useRelatedTopicsByName) {
      referencedTopicsByType = issue.referenced_topics.reduce(
        (result, refTopicId) => {
          const refTopic = topicsById[refTopicId];
          if (refTopic) {
            if (
              refTopic.description.related_topics &&
              refTopic.description.related_topics.length > 0
            ) {
              result.relatedTopics = [
                ...result.relatedTopics,
                ...refTopic.description.related_topics,
              ];
            } else {
              result.topicGroups.push(refTopicId);
            }
          }
          return result;
        },
        {relatedTopics: [], topicGroups: []},
      );
      referencedTopicsByType.relatedTopics = _.uniq(
        referencedTopicsByType.relatedTopics,
      );
    } else {
      referencedTopicsByType.relatedTopics = [];
      referencedTopicsByType.topicGroups = [];
    }
  }

  const relatedClausesDataForRelatedTopics = constructRelatedClausesDataForRelatedTopics(
    referencedTopicsByType.relatedTopics,
    documentClauses,
    topicsById,
    acceptedTopicCategoryIds,
    applicableClauses,
  );
  const relatedClausesDataForTopicGroups = constructRelatedClausesDataForTopicGroups(
    referencedTopicsByType.topicGroups,
    documentClauses,
    topicsById,
    acceptedTopicCategoryIds,
    applicableClauses,
  );
  return _.chain(
    Object.values({
      ...relatedClausesDataForRelatedTopics.data,
      ...relatedClausesDataForTopicGroups.data,
    }),
  )
    .flatten()
    .uniq(clause => clause.id)
    .value();
}

export default constructIssueDetailData;

import _ from "underscore";
import React from "react";

import AutoComplete from "material-ui/AutoComplete";
import TextField from "material-ui/TextField";
import DeleteIcon from "material-ui/svg-icons/action/delete";

const styles = {
  deleteIcon: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    height: "20px",
    width: "20px",
    cursor: "pointer",
    transition: "none",
    flexShrink: 0,
  },
};

class ParameterValuesSelect extends React.Component {
  state = {
    parameterValueErrors: {},
    parameterValue: "",
  };

  componentDidUpdate(prevProps) {
    const {value: prevValue} = prevProps;
    const {value: currentValue} = this.props;
    if (!_.isEqual(prevValue, currentValue)) {
      this.checkParameterValueErrors();
    }
  }

  render() {
    const {value, options, className} = this.props;
    return (
      <div className={className}>
        {value.map((parameterValue, valueIndex) => (
          <div
            key={`parameter-value-${valueIndex}`}
            style={{display: "flex", alignItems: "center"}}
          >
            <TextField
              name={parameterValue.label}
              value={parameterValue.value}
              onChange={this.onParameterValueChange(valueIndex)}
              multiLine={false}
              errorText={this.state.parameterValueErrors[valueIndex]}
              style={{width: "100%", ...this.props.style}}
              disabled={this.props.disabled}
            />
            <DeleteIcon
              onClick={this.removeParameterValue(valueIndex)}
              color="#616161"
              style={styles.deleteIcon}
            />
          </div>
        ))}
        {!this.props.disabled && (
          <AutoComplete
            name="topicparameter-value-options"
            hintText="Type here to add"
            animated={false}
            menuCloseDelay={1}
            filter={AutoComplete.caseInsensitiveFilter}
            searchText={this.state.parameterValue || ""}
            onFocus={this.onParameterValueFocus}
            onUpdateInput={this.onParameterValueInputUpdate}
            dataSource={options}
            dataSourceConfig={{text: "label", value: "value"}}
            openOnFocus={true}
            onNewRequest={this.onParameterValueNewRequest}
            onBlur={this.onParameterValueBlur}
            style={{width: "100%"}}
            textFieldStyle={{width: "100%"}}
            menuProps={{maxHeight: 256}}
          />
        )}
      </div>
    );
  }

  onParameterValueChange = changeIndex => (e, newValue) => {
    const newValues = this.props.value.map((itemValue, itemIndex) => {
      if (itemIndex === changeIndex) {
        return {value: newValue, label: newValue};
      }
      return itemValue;
    });
    this.props.onChange(newValues);
  };

  removeParameterValue = removeIndex => () => {
    const newValues = this.props.value.filter(
      (value, valueIndex) => valueIndex !== removeIndex,
    );
    this.props.onChange(newValues);
  };

  onParameterValueFocus = () => this.setState(() => ({parameterValue: ""}));

  onParameterValueInputUpdate = parameterValue =>
    this.setState(() => ({parameterValue}));

  onParameterValueNewRequest = newValue => {
    if (newValue && typeof newValue === "string") {
      this.onParameterValueAdd(newValue);
    } else if (newValue && typeof newValue !== "string") {
      this.setState(() => ({parameterValue: ""}));
    }
  };

  onParameterValueBlur = e => {
    const newValue = e.target.value;
    if (newValue) {
      this.onParameterValueAdd(newValue);
    }
  };

  onParameterValueAdd = newValue => {
    const {onChange, value: values} = this.props;
    this.setState(
      () => ({parameterValue: ""}),
      () => onChange(values.concat({value: newValue, label: newValue})),
    );
  };

  checkParameterValueErrors = () => {
    const {getRegexErrors} = this.props;
    this.setState(() => ({
      parameterValueErrors: getRegexErrors(
        this.props.value.map(item => item.value),
      ),
    }));
  };
}

export default ParameterValuesSelect;

import React from "react";
import {Link} from "react-router";
import Select from "react-select";

import {makeStyles} from "@material-ui/core/styles";
import IconButton from "material-ui/IconButton";
import LinkIcon from "material-ui/svg-icons/content/link";
import * as colors from "material-ui/styles/colors";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: "#000",
    textDecoration: "none",
    cursor: "pointer",
  },
  select: {
    width: "100%",
    "& > .Select > .Select-control": {
      border: props =>
        props.shouldTopicSelectBeHighlighted
          ? "1px solid red !important"
          : undefined,
    },
  },
});

export default function SelectWithLink(props) {
  const classes = useStyles(props);
  const newClassName = `${
    props.className ? `${props.className}-` : ""
  }select-with-link`;
  if (props.link) {
    return (
      <div className={classes.container}>
        <Link to={props.link} target="_blank" className={classes.link}>
          <IconButton>
            <LinkIcon color={colors.grey500} />
          </IconButton>
        </Link>
        <div className={classes.select}>
          <Select {...props} className={newClassName} />
        </div>
      </div>
    );
  }
  return <Select {...props} className={newClassName} />;
}

export function getTopicLink(organisationId, topicId) {
  return organisationId && topicId
    ? `/organisation/${organisationId}/topic/${topicId}/detail`
    : null;
}

export function getParameterLink(organisationId, topicId, parameterId) {
  return organisationId && topicId && parameterId && parameterId.length !== 0
    ? `/organisation/${organisationId}/topic/${topicId}/detail?topicparameter_id=${parameterId}`
    : null;
}

import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";

export default function permissionGroupPermissionsReducer(
  state = UNINITIALISED,
  action,
) {
  switch (action.type) {
    case ActionTypes.PERMISSION_GROUP_PERMISSIONS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PERMISSION_GROUP_PERMISSION_ADD.SUCCESS: {
      const itemExists = state.find(
        item =>
          item.permission_group_id === action.payload.permissionGroupId &&
          item.permission_id === action.payload.permissionId,
      );
      if (itemExists) {
        return state;
      }
      return [
        ...state,
        {
          permission_group_id: action.payload.permissionGroupId,
          permission_id: action.payload.permissionId,
        },
      ];
    }
    case ActionTypes.PERMISSION_GROUP_PERMISSION_DELETE.SUCCESS: {
      return state.filter(
        item =>
          !(
            item.permission_group_id === action.payload.permissionGroupId &&
            item.permission_id === action.payload.permissionId
          ),
      );
    }
    default:
      return state;
  }
}

import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";

import styles from "../styles";

export default class DownloadButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  render() {
    const items = this.getItems();
    if (!items.length) {
      return null;
    }
    if (items.length === 1) {
      return (
        <IconButton style={styles.iconButton} onClick={items[0].onClick}>
          <GetAppIcon />
        </IconButton>
      );
    }
    const isOpen = Boolean(this.state.anchorEl);
    return (
      <React.Fragment>
        <IconButton
          style={styles.iconButton}
          onClick={event => this.setState({anchorEl: event.target})}
        >
          <GetAppIcon {...(isOpen && {color: "primary"})} />
        </IconButton>
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{horizontal: "left", vertical: "bottom"}}
          transformOrigin={{horizontal: "left", vertical: "top"}}
          anchorEl={this.state.anchorEl}
          open={isOpen}
          onClose={() => this.setState({anchorEl: null})}
          onClick={() => this.setState({anchorEl: null})}
        >
          {items.map(item => (
            <MenuItem
              key={item.text.toLowerCase().replace(/\s+/, "-")}
              onClick={item.onClick}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }

  getItems = () => {
    const {user, document, documentChanges} = this.props;
    const canDownloadDocument = findPermission(
      "download-document",
      user.permissions,
    );
    const items = [];

    if (canDownloadDocument && document.file_extension !== "pdf") {
      items.push({
        text: "Download Original",
        icon: <GetAppIcon />,
        onClick: () => this.props.downloadDocument(false)(this.props.fileIndex),
      });
    }

    if (canDownloadDocument) {
      items.push({
        text: "Download Original (without link)",
        icon: <GetAppIcon />,
        onClick: () =>
          this.props.downloadDocument(
            false,
            false,
            false,
            true,
          )(this.props.fileIndex),
      });
    }

    if (findPermission("download-altered-document", user.permissions)) {
      const hasChanges = documentChanges && documentChanges.length > 0;
      const {comments} = document;
      const hasComments =
        comments &&
        comments.length > 0 &&
        comments.filter(comment => comment.external).length > 0;

      if (hasComments) {
        items.push({
          text: `Download With Comments${hasChanges ? " (No Changes)" : ""}`,
          icon: <GetAppIcon />,
          onClick: () =>
            this.props.downloadDocument(
              true,
              false,
              true,
            )(this.props.fileIndex),
        });
      }

      if (hasChanges) {
        items.push({
          text: "Download With Changes (Clean)",
          icon: <GetAppIcon />,
          onClick: () =>
            this.props.downloadDocument(true)(this.props.fileIndex),
        });
      }

      if (hasChanges) {
        items.push({
          text: "Download With Changes (Markup)",
          icon: <GetAppIcon />,
          onClick: () =>
            this.props.downloadDocument(true, true)(this.props.fileIndex),
        });
      }
    }

    return items;
  };
}

function findPermission(permissionName, permissions) {
  return permissions.find(permission => permission.name === permissionName);
}

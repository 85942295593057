import EmailIcon from "@material-ui/icons/Email";
import React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import RefreshIcon from "@material-ui/icons/Refresh";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ViewStreamIcon from "@material-ui/icons/ViewStream";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import SummaryIcon from "@material-ui/icons/Equalizer";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import EventNoteIcon from "@material-ui/icons/EventNote";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import LinkIcon from "@material-ui/icons/Link";
import AccountTree from "@material-ui/icons/AccountTree";

import appHost from "app_host";
import styles from "../styles";

const DIVIDER = "DIVIDER";

export default class SettingsButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  render() {
    const items = this.getItems();
    if (!items.length) {
      return null;
    }
    const isOpen = Boolean(this.state.anchorEl);
    return (
      <React.Fragment>
        <IconButton
          style={styles.iconButton}
          onClick={event => this.setState({anchorEl: event.target})}
        >
          <MenuIcon {...(isOpen && {color: "primary"})} />
        </IconButton>
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top",
          }}
          anchorEl={this.state.anchorEl}
          open={isOpen}
          onClose={() => this.setState({anchorEl: null})}
        >
          {items.map((item, index) => {
            if (item === DIVIDER) {
              return <Divider key={`divider_${index}`} />;
            } else if (item.isLink) {
              return (
                <a
                  key={`link-${index}`}
                  href={item.href}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  <MenuItem>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </MenuItem>
                </a>
              );
            }
            return (
              <MenuItem
                key={item.text.toLowerCase().replace(/\s+/, "-")}
                onClick={item.onClick}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </MenuItem>
            );
          })}
        </Menu>
      </React.Fragment>
    );
  }

  getItems = () => {
    const {
      user,
      showClausesTopicsSelectorValue,
      organisation,
      document,
    } = this.props;
    const canSeeAdminItems =
      user.is_admin || findPermission("view-issue-detail", user.permissions);
    const items = [];

    if (canSeeAdminItems) {
      items.push({
        text: "Analyst view",
        icon: <KeyboardReturnIcon />,
        onClick: this.props.goToAnalystView,
      });
      items.push(DIVIDER);
    }

    if (findPermission("show-comparison-summary", user.permissions)) {
      items.push({
        text: "View Summary",
        icon: <SummaryIcon />,
        onClick: this.props.viewSummaryPage,
      });
    }

    if (findPermission("revert-document", user.permissions)) {
      items.push({
        text: "Revert document & issue state",
        icon: <RefreshIcon />,
        onClick: this.props.revertAllIssues,
      });
    }

    if (findPermission("email-report", user.permissions)) {
      items.push({
        text: "Email Report",
        icon: <EmailIcon />,
        onClick: this.props.showEmailReportDialog,
      });
    }

    if (
      findPermission("upload-to-api", user.permissions) &&
      this.props.project.document_type_id === 4
    ) {
      items.push({
        text: "Upload to Wordpress",
        icon: <CloudUploadIcon />,
        onClick: this.props.uploadDocumentToAPI,
      });
    }

    if (user.is_admin) {
      items.push({
        text: "Show Logs",
        icon: <EventNoteIcon />,
        onClick: this.props.showDocumentLogs,
      });
    }

    if (findPermission("link-document-definition", user.permissions)) {
      items.push({
        text: "Linked Definitions",
        icon: <MergeTypeIcon />,
        onClick: this.props.showLinkedDefinitions,
      });
    }

    items.push({
      text: "Reprocess Roles",
      icon: <RefreshIcon />,
      onClick: this.props.reprocessDocumentRoles,
    });

    if (canSeeAdminItems) {
      items.push(DIVIDER);
      items.push({
        text: "Expand all",
        icon: <ViewStreamIcon />,
        onClick: this.props.expandClauseHeadings,
      });
      items.push({
        text: "Collapse all",
        icon: <ViewHeadlineIcon />,
        onClick: this.props.collapseClauseHeadings,
      });
      items.push(DIVIDER);
      items.push({
        text: "Show All Topics",
        icon: renderCheckbox(showClausesTopicsSelectorValue === "all"),
        onClick: this.props.showClausesTopics("all"),
      });
      items.push({
        text: "Show Selected Topics",
        icon: renderCheckbox(showClausesTopicsSelectorValue === "selected"),
        onClick: this.props.showClausesTopics("selected"),
      });
      items.push({
        text: "Hide Topics",
        icon: renderCheckbox(showClausesTopicsSelectorValue === "none"),
        onClick: this.props.showClausesTopics("none"),
      });
      items.push(DIVIDER);

      items.push({
        text: "Client Mode",
        icon: renderCheckbox(this.props.clientModeOn),
        onClick: this.props.switchClientModeOn,
      });

      if (!user.is_admin || !this.props.clientModeOn) {
        items.push({
          text: "Collapse Issues",
          icon: renderCheckbox(this.props.areIssuesCollapsed),
          onClick: this.props.collapseIssues,
        });
      }

      items.push({
        text: "Highlight fixes",
        icon: renderCheckbox(this.props.highlightFixes),
        onClick: this.props.toggleHighlightFixes,
      });
      items.push({
        text: "Review Tracking",
        icon: renderCheckbox(this.props.reviewTrackingOn),
        onClick: this.props.toggleReviewTrackingOn,
      });
      items.push({
        text: "Show Issue Correctness Panel",
        icon: renderCheckbox(this.props.isIssueCorrectnessPanelShown),
        onClick: this.props.showIssueCorrectnessPanel,
      });
      items.push({
        text: "Show Clause Details",
        icon: renderCheckbox(this.props.isClauseDetailsShown),
        onClick: this.props.showClauseDetails,
      });
      items.push({
        text: "Edit Playbook Mode",
        icon: renderCheckbox(this.props.editPlaybookMode),
        onClick: this.props.switchEditPlaybookMode,
      });
      if (user.is_admin) {
        items.push({
          text: "Toggle Checklist Location",
          icon: renderCheckbox(this.props.isChecklistLocationToggled),
          onClick: this.props.toggleChecklistLocation,
        });
      }
      items.push({
        text: "Inline review",
        icon: renderCheckbox(this.props.documentLedReview),
        onClick: this.props.toggleDocumentLedReview,
      });
      items.push({
        text: "View docx",
        icon: renderCheckbox(this.props.viewDocx),
        onClick: this.props.switchViewDocx,
      });
      if (user.is_admin) {
        items.push({
          text: "Add-in View",
          isLink: true,
          href: constructAddInLink(this.props.document),
          icon: <LinkIcon />,
        });
      }
      items.push({
        text: "Show Two Panes",
        icon: renderCheckbox(this.props.showTwoPanes),
        onClick: this.props.toggleShowTwoPanes,
      });
      items.push({
        text: "Is badly parsed",
        icon: renderCheckbox(this.props.isBadlyParsed),
        onClick: this.props.toggleIsBadlyParsed,
      });
      if (user.is_admin && findPermission("get-workflows", user.permissions)) {
        items.push({
          text: "Kick off per-clause workflow",
          icon: <AccountTree />,
          onClick: this.props.showKickOffWorkflowDialog,
        });
      }
      items.push({
        text: "View LLM Runs",
        isLink: true,
        href: `/organisation/${organisation.id}/llm_run/list?document=${document.id}`,
        icon: <LinkIcon />,
      });
    }

    return items;
  };
}

function renderCheckbox(isChecked) {
  return isChecked ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;
}

function findPermission(permissionName, permissions) {
  return permissions.find(permission => permission.name === permissionName);
}

function constructAddInLink(openDocument) {
  if (!openDocument || !openDocument.id) {
    return appHost;
  }
  return [
    `${appHost}/word/taskpane.html#`,
    `organisation/${openDocument.organisation_id}`,
    `/project/${openDocument.project_id}`,
    `/document/${openDocument.id}/checklist`,
  ].join("");
}

import Describer from "../../../utils/issues/issue_description/describer";

function describeReason(clauses, topicsById, issue) {
  const describer = new Describer.IssueDescriber(
    issue,
    Object.values(topicsById),
    "not",
  );
  return `Rule "${describer.printChildIssue(issue.rules)}" was not triggered`;
}

export default describeReason;

import _ from "lodash";

function sortClausesByReference(arr, sections) {
  const sortedClauses = _.sortBy(
    [
      ...[...arr].map(clausepart => {
        const section = sections.find(
          section => section.id === clausepart.clause_section_id,
        );

        return {
          ...clausepart,
          x_section_order: section.order,
          x_clause_reference: clausepart.clause_reference.padStart(4, "0"),
          x_clause_order: clausepart.clause_order,
        };
      }),
    ],
    ["x_section_order", "x_clause_reference", "x_clause_order"],
  );
  sortedClauses.forEach(item => {
    if (item?.x_section_order) {
      delete item.x_section_order;
    }
    if (item?.x_clause_reference) {
      delete item.x_clause_reference;
    }
    if (item?.x_clause_order) {
      delete item.x_clause_order;
    }
  });
  return sortedClauses;
}

export default sortClausesByReference;

import constructSubissues from "./construct_subissues";

function addSubissueDataToGroupedIssues(groupedIssues, displayHiddenSubissues) {
  return groupedIssues.map(group => {
    if (!group.isGroup) {
      return group;
    }
    return {
      ...group,
      item: constructSubissues(group.item || [], displayHiddenSubissues),
    };
  });
}

export default addSubissueDataToGroupedIssues;

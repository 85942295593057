import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";
import UserActionTypes from "modules/user/constants/action_types";

export default function definitionGroupsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.DEFINITION_GROUPS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.DEFINITION_GROUP_ADD.SUCCESS: {
      const definitionGroupExists = state.find(
        dg => dg.name === action.payload.name,
      );
      if (definitionGroupExists) {
        return state;
      }
      return [...state, action.payload];
    }
    case ActionTypes.DEFINITION_GROUP_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(dg => {
        if (dg.id === action.payload.id) {
          return {
            ...dg,
            ...action.payload,
          };
        }
        return dg;
      });
    }
    case ActionTypes.DEFINITION_GROUP_REMOVE.SUCCESS: {
      if (action.payload.is_deleted) {
        return state.filter(dg => dg.id !== action.payload.id);
      }
      return state;
    }
    case ActionTypes.DOCUMENT_UPDATE.REQUEST: {
      if (action.payload && action.payload.added_synonyms) {
        const {added_synonyms: addedSynonyms} = action.payload;
        return state.map(
          dg =>
            addedSynonyms[dg.id]
              ? {...dg, synonyms: [...dg.synonyms, ...addedSynonyms[dg.id]]}
              : dg,
        );
      }
      return state;
    }
    case ActionTypes.DEFINITION_GROUPS_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

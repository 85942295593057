import _ from "underscore";

import calculateDocumentTopics from "utils/topic/calculate_document_topics";

function calculateTopics(clause, props) {
  const {topicMasks, topicsById, topicMasksById} = props;
  const topics = _.uniq(
    calculateDocumentTopics(clause, topicMasks, topicsById, topicMasksById),
    topic => topic.id,
  );
  return topics;
}

export default calculateTopics;

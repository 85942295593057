import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function generateDocumentIssueLlmPrompt(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_ISSUE_GENERATE_PROMPT,
    (
      organisationId,
      projectId,
      documentId,
      issueId,
      issuesetMasterId,
      issuesetClientId,
      prompt,
      conversationType,
      conversationId,
      previousPromptId,
    ) =>
      requestor
        .post(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            `/issue/${issueId}/generate_prompt${
              issuesetMasterId ? `?issueset_master_id=${issuesetMasterId}` : ""
            }${
              issuesetMasterId && issuesetClientId
                ? `&issueset_client_id=${issuesetClientId}`
                : ""
            }`,
          {
            conversation_type: conversationType,
            conversation_id: conversationId,
            previous_prompt_id: previousPromptId,
            new_prompt: prompt,
          },
          {withCredentials: true},
        )
        .then(response => ({
          id: conversationId,
          conversation_type: conversationType,
          issueset_master_id: issuesetMasterId,
          issueset_remote_client_id: issuesetClientId,
          ...response.data,
        })),
  );
}

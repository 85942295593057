import React from "react";
import _ from "underscore";

import MatchSection from "../../match_section";
import mergeClausepartsAndHeadings from "../../tabs/merge_clauseparts_and_headings";

import getClauseAtoms from "common/utils/clauses/get_clause_atoms";
import escapeRegex from "utils/escape_regex";
import formatClauseSearchText from "routes/document_detail/utils/format_clause_search_text";

export default class TextGroups extends React.Component {
  render() {
    return (
      <MatchSection
        items={this.props.items}
        disableMatchAll={true}
        sectionTitle="Clause Text"
        onSearch={() => null}
        noMatchMessage={this.getNoMatchMessage()}
        isLast={this.props.isLast}
        appType={this.props.appType}
      />
    );
  }
  getNoMatchMessage = () => {
    return !this.props.searchValue.length ? "No Matches" : null;
  };
}

TextGroups.findMatches = props => {
  const {searchValue} = props;
  if (!searchValue) {
    return [];
  }
  const clauseAtoms = getClauseAtoms(props.documentClauses);
  const definitionsOmitRegex = getDefinitionsOmitRegex(
    props.documentDefinitions,
  );
  const foundClauseMatches = getMatches(clauseAtoms, searchValue);
  const matchedHeadings = getMatches(props.documentHeadings, searchValue);
  const matchedHeadingAndClauseparts = mergeClausepartsAndHeadings(
    foundClauseMatches,
    matchedHeadings,
  );

  return matchedHeadingAndClauseparts.map(match => ({
    id: match.id,
    name: formatClauseSearchText(
      match.text,
      searchValue,
      match,
      clause => () =>
        match.isHeading
          ? props.scrollToHeading(clause)
          : props.scrollToClause(clause),
      definitionsOmitRegex,
    ),
  }));
};

function getMatches(items, searchValue) {
  return items.filter(
    item =>
      item &&
      item.text &&
      item.text &&
      item.text.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1,
  );
}

const getDefinitionsOmitRegex = _.memoize(
  function(definitions = []) {
    const defs = definitions.map(def => escapeRegex(def.term));
    defs.push("meaning");
    const regexStr = `\\.?(?:${defs.join("|")})`;
    return new RegExp(regexStr);
  },
  (...argv) => {
    return JSON.stringify(argv);
  },
);

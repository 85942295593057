import _ from "lodash";
import UNINITIALISED from "utils/uninitialised";

import updateOverrideDocumentIssueValues from "common/utils/update_override_document_issue_values";

import {
  updateStateWithRequest,
  updateStateWithSuccess,
} from "utils/ensure_fresh_update";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function issueReducer(state = UNINITIALISED, action) {
  if (
    state === UNINITIALISED &&
    action.type !== ActionTypes.ISSUE_FETCH.SUCCESS &&
    action.type !== ActionTypes.ISSUE_INFO_FETCH.SUCCESS
  ) {
    return state;
  }
  switch (action.type) {
    case ActionTypes.ISSUE_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.ISSUE_INFO_FETCH.SUCCESS:
      return {...action.payload, usage: []};
    case ActionTypes.ISSUE_USAGES_FETCH.SUCCESS:
      return {...state, usage: action.payload};
    case "@@router/LOCATION_CHANGE": {
      // this is used instead of ISSUE_CLEAR.SUCCESS as
      // react-router treats change in pathname parameter (i.e. issueId)
      // as leaving the route and invokes onLeave hook which we
      // use to clear reducer
      const pathnameRegex = /\/organisation\/\d+\/issue\/\d+/;
      if (
        action &&
        action.payload &&
        action.payload.pathname &&
        !pathnameRegex.test(action.payload.pathname)
      ) {
        return UNINITIALISED;
      }
      return state;
    }
    case ActionTypes.ISSUE_UPDATE.REQUEST: {
      if (state.id === action.payload.id) {
        return {
          usage: [],
          ...state,
          ...updateStateWithRequest(state, action.payload, [
            "id",
            "last_edited",
          ]),
          reprocessingComplete: false,
        };
      }
      return state;
    }
    case ActionTypes.ISSUE_UPDATE.SUCCESS: {
      if (state.id === action.payload.id) {
        return updateStateWithSuccess(state, action.payload);
      }
      return state;
    }
    case ActionTypes.ISSUES_UPDATE.SUCCESS: {
      if (Array.isArray(action.payload)) {
        const issue = action.payload.find(issue => issue.id === state.id);
        if (issue) {
          return issue;
        }
      }
      return state;
    }
    case ActionTypes.CONTRACT_TYPE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const newIssueContractTypes = state.contract_types.map(ct => {
        if (ct.id === action.payload.id) {
          return {
            ...ct,
            ...action.payload,
          };
        }
        return ct;
      });
      return {
        ...state,
        contract_types: newIssueContractTypes,
      };
    }
    case ActionTypes.CONTRACT_TYPE_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const newIssueContractTypes = state.contract_types.filter(
        issueContractType =>
          issueContractType.contract_type_id !== action.payload.id,
      );
      return {
        ...state,
        contract_types: newIssueContractTypes,
      };
    }
    case ActionTypes.DOCUMENT_ISSUE_MANUAL_CORRECT.SUCCESS:
    case ActionTypes.DOCUMENT_ISSUE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      const newIssueUsage = {...state.usage};
      if (state.usage[payload.document_issue_id]) {
        newIssueUsage[payload.document_issue_id] = state.usage[
          payload.document_issue_id
        ].map(usageItem => {
          if (
            usageItem.document_id === payload.document_id &&
            usageItem.issue_id === payload.issue_id
          ) {
            if (
              payload.options &&
              payload.options.update_override_document_issue_values &&
              payload.options.merge_update_object
            ) {
              const overrideDocumentIssueValues = _.cloneDeep(
                usageItem.override_document_issue_values,
              );
              updateOverrideDocumentIssueValues(
                payload.updates,
                `${payload.options.data_path}${
                  payload.options.is_manual_correction
                    ? ".manual_corrections"
                    : ""
                }`,
                overrideDocumentIssueValues,
              );
              return {
                ...usageItem,
                document_issue_last_edited: payload.last_edited,
                override_document_issue_values: overrideDocumentIssueValues,
              };
            }
            return {
              ...usageItem,
              document_issue_last_edited: payload.last_edited,
              ...payload.updates,
            };
          }
          return usageItem;
        });
      }
      return {
        ...state,
        usage: newIssueUsage,
      };
    }
    case ActionTypes.DOCUMENT_ISSUES_RESET.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      const {payload} = action;
      return {
        ...state,
        usage: _.mapObject(state.usage, clauses =>
          clauses.map(clause => {
            const newDi = payload.find(
              di => di.id === clause.document_issue_id,
            );
            if (newDi) {
              return {
                ...clause,
                ..._.omit(newDi, "id", "last_edited"),
                document_issue_last_edited: newDi.last_edited,
              };
            }
            return clause;
          }),
        ),
      };
    }
    case ActionTypes.DOCUMENT_ISSUES_REFETCH.SUCCESS: {
      if (state === UNINITIALISED || !action.payload || !action.payload.usage) {
        return state;
      }
      const {
        usage: payloadUsages,
        document_id: payloadDocumentId,
        last_job_in_batch: lastJobInBatch,
        issues_last_edited: payloadIssuesLastEdited,
      } = action.payload;
      const {usage: stateUsagesPerDocumentIssue} = state;
      let newStateUsagesPerDocumentIssue = {};
      const reprocessingComplete = lastJobInBatch;
      let hasDocumentIssuesLastEditedChanged = false;
      if (payloadUsages.length === 0) {
        _.each(stateUsagesPerDocumentIssue, (stateUsages, documentIssueId) => {
          const newStateUsages = stateUsages.reduce((result, stateUsage) => {
            if (
              stateUsage.document_id === payloadDocumentId &&
              stateUsage.issues_last_edited !== payloadIssuesLastEdited
            ) {
              hasDocumentIssuesLastEditedChanged = true;
            }
            if (stateUsage.document_id !== payloadDocumentId) {
              result.push(stateUsage);
            }
            return result;
          }, []);
          if (newStateUsages.length > 0) {
            newStateUsagesPerDocumentIssue[documentIssueId] = newStateUsages;
          }
        });
      } else {
        const payloadUsagesDocumentIssueId = payloadUsages[0].document_issue_id;
        const changedUsages = (
          stateUsagesPerDocumentIssue[payloadUsagesDocumentIssueId] || []
        ).filter(
          stateUsage =>
            stateUsage.issues_last_edited !== payloadIssuesLastEdited,
        );
        if (changedUsages && changedUsages.length > 0) {
          hasDocumentIssuesLastEditedChanged = true;
        }
        newStateUsagesPerDocumentIssue = {
          ...stateUsagesPerDocumentIssue,
          [payloadUsagesDocumentIssueId]: payloadUsages,
        };
      }
      return hasDocumentIssuesLastEditedChanged
        ? {
            ...state,
            reprocessingComplete,
            usage: newStateUsagesPerDocumentIssue,
          }
        : state;
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

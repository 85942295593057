import conceptRules from "./concept_rules";

export default function evaluateRules(rules, evaluationRules) {
  const semantics = conceptRules
    .createSemantics()
    .addOperation("eval", evaluationRules);
  let result;
  const match = conceptRules.match(rules);
  if (match.succeeded()) {
    try {
      result = semantics(match).eval();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return false;
    }
  } else {
    throw new Error(match.message);
  }

  return result;
}

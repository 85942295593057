import React from "react";

import IconButton from "@material-ui/core/IconButton";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Tooltip from "@material-ui/core/Tooltip";

import styles from "../styles";

const ExternalClauseLibraryButton = ({
  externalClauseLibrary,
  showIcon,
  iconStyles,
  buttonStyles,
}) => {
  if (!externalClauseLibrary) {
    return null;
  } else {
    const parts = externalClauseLibrary.split("|");
    const title = parts.length >= 2 ? parts[0] : "External clause library";
    const link = parts.length >= 2 ? parts[1] : externalClauseLibrary;
    const Icon = parts.length >= 3 ? makeSvg(parts[2]) : MenuBookIcon;

    return (
      <a
        href={link}
        target="_blank"
        style={
          showIcon
            ? iconStyles
              ? {...iconStyles}
              : {...styles.iconButton}
            : {...styles.externalClauseBtn}
        }
      >
        <Tooltip title={title}>
          {showIcon ? (
            <IconButton style={buttonStyles ? buttonStyles : styles.iconButton}>
              <Icon />
            </IconButton>
          ) : (
            <span style={styles.spanMessage}>
              <span style={{paddingRight: "5px"}}>{title}</span>
              <OpenInNewIcon />
            </span>
          )}
        </Tooltip>
      </a>
    );
  }
};

function makeSvg(text) {
  const svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">${text}</svg>`;
  return () => <div dangerouslySetInnerHTML={{__html: svgContent}} />;
}

export default ExternalClauseLibraryButton;

import React from "react";

import ZoomMenu from "./zoom_menu";
import FiltersPanel from "common_components/filters";

import UnfoldLess from "icons/UnfoldLess";
import UnfoldMore from "icons/UnfoldMore";
import MenuOpen from "icons/MenuOpen";
import VerticalSplit from "icons/VerticalSplit";
import ClearIcon from "@material-ui/icons/Clear";
import Permissioner from "utils/permissioner";
import {filtersState} from "common_components/filters/constants";
import getFiltersTitles from "../../report/utils/get_filters_titles";

const styles = {
  iconStyle: {
    cursor: "pointer",
    color: "#50b3ff",
    height: 20,
    width: 20,
  },
  iconButtonBlock: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 10px",
  },
  checklistActions: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    height: "30px",
  },
  checklistActionsPanel: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "sticky",
    transition: "top 0.3s",
    zIndex: 2,
    color: "#616161",
    top: 0,
  },
  filtersPanel: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    padding: "5px",
  },
  filterClips: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeigh: "400",
    borderRadius: "70px",
    padding: "2px 0 2px 6px",
  },
  filterClipsLight: {
    backgroundColor: "#000",
    color: "#FFF",
  },
  filterClipsDark: {
    backgroundColor: "#FFF",
    color: "#000",
  },
  clearFiltersBtn: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#50b3ff",
    fontSize: "12px",
    fontWeigh: "400",
  },
};

const allowTwoPanelToggle = false;

const ChecklistActionsPanel = props => {
  const CollapseIcon = props.areIssueGroupsExpanded ? UnfoldLess : UnfoldMore;
  const VerticalSplitIcon = props.showTwoPanes ? MenuOpen : VerticalSplit;
  const flipColor = props.isFlipChecklistIcons ? "lightgray" : "#4F575f";
  const clipsTheme = props.isFlipChecklistIcons
    ? styles.filterClipsLight
    : styles.filterClipsDark;

  const canViewChecklistFilters = () => {
    const permissions = new Permissioner(props.user);
    return (
      permissions.isAdmin() ||
      permissions.hasPermission("can-view-checklist-filters")
    );
  };

  const handleClearFilter = item =>
    props.changeItemIssuesFilterValues(
      filtersState.find(filter => filter.title === item),
    );
  const handleClearAllFilters = () =>
    props.changeItemIssuesFilterValues("delete");

  return (
    <div
      id="checklist-actions-panel"
      style={{
        ...styles.checklistActionsPanel,
        borderBottom: `1px solid ${flipColor}`,
        background: props.isFlipChecklistIcons ? "#f1f1f1" : "#232E38",
      }}
    >
      <div style={styles.checklistActions}>
        {canViewChecklistFilters() ? (
          <FiltersPanel
            isFlipChecklistIcons={props.isFlipChecklistIcons}
            changeItemIssuesFilterValues={props.changeItemIssuesFilterValues}
            itemIssuesFilterValues={props.itemIssuesFilterValues}
            reviewTrackinOn={props.reviewTrackingOn}
          />
        ) : null}
        <div
          style={{
            ...styles.iconButtonBlock,
            borderLeft: `1px solid ${flipColor}`,
          }}
        >
          <ZoomMenu
            title="Zoom"
            zoom={props.zoom}
            onZoomUpdate={props.onZoomUpdate}
          />
          {!allowTwoPanelToggle || !props.isWindowNarrow ? null : (
            <div
              title={
                props.showTwoPanes
                  ? "Use two columns only when space"
                  : "Force two columns"
              }
              style={styles.iconStyle}
            >
              <VerticalSplitIcon
                style={{height: "100%"}}
                onClick={props.toggleShowTwoPanes}
              />
            </div>
          )}
          <div
            title={
              props.areIssueGroupsExpanded ? "Collapse Issues" : "Expand Issues"
            }
            style={styles.iconStyle}
          >
            <CollapseIcon
              style={{height: "100%"}}
              onClick={props.expandIssueGroups}
            />
          </div>
        </div>
      </div>
      {getFiltersTitles(filtersState, props.itemIssuesFilterValues).length ? (
        <div
          style={{
            ...styles.filtersPanel,
            borderTop: `1px solid ${flipColor}`,
          }}
        >
          {getFiltersTitles(filtersState, props.itemIssuesFilterValues).map(
            item => (
              <div key={item} style={{...styles.filterClips, ...clipsTheme}}>
                {item}
                <ClearIcon
                  style={{height: "15px", cursor: "pointer"}}
                  onClick={() => handleClearFilter(item)}
                />
              </div>
            ),
          )}
          <div style={styles.clearFiltersBtn} onClick={handleClearAllFilters}>
            <ClearIcon style={{height: "15px"}} />
            Clear All
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ChecklistActionsPanel;

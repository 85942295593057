import React from "react";
import Paper from "@material-ui/core/Paper";

const TopPanel = ({
  styles,
  rootDivStyles,
  renderInSeparateWindow,
  isReportShownInRightSide,
  showSelectionTriangle,
  triangleRightOffset,
  renderTopMenu,
  children,
  onContainerClick,
}) => {
  return (
    <Paper
      id="top-panel"
      style={{
        ...styles.topPanelMain,
        ...(rootDivStyles ? rootDivStyles : {}),
        ...(renderInSeparateWindow ? {maxHeight: "100%"} : {maxHeight: "50%"}),
      }}
    >
      {renderTopMenu()}
      <div
        style={{
          ...styles.container,
          ...(isReportShownInRightSide
            ? {
                height: "calc(100vh - 40px)",
                overflow: "auto",
              }
            : renderInSeparateWindow
            ? {
                height: "calc(100vh - 130px)",
                overflow: "auto",
              }
            : {}),
        }}
        onClick={onContainerClick}
      >
        {showSelectionTriangle && (
          <div
            style={{
              ...styles.triangle,
              right: `${triangleRightOffset ? triangleRightOffset : "30"}px`,
            }}
          />
        )}
        <div
          className="top-panel-scroller"
          style={{
            ...styles.topPanelScroller,
            ...(renderInSeparateWindow || isReportShownInRightSide
              ? {}
              : {
                  maxHeight: "33vh",
                  overflowY: "auto",
                }),
          }}
        >
          {children}
        </div>
      </div>
    </Paper>
  );
};

export default TopPanel;

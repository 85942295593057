import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

/* eslint-disable complexity */
export default function topicParametersReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.TOPIC_PARAMETERS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.TOPIC_PARAMETERS_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.TOPICPARAMETER_UPDATE.SUCCESS:
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(topicparameter => {
        if (topicparameter.topicparameter_id === action.payload.id) {
          return {
            ...topicparameter,
            ..._.omit(action.payload, ["id"]),
          };
        }
        return topicparameter;
      });
    default:
      return state;
  }
}

import _ from "underscore";

function getClauseAtomsByIds(atomIds, documentClauses) {
  const atoms = [];
  function getAtoms(nodes, clause) {
    if (nodes.text && nodes.topics && atomIds.includes(nodes.id)) {
      atoms.push({
        reference: nodes.reference,
        text: nodes.partial_text,
        full_text: nodes.text,
        id: nodes.id,
        level: nodes.level,
        clause_reference: clause.reference,
        clause_id: clause.id,
        clause_section_id: clause.section_id,
        clause_order: nodes.order,
        nodes: clause.nodes,
        topics: nodes.topics,
      });
    }
    if (nodes.clauseNodes) {
      nodes.clauseNodes.forEach(child => getAtoms(child, clause));
    }
  }
  _.forEach(documentClauses, clauses => {
    clauses.forEach(clause => {
      getAtoms(clause.nodes, clause);
    });
  });
  return atoms;
}

export default getClauseAtomsByIds;

import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import Sidebar from "routes/logged_in/containers/sidebar";
import SidebarCollapsedNav from "../sidebar_collapsed_nav";

export default class DocumentSidebar extends React.Component {
  render() {
    const collapsedNavProps = _.omit(this.props, [
      "router",
      "isCollapsedLight",
      "overrideRole",
    ]);
    return (
      <Sidebar
        overrideRole={this.props.overrideRole}
        router={this.props.router}
        isCollapsedLight={true}
        collapsedUserNav={<SidebarCollapsedNav {...collapsedNavProps} />}
        clientModeOn={this.props.clientModeOn}
      />
    );
  }
}

DocumentSidebar.defaultProps = {
  isCollapsedNavDisabled: true,
};

DocumentSidebar.propTypes = {
  isCollapsedNavDisabled: PropTypes.bool.isRequired,
};

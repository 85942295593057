import React, {useContext, useState} from "react";

import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import DocumentSections from "./document_sections";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import DocumentDetailContext from "common_components/context/document_detail_context";
import postBroadcastData from "../../utils/post_brodcast_data";
import keyCreator from "../../utils/key_creator";
import getCursorPosition from "./utils/get_cursor_position";
import getSelectedClauseId from "./utils/get_selected_clause_id";
import findIndexClauseId from "./utils/find_index_clause_id";

const styles = {
  documentView: {
    margin: "0 auto",
    fontSize: "14px",
    padding: "1.5rem",
    position: "relative",
  },
  addButtonBlock: {
    position: "absolute",
    zIndex: 1000,
  },
  addButton: {
    transition: "width .3s",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

const DocumentView = props => {
  const {
    additionalReportClausesState,
    isReportTabShow,
    scrollTopValue,
    isReportPanelShown,
    editModeOn,
  } = props;
  const clauseButtonDefaultValue = {
    isShow: false,
    left: 0,
    top: 0,
  };
  const [addClausesButton, setAddClausesButton] = useState(
    clauseButtonDefaultValue,
  );
  const [isHover, setIsHover] = useState(false);
  const context = useContext(DocumentDetailContext);
  postBroadcastData(keyCreator("report", props.document.document_id), {
    selectedIssue: props?.selectedIssue
      ? parseInt(props.selectedIssue.split("_")[0], 10)
      : null,
  });

  const onHover = () => setIsHover(true);
  const onLeave = () => setIsHover(false);

  const handleMouseUp = event => {
    const selection = window.getSelection();
    const text = selection.toString();

    if (additionalReportClausesState && isReportTabShow) {
      const {anchorNode, focusNode} = selection;
      const startSelection = getSelectedClauseId(anchorNode.parentNode);
      const endElem = focusNode.parentNode;
      const endSelection = getSelectedClauseId(endElem);
      const selectedIds = [];
      const clauseParts = [...props.documentClauseparts];
      if (startSelection && endSelection && startSelection !== endSelection) {
        const indexStart = findIndexClauseId(clauseParts, startSelection, true);
        const indexEnd = findIndexClauseId(clauseParts, endSelection, false);
        clauseParts
          .slice(
            indexStart > indexEnd ? indexEnd : indexStart,
            indexStart > indexEnd ? indexStart + 1 : indexEnd + 1,
          )
          .forEach(item => selectedIds.push(item.id));
        props.setSelectedClauses(selectedIds);
        const top =
          endElem.getBoundingClientRect().top -
          60 +
          window.pageYOffset +
          scrollTopValue;
        const left =
          60 +
          getCursorPosition(event).x -
          endElem.getBoundingClientRect().x +
          (endElem?.offsetParent?.offsetLeft ?? 0);
        setAddClausesButton({
          isShow: true,
          top,
          left,
        });
      } else {
        props.setSelectedClauses(null);
        setAddClausesButton(clauseButtonDefaultValue);
      }
    }

    //  if it has text then copy to clipboard
    if (text.length) {
      if (window.clipboardData) {
        try {
          window.clipboardData.setData(text, null);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Text was not copied.", error);
        }
      } else {
        try {
          navigator.clipboard.writeText(text);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error("Text was not copied.", error);
        }
      }
    }
  };

  const addClausesToReport = () => {
    props.onAddClauseToReport(props.selectedClauses);
    setAddClausesButton(clauseButtonDefaultValue);
    props.setSelectedClauses(null);
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else {
      document.selection.empty();
    }
  };

  return (
    <div
      id="documentView"
      className="document-view"
      style={{
        ...styles.documentView,
        ...props.style,
        maxWidth: getZoomedFontSize(1072, "document", context.zoom),
      }}
      onMouseUp={handleMouseUp}
    >
      {addClausesButton.isShow &&
      props.selectedClauses.length &&
      !editModeOn ? (
        <div
          style={{
            top: isReportPanelShown
              ? addClausesButton.top - 487
              : editModeOn === false
                ? addClausesButton.top - 38
                : addClausesButton.top,
            left: addClausesButton.left,
            ...styles.addButtonBlock,
          }}
        >
          <Fab
            size="small"
            color="primary"
            variant={"extended"}
            onClick={addClausesToReport}
            disabled={!props.selectedClauses.length}
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
            style={{
              ...styles.addButton,
              width: isHover ? "200px" : "24px",
            }}
          >
            {isHover ? (
              `Add ${props.selectedClauses.length} clauses to report ? `
            ) : (
              <AddIcon />
            )}
          </Fab>
        </div>
      ) : null}
      <DocumentSections {...props} />
    </div>
  );
};

export default DocumentView;

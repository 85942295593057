import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginTop: ".5em",
  },
  button: {
    marginRight: ".5em",
  },
  textField: {
    width: "100%",
    fontSize: ".9em",
  },
});

export default function CommentTextEditor({
  id,
  label,
  text,
  className,
  onChange,
  confirmLabel,
  onConfirm,
  declineLabel,
  onDecline,
}) {
  const classes = useStyles();
  return (
    <div className={`${classes.root}${className ? ` ${className}` : ""}`}>
      <TextField
        id={id}
        label={label}
        size="small"
        multiline
        className={classes.textField}
        value={text}
        onChange={onChange}
      />
      <div className={classes.buttons}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={onConfirm}
        >
          {confirmLabel}
        </Button>
        <Button
          variant="outlined"
          className={classes.button}
          onClick={onDecline}
        >
          {declineLabel}
        </Button>
      </div>
    </div>
  );
}

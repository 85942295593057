import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function initTopics(requestor) {
  return createReduxPromiseAction(
    ActionTypes.TOPICS_FETCH,
    (organisationId, projectId, documentId) =>
      requestor
        .get(
          `${basePath}/organisation/${organisationId}/project/${projectId}/` +
            `document/${documentId}/topic`,
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}

/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */

import findReference from "../../../../utils/find_reference";
import calculateChildReference from "../../../../utils/calculate_child_reference";

import getTextStyle from "../utils/get_text_style";
import formatText from "utils/text/format_text";

const textStyle = {
  marginRight: "0.5em",
};

export default function renderText(props) {
  const {node, reference} = props;
  const text = renderTextNode(node, reference, props);
  return <span style={textStyle}>{text}</span>;
}

function renderTextNode(node, reference, props) {
  const text = [];
  if (node.type.endsWith("NumberedList")) {
    text.push(
      ...node.clauseNodes.map((child, index) => {
        const childReference = findReference(child);
        const childReferenceText = renderReference(
          childReference,
          reference,
          node,
        );
        const childText = renderTextNode(child, childReference, {
          ...props,
          path: `${props.path}[${index}]`,
        });
        const textStyle = getTextStyle(child, props);
        return (
          <span
            key={`${calculateKey(childReferenceText, node)}_${index}`}
            style={textStyle}
          >
            <span className="reference">{childReferenceText}</span>
            <span className="text">{childText}</span>
          </span>
        );
      }),
    );
  } else {
    if (node.type.startsWith("Numbered") && node.clauseId) {
      /* eslint-disable-next-line no-param-reassign */
      reference = node.clauseId;
    }
    if (node.text) {
      const childReference = findReference(node);
      const childReferenceText = renderReference(
        childReference,
        reference,
        node,
      );
      const textStyle = getTextStyle(node, props);
      return (
        <span
          key={`${calculateKey(childReferenceText, node)}_${0}`}
          style={textStyle}
        >
          <span className="reference">{childReferenceText}</span>
          <span className="text">
            {formatText(
              props.document.file_extension,
              node.partial_text || node.text,
              props,
              "",
              {},
            )}
          </span>
        </span>
      );
    }
    if (node.clauseNodes) {
      text.push(
        ...node.clauseNodes.map((child, index) =>
          renderTextNode(child, reference, {
            ...props,
            path: `${props.path}[${index}]`,
          }),
        ),
      );
    }
  }

  const textStyle = getTextStyle(node, props);
  return (
    <span
      key={calculateKey(reference, node)}
      style={{...textStyle, color: "#333"}}
      onMouseOver={props.onTextHovered}
    >
      {text}
    </span>
  );
}

function calculateKey(reference, node) {
  return [
    reference,
    node.type,
    node.level,
    node.listOrder,
    node.clausePartNumber,
    node.order,
  ].join("_");
}

function renderReference(childReference, reference, node) {
  const refText =
    childReference && calculateChildReference(childReference, reference, node);
  if (refText && refText.length > 0) {
    return `(${refText}) `;
  }
  return "";
}

import React from "react";
import _ from "underscore";

import AddCircle from "material-ui/svg-icons/content/add-circle";
import Undo from "material-ui/svg-icons/content/undo";
import TextField from "material-ui/TextField";

import applyChanges from "utils/text/apply_text_changes";

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: -3,
    marginTop: -2,
    height: 48,
  },
  actionButton: {
    color: "rgb(3, 155, 229)",
    cursor: "pointer",
    width: "20px",
    height: "20px",
  },
};

class HeadingEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: Boolean(this.getText(props)),
      isNewHeadingAdded: false,
      error: "",
      isControlButtonClicked: false,
    };
  }

  componentDidMount() {
    if (this.props.doesHaveEditableClauseHeading && this.headingTextRef) {
      this.headingTextRef.focus();
    }
    window.addEventListener("click", this.handleEditorOutsideClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleEditorOutsideClick);
  }

  handleEditorOutsideClick = event => {
    if (
      !this.state.isEditing &&
      !this.headingEditorRef.contains(event.target)
    ) {
      this.props.unsetEditableClauseHeading();
    }
  };

  componentDidUpdate(prevProps) {
    const {changes: currentChanges, isAdded: currentIsAdded} = this.props;
    const {id: currentHeadingId} = this.props;
    const {changes: prevChanges, isAdded: prevIsAdded} = prevProps;
    if (prevIsAdded && !currentIsAdded && !currentHeadingId) {
      return this.setState(() => ({isEditing: false}));
    }
    if (
      currentChanges &&
      currentChanges.length === 0 &&
      prevChanges &&
      !_.isEqual(currentChanges, prevChanges)
    ) {
      const {text} = this.props.heading;
      const that = this;
      this.setState(
        () => ({isEditing: Boolean(text)}),
        () => {
          if (that.headingTextRef) {
            that.headingTextRef.input.value = text;
          }
        },
      );
    }
    if (
      this.state.isEditing &&
      prevProps.isDeleted !== this.props.isDeleted &&
      this.props.isDeleted
    ) {
      this.props.unsetEditableClauseHeading();
    }
  }

  render() {
    const {heading} = this.props;
    return (
      <div ref={this.createHeadingEditorRef} style={styles.root}>
        {this.state.isEditing ? (
          <TextField
            name="heading-text-textfield"
            defaultValue={this.getText(this.props)}
            errorText={this.state.error}
            onBlur={this.onHeadingSave}
            ref={this.createHeadingTextRef}
            onFocus={this.onHeadingFocus}
            style={{fontSize: 19, width: "100%"}}
          />
        ) : (
          <span style={{marginRight: "0.5rem", flexGrow: 1}}>
            Heading not set
          </span>
        )}
        {this.renderControlPanel(heading)}
      </div>
    );
  }

  renderControlPanel = heading => {
    const actions = [];
    const textWithChanges = this.getText(this.props);
    const baseHeadingPresent = Object.keys(heading).length !== 0;
    if (!this.state.isEditing && !baseHeadingPresent) {
      actions.push(
        <AddCircle
          key="add-button"
          style={styles.actionButton}
          onClick={this.onAddNewHeading}
        />,
      );
    }
    if (
      this.props.isAdded ||
      (heading && heading.text && heading.text !== textWithChanges)
    ) {
      actions.push(
        <Undo
          key="undo-button"
          style={styles.actionButton}
          onMouseDown={this.onHeadingChangesRevert}
        />,
      );
    }
    return actions;
  };

  onAddNewHeading = () =>
    this.setState(
      () => ({isEditing: true, isNewHeadingAdded: true}),
      () => this.headingTextRef.focus(),
    );

  onHeadingFocus = e => {
    const t = e.target.value;
    e.target.value = "";
    e.target.value = t;
    this.setState(() => ({error: ""}));
  };

  onHeadingSave = e => {
    const newValue = e.target.value;
    const {doesHaveEditableClauseHeading} = this.props;
    if (this.state.isControlButtonClicked && doesHaveEditableClauseHeading) {
      return this.props.unsetEditableClauseHeading();
    }
    const {heading} = this.props;
    if (newValue === this.getText(this.props)) {
      return this.props.unsetEditableClauseHeading();
    }
    const {isNewHeadingAdded} = this.state;
    if (newValue && isNewHeadingAdded) {
      return this.props.onAdd(
        this.props.section.id,
        this.props.reference,
        newValue,
      );
    }
    if (!newValue && isNewHeadingAdded) {
      return this.setState(() => ({
        isEditing: false,
        isNewHeadingAdded: false,
      }));
    }
    if (!newValue && this.props.onDelete) {
      return this.setState(
        () =>
          doesHaveEditableClauseHeading
            ? {isControlButtonClicked: true}
            : {isEditing: false, isControlButtonClicked: true},
        () => this.props.onDelete(heading),
      );
    }
    this.props.onAlter(heading, newValue);
    if (doesHaveEditableClauseHeading) {
      this.props.unsetEditableClauseHeading();
    }
  };

  getText = props => {
    return applyChanges(
      (props.heading && props.heading.text) || "",
      props.changes || [],
    );
  };

  onHeadingChangesRevert = () => {
    const {
      heading,
      onRevert,
      doesHaveEditableClauseHeading,
      unsetEditableClauseHeading,
    } = this.props;
    this.setState(
      () => ({isControlButtonClicked: true}),
      () => {
        onRevert(heading);
        if (doesHaveEditableClauseHeading) {
          unsetEditableClauseHeading();
        }
      },
    );
  };

  createHeadingEditorRef = node => (this.headingEditorRef = node);
  createHeadingTextRef = node => (this.headingTextRef = node);
}

export default HeadingEditor;

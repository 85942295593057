import React, {useState} from "react";

import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";

import SwitchStyled from "common_components/inputs/switch_styled";

import DefinitionsBlock from "./definitions_block";

const styles = {
  container: {
    fontSize: 12,
    color: "#33332d",
  },
  headerContainer: {
    padding: "4px 1em",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerLabel: {
    display: "flex",
    alignItems: "center",
    userSelect: "none",
    msUserSelect: "none",
    fontWeight: 600,
  },
  openIcon: {
    height: 16,
    width: 16,
    color: "#9e9e9e",
    cursor: "pointer",
  },
  bodyContainer: {},
};

const useSelectStyles = makeStyles({
  root: {
    borderRadius: 0,
    background: "white",
    "& .MuiOutlinedInput-input": {
      padding: "9px 32px 9px 6px",
    },
  },
  select: {backgroundColor: "#fff", padding: "0.75em"},
  selectValueContainer: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 13,
    fontWeight: 400,
    borderRadius: 0,
  },
  selectItem: {display: "inherit"},
});

function SubstituteDefinitions(props) {
  const {
    substitutionMap,
    optionsMap,
    activeSubstitutions,
    otherSubstitutions,
    noSubstitutions,
    areDefinitionsSubstituted = false,
    triggerSubstituteDefinitions,
    updateSubstitutionMapValue,
    precedentTerms,
    onPrecedentTermUpdate,
    isFlipChecklistIcons,
    whatToSubstitute,
    updateWhatToSubstitute,
    whatToSubstituteTypes,
    whatToSubstituteStats,
    precedentDefinitions,
  } = props;
  const foundTerms = Object.keys(substitutionMap);

  const [showBody, updateShowBody] = useState(false);
  function triggerShowBody() {
    if (!showBody && !areDefinitionsSubstituted) {
      triggerSubstituteDefinitions(null, true);
    }
    updateShowBody(!showBody);
  }

  const classes = useSelectStyles();

  function renderWhatToSubstituteSelector() {
    if (!showBody) {
      return null;
    }
    const seletedItem = whatToSubstituteTypes.find(
      wtsType => wtsType.type === whatToSubstitute,
    );
    function onChange(e) {
      updateWhatToSubstitute(e.target.value);
    }
    return (
      <div style={{padding: "15px"}}>
        <FormControl variant="outlined" style={{width: "100%"}}>
          <InputLabel
            htmlFor="what-to-substitute"
            style={{padding: "0px 4px", top: -8, left: -16}}
          >
            What to substitute
          </InputLabel>
          <Select
            value={whatToSubstitute}
            onChange={onChange}
            className={classes.root}
            inputProps={{id: "what-to-substitute"}}
            renderValue={() => (
              <span className={classes.selectValueContainer}>
                <span className={classes.selectValueLabel}>
                  {seletedItem.label}
                </span>
              </span>
            )}
          >
            {whatToSubstituteTypes.map((wtsType, wtsTypeIndex) => (
              <MenuItem
                key={wtsTypeIndex}
                value={wtsType.type}
                className={classes.selectItem}
              >
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <div>{wtsType.label}</div>
                  <div>{`${whatToSubstituteStats[wtsType.type]}`}</div>
                </div>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  function renderHeader() {
    const Icon = showBody ? RemoveIcon : AddIcon;
    if (props.canOpen) {
      return (
        <div
          className="subsitute-definitions-header"
          style={styles.headerContainer}
        >
          <div
            onClick={triggerShowBody}
            style={{
              ...styles.headerLabel,
              cursor: disabled ? "default" : "pointer",
            }}
          >
            <Icon style={styles.openIcon} />
            <div style={{marginLeft: 6}}>{`Substitute Definitions (${
              foundTerms.length
            })`}</div>
          </div>

          <div style={{display: "flex", alignItems: "center"}}>
            <div style={{marginRight: 2}}>
              {areDefinitionsSubstituted ? "On" : "Off"}
            </div>
            <SwitchStyled
              checked={areDefinitionsSubstituted}
              onChange={triggerSubstituteDefinitions}
            />
          </div>
        </div>
      );
    }
    const disabled = !foundTerms.length;
    return (
      <div
        className="subsitute-definitions-header"
        style={{
          ...styles.headerContainer,
          ...(isFlipChecklistIcons ? {} : {border: "1px solid #e0e0e0"}),
        }}
        onClick={
          disabled
            ? () => null
            : event =>
                triggerSubstituteDefinitions(event, !areDefinitionsSubstituted)
        }
      >
        <div
          style={{
            ...styles.headerLabel,
            cursor: disabled ? "default" : "pointer",
            ...(foundTerms.length ? {} : {color: "#888"}),
          }}
        >
          {`Substitute Definitions (${foundTerms.length})`}
        </div>

        <div style={{display: "flex", alignItems: "center"}}>
          <SwitchStyled
            disabled={disabled}
            checked={areDefinitionsSubstituted}
          />
        </div>
      </div>
    );
  }

  const commonDefinitionBlockProps = {
    substitutionMap,
    optionsMap,
    precedentTerms,
    areDefinitionsSubstituted,
    onPrecedentTermUpdate,
    updateSubstitutionMapValue,
    precedentDefinitions,
  };

  function renderBody() {
    if (!showBody) {
      return null;
    }
    return (
      <div style={{border: "1px solid #eee"}}>
        {renderWhatToSubstituteSelector()}
        <DefinitionsBlock
          {...commonDefinitionBlockProps}
          terms={activeSubstitutions}
          title="Active substitutions"
          openByDefault={true}
        />
        <DefinitionsBlock
          {...commonDefinitionBlockProps}
          terms={otherSubstitutions}
          title="Other substitutions"
          openByDefault={true}
        />
        <DefinitionsBlock
          {...commonDefinitionBlockProps}
          terms={noSubstitutions}
          title="No substitution required"
          openByDefault={false}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        ...styles.container,
        background: isFlipChecklistIcons ? "#f4f4f4" : "#eeeeee",
      }}
    >
      {renderHeader()}
      {renderBody()}
    </div>
  );
}

export default SubstituteDefinitions;

import _ from "underscore";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import Run from "./run";

const useStyles = makeStyles({
  paragraph: {
    margin: ".5em",
  },
});

export default function Paragraph({r}) {
  const classes = useStyles();

  if (_.isArray(r) && r.length) {
    return (
      <div className={classes.paragraph}>
        {r.map((row, index) => <Run key={index} {...row} />)}
      </div>
    );
  }

  if (_.isObject(r)) {
    return (
      <div className={classes.paragraph}>
        <Run {...r} />
      </div>
    );
  }

  return null;
}

function decodeURIPart(part) {
  return decodeURIComponent(part || "").trim();
}

export function parseQuery(query): Record<string, string> {
  const params = {};
  const trimmedQuery = query.trim();
  if (trimmedQuery) {
    const justQuery =
      trimmedQuery.charAt(0) === "?" ? trimmedQuery.substring(1) : trimmedQuery;
    const paramPairs = justQuery.split("&");
    if (paramPairs.length) {
      paramPairs.forEach(paramPair => {
        const [decodedName, decodedValue] = paramPair
          .split("=")
          .map(decodeURIPart);
        const isArray =
          Object.keys(params).find(name => name === decodedName) !== undefined;
        if (isArray) {
          if (Array.isArray(params[decodedName])) {
            params[decodedName].push(decodedValue);
          } else {
            params[decodedName] = [params[decodedName], decodedValue];
          }
        } else {
          params[decodedName] = decodedValue;
        }
      });
    }
  }
  return params;
}

export function buildQuery(params) {
  const paramPairs: string[] = [];
  for (const name in params) {
    if ({}.hasOwnProperty.call(params, name)) {
      if (Array.isArray(params[name])) {
        params[name].forEach(param => {
          paramPairs.push(`${name}=${param}`);
        });
      } else {
        paramPairs.push(`${name}=${params[name]}`);
      }
    }
  }
  return paramPairs.length ? `?${paramPairs.join("&")}` : "";
}

import React from "react";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";

function ClausesAreTextualDialog(props) {
  const actions = [
    <FlatButton
      label="OK"
      key="clauses-are-textual-dialog-ok-button"
      secondary={true}
      // we use onMouseDown instead of onClick here because when new clause is added and
      // GUI editor is shown, clicking on the Dialog OK button (with onClick event handler)
      // hides the gui editor as it listens for onClick event outside the editor to hide it
      onMouseDown={props.onDismiss}
    />,
  ];
  return (
    <Dialog
      className="dialog"
      title="Cannot update clause numbering"
      onRequestClose={props.onDismiss}
      open={true}
      actions={actions}
      bodyStyle={{overflowY: "inherit"}}
      modal={false}
    >
      <div style={{textAlign: "justify"}}>
        This document does not have automatic numbering on clauses. We are
        working to handle such documents, but in the meantime, document
        numbering will need to be updated manually.
      </div>
    </Dialog>
  );
}

export default ClausesAreTextualDialog;

// import pluralise from "./utils/pluralise";
import _ from "underscore";
import Handlebars from "handlebars";
import JSONPath from "JSONPath";

import registerHandlebarsHelpers from "./register";

function getValue(obj, path) {
  return JSONPath.eval(obj, `$.${path}`)[0];
}

function listHelper(list, templateText, ..._keys) {
  registerHandlebarsHelpers();
  const options = _keys.pop();
  const keys = {};
  for (let index = 0; index < _keys.length; index += 2) {
    const path = _keys[index + 1];
    keys[_keys[index]] = path;
  }

  const template = Handlebars.compile(templateText);
  const result = list.map(item => {
    const valueArr = Object.keys(keys).map(key => [
      key,
      getValue(item, keys[key]),
    ]);
    const values = _.object(valueArr);
    return template({...(options.data && options.data.root), ...values});
  });
  return result;
}

export default listHelper;

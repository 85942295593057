import _ from "underscore";

import addPriorityToClauses from "./add_priority_to_clauses";

export default function(clauses, clausePriorities, issueId) {
  return _.sortBy(
    addPriorityToClauses(clauses, clausePriorities, issueId),
    clause => clause?.clausePriority?.priority ?? 9999999,
  );
}

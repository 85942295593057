import _ from "underscore";

import calculateTopics from "utils/topic/calculate_topics";

export function mapClauseTopic(clauseTopic, topicsById, topicMasksById) {
  return {
    id: clauseTopic.topic_id
      ? clauseTopic.topic_id
      : `m_${clauseTopic.topic_mask_id}`,
    path: clauseTopic.path,
    reference: clauseTopic.reference,
    clauseTopic,
    topic:
      topicsById[clauseTopic.topic_id] ||
      topicMasksById[clauseTopic.topic_mask_id],
  };
}

const calculateDocumentTopics = _.memoize(
  (
    clause,
    masks,
    topicsById,
    topicMasksById,
    clauseReference = "",
    preventRecurse = false,
  ) => {
    const topics = [];
    function getTopics(node, parentReference) {
      const reference = node.reference || parentReference;
      if (node.topics) {
        node.topics.forEach(topic => {
          if (!topic.is_deleted && topicsById[topic.topic_id]) {
            topics.push({
              path: node.path,
              reference,
              topic: {
                id: `${node.path}_${topic.topic_id}`,
                ...topic,
                topiccategory_id: topicsById[topic.topic_id].topiccategory_id,
              },
            });
          }
        });
      }
      if (node.clauseNodes && !preventRecurse) {
        node.clauseNodes.forEach(child => getTopics(child, reference));
      }
    }
    getTopics(clause.nodes, clauseReference);
    const clauseTopics = topics.map(topic => ({
      ...topic.topic,
      path: topic.path,
      reference: topic.reference,
    }));
    return calculateTopics(clauseTopics, true, masks, topicsById).map(
      clauseTopic => mapClauseTopic(clauseTopic, topicsById, topicMasksById),
    );
  },
  (...args) => JSON.stringify([args[0].id, args[5]]),
);

export default calculateDocumentTopics;

import React, {useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {grey} from "@material-ui/core/colors";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import {getUsername} from "utils/user";
import CommentMenu from "./comment_menu";
import CommentTextEditor from "./comment_text_editor";

const useStyles = makeStyles({
  comment: props => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: "1em",
    width: "100%",
    backgroundColor: props.comment.external ? "#f1f5ff" : "transparent",
  }),
  heading: {
    marginTop: ".3em",
    fontSize: ".8em",
    color: grey[700],
    "& span": {
      fontStyle: "italic",
      marginRight: ".5em",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiFormControlLabel-label": {
      fontSize: ".8em",
      color: grey[700],
    },
  },
  text: {
    width: "100%",
    fontSize: ".9em",
  },
  editor: {
    margin: ".5em auto",
  },
});

export default function Comment(props) {
  const {
    comment,
    onDelete,
    onUpdate,
    hasDeletePermission,
    hasEditPermission,
  } = props;
  const [text, setText] = useState(null);
  const classes = useStyles(props);
  const author = getUsername(comment);
  const date = new Date(comment.creation_date).toLocaleString("en-GB", {
    timeZone: "UTC",
  });
  const isEditMode = text !== null;
  const onCommentSave = () => {
    onUpdate({text});
    setText(null);
  };
  return (
    <div className={classes.comment}>
      <div className={classes.topRow}>
        <div className={classes.heading}>
          <span>{author}</span>
          <span>@</span>
          <span>{date}</span>
        </div>
        {hasEditPermission && (
          <FormControlLabel
            control={
              <Switch
                size="small"
                color="primary"
                checked={comment.external}
                onChange={() => onUpdate({external: !comment.external})}
              />
            }
            label="External"
          />
        )}
      </div>
      <div className={classes.row}>
        {isEditMode ? (
          <CommentTextEditor
            id="edit-comment"
            label="Edit Comment"
            confirmLabel="Save"
            declineLabel="Cancel"
            text={text}
            className={classes.editor}
            onChange={event => setText(event.target.value)}
            onConfirm={onCommentSave}
            onDecline={() => setText(null)}
          />
        ) : (
          <div className={classes.text}>{comment.text}</div>
        )}
        <CommentMenu
          onEdit={() => setText(comment.text)}
          onDelete={onDelete}
          hasEditPermission={hasEditPermission}
          hasDeletePermission={hasDeletePermission}
        />
      </div>
      <Divider />
    </div>
  );
}

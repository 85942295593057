import _ from "underscore";

const getRouteIds = _.memoize(
  router => {
    if (!router) {
      return {};
    }
    const location = router.location ? "location" : "locationBeforeTransitions";
    if (!router[location]) {
      return {};
    }
    return router[location].pathname.split("/").reduce(
      (state, route) => {
        const id = parseInt(route, 10);
        if (state.last) {
          state.ids[`${state.last}Id`] = id.toString() === route ? id : route;
          state.last = null;
        } else {
          state.last = route;
        }
        return state;
      },
      {ids: {}, last: null},
    ).ids;
  },
  router => {
    const location =
      router && router.location ? "location" : "locationBeforeTransitions";
    return JSON.stringify([
      location,
      router && router[location] && router[location].pathname,
    ]);
  },
);

export default getRouteIds;

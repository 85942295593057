import {observableFromStore} from "redux-rx";

let observer;

export default function(store) {
  if (!observer) {
    observer = observableFromStore(store);
  }
  return observer;
}

export function reset() {
  observer = null;
}

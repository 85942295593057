import React, {useState} from "react";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import FormatSizeIcon from "@material-ui/icons/FormatSize";

import ZoomControl from "common_components/zoom_control";

import defaultZoom from "../../../constants/default_zoom";

function ZoomMenu(props) {
  const {zoom} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function updateZoomItem(key) {
    return function(newValue) {
      props.onZoomUpdate({
        ...zoom,
        [key]: newValue,
      });
    };
  }

  function resetZoom() {
    Object.keys(sessionStorage).forEach(itemName => {
      if (
        itemName.startsWith("resizable_block.document_detail.right_hand_menu")
      ) {
        sessionStorage.removeItem(itemName);
      }
    });
    props.onZoomUpdate(defaultZoom);
  }

  return (
    <>
      <IconButton onClick={handleClick} size={props.buttonSize}>
        <FormatSizeIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div>
          <div
            style={{
              marginTop: "10px",
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            Zoom
          </div>
          <ZoomControl
            label="Document"
            name="document"
            value={zoom.document}
            onChange={updateZoomItem("document")}
            step={0.05}
            minValue={0.8}
            maxValue={1.45}
          />
          <ZoomControl
            label="Checklist"
            name="checklist"
            value={zoom.checklist}
            onChange={updateZoomItem("checklist")}
            containerStyle={{marginBottom: "0px"}}
            step={0.2}
            minValue={0.6}
            maxValue={2.2}
          />
          <Button
            variant="contained"
            color="primary"
            style={{
              display: "block",
              margin: "6px 14px 12px 14px",
              fontSize: "16px",
              borderRadius: 0,
              width: 148,
            }}
            onClick={resetZoom}
          >
            Reset Zoom
          </Button>
        </div>
      </Popover>
    </>
  );
}

export default ZoomMenu;

import pad from "./pad";

function padClausepartIds(clausepartId) {
  let mainRef;
  let rest;
  const dotLocation = clausepartId.indexOf(".");
  if (dotLocation > 0) {
    mainRef = clausepartId.substring(0, dotLocation);
    rest = clausepartId.substring(dotLocation + 1);
  } else {
    mainRef = clausepartId;
    rest = "";
  }
  const sortReference = `${pad(mainRef, 4, "0")}.${rest}`;
  return sortReference;
}

export default padClausepartIds;

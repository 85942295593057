import _ from "underscore";
import capitalize from "utils/capitalize";

function groupClausesByHeadings(clauses, documentHeadings, documentSections) {
  const withHeadings = {};
  const withoutHeadings = [];
  (clauses || []).forEach(clause => {
    const sectionId = clause.clause_section_id || clause.section_id;
    const section =
      (documentSections || []).find(section => section.id === sectionId) || {};
    const clauseReference = clause?.clause_reference?.toString() ?? "";
    const referenceParts = clauseReference ? clauseReference.split(".") : [];
    const referenceParts0 = referenceParts[0] || "";
    const referenceParts1 = referenceParts[1] || "";
    const heading =
      (documentHeadings || []).find(
        _heading =>
          sectionId === _heading.section_id &&
          _heading.reference.indexOf(".") === -1 &&
          _heading.reference === referenceParts0,
      ) || {};
    const order = [
      section.file_index,
      section.order,
      referenceParts0.padStart(4, "0"),
    ]
      .filter(item => item)
      .join("_");
    const subHeading =
      (documentHeadings || []).find(
        _heading =>
          sectionId === _heading.section_id &&
          _heading.reference.indexOf(".") !== -1 &&
          _heading.reference === `${referenceParts0}.${referenceParts1}`,
      ) || {};
    const {headingKey, fullHeadingText, reference, text} = getFullHeading(
      heading,
      subHeading,
      section,
    );
    const tidiedClause = {
      ...clause,
      reference: tidyReference(clause, section),
    };
    if (headingKey) {
      if (!withHeadings[headingKey]) {
        withHeadings[headingKey] = {
          hasHeading: true,
          reference,
          text,
          headingText: fullHeadingText,
          items: [tidiedClause],
          order,
        };
      } else {
        withHeadings[headingKey].items = [
          ...withHeadings[headingKey].items,
          tidiedClause,
        ];
      }
    } else {
      withoutHeadings.push({
        hasHeading: false,
        item: tidiedClause,
        order,
      });
    }
  });
  const result = _.chain([...withoutHeadings, ...Object.values(withHeadings)])
    .map(group => ({
      ...group,
      items: _.sortBy(group.items, item => item.clause_order),
    }))
    .sortBy(group => parseInt(group.order.split("_")[2], 10))
    .sortBy(group => parseInt(group.order.split("_")[1], 10))
    .sortBy(group => parseInt(group.order.split("_")[0], 10))
    .value();
  return result;
}

function tidyReference(clause, section) {
  if (section.reference === "background") {
    return clause.reference.replace(/^b/, "");
  }
  if (section.reference === "title_parties") {
    return "";
  }
  return clause.reference;
}

function clearHeadingText(headingText) {
  if (!headingText) {
    return "";
  }
  return headingText.replace(/:$/, "");
}

function getFullHeading(heading = {}, subHeading = {}, section = {}) {
  const sectionText =
    section && section.reference && section.reference !== "main"
      ? section.reference
          .split("_")
          .map(part => capitalize(part))
          .join(" ")
      : "";
  const {reference: headingReference} = heading;
  const {reference: subHeadingReference} = subHeading;
  const reference = !section.is_unnumbered
    ? subHeadingReference
      ? subHeadingReference
      : headingReference || ""
    : "";
  const fullHeading = [
    sectionText,
    `${reference ? `${reference}.` : ""} ${clearHeadingText(heading.text)}`,
    clearHeadingText(subHeading.text),
  ]
    .filter(item => item.trim())
    .join(" - ");
  const fileIndex = (section.file_index ?? "-1").toString();
  return {
    headingKey: fullHeading.length
      ? `${fileIndex}_${fullHeading}`
      : fullHeading,
    fullHeadingText: fullHeading,
    reference,
    text: !section.is_unnumbered
      ? subHeading.text
        ? subHeading.text
        : heading.text || ""
      : "",
  };
}

export default groupClausesByHeadings;

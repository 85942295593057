import _ from "lodash";
import getItemReturnValue from "./utils/get_item_return_value";
import doesClauseReferenceMatch from "./utils/does_clause_reference_match";

const operators = ["=", ">=", "<=", ">", "<"];

function getReferences(
  reasonData = [],
  returnType,
  topicId,
  parameterId,
  value,
  _useReferences = false,
) {
  const useReferences = _useReferences === true;
  if (!topicId || typeof topicId === "object") {
    return [];
  }

  // we do 'not object' check as handlebars adds internal argument as the last
  // parameter to the helper function
  const hasParameterId = parameterId && typeof parameterId !== "object";
  const hasValue = value && typeof value !== "object";
  const matchingClauses = reasonData.filter(atom => {
    const byTopicStr = `topics.${String(topicId)}`;
    const byTopic = _.get(atom, byTopicStr);
    if (!byTopic) {
      return false;
    } else if (!hasParameterId) {
      return true;
    }

    if (hasParameterId) {
      const byTopicparameterStr = `parameters[${parameterId}]`;
      const byTopicparameter = _.get(byTopic, byTopicparameterStr);
      if (!byTopicparameter) {
        return false;
      } else if (!hasValue) {
        return true;
      }

      const topicparameterValues = _.get(byTopicparameter, "values");
      if (topicparameterValues) {
        const matchingOperator = operators.find(operator =>
          value.startsWith(operator),
        );
        if (matchingOperator) {
          return getComparatorValue(
            matchingOperator,
            value,
            topicparameterValues,
          );
        }
        const valuesString = Object.keys(topicparameterValues).join("");
        const regex = new RegExp(value);
        return regex.test(valuesString);
      }
    }
    return false;
  });
  const references =
    useReferences && matchingClauses.length
      ? reasonData.filter(atom =>
          matchingClauses.find(
            matchingAtom =>
              matchingAtom.references &&
              matchingAtom.references.find(reference => {
                return doesClauseReferenceMatch(atom.reference, reference.id);
              }),
          ),
        )
      : [];
  return _.chain(matchingClauses.concat(references))
    .map(item => getItemReturnValue(item, returnType))
    .uniq()
    .value();
}

function getComparatorValue(matchingOperator, value, topicparameterValues) {
  const remainingValue = parseInt(value.substring(matchingOperator.length), 10);
  const convertedValues = Object.keys(topicparameterValues).map(strValue =>
    getValue(strValue),
  );

  switch (matchingOperator) {
    case "=":
      return convertedValues.find(tpValue => tpValue === remainingValue);
    case ">=":
      return convertedValues.find(tpValue => tpValue >= remainingValue);
    case "<=":
      return convertedValues.find(tpValue => tpValue <= remainingValue);
    case ">":
      return convertedValues.find(tpValue => tpValue > remainingValue);
    case "<":
      return convertedValues.find(tpValue => tpValue < remainingValue);
  }
  return null;
}

function getValue(strValue) {
  try {
    try {
      const obj = JSON.parse(strValue);
      if (obj?.value) {
        return parseInt(obj.value, 10);
      }
      return null;
    } catch {
      // is not an object
    }
    return parseInt(strValue, 10);
  } catch {
    // is not a string
  }
  return null;
}

export default getReferences;

import editor from "utils/issues/editor/multi_selector";

export function typeFilter(type) {
  const typesToExclude = [
    "AND_ON_CLAUSE",
    "AND_ON_WHOLE_CLAUSE",
    "AND_ON_DOCUMENT",
  ];
  return !typesToExclude.includes(type);
}

export default {
  ...editor(typeFilter),
  typeFilter,
};

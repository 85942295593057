import {ClauseAtom, DocumentSection} from "common/types/document";
import _ from "underscore";

interface SortedClause extends ClauseAtom {
  order: string;
}

function findFirstApplicableClause(
  clauses: ClauseAtom[],
  documentSections: DocumentSection[],
): ClauseAtom | null {
  if (!clauses || clauses.length === 0) {
    return null;
  }

  // Convert each clause into a format that includes its sorting order
  const clausesWithOrder: SortedClause[] = clauses.map(clause => {
    const sectionId = clause.clause_section_id || clause.section_id;
    const section =
      (documentSections || []).find(section => section.id === sectionId) ||
      ({} as DocumentSection);
    const clauseReference = clause?.clause_reference?.toString() ?? "";
    const referenceParts = clauseReference.split(".");
    const referenceParts0 = referenceParts[0] || "";

    // Construct order string for sorting
    const order: string = [
      section.file_index?.toString().padStart(4, "0"),
      section.order?.toString().padStart(4, "0"),
      referenceParts0.padStart(4, "0"),
      clause.clause_order || 999999, // ensure clauses without a specific order come last
    ]
      .filter(item => item !== undefined && item !== null)
      .map(item => item.toString().padStart(4, "0")) // pad for consistent sorting
      .join("_");

    return {...clause, order};
  });

  // Sort clauses by their calculated order
  const sortedClauses = _.sortBy(clausesWithOrder, "order");

  // Return the first applicable clause
  return sortedClauses[0];
}

export default findFirstApplicableClause;

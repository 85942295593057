function joinListsDeep(first, ...args) {
  if (!first) {
    return [];
  }
  let result = [first];
  args.forEach(argList => {
    if (Array.isArray(argList)) {
      result = result.concat(argList);
    }
  });
  return result;
}

export default joinListsDeep;

function constructReportFilename(
  namePrefix,
  document,
  reportLabel,
  format = "docx",
) {
  const label = reportLabel.replace(/ /gi, "_");
  if (!document || !document.name) {
    return `${namePrefix ? `${namePrefix}_` : ""}${label}.${format}`;
  }
  const fullName = document.name;
  const lastDotIndex = fullName.lastIndexOf(".");
  const name =
    lastDotIndex > 0 ? fullName.substring(0, lastDotIndex) : fullName;
  return `${name}-${label}.${format}`;
}

export default constructReportFilename;

import UNINITIALISED from "utils/uninitialised";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function projectTasksReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.PROJECT_TASKS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PROJECT_TASKS_REORDER.SUCCESS: {
      return state.map(task => ({
        ...task,
        order: action.payload.order[task.id].order,
        last_edited: action.payload.last_edited,
      }));
    }
    case ActionTypes.PROJECT_TASK_CREATE.SUCCESS:
      return [
        ...state,
        {
          clauseReference: "",
          status: 1,
          ...action.payload,
        },
      ];
    case ActionTypes.TASK_UPDATE.REQUEST:
    case ActionTypes.TASK_UPDATE.SUCCESS: {
      return state.map(task => {
        if (task.id === action.payload.id) {
          return {...task, ...action.payload};
        }
        return task;
      });
    }
    case ActionTypes.TASK_DELETE.SUCCESS: {
      return state.filter(task => task.id !== action.payload.id);
    }
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

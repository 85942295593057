import * as colors from "@material-ui/core/colors";

function getIssueColorValue(colorName) {
  switch (colorName) {
    case "red":
      return "#EF5350";
    case "amber":
      return "#ffa000";
    case "grey":
      return colors.grey[500];
    case "green":
      return "#66BB6A";
    case "blue":
      return "#42a5f5";
    default:
      return "#00796b";
  }
}

export default getIssueColorValue;

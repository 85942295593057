import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import SidebarContext from "./context";

export default class Popover extends React.Component {
  constructor(props) {
    super(props);
    this.windowClickTimeoutId = null;
    this.rootNode = null;
    this.innerNode = null;
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowResize);
    setTimeout(() => {
      window.addEventListener("click", this.handleWindowClick, true);
    }, 0);
    this.setPosition();
  }

  componentWillUnmount() {
    clearTimeout(this.windowClickTimeoutId);
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("click", this.handleWindowClick, true);
  }

  componentDidUpdate() {
    this.setPosition();
  }

  handleWindowResize = () => {
    this.setPosition();
  };

  handleWindowClick = event => {
    if (this.rootNode) {
      if (!this.rootNode.contains(event.target)) {
        this.windowClickTimeoutId = setTimeout(() => {
          clearTimeout(this.windowClickTimeoutId);
          this.props.onHide();
        }, 0);
      }
    }
  };

  setPosition = () => {
    const {rootNode, innerNode} = this;
    const {parentNode} = this.props;
    if (rootNode && parentNode) {
      const parentRect = parentNode.getBoundingClientRect();
      const heightDiff =
        window.innerHeight -
        Math.min(rootNode.offsetHeight, this.props.maxHeight);
      const maxTop = heightDiff > 0 ? heightDiff : 0;
      rootNode.style.left = `${parentRect.right}px`;
      rootNode.style.top = `${Math.min(parentRect.top, maxTop)}px`;
      rootNode.style.height = "";
      rootNode.style.height = `${Math.min(
        window.innerHeight,
        this.props.maxHeight,
        innerNode.clientHeight + 2,
      )}px`;
    }
  };

  render() {
    return ReactDOM.createPortal(
      <div
        ref={node => (this.rootNode = node)}
        className={(() => {
          const classNames = [];
          const className = "app-sidebar-popover";
          classNames.push(className);
          if (this.context.isLight) {
            classNames.push(`${className}--light`);
          }
          return classNames.join(" ");
        })()}
      >
        <div
          ref={node => (this.innerNode = node)}
          className="app-sidebar-popover__inner"
        >
          {this.props.children}
        </div>
      </div>,
      document.body,
    );
  }
}

Popover.contextType = SidebarContext;

Popover.defaultProps = {
  maxHeight: 516,
};

Popover.propTypes = {
  maxHeight: PropTypes.number.isRequired,
  parentNode: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};

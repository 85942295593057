import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function updateDocumentIssues(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_ISSUES_UPDATE,
    (organisationId, projectId, documentId, documentIssues, updates, options) =>
      requestor
        .put(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}` +
            `/document/${documentId}` +
            "/document_issues/",
          {
            document_issues: documentIssues,
            updates,
            options: typeof options === "object" ? options : {},
          },
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}

import React from "react";

import IconButton from "@material-ui/core/IconButton";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import Tooltip from "@material-ui/core/Tooltip";
import {hasPermission} from "utils/permissioner";

const ExternalClauseLibraryButton = ({user, showLlmChat, toggleLlmChat}) => {
  const canViewLlmChat = hasPermission("can-perform-per-doc-llm-queries", user);
  if (!canViewLlmChat) {
    return null;
  } else {
    return (
      <Tooltip title={"Q&A with a LLM model about the contract"}>
        <IconButton
          style={showLlmChat ? {backgroundColor: "#aaa"} : {}}
          onClick={() => toggleLlmChat()}
        >
          <QuestionAnswerIcon />
        </IconButton>
      </Tooltip>
    );
  }
};

export default ExternalClauseLibraryButton;

import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import {GetLlmRunsAction} from "../actions/llm_runs_fetch";
import actionTypes from "../constants/action_types";
import {LlmRunInfo} from "common/flowmaster/types/llm_run";

export default function workflowsReducer(
  state: MaybeUninitialised<LlmRunInfo[]> = UNINITIALISED,
  action: GetLlmRunsAction,
): typeof state {
  if (action.type === actionTypes.LLM_RUNS_FETCH.SUCCESS) {
    return (action as GetLlmRunsAction).payload;
  }
  return state;
}

import basePath from "base_path";
import ActionTypes from "../../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function reprocessDocumentRoles(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_ROLES_REPROCESS,
    (organisationId, projectId, documentId, processManualRoles) =>
      requestor
        .patch(
          `${basePath}/organisation/${organisationId}` +
            `/project/${projectId}/document/${documentId}/roles`,
          {process_manual_roles: processManualRoles},
          {withCredentials: true},
        )
        .then(response => response.data),
  );
}

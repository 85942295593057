/* eslint-disable no-unused-vars*/
import React from "react";
/* eslint-enable no-unused-vars*/

export default function renderError(errors, key) {
  const message = errors && errors[key];
  if (message) {
    return (
      <div
        style={{margin: "1em", color: "red"}}
        className={`error error-${key}`}
      >
        {message}
      </div>
    );
  }
  return null;
}

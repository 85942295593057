import React from "react";

import MatchSection from "../../match_section";
import findMatches from "./find_matches";

function Issues(props) {
  return (
    <MatchSection
      items={props.items}
      sectionTitle="Issues"
      onSearch={props.onIssueClick}
      disableMatchAll={true}
      isLast={props.isLast}
      appType={props.appType}
    />
  );
}

Issues.findMatches = props => findMatches(props.searchValue, props.issues);

export default Issues;

import _ from "underscore";
import ActionTypes from "../constants/action_types";
import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";

export default function issueComparisonDataReducer(
  state = UNINITIALISED,
  action,
) {
  switch (action.type) {
    case ActionTypes.ISSUE_COMPARISON_DATA_FETCH.SUCCESS:
      return _.pick(
        action.payload,
        "document_id",
        "issues",
        "clauseparts",
        "clauses",
        "parties",
        "sections",
        "deleted",
      );
    case ActionTypes.ISSUE_COMPARISON_DATA_CLEAR.SUCCESS:
    case ActionTypes.DOCUMENT_CLEAR.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

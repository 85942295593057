import * as TopicParameters from "plugins/topicparameters";

function reasonViewer(reason) {
  const result = [];
  Object.keys(reason.topics || {}).forEach(topicId => {
    if (topicId) {
      Object.keys(reason.topics[topicId]).forEach(parameterId => {
        if (parameterId) {
          reason.topics[topicId][parameterId].forEach((item, itemIndex) => {
            const basePath = `topics.[${topicId}].[${parameterId}].[${itemIndex}]`;
            const baseValue = reason.topics[topicId][parameterId][itemIndex];
            result.push(
              // eslint-disable-next-line import/namespace
              TopicParameters["monetary"].makeIssueReasonValue(
                basePath,
                baseValue,
              ),
            );
          });
        }
      });
    }
  });
  return result;
}

function getDefaultIssueRuleValues(rules) {
  const basePath = `topics.[${rules.topic_id}].[${rules.parameter_id}].[0]`;
  const values = [
    {
      label: "value",
      path: `${basePath}.value`,
      value: "",
    },
    {
      label: "symbol",
      path: `${basePath}.symbol`,
      value: "",
    },
    {
      label: "currency",
      path: `${basePath}.currency`,
      value: "",
    },
  ];
  return [{path: basePath, values}];
}

export default {
  reasonViewer,
  getDefaultIssueRuleValues,
};

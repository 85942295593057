import React from "react";
import _ from "underscore";
import Divider from "material-ui/Divider";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import AddIcon from "material-ui/svg-icons/content/add";
import IssuesetInfoWidget from "common_components/issueset_info_widget";

import IssuesetSelectorAutocomplete from "./issueset_selector_autocomplete";
import getDefaultIssuesetId from "utils/issuesets/get_default_issueset_id";
import getApplicableIssuesetIds from "utils/issuesets/get_applicable_issueset_ids";
import getProjectIssuesets from "utils/issuesets/get_project_issuesets";
import byId from "common/utils/by_id";
import issuesetUtils from "common/utils/issues/issueset_utils";

const styles = {
  menuItemIcon: {
    width: "20px",
    height: "20px",
  },
  menuItemWithLeftIcon: {
    paddingLeft: "52px",
  },
  dropDown: {
    fontWeight: 500,
    height: "auto",
  },
  dropDownLabel: {
    whiteSpace: "normal",
    lineHeight: 1.2,
    height: "auto",
    paddingRight: 48,
    paddingLeft: 6,
    color: "inherit",
    fontSize: 16,
  },
  dropDownIcon: {
    top: "50%",
    marginTop: -24,
    right: 0,
  },
  headerTitleDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  headerTitleLabel: {
    color: "#9ca1a5",
    fontSize: 12,
    paddingLeft: 6,
    lineHeight: 1.7,
  },
  issuesetSelectorWrapper: {
    fontWeight: 500,
    marginLeft: "0.3rem",
    display: "flex",
    alignItems: "center",
  },
  checklistWrapper: {
    fontWeight: 500,
    marginLeft: "0.3rem",
  },
};

class HeaderTitle extends React.Component {
  componentDidUpdate() {
    const {document: currentDocument, project} = this.props;
    if (
      !this.props.useAllIssuesets &&
      this.props.project.select_checklist_on_upload &&
      currentDocument.contract_type.id === project.default_contract_type.id &&
      currentDocument.issuesets &&
      currentDocument.issuesets.length > 0 &&
      !currentDocument.issuesets.includes(this.props.currentIssueset)
    ) {
      const defaultIssuesetId = getDefaultIssuesetId(
        this.props.document,
        this.props.project,
        this.props.contractTypesById,
      );
      this.props.onCurrentIssuesetChange(null, null, defaultIssuesetId);
    }
  }

  render() {
    const {
      document,
      project,
      contractTypesById,
      hideFrontPage,
      currentIssueset,
      showSelectChecklistDialog,
      isFlipChecklistIcons,
      user = {},
    } = this.props;
    const {select_checklist_on_upload: selectChecklistOnUpload} = project;

    const dropDownMenuStyles = {
      backgroundColor: isFlipChecklistIcons ? "#fff" : "#333d46",
    };

    const dropDownMenuItemStyles = {
      fontSize: 14,
      color: isFlipChecklistIcons ? "#424242" : "#fff",
    };

    if (document && project) {
      if (project.document_type_id === 4) {
        return (
          <DropDownMenu
            value={this.props.viewMode}
            onChange={this.props.onViewModeChange}
            underlineStyle={{display: "none"}}
            style={styles.dropDown}
            labelStyle={styles.dropDownLabel}
            iconStyle={styles.dropDownIcon}
            menuStyle={dropDownMenuStyles}
            menuItemStyle={dropDownMenuItemStyles}
          >
            <MenuItem value={"checklist"} primaryText="Checklist" />
            {!hideFrontPage && (
              <MenuItem value={"FORM"} primaryText="Front Page" />
            )}
            <MenuItem
              value={"PLANNING_CERTIFICATE"}
              primaryText="Planning Certificate"
            />
            <MenuItem value={"TITLE_SEARCH"} primaryText="Title Search" />
          </DropDownMenu>
        );
      }
      const {getIssuesetsFromContractTypes, projects} = this.props;
      const selectedProjectIssuesetIds = getApplicableIssuesetIds(
        document,
        project,
        contractTypesById,
        {
          get_issuesets_from_contract_types: getIssuesetsFromContractTypes,
        },
      );
      const issuesetsById = issuesetUtils.getIssuesetsById(
        contractTypesById,
        false,
        true,
      );
      const selectedProjectIssuesets = issuesetUtils.constructGroupedIssuesetSelectorItems(
        selectedProjectIssuesetIds,
        issuesetsById,
        Boolean(getIssuesetsFromContractTypes), // show contract type name
      );
      let baseResultIssuesets = selectedProjectIssuesets;
      const isOnIssueDetailPage = getIssuesetsFromContractTypes && projects;
      if (isOnIssueDetailPage) {
        // if code goes here we're dealing with issue detail page
        // as on this page we show all hierarchical issuesets per contract type

        // 1) get all other issuesets not related to current project
        const nonCurrentIssuesetIds = Object.keys(issuesetsById).reduce(
          (result, issuesetIdStr) => {
            const issuesetId = parseInt(issuesetIdStr, 10);
            if (!selectedProjectIssuesetIds.includes(issuesetId)) {
              result.push(issuesetId);
            }
            return result;
          },
          [],
        );
        // 2) group all those issuesets by contract type
        const nonCurrentIssuesetIdsByCtId = nonCurrentIssuesetIds.reduce(
          (result, issuesetId) => {
            const issueset = issuesetsById[issuesetId];
            if (!result[issueset.contract_type_id]) {
              result[issueset.contract_type_id] = [issuesetId];
            } else {
              result[issueset.contract_type_id].push(issuesetId);
            }
            return result;
          },
          {},
        );
        // 3) construct issuesetData per each contract type
        const nonCurrentIssuesetsPerCtId = {};
        Object.keys(nonCurrentIssuesetIdsByCtId).forEach(ctId => {
          const issuesetIds = nonCurrentIssuesetIdsByCtId[ctId];
          nonCurrentIssuesetsPerCtId[
            ctId
          ] = issuesetUtils.constructGroupedIssuesetSelectorItems(
            issuesetIds,
            issuesetsById,
            true,
          );
        });
        Object.keys(nonCurrentIssuesetsPerCtId).forEach(ctId => {
          baseResultIssuesets = baseResultIssuesets.concat(
            nonCurrentIssuesetsPerCtId[ctId],
          );
        });
        // 4) adding projects of contract type to each item
        baseResultIssuesets.forEach(issueset => {
          issueset.projects =
            contractTypesById[issueset.contract_type_id].projects;
        });
        // 5) adding a flag to items if the item issueset is in currently
        // selected project's contract type (we show in bold those
        // elements in selected project)

        // 5.1) getting contract_type_id of selectedProjectIssuesets
        const selectedContractTypeId =
          selectedProjectIssuesets[0].contract_type_id;
        // 5.2) adding the flag
        baseResultIssuesets.forEach(issueset => {
          if (issueset.contract_type_id === selectedContractTypeId) {
            issueset.isInSelectedProject = true;
          }
        });
      }

      const dialogIssuesets = getProjectIssuesets(project, contractTypesById);

      let resultIssuesets = baseResultIssuesets;
      const shouldShowDialogIssuesets =
        selectChecklistOnUpload &&
        showSelectChecklistDialog &&
        dialogIssuesets.length > 1;
      if (shouldShowDialogIssuesets) {
        resultIssuesets = [
          {value: "other_checklists_dialog"},
          {value: "divider"},
          ...resultIssuesets,
        ];
      }

      const issuesetsOfSelectedIssue = this.props.issue
        ? Array.from(
            // Use Map constructor to deduplicate issuesets...
            new Map(
              this.props.issue.contract_types
                .flatMap(ct =>
                  ct.issuesets.map(issueset => ({
                    ...issueset,
                    contract_type_id: ct.contract_type_id,
                  })),
                )
                // ...by id
                .map(issueset => [issueset.id, issueset]),
            ).values(),
          )
        : undefined;

      if (baseResultIssuesets.length > 5) {
        return (
          <IssuesetSelectorAutocomplete
            issuesets={
              // If an issue is selected, filter by its issuesets
              issuesetsOfSelectedIssue
                ? resultIssuesets.filter(issueset =>
                    issuesetsOfSelectedIssue.some(
                      ({id, contract_type_id}) =>
                        id === issueset.value ||
                        (issueset.value === null &&
                          issueset.contract_type_id === contract_type_id),
                    ),
                  )
                : resultIssuesets
            }
            currentIssueset={currentIssueset}
            isOnIssueDetailPage={isOnIssueDetailPage}
            onCurrentIssuesetChange={this.props.onCurrentIssuesetChange}
            getIssuesetsFromContractTypes={getIssuesetsFromContractTypes}
            onIssuesetItemProjectAndDocumentChange={
              this.onIssuesetItemProjectAndDocumentChange
            }
            showSelectChecklistDialog={showSelectChecklistDialog}
            isFlipChecklistIcons={isFlipChecklistIcons}
            user={user}
          />
        );
      } else if (baseResultIssuesets.length < 2 && !shouldShowDialogIssuesets) {
        const issueset = baseResultIssuesets?.[0] ?? {
          is_star: false,
          is_hot: false,
        };
        if (!user.is_admin) {
          return null;
        }
        return (
          <div style={styles.issuesetSelectorWrapper}>
            {baseResultIssuesets.length === 0
              ? "Checklist"
              : baseResultIssuesets[0].label ||
                baseResultIssuesets[0].primaryText}

            <IssuesetInfoWidget
              isInStarIssueset={issueset.is_star}
              isHot={issueset.is_hot}
              ignoreIssueStar={true}
            />
          </div>
        );
      }

      const resultItems = resultIssuesets.map((item, index) => {
        if (item.value === "other_checklists_dialog") {
          return (
            <MenuItem
              key="view-other-checklists"
              primaryText="View other checklists"
              onClick={showSelectChecklistDialog}
              leftIcon={<AddIcon style={styles.menuItemIcon} />}
              innerDivStyle={styles.menuItemWithLeftIcon}
              value={"view_other_checklists"}
            />
          );
        } else if (item.value === "divider") {
          return <Divider key="divider" />;
        }
        return (
          <MenuItem
            key={index}
            value={item.value}
            disabled={
              isOnIssueDetailPage
                ? item.disabled || !item.projects
                : item.disabled
            }
            primaryText={
              <div
                style={{
                  fontWeight: item.isInSelectedProject ? "600" : "400",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{paddingLeft: item.level * 15}} />
                <span>{item.primaryText}</span>
                {user.is_admin && (
                  <IssuesetInfoWidget
                    isInStarIssueset={item.is_star}
                    isHot={item.is_hot}
                    ignoreIssueStar={true}
                  />
                )}
                {isOnIssueDetailPage && item.level !== 0 && !item.projects && (
                  <span style={{fontStyle: "italic"}}>
                    {" (Not in any project)"}
                  </span>
                )}
              </div>
            }
            onClick={
              !item.isInSelectedProject &&
              item.projects &&
              getIssuesetsFromContractTypes
                ? this.onIssuesetItemProjectAndDocumentChange(item)
                : undefined
            }
          />
        );
      });

      return (
        <div style={styles.headerTitleDiv}>
          <div style={styles.headerTitleDiv}>Checklist</div>
          <DropDownMenu
            value={currentIssueset}
            onChange={this.props.onCurrentIssuesetChange}
            underlineStyle={{display: "none"}}
            style={styles.dropDown}
            labelStyle={styles.dropDownLabel}
            iconStyle={styles.dropDownIcon}
            menuStyle={dropDownMenuStyles}
            menuItemStyle={dropDownMenuItemStyles}
            disabled={this.props.disableIssuesetSelector}
          >
            {resultItems}
          </DropDownMenu>
        </div>
      );
    }
    return <div style={styles.checklistWrapper}>Checklist</div>;
  }

  onIssuesetItemProjectAndDocumentChange = issueset => async () => {
    const {projects: itemProjects} = issueset;
    if (itemProjects.length === 0) {
      return;
    }
    const projectsById = byId(this.props.projects);
    let mostRecentProject = projectsById[itemProjects[0]];
    for (let i = 1; i < itemProjects.length; i++) {
      const itemProjectId = itemProjects[i];
      const itemProject = projectsById[itemProjectId];
      const currentMostRecentProjectCreationDate = new Date(
        mostRecentProject.creation_date,
      );
      const itemProjectCreationDate = new Date(itemProject.creation_date);
      if (itemProjectCreationDate > currentMostRecentProjectCreationDate) {
        mostRecentProject = itemProject;
      }
    }
    const result = await this.props.onProjectChange(mostRecentProject);
    if (!result || !result.value) {
      return;
    }
    const documents = _.sortBy(result.value, doc => -doc.creation_date);
    const mostRecentDocument = documents[0];
    if (mostRecentDocument) {
      await this.props.onDocumentChange(mostRecentDocument);
    }
    this.props.onCurrentIssuesetChange(null, null, issueset.value);
  };
}

export default HeaderTitle;

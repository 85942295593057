import Describer from "../../../utils/issues/issue_description/describer";

function render(issue, topics) {
  const describer = new Describer.IssueDescriber(issue, topics, "not");
  return `(NOT): Active when ${describer.printChildIssue(
    issue.rules,
  )} is not triggered`;
}

export default render;

import React from "react";
import CircularProgress from "material-ui/CircularProgress";

import {mainHeights} from "../constants/styles";

const maxWidth = "73rem";
const styles = {
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    flexGrow: 1,
    flexShrink: 1,
    minWidth: "30rem",
    height: `calc(100vh - ${mainHeights.mainHeaderHeight +
      mainHeights.documentHeaderHeight}rem)`,
    fontFamily: "Roboto, sans-serif",
  },
  midContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: "30rem",
  },
  header: {
    background: "rgb(33, 150, 243)",
    transition: "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms",
    boxSizing: "inherit",
    fontFamily: "Roboto, sans-serif",
    boxShadow:
      "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
    alignSelf: "stretch",
    textAlign: "center",
    maxWidth,
    fontSize: "24px",
    height: "2em",
    color: "white",
    fontWeight: 400,
    padding: "1rem 0",
  },
  innerContainer: {
    maxWidth,
    overflow: "auto",
  },
  noDocumentImages: {
    flexGrow: "1",
    textAlign: "center",
    padding: "4rem",
    fontSize: "36px",
    color: "lightgray",
  },
};
export default function ImagesPageView(props) {
  const {images} = props;
  if (!images) {
    return (
      <div style={styles.noDocumentImages}>
        <CircularProgress
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
          }}
        />
      </div>
    );
  }
  if (images.length === 0) {
    return (
      <div style={styles.noDocumentImages}>
        Document Page Images Unavailable
      </div>
    );
  }
  return (
    <div style={styles.mainContainer}>
      <div style={styles.midContainer}>
        <div style={styles.header}>{props.document.name}</div>
        <div style={styles.innerContainer}>
          {renderPageImages(props.images)}
        </div>
      </div>
    </div>
  );
}

function renderPageImages(images) {
  return images.map((imageData, index) => (
    <img
      key={index}
      src={`data:image/jpeg;base64, ${imageData}`}
      alt="Images"
      style={{maxWidth, width: "100%"}}
      id={`page-${index + 1}`}
    />
  ));
}

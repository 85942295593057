import React, {useState} from "react";
import ReportClauses from "./rag_report/report_clauses";
import {correctDocumentIssueManually} from "../../../routes/document_detail/components/document_detail";
import ClausesRenderer from "../../../utils/clauses/clauses_renderer";
import areClausesPresent from "./utils/are_clauses_present";
import {GroupedApplicableClauses} from "common/types/document";

type ClausesPanelProps = {
  applicableClauses: GroupedApplicableClauses;
} & {[key: string]: unknown};

const ClausesPanel = (props: ClausesPanelProps) => {
  const [isGuiEdited, setIsGuiEdited] = useState(false);
  const {
    issue,
    applicableClauses,
    applicableClausesReferenced,
    topicsById,
    onClauseClick,
    onHeadingClick,
    documentHeadings,
    documentSections,
    documentDefinitions,
    documentChanges,
    documentClauseparts,
    document,
    selectedReport,
    onManualCorrectDocumentIssue,
    user,
    editingReport,
    updateEditState,
    fileIndex,
    fileNames,
  } = props;

  const correctApplicableClause = newManualCorrections => {
    correctDocumentIssueManually(issue, {
      manual_corrections: newManualCorrections,
    });
  };
  const openTextInGuiEditor = () => setIsGuiEdited(true);
  const closeTextInGuiEditor = () => setIsGuiEdited(false);
  const getParameterNames = (parameterFilters, topicsById) => {
    const result = [];

    parameterFilters.forEach(({topic_id: topicId, parameters}) => {
      const topic = topicsById[topicId];
      if (topic) {
        parameters.forEach(paramId => {
          const foundParam = topic.parameters.find(
            param => param.id === paramId,
          );
          if (foundParam) {
            result.push(foundParam.name);
          }
        });
      }
    });
    return result;
  };
  const selectedParameterNames = getParameterNames(
    issue.parameter_filters || [],
    topicsById,
  );

  // filter out parameter clauses that are not selected in parameter_filters
  const filteredApplicableClauses = {};
  Object.keys(applicableClauses).forEach(topicOrParamName => {
    const clauses = applicableClauses[topicOrParamName];
    if (
      !clauses.isParameter ||
      selectedParameterNames.length === 0 ||
      (clauses.isParameter &&
        selectedParameterNames.find(
          paramName => paramName === topicOrParamName,
        ))
    ) {
      filteredApplicableClauses[topicOrParamName] = clauses;
    }
  });

  const baseProps = {
    onClauseClick,
    onHeadingClick,
    topicsById: topicsById || {},
    documentHeadings: documentHeadings || [],
    documentSections: documentSections || [],
    documentDefinitions: documentDefinitions || [],
    documentChanges: documentChanges || [],
    document: document || {},
    documentClauseparts: documentClauseparts || [],
    selectedReport,
    manualCorrections: issue.manual_corrections,
    correctApplicableClause,
    issue,
    user,
    editingReport,
    updateEditState,
    fileIndex,
    fileNames,
  };

  return (
    <>
      {props.issue.show_parties && renderParties(props)}

      {props.editingReport ? (
        <ReportClauses
          {...props}
          isGuiEdited={isGuiEdited}
          onManualCorrectDocumentIssue={onManualCorrectDocumentIssue}
          openTextInGuiEditor={openTextInGuiEditor}
          closeTextInGuiEditor={closeTextInGuiEditor}
        />
      ) : (
        <>
          {areClausesPresent(filteredApplicableClauses) ||
          !areClausesPresent(applicableClausesReferenced) ? (
            <ClausesRenderer
              key="applicable-clauses"
              {...baseProps}
              clauses={filteredApplicableClauses}
              disableNoClausesFoundMessage={props.issue.show_parties}
            />
          ) : null}
          {areClausesPresent(applicableClausesReferenced) ? (
            <ClausesRenderer
              key="applicable-clauses-referenced"
              styles={{marginTop: "0.4rem"}}
              {...baseProps}
              headingTitle="Referenced Clauses"
              clauses={applicableClausesReferenced}
            />
          ) : null}
        </>
      )}
    </>
  );
};

function renderParties(props) {
  return props.document.parties.length ? (
    <div>
      <span style={{fontWeight: 500}}>Parties</span>
      <div>
        {(props.document.parties ?? []).map(party => (
          <div
            key={party.id}
            style={{margin: "5px 0", cursor: "pointer"}}
            onClick={() => props.scrollToParties()}
          >
            <span style={{marginRight: "1em"}}>{party.name}</span>
            {party.term && <span>("{party.term}")</span>}
          </div>
        ))}
      </div>
    </div>
  ) : null;
}
export default ClausesPanel;

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";

export default function pageLoadLogAdd(requestor) {
  return createReduxPromiseAction(
    ActionTypes.PAGE_LOAD_LOG_ADD,
    (organisationId, page, start, end, pageData) => {
      return requestor
        .post(
          `${basePath}/organisation/${organisationId}/log/page_load`,
          {page, start, end, duration: end - start, page_data: pageData},
          {
            withCredentials: true,
          },
        )
        .then(response => {
          return response.data;
        });
    },
  );
}

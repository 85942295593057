import React from "react";
import {get} from "lodash";

import TextField from "material-ui/TextField";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import * as colors from "material-ui/styles/colors";

import ItemContainer from "../helper_components/item_container";
import EmailButton from "../helper_components/email_button";

import getEmailTextTemplate from "utils/templates/get_email_text_template";
import isIssueAlert from "common/utils/issues/is_issue_alert";
import {isIe11} from "utils/browser_test";
import getZoomedFontSize from "utils/get_zoomed_font_size";

const styles = {
  main: {
    padding: "0 1.5rem",
    zIndex: 2,
    marginTop: "12px",
  },
  header: {
    height: "3.6rem",
    fontWeight: 800,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headingIcon: {
    height: "15px",
    width: "15px",
    cursor: "pointer",
    fill: colors.grey700,
    margin: "0 0.1rem",
  },
  expandIcon: {
    height: "30px",
    width: "30px",
    fill: colors.blue200,
    cursor: "pointer",
  },
  body: {},
  textareaStyle: {
    fontWeight: 400,
    textAlign: "justify",
    wordBreak: "break-word",
    margin: 0,
  },
  textFieldMain: {
    width: "100%",
    padding: "1rem",
    boxSizing: "border-box",
    border: `1px solid ${colors.grey200}`,
    backgroundColor: "white",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0.4rem 0",
    ...(isIe11() ? {width: "100%"} : {}),
  },
};

function getBaseEmailText(props) {
  const {issue} = props;
  return isIssueAlert(issue)
    ? issue.user_text || getEmailTextTemplate(issue, props)
    : issue.user_text || "";
}

class EmailText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRevertDialogOpen: false,
      emailText: getBaseEmailText(props),
      isRequestPending: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {issue: prevIssue} = prevProps;
    const {issue: currentIssue} = this.props;
    if (
      this.state.isRequestPending &&
      prevIssue.user_text !== currentIssue.user_text
    ) {
      this.setState(() => ({isRequestPending: false}));
    }

    if (
      prevIssue &&
      currentIssue &&
      (prevIssue.id !== currentIssue.id ||
        prevIssue.user_text !== currentIssue.user_text)
    ) {
      this.setState(() => ({
        emailText: getBaseEmailText(this.props),
      }));
    }
  }

  render() {
    const {
      issue,
      isFlipChecklistIcons,
      showIssuesInChecklist,
      title,
      zoom,
    } = this.props;
    const {emailText} = this.state;

    const {userEmailText, templateEmailText} = this.getEmailTextData();
    const baseEmailText = getBaseEmailText(this.props);

    const isRevertEmailTextAvailable = userEmailText !== templateEmailText;
    const reportSettingsId = get(this.props.reportSettings, "id");
    const addAllItems = get(
      this.props.reportSettings,
      "add_all_items_to_report",
    );
    const issueActionState = (issue.action_state || {})[reportSettingsId];
    const isEditingAvailable =
      issueActionState === 1 ||
      ((issueActionState === null || issueActionState === undefined) &&
        addAllItems);
    return (
      <ItemContainer
        title={title}
        expandable={true}
        onExpandMenu={this.props.onOpen}
        isMenuExpanded={this.props.isOpen}
        containerStyles={this.props.containerStyles}
      >
        <div style={styles.body}>
          <TextField
            value={emailText || ""}
            multiLine
            name="emailText"
            ref={this.createEmailTextRef}
            textareaStyle={{
              ...styles.textareaStyle,
              fontFamily: isFlipChecklistIcons
                ? "Segoe UI, Roboto, sans-serif"
                : "Roboto, sans-serif",
              fontSize: getZoomedFontSize(13, "checklist", zoom),
              lineHeight: `${getZoomedFontSize(15, "checklist", zoom)}px`,
            }}
            style={styles.textFieldMain}
            underlineShow={false}
            onChange={this.onEmailTextChange}
            rows={4}
            rowsMax={4}
            disabled={!isEditingAvailable}
          />
          <div style={styles.buttonsContainer}>
            {this.renderButtons(
              emailText,
              baseEmailText,
              showIssuesInChecklist,
              issueActionState,
            )}
          </div>
          {this.renderRevertIssueDialog(
            isRevertEmailTextAvailable,
            isEditingAvailable,
            templateEmailText,
          )}
        </div>
      </ItemContainer>
    );
  }

  renderButtons = (
    emailText,
    baseEmailText,
    showIssuesInChecklist,
    issueActionState,
  ) => {
    const status = getStatus(issueActionState);
    if (!status) {
      return null;
    }
    const padding = {
      paddingLeft: "1em",
      paddingRight: "1em",
      minWidth: "3em",
      flexGrow: 1,
    };
    return status === "Outstanding" ? (
      [
        !showIssuesInChecklist && (
          <EmailButton
            key="left-button"
            onButtonClick={this.onLeftButtonClick}
            primary={false}
            disabled={
              this.state.isRequestPending || baseEmailText === emailText
            }
            buttonContainerStyles={{
              ...padding,
              flexGrow: 0.5,
              marginRight: "1rem",
            }}
          >
            Cancel
          </EmailButton>
        ),
        <EmailButton
          key="right-button"
          onButtonClick={this.onRightButtonClick}
          primary={true}
          disabled={
            this.state.isRequestPending || !emailText || !emailText.length
          }
          buttonContainerStyles={padding}
        >
          {emailText !== baseEmailText
            ? "Save"
            : this.props.reportSettings.add_all_items_to_report
              ? "Remove from Report"
              : "Add to Report"}
        </EmailButton>,
      ]
    ) : (
      <EmailButton
        key="revert-button"
        onButtonClick={this.onRevert}
        primary={true}
        disabled={this.state.isRequestPending}
        buttonContainerStyles={padding}
      >
        {this.getRevertButtonText(status)}
      </EmailButton>
    );
  };

  getRevertButtonText(status) {
    switch (status) {
      case "Rejected":
        return "Add to Report";
      case "Confirmed":
        return "Remove from Report";
      default:
        return "Revert Issue State";
    }
  }

  triggerShowEmailBody = () =>
    this.setState(prevState => ({
      isEmailBodyShown: !prevState.isEmailBodyShown,
    }));

  onEmailTextChange = e => {
    const emailText = e.target.value;
    this.setState(() => ({emailText}));
  };

  renderRevertIssueDialog = (
    isRevertAvailable,
    isEditingAvailable,
    templateEmailText,
  ) => {
    if (!isRevertAvailable || !isEditingAvailable) {
      return null;
    }
    const actions = [
      <FlatButton
        label="Confirm"
        key="revert-issue-dialog-confirm-button"
        primary={true}
        onClick={this.onEmailTextRevert}
      />,
      <FlatButton
        label="Cancel"
        key="revert-issue-dialog-cancel-button"
        primary={true}
        onClick={this.onRevertDialogClose}
      />,
    ];
    return (
      <Dialog
        title="Revert Email Text"
        actions={actions}
        modal={false}
        open={this.state.isRevertDialogOpen}
        onRequestClose={this.onRevertDialogClose}
      >
        Are you sure you want to revert the email text to:
        <div style={{fontWeight: 600, marginTop: "10px"}}>
          {templateEmailText}
        </div>
      </Dialog>
    );
  };

  onEmailTextCancel = emailText => this.setState(() => ({emailText}));

  onEmailTextRevert = () => {
    const {onEmailTextSave} = this;
    this.setState(
      () => ({isRevertDialogOpen: false}),
      () => onEmailTextSave(""),
    );
  };

  onRevertDialogOpen = () => {
    this.setState(() => ({isRevertDialogOpen: true}));
  };

  onRevertDialogClose = () => {
    this.setState(() => ({isRevertDialogOpen: false}));
  };

  onEmailTextFocus = () => {
    this.emailTextRef.focus();
  };

  onRightButtonClick = () => {
    const {userEmailText, templateEmailText} = this.getEmailTextData();

    if (this.state.emailText !== (userEmailText || templateEmailText)) {
      this.onEmailTextSave(this.state.emailText);
    } else {
      if (this.props.reportSettings.add_all_items_to_report) {
        this.props.onIgnore(this.props.reportSettings.id);
      } else {
        this.props.onAddToEmail(this.props.reportSettings.id);
      }
    }
  };

  onRevert = () => this.props.onRevert(this.props.reportSettings.id);

  onLeftButtonClick = () => {
    const {userEmailText, templateEmailText} = this.getEmailTextData();
    const baseEmailText = userEmailText || templateEmailText;
    if (this.state.emailText !== baseEmailText) {
      this.onEmailTextCancel(baseEmailText);
    }
  };

  getEmailTextData = () => {
    const {issue} = this.props;
    return {
      userEmailText: issue.user_text,
      templateEmailText:
        issue.email_template && getEmailTextTemplate(issue, this.props),
    };
  };

  createEmailTextRef = node => (this.emailTextRef = node);

  onEmailTextSave = user_text => {
    if (!this.props.updateDocumentIssue) {
      return;
    }
    this.setState(
      () => ({isRequestPending: true}),
      () => this.props.updateDocumentIssue(this.props.issue, {user_text}),
    );
  };
}

function getStatus(state) {
  switch (state) {
    case 1:
      return "Confirmed";
    case 0:
      return "Rejected";
    case null:
    case undefined:
      return "Outstanding";
    default:
      return "";
  }
}

export default EmailText;

import React, {useContext} from "react";
import DocumentDetailContext from "common_components/context/document_detail_context";
import PartyList from "common_components/party_list";

const styles = {
  mainContainer: {
    padding: "1rem 1.5rem",
    borderBottom: "1px solid lightgray",
    overflow: "hidden",
  },
};

function PartiesSection(props) {
  const {parties, contract_type_id: contractTypeId} = props.document;
  const {roles: projectRoles} = props.project;
  const context = useContext(DocumentDetailContext);
  const roles = contractTypeId
    ? props.roles.filter(
        role => role.contract_types.indexOf(contractTypeId) !== -1,
      )
    : props.roles;
  return (
    <div
      style={{
        ...styles.mainContainer,
        ...(props.forcePartyConfirmation && !props.document.are_roles_confirmed
          ? {backgroundColor: "rgb(158, 186, 213)"}
          : {}),
      }}
      id="parties-section"
    >
      <PartyList
        organisationId={props.organisationId}
        organisationParties={props.parties}
        updateCount={props.document.partyUpdateCount}
        documentParties={parties}
        onUpdate={props.updateDocument}
        isHorizontal={true}
        context={context}
        roles={roles}
        projectRoles={projectRoles}
        reprocessRoles={props.reprocessRoles}
        resetRoles={props.resetRoles}
        user={props.user}
        areRolesConfirmed={props.document.are_roles_confirmed}
        forcePartyConfirmation={props.forcePartyConfirmation}
      />
    </div>
  );
}

export default PartiesSection;

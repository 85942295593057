import FileSaver from "file-saver";
import {Packer} from "docx";

function downloadDocx(data, filename) {
  Packer.toBuffer(data).then(buffer => {
    const downloadedDocumentBlob = new Blob([buffer], {
      type: "application/octet-binary",
    });
    FileSaver.saveAs(downloadedDocumentBlob, filename);
  });
}

export default downloadDocx;

function getIssueNames(issue) {
  const nameArray = issue.name.split("/");
  return {
    groupName: nameArray[0].trim(),
    name:
      nameArray.length > 1
        ? nameArray[nameArray.length - 1].trim()
        : nameArray[0].trim(),
  };
}

export default getIssueNames;

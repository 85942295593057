import * as colors from "material-ui/styles/colors";

const topicGroupNameStyles = {
  padding: "4px 1rem",
  textAlign: "center",
  fontSize: "13px",
  fontWeight: 200,
  color: "#111",
  backgroundColor: colors.amber100,
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
};

function getTopicGroupName(fullName) {
  const nameArray = fullName.split("/");
  return nameArray.length > 1 ? nameArray[1].trim() : nameArray[0].trim();
}

function getTopicName(fullName) {
  const nameArray = fullName.split("/");
  const nameArrayLength = nameArray.length;
  if (nameArrayLength <= 1) {
    return fullName;
  }
  return nameArray[nameArray.length - 1].trim();
}

export default {getTopicGroupName, topicGroupNameStyles, getTopicName};

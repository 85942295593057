import reasonViewerTopicparameter from "utils/issues/reason_viewer_topicparameter";

function getDefaultIssueRuleValues(rules) {
  return [{path: `topics.[${rules.topic_id}].[11]`, value: ""}];
}

export default {
  reasonViewer: reasonViewerTopicparameter,
  getDefaultIssueRuleValues,
};

import React from "react";
import * as jsDiff from "diff";
import _ from "underscore";

import CircularProgress from "@material-ui/core/CircularProgress";
import InfoIcon from "@material-ui/icons/Info";

import IssueDetailHeading from "common_components/issue_detail/issue_detail_heading";
import cleanReference from "utils/clauses/clean_reference";

import ItemsSection from "./items_section";

import getClausesDataAsync from "./get_clauses_data_async";

const styles = {
  mainContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  bodyContainer: {
    padding: "0px 16px",
    overflow: "auto",
    height: "100%",
  },
  clausesLabel: {
    display: "flex",
    alignItems: "center",
    color: "#333333",
    cursor: "pointer",
    paddingBottom: 14,
    borderBottom: "1px solid #f4f4f4",
    marginBottom: 12,
  },
  reasonText: {
    padding: "20px",
    background: "#f4f4f4",
  },
  reasonTextLabel: {
    paddingTop: "16px",
    fontSize: "14px",
  },
};

const LONG_DOCUMENT_CLAUSEPARTS_LENGTH = 200;

function ComparisonDetail(props) {
  const {
    comparisonDetailData, // calculated data
    issueComparisonData, // fetched from server data
    issue,
    project,
    selectedReport,
    showIssuesInChecklist,
    zoom,
    isWindowNarrow,
    clearPanelData,
    documentClauseparts,
    documentSections,
    scrollToClause,
    appType,
    isComparisonBetweenRevisions,
    reasonText,
  } = props;

  const {
    issueApplicableClauses,
    comparisonIssueApplicableClauses,
  } = comparisonDetailData;

  const {
    clauseparts: comparisonDocumentClauseparts,
    sections: comparisonDocumentSections,
  } = issueComparisonData;

  const [isProcessing, updateIsProcessing] = React.useState(false);

  const [calculationsDone, updateCalculationsDone] = React.useState(0);

  const calculatedRef = React.useRef(null);
  const isAnyDocumentLong =
    documentClauseparts.length > LONG_DOCUMENT_CLAUSEPARTS_LENGTH ||
    comparisonDocumentClauseparts.length > LONG_DOCUMENT_CLAUSEPARTS_LENGTH;

  async function getData(useNearbySearchOnly) {
    calculatedRef.current = await getClausesDataAsync(
      issueApplicableClauses,
      comparisonIssueApplicableClauses,
      documentClauseparts,
      comparisonDocumentClauseparts,
      useNearbySearchOnly,
    );
    updateCalculationsDone(calculationsDone + 1);
    updateIsProcessing(false);
  }

  function performFullComparison() {
    updateIsProcessing(true);
    calculatedRef.current = null;
    getData(false);
  }

  React.useEffect(() => {
    updateIsProcessing(true);
    getData(isAnyDocumentLong);
  }, []);

  React.useEffect(() => {
    updateIsProcessing(true);
    getData(isAnyDocumentLong);
  }, [props.issue && props.issue.id]);

  function renderLongDocumentMessage() {
    if (!isAnyDocumentLong || calculationsDone !== 1) {
      return null;
    }

    const {
      nonDirectSearchPerformed,
      hasClausepartsOfNonIdenticalClauses,
      similarClauseLocationSearchPerformed,
    } = calculatedRef.current || {
      nonDirectSearchPerformed: false,
      hasClausepartsOfNonIdenticalClauses: false,
      similarClauseLocationSearchPerformed: false,
    };

    if (
      !nonDirectSearchPerformed ||
      (similarClauseLocationSearchPerformed &&
        !hasClausepartsOfNonIdenticalClauses)
    ) {
      return null;
    }

    return (
      <div
        style={{
          padding: "2px 16px",
          display: "flex",
          alignItems: "center",
          fontSize: 12,
        }}
      >
        <InfoIcon
          style={{
            height: 16,
            width: 16,
            marginTop: 1,
            marginRight: 2,
            color: "#616161",
            alignSelf: "flex-start",
          }}
        />
        <span>
          It looks like these documents don't match,
          <span
            style={{cursor: "pointer", color: "#50B2FF"}}
            onClick={performFullComparison}
          >
            {" "}
            click here{" "}
          </span>
          to keep looking. This may take a while and can cause the page to be
          less responsive.
        </span>
      </div>
    );
  }

  function renderBody() {
    if (isProcessing || calculatedRef.current === null) {
      return (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <ItemsSection
        items={constructClausesItems(
          calculatedRef.current,
          documentSections,
          comparisonDocumentSections,
        )}
        label="Changes from this document"
        zoom={zoom}
        scrollToClause={scrollToClause}
        appType={appType}
        isComparisonBetweenRevisions={isComparisonBetweenRevisions}
      />
    );
  }

  return (
    <div style={styles.mainContainer}>
      <IssueDetailHeading
        issue={issue}
        project={project}
        selectedReport={selectedReport}
        showIssuesInChecklist={showIssuesInChecklist}
        zoom={zoom}
        onCloseClick={clearPanelData}
        closeButtonOnTheLeft={isWindowNarrow}
        shouldHideIcon={true}
        user={props.user}
        currentIssuesetItem={props.currentIssuesetItem}
      />
      {renderLongDocumentMessage()}
      <div style={styles.bodyContainer}>
        {reasonText !== undefined && reasonText.length !== 0 && (
          <>
            <div style={styles.reasonTextLabel}>
              Comparison Document Issue Summary:
            </div>
            <div style={styles.reasonText}>{reasonText}</div>
          </>
        )}
        {renderBody()}
      </div>
    </div>
  );
}

// merge clauses in single array
export function constructClausesItems(
  clausepartData,
  documentSections,
  comparisonDocumentSections,
) {
  const sectionOrderById = getSectionOrderById(
    documentSections,
    comparisonDocumentSections,
  );
  const {
    clauses,
    comparisonClauses,
    similarClauses,
    matchedClauses,
  } = clausepartData;

  const result = [];

  // blue
  result.push(
    ...clauses.map(clause => {
      const referenceClean = cleanReference(clause.reference);
      return {
        type: "extra_in_current",
        partial_text: clause.partial_text,
        ...getReferenceData(referenceClean),
        reference: referenceClean,
        id: clause.id,
        clausepart_id: clause.id,
        section_order: sectionOrderById[clause.section_id],
        order: clause.order,
      };
    }),
  );

  // strikethrough
  result.push(
    ...comparisonClauses.map(clause => {
      const referenceClean = cleanReference(clause.reference);
      return {
        type: "extra_in_previous",
        partial_text: null,
        text: clause.partial_text,
        ...getReferenceData(referenceClean),
        reference: referenceClean,
        id: clause.id,
        clausepart_id: undefined,
        section_order: sectionOrderById[clause.section_id],
        order: clause.order,
      };
    }),
  );

  // similar
  similarClauses.forEach(similarClause => {
    const {clause, comparisonClause} = similarClause;
    const referenceClean = cleanReference(clause.reference);

    result.push({
      type: "similar",
      partial_text: clause.partial_text,
      reference: referenceClean,
      ...getReferenceData(referenceClean),
      diffs: getDiffs(comparisonClause, clause) || [],
      id: clause.id,
      clausepart_id: clause.id,
      section_order: sectionOrderById[clause.section_id],
      order: clause.order,
    });
  });

  // matched
  matchedClauses.forEach(matchedClause => {
    const {clause} = matchedClause;
    const referenceClean = cleanReference(clause.reference);

    result.push({
      type: "matched",
      partial_text: clause.partial_text,
      text: clause.partial_text,
      reference: referenceClean,
      ...getReferenceData(referenceClean),
      id: clause.id,
      clausepart_id: clause.id,
      section_order: sectionOrderById[clause.section_id],
      order: clause.order,
    });
  });

  return _.chain(result)
    .sortBy(item => item.order)
    .sortBy(item => item.id)
    .sortBy(item => item.referenceSuffix)
    .sortBy(item => item.section_order)
    .groupBy(clause => clause.reference)
    .map(clausesInGroup =>
      clausesInGroup.map((clause, index) =>
        clause.type !== "similar"
          ? {
              ...clause,
              // Only the first clause for a given reference displays it as a prefix
              text: (index === 0 ? `${clause.reference}. ` : "").concat(
                clause.text ?? clause.partial_text,
              ),
            }
          : clause,
      ),
    )
    .flatten()
    .value();
}

function getReferenceData(reference) {
  const firstDotIndex = reference.indexOf(".");
  return {
    clauseId: parseInt(reference, 10) || 999999,
    referenceSuffix:
      firstDotIndex === -1 ? reference : reference.slice(firstDotIndex + 1),
  };
}

function getDiffs(clause, comparisonClause) {
  return jsDiff.diffWords(clause.partial_text, comparisonClause.partial_text);
}

function getSectionOrderById(sectionsA = [], sectionsB = []) {
  let maxLenSections = sectionsA;
  let minLenSections = sectionsB;

  if (sectionsA.length < sectionsB.length) {
    maxLenSections = sectionsB;
    minLenSections = sectionsA;
  }

  const unusedMinLenSections = [...minLenSections];

  const result = {};

  maxLenSections.forEach(maxLenSection => {
    result[maxLenSection.id] = maxLenSection.order;
    const foundMinSectionIndex = unusedMinLenSections.findIndex(
      minlenSection => minlenSection.reference === maxLenSection.reference,
    );
    if (foundMinSectionIndex !== -1) {
      const foundMinSection = unusedMinLenSections[foundMinSectionIndex];
      result[foundMinSection.id] = maxLenSection.order;
      unusedMinLenSections.splice(foundMinSectionIndex, 1);
    }
  });

  unusedMinLenSections.forEach(minLenSection => {
    result[minLenSection.id] = minLenSection.order;
  });
  return result;
}

export default ComparisonDetail;

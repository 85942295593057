import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import OrganisationActionTypes from "modules/organisation/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function projectsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.PROJECTROLES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PROJECT_PARTY_UPDATE.SUCCESS: {
      if (
        !state.find(
          projectrole => projectrole.id === action.payload.projectrole_id,
        )
      ) {
        return state.concat([
          {
            id: action.payload.projectrole_id,
            name: action.payload.projectrole_name,
          },
        ]);
      }
      return state;
    }
    case OrganisationActionTypes.ORGANISATION_CHANGE.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

function getNodeAtoms(atomsContainer) {
  if (Array.isArray(atomsContainer)) {
    return atomsContainer.reduce((result, node) => {
      return [...result, ...getSingleNodeAtoms(node)];
    }, []);
  }
  return getSingleNodeAtoms(atomsContainer);
}

function getSingleNodeAtoms(node) {
  const children = [];

  if (node.type.toLowerCase().endsWith("atom")) {
    return [node];
  }
  function getAtoms(node) {
    if (node.text && !node.is_conjunction) {
      children.push(node);
    }
    if (node.clauseNodes) {
      node.clauseNodes.forEach(child => getAtoms(child));
    }
  }
  if (node.clauseNodes) {
    node.clauseNodes.forEach(node => {
      getAtoms(node);
    });
  }
  return children;
}

export default getNodeAtoms;

import React from "react";

import FlatButton from "material-ui/FlatButton";
import AddIcon from "material-ui/svg-icons/av/playlist-add";
import * as colors from "material-ui/styles/colors";

import EditorItem from "./editor_item";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    width: "24rem",
  },
  addItemButton: {
    margin: "0.2rem",
  },
};

class ValueEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {values: props.values};
  }

  render() {
    return (
      <div style={styles.mainContainer}>
        {this.state.values &&
          this.state.values.map((value, index) => (
            <EditorItem
              key={index}
              value={value}
              valueIndex={index}
              onUpdateValue={this.onUpdateValue}
              onDeleteValue={this.onDeleteValue}
              currentValues={this.state.values}
            />
          ))}
        <FlatButton
          backgroundColor={colors.grey100}
          icon={<AddIcon color={colors.grey700} />}
          style={styles.addItemButton}
          onClick={this.onAddValue}
        />
      </div>
    );
  }

  onDeleteValue = index => () =>
    this.setState(prevState => {
      const {values} = prevState;
      values.splice(index, 1);
      return {values};
    });

  onAddValue = () =>
    this.setState(prevState => ({
      values: [
        ...(prevState.values ? prevState.values : []),
        {value: 0, symbol: ", currency: "},
      ],
    }));

  onUpdateValue = (updatedItem, index) =>
    this.setState(prevState => {
      const {values} = prevState;
      values.splice(index, 1, updatedItem);
      return {values};
    });
}

export default ValueEditor;

import _ from "lodash";

function getClauseReferences(reasonData = [], searchType, clauseReference) {
  if (searchType === "clausepart_reference") {
    const sectionId = clauseReference.split("-")[0];
    const clauseRef = clauseReference.split("-")[1];
    const matchingClauses = reasonData.filter(
      atom =>
        atom.section_id.toString() === sectionId &&
        atom.reference === clauseRef,
    );
    return _.chain(matchingClauses)
      .map(item => (item.references || []).map(refObj => refObj.id))
      .flatten()
      .uniq()
      .map(item => `${sectionId}-${item}`)
      .value();
  }
  if (searchType === "clausepart_id") {
    const matchingClauses = reasonData.filter(
      atom => atom.id === clauseReference,
    );
    const matchingRefs = _.chain(matchingClauses)
      .map(item => item.references.map(refObj => [item.section_id, refObj.id]))
      .flatten()
      .uniq()
      .value();
    return reasonData
      .filter(atom =>
        matchingRefs.find(
          matchingAtom =>
            atom.section_id === matchingAtom[0] &&
            atom.reference === matchingAtom[1],
        ),
      )
      .map(item => item.id);
  }
  return [];
}

export default getClauseReferences;

function getIssueOrder(order) {
  const orderArray = order && order.length ? order.split(".") : [];
  const orderArrayLength = orderArray.length;
  const issueOrder = {};
  if (order === "" || order === undefined) {
    issueOrder.groupOrder = 99999999;
    issueOrder.issueItemOrder = 99999999;
  }
  if (orderArrayLength === 1 && order !== "") {
    issueOrder.groupOrder = orderArray[0] ? parseInt(orderArray[0], 10) : 0;
    issueOrder.issueItemOrder = 99999999;
  }
  if (orderArrayLength > 1) {
    issueOrder.groupOrder = orderArray[0] ? parseInt(orderArray[0], 10) : 0;
    issueOrder.issueItemOrder = orderArray[1] ? parseInt(orderArray[1], 10) : 0;
  }
  return issueOrder;
}

export default getIssueOrder;

export const placeholderReplacements = [
  {from: "_{3,100}", to: "___", pseudo: "PLACEHOLDER0001"},
  {from: "\\.{4,100}", to: "...", pseudo: "PLACEHOLDER0002"},
  {from: "\\.([ ]+\\.){4,100}", to: "...", pseudo: "PLACEHOLDER0003"},
  {from: "…{4,100}", to: "………", pseudo: "PLACEHOLDER0004"},

  // @TODO: process this placeholder
  // if we have a placeholder made from `…` with a dot after it, we remove this dot
  // if the next word after the space/newline is lowcased
  // {from: "………\\.(\\s[a-z])", to: "………$1"},

  // replace non-standard placeholder symbols
  {from: "°{3,100}", to: "___", pseudo: "PLACEHOLDER0006"},

  // combine `…` and `...` placeholders
  {from: "\\.{3,3}…+", to: "...", pseudo: "PLACEHOLDER0007"},
  {from: "…+\\.{3,3}", to: "...", pseudo: "PLACEHOLDER0008"},

  {from: "\\*{4,100}", to: "***", pseudo: "PLACEHOLDER0009"},

  // @TODO: process this placeholder
  // remove a dot before `]` inside a sentence (it splits the sentence)
  // {from: "\\.(\\][,;]? [a-z])", to: "$1"},
].map(replacement => ({
  ...replacement,
  type: "significant",
}));

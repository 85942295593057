import React from "react";

import * as colors from "material-ui/styles/colors";

import GraphNode from "./graph_node";

const styles = {
  clauseAddition: {
    color: colors.lightBlue600,
    fontWeight: 500,
  },
};

export default function renderOpenClause(props) {
  const {changes, fullReference, clauseAdditionChange} = props;
  const referenceChange = changes.find(
    _change =>
      _change.new_reference === fullReference &&
      (_change.type === "clause_reference_alteration" ||
        _change.type === "clause_addition" ||
        _change.type === "clausepart_addition"),
  );

  const {read_plain: readPlain} = props.project;

  return (
    <div
      className="open-clause"
      style={{...(clauseAdditionChange ? styles.clauseAddition : {})}}
    >
      <GraphNode
        {...props}
        node={props.clause.nodes}
        reference={readPlain ? "" : props.clause.reference}
        firstNode={true}
        lastNode={true}
        referenceChange={referenceChange}
      />
    </div>
  );
}

import _ from "lodash";

import UNINITIALISED from "utils/uninitialised";

import ActionTypes from "../constants/action_types";

export default function clientsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.CLIENTS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.CLIENT_ADD.SUCCESS: {
      return [...state, action.payload];
    }
    case ActionTypes.CLIENT_UPDATE.SUCCESS: {
      const {id, contract_types} = action.payload;
      return state.map(client => {
        if (client.id === id) {
          const updatedClient = {
            ...client,
            ..._.omit(action.payload, ["contract_types"]),
          };
          if (contract_types) {
            contract_types.forEach(contractType => {
              const prevContractType = updatedClient.contract_types.find(
                ({master_id}) => master_id === contractType.master_id,
              );
              if (!prevContractType) {
                updatedClient.contract_types.push(contractType);
              } else {
                prevContractType.issuesets = [
                  ...prevContractType.issuesets,
                  ...contractType.issuesets,
                ];
              }
            });
          }
          return updatedClient;
        }
        return client;
      });
    }
    case ActionTypes.CLIENT_SYNC_ISSUESET.SUCCESS: {
      const {
        clientId,
        issuesetMasterId,
        syncList,
        last_sync_date,
      } = action.payload;
      return state.map(client => {
        if (client.id === clientId) {
          client.contract_types.forEach(contractType => {
            contractType.issuesets.forEach(issueset => {
              if (issueset.master_id === issuesetMasterId) {
                if (!_.has(issueset, "last_sync_dates")) {
                  issueset.last_sync_dates = {};
                }
                issueset.last_sync_dates[syncList] = last_sync_date;
              }
            });
          });
        }
        return client;
      });
    }
    case ActionTypes.ISSUESET_UPDATE.SUCCESS: {
      const {id: issuesetId, updates} = action.payload;
      if (state && state !== UNINITIALISED) {
        return state.map(client => ({
          ...client,
          contract_types: client.contract_types.map(contractType => ({
            ...contractType,
            issuesets: contractType.issuesets.map(issueset => ({
              ...issueset,
              ...(issuesetId === issueset.id ? updates : {}),
            })),
          })),
        }));
      }
      return state;
    }
    case ActionTypes.CLIENTS_CLEAR.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

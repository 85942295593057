import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";

const styles = {
  icon: {
    cursor: "pointer",
    marginLeft: "4px",
    width: "18px",
    height: "18px",
  },
  buttonsDirection: {
    display: "flex",
    flexDirection: "column",
  },
};

const TextFieldHandlers = ({onSave, onAbortField, isColumnDirection}) => {
  return (
    <div style={isColumnDirection ? styles.buttonsDirection : null}>
      <Tooltip title={"Save changes"} enterDelay={1000} placement="top" arrow>
        <DoneIcon
          onMouseDown={onSave}
          style={{
            ...styles.icon,
            color: "#00c853",
          }}
        />
      </Tooltip>
      <Tooltip title={"Abort changes"} enterDelay={1000} placement="top" arrow>
        <CancelIcon
          onMouseDown={onAbortField}
          style={{
            ...styles.icon,
            color: "#757575",
          }}
        />
      </Tooltip>
    </div>
  );
};

export default TextFieldHandlers;

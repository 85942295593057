import React, {useEffect, useState} from "react";
import moment from "moment";
import _ from "lodash";
import {Link} from "react-router";

import CircularProgress from "@material-ui/core/CircularProgress";

import HeaderTitle from "common_components/checklist/header_title";
import MenuHeading from "common_components/menu_heading";
import HeaderMenu from "common_components/checklist/header_menu";
import SectionSelector from "common_components/checklist/section_selector";
import Checklist from "common_components/checklist";
import RefreshIssuesButton from "common_components/document/refresh_issues_button";
import RequestErrorPanel from "common_components/request_error_panel";
import EditIcon from "icons/Edit";
import CompareIcon from "@material-ui/icons/Compare";
import LinkIcon from "@material-ui/icons/Link";

import {projectPath, setParamsToPath} from "routes/navigation";
import getIssuesData from "utils/issues/get_issues_data";
import issueHeaderUtils from "utils/issues/issue_header_utils";

import Permissioner from "utils/permissioner";
import getZoomedFontSize from "utils/get_zoomed_font_size";
import getIssueApplicableClausesByPath from "utils/clauses/get_issue_applicable_clauses_by_path";

import "./compare_panel_comparison.css";

const styles = {
  headerContainer: {
    padding: "15px 15px 15px 20px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    columnGap: "0.25rem",
  },
  spinnerContainer: {
    height: "100%",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    overflow: "auto",
    height: "100%",
  },
};

function ComparePanelComparison(props) {
  const {
    applicableClausesPath,
    document,
    documents,
    project,
    contractTypesById,
    currentIssueset,
    firstUploadedDocument,
    comparisonDocumentId,
    isFlipChecklistIcons,
    zoom,
    issueComparisonData,
    appType,
  } = props;

  const {
    document_id: issueComparisonDataDocumentId,
    issues: issueComparisonDataIssues,
    deleted: issueComparisonDataDocumentDeleted,
  } = issueComparisonData || {};

  const [isRequestPending, updateIsRequestPending] = useState(false);
  const [isFetchErrorPresent, updateIsFetchErrorPresent] = useState(false);

  async function fetchData() {
    updateIsFetchErrorPresent(false);
    updateIsRequestPending(true);
    try {
      await props.fetchIssueComparisonData(comparisonDocumentId);
    } catch {
      updateIsFetchErrorPresent(true);
    }
    setTimeout(() => {
      updateIsRequestPending(false);
    }, 1);
  }

  useEffect(() => {
    if (
      comparisonDocumentId &&
      issueComparisonDataDocumentId !== comparisonDocumentId
    ) {
      fetchData();
    }
  }, [issueComparisonDataDocumentId, comparisonDocumentId]);

  function renderSpinner() {
    return (
      <div style={styles.spinnerContainer}>
        <CircularProgress style={{height: 24, width: 24}} />
      </div>
    );
  }

  function renderDeletedMessage() {
    return (
      <div
        style={{
          ...styles.spinnerContainer,
          flexDirection: "column",
          padding: "0 24px",
          textAlign: "center",
        }}
      >
        <div>Comparison document is deleted.</div>
        <div>Please select a different document.</div>
      </div>
    );
  }

  function renderContent() {
    if (!issueComparisonData) {
      return renderSpinner();
    } else if (issueComparisonDataDocumentDeleted) {
      return renderDeletedMessage();
    }

    // get comparison issues
    const baseProps = {
      document,
      project,
      contractTypesById,
      documentIssues: issueComparisonDataIssues,
    };
    const {groupedIssues: groupedComparisonDocumentIssues} = getIssuesData(
      baseProps,
      currentIssueset,
      props.areIssuesCollapsed,
      false, // dontFilterIssuesByIssuesets
      props.selectedChecklistSectionId,
    );

    // add comparison data to current document grouped issues
    const {checklistProps} = props;
    const groupedIssues = getGroupedIssuesWithComparisonDetailData(
      checklistProps.groupedIssues,
      groupedComparisonDocumentIssues,
      applicableClausesPath,
      _.pick(checklistProps, [
        "showIssuesInChecklist",
        "project",
        "selectedReport",
        "currentIssuesetItem",
      ]),
    );

    return (
      <>
        <MenuHeading
          containerStyle={{
            borderBottom: "1px solid #4f575f",
            minHeight: "unset",
            flexShrink: 0,
          }}
        >
          {props.user.is_admin && (
            <RefreshIssuesButton
              document={document}
              project={project}
              runDocumentIssuesFind={props.runDocumentIssuesFind}
              styles={{marginRight: 4}}
            />
          )}
          <HeaderTitle
            hideFrontPage={true}
            project={project}
            document={document}
            isFlipChecklistIcons={isFlipChecklistIcons}
            contractTypesById={contractTypesById}
            onViewModeChange={() => null}
            showSelectChecklistDialog={() => null}
            currentIssueset={currentIssueset}
            isIssuesetContractTypeNameHidden={true}
            viewMode={props.viewMode}
            onCurrentIssuesetChange={props.onCurrentIssuesetChange}
            user={props.user}
          />
          {props.headerMenuProps && <HeaderMenu {...props.headerMenuProps} />}
        </MenuHeading>

        {project.should_show_per_schedule_analysis && (
          <SectionSelector
            isFlipChecklistIcons={props.isFlipChecklistIcons}
            sections={props.documentSections}
            selectedChecklistSectionId={props.selectedChecklistSectionId}
            onSelectedChecklistSectionIdChange={
              props.onSelectedChecklistSectionIdChange
            }
          />
        )}
        <div>
          <Checklist
            {...props.checklistProps}
            groupedIssues={groupedIssues}
            showCompareIssues={true}
          />
        </div>
      </>
    );
  }

  const {document: comparisonDocument, label} = getComparisonDocument(
    comparisonDocumentId,
    document,
    documents,
    firstUploadedDocument,
  );

  if (!comparisonDocument) {
    return null;
  }

  const linkPath = setParamsToPath(
    {
      organisationId: props.organisationId,
      projectId: props.project.id,
      documentId: comparisonDocumentId,
    },
    `${projectPath}/document/:documentId/detail`,
  );

  return (
    <>
      <div
        style={{
          ...styles.headerContainer,
          ...(isFlipChecklistIcons
            ? {background: "#dadada"}
            : {
                borderBottom: "1px solid #616161",
                backgroundColor: "#232e38",
                color: "#fff",
              }),
        }}
      >
        {props.user.is_admin && (
          <RefreshIssuesButton
            document={comparisonDocument}
            project={project}
            runDocumentIssuesFind={props.runDocumentIssuesFind}
            styles={{marginRight: 4}}
          />
        )}
        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
          <div
            style={{
              fontSize: getZoomedFontSize(10, "checklist", zoom),
              marginBottom: -4,
            }}
          >
            Compare this document with
          </div>
          <a
            href={linkPath}
            target="_blank"
            className="compare-document-link"
            style={{color: "inherit"}}
          >
            <div
              style={{
                fontSize: getZoomedFontSize(13, "checklist", zoom),
                wordBreak: "break-all",
              }}
            >
              {`${label} @ ${
                label === "Other Document"
                  ? comparisonDocument.name
                  : moment(comparisonDocument.creation_date).format(
                      "DD/MM/YYYY HH:mm",
                    )
              }`}
            </div>
            <LinkIcon className="compare-document-link-icon" />
          </a>
          <div
            style={{
              fontSize: getZoomedFontSize(10, "checklist", zoom),
            }}
          >
            {`${comparisonDocument.user_email}${
              label === "Other Document"
                ? ` @ ${moment(comparisonDocument.creation_date).format(
                    "DD/MM/YYYY HH:mm",
                  )}`
                : ""
            }`}
          </div>
        </div>
        {new Permissioner(props.user).hasPermission(
          "show-comparison-summary",
        ) &&
          appType !== "wordTaskpane" && (
            <Link
              style={{
                color: "rgb(142, 149, 155)",
                height: "40px",
                width: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              to={{
                pathname: `/organisation/${props.organisationId}/project/${props.project.id}/document/${document.id}/comparison`,
                search: `?comparison_document=${comparisonDocument.id}`,
              }}
            >
              <CompareIcon style={{height: "100%"}} />
            </Link>
          )}
        <SquareButton
          icon={EditIcon}
          onClick={props.setSelectMode}
          isFlipChecklistIcons={isFlipChecklistIcons}
        />
      </div>
      <div
        id="checklistMenu"
        onScroll={props.onChecklistScroll ? props.onChecklistScroll : undefined}
        style={{
          ...styles.contentContainer,
          ...(isFlipChecklistIcons
            ? {backgroundColor: "#fff"}
            : {backgroundColor: "#232e38", color: "#fff"}),
        }}
      >
        {isFetchErrorPresent ? (
          <RequestErrorPanel fetchData={fetchData} />
        ) : isRequestPending ? (
          renderSpinner()
        ) : (
          renderContent()
        )}
      </div>
    </>
  );
}

function SquareButton(props) {
  const {icon: Icon, isFlipChecklistIcons} = props;
  return (
    <div
      style={{
        width: "40px",
        height: "40px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        flexShrink: 0,
        contentBox: "border-box",
        ...(isFlipChecklistIcons
          ? {background: "white"}
          : {border: "1px solid #616161"}),
      }}
      onClick={props.onClick}
    >
      <Icon style={{width: 16, height: 16, color: "#50b2ff"}} />
    </div>
  );
}

function getGroupedIssuesWithComparisonDetailData(
  groupedIssues,
  groupedComparisonIssues,
  applicableClausesPath,
  props,
) {
  return groupedIssues.map((group) => {
    const comparisonGroup = groupedComparisonIssues.find(
      (cg) => cg.name === group.name,
    );
    if (!comparisonGroup) {
      return group;
    }
    const comparisonGroupIssues = comparisonGroup.item;

    let comparisonDiffCount = 0;
    let hasTextChangesCount = 0;
    const newGroup = {
      ...group,
      item: group.item.map((issue) => {
        const comparisonIssue = getComparisonIssue(
          issue,
          comparisonGroupIssues,
        );
        const clonedIssue = {...issue};
        if (issue.subissueData && issue.subissueData.isSubissueParent) {
          const newSubissues = issue.subissueData.subissues.map((subissue) => {
            const comparisonSubissue = getComparisonIssue(
              subissue,
              _.get(comparisonIssue, "subissueData.subissues", []),
            );

            const subissueBaseIconName = issueHeaderUtils.getBaseIconName(
              subissue,
              props.currentIssuesetItem,
              props.showIssuesInChecklist,
              props.project,
            );

            const newSubissue = addComparisonDetailData(
              subissue,
              comparisonSubissue,
              props,
              subissueBaseIconName,
              applicableClausesPath,
            );

            const shouldShowComparisonIcon = _.get(
              newSubissue,
              "comparisonDetailData.shouldShowComparisonIcon",
            );

            if (
              !(subissue.shouldBeHidden && comparisonSubissue.shouldBeHidden)
            ) {
              if (shouldShowComparisonIcon) {
                comparisonDiffCount += 1;
              }

              if (
                newSubissue.comparisonDetailData &&
                newSubissue.comparisonDetailData.hasTextChanges
              ) {
                hasTextChangesCount += 1;
              }
            }

            return newSubissue;
          });
          clonedIssue.subissueData = {
            ...issue.subissueData,
            subissues: newSubissues,
          };
        }

        const issueBaseIconName = issueHeaderUtils.getBaseIconName(
          issue,
          props.currentIssuesetItem,
          props.showIssuesInChecklist,
          props.project,
        );

        const newIssue = addComparisonDetailData(
          clonedIssue,
          comparisonIssue,
          props,
          issueBaseIconName,
          applicableClausesPath,
        );

        const shouldShowComparisonIcon = _.get(
          newIssue,
          "comparisonDetailData.shouldShowComparisonIcon",
        );

        if (!(issue.shouldBeHidden && comparisonIssue.shouldBeHidden)) {
          if (shouldShowComparisonIcon) {
            comparisonDiffCount += 1;
          }

          if (
            newIssue.comparisonDetailData &&
            newIssue.comparisonDetailData.hasTextChanges
          ) {
            hasTextChangesCount += 1;
          }
        }

        return newIssue;
      }),
    };

    return {...newGroup, comparisonDiffCount, hasTextChangesCount};
  });
}

function getComparisonDocument(
  comparisonDocumentId,
  currentDocument = {},
  documents = [],
  firstUploadedDocument = {},
) {
  if (!comparisonDocumentId) {
    return {document: {user_email: "N/A"}, label: "Not Found"};
  } else if (comparisonDocumentId === firstUploadedDocument.id) {
    return {document: firstUploadedDocument, label: "Standard Terms"};
  }

  const revisionDocument = (currentDocument.revisions || []).find(
    (revision) => revision.id === comparisonDocumentId,
  );
  if (revisionDocument) {
    return {document: revisionDocument, label: "Previous Version"};
  }

  const otherDocument = documents.find(
    (doc) => doc.id === comparisonDocumentId,
  );
  if (otherDocument) {
    return {document: otherDocument, label: "Other Document"};
  }
  return {document: {user_email: "N/A"}, label: "Not Found"};
}

function getComparisonIssue(issue, comparisonIssues) {
  if (issue.underlyingIssues) {
    return comparisonIssues.find(
      (comparisonIssue) =>
        comparisonIssue.underlyingIssues &&
        Boolean(
          comparisonIssue.underlyingIssues.find(
            (comparisonUndelyingIssue) =>
              comparisonUndelyingIssue.id === issue.id,
          ),
        ),
    );
  }
  return comparisonIssues.find(
    (comparisonIssue) => comparisonIssue.id === issue.id,
  );
}

function addComparisonDetailData(
  issue,
  comparisonIssue,
  props,
  baseIssueIconName,
  applicableClausesPath,
) {
  if (issue.shouldBeHidden && comparisonIssue.shouldBeHidden) {
    return issue;
  }
  const baseComparisonIconName = issueHeaderUtils.getBaseIconName(
    comparisonIssue,
    props.currentIssuesetItem,
    props.showIssuesInChecklist,
    props.project,
    true,
  );
  // comparison icon name with manual corrections considered
  const comparisonIconName = issueHeaderUtils.getIconName(
    comparisonIssue,
    props.currentIssuesetItem,
    props.showIssuesInChecklist,
    props.project,
    props.selectedReport,
    false, // ignore resolved
  );

  // applicable clauses comparison
  const issueApplicableClauses = getIssueApplicableClausesByPath(
    issue,
    applicableClausesPath,
  );
  const comparisonIssueApplicableClauses = getIssueApplicableClausesByPath(
    comparisonIssue,
    applicableClausesPath,
  );

  const issueApplicablesString = getApplicablesString(issueApplicableClauses);
  const comparisonIssueApplicablesString = getApplicablesString(
    comparisonIssueApplicableClauses,
  );

  const newIssue = {
    ...issue,
    comparisonDetailData: {
      baseComparisonIconName,
      comparisonIconName,
      shouldBeHidden: comparisonIssue.shouldBeHidden,
      shouldShowComparisonIcon:
        baseComparisonIconName !== baseIssueIconName ||
        baseComparisonIconName !== comparisonIconName,
      hasTextChanges:
        issueApplicablesString !== comparisonIssueApplicablesString,
      issueApplicableClauses,
      comparisonIssueApplicableClauses,
    },
  };

  if (issue.shouldBeHidden) {
    newIssue.showForComparison = true;
  }
  return newIssue;
}

function getApplicablesString(clauses) {
  return _.chain(clauses)
    .map((clause) => clause.partial_text)
    .sortBy((text) => text)
    .value()
    .join("");
}

export default ComparePanelComparison;

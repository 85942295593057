import _ from "lodash";
function filterWhenEmpty(baseList = [], keys) {
  return baseList.filter(item =>
    keys.some(key => {
      const value = _.get(item, key);
      return !(
        value === null ||
        value === undefined ||
        (Array.isArray(value) &&
          value.every(arrVal => arrVal === undefined || arrVal === null))
      );
    }),
  );
}

export default filterWhenEmpty;

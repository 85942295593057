import issuesetUtils from "common/utils/issues/issueset_utils";

function getIssuesetOptions(project, contractTypesById) {
  const {id: projectDefaultContractTypeId} = project.default_contract_type;
  const issuesetsById = issuesetUtils.getIssuesetsById(
    contractTypesById,
    projectDefaultContractTypeId,
  );
  const {issuesets: projectIssuesets} = project;
  if (projectIssuesets && projectIssuesets.length > 0) {
    return issuesetUtils.constructIssuesetSelectorItems(
      projectIssuesets,
      issuesetsById,
    );
  }
  return issuesetUtils.constructIssuesetSelectorItems(
    Object.keys(issuesetsById).map(issuesetId => parseInt(issuesetId, 10)),
    issuesetsById,
  );
}

export default getIssuesetOptions;

export default function isElementInViewport(el, offset = 0, findView = null) {
  if (!el) {
    return false;
  }
  const {rect, top, bottom} = getValues(el, findView);
  return (
    isPartiallyInside(rect, top, bottom, offset) ||
    isEntirelyInside(rect, top, bottom, offset)
  );
}

export function isElementEntirelyInViewport(el, offset = 0, findView = null) {
  if (!el) {
    return false;
  }
  const {rect, top, bottom} = getValues(el, findView);
  return isEntirelyInside(rect, top, bottom, offset);
}

function getValues(el, findView) {
  const rect = el.getBoundingClientRect();

  const parent = findView ? findParentView(el, findView) : null;
  const parentRect = parent && parent.getBoundingClientRect();

  const top = parentRect ? parentRect.top : 0;
  const bottom = parentRect
    ? parentRect.bottom
    : window.innerHeight || document.documentElement.clientHeight;

  return {rect, top, bottom};
}

function isEntirelyInside(rect, top, bottom, offset) {
  return rect.top > top && rect.bottom - offset < bottom;
}

function isPartiallyInside(rect, top, bottom, offset) {
  return !(rect.bottom - offset < top || rect.top > bottom);
}

function findParentView(el, findView) {
  if (findView(el)) {
    return el;
  }

  if (el.parentNode) {
    return findParentView(el.parentNode, findView);
  }
  return null;
}

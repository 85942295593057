import _ from "underscore";

function getIssuesSearchData(searchValue, documentIssues) {
  const issues = [];
  if (!searchValue) {
    return [];
  }
  const words = searchValue.toLowerCase().split(/\s+/);
  documentIssues.forEach(issue => {
    const {name} = issue;
    const searchName = name.toLowerCase();
    if (words.every(word => searchName.indexOf(word) !== -1)) {
      issues.push(issue);
    }
  });
  return _.sortBy(issues, issue => issue.name);
}

export default getIssuesSearchData;

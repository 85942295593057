import _ from "underscore";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import getListStyleType from "../utils/get_list_style_type";
import makeIndexProducer from "../utils/make_index_producer";
import convertListIndex from "common/utils/contents/convert_list_index";
import limitTextLines from "utils/limit_text_lines";

export default function LanguageText({language, limit = 0}) {
  if (!_.isArray(language)) {
    return null;
  }
  const getIndex = makeIndexProducer();
  let length = 0;
  return language
    .map(({level, text}, index) => {
      if (limit && length > limit) {
        return null;
      }
      const maxTextLength = limit ? limit - length : 0;
      length += text.length;
      return (
        <ListItem
          key={index}
          index={getIndex(level - 1)}
          level={level - 1}
          text={limitTextLines(text, maxTextLength)}
        />
      );
    })
    .filter(element => Boolean(element));
}

const useItemStyles = makeStyles({
  listItem: ({level}) => ({
    marginLeft: `${0.5 * (level || 0)}em`,
    display: "flex",
  }),
  listItemText: {},
});

function ListItem(props) {
  const classes = useItemStyles(props);
  const {level, text, index} = props;
  return (
    <div className={classes.listItem}>
      <Numbering index={index} level={level} />
      <div className={classes.listItemText}>{text}</div>
    </div>
  );
}

const useNumberingStyles = makeStyles({
  numbering: {},
  endsWith: {
    marginRight: ".5em",
  },
});

function Numbering({index, level}) {
  const classes = useNumberingStyles();
  const number = getNumber(level, index);
  if (!number || level < 1) {
    return null;
  }
  return (
    <>
      <div className={classes.numbering}>{number}</div>
      <div className={classes.endsWith}>)</div>
    </>
  );
}

function getNumber(level, index) {
  const listStyleType = getListStyleType(level);
  const number = convertListIndex(index, listStyleType);
  return number;
}

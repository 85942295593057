const REPORT_TYPES = {
  rag_report: {
    label: "Rag Report",
    default_settings: {
      type: "rag_report",
      label: "RAG Report",
      are_report_items_numbered: true,
      add_all_items_to_report: true,
      hide_issue_group_headings: false,
      show_document_risk_score: true,
      fields: [
        {
          type: "issue_name",
          label: "Issue Name",
          value: true,
        },
        {
          type: "reason",
          label: "Reason",
          value: false,
        },
        {
          type: "detailed_reason",
          label: "Detailed Reason",
          value: true,
        },
        {
          type: "required_change",
          label: "Required Change",
          value: false,
        },
        {
          type: "clause_references",
          label: "Clause References",
          value: false,
        },
        {
          type: "clause_text",
          label: "Clause Text",
          value: true,
        },
        {
          type: "fallback",
          label: "Fallback",
          value: false,
        },
        {
          type: "guidance",
          label: "Guidance",
          value: false,
        },
        {
          type: "description",
          label: "Description",
          value: false,
        },
        {
          type: "standard_language",
          label: "Standard Language",
          value: false,
        },
        {
          type: "comment",
          label: "Comment",
          value: false,
        },
        {
          type: "status",
          label: "Status",
          value: true,
        },
        {
          type: "review_state",
          label: "Review State",
          value: false,
        },
        {
          type: "rag_score",
          label: "Risk Score",
          value: false,
        },
      ],
    },
  },
  email: {
    label: "Email Report",
    default_settings: {
      type: "email",
      label: "Email Report",
      add_all_items_to_report: true,
      are_report_items_numbered: true,
      fields: [
        {
          type: "footer_content",
          label: "footer_content",
          value: "Kind Regards",
        },
        {
          type: "header_content",
          label: "header_content",
          value:
            "Dear Sir/Madam, 22224444\n\nWe have reviewed your document and found these issues:",
        },
      ],
    },
  },
  custom_report: {
    label: "Custom Report",
    default_settings: {
      type: "custom_report",
      label: "Custom Report",
      referenced_issueset_id: null,
      rows: [],
      fields: [
        {
          type: "issue_name",
          label: "Issue Name",
          value: true,
        },
        {
          type: "reason",
          label: "Reason",
          value: false,
        },
        {
          type: "detailed_reason",
          label: "Detailed Reason",
          value: true,
        },
        {
          type: "required_change",
          label: "Required Change",
          value: false,
        },
        {
          type: "clause_references",
          label: "Clause References",
          value: false,
        },
        {
          type: "clause_text",
          label: "Clause Text",
          value: true,
        },
        {
          type: "fallback",
          label: "Fallback",
          value: false,
        },
        {
          type: "guidance",
          label: "Guidance",
          value: false,
        },
        {
          type: "description",
          label: "Description",
          value: false,
        },
        {
          type: "standard_language",
          label: "Standard Language",
          value: false,
        },
        {
          type: "comment",
          label: "Comment",
          value: false,
        },
        {
          type: "review_state",
          label: "Review State",
          value: false,
        },
        {
          type: "rag_score",
          label: "Risk Score",
          value: false,
        },
      ],
    },
  },
  notice_of_assignment: {
    label: "Assignment",
    default_settings: {
      type: "notice_of_assignment",
      label: "Assignment",
    },
  },
  transfer_certificate: {
    label: "Transfer Certificate",
    default_settings: {
      type: "transfer_certificate",
      label: "Transfer Certificate",
    },
  },
  assign_consent: {
    label: "Assign (Consent)",
    default_settings: {
      type: "assign_consent",
      label: "Assign (Consent)",
    },
  },
  transfer_consent: {
    label: "Transfer (Consent)",
    default_settings: {
      type: "transfer_consent",
      label: "Transfer (Consent)",
    },
  },
};

export default REPORT_TYPES;

import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";

import HistoryPanel from "../panels/history";
import CommentsPanel from "../panels/comments";
import IssueCommentComponent from "../panels/components/issue_comment_component";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
  },
  top: {},
  bottom: {padding: "1rem"},
});

export default function CollaborateTab(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.top}>
        <CommentsPanel {...props} />
        <HistoryPanel {...props} />
      </div>
      <div className={classes.bottom}>
        <IssueCommentAdditionForm
          initialText={""}
          saveIssueComment={props.addIssueComment}
        />
      </div>
    </div>
  );
}

function IssueCommentAdditionForm({
  initialText,
  saveIssueComment,
  clearInitialText,
}) {
  const isEditMode = Boolean(initialText);

  const [value, setValue] = useState(initialText);
  const [isBlurred, makeBlurred] = useState(isEditMode);

  const onClear = () => {
    setValue("");
    makeBlurred(false);
    if (typeof clearInitialText === "function") {
      clearInitialText();
    }
  };

  useEffect(
    () => {
      setValue(initialText);
      makeBlurred(isEditMode);
    },
    [initialText],
  );

  const onClick = () => makeBlurred(true);
  const onChange = e => {
    setValue(e.target.value);
  };
  const onCancel = () => {
    onClear();
  };
  const onSave = () => {
    saveIssueComment(value);
    onClear();
  };

  return (
    <IssueCommentComponent
      value={value}
      onClick={onClick}
      onChange={onChange}
      rows={isBlurred ? 5 : 0}
      isBlurred={isBlurred}
      onCancel={onCancel}
      onSave={onSave}
    />
  );
}

import _ from "underscore";

const areIndentEqual = (indent1, indent2) =>
  indent1 === indent2 || (!indent1 && !indent2);

const applyListTypeChangesOnSameLevel = (changes, contents) => {
  if (
    changes &&
    changes.ops &&
    changes.ops.length === 2 &&
    contents &&
    contents.ops &&
    contents.ops.length > 1
  ) {
    const [skip, change] = changes.ops;
    const {ops} = contents;
    if (skip && Number.isInteger(skip.retain) && change && change.attributes) {
      const {attributes: changeAttributes} = change;
      const {"list-style-type": changeListStyleType} = changeAttributes;

      let position = 0;
      let currentIndex = 0;
      for (
        ;
        currentIndex < ops.length &&
        ops[currentIndex].insert &&
        position + ops[currentIndex].insert.length <= skip.retain;
        currentIndex += 1
      ) {
        position += ops[currentIndex].insert.length;
      }
      if (currentIndex < ops.length) {
        const {attributes: currentAttributes} = ops[currentIndex];
        if (currentAttributes) {
          const currentListIndexes = [currentIndex];
          for (let index = currentIndex - 1; index >= 0; index -= 1) {
            const {attributes: opAttributes} = ops[index];
            if (opAttributes) {
              if (opAttributes.list !== "ordered") {
                break;
              }
              currentListIndexes.unshift(index);
            }
          }
          for (let index = currentIndex + 1; index < ops.length; index += 1) {
            const {attributes: opAttributes} = ops[index];
            if (opAttributes) {
              if (opAttributes.list !== "ordered") {
                break;
              }
              currentListIndexes.push(index);
            }
          }

          const updates = [];
          for (const index of currentListIndexes) {
            const {attributes: opAttributes} = ops[index];
            if (opAttributes) {
              if (changeListStyleType || changeListStyleType === null) {
                if (
                  areIndentEqual(opAttributes.indent, currentAttributes.indent)
                ) {
                  updates.push({
                    index,
                    name: "list-style-type",
                    value: changeListStyleType,
                  });
                }
              } else if (
                changeListStyleType === undefined &&
                opAttributes.list === "ordered" &&
                areIndentEqual(opAttributes.indent, currentAttributes.indent) &&
                index !== currentIndex
              ) {
                updates.push({
                  index: currentIndex,
                  name: "list-style-type",
                  value: opAttributes["list-style-type"] || null,
                });
                break;
              }
            }
          }
          const sortedUpdates = _.sortBy(updates, "index");

          if (sortedUpdates.length > 0) {
            let startIndex = 0;
            const updateOps = _.flatten(
              _.map(sortedUpdates, ({index, name, value}) => {
                let sum = 0;
                for (let i = startIndex; i < index; i += 1) {
                  sum += ops[i].insert.length;
                }
                startIndex = index + 1;
                const currentUpdateOps = [];
                if (sum > 0) {
                  currentUpdateOps.push({retain: sum});
                }
                currentUpdateOps.push({
                  retain: ops[index].insert.length,
                  attributes: {[name]: value},
                });
                return currentUpdateOps;
              }),
            );
            return updateOps;
          }
        }
      }
    }
  }
  return [];
};

export default applyListTypeChangesOnSameLevel;

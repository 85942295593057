import * as Sentry from "@sentry/react";
import _ from "underscore";
import upperFirst from "utils/upper_first";

const LONG_REQUEST_TIME = 3; // in seconds

const httpMethods = [
  "get",
  "delete",
  "head",
  "options",
  "post",
  "put",
  "patch",
];

function logRequestAttempt(method, args, error) {
  const message = `${upperFirst(method)} request to ${args[0]} failed: ${
    error.data ? error.data.message : "<no error data>"
  }`;
  const err = new Error();
  Object.assign(err, error);
  err.message = message;
  Sentry.captureException(err);
}

function logLongRequest(method, args) {
  const payload = `${method} ${args[0]}`;
  const err = new Error();
  err.message = `LONG_REQUEST_TIME ${payload}`;
  Sentry.captureException(err);
}

export default function wrapHttpMethods(target) {
  httpMethods.forEach(method => {
    if (_.isFunction(target[method])) {
      target[`original_${method}`] = target[method];
      target[method] = async (...args) => {
        try {
          const before = performance.now();
          const result = await target[`original_${method}`](...args);
          const after = performance.now();
          const timeOfRequestSecs = Math.floor((after - before) / 1000);

          if (timeOfRequestSecs > LONG_REQUEST_TIME) {
            logLongRequest(method, args);
          }
          return result;
        } catch (error) {
          logRequestAttempt(method, args, error);
          throw error;
        }
      };
    }
  });
  return target;
}

function reasonViewerTopicparameter(reason) {
  const result = [];
  Object.keys(reason.topics || {}).forEach(topicId => {
    if (topicId) {
      Object.keys(reason.topics[topicId]).forEach(parameterId => {
        if (parameterId) {
          result.push({
            path: `topics.[${topicId}].[${parameterId}]`,
            value: reason.topics[topicId][parameterId],
          });
        }
      });
    }
  });
  return result;
}

export default reasonViewerTopicparameter;

function findNodeByReference(node, reference) {
  if (node) {
    if (node.clauseId === reference) {
      return node;
    } else if (node.clauseNodes && node.clauseNodes.length) {
      for (const childNode of node.clauseNodes) {
        const clausepart = findNodeByReference(childNode, reference);
        if (clausepart) {
          return clausepart;
        }
      }
    }
  }
  return null;
}

export default function getClausepartbyReference(clauses, reference) {
  if (!clauses || !clauses.length || !reference) {
    return null;
  }

  for (const clause of clauses) {
    if (clause && clause.nodes) {
      const clausepart = findNodeByReference(clause.nodes, reference);
      if (clausepart) {
        return clausepart;
      }
    }
  }

  return null;
}

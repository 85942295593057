import React from "react";
import {makeStyles} from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      marginTop: 10,
    },
  },
});

export function StatefulTextField(props) {
  const classes = useStyles();
  const {
    style = {},
    value: initValue,
    label,
    onBlur,
    preventError,
    inputId,
  } = props;
  const [value, updateValue] = React.useState(initValue);
  const [error, updateError] = React.useState(null);
  React.useEffect(
    () => {
      updateValue(initValue);
    },
    [initValue],
  );
  const onValueChange = e => updateValue(e.target.value);
  const onTextBlur = () => {
    if (onBlur) {
      if (!value && !preventError) {
        updateError("Please Input Report Name");
      } else {
        onBlur(value);
      }
    }
  };
  const onFocus = () => {
    updateError(null);
    if (props.onFocus) {
      props.onFocus();
    }
  };
  return (
    <TextField
      inputProps={{id: inputId}}
      className={classes.root}
      label={label}
      onChange={onValueChange}
      value={value}
      style={style}
      onBlur={onTextBlur}
      error={Boolean(error)}
      helperText={error}
      onFocus={onFocus}
    />
  );
}

export default StatefulTextField;

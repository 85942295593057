export const mainStyles = {
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  clearButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "55px",
    borderTop: "1px solid",
  },
};

export const filterTitlesStyles = {
  clearFiltersBtn: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: "#50b3ff",
    fontSize: "12px",
    fontWeigh: "400",
  },
  filterClips: {
    display: "flex",
    alignItems: "center",
    fontSize: "12px",
    fontWeigh: "400",
    borderRadius: "70px",
    padding: "2px 0 2px 6px",
    backgroundColor: "#000",
    color: "#FFF",
  },
  filtersPanel: {
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
    padding: "5px",
    backgroundColor: "#FFF",
    borderTop: "1px solid lightgrey",
    borderBottom: "1px solid lightgrey",
  },
  clearIcon: {
    height: "15px",
    cursor: "pointer",
  },
};

export const optionsStyles = {
  optionsTitle: {
    fontSize: "12px",
    color: "#333",
    fontWeigh: "400",
    paddingLeft: "10px",
  },
  optionsContainer: {
    height: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px 10px",
  },
  optionsDescription: {
    fontSize: "10px",
    display: "block",
    position: "relative",
  },
  optionsIconStyle: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  optionsCheckbox: {
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  optionsRadio: {
    display: "block",
    right: "-4px",
    padding: 0,
  },
};

export const topPanelStyles = {
  filterContainer: {
    display: "flex",
    alignItems: "center",
    borderRight: "1px solid lightgrey",
  },
  container: {
    display: "flex",
  },
};

export const containerStyles = {
  filterContainer: {
    flex: "1 1 50%",
    display: "flex",
    alignItems: "center",
  },
  buttonContainer: {
    height: "100%",
    width: "100%",
    padding: "0 10px",
    color: "#50B2FF",
  },
  buttonTitle: {
    fontWeigh: "400",
    whiteSpace: "nowrap",
  },
  itemsContainer: {
    width: "260px",
  },
};

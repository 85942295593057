import React from "react";
import ReactDOM from "react-dom";

import Paper from "material-ui/Paper";
import * as colors from "material-ui/styles/colors";
import ClauseIcon from "material-ui/svg-icons/editor/short-text";

import Issues from "./issues";
import Topics from "./topics";
import Definitions from "./definitions";
import RelatedClauses from "./related_clauses";
import MenuHeading from "common_components/menu_heading";

import calculateTopics from "./utils/calculate_topics";
import getAcceptedTopicCategoryIds from "utils/topic/get_accepted_topic_category_ids";

const styles = {
  clauseDetail: {
    display: "flex",
    flexDirection: "column",
    paddingRight: 0,
    paddingLeft: 0,
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: "100",
    zIndex: 1,
    height: "100%",
  },
  clauseHeading: {
    paddingTop: "2px",
    marginLeft: "5px",
    fontSize: "16px",
    fontWeight: 400,
  },
  subHeading: {
    backgroundColor: colors.blue500,
    color: "#fff",
    textAlign: "center",
    fontSize: "14px",
    fontWeight: "400",
    padding: "4px",
    cursor: "pointer",
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  content: {
    backgroundColor: "#FAFAFA",
    fontFamily: "Roboto, sans-serif",
    fontSize: "10px",
    overflowY: "auto",
  },
};

class ClauseDetail extends React.Component {
  componentDidUpdate(prevProps) {
    if (
      this.props.currentClause &&
      prevProps.currentClause &&
      this.props.currentClause.clause.id !== prevProps.currentClause.clause.id
    ) {
      ReactDOM.findDOMNode(this.contentRef).scrollTop = 0;
    }
  }
  render() {
    const {currentClause} = this.props;
    return (
      <Paper style={styles.clauseDetail}>
        {this.renderHeading()}
        <div
          className="content"
          ref={this.createContentRef}
          style={styles.content}
        >
          {this.renderContent(currentClause)}
        </div>
      </Paper>
    );
  }

  renderHeading = () => {
    const {currentClause, documentHeadings} = this.props;
    const reference = currentClause.clause.clause_reference
      ? currentClause.clause.clause_reference
      : currentClause.clause.reference;
    let foundHeading = documentHeadings.find(
      heading => heading.reference === reference,
    );
    if (!foundHeading) {
      foundHeading = documentHeadings.find(
        heading => reference.indexOf(heading.reference) === 0,
      );
    }
    const headingText = foundHeading ? foundHeading.text : "";
    const headingTextArray = headingText.split(" ");
    const clauseHeading =
      headingTextArray[0] === "Clause"
        ? `Clause ${reference}`
        : `${reference}. ${headingText}`;
    return (
      <MenuHeading
        onCloseClick={this.props.clearPanelData}
        menuIcon={
          <ClauseIcon
            style={{
              width: "28px",
              height: "28px",
              marginLeft: "1px",
              flexShrink: 0,
            }}
          />
        }
      >
        <div style={styles.clauseHeading}>{clauseHeading}</div>
      </MenuHeading>
    );
  };

  renderContent(currentClause) {
    const {topicsById, positiveReasonData, issues} = this.props;
    const definitionsCount = currentClause.definitions.length
      ? `(${currentClause.definitions.length})`
      : "";
    const acceptedTopicCategoryIds = getAcceptedTopicCategoryIds(
      this.props.topicCategories,
    );
    return (
      <div>
        <Issues
          headingStyles={styles.subHeading}
          clauseIssues={getClauseIssues(currentClause.clause, issues)}
          documentClauses={this.props.documentClauses}
          topicsById={topicsById}
          positiveReasonData={positiveReasonData}
          showIssueDetail={this.props.showIssueDetail}
          documentClauseparts={this.props.documentClauseparts}
        />
        <Topics
          headingStyles={styles.subHeading}
          topics={calculateTopics(currentClause.clause, this.props)}
          acceptedTopicCategoryIds={acceptedTopicCategoryIds}
          documentClauses={this.props.documentClauses}
          scrollToClause={this.props.scrollToClause}
        />
        <Definitions
          headingStyles={styles.subHeading}
          currentClause={currentClause}
          definitionsCount={definitionsCount}
        />
        <RelatedClauses
          headingStyles={styles.subHeading}
          currentClause={currentClause}
          topicCategories={this.props.topicCategories}
          acceptedTopicCategoryIds={acceptedTopicCategoryIds}
          topicsById={topicsById}
          documentClauses={this.props.documentClauses}
          scrollToClause={this.props.scrollToClause}
        />
      </div>
    );
  }

  onClose = () => {
    this.props.clearPanelData();
  };

  createContentRef = node => (this.contentRef = node);
}

function getClauseIssues(clause, issues) {
  const clauseId = clause.id;
  return issues.filter(
    issue =>
      issue.reason &&
      issue.reason.filter(reason => reason.reason.clause_id === clauseId)
        .length > 0,
  );
}

export default ClauseDetail;

import React, {useEffect, useState} from "react";

import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import Switch from "@material-ui/core/Switch";
import RevertIcon from "material-ui/svg-icons/av/replay";
import FindReplaceIcon from "material-ui/svg-icons/action/find-replace";
import Delete from "material-ui/svg-icons/action/delete";
import AddAbove from "material-ui/svg-icons/editor/vertical-align-top";
import AddBelow from "material-ui/svg-icons/editor/vertical-align-bottom";
import FormatStrikethroughIcon from "material-ui/svg-icons/editor/strikethrough-s";
import * as colors from "material-ui/styles/colors";

import {tooltipStyles} from "common_components/gui_text_editor/clausepart_control";
import Tooltip from "common_components/tooltip";

import FindAndReplace from "./find_and_replace";

import renderChangesTable from "./render_changes_table";

const styles = {
  mainContainer: {
    borderBottom: `1px solid ${colors.grey300}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    fontSize: ".85em",
  },
  row: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  containerLeft: {
    height: "38px",
    marginLeft: "1.5rem",
    borderRight: `1px solid ${colors.grey300}`,
  },
  containerRight: {
    height: "38px",
    marginRight: "1.5rem",
    borderLeft: `1px solid ${colors.grey300}`,
  },
  editMode: {
    display: "flex",
    alignItems: "center",
    marginRight: "1rem",
    color: colors.grey600,
  },
  editModeSpan: {
    fontWeight: "600",
    color: "#000",
  },
  iconBase: {
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  color: colors.grey600,
  iconIndent: {
    marginLeft: ".7rem",
    marginRight: "1rem",
  },
  iconButtonStyle: {
    padding: "unset",
    width: "unset",
    height: "unset",
  },
  divider: {
    width: "2.55rem",
  },
  separator: {
    height: "1.5rem",
    borderLeft: `1px solid ${colors.grey300}`,
  },
  findAndReplace: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
};

const EditModePanel = ({
  editModeOn,
  editableClausepart,
  documentHasChanges,
  onRevertDocumentChangesDialogShow,
  hideTextDeletions,
  triggerHideTextDeletions,
  onAddClauseBeforeCurrent,
  onAddClausepartBeforeCurrent,
  onAddClauseAfterCurrent,
  onAddClausepartAfterCurrent,
  onDeleteClause,
  onDeleteClausepart,
  setEditModeOn,
  findAndReplace,
  onFindAndReplaceChange,
  documentTextReplace,
  document,
}) => {
  const [bottomPanel, setBottomPanel] = useState(null);
  const [shownChanges, setShownChanges] = useState(null);
  const [hideEditModePanel, setHideEditModePanel] = useState(false);

  useEffect(() => {
    if (document.file_extension === "pdf" || document.plain_text_length > 100000) {
      setHideEditModePanel(true);
    }
  }, []);

  const renderLeftContainer = () => {
    return (
      <div style={{...styles.row, ...styles.containerLeft}}>
        {renderEditModeSwitcher()}
        {renderSeparator()}
        {renderRevertAllChanges()}
        {renderSeparator()}
        {renderHideTextDeletions()}
        {renderSeparator()}
        {renderSearchAndReplace()}
      </div>
    );
  };

  const renderRightContainer = () => {
    const contents = renderClauseEditControl();
    return contents ? (
      <div style={{...styles.row, ...styles.containerRight}}>{contents}</div>
    ) : null;
  };
  const renderEditModeSwitcher = () => {
    return (
      <div style={styles.editMode}>
        <div style={styles.editModeSpan}>Edit Mode</div>
        <div style={styles.row} onClick={toggleEditModeOn}>
          <Switch checked={editModeOn} color="primary" />
          <div>{editModeOn ? "On" : "Off"}</div>
        </div>
      </div>
    );
  };
  const renderSeparator = () => <div style={styles.separator} />;
  const renderRevertAllChanges = () => {
    return renderItemWithTooltip(
      "Revert All Changes",
      <RevertIcon
        style={{
          ...styles.iconBase,
          ...styles.iconIndent,
          color: documentHasChanges ? colors.grey800 : colors.grey400,
        }}
        onClick={
          documentHasChanges
            ? onRevertDocumentChangesDialogShow
            : undefined
        }
      />,
    );
  };
  const renderHideTextDeletions = () => {
    return renderItemWithTooltip(
      `${hideTextDeletions ? "Show" : "Hide"} Text Deletions`,
      <FormatStrikethroughIcon
        style={{
          ...styles.iconBase,
          ...styles.iconIndent,
          color: getIconColor(!hideTextDeletions),
        }}
        onClick={triggerHideTextDeletions}
      />,
    );
  };
  const renderSearchAndReplace = () => {
    const fontStyles = {
      color: getIconColor(bottomPanel === "findAndReplace"),
    };
    return renderItemWithTooltip(
      "Find And Replace",
      <div
        style={{
          ...styles.findAndReplace,
          ...styles.iconIndent,
          ...fontStyles,
        }}
        onClick={onShowBottomPanel("findAndReplace")}
      >
        <FindReplaceIcon style={{...styles.iconBase, ...fontStyles}} />
        <span style={{marginLeft: ".3rem"}}>Find &amp; Replace</span>
      </div>,
    );
  };
  const renderItemWithTooltip = (message, element) => (
    <Tooltip
      key={message}
      tooltipContent={message}
      rootDivStyle={{...tooltipStyles.rootDiv, ...styles.row}}
      tooltipContainerStyle={tooltipStyles.getTooltipContainerStyles({
        right: "16px",
      })}
      tooltipContentStyle={tooltipStyles.getTooltipContentStyles({
        width: `${message.length * 0.32}rem`,
      })}
      delay={300}
    >
      {element}
    </Tooltip>
  );
  const renderChangesDialog = () => {
    if (!shownChanges) {
      return null;
    }
    const actions = [
      <FlatButton
        key="changes-dialog-ok-button"
        label="OK"
        primary={true}
        onClick={hideChangesDialog}
      />,
    ];
    return (
      <Dialog
        title="Some changes could not be made."
        actions={actions}
        modal={false}
        open={true}
        onRequestClose={hideChangesDialog}
      >
        <div>
          The system was unable to apply some of the changes to the document.
        </div>
        <div>{renderChangesTable(shownChanges)}</div>
      </Dialog>
    );
  };
  const renderClauseEditControl = () => {
    if (editableClausepart && !editableClausepart.isClauseAddition) {
      const {
        isFirstNodeAtomOrList,
        willRenderReference,
        shouldClausepartButtonsBeShown,
      } = editableClausepart;
      const isDeleteSubclauseDisabled =
        editableClausepart.isClausepartAddition ||
        editableClausepart.isClausepartDeletion ||
        editableClausepart.isClauseDeletion ||
        editableClausepart.hasClausepartTextChanges;
      return [
        {
          tooltipMessage: "Add Item Above",
          element: (
            <IconMenu
              iconButtonElement={
                <IconButton
                  style={styles.iconButtonStyle}
                  iconStyle={styles.iconBase}
                >
                  <AddAbove />
                </IconButton>
              }
              style={{top: "1px", margin: "0 1px"}}
            >
              <MenuItem
                onClick={onAddClauseBeforeCurrent}
                primaryText="Add clause above"
                leftIcon={<AddAbove />}
              />
              <MenuItem
                onClick={
                  !isFirstNodeAtomOrList && onAddClausepartBeforeCurrent
                }
                primaryText="Add subclause above"
                leftIcon={<AddAbove />}
                disabled={
                  isFirstNodeAtomOrList ||
                  willRenderReference ||
                  !shouldClausepartButtonsBeShown
                }
              />
            </IconMenu>
          ),
        },
        {
          tooltipMessage: "Add Item Below",
          element: (
            <IconMenu
              iconButtonElement={
                <IconButton
                  style={styles.iconButtonStyle}
                  iconStyle={styles.iconBase}
                >
                  <AddBelow />
                </IconButton>
              }
              style={{top: "1px", margin: "0 1px"}}
            >
              <MenuItem
                onClick={onAddClauseAfterCurrent}
                primaryText="Add clause below"
                leftIcon={<AddBelow />}
              />
              <MenuItem
                onClick={
                  !isFirstNodeAtomOrList && onAddClausepartAfterCurrent
                }
                primaryText="Add subclause below"
                leftIcon={<AddBelow />}
                disabled={
                  isFirstNodeAtomOrList ||
                  willRenderReference ||
                  !shouldClausepartButtonsBeShown
                }
              />
            </IconMenu>
          ),
        },
        {
          tooltipMessage: "Delete Item",
          element: (
            <IconMenu
              iconButtonElement={
                <IconButton
                  style={styles.iconButtonStyle}
                  iconStyle={styles.iconBase}
                >
                  <Delete />
                </IconButton>
              }
              style={{top: "1px", margin: "0 1px"}}
            >
              <MenuItem
                onClick={onDeleteClause}
                primaryText="Delete clause"
                leftIcon={<Delete />}
                disabled={editableClausepart.isClauseDeletion}
              />
              <MenuItem
                onClick={
                  !isDeleteSubclauseDisabled && onDeleteClausepart
                }
                primaryText="Delete subclause"
                disabled={isDeleteSubclauseDisabled || willRenderReference}
                leftIcon={<Delete />}
              />
            </IconMenu>
          ),
        },
      ].map(({tooltipMessage, element}) =>
        renderItemWithTooltip(tooltipMessage, element),
      );
    }
    return null;
  };
  const hideChangesDialog = () => setShownChanges(null);
  const renderBottomPanel = () => {
    switch (bottomPanel) {
      case "findAndReplace": {
        return (
          <FindAndReplace
            find={findAndReplace.find}
            replace={findAndReplace.replace}
            onFindAndReplaceChange={onFindAndReplaceChange}
            onReplace={documentTextReplace}
          />
        );
      }
      default:
        return null;
    }
  };
  const getIconColor = isIconSelected => isIconSelected ? colors.grey800 : colors.grey400;
  const onShowBottomPanel = panelName => () => {
    if (panelName === bottomPanel) {
      setBottomPanel(null);
    } else {
      setBottomPanel(panelName);
    }
  };
  const toggleEditModeOn = () => setEditModeOn(!editModeOn);

  return !hideEditModePanel ? (
    <>
      <div id="edit-mode-panel" style={styles.mainContainer}>
        <div
          style={{
            ...styles.row,
            justifyContent: "space-between",
            borderBottom: bottomPanel === "findAndReplace"
              ? `1px solid ${colors.grey300}`
              : "none",
          }}
        >
          {renderLeftContainer()}
          {renderRightContainer()}
        </div>
        {renderBottomPanel()}
      </div>
      {renderChangesDialog()}
    </>
  ) : null;
};

export default EditModePanel;

import getColorByBgColor from "../../../utils/get_color_by_bg_color";

const getRoleColor = (party, roles, projectRoles) => {
  if (!party.role_id) {
    return {};
  }
  const role =
    (projectRoles || []).find(
      role => role.id === party.role_id && role.color,
    ) ?? roles.find(role => role.id === party.role_id);
  if (role) {
    return {
      backgroundColor: role.color,
      color: getColorByBgColor(role.color),
    };
  }
  return {};
};

export default getRoleColor;

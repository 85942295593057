import isBadRegex from "utils/bad_regexes";
const normalStyle = {
  textDecoration: "",
};

const warningStyle = {
  textDecoration: "underline wavy #ffb734",
};

const dangerStyle = {
  textDecoration: "underline wavy #fb1313",
};

const ifHasWarningSpaces = text => Boolean(text.match(/[\n\s]/));

const ifHasDangerSpaces = text => Boolean(text.match(/\s{2,}|^\s|\s$/));

const getStyle = text => {
  if (typeof text === "string") {
    if (ifHasDangerSpaces(text) || isBadRegex(text)) {
      return dangerStyle;
    } else if (ifHasWarningSpaces(text)) {
      return warningStyle;
    }
  }

  return normalStyle;
};

export default {
  getStyle,
};

import Describer from "../../../utils/issues/reason/describer";

function reason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);
  return [
    `Topic '${describer.issueTopicName}' is not present`,
    showClause ? " in any clause" : "",
  ].join("");
}

export default reason;

import Describer from "../../../utils/issues/issue_description/describer";

function render(issue, topics) {
  const describer = new Describer.IssueDescriber(issue, topics);
  const {rules} = issue;
  const topicText = `${describer.topicName} exists`;
  const parameterText = rules.ignore_params
    ? "regardless of parameters"
    : rules.any
      ? "and any parameter exists"
      : rules.parameter_ids.length > 0
        ? `and ${
            !rules.match_all && rules.parameter_ids.length > 1 ? "either " : ""
          }${describer.listParameterRules(rules.match_all ? "and" : "or")}`
        : "without parameters";

  return `${topicText}, ${parameterText} on any instance`;
}

export default render;

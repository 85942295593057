import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {buildQuery} from "utils/uri";

export default function initDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENT_FETCH,
    (organisationId, projectId, documentId, options) => {
      const reqString = `${basePath}/organisation/${organisationId}/project/${projectId}
        /document/${documentId}${buildQuery(options)}`;
      return requestor
        .get(reqString, {withCredentials: true})
        .then(response => ({
          organisation_id: parseInt(organisationId, 10),
          project_id: parseInt(projectId, 10),
          document_id: parseInt(documentId, 10),
          ...response.data,
        }));
    },
  );
}

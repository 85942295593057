import ohm from "ohm-js";

const grammarSource = String.raw`
  Rules {
    Exp = Rule
    Rule = AndExp -- and
      | OrExp -- or
      | ConceptDescriptor
    AndExp = "AND(" NonemptyListOf<Rule, ", "> ")"
    OrExp = "OR(" NonemptyListOf<Rule, ", "> ")"
    ConceptDescriptor =  "!" TopicId ParameterId Value -- negated_topic_and_param_value
                      | TopicId ParameterId Value -- topic_and_param_value
                      | "!" TopicId ParameterId -- negated_topic_and_param
                      | TopicId ParameterId -- topic_and_param
                      | "!" TopicId -- negated_topic
                      | TopicId -- topic
    TopicId = number
    ParameterId = number
    quotedValue = ("'" (alnum | space)* "'")
    Value = alnum+ | quotedValue
    number = digit+
  }
`;

const grammar = ohm.grammar(grammarSource);

export default grammar;

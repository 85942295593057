import _ from "underscore";

export default function addIndentationToClauses(
  clausesGroupedByHeadingsAndDefinitions,
) {
  return clausesGroupedByHeadingsAndDefinitions.map(group => {
    const newItems = addIndentationToItems(group.items);
    return {...group, items: newItems};
  });
}

function addIndentationToItems(allItems) {
  const allLevels = [];
  allItems.forEach(baseItem => {
    if (baseItem.items) {
      baseItem.items.forEach(baseItemItem => {
        allLevels.push(baseItemItem.level);
      });
    }
    if (baseItem.item) {
      allLevels.push(baseItem.item.level);
    }
  });

  const indents = _.chain(allLevels)
    .uniq()
    .sortBy(it => it)
    .reduce((result, level, levelIndex) => {
      result[level] = levelIndex;
      return result;
    }, {})
    .value();

  const indentData = {
    prevIndent: null,
    prevItemIndent: null,
  };
  return allItems.map(baseItem => {
    if (baseItem.items) {
      const newItems = baseItem.items.map(baseItemItem => {
        const itemIndent = indents[baseItemItem.level];
        return {
          ...baseItemItem,
          indent: getIndent(itemIndent, indentData),
        };
      });
      return {...baseItem, items: newItems};
    }
    const itemIndent = indents[baseItem.item.level];
    baseItem.item = {
      ...baseItem.item,
      indent: getIndent(itemIndent, indentData),
    };
    return baseItem;
  });
}

function getIndent(itemIndent, indentData) {
  let indent;
  if (indentData.prevIndent === null) {
    indent = 0;
    indentData.prevItemIndent = itemIndent;
    indentData.prevIndent = indent;
    return indent;
  }
  if (itemIndent === indentData.prevItemIndent) {
    indent = indentData.prevIndent;
  }
  if (itemIndent > indentData.prevItemIndent) {
    indent = indentData.prevIndent + 1;
  }
  if (itemIndent < indentData.prevItemIndent) {
    indent = indentData.prevIndent - (indentData.prevItemIndent - itemIndent);
  }
  indentData.prevItemIndent = itemIndent;
  indentData.prevIndent = indent;
  if (indent < 0) {
    indent = 0;
    indentData.prevIndent = 0;
  }
  return indent;
}

import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import formatDate from "./utils/format_date";
import getPersonName from "./utils/get_person_name";
import getLogType from "./utils/get_log_type";
import ItemContainer from "../helper_components/item_container";

export default function HistoryPanel({
  logs,
  organisationId,
  document,
  issue,
  logsFetch,
  onItemOpen,
  openTabs,
}) {
  React.useEffect(
    () => {
      if (document && issue) {
        logsFetch(
          organisationId,
          `document_id=${document.id}&issue_id=${issue.id}`,
        );
      }
    },
    [document.id, issue.id],
  );
  if (!logs) {
    return null;
  }
  return (
    <ItemContainer
      title="History"
      expandable={true}
      onExpandMenu={onItemOpen("historyPanel")}
      isMenuExpanded={openTabs.historyPanel}
      containerStyles={{flex: "1 0 auto"}}
    >
      {logs.length ? (
        logs.map((log, index) => (
          <TextWithAuthorAndDate
            key={index}
            text={getLogType(log)}
            author={getPersonName(log)}
            date={formatDate(log.time)}
          />
        ))
      ) : (
        <div>No history</div>
      )}
    </ItemContainer>
  );
}

const usemakeTextWithAuthorAndDateStylesStypes = makeStyles({
  main: {
    padding: "1em 1.5em",
    border: "1px solid rgb(221, 221, 221)",
    margin: "0 0 .5em",
  },
  type: {
    marginBottom: ".5em",
    fontWeight: "bold",
  },
  sign: {
    display: "flex",
    fontSize: ".75em",
    "& > *": {
      marginRight: ".5em",
    },
  },
  author: {
    fontWeight: "bold",
  },
});

function TextWithAuthorAndDate({text, author, date}) {
  const classes = usemakeTextWithAuthorAndDateStylesStypes();
  return (
    <div className={classes.main}>
      <div className={classes.sign}>
        <div className={classes.author}>{author}</div>
        <div>|</div>
        <div>{date}</div>
      </div>
      <div className={classes.text}>{text}</div>
    </div>
  );
}

import basePath from "base_path";
import requestor from "requestor";
import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.SEARCH_BY_ISSUE,
  (
    organisationId: number,
    projectId: number,
    issueId: number | number[],
    issuesetPath = "base",
  ) => {
    return requestor
      .get(
        `${basePath}/organisation/${organisationId}/search?type=text&query=${
          Array.isArray(issueId) ? issueId.join(" ") : issueId
        }&project=${projectId}&issueset_path=${issuesetPath}&remove_duplicates=true`,
        {
          withCredentials: true,
        },
      )
      .then(response => {
        return response.data;
      });
  },
);

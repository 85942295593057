import apply from "./apply_function";
import areConceptsInClause from "./are_concepts_in_clause";
import areConceptsInDocument from "./are_concepts_in_document";
import ifConceptsInClause from "./if_concepts_in_clause";
import ifConceptsInDocument from "./if_concepts_in_document";
import compose from "./compose";
import concat from "./concat";
import extractDuration from "./extract_duration_data";
import extractMonetary from "./extract_monetary_data";
import extractPercentage from "./extract_percentage_data";
import extractValue from "./extract_value_data";
import flatten from "./flatten";
import getFirstValueInLists from "./get_first_value_in_lists";
import groupBy from "./group_by";
import groupByTopicOrder from "./group_by_topic_order";
import groupTextByClause from "./group_text_by_clause";
import ifCond from "./if_cond";
import isInList from "./is_in_list";
import isInClause from "./is_in_clause";
import isListEmpty from "./is_list_empty";
import isListPopulated from "./is_list_populated";
import join from "./join";
import makeList from "./make_list";
import makeObject from "./make_object";
import list from "./list";
import listLength from "./list_length";
import listRaw from "./list_raw";
import listDeep from "./list_deep";
import map from "./map";
import max from "./max";
import min from "./min";
import not from "./not";
import pc from "./pc";
import pluralise from "./pluralise";
import replace from "./replace";
import replaceInList from "./replace_in_list";
import split from "./split";
import splitEach from "./split_each";
import template from "./template";
import templateEach from "./template_each";
import unique from "./unique";
import and from "./and";
import or from "./or";
import compare from "./compare";
import ifFn from "./if";
import indexExistsInList from "./index_exists_in_list";
import intersection from "./intersection";
import getReferences from "./get_references";
import getReferringClauses from "./get_referring_clauses";
import getClauseValue from "./get_clause_value";
import getClauseReference from "./get_clause_reference";
import getClauseReferences from "./get_clause_references";
import getClausepartIds from "./get_clausepart_ids";
import filterOut from "./filter_out";
import filter from "./filter";
import filterEach from "./filter_each";
import filterEmptyTextArrays from "./filter_empty_text_arrays";
import filterCondition from "./filter_condition";
import filterConditionOut from "./filter_condition_out";
import filterWhenEmpty from "./filter_when_empty";
import findFirstMatches from "./find_first_matches";
import parseIntList from "./parse_int_list";
import log from "./log";
import formatNumber from "./format_number";
import joinLists from "./join_lists";
import joinObjects from "./join_objects";
import joinNonEmptyLists from "./join_non_empty_lists";
import joinListsDeep from "./join_lists_deep";
import pick from "./pick";
import setVariable from "./set_variable";
import getVariable from "./get_variable";
import zip from "./zip";
import zipDouble from "./zip_double";
import zipObject from "./zip_object";
import stringify from "./stringify";
import sort from "./sort";
import sortReferences from "./sort_references";

const functionList = {
  apply,
  areConceptsInClause,
  areConceptsInDocument,
  ifConceptsInClause,
  ifConceptsInDocument,
  compose,
  concat,
  extractDuration,
  extractMonetary,
  extractPercentage,
  extractValue,
  flatten,
  getFirstValueInLists,
  groupBy,
  groupByTopicOrder,
  groupTextByClause,
  ifCond,
  isInList,
  isInClause,
  isListEmpty,
  isListPopulated,
  join,
  list,
  listLength,
  listRaw,
  listDeep,
  makeList,
  makeObject,
  map,
  min,
  max,
  not,
  pc,
  pluralise,
  replace,
  replaceInList,
  split,
  splitEach,
  template,
  templateEach,
  unique,
  and,
  or,
  compare,
  if: ifFn,
  indexExistsInList,
  intersection,
  getReferences,
  getReferringClauses,
  getClauseValue,
  getClauseReference,
  getClauseReferences,
  getClausepartIds,
  filterOut,
  filter,
  filterEach,
  filterEmptyTextArrays,
  filterCondition,
  filterConditionOut,
  filterWhenEmpty,
  findFirstMatches,
  parseIntList,
  log,
  formatNumber,
  joinLists,
  joinObjects,
  joinNonEmptyLists,
  joinListsDeep,
  pick,
  setVariable,
  getVariable,
  zip,
  zipDouble,
  zipObject,
  stringify,
  sort,
  sortReferences,
};

export default functionList;

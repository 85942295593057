import React, {useState} from "react";
import ReplayIcon from "@material-ui/icons/Replay";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import {
  IssueIcon,
  NoIssueIcon,
  WarningIcon,
  GuidanceIcon,
  FixedIcon,
  IgnoreIcon,
} from "constants/icons";

import issueHeaderUtils from "utils/issues/issue_header_utils";
import getDefaultColor from "utils/issues/get_default_color";
import {
  getIssueManualCorrections,
  getUpdatedManualCorrections,
} from "utils/manual_corrections_utils";
import Permissioner from "utils/permissioner";
import globalOverrideKey from "common/utils/issues/global_override_key";

import "./issue_item_icon_menu.css";

const styles = {
  menuItemContainer: {
    padding: "8px 6px",
    fontSize: 16,
    color: "#424242",
    minHeight: 0,
  },
  recommended: {
    fontSize: 11,
    fontWeight: 500,
    margin: "0px 4px 0px 24px",
    color: issueHeaderUtils.colors["blue0"],
  },
  icon: {
    width: 18,
    height: 18,
    cursor: "pointer",
    borderRadius: "50%",
    marginLeft: "2px",
  },
  iconWrapper: {
    position: "absolute",
    top: "2px",
    left: "25px",
  },
  recommendedBlock: {
    display: "flex",
    alignItems: "center",
  },
  menu: {
    vertical: "top",
    horizontal: "right",
  },
};

function IssueItemIconMenu(props) {
  const {isFlipChecklistIcons} = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, updateIsHovered] = useState(false);
  const open = Boolean(anchorEl);

  const {
    issue,
    disabled,
    project,
    Icon,
    // selectedReportId,
    issueset,
  } = props;

  const defaultIssueValue = {
    new_state: undefined,
    alert_color: undefined,
  };

  const issueValues = [
    {
      label: "Issue",
      value: {
        new_state: "alert",
        alert_color: "red",
      },
    },
    {
      label: "Warning",
      value: {
        new_state: "alert",
        alert_color: "amber",
      },
    },
    {
      label: "No issue",
      value: {
        new_state: "non-alert",
        alert_color: "green",
      },
    },
    {
      label: "Guidance",
      value: defaultIssueValue,
    },
  ];

  const handleClick = e => {
    if (disabled) {
      return null;
    }
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TODO: override for report only
  // See data from get_issues_report_data
  // const correctionKey = selectedReportId || globalOverrideKey;
  const correctionKey = globalOverrideKey;

  const defaultLabel = getDefaultLabel(issue, issueset, correctionKey);
  const currentLabel = getCurrentLabel(issue, issueset, correctionKey);

  function onManualCorrectDocumentIssue(updates) {
    if (issue.groupName === "Unrecognised Clauses" || issue.correction) {
      return;
    }
    const newManualCorrections = getUpdatedManualCorrections(
      issue.manual_corrections,
      correctionKey,
      null,
      null,
      updates,
    );

    if (newManualCorrections) {
      const finalUpdates = {
        manual_corrections: newManualCorrections,
      };
      if (issue.resolved_state === 0 || issue.resolved_state === 1) {
        finalUpdates.resolved_state = null;
      }
      props.correctDocumentIssueManually(issue, finalUpdates);
    }
  }

  function getIssueValue(label) {
    return issueValues.find(issue => issue.label === label).value;
  }

  function revertToDefaultValue() {
    onManualCorrectDocumentIssue(getIssueValue(defaultLabel));
    onHoverFinish();
  }

  // logic for Issue
  function makeIssue() {
    if (currentLabel === "Issue") {
      return null;
    }
    let updates = {};
    if (defaultLabel === "Issue") {
      updates = defaultIssueValue;
    } else {
      updates = getIssueValue("Issue");
    }
    onManualCorrectDocumentIssue(updates);
  }

  // logic for Warning
  function makeWarning() {
    if (currentLabel === "Warning") {
      return null;
    }
    let updates = {};
    if (defaultLabel === "Warning") {
      updates = defaultIssueValue;
    } else {
      updates = getIssueValue("Warning");
    }
    onManualCorrectDocumentIssue(updates);
  }

  // logic for No issue
  function makeNoIssue() {
    if (currentLabel === "No issue") {
      return null;
    }
    let updates = {};
    if (defaultLabel === "No issue") {
      updates = defaultIssueValue;
    } else {
      updates = getIssueValue("No issue");
    }
    onManualCorrectDocumentIssue(updates);
  }

  // logic for Guidance
  function makeGuidance() {
    if (currentLabel === "Guidance") {
      return null;
    }
    onManualCorrectDocumentIssue(defaultIssueValue);
  }

  // logic for Fixed
  const hideFixed = !project.resolve_issues || props.hideResolveActions;
  const isGuidance = issue.issue_class_id === 2;

  function onResolve() {
    const resolved_state = issue.resolved_state === 1 ? null : 1;
    props.updateDocumentIssue(issue, {
      resolved_state,
    });
  }

  function onIgnore() {
    const resolved_state = issue.resolved_state === 0 ? null : 0;
    props.updateDocumentIssue(issue, {
      resolved_state,
    });
  }

  // menuItems construction
  const menuItems = [
    {
      label: "Issue",
      handler: createItemClicker(handleClose, makeIssue),
      Icon: IssueIcon,
      iconColor: "red0",
    },
    {
      label: "Warning",
      handler: createItemClicker(handleClose, makeWarning),
      Icon: WarningIcon,
      iconColor: "amber0",
    },
    {
      label: "No issue",
      handler: createItemClicker(handleClose, makeNoIssue),
      Icon: NoIssueIcon,
      iconColor: "green0",
    },
    isGuidance && {
      // Note issue_class
      label: "Guidance",
      handler: createItemClicker(handleClose, makeGuidance),
      Icon: GuidanceIcon,
      iconColor: "grey0",
    },
    !hideFixed && {
      label: issue.resolved_state === 1 ? "Revert Actioned State" : "Actioned",
      handler: createItemClicker(handleClose, onResolve),
      Icon: FixedIcon,
      iconColor: "blue1",
    },
    !hideFixed && {
      label: issue.resolved_state === 0 ? "Revert Ignored State" : "Ignored",
      handler: createItemClicker(handleClose, onIgnore),
      Icon: IgnoreIcon,
      iconColor: "grey0",
    },
  ].filter(item => item);

  function renderDefaultIcon() {
    if (
      !props.hideDefaultIcon &&
      !project.hide_state_changes &&
      defaultLabel &&
      currentLabel &&
      defaultLabel !== currentLabel
    ) {
      const DefaultIcon = (
        menuItems.find(item => item.label === defaultLabel) || {}
      ).Icon;
      return (
        <div className="default-checklist-item-icon">
          <DefaultIcon
            style={{
              ...props.iconStyles,
              color: "#9e9e9e",
            }}
          />{" "}
        </div>
      );
    }
  }

  const canCorrectDocumentIssues = new Permissioner(props.user).hasPermission(
    "can-correct-document-issues",
  );
  const onHoverStart = () => updateIsHovered(true);
  const onHoverFinish = () => updateIsHovered(false);

  return (
    <div
      style={{position: "relative"}}
      onMouseEnter={onHoverStart}
      onMouseLeave={onHoverFinish}
    >
      <div
        style={{
          ...styles.recommendedBlock,
          ...(canCorrectDocumentIssues
            ? {}
            : {
                marginTop: ".3em",
                marginRight: "1em",
              }),
          ...(props.iconContainerStyles || {}),
        }}
        onClick={handleClick}
      >
        {renderDefaultIcon()} <Icon style={props.iconStyles} />
        {"  "}
        {!props.hideSelector &&
          canCorrectDocumentIssues && (
            <ExpandMoreIcon
              style={{
                color: isFlipChecklistIcons ? "#bdbdbd" : "#5D6770",
                visibility: disabled ? "hidden" : "visible",
                width: 24,
                height: 24,
              }}
            />
          )}{" "}
      </div>{" "}
      {props.fromReport && isHovered && defaultLabel !== currentLabel ? (
        <div style={styles.iconWrapper} title="Revert to default value">
          <ReplayIcon style={styles.icon} onClick={revertToDefaultValue} />
        </div>
      ) : null}
      {canCorrectDocumentIssues && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          style={styles.menuContainer}
          PaperProps={{
            style: {
              borderRadius: 0,
            },
          }}
          getContentAnchorEl={null}
          anchorOrigin={styles.menu}
          transformOrigin={styles.menu}
        >
          {menuItems.map((item, itemIndex) => {
            const {Icon} = item;
            return (
              <MenuItem
                key={itemIndex}
                style={styles.menuItemContainer}
                onClick={item.handler}
              >
                <ListItemIcon
                  style={{
                    minWidth: 0,
                  }}
                >
                  <Icon
                    style={{
                      width: 18,
                      height: 18,
                      marginRight: 10,
                      color: issueHeaderUtils.colors[item.iconColor],
                    }}
                  />{" "}
                </ListItemIcon>{" "}
                <div style={styles.recommendedBlock}>
                  <div> {item.label} </div>{" "}
                  {defaultLabel === item.label && (
                    <div style={styles.recommended}> Recommended </div>
                  )}{" "}
                </div>{" "}
              </MenuItem>
            );
          })}{" "}
        </Menu>
      )}{" "}
    </div>
  );
}

function createItemClicker(onClose, handler) {
  return e => {
    e.stopPropagation();
    if (handler) {
      handler();
    }
    onClose();
  };
}

function getDefaultLabel(issue, issueset) {
  const colorLabelMap = {
    red: "Issue",
    amber: "Warning",
    green: "No issue",
    grey: "Guidance",
  };
  const defaultColor = getDefaultColor(issue, issueset);
  return colorLabelMap[defaultColor];
}

function getCurrentLabel(issue, issueset, key) {
  if (issue.resolved_state === 1) {
    return "Actioned";
  } else if (issue.resolved_state === 0) {
    return "Ignored";
  }

  const manualCorrections = getIssueManualCorrections(issue, issueset, key);
  let alertColor = manualCorrections.alert_color;
  if (!alertColor && key !== globalOverrideKey) {
    const globalManualCorrections = getIssueManualCorrections(
      issue,
      issueset,
      globalOverrideKey,
    );
    const {alert_color: secondaryAlertColor} = globalManualCorrections;
    alertColor = secondaryAlertColor;
  }

  if (alertColor === "green") {
    return "No issue";
  } else if (alertColor === "red") {
    return "Issue";
  } else if (alertColor === "amber") {
    return "Warning";
  }
  return getDefaultLabel(issue, issueset);
}

export default IssueItemIconMenu;

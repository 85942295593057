import UNINITIALISED from "utils/uninitialised";
import {MaybeUninitialised} from "modules/redux_types";
import {GetWorkflowsAction} from "../actions/workflows_fetch";
import {WorkflowAddAction} from "../actions/workflow_add";
import {WorkflowUpdateAction} from "../actions/workflow_update";
import {WorkflowDuplicateAction} from "../actions/workflow_duplicate";
import {WorkflowRemoveAction} from "../actions/workflow_remove";
import actionTypes from "../constants/action_types";
import {WorkflowInfo, WorkflowId} from "common/flowmaster/types/workflow";

export default function workflowsReducer(
  state: MaybeUninitialised<WorkflowInfo[]> = UNINITIALISED,
  action:
    | GetWorkflowsAction
    | WorkflowAddAction
    | WorkflowUpdateAction
    | WorkflowDuplicateAction
    | WorkflowRemoveAction,
): typeof state {
  if (action.type === actionTypes.WORKFLOWS_FETCH.SUCCESS) {
    return (action as GetWorkflowsAction).payload;
  }
  if (state !== UNINITIALISED) {
    if (action.type === actionTypes.WORKFLOW_ADD.SUCCESS) {
      const newWorkflow: WorkflowInfo = (action as WorkflowAddAction).payload;
      return [...state, newWorkflow];
    } else if (action.type === actionTypes.WORKFLOW_DUPLICATE.SUCCESS) {
      const duplicateWorkflow: WorkflowInfo = (action as WorkflowDuplicateAction)
        .payload;
      return [...state, duplicateWorkflow];
    } else if (action.type === actionTypes.WORKFLOW_UPDATE.SUCCESS) {
      const newWorkflow: WorkflowInfo = (action as WorkflowUpdateAction)
        .payload;
      return state.map(workflow =>
        workflow.id === newWorkflow.id ? newWorkflow : workflow,
      );
    } else if (action.type === actionTypes.WORKFLOW_REMOVE.SUCCESS) {
      const newWorkflow: {id: WorkflowId} = (action as WorkflowRemoveAction)
        .payload;
      return state.filter(workflow => workflow.id !== newWorkflow.id);
    }
  }
  return state;
}

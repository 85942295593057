import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export default function CommentMenu({
  onEdit,
  onDelete,
  hasEditPermission,
  hasDeletePermission,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function renderMenuItems() {
    const menuItems = [];

    const handleAction = action => () => {
      if (typeof action === "function") {
        action();
      }
      setAnchorEl(null);
    };

    if (hasEditPermission) {
      menuItems.push(
        <MenuItem key="edit-comment" onClick={handleAction(onEdit)}>
          <ListItemIcon fontSize="small">
            <EditIcon />
          </ListItemIcon>
          <Typography variant="inherit">Edit</Typography>
        </MenuItem>,
      );
    }

    if (hasDeletePermission) {
      menuItems.push(
        <MenuItem key="delete-comment" onClick={handleAction(onDelete)}>
          <ListItemIcon fontSize="small">
            <DeleteIcon />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>,
      );
    }

    return menuItems;
  }

  const menuItems = renderMenuItems();
  if (menuItems.length === 0) {
    return null;
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreVertIcon className="menu-icon" />
      </IconButton>
      <Menu
        id="comment-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{horizontal: "center", vertical: "bottom"}}
        getContentAnchorEl={null}
      >
        {menuItems}
      </Menu>
    </>
  );
}

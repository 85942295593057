import requestor from "requestor";
import basePath from "base_path";
import ActionTypes from "../constants/action_types";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.DOCUMENT_ISSUES_REFRESH,
  (organisationId, projectId, documentId, lastEdited) =>
    requestor
      .get(
        `${basePath}/organisation/${organisationId}/project/${projectId}/document/${documentId}/issues/refresh?last_edited=${lastEdited}`,
        {withCredentials: true},
      )
      .then(response => response.data),
);

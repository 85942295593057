import React from "react";

import ThumbUp from "material-ui/svg-icons/action/thumb-up";
import ThumbDown from "material-ui/svg-icons/action/thumb-down";
import * as colors from "material-ui/styles/colors";

const styles = {
  root: {
    borderBottom: `1px solid ${colors.grey300}`,
    padding: "0.5rem 1rem",
    fontSize: "14px",
    display: "block",
    borderCollapse: "separate",
    borderSpacing: "4px",
  },
  dummy: {
    width: "2em",
  },
  heading: {
    fontWeight: 600,
    minWidth: "10em",
  },
  subheading: {
    fontWeight: 400,
    fontStyle: "italic",
    width: "15em",
  },
  value: {
    width: "100%",
  },
  verifyControl: {
    width: "2em",
  },
  thumbIcon: {
    height: "15px",
    width: "15px",
    margin: "0.1rem 0rem",
    cursor: "pointer",
    flexShrink: "0",
  },
};
export default class FrontPageMenuItem extends React.Component {
  render() {
    const {item, shouldShowBottomBorder} = this.props;

    return (
      <tbody
        style={{
          ...styles.root,
          borderBottom: shouldShowBottomBorder
            ? `1px solid ${colors.grey300}`
            : "none",
        }}
      >
        <tr key={-1}>
          {this.renderItem(item)}
          <td style={styles.verifyControl}>{this.renderVerifyControl()}</td>
        </tr>
        {this.renderValues(item)}
      </tbody>
    );
  }

  renderValues = item => {
    if (!item.values) {
      return null;
    }
    return item.values.map((valueItem, index) => (
      <tr style={{margin: "0.5rem 1rem"}} key={index}>
        {this.renderItem(valueItem, index, false)}
      </tr>
    ));
  };

  renderItem = (_valueItem, index, topLevel = true) => {
    let valueItem = _valueItem;
    if (!topLevel) {
      const valueItemValue = ((valueItem && valueItem.value) || []).split(":");
      valueItem = {
        name: valueItemValue[0],
        value: valueItemValue[1],
      };
    }
    return [
      ...(topLevel ? [] : [<td key={`dummy-${index}`} style={styles.dummy} />]),
      <td
        key={`name-${index}`}
        style={topLevel ? styles.heading : styles.subheading}
        colSpan={topLevel ? 2 : 1}
      >
        {`${valueItem.name}: `}
      </td>,
      <td key={`value-${index}`} style={styles.value}>
        {valueItem.value &&
          valueItem.value.split("\n").map((item, key) => {
            return (
              <span style={{wordBreak: "break-word"}} key={key}>
                {item}
                <br />
              </span>
            );
          })}
      </td>,
    ];
  };

  renderVerifyControl = () => {
    if (!this.props.verifyIssues) {
      return null;
    }
    const {itemFeedBack} = this.props;
    return (
      <div style={{display: "flex", flexDirection: "column"}}>
        <ThumbUp
          onClick={this.onItemVerify}
          style={{
            ...styles.thumbIcon,
            color:
              itemFeedBack.user_verified === true ? "#1d7400" : colors.grey400,
          }}
        />
        <ThumbDown
          onClick={this.onItemReject}
          style={{
            ...styles.thumbIcon,
            color:
              itemFeedBack.user_verified === false ? "#bc0000" : colors.grey400,
          }}
        />
      </div>
    );
  };

  onItemVerify = e => {
    e.stopPropagation();
    const {itemFeedBack, item} = this.props;
    if (
      itemFeedBack.user_verified === undefined ||
      itemFeedBack.user_verified === null ||
      itemFeedBack.user_verified === false
    ) {
      return this.props.updateDocumentMetaDataFeedback(item.name, {
        user_verified: true,
      });
    }
    return this.revertUserVerified(item.name);
  };

  onItemReject = e => {
    e.stopPropagation();
    const {itemFeedBack, item} = this.props;
    if (
      itemFeedBack.user_verified === undefined ||
      itemFeedBack.user_verified === null ||
      itemFeedBack.user_verified === true
    ) {
      return this.props.updateDocumentMetaDataFeedback(item.name, {
        user_verified: false,
      });
    }
    return this.revertUserVerified(item.name);
  };

  revertUserVerified = name => {
    const updates = {user_verified: null};
    return this.props.updateDocumentMetaDataFeedback(name, updates);
  };
}

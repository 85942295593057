import _ from "underscore";
import getClauseByPath from "../../get_clause_by_path";

function getSingleReason(reason) {
  if (Array.isArray(reason)) {
    return getSingleReason(reason.find(re => getSingleReason(re)));
  }
  return reason;
}

class ReasonDescriber {
  constructor(documentClauses, topicsById, issue) {
    this.documentClauses = documentClauses;
    this.topicsById = topicsById;
    this.issue = issue;
  }

  get reason() {
    if (!this.cachedReason) {
      this.cachedReason = getSingleReason(this.issue.reason);
    }
    return this.cachedReason;
  }

  get reasons() {
    return this.issue.reason ? _.flatten([this.issue.reason]) : [];
  }

  // TOPIC

  get topicId() {
    return this.reason.topic_id || Object.keys(this.reason.topics)[0];
  }

  get topic() {
    return this.reason.topics[this.topicId];
  }

  get topicName() {
    return this.topicsById[this.topicId].name;
  }

  get issueTopicName() {
    return this.topicsById[this.issue.rules.topic_id].name;
  }

  // PARAMETER

  get parameterId() {
    if (!this.cachedParameterId) {
      this.cachedParameterId = parseInt(Object.keys(this.topic)[0], 10);
    }
    return this.cachedParameterId;
  }

  get parameter() {
    return this.topicsById[this.topicId].parameters.find(
      parameter => parameter.id === this.parameterId,
    );
  }

  get parameterName() {
    return this.parameter.name;
  }

  listParameters(conjunction = "and") {
    const values = this.issue.rules.parameter_ids.map(
      parameterId =>
        this.topicsById[this.topicId].parameters.find(
          parameter => parameter.id === parameterId,
        ).name,
    );
    return this.makeList(values, conjunction);
  }

  listParameterRules(conjunction = "and") {
    const values = this.issue.rules.parameter_ids
      .map(parameterRule => {
        const parameterId = this.getParameterId(parameterRule);
        const parameterType = this.getParameterType(parameterRule);
        const topic = this.topicsById[this.topicId];
        const parameterName = topic.parameters.find(
          parameter => parameter.id === parameterId,
        ).name;
        const parameterValues = this.topic[parameterId];
        const parameterValue = this.makeList(parameterValues, "and");
        if (!this.issue.rules.match_all && !parameterValue) {
          return "";
        }
        return `parameter ${parameterName} existing${
          parameterType === "any_value"
            ? ""
            : ` with values "${parameterValue}"" matching regex "${
                parameterRule.text
              }"`
        }`;
      })
      .filter(text => text);
    return this.makeList(values, conjunction);
  }

  getParameterType(parameterRule) {
    return typeof parameterRule === "object" ? parameterRule.type : "any_value";
  }

  getParameterId(parameterRule) {
    return typeof parameterRule === "object" ? parameterRule.id : parameterRule;
  }

  // CLAUSE

  getClauseForReason(reason) {
    const section = this.documentClauses[reason.section_id];
    return section
      ? section.find(clause => clause.id === reason.clause_id)
      : null;
  }

  getClausePartForReason(reason) {
    const section = this.documentClauses[reason.section_id];
    const clause = section
      ? section.find(_clause => _clause.id === reason.clause_id)
      : null;
    return clause && reason.path ? getClauseByPath(clause, reason.path) : null;
  }
  get clause() {
    return this.getClauseForReason(this.reason);
  }

  // get clausepart () {
  //   return this.getClausePartForReason(this.reason);
  // }

  get clauseReference() {
    return this.clause && this.clause.reference;
  }

  get clauses() {
    return this.reasons
      .map(reason => this.getClauseForReason(reason))
      .filter(clause => clause);
  }

  get clauseparts() {
    return this.reasons
      .map(reason => this.getClausePartForReason(reason))
      .filter(clausepart => clausepart);
  }

  get clausepartReference() {
    return this.clauseparts && this.clauseparts[0].reference;
  }

  get clauseReferences() {
    const references = _.chain(this.clauses)
      .uniq(clause => clause.reference)
      .sortBy(clause => clause.sort_reference)
      .map(clause => clause.reference)
      .value();
    if (references.length === 0) {
      return "";
    }
    if (references.length === 1) {
      return references[0];
    }
    if (references.length === 2) {
      return references.join(" & ");
    }
    return `${_.initial(references).join(", ")} & ${_.last(references)}`;
  }

  // VALUES

  get issueValue() {
    return this.issue.rules.parameter_values[0];
  }

  get reasonValue() {
    return this.topic[this.parameterId][0];
  }

  makeList(values, predicate) {
    let valueText;
    if (values.length >= 3) {
      valueText = [
        values.slice(0, -1).join(", "),
        predicate,
        values[values.length - 1],
      ].join(" ");
    } else {
      valueText = values.join(` ${predicate} `);
    }
    return valueText;
  }
}

export default ReasonDescriber;

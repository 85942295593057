import React, {Component} from "react";
import TextEditor from "../utils/text_editor";
import GuiTextEditor from "common_components/gui_text_editor";
import applyChanges from "utils/text/apply_text_changes";

export const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
};

export default class AtomClauseEditor extends Component {
  render() {
    const {props} = this;
    const {
      clauseAdditionChange,
      clausepartAdditionChange,
      definitionAdditionChange,
      clause,
      usedListFormatStylesPerLevel,
      editModeOn,
    } = props;
    const text = applyChanges(
      clause.partial_text || clause.text || "",
      props.clausepartTextChanges,
    );
    let editor;
    if (clauseAdditionChange) {
      editor = (
        <GuiTextEditor
          contents={clauseAdditionChange.contents}
          closeHandler={props.hideClausepartEditor}
          changeHandler={this.props.updateAddedClause}
          caretOffset={props.caretOffset}
          startingLevel={clause.level}
          usedListFormatStylesPerLevel={usedListFormatStylesPerLevel}
          decimalPrefix={this.getDecimalPrefix()}
        />
      );
    } else if (clausepartAdditionChange) {
      editor = (
        <GuiTextEditor
          onAddItemBefore={this.props.onAddClausepartBeforeCurrent}
          onAddItemAfter={this.props.onAddClausepartAfterCurrent}
          onRevertClausepartAddition={props.revertClausepartAddition}
          contents={clausepartAdditionChange.contents}
          changeHandler={props.updateAddedClausepart}
          closeHandler={props.hideClausepartEditor}
          caretOffset={props.caretOffset}
          startingLevel={clause.level}
          usedListFormatStylesPerLevel={usedListFormatStylesPerLevel}
          decimalPrefix={this.getDecimalPrefix()}
        />
      );
    } else if (definitionAdditionChange) {
      editor = (
        <GuiTextEditor
          itemType="Definition"
          onAddItemBefore={this.props.onAddDefinitionBeforeCurrent}
          onAddItemAfter={this.props.onAddDefinitionAfterCurrent}
          contents={definitionAdditionChange.contents}
          changeHandler={props.updateAddedClausepart}
          closeHandler={props.hideClausepartEditor}
          caretOffset={props.caretOffset}
          startingLevel={clause.level}
          usedListFormatStylesPerLevel={usedListFormatStylesPerLevel}
          decimalPrefix={this.getDecimalPrefix()}
        />
      );
    } else {
      const {itemType} = this.props;
      editor = (
        <TextEditor
          id={`clause-${props.clause.id}`}
          itemType={itemType}
          text={text}
          disabled={props.isClauseDeletion || props.isClausepartDeletion}
          changeHandler={this.onClausepartTextChange}
          hideEditor={props.hideClausepartEditor}
          onKeyPress={this.onKeyPress}
          caretOffset={props.caretOffset}
          clausepartTextChanges={this.props.clausepartTextChanges}
          onAddItemBefore={this.getOnAddItemBeforeHandler()}
          onAddItemAfter={this.getOnAddItemAfterHandler()}
          revertDocumentClausepartChanges={
            this.props.revertDocumentClausepartChanges
          }
          onDeleteClausepart={
            itemType === "clause"
              ? this.props.onDeleteClause
              : this.showDeleteClausepartDialog
          }
          editModeOn={editModeOn}
        />
      );
    }
    return <div style={styles.root}>{editor}</div>;
  }

  onClausepartTextChange = async text => {
    const {props} = this;
    if (text.length === 0) {
      return this.showDeleteClausepartDialog();
    }
    await props.alterDocumentClausepartBinded(props.clause, text);
    props.hideClausepartEditor();
  };

  showDeleteClausepartDialog = () => {
    // Timeout to prevent a click event on opening ConfirmDialog's Overlay
    // that closes this dialog
    setTimeout(this.props.onDeleteClausepart, 150);
  };

  onKeyPress = ref => (event, text) => {
    if (event.key === "Enter") {
      const {
        isFirstClauseItem,
        isLastClauseItem,
        listItemPath,
        isFirstParentListNumbered,
        isInNumberedList,
      } = this.props;
      const itemType =
        listItemPath !== null && (isFirstParentListNumbered || isInNumberedList)
          ? "clausepart"
          : isFirstClauseItem === true || isLastClauseItem === true
            ? "clause"
            : null;
      const {selectionStart, selectionEnd} = event.target;
      const isRangeCollapsed = selectionStart === selectionEnd;
      if (isRangeCollapsed) {
        const addPosition =
          selectionStart === text.length
            ? "after"
            : selectionStart === 0 ? "before" : null;
        if (itemType && addPosition) {
          const {
            onAddClauseBeforeCurrent,
            onAddClauseAfterCurrent,
            onAddClausepartBeforeCurrent,
            onAddClausepartAfterCurrent,
          } = this.props;
          switch (itemType) {
            case "clause":
              if (addPosition === "before" && isFirstClauseItem === true) {
                event.preventDefault();
                onAddClauseBeforeCurrent();
              } else if (addPosition === "after" && isLastClauseItem === true) {
                event.preventDefault();
                onAddClauseAfterCurrent();
              }
              break;
            case "clausepart":
              event.preventDefault();
              if (addPosition === "before") {
                onAddClausepartBeforeCurrent && onAddClausepartBeforeCurrent();
              } else {
                const {clause} = this.props;
                const existingTextWithChanges = applyChanges(
                  clause.partial_text || clause.text || "",
                  this.props.clausepartTextChanges,
                );
                if (text !== existingTextWithChanges) {
                  return ref.blur();
                }
                onAddClausepartAfterCurrent && onAddClausepartAfterCurrent();
              }
              break;
          }
        }
      }
    }
  };

  getOnAddItemBeforeHandler = () => {
    const {itemType} = this.props;
    switch (itemType) {
      case "definition":
        return this.props.onAddDefinitionBeforeCurrent;
      case "clause":
        return this.props.onAddClauseBeforeCurrent;
      default:
        return this.props.onAddClausepartBeforeCurrent;
    }
  };

  getOnAddItemAfterHandler = () => {
    const {itemType} = this.props;
    switch (itemType) {
      case "definition":
        return this.props.onAddDefinitionAfterCurrent;
      case "clause":
        return this.props.onAddClauseAfterCurrent;
      default:
        return this.props.onAddClausepartAfterCurrent;
    }
  };

  getDecimalPrefix = () => {
    const {reference} = this.props;
    return /^[\d.]+$/.test(reference) ? reference : undefined;
  };
}

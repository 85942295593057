import Describer from "../../../utils/issues/reason/describer";

/* eslint-disable complexity */
function reason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);

  const {range} = issue.rules;
  const {value, type} = describer.reasonValue;
  let valueString;
  if (type === "percentage") {
    valueString = `${value * 100}%`;
  } else {
    valueString = `${value}`;
  }

  const {min, max} = range;
  const minExists = min !== undefined && min !== null;
  const maxExists = max !== undefined && max !== null;
  return (
    [
      `Topic '${describer.topicName}'`,
      `has value '${valueString}'`,
      `for '${describer.parameterName}' parameter`,
      "which is",
      [
        minExists ? `more than ${min}` : "",
        minExists && maxExists ? " and " : "",
        maxExists ? `less than ${max}` : "",
      ].join(""),
    ].join(" ") + (showClause ? ` in clause ${describer.clauseReference}` : "")
  );
}

export default reason;

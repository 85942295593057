import _ from "underscore";

function combineValues(result, values) {
  _.each(values, (keyValue, key) => {
    result[key] = _.uniq((result[key] || []).concat(keyValue));
  });
}

/* eslint-disable no-use-before-define */
function getObjectValues(data, keys) {
  const result = {};
  keys.forEach(key => {
    const value = data[key];
    if (value !== undefined) {
      result[key] = [value];
    }
  });
  _.each(data, value => {
    const values = getKeyValues(value, keys);
    combineValues(result, values);
  });
  return result;
}

function getArrayValues(data, keys) {
  const result = {};
  data.forEach(item => {
    const values = getKeyValues(item, keys);
    combineValues(result, values);
  });
  return result;
}
/* eslint-enable no-use-before-define */

function getKeyValues(data, keys) {
  let values;
  if (_.isArray(data)) {
    values = getArrayValues(data, keys);
  } else if (_.isObject(data)) {
    values = getObjectValues(data, keys);
  } else {
    values = {};
  }
  keys.forEach(key => {
    if (values[key] === undefined) {
      values[key] = [];
    }
  });
  return values;
}

export default getKeyValues;

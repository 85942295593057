import React from "react";

const styles = {
  rootDiv: {},
  tooltipContainer: {
    display: "inline-block",
    position: "absolute",
    backgroundColor: "rgba(0,0,0, 0.7)",
    border: "1px solid black",
    zIndex: 1,
    borderRadius: "3px",
    padding: "5px",
    textAlign: "center",
  },
  tooltipContent: {
    color: "white",
    fontSize: "10px",
    display: "inline-block",
  },
};

class Tooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isTooltipShown: false};
    this.isTooltipMounted = true;
  }
  componentWillUnmount() {
    this.isTooltipMounted = false;
  }
  render() {
    return (
      <div
        className="element-with-tooltip"
        onMouseEnter={this.showTooltip}
        onMouseLeave={this.hideTooltip}
        onClick={this.props.onClick}
        style={{
          ...styles.rootDiv,
          ...(this.props.rootDivStyle ? this.props.rootDivStyle : {}),
        }}
      >
        {this.props.children}
        {!this.props.disabled &&
          this.state.isTooltipShown &&
          this.props.tooltipContent &&
          this.renderTooltip()}
      </div>
    );
  }
  renderTooltip = () => (
    <div
      style={{
        ...styles.tooltipContainer,
        ...(this.props.tooltipContainerStyle
          ? this.props.tooltipContainerStyle
          : {}),
      }}
    >
      <div
        style={{
          ...styles.tooltipContent,
          ...(this.props.tooltipContentStyle
            ? this.props.tooltipContentStyle
            : {}),
        }}
      >
        {this.props.tooltipContent}
      </div>
    </div>
  );
  showTooltip = () => {
    const that = this;
    this.clearId = setTimeout(() => {
      if (that.isTooltipMounted) {
        that.setState(() => ({isTooltipShown: true}));
      }
    }, typeof this.props.delay === "number" ? this.props.delay : 1200);
  };
  hideTooltip = () =>
    this.setState(() => ({isTooltipShown: false}), clearTimeout(this.clearId));
}

export default Tooltip;

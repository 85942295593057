export default function(changes) {
  if (changes && changes.length) {
    return changes
      .filter(
        change =>
          change.type.startsWith("clausepart_text") ||
          change.type.startsWith("clauseheading_text"),
      )
      .map(change => ({
        type: "addition",
        start: change.new_start_offset,
        end: change.new_end_offset,
        old_start: change.old_start_offset,
        old_end: change.old_end_offset,
        text: change.new_text,
        old_text: change.old_text,
      }));
  }
  return [];
}

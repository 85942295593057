import _ from "underscore";

function getClauseTopicsNames(topics, topicsById) {
  if (!topics || topics.length === 0) {
    return [];
  }
  const topicNames = [];
  topics.forEach(topic => {
    const topicNameArray = topicsById[topic.id || topic.topic_id].name.split(
      "/",
    );
    const topicName = topicNameArray[topicNameArray.length - 1];
    topicNames.push(topicName ? topicName.trim() : topic.name.trim());
  });
  return _.sortBy(topicNames);
}

export default getClauseTopicsNames;

import _ from "underscore";

export function ignoreManualCorrections(issues) {
  return _.map(issues, issue =>
    _.omit(issue, ["manual_corrections", "last_edited"]),
  );
}

export function getOverride(issue, issueset) {
  const {
    master_id: issuesetMasterId,
    remote_client_id: remoteClientId,
  } = issueset;
  const clientId = remoteClientId === null ? "master" : remoteClientId;
  const overrides =
    issue.override_document_issue_values?.[issuesetMasterId]?.[clientId];
  return overrides;
}

export function getIssueManualCorrections(issue, issueset, selectedReport) {
  if (!selectedReport) {
    return {};
  }
  const overrides = getOverride(issue, issueset);
  return overrides?.manual_corrections?.[selectedReport] ?? {};
}

export function getUpdatedManualCorrections(
  baseManualCorrections,
  selectedReportId,
  key,
  value,
  updatedValues,
  isRevert,
  isLocked,
  lockedTextAction,
) {
  if (!selectedReportId) {
    return baseManualCorrections;
  }
  const updates = updatedValues
    ? updatedValues
    : {[key]: value ? value : undefined};
  const reportManualCorrections =
    (baseManualCorrections ?? {})[selectedReportId] ?? {};

  if (lockedTextAction === "delete") {
    delete reportManualCorrections.locked_value[key];
  }

  if (lockedTextAction === "revert") {
    delete reportManualCorrections.locked_value[key];
    return {
      ...(baseManualCorrections || {}),
      [selectedReportId]: {
        ...reportManualCorrections,
        ...updates,
      },
    };
  }

  if (isLocked) {
    reportManualCorrections.locked_value = {
      ...reportManualCorrections.locked_value,
      [key]: isLocked,
    };
    return {
      ...(baseManualCorrections || {}),
      [selectedReportId]: {
        ...reportManualCorrections,
        ...updates,
      },
    };
  }

  if (selectedReportId !== "global") {
    if (isRevert) {
      delete reportManualCorrections.previous_values[key];
    } else {
      if (key !== "applicable_clauses_display_mode") {
        if (
          reportManualCorrections?.previous_values === undefined ||
          (reportManualCorrections?.previous_values &&
            !(key in reportManualCorrections.previous_values))
        ) {
          const prevValue = reportManualCorrections?.[key]
            ? reportManualCorrections[key]
            : "";
          reportManualCorrections.previous_values = {
            ...reportManualCorrections?.previous_values,
            [key]: [prevValue],
          };
        }
      }
    }
  }

  const newReportManualCorrections = {
    ...reportManualCorrections,
    ...updates,
  };
  return {
    ...(baseManualCorrections || {}),
    [selectedReportId]: newReportManualCorrections,
  };
}

export function shouldUseManualCorrections(key, project, selectedReportId) {
  if (!key || !project || !selectedReportId) {
    return false;
  }

  const clientMode = sessionStorage.getItem("clientModeOn");
  const clientModeOn =
    clientMode !== null && clientMode !== undefined
      ? clientMode.toString() === "true"
      : false;

  const {
    report_settings: projectReportSettings,
    edit_all_issue_values: editAllIssueValues,
  } = project;
  const reportSettings = projectReportSettings.find(
    report => report.id === selectedReportId,
  ) || {fields: []};
  if (reportSettings.type === "custom_report") {
    return true;
  }
  return editAllIssueValues
    ? !clientModeOn
    : (reportSettings.fields.find(field => field.type === key) || {}).value;
}

export default {
  ignoreManualCorrections,
  getIssueManualCorrections,
  getUpdatedManualCorrections,
  shouldUseManualCorrections,
};

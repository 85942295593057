const getUpdatedColor = (diffType, liveValue, value) => {
  if (diffType === "deleted") {
    return "#ffebee";
  } else if (diffType === "added") {
    return "#e8f5e9";
  }
  return liveValue !== undefined && liveValue !== value ? "#fff9c4" : "inherit";
};

export default getUpdatedColor;

import React, {useEffect, useState, useRef} from "react";
import * as colors from "@material-ui/core/colors";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "material-ui/CircularProgress";
import Markdown from "common_components/markdown";
import ClearIcon from "@material-ui/icons/Clear";

import {
  GenerateDocumentIssueLlmPrompt,
  FetchDocumentIssueConversation,
  ClearDocumentIssueConversation,
} from "../types";
import {
  Conversation,
  ConversationItem,
  ConversationThread,
} from "common/types/conversation";
import {DocumentIssue} from "common/types/document_issue";
import {Issueset} from "common/types/issueset";

const styles = {
  wrapper: {
    padding: "0 1rem",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  title: {
    fontWeight: 500,
    fontSize: "15px",
    color: "rgb(66, 165, 245)",
    marginTop: "20px",
  },
  text: {
    fontWeight: 400,
    display: "flex",
    alignItems: "left",
    paddingLeft: "0px",
    paddingTop: "4px",
    paddingBottom: "4px",
    fontSize: "13px",
    color: "rgb(117, 117, 117)",
    lineHeight: "15px",
    cursor: "pointer",
    flexDirection: "column" as const,
  },
  block: {
    display: "flex",
    justifyContent: "center",
    height: "60px",
  },
  buttonBlock: {
    padding: "10px 15px",
    margin: "10px 15px",
    border: `1px solid ${colors.grey[300]}`,
    backgroundColor: "transparent",
    cursor: "pointer",
  },
};

function threadConversationItems(
  conversationItems: ConversationItem[],
): ConversationThread[] {
  const itemsById = {};
  if (conversationItems) {
    const topThreads = [];
    conversationItems.forEach(conversationItem => {
      const thread = {item: conversationItem, nextItems: []};
      itemsById[conversationItem.id] = thread;
      if (conversationItem.item_order === 1) {
        topThreads.push(thread);
      } else {
        itemsById[conversationItem.previous_item_id].nextItems.push(thread);
      }
    });
    return topThreads;
  }
  return null;
}

function getConversationToRender(
  conversationThread: ConversationThread[],
): ConversationItem[] {
  if (!conversationThread) {
    return null;
  }
  const conversationItems = [];
  let current = conversationThread[0];
  while (current) {
    conversationItems.push(current.item);
    current = current.nextItems[0];
  }
  return conversationItems;
}

const conversationType = "issue_summary";

const LlmResponse = ({
  generateDocumentIssueLlmPrompt,
  fetchDocumentIssueConversation,
  clearDocumentIssueConversation,
  issue,
  issueset,
}: {
  generateDocumentIssueLlmPrompt: GenerateDocumentIssueLlmPrompt;
  fetchDocumentIssueConversation: FetchDocumentIssueConversation;
  clearDocumentIssueConversation: ClearDocumentIssueConversation;
  issue: DocumentIssue;
  issueset: Issueset;
}) => {
  const issueSummaryConversation: Conversation =
    issue.conversations?.[conversationType]?.[issueset.master_id]?.[
      issueset.remote_client_id ?? "master"
    ];

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({behavior: "smooth"});
  };

  const clearIssue = () => {
    clearDocumentIssueConversation(issue.id);
  };

  const conversation =
    issue?.conversations?.[conversationType]?.[issueset.master_id]?.[
      issueset.remote_client_id || "master"
    ];

  const [issueId, setIssueId] = useState(issue.id);
  const [conversationThread, setConversationThread] = useState(null);
  const [GPTContent, setGPTContent] = useState(null);
  const [llmContentBeingFetched, setLlmContentBeingFetched] = useState(
    Boolean(conversation),
  );
  const [newPrompt, setNewPrompt] = useState(null);
  const generateIssueLlmPrompt = () => {
    setLlmContentBeingFetched(true);
    setGPTContent([
      ...(GPTContent ?? []),
      ...(newPrompt ? [{role: "user", content: newPrompt}] : []),
    ]);
    setNewPrompt("");
    return generateDocumentIssueLlmPrompt(
      issue.id,
      issueset.master_id,
      issueset.remote_client_id,
      newPrompt,
      conversationType,
      issueSummaryConversation?.id,
      issueSummaryConversation?.items?.at(-1)?.id,
    );
  };

  const onSetNewPrompt = event => {
    setNewPrompt(event.target.value);
  };

  useEffect(() => {
    if (issueSummaryConversation?.items && llmContentBeingFetched) {
      setLlmContentBeingFetched(false);
      setGPTContent(issueSummaryConversation?.items);
    } else if (!issueSummaryConversation) {
      setLlmContentBeingFetched(false);
      setGPTContent([]);
    }
  }, [issueSummaryConversation?.items]);

  useEffect(() => {
    if (conversation && !issueSummaryConversation?.items) {
      fetchDocumentIssueConversation(
        issue.id,
        conversation.id,
        conversationType,
        issueset.master_id,
        issueset.remote_client_id,
      );
    } else {
      setConversationThread(
        threadConversationItems(issueSummaryConversation?.items),
      );
      setTimeout(() => scrollToBottom(), 20);
    }
  }, [issueSummaryConversation?.items]);
  useEffect(() => {
    if (issue?.id !== issueId) {
      setGPTContent(null);
      setLlmContentBeingFetched(false);
      setNewPrompt(null);
      setIssueId(issue.id);
    }
  }, [issue?.id]);

  if (!issue.llm_prompt) {
    return null;
  }

  const conversationToRender = getConversationToRender(conversationThread);

  // TODO: auto scroll content to bottom when updated
  return (
    <div style={styles.wrapper}>
      <div style={styles.titleContainer}>
        <div style={styles.title}>LLM Response</div>
        {GPTContent?.length ? <ClearIcon onClick={clearIssue} /> : null}
      </div>
      <div style={{maxHeight: "20em", overflowY: "scroll"}}>
        {conversationToRender
          ? conversationToRender
              .filter(message => message.role !== "system")
              .map((message, index) => (
                <div
                  key={index}
                  style={{
                    ...styles.text,
                    ...(message.role === "assistant"
                      ? {
                          borderLeft: "1px solid #42a5f5",
                          marginLeft: "4px",
                          paddingLeft: "4px",
                        }
                      : {}),
                  }}
                >
                  <Markdown>{message.text}</Markdown>
                </div>
              ))
          : null}
        <div ref={messagesEndRef} />
      </div>
      {!llmContentBeingFetched && GPTContent?.length ? (
        <TextField
          label="Type message to respond"
          onChange={onSetNewPrompt}
          value={newPrompt}
          style={{marginTop: "0.5em", width: "100%", fontSize: "13px"}}
          inputProps={{style: {fontSize: "13px"}}}
        />
      ) : null}
      <div style={styles.block}>
        {llmContentBeingFetched ? (
          <CircularProgress />
        ) : (
          <button
            style={styles.buttonBlock}
            onClick={generateIssueLlmPrompt}
            disabled={!issue?.llm_prompt}
          >
            Generate text
          </button>
        )}
      </div>
    </div>
  );
};

export default LlmResponse;

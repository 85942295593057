import React from "react";
import _ from "lodash";
import GuiTextEditor from "common_components/gui_text_editor";
import GraphNode from "./graph_node";

import calculateNodeKey from "../../../../utils/calculate_node_key";

import renderReference from "../utils/render_reference";
import isDoubleNewLineInContents from "../utils/is_double_newline_in_contents";

const styles = {
  graphClauseNode: {
    display: "flex",
    width: "100%",
    marginBottom: "1em",
  },
  graphItem: {
    width: "100%",
  },
};

export default function renderNode(props) {
  const {clause, reference, node: {level}} = props;
  const parentReference =
    (clause.clauseNodes[0] && clause.clauseNodes[0].reference) || reference;

  const referenceText =
    props.showReference &&
    renderReference(props.numberParent, {
      ...clause,
      reference: clause.reference || reference,
    });
  const isSectionUnnumbered = props.section.is_unnumbered;
  const willRenderReference =
    !isSectionUnnumbered && referenceText && referenceText.length > 0;
  clause.clauseNodes = getChildrenClauses(clause.clauseNodes);

  const hasHeader = hasText(clause.clauseNodes[0]);
  const hasFooter = hasText(clause.clauseNodes[2]);
  return (
    <div className="graphClauseNode" style={styles.graphClauseNode}>
      <div className="graph-item clause-node" style={styles.graphItem}>
        {clause.clauseNodes.map((child, index, clauseNodesArray) =>
          renderClauseNode(
            {
              ...props,
              indentFooter: props.indentFooter || (index === 2 && level === 1),
            },
            clause,
            index === 0 ? reference : parentReference,
            child,
            index,
            willRenderReference,
            hasHeader,
            hasFooter,
            clauseNodesArray.length,
          ),
        )}
      </div>
    </div>
  );
}

function renderClauseNode(
  parentProps,
  parentClause,
  parentReference,
  node,
  index,
  willRenderReference,
  hasHeader,
  hasFooter,
  clauseNodesArrayLength,
) {
  const props = {...parentProps, index, isFirstNodeAtomOrList: false};
  const key = calculateNodeKey(props);
  const firstNode = parentProps.firstNode && index === (hasHeader ? 0 : 1);
  let showReferenceForNodeAtoms = false;

  if (firstNode && parentProps.showReference) {
    showReferenceForNodeAtoms = true;
  }

  const componentsToRender = [
    <GraphNode
      {...props}
      key={key}
      node={node}
      index={index}
      parentNode={{
        ...parentClause,
        reference: parentClause.reference || parentReference,
        x: 2,
      }}
      reference={parentReference}
      firstNode={firstNode}
      showReferenceForNodeAtoms={
        showReferenceForNodeAtoms && !firstNode && parentProps.showReference
      }
      lastNode={index === parentClause.clauseNodes.length - 1}
      hasTopLevelReference={willRenderReference}
      hasFooter={hasFooter}
      indentFooter={
        (parentProps.indentFooter || (willRenderReference && index === 2)) &&
        clauseNodesArrayLength === 2
      }
    />,
  ];
  const {newClausepartData} = props;

  if (
    newClausepartData &&
    newClausepartData.path === node.path &&
    newClausepartData.clauseId === props.documentClause.id
  ) {
    const isEditorLastInList = clauseNodesArrayLength - 1 === index;
    // updateHandler is used for the case when adding last item to a list and
    // hitting enter twice => this will bring new editor on the level above
    // current
    const updateHandler = getGuiEditorUpdateHandler(
      isEditorLastInList,
      props.documentClause,
      parentClause.path,
      props.showAddClausepartEditor,
      props.showAddClauseEditor,
      props.hideAddClausepartEditor,
    );

    const addNewItemOnTheLevelBelowEndHandler = getAddNewItemOnTheLevelBelowEndHandler(
      isEditorLastInList,
      props.documentClause,
      props.showAddClausepartEditor,
      newClausepartData,
    );

    componentsToRender.push(
      <GuiTextEditor
        contents={newClausepartData.oldContents}
        closeHandler={props.saveNewClausepartBinded}
        updateHandler={updateHandler}
        addNewItemOnTheLevelBelowEndHandler={
          addNewItemOnTheLevelBelowEndHandler
        }
        startingLevel={props.level}
        usedListFormatStylesPerLevel={props.usedListFormatStylesPerLevel}
      />,
    );
  }
  return componentsToRender;
}

function getGuiEditorUpdateHandler(
  isEditorLastInList,
  documentClause,
  listPath,
  showAddClausepartEditor,
  showAddClauseEditor,
  hideAddClausepartEditor,
) {
  if (!isEditorLastInList) {
    return;
  }
  return function(props, prevProps, state, prevState) {
    const {contents: currentContents} = state;
    const {contents: prevContents} = prevState;
    if (
      !_.isEqual(currentContents, prevContents) &&
      isDoubleNewLineInContents(currentContents)
    ) {
      // here we wait for quill's internal selection state update and
      // only after that run our function
      _.delay(
        addNewItemOnTheLevelAboveEnd,
        1,
        documentClause,
        listPath,
        showAddClausepartEditor,
        showAddClauseEditor,
        hideAddClausepartEditor,
      );
    }
  };
}

function getAddNewItemOnTheLevelBelowEndHandler(
  isEditorLastInList,
  documentClause,
  showAddClausepartEditor,
  newClausepartData,
) {
  if (
    !isEditorLastInList ||
    !newClausepartData ||
    !newClausepartData.oldPath ||
    !documentClause
  ) {
    return;
  }
  return function(contents) {
    showAddClausepartEditor(
      documentClause.id,
      newClausepartData.oldPath,
      undefined,
      contents,
    );
  };
}

async function addNewItemOnTheLevelAboveEnd(
  documentClause,
  listPath,
  showAddClausepartEditor,
  showAddClauseEditor,
  hideAddClausepartEditor,
) {
  if (!documentClause) {
    return;
  }
  const {id: clauseId} = documentClause;
  if (listPath && listPath !== "root") {
    showAddClausepartEditor(clauseId, listPath);
  } else if (
    (!listPath || listPath === "root") &&
    showAddClauseEditor &&
    hideAddClausepartEditor
  ) {
    await hideAddClausepartEditor();
    showAddClauseEditor(documentClause, 1)();
  }
}

function hasText(node) {
  if (!node) {
    return false;
  }
  const nodeText = node.text || node.partial_text;
  if (nodeText && nodeText.length) {
    return true;
  }
  if (node.clauseNodes) {
    return node.clauseNodes.find(child => hasText(child));
  }
  return false;
}

function getChildrenClauses(nodes = []) {
  // getChildrenClauses is used for getting clause children for deleting/reverting child clauses
  // when deleting a clause (clause in ordinary meaning but not clause as a data structure we use)
  if (nodes.length < 2) {
    return nodes;
  }
  const result = [];
  nodes.forEach((node, nodeIndex) => {
    const nextNode = nodes[nodeIndex + 1];
    if (
      node &&
      node.text &&
      node.type.endsWith("Atom") &&
      nextNode &&
      !nextNode.type.endsWith("Atom") &&
      nextNode.level > node.level
    ) {
      result.push({...node, childrenClausesNode: nextNode});
    } else {
      result.push(node);
    }
  });
  return result;
}

import _ from "underscore";

import Issues from "../../issues";

function describeReason(clauses, topicsById, issue, showClause = true) {
  const reasons = _.zip(issue.reason[0], issue.rules.issues)
    .map(args => {
      const [reason, rules] = args;
      return Issues[rules.issue_type.toLowerCase()].reason(
        clauses,
        topicsById,
        {...rules, reason},
        showClause,
      );
    })
    .join(" and ");

  return reasons;
}

export default describeReason;

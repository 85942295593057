import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";
import UserActionTypes from "modules/user/constants/action_types";
import OrganisationActionTypes from "modules/organisation/constants/action_types";
import ActionTypes from "../constants/action_types";

export default function projectsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.PROJECTS_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.PROJECT_CREATE.SUCCESS: {
      const foundProject = state.find(
        project => project.id === action.payload.id,
      );
      if (foundProject) {
        return state;
      }
      return [...state, action.payload];
    }
    case ActionTypes.PROJECT_UPDATE.SUCCESS: {
      let isDefaultChanged = false;
      const updatedProject = _.findWhere(state, {id: action.payload.id});
      if (
        updatedProject &&
        updatedProject.is_default !== action.payload.is_default
      ) {
        isDefaultChanged = true;
      }
      return state.map(project => {
        if (action.payload.id === project.id) {
          return action.payload;
        } else if (isDefaultChanged) {
          return {
            ...project,
            is_default: false,
          };
        }
        return project;
      });
    }
    case ActionTypes.PROJECT_DELETE.SUCCESS:
      return state.filter(project => project.id !== action.payload.id);
    case OrganisationActionTypes.ORGANISATION_CHANGE.SUCCESS:
    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

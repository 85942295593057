import {get} from "lodash";

function indexExistsInList(list, rawPath) {
  if (!Array.isArray(list) || !rawPath) {
    return false;
  }
  const path = rawPath
    .toString()
    .replace(/\[/g, "")
    .replace(/\]/g, ".")
    .replace(/\.\./g, ".")
    .replace(/\.$/g, "");
  return Boolean(list.find(item => get(item, path)));
}

export default indexExistsInList;

import React from "react";
import {cloneDeep} from "lodash";

import List from "@material-ui/core/List";
import InfoIcon from "@material-ui/icons/Info";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import ListItemHoverable from "./list_item_hoverable";
import StatefulTextField from "common_components/inputs/stateful_textfield";
import CheckboxBasic from "common_components/inputs/checkbox_basic";

import REPORT_TYPES from "constants/report_types";
import generateRandomId from "utils/generate_random_id";
import uuid from "utils/uuid";

const defaultSettings = REPORT_TYPES["rag_report"].default_settings;
const changedKeyColor = "#fffff3";

const isCustomFieldType = fieldType => fieldType.indexOf("custom_field") === 0;

const getFieldName = (defaultSettings, fieldType) => {
  if (isCustomFieldType(fieldType)) {
    return "Custom Field";
  }
  return defaultSettings.fields.find(
    defaultField => defaultField.type === fieldType,
  ).label;
};

function RagReportSettings(props) {
  if (!props.settings) {
    return null;
  }
  const {fields = []} = props.settings;
  const fieldsNotInSettings = getFieldsNotInSettings(
    fields,
    defaultSettings.fields,
  );
  const {
    shownFields,
    fieldsAvailableToAdd: _fieldsAvailableToAdd,
  } = fields.reduce(
    (result, field) => {
      if (field.type !== "clause_display") {
        if (field.value) {
          result.shownFields.push(field);
        } else {
          result.fieldsAvailableToAdd.push(field);
        }
      }
      return result;
    },
    {shownFields: [], fieldsAvailableToAdd: []},
  );
  const fieldsAvailableToAdd = [
    ..._fieldsAvailableToAdd,
    ...fieldsNotInSettings,
  ];
  const {fields: baseFields = []} = props.baseSettings;

  const onFieldChange = (fieldType, keyName) => event => {
    const newValue = keyName === "label" ? event : event.target.checked;
    const newFields = fields.map(field =>
      field.type === fieldType ? {...field, [keyName]: newValue} : field,
    );
    const newSettings = {
      ...props.settings,
      fields: newFields,
    };
    props.onSettingsChange(newSettings);
  };

  const onSettingCheck = key => () => {
    const newSettings = {
      ...props.settings,
      [key]: !props.settings[key],
    };
    props.onSettingsChange(newSettings);
  };

  const onShowSummaryEditorCheck = () => {
    const newSettings = {
      ...props.settings,
      show_summary_editor: !props.settings.show_summary_editor,
      summary_fields: props.settings.summary_fields ?? [createSummaryField],
    };
    props.onSettingsChange(newSettings);
  };

  const onSummaryFieldSave = (index, key) => event => {
    const {summary_fields: summaryFields} = props.settings;
    const newSettings = {
      ...props.settings,
      summary_fields: [
        ...summaryFields.slice(0, index),
        (summaryFields[index] = {
          ...summaryFields[index],
          [key]: event.currentTarget.value,
        }),
        ...summaryFields.slice(index + 1),
      ],
    };
    props.onSettingsChange(newSettings);
  };

  function addSummaryField() {
    const newSettings = {
      ...props.settings,
      summary_fields: [
        ...(props.settings?.summary_fields ?? []),
        createSummaryField(),
      ],
    };
    props.onSettingsChange(newSettings);
  }
  const removeSummaryField = index => () => {
    const newSettings = {
      ...props.settings,
      summary_fields: props.settings.summary_fields.filter(
        (item, itemIndex) => index !== itemIndex,
      ),
    };
    props.onSettingsChange(newSettings);
  };
  const moveSummaryFieldUp = index => () => {
    if (index <= 0 || index >= props.settings.summary_fields.length) {
      return;
    }

    const newSummaryFields = [...props.settings.summary_fields];

    const temp = newSummaryFields[index];
    newSummaryFields[index] = newSummaryFields[index - 1];
    newSummaryFields[index - 1] = temp;

    const newSettings = {
      ...props.settings,
      summary_fields: newSummaryFields,
    };

    props.onSettingsChange(newSettings);
  };
  const moveSummaryFieldDown = index => () => {
    if (index < 0 || index >= props.settings.summary_fields.length - 1) {
      return;
    }

    const newSummaryFields = [...props.settings.summary_fields];
    const temp = newSummaryFields[index];
    newSummaryFields[index] = newSummaryFields[index + 1];
    newSummaryFields[index + 1] = temp;

    const newSettings = {
      ...props.settings,
      summary_fields: newSummaryFields,
    };

    props.onSettingsChange(newSettings);
  };

  function createSummaryField() {
    return {
      id: uuid(),
      title: "",
      placeholder: "",
    };
  }

  function getFieldValue(type, key, fields) {
    return (fields.find(field => field.type === type) || {})[key];
  }

  const shiftFieldUp = fieldType => () => {
    const fieldIndex = shownFields.findIndex(field => field.type === fieldType);
    const newShownFields = cloneDeep(shownFields);
    const temp = newShownFields[fieldIndex];
    newShownFields[fieldIndex] = newShownFields[fieldIndex - 1];
    newShownFields[fieldIndex - 1] = temp;
    const newSettings = {
      ...props.settings,
      fields: [...newShownFields, ...fieldsAvailableToAdd],
    };
    props.onSettingsChange(newSettings);
  };

  const shiftFieldDown = fieldType => () => {
    const fieldIndex = shownFields.findIndex(field => field.type === fieldType);
    const newShownFields = cloneDeep(shownFields);
    const temp = newShownFields[fieldIndex];
    newShownFields[fieldIndex] = newShownFields[fieldIndex + 1];
    newShownFields[fieldIndex + 1] = temp;
    const newSettings = {
      ...props.settings,
      fields: [...newShownFields, ...fieldsAvailableToAdd],
    };
    props.onSettingsChange(newSettings);
  };

  const deleteField = fieldType => () => {
    let newFields;
    if (isCustomFieldType(fieldType)) {
      newFields = [
        ...shownFields.filter(field => field.type !== fieldType),
        ...fieldsAvailableToAdd,
      ];
    } else {
      const removedField = shownFields.find(field => field.type === fieldType);
      removedField.value = false;
      const newShownFields = shownFields.filter(
        field => field.type !== fieldType,
      );
      newFields = [...newShownFields, removedField, ...fieldsAvailableToAdd];
    }
    const newSettings = {
      ...props.settings,
      fields: newFields,
    };
    props.onSettingsChange(newSettings);
  };

  const renderAddFieldSelector = () => {
    const hasClauseTextSelected = shownFields.find(
      field => field.type === "clause_text",
    );
    const hasClauseReferencesSelected = shownFields.find(
      field => field.type === "clause_references",
    );
    const shownFieldsAvailableToAdd = fieldsAvailableToAdd.filter(field =>
      hasClauseTextSelected
        ? field.type !== "clause_references"
        : hasClauseReferencesSelected
        ? field.type !== "clause_text"
        : true,
    );

    const onFieldChange = e => {
      const fieldTypeToAdd = e.target.value;
      let newFields;
      if (fieldTypeToAdd === "custom_field") {
        const fieldToAdd = {
          type: `custom_field_${generateRandomId(10)}`,
          label: "Custom Field",
          value: true,
        };
        newFields = [...shownFields, fieldToAdd, ...fieldsAvailableToAdd];
      } else {
        const fieldToAdd = cloneDeep(
          fieldsAvailableToAdd.find(field => field.type === fieldTypeToAdd),
        );
        fieldToAdd.value = true;
        const newFieldsAvailableToAdd = fieldsAvailableToAdd.filter(
          field => field.type !== fieldTypeToAdd,
        );
        newFields = [...shownFields, fieldToAdd, ...newFieldsAvailableToAdd];
      }

      const newSettings = {
        ...props.settings,
        fields: newFields,
      };
      props.onSettingsChange(newSettings);
    };

    return (
      <Select
        value={"select_column_to_add"}
        onChange={onFieldChange}
        style={{
          marginLeft: "3rem",
          marginTop: 10,
          width: 204,
          fontStyle: "italic",
        }}
      >
        <MenuItem
          disabled
          value="select_column_to_add"
          style={{background: "none", fontStyle: "italic"}}
        >
          Select Column To Add
        </MenuItem>
        <MenuItem value="custom_field" style={{fontWeight: 500}}>
          Custom Field
        </MenuItem>
        {shownFieldsAvailableToAdd.map(field => (
          <MenuItem key={field.type} value={field.type}>
            {
              REPORT_TYPES["rag_report"].default_settings.fields.find(
                defaultField => field.type === defaultField.type,
              ).label
            }
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderInfoIcon = hintMessage => (
    <div title={hintMessage}>
      <InfoIcon
        style={{
          color: "#9e9e9e",
          width: 18,
          height: 18,
          marginLeft: 4,
          marginTop: 4,
        }}
      />
    </div>
  );

  return (
    <div>
      <CheckboxBasic
        label="Hide issue group headings"
        checked={props.settings["hide_issue_group_headings"]}
        onCheck={onSettingCheck("hide_issue_group_headings")}
        containerStyles={{
          margin: "12px 0px",
          background:
            props.settings["hide_issue_group_headings"] !==
            props.baseSettings["hide_issue_group_headings"]
              ? changedKeyColor
              : "inherit",
        }}
      />
      <div style={{display: "flex", alignItems: "center"}}>
        <CheckboxBasic
          label="Number each report item"
          checked={props.settings["are_report_items_numbered"]}
          onCheck={onSettingCheck("are_report_items_numbered")}
          containerStyles={{
            margin: "12px 0px",
            background:
              props.settings["are_report_items_numbered"] !==
              props.baseSettings["are_report_items_numbered"]
                ? changedKeyColor
                : "inherit",
          }}
        />
        {renderInfoIcon(
          "Add a first column to the report which numbers report items",
        )}
      </div>
      <div style={{display: "flex", alignItems: "center"}}>
        <CheckboxBasic
          label="Include all checklist items by default"
          checked={props.settings["add_all_items_to_report"]}
          onCheck={onSettingCheck("add_all_items_to_report")}
          containerStyles={{
            margin: "12px 0px",
            background:
              props.settings["add_all_items_to_report"] !==
              props.baseSettings["add_all_items_to_report"]
                ? changedKeyColor
                : "inherit",
          }}
        />
        {renderInfoIcon(
          "If selected all items in the checklist are added by default and can be removed individually. Otherwise issues can be added individually from the checklist",
        )}
      </div>
      <div style={{display: "flex", alignItems: "center"}}>
        <CheckboxBasic
          label="Show summary editor"
          checked={props.settings["show_summary_editor"]}
          onCheck={onShowSummaryEditorCheck}
          containerStyles={{
            margin: "12px 0px",
            background:
              props.settings["show_summary_editor"] !==
              props.baseSettings["show_summary_editor"]
                ? changedKeyColor
                : "inherit",
          }}
        />
        {renderInfoIcon(
          "If selected show a text box at the top of the report that allows a user to generate a summary",
        )}
      </div>
      {props.settings["show_summary_editor"] && (
        <fieldset
          style={{
            border: "1px solid black",
            padding: "1em",
          }}
        >
          <legend>Summary settings</legend>
          {(props.settings.summary_fields ?? []).map((field, index) => {
            return (
              <div
                key={field.id}
                style={{
                  border: "1px solid black",
                  backgroundColor: "#f8f8f8",
                  padding: "1em",
                  margin: "0.5em",
                  borderRadius: "3px",
                }}
              >
                <div>
                  <TextField
                    label="Title"
                    defaultValue={field.summary_title}
                    onBlur={onSummaryFieldSave(index, "summary_title")}
                    style={{width: "100%", marginBottom: "0.5em"}}
                  />
                </div>
                <div>
                  <TextField
                    label="Placeholder text"
                    multiline
                    defaultValue={field.summary_placeholder}
                    onBlur={onSummaryFieldSave(index, "summary_placeholder")}
                    style={{width: "100%"}}
                  />
                </div>
                <div
                  style={{
                    marginTop: "0.5em",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <IconButton
                    variant="outlined"
                    onClick={removeSummaryField(index)}
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                  <IconButton
                    variant="outlined"
                    onClick={moveSummaryFieldUp(index)}
                    aria-label="move up"
                    disabled={index === 0}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    variant="outlined"
                    onClick={moveSummaryFieldDown(index)}
                    aria-label="move down"
                    disabled={
                      index === props.settings.summary_fields.length - 1
                    }
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </div>
              </div>
            );
          })}
          <Button variant="outlined" onClick={addSummaryField}>
            Add field
          </Button>
        </fieldset>
      )}
      <div style={{display: "flex", alignItems: "center"}}>
        <CheckboxBasic
          label="Show document risk score"
          checked={props.settings["show_document_risk_score"]}
          onCheck={onSettingCheck("show_document_risk_score")}
          containerStyles={{
            margin: "12px 0px",
            background:
              props.settings["show_document_risk_score"] !==
              props.baseSettings["show_document_risk_score"]
                ? changedKeyColor
                : "inherit",
          }}
        />
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        <fieldset
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "1rem",
            border: "1px solid rgba(0, 0, 0, 0.3)",
            paddingRight: 0,
          }}
        >
          <legend style={{color: "rgba(0, 0, 0, 0.7)", fontSize: "14px"}}>
            Columns
          </legend>
          <List dense={true}>
            {shownFields.map((field, fieldIndex) => (
              <ListItemHoverable
                key={`field-${fieldIndex}`}
                isFirstInList={fieldIndex === 0}
                isLastInList={fieldIndex === shownFields.length - 1}
                style={{background: "inherit"}}
                onShiftUp={shiftFieldUp(field.type)}
                onShiftDown={shiftFieldDown(field.type)}
                onDelete={deleteField(field.type)}
              >
                <StatefulTextField
                  value={field.label}
                  onBlur={onFieldChange(field.type, "label")}
                  style={{
                    margin: "4px 8px 4px 4px",
                    background:
                      field.label !==
                      getFieldValue(field.type, "label", baseFields)
                        ? changedKeyColor
                        : "inherit",
                  }}
                  label={getFieldName(defaultSettings, field.type)}
                />
              </ListItemHoverable>
            ))}
          </List>
        </fieldset>
        {renderAddFieldSelector()}
      </div>
    </div>
  );
}

function getFieldsNotInSettings(fields, defaultFields) {
  return defaultFields.filter(
    defaultField => !fields.find(field => field.type === defaultField.type),
  );
}

export default RagReportSettings;

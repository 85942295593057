import React, {useEffect, useState} from "react";

const styles = {
  ragStoreBlock: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ragScoreInput: {
    border: "none",
    width: "50%",
    minWidth: "25px",
  },
};

const RagScoreField = ({cellChild, onEditSave}) => {
  const {value} = cellChild;
  const [fieldValue, setFieldValue] = useState(
    value === "" ? 0 : parseInt(value, 10),
  );

  useEffect(
    () => {
      if (fieldValue !== value) {
        setFieldValue(parseInt(value, 10));
      }
    },
    [value],
  );

  const handleChange = event => setFieldValue(event.target.value);
  const handleSave = () => {
    if (value !== fieldValue) {
      if (fieldValue === "") {
        setFieldValue(0);
        onEditSave(0);
      } else {
        onEditSave(fieldValue);
      }
    }
  };

  return (
    <div style={styles.ragStoreBlock}>
      <input
        type="number"
        style={styles.ragScoreInput}
        value={fieldValue}
        onChange={handleChange}
        onBlur={handleSave}
      />
    </div>
  );
};

export default RagScoreField;

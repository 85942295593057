import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

createReduxPromiseActionType(actionTypes, "PERMISSIONS_FETCH");
createReduxPromiseActionType(actionTypes, "PERMISSION_GROUPS_FETCH");
createReduxPromiseActionType(actionTypes, "PERMISSION_GROUPS_CLEAR");
createReduxPromiseActionType(actionTypes, "PERMISSION_GROUP_UPDATE");
createReduxPromiseActionType(actionTypes, "PERMISSION_GROUP_ADD");
createReduxPromiseActionType(actionTypes, "PERMISSION_GROUP_PERMISSIONS_FETCH");
createReduxPromiseActionType(actionTypes, "PERMISSION_GROUP_PERMISSION_ADD");
createReduxPromiseActionType(actionTypes, "PERMISSION_GROUP_PERMISSION_DELETE");

export default actionTypes;

const ellipsisTextOverflow = {
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const styles = {
  container: {
    display: "flex",
    flexShrink: 1,
    overflow: "hidden",
  },
  toolbarIcon: {
    marginRight: 11,
    color: "inherit",
  },
  toolbarInner: {
    padding: "0px 24px",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  projectIcon: {
    fontSize: 16,
    marginRight: 12,
  },
  projectContainer: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    cursor: "pointer",
  },
  projectNameContainer: {
    fontSize: 14,
    whiteSpace: "nowrap",
    ...ellipsisTextOverflow,
  },
  projectNameParentPath: {
    fontSize: 10,
    color: "#91979c",
    ...ellipsisTextOverflow,
  },
  projectName: {
    ...ellipsisTextOverflow,
  },
  iconButton: {
    color: "#91979c",
  },
  disableIconButton: {
    color: "#e0e0e0",
  },
  editableFieldRootDiv: {
    width: "auto",
    maxWidth: "25em",
    overflow: "hidden",
  },
  editableFieldContainer: {
    margin: 0,
    marginRight: "0.5rem",
    height: "inherit",
    maxWidth: "100%",
    flexGrow: 1,
    overflow: "hidden",
  },
  editableFieldInput: {
    display: "inline-block",
    height: "initial",
    color: "inherit",
    cursor: "pointer",
    maxWidth: "100%",
    lineHeight: 1,
    padding: "3px 4px",
    border: "2px solid transparent",
    borderRadius: 3,
    boxSizing: "border-box",
    fontSize: 16,
    textOverflow: "ellipsis",
    borderBottom: "2px solid transparent",
  },
  editableFieldInputHover: {
    borderBottom: "2px solid #e6e6e6",
  },
  editableFieldInputFocus: {
    borderBottom: "2px solid #1f88e5",
  },
  editableFieldIcon: {
    fill: "#e0e0e0",
    top: "0px",
  },
  documentWrapper: {
    marginLeft: "1em",
  },
  documentName: {
    display: "flex",
    alignItems: "center",
  },
  stateSelect: {
    marginRight: "12px",
    marginTop: "-5px",
  },
  formLabel: {
    marginLeft: 0,
    marginRight: 34,
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexGrow: 1,
    width: "100%",
  },
  linkIconBlock: {
    marginLeft: 6,
    minWidth: 46,
  },
  linkBlock: {
    color: "#000",
    textDecoration: "none",
    cursor: "pointer",
  },
  documentSelectorButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spanMessage: {
    fontSize: "14px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  externalClauseBtn: {
    marginTop: "10px",
    color: "rgb(66, 165, 245)",
    marginLeft: "24px",
    textDecoration: "none",
  },
};

export default styles;

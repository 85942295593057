function joinLists(...args) {
  let result = [];
  args.forEach(argList => {
    if (Array.isArray(argList)) {
      result = result.concat(argList);
    }
  });
  return result;
}

export default joinLists;

import evaluationRules from "./utils/are_concepts_in_document_rules";
import evaluateRules from "./utils/evaluate_rules";

/* eslint-disable @typescript-eslint/no-unused-vars */
export default function areConceptsInDocument(_data, _rules) {
  const hasData = typeof _data !== "string";
  const data = hasData ? _data : _rules?.data?.root?.data ?? [];
  const rules = hasData ? _rules : _data;

  return evaluateRules(rules, evaluationRules(data));
}

const {pureReplacements} = require("./replacements/pure");
const {placeholderReplacements} = require("./replacements/placeholder");
const {unicodeReplacements} = require("./replacements/unicode");

export const sourceSignificantList = unicodeReplacements
  .concat(placeholderReplacements)
  .map(({from, pseudo, type}) => ({
    from,
    to: pseudo,
    type,
  }));

export const sourceList = sourceSignificantList.concat(pureReplacements);

const escapedPlaseholderReplacements = placeholderReplacements.map(
  replacement => ({
    ...replacement,
    to: `\\${replacement.to.split("").join("\\")}`,
  }),
);
export const targetList = unicodeReplacements
  .concat(escapedPlaseholderReplacements)
  .map(({to, pseudo, type}) => ({from: to, to: pseudo, type}))
  .concat(pureReplacements);

export const sourceTrashList = pureReplacements;

import _ from "underscore";
import UNINITIALISED from "utils/uninitialised";
import byId from "common/utils/by_id";

import UserActionTypes from "modules/user/constants/action_types";
import ActionTypes from "../constants/action_types";

function constructStateTopic(clausepart) {
  return {
    parameters: [],
    ..._.pick(clausepart, [
      "id",
      "name",
      "last_edited",
      "topiccategory_id",
      "tags",
    ]),
  };
}

function processDocumentClauseTopicAdd(state, clausepart) {
  if (state === UNINITIALISED) {
    return [constructStateTopic(clausepart)];
  }
  if (!state.find(topic => topic.id === clausepart.id)) {
    return state.concat([constructStateTopic(clausepart)]);
  }
  return state;
}

/* eslint-disable complexity */
export default function topicsReducer(state = UNINITIALISED, action) {
  switch (action.type) {
    case ActionTypes.TOPICS_FETCH.SUCCESS:
    case ActionTypes.TOPICS_INFO_FETCH.SUCCESS:
    case ActionTypes.TOPICS_SCORES_FETCH.SUCCESS:
      return action.payload;
    case ActionTypes.TOPICS_CLEAR.SUCCESS:
      return UNINITIALISED;
    case ActionTypes.CLAUSES_TOPIC_ADD.SUCCESS: {
      // here we add one topic to multiple clauseparts. Therefore we can use any
      // of the received clauseparts to get the required topic data
      if (
        action.payload &&
        action.payload.clauseparts &&
        action.payload.clauseparts[0]
      ) {
        return processDocumentClauseTopicAdd(
          state,
          action.payload.clauseparts[0],
        );
      }
      return state;
    }
    case ActionTypes.TOPIC_LIST_TOPIC_ADD.SUCCESS: {
      return [...state, action.payload];
    }
    case ActionTypes.DOCUMENT_CLAUSE_TOPIC_ADD.SUCCESS: {
      if (action.payload) {
        return processDocumentClauseTopicAdd(state, action.payload);
      }
      return state;
    }
    case ActionTypes.TOPIC_UPDATE.SUCCESS: {
      return state.map(
        topic =>
          topic.id === action.payload.id
            ? {...topic, ...action.payload}
            : topic,
      );
    }
    case ActionTypes.TOPIC_DELETE.SUCCESS: {
      return state.filter(topic => topic.id !== action.payload.id);
    }
    case ActionTypes.TOPICS_UPDATE.SUCCESS: {
      const topicsById = byId(action.payload);
      const result = [];
      const topicsLength = state.length;
      let idx;
      for (idx = 0; idx < topicsLength; idx += 1) {
        const topic = state[idx];
        const updatedTopic = topicsById[topic.id];
        if (!updatedTopic) {
          result.push(topic);
        } else {
          result.push({
            ...topic,
            ...updatedTopic,
            referenced_issues: updatedTopic.referenced_issues || [],
          });
        }
      }
      return result;
    }
    case ActionTypes.TOPICPARAMETER_ADD.SUCCESS: {
      return state.map(topic => {
        if (topic.id !== action.payload.topic_id) {
          return topic;
        }
        return {
          ...topic,
          parameters: topic.parameters
            .filter(parameter => parameter.id !== action.payload.id)
            .concat([_.omit(action.payload, "topic_last_edited", "topic_id")]),
          last_edited: action.payload.topic_last_edited,
        };
      });
    }
    case ActionTypes.TOPICPARAMETER_DELETE.SUCCESS: {
      return state.map(topic => {
        if (topic.id !== action.payload.topic_id) {
          return topic;
        }
        return {
          ...topic,
          parameters: topic.parameters.filter(
            parameter => parameter.id !== action.payload.id,
          ),
          last_edited: action.payload.topic_last_edited,
        };
      });
    }
    case ActionTypes.TOPICPARAMETER_UPDATE.SUCCESS: {
      if (_.isArray(state)) {
        return state.map(topic => {
          if (topic.id !== action.payload.topic_id) {
            return topic;
          }
          return {
            ...topic,
            parameters: topic.parameters.map(parameter => {
              if (parameter.id !== action.payload.id) {
                return parameter;
              }
              return {
                ...parameter,
                ..._.omit(action.payload, "topic_last_edited", "topic_id"),
              };
            }),
            last_edited: action.payload.topic_last_edited,
          };
        });
      }
      return state;
    }

    case ActionTypes.TOPICPARAMETERS_PROCESS.SUCCESS: {
      if (Array.isArray(state)) {
        return state.map(topic => {
          if (topic.id !== action.payload.topic_id) {
            return topic;
          }
          const topicparametersById = byId(action.payload.topicparameters);
          return {
            ...topic,
            parameters: topic.parameters.map(param => ({
              ...param,
              ...topicparametersById[param.id],
            })),
          };
        });
      }
      return state;
    }

    case ActionTypes.CONTRACT_TYPE_UPDATE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(topic => {
        const newTopicContractTypes = topic.contract_types.map(
          topicContractType => {
            if (topicContractType.contract_type_id === action.payload.id) {
              return {
                ...topicContractType,
                ...action.payload,
              };
            }
            return topicContractType;
          },
        );
        return {
          ...topic,
          contract_types: newTopicContractTypes,
        };
      });
    }
    case ActionTypes.CONTRACT_TYPE_REMOVE.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(topic => {
        const newTopicContractTypes = topic.contract_types.filter(
          topicContractType =>
            topicContractType.contract_type_id !== action.payload.id,
        );
        return {
          ...topic,
          contract_types: newTopicContractTypes,
        };
      });
    }
    case ActionTypes.TOPIC_CLASSIFIER_LOCK.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(topic => {
        if (topic.id === action.payload.topic_id) {
          return {
            ...topic,
            stats: (topic.stats || []).map(stats => {
              if (stats.classifier_id === action.payload.id) {
                return {
                  ...stats,
                  stats: stats.stats.map(configStats => {
                    if (
                      configStats.configuration_id ===
                      action.payload.configuration_id
                    ) {
                      return {
                        ...configStats,
                        up_to_date: true,
                        last_edited: action.payload.last_edited,
                      };
                    }
                    return configStats;
                  }),
                };
              }
              return stats;
            }),
          };
        }
        return topic;
      });
    }
    case ActionTypes.CLASSIFIER_RUN_BATCH_ADD_REGEX.SUCCESS: {
      if (state === UNINITIALISED) {
        return state;
      }
      return state.map(topic => {
        if (topic.id === action.payload.topic_id) {
          return {
            ...topic,
            stats: (topic.stats || []).map(stats => {
              if (stats.classifier_id === action.payload.classifier_id) {
                return {
                  ...stats,
                  stats: stats.stats.map(configStats => {
                    if (
                      configStats.configuration_id ===
                      action.payload.configuration_id
                    ) {
                      return {
                        ...configStats,
                        ...action.payload.scores,
                      };
                    }
                    return configStats;
                  }),
                };
              }
              return stats;
            }),
          };
        }
        return topic;
      });
    }

    case UserActionTypes.USER_LOGOUT.SUCCESS:
      return UNINITIALISED;
    default:
      return state;
  }
}

import React from "react";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";

import copySelection from "utils/copy_selected_text_inside_element";
import TextareaAutosize from "../textarea_autosize";
import useStyles from "./styles";
import CopyTooltip from "../copy_tooltip";

export default function IssueResponseTextField({
  name,
  value,
  id,
  text,
  showCopy,
  editMode,
  onChange,
  disabled,
  editor,
}) {
  const classes = useStyles();
  const docId = `${name.replace(/[^\w\d]+/g, "")}-${id}`;
  const shouldShowCopy = showCopy && value;
  return (
    <div>
      <div className={classes.fieldHeading}>
        <div className={classes.fieldHeadingText}>{name}</div>
        {shouldShowCopy && (
          <CopyTooltip>
            <CopyIcon
              className={classes.copyIcon}
              onClick={copySelection(text, docId)}
            />
          </CopyTooltip>
        )}
      </div>
      {editMode ? (
        editor || (
          <TextareaAutosize
            value={value}
            onChange={onChange}
            disabled={disabled}
            styles={{width: "calc(100% - 1em)"}}
            placeholder="Type here"
          />
        )
      ) : (
        <div className={classes.fieldBody} id={docId}>
          {value || <span className={classes.italic}>Not set</span>}
        </div>
      )}
    </div>
  );
}

import React from "react";
import _ from "underscore";

import IconButton from "material-ui/IconButton";
import DoneIcon from "material-ui/svg-icons/action/done";
import CancelIcon from "material-ui/svg-icons/content/clear";
import EditIcon from "material-ui/svg-icons/image/edit";
import RevertIcon from "material-ui/svg-icons/av/replay";
import TextField from "material-ui/TextField";
import * as colors from "material-ui/styles/colors";

import commonStyles from "./common_styles";

class EditableParagraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      areActionButtonsVisible: false,
      paragraphText: props.text.content,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.text, prevProps.text) &&
      this.props.text.content
    ) {
      this.setState(() => ({
        paragraphText: this.props.text.content,
      }));
    }
  }

  render() {
    if (!this.props.text.id) {
      return null;
    }
    const {selectedItemId, text, editedItemId} = this.props;
    const {paragraphText, areActionButtonsVisible} = this.state;
    const isParagraphTextEditActive = editedItemId === text.id;
    const isParagraphSelected = selectedItemId === text.id;
    const areEditRevertButtonsShown =
      (areActionButtonsVisible || isParagraphSelected) &&
      !isParagraphTextEditActive;
    return (
      <div style={{width: "100%"}}>
        <table style={{width: "100%", borderSpacing: 0}}>
          <tbody>
            <tr
              onMouseEnter={this.showButtons}
              onMouseLeave={this.hideButtons}
              style={{
                backgroundColor: isParagraphSelected
                  ? colors.grey200
                  : "inherit",
              }}
            >
              <td
                style={{padding: "10px 0px 10px 8px"}}
                onDoubleClick={this.onEditClick}
                onClick={this.onParagraphTextClick}
              >
                {isParagraphTextEditActive ? (
                  <TextField
                    className="email-input-textarea"
                    name="output_text"
                    ref={this.createTextRef(text.id)}
                    style={commonStyles.textFieldMain}
                    textareaStyle={commonStyles.textAreaStyle}
                    underlineShow={false}
                    multiLine
                    value={paragraphText}
                    onChange={this.onParagraphTextChange}
                    spellCheck="false"
                    onFocus={this.moveCaretAtEnd}
                  />
                ) : (
                  <div style={{fontSize: "16px"}}>
                    {paragraphText.split("\n").map(
                      (item, index) =>
                        !item ? (
                          <br key={index} />
                        ) : (
                          <p style={{margin: 0}} key={index}>
                            {item}
                          </p>
                        ),
                    )}
                  </div>
                )}
              </td>
              <td
                style={{
                  ...commonStyles.actionIconContainer,
                  paddingLeft: "8px",
                  paddingTop: "10px",
                }}
              >
                <IconButton
                  style={commonStyles.iconButtonMain}
                  iconStyle={commonStyles.iconStyle}
                  onClick={
                    areEditRevertButtonsShown
                      ? this.onEditClick
                      : this.onEditSave
                  }
                >
                  {areEditRevertButtonsShown ? (
                    <EditIcon hoverColor={commonStyles.iconHoverColor} />
                  ) : isParagraphTextEditActive ? (
                    <DoneIcon hoverColor={commonStyles.iconHoverColor} />
                  ) : null}
                </IconButton>
              </td>

              <td
                style={{
                  ...commonStyles.actionIconContainer,
                  paddingLeft: "4px",
                  paddingTop: "10px",
                  width: "6%",
                }}
              >
                <IconButton
                  style={commonStyles.iconButtonMain}
                  iconStyle={commonStyles.iconStyle}
                  onClick={
                    areEditRevertButtonsShown
                      ? this.onRevertParagraphText
                      : this.onEditCancel
                  }
                >
                  {areEditRevertButtonsShown ? (
                    <RevertIcon hoverColor={commonStyles.iconHoverColor} />
                  ) : isParagraphTextEditActive ? (
                    <CancelIcon hoverColor={commonStyles.iconHoverColor} />
                  ) : null}
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  showButtons = () => {
    if (this.props.selectedItemId) {
      return;
    }
    this.setState(() => ({areActionButtonsVisible: true}));
  };
  hideButtons = () => this.setState(() => ({areActionButtonsVisible: false}));

  onParagraphTextClick = e => {
    e.stopPropagation();
    this.props.selectItem(this.props.text.id);
  };

  onParagraphTextChange = e => {
    this.setState({paragraphText: e.target.value});
  };

  onEditClick = async e => {
    e.stopPropagation();
    if (this.props.editedItemId) {
      return;
    }
    const {text} = this.props;
    await this.setState(() => ({paragraphText: text.content}));
    await this.props.startItemEdit(text.id);
    this[`text-${text.id}`].focus();
  };

  onEditCancel = e => {
    e.stopPropagation();
    this.setState(
      () => ({
        paragraphText: this.props.text.content,
      }),
      () => this.props.finishItemEdit(),
    );
  };

  onEditSave = () => {
    const {text} = this.props;
    const {paragraphText} = this.state;
    if (text.content === paragraphText) {
      this.props.finishItemEdit();
      return;
    }
    const dataToSave = {
      output_text: {
        [text.id]: this.state.paragraphText,
      },
    };
    this.props.finishItemEdit();
    this.props.updateDocument(dataToSave);
  };

  onRevertParagraphText = e => {
    e.stopPropagation();
    this.setState(
      () => ({paragraphText: this.props.baseText}),
      () => {
        this.props.finishItemEdit();
        this.props.updateDocument({
          output_text: {
            [this.props.text.id]: "",
          },
        });
      },
    );
  };

  moveCaretAtEnd(e) {
    const t = e.target.value;
    e.target.value = "";
    e.target.value = t;
  }

  createTextRef = textId => node => (this[`text-${textId}`] = node);
}

export default EditableParagraph;

import Describer from "../../../utils/issues/issue_description/describer";
import getDateStatement from "./get_date_statement";

function render(issue, topics) {
  const {rules} = issue;
  const describer = new Describer.IssueDescriber(issue, topics);

  return [
    `Parameter ${describer.parameterName}`,
    `of topic ${describer.topicName}`,
    "is",
    getDateStatement(rules.from, rules.to, rules.date),
  ].join(" ");
}

export default render;

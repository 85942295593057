import {getIssueManualCorrections} from "utils/manual_corrections_utils";

export default function getManualCorrectionsIcons(issues, issueset, selectedReportId) {
  const icons = JSON.stringify(
    issues.map(issue => {
      if (issue.resolved_state === 0) {
        return "ignored";
      }
      if (issue.resolved_state === 1) {
        return "resolved";
      }
      const manualCorrections = getIssueManualCorrections(
        issue,
        issueset,
        selectedReportId,
      );

      const alertColor = (manualCorrections || {}).alert_color;
      return alertColor || "";
    }),
  );
  return icons;
}

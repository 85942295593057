import Handlebars from "handlebars";

import registerHandlebarsHelpers from "./register";

function templateHelper(value, templateText, options) {
  registerHandlebarsHelpers();
  if (typeof templateText !== "string") {
    return value;
  }
  const template = Handlebars.compile(templateText);
  const data = options?.data?.root ?? {};
  data.value = value;
  return template(data);
}

export default templateHelper;

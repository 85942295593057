import React from "react";

import RagReport from "../panels/rag_report";

function Report(props) {
  switch (props.selectedReport) {
    case "rag_report":
      return (
        <div style={{padding: "1rem 1rem"}}>
          <RagReport {...props} />
        </div>
      );
    default:
      return null;
  }
}

export default Report;

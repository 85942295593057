import React from "react";
import _ from "underscore";

import {makeStyles} from "@material-ui/core/styles";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Popper from "@material-ui/core/Popper";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import InfoIcon from "@material-ui/icons/Info";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import CheckboxBasic from "common_components/inputs/checkbox_basic";

import commonStyles from "./common_styles";

const styles = {
  ...commonStyles,
  definitionItemContainer: {
    padding: "6px 15px",
    display: "flex",
    flexDirection: "column",
  },
};

const autocompleteStyles = {
  root: {
    border: "1px solid #a6a6a6",
    background: "white",
    padding: "0px 12px",
    boxSizing: "border-box",
    "& .MuiTextField-root": {
      width: "100%",
      "& .MuiFormLabel-root": {
        // term label styles
        position: "relative",
        transform: "inherit",
        fontSize: 10,
        paddingTop: 8,
        fontStyle: "normal",
      },
      "& .MuiInputBase-root": {
        marginTop: 0,
        paddingRight: 50,
      },
      "& .MuiAutocomplete-endAdornment": {
        top: -11,
      },
      "& .MuiInput-underline:before": {
        content: "unset",
        borderBottom: "none",
      },
      "& .MuiInput-underline:after": {
        content: "unset",
        borderBottom: "none",
      },
      "& input": {
        fontSize: 14,
        padding: "2px 0px 5px 0px !important",
      },
    },
  },
  paper: {
    borderRadius: "0px",
  },
  option: {
    minHeight: "auto",
    alignItems: "flex-start",
    fontSize: "14px",
    padding: "6px",
  },
  listbox: {
    "& .MuiAutocomplete-groupLabel": {
      color: "black",
      fontWeigh: 800,
    },
  },
};

const useAutocompleteStyles = makeStyles(autocompleteStyles);

const selectReplacementTerm = "Select Replacement Term";

function PopperBottomStart(props) {
  return <Popper placement="bottom-start" {...props} />;
}

function DefinitionItem(props) {
  const {
    term,
    value: baseValue,
    userValue,
    updateValue,
    options: baseOptions,
    isSubstituted,
    updateIsSubstituted,
    shouldRenderClear,
    clearUserSubstitution,
    precedentDefinition,
  } = props;
  const value = userValue || baseValue;
  const [inputValue, setInputValue] = React.useState(
    value.value === null ? selectReplacementTerm : value.value,
  );
  const [prevInputValue, setPrevInputValue] = React.useState("");

  function onInputChange(e, newInputValue) {
    setInputValue(newInputValue);
  }

  const [
    isPrecedentDefinitionShown,
    updateIsPrecedentDefinitionShown,
  ] = React.useState(false);
  function triggerUpdateIsPrecednetDefinitionShown() {
    updateIsPrecedentDefinitionShown(!isPrecedentDefinitionShown);
  }

  const options = constructFinalOptions(baseOptions);

  function onFocus() {
    const value = inputValue;
    setPrevInputValue(value);
    // setInputValue("");
  }

  // hasOnChangeFired is used to prevent onBlur updateValue execution in case
  // we select an option from the dropdown. (when we type in some text and
  // click on an option 2 events are fired - onChange and Textbox.onBlur.
  // The varible helps us preventing firing updateValue in onBlur)
  let hasOnChangeFired = false;

  function onBlur() {
    if (inputValue === prevInputValue) {
      return;
    }

    if (inputValue === "" && prevInputValue) {
      const newInputValue = prevInputValue;
      setPrevInputValue("");
      setInputValue(newInputValue);
    }

    if (hasOnChangeFired) {
      hasOnChangeFired = false;
    } else {
      if (inputValue) {
        const isInOptions = options.find(option => option.value === inputValue);
        if (!isInOptions) {
          updateValue(inputValue, "manual");
        }
      }
    }
  }

  function getRenderInput(term) {
    return function renderInput(params) {
      return (
        <div ref={params.InputProps.ref}>
          <TextField
            {...params}
            label={term}
            style={value.value === null ? {fontStyle: "italic"} : {}}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </div>
      );
    };
  }

  function renderOption(option) {
    if (option.value === null) {
      return <div style={{fontStyle: "italic"}}>{selectReplacementTerm}</div>;
    }
    return option.isRecommended ? (
      <div>
        <div>{option.value}</div>
        <div style={{color: "#42A5F5", fontStyle: "italic"}}>Recommended</div>
      </div>
    ) : (
      <div>{option.value}</div>
    );
  }

  function getOptionLabel(option) {
    if (option.value === null) {
      return selectReplacementTerm;
    } else if (option.value === undefined && option) {
      return option; // free term input
    }
    return option.value;
  }

  function groupBy(option) {
    return option.groupLabel;
  }

  function getOptionDisabled(option) {
    return option.disabled;
  }

  function onAutocompleteChange(e, option) {
    if (
      (option.value === null && inputValue === "Select Replacement Term") ||
      option.value === inputValue
    ) {
      return;
    }
    hasOnChangeFired = true;
    updateValue(option, "option");
  }

  // we use this function to prevent scrolling parent element
  function onOptionsListScroll(e) {
    e.stopPropagation();
  }

  function renderPrecedentDefinition() {
    if (!precedentDefinition) {
      return null;
    }

    const Icon = isPrecedentDefinitionShown ? KeyboardArrowUpIcon : InfoIcon;
    return (
      <div style={{marginTop: 4}}>
        <div
          onClick={triggerUpdateIsPrecednetDefinitionShown}
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            color: "#42A5F5",
            marginBottom: isPrecedentDefinitionShown ? 8 : 0,
          }}
        >
          <Icon
            style={{
              width: 16,
              height: 16,
            }}
          />
          <div
            style={{
              marginLeft: 2,
              display: "flex",
              userSelect: "none",
            }}
          >
            <span style={{display: "block", flexShrink: 0, width: 31}}>
              {isPrecedentDefinitionShown ? "Hide" : "Show"}
            </span>
            <span>definition</span>
          </div>
        </div>
        {isPrecedentDefinitionShown && (
          <div
            style={{
              padding: "5px 0px 5px 5px",
              backgroundColor: "#f0f0f0",
              borderBottom: "1px solid #c3c3c3",
            }}
          >
            {precedentDefinition.meaning}
          </div>
        )}
      </div>
    );
  }

  const autocompleteClasses = useAutocompleteStyles();
  return (
    <div style={styles.definitionItemContainer}>
      <div style={{display: "flex"}}>
        <div style={{flexGrow: 1, width: "70%", position: "relative"}}>
          <Autocomplete
            disableClearable={true}
            blurOnSelect={true}
            freeSolo={true}
            forcePopupIcon={true}
            popupIcon={<KeyboardArrowDownIcon style={{color: "#666666"}} />}
            classes={autocompleteClasses}
            value={value}
            inputValue={inputValue}
            onInputChange={onInputChange}
            options={options}
            onChange={onAutocompleteChange}
            renderInput={getRenderInput(term)}
            PopperComponent={PopperBottomStart}
            renderOption={renderOption}
            getOptionLabel={getOptionLabel}
            groupBy={groupBy}
            getOptionDisabled={getOptionDisabled}
            ListboxProps={{onScroll: onOptionsListScroll}}
          />
          {shouldRenderClear && (
            <IconButton
              size="small"
              style={{position: "absolute", top: 8, right: 34}}
              onClick={clearUserSubstitution}
            >
              <ClearIcon />
            </IconButton>
          )}
        </div>
        <CheckboxBasic
          checked={isSubstituted}
          onCheck={updateIsSubstituted}
          iconStyles={{
            ...styles.checkbox.iconStyles,
            ...(isSubstituted
              ? {color: "#42A5F5"}
              : {color: "#616161", stroke: "#f4f4f4"}),
          }}
          labelStyles={styles.checkbox.labelStyles}
          containerStyles={{
            marginBottom: 5,
            marginLeft: 10,
            justifyContent: "center",
          }}
          disabled={value.value === null}
        />
      </div>
      {renderPrecedentDefinition()}
    </div>
  );
}

const originLabels = {
  definition: "Definitions",
  synonym: "Synonyms",
  capitalized_term: "Capitalised Terms",
  party: "Parties",
};

function constructFinalOptions(baseOptions) {
  const groups = baseOptions.reduce(
    (accum, option) => {
      const {origin} = option;
      if (accum[origin]) {
        accum[origin].push({
          ...option,
          groupLabel: originLabels[option.origin],
        });
      }
      return accum;
    },
    {synonym: [], definition: [], capitalized_term: [], party: []},
  );

  return [
    {value: null}, // value for Select Replacement Term option
    ...getGroupOptions(groups, "party"),
    ...getGroupOptions(groups, "synonym"),
    ...getGroupOptions(groups, "definition"),
    ..._.sortBy(
      getGroupOptions(groups, "capitalized_term"),
      item => item.value,
    ),
  ];
}

function getGroupOptions(groups, groupName) {
  const group = groups[groupName];
  return group && group.length > 0
    ? group
    : getNoneFoundItem(originLabels[groupName]);
}

function getNoneFoundItem(groupLabel) {
  return [
    {
      value: "None Found",
      disabled: true,
      groupLabel,
    },
  ];
}

export default DefinitionItem;

import calculateDocumentRagLevelColor from "../../../utils/reports/calculate_document_rag_level_color";

const calculateReportIssueStats = (issues, issueset) => {
  const documentRagScore = (issues || []).reduce(
    (sum, value) => sum + value.rag_score,
    0,
  );
  return calculateDocumentRagLevelColor(
    documentRagScore,
    issueset.rag_score_levels,
  );
};

export default calculateReportIssueStats;

import React from "react";
import Handlebars from "handlebars";
import copy from "copy-to-clipboard";
import registerHandlebarsHelpers from "common/utils/handlebars_helpers/register";

import RaisedButton from "material-ui/RaisedButton";
import * as colors from "material-ui/styles/colors";

import IssueItem from "./issue_item";
import getEmailTextTemplate from "utils/templates/get_email_text_template";
import OutputTextItem from "./output_text_item";
import TopPanelContainer from "../top_panel_container";

import findGroupedIssue from "utils/issues/find_grouped_issue";
import getIssueNames from "utils/issues/get_issue_names";

const styles = {
  contentMain: {
    width: "100%",
    color: colors.grey600,
  },
  message: {
    width: "4.8rem",
    marginTop: "0.5rem",
    color: colors.grey900,
    fontWeight: "500",
    fontSize: "16px",
    flexShrink: 0,
  },
};

registerHandlebarsHelpers();

class EmailContent extends React.Component {
  state = {
    selectedItemId: null,
    editedItemId: null,
  };

  render() {
    const {reportSettings = {fields: []}, document, reportIssues} = this.props;

    const template = reportSettings
      ? Handlebars.compile(
          (
            (reportSettings.fields || []).find(
              field => field.type === "header_content",
            ) || {}
          ).value || "",
        )
      : Handlebars.compile();
    const headerBaseText =
      reportSettings && template({name: this.props.document.name});
    const footerBaseText =
      reportSettings &&
      (
        (reportSettings.fields || []).find(
          field => field.type === "footer_content",
        ) || {}
      ).value;

    const headerContent = {
      content: document.output_text.email_header || headerBaseText,
      id: "email_header",
    };
    const footerContent = {
      content: document.output_text.email_footer || footerBaseText,
      id: "email_footer",
    };
    const emailIssuesTextArray = reportIssues.map(
      (issue, index) =>
        `${index + 1}. ${issue.user_text ||
          getEmailTextTemplate(issue, this.props)}`,
    );
    const emailText = [
      headerContent.content,
      ...emailIssuesTextArray,
      footerContent.content,
    ].join("\n");
    return (
      <TopPanelContainer
        onContainerClick={this.clearSelectedItem}
        renderInSeparateWindow={this.props.renderInSeparateWindow}
        rootDivStyles={{
          boxShadow: "none",
          borderBottom: "1px solid #e8e8e8",
        }}
      >
        <div className="content" style={styles.contentMain}>
          <div style={{display: "flex"}}>
            <div style={styles.message}>Message:</div>
            <OutputTextItem
              text={headerContent}
              baseText={headerBaseText}
              selectedItemId={this.state.selectedItemId}
              selectItem={this.selectItem}
              editedItemId={this.state.editedItemId}
              startItemEdit={this.startItemEdit}
              finishItemEdit={this.finishItemEdit}
              updateDocument={this.props.updateDocument}
              areReportItemsNumbered={this.props.areReportItemsNumbered}
            />
          </div>
          <div style={{display: "flex"}}>
            <table style={{width: "100%", borderSpacing: 0}}>
              <tbody>
                {this.renderEmailIssuesText(this.props, reportIssues)}
              </tbody>
            </table>
          </div>
          <div style={{display: "flex"}}>
            <div style={styles.message} />
            <OutputTextItem
              text={footerContent}
              baseText={footerBaseText}
              selectedItemId={this.state.selectedItemId}
              selectItem={this.selectItem}
              editedItemId={this.state.editedItemId}
              startItemEdit={this.startItemEdit}
              finishItemEdit={this.finishItemEdit}
              updateDocument={this.props.updateDocument}
            />
          </div>
          <div style={{display: "flex", justifyContent: "center"}}>
            <RaisedButton
              label="Copy Report Text"
              primary={true}
              onClick={() => copy(emailText, {format: "text/plain"})}
            />
          </div>
        </div>
      </TopPanelContainer>
    );
  }

  renderEmailIssuesText(props, emailIssues, inList = true) {
    return emailIssues.map((issue, index) => {
      return (
        <IssueItem
          key={index}
          index={index}
          issue={findGroupedIssue(issue, this.props.groupedIssues)}
          inList={inList}
          selectedIssueId={this.props.selectedIssueId}
          selectIssue={this.props.selectIssue}
          selectItem={this.selectItem}
          selectedItemId={this.state.selectedItemId}
          {...props}
        />
      );
    });
  }

  selectItem = selectedItemId => {
    if (this.state.editedItemId) {
      return;
    }
    const {showIssueDetail} = this.props;
    return this.setState(
      () => ({selectedItemId}),
      () => {
        if (showIssueDetail) {
          if (["email_header", "email_footer"].includes(selectedItemId)) {
            return;
          }
          const issue = this.props.issues.find(
            issue => issue.id === selectedItemId,
          );
          showIssueDetail({
            ...issue,
            ...getIssueNames(issue),
            shownTab: "report",
          });
        }
      },
    );
  };

  clearSelectedItem = e => {
    e.stopPropagation();
    if (this.state.editedItemId) {
      return;
    }
    this.setState(() => ({selectedItemId: null}));
  };

  startItemEdit = id =>
    this.setState(() => ({
      editedItemId: id,
      selectedItemId: id,
    }));

  finishItemEdit = () => this.setState(() => ({editedItemId: null}));
}

export default EmailContent;

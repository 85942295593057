import {WorkflowSettings} from "common/flowmaster/types/workflow";
import {Id} from ".";
import {isApplicableClause} from "./document";

export type IssueId = Id;

/**
 * This type represents the choice of methods we have now to determine the
 * trigger state and reason text for an issue.
 *
 * - Classic (default): The old, manual method
 * - LLM: The new, LLM-driven method
 */
export type TriggerType = "Classic" | "LLM";
export type ManualCorrections = {global: {new_state: unknown}};
export interface Issue {
  id: IssueId;
  name: string;
  trigger_type: TriggerType;
  issue_class_id: number;
  rag_score: string | number;
  manual_corrections: ManualCorrections;
  llm_settings: {
    url: string;
    payload: string;
    method?: string;
    fetch_all?: boolean;
    text_retrieval_method?: "all" | "rag" | "assistants";
    prompt?: string;
    use_production_mode?: boolean;
    workflow_settings?: WorkflowSettings;
  };
}

export interface GroupedIssueItem extends Issue {
  max_rag_score: number;
}

export type GroupedIssue =
  | {
      item: GroupedIssueItem[];
    }
  | GroupedIssueItem;

export type GroupedIssues = GroupedIssue[];

export type ReportIssue = {
  is_alert: boolean;
  rag_score: number;
  max_rag_score: string | number;
  issue_class_id: number;
  manual_corrections: ManualCorrections;
};

export type DocumentIssue = Issue & {
  llm_response: LLMResponse | null;
};

export type RagState = "Red" | "Amber" | "Green" | "Grey";
export const ragStates: RagState[] = ["Red", "Amber", "Green", "Grey"];

/**
 * This type represents our current assumption about what the LLM will output
 * in the LLM Trigger type.
 */
export type LLMResponse = {
  rag_state: RagState;
  issue_summary: string;
  issue_detail?: string;
  applicable_clauses: unknown;
};

export const isLLMResponse = (input: unknown): input is LLMResponse =>
  typeof input === "object" &&
  "rag_state" in input &&
  ragStates.includes(input.rag_state as RagState) &&
  "issue_summary" in input &&
  typeof input.issue_summary === "string" &&
  "applicable_clauses" in input &&
  Object.values(input.applicable_clauses).every(
    group => Array.isArray(group) && group.every(isApplicableClause),
  );

import _ from "lodash";

function constructReportSettingsFields(_baseFields) {
  const baseFields = _.cloneDeep(_baseFields);
  // this functions filters out falsy fields and joins
  // clause_references and clause_text fields into a single item
  const result = [];
  let clauseReferencesUsed = false;
  let clauseTextUsed = false;
  for (const field of baseFields) {
    if (field.value) {
      if (field.type === "clause_references") {
        if (!clauseTextUsed) {
          clauseReferencesUsed = true;
          const clauseTextField = baseFields.find(
            field => field.type === "clause_text",
          );
          if (clauseTextField.value) {
            result.push({
              ...clauseTextField,
              type: "clause_display",
              value: "clause_references_and_text",
            });
          } else {
            result.push({
              ...field,
              type: "clause_display",
              value: "clause_references",
            });
          }
        }
      } else if (field.type === "clause_text") {
        if (!clauseReferencesUsed) {
          clauseTextUsed = true;
          const clauseReferencesField = baseFields.find(
            field => field.type === "clause_references",
          );
          if (clauseReferencesField.value) {
            result.push({
              ...field,
              type: "clause_display",
              value: "clause_references_and_text",
            });
          } else {
            result.push({
              ...field,
              type: "clause_display",
              value: "clause_text",
            });
          }
        }
      } else {
        result.push(field);
      }
    }
  }
  return result;
}

export default constructReportSettingsFields;

import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

createReduxPromiseActionType(actionTypes, "PROJECT_TASKS_FETCH");
createReduxPromiseActionType(actionTypes, "PROJECT_TASKS_REORDER");

createReduxPromiseActionType(actionTypes, "PROJECT_TASK_CREATE");

createReduxPromiseActionType(actionTypes, "TASK_FETCH");
createReduxPromiseActionType(actionTypes, "TASK_UPDATE");
createReduxPromiseActionType(actionTypes, "TASK_ROLE_UPDATE");
createReduxPromiseActionType(actionTypes, "TASK_DELETE");

createReduxPromiseActionType(actionTypes, "TASK_COMMENT_CREATE");

createReduxPromiseActionType(actionTypes, "TASKROLES_FETCH");

export default actionTypes;

import React from "react";
import Paper from "material-ui/Paper";

const styles = {
  definitions: {
    margin: "10px 10px 0 10px",
  },
  definition: {
    textAlign: "justify",
    fontWeight: "100",
    lineHeight: "13px",
    paddingBottom: "0.5em",
  },
  definitionTerm: {
    fontSize: "12px",
    fontWeight: "400",
  },
};

function Definitions(props) {
  const {currentClause, definitionsCount, headingStyles} = props;

  if (!definitionsCount) {
    return null;
  }
  return (
    <div style={{paddingLeft: "0.2rem"}}>
      <Paper
        style={{
          ...headingStyles,
          zIndex: 0,
        }}
      >
        {`Definitions ${definitionsCount}`}
      </Paper>
      {
        <div style={styles.definitions}>
          {currentClause.definitions.map(definition =>
            renderDefinition(definition),
          )}
        </div>
      }
    </div>
  );
}

function renderDefinition(definition) {
  return (
    <div key={definition.term} style={styles.definition}>
      <span style={styles.definitionTerm}>{definition.term}</span>
      <span>{` - ${definition.meaning}`}</span>
    </div>
  );
}

export default Definitions;

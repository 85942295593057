import _ from "lodash";
import getItemReturnValue from "./utils/get_item_return_value";
import doesClauseReferenceMatch from "./utils/does_clause_reference_match";

function getClauseValue(
  reasonData = [],
  searchType,
  referenceList = [],
  topicId,
  parameterId,
  _addBlanks = false,
  _useReferences = false,
  _groupResults = false,
  _findInChildren = false,
) {
  const addBlanks = _addBlanks === true;
  const useReferences = _useReferences === true;
  const groupResults = _groupResults === true;
  const findInChildren = _findInChildren === true;
  // we do 'not object' check as handlebars adds internal argument as the last
  // parameter to the helper function
  if (
    !searchType ||
    !topicId ||
    // !parameterId ||
    (parameterId !== null && typeof parameterId === "object") ||
    typeof topicId === "object"
  ) {
    return [];
  }

  const getStr = parameterId
    ? `topics.${String(topicId)}.parameters[${parameterId}].values`
    : `topics.${String(topicId)}`;

  if (groupResults) {
    const groupedResults = [];
    referenceList.forEach(referenceListItem => {
      const reasonDataItems = reasonData.filter(atom => {
        const isEqual = Boolean(
          getItemReturnValue(atom, searchType) === referenceListItem,
        );
        return findInChildren
          ? isEqual ||
              getItemReturnValue(atom, searchType).startsWith(
                `${referenceListItem}.`,
              )
          : isEqual;
      });
      const itemResult = getResult(
        reasonDataItems,
        reasonData,
        getStr,
        useReferences,
        addBlanks,
        parameterId,
      );
      groupedResults.push(itemResult);
    });
    return groupedResults.filter(item => item);
  }

  const reasonDataItems = reasonData.filter(atom =>
    Boolean(
      referenceList.find(
        referenceListItem =>
          getItemReturnValue(atom, searchType) === referenceListItem,
      ),
    ),
  );
  return getResult(
    reasonDataItems,
    reasonData,
    getStr,
    useReferences,
    addBlanks,
    parameterId,
  );
}

function getResult(
  reasonDataItems,
  reasonData,
  getStr,
  useReferences,
  addBlanks,
  parameterId,
) {
  const result = reasonDataItems.reduce((accum, atom) => {
    const values = getTopicValues(reasonData, atom, getStr, useReferences);
    if (values || addBlanks) {
      if (!values) {
        accum.push(parameterId ? null : false);
        return accum;
      }
      return accum.concat(
        parameterId
          ? Object.keys(values).map(key => {
              if (key.charAt(0) === "{") {
                try {
                  return JSON.parse(key);
                } catch {
                  return key;
                }
              }
              return key;
            })
          : true,
      );
    }
    return accum;
  }, []);
  if (!addBlanks) {
    return _.uniq(result);
  }
  return result;
}

function getTopicValues(reasonData, mainAtom, getStr, useReferences) {
  const mainAtomValue = _.get(mainAtom, getStr);
  if (!useReferences) {
    return mainAtomValue;
  }
  // Using references...
  if (mainAtomValue) {
    return mainAtomValue;
  }
  const referencedAtoms = reasonData.filter(atom =>
    (mainAtom.references || [])
      // TODO: also match section id to reference.section (name)
      .find(reference =>
        doesClauseReferenceMatch(atom.reference, reference.id),
      ),
  );
  const matchingReference = referencedAtoms.find(atom => _.get(atom, getStr));
  if (matchingReference) {
    return _.get(matchingReference, getStr);
  }
  return undefined;
}

export default getClauseValue;

import React, {useState, useEffect} from "react";

import ComparePanelSelect from "./compare_panel_select";
import ComparePanelComparison from "./compare_panel_comparison";

import usePrevious from "utils/hooks/use_previous";

function ComparePanel(props) {
  const {
    document: {comparison_document_id: comparisonDocumentId},
    firstUploadedDocument,
    appType,
  } = props;
  const prevComparisonDocumentId = usePrevious(comparisonDocumentId);
  const [viewMode, updateViewMode] = useState(
    comparisonDocumentId ? "comparison" : "select",
  );
  function setSelectMode() {
    updateViewMode("select");
  }
  function setComparisonMode() {
    if (!comparisonDocumentId) {
      props.showChecklistPrimaryPanel();
    } else {
      updateViewMode("comparison");
    }
  }

  useEffect(() => {
    if (
      comparisonDocumentId &&
      prevComparisonDocumentId !== comparisonDocumentId
    ) {
      updateViewMode("comparison");
    }
  }, [comparisonDocumentId]);

  return viewMode === "select" ? (
    <ComparePanelSelect
      isFlipChecklistIcons={props.isFlipChecklistIcons}
      zoom={props.zoom}
      document={props.document}
      documents={props.documents}
      firstUploadedDocumentId={firstUploadedDocument.id}
      updateComparisonDocumentId={props.updateComparisonDocumentId}
      setComparisonMode={setComparisonMode}
    />
  ) : (
    <ComparePanelComparison
      appType={appType}
      organisationId={props.organisationId}
      user={props.user}
      project={props.project}
      document={props.document}
      documents={props.documents}
      documentSections={props.documentSections}
      firstUploadedDocument={firstUploadedDocument}
      zoom={props.zoom}
      contractTypesById={props.contractTypesById}
      currentIssueset={props.currentIssueset}
      currentIssuesetItem={props.currentIssuesetItem}
      isFlipChecklistIcons={props.isFlipChecklistIcons}
      areIssuesCollapsed={props.areIssuesCollapsed}
      setSelectMode={setSelectMode}
      comparisonDocumentId={comparisonDocumentId}
      prevComparisonDocumentId={prevComparisonDocumentId}
      fetchIssueComparisonData={props.fetchIssueComparisonData}
      issueComparisonData={props.issueComparisonData}
      applicableClausesPath={props.applicableClausesPath}
      onCurrentIssuesetChange={props.onCurrentIssuesetChange}
      viewMode={props.viewMode}
      checklistProps={props.checklistProps} // props not directly related to ComparePanelComparison
      headerMenuProps={props.headerMenuProps}
      onChecklistScroll={props.onChecklistScroll}
      runDocumentIssuesFind={props.runDocumentIssuesFind}
      selectedChecklistSectionId={props.selectedChecklistSectionId}
      onSelectedChecklistSectionIdChange={
        props.onSelectedChecklistSectionIdChange
      }
    />
  );
}

export default ComparePanel;

/* eslint-disable no-invalid-this */

function isInList(list, value, useRegex) {
  if (!list || !list.find) {
    return false;
  }
  if (useRegex) {
    const regex = new RegExp(value);
    return Boolean(
      list.find(item => item && item.toString && item.toString().match(regex)),
    );
  }
  return Boolean(list.find(item => item === value));
}

export default isInList;

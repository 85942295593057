import basePath from "base_path";
import ActionTypes from "../../constants/action_types";
import requestor from "requestor";
import createReduxPromiseAction from "utils/create_redux_promise_action";

export default createReduxPromiseAction(
  ActionTypes.ISSUE_COMMENT_DELETE,
  (organisationId, issueComment) =>
    requestor["delete"](
      `${basePath}/organisation/${organisationId}/document_issue/${
        issueComment.document_issue_id
      }/comment/${issueComment.id}`,
      {
        withCredentials: true,
      },
    ).then(response => response.data),
);

import _ from "underscore";

function getTopicGroupsSearchData(searchValue, documentTopics) {
  if (!searchValue) {
    return [];
  }
  const words = searchValue.toLowerCase().split(/\s+/);
  const topicGroups = [];
  documentTopics.forEach(topic => {
    const {topicGroupName: name} = topic;
    const searchName = name.toLowerCase();
    if (words.every(word => searchName.indexOf(word) !== -1)) {
      topicGroups.push({name: topic.topicGroupName, id: topic.id});
    }
  });
  return _.chain(topicGroups)
    .uniq(item => item.name)
    .sortBy(item => item.name)
    .value();
}

export default getTopicGroupsSearchData;

import React, {FC, useEffect, useState} from "react";
import {connect} from "react-redux";
import _ from "lodash";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

import UNINITIALISED from "utils/uninitialised";
import WorkflowSelector from "common_components/issue_editor/components/workflow_selector";

import {OrganisationId} from "common/types/organisation";
import {DocumentId} from "common/types/document";
import {
  PrerequisiteWorkflow,
  WorkflowInstanceId,
} from "common/flowmaster/types/workflow";

interface PrerequisiteWorkflowsDialogProps {
  organisationId: OrganisationId;
  documentId: DocumentId;
  isShown: boolean;
  onClose: () => void;
  prerequisiteWorkflows: PrerequisiteWorkflow[] | typeof UNINITIALISED;
  onDocumentPrerequisiteWorkflowRun: (
    documentId: DocumentId,
    workflowInstanceId: WorkflowInstanceId,
  ) => void;
  fetchPrerequisiteWorkflows: () => Promise<void>;
}

const PrerequisiteWorkflowsDialog: FC<PrerequisiteWorkflowsDialogProps> = ({
  organisationId,
  documentId,
  isShown,
  onClose,
  prerequisiteWorkflows,
  onDocumentPrerequisiteWorkflowRun,
  fetchPrerequisiteWorkflows,
}) => {
  const [selectedWorkflowId, setSelectedWorkflowId] = useState(-1);
  const [loading, setLoading] = useState(false);

  const isInitialised = prerequisiteWorkflows !== UNINITIALISED;
  const workflowsArray = isInitialised ? prerequisiteWorkflows : [];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchPrerequisiteWorkflows();
      setLoading(false);
    };
    if (!isInitialised) {
      fetchData();
    }
    return () => setLoading(false);
  }, [fetchPrerequisiteWorkflows, isInitialised]);

  useEffect(() => {
    if (
      isInitialised &&
      workflowsArray.length > 0 &&
      selectedWorkflowId === -1
    ) {
      setSelectedWorkflowId(workflowsArray[0].id);
    }
  }, [isInitialised, workflowsArray, selectedWorkflowId]);

  const formattedPrereqWorkflows = workflowsArray.map(workflow => ({
    ...workflow,
    name: `${workflow.name} (ID: ${workflow.id})`,
  }));

  const selectedWorkflow = workflowsArray.find(
    wf => wf.id === selectedWorkflowId,
  );

  const handleRun = () => {
    if (selectedWorkflowId > 0) {
      onDocumentPrerequisiteWorkflowRun(documentId, selectedWorkflowId);
      onClose();
    }
  };

  const noPrereqWorkflowsAvailable = formattedPrereqWorkflows.length === 0;

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="copy-overrides-dialog"
      open={isShown}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Kick off workflow for document</DialogTitle>
      <DialogContent
        style={{
          fontFamily: "Roboto",
          display: "flex",
          flexDirection: "column",
          rowGap: "1rem",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </div>
        ) : noPrereqWorkflowsAvailable ? (
          <div>No per-clause workflows available</div>
        ) : (
          <>
            <WorkflowSelector
              workflows={formattedPrereqWorkflows}
              selectedWorkflowId={selectedWorkflowId}
              organisationId={organisationId}
              updateselectedWorkflowId={setSelectedWorkflowId}
              contextTypeId={3}
              label="Select workflow"
            />

            {selectedWorkflow && !_.isEmpty(selectedWorkflow.inputs) && (
              <div>
                <h3>Inputs used:</h3>
                <div
                  style={{
                    marginTop: "0.5em",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {Object.entries(selectedWorkflow.inputs).map(
                    ([key, value]) => (
                      <div
                        style={{
                          padding: "0.5rem",
                          background: "#f5f5f5",
                        }}
                        key={key}
                      >
                        <div
                          style={{marginBottom: "0.5em", fontWeight: "bold"}}
                        >
                          {key}
                        </div>
                        <div>{value}</div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleRun}
          color="primary"
          disabled={noPrereqWorkflowsAvailable}
        >
          Run
        </Button>
      </DialogActions>
    </Dialog>
  );
};
function select(state) {
  return {
    prerequisiteWorkflows: state.prerequisiteWorkflows,
  };
}

export default connect(select)(PrerequisiteWorkflowsDialog);

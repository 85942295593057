import _ from "underscore";

export default function constructGroupsWithDefinitions(
  clauseItems,
  documentDefinitions,
  documentSections,
) {
  const result = [];
  const noHeadingTemp = [];
  clauseItems.forEach(clauseItem => {
    if (clauseItem.hasHeading) {
      if (noHeadingTemp.length > 0) {
        const groupedWithoutHeading = groupClausesByDefinitions(
          noHeadingTemp,
          documentDefinitions,
          documentSections,
        );
        result.push({
          hasHeading: false,
          items: groupedWithoutHeading,
        });
        noHeadingTemp.length = 0;
      }
      const groupedWithHeading = groupClausesByDefinitions(
        clauseItem.items,
        documentDefinitions,
        documentSections,
      );
      result.push({
        ...clauseItem,
        items: groupedWithHeading,
      });
      return;
    }
    noHeadingTemp.push(clauseItem.item);
  });

  if (noHeadingTemp.length > 0) {
    const groupedWithoutHeading = groupClausesByDefinitions(
      noHeadingTemp,
      documentDefinitions,
      documentSections,
    );
    result.push({
      hasHeading: false,
      items: groupedWithoutHeading,
    });
  }
  return result;
}

function groupClausesByDefinitions(clauses, definitions, sections) {
  const withDefinitions = {};
  const withoutDefinitions = [];
  const mainSection = sections
    ? sections.find(section => section.reference === "main") ||
      sections.find(
        section => !section.reference.match(/_(execution|background)/),
      ) ||
      sections[0]
    : {};

  clauses.forEach((clause, clauseIndex) => {
    const definition = (definitions || []).find(
      def =>
        clause.clause_section_id === mainSection.id &&
        (`${clause.reference}.`.startsWith(`${def.reference}.`) ||
          `${clause.reference}.`.startsWith(`article${def.reference}.`)) &&
        def.type !== "Inline",
    );
    clause.clean_reference = getCleanClauseReference(
      clause.reference,
      definition,
    );
    if (definition) {
      const definitionReference = getDefinitionReference(definition);
      if (!withDefinitions[definitionReference]) {
        withDefinitions[definitionReference] = {
          isDefinition: true,
          definitionReference,
          order: clauseIndex,
          items: [clause],
        };
      } else {
        withDefinitions[definitionReference].items = [
          ...withDefinitions[definitionReference].items,
          clause,
        ];
      }
    } else {
      withoutDefinitions.push({
        isDefinition: false,
        order: clauseIndex,
        item: clause,
      });
    }
  });
  return _.chain([...withoutDefinitions, ...Object.values(withDefinitions)])
    .sortBy(item => item.order)
    .value();
}

function getDefinitionReference(definition) {
  const {reference, term} = definition;
  const cleanReference = reference.replace(term, "").replace(/\.$/, "");
  return `${cleanReference} ${term} (Definition)`;
}

function getCleanClauseReference(_clauseReference, definition) {
  let clauseReference = _clauseReference;
  if (definition) {
    clauseReference = clauseReference.replace(definition.term, "");
  }
  clauseReference = clauseReference
    .replace(".meaning", "")
    .replace(/\.+/g, ".");
  return clauseReference;
}

import React from "react";
import FiltersMenu from "./filters_menu.js";
import FiltersContainer from "./filters_container.js";
import {
  filterIssueState,
  filterReadState,
  filterReviewState,
  sortBy,
} from "./constants.js";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import TuneIcon from "@material-ui/icons/Tune";
import SortIcon from "@material-ui/icons/Sort";
import {mainStyles, topPanelStyles} from "./styles";

const FiltersPanel = ({
  isFlipChecklistIcons,
  changeItemIssuesFilterValues,
  itemIssuesFilterValues,
  reviewTrackinOn,
  reportView,
}) => {
  const handleToggle = item => () => changeItemIssuesFilterValues(item);

  return (
    <div
      style={
        reportView === "top_panel"
          ? topPanelStyles.container
          : mainStyles.container
      }
    >
      <FiltersContainer
        reportView={reportView}
        title={"Add Filters"}
        theme={isFlipChecklistIcons}
        btnIcon={<TuneIcon />}
      >
        <FiltersMenu
          items={
            reportView === "rag_report" || reportView === "top_panel"
              ? filterIssueState.slice(0, 4)
              : filterIssueState
          }
          title={"Filter Issue State"}
          changeItemIssuesFilterValues={changeItemIssuesFilterValues}
          itemIssuesFilterValues={itemIssuesFilterValues}
        />
        {reviewTrackinOn ? (
          <>
            <FiltersMenu
              items={filterReviewState}
              title={"Filter Review State"}
              changeItemIssuesFilterValues={changeItemIssuesFilterValues}
              itemIssuesFilterValues={itemIssuesFilterValues}
              radio
            />
            <FiltersMenu
              items={filterReadState}
              title={"Filter Read State"}
              changeItemIssuesFilterValues={changeItemIssuesFilterValues}
              itemIssuesFilterValues={itemIssuesFilterValues}
              radio
            />
          </>
        ) : null}
        <div style={mainStyles.clearButton}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<ClearIcon />}
            onClick={handleToggle("delete")}
          >
            Clear Filters
          </Button>
        </div>
      </FiltersContainer>
      <FiltersContainer
        title={`Sort by: ${itemIssuesFilterValues?.sort ?? "Default"}`}
        theme={isFlipChecklistIcons}
        btnIcon={<SortIcon />}
        reportView={reportView}
      >
        <FiltersMenu
          items={reviewTrackinOn ? sortBy : sortBy.slice(0, 2)}
          changeItemIssuesFilterValues={changeItemIssuesFilterValues}
          itemIssuesFilterValues={itemIssuesFilterValues}
          radio
        />
      </FiltersContainer>
    </div>
  );
};

export default FiltersPanel;

import _ from "underscore";

function getUnmatchedChanges(changes, clauses) {
  if (!changes || changes.length === 0 || !clauses) {
    return [];
  }
  let deletedChanges = changes.filter(
    change => change.type === "clause_deletion",
  );

  function processClause(clause) {
    const matchingChange = deletedChanges.find(
      change =>
        change.type === "clause_deletion" &&
        clause.full_reference &&
        clause.full_reference.startsWith(change.old_reference),
    );
    deletedChanges = _.without(deletedChanges, matchingChange);
  }

  clauses.forEach(clause => {
    processClause(clause);
  });
  return deletedChanges;
}

export default getUnmatchedChanges;

import basePath from "base_path";
import ActionTypes from "../constants/action_types";

import createReduxPromiseAction from "utils/create_redux_promise_action";
import {buildQuery} from "utils/uri";

export type FilterOptions = {
  search_term?: string;
  user_id?: number;
};

export default function initDocument(requestor) {
  return createReduxPromiseAction(
    ActionTypes.DOCUMENTS_FETCH,
    (
      organisationId: number,
      projectId: number | string,
      page?: number,
      filterOptions?: FilterOptions,
    ) => {
      const query: Partial<{
        page: number;
        search_term: string;
        user_id: number;
      }> = {};

      if (page !== undefined) {
        query.page = page;
      }
      if (filterOptions?.search_term !== undefined) {
        query.search_term = filterOptions.search_term;
      }
      if (filterOptions?.user_id !== undefined) {
        query.user_id = filterOptions.user_id;
      }

      return requestor
        .get(
          `${basePath}/organisation/${organisationId}/project/${projectId}/document${buildQuery(
            query,
          )}`,
          {withCredentials: true},
        )
        .then(response => ({
          ...response.data,
          projectId: parseInt(projectId as string, 10),
        }));
    },
  );
}

import _ from "underscore";
import convertContentsToListItems from "common/utils/contents/convert_contents_to_list_items";

export default function getLanguage(contents) {
  const listItems = convertContentsToListItems(contents);
  if (_.isArray(listItems)) {
    const language = listItems
      .map(listItem => {
        if (_.isString(listItem)) {
          return {
            level: 1,
            text: listItem,
          };
        }
        if (_.isObject(listItem)) {
          const {indent, text} = listItem;
          if (text) {
            return {
              level: (indent || 0) + 2,
              text,
            };
          }
        }
        return null;
      })
      .filter(value => Boolean(value));
    return language;
  }
  return [];
}

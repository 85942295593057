import Handlebars from "handlebars";
import registerHandlebarsHelpers from "common/utils/handlebars_helpers/register";
import logger from "common/utils/logger";

registerHandlebarsHelpers();

function getPositiveReasonIssueTemplatedText(
  issue,
  fieldName,
  data,
  reasonData,
  parties,
  logInfo = false,
) {
  const fieldText = issue[fieldName];
  if (!fieldText || !data) {
    return fieldText;
  }
  try {
    const template = Handlebars.compile(fieldText);
    if (logInfo) {
      logger.verbose("issue", issue);
      logger.verbose("data", data);
    }
    return template({issue, data, reasonData, parties});
  } catch {
    const errorMessage = [
      `${fieldName} of issue ${issue.name} (id: ${
        issue.id
      }) seems to be corrupted.`,
      "Please check the template.",
    ].join(" ");
    logger.error(errorMessage);
    return "";
  }
}

export default getPositiveReasonIssueTemplatedText;

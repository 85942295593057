import React from "react";

import Tooltip from "@material-ui/core/Tooltip";

export default function CopyTooltip({title, children}) {
  const [open, setOpen] = React.useState(false);
  const handleTooltipClose = () => setOpen(false);
  const handleTooltipOpen = () => setOpen(true);

  return (
    <span>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title || ""}
      >
        <span onMouseUp={handleTooltipOpen} onMouseLeave={handleTooltipClose}>
          {children}
        </span>
      </Tooltip>
    </span>
  );
}

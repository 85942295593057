import getSimilarityData from "./get_similarity_data";
import getSimilarPairsFromClausesBySection from "./get_similar_pairs_from_clauses_by_section";

// TODO: Rename to something like getComparison
async function getClausesDataAsync(
  clauses = [], // applicable clauses of the base document
  comparisonClauses = [], // applicable clauses of the comparison document
  baseDocumentClauses = [], // all clauses of the base document
  comparisonDocumentClauses = [], // all clauses of the comparison document
  useNearbySearchOnly = true,
) {
  const clausesEmpty = clauses.length === 0;
  const comparisonClausesEmpty = comparisonClauses.length === 0;

  if (clausesEmpty && comparisonClausesEmpty) {
    return {
      clauses,
      comparisonClauses,
      similarClauses: [],
      matchedClauses: [],
      nonDirectSearchPerformed: false,
    };
  }

  if (
    ((clausesEmpty && !comparisonClausesEmpty) ||
      (!clausesEmpty && comparisonClausesEmpty)) &&
    useNearbySearchOnly
  ) {
    // If the same reference clause has identical clauseparts,
    // then we deem non-found applicable clauses as ADDED/REMOVED
    const clausesPerReference = {};
    [...clauses, ...comparisonClauses].forEach(clause => {
      const reference = parseInt(clause.reference, 10);
      if (reference) {
        if (clausesPerReference[reference]) {
          clausesPerReference[reference].push(clause);
        } else {
          clausesPerReference[reference] = [clause];
        }
      }
    });

    Object.keys(clausesPerReference).forEach(reference => {
      const referenceInt = parseInt(reference, 10);
      const baseClausesToCompare = baseDocumentClauses
        .filter(clause => parseInt(clause.reference, 10) === referenceInt)
        .map(clause => clause.partial_text);
      const comparisonClausesToCompare = comparisonDocumentClauses
        .filter(clause => parseInt(clause.reference, 10) === referenceInt)
        .map(clause => clause.partial_text);

      let hasIdenticalClauses = false;
      /* eslint-disable no-labels */
      loop1: for (const baseClause of baseClausesToCompare) {
        for (const comparisonClause of comparisonClausesToCompare) {
          if (baseClause === comparisonClause) {
            hasIdenticalClauses = true;
            break loop1;
          }
        }
      }
      clausesPerReference[reference].hasIdenticalClauses = hasIdenticalClauses;
    });

    let hasClausepartsOfNonIdenticalClauses = false;
    let resultClauses = [];
    Object.keys(clausesPerReference).forEach(reference => {
      const clauses = clausesPerReference[reference];
      if (clauses.hasIdenticalClauses) {
        resultClauses = [...resultClauses, ...clauses];
      } else {
        hasClausepartsOfNonIdenticalClauses = true;
      }
    });
    return {
      clauses: clausesEmpty ? [] : resultClauses,
      comparisonClauses: clausesEmpty ? resultClauses : [],
      similarClauses: [],
      matchedClauses: [],
      nonDirectSearchPerformed: false,
      hasClausepartsOfNonIdenticalClauses,
      similarClauseLocationSearchPerformed: true,
    };
  }

  const pairs = [];

  // search that involves comparison of applic clauses vs non-applic
  // clauses (can be for current doc clauses of comparison doc clauses)
  let nonDirectSearchPerformed = false;

  clauses.forEach(clause => {
    comparisonClauses.forEach(comparisonClause => {
      pairs.push({
        clauseId: clause.id,
        comparisonClauseId: comparisonClause.id,
        clause,
        comparisonClause,
        ...getSimilarityData(clause, comparisonClause),
      });
    });
  });

  pairs.sort((a, b) => b.similarityRatio - a.similarityRatio);

  const usedClausesIds = {};
  const usedComparisonClausesIds = {};

  const clausesResult = [];
  const comparisonClausesResult = [];
  const similarClausesResult = [];
  const sameClausesResult = [];

  pairs.forEach(pair => {
    const {clauseId, comparisonClauseId} = pair;
    if (pair.areClausesSimilar) {
      if (!usedClausesIds[clauseId]) {
        if (!pair.areClausesIdentical) {
          similarClausesResult.push(pair);
        } else {
          sameClausesResult.push(pair);
        }
        usedClausesIds[clauseId] = true;
        usedComparisonClausesIds[comparisonClauseId] = true;
      }
    }
  });

  let nonDirectClausesResult = [];
  let similarResultLeft = [];

  // "not SIMILAR" clauses
  const notFoundClauses = clauses.filter(clause => !usedClausesIds[clause.id]);
  if (notFoundClauses.length > 0) {
    const nonIteratedComparisonClauses = getNonIteratedClauses(
      usedComparisonClausesIds,
      comparisonDocumentClauses,
    );
    const resultClauses = await getResultClausesAsync(
      notFoundClauses,
      nonIteratedComparisonClauses,
      clauses,
      comparisonDocumentClauses,
      false,
      useNearbySearchOnly,
    );
    nonDirectSearchPerformed = true;
    nonDirectClausesResult = resultClauses.resultClausesResult;
    similarResultLeft = resultClauses.resultSimilarClausesResult;
    matchedResultRight = resultClauses.resultMatchedClausesResult;
  }

  let nonDirectComparisonClausesResult = [];
  let similarResultRight = [];
  let matchedResultRight = [];

  const notFoundComparisonClauses = comparisonClauses.filter(
    clause => !usedComparisonClausesIds[clause.id],
  );
  if (notFoundComparisonClauses.length) {
    // "NON ITERATED" -> "NOT SIMILAR"
    const nonIteratedClauses = getNonIteratedClauses(
      usedClausesIds,
      baseDocumentClauses,
    );

    const resultComparisonClauses = await getResultClausesAsync(
      notFoundComparisonClauses,
      nonIteratedClauses,
      comparisonClauses,
      baseDocumentClauses,
      true,
      useNearbySearchOnly,
    );
    nonDirectSearchPerformed = true;
    nonDirectComparisonClausesResult =
      resultComparisonClauses.resultClausesResult;
    similarResultRight = resultComparisonClauses.resultSimilarClausesResult;
    matchedResultRight = resultComparisonClauses.resultMatchedClausesResult;
  }

  return {
    clauses: [...clausesResult, ...nonDirectClausesResult],
    comparisonClauses: [
      ...comparisonClausesResult,
      ...nonDirectComparisonClausesResult,
    ],
    similarClauses: [
      ...similarClausesResult,
      ...similarResultLeft,
      ...similarResultRight,
    ],
    nonDirectSearchPerformed,
    matchedClauses: [...sameClausesResult, ...matchedResultRight],
  };
}

function getNonIteratedClauses(usedComparisonClausesIds, allComparisonClauses) {
  return Object.keys(usedComparisonClausesIds).length > 0
    ? allComparisonClauses.filter(
        comparisonClause => !usedComparisonClausesIds[comparisonClause.id],
      )
    : allComparisonClauses;
}

async function getResultClausesAsync(
  notFoundClauses,
  notIteratedComparisonDocumentClauses,
  clauses, // applicable clauses
  allComparisonClauses,
  invertPairs,
  useNearbySearchOnly,
) {
  // invertPairs is used because we render results relatively to a base document,
  // Therefore this should be accounted when populating similar pairs as we directly
  // distinct clauses and comparison clauses there

  const resultSimilarClausesResult = [];
  const resultClausesResult = [];
  const resultMatchedClausesResult = [];
  await processAll(
    notFoundClauses,
    notIteratedComparisonDocumentClauses,
    invertPairs,
    resultSimilarClausesResult,
    resultClausesResult,
    useNearbySearchOnly,
    resultMatchedClausesResult,
  );

  return {
    resultClausesResult,
    resultSimilarClausesResult,
    resultMatchedClausesResult,
  };
}

function processAll(
  notFoundClauses,
  notIteratedComparisonDocumentClauses,
  invertPairs,
  resultSimilarClauses,
  resultClauses,
  useNearbySearchOnly,
  matchedClausesResult,
) {
  return new Promise(resolve => {
    let index = 0;
    function doChunk() {
      const applicableClause = notFoundClauses[index];
      const similarPairs =
        getSimilarPairsFromClausesBySection(
          [applicableClause],
          notIteratedComparisonDocumentClauses,
          invertPairs,
          "getMatchesByExactReference",
          useNearbySearchOnly ? "getNearbyMatches" : "geAllRemainingMatches",
        ) || [];
      const similarPair = similarPairs[0];
      if (similarPair) {
        if (!similarPair.areClausesIdentical) {
          resultSimilarClauses.push(similarPair);
        } else {
          matchedClausesResult.push(similarPair);
        }
      } else {
        resultClauses.push(applicableClause);
      }
      ++index;
      if (index < notFoundClauses.length) {
        setTimeout(doChunk, 150);
      } else {
        resolve();
      }
    }
    doChunk();
  });
}

export default getClausesDataAsync;

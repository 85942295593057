import React from "react";
import PropTypes from "prop-types";

import TextField from "material-ui/TextField";
import EditIcon from "material-ui/svg-icons/image/edit";
import * as colors from "material-ui/styles/colors";
import Chevron from "../routes/document_detail/components/toolbar/buttons/chevron";

const styles = {
  baseStyles: {
    cursor: "default",
    margin: "0 1rem",
  },
  inputStyles: {
    color: "black",
  },
  underlineDisabledStyle: {
    display: "none",
  },
  hiddenWidthHelper: {
    visibility: "hidden",
    position: "absolute",
    left: 0,
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
  },
  iconStyle: {
    fill: colors.grey400,
    position: "relative",
    top: "0px",
    height: "14px",
    width: "14px",
    cursor: "pointer",
    marginTop: "4px",
  },
};

export default class EditableField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldText: this.props.text || "",
      errorText: "",
      isMouseOver: false,
      isFocused: false,
    };
  }

  componentDidMount() {
    if (this.props.fitInputContent) {
      this.fitIntputContent();
      window.addEventListener("resize", this.fitIntputContent);
    }
  }

  componentWillUnmount() {
    if (this.props.fitInputContent) {
      window.removeEventListener("resize", this.fitIntputContent);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.fieldText !== prevState.fieldText &&
      this.props.fitInputContent
    ) {
      this.fitIntputContent();
    }
  }

  fitIntputContent = () => {
    const refWidth = this.hiddenWidthHelperRef.getBoundingClientRect().width;
    this.setState(() => ({
      fieldWidth: refWidth > 872 ? 872 : refWidth,
    }));
  };

  onHoverStart = () => this.setState(() => ({isMouseOver: true}));
  onHoverFinish = () => this.setState(() => ({isMouseOver: false}));

  handleChange = event => {
    this.setState({
      fieldText: event.target.value,
    });
  };

  updateField = async () => {
    if (this.props.validator) {
      const {validateFunction, errorMessage} = this.props.validator;
      if (!validateFunction(this.state.fieldText)) {
        this.setState(() => ({errorText: errorMessage}));
        return;
      }
    }
    const {
      organisationId,
      updateFieldName,
      processTextBeforeUpdate,
    } = this.props;
    if (this.state.fieldText === this.props.text) {
      return;
    }
    const newText = processTextBeforeUpdate
      ? processTextBeforeUpdate(this.props.text, this.state.fieldText)
      : this.state.fieldText;
    if (organisationId) {
      const res = await this.props.onFieldUpdate(organisationId, {
        [updateFieldName]: newText,
      });
      if (!res.value.id) {
        this.setState(() => ({fieldText: this.props.text, errorText: ""}));
      }
      this.setState(() => ({errorText: ""}));
    } else {
      await this.props.onFieldUpdate({
        [updateFieldName]: newText,
      });
      this.setState(() => ({fieldText: newText}));
    }
  };

  render() {
    const {
      rootDivStyles,
      containerStyles,
      inputStyles,
      isMenuOpen,
      openDocumentSelector,
      showChevron,
    } = this.props;
    return (
      <div
        style={{
          ...styles.wrapper,
          ...(rootDivStyles ? rootDivStyles : {}),
        }}
        title={this.state.fieldText}
        className={this.props.className}
      >
        {this.props.icon &&
          React.cloneElement(this.props.icon, {
            style: {
              ...styles.icon,
              ...this.props.icon.props.style,
            },
          })}
        <TextField
          name={this.props.name || "text-field"}
          ref={this.createEditableFieldRef}
          value={this.state.fieldText}
          onChange={this.handleChange}
          onMouseEnter={this.onHoverStart}
          onMouseLeave={this.onHoverFinish}
          style={{
            ...styles.baseStyles,
            width:
              this.props.fitInputContent && this.state.fieldWidth
                ? this.state.fieldWidth + 4
                : "initial",
            ...(containerStyles ? containerStyles : {}),
          }}
          inputStyle={{
            ...styles.inputStyles,
            width:
              this.props.fitInputContent && this.state.fieldWidth
                ? this.state.fieldWidth
                : "initial",
            ...(inputStyles ? inputStyles : {}),
            ...(this.state.isMouseOver && {
              ...this.props.inputHoverStyles,
            }),
            ...(this.state.isFocused && {
              ...this.props.inputFocusStyles,
            }),
          }}
          underlineDisabledStyle={styles.underlineDisabledStyle}
          onBlur={() => {
            this.setState({isFocused: false});
            this.updateField();
          }}
          disabled={!this.props.hasPermissionToEdit}
          errorText={this.state.errorText}
          underlineShow={this.props.underlineShow}
          onFocus={event => {
            this.setState({isFocused: true});
            this.moveCaretAtEnd(event);
          }}
          spellCheck="false"
          floatingLabelText={this.props.label}
        />
        {this.renderEditIcon()}
        {this.renderInputWidthHelperField()}
        {!showChevron ? (
          <Chevron isMenuOpen={isMenuOpen} onClick={openDocumentSelector} />
        ) : null}
      </div>
    );
  }

  renderEditIcon = () => {
    if (!this.props.useEditIcon || !this.props.hasPermissionToEdit) {
      return null;
    }

    return (
      <EditIcon
        style={{
          ...styles.iconStyle,
          ...this.props.editIconStyles,
          visibility: this.state.isMouseOver ? "visible" : "hidden",
        }}
        onClick={this.onEditClick}
      />
    );
  };

  renderInputWidthHelperField = () => {
    const {inputStyles} = this.props;
    return (
      <span
        ref={this.createHiddenWidthHelperRef}
        style={{
          ...styles.hiddenWidthHelper,
          ...(inputStyles ? inputStyles : {}),
        }}
      >
        {this.state.fieldText}
      </span>
    );
  };

  onEditClick = () => this.editableField.focus();

  moveCaretAtEnd(e) {
    const t = e.target.value;
    e.target.value = "";
    e.target.value = t;
  }

  createEditableFieldRef = node => (this.editableField = node);
  createHiddenWidthHelperRef = node => (this.hiddenWidthHelperRef = node);
}

EditableField.propTypes = {
  name: PropTypes.string.isRequired,
  organisationId: PropTypes.string,
  text: PropTypes.string,
  updateFieldName: PropTypes.string.isRequired,
  hasPermissionToEdit: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
    .isRequired,
  containerStyles: PropTypes.object,
  inputStyles: PropTypes.object,
  inputHoverStyles: PropTypes.object,
  inputFocusStyles: PropTypes.object,
  onFieldUpdate: PropTypes.func.isRequired,
  processTextBeforeUpdate: PropTypes.func,
  validator: PropTypes.object,
};

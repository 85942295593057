import React, {useContext} from "react";

import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoIcon from "@material-ui/icons/Info";
import Tooltip from "@material-ui/core/Tooltip";

import DocumentDetailContext from "common_components/context/document_detail_context";
import getZoomedFontSize from "utils/get_zoomed_font_size";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    borderBottom: "1px solid #ddd",
    padding: "0rem 1rem",
  },
  header: {
    height: "4em",
    padding: "0",
    fontWeight: "500",
    display: "flex",
    alignItems: "center",
    flexShrink: "0",
    cursor: "pointer",
  },
  body: {
    padding: "12px 0px",
    display: "flex",
    flexDirection: "column",
  },
  innerBody: {
    flipped: {
      padding: "12px 20px",
      background: "#f4f4f4",
      color: "#212121",
    },
  },
  expandIcon: {
    height: "28px",
    width: "28px",
    fill: "#bbb",
    flexShrink: "0",
    cursor: "pointer",
  },
};

function ItemContainer(props) {
  const {
    children,
    expandable,
    isMenuExpanded,
    onExpandMenu,
    title,
    actionButtons,
    bodyClickHandler,
    useFlippedInnerBodyStyles,
    helpText,
    containerStyles = {},
    headerStyles = {},
    titleStyles = {},
    bodyStyles = {},
    innerBodyStyles = {},
  } = props;
  const context = useContext(DocumentDetailContext);
  const {isFlipChecklistIcons, zoom} = context;
  const onClick = expandable ? onExpandMenu : undefined;
  const isBodyShown = expandable ? isMenuExpanded : true;
  return (
    <div style={{...styles.container, ...containerStyles}}>
      <div
        style={{
          ...styles.header,
          fontSize: getZoomedFontSize(15, "checklist", zoom),
          borderBottom: isBodyShown ? "1px solid #ebebeb" : "unset",
          ...headerStyles,
        }}
        onClick={onClick}
      >
        {isFlipChecklistIcons && renderExpandIcon(props, isFlipChecklistIcons)}
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            ...titleStyles,
            color: isFlipChecklistIcons ? "#333333" : "#42a5f5",
          }}
        >
          {title}
          {helpText && (
            <Tooltip title={helpText}>
              <InfoIcon
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#aaa",
                  marginLeft: "10px",
                }}
              />
            </Tooltip>
          )}
        </div>
        {actionButtons}
        {!isFlipChecklistIcons && renderExpandIcon(props)}
      </div>
      {isBodyShown && (
        <div
          className="body-container"
          style={{...styles.body, ...bodyStyles}}
          onClick={bodyClickHandler}
        >
          <div
            className="inner-body-container"
            style={{
              ...(useFlippedInnerBodyStyles && isFlipChecklistIcons
                ? styles.innerBody.flipped
                : {}),
              ...innerBodyStyles,
            }}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
}

function renderExpandIcon(props, isFlipChecklistIcons) {
  const onClick = props.expandable ? props.onExpandMenu : undefined;

  const ExpandIcon = isFlipChecklistIcons ? MinusIcon : ExpandMoreIcon;
  const CollapseIcon = isFlipChecklistIcons ? PlusIcon : ExpandLessIcon;

  const Icon = props.expandable
    ? props.isMenuExpanded
      ? ExpandIcon
      : CollapseIcon
    : null;
  if (Icon) {
    return (
      <Icon
        onClick={onClick}
        style={{
          ...styles.expandIcon,
          ...props.expandIcon,
          stroke: isFlipChecklistIcons ? "#fff" : "inherit",
        }}
      />
    );
  }
}

export default ItemContainer;

import _ from "underscore";

export default _.memoize(
  (fieldName, issue, updateDocumentIssue) => event => {
    event.stopPropagation();
    let updates;
    if (issue[fieldName] === null) {
      updates = {[fieldName]: true};
    }
    if (issue[fieldName] === true) {
      updates = {[fieldName]: false};
    }
    if (issue[fieldName] === false) {
      updates = {[fieldName]: null};
    }
    if (updateDocumentIssue) {
      updateDocumentIssue(issue, updates);
    }
  },
  (...args) => JSON.stringify(args),
);

/* eslint-disable no-unused-vars */
import React from "react";
/* eslint-enable no-unused-vars */

import Definition from "./definition";
import PendingSave from "../open/pending_save";

const styles = {
  definitionClause: {
    // marginLeft: "4.5em",
  },
};
export default class DefinitionClause extends React.Component {
  render() {
    const {
      node,
      reference,
      firstNode,
      editableClausepart,
      clauseId,
    } = this.props;
    const definitions = node.clauseNodes;
    const level = node.level;

    const hasEditiableChild =
      editableClausepart && editableClausepart.documentClauseId === clauseId;

    return (
      <div
        className="definition-clause"
        style={{...(!firstNode && level === 2 && styles.definitionClause)}}
      >
        {definitions.map((definitionNode, index) => {
          if (definitionNode.type === "PendingSave") {
            return <PendingSave key={`definition-${index}`} />;
          }
          return (
            <Definition
              {...this.props}
              key={`definition-${index}`}
              definitionNode={definitionNode}
              reference={reference}
              index={index}
              hasEditiableChild={hasEditiableChild}
              parentNode={node}
            />
          );
        })}
      </div>
    );
  }
}

import {MaybeUninitialised} from "modules/redux_types";
import _ from "underscore";

const UNINITIALISED = "UNINITIALISED";
export default UNINITIALISED;

export function isInitialised<T>(
  keys: MaybeUninitialised<T>,
): keys is Exclude<T, typeof UNINITIALISED> {
  return Array.isArray(keys)
    ? _.all(keys, key => {
        if (typeof key === "number") {
          return true;
        }
        return key && key !== UNINITIALISED;
      })
    : false;
}

import _ from "underscore";

import calculateDocumentTopics from "utils/topic/calculate_document_topics";
import calculateReferenceAtLevel from "utils/calculate_reference_at_level";

function getDocumentClausesTopics(
  documentClauses,
  topicMasks,
  topicsById,
  topicMasksById,
) {
  return _.chain(documentClauses)
    .map(sectionClauses =>
      sectionClauses.map(clause =>
        calculateDocumentTopics(
          clause,
          topicMasks,
          topicsById,
          topicMasksById,
          clause.reference,
        ),
      ),
    )
    .flatten()
    .groupBy(clauseTopic => clauseTopic.topic.id)
    .map(clauseTopicGroup => {
      const firstClauseTopic = clauseTopicGroup[0];
      return {
        ...firstClauseTopic,
        count: _.chain(clauseTopicGroup)
          .groupBy(clauseTopic => {
            return calculateReferenceAtLevel(clauseTopic.reference || "", 2);
          })
          .keys()
          .value().length,
      };
    })
    .sortBy(clauseTopic => clauseTopic.topic.name)
    .value();
}

export default getDocumentClausesTopics;

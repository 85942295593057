import Describer from "../../../utils/issues/reason/describer";

function reason(clauses, topicsById, issue, showClause = true) {
  const describer = new Describer(clauses, topicsById, issue);
  return (
    [
      `Topic '${describer.topicName}' has parameter '${
        describer.parameterName
      }'`,
      "with value present",
    ].join(" ") + (showClause ? ` in clause ${describer.clauseReference}` : "")
  );
}

export default reason;

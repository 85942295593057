import React from "react";

function renderChangesTable(rawChanges) {
  const changes = rawChanges.map(prepareChangeData);
  return (
    <table style={{width: "100%", borderCollapse: "collapse"}}>
      <thead>
        <tr>
          <th style={{width: "1rem"}} />
          <th style={{width: "3rem"}}>Type</th>
          <th style={{width: "14rem"}}>Notes</th>
        </tr>
      </thead>
      <tbody>
        {changes.map((change, changeIndex) => (
          <tr key={changeIndex}>
            <td style={{textAlign: "right", paddingRight: "0.5rem"}}>
              {changeIndex + 1}.
            </td>
            <td>{change.type}</td>
            <td>
              {(change.notes || []).map((note, noteIndex) => (
                <div key={`note-${changeIndex}-${noteIndex}`}>
                  <span style={{fontWeight: "600"}}>{note.name}: </span>
                  <span>{note.value}</span>
                </div>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function prepareChangeData(change) {
  const {type} = change;
  const result = {
    type: type
      .split("_")
      .map(el => `${el[0].toUpperCase()}${el.substring(1)}`)
      .join(" "),
  };
  switch (change.type) {
    case "clauseheading_text_addition":
    case "clausepart_text_addition":
      result.notes = [{name: "Added text", value: change.new_text}];
      break;
    case "clauseheading_text_alteration":
    case "clausepart_text_alteration":
      result.notes = [{name: "Altered text", value: change.new_text}];
      break;
    case "clausepart_addition":
      result.notes = [{name: "Added subclause", value: change.partial_text}];
      break;
    case "clause_addition":
      result.notes = [
        {name: "Added clause", value: getClauseReference(change)},
      ];
      break;
    case "clauseheading_text_deletion":
    case "clausepart_text_deletion":
      result.notes = [{name: "Removed text", value: change.old_text}];
      break;
    case "clauseheading_deletion":
    case "clause_deletion":
    case "clausepart_deletion":
      result.notes = [{name: "Old reference", value: change.old_reference}];
      break;
    case "definition_deletion":
      result.notes = [{name: "Deleted term", value: change.old_reference}];
      break;
    case "definition_addition":
      result.notes = [{name: "Added definition", value: ""}];
      break;
    default:
      break;
  }
  return result;
}

function getClauseReference(change) {
  let reference;
  try {
    reference = change.clauses[change.new_section_id].find(
      clause => clause.id === change.new_clause_id,
    ).reference;
  } catch {
    reference = change.new_reference;
  }
  return reference;
}

export default renderChangesTable;

import {ActionTypes} from "modules/redux_types";
import createReduxPromiseActionType from "utils/create_redux_promise_action_type";

const actionTypes = {};

const types = [
  "ACCESS_CONTROL_ENTRIES_FETCH",
  "ACCESS_CONTROL_ENTRY_ADD",
  "ACCESS_CONTROL_ENTRY_REMOVE",
] as const;

types.forEach(type => createReduxPromiseActionType(actionTypes, type));

export type ActionType = typeof types[number];

export type AccessControlEntryActionTypes = ActionTypes<ActionType>;

export default actionTypes as AccessControlEntryActionTypes;

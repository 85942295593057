const badRegexes = [
  /\(\\w\*\\W\+\)[*+{}]/,
  /\(\\W\*\\w\+\)[*+{}]/,
  /\(\\W\*\\w\*\)[*+{}]/,
  /\(\\w\*\\W\*\)[*+{}]/,
  /\\W[*+]\\W[*+{}]/,
  /\\w[*+]\\w[*+{}]/,
  /\\s[*+]\\s[*+{}]/,
];
export {badRegexes};

export default function isBadRegex(regex) {
  return badRegexes.find(badRegex => regex.match(badRegex));
}

import React from "react";

import TextField from "material-ui/TextField";
import IconButton from "material-ui/IconButton";

import SearchIcon from "material-ui/svg-icons/action/search";
import ReplaceIcon from "material-ui/svg-icons/action/autorenew";
import ClearIcon from "material-ui/svg-icons/content/clear";
import CircularProgress from "material-ui/CircularProgress";
import * as colors from "material-ui/styles/colors";

const styles = {
  label: {
    position: "relative",
    zIndex: "99",
    top: "12px",
    fontSize: "12px",
    color: "#9E9E9E",
  },
  iconButton: {
    width: 28,
    height: 28,
    padding: 4,
  },
  icon: {
    width: 22,
    height: 22,
    color: colors.grey700,
  },
  rotate: {
    WebkitTransform: "rotate(45deg)",
    MozTransform: "rotate(45deg)",
    msTransform: "rotate(45deg)",
    OTransform: "rotate(45deg)",
    transform: "rotate(45deg)",
  },
};

class FindAndReplace extends React.Component {
  state = {isReplaceRequestPending: false};

  componentDidMount() {
    this.findRef.focus();
  }

  render() {
    const showClearButton = false;
    return (
      <div style={{marginLeft: "1.5rem"}}>
        <div style={{display: "flex", flexDirection: "column"}}>
          <label style={styles.label}>Find</label>
          <div style={{display: "flex", alignItems: "center"}}>
            <TextField
              ref={this.createFindRef}
              name="find"
              className="find"
              value={this.props.find}
              onChange={this.handleFindChange}
              onFocus={this.moveCaretAtEnd}
              spellCheck="false"
              style={{width: "100%"}}
            />
            <IconButton
              tooltip="Find"
              tooltipPosition="top-left"
              iconStyle={styles.icon}
              style={styles.iconButton}
            >
              <SearchIcon />
            </IconButton>
            {showClearButton ? (
              <IconButton
                tooltip="Clear"
                tooltipPosition="top-left"
                iconStyle={styles.icon}
                style={styles.iconButton}
                onClick={this.onClearClick}
              >
                <ClearIcon />
              </IconButton>
            ) : (
              <div style={{width: "28px"}} />
            )}
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "column"}}>
          <label style={styles.label}>Replace</label>
          <div style={{display: "flex", alignItems: "center"}}>
            <TextField
              name="replace"
              className="replace"
              value={this.props.replace}
              onChange={this.handleReplaceChange}
              spellCheck="false"
              style={{width: "100%"}}
            />
            <IconButton
              tooltip="Replace All"
              tooltipPosition="top-left"
              iconStyle={{...styles.icon, ...styles.rotate}}
              style={styles.iconButton}
              onClick={this.onReplaceClick}
            >
              <ReplaceIcon />
            </IconButton>
            {this.renderProgress()}
          </div>
        </div>
      </div>
    );
  }

  renderProgress = () => {
    if (!this.state.isReplaceRequestPending) {
      return <div style={{width: "26px"}} />;
    }
    return (
      <CircularProgress
        size={16}
        thickness={2}
        style={{
          marginLeft: "6px",
          marginRight: "2px",
          top: "1px",
        }}
      />
    );
  };

  handleFindChange = e => {
    const find = e.target.value;
    this.props.onFindAndReplaceChange({
      find,
      replace: this.props.replace,
    });
  };

  handleReplaceChange = e => {
    const replace = e.target.value;
    this.props.onFindAndReplaceChange({
      find: this.props.find,
      replace,
    });
  };

  onClearClick = () =>
    this.props.onFindAndReplaceChange({find: "", replace: ""});

  moveCaretAtEnd(e) {
    const t = e.target.value;
    e.target.value = "";
    e.target.value = t;
  }

  onReplaceClick = () => {
    this.setState(
      () => ({isReplaceRequestPending: true}),
      async () => {
        await this.props.onReplace();
        this.onReplaceRequestFinished();
      },
    );
  };

  onReplaceRequestFinished = () =>
    this.setState(() => ({isReplaceRequestPending: false}));

  createFindRef = node => (this.findRef = node);
}

export default FindAndReplace;

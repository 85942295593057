function updateStateOnItemClick(data, itemToUpdate) {
  data.forEach(item => update(item, itemToUpdate));
  return data;
}

function update(item, itemToUpdate) {
  if (
    item.name === itemToUpdate.name &&
    item.nestingLevel === itemToUpdate.nestingLevel
  ) {
    item.isItemOpened = !item.isItemOpened;
    return;
  }
  if (item.children) {
    item.children.forEach(child => update(child, itemToUpdate));
  }
}

export default updateStateOnItemClick;

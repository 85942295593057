function getZoomedFontSize(baseSize = 12, zoomLabel, zoom, correction = 1) {
  if (!zoom || !zoom[zoomLabel]) {
    return baseSize;
  }
  const zoomLevel = zoom[zoomLabel];
  const correctionCoef = zoomLevel > 1 ? correction : 1;
  return zoomLevel === 1 ? baseSize : baseSize * correctionCoef * zoomLevel;
}

export default getZoomedFontSize;

import * as diff from "diff";

const formatTrackedChanges = (currentText, previousText) => {
  const result = [];
  const wordChanges = diff.diffWords(previousText, currentText);

  const hasChangedLine = wordChanges.find(
    item => (item.added || item.removed) && item.value.indexOf("\n") >= 0,
  );
  if (hasChangedLine) {
    const lineChanges = diff.diffLines(`${previousText}\n`, `${currentText}\n`);
    lineChanges.forEach(item => {
      if (item.added) {
        item.value.split("\n").forEach(line => {
          if (line.length) {
            result.push(`≤${line}≤\n`);
          }
        });
      } else if (item.removed) {
        item.value.split("\n").forEach(line => {
          if (line.length) {
            result.push(`≥${line}≥\n`);
          }
        });
      } else {
        result.push(item.value);
      }
    });
  } else {
    wordChanges.forEach(item => {
      if (item.added) {
        result.push(`≤${item.value}≤`);
      } else if (item.removed) {
        result.push(`≥${item.value}≥`);
      } else {
        result.push(item.value);
      }
    });
  }
  return result.join("").trim();
};

export default formatTrackedChanges;

import {DocumentIssue} from "common/types/document_issue";

function isNonEmptyParentIssueAlert(issue: DocumentIssue) {
  if (issue.trigger_type === "LLM" && issue.llm_response) {
    return (
      issue.llm_response.rag_state !== "Green" &&
      issue.llm_response.rag_state !== "Grey"
    );
  }
  // BUG?: Is issue.reason really an array?
  const triggered = Boolean(issue.reason && issue.reason.length > 0);
  return issue.trigger_display ? triggered : !triggered;
}

function isIssueAlert(issue: DocumentIssue) {
  if (issue.trigger_type !== "LLM" && issue.issue_type === "EMPTY_PARENT") {
    const isTriggered = Boolean(
      issue.subissueData &&
        (issue.subissueData.subissues || []).find(subissue =>
          isNonEmptyParentIssueAlert(subissue),
        ),
    );
    return isTriggered;
  }
  const result = isNonEmptyParentIssueAlert(issue);
  return result;
}

export default isIssueAlert;

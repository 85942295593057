export default function calculateDocumentRagLevelColor(
  documentRagScore,
  ragScoreLevels,
) {
  if (documentRagScore < ragScoreLevels.amber) {
    return "green";
  } else if (
    ragScoreLevels.amber <= documentRagScore &&
    documentRagScore < ragScoreLevels.red
  ) {
    return "amber";
  }
  return "red";
}

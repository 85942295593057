import React from "react";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import userInitAction from "modules/user/actions/user_fetch";
import clearUserAction from "modules/user/actions/user_clear";
import requestor from "requestor";
import {observableFromStore} from "redux-rx";

import {
  MuiThemeProvider as MuiThemeProviderV4,
  createMuiTheme,
} from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import amber from "@material-ui/core/colors/amber";

import getMuiTheme from "material-ui/styles/getMuiTheme";
import * as colors from "material-ui/styles/colors";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";

import {replace} from "react-router-redux";

import TimeoutLogoutDialog from "./timeout_logout_dialog";

import sessionStorage from "utils/session_storage";

const muiTheme = getMuiTheme({
  palette: {
    primary1Color: colors.blue500,
    primary2Color: colors.blue700,
    primary3Color: colors.blue300,
    accent1Color: colors.amber300,
    accent2Color: colors.amber500,
    accent3Color: colors.amber100,
    // textColor: darkBlack,
    // alternateTextColor: white,
    // canvasColor: white,
    // borderColor: grey300,
    // disabledColor: fade(darkBlack, 0.3),
    // pickerHeaderColor: cyan500,
    // clockCircleColor: fade(darkBlack, 0.07),
    // shadowColor: fullBlack,
  },
  toggle: {
    thumbOffColor: "#E0E0E0",
    trackOffColor: "#9E9E9E",
  },
  tableRow: {
    stripeColor: "rgba(182, 201, 216, 0.2)",
    hoverColor: "rgba(182, 201, 216, 0.4)",
  },
  tableHeaderColumn: {
    textColor: "#333",
  },
});

const baseV4Theme = {
  palette: {
    primary: {
      light: blue[300],
      main: blue[500],
      dark: blue[700],
    },
    secondary: {
      main: amber[500],
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  zIndex: {
    modal: 11000,
  },
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: 0,
      },
      sizeLarge: {
        padding: "11px 22px",
      },
    },
  },
};

const wordTaskpaneOverrides = {
  MuiMenuItem: {
    root: {
      fontFamily: "Segoe UI, Roboto, sans-serif",
    },
  },
};

function getV4theme(appType) {
  const theme = baseV4Theme;
  if (appType === "wordTaskpane") {
    theme.overrides = {
      ...baseV4Theme.overrides,
      ...wordTaskpaneOverrides,
    };
  }
  return createMuiTheme(theme);
}

function getSessionStorageKey(appType) {
  return `locationBeforeLogin${appType || ""}`;
}
class App extends React.Component {
  render() {
    return (
      <MuiThemeProviderV4 theme={getV4theme(this.props.appType)}>
        <MuiThemeProvider muiTheme={muiTheme}>
          <>
            {this.props.children}
            <TimeoutLogoutDialog
              user={this.props.user}
              clearUser={this.props.clearUser}
              router={this.props.router}
            />
          </>
        </MuiThemeProvider>
      </MuiThemeProviderV4>
    );
  }

  componentDidUpdate() {
    const {router} = this.props;
    const pathname = router && router.location.pathname;
    if (pathname && pathname.startsWith("/organisation/")) {
      sessionStorage.setItem(
        `locationBeforeLogin${this.props.appType}`,
        pathname,
      );
    }
  }

  componentDidMount() {
    this.setupTransitions();
    this.initialiseUser();
  }

  setupTransitions() {
    const watchableState = this.createWatchableState();
    this.setupNotLoggedInTransition(watchableState);
    this.setupLoginTransition(watchableState);
    this.setupLogoutTransition(watchableState);
  }

  createWatchableState() {
    return observableFromStore(this.context.store);
  }

  setupNotLoggedInTransition(watchableState) {
    const didNotLogin$ = watchableState
      .distinctUntilChanged(state => state.user["logged-in"] !== false)
      .filter(state => state.user["logged-in"] === false);
    didNotLogin$.subscribe(() => {
      const {pathname, search} = this.props.location;
      if (
        pathname.startsWith("/set-new-password") ||
        pathname.startsWith("/signup")
      ) {
        return;
      }
      if (pathname && pathname.indexOf("/organisation/") !== -1) {
        sessionStorage.setItem(
          getSessionStorageKey(this.props.appType),
          `${pathname}${search ? search : ""}`,
        );
      }
      this.props.dispatch(
        replace(
          addSearchIfExists(this.props.appType, this.props.location, "/login"),
        ),
      );
    });
  }

  setupLoginTransition(watchableState) {
    const {appType, location} = this.props;
    const usingWordAddin = appType === "wordTaskpane";
    const didLogin = watchableState
      .distinctUntilChanged(
        state =>
          state.user["logged-in"] !== true &&
          !state.router.locationBeforeTransitions.pathname.startsWith(
            "/organisation/",
          ),
      )
      .filter(state => state.user["logged-in"] === true);
    didLogin.subscribe(props => {
      const userOrganisation = props.user.organisations.find(
        organisation => organisation.user_organisation_id !== null,
      );
      const locationBeforeLogin = sessionStorage.getItem(
        getSessionStorageKey(appType),
      );
      if (
        !usingWordAddin &&
        locationBeforeLogin &&
        locationBeforeLogin.length > 5
      ) {
        sessionStorage.removeItem(getSessionStorageKey(appType));
        return this.props.dispatch(
          replace(addSearchIfExists(appType, location, locationBeforeLogin)),
        );
      }
      const organisationId = userOrganisation
        ? userOrganisation.id
        : props.user.organisations[0].id;
      const path = `/organisation/${organisationId}`;
      return this.props.dispatch(
        replace(addSearchIfExists(appType, location, path)),
      );
    });
  }

  setupLogoutTransition(watchableState) {
    const {appType} = this.props;
    const didLogout = watchableState
      .distinctUntilChanged(
        state =>
          state.user["logged-in"] !== false &&
          state.user["logged-in"] !== "unauthorized" &&
          state.router.locationBeforeTransitions.pathname.startsWith(
            "/organisation/",
          ),
      )
      .filter(state => state.user["logged-in"] === false);
    didLogout.subscribe(() => {
      sessionStorage.removeItem(getSessionStorageKey(appType));
    });
  }

  initialiseUser() {
    this.props.userInitAction();
  }
}

function addSearchIfExists(appType, location, path) {
  if (
    appType === "wordTaskpane" &&
    location &&
    location.search === "?search" &&
    path.indexOf("?search") === -1
  ) {
    return `${path}?search`;
  }
  return path;
}

function select(state) {
  return {
    user: state.user,
    projects: state.projects,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        userInitAction: userInitAction(requestor),
        clearUser: clearUserAction,
      },
      dispatch,
    ),
  };
}

App.propTypes = {
  user: PropTypes.shape({
    "logged-in": PropTypes.oneOf(["unknown", true, false]).isRequired,
    organisations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};

App.contextTypes = {
  store: PropTypes.object,
};

export default connect(select, mapDispatchToProps)(App);
export const Component = App;

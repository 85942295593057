import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    border: "1px solid #CAD5E0",
    borderRadius: "5px",
    position: "relative",
    left: "1.1rem",
    background: "#FFFFFF",
    boxShadow: "0 0 6px #B2B2B2",
  },

  triangle: {
    content: "",
    display: "block",
    position: "absolute",
    top: "1.2rem",
    left: "-5px",
    width: "7px",
    height: "7px",
    background: "#FFFFFF",
    borderLeft: "1px solid #CAD5E0",
    borderTop: "1px solid #CAD5E0",
    transform: "rotate(-45deg)",
  },
});

export default function BoxWithArrow({onClick, children}) {
  const classes = useStyles();
  return (
    <div className={classes.container} onClick={onClick}>
      <div className={classes.triangle} />
      {children}
    </div>
  );
}

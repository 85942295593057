import _ from "underscore";

function findFirstMatches(lists, ignoreFalsy) {
  return lists.map(list =>
    (
      _.first(
        list.filter(
          item => (ignoreFalsy ? item.filter(value => value) : item).length > 0,
        ),
      ) || []
    ).filter(ignoreFalsy ? item => item : () => true),
  );
}

export default findFirstMatches;

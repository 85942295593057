import _ from "lodash";

function mergeClausepartsAndHeadings(clauseparts, headings) {
  if (!clauseparts || clauseparts.length === 0) {
    return headings.map(constructHeadingLikeClausepart);
  } else if (!headings || headings.length === 0) {
    return clauseparts;
  }

  const headingsLikeClauseparts = headings.map(constructHeadingLikeClausepart);
  return _.sortBy(
    [...headingsLikeClauseparts, ...clauseparts],
    "section_id",
    item => parseInt(item.reference, 10) || item.reference,
    "id",
  );
}

function constructHeadingLikeClausepart(heading) {
  return {
    ...heading,
    isHeading: true,
    id: 1,
    clause_id: 1,
    join_type: "NO_NAMES",
    sort_field: "0",
    issue_names: [],
    topic_names: [],
    clause_reference: heading.reference,
    issue_trigger_displays: [],
    negative_match_issue_names: [],
    negative_match_issue_trigger_displays: [],
  };
}

export default mergeClausepartsAndHeadings;

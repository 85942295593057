import React from "react";
import PropTypes from "prop-types";

import SettingsIcon from "@material-ui/icons/Settings";

import SidebarContext from "./context";

import NavItem from "./nav_item";
import OrganisationSelector from "./organisation_selector";

export default class AdminNav extends React.Component {
  render() {
    const {routePath, organisationId, projectId} = this.props;
    return (
      <NavItem
        isCollapsed={!this.context.isExpanded}
        icon={<SettingsIcon />}
        routePath={this.props.routePath}
        children={"Admin Menu"}
        nestedChildren={
          <React.Fragment>
            <div className="app-sidebar-popover__header">
              <OrganisationSelector
                user={this.props.user}
                organisationId={this.props.organisationId}
                getOrganisationUrl={this.props.getOrganisationUrl}
                changeOrganisation={this.props.changeOrganisation}
              />
              {routePath &&
              routePath.indexOf("/document/:documentId") !== -1 ? (
                <NavItem
                  pathname={
                    organisationId && projectId
                      ? `/organisation/${organisationId}/project/${projectId}/detail`
                      : undefined
                  }
                >
                  Project Detail
                </NavItem>
              ) : null}
            </div>
            {this.props.navItems.length > 0 && (
              <div className="app-sidebar-popover__scrollable">
                {this.props.navItems.map((item, index) => (
                  <NavItem
                    key={index}
                    pathname={item.pathname}
                    isActive={item.isActive}
                  >
                    {item.text}
                  </NavItem>
                ))}
              </div>
            )}
          </React.Fragment>
        }
      />
    );
  }
}

AdminNav.contextType = SidebarContext;

AdminNav.propTypes = {
  navItems: PropTypes.array.isRequired,
  organisationId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  routePath: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  getOrganisationUrl: PropTypes.func.isRequired,
  changeOrganisation: PropTypes.func.isRequired,
};

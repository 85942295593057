import React from "react";

import {withStyles} from "@material-ui/core/styles";

import {TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import WarningIcon from "@material-ui/icons/Warning";
import HelpIcon from "@material-ui/icons/Help";
import Button from "@material-ui/core/Button";

import getRoleColor from "../report/utils/get_role_color";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";

const GreyTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#888",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#aaa",
      borderBottomHeight: 1,
    },
  },
})(TextField);

const textInputFontSize = "14px";
const textInputStyle = {fontSize: textInputFontSize};
const menuStyle = {...textInputStyle, minHeight: "20px"};

export default function renderPartyEditor({
  party,
  index,
  hideTerm,
  classes,
  parties,
  roles,
  updatePartyName,
  updatePartyTerm,
  updatePartyRole,
  removeParty,
  duplicateParty,
  mergeParty,
}) {
  const colors = getRoleColor(party, roles);
  const roleId = party.new_role_id || party.role_id || -1;
  const needsRole =
    parties
      .map(pp => pp.new_role_id || pp.role_id)
      .filter(roleId => roleId && roleId > 0).length < roles.length;
  return (
    <div
      className={classes.editorItem}
      style={{
        backgroundColor: colors?.backgroundColor
          ? colors.backgroundColor
          : "transparent",
        border: `1px solid ${
          colors?.backgroundColor ? colors.backgroundColor : "#null"
        }`,
        borderRadius: "5px",
        margin: "3px",
      }}
    >
      <div
        style={
          hideTerm
            ? {}
            : {
                padding: 6,
                margin: 6,
                backgroundColor: "rgba(250,250,250,0.75)",
                borderRadius: "3px",
              }
        }
      >
        <div title={party.name}>
          <GreyTextField
            variant="outlined"
            size="small"
            fullWidth
            label="Name of Party"
            value={party.name}
            inputProps={{style: textInputStyle}}
            onKeyDown={event => {
              if (event.key === "Enter") {
                event.target.blur();
              }
            }}
            onChange={event => {
              updatePartyName(party, event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {(party.name ?? "").length === 0 && (
                    <Tooltip title="Consider including the party name (this will help the system if the party name is referenced in the contract).">
                      <IconButton size="small">
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
        {!hideTerm && (
          <GreyTextField
            label="Defined Term"
            variant="outlined"
            style={{marginTop: "0.5em"}}
            inputProps={{style: textInputStyle}}
            value={party.term}
            size="small"
            fullWidth
            onChange={event => {
              const value = event.target.value;
              updatePartyTerm(party, value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {(party.term ?? "").length === 0 &&
                  (party.new_role_id || party.role_id) ? (
                    <Tooltip title="Please include the term(s) used to identify this party in the contract.">
                      <IconButton size="small">
                        <WarningIcon style={{color: "red"}} />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                  <Tooltip title="If there are multiple defined terms, please list them all separated by commas.">
                    <IconButton size="small">
                      <HelpIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        )}
        <TextField
          select
          variant="outlined"
          value={roleId}
          onChange={e => updatePartyRole(party, e.target.value)}
          style={{width: "100%", marginTop: "8px", ...textInputStyle}}
          SelectProps={{
            labelId: `role-${index}`,
            MenuProps: {disablePortal: true},
            style: {padding: "3px 0", ...textInputStyle},
          }}
          label="Role"
          inputProps={{
            style: {padding: 0},
          }}
          InputProps={{
            endAdornment:
              needsRole && !((party.new_role_id || party.role_id) > 0) ? (
                <InputAdornment
                  position="end"
                  style={{
                    position: "absolute",
                    right: "0",
                  }}
                >
                  <Tooltip title="Please give the party a role if applicable">
                    <IconButton size="small" style={{marginRight: "2em"}}>
                      <WarningIcon style={{color: "red"}} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : null,
          }}
        >
          <MenuItem
            value="-1"
            style={{background: "none", fontStyle: "italic", ...menuStyle}}
          >
            {party.is_role_empty || party.role_id || party.new_role_id
              ? "Ignore"
              : "No Role Set"}
          </MenuItem>
          {!party.role_id && !party.new_role_id && !party.is_role_empty && (
            <MenuItem value="-2" style={{background: "none", ...menuStyle}}>
              Ignore
            </MenuItem>
          )}
          {getRoleOptions(parties, roles, party).map(role => (
            <MenuItem
              key={role.id}
              value={role.id}
              style={
                role.used
                  ? {
                      fontStyle: "italic",
                      color: "#888",
                      ...menuStyle,
                    }
                  : menuStyle
              }
            >
              {role.name}
            </MenuItem>
          ))}
        </TextField>
        {duplicateParty && (
          <div>
            <div className={classes.errorMessage}>
              A party named "{party.name}" already exists{" "}
              {duplicateParty.term
                ? `with defined term(s) "${duplicateParty.term}"`
                : ""}
              .
            </div>
            <Button
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() =>
                duplicateParty.term
                  ? mergeParty(party, duplicateParty)
                  : removeParty(duplicateParty)
              }
            >
              <span className={classes.mergeTermsButtonLabel}>
                {duplicateParty.term ? "Merge terms from" : "Delete"} other
                party
              </span>
            </Button>
          </div>
        )}
      </div>
      <Tooltip title={"Delete party"} placement="top" arrow>
        <IconButton
          style={{marginLeft: 2, backgroundColor: "rgba(250,250,250,0.75)"}}
          size="small"
          onClick={() => removeParty(party)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
}

function getRoleOptions(parties = [], roles = [], party) {
  const resultRoles = [...roles];
  return resultRoles.map(role => {
    const hasRoleParty = parties.find(
      partiesParty =>
        role.id === (partiesParty.new_role_id || partiesParty.role_id),
    );
    const used = !(hasRoleParty
      ? hasRoleParty.name === party.name
      : !hasRoleParty);
    return {...role, used};
  });
}

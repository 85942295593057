const markdownLinkRegex = /\[[^\]]{3,50}\]\([^\s]+\)/gi;

function getTemplateFormat(inputText, useDoubleAsterisk) {
  if (!inputText || typeof inputText !== "string") {
    return [];
  }
  const tickPairs = [];
  let tickPair = [];
  const asteriskPairs = [];
  let asteriskPair = [];
  const addedPairs = [];
  let addedPair = [];
  const deletedPairs = [];
  let deletedPair = [];

  // 1. Finding tick/asterisk/deleted/added pairs to be sure ticks/asterisks have a closing pair
  for (let i = 0; i < inputText.length; i++) {
    const currentSymbol = inputText[i];
    if (currentSymbol === "`") {
      tickPair.push(i);
      if (tickPair.length === 2) {
        tickPairs.push(tickPair);
        tickPair = [];
      }
    }

    if (currentSymbol === "≤") {
      addedPair.push(i);
      if (addedPair.length === 2) {
        addedPairs.push(addedPair);
        addedPair = [];
      }
    }

    if (currentSymbol === "≥") {
      deletedPair.push(i);
      if (deletedPair.length === 2) {
        deletedPairs.push(deletedPair);
        deletedPair = [];
      }
    }

    if (
      (!useDoubleAsterisk && currentSymbol === "*") ||
      (useDoubleAsterisk && currentSymbol === "*" && inputText[i + 1] === "*")
    ) {
      if (useDoubleAsterisk) {
        asteriskPair.push(asteriskPair.length === 0 ? i + 1 : i);
      } else {
        asteriskPair.push(i);
      }

      if (asteriskPair.length === 2) {
        asteriskPairs.push(asteriskPair);
        asteriskPair = [];
      }
    }
  }

  // 2. Finding which symbols have tick/asterisk or both
  const baseResult = [];
  for (let i = 0; i < inputText.length; i++) {
    const currentSymbol = inputText[i];
    if (
      currentSymbol !== "`" &&
      currentSymbol !== "≤" &&
      currentSymbol !== "≥" &&
      (currentSymbol !== "*" ||
        (useDoubleAsterisk &&
          currentSymbol === "*" &&
          inputText[i + 1] !== "*" &&
          inputText[i - 1] !== "*"))
    ) {
      const isTick = Boolean(
        tickPairs.find(tickPair => i > tickPair[0] && i < tickPair[1]),
      );
      const isAsterisk = Boolean(
        asteriskPairs.find(
          asteriskPair => i > asteriskPair[0] && i < asteriskPair[1],
        ),
      );
      const isAdded = Boolean(
        addedPairs.find(addedPair => i > addedPair[0] && i < addedPair[1]),
      );
      const isDeleted = Boolean(
        deletedPairs.find(
          deletedPair => i > deletedPair[0] && i < deletedPair[1],
        ),
      );
      baseResult.push({
        symbol: currentSymbol,
        isTick,
        isAsterisk,
        isAdded,
        isDeleted,
      });
    }
  }

  // 3. Merging like blocks
  const blocks = [];
  let block = [baseResult[0]];
  let blockFullText = baseResult[0] ? baseResult[0].symbol : "";
  for (let i = 1; i < baseResult.length; i++) {
    const baseResultItem = baseResult[i];
    const lastBlockItem = block[block.length - 1];
    if (
      baseResultItem.isTick === lastBlockItem.isTick &&
      baseResultItem.isAsterisk === lastBlockItem.isAsterisk &&
      baseResultItem.isAdded === lastBlockItem.isAdded &&
      baseResultItem.isDeleted === lastBlockItem.isDeleted
    ) {
      block.push(baseResultItem);
      blockFullText += baseResultItem.symbol;
    } else {
      blocks.push(getResultItem(blockFullText, lastBlockItem));
      block = [baseResultItem];
      blockFullText = baseResultItem.symbol;
    }
  }
  if (blockFullText) {
    const lastBlockItem = block[block.length - 1];
    blocks.push(getResultItem(blockFullText, lastBlockItem));
  }

  // 4. Find and replace text to hyperlink object
  let result = [];
  blocks.forEach(item => {
    if (markdownLinkRegex.test(item.text)) {
      const newVal = replaceHyperlink(item.text, item);
      result = [...result, ...newVal];
    } else {
      result.push({
        ...item,
        isAnchor: false,
      });
    }
  });

  return result;
}

function replaceHyperlink(text, {isTick, isAsterisk, isAnchor}) {
  const matched = text.match(markdownLinkRegex);
  const defaultObj = {
    isTick,
    isAsterisk,
    isAnchor,
    isAdded: false,
    isDeleted: false,
  };
  let result = [];
  let mapped = [];
  if (matched) {
    mapped = matched.map(item => {
      return {
        text: item.match(/\[(.*?)\]/)[1],
        isAnchor: item.match(/\((.*?)\)/)[1],
        isTick: false,
        isAsterisk: false,
        isAdded: false,
        isDeleted: false,
      };
    });
    const splitStr = text
      .replace(markdownLinkRegex, "$@|@|@$")
      .split("$@|@|@$");
    splitStr.forEach((item, index) => {
      if (index === splitStr.length - 1) {
        result.push({text: item, ...defaultObj});
      } else {
        result = [
          ...result,
          {
            text: item,
            ...defaultObj,
          },
          {
            ...mapped[index],
          },
        ];
      }
    });
  }
  return result;
}

function getResultItem(blockFullText, lastBlockItem) {
  return {
    text: blockFullText,
    isTick: lastBlockItem.isTick,
    isAsterisk: lastBlockItem.isAsterisk,
    isAdded: lastBlockItem.isAdded,
    isDeleted: lastBlockItem.isDeleted,
  };
}

export default getTemplateFormat;
